<ion-split-pane content-id="main">
  <ion-menu
    [disabled]="!isAuthenticated"
    [swipeGesture]="false"
    content-id="main"
  >
    <app-desktop-nav *ngIf="isAuthenticated">
    </app-desktop-nav>
  </ion-menu>

  <ion-router-outlet id="main" [swipeGesture]="false" animated="false">

  </ion-router-outlet>

  <ion-modal class="hide"></ion-modal>
</ion-split-pane>

<tidy-translations [translations]="translations"></tidy-translations>
