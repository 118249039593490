<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="('Contact ' | translate) + data?.shared_link_data?.customer?.first_name">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <ng-container *ngIf="!isPrivate">
        <tidy-row [align]="'center'">
          <tidy-text
            [body]="('We generate a temporary phone number for each job. ' | translate) +
            data?.shared_link_data?.customer?.first_name +
            (' will not see your phone number.' | translate)">
          </tidy-text>
        </tidy-row>

        <tidy-row [align]="'center'" *ngIf="!proNeedsPhone">
          <tidy-text
            [body]="('You can only call using the phone number on your account:' | translate) +
            (data?.shared_link_data?.homekeepers[0]?.phone | telephone:'format')">
          </tidy-text>
        </tidy-row>

        <ng-container *ngIf="proNeedsPhone">
          <tidy-row [align]="'center'">
            <tidy-text
              body="You can only call using the phone number on your account. Please add a phone number below.">
            </tidy-text>
          </tidy-row>

          <tidy-card>
            <tidy-row class="field-top-padding">
              <form [formGroup]="form">
                <tidy-input-international-phone
                  formControlName="phone"
                  [label]="'Phone Number'"
                  data-cy="phone"
                  [form]="form.controls.phone"
                  [inputMode]="'numeric'"
                  [submitted]="submitted"
                  [errorMessage]="'Please enter a valid phone number.'"
                  [icon]="'assets/svg/phone-portrait-outline.svg'"
                  [defaultCountryCode]="'US'"
                  [activeIcon]="'assets/svg/phone-portrait-outline-tidy-green.svg'">
                </tidy-input-international-phone>
              </form>
            </tidy-row>
          </tidy-card>
        </ng-container>
      </ng-container>

      <tidy-row [align]="'center'" *ngIf="isPrivate">
        <tidy-text
          [body]="data?.shared_link_data?.customer?.first_name +
          '\'s phone number: ' +
          (data?.shared_link_data?.customer?.phone_number | telephone:'format')">
        </tidy-text>
      </tidy-row>

      <tidy-two-column-row>
        <tidy-button item-left
          [text]="'Call'"
          [action]="contact.bind(this, 'tel')"
          class="primary">
        </tidy-button>
        <tidy-button item-right
          [text]="'Text'"
          [action]="contact.bind(this, 'sms')"
          class="primary">
        </tidy-button>
      </tidy-two-column-row>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
