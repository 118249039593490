<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Pause All Bookable Hours'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form">
    <tidy-wrapper>
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

        <tidy-row [align]="'center'">
          <tidy-text [body]="'Please let us know when and why you are pausing.'">
          </tidy-text>
        </tidy-row>

        <tidy-row [align]="'center'">
          <tidy-text [body]="'All scheduled jobs on this date and after will be cancelled.  Normal cancellation fees apply.'">
          </tidy-text>
        </tidy-row>

        <tidy-card>
          <tidy-row class="field-top-padding field-bottom-padding">
            <tidy-datepicker
              [label]="'Pause Start Date'"
              [icon]="'assets/svg/calendar.svg'"
              formControlName="date"
              [minDate]="minDate"
              [minSameDate]="true"
              [submitted]="submitted"
              [form]="form.controls.date"
              [errorMessage]="'Please select a date.'">
            </tidy-datepicker>
          </tidy-row>
          <tidy-row>
            <tidy-textarea
              [label]="'Why Are You Pausing?'"
              [icon]="'assets/svg/help.svg'"
              [activeIcon]="'assets/svg/help-tidy-green.svg'"
              formControlName="reason"
              [form]="form.controls.reason"
              [submitted]="submitted"
              [errorMessage]="'Please let us know why you are pausing.'">
            </tidy-textarea>
          </tidy-row>
          <tidy-error-message
            *ngIf="errorMessage"
            [text]="errorMessage">
          </tidy-error-message>
        </tidy-card>

        <tidy-two-column-row>
          <tidy-button
            [action]="goBack.bind(this)"
            [text]="'Go Back'"
            class="primary">
          </tidy-button>

          <tidy-button
            [action]="pause.bind(this)"
            [text]="'Pause'"
            class="secondary">
          </tidy-button>
      </tidy-two-column-row>
    </tidy-wrapper>
  </form>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
