import { AlreadyAuthenticatedGuard } from 'src/providers/guard/already-authenticated.guard';
import { AuthGuard } from 'src/providers/guard/auth.guard';
import { SyncDataGuard } from 'src/main-workflow/guards/mw.sync-data.guard';
import { IncompleteRegisterGuard } from 'src/providers/guard/incomplete-register.guard';

export const configureRouteGuards = config => {
    const guards = [];

    if (!config.public) {
      guards.push(AuthGuard);
    }

    if (config.skipWhenAuthenticated) {
        guards.push(AlreadyAuthenticatedGuard);
    }

    if (config.syncData) {
      guards.push(SyncDataGuard);
    }

  if (config.redirectWhenIncompleteRegister) {
    guards.push(IncompleteRegisterGuard);
  }

    if (guards.length > 0) {
        config.canActivate = [...guards, ...(config.canActivate || [])];
    }
};
