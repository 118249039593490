<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="false"
    [title]="'Welcome to TIDY!'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'The TIDY Business Manager App works best on your mobile phone.  After downloading the app, just log in to continue.'">
      </tidy-text>
    </tidy-row>
    <tidy-row [align]="'center'">
      <tidy-text
        (action)="linkToGooglePlay()"
        [helper]="'Download on Google Play'"
      >
      </tidy-text>
    </tidy-row>
    <tidy-row [align]="'center'">
      <tidy-text
        (action)="linkToIOS()"
        [helper]="'Download on iOS'"
      >
      </tidy-text>
    </tidy-row>
    <tidy-row [align]="'center'">
      <tidy-text
        (action)="linkToDashboard()"
        [helper]="'Skip and view on your browser'"
      >
      </tidy-text>
    </tidy-row>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
