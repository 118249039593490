import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Certification } from 'src/providers/certification/certification';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

import { SuccessPageParamsModel } from 'src/pages/success/success';
import { ReapplyModel } from 'src/shared/models/certification.model';

@Component({
  templateUrl: 'reapply.html'
})

export class ReapplyPage {

  errorMessage: string;
  form: UntypedFormGroup;
  questions = [];

  constructor(
    private customNavCtrl: CustomNavController,
    private certification: Certification,
    private fb: UntypedFormBuilder,
    private iabUtils: InAppBrowserUtils
  ) {
    this.form = this.fb.group({
      hadBusiness: [false, Validators.requiredTrue],
      backgroundCheck: [false, Validators.requiredTrue],
      felony: [false, Validators.requiredTrue],
      agreeTerms: [false, Validators.requiredTrue],
      waiver: [false, Validators.requiredTrue],
      notGuaranteed: [false, Validators.requiredTrue],
    });
    this.questions = [
      {
        viewValue: 'I had my own business before, and that business is still active.',
        formValue: 'hadBusiness'
      },
      {
        viewValue: 'I have not had any changes to my background check.',
        formValue: 'backgroundCheck'
      },
      {
        viewValue: 'I have never had any safety or theft related misdimeanor or felonies.',
        formValue: 'felony'
      },
      {
        viewValue: '',
        formValue: 'agreeTerms'
      },
      {
        viewValue: 'In consideration of TIDY re-reviewing my account to see if I am eligible to be certified, I agree to release TIDY and its agents, affiliates, and employees from any and all actions, suits, damages, claims, and demands, of every kind whatsoever which I have or may have against TIDY for anything whatsoever from the beginning of time through the date of this release, except for any claim that cannot be waived by law.  I knowingly waive any and all rights and protections under California Civil Code Section 1542, which section has been duly explained and read as follows: \“A general release does not extend to claims that the creditor or releasing party does not know or suspect to exist in his favor at the time of executing the release, and that if known by him would have materially affected his settlement with the debtor or released party.\”',
        formValue: 'waiver'
      },
      {
        viewValue: 'I understand that reviewing the account is not guaranteed to lead to re-certification, it is subject to your account history.',
        formValue: 'notGuaranteed'
      }
    ];
  }

  async confirmReapply() {
    this.errorMessage = '';
    if (!this.form.valid) {
      this.errorMessage = 'You must agree to all of the above to reapply.'
      return;
    }
    try {
      const reapply: ReapplyModel = await this.certification.reapply();
      const successParams: SuccessPageParamsModel = this.getSuccessPage(reapply.eligible);
      this.customNavCtrl.navigateForward('success', successParams);
    } catch (err) {
      this.errorMessage = err.message;
    }
  }

  getSuccessPage(reapplyEligible) {
    if (reapplyEligible) {
      return {
        header: 'Succesfully Re-Certified',
        body: `Congratulations, you have been re-certified. Please ensure your calendar and drive time are up to date.`,
        buttonText: 'Ok',
        buttonRoute: `dashboard`
      };
    } else {
      return {
        header: 'Unable to Re-Certify',
        body: `We were not able to certify you at this time. Please try again in at least 14 days.`,
        buttonText: 'Ok',
        buttonRoute: `dashboard`
      };
    }
  }

  openAgreement = () => {
    this.iabUtils.openUrl('https://www.tidy.com/legal/service-provider/');
  }

}
