<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Payout Settings'"
    [customBack]="'more'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'TIDY pays out once a week. Your payout setting:'">
        </tidy-text>
      </tidy-row>

      <ng-container *ngIf="payoutSetting == 'check'">
        <tidy-card>
          <tidy-row [alignLastItemRight]="true" class="extra-bottom-padding">
            <tidy-image
              [src]="'assets/svg/mail.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Check'">
            </tidy-text>
            <tidy-text
              [actionBody]="'Edit'"
              class="link"
              (action)="goToEditPayoutSettingsPage()">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'We will send your payouts via check.'">
            </tidy-text>
          </tidy-row>
        </tidy-card>
      </ng-container>

      <ng-container *ngIf="payoutSetting == 'credit_card'">
        <tidy-card>
          <tidy-row [alignLastItemRight]="true" class="extra-bottom-padding">
            <tidy-image
              [src]="'assets/svg/card.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Credit Card'">
            </tidy-text>
            <tidy-text
              [actionBody]="'Edit'"
              class="link"
              (action)="goToEditPayoutSettingsPage()">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'We will provide you with a one time use card to charge clients. This typically incurs fees by your credit card processor.'">
            </tidy-text>
          </tidy-row>
        </tidy-card>
      </ng-container>

      <ng-container *ngIf="payoutSetting == 'direct_deposit'">
        <tidy-card>
          <tidy-row [alignLastItemRight]="true" class="extra-bottom-padding">
            <tidy-image
              [src]="'assets/svg/business.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Direct Deposit'">
            </tidy-text>
            <tidy-text
              [actionBody]="'Edit'"
              class="link"
              (action)="goToEditPayoutSettingsPage()">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'We will deposit your payouts directly into your bank account.'">
            </tidy-text>
          </tidy-row>
        </tidy-card>
      </ng-container>

      <tidy-card *ngIf="stripeResponse?.status === 'needs_verification'">
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            [src]="'assets/svg/business.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Verification Needed'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="'Stripe (the payment provider handling your account) requires additional information in order to process payments in a timely fashion.'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="stripeResponse?.bank_name"
            [translate]="false">
          </tidy-text><br>
          <tidy-text
            [body]="'Account ending in '">
          </tidy-text>
          <tidy-text
            [body]="stripeResponse?.account_number + ' '">
          </tidy-text>
          <tidy-text
            [body]="' Edit'"
            (action)="editBank()"
            class="link">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-button
            [text]="'Verify Information'"
            [action]="editStripeInfo.bind(this)"
            class="primary">
          </tidy-button>
        </tidy-row>
      </tidy-card>

      <tidy-card *ngIf="stripeResponse?.status === 'pending'">
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            [src]="'assets/svg/business.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Verification Pending'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="'Stripe is verifying your information, no action needed.'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="stripeResponse?.bank_name">
          </tidy-text><br>
          <tidy-text
            [body]="'Account ending in '">
          </tidy-text>
          <tidy-text
            [body]="stripeResponse?.account_number + ' '">
          </tidy-text>
          <tidy-text
            [helper]="'Edit'"
            (action)="editBank()"
            class="link">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-card *ngIf="stripeResponse?.status === 'verified'">
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            [src]="'assets/svg/business.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Bank Account'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="stripeResponse?.bank_name"
            [translate]="false">
          </tidy-text><br>
          <tidy-text
            [body]="'Account ending in '">
          </tidy-text>
          <tidy-text
            [body]="stripeResponse?.account_number + ' '">
          </tidy-text>
          <tidy-text
            [body]="'Edit'"
            (action)="editBank()"
            class="link">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [helper]="'Your ID has been verified by Stripe.'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [helper]="'Click here'"
            (action)="learnMoreTaxes()"
            class="link">
          </tidy-text>
          <tidy-text
            [helper]="' for 1099 or Tax Questions'">
          </tidy-text>
        </tidy-row>
      </tidy-card>

    </ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
