import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Certification } from 'src/providers/certification/certification';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Me } from 'src/shared/providers/me';

@Component({
  selector: 'validate-phone',
  templateUrl: 'validate-phone.html',
})

export class OnboardingValidatePhonePage {

  currentPageState: PageState = PageState.EDIT;
  errorMessage: string;
  form: UntypedFormGroup;
  finishPage: string;
  pageContent: PageContent;
  PageState = PageState;
  submitted = false;
  proCountryCode = '';

  constructor(
    private certification: Certification,
    private navCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private me: Me
  ) {
    this.form = this.fb.group({
      phone: ['', Validators.required],
      code: ['']
    });
    this.pageContent = this.getPageContent(PageState.EDIT);
  }

  async ionViewDidEnter() {
    this.finishPage = this.navCtrl.getParam('finishPage', true);
    try {
      const me = await this.me.load();
      const countryCode = me.profile.address.country_code;
      this.proCountryCode = countryCode;
    } catch (err) {
      this.errorMessage = err?.error?.message ?? err?.message;
    }
  }

  async submitPhone() {
    this.errorMessage = '';
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    try {
      await this.me.updatePhone(this.form.value.phone);
      this.currentPageState = PageState.CODE;
      this.pageContent = this.getPageContent(this.currentPageState);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async submitVerificationCode() {
    this.errorMessage = '';
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    try {
      await this.certification.finishStep('phone_verification', { code: this.form.value.code });
      if (this.finishPage == 'ready-to-leave') {
        this.showSuccessPage();
      } else {
        this.navCtrl.navigateForward(this.finishPage);
      }
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async showSuccessPage() {
    const data = await this.me.fetchWithoutCache();
    const successParams = {
      header: 'Phone Added',
      body: 'You can now contact the client or notify them that you are on the way to the job.',
      buttonText: 'Ok',
      buttonRoute: 'ready-to-leave'
    };
    this.navCtrl.navigateForward('success', successParams);
  }

  async buttonAction() {
    if (this.currentPageState === PageState.EDIT) {
      await this.submitPhone();
    } else {
      this.submitVerificationCode();
    }
  }

  getPageContent(state: PageState): PageContent {
    const contents = {
      edit: {
        title: 'Confirm Phone',
        label: 'What is your number?',
        formControlName: 'phone',
        inputMask: 'phone',
        buttonText: 'Send Verification SMS',
        textBellowButton: ''
      },
      code: {
        title: 'Code Sent',
        label: 'Verification code',
        formControlName: 'code',
        inputMask: '',
        buttonText: 'Confirm',
        textBellowButton: 'Check your SMS messages for a verification code.'
      }
    };
    return contents[state];
  }
}

interface PageContent {
  title: string;
  label: string;
  formControlName: string;
  inputMask: string;
  buttonText: string;
  textBellowButton: string;
}

enum PageState {
  EDIT= 'edit',
  CODE = 'code'
}
