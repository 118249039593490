import { Injectable } from '@angular/core';
import { HttpClient } from 'src/shared/providers/http/http-client';

import { MileageTrackingModel, MileageTrackingRecordModel } from 'src/shared/models/mileage-tracking.model';

@Injectable()
export class MileageTracking {

  constructor(
    private httpClient: HttpClient
  ) {}

  getMileageRecordByDate(startDate, endDate): Promise<MileageTrackingModel[]> {
    const url = `api/v1/homekeeper/mileage-tracking-records/list-by-month?start_date=${startDate}&end_date=${endDate}`;
    return this.httpClient.get(url);
  }

  getMileageRecordByHkJob(hKJobId): Promise<MileageTrackingRecordModel[]> {
    const url = `api/v1/homekeeper/mileage-tracking-records?homekeeper_job_id=${hKJobId}`;
    return this.httpClient.get(url);
  }

  getMileageRecordById(mileageTrackingRecordId): Promise<MileageTrackingRecordModel> {
    const url = `api/v1/homekeeper/mileage-tracking-records/${mileageTrackingRecordId}`;
    return this.httpClient.get(url);
  }

  updateMileageTracking(mileageTrackingRecordId, mileage, start, end): Promise<MileageTrackingRecordModel> {
    const data = {
      estimated_mileage: mileage,
      start_location_name: start,
      end_location_name: end
    }
    const url = `api/v1/homekeeper/mileage-tracking-records/${mileageTrackingRecordId}`;
    return this.httpClient.put(url, data);
  }
}
