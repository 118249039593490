<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="!justAcceptedProposal"
    [noBow]="justAcceptedProposal"
    [title]="proposal ? ((proposal.status | titlecase) + ' Proposal') : ''">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row *ngIf="proposal?.status === 'accepted'" [align]="'center'">
        <tidy-text
          [body]="('This proposal was accepted on ' | translate) + (proposal?.accepted_at | tdate:'M/D') + '.'">
        </tidy-text>
      </tidy-row>

      <tidy-row *ngIf="proposal?.status === 'cancelled'" [align]="'center'">
        <tidy-text
          [body]="('This proposal was cancelled on ' | translate) + (proposal?.cancelled_at | tdate:'M/D') + '.'">
        </tidy-text>
      </tidy-row>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="client?.account_name && client?.account_name !== '' ? client?.account_name : (client?.first_name | titlecase)"
          [translate]="false">
        </tidy-text>
        <tidy-text
          [body]="' can see your proposal at their link. '">
        </tidy-text>
        <tidy-text
          [body]="'Copy URL'"
          (action)="privatePayments.copyWebsite(client.client_hub_uuid)"
          class="link">
        </tidy-text>
        <tidy-text
          *ngIf="privatePayments.isWebsiteCopied()"
          [helper]="' Copied'">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <tidy-row class="extra-bottom-padding">
          <tidy-grid>
            <tidy-row>
              <ion-row>
                <ion-col size="6">
                  <tidy-row>
                    <tidy-text
                      [title]="'Proposal From'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [body]="proName"
                      [translate]="false">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [body]="proEmail"
                      [translate]="false">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [body]="proPhoneNumber | telephone:'format'">
                    </tidy-text>
                  </tidy-row>
                </ion-col>
                <ion-col>
                  <!--TODO add logo-->
                </ion-col>
              </ion-row>
            </tidy-row>
          </tidy-grid>
        </tidy-row>

        <tidy-row class="extra-bottom-padding">
          <!--TODO divider to have [color] input and add dynamic color-->
          <tidy-divider
            class="full-card-width thick"
            style="background: #4DAEED">
          </tidy-divider>
        </tidy-row>

        <div style="margin-top: -15px; background: #F4F4F4; margin-left: -15px; margin-right: -15px; padding: 15px; padding-top:10px ">
          <tidy-row [alignLastItemRight]="true">
            <tidy-text
              [body]="'To: '">
            </tidy-text>
            <tidy-text
              [body]="(proposal?.customer?.account_name !== '' && proposal?.customer?.account_name) ?
              proposal?.customer?.account_name :
              (proposal?.customer?.first_name + ' ' + (proposal?.customer?.last_name | abbrLastName: true))"
              [translate]="false">
            </tidy-text>
            <tidy-text
              [body]="('Sent ' | translate) +
              (proposal?.created_at | tdate:'M/D/YY')">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="proposal?.customer?.email">
            <tidy-text
              [body]="('Email: ' | translate) +
              proposal?.customer?.email"
              [translate]="false">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              *ngIf="proposal?.addresses?.length > 1"
              [body]="'Valid At: All Addresses'">
            </tidy-text>
            <tidy-text
              *ngIf="proposal?.addresses?.length == 1"
              [body]="('Valid At: ' | translate) +
              proposal?.addresses[0]?.address +
              ', ' +
              (proposal?.addresses[0]?.unit ? ('Unit ' + proposal?.addresses[0]?.unit + ', ') : '') +
              proposal?.addresses[0]?.zip">
            </tidy-text>
          </tidy-row>
        </div>

        <tidy-row class="extra-top-padding" [alignLastItemRight]="true">
          <tidy-text
            [body]="'Service'">
          </tidy-text>
          <tidy-text
            [body]="'Cost'">
          </tidy-text>
        </tidy-row>

        <tidy-row class="extra-bottom-padding">
          <tidy-divider>
          </tidy-divider>
        </tidy-row>

        <tidy-row [alignLastItemRight]="true">
          <div style="width:85%">
            <tidy-text
              [header]="proposal?.proposable?.team_service?.name | titlecase">
            </tidy-text>
          </div>
          <div>
            <tidy-text
              [body]="(proposal?.proposable?.client_price | tcurrency)">
            </tidy-text>
          </div>
        </tidy-row>

        <tidy-row style="width:85%" class="extra-bottom-padding">
          <tidy-text
            [body]="proposal?.description">
          </tidy-text>
        </tidy-row>

        <tidy-row [align]="'center'" class="extra-top-padding">
          <tidy-text
            [body]="'We are proud to offer this option for 30 days:'">
          </tidy-text>
        </tidy-row>

        <tidy-row class="no-padding" [align]="'center'">
          <tidy-text
            [body]="proName"
            class="signature"
            [translate]="false">
          </tidy-text>
        </tidy-row>

        <tidy-divider
          style="margin-top:-15px">
        </tidy-divider>
      </tidy-card>

      <tidy-button
        *ngIf="proposal?.status === 'pending'"
        [action]="markAccepted.bind(this)"
        [text]="'Mark as Accepted'"
        class="primary">
      </tidy-button>

      <tidy-button
        *ngIf="proposal?.status === 'accepted'"
        [action]="bookJob.bind(this)"
        [text]="'Schedule Job'"
        class="primary">
      </tidy-button>

      <tidy-button
        *ngIf="justAcceptedProposal"
        [action]="goToDashboardPage.bind(this)"
        [text]="'Go to Dashboard'"
        class="secondary">
      </tidy-button>

      <tidy-row [align]="'center'" *ngIf="proposal?.status !== 'cancelled'">
        <tidy-text
          [body]="'Cancel Proposal'"
          class="link"
          (action)="cancelProposal()">
        </tidy-text>
      </tidy-row>

    </ng-container>

    <ng-container *ngIf="!loaded">

      <tidy-row>
        <ion-skeleton-text animated style="width: 100%; margin: auto"></ion-skeleton-text><br>
        <ion-skeleton-text animated style="width: 140px; margin: auto"></ion-skeleton-text>
      </tidy-row>

      <tidy-card>
        <tidy-row class="extra-bottom-padding">
          <tidy-grid>
            <tidy-row>
              <ion-row>
                <ion-col size="6">
                  <tidy-row>
                    <ion-skeleton-text animated="true" style="width: 150px; height: 20px"></ion-skeleton-text>
                  </tidy-row>
                  <tidy-row>
                    <ion-skeleton-text animated style="width: 90px"></ion-skeleton-text>
                  </tidy-row>
                  <tidy-row>
                    <ion-skeleton-text animated style="width: 105px"></ion-skeleton-text>
                  </tidy-row>
                  <tidy-row>
                    <ion-skeleton-text animated style="width: 80px"></ion-skeleton-text>
                  </tidy-row>
                </ion-col>
              </ion-row>
            </tidy-row>
          </tidy-grid>
        </tidy-row>

        <tidy-row class="extra-bottom-padding">
          <!--TODO divider to have [color] input and add dynamic color-->
          <tidy-divider
            class="full-card-width thick"
            style="background: #275C7C">
          </tidy-divider>
        </tidy-row>

        <div style="margin-top: -15px; background: #F4F4F4; margin-left: -15px; margin-right: -15px; padding: 15px; padding-top:10px ">
          <tidy-row>
            <tidy-grid>
              <ion-row>
                <ion-col size="5">
                  <ion-skeleton-text animated style="width: 125px"></ion-skeleton-text>
                </ion-col>
                <ion-col size="7">
                  <ion-skeleton-text animated style="width: 105px; float: right"></ion-skeleton-text>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 90px"></ion-skeleton-text>
                </ion-col>
              </ion-row>
            </tidy-grid>
          </tidy-row>
        </div>

        <tidy-row class="extra-top-padding">
          <tidy-grid>
            <ion-row>
              <ion-col size="5">
                <ion-skeleton-text animated style="width: 60px"></ion-skeleton-text>
              </ion-col>
              <ion-col size="7">
                <ion-skeleton-text animated style="width: 50px; float: right"></ion-skeleton-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>

        <tidy-row class="extra-bottom-padding">
          <tidy-divider>
          </tidy-divider>
        </tidy-row>

        <tidy-row>
          <tidy-grid>
            <ion-row>
              <ion-col size="5">
                <ion-skeleton-text animated style="width: 140px; height: 15px"></ion-skeleton-text>
              </ion-col>
              <ion-col size="7">
                <ion-skeleton-text animated style="width: 50px; float: right"></ion-skeleton-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>

        <tidy-row>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </tidy-row>

        <tidy-row class="extra-top-padding extra-bottom-padding">
          <ion-skeleton-text animated style="width: 280px; margin: auto"></ion-skeleton-text>
        </tidy-row>

        <tidy-row class="extra-top-padding">
          <ion-skeleton-text animated style="width: 270px; height: 35px; margin: auto"></ion-skeleton-text>
        </tidy-row>

        <tidy-divider>
        </tidy-divider>
      </tidy-card>

      <tidy-row>
        <ion-skeleton-text animated style="height: 70px"></ion-skeleton-text>
      </tidy-row>

      <tidy-row>
        <ion-skeleton-text animated style="width: 140px; margin: auto"></ion-skeleton-text>
      </tidy-row>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
