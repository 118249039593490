import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[tidyMultiClick]'
})
export class TidyMultiClickDirective {

  @Input('tidyMultiClick') threshold = 1;
  @Input('delay') delay = 1000;

  @Output('onMultiClick') onMultiClick: EventEmitter<any> = new EventEmitter();

  clicked = 0;
  clearTimeout: any = null;

  @HostListener('click')
  onClick(): void {
    this.clicked++;

    if (this.clicked >= this.threshold) {
      this.onMultiClick.emit();
      this.reset();
    }

    if (this.clearTimeout == null) {
      this.clearTimeout = setTimeout( () => this.reset(), this.delay);
    }
  }

  reset(): void {
    clearTimeout(this.clearTimeout);
    this.clicked = 0;
    this.clearTimeout = null;
  }

}
