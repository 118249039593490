<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="false"
    [title]="loaded ? state === 'inactive' ? 'Request Certification' : 'Unpause Account' : ''">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper  *ngIf="loaded">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container>

      <tidy-row [align]="'center'" *ngIf="state === 'homekeeper_paused'">
        <tidy-text
          [body]="'Your account is paused. To unpause your account, confirm your address:'">
        </tidy-text>
      </tidy-row>

      <tidy-row [align]="'center'" *ngIf="state === 'inactive'">
        <tidy-text
          [body]="'Your certification is no longer valid. To re-request certification, confirm your address:'">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <form [formGroup]="hkAddressForm" novalidate action="#">
          <tidy-row class="field-top-padding field-bottom-padding">
            <tidy-select
              [icon]="'assets/svg/home-outline.svg'"
              [label]="'Country'"
              [items]="countryList"
              [form]="hkAddressForm.get('countryCode')"
              formControlName="countryCode"
              (optionChange)="changeCountrySelected($event)"
            >
            </tidy-select>
          </tidy-row>

          <tidy-row class="field-bottom-padding">
            <tidy-auto-complete-address
              [submitted]="submitted"
              [addressFormControl]="hkAddressForm.controls.address"
              [countryCode]="hkAddressForm.controls.countryCode.value"
              (onAddressChosen)="choseItem($event)"
              (onError)="errorMessage = $event"
              (noAddressPickedUpdate)="noAddressPickedTemplate = $event"
              (invalidAddressError)="invalidAddressError = $event"
            ></tidy-auto-complete-address>
          </tidy-row>

          <tidy-row class="field-bottom-padding">
            <tidy-input
              formControlName="address2"
              [form]="hkAddressForm.controls.address2"
              label="Unit / Apartment"
              [errorMessage]="'Please enter a valid address'"
              [submitted]="submitted"
              [icon]="'assets/svg/home-outline.svg'"
              [activeIcon]="'assets/svg/home-outline-tidy-green.svg'">
            </tidy-input>
          </tidy-row>

          <tidy-row class="extra-bottom-padding">
            <tidy-input
              formControlName="zipcode"
              [customMask]="zipCodeMask"
              [form]="hkAddressForm.controls.zipcode"
              [errorMessage]="'Please enter a valid zipcode'"
              [submitted]="submitted"
              [inputMode]="'numeric'"
              label="Zip Code"
              [icon]="'assets/svg/home-outline.svg'"
              [activeIcon]="'assets/svg/home-outline-tidy-green.svg'">
            </tidy-input>
          </tidy-row>
        </form>

        <form [formGroup]="travelTimeForm" novalidate action="#">
          <tidy-row>
            <tidy-text
              [body]="'How do you plan to travel?'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-radio-button
              [items]="typeItems"
              (optionChange)="chooseType($event)">
            </tidy-radio-button>
          </tidy-row>
          <ng-container *ngIf="chosenType == 'car'" class="extra-top-padding">
            <tidy-row class="extra-top-padding">
              <tidy-text
                [body]="'What is your max drive time?'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [helper]="'(45 minutes is what most people pick)'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="extra-top-padding">
              <tidy-select
                [form]="travelTimeForm.controls.travelTime"
                [errorMessage]="'Please choose a valid Value'"
                formControlName="travelTime"
                [submitted]="submitted"
                [items]="travelTimesOpts">
              </tidy-select>
            </tidy-row>
          </ng-container>
          <ng-container *ngIf="chosenType == 'walk'">
            <tidy-row class="extra-top-padding">
              <tidy-text
                [body]="'How far from this address can you walk?'">
              </tidy-text>
            </tidy-row>
            <tidy-radio-button
              [items]="howFarItems"
              formControlName="travelTime"
              [submitted]="submitted"
              [form]="travelTimeForm.controls.travelTime"
              [errorMessage]="'Please choose an option.'">
            </tidy-radio-button>
            <tidy-row class="extra-top-padding">
              <tidy-text
                [helper]="'Please ensure the address you enterred is at the center of the area you serve. To remove a specific area, block a zipcode in the \'Locations I Serve\' page.'">
              </tidy-text>
            </tidy-row>
          </ng-container>
        </form>
      </tidy-card>

      <ng-container *ngTemplateOutlet="noAddressPickedTemplate">
      </ng-container>

      <tidy-error-message
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-button
        text="Next"
        class="primary"
        [action]="checkChange.bind(this)">
      </tidy-button>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Note: Your work area is based off this address, so updating your address will change your work area.  Do not use a PO Box.  Your physical location is used to pair you with clients, and changing to a PO Box will cause you to lose 100% of clients and be un-bookable.'">
        </tidy-text>
      </tidy-row>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
