<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="isUpdatingMaxDriveTime ? 'Change Max Drive' : 'Set Max Drive'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Time between jobs is typically 15 minutes.  The further you can travel, the more clients you can get. '">
        </tidy-text>
        <tidy-text
          (action)="learnMore()"
          [body]="'Learn More'"
          class="link">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <form [formGroup]="form" novalidate action="#">
          <tidy-row>
            <tidy-text
              [body]="'How do you plan to travel?'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-radio-button
              [items]="typeItems"
              (optionChange)="chooseType($event)">
            </tidy-radio-button>
          </tidy-row>
          <ng-container *ngIf="chosenType == 'car'" class="extra-top-padding">
            <tidy-row class="extra-top-padding">
              <tidy-divider></tidy-divider>
            </tidy-row>
            <tidy-row class="extra-top-padding">
              <tidy-text
                [body]="'What is your new max drive time?'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [helper]="'(45 minutes is what most people pick)'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-select
                [form]="form.controls.time"
                [errorMessage]="'Please choose a valid value'"
                formControlName="time"
                [submitted]="submitted"
                [items]="travelTimesOpts">
              </tidy-select>
            </tidy-row>
          </ng-container>
          <ng-container *ngIf="chosenType == 'walk'">
            <tidy-row class="extra-top-padding">
              <tidy-divider></tidy-divider>
            </tidy-row>
            <tidy-row class="field-bottom-padding">
              <tidy-text
                [body]="'Pick an address at the center of the area you serve. Then, pick how far you can walk from this address. To remove a specific area, block a zipcode in the next page.'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="field-bottom-padding">
              <tidy-select
                [icon]="'home-outline'"
                [label]="'Country'"
                [items]="countryList"
                [form]="form.get('countryCode')"
                [icon]="'assets/svg/home-outline.svg'"
                formControlName="countryCode"
                (optionChange)="changeCountrySelected($event)">
              </tidy-select>
            </tidy-row>
            <tidy-row class="field-bottom-padding">
              <tidy-auto-complete-address
                [submitted]="submitted"
                [addressFormControl]="form.controls.address"
                [countryCode]="form.controls.countryCode.value"
                (onAddressChosen)="choseItem($event)"
                (onError)="errorMessage = $event"
                (noAddressPickedUpdate)="noAddressPickedTemplate = $event"
                (invalidAddressError)="invalidAddressError = $event">
              </tidy-auto-complete-address>
            </tidy-row>
            <tidy-row class="extra-bottom-padding">
              <tidy-input
                [customMask]="zipCodeMask"
                label="Zip Code"
                [inputMode]="'numeric'"
                [form]="form.controls.zip"
                [submitted]="submitted"
                formControlName="zip"
                [icon]="'assets/svg/home-outline.svg'"
                [activeIcon]="'assets/svg/home-outline-tidy-green.svg'"
                [errorMessage]="'Please enter a valid zipcode'">
              </tidy-input>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'How far from this address can you walk?'">
              </tidy-text>
            </tidy-row>
            <tidy-radio-button
              [items]="howFarItems"
              formControlName="time"
              [submitted]="submitted"
              [form]="form.controls.time"
              [errorMessage]="'Please choose an option.'">
            </tidy-radio-button>
          </ng-container>
        </form>
      </tidy-card>

      <ng-container *ngTemplateOutlet="noAddressPickedTemplate">
      </ng-container>

      <tidy-error-message
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-button
        [text]="isUpdatingMaxDriveTime ? 'Confirm Change' : 'Save'"
        class="secondary"
        [action]="onSubmit.bind(this)">
      </tidy-button>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
