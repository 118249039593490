import { Injectable } from '@angular/core';
import { HttpClient } from 'src/shared/providers/http/http-client';

const REVIEWS_LIMIT = 50;

@Injectable()
export class Reviews {

  constructor(
    private httpClient: HttpClient
  ) {
  }

  getReviews(teamId: number): Promise<Array<ReviewData>> {
    const url = `api/v1/homekeeper/teams/${teamId}/reviews?limit=${REVIEWS_LIMIT}`;
    return this.httpClient.get(url);
  }

  changeReviewVisibility(teamId: number, reviewId: string, visible: boolean) {
    const url = `api/v1/homekeeper/teams/${teamId}/reviews/${reviewId}/change-visibility`;
    const data = { visible_on_web: visible };
    return this.httpClient.put(url, data);
  }
}

export interface ReviewData {
  id: string;
  client_name: string;
  client_initials: string;
  review_content: string;
  review_date: string;
  review_stars: number;
  visible_on_web: boolean;
}
