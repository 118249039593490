<ng-container *ngFor="let subItem of formattedCollection;">
  <tidy-row class="vertical-align-center">
    <tidy-image
      class="title-size"
      src="assets/img/rooms/{{subItem.category}}.png">
    </tidy-image>

    <tidy-text
      [title]="subItem.title">
    </tidy-text>
  </tidy-row>

  <ng-container *ngFor="let formGroupItem of subItem.tasks;">
    <ng-container [formGroup]="formGroupItem">
      <tidy-card>
        <tidy-text
          [body]="formGroupItem.get('title').value"
        >
        </tidy-text>
      </tidy-card>

      <!-- Waiting for BE to send that via todo's endpoint -->
      <!-- <tidy-card>
        <tidy-radio-button
          formControlName="performed"
          [form]="formGroupItem.get('performed')"
          class="horizontal-align"
          [label]="formGroupItem.get('title').value"
          [items]="[{ viewValue: 'Done', value: true },{ viewValue: 'Not Done', value: false }]"
        >
        </tidy-radio-button>
      </tidy-card> -->
    </ng-container>
  </ng-container>
</ng-container>
