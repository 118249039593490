import { Component } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Homekeepers } from 'src/providers/homekeepers/homekeepers';

import { formatHourToIso8601, removeHourAndTimezoneFromDate } from 'src/shared/utils/date-utils';
import { removeCurrencyMask } from 'src/shared/utils/currency-utils';
import { PrivateClient } from 'src/providers/private-client/private-client';

@Component({
  selector: 'add-private-job-confirmation',
  templateUrl: './add-private-job-confirmation.html',
})
export class AddPrivateJobConfirmationPage {

  addressName: string;
  addressId: string;
  billingType: string;
  clientName: string;
  cliendId: string;
  price: string;
  date: string;
  errorMessage: string;
  frequency: string;
  finishCreationJobRoute: string;
  length: string;
  startTime: string;
  startTimeLabel: string;
  service: string;
  serviceName: string;
  description: string;

  constructor(
    private customNavCtrl: CustomNavController,
    private privateClient: PrivateClient,
    private homekeepers: Homekeepers,
  ) {
  }

  ionViewDidEnter() {
    const params = this.customNavCtrl.getParams();

    this.addressId = params.addressId;
    this.addressName = params.addressName;
    this.billingType = params.billingType;
    this.clientName = params.clientName;
    this.cliendId = params.clientId;
    this.date = params.date;
    this.startTime = params.startTime;
    this.length = params.length;
    this.frequency = params.frequency;
    this.finishCreationJobRoute = params.finishCreationJobRoute;
    this.price = params.price;
    this.startTimeLabel = params.startTimeLabel;
    this.service = params.service;
    this.serviceName = params.serviceName;
    this.description = params.description;
  }

  goBack() {
    this.customNavCtrl.back();
  }

  async saveJob() {
    const formattedDate = removeHourAndTimezoneFromDate(this.date)
    const formattedTime = formatHourToIso8601(this.startTime);
    const formattedCurrency = removeCurrencyMask(this.price);

    try {
      const address = await this.privateClient.getPrivateAddressInfo(this.addressId);

      const paramsToPlan = {
        plan: {
          frequency: this.frequency,
        },
        jobs: [{
          date: formattedDate,
          start_time: formattedTime,
          amount: formattedCurrency,
          service_type_key: this.service,
        }],
        address_id: this.addressId,
      }

      const paramsToJob = {
        job: {
          amount: formattedCurrency,
          date: formattedDate,
          start_time: formattedTime,
          service_type_key: this.service,
        },
        proposal: {
          description: this.description,
          price: this.price
        },
        plan_id: address.active_plans.length ? address.active_plans[0].id : null
      };

      if (address.active_plans.length) {
        await this.homekeepers.addJobToExistentPlan(paramsToJob);
      } else {
        await this.homekeepers.createANewPlan(paramsToPlan);
      }

      this.customNavCtrl.navigateRoot(this.finishCreationJobRoute || '/add');
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }
}
