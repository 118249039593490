import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { MWStore } from "src/main-workflow/mw.store";
import { MWService } from "src/main-workflow/mw.service";
import { mwMoments } from "src/main-workflow/mw.moments";

@Injectable({
  providedIn: 'root'
})

export class MWCanEnterGuard implements CanActivate {

  constructor(
    private mwService: MWService,
    private mwStore: MWStore,
    private router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const storedRoute = await this.mwStore.getRoute();
    const storedJobId = await this.mwStore.getJobId();
    const storedJob = await this.mwStore.getJob();

    if (await this.mwService.isTooLateForJob(storedJob.endTime)) {
      return this.mwService.showTooLateAlert();
    }

    if (route.routeConfig.path === 'ready-to-leave') {
      const storedJobIsInProgress = storedJobId == storedJob.id;
      if (storedJobIsInProgress) {
        return this.router.parseUrl(`/${storedRoute}`);
      } else {
        return true;
      }
    }

    if (storedRoute !== route.routeConfig.path) {
      return this.router.parseUrl(`/${storedRoute}`);

    } else {
      return true;
    }
  }
}
