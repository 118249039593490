<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'My Website'"
    [showLinkIcon]="true"
    [linkIcon]="'share'"
    (linkAction)="shareWebsite()"
    [customBack]="'more'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper *ngIf="loaded">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container>
      <tidy-card>
        <tidy-row>
          <tidy-text
            [header]="'Clients Use Your Website to:'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'<li>Learn about your business</li>'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'<li>Book & manage jobs with you</li>'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'<li>Add to dos & notes for you</li>'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'<li>Pay you for a job</li>'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'<li>Leave reviews for your business</li>'">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Edit your website:'">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/globe.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'URL'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'www.tidy.com/' + website?.url_suffix + ' '"
            [translate]="false">
          </tidy-text>
          <tidy-text
            [body]="'Copy URL'"
            (action)="copyWebsite()"
            class="link">
          </tidy-text>
          <tidy-text
            *ngIf="copied"
            [helper]="' Copied'">
          </tidy-text>
        </tidy-row>
        <tidy-card-button (action)="viewWebsite()">
          <tidy-row>
            <tidy-text
              [body]="'View Website'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="editWebsite()">
          <tidy-row>
            <tidy-text
              [body]="'Edit URL'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-card-button (action)="goTo('reviews')" class="arrow-to-top">
          <tidy-row class="vertical-align-center">
           <tidy-image
              [src]="'assets/svg/chatbubbles.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Reviews'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-card-button (action)="goToBusinessDetail()" class="arrow-to-top">
          <tidy-row class="vertical-align-center extra-bottom-padding">
           <tidy-image
              [src]="'assets/svg/briefcase.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Business Detail'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="website?.name"
              [translate]="false">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="website?.description">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-card-button (action)="goTo('profile/location')" class="arrow-to-top">
          <tidy-row class="vertical-align-center extra-bottom-padding">
           <tidy-image
              [src]="'assets/svg/location.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Locations I Serve'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="profileData?.address">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="profileData?.driveTime">
            <tidy-text
              [body]="profileData?.driveTime">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-card-button (action)="goTo('profile/contact')" class="arrow-to-top">
          <tidy-row class="vertical-align-center extra-bottom-padding">
           <tidy-image
              [src]="'assets/svg/chatbox-ellipses.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Contact Info'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="profileData?.name">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="profileData?.phone">
            <tidy-text
              [body]="profileData?.phone">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="profileData?.email">
            <tidy-text
              [body]="profileData?.email">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-card-button (action)="goTo('services')" class="arrow-to-top">
          <tidy-row class="vertical-align-center extra-bottom-padding">
           <tidy-image
              [src]="'assets/svg/pricetags.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Services'">
            </tidy-text>
          </tidy-row>
          <ng-container *ngFor="let type of teamServiceTypes">
            <tidy-row *ngFor="let service of type.team_services">
              <tidy-text
                [body]="'<li>' + service?.name + '</li>'">
              </tidy-text>
            </tidy-row>
          </ng-container>
        </tidy-card-button>
      </tidy-card>


      <tidy-card>
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            [src]="'assets/svg/newspaper.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Certifications'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'<li>Background Check</li>'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'<li>Cleaning Equipment</li>'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'<li>English Speaking</li>'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'<li>Recently Active</li>'">
          </tidy-text>
        </tidy-row>
      </tidy-card>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
