import { Component } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Component({
  templateUrl: 'reschedule-cancel.html'
})

export class RescheduleCancelPage {

  constructor(
    private navCtrl: CustomNavController
  ) {}

  reschedule() {
    const params = this.navCtrl.getParam('rescheduleJobParams');
    this.navCtrl.navigateForward('private-reschedule-ask-subject', params);
  }

  cancel() {
    const params = this.navCtrl.getParam('cancelJobParams');
    this.navCtrl.navigateForward(`private-cancellation/job/${params.jobId}`, params);
  }

}
