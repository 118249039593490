<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Past Weekly Job History'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [hasBottomNav]="true">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

        <tidy-row>
          <form [formGroup]="selectForm" novalidate action="#">
            <tidy-filter
              [initValue]="weeksItems[0].value"
              [items]="weeksItems"
              [fitContent]="true"
              [translate]="true"
              (optionChange)="changeWeekFilter($event)">
            </tidy-filter>
          </form>
        </tidy-row>
        <tidy-card>
          <tidy-row>
            <tidy-text
              [header]="'Payment Summary'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-grid>
              <ion-row>
                <ion-col size="9">
                  <tidy-text
                    [body]="'Accelerated Amount'">
                  </tidy-text>
                </ion-col>
                <ion-col>
                  <tidy-text
                    [body]="totals?.confirmed | tcurrency">
                  </tidy-text>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="9">
                  <tidy-text
                    [body]="'Normal Amount'">
                  </tidy-text>
                </ion-col>
                <ion-col>
                  <tidy-text
                    [body]="totals?.normalAmount | tcurrency">
                  </tidy-text>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="9">
                  <tidy-text
                    [body]="'Total Earned'">
                  </tidy-text>
                </ion-col>
                <ion-col>
                  <tidy-text
                    [body]="totals?.unconfirmed | tcurrency">
                  </tidy-text>
                </ion-col>
              </ion-row>
            </tidy-grid>
          </tidy-row>
        </tidy-card>
    </ng-container>

    <ng-container *ngIf="!loaded">

      <tidy-row class="extra-bottom-padding">
        <div style="
        background: white;
        border-radius: 25px;
        padding: 7px;
        display: block;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3), 0px 0px 3px rgba(0, 0, 0, 0.24);
        font-size: 16px;
        width: fit-content;
        margin: auto;
        max-width: 80%">
          <ion-skeleton-text animated="true" style="width: 95px; margin: 10px"></ion-skeleton-text>
        </div>
      </tidy-row>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <tidy-card-button [hideArrow]="true">
            <tidy-grid>
              <ion-row>
                <ion-col size="7">
                  <ion-skeleton-text animated style="width: 75%"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 45%"></ion-skeleton-text>
                </ion-col>
                <ion-col size="1">
                </ion-col>
                <ion-col size="4">
                  <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
                </ion-col>
              </ion-row>
            </tidy-grid>
          </tidy-card-button>
        </tidy-row>
      </tidy-card>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

<tidy-bottom-nav activeRoute="/jobs">
</tidy-bottom-nav>
