import { Injectable } from '@angular/core';

import { HttpClient } from 'src/shared/providers/http/http-client';
import { RequestCache } from 'src/shared/providers/http/request-cache';


import { CertificationResponseModel, CertificationsModel, ReapplyModel } from 'src/shared/models/certification.model';

@Injectable()
export class Certification {

  constructor(
    private cache: RequestCache,
    private httpClient: HttpClient
  ) {}

  async getCertificationsNoCache(): Promise<CertificationsModel> {
    const rawData = await this.getCertificationsResponse('getNoCache');
    return this.buildCertificationData(rawData);
  }

  async getCertifications(): Promise<CertificationsModel> {
    let rawData = await this.cache.retrieve('api/v1/homekeeper/certifications');
    if (!rawData) {
      rawData = await this.getCertificationsResponse('get');
    }
    return this.buildCertificationData(rawData);
  }

  async getCertificationsResponse(method): Promise<CertificationResponseModel[]> {
    const url = 'api/v1/homekeeper/certifications';
    return await this.httpClient[method](url);
  }

  async getPreCertificationCoops(goLiveCertification = null) {
    try {
      if (goLiveCertification !== 'complete') {
        const url = 'api/v1/homekeeper/emergency-cleanings/sample';
        const certificationCoops = await this.httpClient.get(url);
        return certificationCoops;
      }
      return [];
    } catch (err) {
      return [];
    }
  }

  resendVerificationEmail() {
    const url = 'api/v1/homekeeper/profile/email/resend-verification';
    return this.httpClient.post(url, {})
  }

  resendBackgroundCheckEmail() {
    const url = 'api/v1/homekeeper/certifications/background-check/resend-consent-form';
    return this.httpClient.get(url)
  }

  buildCertificationData(rawData): CertificationsModel {
    let certifications = {
      steps: {
        email_validation: '',
        background_check: '',
        phone_verification: '',
        walkthrough: '',
        equipment_verification: '',
        profile: '',
        active_in_community: '',
        max_drive_time: '',
        review_terms: '',
        bookable_times: '',
        go_live: '',
      },
      existing_clients: false,
      all_steps_except_go_live: false
    };
    rawData.forEach((step) => {
      const slug = this.getCertificationSlug(step.homekeeper_certification_type_id);
      certifications.steps[slug] = step.state;
    });
    certifications.existing_clients =
      (certifications.steps.profile === 'complete' &&
      certifications.steps.email_validation === 'complete' &&
      certifications.steps.phone_verification === 'complete' &&
      certifications.steps.max_drive_time === 'complete');
    certifications.all_steps_except_go_live =
      (certifications.existing_clients &&
      certifications.steps.background_check === 'complete' &&
      certifications.steps.walkthrough === 'complete' &&
      certifications.steps.equipment_verification === 'complete' &&
      certifications.steps.review_terms === 'complete' &&
      certifications.steps.bookable_times === 'complete' &&
      certifications.steps.go_live !== 'complete');
    return certifications;
  }

  getCertificationSlug(id): string {
    const slugs = {
      1: 'email_validation',
      2: 'background_check',
      3: 'phone_verification',
      4: 'walkthrough',
      5: 'equipment_verification',
      6: 'profile',
      7: 'active_in_community',
      8: 'max_drive_time',
      9: 'bookable_times',
      10: 'review_terms',
      11: 'go_live',
    };
    return slugs[id];
  }

  finishStep(step, data): Promise<CertificationResponseModel> {
    const params = {
      type: step,
      data: data
    };
    const url = 'api/v1/homekeeper/certifications';
    return this.httpClient.post(url, params);
  }

  checkReferralCode(code): Promise<any> {
    const url = `api/v1/homekeeper/referral-code/check`;
    const data = {
      referral_code: code
    };
    return this.httpClient.post(url, data);
  }

  recordReferralCode(code): Promise<any> {
    const url = `api/v1/homekeeper/referral-code/apply`;
    const data = {
      referral_code: code
    };
    return this.httpClient.post(url, data);
  }

  reapply(): Promise<ReapplyModel> {
    const data = {
      terms: {
        allQuestionsAnswered: true
      }
    }
    const url = `api/v1/homekeeper/certifications/reapply`;
    return this.httpClient.post(url, data);
  }
}
