import { Component, OnInit, Input, ChangeDetectionStrategy, Output, OnChanges } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { EventEmitter } from '@angular/core';

interface TodoTasks {
  important: boolean;
  job_task_id: number;
  note: string;
  origin: object;
  performance?: string;
  task_end_time?: string;
  title: string;
  photo_notes: any
}

interface TodoRoom {
  category: string;
  complete?: boolean;
  doneTodos?: number;
  id: number;
  notes: any[],
  photo_notes: any;
  room?: string;
  name?: string;
  todos?: TodoTasks[]
  tasks?: TodoTasks[]
}

@Component({
  selector: 'todo-collapse',
  templateUrl: './todo-collapse.component.html',
  styleUrls: ['./todo-collapse.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('0.3s ease-out',
                    style({ opacity: 1 }))
          ]
        )
      ]
    )
  ]
})
export class TodoCollapseComponent implements OnInit, OnChanges {
  @Input() currentRoomId: number;
  @Input() doneTodos = 0;
  @Output() expandRoom: EventEmitter<TodoRoom> = new EventEmitter<TodoRoom>();
  @Input() room: TodoRoom;
  @Input() showCollapse: boolean;
  @Input() disableAnimations = false;
  isAnimationsEnabled = true;
  isToShowCollapse = false;

  constructor() {}

  ngOnInit() {
    this.isToShowCollapse = this.room.complete;
  }

  onExpandRoom() {
    this.expandRoom.emit(this.room);
    this.isToShowCollapse = false;
  }

  ngOnChanges() {
    if(this.room.id === this.currentRoomId) {
      this.isToShowCollapse = this.showCollapse;
    }
  }
}
