<ng-container *ngIf="type.includes('video')">
  <tidy-card>
    <tidy-card-button [hideArrow]="true" style="margin-top: -0.5rem">
      <tidy-row>
        <tidy-text
          [title]="
            ((beforeAfterType | titlecase) | translate) + ' ' + ((mediasType | titlecase) | translate)
          ">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="
            ('Your client requested videos ' | translate) +
            ((beforeAfterType | titlecase) | translate) +
            (' your job. We generally recommend 1 or more videos of every room to capture its current state.' | translate)
          ">
        </tidy-text>
      </tidy-row>
    </tidy-card-button>
    <tidy-button
      *ngIf="deviceInfo.isNativeMobile()"
      [text]="'Take Video'"
      [action]="takeVideo.bind(this)"
      [class]="'secondary'">
    </tidy-button>
    <tidy-file-upload
      [accept]="'video/*'"
      [label]="deviceInfo.isNativeMobile() ? 'Upload Video' : 'Add Video'"
      [images]="beforeAfterPhotos"
      [showDeleteButton]="showDeleteButton"
      [showPreview]="jobIsCompleted"
      (addedImagesChange)="uploadVideo($event)"
      (removedImageChange)="removeMedia($event)"
      (onError)="filePickerError($event)">
    </tidy-file-upload>
    <ng-container *ngIf="!jobIsCompleted">
      <div style="margin-top: 1rem;">
        <ng-container *ngFor="let video of beforeAfterPhotos; let i = index">
          <tidy-row style="margin-bottom: 0.5rem;">
            <div style="display: flex; justify-content: space-between">
              <div>
                <tidy-text
                  [body]="
                    ('Video ' | translate) + (i + 1) + (' of ' | translate) + beforeAfterPhotos.length
                  "></tidy-text>
              </div>
              <div>
                <tidy-text
                  [body]="'Delete'"
                  class="link"
                  (action)="removeMedia(video)"></tidy-text>
              </div>
            </div>
          </tidy-row>
        </ng-container>
      </div>
    </ng-container>
  </tidy-card>
</ng-container>

<ng-container *ngIf="type.includes('photo')">
  <tidy-card>
    <tidy-card-button [hideArrow]="true" style="margin-top: -0.5rem">
      <tidy-row>
        <tidy-text
          [title]="
            (beforeAfterType | titlecase) + ' ' + (mediasType | titlecase)
          ">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="
            ('Your client requested photos ' | translate) +
            (beforeAfterType | titlecase) +
            (' your job. We generally recommend 1 or more photos of every room to capture its current state.' | translate)
          ">
        </tidy-text>
      </tidy-row>
    </tidy-card-button>
    <tidy-button
      *ngIf="deviceInfo.isNativeMobile()"
      [text]="'Take Photo'"
      [action]="takePhoto.bind(this)"
      [class]="'secondary'">
    </tidy-button>
    <tidy-file-upload
      [accept]="'image/*'"
      [label]="deviceInfo.isNativeMobile() ? 'Upload Photo' : 'Add Photo'"
      [images]="beforeAfterPhotos"
      [showDeleteButton]="showDeleteButton"
      (addedImagesChange)="uploadPhoto($event)"
      (removedImageChange)="removeMedia($event)"
      (onError)="filePickerError($event)">
    </tidy-file-upload>
  </tidy-card>
</ng-container>

<tidy-error-message *ngIf="errorMessage" [text]="errorMessage">
</tidy-error-message>

<div style="padding-bottom: 1rem"></div>
