import { Injectable } from '@angular/core';
import { HttpClient } from 'src/shared/providers/http/http-client';
import { Aws } from 'src/shared/providers/aws';
import { FilePicker } from 'src/shared/providers/file-picker/file-picker';
import { generateUuid } from 'src/providers/tidy-session/session-uuid';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Injectable()
export class Communication {

  constructor(
    private aws: Aws,
    private httpClient: HttpClient,
    private filePicker: FilePicker
  ) {}

  async getLatestMessages() {
    const url = 'api/v1/homekeeper/shared-inbox/latest-messages';
    const response = await this.httpClient.get(url);
    return response.reverse();
  }

  async getMessages(homekeeperJobId) {
    const url = `api/v1/homekeeper/communication/${homekeeperJobId}/messages`;
    const response = await this.httpClient.get(url);
    return response.reverse();
  }

  sendMessage(homekeeperJobId, payload) {
    const url = `api/v1/homekeeper/communication/${homekeeperJobId}/send-message`;
    return this.httpClient.post(url, payload);
  }

  sendSharedInboxMessage(payload) {
    const url = 'api/v1/homekeeper/shared-inbox/send-message';
    return this.httpClient.post(url, payload);
  }

  async getSharedInbox(clientId) {
    const url = `api/v1/homekeeper/shared-inbox/messages?filters[customer_account_id]=${clientId}&includes=metadata,chat_room_key`;
    const response = await this.httpClient.get(url);
    return response.reverse();
  }

  async getSharedInboxForHomekeeperJob(homekeeperJobId) {
    const url = `api/v1/homekeeper/shared-inbox/messages?filters[homekeeper_job_ids]=${homekeeperJobId}&includes=metadata,chat_room_key`;
    const response = await this.httpClient.get(url);
    return response.reverse();
  }

  async addAttachment() {
    const uuid = generateUuid();
    const file = await this.filePicker.pickFile();
    const fileKey = `message/${uuid}/${file.filename}`;
    const imagesAllowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (imagesAllowedTypes.includes(file.format)) {
      const response = await this.aws.uploadFileToS3(file.dataUrl, fileKey, file.format);
      return response.Location;
    } else {
      return '';
    }
  }

  getCallMinutes(duration) {
    const length = Math.round(duration / 60);
    let minutes = length == 1 ? 'minute' : 'minutes';
    minutes = new TranslationPipe().transform(minutes);
    return ' (' + length + minutes + ')'
  }

  markMessagesAsRead(messageKeys) {
    const payload = {
      chat_room_keys: messageKeys
    }
    const url = 'api/v1/homekeeper/shared-inbox/mark-chat-as-read';
    return this.httpClient.post(url, payload);
  }

}
