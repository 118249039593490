<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Teams'"
    [customBack]="'more'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'" *ngIf="!isLimitedAccount">
        <tidy-text
          [body]="'Send jobs to your team members or join other teams to get jobs from them! '">
        </tidy-text>
        <tidy-text
          [body]="'Learn More'"
          (action)="learnMore()"
          class="link">
        </tidy-text>
      </tidy-row>

      <ng-container *ngIf="!isLimitedAccount">
        <tidy-alert *ngFor="let invite of invitationsReceived">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/people.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Team Invite Pending'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [body]="invite?.team?.owner?.name"
              [translate]="false">
            </tidy-text>
            <tidy-text
              [body]="' invited you to their team. By joining, you agree that you are a subcontractor or employee of '">
            </tidy-text>
            <tidy-text
              [body]="invite?.team?.owner?.name">
            </tidy-text>
            <tidy-text
              [body]="'\'s business, and they are responsible for safety, legal, insurance, and employment obligations as well as the '">
            </tidy-text>
            <tidy-text
              [body]="'Service Provider Customer Agreement'"
              (action)="goToServiceProviderAgreement()"
              class="link">
            </tidy-text>
            <tidy-text
              [body]="'.'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-button
              [action]="acceptTeamInvite.bind(this, invite)"
              [text]="'Accept Invite'"
              class="primary">
            </tidy-button>
          </tidy-row>
          <tidy-row [align]="'center'">
            <tidy-text
              (action)="rejectTeamInvite(invite)"
              [body]="'Reject Invite'"
              class="link">
            </tidy-text>
          </tidy-row>
        </tidy-alert>
      </ng-container>

      <tidy-card *ngIf="!isLimitedAccount">
        <tidy-row>
          <tidy-text
            [header]="'My Team'">
          </tidy-text>
        </tidy-row>
        <tidy-row [ngClass]="myTeam?.description === '' ? 'extra-bottom-padding' : ''">
          <tidy-text
            [body]="myTeam?.name + ' '">
          </tidy-text>
          <tidy-text
            [body]="'Edit'"
            (action)="editBusinessDetail()"
            class="link">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="myTeam?.description !== ''" class="extra-bottom-padding">
          <tidy-text
            [body]="myTeam?.description">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [header]="'Members'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let member of myTeamMembers">
          <tidy-card-button (action)="goToTeamMemberPage(member)">
            <tidy-row>
              <tidy-text
                [body]="member?.homekeeper?.name"
                [translate]="false">
              </tidy-text><br>
              <tidy-text
                [body]="member?.homekeeper?.email"
                [translate]="false">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </ng-container>
        <ng-container *ngFor="let invite of invitesPending">
          <tidy-card-button (action)="goToTeamMemberInvitedPage(invite)">
            <tidy-row>
              <tidy-text
                [body]="invite?.email"
                [translate]="false">
              </tidy-text>
              <tidy-text
                [helper]="' (pending)'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </ng-container>
        <tidy-row *ngIf="invitesPending?.length === 0 && myTeamMembers.length === 0">
          <tidy-text
            [helper]="'None'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-top-padding">
        <tidy-button
          [action]="invite.bind(this)"
          [text]="'Invite Team Member'"
          class="primary">
        </tidy-button>
      </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [header]="'Teams I Am On'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let team of teamsIAmOn">
          <tidy-card-button (action)="goToTeamIAmOnPage(team)">
            <tidy-row>
              <tidy-text
                [body]="team?.name">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </ng-container>
        <tidy-row *ngIf="teamsIAmOn?.length === 0">
          <tidy-text
            [helper]="'None'">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-button
        *ngIf="!isLimitedAccount"
        [action]="goToUpcomingFeaturePage.bind(this, 'team_payments')"
        [text]="'Set Up Team Payments'"
        class="primary">
      </tidy-button>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
