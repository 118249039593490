// Angular
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';

// Ionic
import { IonicModule } from '@ionic/angular';

// Third party
import { TextMaskModule } from 'angular2-text-mask';
import { TidyUiComponentsModule } from '@tidyapp/tidy-ui-components';

// custom
import { pipes } from './aggregators/pipes';
import { components } from './aggregators/components';
import { providers } from './aggregators/providers';
import { directives } from './aggregators/directives';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TextMaskModule,
    TidyUiComponentsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatIconModule
  ],
  declarations: [
    ...pipes,
    ...components,
    ...directives,
  ],
  exports: [
    ...pipes,
    ...components,
    ...directives,
    MatProgressBarModule
  ],
  providers: [
    ...providers
  ],
  bootstrap: [
    ...components
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
