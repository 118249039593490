import { Injectable } from '@angular/core';
import { HttpClient } from 'src/shared/providers/http/http-client';

import { AppSectionsModel, SearchableResourcesModel } from 'src/shared/models/search.model';

@Injectable()
export class Search {

  constructor(
    private httpClient: HttpClient
  ) {}

  searchableResources(): Promise<SearchableResourcesModel> {
    const url = 'api/v1/homekeeper/searchable-resources';
    return this.httpClient.get(url);
  }

  appSections(): AppSectionsModel[] {
    return [
        { searchTerm: 'Add', displayTerm: 'Add Job Opportunities', path: 'add' },
        { searchTerm: 'Active in Community', displayTerm: 'Active in Community', path: 'active-in-community'},
        { searchTerm: 'Add Private Client', displayTerm: 'Add Private Client', path: 'add-private-client'},
        { searchTerm: 'Add Private Job', displayTerm: 'Add Private Job', path: 'add-private-job'},
        { searchTerm: 'Booking Restrictions', displayTerm: 'Booking Restrictions', path: 'booking-restrictions'},
        { searchTerm: 'Booking Protection', displayTerm: 'Booking Protection', path: 'clients-from-tidy-booking-protection'},
        { searchTerm: 'Fees', displayTerm: 'Fees', path: 'clients-from-tidy-fees'},
        { searchTerm: 'Services', displayTerm: 'Services', path: 'services'},
        { searchTerm: 'Clients', displayTerm: 'Clients', path: 'clients'},
        { searchTerm: 'Client Payment Settings', displayTerm: 'Client Payment Settings', path: 'client-payment-settings'},
        { searchTerm: 'Clients from TIDY Settings', displayTerm: 'Clients from TIDY Settings', path: 'clients-from-tidy-settings'},
        { searchTerm: 'Contact Support', displayTerm: 'Contact Support', path: 'contact-support'},
        { searchTerm: 'Forgot Password', displayTerm: 'Forgot Password', path: 'forgot-password'},
        { searchTerm: 'Feedback', displayTerm: 'Feedback', path: 'feedback' },
        { searchTerm: 'Jobs', displayTerm: 'Jobs', path: 'jobs'},
        { searchTerm: 'Schedule', displayTerm: 'Schedule', path: 'bookable-hours'},
        { searchTerm: 'More', displayTerm: 'More', path: 'more' },
        { searchTerm: 'Past Payments', displayTerm: 'Past Payments', path: 'past-payments'},
        { searchTerm: 'Pause Schedule', displayTerm: 'Pause Schedule', path: 'pause-schedule'},
        { searchTerm: 'Payment Settings', displayTerm: 'Client Payment Settings ', path: 'client-payment-settings'},
        { searchTerm: 'Payout Settings', displayTerm: 'Payout Settings ', path: 'payout-settings'},
        { searchTerm: 'Pending Payment', displayTerm: 'Pending Payment', path: 'pending-payment'},
        { searchTerm: 'Private Services', displayTerm: 'Private Services', path: 'services'},
        { searchTerm: 'Change Address', displayTerm: 'Change Address', path: 'profile/address' },
        { searchTerm: 'Contact Info', displayTerm: 'Contact Info', path: 'profile/contact' },
        { searchTerm: 'Change Email', displayTerm: 'Change Email', path: 'profile/email' },
        { searchTerm: 'Locations I Serve', displayTerm: 'Locations I Serve', path: 'profile/location' },
        { searchTerm: 'Change Phone Number', displayTerm: 'Change Phone Number', path: 'profile/phone' },
        { searchTerm: 'Change Max Drive Time', displayTerm: 'Change Max Drive Time', path: 'profile/travel-time' },
        { searchTerm: 'Reviews', displayTerm: 'Reviews', path: 'reviews'},
        { searchTerm: 'Private Services', displayTerm: 'Private Services', path: 'services'},
        { searchTerm: 'Teams', displayTerm: 'Teams', path: 'team'},
        { searchTerm: 'Waive Fees', displayTerm: 'Waive Fees', path: 'waive-fee'},
        { searchTerm: 'My Website', displayTerm: 'My Website', path: 'website'},

        //Synonym Search Terms
        { searchTerm: 'Opportunities', displayTerm: 'Add Job Opportunities', path: 'add' },
        { searchTerm: 'Opportunity', displayTerm: 'Add Job Opportunities', path: 'add' },
        { searchTerm: 'Cleanings', displayTerm: 'Add Job Opportunities', path: 'add' },
        { searchTerm: 'Jobs', displayTerm: 'Add Job Opportunities', path: 'add' },

        { searchTerm: 'Calendar', displayTerm: 'Schedule', path: 'bookable-hours'},

        { searchTerm: 'Direct Deposit', displayTerm: 'Payment Settings ', path: 'payout-settings'},
        { searchTerm: 'Bank', displayTerm: 'Payment Settings ', path: 'payout-settings'},
        { searchTerm: 'Routing', displayTerm: 'Payment Settings ', path: 'payout-settings'},

        { searchTerm: 'Drive Time', displayTerm: 'Locations I Serve', path: 'profile/location' },
        { searchTerm: 'Driving', displayTerm: 'Locations I Serve', path: 'profile/location' },
        { searchTerm: 'Distance', displayTerm: 'Locations I Serve', path: 'profile/location' },
        { searchTerm: 'Address', displayTerm: 'Locations I Serve', path: 'profile/location' },

        { searchTerm: 'Profile', displayTerm: 'Contact Info', path: 'profile/contact' },
        { searchTerm: 'Account', displayTerm: 'Contact Info', path: 'profile/contact' },

        { searchTerm: 'Ratings', displayTerm: 'Reviews', path: 'reviews'},
        { searchTerm: 'Stars', displayTerm: 'Reviews', path: 'reviews'},

        { searchTerm: 'Help', displayTerm: 'Contact Support', path: 'contact-support'},
        { searchTerm: 'Email', displayTerm: 'Contact Support', path: 'contact-support'},
        { searchTerm: 'Phone', displayTerm: 'Contact Support', path: 'contact-support'},
        { searchTerm: 'Concierge', displayTerm: 'Contact Support', path: 'contact-support'},
    ];
  }
}
