<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [backButton]="true"
    [title]="'Contact Info'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="loaded">
      <ng-container>
        <tidy-card>
          <tidy-row>
            <tidy-text
              [body]="'Clients and TIDY Concierge will use this info to communicate with you.'">
            </tidy-text>
          </tidy-row>
          <tidy-card-button [hideArrow]="true">
            <tidy-text
              [helper]="'Name'">
            </tidy-text><br>
            <tidy-text
              [body]="profileData?.name"
              [translate]="false">
            </tidy-text>
          </tidy-card-button>
          <tidy-card-button (action)="goToProfileEmail()">
            <tidy-text
              [helper]="'Email'">
            </tidy-text><br>
            <tidy-text
              [body]="profileData?.email"
              [translate]="false">
            </tidy-text>
          </tidy-card-button>
          <tidy-card-button (action)="goToProfileTelephone()">
            <tidy-text
              [helper]="'Phone Number'">
            </tidy-text><br>
            <tidy-text
              [body]="profileData?.phone">
            </tidy-text>
          </tidy-card-button>
        </tidy-card>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!loaded">
      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="default-row-padding">
          <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
        </tidy-row>
        <tidy-card-button [hideArrow]="true">
          <ion-skeleton-text animated style="width: 15%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 25%"></ion-skeleton-text>
        </tidy-card-button>
        <tidy-card-button [hideArrow]="true">
          <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 27%"></ion-skeleton-text>
        </tidy-card-button>
        <tidy-card-button [hideArrow]="true">
          <ion-skeleton-text animated style="width: 15%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
        </tidy-card-button>
        <tidy-card-button [hideArrow]="true">
          <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 15%"></ion-skeleton-text>
        </tidy-card-button>
      </tidy-card>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
