import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Homekeepers } from 'src/providers/homekeepers/homekeepers';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm';
import { SuccessPageParamsModel } from 'src/pages/success/success';

import { Loading } from 'src/shared/components/loading/loading';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { Timeout } from 'src/shared/components/timeout/timeout';

@Component({
  selector: 'client-request-counter',
  templateUrl: './client-request-counter.html'
})
export class ClientRequestCounterPage extends Timeout {

  apiError: string;
  pageError: string;
  request: any;
  times: any;

  constructor(
    private customNavCtrl: CustomNavController,
    private homekeepers: Homekeepers,
    private modalCtrl: ModalController,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
  }

  @Loading()
  async ionViewDidEnter() {
    this.request = await this.customNavCtrl.getParams();
    this.times = await this.getTimes();
    this.loaded = true;
  }

  getTimes = async () => {
    const times = await this.homekeepers
      .getAlternateTimes(this.request.address.id,  this.request.frequency, this.request.service_type_details.key);

    const timesArray = [];
    times.map((day, i) => {
      const formattedDay = {
        date: day.display_date,
        times: []
      };
      day.times.map((time, i) => {
        formattedDay.times.push({
          time,
          date: day.date,
          checked: false
        });
      });
      timesArray.push(formattedDay);
    });
    return timesArray;
  }

  proposeAlternate = async () => {
    const data = await this.prepareData();
    if (data.counter_request.request_times.length === 0) {
      this.pageError = `Please select times to propose to ${this.request.address.customer.first_name}`;
      return;
    }
    const params: ConfirmPageParamsModel = {
      title: 'Propose Times?',
      body: `Notify ${this.request.address.customer.first_name} that you cannot help them at the times they requested but that you can help during the times you selected.`,
      backText: 'Go Back',
      confirmText: 'Propose Times',
      confirmAction: this.confirmProposeAlternate.bind(this),
      confirmActionParams: data,
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });
    confirmationModal.present();
  }

  prepareData = () => {
    const data = {
      counter_request: {
        request_times : []
      }
    };
    this.times.map((day) => {
      day.times.map((time) => {
        if (time.checked) {
          delete time.checked;
          data.counter_request.request_times.push(time);
        }
      });
    });
    return data;
  }

  async confirmProposeAlternate(data) {
    await this.homekeepers.proposeAlternateTimes(this.request.id, data);
    const successParams: SuccessPageParamsModel = {
      header: 'Times Proposed',
      body: 'We have sent a notification to this Client.',
      buttonText: 'Ok',
      buttonRoute: `add`
    };
    this.modalCtrl.dismiss();
    this.customNavCtrl.navigateForward('success', successParams);
  }
}
