import { Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Events } from 'src/providers/events/events';
import { MileageTracking } from 'src/providers/mileage-tracking/mileage-tracking';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

import { MileageTrackingRecordModel } from 'src/shared/models/mileage-tracking.model';
import { SuccessPageParamsModel } from 'src/pages/success/success';
import { Me } from 'src/shared/providers/me';

@Component({
  templateUrl: './job-mileage.html',
  encapsulation: ViewEncapsulation.None
})
export class JobMileagePage {

  backButtonRoute: string;
  errorMessage: string;
  form: UntypedFormGroup;
  jobMileage: MileageTrackingRecordModel;
  loaded = false;
  submitted: boolean;
  distanceFormat: string;

  constructor(
    private customNavCtrl: CustomNavController,
    private events: Events,
    private fb: UntypedFormBuilder,
    private mileageTracking: MileageTracking,
    private storage: TidyStorage,
    private iabUtils: InAppBrowserUtils,
    private me: Me
  ) {
    this.form = this.fb.group({
      start: ['', Validators.required],
      end: ['', Validators.required],
      miles: ['', Validators.required],
    });
  }

  async ionViewDidEnter() {
    this.backButtonRoute = await this.storage.retrieve('jobMileageBackRoute') || 'mileage-tracking';
    this.jobMileage = await this.customNavCtrl.getParams();
    this.distanceFormat = await this.me.getDistanceFormatValue();
    this.form.patchValue({
      start: this.jobMileage.start_location_name,
      end: this.jobMileage.end_location_name,
      miles: this.distanceFormat === 'mi' ? this.jobMileage.estimated_mileage : this.jobMileage.estimated_mileage * 1.60934,
    });
    this.loaded = true;
  }

  viewJob() {
    this.customNavCtrl.navigateForward(`past-job/${this.jobMileage.homekeeper_job.job_id}`);
  }

  async saveChanges() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    try {
      const miles = this.distanceFormat === 'mi' ? this.form.value.miles : this.form.value.miles / 1.60934;
      await this.mileageTracking.updateMileageTracking(this.jobMileage.id, miles, this.form.value.start, this.form.value.end);
      const params = this.mountSuccessPageParams();
      this.customNavCtrl.navigateForward('success', params);
    } catch (err) {
      this.errorMessage = err.message;
    }
  }

  mountSuccessPageParams(): SuccessPageParamsModel {
    return {
      header: 'Mileage Updated',
      body: 'The mileage for this job has been saved.',
      buttonText: 'Ok',
      buttonRoute: this.backButtonRoute
    };
  }
}
