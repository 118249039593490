import { Component, ViewEncapsulation } from '@angular/core';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { MySchedule } from 'src/providers/my-schedule/my-schedule';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

@Component({
  selector: 'page-my-schedule',
  templateUrl: 'my-schedule.html',
  encapsulation: ViewEncapsulation.None
})
export class MySchedulePage extends Timeout {

  calendarLoaded: boolean;
  certifiedForPage: boolean;
  changeHistory: any;
  hkNotAvailableRepeat: boolean;
  timeRangeForms: Array<UntypedFormGroup> = [];

  constructor(
    private customNavCtrl: CustomNavController,
    private iabUtils: InAppBrowserUtils,
    private mySchedule: MySchedule,
    private storage: TidyStorage,
    private fb: UntypedFormBuilder,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
  }

  async ionViewDidEnter() {
    try {
      this.loaded = false;
      this.calendarLoaded = false;
      this.changeHistory = await this.mySchedule.fetchScheduleChangeHistory();
      this.hkNotAvailableRepeat = await this.storage.retrieve('not_available_repeat');
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  pause() {
    this.customNavCtrl.navigateForward(['pause-schedule']);
  }

  addTimeRange() {
    const blocksFormGroup = this.fb.group({
      start_time: ['', Validators.required],
      end_time: ['', Validators.required]
    });
    this.timeRangeForms.push(blocksFormGroup);
  }

  removeTimeRange(index: number) {
    this.timeRangeForms.splice(index, 1);
  }

  goToLearnMoreSchedule() {
    this.iabUtils.openUrl('https://help.tidy.com/pros/set-a-schedule');
  }

}
