import { Component, ViewEncapsulation } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Me } from 'src/shared/providers/me';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

import { ProfileData, parseProfileData } from '../profile-data-parser';

import { Loading } from 'src/shared/components/loading/loading';

import { HomekeeperModel } from 'src/shared/models/homekeeper.model';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

@Component({
  selector: 'contact-info',
  templateUrl: 'contact-info.html',
  encapsulation: ViewEncapsulation.None
})
export class ProfileContactInfoPage extends Timeout {
  homekeeper: HomekeeperModel;
  profileData: ProfileData;

  constructor(
    private customNavCtrl: CustomNavController,
    private iabUtils: InAppBrowserUtils,
    private me: Me,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
  }

  async ionViewDidEnter() {
    try {
      this.loadProfile();
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  goToProfileEmail = () => {
    this.customNavCtrl.navigateForward('profile/email');
  }

  goToProfileTelephone = () => {
    this.customNavCtrl.navigateForward('profile/phone');
  }

  @Loading()
  async loadProfile(): Promise<any> {
    this.homekeeper = await this.me.fetchWithoutCache();
    this.profileData = parseProfileData(this.homekeeper);
    this.loaded = true;
  }

  learnMoreReferral() {
    this.iabUtils.openUrl('https://help.tidy.com/pros/fees-for-clients-from-tidy#taking-clients-private');
  }
}
