import { Component, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PrivateClient } from 'src/providers/private-client/private-client';

import { AddressModel } from 'src/shared/models/address.model';
import { SuccessPageParamsModel } from 'src/pages/success/success';
import { Loading } from 'src/shared/components/loading/loading';

@Component({
  templateUrl: 'edit-access-notes.html'
})

export class EditAccessNotesPage {

  errorMessage: string;
  form: UntypedFormGroup;
  params: {
    address: AddressModel;
    clientId: number;
  };
  submitted: boolean;
  zipCodeMask = {
    mask: '00000'
  }
  parkingTypes: { viewValue: string; value: boolean; }[];
  paidParkingSpots: { viewValue: string; value: string; }[];
  freeParkingSpots: { viewValue: string; value: string; }[];
  payOptions: { viewValue: string; value: string; }[];
  maxCostOptions: any[];
  showAlert: boolean;
  loaded: boolean;

  constructor(
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private privateClient: PrivateClient
  ) {
    this.form = this.fb.group({
      home_access: ['', Validators.required],
      home_close: [''],
      paid_parking: ['', Validators.required],
      parking_spot: ['', Validators.required],
      parking_pay_with: [''],
      max_parking_cost: [''],
      parking_notes: ['', Validators.required]
    });

  }

  @Loading()
  async ionViewDidEnter() {
    this.loaded = false;
    this.params = await this.customNavCtrl.getParams();
    this.setFormOptions();
    await this.populateForm(this.params.address);
    this.loaded = true;
  }

  async populateForm(address: AddressModel) {
    const params = {
      ...address,
      paid_parking: address.parking.paid_parking,
      parking_notes: address.parking.parking_notes,
      parking_spot: address.parking.parking_spot,
      parking_pay_with: address.parking.parking_pay_with,
      max_parking_cost: address.parking.max_parking_cost
    };
    this.form.patchValue(params);
  }

  saveChanges = async () =>  {
    this.submitted = true;
    this.errorMessage = '';
    if (!this.form.valid) {
      return;
    }

    const data = {
      home_access: this.form.value.home_access,
      home_close: this.form.value.home_close,
      parking: {
        max_parking_cost: this.form.value.max_parking_cost,
        paid_parking: this.form.value.paid_parking,
        parking_notes: this.form.value.parking_notes,
        parking_pay_with: this.form.value.parking_pay_with,
        parking_spot: this.form.value.parking_spot
      }};
    try {
      await this.privateClient.updatePrivateAddress(data, this.params.address.id);
      const params = this.mountSuccessPageParams();
      this.customNavCtrl.navigateForward('success', params);
    } catch (err) {
      this.errorMessage = err.message || err.error.message;
    }
  }

  mountSuccessPageParams(): SuccessPageParamsModel {
    return {
      header: `Notes Saved`,
      body: `The Access Notes for this Client has been updated.`,
      buttonText: `Ok`,
      buttonRoute: `private-client/${this.params.clientId}`
    };
  }

  setParkingValidators() {
    if (this.form.controls.paid_parking.value) {
      const requiredParkingFields = ['parking_pay_with', 'max_parking_cost'];
      requiredParkingFields.forEach(field => this.form.controls[field].setValidators([Validators.required]));
    }
  }

  clearFields() {
    if (this.form.valid) {
      this.showAlert = false;
    }
    this.form.patchValue({
      parking_spot: null,
      parking_pay_with: null,
      max_parking_cost: null
    });
    this.setParkingValidators();
  }

  setFormOptions() {
    this.parkingTypes = [
      {
        viewValue: 'Free',
        value: false
      },
      {
        viewValue: 'Paid',
        value: true
      }
    ];
    this.paidParkingSpots = [
      {
        viewValue: 'Meter',
        value: 'meter'
      },
      {
        viewValue: 'Paid Lot',
        value: 'paidlot'
      }
    ];
    this.freeParkingSpots = [
      {
        viewValue: 'Street',
        value: 'street'
      },
      {
        viewValue: 'Their Spot / Driveway',
        value: 'myspot'
      },
      {
        viewValue: 'Guest Parking',
        value: 'guest'
      }
    ];
    this.payOptions = [
      {
        viewValue: 'Cash',
        value: 'cash'
      },
      {
        viewValue: 'Card',
        value: 'card'
      },
    ];
    let maxCostOptions = [];
    for (let i = 1; i <= 30; i++) {
      maxCostOptions.push({
        viewValue: 'Max $' + i,
        value: i*100
      });
    }
    this.maxCostOptions = maxCostOptions;
  }
}
