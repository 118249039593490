<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [customBack]="'service'"
    [title]="'Edit Rates'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-card>
        <tidy-row>
          <tidy-text
            [header]="teamService?.name | titlecase">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="teamService?.description">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-divider>
          </tidy-divider>
        </tidy-row>
        <form [formGroup]="form">
          <tidy-row class="field-bottom-padding">
            <tidy-input
              formControlName="repeatRate"
              [form]="form.controls.repeatRate"
              [label]="'Repeat Rate'"
              [icon]="'assets/svg/cash-outline.svg'"
              inputMode="numeric"
              [mask]="'currencyMask'">
            </tidy-input>
          </tidy-row>
        </form>
        <tidy-row>
          <tidy-text
            [body]="'This is your rate for customers who book you directly on the times you choose. No advertising costs! The lower your rates the more business you may get.'">
          </tidy-text>
        </tidy-row>
        <form [formGroup]="form">
          <tidy-row class="extra-top-padding">
            <tidy-input
              formControlName="discount"
              [form]="form.controls.discount"
              [label]="'Discount Amount'"
              [icon]="'assets/svg/cash-outline.svg'"
              inputMode="numeric"
              [mask]="'currencyMask'">
            </tidy-input>
          </tidy-row>
        </form>
        <tidy-row>
          <tidy-text
            [body]="'Pros often win more new clients by offering a first job discount. New clients will pay $' + (getNewClientRate() / 100) + ' for their first job and $' + (getRepeatRateDisplay()) + ' for all subsequent jobs.'">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="getNewClientRate() < 0">
          <tidy-error-message
            [text]="'Please enter a discount that is less than your total rate.'">
          </tidy-error-message>
        </tidy-row>
      </tidy-card>

      <tidy-error-message
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-button
        [text]="'Preview Change'"
        [action]="previewChange.bind(this)"
        class="primary">
      </tidy-button>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
