export const mwMoments = {
  claimCoop: {
    name: 'claimed_coop',
    description: 'Claimed coop'
  },
  reportIssue: {
    name: 'report_problem',
    description: 'Reported issue'
  },
  viewedJob: {
    name: 'viewed_cleaning',
    description: 'Viewed job from "Jobs"'
  },
  tooLateForAction: {
    name: 'too_late_for_action',
    description: 'Too late for actions'
  },
  getDirections: {
    name: 'get_directions',
    description: 'Tap for Directions'
  },
  calledClient: {
    name: 'call_client',
    description: 'Called'
  },
  textedClient: {
    name: 'text_client',
    description: 'Texted'
  },
  tooEarlyCallText: {
    name: 'too_early_call_text',
    description: 'Tried to call / text too early'
  },
  onTheWay: {
    name: 'on_the_way',
    description: 'Notified Client they were on the way'
  },
  tooEarlyOnTheWay: {
    name: 'too_early_on_the_way',
    description: 'Tried to notify Client they were on the way too early'
  },
  callOut: {
    name: 'call_out',
    description: 'Called out'
  },
  clientRefusedService: {
    name: 'client_refused_service',
    description: 'Client refused service'
  },
  initiatedCantAccess: {
    name: 'initiated_no_access',
    description: 'Initiated can’t access home'
  },
  gotAccess: {
    name: 'got_access',
    description: 'Accessed the home'
  },
  confirmedCantAccess: {
    name: 'no_access',
    description: 'Couldn’t access the home'
  },
  arrivedAtJob: {
    name: 'arrived_at_home',
    description: 'Notified Client they have arrived'
  },
  tooEarlyArrivedStandby: {
    name: 'too_early_arrived_standby',
    description: 'Standby tried to notify Client they arrived too early'
  },
  startedJob: {
    name: 'start',
    description: 'Started job'
  },
  startedJobFarFromHome: {
    name: 'started_far_from_home',
    description: 'Started job far from home'
  },
  finishedToDos: {
    name: 'finished_to_dos',
    description: 'Finished To-Dos'
  },
  finishedToDosFarFromHome: {
    name: 'try_gps_far_from_home',
    description: 'Finish To-Dos far from Client'
  },
  retryGPSFarFromHome: {
    name: 'retry_gps_far_from_home',
    description: 'Retry GPS location'
  },
  confirmedFinishedFarFromHome: {
    name: 'not_at_home',
    description: 'Confirm finished far from Client'
  },
  finishedFarFromClientTimeout: {
    name: 'far_from_client_timeout',
    description: 'Unable to complete far from Client (timeout)'
  },
  takeLocationPicture: {
    name: 'take_location_photo_page',
    description: 'Finished Cleaning'
  },
  errorTakingPhoto: {
    name: 'photo_timeout',
    description: 'Error taking photo'
  },
  finishedToDosEarly: {
    name: 'confirm_finished_early',
    description: 'Attempted to finish To-Dos before full time of job'
  },
  finishedToDosEarlyCantStay: {
    name: 'cant_stay',
    description: 'Tell the Client I can\'t stay for the full cleaning'
  },
  finishedTodosEarlyClientUnhappy: {
    name: 'left_early_unhappy',
    description: 'Client instructed me to leave (unhappy)'
  },
  finishedTodosEarlyClientHappy: {
    name: 'left_early_happy',
    description: 'Client instructed me to leave (happy)'
  },
  jobSynced: {
    name: 'job_synced',
    description: 'Job Synced'
  },
  jobFinished: {
    name: 'end',
    description: 'Completed job'
  },
  enteredBeforePhotos: {
    name: 'initiated_before_photos',
    description: 'Entered before photos page'
  },
  enteredAfterPhotos: {
    name: 'initiated_after_photos',
    description: 'Entered after photos page'
  },
  savedBeforePhotos: {
    name: 'before_photos',
    description: 'Saved before photos'
  },
  savedAfterPhotos: {
    name: 'after_photos',
    description: 'Saved after photos'
  },
  initiatedLostItem: {
    name: 'initiated_lost_item',
    description: 'Initiated lost item'
  },
  contestDispute: {
    name: 'contested_dispute',
    description: ''
  },
  notContestDispute: {
    name: 'not_contested_dispute',
    description: ''
  },
};
