import { Injectable } from '@angular/core';

import { HttpClient } from 'src/shared/providers/http/http-client';
import { MWStore } from 'src/main-workflow/mw.store';
import { RequestCache } from 'src/shared/providers/http/request-cache';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { HomekeeperAddressModel } from 'src/models/homekeeper-profile.model';
import { HomekeeperModel } from 'src/shared/models/homekeeper.model';

@Injectable()
export class Me {

  constructor(
    private httpClient: HttpClient,
    private mwStore: MWStore,
    private storage: TidyStorage,
    private cache: RequestCache
  ) {}

  async load(): Promise<HomekeeperModel> {
    const url = 'api/v2/homekeeper/me';
    let data: HomekeeperModel = await this.cache.retrieve(url);
    if (!data) {
      data = await this.httpClient.get(url);
    }
    const notAvailableRepeat = this.isntAvailableForRepeat(data);
    await this.storage.save('not_available_repeat', notAvailableRepeat);
    await this.storage.save('hk_name', data.profile.name);
    await this.storage.save('hk_email', data.profile.email);
    await this.storage.save('hk_phone_number', data.profile.phone_number);
    await this.storage.save('hk_id', data.user.id);
    await this.storage.save('isLimitedAccount', data.user.limited_account);
    return data;
  }

  async fetchWithoutCache(): Promise<HomekeeperModel> {
    const data = await this.httpClient.getNoCache('api/v2/homekeeper/me');
    const notAvailableRepeat = this.isntAvailableForRepeat(data);
    await this.mwStore.setPhoneNumber(data.profile.phone_number);
    await this.storage.save('not_available_repeat', notAvailableRepeat);
    await this.storage.save('hk_id', data.user.id);
    return data;
  }

  updateMe(data) {
    const url = 'api/v1/homekeeper/me';
    return this.httpClient.put(url, data);
  }

  getToDosData(): Promise<any> {
    return this.httpClient.get('api/v1/homekeeper/feedback_cards');
  }

  getMetricsData(): Promise<any> {
    return this.httpClient.get('api/v1/homekeeper/dashboard/metrics');
  }

  getStreaksData(): Promise<any> {
    return this.httpClient.get('api/v1/homekeeper/dashboard/streaks');
  }

  updateAddress(address: HomekeeperAddressModel): Promise<any> {
    const url = `api/v1/homekeeper/profile/address`;
    return this.httpClient.put(url, address);
  }

  updatePhone(phone: string): Promise<any> {
    const url = `api/v1/homekeeper/profile/phone-number`;
    return this.httpClient.put(url, { phone });
  }

  updateTravelTime(maxTravelTime: string): Promise<any> {
    const url = `api/v1/homekeeper/profile/max-travel-time`;
    return this.httpClient.put(url, { max_travel_time: maxTravelTime });
  }

  confirmPhone(code: string): Promise<any> {
    const url = `api/v1/homekeeper/profile/phone-number/verify`;
    return this.httpClient.post(url, { code });

  }

  updateEmail(email: string): Promise<any> {
    const url = `api/v1/homekeeper/profile/email`;
    return this.httpClient.put(url, { email });
  }

  recoverPassword(emailAddress) {
    const data = {email: emailAddress};
    const url = `api/v1/homekeeper/profile/security/recover-password`;
    return this.httpClient.post(url, data);
  }

  signUp(data): Promise<any> {
    const url = `api/v1/homekeeper/sign-up`;
    return this.httpClient.post(url, data);
  }

  changePassword(data) {
    const url = `api/v1/homekeeper/profile/security/change-password`;
    return this.httpClient.post(url, data);
  }

  validateResetPwdToken(token) {
    const data = {reset_password_token: token};
    const url = `api/v1/homekeeper/profile/security/validate-reset-password-token`;
    return this.httpClient.post(url, data);
  }

  validateEmail(token) {
    const data = {email_verification_token: token};
    const url = `api/v1/homekeeper/profile/security/verify-email`;
    return this.httpClient.post(url, data);
  }

  validateEmailUpdate(verificationToken: string): Promise<any> {
    const url = `api/v1/homekeeper/profile/email/verify?verification_token=${verificationToken}`;
    return this.httpClient.get(url);
  }

  isntAvailableForRepeat(data: any): boolean {
    return !data.custom_fields.available_for_repeat_clients
      && data.user.state !== 'homekeeper_paused'
      && data.user.state !== 'homekeeper_pending_decertification'
      && data.certification.certified;
  }

  async getDisabledZipcodes() {
    const url = `/api/v1/homekeeper/disabled-zipcodes`;
    return this.httpClient.get(url);
  }

  async disableZipcode(data) {
    const url = `/api/v1/homekeeper/disabled-zipcodes`;
    return this.httpClient.post(url, data);
  }

  async deleteDisableZipcode(zipcodeId) {
    const url = `/api/v1/homekeeper/disabled-zipcodes/${zipcodeId}`;
    return this.httpClient.delete(url);
  }

  addMetaData(payload) {
    const url = '/api/v1/homekeeper/metadata';
    return this.httpClient.post(url, payload);
  }

  getSettings() {
    const url = 'api/v1/homekeeper/settings';
    return this.httpClient.get(url);
  }

  getBookingFormSelectValue(data) {
    const keys = Object.keys(data);
    return data[keys[0]];
  }

  getBookingFormMultiselectValue(data) {
    let string = '';
    data.map((item, i) => {
      const keys = Object.keys(item);
      string += item[keys[0]];
      if (i < data.length - 1) {
        string += ', ';
      }
    });
    return string;
  }

  getBookingFormAnswers(jobId) {
    const url = `api/v1/homekeeper/customer-booking-form-answers?job_id=${jobId}`;
    return this.httpClient.get(url);
  }

  deleteAccount(homekeeperId) {
    const url = `api/v1/homekeeper/${homekeeperId}/cancel-account`;
    return this.httpClient.post(url, {});
  }

  saveDistanceFormat(distanceFormat: string) {
    const url = `api/v1/homekeeper/metadata`;
    return this.httpClient.post(url, { key: 'distance_format', value: distanceFormat });
  }

  async getDistanceFormat() {
    const url = `api/v1/homekeeper/metadata`;
    const params = {
      'keys[]': ['external.distance_format']
    };
    return await this.httpClient.getWithParams(url, params);
  }

  async getDistanceFormatValue() {
    try {
      const distanceFormatKeyResponse = await this.getDistanceFormat();
      if (distanceFormatKeyResponse?.[0]?.value) {
        return distanceFormatKeyResponse[0].value;
      }
      return 'mi';
    } catch (error) {
      return 'mi';
    }
  }
  // Creates a new metadata when the key doesn't exist
  // Updates the metadata when the key exists
  addMetadata(payload) {
    const url = 'api/v1/homekeeper/metadata';
    return this.httpClient.post(url, payload);
  }

  getMetadata(keys: string[]) {
    const url = `api/v1/homekeeper/metadata?keys[]=${keys.join(',')}`;
    return this.httpClient.get(url);
  }

}
