import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PrivateClient } from 'src/providers/private-client/private-client';
import { Team } from 'src/providers/team/team';

import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';
import { PrivateJobService } from 'src/providers/private-job/private-job';
import { removeCurrencyMask } from 'src/shared/utils/currency-utils';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

@Component({
  templateUrl: 'complete-job.html'
})

export class CompleteJobPage {

  billingType: string;
  errorMessage: string;
  form: UntypedFormGroup;
  lengthItems: TidySelectNumberValueModel[];
  jobHoursItems: TidySelectNumberValueModel[];
  jobMinutesItems: TidySelectNumberValueModel[];
  jobDetails: any;
  jobId: any;
  loaded: boolean;
  submitted: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private privateClient: PrivateClient,
    private team: Team,
    private privateJobService: PrivateJobService,
    private storage: TidyStorage
  ) {
    this.form = this.fb.group({
      jobHours: [''],
      jobMinutes: [''],
      price: [''],
    });
  }

  ionViewDidEnter() {
    this.loaded = false;
    this.lengthItems = this.team.buildJobHourItems();
    this.jobDetails = this.navCtrl.getParam('jobDetails')
    this.jobId = this.navCtrl.getParam('jobId')
    this.billingType = this.jobDetails.job.service_type_details.billing_type;
    this.jobHoursItems = this.team.buildJobWholeHourItems();
    this.jobMinutesItems = this.team.buildJobMinuteItems();
    this.patchDurationForm(this.jobDetails.job.job_durations.from_start_moment_to_now);
    this.loaded = true;
  }

  patchDurationForm(minutes) {
    const hours = Math.floor(minutes / 60);
    minutes = Math.round((minutes - (hours * 60)) / 5) * 5;
    this.form.patchValue({
      jobHours: hours * 60,
      jobMinutes: minutes
    });
  }

  async completeJob() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    const storageKey = await this.storage.retrieve('storageKey');
    const syncData = await this.storage.retrieve(storageKey);
    try {
      const params: any = {
        job_id: this.jobId
      }
      const tasks = this.navCtrl.getParam('tasks');
      if (tasks && syncData.todos.length) {
        if (tasks[0]?.job_task_id) {
          params.job_tasks = tasks;
        } else {
          params.address_tasks = tasks;
        }
      }
      if (this.billingType == 'price_later' && this.form.value.price) {
        params.job_amount = removeCurrencyMask(this.form.value.price);
      }
      const durationPayload = {
        duration: this.form.value.jobHours + this.form.value.jobMinutes
      }
      await this.privateJobService.completePrivateJob(this.jobId, params);
      await this.privateJobService.updateJobDuration(durationPayload, this.jobId);
      await this.privateJobService.clearPrivateJobSyncData(this.jobId);
      if (this.billingType == 'price_later' && !this.form.value.price) {
        this.navCtrl.navigateForward(`private-job/${this.jobId}`);
      } else {
        await this.goToSendInvoicePage();
      }
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async goToSendInvoicePage() {
    try {
      const jobDetails = await this.privateClient.getJobDetailsForAddress(this.jobId, false);
      const params = {
        selectedJobs: [{
          id: this.jobId,
          service_type_details: jobDetails.job.service_type_details,
          date: jobDetails.job.date,
          start_time: jobDetails.job.start_time,
          address: jobDetails.address,
          amount: jobDetails.job.amount,
          billing: {
            amount_due: jobDetails.job.billing.amount_due
          },
          duration: jobDetails.job.job_durations.reported_by_homekeeper || jobDetails.job.job_durations.from_start_to_end_moment
        }],
        client: jobDetails.customer,
        addressUnit: this.navCtrl.getParam('unit'),
        jobCost: jobDetails.job.amount,
        jobId: this.jobId,
        cameFromCompleteJob: true
      };
      this.navCtrl.navigateForward('send-invoice', params);
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }
}
