<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Confirm Rate Change'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-card>
        <tidy-row>
          <tidy-text
            [header]="teamService?.name | titlecase">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="teamService?.description">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-divider>
          </tidy-divider>
        </tidy-row>
        <tidy-row [alignLastItemRight]="true" class="extra-bottom-padding">
          <tidy-text
            [header]="'Repeat Rate'">
          </tidy-text>
          <tidy-text
            [header]="'$' + payload?.repeat_rate">
          </tidy-text>
        </tidy-row>
        <tidy-row [alignLastItemRight]="true">
          <tidy-text
            [header]="'First Job Discount'">
          </tidy-text>
          <tidy-text
            [header]="'$' + (discount || 0)">
          </tidy-text>
        </tidy-row>
      </tidy-card>


      <tidy-alert [ngClass]="ratesPreview?.color + '-background'">
        <tidy-row>
          <tidy-text
            [header]="('These rates are: ' | translate) + ratesPreview?.header">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="ratesPreview?.body + ' '">
          </tidy-text>
          <tidy-text
            (action)="learnMore()"
            [body]="'Learn More'"
            class="link">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <ng-container *ngIf="proposalsToBeReviewed?.length > 0">
        <tidy-row [align]="'center'" class="vertical-align-center">
          <tidy-text
            (action)="changeShowClientsToCancel()"
            [actionBody]="'Cancel current clients below these rates'"
            class="link">
          </tidy-text>
          <tidy-image
            style="margin-left: 5px; height: 10px"
            [src]="showClientsToCancel ? 'assets/img/play-grey-up.svg' : 'assets/img/play-grey-down.svg'">
          </tidy-image>
        </tidy-row>
        <ng-container *ngIf="showClientsToCancel">
          <tidy-row>
            <tidy-text
              [header]="'Select Client(s) to Change Rates'">
            </tidy-text><br>
            <tidy-text
              [body]="'Clients you don\'t select will keep your current rate.'">
            </tidy-text>
          </tidy-row>
          <tidy-card *ngFor="let proposal of proposalsToBeReviewed">
            <tidy-row style="margin-top: -5px">
              <tidy-checkbox
                [items]="[{value: proposal?.customer_name, viewValue: proposal?.customer_name}]"
                (click)="selectProposal(proposal?.id)">
              </tidy-checkbox>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="('You will lose ' | translate) + proposal?.job_ids_to_be_unassigned?.length + (' jobs' | translate)">
              </tidy-text><br>
              <tidy-text
                [body]="('Repeat Rate: ' | translate) + (proposal?.current_user_repeat_rate | tcurrency)">
              </tidy-text><br>
              <tidy-text
                [body]="('Last Job: ' | translate) + (!proposal?.last_completed_job_date ? ('No Completed Jobs' | translate) : (proposal?.last_completed_job_date | tdate:'ddd M/D'))">
              </tidy-text>
            </tidy-row>
          </tidy-card>
        </ng-container>
      </ng-container>

      <tidy-button
        [text]="'Confirm Changes'"
        [action]="confirmRequestChange.bind(this)"
        class="primary">
      </tidy-button>

      <tidy-error-message
        [text]="errorMessage">
      </tidy-error-message>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
