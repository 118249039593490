import { Component, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { AddressService } from 'src/shared/providers/address';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Communication } from 'src/providers/communication/communication';
import { Me } from 'src/shared/providers/me';
import { MileageTracking } from 'src/providers/mileage-tracking/mileage-tracking';
import { TidyStorage } from "src/shared/providers/tidy-storage";
import { PrivateClient } from 'src/providers/private-client/private-client';
import { PrivatePayments } from 'src/providers/private-payments/private-payments';
import { Team } from 'src/providers/team/team'
import { DateTime as LuxonDateTime } from 'luxon';

import * as moment from 'moment';

import { NestedFormComponent } from 'src/shared/components/nested-form/nested-form.component';
import { Loading } from 'src/shared/components/loading/loading';

import { MileageTrackingRecordModel } from 'src/shared/models/mileage-tracking.model';
import { JobDetailsModel, TodosModel, TaskModel, ActivePrivateJob, PrivateJobBeforeAfterPhoto } from 'src/shared/models/job-details.model';
import { ParkingModel } from 'src/shared/models/address.model';

import { formatHourToShowAmPm } from 'src/shared/utils/date-utils';
import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';
import { animate, query, style, transition, trigger } from '@angular/animations';

import {  SuccessPageParamsModel } from 'src/pages/success/success';
import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { PrivateJobService } from 'src/providers/private-job/private-job';

import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';
import { DeviceInfoProvider } from 'src/shared/providers/device-info';
import { ToDosProvider } from 'src/providers/to-dos/to-dos';
import { ToDosComponent } from 'src/shared/components/to-dos/to-dos.component';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';
import { CustomFieldsService } from 'src/shared/providers/custom-fields/custom-fields';

@Component({
  templateUrl: 'private-job.html',
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        query('.js-todo-group', [
          style({ height: 0, marginBottom: -45, opacity: 0 }),
          animate('0.2s ease-out', style({ height: '100%', marginBottom: 0, opacity: 1 }))
        ], { optional: true }),
      ]),
      transition(':leave', [
        query('.js-todo-group', [
          style({ height: '100%', marginBottom: 0, opacity: 1 }),
          animate('0.2s ease-in', style({ height: 0, marginBottom: -45, opacity: 0 }))
        ], { optional: true }),
      ]),
    ])
  ]
})

export class PrivateJobPage extends Timeout {

  @ViewChild(ToDosComponent) toDosComponent: ToDosComponent;
  amount: number;
  addressName: string;
  baMediaType: string;
  beforeAfter: string;
  bookingNote: any;
  accessPhotos: any;
  cameFromPrivateClientPage: boolean;
  cameFromUnpaidJobsPage: boolean;
  control = new UntypedFormControl();
  clientName: string;
  clientId: number;
  closingPhotos: any;
  delegationRole: any;
  date: string;
  disableAnimations: boolean;
  durationForm: UntypedFormGroup;
  errorMessage: string;
  form: UntypedFormGroup;
  hasSameDayCheckIn: boolean;
  homeAccess: string;
  homeClose: string;
  jobId: string;
  jobHoursItems: TidySelectNumberValueModel[];
  jobMinutesItems: TidySelectNumberValueModel[];
  jobDetails: JobDetailsModel;
  jobDuration: any;
  jobMileage: MileageTrackingRecordModel[];
  hasCustomFormFieldError: boolean;
  length: number;
  numberOfRooms: number;
  parking: ParkingModel;
  proName: string;
  showBeforePhotosButton: boolean;
  showAfterPhotosButton: boolean;
  showJobDuration: boolean;
  startTime: string;
  submitted: boolean;
  todos: TodosModel[];
  timezone: any;
  unit: string;
  showCollapse = false;
  currentRoomId: number;
  noTodosList: string;
  beforeAfterPhotoType: string;
  messages: any;
  messagesLoaded: boolean;
  parkingDescriptions = {
    'meter' : 'at a meter',
    'paidlot' : 'in a paid lot',
    'street' : 'on the street',
    'myspot' : 'in their spot/driveway',
    'guest' : 'in guest parking'
  };
  parkingPhotos: any;
  previousGuestReservationDetails: any;
  nextGuestReservationDetails: any;
  screenwidth: number;
  beforePhotos: any[];
  afterPhotos: any[];
  distanceFormat: string;
  customFields: any;

  @ViewChild(NestedFormComponent, {static: false}) nestedFormComponent: NestedFormComponent;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenwidth = event.target.innerWidth
  }

  constructor(
    public toDos: ToDosProvider,
    private addressService: AddressService,
    private navCtrl: CustomNavController,
    public communication: Communication,
    private fb: UntypedFormBuilder,
    private modalCtrl: ModalController,
    private mileageTracking: MileageTracking,
    private privateClient: PrivateClient,
    public privatePayments: PrivatePayments,
    public team: Team,
    private iabUtils: InAppBrowserUtils,
    private store: TidyStorage,
    public me: Me,
    public route: ActivatedRoute,
    private privateJobService: PrivateJobService,
    public deviceInfo: DeviceInfoProvider,
    private customFieldsService: CustomFieldsService,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router
  ) {
    super(timeoutErrorHandler, router, route);
    this.jobId = this.route.snapshot.paramMap.get('jobId');
    this.form = this.fb.group({
      message: [''],
      jobHours: [''],
      jobMinutes: ['']
    });
    this.store.delete('jobDetails');
  }

  async ionViewDidEnter() {
    try {
      this.distanceFormat = await this.me.getDistanceFormatValue();
      const isStarted = localStorage.getItem(`privateJobStarted/${this.jobId}`);
      if (isStarted == 'true') {
        this.jobDetails = await this.store.retrieve(`privateJob/${this.jobId}`);
        this.bookingNote = await this.store.retrieve(`bookingNote/${this.jobId}`);
        this.jobMileage = [];
      } else {
        this.jobDetails = null;
      }
      this.screenwidth = window.innerWidth;
      this.loaded = false;
      this.messagesLoaded = false;
      this.errorMessage = '';
      this.jobDetails = this.jobDetails || await this.privateClient.getJobDetailsForAddress(this.jobId, true);
      this.store.save(`privateJob/${this.jobId}`, this.jobDetails);
      this.jobHoursItems = this.team.buildJobWholeHourItems();
      this.jobMinutesItems = this.team.buildJobMinuteItems();
      this.patchDurationForm(this.jobDetails.job.job_durations);
      await this.getCustomFields();
      if (this.jobDetails?.guest_reservation_data?.previous_guest_reservation?.details) {
        this.previousGuestReservationDetails = {
          ...this.jobDetails?.guest_reservation_data?.previous_guest_reservation?.details,
          customFields: this.customFieldsService.getCustomFieldsForDetails(this.customFields, this.jobDetails?.guest_reservation_data?.previous_guest_reservation?.custom_fields)
        }
      }
      if (this.jobDetails?.guest_reservation_data?.next_guest_reservation?.details) {
        this.nextGuestReservationDetails = {
          ...this.jobDetails?.guest_reservation_data?.next_guest_reservation?.details,
          customFields: this.customFieldsService.getCustomFieldsForDetails(this.customFields, this.jobDetails?.guest_reservation_data?.next_guest_reservation?.custom_fields)
        }
        this.hasSameDayCheckIn = this.checkIfHasSameDayCheckIn();
      }
      this.bookingNote = this.bookingNote || await this.privateClient.getBookingNote(this.jobDetails?.booking?.id);
      this.store.save(`bookingNote/${this.jobId}`, this.bookingNote);
      this.delegationRole = await this.getDelegationRole();
      this.jobMileage = this.jobMileage || await this.mileageTracking.getMileageRecordByHkJob(this.jobDetails.homekeeper_job.id);
      this.amount = this.jobDetails.job.amount;
      this.addressName = this.jobDetails.address.address + (this.jobDetails.address?.address_name ? (' - ' + this.jobDetails.address?.address_name) : '');
      this.unit = this.jobDetails.address.unit;
      this.date = this.jobDetails.job.date;
      this.startTime = this.jobDetails.job.start_time;
      this.length = this.jobDetails.job.duration_in_minutes;
      this.clientName = this.jobDetails.customer.first_name + ' ' + this.jobDetails.customer.last_name;
      const me = await this.me.load();
      this.timezone = me.profile.address.timezone_name;
      this.proName = me.profile.name;
      this.clientId = this.jobDetails.customer.id;
      this.parking = this.jobDetails.address.parking;
      this.homeAccess = this.jobDetails.address.home_access;
      this.homeClose = this.jobDetails.address.home_close;
      this.cameFromPrivateClientPage = this.navCtrl.getParam('cameFromPrivateClientPage');
      this.cameFromUnpaidJobsPage = this.navCtrl.getParam('cameFromUnpaidJobsPage');
      this.parkingPhotos = this.jobDetails.address.photo_notes.filter((photo) => photo.type == 'parking');
      this.accessPhotos = this.jobDetails.address.photo_notes.filter((photo) => photo.type == 'access');
      this.closingPhotos = this.jobDetails.address.photo_notes.filter((photo) => photo.type == 'closing');
      this.loaded = true;
      this.messages = await this.communication.getSharedInboxForHomekeeperJob(this.jobDetails.homekeeper_job.id);
      this.messagesLoaded = true;
      await this.markMessagesAsRead();
    } catch (err) {
      console.error(err);
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async getCustomFields() {
    try {
      this.customFields = await this.customFieldsService.getCustomFields(this.jobDetails?.booking?.id, 'GuestReservation');
    } catch (err) {
      console.error(err);
    }
  }

  patchDurationForm(jobDurations) {
    if (!jobDurations.from_start_to_end_moment && !jobDurations.reported_by_homekeeper) {
      return this.jobDuration = 'Unknown';
    }
    let minutes = jobDurations.reported_by_homekeeper ? jobDurations.reported_by_homekeeper : jobDurations.from_start_to_end_moment;
    const hours = Math.floor(minutes / 60);
    minutes = Math.round((minutes - (hours * 60)) / 5) * 5;
    this.form.patchValue({
      jobHours: hours * 60,
      jobMinutes: minutes
    });
    this.jobDuration = hours + (hours == 1 ? ' hour ' : ' hours ');
    if (minutes > 0) {
      this.jobDuration += minutes + ' minutes';
    }
  }

  async markMessagesAsRead() {
    let array: any = [];
    this.messages.map((message, index) => {
      if (!message.is_read && message.from_member.type == 'CustomerMember' && index < 3 && message?.chat_room_key) {
        array.push((message?.chat_room_key));
      }
    });
    if (array.length > 0) {
      await this.communication.markMessagesAsRead(array);
    }
  }

  async getDelegationRole() {
    const hkId = await this.store.retrieve('hk_id');
    if (!this.jobDetails.job.job_delegation_request) {
      return '';
    } else if (this.jobDetails.job.job_delegation_request?.delegated_by_team_member?.homekeeper?.id == hkId) {
      return 'delegator';
    } else {
      return 'delegatee';
    }
  }

  async goToClientDetails() {
    await this.store.save('privateClientBackRoute', `private-job/${this.jobId}`);
    this.navCtrl.navigateForward(['private-client', this.clientId]);
  }

  getDirections() {
    const { address } = this.jobDetails.address;
    this.addressService.directions(address);
  }

  goToRescheduleCancelPage() {
    const params = {
      rescheduleJobParams: {
        clientName: this.clientName,
        currentFrequency: 'once',
        currentDate: this.jobDetails.job.date,
        addressName: this.addressName,
        addressId: this.jobDetails.address.id,
        jobDateAndHour: `
          ${moment(this.jobDetails.job.date).format('dddd M/D')} at
          ${formatHourToShowAmPm(this.jobDetails.job.start_time)}
        `,
        jobId: `${this.jobId}`,
        jobData: this.jobDetails.job,
        rescheduleType: 'job',
        serviceTypeDetails: this.jobDetails.job.service_type_details
      },
      cancelJobParams: {
        clientName: this.clientName,
        jobData: this.jobDetails.job,
        jobId: this.jobId,
      }
    }
    this.navCtrl.navigateForward('reschedule-cancel', params);
  }

  @Loading()
  async completeJobAction(alreadyCompleted = false) {
    this.toDosComponent.completeList(alreadyCompleted);
  }

  editStatus() {
    const params = {
      clientName: this.clientName,
      jobData: this.jobDetails.job
    };

    this.navCtrl.navigateForward(['private-change-status', this.jobId], params);
  }

  callAction() {
    this.iabUtils.openUrl(`tel://${this.jobDetails.customer.phone_number}`);
  }

  goToUpcomingFeaturePage = (feature) => {
    this.navCtrl.navigateForward('upcoming-feature', { feature });
  }

  delegateJob() {
    this.navCtrl.navigateForward('delegate-job', {hkJobId: this.jobDetails.homekeeper_job.id});
  }

  async cancelDelegationRequest(type) {
    const params = this.getDelegationConfirmParams(type);
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });
    confirmationModal.present();
  }

  getDelegationConfirmParams(type): ConfirmPageParamsModel {
    const params = {
      cancel: {
        title: 'Cancel Request?',
        body: `${new TranslationPipe().transform('Please confirm you want to cancel the request for')} ${this.jobDetails.job.job_delegation_request.delegated_to_team_member.homekeeper.name} ${new TranslationPipe().transform('to accept this job.')}`,
        backText: 'Go Back',
        confirmText: 'Cancel Request',
        confirmAction: this.confirmCancelDelegationRequest.bind(this, type)
      },
      undo: {
        title: 'Undo Acceptance?',
        body: `${new TranslationPipe().transform('Please confirm you want to give this job back to')} ${this.jobDetails.job.job_delegation_request.delegated_by_team_member.homekeeper.name}.`,
        backText: 'Go Back',
        confirmText: 'Confirm',
        confirmAction: this.confirmCancelDelegationRequest.bind(this, type)
      },
      getJobBack: {
        title: 'Undo Delegation?',
        body: `Please confirm you want to complete this job yourself.`,
        backText: 'Go Back',
        confirmText: 'Confirm',
        confirmAction: this.confirmCancelDelegationRequest.bind(this, type)
      }
    }
    return params[type];
  }

  async confirmCancelDelegationRequest(type) {
    await this.team.cancelDelegationRequest(this.jobDetails.job.job_delegation_request.id);
    const delegatorName = this.jobDetails
      .job
      .job_delegation_request
      .delegated_by_team_member
      .homekeeper
      .name;
    const successParams = this.getDelegationSuccessParams(delegatorName, type);
    this.modalCtrl.dismiss();
    this.navCtrl.navigateForward('success', successParams);
  }

  getDelegationSuccessParams(delegatorName, type): SuccessPageParamsModel {
    const params = {
      cancel: {
        header: 'Request Cancelled',
        body: 'You are expected to complete this job.',
        buttonText: 'Go to Jobs',
        buttonRoute: `jobs`
      },
      undo: {
        header: 'Acceptance Undone',
        body: `${delegatorName} ${new TranslationPipe().transform('is now expected to complete this job.')}`,
        buttonText: 'Go to Jobs',
        buttonRoute: `jobs`
      },
      getJobBack: {
        header: 'Delegation Undone',
        body: `You are expected to complete this job.`,
        buttonText: 'Go to Jobs',
        buttonRoute: `jobs`
      }
    }
    return params[type];
  }

  async editMileage() {
    await this.store.save('jobMileageBackRoute', `private-job/${this.jobId}`);
    this.navCtrl.navigateForward('job-mileage', this.jobMileage[0]);
  }

  @Loading()
  async goToSendInvoicePage(cameFromCompleteJob) {
    const shouldBlockComplete = await this.shouldBlockCompleteOnMissingFields();
    if (shouldBlockComplete) {
      return;
    }

    this.privateJobService.goToSendInvoicePage(cameFromCompleteJob, this.jobDetails);
  }

  async shouldBlockCompleteOnMissingFields() {
    const blockComplete = this.jobDetails?.job?.address_task_list?.block_complete_on_missing_fields;

    if (blockComplete) {
      const hasCustomFieldError = await this.toDosComponent.checkIfHasCustomFieldError()
      if (hasCustomFieldError) {
        return true;
      }
    }
    return false;
  }

  async recordPayment() {
    const params = {
      title: 'Mark as Paid?',
      body: 'This will mark the job as paid in full.',
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: this.confirmRecordPayment.bind(this)
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });
    confirmationModal.present();
  }

  async confirmRecordPayment() {
    const payload = {
      customer_id: this.clientId,
      payment_type_id: 1,
      amount: this.jobDetails.job.billing.amount_due,
      job_id: this.jobId
    };
    await this.privatePayments.recordPayment(payload);
    const successParams = {
      header: 'Payment Recorded',
      body: '',
      buttonText: 'Ok',
      buttonRoute: `private-job/${this.jobId}`
    };
    this.modalCtrl.dismiss();
    this.navCtrl.navigateForward('success', successParams);
  }

  goToRefundChargePage() {
    const params = {
      clientName: this.clientName,
      clientId: this.clientId,
      jobId: this.jobId,
      refundable: this.jobDetails.job.billing.refundable
    }
    this.navCtrl.navigateForward('refund-charge', params);
  }

  getBackRoute() {
    const backPage = localStorage.getItem('privateJobBackPage') || 'jobs';
    if (backPage) {
      return backPage;
    } else if (this.cameFromPrivateClientPage) {
      return '/private-client/' + this.clientId;
    } else if (this.cameFromUnpaidJobsPage) {
      return '/unpaid-jobs';
    } else {
      return '/jobs';
    }
  }

  goToEditJobPrice() {
    const params = {
      job: {
        job: this.jobDetails.job,
      },
      date: this.date,
      startTime: this.startTime
    }
    this.navCtrl.navigateForward('edit-private-job-price', params);
  }

  goToJobMessagesPage() {
    const params = {
      job: this.jobDetails,
      clientName: this.clientName,
      proName: this.proName,
      timezone: this.timezone
    }
    this.navCtrl.navigateForward(`private-job-messages/${this.jobId}`, params);
  }

  @Loading()
  async sendMessage(attachment = []) {
    this.form.controls.message.setValidators([Validators.required]);
    this.errorMessage = '';
    this.submitted = true;
    if (attachment.length == 0 && !this.form.get('message').valid) {
      this.form.controls.message.clearValidators();
      this.form.controls.message.updateValueAndValidity();
      return;
    }
    this.form.controls.message.clearValidators();
    this.form.controls.message.updateValueAndValidity();
    const payload = {
      chat: {
        type: 'homekeeper_job',
        data: {
          homekeeper_job_id: this.jobDetails.homekeeper_job.id
        }
      },
      message: {
        text: this.form.value.message,
        files: attachment
      }
    };
    try {
      const response = await this.communication.sendSharedInboxMessage(payload);
      if (response?.data?.message == 'We couldn\'t send your message, please try again later!') {
        return this.errorMessage = response.data.message;
      }
      this.messages = await this.communication.getSharedInboxForHomekeeperJob(this.jobDetails.homekeeper_job.id);
      this.form.patchValue({
        message: ''
      });
      this.submitted = false;
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async sendAttachment() {
    this.errorMessage = '';
    try {
      const attachment = await this.communication.addAttachment();
      if (attachment == '') {
        return this.errorMessage = 'Unable to attach photo. Please upload a PNG or JPEG file.';
      }
      await this.sendMessage([attachment]);
    } catch (err) {
      this.errorMessage =  err.error ? err.error.message : err.message;
    }
  }

  goToSentInvoicePage() {
    const params = {
      invoiceId: this.jobDetails.job.invoice.id,
      clientName: this.clientName,
      clientId: this.clientId
    };
    this.navCtrl.navigateForward('sent-invoice', params);
  }

  checkIfHasSameDayCheckIn() {
    const checkInDate = LuxonDateTime.fromISO(this.jobDetails.guest_reservation_data.next_guest_reservation.check_in_date);
    const jobDate = LuxonDateTime.fromISO(this.jobDetails.job.date);
    return checkInDate.hasSame(jobDate, 'day');
  }

  formatReservationDate(date) {
    const isoDate = LuxonDateTime.fromISO(date);
    return isoDate.toFormat('EEE MMM dd, yyyy');
  }

  formatReservationTime(time) {
    const isoDate = LuxonDateTime.fromISO(time, { zone: 'utc' });
    return isoDate.toFormat('h:mma').toLowerCase();
  }

  getReservationLength(checkInDate, checkOutDate) {
    const isoCheckInDate = LuxonDateTime.fromISO(checkInDate);
    const isoCheckOutDate = LuxonDateTime.fromISO(checkOutDate);
    const numberDays = isoCheckOutDate.diff(isoCheckInDate, 'days').days;
    return ' (' + numberDays + ((numberDays == 1) ? ' day)' : ' days)');
  }

  async markJobComplete() {
    const shouldBlockComplete = await this.shouldBlockCompleteOnMissingFields();
    if (shouldBlockComplete) {
      return;
    }

    const alreadyCompleted = true;
    const params: ConfirmPageParamsModel = {
      body: 'Are you sure you want to mark this job as complete?',
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: this.alreadyCompleteJobAction.bind(this, alreadyCompleted),
      title: 'Mark Complete',
    };
    const modal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });
    modal.present();
  }

  alreadyCompleteJobAction() {
    const alreadyCompleted = true;
    this.completeJobAction(alreadyCompleted);
  }

  toggleShowEditJobDuration() {
    this.showJobDuration = !this.showJobDuration;
  }

  async submitJobDuration() {
    const minutes = this.form.value.jobHours + this.form.value.jobMinutes;
    const payload = {
      duration: minutes
    }
    const response = await this.privateJobService.updateJobDuration(payload, this.jobId);
    this.patchDurationForm(response.job_durations);
    this.showJobDuration = false;
  }
}
