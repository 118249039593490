<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Jobs'"
    [showLinkIcon]="true"
    [linkIcon]="'export'"
    (linkAction)="goToCalendarPage()">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [hasBottomNav]="true">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

        <tidy-row>
          <tidy-jobs-calendar
            [jobs]="calendarJobCounts"
            (selectedStartDate)="onSelectStartDate($event)"
            (selectedPeriod)="onSelectedPeriodChange($event)"
            (selectedWeekEmitter)="selectedWeekEmitter($event)">
          </tidy-jobs-calendar>
        </tidy-row>

        <tidy-alert *ngIf="!hasJobs && !isLimitedAccount">
          <tidy-row class="vertical-align-center">
            <tidy-image
              src="assets/svg/alert-circle.svg"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'No Jobs This Week'">
            </tidy-text>
          </tidy-row>
          <tidy-text
            [body]="'Add existing jobs by adding a client then jobs for them. To get more clients, we recommend getting certifications, opening  your schedule to be open, increasing your service area, & making your rates competitive.'">
          </tidy-text>
        </tidy-alert>

        <ng-container *ngFor="let weekday of upcomingCleanings; let weekdayIndex = index;">
          <tidy-card>
            <tidy-row *ngIf="today !== weekday?.date" class="vertical-align-center">
              <tidy-image
                src="assets/style-guide-images/bowtie-black.svg"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="(weekday?.date | formatDateTitle) | titlecase">
              </tidy-text>
              <tidy-text
                *ngIf="!weekday?.showMap && weekday?.cards.length > 0"
                [body]="'Show Map'"
                (action)="setMap(weekday, weekdayIndex)"
                class="link"
                style="position: absolute; right: 0px; padding-right: 10px;">
              </tidy-text>
              <tidy-text
                *ngIf="weekday?.showMap && weekday?.cards.length > 0"
                [body]="'Hide Map'"
                (action)="weekday.showMap = false"
                class="link"
                style="position: absolute; right: 0px; padding-right: 10px;">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="today === weekday?.date" class="vertical-align-center">
              <tidy-image
                src="assets/style-guide-images/bowtie-black.svg"
                class="title-size">
              </tidy-image>
              <tidy-text
                [title]="(weekday?.date | formatDateTitle)">
              </tidy-text>
              <tidy-text
                *ngIf="!weekday?.showMap && weekday?.cards.length > 0"
                [body]="'Show Map'"
                (action)="setMap(weekday, weekdayIndex)"
                class="link"
                style="position: absolute; right: 0px; padding-right: 10px;">
              </tidy-text>
              <tidy-text
                *ngIf="weekday?.showMap && weekday?.cards.length > 0"
                [body]="'Hide Map'"
                (action)="weekday.showMap = false"
                class="link"
                style="position: absolute; right: 0px; padding-right: 10px;">
              </tidy-text>
            </tidy-row>
            <tidy-row class="extra-bottom-padding" *ngIf="!isLimitedAccount">
              <tidy-text
                [helper]="weekday?.availability + ' '">
              </tidy-text>
              <tidy-text
                *ngIf="scheduleEditable(weekday)"
                [helper]="'Edit'"
                (action)="editAvailability(weekday?.date)"
                class="link">
              </tidy-text>
            </tidy-row>

            <ng-container *ngIf="weekday?.cards.length === 0">
              <tidy-row>
                <tidy-text
                  [helper]="'No Jobs Scheduled'">
                </tidy-text>
              </tidy-row>
            </ng-container>

            <ng-container *ngIf="weekday?.cards.length > 0">
              <tidy-row>
                <div
                  id="map-jobs{{weekdayIndex}}"
                  [attr.id]="'map-jobs' + weekdayIndex"
                  style="width: 100%; border-radius: 6px;"
                  [ngStyle]="weekday?.showMap === true ? {'height': '300px', 'marginBottom': '1rem'} : {'height': '0px'}">
                </div>
              </tidy-row>
              <ng-container *ngFor="let cleaning of weekday?.cards; let i = index">
                <tidy-card-button
                  [class.no-below-stroke]="cleaning?.isTimeSensitiveJob ||
                  showUndoLink(cleaning) ||
                  cleaning?.job_delegation_request?.status === 'accepted' ||
                  cleaning?.job_delegation_request?.status === 'requested'"
                  (click)="onCardClicked(cleaning)">
                  <tidy-grid>
                    <ion-row>
                      <ion-col size="2" *ngIf="weekday?.showMap === true">
                        <tidy-marker [letter]="i"></tidy-marker>
                      </ion-col>
                      <ion-col [size]="weekday?.showMap === true ? 3 : 4">
                        <tidy-text
                          [body]="cleaning.job.start_time | ttime">
                        </tidy-text><br>
                        <tidy-text
                          [body]="(cleaning?.job?.duration_in_minutes / 60) + (cleaning?.job?.duration_in_minutes > 60 ? (' hours' | translate) : (' hour' | translate))">
                        </tidy-text>
                      </ion-col>
                      <ion-col size="1">
                        <tidy-image
                          *ngIf="cleaning?.homekeeper_job?.repeat_client"
                          [src]="'assets/svg/star-yellow.svg'"
                          class="body-size">
                        </tidy-image>
                      </ion-col>
                      <ion-col [size]="weekday?.showMap === true ? 6 : 7">
                        <tidy-text
                          *ngIf="cleaning?.card_type !== 'booking_protection_cleaning'"
                          [body]="(cleaning?.customer?.account_name !== '' && cleaning?.customer?.account_name) ?
                          cleaning?.customer?.account_name :
                          (cleaning?.customer?.first_name + ' ' + (cleaning?.customer?.last_name | abbrLastName: true))"
                          [translate]="false">
                        </tidy-text>
                        <tidy-text
                          *ngIf="cleaning?.customer?.is_premium"
                          [actionBody]="' (Big Spender)'"
                          class="green">
                        </tidy-text>
                        <tidy-text
                          *ngIf="cleaning?.card_type === 'booking_protection_cleaning'"
                          [body]="'Booking Protection Pending'">
                        </tidy-text><br>
                        <ng-container *ngIf="cleaning?.dispute">
                          <tidy-text
                            *ngIf="cleaning?.dispute?.status === 'action_needed'"
                            class="red"
                            [body]="'Action Needed'">
                          </tidy-text>
                          <tidy-text
                            *ngIf="cleaning?.dispute?.status === 'under_review' && cleaning?.dispute?.homekeeper_contest === true"
                            class="red"
                            [body]="'Under Review'">
                          </tidy-text>
                          <ng-container *ngIf="cleaning?.dispute?.status === 'resolved'">
                            <tidy-text
                              *ngIf="cleaning?.job_delegation_request?.role !== 'delegatee'"
                              [body]="(cleaning?.homekeeper_job?.performance | performanceText) + ': ' + (cleaning?.payment_price | tcurrency)">
                            </tidy-text>
                            <tidy-text
                              *ngIf="cleaning?.job_delegation_request?.role == 'delegatee'"
                              [body]="(cleaning?.homekeeper_job?.performance | performanceText)">
                            </tidy-text>
                          </ng-container>
                        </ng-container>
                        <ion-row *ngIf="cleaning?.job?.type === 'custom'">
                          <ion-col>
                            <tidy-text
                              *ngIf="cleaning?.address?.address_name"
                              [body]="cleaning?.address?.address_name">
                            </tidy-text>
                            <tidy-text
                              *ngIf="!cleaning?.address?.address_name"
                              [body]="cleaning?.address?.address1 + (cleaning?.address?.address2 ? (' ' + cleaning?.address?.address2) : '')">
                            </tidy-text>
                          </ion-col>
                        </ion-row>
                        <ng-container *ngIf="cleaning?.job?.type !== 'custom' && !cleaning?.dispute">
                          <ng-container *ngIf="cleaning?.card_type === 'past_cleaning'">
                            <tidy-text
                              *ngIf="cleaning?.job_delegation_request?.role !== 'delegatee'"
                              [body]="(cleaning?.homekeeper_job?.performance | performanceText) + ': ' + (cleaning?.payment_price | tcurrency)">
                            </tidy-text>
                            <tidy-text
                              *ngIf="cleaning?.job_delegation_request?.role == 'delegatee'"
                              [body]="(cleaning?.homekeeper_job?.performance | performanceText)">
                            </tidy-text>
                          </ng-container>
                          <ng-container *ngIf="cleaning?.card_type === 'future_cleaning' || cleaning?.card_type === 'current_cleaning'">
                            <tidy-text
                              *ngIf="cleaning?.job_delegation_request?.role !== 'delegatee'"
                              [body]="(cleaning?.service_type_details?.service_category?.name == 'Regular Cleaning' ? 'Cleaning' : cleaning?.service_type_details?.service_category?.name)">
                            </tidy-text>
                            <tidy-text
                            *ngIf="cleaning?.job_delegation_request?.role !== 'delegatee'"
                            [body]="': ' + (cleaning?.payment_price | tcurrency)">
                          </tidy-text>
                            <tidy-text
                              *ngIf="cleaning?.job_delegation_request?.role === 'delegatee'"
                              [body]="cleaning?.service_type_details?.service_category?.name == 'Regular Cleaning' ? 'Cleaning' : cleaning?.service_type_details?.service_category?.name">
                            </tidy-text>
                          </ng-container>
                        </ng-container>
                        <tidy-text
                          *ngIf="cleaning?.job?.type === 'custom'"
                          [body]="cleaning?.job?.private_status | privateStatus">
                        </tidy-text>
                      </ion-col>
                    </ion-row>
                  </tidy-grid>
                </tidy-card-button>
                <ng-container *ngIf="cleaning?.isTimeSensitiveJob">
                  <tidy-alert class="light-green-background">
                    <tidy-row class="vertical-align-center">
                     <tidy-image
                        [src]="'assets/img/green-alert.svg'"
                        class="header-size">
                      </tidy-image>
                      <tidy-text
                        [body]="'Time Sensitive Job'">
                      </tidy-text>
                    </tidy-row>
                  </tidy-alert>
                  <tidy-divider></tidy-divider>
                </ng-container>
                <ng-container *ngIf="showUndoLink(cleaning)">
                  <tidy-alert class="light-green-background">
                    <tidy-row class="vertical-align-center">
                     <tidy-image
                        [src]="'assets/svg/arrow-redo-circle.svg'"
                        class="body-size">
                      </tidy-image>
                      <tidy-text
                        (action)="redirToUndo(cleaning)"
                        [body]="'Undo Cancellation'"
                        class="link">
                      </tidy-text>
                    </tidy-row>
                  </tidy-alert>
                  <tidy-divider></tidy-divider>
                </ng-container>
                <ng-container *ngIf="cleaning?.job_delegation_request?.status === 'accepted'">
                  <tidy-alert *ngIf="cleaning?.job_delegation_request?.role === 'delegatee'" class="light-green-background">
                    <tidy-row class="vertical-align-center">
                     <tidy-image
                        [src]="'assets/svg/people-circle.svg'"
                        class="body-size">
                      </tidy-image>
                      <tidy-text
                        [body]="'Delegated from: '">
                      </tidy-text>
                      <tidy-text
                        [body]="cleaning?.job_delegation_request?.delegator?.name"
                        [translate]="false">
                      </tidy-text>
                    </tidy-row>
                  </tidy-alert>
                  <tidy-alert *ngIf="cleaning?.job_delegation_request?.role === 'delegator'" class="light-green-background">
                    <tidy-row class="vertical-align-center">
                      <tidy-image
                        [src]="'assets/svg/people-circle.svg'"
                        class="body-size">
                      </tidy-image>
                      <tidy-text
                        [body]="'Delegated to: '">
                      </tidy-text>
                      <tidy-text
                        [body]="cleaning?.job_delegation_request?.delegatee?.name"
                        [translate]="false">
                      </tidy-text>
                    </tidy-row>
                  </tidy-alert>
                  <tidy-divider></tidy-divider>
                </ng-container>
                <ng-container *ngIf="cleaning?.job_delegation_request?.status === 'requested' && cleaning?.job_delegation_request?.role === 'delegator'">
                  <tidy-alert class="light-green-background">
                    <tidy-row>
                      <tidy-icon
                        [name]="'people-circle'"
                        class="body-size wiggle-animation">
                      </tidy-icon>
                      <tidy-text
                        [body]="'Pending delegation'">
                      </tidy-text>
                      <tidy-text
                        [body]="(!cleaning?.job_delegation_request?.delegatee?.name ? ' to your invited team member' : ': ' + cleaning?.job_delegation_request?.delegatee?.name)"
                        [translate]="false">
                      </tidy-text>
                    </tidy-row>
                  </tidy-alert>
                </ng-container>
              </ng-container>
            </ng-container>
          </tidy-card>

          <tidy-row
            *ngIf="weekday.date === today && !isLimitedAccount"
            [align]="'center'">
            <tidy-text
              class="divider"
              [body]="'Nightly 8pm Cutoff'">
            </tidy-text><br>
            <tidy-text
              [helper]="'Jobs may change until 8pm '">
            </tidy-text>
            <tidy-text
              [helper]="'Learn More'"
              (action)="learnMoreNightlyCutoff()"
              class="link">
            </tidy-text>
          </tidy-row>
        </ng-container>

        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'Export Jobs to Calendar'"
            (action)="goToCalendarPage()"
            class="link">
          </tidy-text>
        </tidy-row>

    </ng-container>

    <ng-container *ngIf="!loaded">

      <tidy-row class="extra-bottom-padding">
        <div style="
        background: white;
        border-radius: 25px;
        padding: 7px;
        display: block;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3), 0px 0px 3px rgba(0, 0, 0, 0.24);
        font-size: 16px;
        width: fit-content;
        margin: auto;
        max-width: 80%">
          <ion-skeleton-text animated="true" style="width: 95px; margin: 10px"></ion-skeleton-text>
        </div>
      </tidy-row>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 75%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <tidy-card-button [hideArrow]="true">
            <tidy-grid>
              <ion-row>
                <ion-col size="4">
                  <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 52%"></ion-skeleton-text>
                </ion-col>
                <ion-col size="1">
                </ion-col>
                <ion-col size="7">
                  <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
                </ion-col>
              </ion-row>
            </tidy-grid>
          </tidy-card-button>
          <tidy-card-button [hideArrow]="true">
            <tidy-grid>
              <ion-row>
                <ion-col size="4">
                  <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 52%"></ion-skeleton-text>
                </ion-col>
                <ion-col size="1">
                </ion-col>
                <ion-col size="7">
                  <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
                </ion-col>
              </ion-row>
            </tidy-grid>
          </tidy-card-button>
          <tidy-card-button [hideArrow]="true">
            <tidy-grid>
              <ion-row>
                <ion-col size="4">
                  <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 52%"></ion-skeleton-text>
                </ion-col>
                <ion-col size="1">
                </ion-col>
                <ion-col size="7">
                  <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
                </ion-col>
              </ion-row>
            </tidy-grid>
          </tidy-card-button>
        </tidy-row>
      </tidy-card>

      <tidy-row>
        <ion-skeleton-text animated style="width: 30%; margin: auto"></ion-skeleton-text><br>
        <ion-skeleton-text animated style="width: 60%; margin: auto"></ion-skeleton-text>
      </tidy-row>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 75%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <tidy-card-button [hideArrow]="true">
            <tidy-grid>
              <ion-row>
                <ion-col size="4">
                  <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 52%"></ion-skeleton-text>
                </ion-col>
                <ion-col size="1">
                </ion-col>
                <ion-col size="7">
                  <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
                </ion-col>
              </ion-row>
            </tidy-grid>
          </tidy-card-button>
          <tidy-card-button [hideArrow]="true">
            <tidy-grid>
              <ion-row>
                <ion-col size="4">
                  <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 52%"></ion-skeleton-text>
                </ion-col>
                <ion-col size="1">
                </ion-col>
                <ion-col size="7">
                  <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
                </ion-col>
              </ion-row>
            </tidy-grid>
          </tidy-card-button>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 75%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <tidy-card-button [hideArrow]="true">
            <tidy-grid>
              <ion-row>
                <ion-col size="4">
                  <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 52%"></ion-skeleton-text>
                </ion-col>
                <ion-col size="1">
                </ion-col>
                <ion-col size="7">
                  <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
                </ion-col>
              </ion-row>
            </tidy-grid>
          </tidy-card-button>
          <tidy-card-button [hideArrow]="true">
            <tidy-grid>
              <ion-row>
                <ion-col size="4">
                  <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 52%"></ion-skeleton-text>
                </ion-col>
                <ion-col size="1">
                </ion-col>
                <ion-col size="7">
                  <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
                </ion-col>
              </ion-row>
            </tidy-grid>
          </tidy-card-button>
        </tidy-row>
      </tidy-card>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

<tidy-bottom-nav activeRoute="/jobs">
</tidy-bottom-nav>
