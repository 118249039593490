<tidy-row
  [@.disabled]="disableAnimations"
  @inOutAnimation
  *ngIf="isToShowCollapse"
  class="complete-stack"
>
  <tidy-card
    class="card"
  >
  </tidy-card>

  <tidy-card
    class="card"
    [ngClass]="{'colapse-card2' : isAnimationsEnabled}"
  >
  </tidy-card>

  <tidy-card class="card" [ngClass]="{'colapse-card3' : isAnimationsEnabled}">
    <div item class="expand-card">
      <tidy-image class="helper-size" [src]="'assets/svg/checkmark-circle-outline.svg'"></tidy-image>

      <tidy-text class="done-count" body=" {{doneTodos}} {{'of' | translate}} {{room?.todos?.length || room?.tasks?.length}} {{'done' | translate}}"></tidy-text>

      <tidy-text (action)="onExpandRoom()" [body]="'Expand'" class="link"></tidy-text>
    </div>
  </tidy-card>
</tidy-row>
