<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [customBack]="backButtonRoute"
    [canGoBack]="true"
    [title]="loaded ? client?.account_name && client?.account_name !== '' ? client?.account_name : (client?.first_name | titlecase) + ' ' + (client?.last_name | titlecase) : ''"
    [showLinkIcon]="loaded"
    [linkIcon]="'chatbox-ellipses'"
    (linkAction)="goToClientMessagesPage()">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-card>
        <tidy-row [alignLastItemRight]="true">
          <tidy-image
            [src]="'assets/style-guide-images/private-client-black-white.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Contact Info'">
          </tidy-text>
          <tidy-text
            [body]="'Edit'"
            data-cy="editContactInfo"
            (action)="editInfo()"
            class="link">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="client?.email !== '' && client?.email !== null && client?.email !== undefined">
          <tidy-text
            [body]="client?.email"
            [translate]="false">
          </tidy-text>
        </tidy-row>
        <ng-container *ngIf="client?.phone_number !== null && client?.phone_number !== '' && client?.phone_number !== undefined">
          <tidy-text
            [body]="privateClient.formatPhoneDisplay(client?.phone_number)">
          </tidy-text>
        </ng-container>
      </tidy-card>

      <tidy-card>
        <tidy-row [alignLastItemRight]="true">
          <tidy-image
            [src]="'assets/svg/cash.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Balance'">
          </tidy-text>
          <tidy-text
            [body]="(billingHistory?.balance | tcurrency)">
          </tidy-text>
        </tidy-row>
        <tidy-card-button (action)="goToRequestPaymentPage()">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/card-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              style="margin-right:5px"
              [body]="'Request Payment'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToBillingOptionsPage()">
          <tidy-row class="vertical-align-center" >
            <tidy-image
              [src]="'assets/svg/chatbox-ellipses-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="'More Billing Options'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row [alignLastItemRight]="unpaidJobs?.length == 0" [ngClass]="unpaidJobs?.length == 0 ? '' : 'vertical-align-center'">
          <tidy-image
            [src]="'assets/svg/alert-circle.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Unpaid Jobs'">
          </tidy-text>
          <tidy-text
            *ngIf="unpaidJobs?.length == 0"
            [body]="'0 Unpaid'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let job of unpaidJobs">
          <tidy-card-button (action)="goToPrivateJobPage(job)">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="!job?.invoice ? 'assets/svg/alert-circle-outline.svg' : 'assets/svg/send-outline.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                *ngIf="job?.invoice"
                [body]="(job?.invoice?.amount_due | tcurrency)">
              </tidy-text>
              <tidy-text
                *ngIf="!job?.invoice"
                [body]="(job?.billing?.amount_due | tcurrency)">
              </tidy-text>
              <tidy-text
                [body]="' owed for '">
              </tidy-text>
              <tidy-text
                [body]="(job?.date | tdate: 'M/D')">
              </tidy-text>
              <tidy-text
                [body]="' job'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                *ngIf="job?.invoice !== null && job?.invoice !== undefined"
                [body]="'Invoice Sent - Waiting on Client'">
              </tidy-text>
              <tidy-text
                *ngIf="!job?.invoice && job?.private_status !== 'scheduled'"
                [actionBody]="'No Invoice - Waiting on You to Send'"
                class="green">
              </tidy-text>
              <tidy-text
                *ngIf="job?.private_status == 'scheduled'"
                [actionBody]="'Not Complete  - Mark Complete to Get Paid'"
                class="green">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </ng-container>
      </tidy-card>

      <tidy-card>
        <tidy-card-button (action)="goToProposalsPage()">
          <tidy-row [alignLastItemRight]="true">
            <tidy-image
              [src]="'assets/img/proposals-black.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [header]="'Proposals'">
            </tidy-text>
            <tidy-text
              [body]="pendingProposals?.length">
            </tidy-text>
            <tidy-text
              [body]="' Pending'"
              class="margin-right-5">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="client?.account_name && client?.account_name !== '' ? client?.account_name : (client?.first_name | titlecase)"
          [translate]="false">
        </tidy-text>
        <tidy-text
          [body]="' can see your proposals & invoices at their link. '">
        </tidy-text>
        <tidy-text
          [body]="'Copy URL'"
          (action)="privatePayments.copyWebsite(client?.client_hub_uuid)"
          class="link">
        </tidy-text>
        <tidy-text
          *ngIf="privatePayments.isWebsiteCopied()"
          [helper]="' Copied'">
        </tidy-text>
      </tidy-row>

      <ng-container *ngIf="addressAdded">
        <tidy-row>
          <tidy-filter
            [items]="addresses"
            [initValue]="addresses[0].value"
            (optionChange)="changeAddress($event)"
            [fitContent]="true">
          </tidy-filter>
        </tidy-row>

        <tidy-card>
          <tidy-row [alignLastItemRight]="true">
            <tidy-image
              src="assets/style-guide-images/private-job-black-white.svg"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Jobs'">
            </tidy-text>
            <tidy-text
              [body]="'Add Job'"
              (action)="addJob()"
              class="link">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="addressSummary?.address?.active_plans?.length > 0">
            <ng-container *ngFor="let plan of addressSummary?.address?.active_plans">
              <tidy-text
                [body]="(plan.service_type_details.name | titlecase)">
              </tidy-text>
              <ng-container *ngIf="plan.frequency">
                <tidy-text
                  [body]="' '">
                </tidy-text>
                <tidy-text
                  [body]="(plan.frequency | planFrequency) | titlecase">
                </tidy-text>
              </ng-container>
              <tidy-text
                [body]="' '">
              </tidy-text>
              <tidy-text
                (action)="editPlan(plan)"
                [body]="'Edit Plan'"
                class="link">
              </tidy-text>
            </ng-container>
          </tidy-row>
          <tidy-row *ngIf="addressSummary?.address?.active_plans?.length == 0">
            <tidy-text
              [body]="'No Plan Scheduled'">
            </tidy-text>
          </tidy-row>
          <tidy-card-button (action)="goToFutureJobs()" [hideArrow]="hideFutureJobsArrow">
            <tidy-text
              [body]="'Future Jobs'">
            </tidy-text><br>
            <tidy-text
              *ngIf="addressSummary?.next_job"
              [helper]="'Next '">
            </tidy-text>
            <tidy-text
              *ngIf="addressSummary?.next_job"
              [helper]="addressSummary?.next_job?.date | formatDateTitle">
            </tidy-text>
            <tidy-text
              *ngIf="addressSummary?.next_job"
              [helper]="' at '">
            </tidy-text>
            <tidy-text
              *ngIf="addressSummary?.next_job"
              [helper]="addressSummary?.next_job?.start_time | ttime">
            </tidy-text>
            <tidy-text
              *ngIf="!addressSummary?.next_job"
              [helper]="'No Future Jobs'">
            </tidy-text>
          </tidy-card-button>
          <tidy-card-button (action)="goToPastJobs()" [hideArrow]="hidePastJobsArrow">
            <tidy-text
              [body]="'Past Jobs'">
            </tidy-text><br>
            <tidy-text
              *ngIf="addressSummary?.last_job"
              [helper]="'Last '">
            </tidy-text>
            <tidy-text
              *ngIf="addressSummary?.last_job"
              [helper]="addressSummary?.last_job?.date | formatDateTitle">
            </tidy-text>
            <tidy-text
              *ngIf="addressSummary?.last_job"
              [helper]="' at '">
            </tidy-text>
            <tidy-text
              *ngIf="addressSummary?.last_job"
              [helper]="addressSummary?.last_job?.start_time | ttime">
            </tidy-text>
            <tidy-text
              *ngIf="!addressSummary?.last_job"
              [helper]="'No Past Jobs'">
            </tidy-text>
          </tidy-card-button>
        </tidy-card>

        <tidy-card>
          <tidy-row [alignLastItemRight]="true">
            <tidy-image
              [src]="'assets/svg/home.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Address Info'">
            </tidy-text>
            <tidy-text
              [body]="'Edit'"
              data-cy="editAddressInfo"
              (action)="editAddress(addressSelected)"
              class="link">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="addressSelected?.address +
              ', ' +
              addressSelected?.zipcode"
              [translate]="false">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="addressSelected?.unit">
            <tidy-text
              [body]="'Unit: '">
            </tidy-text>
            <tidy-text
              [body]="addressSelected?.unit">
            </tidy-text>
          </tidy-row>
        </tidy-card>

        <tidy-card>
          <tidy-row [alignLastItemRight]="true">
            <tidy-image
              [src]="'assets/svg/create.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Access Notes'">
            </tidy-text>
            <tidy-text
              [body]="'Edit'"
              data-cy="editAddressInfo"
              (action)="goToEditAccess(addressSelected)"
              class="link">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [body]="'How to park: '">
            </tidy-text>
            <tidy-text
              *ngIf="addressSelected?.parking?.paid_parking !== null && addressSelected?.parking?.paid_parking !== undefined"
              [body]="addressSelected?.parking?.paid_parking ? 'Paid Parking on the ' : 'Free Parking in my '">
            </tidy-text>
            <tidy-text
              *ngIf="!addressSelected?.parking?.paid_parking"
              [helper]="'No parking notes added.'">
            </tidy-text>
            <tidy-text
              *ngIf="addressSelected?.parking?.parking_spot"
              [body]="addressSelected?.parking?.paid_parking ? paidParkingSpots[addressSelected?.parking?.parking_spot] : freeParkingSpots[addressSelected?.parking?.parking_spot]">
            </tidy-text>
            <tidy-text
              *ngIf="addressSelected?.parking?.parking_notes"
              [body]="'. ' + addressSelected?.parking?.parking_notes">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [body]="'How to get in: '">
            </tidy-text>
            <tidy-text
              *ngIf="addressSelected?.home_access !== null && addressSelected?.home_access !== '' && addressSelected?.home_access !== undefined"
              [body]="addressSelected?.home_access">
            </tidy-text>
            <tidy-text
              *ngIf="!addressSelected?.home_access || addressSelected?.home_access == ''"
              [helper]="'No access notes added.'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'How to close up: '">
            </tidy-text>
            <tidy-text
              *ngIf="addressSelected?.home_close !== null && addressSelected?.home_close !== '' && addressSelected?.home_close !== undefined"
              [body]="addressSelected?.home_close">
            </tidy-text>
            <tidy-text
              *ngIf="!addressSelected?.home_close || addressSelected?.home_close == ''"
              [helper]="'No closing notes added.'">
            </tidy-text>
          </tidy-row>
        </tidy-card>

        <tidy-card>
          <tidy-row [alignLastItemRight]="true">
            <tidy-image
              [src]="'assets/svg/checkbox.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'To-Dos'">
            </tidy-text>
            <tidy-text
              [body]="'Add To-Dos'"
              (action)="goToUpcomingFeaturePage('create_to_dos_list')"
              class="link">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'None Added'">
            </tidy-text>
          </tidy-row>
        </tidy-card>
      </ng-container>

      <ng-container *ngIf="!addressAdded">
        <tidy-button
          [text]="'Add Address'"
          data-cy="btn-addAddress"
          [action]="addAddress.bind(this)"
          class="primary">
        </tidy-button>
        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'Add an address to add private jobs for this Client.'">
          </tidy-text>
        </tidy-row>
      </ng-container>

    </ng-container>

    <ng-container *ngIf="!loaded">

      <tidy-card>
        <tidy-row>
          <tidy-grid>
            <ion-row>
              <ion-col size="5">
                <ion-skeleton-text animated style="width: 140px; height: 15px"></ion-skeleton-text>
              </ion-col>
              <ion-col size="7">
                <ion-skeleton-text animated style="width: 35px; float: right"></ion-skeleton-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 120px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 130px"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <tidy-grid>
            <ion-row>
              <ion-col size="5">
                <ion-skeleton-text animated style="width: 90px; height: 15px"></ion-skeleton-text>
              </ion-col>
              <ion-col size="7">
                <ion-skeleton-text animated style="width: 60px; float: right"></ion-skeleton-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated style="width: 140px"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated style="width: 150px"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <tidy-grid>
            <ion-row>
              <ion-col size="5">
                <ion-skeleton-text animated style="width: 135px; height: 15px"></ion-skeleton-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated style="width: 160px"></ion-skeleton-text>
          </tidy-row>
          <tidy-row>
            <ion-skeleton-text animated style="width: 95px"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <tidy-grid>
            <ion-row>
              <ion-col size="5">
                <ion-skeleton-text animated style="width: 110px; height: 15px"></ion-skeleton-text>
              </ion-col>
              <ion-col size="7">
                <ion-skeleton-text animated style="width: 60px; float: right"></ion-skeleton-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
      </tidy-card>

      <tidy-row >
        <ion-skeleton-text animated></ion-skeleton-text>
        <ion-skeleton-text animated></ion-skeleton-text>
        <ion-skeleton-text animated style="width: 80px; margin: auto" ></ion-skeleton-text>
      </tidy-row>

      <tidy-row class="extra-bottom-padding">
        <div style="
        background: white;
        border-radius: 25px;
        padding: 7px;
        display: block;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3), 0px 0px 3px rgba(0, 0, 0, 0.24);
        font-size: 16px;
        width: fit-content;
        margin: auto;
        max-width: 80%">
          <ion-skeleton-text animated="true" style="width: 170px; margin: 10px"></ion-skeleton-text>
        </div>
      </tidy-row>

      <tidy-card>
        <tidy-row>
          <tidy-grid>
            <ion-row>
              <ion-col size="5">
                <ion-skeleton-text animated style="width: 70px; height: 15px"></ion-skeleton-text>
              </ion-col>
              <ion-col size="7">
                <ion-skeleton-text animated style="width: 60px; float: right"></ion-skeleton-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated style="width: 90px"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 180px"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated style="width: 90px"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 160px"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <tidy-grid>
            <ion-row>
              <ion-col size="5">
                <ion-skeleton-text animated style="width: 150px; height: 15px"></ion-skeleton-text>
              </ion-col>
              <ion-col size="7">
                <ion-skeleton-text animated style="width: 35px; float: right"></ion-skeleton-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 120px"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <tidy-grid>
            <ion-row>
              <ion-col size="5">
                <ion-skeleton-text animated style="width: 130px; height: 15px"></ion-skeleton-text>
              </ion-col>
              <ion-col size="7">
                <ion-skeleton-text animated style="width: 70px; float: right"></ion-skeleton-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 120px"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
