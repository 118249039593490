import { Component, ViewEncapsulation } from '@angular/core';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { ScheduleCardModel } from 'src/models/schedule-card.model';
import { Me } from 'src/shared/providers/me';
import { HomekeeperAddressModel } from 'src/models/homekeeper-profile.model';
import { Loading } from 'src/shared/components/loading/loading';
import { SuccessPageParamsModel } from 'src/pages/success/success';

@Component({
  selector: 'confirm-address-cancellation',
  templateUrl: 'confirm-address-cancellation.html',
  encapsulation: ViewEncapsulation.None
})
export class ConfirmAddressCancellationPage {

  errorMessage: string;
  cancellations: Array<ScheduleCardModel>;
  newAddress: HomekeeperAddressModel;

  constructor(
    private customNavCtrl: CustomNavController,
    private me: Me
  ) {
  }

  @Loading()
  async ionViewDidEnter() {
    const params = this.customNavCtrl.getParams();
    this.cancellations = params.cancellations;
    this.newAddress = params.newAddress;
  }

  confirmUpdate = async () => {
    this.errorMessage = '';
    try {
      await this.me.updateAddress(this.newAddress);
      const params = this.mountSuccessPageParams();
      this.customNavCtrl.navigateForward('success', params);
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  mountSuccessPageParams(): SuccessPageParamsModel {
    return {
      header: `Address Updated`,
      body: `Your Address has been updated for all jobs`,
      buttonText: `Ok`,
      buttonRoute: `more`
    };
  }

  goBack = () => {
    this.customNavCtrl.back();
  }
}
