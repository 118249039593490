import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DateTime as LuxonDateTime } from 'luxon';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { TidyStorage } from "src/shared/providers/tidy-storage";
import { MySchedule } from 'src/providers/my-schedule/my-schedule';

import { Loading } from 'src/shared/components/loading/loading';

import { TeamServiceModel } from 'src/shared/models/team.model';

@Component({
  selector: 'clients-from-tidy-services',
  templateUrl: './clients-from-tidy-services.html'
})

export class ClientsFromTidyServicesPage implements OnInit {

  amountChanged = 0;
  discountError: boolean;
  errorMessage: string;
  form: UntypedFormGroup;
  icon: string;
  loaded = false;
  proposalsToBeReviewed: any;
  repeatRate: any;
  rates: any;
  teamService: TeamServiceModel;

  constructor(
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private mySchedule: MySchedule,
    private store: TidyStorage
  ) {
    this.form = this.fb.group({
      discount: [''],
      repeatRate: ['']
    });
  }

  @Loading()
  async ngOnInit() {
    this.teamService = await this.store.retrieve('teamService');
    this.form.patchValue({
      repeatRate: await this.store.retrieve('repeatRate'),
      discount: await this.store.retrieve('discount')
    });
    this.loaded = true;
  }

  async previewChange() {
    if (this.discountError) {
      return;
    }
    const repeatRate = this.form.value.repeatRate ? this.form.value.repeatRate.replace(/^./, "") : 0;
    const didIncreaseRates = await this.checkifDidIncreaseRates(repeatRate);
    if (didIncreaseRates) {
      const isAllowedToChangeRates = await this.checkIfIsAllowedToChangeRates();
      if (!isAllowedToChangeRates) {
        const params = {
          header: 'Unable to Change Rates',
          body: 'You’ve exceeded the number of times you can edit this in a period, please wait and try again later. It’s unusual to increase rates more than 1x per year. Speak with the Concierge if you need help.',
          buttonText: 'Ok',
          buttonRoute: 'service'
        };
        return this.customNavCtrl.navigateForward('success', params);
      }
    }
    const discount = this.form.value.discount ? this.form.value.discount.replace(/^./, "") : 0;
    await this.store.save('repeatRate', repeatRate);
    await this.store.save('discount', discount);
    const params = {
      teamService: this.teamService,
      discount: Math.round(discount),
      payload: {
        team_service_id: this.teamService.id,
        repeat_rate: Math.round(repeatRate),
        proposal_ids_to_force_update: [],
        new_rate: Math.round(parseInt(repeatRate) - parseInt(discount))
      }
    }
    this.customNavCtrl.navigateForward('clients-from-tidy-services/preview-change', params);
  }

  async checkifDidIncreaseRates(repeatRate) {
    const currentRate = await this.store.retrieve('repeatRate');
    return parseInt(currentRate) < parseInt(repeatRate);
  }

  async checkIfIsAllowedToChangeRates() {
    let yearCount = 0;
    let ninetyDayCount = 0;
    let thirtyDayCount = 0;
    let changeHistory = await this.mySchedule.fetchScheduleChangeHistory();
    changeHistory = changeHistory.filter((change) => change.item_type == 'pay_rate_change' && change?.data?.team_service_name == this.teamService.name);
    changeHistory.splice(-1); //Remove the rate change that BE automatically adds for each service when pro first signs up
    changeHistory.map((change) => {
      const date = LuxonDateTime.fromISO(change.created_at);
      const now = LuxonDateTime.now();
      const yearDiff = now.diff(date, 'years').years;
      const dayDiff = now.diff(date, 'days').days;
      if (yearDiff < 1) {
        yearCount += 1;
      }
      if (dayDiff < 90) {
        ninetyDayCount += 1;
      }
      if (dayDiff < 30) {
        thirtyDayCount += 1;
      }
    });
    return yearCount < 9 && ninetyDayCount < 7 && thirtyDayCount < 5;
  }

  getNewClientRate() {
    let discount = this.form.value.discount ? this.form.value.discount.replace(/^./, "") : 0;
    let repeatRate = this.form.value.repeatRate ? this.form.value.repeatRate.replace(/^./, "") : 0;
    repeatRate = parseInt(repeatRate) * 100;
    discount = parseInt(discount) * 100;
    const rate = repeatRate - discount;
    this.discountError = rate < 0;
    return isNaN(rate) ? repeatRate : rate;
  }
  
  getRepeatRateDisplay() {
    const rate = this.form.value.repeatRate ? this.form.value.repeatRate.replace(/^./, "") : 0;
    return parseInt(rate);
  }

}
