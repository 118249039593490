<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Past Job'"
    [customBack]="backPage">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper *ngIf="loaded">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="!isReportedIssuesLoaded">
      <tidy-card>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <tidy-grid>
            <ion-row>
              <ion-col size="4">
                <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
              </ion-col>
              <ion-col size="1">
              </ion-col>
              <ion-col size="7" >
                <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
      </tidy-card>
    </ng-container>

    <ng-container *ngIf="isReportedIssuesLoaded">
      <ng-container *ngFor="let item of reportedIssues">
        <tidy-card *ngIf="item?.components[0]?.value?.status === 'waiting_on_customer'">
          <tidy-card-button (action)="goToConciergeMessage(item)" [hideArrow]="true" [customIcon]="'assets/img/play-black.svg'">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="concierge.getStatusIcon(item?.components[0]?.value?.status)"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="(item?.metadata?.request_data?.subject | slice:0:30) + (item?.metadata?.request_data?.subject?.length > 30 ? '..' : '')">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="item?.components[0]?.value?.status !== 'completed' && item?.components[0]?.value?.status !== 'closed'">
              <tidy-text
                [body]="'<b><i>Waiting on You</i><b>'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'<i>' + (item?.components[0]?.value?.last_update_at | tdate:'M/D/YY h:mma') + '</i>'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
        <tidy-card *ngIf="item?.components[0]?.value?.status !== 'waiting_on_customer'">
          <tidy-card-button (action)="goToConciergeMessage(item)">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="concierge.getStatusIcon(item?.components[0]?.value?.status)"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="(item?.metadata?.request_data?.subject | slice:0:30) + (item?.metadata?.request_data?.subject?.length > 30 ? '..' : '')">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="item?.components[0]?.value?.status !== 'completed' && item?.components[0]?.value?.status !== 'closed'">
              <tidy-text
                [body]="'<i>Waiting on TIDY</i>'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'<i>' + (item?.components[0]?.value?.last_update_at | tdate:'M/D/YY h:mma') + '</i>'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!isLoggedIssuesLoaded">
      <tidy-card>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <tidy-grid>
            <ion-row>
              <ion-col size="4">
                <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
              </ion-col>
              <ion-col size="1">
              </ion-col>
              <ion-col size="7" >
                <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
      </tidy-card>
    </ng-container>

    <ng-container *ngIf="isLoggedIssuesLoaded">
      <tidy-alert *ngFor="let issue of loggedIssues">
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [header]="getIssueHeader(issue)">
          </tidy-text><br>
          <tidy-text
            [helper]="'Status: '">
          </tidy-text>
          <tidy-text
          [helper]="(issue?.status | titlecase)">
        </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="issue?.description">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngFor="let attachment of issue?.issue_report_photos">
          <img [src]="attachment?.photo_url">
        </tidy-row>
      </tidy-alert>
    </ng-container>

    <tidy-alert *ngIf="cleaning?.job_delegation_request?.status === 'accepted'">
      <tidy-row class="vertical-align-center">
       <tidy-image
          [src]="'assets/svg/people-circle.svg'"
          class="body-size">
        </tidy-image>
        <tidy-text
          *ngIf="cleaning?.job_delegation_request?.role === 'delegatee'"
          [header]="'Job Delegated to You'">
        </tidy-text>
        <tidy-text
          *ngIf="cleaning?.job_delegation_request?.role === 'delegator'"
          [header]="'Job Delegated to '">
        </tidy-text>
        <tidy-text
          *ngIf="cleaning?.job_delegation_request?.role === 'delegator'"
          [header]="cleaning?.job_delegation_request?.delegatee?.name">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          *ngIf="cleaning?.job_delegation_request?.role === 'delegator'"
          [body]="'You delegated this job to '">
        </tidy-text>
        <tidy-text
          *ngIf="cleaning?.job_delegation_request?.role === 'delegator'"
          [body]="cleaning?.job_delegation_request?.delegatee?.name">
        </tidy-text>
        <tidy-text
          *ngIf="cleaning?.job_delegation_request?.role === 'delegator'"
          [body]="' and they completed it. All payment will be made to you. '">
        </tidy-text>
        <tidy-text
          *ngIf="cleaning?.job_delegation_request?.role === 'delegatee'"
          [body]="'This job was delegated to you by '">
        </tidy-text>
        <tidy-text
          *ngIf="cleaning?.job_delegation_request?.role === 'delegatee'"
          [body]="cleaning?.job_delegation_request?.delegator?.name">
        </tidy-text>
        <tidy-text
          *ngIf="cleaning?.job_delegation_request?.role === 'delegatee'"
          [body]="'. All payment will be made to them. '">
        </tidy-text>
      </tidy-row>
    </tidy-alert>

    <tidy-alert *ngIf="reimbursementRequest?.status === 'pending'">
      <tidy-row [align]="'center'" class="vertical-align-center">
        <tidy-image
          [src]="'assets/svg/cash.svg'"
          class="header-size">
        </tidy-image>
        <tidy-text
          [header]="'Reimbursement Pending'">
        </tidy-text>
      </tidy-row>
      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'We received your parking reimbursement request and have contacted the Client. We\'ll update the cleaning when we receive their response.'">
        </tidy-text>
      </tidy-row>
    </tidy-alert>

    <tidy-alert *ngIf="cleaning?.dispute_summary?.concierge_item_id">
      <tidy-row [align]="'center'" class="vertical-align-center">
        <tidy-image
          [src]="'assets/svg/alert-circle.svg'"
          class="header-size">
        </tidy-image>
        <tidy-text
          [header]="'Cleaning Disputed '">
        </tidy-text>
        <tidy-text *ngIf="cleaning?.dispute_summary?.concierge_item_type_key === 'homekeeper_dispute_counter_waiting'"
          [header]="'(Dispute Counter Sent)'">
        </tidy-text>
      </tidy-row>

      <tidy-row [align]="'center'" *ngIf="cleaning?.dispute_summary?.concierge_item_type_key === 'homekeeper_dispute_counter_waiting'">
        <tidy-text
          body="The dispute has been contested and is waiting on the Client response. You will receive a response in 2-5 days."
        ></tidy-text>
      </tidy-row>

      <tidy-row [align]="'center'" *ngIf="cleaning?.dispute_summary?.concierge_item_type_key === 'homekeeper_dispute_lost'">
        <tidy-text
          body="The dispute has been Lost."
        ></tidy-text>
      </tidy-row>

      <tidy-row [align]="'center'" *ngIf="cleaning?.dispute_summary?.concierge_item_type_key === 'homekeeper_dispute_won'">
        <tidy-text
          body="The dispute has been contested and won."
        ></tidy-text>
      </tidy-row>

      <tidy-row [align]="'center'" class="extra-bottom-padding" *ngIf="cleaning?.dispute_summary?.concierge_item_type_key === 'homekeeper_dispute_initiated'">
        <tidy-text
          [body]="'Do you want to contest the dispute?'">
        </tidy-text>
      </tidy-row>

      <tidy-two-column-row>
        <tidy-button item-right
          [text]="'Check Dispute Details'"
          [action]="goToDispute.bind(this, cleaning?.dispute_summary?.concierge_item_id)"
          class="secondary">
        </tidy-button>
      </tidy-two-column-row>
    </tidy-alert>

    <tidy-card *ngIf="reimbursementRequest?.status === 'approved'">
      <tidy-row [align]="'center'" class="vertical-align-center">
        <tidy-image
          [src]="'assets/svg/cash.svg'"
          class="header-size">
        </tidy-image>
        <tidy-text
          [header]="'Reimbursement Approved'">
        </tidy-text>
      </tidy-row>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'The Client has 72 hours to cancel, after which this will be added to your payment.'">
        </tidy-text>
      </tidy-row>
    </tidy-card>

    <ng-container *ngIf="!isCleaningLoaded">
      <tidy-card>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <tidy-grid>
            <ion-row>
              <ion-col size="4">
                <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
              </ion-col>
              <ion-col size="1">
              </ion-col>
              <ion-col size="7" >
                <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
      </tidy-card>
    </ng-container>

    <ng-container *ngIf="isCleaningLoaded">
      <tidy-card>
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            src="assets/style-guide-images/bowtie-black.svg"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="cleaning?.job.date | tdate: 'ddd M/D':'YYYY-MM-DD'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-grid>
            <ion-row>
              <ion-col size="4">
                <tidy-text
                  [body]="cleaning.job.start_time | ttime">
                </tidy-text><br>
                <tidy-text
                  [body]="(cleaning?.job?.duration_in_minutes / 60) + (cleaning?.job?.duration_in_minutes > 60 ? ' hours' : ' hour')">
                </tidy-text>
              </ion-col>
              <ion-col size="1">
                <tidy-image
                  *ngIf="cleaning?.homekeeper_job?.repeat_client"
                  [src]="'assets/svg/star-yellow.svg'"
                  class="body-size">
                </tidy-image>
              </ion-col>
              <ion-col size="7" >
                <tidy-text
                  [body]="(cleaning?.customer?.account_name !== '' && cleaning?.customer?.account_name) ?
                  cleaning?.customer?.account_name :
                  (cleaning?.customer?.first_name + ' ' + (cleaning?.customer?.last_name | abbrLastName: true))">
                </tidy-text> <br>
                <ng-container *ngIf="!cleaning.dispute || (cleaning.dispute && cleaning.dispute.status === 'resolved')">
                  <ng-container *ngIf="cleaning.card_type !== 'future_cleaning'">
                    <tidy-text
                      [body]="cleaning.homekeeper_job.performance | performanceText">
                    </tidy-text>
                     <tidy-text
                      *ngIf="cleaning.payment_price != null && cleaning?.job_delegation_request?.role !== 'delegatee'"
                      [body]="': ' + (cleaning.payment_price | tcurrency)">
                    </tidy-text>
                  </ng-container>
                  <tidy-text
                    *ngIf="cleaning.status === 'Accelerated'"
                    class="green"
                    [body]="' Accelerated'">
                  </tidy-text>
                  <tidy-text
                    *ngIf="cleaning.is_fee_waived"
                    [body]="' Fee Waived'"
                    class="green">
                  </tidy-text>
                </ng-container>

              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
      </tidy-card>
    </ng-container>

    <tidy-card *ngIf="cleaning?.job_delegation_request?.role !== 'delegatee'">
      <tidy-row class="extra-bottom-padding vertical-align-center">
        <tidy-image
          [src]="'assets/svg/card.svg'"
          class="header-size">
        </tidy-image>
        <tidy-text
          [header]="'Earnings Breakdown'">
        </tidy-text>
      </tidy-row>

      <ng-container *ngFor="let earning of paymentHistory?.earnings_breakdown?.payment_items">
        <tidy-row  *ngIf="earning.amount != 0">
          <tidy-text
            [body]="earning.description + ' '">
          </tidy-text>
          <tidy-text
            [body]="earning.amount | tcurrency">
          </tidy-text>
          <tidy-text
            *ngIf="isNewBasePayment(earning)"
            [body]="' <b>*</b>'">
          </tidy-text>
        </tidy-row>
      </ng-container>
      <tidy-row *ngIf="hasNewBasePayment">
        <tidy-text
          [body]="'<b>*</b> This client first booked you on '">
        </tidy-text>
        <tidy-text
          [body]="paymentHistory?.job_data?.homekeeper_assigned_local_datetime?.date | tdate:'M/D/YY'">
        </tidy-text>
        <tidy-text
          [body]="' at '">
        </tidy-text>
        <tidy-text
          [body]="paymentHistory?.job_data?.homekeeper_assigned_local_datetime?.time | ttime">
        </tidy-text>
        <tidy-text
          [body]="', prior to your most recent rate change. With your current rates the base payment would be '">
        </tidy-text>
        <tidy-text
          [body]="paymentHistory?.job_data?.base_pay_rate | tcurrency">
        </tidy-text>
        <tidy-text
          [body]="'.'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text class="divider">
        </tidy-text>
      </tidy-row>
      <tidy-row [alignLastItemRight]="showWaiveFee">
        <tidy-text
          [body]="'Total '">
        </tidy-text>
        <tidy-text
        [body]="(paymentHistory?.earningTotal | tcurrency)">
      </tidy-text>
        <tidy-text
          *ngIf="showWaiveFee"
          [body]="'Waive Fee'"
          (action)="goToWaiveFeePage()"
          class="link">
        </tidy-text>
      </tidy-row>
    </tidy-card>

    <ng-container *ngIf="!isPaymentHistoryLoaded">
      <tidy-card>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <tidy-grid>
            <ion-row>
              <ion-col size="4">
                <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
              </ion-col>
              <ion-col size="1">
              </ion-col>
              <ion-col size="7" >
                <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
      </tidy-card>
    </ng-container>

    <ng-container *ngIf="isPaymentHistoryLoaded">
      <tidy-card>
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            [src]="'assets/svg/arrow-redo-circle.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Payment History'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngIf="cleaning?.job_delegation_request?.role !== 'delegatee'">
          <tidy-row class="extra-bottom-padding" *ngFor="let payment of paymentHistory?.payment_history"  >
            <tidy-text
              (action)="goToPaymentPage(payment?.transfer_id)"
              [body]="payment.description"
              class="link">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="paymentHistory?.payment_history.length === 0">
            <tidy-text [body]="'No payments for this job yet. '">
            </tidy-text>
            <tidy-text
              *ngIf="paymentHistory?.min_transfer_date !== null && paymentHistory?.min_transfer_date !== undefined"
              [body]="'Estimated to be included in the '">
            </tidy-text>
            <tidy-text
              *ngIf="paymentHistory?.min_transfer_date !== null && paymentHistory?.min_transfer_date !== undefined"
              [body]="paymentHistory?.min_transfer_date | tdate: 'M/DD/YY'">
            </tidy-text>
            <tidy-text
              *ngIf="paymentHistory?.min_transfer_date !== null && paymentHistory?.min_transfer_date !== undefined"
              [body]="' payment.'">
            </tidy-text>
          </tidy-row>
        </ng-container>
        <ng-container *ngIf="cleaning?.job_delegation_request?.role === 'delegatee'">
          <tidy-row>
            <tidy-text
              [body]="'All payment will be made to '">
            </tidy-text>
            <tidy-text
              [body]="cleaning?.job_delegation_request?.delegator?.name">
            </tidy-text>
            <tidy-text
              [body]="'. Contact them for details.'">
            </tidy-text>
          </tidy-row>
        </ng-container>
      </tidy-card>
    </ng-container>

    <ng-container *ngIf="!isJobMiliageLoaded">
      <tidy-card>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <tidy-grid>
            <ion-row>
              <ion-col size="4">
                <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
              </ion-col>
              <ion-col size="1">
              </ion-col>
              <ion-col size="7" >
                <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
      </tidy-card>
    </ng-container>

    <ng-container *ngIf="isJobMiliageLoaded">
      <tidy-card>
        <tidy-row [alignLastItemRight]="true" class="extra-bottom-padding">
          <tidy-image
            [src]="'assets/svg/car.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Mileage Tracking'">
          </tidy-text>
          <tidy-text
            (action)="editMileage()"
            [body]="'Edit'"
            class="link">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="distanceFormat === 'mi'">
          <tidy-text
          [body]="'Miles Travelled: '">
          </tidy-text>
          <tidy-text
            [body]="(jobMileage[0]?.estimated_mileage | number : '1.2-2') || '0'">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="distanceFormat === 'km'">
          <tidy-text
          [body]="'Kilometers Travelled: '">
          </tidy-text>
          <tidy-text
            [body]="((jobMileage[0]?.estimated_mileage * 1.60934) | number : '1.2-2') || '0'">
          </tidy-text>
        </tidy-row>
      </tidy-card>
    </ng-container>

    <ng-container *ngIf="!isMessagesLoaded">
      <tidy-card>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <tidy-grid>
            <ion-row>
              <ion-col size="4">
                <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
              </ion-col>
              <ion-col size="1">
              </ion-col>
              <ion-col size="7" >
                <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
      </tidy-card>
    </ng-container>

    <ng-container *ngIf="isMessagesLoaded">
      <tidy-card>
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            [src]="'assets/svg/chatbox-ellipses.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text [header]="'Messages'"> </tidy-text>
        </tidy-row>
        <tidy-row [alignLastItemRight]="true">
          <tidy-image [src]="'assets/img/account.svg'" class="title-size">
          </tidy-image>
          <tidy-text
            [header]="cleaning?.customer?.account_name !== '' && cleaning?.customer?.account_name ?
              cleaning?.customer?.account_name :
              (cleaning?.customer?.first_name + ' ' + (cleaning?.customer?.last_name | abbrLastName: true))">
          </tidy-text>
          <tidy-text
            *ngIf="!hasPassed14DaysFromJobStartDate()"
            [body]="'Call'"
            class="link"
            (action)="contactClient('call')">
          </tidy-text>
        </tidy-row>
        <ng-container
          *ngIf="!hasPassed14DaysFromJobStartDate(); else cantContactTemplate">
          <tidy-row class="field-top-padding extra-bottom-padding">
            <form [formGroup]="form" novalidate action="#">
              <tidy-textarea
                [label]="'Send Message'"
                formControlName="message"
                [form]="form.controls.message"
                [submitted]="submitted"
                [errorMessage]="'Please enter a message to send.'"
                [rightIconAction]="contactClient.bind(this, 'message')"
                [leftIconAction]="contactClient.bind(this, 'attachment')">
              </tidy-textarea>
            </form>
          </tidy-row>
        </ng-container>
        <ng-template #cantContactTemplate>
          <tidy-row [align]="'center'" class="extra-bottom-padding">
            <tidy-alert>
              <tidy-text
                [helper]="'You can\'t contact this client because it has been more than 14 days since the job has been completed.'">
              </tidy-text>
            </tidy-alert>
          </tidy-row>
        </ng-template>
        <tidy-row *ngIf="messages?.length == 0" [align]="'center'">
          <tidy-text
            [helper]="'No Messages'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let message of messages; let last = last; let index = index">
          <message
            *ngIf="index < 3"
            [message]="message"
            [last]="last">
          </message>
        </ng-container>
        <tidy-row [align]="'center'" *ngIf="messages?.length > 3">
          <tidy-text
            class="link"
            [actionBody]="'View '"
            (action)="goToJobMessagesPage()">
          </tidy-text>
          <tidy-text
            class="link"
            [actionBody]="(messages?.length - 3)"
            (action)="goToJobMessagesPage()">
          </tidy-text>
          <tidy-text
            class="link"
            [actionBody]="' More Message' + (messages?.length == 4 ? '' : 's')"
            (action)="goToJobMessagesPage()">
          </tidy-text>
        </tidy-row>
      </tidy-card>
    </ng-container>

    <ng-container *ngIf="!isUpdatesLoaded">
      <tidy-card>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <tidy-grid>
            <ion-row>
              <ion-col size="4">
                <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
              </ion-col>
              <ion-col size="1">
              </ion-col>
              <ion-col size="7" >
                <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
      </tidy-card>
    </ng-container>

    <ng-container
      *ngIf="beforeAfter === 'photo' || beforeAfter === 'video' || beforeAfter === 'before_photo_only' || beforeAfter === 'before_video_only'">
      <tidy-before-after
        [baState]="'before'"
        [page]="'MWToDosPage'"
        [jobIsCompleted]="true"
        [showDeleteButton]="false"
        [beforeAfterPhotos]="beforePhotos"
        [type]="beforeAfter.includes('photo') ? 'before_photos' : 'before_videos'"
        [uuid]="cleaning?.homekeeper_job_id">
      </tidy-before-after>
    </ng-container>

    <ng-container *ngIf="isUpdatesLoaded">
      <tidy-card>
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            [src]="'assets/svg/chatbox-ellipses.svg'"
            class="header-size" >
          </tidy-image>
          <tidy-text
            [header]="'Cleaning Updates'">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="updates?.length == 0" [align]="'center'">
          <tidy-text
            [helper]="'No Updates'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let update of updates; let last = last">
          <tidy-row [ngClass]="last ? '' : 'extra-bottom-padding'">
            <tidy-text
              [helper]="update.hk_date + ' ' + update.hk_time | tdate:'MM/DD/YYYY, h:mm a':'YYYY-MM-DD HH:mm'">
            </tidy-text><br>
            <tidy-text
              [body]="update.description">
            </tidy-text>
          </tidy-row>
        </ng-container>
      </tidy-card>
    </ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container
      *ngIf="beforeAfter === 'photo' || beforeAfter === 'video' || beforeAfter === 'after_photo_only' || beforeAfter === 'after_video_only'">
      <tidy-before-after
        [baState]="'after'"
        [page]="'MWToDosPage'"
        [jobIsCompleted]="true"
        [showDeleteButton]="false"
        [beforeAfterPhotos]="afterPhotos"
        [type]="beforeAfter.includes('photo') ? 'after_photos' : 'after_videos'"
        [uuid]="cleaning?.homekeeper_job_id">
      </tidy-before-after>
    </ng-container>

    <tidy-alert *ngIf="cleaning?.blacklisted_by_homekeeper">
      <tidy-row class="vertical-align-center">
       <tidy-image
          [src]="'assets/svg/close-circle.svg'"
          class="header-size">
        </tidy-image>
        <tidy-text
          [header]="'Client Blacklisted'">
        </tidy-text><br>
        <tidy-text
          *ngIf="cleaning?.customer?.account_name !== '' && cleaning?.customer?.account_name"
          [body]="'You will no longer be paired with '">
        </tidy-text>
        <tidy-text
          *ngIf="cleaning?.customer?.account_name !== '' && cleaning?.customer?.account_name"
          [body]="cleaning?.customer?.account_name">
        </tidy-text>
        <tidy-text
          *ngIf="!(cleaning?.customer?.account_name !== '' && cleaning?.customer?.account_name)"
          [body]="'You will no longer be paired with '">
        </tidy-text>
        <tidy-text
          *ngIf="!(cleaning?.customer?.account_name !== '' && cleaning?.customer?.account_name)"
          [body]="cleaning?.customer?.first_name + ' ' + (cleaning?.customer?.last_name | abbrLastName: true)">
        </tidy-text>
      </tidy-row>
    </tidy-alert>

    <tidy-button
      *ngIf="reportedIssues?.length === 0"
      [text]="'Log Issue'"
      [action]="goToReportIssue.bind(this)"
      class="secondary">
    </tidy-button>

    <tidy-button
      [text]="'Missing an Item?'"
      [action]="lostItem.bind(this)"
       *ngIf="showLostItem"
      class="secondary">
    </tidy-button>

    <tidy-button
      *ngIf="reimbursementRequest?.status !== 'pending' && showParkingReimbursement"
      [text]="'Request Parking Reimbursement'"
      [action]="parkingReimbursement.bind(this)"
      class="secondary">
    </tidy-button>

    <tidy-button
      *ngIf="!cleaning?.blacklisted_by_homekeeper"
      [text]="'Blacklist this Client'"
      [action]="blacklistClient.bind(this)"
      class="secondary">
    </tidy-button>

  </tidy-wrapper>

  <tidy-wrapper *ngIf="!loaded">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card>
      <tidy-row class="extra-bottom-padding">
        <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
      </tidy-row>
      <tidy-row>
        <tidy-grid>
          <ion-row>
            <ion-col size="4">
              <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
              <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
            </ion-col>
            <ion-col size="1">
            </ion-col>
            <ion-col size="7" >
              <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
              <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
            </ion-col>
          </ion-row>
        </tidy-grid>
      </tidy-row>
    </tidy-card>

    <tidy-card>
      <tidy-row class="extra-bottom-padding">
        <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
      </tidy-row>
      <tidy-row>
        <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
      </tidy-row>
      <tidy-row>
      <tidy-text class="divider">
      </tidy-text>
      <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
      </tidy-row>
    </tidy-card>

    <tidy-card>
      <tidy-row class="extra-bottom-padding">
        <ion-skeleton-text animated style="width: 45%"></ion-skeleton-text>
      </tidy-row>
      <tidy-row>
        <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
      </tidy-row>
    </tidy-card>

    <tidy-card>
      <tidy-row class="extra-bottom-padding">
        <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
      </tidy-row>
      <tidy-row class="extra-bottom-padding">
        <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
      </tidy-row>
      <tidy-row>
        <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        <ion-skeleton-text animated style="width: 35%"></ion-skeleton-text>
      </tidy-row>
    </tidy-card>

    <tidy-row>
      <ion-skeleton-text animated style="height: 45px"></ion-skeleton-text>
    </tidy-row>

    <tidy-row>
      <ion-skeleton-text animated style="height: 45px"></ion-skeleton-text>
    </tidy-row>

    <tidy-row>
      <ion-skeleton-text animated style="height: 45px"></ion-skeleton-text>
    </tidy-row>

    <tidy-row>
      <ion-skeleton-text animated style="height: 45px"></ion-skeleton-text>
    </tidy-row>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
