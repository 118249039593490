import { Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { AppConfig } from 'src/shared/providers/config';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { MWStore } from 'src/main-workflow/mw.store';
import { ReportIssue } from 'src/providers/report-issue/report-issue';
import { SharedLinksProvider } from 'src/providers/shared-links/shared-links';
import { TidyStorage } from "src/shared/providers/tidy-storage";

import { Loading } from 'src/shared/components/loading/loading';

@Component({
  templateUrl: 'report-issue.html',
  encapsulation: ViewEncapsulation.None
})

export class ReportIssuePage {

  attachments: any = [];
  errorMessage: string;
  form: UntypedFormGroup;
  submitted: boolean;
  title: string;
  type: any;

  constructor(
    private fb: UntypedFormBuilder,
    private mwStore: MWStore,
    private navCtrl: CustomNavController,
    private reportIssue: ReportIssue,
    private sharedLinks: SharedLinksProvider,
    private store: TidyStorage
  ) {
    this.form = this.fb.group({
      description: ['', Validators.required]
    });
  }

  ionViewDidEnter() {
    const type = this.navCtrl.getParam('type');
    if (type) {
      this.selectIssue(type);
    }
  }

  getTitle() {
    const titles = {
      'damage': 'Report Damage',
      'low_inventory': 'Report Low Inventory',
      'pest_report': 'Pest Report',
      'utility_issue': 'Utility Issue',
      'other': 'Other'
    }
    return titles[this.type];
  }

  async send() {
    this.errorMessage = '';
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    try {
      const flow = this.navCtrl.getParam('flow');
      const isMainWorkflow = this.navCtrl.getParam('isMainWorkflow');
      if (flow == 'sharedJob' || flow == 'sharedList') {
        let payload = this.navCtrl.getParam('payload');
        payload.action_params['issue_report_type_id'] = this.getReportIssueId();
        payload.action_params['description'] = this.form.value.description;
        payload.action_params['issue_report_photos'] = this.attachments
        const uuid = this.navCtrl.getParam('uuid');
        if (flow == 'sharedJob') {
          await this.sharedLinks.updateSharedLink(uuid, payload);
          let storedIssues = await this.store.retrieve(`sharedJobIssueReport/${uuid}`) || [];
          storedIssues.push({
            type: this.type,
            title: this.title,
            description: this.form.value.description
          });
          await this.store.save(`sharedJobIssueReport/${uuid}`, storedIssues);
        } else if (flow == 'sharedList') {
          let storedIssues = await this.store.retrieve(`sharedListIssueReport/${uuid}`) || [];
          storedIssues.push({
            payload: payload,
            title: this.title,
            type: this.type,
            description: this.form.value.description
          });
          await this.store.save(`sharedListIssueReport/${uuid}`, storedIssues);
        }
      } else {
        if (isMainWorkflow) {
          let storedIssues = await this.mwStore.getStoredIssues() || [];
          storedIssues.push({
            title: this.title,
            type: this.type,
            description: this.form.value.description
          });
          await this.mwStore.setStoredIssues(storedIssues);
        }
        if (flow == 'privateJob') {
          const jobId = this.navCtrl.getParam('jobId');
          let storedIssues = await this.store.retrieve(`privateJobIssueReports/${jobId}`) || [];
          storedIssues.push({
            title: this.title,
            type: this.type,
            description: this.form.value.description
          });
          await this.store.save(`privateJobIssueReports/${jobId}`, storedIssues);
        }
        const payload = {
          address_id: this.navCtrl.getParam('addressId'),
          homekeeper_job_id: this.navCtrl.getParam('homekeeperJobId'),
          issue_report_type_id: this.getReportIssueId(),
          description: this.form.value.description,
          issue_report_photos: this.attachments
        };
        await this.reportIssue.reportIssue(payload);
      }
      this.goToSuccessPage(flow);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  goToSuccessPage(type) {
    const pathAfterReportIssue = localStorage.getItem('pathAfterReportIssue');
    const params = {
      header: 'Issue Logged',
      body: type == 'sharedList' ? 'We will notify the client of the issue after you complete the To-Do list.' : 'We will notify the client of the issue.',
      buttonText: 'Ok',
      buttonRoute: pathAfterReportIssue
    };
    this.navCtrl.navigateForward('success', params);
  }

  @Loading()
  async addAttachment() {
    this.errorMessage = '';
    try {
      const attachment = await this.reportIssue.addAttachment();
      if (attachment.filename !== '') {
        this.attachments.push({
          'photo_url': attachment.fileKey
        });
      } else {
        this.errorMessage = 'Unable to attach photo. Please upload a PNG or JPEG file.';
      }
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  removeAttachment(attachIndex: number) {
    this.attachments.splice(attachIndex, 1);
  }

  getReportIssueId() {
    const types = {
      'https://api-sandbox3.tidy.com/': {
        'damage': 2,
        'low_inventory': 3,
        'pest_report': 67,
        'utility_issue': 100,
        'other': 34
      },
      'https://api-staging.tidy.com/': {
        'damage': 1,
        'low_inventory': 2,
        'pest_report': 34,
        'utility_issue': 67,
        'other': 3
      },
      'https://api-production.gotidy.com/': {
        'damage': 1,
        'low_inventory': 2,
        'pest_report': 68,
        'utility_issue': 101,
        'other': 35
      }
    }
    return types[AppConfig.API][this.type];
  }

  selectIssue(type) {
    const jobId = this.navCtrl.getParam('jobId');
    const isPrivateJob: boolean = jobId;
    if (type == 'other' && isPrivateJob) {
      const params = this.mountSuccessPageParams(jobId);
      return this.navCtrl.navigateForward('success', params);
    }
    this.type = type;
    this.title = this.getTitle();
  }

  mountSuccessPageParams(jobId) {
    return {
      header: 'Please Contact the Client',
      body: 'For other issues it is best to contact the client directly.',
      buttonText: `Ok`,
      buttonRoute: `private-job-messages/${jobId}`
    };
  }

}
