import { Injectable } from '@angular/core';
import { HttpClient as Http, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { timeout } from 'rxjs/operators';

import { AppConfig } from 'src/shared/providers/config';
import { AppState } from 'src/shared/providers/http/app-state';
import { DeviceInfoProvider } from 'src/shared/providers/device-info';
import { Events } from 'src/providers/events/events';
import { Logger } from 'src/shared/providers/logger';
import { RequestCache } from 'src/shared/providers/http/request-cache';

import { RequestModel } from 'src/shared/models/request.model';

import { DeviceInfo } from '@capacitor/device';

@Injectable()
export class HttpClient {
  constructor(
    public appState: AppState,
    public cache: RequestCache,
    public deviceInfo: DeviceInfoProvider,
    public events: Events,
    public http: Http,
    public logger: Logger
  ) {
  }

  async get(url): Promise<any> {
    if (!await this.appState.isOnline()) {
      return this.getFromCache(url);
    }

    const result = await this.request({method: 'GET', url});
    await this.cache.store(url, result);
    return result;
  }

  async getFromCache(url) {
    const storedData = await this.cache.retrieve(url);

    if (!storedData) {
      // TODO LOGGER
      // this.logger.log({ type: 'error', message: 'Data not available offline.', url });
      throw new Error('Something went wrong.');
    }

    return storedData;
  }

  async getNoCache(url) {
    const result = await this.request({method: 'GET', url});
    await this.cache.store(url, result);
    return result;
  }

  post = (url, payload) => {
    return this.request({ method: 'POST', url, payload })
  };

  put = (url, payload) => {
    return this.request({ method: 'PUT', url, payload })
  };

  delete = (url) => {
    return this.request({method: 'DELETE', url})
  };

  async getWithParams(url, params) {
    const isExternalUrl = this.isExternalUrl(url);
    const formattedUrl = isExternalUrl ? url : `${AppConfig.API}${url}`;
    return this.http.get(formattedUrl, { headers: await this.header(isExternalUrl), params }).toPromise();
  }

  async request(request: RequestModel): Promise<any> {

    const isExternalUrl = this.isExternalUrl(request.url);
    const formattedUrl = isExternalUrl ? request.url : `${AppConfig.API}${request.url}`
    const startTime = Date.now();
    return this.http
      .request(request.method, formattedUrl, await this.payload(request.payload, request.method, isExternalUrl))
      .pipe(
        timeout(request.timeout || AppConfig.TIMEOUT_THRESHOLD)
      ).toPromise()
      .catch( error => this.onError(error, formattedUrl, request.payload) )
      .finally(() => {
        const endTime = Date.now();
        this.logDuration(startTime, endTime, formattedUrl, request?.payload);
      });
  }

  async header(isExternalUrl: boolean): Promise<HttpHeaders> {
    const headers = {
      Authorization: `${localStorage.getItem('tidy_token_type')} ${localStorage.getItem('tidy_token')}`,
      'Content-Type': 'application/json'
    };

    if (!isExternalUrl) {
      const deviceInfo: DeviceInfo = await this.deviceInfo.getDeviceInfo();
      const deviceUUID: string = await this.deviceInfo.uuid();
      const appVersion: string = await this.deviceInfo.getAppVersion();

      headers['X-App-Version'] = AppConfig.VERSION;
      headers['X-App-Name'] = 'business-manager';
      headers['X-App-Device-UUID'] = deviceUUID;
      headers['X-App-Build'] = appVersion || AppConfig.VERSION;
      headers['X-App-Device-OS-Name'] = deviceInfo.operatingSystem;
      headers['X-App-Device-OS-Version'] = deviceInfo.osVersion;
      headers['X-App-Device-Model'] = deviceInfo.model;
    }
    const httpHeaders = new HttpHeaders(headers);
    return httpHeaders;
  }

  async payload(data: any, method: string, isExternalUrl: boolean): Promise<any> {
    return {
      headers: await this.header(isExternalUrl),
      body: JSON.stringify(data),
      method
    };
  }

  async onError(error, url, data): Promise<any> {
    let parsedError = error;
    if(error instanceof HttpErrorResponse) {
      parsedError = error?.error;
    }

    if (parsedError && error instanceof HttpErrorResponse) {
      const apiError = parsedError;
      parsedError = apiError.error ? apiError.error : apiError;
    }

    if (error instanceof HttpErrorResponse && this.isUnauthorized(parsedError)) {
      this.events.publish('auth:logout');
    }

    this.logger.requestError(parsedError, url, data);
    return Promise.reject(parsedError);
  }

  isExternalUrl(url): boolean {
    return (url.indexOf('https://') === 0);
  }

  isUnauthorized({ code, message }): boolean {
    return code == 401
      || message === 'Authentication failled for this homekeeper'
      || message === 'Authentication failed for this homekeeper'
      || message === 'The access token is invalid';
  }

  isTimeout({ message }) {
    return message.indexOf('Timeout') !== -1;
  }

  logDuration(startTime, endTime, url, payload) {
    const duration = endTime - startTime;
    if (duration >= AppConfig.REQUEST_LOG_THRESHOLD) {
      this.logger.log({
        message: 'Request duration',
        duration,
        url,
        payload
      })
    }
  }
}
