<tidy-card class="calendar-card">
  <div class="calendar-container">
    <div class="calendar-header">
      <tidy-text
        class="month-title"
        [header]="(currentMonth | titlecase) + ' ' + currentYear">
      </tidy-text>

      <div class="calendar-navigation">
        <button class="arrow-icon me-3" (click)="previousMonth()">
          <tidy-image
            [src]="'../../../assets/icon/right-arrow-white.png'"
            class="reverse-arrow"></tidy-image>
        </button>
        <button class="arrow-icon" (click)="nextMonth()">
          <tidy-image
            [src]="'../../../assets/icon/right-arrow-white.png'"></tidy-image>
        </button>
      </div>
    </div>

    <div class="week-days-row">
      <div>
        <tidy-text [header]="('Sunday' | translate) | slice:0:3"></tidy-text>
      </div>
      <div>
        <tidy-text [header]="'Monday' | translate | slice:0:3"></tidy-text>
      </div>
      <div>
        <tidy-text [header]="'Tuesday' | translate | slice:0:3"></tidy-text>
      </div>
      <div>
        <tidy-text [header]="'Wednesday' | translate | slice:0:3"></tidy-text>
      </div>
      <div>
        <tidy-text [header]="'Thursday' | translate | slice:0:3"></tidy-text>
      </div>
      <div>
        <tidy-text [header]="'Friday' | translate | slice:0:3"></tidy-text>
      </div>
      <div>
        <tidy-text [header]="'Saturday' | translate | slice:0:3"></tidy-text>
      </div>
    </div>
  </div>

  <div
    *ngFor="let week of calendarMatrix; let i = index"
    class="week-row"
    [class.selected-week]="isSelectedWeek(i)"
    (click)="selectWeek(i)">
    <div class="week-background"></div>
    <div class="week-days">
      <ng-container *ngFor="let day of week">
        <div (click)="selectDay(day)">
          <ng-container
            *ngIf="day?.day === todayDay && isInitialMonth && !day?.disabled">
            <div class="today-circle"></div>
          </ng-container>
          <div
            [ngClass]="{
              disabled: day?.disabled,
              'center-content': day?.event,
              today: day?.day === todayDay && isInitialMonth && !day?.disabled
            }"
            [matTooltip]="
              day.jobQuantity > 0 ? (day.jobQuantity + ' jobs' | translate) : ('No jobs' | translate)
            ">
            <tidy-text
              [body]="day?.day"
              class="week-day"
              [class.selected-day]="
                day.day === selectedDay && isSelectedWeek(i)
              ">
            </tidy-text>
          </div>
          <div *ngIf="day.jobQuantityToDisplay">
            <ng-container
              *ngFor="let dot of getArray(day.jobQuantityToDisplay)">
              <span class="dot"></span>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</tidy-card>
