import { Component, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Loading } from 'src/shared/components/loading/loading';
import { MultipleCountryService } from 'src/providers/addresses/multiple-country.service';
import { PrivateClient } from 'src/providers/private-client/private-client';
import { Proposals } from 'src/providers/proposals/proposals';
import { Team } from 'src/providers/team/team';
import { Me } from 'src/shared/providers/me';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';
import { removeCurrencyMask } from 'src/shared/utils/currency-utils';
import validationUtils from 'src/shared/utils/validation-utils';
import { keepOnlyText } from 'src/shared/utils/text-utils';

import { AutocompleteModel } from '@tidyapp/tidy-ui-components';
import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';

import { AddressMetadata } from 'src/shared/components/autocomplete-address/autocomplete-address';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

@Component({
  templateUrl: 'send-proposal.html'
})

export class SendProposalPage extends Timeout {

  addressName: string;
  addingNewClient = false;
  addressItems: TidySelectNumberValueModel[];
  addresses: any;
  countryCode: string;
  clientName: string;
  clients: any;
  clientIsMissingEmail = false;
  clientId: string;
  clientSelected = false;
  clientItems: AutocompleteModel[];
  errorMessage: string;
  form: UntypedFormGroup;
  invalidAddressError: string;
  noAddressPickedTemplate: ElementRef;
  serviceName: string;
  serviceItems: TidySelectNumberValueModel[];
  selectedClient: AutocompleteModel;
  selectedService = false;
  submitted: boolean;
  teamServices: any;
  zipCodeMask = {
    mask: '00000'
  };

  constructor(
    private fb: UntypedFormBuilder,
    private iabUtils: InAppBrowserUtils,
    private me: Me,
    private multipleCountryService: MultipleCountryService,
    private navCtrl: CustomNavController,
    private proposals: Proposals,
    private privateClient: PrivateClient,
    public route: ActivatedRoute,
    private team: Team,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.fb.group({
      client: ['', Validators.required],
      clientOptions: ['existing'],
      service: ['', Validators.required],
      currentClientAddress: ['', Validators.required],
      address: [''],
      phone: [null],
      price: ['', Validators.required],
      description: ['', Validators.required],
      firstName: [''],
      lastName: [''],
      email: [''],
      unit: [''],
      zip: [''],
      latitude: '',
      longitude: '',
      city: [''],
      state: ['']
    });
  }

  async ionViewDidEnter() {
    try {
      this.loaded = false;
      this.teamServices = await this.team.getTeamServices();
      this.clients = await this.privateClient.getPrivateClients();
      this.clientItems = this.clients.map(client => ({ display: (client.account_name && client.account_name !== '' ? client.account_name : client.first_name), value: `${client.id}` }));
      this.clientId = this.route.snapshot.paramMap.get('clientId');
      if (this.clientId) {
        this.selectedClient = this.clientItems.find(client => client.value === this.clientId);
        await this.selectClient(this.selectedClient);
      }
      this.serviceItems = this.parseServiceItems();
      this.form.patchValue({ currentClientAddress: 0 });
      if (!this.clientItems.length) {
        this.form.patchValue({ clientOptions: 'new' });
        this.addNewClient();
      }
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  @Loading()
  async selectClient(item) {
    this.clientSelected = false;
    const clientResponse = this.clients.find(client => item.value == client.id);
    this.clientName = clientResponse.account_name && clientResponse.account_name !== '' ? clientResponse.account_name : clientResponse.first_name + ' ' + clientResponse.last_name;
    if (clientResponse.email === '') {
      this.clientIsMissingEmail = true;
      this.form.controls.email.setValidators([Validators.required, validationUtils.validateEmail]);
    } else {
      this.clientIsMissingEmail = false;
      this.form.get('email').clearValidators();
      this.form.get('email').updateValueAndValidity();
    }
    this.addresses = await this.privateClient.getPrivateClientAddress(item.value);
    this.addressItems = this.addresses.map((address) => ({ viewValue: address.address, value: address.id }));
    this.addressItems.unshift({ viewValue: 'All Addresses', value: 0 });
    if (this.addressItems.length <= 2) {
      this.addressName = this.addressItems[0].viewValue;
    } else {
      this.addressName = 'All Addresses'
    }
    this.clientSelected = true;
  }

  selectAddress(selection) {
    if (selection == 0) {
      this.addressName = 'Valid at all addresses'
    } else {
      const addressResponse = this.addresses.find(address => selection == address.id);
      this.addressName = addressResponse.address;
    }
  }

  selectService(serviceId) {
    this.selectedService = true;
    const service = this.teamServices.find(service => service.id === serviceId);
    this.serviceName = service.name;
    this.form.patchValue({
      description: service.description,
      price: (service.user_new_rate / 100).toString()
    });
  }

  parseServiceItems() {
    const services = this.teamServices.map(service => ({
      viewValue: service.name,
      value: service.id
      }
    ));
    return services;
  }

  async sendProposal() {
    this.errorMessage = '';
    this.submitted = true;
    try {
      if (!this.clientSelected) {
        this.form.controls.client.setErrors({'incorrect': true});
      }
      if (!this.form.valid || !this.clientSelected) {
        return;
      }
      let params = {
        addressName: (this.addressName || 'All Addresses'),
        clientName: (this.clientName || this.form.value.firstName),
        serviceName: this.serviceName,
        proposalPayload: {
          team_service_id: this.form.value.service,
          description: this.form.value.description,
          price: removeCurrencyMask(this.form.value.price),
          frequency_option: 'one_time_or_recurring',
          customer_id: this.form.value.client
        }
      }
      if (this.form.value.currentClientAddress !== 0) {
        params.proposalPayload['address_id'] = this.form.value.currentClientAddress;
      }
      if (this.addingNewClient) {
        if (this.invalidAddressError) {
          return this.errorMessage = this.invalidAddressError;
        }
        const payload = this.buildNewClientPayload();
        const client = await this.privateClient.addPrivateClient(payload);
        params.proposalPayload.customer_id = client.id;
        this.navCtrl.navigateForward('confirm-send-proposal', params);
        this.addingNewClient = false;
        this.form.patchValue({clientOptions: 'existing'});
      }
      if (this.addresses.length == 0 && this.form.value.address) {
        if (this.invalidAddressError) {
          return this.errorMessage = this.invalidAddressError;
        }
        const payload = this.buildNewAddressPayload();
        await this.privateClient.addPrivateAddress(payload);
      }
      if (this.clientIsMissingEmail) {
        params['clientUpdatePayload'] = {
          email: this.form.value.email
        };
      }
      this.navCtrl.navigateForward('confirm-send-proposal', params);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  buildNewAddressPayload() {
    return {
      address: this.buildAddressPayload(),
      customer_id: this.form.value.client,
      latitude: this.form.value.latitude,
      longitude: this.form.value.longitude
    };
  }

  buildNewClientPayload() {
    let payload = {
      customer: {
        first_name: this.form.value.firstName,
        last_name: this.form.value.lastName,
        email: this.form.value.email
      },
      address: this.buildAddressPayload()
    }
    if (this.form.value.phone_number) {
      payload.customer['phone_number'] = this.form.value.phone;
    }
    return payload;
  }

  buildAddressPayload() {
    return {
      address: this.form.value.address,
      zipcode: keepOnlyText(this.form.value.zip),
      unit: this.form.value.unit,
      latitude: this.form.value.latitude,
      longitude: this.form.value.longitude,
      country_code: this.countryCode,
      city: this.form.value.city,
      address_state: this.form.value.state
    }
  }

  changeClientOption(option) {
    if (option === 'new') {
      this.addNewClient();
    } else {
      this.stopAddNewClient();
    }
  }

  addNewClient() {
    this.clientSelected = true;
    this.form.controls.email.setValidators([validationUtils.validateEmail]);
    this.form.get('client').clearValidators();
    this.form.get('client').updateValueAndValidity();
    this.form.controls.firstName.setValidators([Validators.required]);
    this.form.controls.lastName.setValidators([Validators.required]);
    this.form.controls.email.setValidators([Validators.required, validationUtils.validateEmail]);
    this.addingNewClient = true;
  }

  stopAddNewClient() {
    this.clientSelected = false;
    const fields = ['email', 'firstName', 'lastName'];
    fields.forEach(field => {
      this.form.get(field).clearValidators();
      this.form.get(field).updateValueAndValidity();
    });
    this.form.controls.client.setValidators([Validators.required]);
    this.addingNewClient = false;
  }

  learnMore() {
    this.iabUtils.openUrl('https://help.tidy.com/pros/proposals');
  }

  async populateForm() {
    const me = await this.me.fetchWithoutCache();
    this.countryCode = me.profile.address.country_code;
    this.setValidators(this.countryCode);
  }

  setValidators(proCountryCode: string) {
    const validatorObject = this.multipleCountryService.getCountrySettings(proCountryCode);
    this.zipCodeMask = validatorObject.mask;
  }

  choseItem(addressMetadata: AddressMetadata) {
    this.form.patchValue({ zip: addressMetadata.zipcode, ...addressMetadata });
    this.errorMessage = '';
  }
}
