<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="false"
    [title]="'More'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [hasBottomNav]="true">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card>
      <tidy-card-button (action)="goTo('search')">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/search-circle.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Search'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

    <tidy-card data-cy="card-my-business">
      <tidy-row class="extra-bottom-padding vertical-align-center">
        <tidy-image
          [src]="'assets/svg/briefcase.svg'"
          class="header-size">
        </tidy-image>
        <tidy-text
          [header]="isLimitedAccount ? 'My Settings' : 'My Business'">
        </tidy-text>
      </tidy-row>
      <tidy-card-button (action)="goTo('bookable-hours')" data-cy="btn-my-schedule" *ngIf="!isLimitedAccount">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/calendar-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Bookable Hours'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="goTo('website')" data-cy="btn-website" *ngIf="!isLimitedAccount">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/globe-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'My Website'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="goTo('team')" data-cy="btn-website">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/people-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Teams'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="goTo('profile/contact')" data-cy="btn-contact-info">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/chatbox-ellipses-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Contact Info'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="goTo('profile/location')" data-cy="btn-location-i-serve" *ngIf="!isLimitedAccount">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/location-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Locations Served'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="goTo('services')" data-cy="btn-rates-fees" *ngIf="!isLimitedAccount">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/pricetags-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Services'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="goTo('active-in-community')" data-cy="btn-active-community" *ngIf="!isLimitedAccount">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/business-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Compliance'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

    <tidy-card data-cy="card-payments" *ngIf="!isLimitedAccount">
      <tidy-row class="extra-bottom-padding vertical-align-center">
        <tidy-image
          [src]="'assets/svg/card.svg'"
          class="header-size">
        </tidy-image>
        <tidy-text
          [header]="'Payments'">
        </tidy-text>
      </tidy-row>
      <tidy-card-button (action)="goToPayouts()" data-cy="btn-pending-payments">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/reload-circle-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Payouts'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="goToPayoutSettingsPage()" data-cy="btn-payment-settings">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/settings-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Payout Settings'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="goTo('client-payment-settings')">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/card-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Client Payment Settings'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="goTo('past-weekly-job-history')">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/icon/history.png'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Past Weekly Job History'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

    <tidy-card data-cy="card-clients" *ngIf="!isLimitedAccount">
      <tidy-row class="extra-bottom-padding vertical-align-center">
        <tidy-image
          [src]="'assets/svg/people.svg'"
          class="header-size">
        </tidy-image>
        <tidy-text
          [header]="'Clients'">
        </tidy-text>
      </tidy-row>
      <tidy-card-button (action)="goTo('clients')" data-cy="btn-client-list">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/list-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Client List'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="goTo('reviews')" data-cy="btn-reviews">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/chatbubbles-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Reviews'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="goTo('feedback')" data-cy="btn-scores">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/bar-chart-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Scores'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

    <tidy-card *ngIf="!isLimitedAccount">
      <tidy-card-button (action)="goTo('proposals')">
        <tidy-row>
          <tidy-image
            [src]="'assets/img/proposals-black.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [header]="'Proposals'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

    <tidy-card *ngIf="!isLimitedAccount">
      <tidy-card-button (action)="goTo('unpaid-jobs')">
        <tidy-row>
          <tidy-image
            [src]="'assets/svg/documents.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [header]="'Invoices'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

    <tidy-card *ngIf="!isLimitedAccount">
      <tidy-card-button (action)="goToQuests()">
        <tidy-row class="vertical-align-center">
         <tidy-image
            src="assets/img/quest-mountain-fill.svg"
            class="body-size">
          </tidy-image>
          <tidy-text
            [header]="'Quests'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

    <tidy-card data-cy="card-clients" *ngIf="!isLimitedAccount">
      <tidy-row class="extra-bottom-padding vertical-align-center">
        <tidy-image
          [src]="'assets/svg/analytics.svg'"
          class="header-size">
        </tidy-image>
        <tidy-text
          [header]="'Reports'">
        </tidy-text>
      </tidy-row>
      <tidy-card-button (action)="goToMileageTracking()">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/car-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Mileage Tracking'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="goToUpcomingFeaturePage('tax_center')">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/cash-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Tax Center'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="goToUpcomingFeaturePage('accounting')">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/documents-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Accounting'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="goToUpcomingFeaturePage('equipment')">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/briefcase-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Equipment Tracking'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

    <tidy-card data-cy="card-clients" *ngIf="!isLimitedAccount">
      <tidy-row class="extra-bottom-padding vertical-align-center">
        <tidy-image
          [src]="'assets/svg/bar-chart.svg'"
          class="header-size">
        </tidy-image>
        <tidy-text
          [header]="'Marketing'">
        </tidy-text>
      </tidy-row>
      <tidy-card-button (action)="goToUpcomingFeaturePage('email_marketing')">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/mail-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Email Marketing'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="goToUpcomingFeaturePage('postcard_marketing')">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/newspaper-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Postcard Marketing'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="goToUpcomingFeaturePage('local_marketing')">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/navigate-circle-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Local Service Ads'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

    <tidy-card>
      <tidy-card-button (action)="goTo('notification-settings')">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/notifications.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Notification Settings'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

    <tidy-card data-cy="card-taxes" *ngIf="!isLimitedAccount">
      <tidy-card-button (action)="goTo('clients-from-tidy-settings')" data-cy="btn-taxes">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/settings.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Clients from TIDY Settings'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

    <tidy-card data-cy="card-taxes">
      <tidy-card-button (action)="goTo('agreement-management')" data-cy="btn-taxes">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/img/terms.png'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Agreement Management'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

    <tidy-card>
      <tidy-card-button (action)="goTo('edit-language')">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/earth-outline.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Display Language'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

    <tidy-card>
      <tidy-card-button (action)="goTo('distance-format')">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/icon/speedometer.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Distance Format'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

    <tidy-card data-cy="card-help">
      <tidy-row class="extra-bottom-padding vertical-align-center">
        <tidy-image
          [src]="'assets/svg/help-circle.svg'"
          class="header-size">
        </tidy-image>
        <tidy-text
          [header]="'Help'">
        </tidy-text>
      </tidy-row>
      <tidy-card-button (action)="goToDocumentation()" data-cy="btn-documentation">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/information-circle-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Documentation'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="goTo('concierge')" data-cy="btn-contact-support">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/mail-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Contact Support'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="goToMyAccount()">
        <tidy-row class="vertical-align-center">
          <tidy-image
             [src]="'assets/svg/person-outline.svg'"
             class="body-size">
           </tidy-image>
          <tidy-text
            [body]="'My Account'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

    <tidy-row [alignLastItemRight]="true">
      <tidy-text
        [body]="'Sign Out'"
        (action)="signOut()"
        data-cy="btn-signOut"
        class="link">
      </tidy-text>
    </tidy-row>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

<tidy-bottom-nav activeRoute="/more">
</tidy-bottom-nav>
