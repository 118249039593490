import { Component } from '@angular/core';

import { Certification } from 'src/providers/certification/certification';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { StaticData } from 'src/shared/providers/static-data';

import { SelectModel } from '@tidyapp/tidy-ui-components';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

import { ScheduleModel } from 'src/shared/models/certification.model';

import { SuccessPageParamsModel } from 'src/pages/success/success';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MySchedule } from 'src/providers/my-schedule/my-schedule';
import { TidyNumberToWeekDayPipe } from 'src/shared/pipes/tidy-number-to-weekday.pipe';



@Component({
  selector: 'make-bookable',
  templateUrl: 'make-bookable.html',
})
export class OnboardingMakeBookablePage {

  weekSchedule: ScheduleModel[];
  checkboxItems: SelectModel;
  errorMessage: string;
  submitted: boolean;
  loaded = false;

  timeRangeForms: Array<Array<UntypedFormGroup>> = Array(7).fill(null).map(() => []);
  weekDayCheckBox: Array<UntypedFormGroup>;
  timeOpts = [];

  constructor(
    private certification: Certification,
    private customNavCtrl: CustomNavController,
    private formBuilder: UntypedFormBuilder,
    private iabUtils: InAppBrowserUtils,
    private mySchedule: MySchedule,
    private staticData: StaticData
  ) {
    this.mountPageData();
  }

  async ionViewDidEnter() {
    const params = this.customNavCtrl.getParams(false);
    if (params.weekSchedule) {
      this.weekSchedule = params.weekSchedule;
    }
    this.loaded = true;
  }

  mountPageData() {
    this.weekSchedule = this.staticData.buildWeekDays();
    this.timeOpts = this.staticData.timeRanges();
    this.weekDayCheckBox = Array(7).fill(null).map(() => this.formBuilder.group({
      checked: false
    }));
  }

  async saveSchedule() {
    this.errorMessage = '';
    this.submitted = true;

    if(!this.validateForm()) {
      return;
    }

    const weekDayPipe = new TidyNumberToWeekDayPipe();
    const data = this.timeRangeForms.map((day, index) => {
      return {
        day: weekDayPipe.transform(index),
        time_blocks: day.map(timeBlock => timeBlock.value)
      };
    });

    const hasBookableTimes = this.checkIfHasBookableTimes(data);
    if (!hasBookableTimes) {
      return this.errorMessage = 'You must add available times to save your schedule.'
    }

    try {
      await this.certification.finishStep('bookable_times', { homekeeper_schedule_week: data });
      const params = this.mountSuccessPageParams();
      this.customNavCtrl.navigateForward('success', params);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  validateForm(): boolean {
    return this.timeRangeForms.every(dayFormArray => {
      return dayFormArray.every(timeRangeFormGroup => {
        return timeRangeFormGroup.valid;
      });
    });
  }

  checkIfHasBookableTimes(data): boolean {
    let hasBookableTimes = false;
    data.find((day) => {
      if (day.time_blocks.length > 0 ) {
        hasBookableTimes = true;
      }
    })
    return hasBookableTimes;
  }

  mountSuccessPageParams(): SuccessPageParamsModel {
    return {
      header: 'Availability Set!',
      body: 'You added your availability.',
      buttonText: 'Ok',
      buttonRoute: 'new-clients'
    };
  }

  learnMore() {
    const url = 'https://help.tidy.com/pros/set-service-area-max-drive-time';
    this.iabUtils.openUrl(url);
  }

  addNewTimeRange(weekDay: number, index: number = 0) {
    if(!this.timeRangeForms[weekDay]) {
      this.timeRangeForms[weekDay] = [];
    }
    const weekDayForms = this.timeRangeForms[weekDay];
    this.mySchedule.addNewTimeRange(index, weekDayForms);
  }

  updateValidity(index: number) {
    this.timeRangeForms[index].forEach(form => {
      form.updateValueAndValidity();
    });
  }

  markCheckbox(value: boolean, dayIndex: number) {
    if (value) {
      this.addNewTimeRange(dayIndex);
    } else {
      this.timeRangeForms[dayIndex] = [];
    }
  }

  removeTimeRange(dayIndex: number, timeRangeIndex: number) {
    this.timeRangeForms[dayIndex].splice(timeRangeIndex, 1);
  }

  endTimes(startTime: string) {
    if (!startTime) return [];
    
    return this.timeOpts.filter(opt => {
      return opt.value > startTime;
    });
  }
}
