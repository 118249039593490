<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Change Address'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'By changing your address, you will immediately cancel all these clients who are outside your new address area. You may not be able to get clients back.'">
      </tidy-text>
    </tidy-row>

    <tidy-card>
      <tidy-row >
        <tidy-text
          [header]="'Cancelled Cleanings'">
        </tidy-text>
      </tidy-row>
      <ng-container *ngFor="let cancellation of cancellations">
        <tidy-row>
          <tidy-grid>
            <ion-row>
              <ion-col size="5">
                <tidy-text
                  [body]="cancellation?.time | ttime">
                </tidy-text><br>
                <tidy-text
                  [body]="cancellation?.type | tidyCleaningType">
                </tidy-text>
              </ion-col>
              <ion-col size="7" >
                <tidy-text
                  [body]="cancellation.date | tdate: 'MM/DD/YY'">
                </tidy-text><br>
                <tidy-text
                  style="color:#AC6D67"
                  body="{{cancellation.performance | performanceText}}: {{cancellation.fee | tcurrency}}">
                </tidy-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
        <tidy-row >
          <tidy-divider></tidy-divider>
        </tidy-row>
      </ng-container>
    </tidy-card>

    <tidy-row [align]="'center'">
      <tidy-text
        body="{{'Change address to' | translate}} {{newAddress?.address}}, {{newAddress?.zipcode}}? {{'Your work area will now change.' | translate}}">
      </tidy-text>
    </tidy-row>

    <tidy-error-message
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-two-column-row>
      <tidy-button item-left
        [text]="'Go Back'"
        [action]="goBack.bind(this)"
        class="primary">
      </tidy-button>
      <tidy-button item-right
        [text]="'Confirm'"
        [action]="confirmUpdate.bind(this)"
        class="secondary">
      </tidy-button>
    </tidy-two-column-row>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
