import { Component } from '@angular/core';
import { DateTime as LuxonDateTime } from 'luxon';

import { MWService } from 'src/main-workflow/mw.service';
import { MWStore } from 'src/main-workflow/mw.store';
import { mwMoments } from 'src/main-workflow/mw.moments';

import { Communication } from 'src/providers/communication/communication';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

import { Loading } from 'src/shared/components/loading/loading';


@Component({
  selector: 'messages',
  templateUrl: 'messages.html',
})

export class MessagesPage {

  messages: any;
  loaded: boolean;

  constructor(
    public communication: Communication,
    private mwService: MWService,
    private mwStore: MWStore,
    private navCtrl: CustomNavController,
  ) {}

  async ionViewDidEnter() {
    this.loaded = false;
    const messages = await this.communication.getLatestMessages();
    this.messages = this.parseAndSortMessagesByDate(messages);
    this.dontShowProMessagesAsUnread();
    this.loaded = true;
  }

  parseAndSortMessagesByDate(messages) {
    const array: any = [];
    messages.map((message) => {
      message.chat_rooms.map((chatRoom) => {
        array.push({
          chatRoom: chatRoom,
          job: message.job,
          type: message.type
        });
      });
    });
    array.sort((a, b) => a.chatRoom.last_chat_room_message.sent_at < b.chatRoom.last_chat_room_message.sent_at ? 1 : -1);
    return array;
  }

  dontShowProMessagesAsUnread() {
    this.messages.map((message) => {
      if (message.chatRoom.last_chat_room_message.from_member.type == 'Homekeeper' && !message.chatRoom.last_chat_room_message.is_read) {
        message.chatRoom.last_chat_room_message.is_read = true;
      }
    });
  }

  goToMessagesPage(message) {
    if (message.type == 'account') {
      localStorage.setItem('clientMessagesBackPage', 'messages');
      this.navCtrl.navigateForward(`client-messages/${message.chatRoom.data.customer_account_id}`);
    } else if (message.job.is_private) {
      localStorage.setItem('privateJobBackPage', 'messages');
      this.navCtrl.navigateForward(`private-job/${message.job.id}`);
    } else if (message.job.state == 'completed' || message.job.state == 'cancelled_once' || message.job.state == 'cancelled') {
      localStorage.setItem('pastJobBackPage', 'messages');
      this.navCtrl.navigateForward(`past-job/${message.job.id}`);
    } else {
      this.goToMainWorkflow(message.job.id);
    }
  }

  @Loading()
  async goToMainWorkflow(jobId) {
    try {
      const jobData = await this.mwService.getJob(jobId);
      this.mwStore.setJob(jobData);
      const storedJobId = await this.mwStore.getJobId();
      const syncData = await this.mwStore.getSyncData();
      const hasJobInProgress = storedJobId !== null && storedJobId !== undefined;
      const clickedJobInProgress = storedJobId == jobId;
      const startedJobInProgress = syncData !== null && syncData !== undefined;
      const clearStore = hasJobInProgress && clickedJobInProgress;
      if (this.mwService.isTooLateForJob(jobData.endTime)) {
        return this.mwService.showTooLateAlert();
      }
      if (jobData.cancelled) {
        return this.mwService.showJobCancelledAlert(clearStore);
      }
      if (jobData.isStandbyCancelled) {
        return this.mwService.showStandbyCancelledAlert(clearStore);
      }
      if (jobData.inactive) {
        return this.mwService.showInactiveJobAlert(clearStore);
      }
      if (startedJobInProgress && clickedJobInProgress) {
        this.mwService.addMomentToSyncData(mwMoments.viewedJob, 'jobs', jobId);
      } else {
        this.mwService.sendMomentToBackend(mwMoments.viewedJob, 'jobs', jobId);
      }
      if (hasJobInProgress && clickedJobInProgress) {
        const storedRoute = await this.mwStore.getRoute();
        this.navCtrl.navigateForward(storedRoute);
      } else {
        localStorage.setItem('readyToLeaveBackPage', 'jobs')
        this.navCtrl.navigateForward('ready-to-leave');
      }
    } catch (err) {
      this.mwService.showErrorPage(err);
    }
  }

  getJobDateTime(time, timezone) {
    return LuxonDateTime.fromISO(time).setZone(timezone).toFormat('L/d h:mm a');
  }

  getMessageDateTime(message) {
    const date = message?.chatRoom?.last_chat_room_message?.sent_at;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const relativeDate = LuxonDateTime.fromISO(date).setZone(timezone).toRelative();
    if (relativeDate.includes('day') || relativeDate.includes('month') || relativeDate.includes('year')) {
      return LuxonDateTime.fromISO(date).setZone(timezone).toFormat('EEE LLL d h:mm a');
    } else {
      return relativeDate;
    }
  }

}
