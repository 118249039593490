import { CommonUtils } from './../../../shared/utils/common-utils';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, NgModel } from '@angular/forms';
import * as moment from 'moment';

import { MWService } from "src/main-workflow/mw.service";
import { mwMoments } from 'src/main-workflow/mw.moments';

import { AppConfig } from 'src/shared/providers/config';
import { Certification } from 'src/providers/certification/certification';
import { ClaimJobs } from 'src/providers/claim-jobs/claim-jobs';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Homekeepers } from 'src/providers/homekeepers/homekeepers';
import { Me } from 'src/shared/providers/me';
import { Team } from 'src/providers/team/team';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';
import promiseUtils from 'src/shared/utils/promise-utils';
import { TimePipe } from 'src/shared/pipes/tidy-time';
import { TidyDatePipe } from 'src/shared/pipes/tidy-date';

import { CertificationsModel } from 'src/shared/models/certification.model';
import { SuccessPageParamsModel } from 'src/pages/success/success';
import { generateTimeStamp } from 'src/providers/tidy-session/session-uuid';
import { Loading } from 'src/shared/components/loading/loading';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

declare const mapboxgl: any;

@Component({
  selector: 'claim-job',
  templateUrl: './claim-job.html',
})
export class ClaimJobPage extends Timeout implements OnInit {

  apiError: string;
  bookingFormAnswers: any;
  currentWeek: any;
  jobsFromSameClient: any;
  select: string;
  selectedCleaningTime: string;
  form: UntypedFormGroup;
  cleaning;
  didGoLive: boolean;
  today: string;
  jobsFromClientCount: number;
  homeLongitude: any;
  homeLatitude: any;
  bookingProtection = true;
  cleaningEndTime: moment.Moment;
  pageTitle: string;
  countdownTarget: moment.Moment;
  confirmingPlanCoop = false;
  planDates: any[] = [];
  map: any = {};
  claimButtonDisabled = true;
  mapUid: string;
  hasTeamMembers: boolean;
  jobTimeItems: any;
  jobsGroupedByJobId: any;

  constructor(
    private certification: Certification,
    private claimJobs: ClaimJobs,
    private navCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private iabUtils: InAppBrowserUtils,
    private homekeepers: Homekeepers,
    public me: Me,
    private mwService: MWService,
    private team: Team,
    private timePipe: TimePipe,
    private tidyDatePipe: TidyDatePipe,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    private route: ActivatedRoute,
    private utils: CommonUtils,
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.fb.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      select: ['', Validators.required],
      lastName: ['', Validators.required],
      jobTime: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.mapUid = `map-${generateTimeStamp()}`;
  }

  @Loading()
  async ionViewDidEnter() {
    try {
      this.loaded = false;
      this.today = moment().format('YYYY-MM-DD');
      this.cleaning = this.navCtrl.getParam('cleaning', false);
      if (!this.cleaning) {
        const lms = await this.homekeepers.fetchLmsNoCache();
        const cleaningId = this.route.snapshot.paramMap.get('cleaningId');
        this.cleaning = lms.find((cleaning) => cleaning.job_id == cleaningId);
        if (!this.cleaning) {
          return this.showCleaningNoLongerAvailable();
        }
        const data: any = await this.claimJobs.parseDays(lms);
        this.jobsFromSameClient = this.claimJobs.getJobsFromSameClient(data.currentWeek, this.cleaning);
        this.jobsGroupedByJobId = data.currentWeek.jobs;
        await this.getHkHomeData();
        if (this.cleaning.homekeeper_preferences.gender == 'female' ||  this.cleaning.homekeeper_preferences.gender == 'male') {
          this.hasTeamMembers = await this.team.getIfHasTeamMembers();
        }
      } else {
        this.jobsFromSameClient = this.navCtrl.getParam('jobsFromSameClient');
        this.jobsGroupedByJobId = this.navCtrl.getParam('jobsGroupedByJobId');
        this.homeLongitude = this.navCtrl.getParam('homeLongitude');
        this.homeLatitude = this.navCtrl.getParam('homeLatitude');
        this.hasTeamMembers = this.navCtrl.getParam('hasTeamMembers');
      }
      this.jobsFromClientCount = this.getJobsFromClientCount();
      this.cleaningEndTime = moment(this.cleaning.date).add(60, 'm');
      this.pageTitle = this.cleaning.type === 'cleaning' ? 'Add Job' : 'Add Plan';
      this.today = moment().format('YYYY-MM-DD');
      this.countdownTarget = moment().add(5, 'seconds');
      const certification: CertificationsModel = await this.certification.getCertifications();
      this.didGoLive = certification.steps.go_live === 'complete';
      this.jobTimeItems = await this.buildJobTimeItems();
      this.form.patchValue({jobTime: this.cleaning.emergency_cleaning_request_id});
      this.jobsGroupedByJobId.map((group) => {
        group.jobs.map((job) => {
          if (job.emergency_cleaning_request_id == this.cleaning.emergency_cleaning_request_id) {
            this.selectedCleaningTime = job.time;
          }
        });
      });
      this.bookingFormAnswers = await this.me.getBookingFormAnswers(this.cleaning.job_id);
      this.loaded = true;
      await this.setMap();
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  buildJobTimeItems() {
    const array: any = [];
    this.jobsGroupedByJobId.map((job) => {
      if (job.job_id == this.cleaning.job_id) {
        job.jobs.map((item) => {
          let viewValue = this.timePipe.transform(item.time) + ' ' + new TranslationPipe().transform('on') + ' ' + this.tidyDatePipe.transform(item.date, 'M/D/YY');
          if (item.is_preferred_start_date_and_time) {
            viewValue += new TranslationPipe().transform(' (preferred)');
          }
          array.push({
            value: item.emergency_cleaning_request_id,
            viewValue: viewValue
          });
        });
      }
    });
    return array;
  }

  getJobsFromClientCount() {
    const array: any = [];
    this.jobsGroupedByJobId.map((group) => {
      group.jobs.map((job) => {
        const isSameClient = job.customer_id == this.cleaning.customer_id;
        if (isSameClient && !array.includes(job.job_id)) {
          array.push((job.job_id));
        }
      });
    });
    return array.length;
  }

  async setMap() {
    mapboxgl.accessToken = AppConfig.MAPBOX_KEY;
    let centerLongitude = this.homeLongitude;
    let centerLatitude = this.homeLatitude;
    if (this.homeLongitude == null || this.homeLongitude == undefined) {
      centerLongitude = this.cleaning.longitude;
      centerLatitude = this.cleaning.latitude;
    } else {
      centerLongitude += this.cleaning.longitude;
      centerLatitude += this.cleaning.latitude;
      centerLongitude = centerLongitude / 2;
      centerLatitude = centerLatitude / 2;
    }
    const mapElementId = this.mapUid;
    await this.awaitMapElement(mapElementId);
    const map = new mapboxgl.Map({
      container: mapElementId,
      style: 'mapbox://styles/mapbox/light-v10',
      center: [ centerLongitude, centerLatitude ],
      zoom: 8,
      maxZoom: 11,
      minZoom: 5
    });
    this.addOppMarker(map);
    this.addHomeMarker(map);
  }

  addOppMarker(map) {
    const el = document.createElement('div');
    const letter = String.fromCharCode(65);
    el.className = 'marker';
    el.innerHTML = `<span class="map-marker">${letter}</span>`;
    this.createMarkerInstance({map, el, longLat: [ this.cleaning.longitude, this.cleaning.latitude ]})
  }

  async addHomeMarker(map) {
    const el = document.createElement('div');
    el.className = 'marker';
    el.innerHTML = `<image src="assets/img/home-black.svg" style="height: 30px"></image>`;
    this.createMarkerInstance({map, el, longLat: [ this.homeLongitude, this.homeLatitude ]});
  }

  createMarkerInstance(obj) {
    const {el, longLat, map} = obj;
    new mapboxgl.Marker(el)
      .setLngLat(longLat)
      .addTo(map);
  }

  async awaitMapElement(mapId, atempts = 1) {
    const mapElement = document.getElementById(mapId);
    if(!mapElement && atempts < 4) {
      await promiseUtils.sleep(100);
      await this.awaitMapElement(mapId, (atempts + 1));
    }
  }

  openBookingProtectionLearnMore = () => {
    this.iabUtils.openUrl('https://help.tidy.com/pros/booking-protection');
  }

  openBonusLearnMore = () => {
    this.iabUtils.openUrl('https://help.tidy.com/pros/how-to-get-clients-from-tidy#select-clients-who-need-you');
  }

  openStandbyLearnMore = () => {
    this.iabUtils.openUrl('https://help.tidy.com/pros/how-to-get-clients-from-tidy#select-clients-who-need-you');
  }

  confirmPlanCoop = () => {
    this.confirmingPlanCoop = true;
    this.pageTitle = 'Are You Sure?';
    this.getPlanDates();
  }

  getPlanDates() {
    this.planDates = [];

    const date = moment(this.cleaning.date, 'YYYY-MM-DD');

    for (let i = 0; i <= 4; i++) {
      const cloneDate = date.clone();
      const planDate = cloneDate.add(i * 7, 'days');
      this.planDates.push(planDate);
    }
  }

  async showConfirmDeclineCleaningAlert(): Promise<void> {
    const options = {
      header: 'Decline Request?',
      message: 'Are you sure you want to decline this job?',
    }
    const confirmAlert = await this.utils.showConfirmAlert(options);
    confirmAlert.onDidDismiss().then((data) => {
      if (data.role === 'confirm') {
        this.declineCleaning();
      }
    });
  }

  async declineCleaning() {
    const loading = await this.utils.showLoading();
    try {
      await this.homekeepers.declineCleaning(this.cleaning.emergency_cleaning_request_id);
      loading.dismiss();
      this.navCtrl.navigateForward('add');
    } catch (error) {
      loading.dismiss();
      console.error(error);
      this.apiError = error.error ? error.error.message : error.message;
      this.utils.showError(`Error declining cleaning: ${this.apiError}`);
    }
  }

  async claimCleaning() {
    const hkId = await this.homekeepers.hkId();
    this.apiError = '';
    try {
      const data = {
        booking_protection_enabled: this.didGoLive ? this.bookingProtection : false,
        emergency_cleaning_request_id: this.form.value.jobTime,
        type: this.cleaning.type,
        id: hkId
      };
      await this.homekeepers.claimCleaning(data);
      this.mwService.sendMomentToBackend(mwMoments.claimCoop, 'claim-job', this.cleaning.job_id);
      const params = this.mountSuccessPageParams();
      this.navCtrl.navigateForward('success', params);
    } catch (err) {
      this.apiError = err.error ? err.error.message : err.message;
      this.showCleaningNoLongerAvailable();
    }
  }

  mountSuccessPageParams(): SuccessPageParamsModel {
    return {
      header: 'Job Added',
      body: this.cleaning?.is_private ? 'Nice work! You just booked with your private client.' : 'Nice work! You just gained a new client.',
      buttonText: 'Ok',
      buttonRoute: 'jobs'
    };
  }

  showCleaningNoLongerAvailable() {
    this.navCtrl.navigateForward('success', {
      header: 'Opportunity No Longer Available',
      body: 'Unfortunately this opportunity was taken by someone else.  They can be quite competitive.',
      buttonText: 'Ok',
      buttonRoute: 'add'
    });
  }

  enableClaimButton() {
    this.claimButtonDisabled = false;
  }

  openOtherOptionsPage() {
    const params = {
      jobsFromSameClient: this.jobsFromSameClient,
      clientName: this.cleaning.customer_first_name,
      jobsGroupedByJobId: this.jobsGroupedByJobId
    }
    this.navCtrl.navigateForward('other-options', params);
  }

  parseServiceLength(name) {
    const lastIndex = name.lastIndexOf(" ");
    return name.substring(0, lastIndex);
  }

  async getHkHomeData(): Promise<Array<number>> {
    const hk = await this.me.load();
    this.homeLongitude = hk.user.longitude;
    this.homeLatitude = hk.user.latitude;
    return [this.homeLongitude, this.homeLatitude];
  }
}
