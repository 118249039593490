<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Change Schedule?'"
    [noBow]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngIf="loaded">
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Schedule for '">
        </tidy-text>
        <tidy-text
          [body]="date | tdate:'dddd M/D'">
        </tidy-text>
        <tidy-text *ngIf="singleDay"
          [body]="':'">
        </tidy-text>
        <ng-container *ngIf="!singleDay">
          <tidy-text
            [body]="' and all '">
          </tidy-text>
          <tidy-text
            [body]="date | tdate:'dddd'">
          </tidy-text>
          <tidy-text
            [body]="'\'s after:'">
          </tidy-text>
        </ng-container>
      </tidy-row>

      <tidy-row [align]="'center'">
        <ng-container *ngIf="selectedTimesText !== ''; else notBookable">
          <tidy-text
            [body]="'Bookable: '">
          </tidy-text>
          <tidy-text
            [body]="selectedTimesText">
          </tidy-text>
        </ng-container>
        <ng-template #notBookable>
          <tidy-text
            [body]="'Not Bookable'">
          </tidy-text>
        </ng-template>
      </tidy-row>

      <tidy-card *ngIf="cancellations.cleanings_to_cancel.length > 0">
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [header]="'Cancelled Cleanings'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let cancellation of cancellations.cleanings_to_cancel; let last = last">
          <tidy-row>
            <tidy-grid>
              <ion-row>
                <ion-col size="5">
                  <tidy-text
                    [body]="cancellation?.date | tdate:'M/D/YY'">
                  </tidy-text><br>
                  <tidy-text
                    [body]="cancellation?.time | ttime">
                  </tidy-text>
                </ion-col>
                <ion-col size="7" >
                  <tidy-text
                    [body]="cancellation?.service_type_details?.name">
                  </tidy-text><br>
                  <tidy-text
                    [body]="cancellation?.performance | performanceText">
                  </tidy-text>
                  <tidy-text
                    [body]="': '">
                  </tidy-text>
                  <tidy-text
                  [body]="cancellation?.fee | tcurrency">
                </tidy-text>
                </ion-col>
              </ion-row>
            </tidy-grid>
          </tidy-row>
          <tidy-row *ngIf="!last">
            <tidy-divider></tidy-divider>
          </tidy-row>
        </ng-container>
      </tidy-card>

      <tidy-card *ngIf="cancellations.bonuses_to_cancel.length > 0">
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [header]="'You will lose the following bonus:'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let cancellation of cancellations.bonuses_to_cancel; let last = last; let first = first">
          <tidy-row>
            <tidy-grid>
              <tidy-row [ngClass]="first ? '' : 'extra-top-padding'">
                <tidy-text
                  body="{{ cancellation.bonus_amount | tcurrency }}"
                  class="red"
                ></tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  body="You lose this bonus if you do not remain bookable ">
                </tidy-text>
                <tidy-text
                  [body]="date | tdate:'dddd'">
                </tidy-text>
                <tidy-text
                  body="s ">
                </tidy-text>
                <tidy-text
                  [body]="cancellation.keep_bookable_times">
                </tidy-text>
                <tidy-text
                  body=" between ">
                </tidy-text>
                <tidy-text
                  [body]="cancellation.keep_bookable_start_date | tdate: 'M/D/YY'">
                </tidy-text>
                <tidy-text
                  body=" and ">
                </tidy-text>
                <tidy-text
                  [body]="cancellation.keep_bookable_end_date | tdate: 'M/D/YY'">
                </tidy-text>
                <tidy-text
                  body=".">
                </tidy-text>
              </tidy-row>
            </tidy-grid>
          </tidy-row>
          <tidy-row *ngIf="!last">
            <tidy-divider></tidy-divider>
          </tidy-row>
        </ng-container>
      </tidy-card>

      <tidy-card *ngIf="hasCancellations">
        <form [formGroup]="form" novalidate action="#">
          <tidy-row class="field-top-padding">
            <tidy-select
              [label]="'Why are you cancelling?'"
              [form]="form.controls.cancelReason"
              formControlName="cancelReason"
              errorMessage="Please select why are you canceling?"
              [submitted]="submitted"
              [items]="cancelReasons">
            </tidy-select>
          </tidy-row>
        </form>
      </tidy-card>

      <tidy-error-message
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-two-column-row>
        <tidy-button item-left
          [text]="'Go Back'"
          [action]="goBack.bind(this)"
          class="secondary">
        </tidy-button>
        <tidy-button item-right
          [text]="'Confirm'"
          [action]="save.bind(this)"
          class="secondary">
        </tidy-button>
      </tidy-two-column-row>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
