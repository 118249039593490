
class PromiseUtils {

  sleep(milliseconds): Promise<void> {
    return new Promise( res => setTimeout(res, milliseconds) );
  }

}

export default new PromiseUtils();
