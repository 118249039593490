import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { MWService } from "src/main-workflow/mw.service";
import { MWStore } from "src/main-workflow/mw.store";

import { ServerData } from 'src/shared/providers/server-data';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';

import { Loading } from 'src/shared/components/loading/loading';

@Component({
  templateUrl: 'mw.call-out.page.html'
})

export class MWCallOutPage {

  cancelReasons: any;
  errorMessage: string;
  form: any;
  isFutureJob = true;
  job: any;
  loaded: boolean;
  submitted: boolean;
  title: string;

  constructor(
    private fb: UntypedFormBuilder,
    private mwService: MWService,
    private mwStore: MWStore,
    private navCtrl: CustomNavController,
    private serverData: ServerData
  ) {
    this.form = this.fb.group({
      cancelReason: ['', Validators.required],
    });
  }

  @Loading()
  async ionViewDidEnter() {
    this.loaded = false;
    this.cancelReasons = await this.loadCancelReasons();
    let job = this.navCtrl.getParam('job');
    if (!job) {
      job = await this.mwStore.getJob();
      this.isFutureJob = false;
    }
    this.job = await this.parseJobData(job);
    this.title = this.isFutureJob ? 'Cancel Job' : 'Call Out';
    this.loaded = true;
  }

  async parseJobData(job) {
    if (this.isFutureJob) {
      return {
        startTime: `${job.job.date} ${job.job.start_time}`,
        type: job.job.type,
        repeat: job.homekeeper_job.repeat_client,
        firstName: job.customer.first_name,
        lastName: job.customer.last_name,
        fee: job.call_out_price,
        performance: job.call_out_performance,
        id: job.job.id,
        duration: job.job.duration_in_minutes
      }
    } else {
      return {
        startTime: job.startTime,
        type: job.type,
        repeat: job.isRepeatClient,
        firstName: job.client,
        lastName: job.last_name,
        fee: job.fee,
        performance: 'call_out',
        id: job.id,
        duration: job.duration
      }
    }
  }

  @Loading()
  async cancel(): Promise<any> {
    this.errorMessage = null;
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    try {
      if (this.isFutureJob) {
        await this.mwService.cancelFutureJob(this.job.id, this.form.controls.cancelReason.value);
        this.goToSuccessPage('Successfully Cancelled Job');
      } else {
        await this.mwService.cancelJob('call_out', this.job, this.form.controls.cancelReason.value);
        this.goToSuccessPage('Successfully Called Out');
      }
    } catch(err) {
      setTimeout(() => this.errorMessage = null, 10000);
      this.errorMessage = await this.mwService.buildErrorAlert(err);
    }
  }

  goToSuccessPage(header) {
    return this.navCtrl.navigateForward('success', {
      header: header,
      body: 'Your clients have been notified.',
      buttonText: 'Go to My Jobs',
      buttonRoute: '/jobs'
    });
  }

  async loadCancelReasons(): Promise<TidySelectNumberValueModel[]> {
    try {
      const reasons = await this.serverData.cancelReasons(true);
      return reasons.reduce((acc, item) =>  {
        acc.push({
          viewValue: item.title,
          value: item.id
        });
        return acc;
      }, []);
    } catch(err) {
      this.mwService.showErrorPage(err);
    }
  }
}
