<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Search'"
    [canGoBack]="true"
    [customBack]="'more'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card>
      <form [formGroup]="form" novalidate action="#">
        <tidy-row class="field-top-padding">
          <tidy-input
            #searchInput
            [icon]="'assets/svg/search-outline.svg'"
            [activeIcon]="'assets/svg/search-tidy-green.svg'"
            [noBorder]="true"
            type="search"
            formControlName="search"
            [label]="'Search'"
            [form]="form">
          </tidy-input>
        </tidy-row>
      </form>
    </tidy-card>

    <ng-container *ngIf="loaded">

      <ng-container *ngIf="form.controls.search.value === ''">
        <tidy-row>
          <tidy-text
            [header]="'Example Searches'">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngFor="let example of exampleSearches">
          <tidy-text
            [body]="example">
          </tidy-text>
        </tidy-row>
      </ng-container>

      <ng-container *ngIf="pastSearches.length > 0 && form.controls.search.value === ''">
        <tidy-row>
          <tidy-text
            [header]="'Recent Searches'">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngFor="let search of pastSearches">
          <tidy-text
            [body]="search"
            (action)="selectPastSearch(search)"
            class="link">
          </tidy-text>
        </tidy-row>
      </ng-container>

      <ng-container *ngIf="clientsResult?.length > 0 && form.controls.search.value != ''">
        <tidy-row>
          <tidy-text
            [header]="'Clients'">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngFor="let item of clientsResult">
          <tidy-text
            (action)="gotoCustomer(item.id)"
            [body]="item.first_name + ' ' + item.last_name"
            class="link"
            [translate]="false">
          </tidy-text>
        </tidy-row>
      </ng-container>

      <ng-container *ngIf="appSectionResults?.length > 0 && form.controls.search.value != ''">
        <tidy-row>
          <tidy-text
            [header]="'App Sections'">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngFor="let section of appSectionResults">
          <tidy-text
            (action)="gotoAppSection(section?.path)"
            [body]="section?.displayTerm"
            class="link">
          </tidy-text>
        </tidy-row>
      </ng-container>

      <ng-container *ngIf="helpResult?.length > 0 && form.controls.search.value != ''">
        <tidy-row>
          <tidy-text [header]="'Help Center'" >
          </tidy-text>
        </tidy-row>
        <tidy-row *ngFor="let item of helpResult" >
          <tidy-text
            (action)="goToHelp(item.url)"
            [body]="item.title"
            class="link">
          </tidy-text>
        </tidy-row>
      </ng-container>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
