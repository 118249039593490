import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Team } from 'src/providers/team/team';
import { Updates } from 'src/providers/updates/updates';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm';
import { SuccessPageParamsModel } from 'src/pages/success/success';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: 'accept-reject-job.html'
})

export class AcceptRejectJobPage {

  delegatorName: string;
  errorMessage: string;
  loaded = false;
  update: any;
  updateId: any;
  requestId: any;
  jobDate: any;
  jobTime: any;
  jobDuration: any;

  constructor(
    private navCtrl: CustomNavController,
    private modalCtrl: ModalController,
    private team: Team,
    private updates: Updates
  ) {}

  async ionViewDidEnter() {
    const cameFromAddPage = this.navCtrl.getParam('cameFromAddPage');
    if (cameFromAddPage) {
      this.updateId = this.navCtrl.getParam('updateId');
      this.requestId = this.navCtrl.getParam('requestId');
      this.delegatorName = this.navCtrl.getParam('delegatorName');
      this.jobDate = this.navCtrl.getParam('jobDate');
      this.jobTime = this.navCtrl.getParam('jobTime');
      this.jobDuration = this.navCtrl.getParam('jobDuration');
    } else {
      const update = this.navCtrl.getParams();
      this.updateId = update.id;
      this.requestId = update.data.job_delegation_request.id;
      this.delegatorName = update.data.job_delegation_request.delegator_name;
      this.jobDate = update.data.job.friendly_date;
      this.jobTime = update.data.job.friendly_start_time;
      this.jobDuration = update.data.job.duration_in_hours;
    }
    this.loaded = true;
  }

  async showConfirm(type) {
    const params: ConfirmPageParamsModel = this.getConfirmParams(type);
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });
    confirmationModal.present();
  }

  getConfirmParams(type): ConfirmPageParamsModel {
    const params = {
      acceptJob: {
        title: 'Accept Job?',
        body: `${ new TranslationPipe().transform('You will be expected to complete the job.  All payment will be made to')} ${this.delegatorName}.`,
        backText: 'Go Back',
        confirmText: 'Accept Job',
        confirmAction: this.confirm.bind(this, type),
      },
      rejectJob: {
        title: 'Reject Job?',
        body: `${ new TranslationPipe().transform('We will notify')} ${this.delegatorName}.`,
        backText: 'Go Back',
        confirmText: 'Reject Job',
        confirmAction: this.confirm.bind(this, type),
      }
    }
    return params[type];
  }

  async confirm(type) {
    try{
      await this.team[type](this.requestId);
      if(this.updateId){
        await this.updates.dismissUpdate(this.updateId);
      }
      const params = this.getSuccessParams(type);
      this.navCtrl.navigateForward('success', params);
      this.modalCtrl.dismiss();
    } catch(err) {
      this.modalCtrl.dismiss();
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  getSuccessParams(type): SuccessPageParamsModel {
    const params = {
      acceptJob: {
        header: 'Job Accepted',
        body: `${ new TranslationPipe().transform('View this job in the Jobs section.  All payment will be made to')} ${this.delegatorName}.`,
        buttonText: 'Ok',
        buttonRoute: 'jobs'
      },
      rejectJob: {
        header: 'Job Rejected',
        body: `${ new TranslationPipe().transform('We have notified')} ${this.delegatorName}.`,
        buttonText: 'Ok',
        buttonRoute: 'jobs'
      }
    }
    return params[type];
  }
  
}
