<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [customBack]="'more'"
    [title]="'Clients from TIDY Settings'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card>
      <tidy-card-button (action)="goToFeesAndBooking()">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/notifications-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Client Fees & Booking Protection'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <!-- <tidy-card-button (action)="goToNotificationSettings()">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/notifications-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Notification Settings'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button> -->
      <tidy-card-button (action)="goToBookingRestrictions()">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/checkbox-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Your Booking Settings'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="goToTerms()">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/document-text-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Review Terms of Use'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
