import { Component, NgZone, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { RadioButtonModel, SelectModel } from '@tidyapp/tidy-ui-components';

import { AddressForm } from 'src/providers/address-form/address-form';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Me } from 'src/shared/providers/me';
import { MultipleCountryService } from 'src/providers/addresses/multiple-country.service';

import { HomekeeperAddressModel } from 'src/models/homekeeper-profile.model';

import { Loading } from 'src/shared/components/loading/loading';
import { AutoCompleteAddressModel } from 'src/shared/models/autocomplete-address.model';
import { HomekeeperModel } from 'src/shared/models/homekeeper.model';
import { keepOnlyText } from 'src/shared/utils/text-utils';
import { AddressMetadata } from 'src/shared/components/autocomplete-address/autocomplete-address';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: './unpause-update-address.html'
})

export class UnpauseAddressPage extends Timeout {

  errorMessage: string;
  chosenType: string;
  hkAddressForm: UntypedFormGroup;
  howFarItems: Array<SelectModel>;
  homekeeper: HomekeeperModel;
  autocompleteAddress: AutoCompleteAddressModel[];
  submitted: boolean;
  countryList: RadioButtonModel[];
  zipCodeMask = {
    mask: '00000'
  }
  noAddressPickedTemplate: ElementRef;
  invalidAddressError: string;
  travelTimeForm: any;
  travelTimesOpts: Array<SelectModel>;
  typeItems: Array<SelectModel>;
  certificationStatus: any;
  state: string;

  constructor(
    private addressForm: AddressForm,
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private me: Me,
    public _zone: NgZone,
    private multipleCountryService: MultipleCountryService,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
    this.hkAddressForm = this.fb.group({
      countryCode: ['US', Validators.required],
      address: ['', Validators.compose([Validators.maxLength(100), Validators.required])],
      address2: ['', Validators.compose([Validators.maxLength(100)])],
      latitude: '',
      longitude: '',
      zipcode: ['', this.multipleCountryService.getCountrySettings('US').validator],
      city: ['', Validators.required],
      state: ['', Validators.required]
    });
    this.init();
    this.countryList = this.multipleCountryService.countryRadioButtonList;
  }

  async init() {
    this.typeItems = this.getTypeItems();
    this.howFarItems = this.getHowFarItems();
    this.travelTimesOpts = this.getTravelTimesOptions();
    this.travelTimeForm = this.fb.group({
      travelTime: ['', Validators.required]
    });
  }

  @Loading()
  async ionViewDidEnter() {
    try {
      this.loaded = false;
      this.homekeeper = await this.me.load();
      this.state = this.homekeeper.user.state;
      this.setCurrentAddress();
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  setCurrentAddress(){
    const currentAddress = {
      countryCode: this.homekeeper.profile.address.country_code,
      address: this.homekeeper.profile.address.address,
      address2: this.homekeeper.profile.address.address2,
      latitude: this.homekeeper.user.latitude,
      longitude: this.homekeeper.user.longitude,
      zipcode: this.homekeeper.profile.address.zipcode,
      city: this.homekeeper.profile.address.city,
      state: this.homekeeper.profile.address.add_state
    }
    this.hkAddressForm.patchValue(currentAddress);
  }

  checkChange = async () => {
    const zipcode = keepOnlyText(this.hkAddressForm.value.zipcode);

    this.submitted = true;
    this.errorMessage = '';
    const travelTime = this.travelTimeForm.value.travelTime;
    const newAddress: HomekeeperAddressModel = {
      ...this.hkAddressForm.value,
      country_code: this.hkAddressForm.value.countryCode,
      zipcode,
      add_state: this.hkAddressForm.value.state
    };

    if (!this.travelTimeForm.valid) {
      return;
    }

    if (this.addressForm.addressError(this.hkAddressForm.value.address)) {
      this.hkAddressForm.controls.address.setErrors({'incorrect': true});
      return this.errorMessage = 'Unfortunately you must have a physical address in order to accurately determine your service area, and PO boxes cannot be used. Clients do not see your address. Please add a different address.';
    }

    if(this.invalidAddressError) {
      this.errorMessage = this.invalidAddressError;
      return;
    }

    if (!this.hkAddressForm.valid) {
      return;
    }

    try {
      await this.me.updateAddress(newAddress);
      await this.me.updateTravelTime(travelTime);
      this.customNavCtrl.navigateForward('unpause/update-schedule');
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  choseItem(addressMetadata: AddressMetadata) {
    this.hkAddressForm.patchValue(addressMetadata);
    this.errorMessage = '';
  }

  changeCountrySelected(selectedCountry: string) {
    const validatorObject = this.multipleCountryService.getCountrySettings(selectedCountry);
    this.hkAddressForm.get('zipcode').setValidators(validatorObject.validator);
    this.hkAddressForm.get('zipcode').updateValueAndValidity();
    this.zipCodeMask = validatorObject.mask;

    setTimeout(() => {
      this.hkAddressForm.patchValue({
        address: '',
        address2: '',
        zipcode: ''
      });
    });
  }

  getTravelTimesOptions(): Array<SelectModel> {
    return Array(14).fill(0).map((item, index) => {
      const time = 10 + index * 5;
      return {
        value: `${time}`,
        viewValue: `${time} ${new TranslationPipe().transform('minutes')}`
      };
    });
  }

  getTypeItems() {
    return [
      {
        viewValue: 'By car (recommended except in very dense areas)',
        value: 'car'
      },
      {
        viewValue: 'By public transit or walk',
        value: 'walk'
      },
    ];
  }

  getHowFarItems() {
    return [
      {
        viewValue: '3 Minutes',
        value: '3'
      },
      {
        viewValue: '6 Minutes',
        value: '6'
      },
      {
        viewValue: '9 Minutes',
        value: '9'
      }
    ];
  }

  chooseType(chosenType) {
    this.chosenType = chosenType.value;
  }
}
