import { Component, ViewEncapsulation } from '@angular/core';
import { CommonUtils } from 'src/shared/utils/common-utils';
import { ModalController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Photo } from '@capacitor/camera';
import * as moment from 'moment';

import { AppConfig } from 'src/shared/providers/config';
import { Aws } from 'src/shared/providers/aws';
import { Blacklist } from 'src/providers/blacklist/blacklist';
import { Camera } from 'src/shared/providers/camera/camera';
import { Cleanings } from 'src/providers/cleanings/cleanings';
import { Concierge } from 'src/providers/concierge/concierge';
import { Communication } from 'src/providers/communication/communication';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Me } from 'src/shared/providers/me';
import { MileageTracking } from 'src/providers/mileage-tracking/mileage-tracking';
import { ParkingReimbursement } from 'src/providers/parking-reimbursement/parking-reimbursement';
import { ReportIssue } from 'src/providers/report-issue/report-issue';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { Loading } from 'src/shared/components/loading/loading';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';
import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm';
import { SuccessPageParamsModel } from 'src/pages/success/success';

import { MileageTrackingRecordModel } from 'src/shared/models/mileage-tracking.model';
import { ConciergeItemModel } from 'src/models/concierge.model';
import { Timeout } from 'src/shared/components/timeout/timeout';

import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

import { beforeAfterOptions } from 'src/shared/constants/camera';
import { JobMediasProvider } from 'src/providers/job-medias/job-medias.provider';
import { MWStore } from 'src/main-workflow/mw.store';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MWService } from 'src/main-workflow/mw.service';

@Component({
  selector: 'past-job',
  templateUrl: 'past-job.html',
  encapsulation: ViewEncapsulation.None
})
export class PastJobPage extends Timeout {

  backPage: string;
  beforeAfter: string;
  cleaning: any;
  disputeReasonDescriptions: any;
  disputeReasonText: any;
  errorMessage: string;
  jobId: number;
  jobMileage: MileageTrackingRecordModel[];
  leftEarly = false;
  loggedIssues: any;
  messages: any;
  paymentHistory: any;
  pendingIssue: boolean;
  proName: string;
  reimbursementRequest: any;
  reportedIssues: ConciergeItemModel[];
  rooms: any;
  showWaiveFee = false;
  showLostItem: boolean;
  showParkingReimbursement: boolean;
  timezone: any;
  hasNewBasePayment: boolean;
  updates: any;
  isReportedIssuesLoaded: boolean;
  isLoggedIssuesLoaded: boolean;
  isCleaningLoaded: boolean;
  isPaymentHistoryLoaded: boolean;
  isMessagesLoaded: boolean;
  isUpdatesLoaded: boolean;
  isJobMiliageLoaded: boolean;
  beforePhotos: any[];
  afterPhotos: any[];
  form: UntypedFormGroup;
  submitted: boolean;
  distanceFormat: string;

  constructor(
    private aws: Aws,
    private blacklist: Blacklist,
    private camera: Camera,
    private cleanings: Cleanings,
    public concierge: Concierge,
    public communication: Communication,
    private navCtrl: CustomNavController,
    private iabUtils: InAppBrowserUtils,
    private me: Me,
    private mileageTracking: MileageTracking,
    private modalCtrl: ModalController,
    private parkingReim: ParkingReimbursement,
    private reportIssue: ReportIssue,
    public route: ActivatedRoute,
    private storage: TidyStorage,
    private util: CommonUtils,
    private jobMediasProvider: JobMediasProvider,
    private mwStore: MWStore,
    private fb: UntypedFormBuilder,
    private mwService: MWService,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.fb.group({
      message: ['', Validators.required],
    });
  }

  async ionViewDidEnter() {
    this.loaded = true;
    this.distanceFormat = await this.me.getDistanceFormatValue();
    this.jobId = +this.route.snapshot.paramMap.get('jobId');
    this.backPage = localStorage.getItem('pastJobBackPage') || 'jobs';
    this.getReportedIssues();
    const cleaning = await this.fetchCleaning();
    this.mwStore.setJob(cleaning);
    this.rooms = this.parseRooms();
    this.getCleaningData(cleaning);
    this.getPersonalData();
    const jobMedias = await this.jobMediasProvider.getJobMedias([this.jobId]);
    this.beforePhotos = this.getParsedBeforeAfterPhotos(jobMedias, 'before_job');
    this.afterPhotos = this.getParsedBeforeAfterPhotos(jobMedias, 'after_job');
  }

  getParsedBeforeAfterPhotos(jobMedias: any[], category: string): any[] {
    if (!jobMedias) {
      return [];
    }
    let parsedJobMedias = jobMedias.filter((media) => media.category === category);
    parsedJobMedias = parsedJobMedias.map((media) => {
      return {
        ...media,
        mediaType: media?.media_format,
        url: media.media_url,
        category: null,
      };
    })
    return parsedJobMedias;
  }

  async getReportedIssues(): Promise<void> {
    try {
      this.isReportedIssuesLoaded = false;
      this.reportedIssues = await this.concierge.getConciergeItemsByJob(this.jobId);
      this.isReportedIssuesLoaded = true;
    } catch (err) {
      this.isReportedIssuesLoaded = true;
      this.handleLoadErrors(err);
    }
  }

  async fetchCleaning(): Promise<any> {
    try {
      this.isCleaningLoaded = false;
      let cleaning = await this.cleanings.fetchCleaning(this.jobId);
      cleaning = {
        ...cleaning,
        customerId: cleaning?.customer?.id,
        homekeeper_job_id: cleaning?.homekeeper_job?.id,
        id: cleaning?.job?.id,
      }
      this.cleaning = cleaning;
      this.isCleaningLoaded = true;
      return cleaning;
    } catch (err) {
      this.isCleaningLoaded = true;
      this.handleLoadErrors(err);
    }
  }

  async getCleaningData(cleaning: any): Promise<void> {
    const homekeeperJobId = cleaning?.homekeeper_job?.id;
    this.getJobMileage(homekeeperJobId);
    this.getLoggedIssues(homekeeperJobId);
    this.getHistory();
    this.getRequests(cleaning.job.id);
    this.getPaymentHistory(homekeeperJobId);
    this.getMessages(homekeeperJobId);
    try {
      this.leftEarly = (cleaning.homekeeper_job.performance === 'left_early');
      if (cleaning.dispute) {
        this.disputeReasonText = this.getDisputeDescription(cleaning.dispute.reason);
      }
      this.showParkingReimbursement = this.checkIfShowParking();
      this.showLostItem = this.checkIfShowLostItem(cleaning.job.date);
    } catch (err) {
      this.handleLoadErrors(err);
    }
  }

  async getJobMileage(homekeeperJobId: number): Promise<void> {
    try {
      this.isJobMiliageLoaded = false;
      this.jobMileage = await this.mileageTracking.getMileageRecordByHkJob(homekeeperJobId);
      this.isJobMiliageLoaded = true;
    } catch (err) {
      this.isJobMiliageLoaded = true;
      this.handleLoadErrors(err);
    }
  }

  async getLoggedIssues(homekeeperJobId: number): Promise<void> {
    try {
      this.isLoggedIssuesLoaded = false;
      this.loggedIssues = await this.reportIssue.getReportedIssues([homekeeperJobId]);
      this.isLoggedIssuesLoaded = true;
    } catch (err) {
      this.isLoggedIssuesLoaded = true;
      this.handleLoadErrors(err);
    }
  }

  async getHistory(): Promise<void> {
    try {
      this.isUpdatesLoaded = false;
      this.updates = await this.cleanings.history(this.jobId);
      this.isUpdatesLoaded = true;
    } catch (err) {
      this.isUpdatesLoaded = true;
      this.handleLoadErrors(err);
    }
  }

  async getRequests(jobId: number): Promise<void> {
    try {
      this.reimbursementRequest = await this.parkingReim.getRequests(jobId);
    } catch (err) {
      this.handleLoadErrors(err);
    }
  }

  async getPaymentHistory(homekeeperJobId: number): Promise<void> {
    try {
      this.isPaymentHistoryLoaded = false;
      this.paymentHistory = await this.cleanings.fetchPaymentHistory(homekeeperJobId);
      this.paymentHistory.earningTotal = this.sumTotal(this.paymentHistory.earnings_breakdown.payment_items);
      this.checkIfShowWaiveFee();
      this.isPaymentHistoryLoaded = true;
    } catch (err) {
      this.isPaymentHistoryLoaded = true;
      this.handleLoadErrors(err);
    }
  }

  async getMessages(homekeeperJobId: number): Promise<void> {
    try {
      this.isMessagesLoaded = false;
      this.messages = await this.communication.getSharedInboxForHomekeeperJob(homekeeperJobId);
      this.markMessagesAsRead();
      this.isMessagesLoaded = true;
    } catch (err) {
      this.isMessagesLoaded = true;
      this.handleLoadErrors(err);
    }
  }

  async getPersonalData(): Promise<void> {
    try {
      const me = await this.me.load();
      this.proName = me.profile.name;
      this.timezone = me.profile.address.timezone_name;
      this.beforeAfter = this.cleanings.getBeforeAfter(this.cleaning.address_task_list.before_after_photos_state);
    } catch (err) {
      this.handleLoadErrors(err);
    }
  }

  getPhotoButtonText(type, photos) {
    const photo = photos.find((photo) => photo.photo_type == type);
    if (photo) {
      return 'Change ' + type + ' photo';
    } else {
      return 'Add ' + type + ' photo';
    }
  }

  parseRooms() {
    let rooms = [];
    if (!this.cleaning?.room_or_group_before_after_photos) {
      return rooms;
    }
    this.cleaning.room_or_group_before_after_photos.map((room) => {
      const roomAdded = rooms.find((item) => item.room_or_group_id == room.room_or_group_id);
      if (!roomAdded) {
        rooms.push(room);
      }
    });
    return rooms;
  }

  handleLoadErrors(error: any): void {
    const route = window.location.pathname;
    if (!route.includes('past-job')) {
      return;
    }
    console.error('error', error);
    this.timeoutHandler(error);
    const errorMessage = (error.error && error.error.message) ? error.error.message : error.message;
    this.util.showError(errorMessage, 10000);
  }

  async markMessagesAsRead() {
    let array: any = [];
    this.messages.map((message, index) => {
      if (!message.is_read && message.from_member.type == 'CustomerMember' && index < 3 && message?.chat_room_key) {
        array.push((message?.chat_room_key));
      }
    });
    if (array.length > 0) {
      await this.communication.markMessagesAsRead(array);
    }
  }

  checkIfShowParking() {
    const eligiblePerformances = [
      'homekeeper_reported_client_cancellation',
      'client_cancellation',
      'on_time',
      'left_early',
      'late',
      'no_access'
    ]
    return eligiblePerformances.includes(this.cleaning.homekeeper_job.performance);
  }

  checkIfShowWaiveFee() {
    const diff = moment().diff(moment(this.cleaning.job.date));
    const duration = moment.duration(diff);
    const jobIsWithinTwoWeeks = (duration.days() <= 14);
    if (!jobIsWithinTwoWeeks || this.cleaning.is_fee_waived) {
      return;
    }
    const array = this.paymentHistory.earnings_breakdown.payment_items;
    array.splice(6, 1);
    array.splice(6, 1);
    array.map((item) => {
      if (item.amount < 0) {
        return this.showWaiveFee = true;
      }
    })
  }

  goToPaymentPage(transferId) {
    this.navCtrl.navigateForward(`past-payout/${transferId}`);
  }

  goToDispute(disputeId) {
    this.navCtrl.navigateForward(`dispute-resolution/${disputeId}`);
  }

  disputePagesParams() {
    return {
      disputeTexts: this.disputeReasonText,
      disputeId: this.cleaning.dispute.id,
      jobId: this.cleaning.job.id
    };
  }

  goToReportIssue = () => {
    localStorage.setItem('pathAfterReportIssue', `past-job/${this.cleaning.job.id}`);
    const params = {
      job: this.cleaning,
      paymentHistory: this.paymentHistory
    }
    this.navCtrl.navigateForward('job-issue', params);
  }

  lostItem = () => {
    this.navCtrl.navigateForward(['lost-item', this.cleaning.job.id, this.cleaning.homekeeper_job.id]);
  }

  goToLearnMore() {
    this.iabUtils.openUrl('https://help.tidy.com/pros/payment-faqs#what-is-a-dispute');
  }

  private sumTotal(earnings: any[]): number {
    return earnings.reduce( (total, earning) => total + earning.amount, 0 );
  }

  parkingReimbursement = () => {
    this.navCtrl.navigateForward(['parking-reimbursement', this.cleaning.job.id]);
  }

  goToWaiveFeePage() {
    this.navCtrl.navigateForward(`waive-fee`);
  }

  checkIfShowLostItem(jobDate: string): boolean {
    if (this.cleaning.feedback_type === 'negative') {
      return false;
    }

    const diff = moment().diff(moment(jobDate));
    const duration = moment.duration(diff);
    return (duration.days() <= 5 && this.cleaning.homekeeper_job.performance === 'on_time');
  }

  async blacklistClient() {
    const params: ConfirmPageParamsModel = {
      title: 'Blacklist Client?',
      body: `Adding this Client to your blacklist will permanently remove them from your schedule.  The Client is not notified.`,
      backText: 'Go Back',
      confirmText: 'Blacklist Client',
      confirmAction: this.confirmBlacklist.bind(this),
    };

    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });

    confirmationModal.present();
  }

  async confirmBlacklist()  {
    await this.blacklist.blacklistClient(this.cleaning.customer.id);
    const params = this.getSuccesBlacklistParams();
    this.navCtrl.navigateRoot('success', params);
    this.modalCtrl.dismiss();
  }

  getSuccesBlacklistParams(): SuccessPageParamsModel {
    return {
      header: 'Client Blacklisted',
      body: 'The Client was permanently removed from your schedule.',
      buttonText: 'Ok',
      buttonRoute: `/past-job/${this.jobId}`
    };
  }

  getDisputeDescription(reason): any {
    const reasons = {
      left_early: 'you left early',
      equipment_issue: 'you didnt have all your equipment or brought the wrong equipment',
      no_show: 'you did not show up to the cleaning',
      didnt_clean: 'you showed up to the cleaning but did not clean at all',
      unauthorized_party: 'you brought an unauthorized third party to the home',
      false_updates: 'you provided false updates',
      theft: 'you stole something',
      damage: 'you caused some damage',
      no_mask: 'you were not wearing a mask',
      no_full_vacuum: 'you did not have your vacuum with you'
    }
    return reasons[reason];
  };

  async editMileage() {
    await this.storage.save('jobMileageBackRoute', `past-job/${this.jobId}`);
    this.navCtrl.navigateForward('job-mileage', this.jobMileage[0]);
  }

  @Loading()
  async goToConciergeMessage(conciergeItem) {
    await this.concierge.setProInfo();
    const params = {
      item: conciergeItem
    };
    this.navCtrl.navigateForward(`concierge-message/${conciergeItem.id}`, params);
  }

  isNewBasePayment(breakdown) {
    if (breakdown.description == 'Cleaning Base Payment' && breakdown.amount > 0 && breakdown.amount !== this.paymentHistory.job_data?.base_pay_rate) {
      this.hasNewBasePayment = true;
      return true;
    };
  }

  getIssueHeader(issue) {
    const issues = {
      'low_inventory': 'Reported Issue: Low Inventory',
      'damage': 'Reported Issue: Damage',
      'pest_report': 'Reported Issue: Pest Report',
      'utility_issue': 'Reported Issue: Utility Issue',
      'other': 'Other'
    };
    return issues[issue.issue_report_type.name];
  }

  openAttachment(attachmentKey: string) {
    const bucketUrl = AppConfig.AWS_S3_BUCKET_URL;
    const fileUrl = `${bucketUrl}${attachmentKey}`;
    this.iabUtils.openUrl(fileUrl);
  }

  goToJobMessagesPage() {
    const params = {
      cleaning: this.cleaning,
      proName: this.proName,
      timezone: this.timezone
    }
    this.navCtrl.navigateForward(`past-job-messages/${this.jobId}`, params);
  }

  hasPassed14DaysFromJobStartDate() {
    const diff = moment().diff(moment(this.cleaning.job.date));
    const duration = moment.duration(diff);
    return (duration.days() > 14);
  }

  contactClient(type) {
    if (type == 'attachment') {
      this.attachment();
      return;
    }
    if (type == 'message') {
      this.message();
      return;
    }
    this.call();
  }

  @Loading()
  async message(attachment = []) {
    this.errorMessage = '';
    this.submitted = true;
    if (attachment.length == 0 && !this.form.get('message').valid) {
      return;
    }
    const payload = {
      chat: {
        type: 'homekeeper_job',
        data: {
          homekeeper_job_id: this.cleaning.homekeeper_job_id
        }
      },
      message: {
        text: this.form.value.message,
        files: attachment
      }
    };
    try {
      await this.mwService.startCommunication(this.cleaning);
      const response = await this.communication.sendSharedInboxMessage(payload);
      if (response?.data?.message == 'We couldn\'t send your message, please try again later!') {
        return this.errorMessage = response.data.message;
      }
      this.form.patchValue({
        message: ''
      });
      this.messages = await this.communication.getSharedInboxForHomekeeperJob(this.cleaning.homekeeper_job_id);
      this.submitted = false;
    } catch (err) {
      const error = await this.mwService.buildErrorAlert(err);
      return this.showErrorMessage(error);
    }
  }

  async attachment() {
    this.errorMessage = '';
    try {
      const attachment = await this.communication.addAttachment();
      if (attachment == '') {
        return this.errorMessage = 'Unable to attach photo. Please upload a PNG or JPEG file.';
      }
      await this.message([attachment]);
    } catch (err) {
      this.errorMessage =  err.error ? err.error.message : err.message;
    }
  }

  async call() {
    try {
      await this.mwService.startCommunication(this.cleaning);
    } catch (err) {
      const error = await this.mwService.buildErrorAlert(err);
      return this.showErrorMessage(error);
    }
    window.location.href = `tel:${this.cleaning.job.gateway_phone}`;
  }

  showErrorMessage(message) {
    this.errorMessage = message;
    setTimeout(() => this.errorMessage = null, 10000);
  }
}
