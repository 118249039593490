<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [customBack]="'/more'"
    [canGoBack]="true"
    [title]="'Learn About TIDY'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-two-column-row>
      <tidy-card item-left>
        <tidy-row [align]="'center'">
          <iframe
            src="https://player.vimeo.com/video/452290169?playsinline=false"
            style="height: 100px"
            frameborder="0"
            webkitallowfullscreen mozallowfullscreen allowfullscreen>
          </iframe><br>
          <tidy-text
            [body]="'Existing Clients'">
          </tidy-text>
        </tidy-row>
      </tidy-card>
      <tidy-card item-right>
        <tidy-row [align]="'center'">
          <iframe
            src="https://player.vimeo.com/video/452288772?playsinline=false"
            style="height: 100px"
            frameborder="0"
            webkitallowfullscreen mozallowfullscreen allowfullscreen>
          </iframe><br>
          <tidy-text
            [body]="'New Clients'">
          </tidy-text>
        </tidy-row>
      </tidy-card>
    </tidy-two-column-row>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
