import { Component, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { Loading } from 'src/shared/components/loading/loading';

@Component({
  selector: 'other-options',
  templateUrl: 'other-options.html',
  encapsulation: ViewEncapsulation.None
})

export class OtherOptionsPage {

  clientName: string;
  jobsFromSameClient: any;
  loaded: boolean;
  today: string;

  constructor(
    private navCtrl: CustomNavController,
  ) {}

  @Loading()
  async ionViewDidEnter() {
    this.loaded = false;
    this.jobsFromSameClient = this.navCtrl.getParam('jobsFromSameClient', true);
    this.clientName = this.navCtrl.getParam('clientName', true);
    this.today = moment().format('YYYY-MM-DD');
    this.loaded = true;
  }

  goToJob(job) {
    const params = {
      cleaning: job,
      homeLongitude: this.navCtrl.getParam('homeLongitude'),
      homeLatitude: this.navCtrl.getParam('homeLatitude'),
      jobsFromSameClient: this.jobsFromSameClient,
      jobsGroupedByJobId: this.navCtrl.getParam('jobsGroupedByJobId')
    }
    this.navCtrl.navigateForward('claim-job', params);
  }

  parseServiceLength(name) {
    const lastIndex = name.lastIndexOf(" ");
    return name.substring(0, lastIndex);
  }
}
