import { Component } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Component({
  templateUrl: 'mw.exit-job.page.html'
})

export class ExitJobPage {

  constructor(
    private navCtrl: CustomNavController
  ) {}

  goToCallOutPage() {
    this.navCtrl.navigateForward('cancel-job');
  }

  goToClientRefusedServicePage() {
    this.navCtrl.navigateForward('client-refused-service');
  }

  goBack() {
    this.navCtrl.back();
  }
}
