import { DeviceInfoProvider } from 'src/shared/providers/device-info';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { HttpClient } from 'src/shared/providers/http/http-client';

@Injectable()
export class ActiveInCommunity {

  constructor(
    private httpClient: HttpClient,
    private platform: Platform,
    private deviceInfo: DeviceInfoProvider,
  ) {}

  completedReviews(hkId) {
    const url = `api/v1/homekeeper/community-reviews?homekeeper_id=${hkId}`;
    return this.httpClient.get(url);
  }

  saveCommunityReview(review) {
    const url = `api/v1/homekeeper/community-reviews`;
    const data = {
      homekeeper_id: review.hkId,
      review_destination: review.destination,
      review_text: review.text,
    };
    return this.httpClient.post(url, data);
  }

  publicPlaces() {
    const platform = this.platform.is('ios') ? 'ios' : 'android';
    const urls = this.postHereUrls(platform);

    const places = [
      {
        title: 'Google Places',
        description: 'promoting your business in a positive light.',
        postHere: 'https://search.google.com/local/writereview?placeid=ChIJk6z4PP_f3IARmhnYCfUr6gU',
        destination: 'google_places'
      },
      {
        title: 'Your own Yelp page',
        description: 'Simply provide us a link to your Yelp page showing an active business.',
        destination: 'yelp'
      },
      {
        title: 'Your own Facebook Page',
        description: 'Simply provide us a link to your Facebook page showing an active business.',
        destination: 'facebook'
      },
      {
        title: 'Your own Twitter',
        description: 'Simply provide us a link to your Twitter page showing an active business.',
        destination: 'twitter'
      },
      {
        title: 'Your own Instagram',
        description: 'Simply provide us a link to your Instagram page showing an active business.',
        destination: 'instagram'
      },
      {
        title: 'Your own webpage',
        description: 'Simply provide us a link to your webpage showing an active business.',
        destination: 'webpage'
      },
      {
        title: 'Your consumer affairs page',
        description: 'Simply provide us a link to your Consumer Affairs profile showing an active business.',
        destination: 'consumer_affairs'
      },
      {
        title: 'Your own Angies List profile',
        description: 'Simply provide us a link to your Angies List profile showing an active business.',
        destination: 'angies_list'
      }
    ];
    const isNativeMobile = this.deviceInfo.isNativeMobile();
    const nativeMobilePlaces = [
      {
        title: 'TIDY Client App',
        description: 'promoting your business in a positive light.',
        postHere: urls.client,
        destination: `${platform}_client`
      },
      {
        title: 'TIDY Homekeeper App',
        description: 'promoting your business in a positive light.',
        postHere: urls.homekeeper,
        destination: `${platform}_homekeeper`
      },
    ]
    if (isNativeMobile) {
      places.unshift(...nativeMobilePlaces);
    }

    return places;
  }

  postHereUrls(platform) {
    const urls = {
      ios: {
        client: 'itms-apps://itunes.apple.com/us/app/itunes-u/id1133337954?action=write-review',
        homekeeper: 'itms-apps://itunes.apple.com/us/app/itunes-u/id1518099748?action=write-review'
      },
      android: {
        client: 'market://details?id=com.tidy.clientmobile&hl=en_US',
        homekeeper: 'market://details?id=com.tidy.pro&hl=en_US'
      }
    };

    return urls[platform];
  }

  extractDestinationsComplete(reviews) {
    const destinations = [
      'ios_homekeeper',
      'android_homekeeper',
      'ios_client',
      'android_client',
      'google_places',
      'yelp',
      'facebook',
      'twitter',
      'instagram',
      'webpage',
      'consumer_affairs',
      'angies_list'
    ];

    const detinationsComplete = {};
    destinations.forEach(destination => {
      detinationsComplete[destination] = false;
    });

    reviews.forEach(review => {
      detinationsComplete[review.review_destination] = true;
    });

    return detinationsComplete;
  }

  getDestinationContent(destination) {
    const places = this.publicPlaces();
    return places.find(place => {
      return place.destination === destination;
    });
  }
}
