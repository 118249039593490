<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="!cameFromJobLink"
    [backButton]="true"
    [title]="loaded ? (cameFromJobLink ? 'Job Completed!' : 'Log In') : ''"
    [customBack]="'introduction'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'" *ngIf="cameFromJobLink">
      <tidy-text
        *ngIf="!isPrivateJob"
        [body]="'Log in to see details for this job. You can also browse available jobs. It\'s free to use.'">
      </tidy-text>
      <tidy-text
        *ngIf="isPrivateJob"
        [body]="'Log in to send an invoice & instantly request payment. You can also browse available jobs. It\’s free to use.'">
      </tidy-text>
    </tidy-row>

    <tidy-card>
      <form [formGroup]="loginForm" novalidate action="#">
        <tidy-row class="field-top-padding field-bottom-padding">
          <tidy-input
            formControlName="username"
            [form]="loginForm.controls.username"
            label="Email"
            [icon]="'assets/svg/mail-outline.svg'"
            [activeIcon]="'assets/svg/mail-outline-tidy-green.svg'"
            data-cy="email-login"
            [errorMessage]="'Please enter a valid email'"
            [submitted]="submitted"
            type="email"
          >
          </tidy-input>
        </tidy-row>
        <tidy-row>
          <tidy-input
            formControlName="password"
            [form]="loginForm.controls.password"
            label="Password"
            [icon]="'assets/svg/lock-closed-outline.svg'"
            [activeIcon]="'assets/svg/lock-closed-outline-tidy-green.svg'"
            data-cy="password-login"
            type="password"
            [errorMessage]="'Please enter a password'"
            [submitted]="submitted"
          >
          </tidy-input>
        </tidy-row>
      </form>
    </tidy-card>

    <tidy-error-message [text]="errorMessage"></tidy-error-message>

    <tidy-row>
      <tidy-button
        [text]="'Log In'"
        [action]="onSubmit.bind(this)"
        class="primary"
        data-cy="button-login"
      >
      </tidy-button>
    </tidy-row>
    <tidy-row align="center">
      <tidy-text
        class="link"
        data-cy="forgot-password-login"
        helper="Forgot Password"
        (action)="goToForgotPassword()"
      ></tidy-text>
    </tidy-row>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
