import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Team } from 'src/providers/team/team';
import { PrivateClient } from 'src/providers/private-client/private-client';

import { Loading } from 'src/shared/components/loading/loading';

import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';
import { TeamModel } from 'src/shared/models/team.model';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  selector: 'private-services',
  templateUrl: './private-services.html'
})

export class PrivateServicesPage extends Timeout {

  costs: TidySelectNumberValueModel[];
  certifiedForPage: boolean;
  errorMessage: string;
  form: UntypedFormGroup;
  lengths: TidySelectNumberValueModel[];
  savingText = 'All Changes Saved.';
  website: TeamModel;

  constructor(
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private team: Team,
    private privateClient: PrivateClient,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.fb.group({
      minLength: [''],
      maxLength: [''],
      minCost: [''],
      hourlyRate: [''],
    });
  }

  @Loading()
  async ionViewDidEnter() {
    try {
      this.loaded = false;
      this.costs = this.getCosts();
      this.lengths = this.getLengths();
      this.website = await this.team.getMyTeam();
      this.loaded = true;

      this.form.patchValue({
        minLength: this.website?.service_types[0]?.min_length,
        maxLength: this.website?.service_types[0]?.max_length,
        minCost: this.website?.service_types[0]?.min_cost,
        hourlyRate: this.website?.service_types[0]?.hourly_rate,
      })
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  async saveChanges() {
    this.errorMessage = '';
    this.savingText = 'Saving Changes...';
    try {
      await this.privateClient.updateServices(this.prepareData(), this.website.service_types[0].id);
      this.savingText = 'All Changes Saved.';
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  prepareData(): any {
    return {
      min_length: this.form.value.minLength,
      max_length: this.form.value.maxLength,
      min_cost: this.form.value.minCost,
      hourly_rate: this.form.value.hourlyRate,
    }
  }

  getCosts(): TidySelectNumberValueModel[] {
    let array = [];
    for(let i=1; i<101; i++) {
      array.push({
        viewValue: `$${i}`,
        value: i*100
      })
    }
    return array;
  }

  getLengths(): TidySelectNumberValueModel[] {
    let array = [];
    for(let i=1; i<20; i++) {
      array.push({
        viewValue: `${i*30} ${new TranslationPipe().transform('minutes')}`,
        value: i*30
      })
    }
    return array;
  }

  goToUpcomingFeaturePage(feature) {
    this.customNavCtrl.navigateForward('upcoming-feature', {feature: feature});
  }
}
