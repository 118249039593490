<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav [class]="extraClass" [canGoBack]="true" title="Change Address">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-card>
        <form [formGroup]="hkAddressForm" novalidate action="#">
          <tidy-row class="field-top-padding field-bottom-padding">
            <tidy-select
              [icon]="'assets/svg/home-outline.svg'"
              [label]="'Country'"
              [items]="countryList"
              [form]="hkAddressForm.get('countryCode')"
              formControlName="countryCode"
              (optionChange)="changeCountrySelected($event)"
            >
            </tidy-select>
          </tidy-row>

          <tidy-row class="field-bottom-padding">
            <tidy-auto-complete-address
              [submitted]="submitted"
              [addressFormControl]="hkAddressForm.controls.address"
              [countryCode]="hkAddressForm.controls.countryCode.value"
              (onAddressChosen)="choseItem($event)"
              (onError)="errorMessage = $event"
              (noAddressPickedUpdate)="noAddressPickedTemplate = $event"
              (invalidAddressError)="invalidAddressError = $event"
            ></tidy-auto-complete-address>
          </tidy-row>

          <tidy-row class="field-bottom-padding">
            <tidy-input
              formControlName="address2"
              [form]="hkAddressForm.controls.address2"
              label="Unit / Apartment"
              [errorMessage]="'Please enter a valid address'"
              [submitted]="submitted"
              [icon]="'assets/svg/home-outline.svg'"
              [activeIcon]="'assets/svg/home-outline-tidy-green.svg'">
            </tidy-input>
          </tidy-row>

          <tidy-row>
            <tidy-input
              formControlName="zipcode"
              [customMask]="zipCodeMask"
              [form]="hkAddressForm.controls.zipcode"
              [errorMessage]="'Please enter a valid zipcode'"
              [submitted]="submitted"
              [inputMode]="'numeric'"
              label="Zip Code"
              [icon]="'assets/svg/home-outline.svg'"
              [activeIcon]="'assets/svg/home-outline-tidy-green.svg'">
            </tidy-input>
          </tidy-row>
        </form>
      </tidy-card>

      <ng-container *ngTemplateOutlet="noAddressPickedTemplate">
      </ng-container>

      <tidy-error-message
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-button
        text="Confirm Change"
        class="primary"
        [action]="checkChange.bind(this)">
      </tidy-button>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Note: Your work area is based off this address, so updating your address will change your work area.  Do not use a PO Box.  Your physical location is used to pair you with clients, and changing to a PO Box will cause you to lose 100% of clients and be un-bookable.'">
        </tidy-text>
      </tidy-row>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
