import { Component } from '@angular/core';

import { Certification } from 'src/providers/certification/certification';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

@Component({
  selector: 'review-terms',
  templateUrl: 'review-terms.html',
})
export class OnboardingReviewTermsPage {

  errorMessage: string;
  didTapTerms: boolean;

  constructor(
    private certification: Certification,
    private customNavCtrl: CustomNavController,
    private iabUtils: InAppBrowserUtils
  ) {}

  goToTerms() {
    this.didTapTerms = true;
    this.iabUtils.openUrl('https://www.tidy.com/legal/service-provider');
  }

  async confirm() {
    this.errorMessage = '';
    if (!this.didTapTerms) {
      return this.errorMessage = `You must tap “Terms of Use” and review the terms before confirming.`;
    }
    try {
      await this.certification.finishStep('review_terms', {});
      this.customNavCtrl.navigateForward('new-clients');
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }
}
