import { Events } from 'src/providers/events/events';
import { Injectable, NgZone } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Network } from '@capacitor/network';

declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class AppState {

  private POOR_CONNECTION_TIMEOUT = 5 * 60 * 1000;

  public online;
  public active = true;

  constructor(
    private events: Events,
    private ngZone: NgZone,
    private platform: Platform
  ) {
    this.subscribePertinentEvents();
  }

  async isOnline() {
    if (this.online === undefined) {
      const networkStatus = await Network.getStatus();
      this.online = networkStatus.connected;
    }
    return this.online;
  }

  public fireCurrentState(): void {
    this.onConnectionChange(this.online);
    this.onStateChange(this.active);
  }

  public setPoorConnectionState(): void {
    this.onConnectionChange(false);
    setTimeout( () => this.onConnectionChange(true), this.POOR_CONNECTION_TIMEOUT );
  }

  private subscribePertinentEvents(): void {
    window.addEventListener('online', () => this.onConnectionChange(true));
    window.addEventListener('offline', () => this.onConnectionChange(false));

    this.platform.ready().then(async () => {
      this.platform.pause.subscribe( () => this.onStateChange(false) );
      this.platform.resume.subscribe( () => this.onStateChange(true) );
      this.online = await this.isOnline();

    });
  }

  onConnectionChange(online): void {
    this.ngZone.run( () =>  {
      this.online = online;
      this.events.publish('app:connection', {online: this.online});
    });
  }

  private onStateChange(active): void {
    this.ngZone.run( () =>  {
      this.active = active;
      this.events.publish('app:state', {active: this.active});
    });
  }
}
