import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import * as moment from 'moment';

import { MWService } from "src/main-workflow/mw.service";
import { MWStore } from "src/main-workflow/mw.store";

import { AppState } from 'src/shared/providers/http/app-state';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { SuccessPage, SuccessPageParamsModel } from 'src/pages/success/success';

import { CompleteJobModal } from 'src/shared/components/complete-job-modal/complete-job-modal';
import { Logger } from 'src/shared/providers/logger';


@Injectable({ providedIn: 'root' })
export class SyncDataGuard implements CanActivate {

  constructor(
    private appState: AppState,
    private customNavCtrl: CustomNavController,
    private modalCtrl: ModalController,
    private mwService: MWService,
    private mwStore: MWStore,
    private logger: Logger
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      if (!await this.appState.isOnline()) {
        await this.showOfflineAlert();
        return false;
      }
      const syncData = await this.mwStore.getSyncData();
      if (syncData !== null && syncData !== undefined) {
        if (syncData.shouldSync || await this.mwService.isTooLateForJob(syncData.job.jobEndTime)) {
          const modal = await this.modalCtrl.create({
            component: CompleteJobModal
          });
          modal.present();
        }
      }
    } catch (err) {
      this.logger.error(err, 'sync-data-guard-error');
    }
    return true;
  }

  async showOfflineAlert() {
    let modalParams: SuccessPageParamsModel = {
      header: 'Connect to the Internet to Continue',
      body: 'The app works offline for some things, but not everything.  Connect to the internet to take the next steps.',
      buttonText: 'Try Again - I\'m Online',
      buttonRoute: `jobs`,
      customAction: this.dismissOfflineModal.bind(this)
    };

    const toDos = await this.mwStore.getToDos();
    const arrivedAtJobInProgress = toDos !== null && toDos !== undefined;
    if (arrivedAtJobInProgress) {
      modalParams['header'] = 'Continue Job Offline?',
      modalParams['body'] = 'You appear to be offline. Would you like to continue your job offline or try to go to the dashboard once online?',
      modalParams['buttonText'] = 'Go to Dashboard',
      modalParams['secondButtonText'] = 'Continue Job Offline',
      modalParams['secondCustomAction'] = this.goToInProgressJob.bind(this)
    }

    const modal = await this.modalCtrl.create({
      component: SuccessPage,
      componentProps: {modalParams}
    });
    modal.present();
  }

  dismissOfflineModal() {
    this.modalCtrl.dismiss();
    this.customNavCtrl.navigateRoot('jobs');
  }

  async goToInProgressJob() {
    this.modalCtrl.dismiss();
    const route = await this.mwStore.getRoute();
    this.customNavCtrl.navigateRoot(route);
  }
}
