<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [title]="'To-Do List'"
    [class]="extraClass"
    [canGoBack]="false"
    [noBow]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="list?.shared_link_data?.customer?.first_name +
          (' uses TIDY to manage their home service jobs. ' | translate)">
        </tidy-text>
        <tidy-text
          [actionBody]="'Learn More'"
          class="link"
          (action)="goToLearnMore()">
        </tidy-text>
      </tidy-row>
    
      <ng-container *ngIf="screenwidth < 1000">
        <slider-trigger
          [label]="toDos.getSliderText('SharedToDoListPage')"
          [onTriggeredAction]="completeList.bind(this)">
        </slider-trigger>
      </ng-container>
    
      <ng-container *ngIf="screenwidth >= 1000">
        <tidy-button
          [text]="toDos.getSliderText('SharedToDoListPage')"
          class="primary"
          [action]="completeList.bind(this)">
        ></tidy-button>
      </ng-container>
    
      <tidy-row [align]="'center'" *ngIf="toDos.didStartJob">
        <tidy-text
          [body]="'Client was told you have <b>started the job</b>.'">
        </tidy-text>
      </tidy-row>
    
      <tidy-card>
        <tidy-row class="vertical-align-center extra-bottom-padding">
          <tidy-image
            [src]="'assets/img/list.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="list?.shared_link_data?.address_task_list?.title">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/home.svg'">
          </tidy-image>
          <tidy-text
            [body]="('Address: ' | translate) + list?.shared_link_data.address?.address"
            style="margin-right:5px">
          </tidy-text>
          <tidy-text
            [body]="'Get Directions'"
            (action)="getDirections()"
            class="link">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/account.svg'">
          </tidy-image>
          <tidy-text
            [body]="('Client: ' | translate) +
            list?.shared_link_data?.customer?.first_name"
            style="margin-right:5px">
          </tidy-text>
          <tidy-text
            *ngIf="showContactButton"
            [actionBody]="'Contact'"
            (action)="goToContactPage()"
            class="link">
          </tidy-text>
        </tidy-row>
      </tidy-card>
    
      <tidy-card *ngIf="mapUrl">
        <form [formGroup]="form">
          <tidy-row class="extra-bottom-padding" [alignLastItemRight]="mapItems?.length > 1" [ngClass]="mapItems?.length > 1 ? '' : 'vertical-align-center'">
            <tidy-image
              [src]="'assets/img/house-sketch.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Property Map'">
            </tidy-text>
            <tidy-select
              *ngIf="mapItems?.length > 1"
              [items]="mapItems"
              formControlName="map"
              [form]="form.controls.map"
              (optionChange)="changeMap($event)"
              style="width:20px">
            </tidy-select>
          </tidy-row>
          <div class="map-wrap">
            <iframe class="frame" [src]="mapUrl"></iframe>
          </div>
        </form>
      </tidy-card>
    
      <tidy-card>
        <tidy-row class="vertical-align-center">
        <tidy-image
            [src]="'assets/img/car.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Parking'">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="list?.shared_link_data?.address?.parking?.paid_parking !== null || list?.shared_link_data?.address?.parking?.parking_notes">
          <tidy-text
            *ngIf="list?.shared_link_data?.address?.parking?.paid_parking !== null"
            [body]="list?.shared_link_data?.address?.parking?.paid_parking ? 'Paid parking ' : 'Free parking '">
          </tidy-text>
          <tidy-text
            *ngIf="list?.shared_link_data?.address?.parking?.parking_spot"
            [body]="toDos.parkingDescriptions[list?.shared_link_data?.address?.parking?.parking_spot]">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="list?.shared_link_data?.address?.parking?.max_parking_cost">
          <tidy-text
            [body]="('Client will cover up to ' | translate) + (list?.shared_link_data?.address?.parking?.max_parking_cost | tcurrency)">
          </tidy-text>
          <tidy-text
            *ngIf="list?.shared_link_data?.address?.parking?.parking_pay_with"
            [body]="(' and reimburse ' | translate) + (list?.shared_link_data?.address?.parking?.parking_pay_with == 'with cash' ? ('cash.' | translate) : ('through the TIDY app.' | translate))">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="list?.shared_link_data?.address?.parking?.parking_notes">
          <tidy-text
            [body]="('Client Notes: ' | translate) + list?.shared_link_data?.address?.parking?.parking_notes"
            [translate]="false">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="!list?.shared_link_data?.address?.parking?.parking_notes && list?.shared_link_data?.address?.parking?.paid_parking == null">
          <tidy-text
            [body]="'<i>No Parking Notes</i>'">
          </tidy-text>
        </tidy-row>
        <tidy-carrousel *ngIf="parkingPhotos?.length > 0" >
          <ng-container *ngFor="let photo of parkingPhotos">
            <tidy-photo-note
              class="full-width there-is-carrousel no-margin"
              *tidyCarrousel
              [caption]="photo?.photo_note_caption"
              [src]="photo?.photo_url">
            </tidy-photo-note>
          </ng-container>
        </tidy-carrousel>
      </tidy-card>
    
      <tidy-card>
        <tidy-row class="vertical-align-center">
        <tidy-image
            [src]="'assets/img/notes.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Access'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="list?.shared_link_data?.address?.home_access">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="!list?.shared_link_data?.address?.home_access">
          <tidy-text
            [body]="'<i>No Access Notes</i>'">
          </tidy-text>
        </tidy-row>
        <tidy-carrousel *ngIf="accessPhotos?.length > 0" >
          <ng-container *ngFor="let photo of accessPhotos">
            <tidy-photo-note
              class="full-width there-is-carrousel no-margin"
              *tidyCarrousel
              [caption]="photo?.photo_note_caption"
              [src]="photo?.photo_url">
            </tidy-photo-note>
          </ng-container>
        </tidy-carrousel>
      </tidy-card>
    
      <tidy-to-dos
        [page]="'SharedToDoListPage'"
        [sharedList]="list"
        [rooms]="list?.shared_link_data?.address_task_list?.address_rooms">
      </tidy-to-dos>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
