import { Pipe, PipeTransform } from '@angular/core';
import { BeforeAfterPhotoType } from 'src/main-workflow/mw.enums';
import { PrivateJobBeforeAfterPhoto } from 'src/shared/models/job-details.model';

@Pipe({
  name: 'takenPhotos'
})
export class TakenPhotosPipe implements PipeTransform {

  transform(beforeAfterPhotos: PrivateJobBeforeAfterPhoto[], type: BeforeAfterPhotoType, checkAlltaken = false): number | boolean {
    const takenPhotosNumber = beforeAfterPhotos
                                .filter(photo => photo[type])
                                .length;

    if (checkAlltaken) {
      return beforeAfterPhotos.length === takenPhotosNumber;
    }

    return takenPhotosNumber;
  }
}
