<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Get Help'"
    [canGoBack]="true"
    [customBack]="'concierge'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <!--TODO replace inline style https://trello.com/c/XpKJZqE7/9010-f-ca-address-inline-styles-->
    <tidy-card style="padding: 15px 15px 15px 10px">
      <tidy-row [align]="'center'" class="extra-bottom-padding">
        <tidy-text
          [title]="'What can we help with?'">
        </tidy-text>
      </tidy-row>

      <tidy-card-button (action)="howToUseTidy()">
        <tidy-grid>
          <tidy-row>
            <ion-row>
              <ion-col size="2" class="vertical-align-center">
                <div style="margin: auto">
                  <tidy-image
                    [src]="'assets/icon/home-heart.svg'"
                    style="width:25px">
                  </tidy-image>
                </div>
              </ion-col>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'How to Use TIDY'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'e.g. How do Clients book with me?'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-grid>
      </tidy-card-button>
      <tidy-card-button (action)="jobIssue()">
        <tidy-grid>
          <tidy-row>
            <ion-row>
              <ion-col size="2" class="vertical-align-center">
                <div style="margin: auto">
                  <tidy-image
                    [src]="'assets/icon/repair-tools.svg'"
                    style="width:25px">
                  </tidy-image>
                </div>
              </ion-col>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'Job Issue'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'e.g. The job is marked incorrectly.'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-grid>
      </tidy-card-button>
      <tidy-card-button (action)="paymentIssue()">
        <tidy-grid>
          <tidy-row>
            <ion-row>
              <ion-col size="2" class="vertical-align-center">
                <div style="margin: auto">
                  <tidy-image
                    [src]="'assets/icon/billing-issue.svg'"
                    style="width:25px">
                  </tidy-image>
                </div>
              </ion-col>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'Bank Payout Issue'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'e.g. My payment hasn\'t arrived.'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-grid>
      </tidy-card-button>
      <tidy-card-button (action)="managingTeam()">
        <tidy-grid>
          <tidy-row>
            <ion-row>
              <ion-col size="2" class="vertical-align-center">
                <div style="margin: auto">
                  <tidy-image
                    [src]="'assets/icon/team.svg'"
                    style="width:25px">
                  </tidy-image>
                </div>
              </ion-col>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'Managing My Team'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'e.g. How do I delegate a job?'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-grid>
      </tidy-card-button>
      <tidy-card-button (action)="managingPrivateClients()">
        <tidy-grid>
          <tidy-row>
            <ion-row>
              <ion-col size="2" class="vertical-align-center">
                <div style="margin: auto">
                  <tidy-image
                    [src]="'assets/icon/top-performers.svg'"
                    style="width:25px">
                  </tidy-image>
                </div>
              </ion-col>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'Managing Private Clients'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'e.g. How do I add a private job?'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-grid>
      </tidy-card-button>
      <tidy-card-button (action)="otherQuestion()">
        <tidy-grid>
          <tidy-row>
            <ion-row>
              <ion-col size="2" class="vertical-align-center">
                <div style="margin: auto">
                  <tidy-image
                    [src]="'assets/icon/help.svg'"
                    style="width:25px">
                  </tidy-image>
                </div>
              </ion-col>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'Other'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'Note: this is the slowest option'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-grid>
      </tidy-card-button>
    </tidy-card>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
