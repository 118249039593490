import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PrivatePayments } from 'src/providers/private-payments/private-payments';

import { Loading } from 'src/shared/components/loading/loading';

import { BillingHistoryModel } from 'src/models/private-payments.model';
import { SuccessPageParamsModel } from 'src/pages/success/success';
import { removeCurrencyMask } from 'src/shared/utils/currency-utils';

@Component({
  templateUrl: 'record-payment.html',
  encapsulation: ViewEncapsulation.None
})

export class RecordPaymentPage {

  amount: any;
  billingHistory: BillingHistoryModel;
  clientName: any;
  date: any;
  errorMessage: string;
  form: UntypedFormGroup;
  length: any;
  loaded: boolean;
  startTime: any;
  submitted: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private privatePayments: PrivatePayments,
    private route: ActivatedRoute
  ) {
    this.form = this.fb.group({
      amount: ['', Validators.required],
    });
  }

  @Loading()
  async ionViewDidEnter() {
    this.loaded = false;
    this.length = this.navCtrl.getParam('length');
    this.date = this.navCtrl.getParam('date');
    this.startTime = this.navCtrl.getParam('startTime');
    this.clientName = this.navCtrl.getParam('clientName');
    this.amount = this.navCtrl.getParam('amount');
    this.loaded = true;
  }

  goToClientDetails() {
    const clientId = this.navCtrl.getParam('clientId');
    this.navCtrl.navigateForward(`private-client/${clientId}`);
  }

  async recordPayment() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    try {
      const jobId = this.navCtrl.getParam('jobId');
      const payload = {
        pay_with: "cash",
        amount: removeCurrencyMask(this.form.value.amount),
        job_id: jobId
      };
      await this.privatePayments.recordPayment(payload);
      const successParams: SuccessPageParamsModel = {
        header: 'Payment Recorded',
        body: '',
        buttonText: 'Ok',
        buttonRoute: `private-job/${jobId}`
      };
      this.navCtrl.navigateForward('success', successParams);
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }
}
