import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Unpause } from 'src/providers/unpause/unpause';

import { SuccessPageParamsModel } from 'src/pages/success/success';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';
import { HomekeeperModel } from 'src/shared/models/homekeeper.model';
import { Me } from 'src/shared/providers/me';

@Component({
  templateUrl: './unpause.html'
})

export class UnpausePage {

  errorMessage: string;
  form: UntypedFormGroup;
  nextStep = false;
  submitted = false;

  constructor(
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private iabUtils: InAppBrowserUtils,
    private unpause: Unpause,
    private me: Me,
  ) {
    this.form = this.fb.group({
      terms: ['', Validators.requiredTrue]
    });
  }

  async confirmUnpause() {
    this.errorMessage = '';
    if (!this.form.valid) {
      return this.errorMessage = 'You must agree to the terms to unpause your account.';
    }
    try {
      const hk: HomekeeperModel = await this.me.fetchWithoutCache();
      await this.unpause.unpause(hk.user.id);
      const successParams: SuccessPageParamsModel = {
        header: 'Account Unpaused',
        body: `Your account is now unpaused.`,
        buttonText: 'Ok',
        buttonRoute: `dashboard`
      };
      this.customNavCtrl.navigateForward('success', successParams);
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  goToTerms() {
    this.iabUtils.openUrl('https://www.tidy.com/terms');
  }
}
