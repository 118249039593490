import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Team } from 'src/providers/team/team';

import { Loading } from 'src/shared/components/loading/loading';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

import { TidySelectNumberValueModel, TidySelectStringValueModel } from 'src/models/tidy-select-item.model';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { removeCurrencyMask } from 'src/shared/utils/currency-utils';

@Component({
  selector: 'add-service',
  templateUrl: './add-service.html'
})

export class AddServicePage extends Timeout {

  allowOnWebsite = true;
  billingTypeItems: TidySelectStringValueModel[];
  categories: any;
  errorMessage: string;
  form: UntypedFormGroup;
  categoryItems: TidySelectNumberValueModel[];
  frequencyItems: TidySelectStringValueModel[];
  serviceCostLabel: string;
  showAdvancedSettings: boolean;
  showServiceCost: boolean;
  submitted: boolean;
  timeToBlockItems: TidySelectNumberValueModel[];
  timeToBlockLabel: string;

  constructor(
    private fb: UntypedFormBuilder,
    private iabUtils: InAppBrowserUtils,
    private navCtrl: CustomNavController,
    public team: Team,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.fb.group({
      billingType: ['', Validators.required],
      category: ['', Validators.required],
      description: ['', Validators.required],
      frequency: ['', Validators.required],
      serviceCost: ['', Validators.required],
      serviceName: ['', Validators.required],
      timeToBlock: ['', Validators.required]
    });
  }

  @Loading()
  async ionViewDidEnter() {
    try {
      this.loaded = false;
      await this.buildFormItems();
      this.form.patchValue({
        frequency: 'one_time_or_recurring',
        category: 1,
        billingType: 'flat_rate'
      });
      this.showServiceCost = true;
      this.serviceCostLabel = 'Flat Rate';
      this.timeToBlockLabel = 'Block Off My Schedule For';
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  changeBillingType(type) {
    if (type == 'flat_rate') {
      this.showServiceCost = true;
      this.serviceCostLabel = 'Flat Rate';
      this.timeToBlockLabel = 'Block Off My Schedule For';
      this.form.controls.serviceCost.setValidators([Validators.required]);
    } else if (type == 'hourly') {
      this.showServiceCost = true;
      this.serviceCostLabel = 'Hourly Rate';
      this.timeToBlockLabel = 'Minimum Length';
      this.form.controls.serviceCost.setValidators([Validators.required]);
    } else {
      this.showServiceCost = false;
      this.timeToBlockLabel = 'Block Off My Schedule For';
      this.form.get('serviceCost').clearValidators();
      this.form.get('serviceCost').updateValueAndValidity();
    }
  }

  async buildFormItems() {
    this.billingTypeItems = this.team.buildBillingTypeItems();
    this.categories = this.navCtrl.getParam('categories') || await this.team.getServiceTypes();
    this.categoryItems = await this.team.buildCategoryItems(this.categories);
    this.frequencyItems = this.team.buildFrequencyItems();
    this.timeToBlockItems = this.team.buildJobHourItems();
  }

  async addService() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    try {
      const payload: any = {
        name: this.form.value.serviceName,
        description: this.form.value.description,
        service_type_id: this.form.value.category,
        frequency_options: this.form.value.frequency,
        duration_to_block_off: this.form.value.timeToBlock,
        billing_type: this.form.value.billingType,
        bookable_online: this.allowOnWebsite,
      };
      if (this.form.value.billingType == 'flat_rate' || this.form.value.billingType == 'hourly') {
        payload.user_new_rate = removeCurrencyMask(this.form.value.serviceCost);
        payload.user_repeat_rate = removeCurrencyMask(this.form.value.serviceCost);
      } else if (this.form.value.billingType == 'price_later') {
        payload.user_new_rate = 1;
        payload.user_repeat_rate = 1;
      } else if (this.form.value.billingType == 'free') {
        payload.user_new_rate = 0;
        payload.user_repeat_rate = 0;
      }
      await this.team.addService(payload);
      const params = {
        header: 'Service Added',
        body: '',
        buttonText: 'Ok',
        buttonRoute: 'services'
      };
      this.navCtrl.navigateForward('success', params);
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  toggleShowAdvancedSettings() {
    this.showAdvancedSettings = true;
  }

  goToScheduleCallPage() {
    this.iabUtils.openUrl('https://www.tidy.com/schedule-pro-support');
  }

  goToRequestNewServiceType() {
    this.navCtrl.navigateForward('upcoming-feature', {feature: 'other_services'});
  }

  getServiceName() {
    const category = this.categoryItems.find(category => category.value == this.form.value.category);
    return category.viewValue;
  }

  getServiceIcon() {
    const category = this.categories.find((category) => category.id == this.form.value.category);
    return category.icon_url;
  }

}
