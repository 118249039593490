import { Injectable } from '@angular/core';
import { AppConfig } from './config';
import { HttpClient } from '@angular/common/http';
import { Logger } from './logger';
declare const google: any;

@Injectable()
export class GoogleGeocode {

  private mapsBaseUrl = 'https://maps.googleapis.com/maps/api/geocode/json';
  googleGeocoder;

  constructor(
    private http: HttpClient,
    private logger: Logger
  ) {
    this.googleGeocoder = new google.maps.Geocoder();
  }

  findFieldByName(addressFields, field) {
    const findByField = item => item.types[0] === field;
    const getValue = (_, item) => item ? item.long_name : null;

    return addressFields ? addressFields.filter( findByField ).reduce( getValue, null ) : null;
  }

  async getLatLong(placeId): Promise<{latitude: string, longitude: string}> {
    try {
      return await new Promise((resolve, _) => {
        this.googleGeocoder.geocode({placeId}, (geocoderResult) => {
          resolve({
            latitude: geocoderResult[0]?.geometry?.location?.lat(),
            longitude: geocoderResult[0]?.geometry?.location?.lng()
          });
        });
      });
    } catch (err) {
      this.logger.error(err);

      return { latitude: '', longitude: '' };
    }
  }

  getGeocode(zipCode): Promise<any> {
    return this.http.get(`${this.mapsBaseUrl}?place_id=${zipCode}&key=${AppConfig.GOOGLE_MAPS_KEY}`)
    .toPromise()
  }

  getLocationByCoordinates(latitude: string, longitude: string): Promise<any> {
    return this.http.get(`${this.mapsBaseUrl}?latlng=${latitude},${longitude}&key=${AppConfig.GOOGLE_MAPS_KEY}`)
    .toPromise()
  }
}
