import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { RadioButtonModel } from '@tidyapp/tidy-ui-components';
import { AddressRadioButtonModel, AutoCompleteAddressModel } from 'src/shared/models/autocomplete-address.model';
import { AddressComponentsModel, AddressForm } from 'src/providers/address-form/address-form';
import { GoogleGeocode } from 'src/shared/providers/google-geocode';

@Component({
  templateUrl: './autocomplete-address.html',
  selector: 'tidy-auto-complete-address'
})
export class AutocompleteAddressComponent implements AfterViewInit {

  @Input() addressFormControl;
  @Input() countryCode;
  @Input() submitted: boolean;
  @Output() invalidAddressError: EventEmitter<string> = new EventEmitter<string>();
  @Output() onError: EventEmitter<string> = new EventEmitter<string>();
  @Output() onAddressChosen: EventEmitter<AddressMetadata> = new EventEmitter<AddressMetadata>();
  @Output() noAddressPickedUpdate: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();
  @ViewChild('noAddressPickedTemplate', { static: true }) noAddressPickedTemplate: ElementRef;

  autocompleteAddress: Array<AutoCompleteAddressModel>;
  didChooseAddress: boolean;
  radioButtonAddress = [];
  typedOnAddress: boolean;
  _invalidAddressError: string;

  constructor(
    private addressForm: AddressForm,
    private googleGeocode: GoogleGeocode
  ) {
  }

  ngAfterViewInit() {
    this.noAddressPickedUpdate.emit(this.noAddressPickedTemplate);
  }

  async updateSearch() {
    let errorMessage = '';
    try {
      this.autocompleteAddress = await this.addressForm.updateSearch(this.addressFormControl.value, this.countryCode);

      if (!this.didChooseAddress) {
        const invalidAddressError = 'We could not validate your address with Google. Please enter an address that can be found with Google Maps and select an option.';
        this.emitInvalidAddress(invalidAddressError);
      } else {
        this.emitInvalidAddress('');
      }
    } catch (err) { 
      errorMessage = 'An error occurred when getting address information. Please type another address.';
    } finally {
      this.onError.emit(errorMessage);
    }

    this.radioButtonAddress = this.autocompleteAddress.map(address => {
      return {
        value: address.value,
        viewValue: address.display,
        placeId: address.placeId
      }
    });

    this.typedOnAddress = true;	
    this.didChooseAddress = false;
  }

  async chooseItem(item: AutoCompleteAddressModel | AddressRadioButtonModel) {
    const zipcode = await this.addressForm.chooseItem(item);
    const address: AddressComponentsModel = await this.addressForm.getAdditonalAddressInfo(item.placeId);
    const latLngData = await this.googleGeocode.getLatLong(item.placeId);
    this.onAddressChosen.emit({zipcode, ...address, ...latLngData});
    this.autocompleteAddress = [];
    this.didChooseAddress = true;
    this.typedOnAddress = false;
    this.emitInvalidAddress('');
  }

  setAddressFormControl(item: RadioButtonModel) {
    this.addressFormControl.setValue(item.value);
  }

  emitInvalidAddress(invalid: string) {
    if (this._invalidAddressError != invalid) {
      this._invalidAddressError = invalid;
      this.invalidAddressError.emit(invalid);
    }
  }
}

export interface AddressMetadata extends AddressComponentsModel {
  zipcode: string;
  latitude: string;
  longitude: string;
}
