import { Component, ViewEncapsulation } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

@Component({
  selector: 'export-calendar',
  templateUrl: 'export-calendar.html',
  encapsulation: ViewEncapsulation.None
})
export class ExportCalendarPage {

  addressName: string;
  copied: boolean;
  errorMessage: string;
  calendarUrl: any;

  constructor(
    private customNavCtrl: CustomNavController,
    private iabUtils: InAppBrowserUtils
  ) { }

  async ionViewDidEnter() {
    this.calendarUrl = this.customNavCtrl.getParam('calendarUrl', true)
  }

  copyLink() {
    const textArea = document.createElement('textarea');
    textArea.value = this.calendarUrl;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    this.copied = true;
    setTimeout(() => this.copied = false, 3000);
  }

  learnMore() {
    this.iabUtils.openUrl(this.calendarUrl);
  }
}
