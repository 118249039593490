import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Me } from 'src/shared/providers/me';
import { CommonUtils } from 'src/shared/utils/common-utils';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: 'edit-distance-format.html'
})

export class EditDistanceFormatPage implements OnInit {
  distanceFormat = 'mi';
  distanceFormatItems: any;
  form: UntypedFormGroup;
  loaded: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private me: Me,
    private util: CommonUtils
  ) {
    this.form = this.fb.group({
      distanceFormat: [''],
    });
  }

  async ngOnInit() {
    this.loaded = false;
    this.distanceFormat = await this.me.getDistanceFormatValue();
    this.distanceFormatItems = this.getDistanceFormatItems();
    this.form.patchValue({
      distanceFormat: this.distanceFormat
    });
    this.loaded = true;
  }

  getDistanceFormatItems() {
    return [
      {
        value: 'mi',
        viewValue: 'Miles'
      },
      {
        value: 'km',
        viewValue: 'Kilometers'
      }
    ]
  }

  async save() {
    const loading = await this.util.showLoading();
    try {
      await this.me.saveDistanceFormat(this.form.value.distanceFormat);
      this.navCtrl.navigateForward('more');
      this.util.showSuccess(new TranslationPipe().transform('Distance format updated'));
    } catch (error) {
      console.error(error);
      const errorMessage = error.error.message || new TranslationPipe().transform('Something went wrong');
      this.util.showError(errorMessage);
    } finally {
      loading.dismiss();
    }
  }

}
