import { Component } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Certification } from 'src/providers/certification/certification';

import { Team } from 'src/providers/team/team';

@Component({
  templateUrl: 'go-live.html'
})

export class GoLivePage {

  errorMessage: string;
  loaded: boolean;
  requiredServices: any;

  constructor(
    private certification: Certification,
    private navCtrl: CustomNavController,
    public team: Team
  ) {}

  async ionViewDidEnter() {
    this.loaded = false;
    const teamServiceTypes = await this.team.getTeamServiceTypes();
    this.requiredServices = teamServiceTypes[0].team_services.filter((service) => {
      return service.required_team_service_id !== null && service.required_team_service_id !== undefined;
    });
    this.loaded = true;
  }

  async goLive() {
    try {
      await this.certification.finishStep('go_live', {});
      this.showSuccessPage();
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  goToEditRates() {
    this.navCtrl.navigateForward('services');
  }

  showSuccessPage() {
    const params = {
      header: 'Clients from TIDY',
      body: 'You can now get Clients from TIDY. Please ensure your schedule, max driving distance, and other settings are correct.',
      buttonText: 'Ok',
      buttonRoute: 'dashboard'
    };
    this.navCtrl.navigateForward('success');
  }

}
