import { Injectable } from '@angular/core';
import { HttpClient } from 'src/shared/providers/http/http-client';

@Injectable()
export class Quests {

  constructor(
    private httpClient: HttpClient,
  ) {}

  getQuests() {
    const url = `api/v1/homekeeper/quests`;
    return this.httpClient.get(url);
  }

  startQuest(homekeeperQuestId) {
    const url = `api/v1/homekeeper/quests/${homekeeperQuestId}/start`;
    return this.httpClient.post(url, {});
  }

  async getAvailableQuestCount() {
    const quests = await this.getQuests();
    const ongoingQuests = quests.filter((quest) => quest.status === 'eligible');
    return ongoingQuests.length;
  }

  parseQuests(response, statusOne, statusTwo?) {
    const quests = response.filter((quest) => quest.status === statusOne || quest.status === statusTwo);
    quests.map((quest) => {
      const data = this.questInfo(quest.quest_type);
      quest.title = data.title;
      quest.challenge = data.challenge;
      quest.reward = data.reward;
    });
    return quests;
  }

  questInfo(questType) {
    //There is only 1 quest now but we will add more soon
    const questInfos = {
      'returned_from_decertification': {
        title: 'Redemption Quest',
        challenge: 'Complete 3 jobs in 21 days',
        reward: 'When you were decertified you lost previously waived fees. Finish this quest to get those fees re-waived. This is great for starting again as if you were never decertified.'
      },
      'complete_first_private_job': {
        title: 'Complete a Private Job',
        challenge: 'Complete a private job for one of your private Clients that you add within the next 30 days.',
        reward: 'Earn $10 credit for finishing this quest'
      },
      'delegate_a_job': {
        title: 'Delegate a Job to a teammate',
        challenge: 'Delegate a Job to a teammate. A teammate must complete a delegated job within the next 30 days.',
        reward: 'Earn $10 credit for finishing this quest'
      }
    }
    return questInfos[questType];
  }
}
