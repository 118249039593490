import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { MWStore } from "src/main-workflow/mw.store";
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm';
import { SuccessPageParamsModel } from 'src/pages/success/success';

@Component({
  templateUrl: 'mw.other-job-in-progress.page.html'
})

export class OtherJobInProgressPage {

  constructor(
    private modalCtrl: ModalController,
    private mwStore: MWStore,
    private navCtrl: CustomNavController
  ) {}

  goToJobs() {
    this.navCtrl.navigateForward('jobs');
  }

  async clearStore() {
    const params: ConfirmPageParamsModel = {
      title: 'Clear Other Job Data?',
      body: `Any unsynced updates will be lost, but the status of the job is not affected by clearing your cache.`,
      backText: 'Go Back',
      confirmText: 'Clear Data',
      confirmAction: this.confirmClearStore.bind(this)
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });
    confirmationModal.present();
  }

  async confirmClearStore() {
    await this.mwStore.clearStore();
    const successParams: SuccessPageParamsModel = {
      header: 'Other Job Data Cleared',
      body: 'You can now notify on the way for this job.  Just tap on the job in the Jobs Page.',
      buttonText: 'Go to Jobs',
      buttonRoute: `jobs`
    };
    this.navCtrl.navigateForward('success', successParams);
    this.modalCtrl.dismiss();
  }
}
