<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Receive Payments'"
    [customBack]="'/dashboard'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper *ngIf="loaded">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'Complete steps to receive payments from your clients and get paid out through TIDY:'">
      </tidy-text>
    </tidy-row>

    <tidy-row [align]="'center'">
      <tidy-text
        [header]="completedCount">
      </tidy-text>
      <tidy-text
        [header]="'/2 Completed'">
      </tidy-text>
    </tidy-row>

    <tidy-card *ngFor="let card of cards">
      <tidy-card-button (action)="goToPage(card?.linkPage, card?.finished?.hideArrow)" [hideArrow]="card?.finished?.hideArrow" class="arrow-to-top">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/' + card?.finished?.icon + '.svg'"
            [ngClass]="card?.finished?.iconClass"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="card?.header">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="card?.body">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
