import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

import { Loading } from 'src/shared/components/loading/loading';
import { Timeout } from 'src/shared/components/timeout/timeout';

import { SectionCardModel, SectionStylesModel } from 'src/shared/models/certification.model';

@Component({
  templateUrl: 'receive-payments-from-clients.html',
})
export class OnboardingReceivePaymentsFromClientsPage extends Timeout {

  cards: SectionCardModel[];
  completedCount: number;
  payoutSetting: any;
  paymentSetting: any;

  constructor(
    private navCtrl: CustomNavController,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
  }

  @Loading()
  async ionViewDidEnter() {
    try {
      this.loaded = false;
      this.payoutSetting = localStorage.getItem('payoutSetting');
      this.paymentSetting = localStorage.getItem('paymentSetting');
      this.countCompleted();
      this.cards = this.buildCards();
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  countCompleted() {
    this.completedCount = 0;
    if (this.payoutSetting) {
      this.completedCount += 1;
    }
    if (this.paymentSetting) {
      this.completedCount += 1;
    }
  }

  buildCards(): SectionCardModel[] {
    return [
      {
        header: 'Confirm Payment Settings',
        body: 'You can allow private clients to pay your invoices with cash, check, or through TIDY with card.',
        linkPage: 'client-payment-settings',
        finished: this.getFinishedStyles(this.paymentSetting)
      },
      {
        header: 'Choose Payout Type',
        body: 'TIDY pays out payments collected from your clients once a week. You can get paid out via direct deposit, credit card or check.',
        linkPage: 'edit-payout-settings',
        finished: this.getFinishedStyles(this.payoutSetting)
      }
    ];
  }

  getFinishedStyles(finished: boolean): SectionStylesModel {
    if (finished) {
      return {
        icon: 'checkbox',
        iconClass: 'green',
        hideArrow: true,
      };
    } else {
      return {
        icon: 'square-outline',
        iconClass: '',
        hideArrow: false,
      };
    }
  }

  goToPage(page, alreadyFinishedStep) {
    if (!alreadyFinishedStep) {
      this.navCtrl.navigateForward(page, {cameFromOnboardingPage: true});
    }
  }

}
