import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Auth } from 'src/providers/auth/auth';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private auth: Auth,
    private customNavController: CustomNavController
  ) {}

  canActivate(): boolean {
    if (!this.auth.isAuthenticated()) {
      this.customNavController.navigateRoot(['introduction']);
      return false;
    }

    return true;
  }

}
