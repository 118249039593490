import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PendingDecertification } from 'src/providers/pending-decertific/pending-decertific';

import { SuccessPageParamsModel } from 'src/pages/success/success';

@Component({
  templateUrl: './pending-decertification.html'
})

export class PendingDecertificationPage {

  errorMessage: string;
  form: UntypedFormGroup;
  nextStep = false;
  submitted = false;

  constructor(
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private pendingDc: PendingDecertification,
  ) {
    this.form = this.fb.group({
      issue: ['', Validators.required]
    });
  }

  async next() {
    this.nextStep = true;
  }

  async submit() {
    this.submitted = true;
    this.errorMessage = '';
    if (!this.form.valid) {
      return this.errorMessage = 'You must agree to all of the above to reapply.';
    }
    try {
      await this.pendingDc.undoPendingDecertification(this.form.value.issue);
      const successParams: SuccessPageParamsModel = {
        header: 'Succesfully Activated',
        body: `You are now Active! More cancellations can lead to fees even decertification form TIDY. Make sure to check \"My Schedule\" to confirm your \"Bookable\" schedule is up-to-date.`,
        buttonText: 'Ok',
        buttonRoute: `dashboard`
      };
      this.customNavCtrl.navigateForward('success', successParams);
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }
}
