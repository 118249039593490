import { Injectable } from '@angular/core';

import { AppConfig } from './config';
import { Logger } from './logger';

import { RequestModel } from '../models/request.model';
import * as AWS from '@aws-sdk/client-s3';
import { PutObjectCommand } from '@aws-sdk/client-s3';
import { BeforeAfterMediaType } from 'src/main-workflow/mw.enums';
import { Geolocation } from '@capacitor/geolocation';
import { Camera } from './camera/camera';
import { randomUUID } from '../utils/secure-utils';
import { Capacitor } from '@capacitor/core';
import { DateTime } from 'luxon';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Location } from 'src/shared/providers/location';

export interface S3UploadResponse {
  Bucket: string;
  Key: string;
  Location: string;
}

@Injectable()
export class Aws {
  private WALKTHROUGH_URL =
    'https://s3-us-west-1.amazonaws.com/tidy-hk-app-walkthrough';
  private RETRY_DELAY = 30000;

  constructor(
    private logger: Logger,
    private camera: Camera,
    private storage: TidyStorage,
    private location: Location,
  ) {}

  async request(request: RequestModel): Promise<S3UploadResponse> {
    const mediaFormat = request.params.media_format;
    try {
      const key = request.url;
      const bucketResponse =
        mediaFormat == BeforeAfterMediaType.VIDEO
          ? await this.uploadVideoToS3(request.params.mediaData, key)
          : await this.uploadImageToS3(request.params.mediaData, key);

      return bucketResponse;
    } catch (err) {
      const retries = request.retries || 1;
      if (retries < 3) {
        setTimeout(
          () => this.request({ ...request, retries: retries + 1 }),
          this.RETRY_DELAY
        );
      } else {
        this.logger.error(err, `Error trying to upload ${mediaFormat}.`);
      }
    }
  }

  async saveTodoMediaToAws(
    MediaData,
    mediaType: string,
    url: string,
    file?,
    exif?
  ) {
    if (MediaData === undefined || MediaData === null || MediaData === '') {
      return;
    }
    const key = this.uniqueKey(url);
    if (mediaType == BeforeAfterMediaType.VIDEO) {
      return await this.uploadVideoToS3(MediaData, key, file, exif);
    }

    return await this.uploadImageToS3(MediaData, key, file, exif);
  }

  parseCoordinate(coordString: string): string {
    if (!coordString) {
      return null;
    }
    const isNegative = coordString.startsWith('-');
    const parts = coordString.replace('-', '').split(',');

    const [degrees, minutes, seconds] = parts.map((part) => {
      const [numerator, denominator] = part.trim().split('/').map(Number);
      return numerator / (denominator || 1);
    });

    let result = degrees + (minutes || 0) / 60 + (seconds || 0) / 3600;
    if (isNegative) {
      result = -result;
    }

    return result.toString();
  }

  parseGPSCoordinates(latitude: number, longitude: number, latRef: string, longRef: string): { latitude: string, longitude: string } {
    const parsedLat = this.parseCoordinate(latitude.toString());
    const parsedLong = this.parseCoordinate(longitude.toString());

    const finalLat = latRef === 'S' ? `-${parsedLat}` : parsedLat;
    const finalLong = longRef === 'W' ? `-${parsedLong}` : parsedLong;

    return {
      latitude: finalLat,
      longitude: finalLong
    };
  }

  getLatitude(exif) {
    try {
      if (exif?.latitude) {
        return exif?.latitude?.toString();
      }
      if (this.parseCoordinate(exif?.GPSLatitude)) {
        return this.parseCoordinate(exif?.GPSLatitude);
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  getLongitude(exif) {
    try {
      if (exif?.longitude) {
        return exif?.longitude?.toString();
      }
      if (this.parseCoordinate(exif?.GPSLongitude)) {
        return this.parseCoordinate(exif?.GPSLongitude);
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  getDate(exif, file) {
    try {
      if (exif?.CreateDate) {
        return exif?.CreateDate?.toISOString();
      }
      if (exif?.DateTimeOriginal) {
        return DateTime.fromFormat(
          exif?.DateTimeOriginal,
          'yyyy:MM:dd HH:mm:ss'
        )?.toISO();
      }
      if (file?.modifiedAt || exif?.modifiedAt) {
        return DateTime.fromMillis(file?.modifiedAt || exif?.modifiedAt)?.toISO();
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  // INFO: All these different handlings of properties is because of the different sources of data we can get from the platforms.
  async getFileMetadata(file?: any, exif?: any) {
    try {
      const date = new Date();
      let metadata: any = {
        date: file?.lastModifiedDate?.toISOString() || date.toISOString(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      exif = file?.exif || exif;
      if (exif) {
        let latitude = this.getLatitude(exif);
        let longitude = this.getLongitude(exif);
        metadata.latitude = latitude;
        metadata.longitude = longitude;

        if (exif?.GPS?.Latitude && exif?.GPS?.Longitude) {
          const { latitude, longitude } = this.parseGPSCoordinates(exif?.GPS?.Latitude, exif?.GPS?.Longitude, exif?.GPS?.LatitudeRef, exif?.GPS?.LongitudeRef);
          metadata.latitude = latitude;
          metadata.longitude = longitude;
        }
        const date = this.getDate(exif, file);
        metadata.date = date;

        if (latitude !== null || longitude !== null || date !== null) {
          return metadata;
        }
      }
      if (metadata.date === null) {
        metadata.date = file?.lastModifiedDate?.toISOString() || date.toISOString();
      }
      if (Capacitor.isNativePlatform()) {
        await Geolocation.requestPermissions();
      }
      await Geolocation.checkPermissions();
      try {
        const deviceLocation: any = await this.location.get();
        return {
          ...metadata,
          latitude: deviceLocation?.latitude?.toString() || null,
          longitude: deviceLocation?.longitude?.toString() || null,
        };
      } catch (error) {
        console.error(error);
        return metadata;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async uploadFileToS3(
    fileData,
    key,
    contentType,
    file?,
    exif?
  ): Promise<S3UploadResponse> {
    const body = this.fileDataToBlob(fileData, contentType) || fileData;
    const Bucket = AppConfig.AWS_S3_BUCKET.split('/')[0];
    const Key = `${AppConfig.AWS_S3_BUCKET.split('/')[1]}/${key}`;
    const metadata = await this.getFileMetadata(file, exif);
    const params: any = {
      Bucket,
      Key,
      Body: body,
      ContentType: contentType,
    };
    if (metadata) {
      params.Metadata = metadata;
    }
    const abortController = new AbortController();
    const abortSignal = abortController.signal;
    const isCompletingJob = await this.storage.retrieve('isCompletingJob');
    const timeoutId = setTimeout(() => abortController.abort(), isCompletingJob ? 300000 : 30000);
    await this.getS3().send(new PutObjectCommand(params), { abortSignal });
    clearTimeout(timeoutId);
    return {
      Bucket,
      Key,
      Location: `${AppConfig.AWS_S3_BUCKET_URL}${key}`,
    };
  }

  async uploadImageToS3(picture, key, file?, exif?): Promise<S3UploadResponse> {
    try {
      picture = await this.camera.resizeImage(picture);
    } catch (error) {
      // If the image is not resized, we will upload the original image
    }

    return await this.uploadFileToS3(
      picture,
      key,
      picture?.type || 'image/jpeg',
      file,
      exif
    );
  }

  async uploadVideoToS3(picture, key, file?, exif?): Promise<S3UploadResponse> {
    return await this.uploadFileToS3(picture, key, 'video/mp4', file, exif);
  }

  getImagesFromS3Bucket(bucket): Promise<any> {
    return new Promise<Array<any>>((resolve, reject) => {
      this.getS3().listObjectsV2({ Bucket: bucket }, (err, data) =>
        err ? reject(err) : resolve(this.extractSteps(data.Contents))
      );
    });
  }

  extractSteps(contents) {
    return contents.map((step) => ({
      url: `${this.WALKTHROUGH_URL}/${step.Key}`,
    }));
  }

  fileDataToBlob(fileData, contentType) {
    try {
      if (typeof fileData === 'string') {
        const base64String = fileData.split(',')[1] || fileData;
        const binary = atob(base64String);
        const array = [];
        for (let i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
        }

        return new Blob([new Uint8Array(array)], { type: contentType });
      } else {
        return fileData;
      }
    } catch {
      return null;
    }
  }

  private uniqueKey(key) {
    const parts = key.split('/');
    const filename = parts.pop();
    const lastPartSplit = filename.split('.');
    let newFilename = randomUUID();

    if (lastPartSplit.length > 1) {
      newFilename += '.' + lastPartSplit.pop();
    }

    parts.push(newFilename);

    return parts.join('/');
  }

  private getS3() {
    /* const retryDelayBase = 5000;
    const maxRetries = 3; */
    const client = new AWS.S3({
      region: AppConfig.AWS_S3_REGION_BUCKET,
      credentials: {
        accessKeyId: AppConfig.AWS_ACCESS_KEY_ID,
        secretAccessKey: AppConfig.AWS_SECRET_ACCESS_KEY,
      },
      /* maxAttempts: maxRetries, */
    });
    /* client.middlewareStack.add(
      (next, context) => async (args) => {
        for (let attempt = 0; attempt < maxRetries; attempt++) {
          try {
            return await next(args);
          } catch (error) {
            if (attempt < maxRetries - 1) {
              await new Promise((resolve) =>
                setTimeout(resolve, retryDelayBase * (attempt + 1))
              );
            } else {
              throw error;
            }
          }
        }
      },
      {
        step: 'finalizeRequest',
      }
    ); */

    return client;
  }
}
