import { Component } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { MWStore } from "src/main-workflow/mw.store";

import { mwMoments } from 'src/main-workflow/mw.moments';

@Component({
  templateUrl: 'mw.job-sync-problem.page.html'
})

export class JobSyncProblemPage {

  loaded: boolean;
  job: any;

  constructor(
    private navCtrl: CustomNavController,
    private mwStore: MWStore
  ) {}

  async ionViewDidEnter() {
    this.loaded = false;
    this.job = this.mwStore.getJob();
    this.loaded = true;
  }

  notifyFinished() {

  }
}
