import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { CommonUtils } from 'src/shared/utils/common-utils';
import { Me } from 'src/shared/providers/me';
import { Events } from 'src/providers/events/events';

import { parseProfileData } from 'src/pages/profile/profile-data-parser';
import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

@Component({
  selector: 'delete-account',
  templateUrl: 'delete-account.html',
})

export class DeleteAccountPage {

  form: UntypedFormGroup;
  errorMessage = '';
  proCountryCode = '';
  email = '';
  phone = '';
  submitted = false;

  constructor(
    private fb: UntypedFormBuilder,
    private iabUtils: InAppBrowserUtils,
    private modalCtrl: ModalController,
    private util: CommonUtils,
    private me: Me,
    private events: Events
  ) {
    this.form = this.fb.group({
      email: ['', Validators.required],
      phone: ['', Validators.required],
    });
  }

  async ngOnInit() {
    try {
      const homekeeper = await this.me.fetchWithoutCache();
      const profileData = parseProfileData(homekeeper);
      this.proCountryCode = homekeeper.profile.address.country_code;
      this.email = profileData.email;
      this.phone = profileData.phone;
    } catch (error) {
      this.util.showError((error.error && error.error.message) ? error.error.message : error.message, 10000);
    }
  }

  async deleteAccount() {
    this.submitted = true;
    this.errorMessage = '';
    if (!this.form.valid) {
      return;
    }
    const isCorrectEmail = this.form.get('email')?.value === this.email;
    const isCorrectPhone = this.form.get('phone')?.value === this.phone;
    if (!isCorrectEmail && isCorrectPhone) {
      return this.errorMessage = 'The email you entered does not match your account email.';
    } else if (!isCorrectPhone && isCorrectEmail) {
      return this.errorMessage = 'The phone number you entered does not match your account phone number.';
    } else if (!isCorrectEmail && !isCorrectPhone) {
      return this.errorMessage = 'The email and phone number you entered do not match your account email and phone number.';
    }
    const params: ConfirmPageParamsModel = {
      body: 'Are you sure you want to delete your account?',
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: this.confirmDeleteAccount.bind(this),
      title: 'Delete Account',
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });
    await confirmationModal.present();
  }

  async confirmDeleteAccount() {
    try {
      const me = await this.me.load();
      await this.me.deleteAccount(me.user.id);
      this.events.publish('auth:logout');
      this.util.showSuccess('Your account has been deleted.', 10000);
      this.modalCtrl.dismiss();
    } catch (error) {
      this.util.showError((error.error && error.error.message) ? error.error.message : error.message, 10000);
    }
  }

  openUrl(url: string) {
    this.iabUtils.openUrl(url);
  }

}
 