import { Component, ChangeDetectionStrategy } from '@angular/core';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

@Component({
  selector: 'footer-informations',
  template: `
    <tidy-alert>
      <tidy-row [align]="'center'" class="vertical-align-center extra-bottom-padding">
        <tidy-image
          [src]="'assets/svg/lock-closed.svg'"
          class="body-size">
        </tidy-image>
        <tidy-text
          [header]="'Private Job Guarantee'">
        </tidy-text>
      </tidy-row>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Your data will be kept private and your Job will remain your Job. '">
        </tidy-text>
        <tidy-text
          (action)="learnMore()"
          [body]="'Learn More'"
          class="link">
        </tidy-text>
      </tidy-row>
    </tidy-alert>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterInformationsComponent {

  constructor(
    private iabUtils: InAppBrowserUtils
  ) {}

  learnMore() {
    const url = 'https://help.tidy.com/pros/manage-private-clients#what-are-my-private-clients';
    this.iabUtils.openUrl(url);
  }
}
