import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { MWStore } from "src/main-workflow/mw.store";
import { MWService } from "src/main-workflow/mw.service";
import { MWJobModel } from "src/main-workflow/mw.models";

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm';

@Component({
  templateUrl: 'mw.client-refused-service.page.html'
})

export class ClientRefusedServicePage {

  job: MWJobModel;
  loaded: boolean;

  constructor(
    private modalCtrl: ModalController,
    public mwService: MWService,
    private mwStore: MWStore,
    private navCtrl: CustomNavController
  ) {}

  async ionViewDidEnter() {
    this.loaded = false;
    this.job = await this.mwStore.getJob();
    this.loaded = true;
  }

  async refusedService() {
    const params: ConfirmPageParamsModel = {
      title: 'Client Refused Service?',
      body: `Send Client notice they cancelled & may be charged a fee? Only do this if the Client says that they cancel. <strong>If you cannot contact the Client do not use this.</strong> Go back and use the button that best describes the issue.`,
      backText: 'Go Back',
      confirmText: 'Confirm & Send',
      confirmAction: this.confirmRefuseService.bind(this),
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });
    confirmationModal.present();
  }

  async confirmRefuseService() {
    try {
      await this.mwService.cancelJob('client_refused_service', this.job);
      this.navCtrl.navigateForward('success', {
        header: 'Job Cancelled',
        body: `We have sent a notification to this Client`,
        buttonText: 'Go to My Jobs',
        buttonRoute: 'jobs'
      });
      this.mwStore.clearStore();
      this.modalCtrl.dismiss();
    } catch(err) {
      this.mwService.showErrorPage(err);
      this.modalCtrl.dismiss();
    }
  }

  async showInvalidAction() {
    const priorPageRoute = await this.mwStore.getRoute();
    this.navCtrl.navigateForward('success', {
      header: 'Not a Valid Option',
      body: `Client refusing service is only authorized when clients tell you they cancel. If you are unable to access or find the home, tap "Can't Access Home" or "Can't Find Home" and follow the steps. This includes if you cannot contact them.`,
      buttonText: 'Back To Job',
      buttonRoute: priorPageRoute
    });
  }
}
