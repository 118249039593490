<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [noBow]="true"
    [title]="'Finish Far From Client'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Your GPS indicates you are not near the Client\'s location.'">
        </tidy-text>
      </tidy-row>

      <tidy-button
        text="I'm Here - Try GPS Again"
        [action]="tryAgain.bind(this)"
        class="secondary">
      </tidy-button>

      <tidy-button
        text="I'm Here - Take Photo"
        [action]="takePhoto.bind(this)"
        class="secondary">
      </tidy-button>

      <tidy-button
        text="I'm Not Here Anymore"
        [action]="confirmNotAtHome.bind(this)"
        class="secondary">
      </tidy-button>

      <tidy-alert>
        <tidy-row [align]="'center'">
          <tidy-image
            [src]="'assets/svg/alert.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'You have '">
          </tidy-text>
          <countdown
            *ngIf="timeoutTime"
            [target]="timeoutTime"
            (onTimeout)="onTimeout()">
          </countdown>
          <tidy-text
            [body]="' to complete this page.'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
