import { Injectable } from '@angular/core';
import { LoadingController, LoadingOptions, ToastController, AlertController, AlertOptions } from '@ionic/angular';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Injectable()
export class CommonUtils {
  constructor(
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
    private alertController: AlertController,
  ) {}

  async showLoading(params?: LoadingOptions): Promise<HTMLIonLoadingElement> {
    const options: LoadingOptions = {
      message: new TranslationPipe().transform('Loading...'),
      spinner: 'circles',
      mode: 'ios',
      ...params,
    };
    const loading = await this.loadingCtrl.create(options);

    loading.present();
    return loading;
  }

  async showInfo(message: string, duration = 5000): Promise<void> {
    const toast = await this.toastCtrl.create({
      message: new TranslationPipe().transform(message),
      duration,
      cssClass: 'toast-info',
    });
    toast.present();
  }

  async showError(message: string, duration = 5000): Promise<void> {
    const toast = await this.toastCtrl.create({
      message: new TranslationPipe().transform(message),
      duration,
      cssClass: 'toast-error',
    });
    toast.present();
  }

  async showSuccess(message: string, duration = 5000): Promise<void> {
    const toast = await this.toastCtrl.create({
      message: new TranslationPipe().transform(message),
      duration,
      cssClass: 'toast-success',
    });
    toast.present();
  }

  async showConfirmAlert(options: AlertOptions): Promise<HTMLIonAlertElement> {
    const alert = await this.alertController.create({
      header: new TranslationPipe().transform('Confirm'),
      message: new TranslationPipe().transform('Are you sure you want to do this?'),
      mode: 'ios',
      buttons: [
        {
          text: new TranslationPipe().transform('Go Back'),
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: new TranslationPipe().transform('Confirm'),
          role: 'confirm',
        },
      ],
      ...options,
    });
    alert.present();
    return alert;
  }
}
