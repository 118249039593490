import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { interval, Subscription } from 'rxjs';

import { GoalModel } from 'src/shared/components/countdown/goal.model';

import * as moment from 'moment';

@Component({
  selector: 'countdown',
  template: `
    <tidy-text
      *ngIf="isTopNav"
      class="color-inherit"
      title="{{ target | tcountdown: now }}"
    >
    </tidy-text>
    <tidy-text
      *ngIf="!isTopNav"
      class="color-inherit"
      body="<b> {{ target | tcountdown: now }} </b>"
    >
    </tidy-text>
  `,
  encapsulation: ViewEncapsulation.None
})
export class CountdownComponent implements OnInit, OnDestroy {

  @Input() isTopNav: boolean;
  @Input() target: moment.Moment;
  @Input() goals: Array<GoalModel> = [] ;

  @Output('onTimeout') onTimeout: EventEmitter<void> = new EventEmitter<void>();
  @Output('onGoal') onGoal: EventEmitter<GoalModel> = new EventEmitter<GoalModel>();

  private ONE_SECOND = 1000;

  now: moment.Moment = moment();
  private intervalSubscription: Subscription;

  async ngOnInit() {
    this.intervalSubscription = interval(this.ONE_SECOND).subscribe( () => this.tick() );
  }

  private tick(): void {
    this.now = moment();
    this.checkGoals();
    this.checkTimeout();
  }

  private checkGoals(): void {
    this.goals.forEach( g => {
      if (!g.solved && this.remaining(g.goal) < this.ONE_SECOND) {
        g.solved = true;
        this.onGoal.emit(g);
      }
    });
  }

  private checkTimeout(): void {
    if (this.remaining(this.target) < this.ONE_SECOND) {
      this.stopCounter();
      this.onTimeout.emit();
    }
  }

  private remaining(target): number {
    return target.diff(this.now);
  }

  ngOnDestroy(): void {
    this.stopCounter();
  }

  stopCounter() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

}
