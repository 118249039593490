import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'title-header',
  template: `
    <div class="title-header">
      <ng-content select="[item]"></ng-content>
    </div>
  `,
  styles: [
    `
      .title-header {
        font-family: Georgia, Times, "Times New Roman", serif;
        font-weight: bold;
        font-size: 20px;
      }
    `
  ],
  encapsulation: ViewEncapsulation.None
})
export class TitleHeader {}
