import { Injectable, NgZone } from '@angular/core';
import { Validators } from '@angular/forms';

@Injectable()
export class MultipleCountryService {
  countryRadioButtonList = [
    { value: 'AF', viewValue: 'Afghanistan' },
    { value: 'AL', viewValue: 'Albania' },
    { value: 'DZ', viewValue: 'Algeria' },
    { value: 'AS', viewValue: 'American Samoa' },
    { value: 'AD', viewValue: 'Andorra' },
    { value: 'AO', viewValue: 'Angola' },
    { value: 'AI', viewValue: 'Anguilla' },
    { value: 'AQ', viewValue: 'Antarctica' },
    { value: 'AG', viewValue: 'Antigua and Barbuda' },
    { value: 'AR', viewValue: 'Argentina' },
    { value: 'AM', viewValue: 'Armenia' },
    { value: 'AW', viewValue: 'Aruba' },
    { value: 'AU', viewValue: 'Australia' },
    { value: 'AT', viewValue: 'Austria' },
    { value: 'AZ', viewValue: 'Azerbaijan' },
    { value: 'BS', viewValue: 'Bahamas' },
    { value: 'BH', viewValue: 'Bahrain' },
    { value: 'BD', viewValue: 'Bangladesh' },
    { value: 'BB', viewValue: 'Barbados' },
    { value: 'BY', viewValue: 'Belarus' },
    { value: 'BE', viewValue: 'Belgium' },
    { value: 'BZ', viewValue: 'Belize' },
    { value: 'BJ', viewValue: 'Benin' },
    { value: 'BM', viewValue: 'Bermuda' },
    { value: 'BT', viewValue: 'Bhutan' },
    { value: 'BO', viewValue: 'Bolivia' },
    { value: 'BQ', viewValue: 'Bonaire, Sint Eustatius and Saba' },
    { value: 'BA', viewValue: 'Bosnia and Herzegovina' },
    { value: 'BW', viewValue: 'Botswana' },
    { value: 'BV', viewValue: 'Bouvet Island' },
    { value: 'BR', viewValue: 'Brazil' },
    { value: 'IO', viewValue: 'British Indian Ocean Territory' },
    { value: 'BN', viewValue: 'Brunei Darussalam' },
    { value: 'BG', viewValue: 'Bulgaria' },
    { value: 'BF', viewValue: 'Burkina Faso' },
    { value: 'BI', viewValue: 'Burundi' },
    { value: 'CV', viewValue: 'Cabo Verde' },
    { value: 'KH', viewValue: 'Cambodia' },
    { value: 'CM', viewValue: 'Cameroon' },
    { value: 'CA', viewValue: 'Canada' },
    { value: 'KY', viewValue: 'Cayman Islands' },
    { value: 'CF', viewValue: 'Central African Republic' },
    { value: 'TD', viewValue: 'Chad' },
    { value: 'CL', viewValue: 'Chile' },
    { value: 'CN', viewValue: 'China' },
    { value: 'CX', viewValue: 'Christmas Island' },
    { value: 'CC', viewValue: 'Cocos Islands' },
    { value: 'CO', viewValue: 'Colombia' },
    { value: 'KM', viewValue: 'Comoros' },
    { value: 'CD', viewValue: 'Democratic Republic of the Congo)' },
    { value: 'CG', viewValue: 'Congo' },
    { value: 'CK', viewValue: 'Cook Islands' },
    { value: 'CR', viewValue: 'Costa Rica' },
    { value: 'HR', viewValue: 'Croatia' },
    { value: 'CU', viewValue: 'Cuba' },
    { value: 'CW', viewValue: 'Curaçao' },
    { value: 'CY', viewValue: 'Cyprus' },
    { value: 'CZ', viewValue: 'Czechia' },
    { value: 'CI', viewValue: 'Côte d\'Ivoire' },
    { value: 'DK', viewValue: 'Denmark' },
    { value: 'DJ', viewValue: 'Djibouti' },
    { value: 'DM', viewValue: 'Dominica' },
    { value: 'DO', viewValue: 'Dominican Republic' },
    { value: 'EC', viewValue: 'Ecuador' },
    { value: 'EG', viewValue: 'Egypt' },
    { value: 'SV', viewValue: 'El Salvador' },
    { value: 'GQ', viewValue: 'Equatorial Guinea' },
    { value: 'ER', viewValue: 'Eritrea' },
    { value: 'EE', viewValue: 'Estonia' },
    { value: 'SZ', viewValue: 'Eswatini' },
    { value: 'ET', viewValue: 'Ethiopia' },
    { value: 'FK', viewValue: 'Falkland Islands' },
    { value: 'FO', viewValue: 'Faroe Islands' },
    { value: 'FJ', viewValue: 'Fiji' },
    { value: 'FI', viewValue: 'Finland' },
    { value: 'FR', viewValue: 'France' },
    { value: 'GF', viewValue: 'French Guiana' },
    { value: 'PF', viewValue: 'French Polynesia' },
    { value: 'TF', viewValue: 'French Southern Territories' },
    { value: 'GA', viewValue: 'Gabon' },
    { value: 'GM', viewValue: 'Gambia' },
    { value: 'GE', viewValue: 'Georgia' },
    { value: 'DE', viewValue: 'Germany' },
    { value: 'GH', viewValue: 'Ghana' },
    { value: 'GI', viewValue: 'Gibraltar' },
    { value: 'GR', viewValue: 'Greece' },
    { value: 'GL', viewValue: 'Greenland' },
    { value: 'GD', viewValue: 'Grenada' },
    { value: 'GP', viewValue: 'Guadeloupe' },
    { value: 'GU', viewValue: 'Guam' },
    { value: 'GT', viewValue: 'Guatemala' },
    { value: 'GG', viewValue: 'Guernsey' },
    { value: 'GN', viewValue: 'Guinea' },
    { value: 'GW', viewValue: 'Guinea-Bissau' },
    { value: 'GY', viewValue: 'Guyana' },
    { value: 'HT', viewValue: 'Haiti' },
    { value: 'HM', viewValue: 'Heard Island and McDonald Islands' },
    { value: 'VA', viewValue: 'Vatican' },
    { value: 'HN', viewValue: 'Honduras' },
    { value: 'HK', viewValue: 'Hong Kong' },
    { value: 'HU', viewValue: 'Hungary' },
    { value: 'IS', viewValue: 'Iceland' },
    { value: 'IN', viewValue: 'India' },
    { value: 'ID', viewValue: 'Indonesia' },
    { value: 'IR', viewValue: 'Iran' },
    { value: 'IQ', viewValue: 'Iraq' },
    { value: 'IE', viewValue: 'Ireland' },
    { value: 'IM', viewValue: 'Isle of Man' },
    { value: 'IL', viewValue: 'Israel' },
    { value: 'IT', viewValue: 'Italy' },
    { value: 'JM', viewValue: 'Jamaica' },
    { value: 'JP', viewValue: 'Japan' },
    { value: 'JE', viewValue: 'Jersey' },
    { value: 'JO', viewValue: 'Jordan' },
    { value: 'KZ', viewValue: 'Kazakhstan' },
    { value: 'KE', viewValue: 'Kenya' },
    { value: 'KI', viewValue: 'Kiribati' },
    { value: 'KP', viewValue: 'Democratic People\'s Republic of Korea' },
    { value: 'KR', viewValue: 'Republic of Korea' },
    { value: 'KW', viewValue: 'Kuwait' },
    { value: 'KG', viewValue: 'Kyrgyzstan' },
    { value: 'LA', viewValue: 'Lao People\'s Democratic Republic' },
    { value: 'LV', viewValue: 'Latvia' },
    { value: 'LB', viewValue: 'Lebanon' },
    { value: 'LS', viewValue: 'Lesotho' },
    { value: 'LR', viewValue: 'Liberia' },
    { value: 'LY', viewValue: 'Libya' },
    { value: 'LI', viewValue: 'Liechtenstein' },
    { value: 'LT', viewValue: 'Lithuania' },
    { value: 'LU', viewValue: 'Luxembourg' },
    { value: 'MO', viewValue: 'Macao' },
    { value: 'MG', viewValue: 'Madagascar' },
    { value: 'MW', viewValue: 'Malawi' },
    { value: 'MY', viewValue: 'Malaysia' },
    { value: 'MV', viewValue: 'Maldives' },
    { value: 'ML', viewValue: 'Mali' },
    { value: 'MT', viewValue: 'Malta' },
    { value: 'MH', viewValue: 'Marshall Islands' },
    { value: 'MQ', viewValue: 'Martinique' },
    { value: 'MR', viewValue: 'Mauritania' },
    { value: 'MU', viewValue: 'Mauritius' },
    { value: 'YT', viewValue: 'Mayotte' },
    { value: 'MX', viewValue: 'Mexico' },
    { value: 'FM', viewValue: 'Micronesia' },
    { value: 'MD', viewValue: 'Moldova' },
    { value: 'MC', viewValue: 'Monaco' },
    { value: 'MN', viewValue: 'Mongolia' },
    { value: 'ME', viewValue: 'Montenegro' },
    { value: 'MS', viewValue: 'Montserrat' },
    { value: 'MA', viewValue: 'Morocco' },
    { value: 'MZ', viewValue: 'Mozambique' },
    { value: 'MM', viewValue: 'Myanmar' },
    { value: 'NA', viewValue: 'Namibia' },
    { value: 'NR', viewValue: 'Nauru' },
    { value: 'NP', viewValue: 'Nepal' },
    { value: 'NL', viewValue: 'Netherlands' },
    { value: 'NC', viewValue: 'New Caledonia' },
    { value: 'NZ', viewValue: 'New Zealand' },
    { value: 'NI', viewValue: 'Nicaragua' },
    { value: 'NE', viewValue: 'Niger' },
    { value: 'NG', viewValue: 'Nigeria' },
    { value: 'NU', viewValue: 'Niue' },
    { value: 'NF', viewValue: 'Norfolk Island' },
    { value: 'MP', viewValue: 'Northern Mariana Islands' },
    { value: 'NO', viewValue: 'Norway' },
    { value: 'OM', viewValue: 'Oman' },
    { value: 'PK', viewValue: 'Pakistan' },
    { value: 'PW', viewValue: 'Palau' },
    { value: 'PS', viewValue: 'Palestine' },
    { value: 'PA', viewValue: 'Panama' },
    { value: 'PG', viewValue: 'Papua New Guinea' },
    { value: 'PY', viewValue: 'Paraguay' },
    { value: 'PE', viewValue: 'Peru' },
    { value: 'PH', viewValue: 'Philippines' },
    { value: 'PN', viewValue: 'Pitcairn' },
    { value: 'PL', viewValue: 'Poland' },
    { value: 'PT', viewValue: 'Portugal' },
    { value: 'PR', viewValue: 'Puerto Rico' },
    { value: 'QA', viewValue: 'Qatar' },
    { value: 'MK', viewValue: 'Republic of North Macedonia' },
    { value: 'RO', viewValue: 'Romania' },
    { value: 'RU', viewValue: 'Russia' },
    { value: 'RW', viewValue: 'Rwanda' },
    { value: 'RE', viewValue: 'Réunion' },
    { value: 'BL', viewValue: 'Saint Barthélemy' },
    { value: 'SH', viewValue: 'Saint Helena, Ascension and Tristan da Cunha' },
    { value: 'KN', viewValue: 'Saint Kitts and Nevis' },
    { value: 'LC', viewValue: 'Saint Lucia' },
    { value: 'MF', viewValue: 'Saint Martin (French part)' },
    { value: 'PM', viewValue: 'Saint Pierre and Miquelon' },
    { value: 'VC', viewValue: 'Saint Vincent and the Grenadines' },
    { value: 'WS', viewValue: 'Samoa' },
    { value: 'SM', viewValue: 'San Marino' },
    { value: 'ST', viewValue: 'Sao Tome and Principe' },
    { value: 'SA', viewValue: 'Saudi Arabia' },
    { value: 'SN', viewValue: 'Senegal' },
    { value: 'RS', viewValue: 'Serbia' },
    { value: 'SC', viewValue: 'Seychelles' },
    { value: 'SL', viewValue: 'Sierra Leone' },
    { value: 'SG', viewValue: 'Singapore' },
    { value: 'SX', viewValue: 'Sint Maarten (Dutch part)' },
    { value: 'SK', viewValue: 'Slovakia' },
    { value: 'SI', viewValue: 'Slovenia' },
    { value: 'SB', viewValue: 'Solomon Islands' },
    { value: 'SO', viewValue: 'Somalia' },
    { value: 'ZA', viewValue: 'South Africa' },
    { value: 'GS', viewValue: 'South Georgia and the South Sandwich Islands' },
    { value: 'SS', viewValue: 'South Sudan' },
    { value: 'ES', viewValue: 'Spain' },
    { value: 'LK', viewValue: 'Sri Lanka' },
    { value: 'SD', viewValue: 'Sudan' },
    { value: 'SR', viewValue: 'Suriname' },
    { value: 'SJ', viewValue: 'Svalbard and Jan Mayen' },
    { value: 'SE', viewValue: 'Sweden' },
    { value: 'CH', viewValue: 'Switzerland' },
    { value: 'SY', viewValue: 'Syrian Arab Republic' },
    { value: 'TW', viewValue: 'Taiwan (Province of China)' },
    { value: 'TJ', viewValue: 'Tajikistan' },
    { value: 'TZ', viewValue: 'Tanzania' },
    { value: 'TH', viewValue: 'Thailand' },
    { value: 'TL', viewValue: 'Timor-Leste' },
    { value: 'TG', viewValue: 'Togo' },
    { value: 'TK', viewValue: 'Tokelau' },
    { value: 'TO', viewValue: 'Tonga' },
    { value: 'TT', viewValue: 'Trinidad and Tobago' },
    { value: 'TN', viewValue: 'Tunisia' },
    { value: 'TR', viewValue: 'Turkey' },
    { value: 'TM', viewValue: 'Turkmenistan' },
    { value: 'TC', viewValue: 'Turks and Caicos Islands' },
    { value: 'TV', viewValue: 'Tuvalu' },
    { value: 'UG', viewValue: 'Uganda' },
    { value: 'UA', viewValue: 'Ukraine' },
    { value: 'AE', viewValue: 'United Arab Emirates' },
    { value: 'GB', viewValue: 'United Kingdom' },
    { value: 'UM', viewValue: 'United States Minor Outlying Islands' },
    { value: 'US', viewValue: 'United States of America' },
    { value: 'UY', viewValue: 'Uruguay' },
    { value: 'UZ', viewValue: 'Uzbekistan' },
    { value: 'VU', viewValue: 'Vanuatu' },
    { value: 'VE', viewValue: 'Venezuela' },
    { value: 'VN', viewValue: 'Vietnam' },
    { value: 'VG', viewValue: 'Virgin Islands (British)' },
    { value: 'VI', viewValue: 'Virgin Islands (U.S.)' },
    { value: 'WF', viewValue: 'Wallis and Futuna' },
    { value: 'EH', viewValue: 'Western Sahara' },
    { value: 'YE', viewValue: 'Yemen' },
    { value: 'ZM', viewValue: 'Zambia' },
    { value: 'ZW', viewValue: 'Zimbabwe' },
    { value: 'AX', viewValue: 'Åland Islands' }
  ];

  validators = [
    {
      country: 'US',
      validator: Validators.compose([Validators.pattern('[0-9]{5}'), Validators.required]),
      mask: {
        mask: '00000'
      }
    },
    {
      country: 'GB',
      validator: Validators.required,
      mask: {
        mask: '---- ---',
        definitions: {
          '-': /[a-zA-Z0-9]/
        }
      }
    },
    {
      country: 'CA',
      validator: Validators.compose([Validators.pattern('[0-9A-Za-z ]{7}'), Validators.required]),
      mask: {
        mask: '--- ---',
        definitions: {
          '-': /[a-zA-Z0-9]/
        }
      }
    },
    {
      country: 'AU',
      validator: Validators.compose([Validators.pattern('[0-9]{4}'), Validators.required]),
      mask: {
        mask: '0000'
      }
    },
    {
      country: 'DEFAULT',
      validator: Validators.required,
      mask: null
    }
  ];

  getCountrySettings(countryCodeSelected) {
    const validatorObject = this.validators.find(validator => validator.country === countryCodeSelected);
    const defaultValidatorObject = this.validators.find(validator => validator.country === 'DEFAULT');

    return validatorObject || defaultValidatorObject;
  }
}
