import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'tcountdown'
})
export class TidyCountdownPipe implements PipeTransform {

  transform(target: moment.Moment, now: moment.Moment = moment()): string {
    if (target) {
      const diff = target.diff(now);
      if (diff <= 0) {
          return '00:00';
      }

      const duration = moment.duration(diff);
      const hours = duration.hours();
      const remaining = moment(`${hours}:${duration.minutes()}:${duration.seconds()}`, 'h:m:s');

      return remaining.format(this.mask(hours));
    }
  }

  mask(hours: number = 0): string {
    return `${hours ? 'H:' : ''}mm:ss`;
  }
}
