import { Component } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Component({
  selector: 'my-account',
  templateUrl: 'my-account.html',
})

export class MyAccountPage {

  constructor(
    private navCtrl: CustomNavController,
  ) {}

  deleteAccount() {
    this.navCtrl.navigateForward('delete-account');
  }

}
