import { Injectable } from '@angular/core';

import { HttpClient } from 'src/shared/providers/http/http-client';
import { ProposalModel } from 'src/shared/models/proposals.model';

@Injectable()
export class Proposals {

  constructor(
    private httpClient: HttpClient
  ) {}

  getProposals(): Promise<ProposalModel[]> {
    const url = `api/v1/homekeeper/proposals?includes=customer,next_scheduled_job`;
    return this.httpClient.get(url);
  }

  getPrivateProposals(): Promise<ProposalModel[]> {
    const url = `api/v1/homekeeper/proposals?includes=customer,next_scheduled_job&is_private=true`;
    return this.httpClient.get(url);
  }

  getProposalsByClient(customerId): Promise<ProposalModel[]> {
    const url = `api/v1/homekeeper/proposals?includes=customer,next_scheduled_job&customer_id=${customerId}`;
    return this.httpClient.get(url);
  }

  getProposalDetail(id): Promise<ProposalModel> {
    const url = `api/v1/homekeeper/proposals/${id}?includes=customer,next_scheduled_job`;
    return this.httpClient.get(url);
  }

  createProposal(params) {
    const url = `api/v1/homekeeper/proposals`;
    return this.httpClient.post(url, params);
  }

  acceptProposal(id) {
    const url = `api/v1/homekeeper/proposals/${id}/accept`;
    return this.httpClient.put(url, {});
  }

  cancelProposal(id) {
    const url = `api/v1/homekeeper/proposals/${id}/cancel`;
    return this.httpClient.put(url, {});
  }
}
