<ng-template #content>
  <tidy-wrapper class="vertical-center hasnt-top-nav ">
    <tidy-card>
      <tidy-row [align]="'center'" class="extra-bottom-padding">
        <tidy-text [header]="'Client Emailed'"> </tidy-text>
      </tidy-row>
      <tidy-row [align]="'center'" class="extra-bottom-padding">
        <tidy-text
          [body]="'We have emailed the other party requesting they call you to help coordinate a pickup. This temporary session will automatically close whenever both parties stop communicating for 48 hours.'"
        >
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-button
          [text]="'Go to My Jobs'"
          [action]="goToMyJobs.bind(this)"
          class="secondary"
        >
        </tidy-button>
      </tidy-row>
    </tidy-card>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
