import { Component } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PrivateClient } from 'src/providers/private-client/private-client';

import { PrivateJobModel } from 'src/shared/models/private-job.model';

import { Loading } from 'src/shared/components/loading/loading';
import { Logger } from 'src/shared/providers/logger';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

@Component({
  templateUrl: 'future-jobs.html'
})

export class PrivateFutureJobsPage extends Timeout {

  accountName: string;
  clientName: string;
  futureJobs: PrivateJobModel[];

  constructor(
    private customNavCtrl: CustomNavController,
    private privateClient: PrivateClient,
    private logger: Logger,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
  }

  @Loading()
  async ionViewDidEnter() {
    this.clientName = await this.customNavCtrl.getParam('clientName', true);
    this.accountName = await this.customNavCtrl.getParam('accountName');
    const addressId = await this.customNavCtrl.getParam('addressId', true);
    try {
      this.futureJobs = await this.privateClient.getFutureJobsForAddress(addressId);
    } catch (err) {
      this.timeoutHandler(err);
      this.customNavCtrl.navigateRoot('jobs');
      this.logger.error(err, 'error-loading-future-jobs')
    }
  }

  goToJob(job) {
    this.customNavCtrl.navigateForward(`private-job/${job.id}`);
  }

}
