
import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'title-card',
  template:
    `
      <div class="title-card">
        <ng-content select="[item]">
        </ng-content>
      </div>
    `,
    styles: [`.title-card{
      font-family: Georgia;
      font-weight: bold;
      font-size: 25px;
      color: #333333;
    }`],
    encapsulation: ViewEncapsulation.None
})
export class TitleCard { }
