import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Team } from 'src/providers/team/team';
import { TidyStorage } from "src/shared/providers/tidy-storage";

import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';
import { removeCurrencyMask } from 'src/shared/utils/currency-utils';
import { CompleteJobModal } from 'src/shared/components/complete-job-modal/complete-job-modal';

@Component({
  templateUrl: 'shared-complete-job.html'
})

export class SharedCompleteJobPage {

  billingType: string;
  errorMessage: string;
  form: UntypedFormGroup;
  lengthItems: TidySelectNumberValueModel[];
  loaded: boolean;
  submitted: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private team: Team,
    private modalCtrl: ModalController,
    private storage: TidyStorage
  ) {
    this.form = this.fb.group({
      length: [''],
      price: [''],
    });
  }

  ionViewDidEnter() {
    this.loaded = false;
    this.lengthItems = this.team.buildJobHourItems();
    this.billingType = this.navCtrl.getParam('billingType');
    if (this.billingType == 'hourly') {
      this.form.controls.length.setValidators([Validators.required]);
    } else {
      this.form.controls.price.setValidators([Validators.required]);
    }
    this.loaded = true;
  }

  async completeJob() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    this.storage.save('sharedJobDuration', this.form.value.length);
    this.storage.save('sharedJobBillingAmount', removeCurrencyMask(this.form.value.price));
    const modal = await this.modalCtrl.create({
      component: CompleteJobModal
    });
    modal.present();
  }

}
