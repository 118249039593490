import { Component } from '@angular/core';

import { MWStore } from "src/main-workflow/mw.store";

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Component({
  templateUrl: 'mw.learn-about-teams.page.html'
})

export class LearnAboutTeamsPage {

  cameFromMainWorkflow: boolean;
  callOutFee: number;
  job: any;
  loaded: boolean;

  constructor(
    private mwStore: MWStore,
    private navCtrl: CustomNavController
  ) {}

  async ionViewDidEnter() {
    this.loaded = false;
    if (!this.job) {
      this.job = this.navCtrl.getParam('job');
      this.callOutFee = this.job?.call_out_price;
      if (!this.job) {
        this.cameFromMainWorkflow = true;
        this.job = await this.mwStore.getJob();
        this.callOutFee = this.job.fee;
      }
    }
    this.loaded = true;
  }

  goToDelegatePage() {
    const params = {
      hkJobId: this.cameFromMainWorkflow ? this.job.homekeeper_job_id : this.job.homekeeper_job.id
    }
    this.navCtrl.navigateForward('delegate-job', params);
  }

  goToCancelJobPage() {
    if (this.cameFromMainWorkflow) {
      this.navCtrl.navigateForward('cancel-job');
    } else {
      this.navCtrl.navigateForward('cancel-job', { job: this.job });
    }
  }
}
