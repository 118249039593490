import { Component } from '@angular/core';
import { Me } from '../../../shared/providers/me';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SuccessPageParamsModel } from 'src/pages/success/success';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Component({
  selector: 'forgot-password',
  templateUrl: 'forgot-password.html'
})
export class ForgotPasswordPage {

  forgotPwdForm: UntypedFormGroup;
  errorMessage: string;
  submitted = false;

  constructor(
    private me: Me,
    private fb: UntypedFormBuilder,
    private customNavCtrl: CustomNavController
  ) {
    this.forgotPwdForm = this.fb.group({
      email: ['', Validators.required]
    });
  }

  sendRecoveryPasswordEmail = async () => {
    this.submitted = true;
    this.errorMessage = '';
    if (this.forgotPwdForm.valid) {
      try {
        await this.me.recoverPassword(this.forgotPwdForm.value.email);
        this.successRecoveryEmail();
      } catch (err) {
        if (err && err.code === 404) {
          this.errorMessage = 'We couldn\'t find an account matching this email.';
        } else {
          this.errorMessage = err.message || err.error.message;
        }
      }
    }
  }

  successRecoveryEmail() {
    const params: SuccessPageParamsModel = {
      header: 'Please Check Your Email',
      body: 'We\'ve sent a link to create a new password.',
      buttonText: 'ok',
      buttonRoute: 'login'
    };
    this.customNavCtrl.navigateForward('success', params);
  }
}
