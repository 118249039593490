import { Injectable } from '@angular/core';
import { Camera } from '../camera/camera';

@Injectable({
  providedIn: 'root'
})
export class FilePicker {

  constructor(
    private camera: Camera
  ) {}

  async getImg() {
    const file = await this.pickFile('image/*');
    const base64String = file.dataUrl.replace(`data:${file.format};base64,`, '');

    return {...file, base64String};
  }

  pickFile(fileTypeFilter: string = '*'): Promise<{ dataUrl: string, format: string, filename: string }> {
    return new Promise<any>(async (resolve, reject) => {
      let input = document.querySelector(
        '#_file-capture-input'
      ) as HTMLInputElement;

      const cleanup = () => {
        input.parentNode && input.parentNode.removeChild(input);
      };

      if (!input) {
        input = document.createElement('input') as HTMLInputElement;
        input.id = '_file-capture-input';
        input.type = 'file';
        document.body.appendChild(input);
      }

      input.accept = fileTypeFilter;
      (input as any).capture = true;

      input.removeAttribute('capture');

      input.addEventListener('change', (e: any) => {
        const file = input.files[0];
        const format = file.type;

        let reader = new FileReader();

        if (file instanceof Blob) {
          const realFileReader = (reader as any)._realReader;
          if (realFileReader) {
            reader = realFileReader;
          }
        }

        reader.onload = async () => {

          const isImage = format.includes('image');
          const dataUrl = isImage ? await this.camera.resizeImage(reader.result as string, format) : reader.result;

          resolve({
            dataUrl,
            format,
            filename: file.name
          });

          cleanup();
        };
        reader.readAsDataURL(file);
      });

      input.click();

      input.onerror = (err) => {
        reject(err);
      };
    });
  }
}
