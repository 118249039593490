import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Pipe({
  name: 'formatDateTitle'
})
export class FormatDateTitlePipe implements PipeTransform {

  constructor(
  ) {}

  transform(value: string) {
    const today = moment();
    const tomorrow = moment().add(1, 'days');
    const date = moment(value, 'YYYY-MM-DD');
    const formatedDate = date.format('M/D/YY');

    if (today.isSame(date, 'day')) {
      return (new TranslationPipe().transform('Today')) + ' ' + formatedDate;
    }

    if (tomorrow.isSame(date, 'day')) {
      return (new TranslationPipe().transform('Tomorrow')) + ' ' + formatedDate;
    }

    const weekDay = moment.weekdays(date.weekday());
    return (new TranslationPipe().transform(weekDay)) + ' ' + formatedDate;
  }

}
