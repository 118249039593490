import { Injectable } from '@angular/core';
import { HttpClient } from 'src/shared/providers/http/http-client';
import { Photo } from '@capacitor/camera';

import { Me } from 'src/shared/providers/me';

import { FilePicker } from 'src/shared/providers/file-picker/file-picker';
import { Aws } from 'src/shared/providers/aws';
import { AttachmentModel } from 'src/models/concierge.model';
import { generateUuid } from '../tidy-session/session-uuid';

@Injectable()
export class Concierge {

  receiptPhoto: Photo;

  constructor(
    private httpClient: HttpClient,
    private me: Me,
    private aws: Aws,
    private filePicker: FilePicker,
  ) {}

  getConciergeItems() {
    const url = 'api/v1/homekeeper/concierge-items?visible=true';
    return this.httpClient.get(url);
  }

  getConciergeItemById(conciergeItemId) {
    const url = `api/v1/homekeeper/concierge-items/${conciergeItemId}`;
    return this.httpClient.get(url);
  }

  getConciergeItemsByJob(jobId) {
    const url = `api/v1/homekeeper/concierge-items?job_id=${jobId}`;
    return this.httpClient.get(url);
  }

  addConciergeItem(data) {
    const url = 'api/v1/homekeeper/concierge-items';
    return this.httpClient.post(url, data);
  }

  addConciergeTicketComment(data) {
    const url = 'api/v1/homekeeper/support/ticket-comments';
    return this.httpClient.post(url, data);
  }

  replyDisputeCounter(conciergeItemId, data){
    const url = `api/v1/homekeeper/concierge-items/${conciergeItemId}/action`;
    return this.httpClient.post(url, data);
  }

  getStatusIcon(status) {
    switch (status) {
      case 'waiting_on_tidy':
        return 'assets/img/help-pending.svg';
      case 'waiting_on_customer':
        return 'assets/img/help-attention.svg';
      case 'completed':
        return 'assets/img/help-done.svg';
      case 'closed':
        return 'assets/img/help-done.svg';
    }
  }

  getReplyTime(type) {
    switch (type) {
      case 'support.homekeeper_job_issue':
        return '2 business days';
      case 'support.homekeeper_how_to':
        return '2 business hours';
      case 'support.homekeeper_payment':
        return '4 business hours';
      case 'support.homekeeper_team':
        return '2 business hours';
      case 'support.homekeeper_private_client':
        return '2 business hours';
      case 'support.homekeeper_other':
        return '2 business days';
      default:
        return '2 business hours';
    }
  }

  async setProInfo() {
    const pro = await this.me.fetchWithoutCache();
    localStorage.setItem('proEmail', pro.profile.email);
  }

  async addAttachment(): Promise<Partial<AttachmentModel>> {
    const uuid = generateUuid();
    const file = await this.filePicker.pickFile();
    const fileKey = `concierge-attachments/${uuid}/${file.filename}`;
    const imagesAllowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (imagesAllowedTypes.includes(file.format)) {
      await this.aws.uploadFileToS3(file.dataUrl, fileKey, file.format);
      return {
        filename: file.filename,
        fileKey
      };
    } else {
      return {
        filename: '',
        fileKey: ''
      };
    }
  }

}
