import { Injectable } from '@angular/core';
import { HttpClient } from '../../shared/providers/http/http-client';

import { RatesPreviewResponseModel, RatesPreviewModel, RatesResponseModel } from 'src/shared/models/client-from-tidy';

@Injectable()
export class ClientFromTidy {

  constructor(
    private httpClient: HttpClient,
  ) {}

  async getPayRates() {
    const url = 'api/v1/homekeeper/team-services?from_tidy=true';
    return this.httpClient.get(url);
  }

  async previewRatesChange(teamServiceId, rate) {
    const url = `api/v1/homekeeper/team-services/${teamServiceId}/preview-pay-rate?repeat_rate=${rate}`;
    return this.httpClient.get(url);
  }

  async requestRatesChange(data) {
    const url = `api/v1/homekeeper/team-services/${data.team_service_id}/pay-rate`;
    return this.httpClient.put(url, data);
  }
}
