import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Certification } from 'src/providers/certification/certification';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PrivateClient } from 'src/providers/private-client/private-client';
import { Proposals } from 'src/providers/proposals/proposals';

import { Loading } from 'src/shared/components/loading/loading';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';
import { titleCase } from 'src/shared/utils/text-utils';

import { CertificationsModel } from 'src/shared/models/certification.model';
import { ClientModel } from 'src/shared/models/client.model';
import { ProposalModel } from 'src/shared/models/proposals.model';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: 'proposals.html'
})

export class ProposalsPage extends Timeout {

  allProposals: ProposalModel[];
  buttonText: string;
  certifications: CertificationsModel
  client: ClientModel;
  clientId: string;
  currentProposals: ProposalModel[];
  currentStatus: string;
  noProposalsCards: any;
  pageTitle: string;
  proposalsLoaded: boolean;

  constructor(
    private certification: Certification,
    private iabUtils: InAppBrowserUtils,
    private navCtrl: CustomNavController,
    private proposals: Proposals,
    private privateClient: PrivateClient,
    public route: ActivatedRoute,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router
  ) {
    super(timeoutErrorHandler, router, route);
  }

  @Loading()
  async ionViewDidEnter() {
    try {
      this.loaded = false;
      this.allProposals = this.navCtrl.getParam('proposals');
      this.currentStatus = 'pending';
      this.certifications = this.navCtrl.getParam('certifications') || await this.certification.getCertifications();
      this.noProposalsCards = this.getNoProposalsCards();
      const clientId = this.route.snapshot.paramMap.get('clientId');
      if (clientId) {
        this.client = await this.privateClient.getPrivateClientInfo(clientId);
        const name = this.client.account_name !== '' && this.client.account_name ? this.client.account_name : titleCase(this.client.first_name);
        this.pageTitle = `${name}'s ${new TranslationPipe().transform('Proposals')}`;
        this.buttonText = `${new TranslationPipe().transform('Send Proposal to')} ${name}`;
      } else {
        this.pageTitle = `Proposals`;
        this.buttonText = `Send Proposal`;
      }
      if (!this.allProposals) {
        if (!this.client) {
          this.allProposals = await this.proposals.getPrivateProposals();
        } else {
          this.allProposals = await this.proposals.getProposalsByClient(clientId);
        }
      }
      this.currentProposals = this.filterProposals('pending');
      this.proposalsLoaded = true;
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  filterProposals(status): any {
    return this.allProposals.filter((proposal) => {
      if (proposal.status == status) {
        return proposal;
      }
    });
  }

  @Loading()
  async updateFilter(status) {
    this.proposalsLoaded = false;
    this.currentStatus = status;
    if (this.client) {
      this.currentProposals = this.filterProposals(status);
    } else {
      this.currentProposals = this.filterProposals(status);
    }
    this.proposalsLoaded = true;
  }

  goToProposalPage(proposal) {
    const params = {
      proposal: proposal
    }
    this.navCtrl.navigateForward(`proposal/${proposal.id}`, params)
  }

  goToSendProposal() {
    if (this.client) {
      this.navCtrl.navigateForward(`send-proposal/${this.client.id}`);
    } else {
      this.navCtrl.navigateForward('send-proposal');
    }
  }

  learnMore() {
    this.iabUtils.openUrl('https://help.tidy.com/pros/proposals');
  }

  goToExistingClientsPage() {
    this.navCtrl.navigateForward('existing-clients');
  }

  getNoProposalsCards() {
    return [
      {
        text: 'Send Clients a beautiful proposal they can accept later',
        image: 'assets/img/send.svg'
      },
      {
        text: 'Clients can review and eSign easily',
        image: 'assets/img/eSign.svg'
      },
      {
        text: 'Automatic reminders increase acceptance up to 50%',
        image: 'assets/img/reminders.svg'
      }
    ];
  }
}
