import { Injectable } from '@angular/core';
import { TidyStorage } from '../../shared/providers/tidy-storage';
import { DateProvider } from '../../shared/providers/date';

@Injectable()
export class UpdateCheck {

  WEEKS_INTERVAL = 4;
  LAST_CHECKED_STORAGE_KEY = 'lastUpdateCheckDisplayed';

  constructor(
    private storage: TidyStorage,
    private dateProvider: DateProvider
  ) {}

  async shouldDisplayCheckUpdate(): Promise<boolean> {
    const lastDisplayedAt: string = await this.storage.retrieve(this.LAST_CHECKED_STORAGE_KEY);

    if (!lastDisplayedAt) {
      await this.updateLastDisplayedCheck();
      return false;
    }

    const lastDisplayedDate = this.dateProvider.parseDateTime(lastDisplayedAt);
    const nextDisplayAt = lastDisplayedDate.add(this.WEEKS_INTERVAL, 'w');

    return nextDisplayAt.isBefore(this.dateProvider.now());
  }

  async updateLastDisplayedCheck(): Promise<void> {
    await this.storage.save(this.LAST_CHECKED_STORAGE_KEY, this.dateProvider.todayNowStr());
  }

}
