import { Component } from '@angular/core';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'page-retention-score',
  templateUrl: 'retention-score.html'
})
export class RetentionScorePage {
  retentionScore: number;

  constructor(public navParams: NavParams) {}

  ngOnInit() {
    this.retentionScore = this.navParams.get('score');
  }
}
