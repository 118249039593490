<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav [class]="extraClass" [canGoBack]="false" [title]="'TIDY.com for Pros'"> </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card>
      <tidy-row class="extra-bottom-padding">
        <tidy-image
          [src]="'assets/img/People.png'"
          class="hero-size">
        </tidy-image>
      </tidy-row>
      <tidy-row [align]="'center'" class="extra-bottom-padding">
        <tidy-text
          [body]="'TIDY for pros helps you earn more with less time on admin work.'">
        </tidy-text>
      </tidy-row>
      <tidy-row class="extra-bottom-padding">
        <tidy-divider>
        </tidy-divider>
      </tidy-row>
      <tidy-grid>
        <tidy-row>
          <ion-row>
            <ion-col size="1">
              <tidy-image
                class="body-size"
                [src]="'assets/svg/people.svg'">
              </tidy-image>
            </ion-col>
            <ion-col>
              <tidy-text
                [header]="'Get New Clients'">
              </tidy-text><br>
              <tidy-text
                [body]="'Great pros earn up to $200/day more.*'">
              </tidy-text>
            </ion-col>
          </ion-row>
        </tidy-row>
        <tidy-row>
          <ion-row>
            <ion-col size="1">
              <tidy-image
                class="body-size"
                [src]="'assets/svg/calendar.svg'">
              </tidy-image>
            </ion-col>
            <ion-col>
              <tidy-text
                [header]="'Control Everything'">
              </tidy-text><br>
              <tidy-text
                [body]="'Pick your schedule, area you serve, & services.'">
              </tidy-text>
            </ion-col>
          </ion-row>
        </tidy-row>
        <tidy-row>
          <ion-row >
            <ion-col size="1">
              <tidy-image
                class="body-size"
                [src]="'assets/svg/cash.svg'">
              </tidy-image>
            </ion-col>
            <ion-col>
              <tidy-text
                [header]="'Save Time & Money'">
              </tidy-text><br>
              <tidy-text
                [body]="'Let Clients book & pay online. Optimize driving & deductions.'">
              </tidy-text>
            </ion-col>
          </ion-row>
        </tidy-row>
        <tidy-row>
        <ion-row >
          <ion-col size="1">
            <tidy-image
              class="body-size"
              [src]="'assets/svg/people.svg'">
            </tidy-image>
          </ion-col>
          <ion-col>
            <tidy-text
              [header]="'Delight Your Clients'">
            </tidy-text><br>
            <tidy-text
              [body]="'Share To Do lists & receipts with Clients.'">
            </tidy-text>
          </ion-col>
        </ion-row>
      </tidy-row>
      <tidy-row>
        <ion-row>
          <ion-col size="1">
            <tidy-image
              class="body-size"
              [src]="'assets/svg/timer.svg'">
            </tidy-image>
          </ion-col>
          <ion-col>
            <tidy-text
              [header]="'Fast, Easy, & Free'">
            </tidy-text><br>
            <tidy-text
              [body]="'Get your 1st Client in as little as 2 days.**'">
            </tidy-text>
          </ion-col>
        </ion-row>
      </tidy-row>
      </tidy-grid>
    </tidy-card>

    <tidy-row>
      <tidy-button
        [text]="'Create Account'"
        data-cy="createAccount-button"
        [action]="createAccount.bind(this)"
        class="primary">
      </tidy-button>
    </tidy-row>

    <tidy-row>
      <tidy-button
        [text]="'Log In'"
        data-cy="goToLogin-button"
        [action]="goToLogin.bind(this)"
        class="secondary">
      </tidy-button>
    </tidy-row>

    <tidy-row class="extra-bottom-padding">
      <tidy-text
        [body]="'* Potential earnings from our marketing tools vary by your location, skill, settings, and more.'">
      </tidy-text>
    </tidy-row>

    <tidy-row class="extra-bottom-padding">
      <tidy-text
        [body]="'** Using the app is free, but there are some paid features you will be notified of in advance.'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'TIDY.com for Pros is for bona-fide professionals with their own business (sole proprietorships & new businesses are ok). By using the software you are a customer & absolutely in no way a TIDY.com employee. This is not for you if you are a “gig worker” or looking for employment. We never employ home service workers, but may connect you with clients for your business. By signing up you agree to the '">
      </tidy-text>
      <tidy-text
        [body]="'Service Provider Customer Agreement.'"
        (action)="openAgreement()"
        class="link">
      </tidy-text>
    </tidy-row>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
