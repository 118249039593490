import { Component, ViewEncapsulation } from '@angular/core';

import { Concierge } from 'src/providers/concierge/concierge';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Me } from 'src/shared/providers/me';

import { Loading } from 'src/shared/components/loading/loading';

@Component({
  templateUrl: 'job-issue.html',
  encapsulation: ViewEncapsulation.None
})

export class JobIssuePage {

  didChooseConciergeType: boolean;
  loaded: boolean;
  job: any;
  isMainWorkflow: boolean;

  constructor(
    private concierge: Concierge,
    private me: Me,
    private navCtrl: CustomNavController
  ) {}

  @Loading()
  async ionViewDidEnter() {
    this.loaded = false;
    if (!this.job) {
      this.job = this.navCtrl.getParam('job', true);
      this.isMainWorkflow = this.navCtrl.getParam('isMainWorkflow')
    }
    this.loaded = true;
  }

  async chooseType(type) {
    if (type == 'client') {
      const params = {
        addressId: this.job.address.id,
        homekeeperJobId: this.job.homekeeper_job.id,
        isMainWorkflow: this.isMainWorkflow
      };
      this.navCtrl.navigateForward('report-issue', params);
    } else {
      this.didChooseConciergeType = true;
    }
  }

  async selectIssue(issue) {
    await this.setProInfo();
    let params = {};
    switch(issue) {

      case 'wrong_performance':
        params = {
          job: this.job
        };
        return this.navCtrl.navigateForward('wrong-status', params);

      case 'safety_issue':
        params = {
          isMainWorkflow: this.isMainWorkflow,
          title: 'Safety Issue',
          type: 'support.homekeeper_job_issue',
          metadata: {
            subtype: 'safety_issue',
            job_id: this.job.job.id,
            job_date: this.job.job.date,
            job_time: this.job.job.start_time
          }
        };
        return this.navCtrl.navigateForward('contact-concierge', params);

      case 'other':
        params = {
          isMainWorkflow: this.isMainWorkflow,
          title: 'Other Job Issue',
          type: 'support.homekeeper_job_issue',
          metadata: {
            subtype: 'other_Issue',
            job_id: this.job.job.id,
            job_date: this.job.job.date,
            job_time: this.job.job.start_time
          }
        };
        return this.navCtrl.navigateForward('contact-concierge', params)
    }
  }

  async setProInfo() {
    const pro = await this.me.fetchWithoutCache();
    localStorage.setItem('proEmail', pro.profile.email);
  }

}
