import { CameraResultType, ImageOptions, CameraSource, CameraDirection } from '@capacitor/camera';

const defaultOptions: ImageOptions = {
  width: 1080,
  height: 1080,
  resultType: CameraResultType.Base64,
  source: CameraSource.Camera,
  correctOrientation: true,
  saveToGallery: true
};

export const beforeAfterOptions: ImageOptions = {
  quality: 85,
  ...defaultOptions
};

export const equipmentOptions: ImageOptions = {
  quality: 50,
  ...defaultOptions
};

export const locationConfirmOptions: ImageOptions = {
  quality: 50,
  ...defaultOptions
};

export const disputesOptions: ImageOptions = {
  quality: 50,
  allowEditing: false,
  ...defaultOptions
};

export const reimbursementOptions: ImageOptions = {
  quality: 50,
  allowEditing: false,
  ...defaultOptions,
  source: CameraSource.Prompt,
};
