import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Events } from 'src/providers/events/events';

import { HomekeeperModel } from 'src/shared/models/homekeeper.model';

@Injectable()
export class Authenticated {

  constructor(
    private customNavCtrl: CustomNavController,
    private events: Events,
    private platform: Platform
  ) {}

  async onAuthenticated(me: HomekeeperModel): Promise<any> {
    return this.customNavCtrl.navigateRoot('dashboard')
      .then( () => this.events.publish('auth:authenticated', { me }));
  }

}
