<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Complete To-Do List'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="('Enter your info to send to ' | translate) + clientName + ':'">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            [src]="'assets/svg/information-circle.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Job Detail'">
          </tidy-text>
        </tidy-row>
        <form [formGroup]="form">
          <tidy-row class="field-top-padding field-bottom-padding">
            <tidy-input
              formControlName="name"
              [form]="form.controls.name"
              [label]="'Name'"
              [errorMessage]="'Please enter your name'"
              [submitted]="submitted"
              [icon]="'assets/svg/person-outline.svg'">
            </tidy-input>
          </tidy-row>
          <tidy-row class="field-bottom-padding">
            <tidy-input
              formControlName="email"
              [form]="form.controls.email"
              [label]="'Email'"
              type="email"
              [errorMessage]="'Please enter a valid email'"
              [submitted]="submitted"
              [icon]="'assets/svg/mail-outline.svg'">
            </tidy-input>
          </tidy-row>
          <tidy-row>
            <tidy-input
              formControlName="price"
              [form]="form.controls.price"
              [label]="'Price'"
              [errorMessage]="'Please enter the price you charge'"
              [submitted]="submitted"
              [icon]="'assets/svg/card-outline.svg'">
            </tidy-input>
          </tidy-row>
        </form>
      </tidy-card>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <slider-trigger
        [label]="'Complete To-Do List'"
        [onTriggeredAction]="completeList.bind(this)">
      </slider-trigger>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="('This will send an email to ' | translate) + clientName + (' with a list of what was done and not done.' | translate)">
        </tidy-text>
      </tidy-row>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
