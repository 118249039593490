<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Request Payment'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-alert *ngIf="unpaidJobsWithInvoice?.length > 0">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/send.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Invoice Pending'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="('You already sent an invoice for ' | translate) + unpaidJobsWithInvoice?.length + (' unpaid job' | translate) + (unpaidJobsWithInvoice?.length > 1 ? 's' : '') + (' with ' | translate)">
          </tidy-text>
          <tidy-text
            [body]="(client?.account_name !== '' && client?.account_name) ?
            client?.account_name :
            (client?.first_name + ' ' + (client?.last_name | abbrLastName: true))"
            [translate]="false">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <ng-container *ngIf="unpaidJobItems?.length > 0">
        <tidy-card>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [body]="'Select which job(s) to send invoice:'">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngFor="let job of unpaidJobItems">
            <tidy-checkbox
              [items]="[job]"
              (checkedChange)="selectJob(job)">
            </tidy-checkbox>
          </tidy-row>
          <tidy-row class="extra-top-padding extra-bottom-padding">
            <tidy-divider>
            </tidy-divider>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Don\'t see the job? You can only request for completed unpaid jobs. '">
            </tidy-text>
            <tidy-text
              [body]="'Add job'"
              class="link"
              (action)="goToAddJobPage()">
            </tidy-text>
            <tidy-text
              [body]="' or '">
            </tidy-text>
            <tidy-text
              [body]="'collect deposit'"
              class="link"
              (action)="goToDepositForFutureJobPage()">
            </tidy-text>
            <tidy-text
              [body]="' for a future job.'">
            </tidy-text>
          </tidy-row>
        </tidy-card>

        <tidy-button
          [text]="'Preview Invoice'"
          [action]="goToSendInvoicePage.bind(this)"
          class="primary">
        </tidy-button>
      </ng-container>

      <ng-container *ngIf="unpaidJobItems?.length == 0">
        <tidy-card style="padding: 15px 15px 15px 10px">
          <tidy-row [align]="'center'" class="extra-bottom-padding">
            <tidy-text
              [header]="'Steps to Request Payment'">
            </tidy-text>
          </tidy-row>
          <ng-container *ngFor="let card of noUnpaidJobCards; let last = last">
            <tidy-grid>
              <tidy-row>
                <ion-row>
                  <ion-col size="2" class="vertical-align-center">
                    <div style="margin: auto">
                      <tidy-image
                        [src]="card?.image"
                        style="width:30px">
                      </tidy-image>
                    </div>
                  </ion-col>
                  <ion-col>
                    <tidy-row>
                      <tidy-text
                        [body]="card?.text">
                      </tidy-text>
                    </tidy-row>
                  </ion-col>
                </ion-row>
              </tidy-row>
            </tidy-grid>
            <tidy-row class="extra-top-padding extra-bottom-padding" *ngIf="!last">
              <tidy-divider></tidy-divider>
            </tidy-row>
          </ng-container>
        </tidy-card>

        <tidy-button
          [text]="'Add a Job'"
          [action]="goToAddJobPage.bind(this)"
          class="secondary">
        </tidy-button>

        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'Or '">
          </tidy-text>
          <tidy-text
            [body]="'collect deposit'"
            class="link"
            (action)="goToDepositForFutureJobPage()">
          </tidy-text>
          <tidy-text
            [body]="' for a future job.'">
          </tidy-text>
        </tidy-row>
      </ng-container>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
