import { Injectable, ErrorHandler} from '@angular/core';
import { Logger } from '../shared/providers/logger';
import { Events } from 'src/providers/events/events';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {

  constructor(
    private events: Events,
    private logger: Logger
  ) {
    super();
  }

  handleError(err: Error): void {
    const original = this.extractOriginalError(err);
    // console.error(err);
    const message = this.extractMessage(original);
    this.logger.error(original, message);
  }

  private extractOriginalError(error): Error {
    return error.rejection || error.originalError || error;
  }

  private extractMessage(error): string {
    if (typeof error === 'string') {
      return error;
    }

    if (error._body && error.json && typeof error.json === 'function') {
      const body = error.json();

      if (body.error && body.error.message) {
        const requestId = body.error.request_id.split('-').slice(-1)[0];
        return `${body.error.message}: ${requestId}`;
      }
    }

    if (error.name === 'TimeoutError') {
      return 'The current request is taking too long. Please try again in a moment';
    }

    if (error.request_id){
      return error.message || `An error has occurred: ${error.request_id.split('-').slice(-1)[0]}`;
    }

    return error.message || `An error has occurred`;
  }

}
