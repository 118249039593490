import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PrivatePayments } from 'src/providers/private-payments/private-payments';
import { Timeout } from 'src/shared/components/timeout/timeout';

import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

@Component({
  templateUrl: 'invoices.html',
  encapsulation: ViewEncapsulation.None
})

export class InvoicesPage extends Timeout {

  accountName: string;
  clientName: string;
  clientId: string;
  currentStatus: string;
  paidInvoices;
  pendingInvoices: any;
  showPaidInvoiceLimit: number;
  showPendingInvoiceLimit: number;

  constructor(
    private navCtrl: CustomNavController,
    private privatePayments: PrivatePayments,
    public route: ActivatedRoute,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router
  ) {
    super(timeoutErrorHandler, router, route);
  }

  async ionViewDidEnter() {
    try {
      this.loaded = false;
      this.currentStatus = 'pending';
      this.clientId = this.route.snapshot.paramMap.get('clientId');
      this.clientName = localStorage.getItem('clientName');
      this.accountName = localStorage.getItem('accountName');
      const invoices = await this.privatePayments.getInvoices(this.clientId);
      this.pendingInvoices = this.parseInvoices(invoices, false);
      this.paidInvoices = this.parseInvoices(invoices, true);
      this.showPaidInvoiceLimit = 3;
      this.showPendingInvoiceLimit = 3;
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  parseInvoices(invoices, fullPaid) {
    const array = [];
    invoices.map((invoice) => {
      if (invoice.full_paid == fullPaid) {
        array.push(invoice);
      }
    });
    return array;
  }

  increasePendingInvoiceLimit() {
    this.showPendingInvoiceLimit = 100;
  }

  increasePaidInvoiceLimit() {
    this.showPaidInvoiceLimit = 100;
  }

  goToSentInvoicePage(invoice) {
    const params = {
      invoice: invoice,
      clientName: this.clientName
    };
    this.navCtrl.navigateForward('sent-invoice', params);
  }
}
