import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'client-and-adress-section',
  templateUrl: './client-and-adress-section.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientAndAdressSectionComponent implements OnInit {
  @Input() clientName: string;
  @Input() addressName: string;

  constructor() { }

  ngOnInit() {}

}
