import { Component, Input, OnInit, HostListener } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Photo } from '@capacitor/camera';
import { ActivatedRoute } from '@angular/router';
import { trigger, transition, style, animate, query } from '@angular/animations';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Camera } from 'src/shared/providers/camera/camera';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { DeviceInfoProvider } from 'src/shared/providers/device-info';
import { JobMediasProvider } from 'src/providers/job-medias/job-medias.provider';
import { SharedLinksProvider } from 'src/providers/shared-links/shared-links';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { ToDosProvider } from 'src/providers/to-dos/to-dos';

import { beforeAfterOptions } from 'src/shared/constants/camera';
import { CommonUtils } from 'src/shared/utils/common-utils';
import { Loading } from 'src/shared/components/loading/loading';
import { TodosAnimation } from 'src/shared/utils/todo-animations';

import { ConfirmPage } from 'src/pages/confirm/confirm';

import { async, Subject } from 'rxjs';

import { PrivateJobService } from 'src/providers/private-job/private-job';
import { ReportIssue } from 'src/providers/report-issue/report-issue';
import { MWService } from 'src/main-workflow/mw.service';

import { MWStore } from 'src/main-workflow/mw.store';

import { randomUUID } from 'src/shared/utils/secure-utils';
import { Capacitor } from '@capacitor/core';
import { CompleteJobModal } from 'src/shared/components/complete-job-modal/complete-job-modal';

/*
ABOUT THIS COMPONENT

There are 4 pages that use this component:
- SharedToDoListPage
- SharedJobPage
- PrivateJobPage
- MWToDosPage

When this component is initizialized:
-Parse the To-Dos from storage and patch them into the form
-Parse the B/A photos/videos from storage and display them
-If the job is not complete, upload all B/A photos/videos to AWS (if not already uploaded)
-If photos were uploaded, sync the job

When user inputs a value into To-Dos form:
-Save the value in storage
-Sync the job (if < 30 seconds from last sync)

When user takes a photo or video (B/A photos/videos or custom "Take Photo" To-Do):
-Store the raw photo/video in storage
-(If on mobile) Save the photo/video to the device
-Try to upload the photo/video to AWS (along with any other B/A photo/video that failed to upload)
-Sync the job (if < 30 seconds from last sync)

When user submits the form:
-Run unique logic per page to add a moment or let the user move forward
-Check for custom field errors and show prompt if so
-Complete the job or send user to the next page based on the page

When the user completes the job:
-Upload any remaining B/A photos/videos or custom "Take Photo" To-Do that failed to upload to AWS
-Send all To-Do inputs and media to BE

ToDosComponent:
-Handles parsing To-Dos to display, creating and patching the form, saving To-Dos in storage

ToDosComponent, JobMediasProvider:
-Handles taking custom To-Do "Take Photos" photos

BeforeAfterComponent, JobMediasProvider:
-Handles taking before/after photos/videos

ToDosProvider, SharedLinksProvider, PrivateJobService, MWService + Other Page Components:
-Handles packaging the final payload and completing the To-Do list

Note that after FE uploads media to AWS BE will change the URL at some point in the future. But that shouldn't matter since FE always displays the media (base64/blob) from storage.

SYNCING & AWS LOGIC:

When user updates a To-Do custom field or performance (handled in this component):
1) Store the input in storage
2) Upload all B/A media to AWS and store in storage. Ignore any AWS errors.
3) Upload all custom To-Do photos to AWS and store in storage. Ignore any AWS errors.
3) Sync the job

When user uploads a B/A media (handled in before-after component):
1) (Mobile) Save media in device
2) Store media in storage
3) Upload all B/A media to AWS and store in storage. Ignore any AWS errors.
4) Sync the job (which will upload all custom To-Do photos to AWS and store in storage). Ignore any AWS errors.

When user completes the list (handled in this component or respective page):
TODO


*/

@Component({
  selector: 'tidy-to-dos',
  templateUrl: 'to-dos.component.html',
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            query('.js-todo-group',[
              style({ height: 0, marginBottom: -45, opacity: 0 }),
              animate('0.2s ease-out',
                      style({ height: '100%', marginBottom: 0, opacity: 1 }))
            ]),
          ]
        ),
        transition(
          ':leave',
          [
            query('.js-todo-group',[
              style({ height: '100%', marginBottom: 0, opacity: 1 }),
              animate('0.2s ease-in',
                      style({ height: 0, marginBottom: -45, opacity: 0 }))
            ]),
          ]
        ),
      ]
    )
  ],
})

export class ToDosComponent implements OnInit {

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenwidth = event.target.innerWidth
  }

  @Input() mwJob: any;
  @Input() mwToDos: any;
  @Input() page: string;
  @Input() privateJob: any;
  @Input() rooms: any;
  @Input() sharedJob: any;
  @Input() sharedList: any;
  accessPhotos: any[];
  baMediaType: string;
  beforeAfterJobType: string;
  beforeAfterState: string;
  blockCompleteOnMissingFields: boolean;
  clientName: string;
  closingNotes: string;
  closingPhotos: any[];
  currentRoomId: number;
  destroy$ = new Subject<void>();
  disableAnimations: boolean;
  doNotCleanRooms: any[];
  form: UntypedFormGroup;
  loaded: boolean;
  map: any;
  mapItems: any[];
  parkingPhotos: any[];
  privateJobAfterPhotos: any[];
  privateJobBeforePhotos: any[];
  screenwidth: number;
  sharedJobStatus: string;
  showAfterPhotosButton: boolean;
  showBeforePhotosButton: boolean;
  showCollapse: boolean = false;
  storedIssues: any;
  storageKey: string;
  submitted: boolean;
  uuid: string;

  constructor(
    private camera: Camera,
    public deviceInfo: DeviceInfoProvider,
    private fb: UntypedFormBuilder,
    private jobMediasProvider: JobMediasProvider,
    private modalCtrl: ModalController,
    private mwService: MWService,
    private mwStore: MWStore,
    private navCtrl: CustomNavController,
    private privateJobService: PrivateJobService,
    private reportIssue: ReportIssue,
    private sharedLinksProvider: SharedLinksProvider,
    private storage: TidyStorage,
    private utils: CommonUtils,
    public route: ActivatedRoute,
    public toDos: ToDosProvider
  ) {
    this.screenwidth = window.innerWidth;
  }

  @Loading()
  async ionViewWillEnter() {
    this.disableAnimations = true;
  }

  async ngOnInit() {
    await (async () => {
      switch (this.page) {
        case 'SharedToDoListPage':
          await this.getSharedToDoListPageData();
          break;
        case 'SharedJobPage':
          await this.getSharedJobPageData();
          break;
        case 'PrivateJobPage':
          await this.getPrivateJobPageData();
          break;
        case 'MWToDosPage':
          await this.getMWToDosPageData();
          break;
      }
    })();
    this.createCustomFieldForms();
    this.checkForBeforeAfterPhotos();
    await this.setInitialStoredToDos();
    await this.patchFormWithStoredToDos();
    await this.saveVariablesInStorage();
    this.loaded = true;
    await this.toDos.uploadAllMediaAndStoreResponse();
    const didUploadNewMedia = await this.storage.retrieve('didUploadNewMedia');
    const mediaFailedToUpload = await this.storage.retrieve('mediaFailedToUpload');
    const updatedToDos = await this.ensureDeletedUuids();
    await this.storage.save(this.storageKey, updatedToDos);
    if (didUploadNewMedia && !mediaFailedToUpload) {
      await this.toDos.syncJob();
    }
    console.log(await this.storage.retrieve(this.storageKey));
    console.log(await this.storage.retrieve(this.storageKey + 'deletedUuids'));
    console.log(await this.storage.retrieve(this.storageKey + 'toDoPayload'));
    setTimeout(() => {
      this.disableAnimations = false;
    }, 300);
  }

  async saveVariablesInStorage() {
    await this.storage.save('uuid', this.uuid);
    await this.storage.save('storageKey', this.storageKey);
    await this.storage.save('page', this.page);
  }

  async storeToDosAndSyncJob(toDo, room, isNewPhoto = false) {
    await this.storeToDo(toDo);
    this.collapseCompleteRoom(room);
    const thirtySecondsHavePassed = await this.checkIfThirtySecondsHavePassed();
    if (thirtySecondsHavePassed || isNewPhoto) {
      await this.toDos.uploadAllMediaAndStoreResponse();
      const mediaFailedToUpload = await this.storage.retrieve('mediaFailedToUpload');
      if (!mediaFailedToUpload && this.page !== 'SharedToDoListPage') {
        await this.toDos.syncJob();
      }
    }
    console.log(await this.storage.retrieve(this.storageKey));
  }

  async storeToDo(toDo) {
    if (this.page == 'MWToDosPage') {
      return await this.mwStore.storeToDo(toDo);
    }
    let dataToStore = {
      address_task_id: toDo.id,
      performance: toDo.performance,
      task_end_time: `${new Date()}`,
      task_options: []
    }
    await Promise.all(toDo.options?.map(async (option) => {
      const value = await this.parseCustomFormFieldValue(toDo, option);
      dataToStore.task_options.push({
        address_task_option_id: option.id,
        value,
      });
    }));
    let storedToDos = await this.storage.retrieve(this.storageKey);
    const index = storedToDos.todos.findIndex(item => item.address_task_id === toDo.id);
    if (index !== -1) {
      storedToDos.todos[index].task_options.forEach((option) => {
        const optionIndex = dataToStore.task_options.findIndex(item => item.address_task_option_id === option.address_task_option_id);
        dataToStore.task_options[optionIndex].payload_value = option.payload_value;
      });
      storedToDos.todos[index] = dataToStore;
    } else {
      storedToDos.todos.push(dataToStore);
    }
    await this.storage.save(this.storageKey, storedToDos);
  }

  // INFO: This is a workaround to ensure that the deleted photos are really deleted cause the data got lost sometimes idk why
  private async ensureDeletedUuids() {
    const deletedUuids = await this.storage.retrieve(
      this.storageKey + 'deletedUuids'
    );
    const storedToDos = await this.storage.retrieve(this.storageKey);
    const isMwJob = this.page === 'MWToDosPage';
    if (!isMwJob) {
      storedToDos.todos.forEach((toDo) => {
        toDo.task_options.forEach((option) => {
          if (Array.isArray(option.payload_value)) {
            option.payload_value = option.payload_value.map((item) => {
              const uuid = item.split('uuid=')[1];
              const isDeletedKey = '&deleted=true';
              if (deletedUuids?.includes(uuid) && !item?.includes(isDeletedKey)) {
                return item + isDeletedKey;
              }
              return item;
            });
          }
        });
      });
    } else {
      storedToDos.data.todos.forEach((toDo) => {
        toDo.job_task_options.forEach((option) => {
          if (Array.isArray(option.payload_value)) {
            option.payload_value = option.payload_value.map((item) => {
              const uuid = item.split('uuid=')[1];
              const isDeletedKey = '&deleted=true';
              if (deletedUuids?.includes(uuid) && !item?.includes(isDeletedKey)) {
                return item + isDeletedKey;
              }
              return item;
            });
          }
        });
      });
    }
    return storedToDos;
  }

  async checkIfThirtySecondsHavePassed() {
    const currentTime = Date.now();
    const thirtySeconds = 30000;
    const lastSyncTime = await this.storage.retrieve('lastStoreToDosAndSyncJobTime') || 0;
    const shouldSync = (currentTime - lastSyncTime) >= thirtySeconds;
    await this.storage.save('lastStoreToDosAndSyncJobTime', currentTime);
    return shouldSync;
  }

  async getSharedToDoListPageData() {
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    this.storageKey = 'sharedJobToDos/' + this.uuid;
    this.beforeAfterJobType = 'shared_to_do_list';
    this.beforeAfterState = this.sharedList?.shared_link_data?.address_task_list?.before_after_photos_state;
    this.blockCompleteOnMissingFields = this.sharedList?.shared_link_data?.address_task_list?.block_complete_on_missing_fields;
    this.markDoNotCleanRooms(this.sharedList?.shared_link_data?.address_task_list?.do_not_clean_rooms);
    this.page = 'SharedToDoListPage';
    this.clientName = this.sharedList?.shared_link_data?.customer?.first_name;
    this.toDos.didStartJob = await this.storage.retrieve(`${this.storageKey}/start-moment`);
    this.closingNotes = this.sharedList?.shared_link_data?.address?.home_close;
    this.storedIssues = await this.storage.retrieve(`sharedListIssueReport/${this.uuid}`);
  }

  async getSharedJobPageData() {
    this.uuid = this.sharedJob.uuid;
    this.storageKey = 'sharedJobToDos/' + this.uuid;
    this.beforeAfterJobType = 'shared_job';
    this.markDoNotCleanRooms(this.sharedJob.shared_link_data?.address_task_list?.do_not_clean_rooms);
    this.beforeAfterState = this.sharedJob.shared_link_data.address_task_list?.before_after_photos_state;
    this.blockCompleteOnMissingFields = this.sharedJob.shared_link_data.address_task_list?.block_complete_on_missing_fields;
    this.sharedJobStatus = this.sharedJob?.shared_link_data?.job?.private_status;
    this.closingNotes = this.sharedJob?.shared_link_data?.address?.home_close;
    this.page = 'SharedJobPage';
    this.clientName = this.sharedJob?.shared_link_data?.customer?.first_name;
    this.toDos.didStartJob = await this.storage.retrieve(`${this.storageKey}/start-moment`);
    this.storedIssues = await this.storage.retrieve(`sharedJobIssueReport/${this.uuid}`);
    await this.storage.save('jobId', this.sharedJob.shared_link_data.job.id);
    await this.storage.save('sharedJobProState', this.sharedJob.shared_link_data.homekeepers[0].state);
    await this.storage.save('sharedJobProEmail', this.sharedJob.shared_link_data.homekeepers[0].email);
    await this.storage.save('sharedJobBillingType', this.sharedJob.shared_link_data.job.billing_type);
    await this.storage.save('sharedJobIsPrivate', this.sharedJob.shared_link_data.job.is_private);
  }

  async getPrivateJobPageData() {
    this.storageKey = `privateJobSyncData/${this.privateJob.job.id}`;
    this.beforeAfterJobType = 'private_job';
    this.rooms.map((room, roomIndex) => {
      room.tasks.map((task, taskIndex) => {
        this.rooms[roomIndex].tasks[taskIndex] = {
          ...task,
          ...task.address_task
        }
      });
    });
    this.beforeAfterState = this.privateJob.job?.address_task_list?.before_after_photos_state;
    this.blockCompleteOnMissingFields = this.privateJob.job?.address_task_list?.block_complete_on_missing_fields;
    this.uuid = this.privateJob?.job?.id;
    this.storedIssues = await this.storage.retrieve(`privateJobIssueReports/${this.privateJob.job.id}`);
    if (this.privateJob.job.private_status !== 'scheduled') {
      this.storedIssues = await this.reportIssue.getReportedIssues([this.privateJob.homekeeper_job.id]);
      const jobMedias = await this.jobMediasProvider.getJobMedias([this.privateJob.job.id]);
      this.privateJobBeforePhotos = this.privateJobService.getParsedBeforeAfterPhotos(jobMedias, 'before_job');
      this.privateJobAfterPhotos = this.privateJobService.getParsedBeforeAfterPhotos(jobMedias, 'after_job');
    }
    await this.storage.save('jobId', this.privateJob.job.id);
    const storedToDos = await this.storage.retrieve(this.storageKey);
    const toDosWereChangedByClient = this.privateJobService.checkIfToDosWereChangedByClient(this.rooms, storedToDos);
    if (toDosWereChangedByClient) {
      await this.storage.delete(this.storageKey)
      await this.setInitialStoredToDos();
    }
  }

  async getMWToDosPageData() {
    this.uuid = this.mwJob?.id;
    this.storageKey = 'syncData';
    this.beforeAfterJobType = 'mw_job';
    this.beforeAfterState = this.mwJob?.before_after_photos;
    this.blockCompleteOnMissingFields = this.mwJob?.block_complete_on_missing_fields;
    this.storedIssues = await this.mwStore.getStoredIssues();
  }

  async setInitialStoredToDos() {
    let storedToDos = await this.storage.retrieve(this.storageKey);
    if (storedToDos || this.page == 'MWToDosPage') {
      return;
    }
    const initialTodos = [];
    await Promise.all(this.rooms?.map(async (room) => {
      await Promise.all(room?.tasks?.map(async (task) => {
        const taskOptions = await Promise.all(task.options.map(async (option) => {
          return {
            address_task_option_id: option.id, //This is id in private job, does that cause issues?
            value: await this.parseCustomFormFieldValue(task, option),
            name: option.name
          }
        }));
        const parsedTask = {
          address_task_id: task.id,
          performance: null,
          task_end_time: `${new Date()}`,
          task_options: taskOptions
        }
        initialTodos.push(parsedTask);
      }));
    }));
    storedToDos = {
      todos: initialTodos,
      before_photos: [],
      after_photos: []
    }
    if (this.page == 'PrivateJobPage') {
      storedToDos['address'] = {
        unit: this.privateJob?.address.unit
      }
    }
    await this.storage.save(this.storageKey, storedToDos);
  }

  async patchFormWithStoredToDos() {
    const storedToDos = await this.storage.retrieve(this.storageKey);
    if (!storedToDos && this.page === 'PrivateJobPage' && this.privateJob?.job?.private_status === 'scheduled') {
      // TODO: Implement the below logic that is mostly done
      // await this.storeAlreadyUploadedBAPhotoVideos();
      // await this.storeAlreadyUploadedTakePhotoAwsUrls();
      // await this.patchFormWithBackendCustomToDosAndStoreToDos();
    }
    if (!storedToDos) {
      return;
    }
    const todos = this.page === 'MWToDosPage' ? storedToDos.data.todos : storedToDos.todos;
    todos.forEach((todo) => {
      this.rooms.forEach((room, roomIndex) => {
        room.tasks.forEach((task, taskIndex) => {
          const taskMatch = this.page === 'MWToDosPage'
            ? task.job_task_id === todo.job_task_id
            : task.id === todo.address_task_id;
          if (taskMatch) {
            task.performance = todo.performance;
            const taskOptions = this.page === 'MWToDosPage' ? todo.job_task_options : todo.task_options;
            this.patchTaskOptions(task, taskOptions, roomIndex, taskIndex);
          }
        });
      });
    });
  }

  private patchTaskOptions(task: any, taskOptions: any[], roomIndex: number, taskIndex: number) {
    task.options.forEach((option) => {
      const matchedOption = taskOptions.find((opt) => opt.id === option.id || opt.address_task_option_id === option.id);
      if (matchedOption && matchedOption.value) {
        const isCheckbox = this.checkIfOptionIsCheckbox(matchedOption);
        if (isCheckbox) {
          this.patchCheckboxOptions(matchedOption, roomIndex, taskIndex, option.id);
        } else {
          this.patchSingleOption(matchedOption.value, roomIndex, taskIndex, option.id);
        }
      }
    });
  }

  private patchCheckboxOptions(matchedOption: any, roomIndex: number, taskIndex: number, optionId: number) {
    const values = matchedOption.value.split(', ').filter(Boolean);
    values.forEach((value) => {
      const controlName = `${optionId}-${value}`;
      const control = this.rooms[roomIndex].tasks[taskIndex].customFieldForm.get(controlName);
      if (control) {
        control.patchValue(true);
      }
    });
  }

  private patchSingleOption(value: string, roomIndex: number, taskIndex: number, optionId: number) {
    const controlName = optionId.toString();
    const control = this.rooms[roomIndex].tasks[taskIndex].customFieldForm.get(controlName);
    if (control) {
      control.patchValue(value);
    }
  }

  checkIfOptionIsCheckbox(todooption) {
    let optionIsCheckbox = false;
    this.rooms.map((room) => {
      room.tasks.map((task) => {
        task.options.map((option) => {
          if ((todooption?.address_task_option_id == option.id || todooption?.id == option.id) && option.name == 'Checkbox(es)') {
            optionIsCheckbox = true;
          }
        });
      });
    });
    return optionIsCheckbox;
  }

  async storeAlreadyUploadedTakePhotoAwsUrls() {
    const alreadyUploadedAwsUrls = [];
    this.rooms.map((toDo) => {
      toDo.assigned_job_task?.options.map((option) => {
        if (option.name == 'Take Photos') {
          option.json_value.map((url) => {
            alreadyUploadedAwsUrls.push(url);
          })
        }
      })
    });
    await this.storage.save(`privateJobAlreadyUploadedAwsUrls/${this.privateJob.job.id}`, alreadyUploadedAwsUrls);
  }

  async storeAlreadyUploadedBAPhotoVideos() {
    const storedToDos = await this.storage.retrieve(this.storageKey);
    storedToDos.before_photos = this.privateJobBeforePhotos;
    storedToDos.after_photos = this.privateJobAfterPhotos;
    await this.storage.save(this.storageKey, storedToDos);
  }

  async patchFormWithBackendCustomToDosAndStoreToDos() {
    this.privateJob.todos.map((room, roomIndex) => {
      room.tasks.map((task, taskIndex) => {
        task.assigned_job_task?.options.map((option, optionIndex) => {
          if (option.json_value && option.json_value !== '') {
            const optionIsCheckbox = option.value_type == 'checkboxes';
            if (optionIsCheckbox) {
              const valueArray = option.json_value.split(', ');
              valueArray.map((value) => {
                this.rooms[roomIndex].tasks[taskIndex].customFieldForm.get(task.address_task.options[optionIndex].id.toString() + '-' + value).patchValue(true);
              });
            } else {
              this.rooms[roomIndex].tasks[taskIndex].customFieldForm.get(task.address_task.options[optionIndex].id.toString()).patchValue(option.json_value);
            }
            this.storeToDosAndSyncJob(task, room);
          }
        });
      });
    });
  }

  async createCustomFieldForms() {
    this.rooms.map((room) => {
      room.tasks.map((todo) => {
        todo.options.map((option) => {
          let array = [];
          option.allowed_values?.map((value) => {
            array.push({
              value: value,
              viewValue: value
            });
          });
          option.allowed_values = array
        });
      });
    });
    this.rooms.map((room) => {
      room.tasks.map((toDo) => {
        let group = {};
        group['taskId'] = [toDo.id];
        toDo.options.map((customField) => {
          if (customField.name == 'Checkbox(es)') {
            customField.allowed_values.map((allowedValue) => {
              group[customField.id + '-' + allowedValue.viewValue] = [false];
            });
          } else {
            if (customField?.required) {
              group[customField.id] = ['', Validators.required];
            } else {
              group[customField.id] = [''];
            }
          }
        });
        if (toDo.options?.length > 0) {
          const customFieldForm: UntypedFormGroup = this.fb.group(group);
          toDo['customFieldForm'] = customFieldForm;
        }
      });
    });
  }

  markDoNotCleanRooms(doNotCleanRooms) {
    this.rooms.map((room, i) => {
      room.tasks.map((task) => {
        task.performance = null;
      });
       doNotCleanRooms.map((doNotCleanRoom) => {
        if (doNotCleanRoom.address_room.id == room.id) {
          this.rooms[i].is_do_not_clean_room = true;
        }
      });
    });
  }

  async checkForBeforeAfterPhotos() {
    if (!this.beforeAfterState) return;
    this.baMediaType = this.beforeAfterState.includes('photo') ? 'photo' : 'video';
    const showBothButtons = ['photo', 'video'];
    const showBeforeOnly = ['before_photo_only', 'before_video_only'];
    const showAfterOnly = ['after_photo_only', 'after_video_only'];
    this.showBeforePhotosButton = showBothButtons.includes(this.beforeAfterState) || showBeforeOnly.includes(this.beforeAfterState);
    this.showAfterPhotosButton = showBothButtons.includes(this.beforeAfterState) || showAfterOnly.includes(this.beforeAfterState);
  }

  async takeCustomToDoPhoto(field, toDoIndex, roomIndex, toDo, room) {
    this.camera.getPhoto(beforeAfterOptions).then(async (photo: Photo) => {
      if (!photo?.base64String && !photo?.dataUrl) {
        return;
      }
      const parsedPhoto = photo?.dataUrl && !photo?.base64String ? photo?.dataUrl : `data:image/jpeg;base64,${photo.base64String}`;
      this.jobMediasProvider.saveImageToDevice(parsedPhoto);
      const { photoField, photoFieldValue } = this.getCustomToDoPhotoField(field, toDoIndex, roomIndex);
      const newPhoto = { url: parsedPhoto, mediaType: 'photo', uuid: randomUUID() };
      photoField.patchValue([...photoFieldValue, newPhoto]);
      this.storeToDosAndSyncJob(toDo, room, true);
    });
  }

  async uploadCustomToDoPhoto(event: any[], field, toDoIndex, roomIndex, toDo, room) {
    try {
      const { photoField, photoFieldValue } = this.getCustomToDoPhotoField(field, toDoIndex, roomIndex);
      await Promise.all(event.map(async (photo) => {
        photo.url = await this.camera.resizeImage(photo.url);
      }));
      const parsedPhotoFieldValues = event.map((photo) => {
        return { url: photo.url, mediaType: photo.mediaType, uuid: photo.uuid, file: photo?.file, exif: photo?.exif };
      });
      photoField.patchValue([...photoFieldValue, ...parsedPhotoFieldValues]);
      this.storeToDosAndSyncJob(toDo, room, true);
    } catch (err) {
      this.utils.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    }
  }

  async removeCustomToDoPhoto(event, field, toDoIndex, roomIndex, toDo, room) {
    try {
      const alertOptions = {
        header: 'Remove Photo',
        message: 'Are you sure you want to remove this photo?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: 'Remove',
            role: 'confirm',
          },
        ],
      };
      const alert = await this.utils.showConfirmAlert(alertOptions);
      const res = await alert.onDidDismiss();
      if (res.role !== 'confirm') {
        return;
      }
      const { photoField, photoFieldValue } = this.getCustomToDoPhotoField(field, toDoIndex, roomIndex);
      const uuid = photoFieldValue[event.index]?.uuid;

      if (event.index >= 0 && event.index < photoFieldValue.length) {
        photoFieldValue.splice(event.index, 1);
        photoField.patchValue(photoFieldValue);
        await this.addDeletedAttributeToPhoto(field, toDo, uuid);
      } else {
        console.warn('Invalid index for splice operation');
      }
    } catch (err) {
      console.error(err);
      this.utils.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    }
  }

  async addDeletedAttributeToPhoto(field, toDo, uuid) {
    const toDoPayload = await this.getToDoPayload();
    const storedToDos = await this.getStoredToDos();
    const isMwJob = this.page === 'MWToDosPage';
    const toDos = isMwJob ? storedToDos.data.todos : storedToDos.todos;
    const fieldId = field.id;
    const todoId = this.getToDoId(toDo);

    toDoPayload.forEach((item) => {
      if (this.isTaskIdMatch(item, todoId)) {
        this.updateTaskOptions(item.task_options, fieldId, uuid);
        this.updateTaskOptions(item.job_task_options, fieldId, uuid);
      }
    });

    toDos.forEach((item) => {
      if (this.isTaskIdMatch(item, todoId)) {
        this.updateTaskOptions(item.task_options, fieldId, uuid);
        this.updateTaskOptions(item.job_task_options, fieldId, uuid);
      }
    });

    if (isMwJob) {
      storedToDos.data.todos = toDos;
    } else {
      storedToDos.todos = toDos;
    }

    let deletedUuids = await this.storage.retrieve(this.storageKey + 'deletedUuids') || [];
    if (deletedUuids) {
      deletedUuids.push(uuid);
    } else {
      deletedUuids = [uuid];
    }

    await this.storage.save(this.storageKey + 'deletedUuids', deletedUuids);
    await this.storage.save(this.storageKey + 'toDoPayload', toDoPayload);
    await this.storage.save(this.storageKey, storedToDos);
    await this.toDos.syncJob();
  }

  private async getToDoPayload() {
    return (await this.storage.retrieve(this.storageKey + 'toDoPayload')) || [];
  }

  private async getStoredToDos() {
    return (await this.storage.retrieve(this.storageKey)) || [];
  }

  private getToDoId(toDo) {
    return toDo?.job_task_id || toDo?.id || toDo?.address_task_id;
  }

  private isTaskIdMatch(item, todoId) {
    return item?.id === todoId || item?.job_task_id === todoId || item?.address_task_id === todoId;
  }

  private updateTaskOptions(taskOptions, fieldId, uuid) {
    if (!taskOptions) return;

    taskOptions.forEach((option) => {
      if (this.isOptionIdMatch(option, fieldId)) {
        this.markPhotoAsDeleted(option, uuid);
        this.removePhotoFromToDo(option, uuid);
      }
    });
  }

  private isOptionIdMatch(option, fieldId) {
    return option?.id === fieldId || option?.address_task_option_id === fieldId;
  }

  private markPhotoAsDeleted(option, uuid) {
    if (uuid && Array.isArray(option.payload_value)) {
      option.payload_value = option.payload_value.map((photo) =>
        photo.includes(uuid) ? `${photo}&deleted=true` : photo
      );
    }
    if (uuid && Array.isArray(option.json_value)) {
      option.json_value = option.json_value.map((photo) =>
        photo.includes(uuid) ? `${photo}&deleted=true` : photo
      );
    }
  }

  private removePhotoFromToDo(option, uuid) {
    if (uuid && Array.isArray(option.value)) {
      option.value = option.value.filter((photo) => !photo?.uuid?.includes(uuid));
    }
  }

  getCustomToDoPhotoField(field, toDoIndex, roomIndex) {
    const photoField = this.rooms[roomIndex].tasks[toDoIndex].customFieldForm.get(field.id.toString());
    const photoFieldValue = photoField.value;
    return { photoField, photoFieldValue };
  }

  async parseCustomFormFieldValue(toDo, option) {
    if (option.name == 'Checkbox(es)') {
      let selections = '';
      option.allowed_values.map((value, index) => {
        if (toDo.customFieldForm?.value[option.id + '-' + value.viewValue]) {
          selections += value.viewValue + ', ';
        }
      });
      return selections.substring(0, selections.length - 2);
    } else {
      return toDo.customFieldForm?.value[option.id];
    }
  }

  getRoomEstimatedTime(room) {
    let estimatedTime = 0;
    room.tasks.map((task) => {
      estimatedTime += task.estimated_time;
    });
    return estimatedTime;
  }

  collapseCompleteRoom(room) {
    const hasSomeCustomFieldFormInvalid = room.tasks.some((task) => {
      return task.customFieldForm?.valid === false;
    });
    if (TodosAnimation.roomTodosDone(room.tasks) && !hasSomeCustomFieldFormInvalid) {
      room.complete = true;
      room.doneTodos = TodosAnimation.countDoneTodos(room.tasks);
      this.currentRoomId = room.id;
      this.showCollapse = true;
    }
  }

  goToLogIssue(type = null) {
    if (this.page == 'MWToDosPage') {
      localStorage.setItem('pathAfterReportIssue', 'to-dos');
      const params = {
        isMainWorkflow: true,
        type: type,
        job: {
          address: {
            id: this.mwJob.addressId
          },
          homekeeper_job: {
            id: this.mwJob.homekeeper_job_id
          },
          job: {
            id: this.mwJob.id,
            date: this.mwJob.date,
            start_time: this.mwJob.time
          }
        }
      };
      return this.navCtrl.navigateForward('job-issue', params);
    }
    const params: any = {
      type: type,
      uuid: this.uuid,
      payload: {
        action: 'issue_report',
        action_params: {}
      }
    };
    switch (this.page) {
      case 'SharedToDoListPage':
        localStorage.setItem('pathAfterReportIssue', `to-do-list/${this.uuid}`);
        params['flow'] = 'sharedList';
        break;
      case 'SharedJobPage':
        localStorage.setItem('pathAfterReportIssue', `job/${this.uuid}`);
        params['flow'] = 'sharedJob';
        params.payload.action_params = {
          homekeeper_job_id: this.sharedJob.shared_link_data.homekeeper_job.id
        }
        break;
      case 'PrivateJobPage':
        localStorage.setItem('pathAfterReportIssue', `private-job/${this.privateJob.job.id}`);
        params['flow'] = 'privateJob';
        params['addressId'] = this.privateJob.address.id,
        params['homekeeperJobId'] = this.privateJob.homekeeper_job.id,
        params['jobId'] = this.privateJob.job.id
        break;
    }
    return this.navCtrl.navigateForward('report-issue', params);
  }

  async completeList(skipCustomFieldCheck = false) {
    switch (this.page) {
      case 'PrivateJobPage': {
        const isStarted = localStorage.getItem(`privateJobStarted/${this.privateJob.job.id}`);
        if (!isStarted && !skipCustomFieldCheck) {
          const moments = [
            {
              name: 'arrived_at_home',
              description: 'Notified Client they have arrived'
            },
            {
              name: 'start',
              description: 'Started job'
            }
          ];
          const route = 'private_job';
          localStorage.setItem(`privateJobStarted/${this.privateJob.job.id}`, 'true');
          this.privateJobService.createPrivateJobMoments(moments, route, this.privateJob.job.id);
          return ;
        }
        break;
      }
      case 'SharedToDoListPage': {
        const startMoment = await this.storage.retrieve(`sharedJob/${this.uuid}/start-moment`);
        if (!startMoment) {
          const data = {
            name: 'start',
            description: 'Started job'
          }
          const moment = await this.sharedLinksProvider.buildPrivateJobMoment(data, 'shared_job');
          await this.storage.save(`sharedJob/${this.uuid}/start-moment`, moment);
          return this.toDos.didStartJob = true;
        }
        break;
      }
      case 'SharedJobPage': {
        const moments = await this.storage.retrieve(`sharedJob/${this.uuid}/moments`);
        const lastSharedJobMoment = localStorage.getItem(`sharedJob/${this.uuid}/last-moment`);
        if (this.sharedJob.shared_link_data.job.is_private) {
          if (!lastSharedJobMoment) {
            this.sharedLinksProvider.addSharedJobMoment(this.uuid, [], 'start', 'Started job');
            return await this.toDos.syncJob();
          }
        } else {
          if (!lastSharedJobMoment) {
            this.sharedLinksProvider.addSharedJobMoment(this.uuid, [], 'on_the_way', 'Notified Client they were on the way');
            return await this.toDos.syncJob();
          } else if (lastSharedJobMoment == 'on_the_way') {
            this.sharedLinksProvider.addSharedJobMoment(this.uuid, moments, 'arrived_at_home', 'Notified Client they have arrived');
            return await this.toDos.syncJob();
          } else if (lastSharedJobMoment == 'arrived_at_home') {
            this.sharedLinksProvider.addSharedJobMoment(this.uuid, moments, 'start', 'Started job');
            return await this.toDos.syncJob();
          }
        }
        break;
      }
      case 'MWToDosPage': {
        if (this.mwService.isTooLateForJob(this.mwJob.endTime)) {
          return this.mwService.showTooLateAlert();
        }
        break;
      }
    }
    const hasCustomFieldError = await this.checkIfHasCustomFieldError(skipCustomFieldCheck);
    if (hasCustomFieldError) {
      return;
    }
    switch (this.page) {
      case 'PrivateJobPage':
        this.privateJobService.goToSendInvoicePage(true, this.privateJob);
        break;
      case 'SharedToDoListPage':
        this.sharedLinksProvider.goToCompleteSharedListPage(this.rooms, this.sharedList, this.clientName, this.uuid);
        break;
      case 'SharedJobPage':
        this.submitted = true;
        if (this.sharedJob.shared_link_data.job.billing_type == 'price_later' || this.sharedJob.shared_link_data.job.billing_type == 'hourly') {
          const params = {
            uuid: this.uuid,
            proEmail: this.sharedJob.shared_link_data.homekeepers[0].email,
            isPrivateJob: this.sharedJob.shared_link_data.job.is_private,
            billingType: this.sharedJob.shared_link_data.job.billing_type,
            jobId: this.sharedJob.shared_link_data.job.id,
            hasAccount: this.sharedJob.shared_link_data.homekeepers[0].state == 'active'
          }
          return this.navCtrl.navigateForward('shared-complete-job', params);
        } else {
          const modal = await this.modalCtrl.create({
            component: CompleteJobModal
          });
          modal.present();
        }
        break;
      case 'MWToDosPage':
        this.mwService.goToMWFinishJobPage(this.mwJob);
        break;
    }
  }

  async checkIfHasCustomFieldError(skipCustomFieldCheck = false) {
    let hasCustomFormFieldError = false;
    let firstErrorTaskId = null;
    if (!skipCustomFieldCheck) {
      this.rooms.map((room) => {
        room.tasks.map((task) => {
          if (task?.customFieldForm && !task?.customFieldForm?.valid && room?.is_do_not_clean_room !== true) {
            task?.customFieldForm?.markAllAsTouched();
            hasCustomFormFieldError = true;
            this.expandRoom(room);
            if (!firstErrorTaskId) {
              firstErrorTaskId = task.id;
            }
          }
        })
      });
      this.submitted = true;
      if (hasCustomFormFieldError && !this.blockCompleteOnMissingFields) {
        const confirmParams = {
          title: 'Skip Required To-Dos?',
          body: 'You did not complete To-Dos that were required by the client. Do you want to finish those To-Dos or skip what you did not finish?',
          backText: 'Go Back & Finish To-Dos',
          confirmText: 'Skip To-Dos',
          backButtonClass: 'secondary',
          confirmButtonClass: 'secondary',
          sameRowButtons: false,
          customBackAction: this.dismissModal.bind(this, firstErrorTaskId),
          confirmAction:  this.completeList.bind(this, true)
        }
        const confirmationModal = await this.modalCtrl.create({
          component: ConfirmPage,
          componentProps: confirmParams,
          animated: false
        });
        confirmationModal.present();
      } else {
        this.goToErrorTask(firstErrorTaskId);
      }
    } else {
      this.modalCtrl.dismiss();
    }
    return hasCustomFormFieldError;
  }

  dismissModal(firstErrorTaskId) {
    this.modalCtrl.dismiss();
    this.goToErrorTask(firstErrorTaskId);
  }

  goToErrorTask(taskId, retry = false) {
    const task = document.getElementById(taskId);
    if (task) {
      task.scrollIntoView({ behavior: 'smooth' });
    } else if (!retry) {
      setTimeout(() => {
        this.goToErrorTask(taskId, true);
      }, 500);
    }
  }

  expandRoom(room) {
    room.complete = false;
    this.currentRoomId = room.id;
    this.showCollapse = false;
  }

  getClosingPhotoURL(photo) {
    if (this.page == 'MWToDosPage') {
      return photo?.id?.toString();
    } else {
      return  photo?.photo_url;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
