<ng-template #content>
  <tidy-wrapper class="vertical-center hasnt-top-nav">
    <tidy-card>
      <tidy-row [align]="'center'" class="extra-bottom-padding">
        <tidy-text
          [header]="params?.header">
        </tidy-text>
      </tidy-row>

      <tidy-row [align]="'center'" class="extra-bottom-padding">
        <tidy-text
          [body]="params?.body">
        </tidy-text>
        <ng-container *ngIf="params?.learnMoreLink">
          <tidy-text
            [body]="' '">
          </tidy-text>
          <tidy-text
            [body]="'Learn More'"
            (action)="goToLearnMore()"
            class="link">
          </tidy-text>
        </ng-container>
      </tidy-row>

      <tidy-error-message *ngIf="errorMessage" [text]="errorMessage"></tidy-error-message>

      <tidy-row *ngIf="params?.secondButtonText">
        <tidy-button
          [text]="params?.secondButtonText"
          [action]="secondButtonAction.bind(this)"
          class="primary">
        </tidy-button>
      </tidy-row>

      <tidy-row>
        <tidy-button
          [text]="params?.buttonText"
          [action]="buttonAction.bind(this)"
          class="secondary">
        </tidy-button>
      </tidy-row>

    </tidy-card>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
