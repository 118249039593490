import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Platform } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { Auth } from 'src/providers/auth/auth';
import { CookieService } from 'ngx-cookie-service';
import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';
import { Logger } from 'src/shared/providers/logger';
import { Me } from 'src/shared/providers/me';

import validationUtils from 'src/shared/utils/validation-utils';
import { Authenticated } from 'src/behaviors/authenticated';
import { SuccessPageParamsModel } from 'src/pages/success/success';

@Component({
  selector: 'create-account',
  templateUrl: 'create-account.html'
})
export class OnboardingCreateAccountPage {

  cameFromJobLink: boolean;
  signupForm: UntypedFormGroup;
  errorMessage: string;
  submitted = false;
  termsError: boolean;
  validationToken: string;
  isMobile: boolean;
  isPrivateJob: boolean;
  quotes: any;
  loaded: boolean;

  constructor(
    private auth: Auth,
    private authenticated: Authenticated,
    private cookieService: CookieService,
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private iabUtils: InAppBrowserUtils,
    private logger: Logger,
    private me: Me,
    private route: ActivatedRoute,
    private platform: Platform
  ) {
    this.signupForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, validationUtils.validateEmail])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
      hasClients: ['', Validators.required],
      aboutTeam: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      terms: [true, Validators.requiredTrue]
    });
  }

  async ionViewDidEnter() {
    this.loaded = false;
    const email = this.route.snapshot.paramMap.get('email');
    this.validationToken = this.route.snapshot.paramMap.get('emailValidationToken');
    this.cameFromJobLink = this.customNavCtrl.getParam('cameFromJobLink');
    if (this.cameFromJobLink) {
      this.isPrivateJob = this.customNavCtrl.getParam('isPrivateJob');
    }
    this.isMobile = this.platform.is('mobile');
    this.quotes = this.getQuotes();

    if (email && this.validationToken) {
      this.signupForm.patchValue({ email });
      this.signupForm.get('email').disable();
    } else if (this.cameFromJobLink) {
      this.signupForm.patchValue({ email });
    }
    this.loaded = true;
  }

  async onSubmit() {
    this.submitted = true;
    this.termsError = false;
    if (this.signupForm.invalid) {
      if (!this.signupForm.controls.terms.valid) {
        this.termsError = true;
      }
      return;
    }
    try {
      await this.auth.getToken();
      await this.me.signUp(this.getFormData());
      await this.authorize();
      const metadataPayload = this.buildMetaDataPayload();
      await this.me.addMetaData(metadataPayload);
      this.success();
    } catch (err) {
      if (err.code === 500 || err.code === 400) {
        if (['This e-mail already exists on our DB', 'Validation failed: Email has already been taken'].includes(err.message))  {
          this.errorMessage =  'There is already an account under this email. Please log in there or use a different email.';
        } else {
          this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
        }
      }
      this.logger.error(err);
    }
  }

  success() {
    const successParams: SuccessPageParamsModel = {
      header: 'Thanks for Signing Up!',
      body: 'TIDY lets you manage your existing clients and get new clients for free!\
        We know your work is hard, and its our mission to help.\
        <br><br>\
        Make sure to check out the "Getting Started" steps on the dashboard for more details,\
        and if you ever need help, contact our Concierge team.',
      buttonText: 'Go To Dashboard',
      buttonRoute: 'dashboard'
    };
    this.customNavCtrl.navigateForward('success', successParams);
  }

  async authorize() {
    await this.login(this.getFormData());
    const me = await this.me.fetchWithoutCache();
    await this.authenticated.onAuthenticated(me);
  }

  async login(data) {
    const loginData = {
      username: data.email,
      password: data.password
    };

    return await this.auth.login(loginData);
  }

  getFormData(): { email: string, password: string } {
    const validationTokenForm =
      this.validationToken ? { email_validation_token: this.validationToken } : {}

    const data = {
      first_name: this.signupForm.controls.firstName.value,
      last_name: this.signupForm.controls.lastName.value,
      email: this.signupForm.controls.email.value,
      password: this.signupForm.controls.password.value,
      ...validationTokenForm
    };

    return data;
  }

  openAgreement = () => {
    this.iabUtils.openUrl('https://www.tidy.com/legal/service-provider/');
  }

  getQuotes() {
    var Quotes = [
      {
        icon: 'assets/img/tidy_hk_1.jpg',
        text: '\"Wow... I made over $1,000 more in a week with clients who booked me on tidy.com. Off to a great start!\"',
        person: 'Kris M'
      },
      {
        icon: 'assets/img/tidy_hk_2.jpg',
        text: '\"It\'s awesome to set my preferences and only let clients book me who match my rules.\"',
        person: 'Sandra M'
      }
    ];
      return Quotes;
  }

  selectTeamAnswer(selection) {
    if (selection.value == 'yes') {
      this.signupForm.controls.aboutTeam.clearValidators();
      this.signupForm.controls.aboutTeam.updateValueAndValidity();
    } else {
      this.signupForm.controls.aboutTeam.setValidators([Validators.required]);
    }
  }

  buildMetaDataPayload() {
    const value: any = [
      {
        question: 'Do you already have existing Clients you work with?',
        answer: this.signupForm.value.hasClients
      }
    ];
    if (this.signupForm.value.hasClients == 'yes') {
      value.push({
        question: 'What does your team look like?',
        answer: this.signupForm.value.aboutTeam
      })
    }
    return {
       key: 'pa-create-account-page',
       value: JSON.stringify(value)
    };
  }
}
