import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Team } from 'src/providers/team/team';

import { ConfirmPageParamsModel, ConfirmPage } from 'src/pages/confirm/confirm';
import { SuccessPageParamsModel } from 'src/pages/success/success';
import { MemberModel } from 'src/shared/models/team.model';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: 'team-member.html'
})

export class TeamMemberPage {

  member: MemberModel;
  errorMessage: string;

  constructor(
    private customNavCtrl: CustomNavController,
    private modalCtrl: ModalController,
    private team: Team,
  ) {}

  async ionViewDidEnter() {
    this.member = this.customNavCtrl.getParams();
  }

  async remove() {
    const confirmResend: ConfirmPageParamsModel = {
      title: 'Remove Member?',
      body: `${new TranslationPipe().transform('Are you sure you want to remove')} ${this.member.homekeeper.name} ${new TranslationPipe().transform('from your team')}?`,
      backText: 'Go Back',
      confirmText: 'Remove',
      confirmAction: this.confirmRemove.bind(this)
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: confirmResend,
      animated: false
    });
    confirmationModal.present();
  }

  async confirmRemove() {
    await this.team.removeTeamMember(this.member.id);
    const onSuccess: SuccessPageParamsModel = {
      header: 'Member Removed',
      body: `${new TranslationPipe().transform('We removed')} ${this.member.homekeeper.email} ${new TranslationPipe().transform('from your team')}`,
      buttonText: 'Ok',
      buttonRoute: 'team'
    };
    this.modalCtrl.dismiss();
    this.customNavCtrl.navigateForward('success', onSuccess);
  }

}
