<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Business Detail'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card>
      <form [formGroup]="form" novalidate action="#">
        <tidy-row class="field-bottom-padding field-top-padding">
          <tidy-input
            [label]="'Business Name'"
            [icon]="'assets/svg/briefcase-outline.svg'"
            [activeIcon]="'assets/svg/briefcase-outline-tidy-green.svg'"
            formControlName="businessName"
            [form]="form.controls.businessName"
            [submitted]="submitted"
            [errorMessage]="formErrorMessage">
          </tidy-input>
        </tidy-row>
        <tidy-row>
          <tidy-textarea
            [label]="'Business Description'"
            [icon]="'assets/svg/chatbox-ellipses-outline.svg'"
            [activeIcon]="'assets/svg/chatbox-ellipses-outline-tidy-green.svg'"
            formControlName="businessDescription"
            [form]="form.controls.businessDescription"
            [submitted]="submitted"
            [errorMessage]="'Please enter a business description.'">
          </tidy-textarea>
        </tidy-row>
      </form>
    </tidy-card>

    <tidy-error-message
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-button
      [action]="save.bind(this)"
      [text]="'Save Changes'"
      class="primary">
    </tidy-button>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
