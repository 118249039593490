import { Component, ViewEncapsulation } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Component({
  selector: 'pause-schedule',
  templateUrl: 'pause-schedule.html',
  encapsulation: ViewEncapsulation.None
})
export class PauseSchedulePage {

  constructor(
    private customNavCtrl: CustomNavController,
  ) {}

  goBack = () => {
    this.customNavCtrl.navigateForward(['bookable-hours']);
  }

  startPausing = () => {
    this.customNavCtrl.navigateForward(['pause-schedule-date']);
  }
}
