<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Add Address'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card>
      <form [formGroup]="form" novalidate action="#">
        <tidy-row class="field-top-padding field-bottom-padding">
          <tidy-auto-complete-address
            [submitted]="submitted"
            [addressFormControl]="form.controls.address"
            [countryCode]="countryCode"
            (onAddressChosen)="choseItem($event)"
            (onError)="errorMessage = $event"
            (noAddressPickedUpdate)="noAddressPickedTemplate = $event"
            (invalidAddressError)="invalidAddressError = $event"
            data-cy="address"
          ></tidy-auto-complete-address>
        </tidy-row>
        <tidy-row class="field-bottom-padding">
          <tidy-input
            [label]="'Unit / Suite'"
            formControlName="unit"
            data-cy="unit"
            [form]="form.controls.unit"
            [icon]="'assets/svg/home-outline.svg'"
            [activeIcon]="'assets/svg/home-outline-tidy-green.svg'">
          </tidy-input>
        </tidy-row>
        <tidy-row class="field-bottom-padding">
          <tidy-input
            [label]="'Zipcode'"
            formControlName="zipcode"
            data-cy="zipcode"
            [customMask]="zipCodeMask"
            [inputMode]="'numeric'"
            [form]="form.controls.zipcode"
            [icon]="'assets/svg/home-outline.svg'"
            [activeIcon]="'assets/svg/home-outline-tidy-green.svg'"
            [submitted]="submitted"
            [errorMessage]="'Please enter a valid zip code'">
          </tidy-input>
        </tidy-row>
        <tidy-row class="field-bottom-padding">
          <tidy-textarea
            label="Access Notes"
            icon="assets/svg/lock-open-outline.svg"
            [activeIcon]="'assets/svg/lock-open-outline-tidy-green.svg'"
            formControlName="home_access"
            data-cy="accessNotes"
            [form]="form.controls.home_access"
          ></tidy-textarea>
        </tidy-row>
        <tidy-row>
          <tidy-textarea
            label="Closing Notes"
            icon="assets/svg/lock-closed-outline.svg"
            [activeIcon]="'assets/svg/lock-closed-outline-tidy-green.svg'"
            formControlName="home_close"
            data-cy="closingNotes"
            [form]="form.controls.home_close"
          ></tidy-textarea>
        </tidy-row>
      </form>
    </tidy-card>

    <ng-container *ngTemplateOutlet="noAddressPickedTemplate">
    </ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-button
      [text]="'Add Address'"
      data-cy="btn-addAddress"
      [action]="addAddress.bind(this)"
      class="primary">
    </tidy-button>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
