<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="pageContent.title"
  >
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="loaded">
      <tidy-card>
        <form [formGroup]="phoneForm">
          <tidy-row class="field-top-padding">
            <tidy-input-international-phone
              *ngIf="currentPageState === PageState.EDIT"
              formControlName="phone"
              [label]="'Phone'"
              [form]="phoneForm.controls.phone"
              [inputMode]="'numeric'"
              [icon]="'assets/svg/phone-portrait-outline.svg'"
              [activeIcon]="'assets/svg/phone-portrait-outline-tidy-green.svg'"
              [submitted]="submitted"
              [errorMessage]="'Please enter a valid phone.'"
              [icon]="'assets/svg/phone-portrait-outline.svg'"
              [activeIcon]="'assets/svg/phone-portrait-outline-tidy-green.svg'"
              [defaultCountryCode]="proCountryCode"
            >
            </tidy-input-international-phone>
            <tidy-input
              *ngIf="currentPageState === PageState.CODE"
              formControlName="code"
              [inputMode]="'numeric'"
              [label]="pageContent.header"
              [form]="phoneForm.controls.code"
              [icon]="'assets/svg/phone-portrait-outline.svg'"
              [activeIcon]="'assets/svg/phone-portrait-outline-tidy-green.svg'"
              [submitted]="submittedCode"
              [errorMessage]="'Please enter a valid code'"
              [mask]="pageContent.inputMask"
            >
            </tidy-input>
          </tidy-row>
        </form>
      </tidy-card>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-button
        [text]="pageContent.buttonText"
        class="primary"
        [action]="buttonAction.bind(this)"
      >
      </tidy-button>

      <tidy-row [align]="'center'">
        <tidy-text [body]="pageContent.textBellowButton">
        </tidy-text>
      </tidy-row>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
