<ng-template #content>
  <tidy-wrapper class="vertical-center hasnt-top-nav">
    <tidy-card>
      <tidy-row [align]="'center'" class="extra-bottom-padding">
        <tidy-text
          header="Warning Location Services Off">
        </tidy-text>
      </tidy-row>
      <tidy-row [align]="'center'" class="extra-bottom-padding">
        <tidy-text
          [body]="'We can\'t get your location. GPS isn\'t available here or your phone has location disabled.'">
        </tidy-text>
        <tidy-text
          [body]="'More.'"
          class="link"
          (action)="openHelpLink()">
        </tidy-text>
      </tidy-row>

      <tidy-row>
        <tidy-button
          [text]="'Ok'"
          [disabled]="false"
          [action]="dismissModal.bind(this)"
          class="secondary">
        </tidy-button>
      </tidy-row>
    </tidy-card>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
