import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PrivateClient } from 'src/providers/private-client/private-client';
import { Proposals } from 'src/providers/proposals/proposals';
import { PrivatePayments } from 'src/providers/private-payments/private-payments';
import { Team } from 'src/providers/team/team';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { AddressModel } from 'src/shared/models/address.model';
import { AddressSummaryModel } from 'src/shared/models/address-summary.model';
import { BillingHistoryModel } from 'src/models/private-payments.model';
import { ClientModel } from 'src/shared/models/client.model';
import { PrivateJobModel } from 'src/shared/models/private-job.model';
import { ProposalModel } from 'src/shared/models/proposals.model';
import { TeamModel } from 'src/shared/models/team.model';
import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';

import { formatHourToShowAmPm } from 'src/shared/utils/date-utils';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

@Component({
  templateUrl: 'private-client.html'
})

export class PrivateClientPage extends Timeout {

  addressAdded: boolean;
  addresses: Array<TidySelectNumberValueModel>;
  addressSelected: AddressModel;
  addressSummary: AddressSummaryModel;
  addressResponse: Array<AddressModel>;
  backButtonRoute: string;
  billingHistory: BillingHistoryModel;
  client: ClientModel;
  hideFutureJobsArrow: boolean;
  hidePastJobsArrow: boolean;
  website: TeamModel;
  unpaidJobs: PrivateJobModel[];
  pendingProposals: ProposalModel[];
  paidParkingSpots: { [id: string]: string} = {
    'meter' : 'Meter',
    'paidlot' : 'Paid Lot'
  };
  freeParkingSpots: { [id: string]: string} = {
    'street' : 'Street',
    'myspot' : 'My Spot/Driveway',
    'guest' : 'Guest Parking'
  };

  constructor(
    private navCtrl: CustomNavController,
    public privatePayments: PrivatePayments,
    public privateClient: PrivateClient,
    private proposals: Proposals,
    public route: ActivatedRoute,
    private storage: TidyStorage,
    private team: Team,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router
  ) {
    super(timeoutErrorHandler, router, route);
  }

  async ionViewDidEnter() {
    this.loaded = false;
    this.backButtonRoute = await this.storage.retrieve('privateClientBackRoute') || 'clients';
    this.unpaidJobs = [];
    const clientId = this.route.snapshot.paramMap.get('clientId');
    this.client = await this.privateClient.getPrivateClientInfo(clientId);
    try {
      this.billingHistory = await this.privatePayments.getBillingHistory(this.client.id);
    } catch (err) {
      this.timeoutHandler(err);
      //TODO remove this once BE supports billing history for pending Clients
    }
    this.unpaidJobs = await this.privateClient.getUnpaidJobsForClient(this.client.id);
    this.addressResponse = await this.privateClient.getPrivateClientAddress(this.client.id);
    const proposals = await this.proposals.getProposalsByClient(this.client.id);
    this.pendingProposals = this.filterPendingProposals(proposals);
    this.formatAddresses();
    this.loaded = true;
  }

  filterPendingProposals(proposals) {
    const array = [];
    proposals.map((proposal) => {
      if (proposal.status == 'pending') {
        array.push(proposal);
      }
    });
    return array;
  }

  formatAddresses() {
    this.addressAdded = this.addressResponse.length > 0;
    if (this.addressAdded) {
      this.addresses = this.addressResponse.map(item => {
        const viewAddress = item.address.includes(',') ?
          item.address.substring(0, item.address.indexOf(',')) :
          item.address;
        const viewUnit = (item.unit !== '') ? ', Unit ' + item.unit : '';
        const addressName = item?.address_name ? (' - ' + item?.address_name) : '';
        return {
          viewValue: viewAddress + viewUnit + addressName,
          value: item.id
        };
      });
      this.addresses.push({
        viewValue: 'Add Address',
        value: 0
      });
      this.addressSelected = this.addressResponse[0];
      this.getAddressInfo();
    }
  }

  async changeAddress(addressId) {
    if (addressId == 0) {
      return this.addAddress();
    }
    this.addressSelected = await this.addressResponse.find(item => item.id === addressId);
    await this.getAddressInfo();
  }

  async getAddressInfo()  {
    this.addressSummary = await this.privateClient.getAddressSummary(this.addressSelected.id);
    this.hideFutureJobsArrow = !this.addressSummary.next_job;
    this.hidePastJobsArrow = !this.addressSummary.last_job;
  }

  editAddress = (address) => {
    const params = {
      address,
      clientId: this.client.id
    };
    this.navCtrl.navigateForward('/edit-private-address', params);
  }

  goToEditAccess(address) {
    const params = {
      address,
      clientId: this.client.id
    };
    this.navCtrl.navigateForward('/edit-access-notes', params);
  }

  editInfo = () => {
    const params = {
      client: this.client,
      nextJob: this.addressSummary?.next_job
    }
    this.navCtrl.navigateForward('/edit-private-client-info', params);
  }

  addAddress = async () => {
    this.navCtrl.navigateForward('/add-private-address', this.client);
  }

  editPlan(plan) {
    // TODO next_job is not right place to get the job info, but for now BE is not returning some infos from plan data
    // TODO next_job is only a placeholder
    const jobDateAndHour = `
      ${moment(this.addressSummary.next_job.date).format('dddd M/D')} at
      ${formatHourToShowAmPm(this.addressSummary.next_job.start_time)}
    `;
    const params = {
      clientName: this.client.first_name,
      nextJob: this.addressSummary.next_job,
      addressName: this.addressSummary.address.address,
      addressId: this.addressSelected.id,
      jobDateAndHour,
      jobData: this.addressSummary.next_job as PrivateJobModel,
      rescheduleType: 'plan',
      currentFrequency: plan.frequency,
      serviceTypeDetails: plan.service_type_details,
      planId: plan.id
    };
    this.navCtrl.navigateForward(`edit-private-plan`, params);
  }

  goToClientMessagesPage() {
    localStorage.setItem('clientMessagesBackPage', `private-client/${this.client.id}`);
    const params = {
      client: this.client
    };
    this.navCtrl.navigateForward(`client-messages/${this.client.id}`, params);
  }

  goToPastJobs() {
    if (!this.addressSummary.last_job) {
      return;
    }
    const params = this.getJobsParams();
    localStorage.setItem('privateJobBackPage', `private-client/${this.client.id}`)
    this.navCtrl.navigateForward(`/past-jobs/${params.addressId}`, params);
  }

  goToFutureJobs() {
    if (!this.addressSummary.next_job) {
      return;
    }
    const params = this.getJobsParams();
    localStorage.setItem('privateJobBackPage', `private-client/${this.client.id}`)
    this.navCtrl.navigateForward('/future-jobs', params);
  }

  getJobsParams() {
    return {
      addressId: this.addressSelected.id,
      clientName: `${this.client.first_name} ${this.client.last_name}`,
      accountName: this.client.account_name
    };
  }

  addJob() {
    const clientName = this.client.account_name && this.client.account_name !== '' ? this.client.account_name : this.client.first_name;
    this.navCtrl.navigateForward(['add-private-job', this.client.id], { clientName: clientName});
  }

  goToUpcomingFeaturePage(feature) {
    this.navCtrl.navigateForward('upcoming-feature', {feature: feature});
  }

  goToProposalsPage() {
    const params = {
      proposals: this.pendingProposals
    }
    this.navCtrl.navigateForward(`proposals/${this.client.id}`, params);
  }

  goToRequestPaymentPage() {
    const params = {
      unpaidJobs: this.unpaidJobs,
      client: this.client
    };
    this.navCtrl.navigateForward(`request-payment`, params);
  }

  goToBillingOptionsPage() {
    const params = {
      addresses: this.addresses,
      addressSelected: this.addressSelected,
      client: this.client,
      billingHistory: this.billingHistory,
      unpaidJobs: this.unpaidJobs
    }
    this.navCtrl.navigateForward(`client-billing-options/${this.client.id}`, params);
  }

  goToPrivateJobPage(job) {
    const params = {
      cameFromPrivateClientPage: true
    }
    localStorage.setItem('privateJobBackPage', `private-client/${this.client.id}`)
    this.navCtrl.navigateForward(`private-job/${job.id}`, params);
  }

}
