import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Team } from 'src/providers/team/team'

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm';
import { SuccessPageParamsModel } from 'src/pages/success/success';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  selector: 'future-job',
  templateUrl: 'future-job.html'
})
export class FutureJobPage {

  backPage: string;
  cleaning: any;

  constructor(
    private customNavController: CustomNavController,
    private modalCtrl: ModalController,
    private router: Router,
    private team: Team
  ) {
    this.cleaning = this.router.getCurrentNavigation().extras.state;
  }

  ionViewDidEnter() {
    this.backPage = localStorage.getItem('futureJobBackPage') || 'jobs';
  }

  cancel() {
    const params = {
      job : this.cleaning
    };
    this.customNavController.navigateForward('learn-about-teams', params);
  }

  parseCleaningData() {
    return {
      id: this.cleaning.job.id,
      type: this.cleaning.job.type,
      time: this.cleaning.job.start_time,
      date: this.cleaning.job.date,
      performance: this.cleaning.homekeeper_job.performance,
      fee: this.cleaning.call_out_price
    };
  }

  delegateJob() {
    this.customNavController.navigateForward('delegate-job', {hkJobId: this.cleaning.homekeeper_job.id});
  }

  async cancelDelegationRequest(type) {
    const params = this.getDelegationConfirmParams(type);
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });
    confirmationModal.present();
  }

  getDelegationConfirmParams(type): ConfirmPageParamsModel {
    const params = {
      cancel: {
        title: 'Cancel Request?',
        body: `${new TranslationPipe().transform('Please confirm you want to cancel the request for')} ${this.cleaning.job_delegation_request.delegatee.name} ${new TranslationPipe().transform('to accept this job.')}`,
        backText: 'Go Back',
        confirmText: 'Cancel Request',
        confirmAction: this.confirmCancelDelegationRequest.bind(this, type)
      },
      undo: {
        title: 'Undo Acceptance?',
        body: `${new TranslationPipe().transform('Please confirm you want to give this job back to')} ${this.cleaning.job_delegation_request.delegator.name}.`,
        backText: 'Go Back',
        confirmText: 'Confirm',
        confirmAction: this.confirmCancelDelegationRequest.bind(this, type)
      },
      getJobBack: {
        title: 'Undo Delegation?',
        body: `Please confirm you want to complete this job yourself.`,
        backText: 'Go Back',
        confirmText: 'Confirm',
        confirmAction: this.confirmCancelDelegationRequest.bind(this, type)
      }
    }
    return params[type];
  }

  async confirmCancelDelegationRequest(type) {
    await this.team.cancelDelegationRequest(this.cleaning.job_delegation_request.id);
    const successParams = this.getDelegationSuccessParams(this.cleaning.job_delegation_request.delegator.name, type);
    this.modalCtrl.dismiss();
    this.customNavController.navigateForward('success', successParams);
  }

  getDelegationSuccessParams(delegatorName, type): SuccessPageParamsModel {
    const params = {
      cancel: {
        header: 'Request Cancelled',
        body: 'You are expected to complete this job.',
        buttonText: 'Go to Jobs',
        buttonRoute: `jobs`
      },
      undo: {
        header: 'Acceptance Undone',
        body: `${delegatorName} ${new TranslationPipe().transform('is now expected to complete this job.')}`,
        buttonText: 'Go to Jobs',
        buttonRoute: `jobs`
      },
      getJobBack: {
        header: 'Delegation Undone',
        body: `You are expected to complete this job.`,
        buttonText: 'Go to Jobs',
        buttonRoute: `jobs`
      }
    }
    return params[type];
  }
}
