<form [formGroup]="formGroupVar">
  <tidy-row class="field-bottom-padding vertical-align-center">
    <tidy-image
      [src]="'assets/svg/information-circle.svg'"
      class="header-size">
    </tidy-image>
    <tidy-text
      header="{{rescheduleType | titlecase}}">
    </tidy-text>
    <tidy-text
      header="Details">
    </tidy-text>
  </tidy-row>
  <tidy-row class="field-bottom-padding">
    <tidy-datepicker
      [icon]="'assets/svg/calendar-outline.svg'"
      formControlName="date"
      [label]="'Date'"
      [form]="formGroupVar.controls.date"
      [submitted]="submitted"
      [errorMessage]="'Please enter the job\'s start date.'">
    </tidy-datepicker>
  </tidy-row>
  <tidy-row class="field-bottom-padding">
    <tidy-select
      [icon]="'assets/svg/time-outline.svg'"
      [label]="'Start Time'"
      [form]="formGroupVar.controls.startTime"
      formControlName="startTime"
      [items]="times"
      [submitted]="submitted"
      [errorMessage]="'Please enter the job\'s start time.'">
    </tidy-select>
  </tidy-row>
  <tidy-row class="field-bottom-padding">
    <tidy-select
      [icon]="'assets/svg/refresh-circle-outline.svg'"
      [label]="'Frequency'"
      [form]="formGroupVar.controls.frequency"
      formControlName="frequency"
      [items]="frequencies"
      [submitted]="submitted"
      [errorMessage]="'Please enter the job\'s frequency.'">
    </tidy-select>
  </tidy-row>
  <tidy-row [ngClass]="selectedService ? 'field-bottom-padding' : ''">
    <tidy-select
      [icon]="'assets/svg/information-circle-outline.svg'"
      [label]="'Service'"
      [form]="formGroupVar.controls.service"
      formControlName="service"
      [items]="teamServiceItems"
      [submitted]="submitted"
      [errorMessage]="'Please enter the service.'"
      (optionChange)="populatePriceInput($event)">
    </tidy-select>
  </tidy-row>
  <ng-container *ngIf="selectedService">
    <tidy-row *ngIf="showPriceForm" class="field-bottom-padding">
      <tidy-input
        formControlName="price"
        inputMode="numeric"
        [label]="priceLabel"
        [form]="formGroupVar.controls.price"
        [icon]="'assets/svg/cash-outline.svg'"
        [mask]="'currencyMask'"
        [type]="'text'"
        [submitted]="submitted"
        [errorMessage]="'Please enter the service price.'">
      </tidy-input>
    </tidy-row>
    <tidy-row>
      <tidy-textarea
        formControlName="description"
        [label]="'Description'"
        [form]="formGroupVar.controls.description"
        [icon]="'assets/svg/chatbox-ellipses-outline.svg'"
        [type]="'text'"
        [submitted]="submitted"
        [errorMessage]="'Please enter the service description.'">
      </tidy-textarea>
    </tidy-row>
  </ng-container>
</form>
