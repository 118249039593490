<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Add Job'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card>
      <client-and-adress-section
        [clientName]="clientName"
        [addressName]="addressName"
      ></client-and-adress-section>
    </tidy-card>

    <tidy-card>
      <jobs-details-section
        [date]="date"
        [frequency]="frequency"
        [startTimeLabel]="startTimeLabel"
        [service]="service"
        [serviceName]="serviceName"
        [description]="description"
        [price]="price"
        [billingType]="billingType"
      >
      </jobs-details-section>
    </tidy-card>

    <tidy-two-column-row>
      <tidy-button item-left
        [text]="'Go Back'"
        data-cy="btn-goBack"
        [action]="goBack.bind(this)"
        class="secondary">
      </tidy-button>

      <tidy-button item-right
        [text]="'Add Job'"
        data-cy="btn-addJob"
        [action]="saveJob.bind(this)"
        class="primary">
      </tidy-button>

    </tidy-two-column-row>

    <tidy-error-message
      [text]="errorMessage"
    >
    </tidy-error-message>

    <footer-informations></footer-informations>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
