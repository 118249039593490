import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Team } from 'src/providers/team/team';

import { ConfirmPageParamsModel, ConfirmPage } from 'src/pages/confirm/confirm';
import { SuccessPageParamsModel } from 'src/pages/success/success';
import { TeamModel } from 'src/shared/models/team.model';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: 'team-i-am-on.html'
})

export class TeamIAmOnPage {

  teamIAmOn: TeamModel;
  errorMessage: string;

  constructor(
    private customNavCtrl: CustomNavController,
    private modalCtrl: ModalController,
    private team: Team,
  ) {}

  async ionViewDidEnter() {
    this.teamIAmOn = this.customNavCtrl.getParams();
  }

  async leaveTeam() {
    const confirmResend: ConfirmPageParamsModel = {
      title: 'Leave Team?',
      body: `${new TranslationPipe().transform('Are you sure you want to leave')} ${this.teamIAmOn.name}\'s ${new TranslationPipe().transform('team')}?`,
      backText: 'Go Back',
      confirmText: 'Leave Team',
      confirmAction: this.confirmLeaveTeam.bind(this)
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: confirmResend,
      animated: false
    });
    confirmationModal.present();
  }

  async confirmLeaveTeam() {
    await this.team.leaveTeam(this.teamIAmOn.id);
    const onSuccess: SuccessPageParamsModel = {
      header: 'Team Left',
      body: `${new TranslationPipe().transform('You left')} ${this.teamIAmOn.name}\'s ${new TranslationPipe().transform('team')}.`,
      buttonText: 'Ok',
      buttonRoute: 'team'
    };
    this.modalCtrl.dismiss();
    this.customNavCtrl.navigateForward('success', onSuccess);
  }
  
}
