import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Quests } from 'src/providers/quests/quests';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { Loading } from 'src/shared/components/loading/loading';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

import { SuccessPageParamsModel } from 'src/pages/success/success';

import { QuestModel } from 'src/shared/models/quest.model';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { Router, ActivatedRoute } from '@angular/router';

import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

@Component({
  templateUrl: 'quests.html'
})
export class QuestsPage extends Timeout {

  backButtonRoute: string;
  errorMessage: string;
  activeQuests: QuestModel[];
  availableQuests: QuestModel[];
  pastQuests: QuestModel[];

  constructor(
    private customNavCtrl: CustomNavController,
    private iabUtils: InAppBrowserUtils,
    private quests: Quests,
    private storage: TidyStorage,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
  }

  @Loading()
  async ionViewDidEnter() {
    try {
      this.loaded = false;
      const quests = await this.quests.getQuests();
      this.backButtonRoute = await this.storage.retrieve('questsBackRoute') || 'dashboard';
      this.availableQuests = this.quests.parseQuests(quests, 'eligible');
      this.activeQuests = this.quests.parseQuests(quests, 'pending');
      this.pastQuests = this.quests.parseQuests(quests, 'success', 'failure');
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  async startQuest(quest) {
    try {
      await this.quests.startQuest(quest.id);
      const params = this.mountSuccessPageParams(quest.challenge);
      this.customNavCtrl.navigateForward('success', params);
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  mountSuccessPageParams(questChallenge): SuccessPageParamsModel {
    return {
      header: 'Quest Started',
      body: questChallenge,
      buttonText: 'Ok',
      buttonRoute: 'quests'
    };
  }

  learnMore() {
    const url = 'https://help.tidy.com/pros/quests-and-bonuses#quests';
    this.iabUtils.openUrl(url);
  }
}
