<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Client Fees & Booking Protection'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card>
      <tidy-row>
        <tidy-text
          [header]="'Max Cancellation Fees'">
        </tidy-text>
      </tidy-row>
      <tidy-row class="extra-bottom-padding">
        <tidy-text
          [body]="'There are the max cancellation fees you allow clients to charge. Edit these anytime with an account manager. '">
        </tidy-text>
        <tidy-text
          (action)="learnMoreBooking()"
          [body]="'Learn More'"
          class="link">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'$10 - Cancelling within 72 hours'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'$20 - Cancelling within 24 hours'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'$30 - Cancelling within 3 hours'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'$40 - No show'">
        </tidy-text>
      </tidy-row>
    </tidy-card>

    <tidy-card>
      <tidy-row>
        <tidy-text
          [header]="'Booking Protection'">
        </tidy-text>
      </tidy-row>
      <tidy-row class="extra-bottom-padding">
        <tidy-text
          [body]="'Booking Protection means that if a client cancels on you after 8pm the night before, then you keep your schedule open for another client during that time. Disable this on any individual job. '">
        </tidy-text>
        <tidy-text
          (action)="learnMoreFees()"
          [body]="'Learn More'"
          class="link">
        </tidy-text>
      </tidy-row>
    </tidy-card>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
