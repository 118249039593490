<tidy-row class="extra-bottom-padding vertical-align-center">
  <tidy-image
    class="header-size"
    [src]="'assets/svg/person.svg'">
  </tidy-image>
  <tidy-text
    [header]="'Client'">
  </tidy-text>
</tidy-row>
<tidy-row class="vertical-align-center">
  <tidy-image
    [src]="'assets/svg/person-circle-outline.svg'">
  </tidy-image>
  <tidy-text
    [body]="clientName"
    [translate]="false">
  </tidy-text>
</tidy-row>
<tidy-row class="vertical-align-center">
  <tidy-image
    [src]="'assets/svg/home-outline.svg'">
  </tidy-image>
  <tidy-text
    [body]="addressName"
    [translate]="false">
  </tidy-text>
</tidy-row>
