import { Injectable } from '@angular/core';
import { HttpClient } from 'src/shared/providers/http/http-client';

@Injectable()
export class CustomNotificationsService {
  constructor(private httpClient: HttpClient) {}

  async getNotificationsSettings() {
    const url = `api/v1/homekeeper/notification-settings`;
    return this.httpClient.get(url);
  }

  async updateNotificationSettings(payload: any) {
    const url = `api/v1/homekeeper/notification-settings`;
    return this.httpClient.put(url, payload);
  }
}
