<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Add Job'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-card>
        <client-and-adress-section
          [clientName]="clientName"
          [addressName]="addressName">
        </client-and-adress-section>
      </tidy-card>

      <tidy-card>
        <private-reschedule-form
          [submitted]="submitted"
          [disableFrequencyDropdown]="clientHasPlan"
          [rescheduleType]="'Job'"
          [teamServiceTypes]="teamServiceTypes"
          [teamServiceItems]="teamServiceItems">
        </private-reschedule-form>
      </tidy-card>

      <tidy-button
        [text]="'Next'"
        data-cy="btn-next"
        [action]="save.bind(this)"
        [disabled]="!loaded"
        class="primary">>
      </tidy-button>

      <tidy-error-message
        [text]="errorMessage">
      </tidy-error-message>

      <footer-informations></footer-informations>

    </ng-container>

    <ng-container *ngIf="!loaded">

      <tidy-card>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 120px;  height: 20px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 100px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 150px"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row class="field-bottom-padding">
          <ion-skeleton-text animated style="width: 140px;  height: 20px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="field-top-padding field-bottom-padding">
          <ion-skeleton-text animated style="width: 140px; margin-bottom: 5px"></ion-skeleton-text>
          <tidy-divider></tidy-divider>
        </tidy-row>
        <tidy-row class="field-top-padding field-bottom-padding">
          <ion-skeleton-text animated style="width: 160px; margin-bottom: 5px"></ion-skeleton-text>
          <tidy-divider></tidy-divider>
        </tidy-row>
        <tidy-row class="field-top-padding field-bottom-padding">
          <ion-skeleton-text animated style="width: 120px; margin-bottom: 5px"></ion-skeleton-text>
          <tidy-divider></tidy-divider>
        </tidy-row>
        <tidy-row class="field-top-padding">
          <ion-skeleton-text animated style="width: 140px; margin-bottom: 5px"></ion-skeleton-text>
          <tidy-divider></tidy-divider>
        </tidy-row>
      </tidy-card>

      <tidy-row>
        <ion-skeleton-text animated style="height: 70px"></ion-skeleton-text>
      </tidy-row>

      <tidy-alert>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 220px; height: 20px; margin: auto"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 100%; margin: auto"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 230px; margin: auto"></ion-skeleton-text>
        </tidy-row>
      </tidy-alert>

    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
