import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Network } from '@capacitor/network';
import * as moment from 'moment';

import { MWService } from 'src/main-workflow/mw.service';
import { MWStore } from 'src/main-workflow/mw.store';
import { mwMoments } from 'src/main-workflow/mw.moments';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { DeviceInfoProvider } from 'src/shared/providers/device-info';
import { HttpClient } from 'src/shared/providers/http/http-client';

import { ToDosProvider } from 'src/providers/to-dos/to-dos';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { SharedLinksProvider } from 'src/providers/shared-links/shared-links';
import { Auth } from 'src/providers/auth/auth';
import { PrivateJobService } from 'src/providers/private-job/private-job';

@Component({
  templateUrl: 'complete-job-modal.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['complete-job-modal.scss']
})

export class CompleteJobModal implements OnInit {

  errorMessage: string;
  title: any;
  syncStatus: string;
  syncData: any;
  url: string;
  page: string;
  storageKey: string;
  sharedJobProState: string;
  uuid: string;
  jobId: string;
  percentComplete: number = 10;
  isLoggedIn: boolean;
  didSendInvoice: boolean;
  delegationRole: string;
  completedJobResponse: any;

  constructor(
    private deviceInfo: DeviceInfoProvider,
    private httpClient: HttpClient,
    private modalCtrl: ModalController,
    private mwStore: MWStore,
    private mwService: MWService,
    private navCtrl: CustomNavController,
    public toDosProvider: ToDosProvider,
    private storage: TidyStorage,
    private sharedLinksProvider: SharedLinksProvider,
    private auth: Auth,
    private privateJobService: PrivateJobService
  ) {}

  async ngOnInit() {
    this.title = 'Syncing Data';
    this.syncStatus = 'isSyncing';
    await this.storage.save('isCompletingJob', true);
    await this.getPageParams();
    this.completeJob();
  }

  async getPageParams() {
    this.storageKey = await this.storage.retrieve('storageKey');
    this.page = await this.storage.retrieve('page');
    this.syncData = await this.storage.retrieve(this.storageKey);
    this.uuid = await this.storage.retrieve('uuid');
    this.jobId = await this.storage.retrieve('jobId');
  }

  async completeJob() {
    try {
      this.setProgressBarValue();
      this.title = 'Syncing Data';
      this.syncStatus = 'isSyncing';
      this.errorMessage = null;
      await this.toDosProvider.uploadAllMediaAndStoreResponse();
      const mediaFailedToUpload = await this.storage.retrieve('mediaFailedToUpload');
      if (mediaFailedToUpload) {
        this.title = 'Error Completing Job';
        return this.syncStatus = 'badInternetError';
      }
      let payload: any = {
        device_id: await this.deviceInfo.uuid(),
        sync_job_tasks: await this.storage.retrieve(this.storageKey + 'toDoPayload'),
        job_medias: await this.storage.retrieve(this.storageKey + 'jobMediasPayload')
      };
      const moment = {
        name: 'end',
        description: 'Complete job'
      };
      const privateJobEndMoment = await this.sharedLinksProvider.buildPrivateJobMoment(moment, 'private-job');
      try {
        switch (this.page) {
          case 'PrivateJobPage': {
            payload = {
              ...payload,
              job_id: this.jobId,
              moments: [
                privateJobEndMoment
              ]
            };
            const momentsPayload = await this.storage.retrieve(`privateJobMomentsPayload/${this.jobId}`);
            if (momentsPayload) {
              await this.httpClient.post(`api/v1/homekeeper/private/jobs/${this.jobId}/create-moments`, momentsPayload);
              await this.storage.delete(`privateJobMomentsPayload/${this.jobId}`);
            }
            this.delegationRole = await this.storage.retrieve('delegationRole');
            const privateJobAmount = await this.storage.retrieve('privateJobBillingAmount');
            if (privateJobAmount) {
              payload.job_amount = privateJobAmount;
            }
            break;
          }
          case 'SharedToDoListPage': {
            const startMoment = await this.storage.retrieve(`sharedJob/${this.uuid}/start-moment`);
            const sharedListFormPayload = await this.storage.retrieve('sharedListFormPayload');
            payload = {
              action: 'complete_new_homekeeper_job',
              action_params: {
                ...payload,
                moments: [startMoment, privateJobEndMoment],
                name: sharedListFormPayload.name,
                email: sharedListFormPayload.email,
                amount: sharedListFormPayload.amount
              }
            };
            break;
          }
          case 'SharedJobPage': {
            const moments = await this.storage.retrieve(`sharedJob/${this.uuid}/moments`);
            payload = {
              action: 'complete_job',
              action_params: {
                ...payload,
                moments: [...moments, privateJobEndMoment]
              }
            };
            const billingType = await this.storage.retrieve('sharedJobBillingType');
            if (billingType === 'hourly') {
              payload.action_params['duration'] = await this.storage.retrieve('sharedJobDuration');
            }
            if (billingType === 'price_later') {
              payload.action_params['amount'] = await this.storage.retrieve('sharedJobBillingAmount');
            }
            break;
          }
          case 'MWToDosPage': {
            payload = {
              ...payload,
              moments: this.syncData.data.moments,
              location_verification_photo_key: await this.mwService.uploadLocationPicture(),
              job_rating: this.syncData.data.jobRating,
              did_to_dos: this.syncData.data.didToDos,
              left_early: !this.syncData.job.isRepeatClient && this.syncData.data.leftEarly
            };
            if (this.syncData?.data?.upgradePlan) {
              await this.httpClient.post(`api/v1/homekeeper/plans/${this.syncData.job.planId}/plan-upgrade`, {});
            }
            break;
          }
        }
        const endpointUrl = this.getEndpointUrl();
        if (!this.completedJobResponse) this.completedJobResponse = await this.httpClient.post(endpointUrl, payload);
        this.toDosProvider.numberOfMediaUploaded += 1;
        if (this.page == 'PrivateJobPage' && await this.storage.retrieve('shouldSendInvoice')) {
          await this.privateJobService.sendPrivateJobInvoice();
        }
        this.performPostCompleteJobActions(this.completedJobResponse);
      } catch(err) {
        console.error(err);
        const networkStatus = await Network.getStatus();
        const timeOut = err?.name === 'TimeoutError';
        this.title = 'Error Completing Job';
        if (timeOut || !networkStatus.connected) {
          this.syncStatus = 'badInternetError';
        } else {
          this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
          this.syncStatus = 'APIError';
        }
      }
    } catch (error) {
      this.errorMessage = (error.error && error.error.message) ? error.error.message : error.message;
      console.error(error);
    }
  }

  async performPostCompleteJobActions(response) {
    switch (this.page) {
      case 'PrivateJobPage':
        await this.storage.delete('lastJobSyncTime');
        await this.storage.delete(`privateJobSyncData/${this.jobId}`);
        await this.storage.delete(`privateJobPayloadValues/${this.jobId}`);
        await this.storage.delete(`privateJobStarted/${this.jobId}`);
        await this.storage.delete(`privateJob/${this.jobId}`);
        await this.storage.delete(`privateJobIssueReports/${this.jobId}`);
        await this.storage.delete(`bookingNote/${this.jobId}`);
        if (await this.storage.retrieve('shouldSendInvoice')) {
          this.title = 'Job Completed & Invoice Sent';
        } else {
          this.title = 'Job Completed';
        }
        this.syncStatus = 'syncSuccess';
        break;
      case 'SharedToDoListPage':
        let loggedIssuePayload = await this.storage.retrieve(`sharedListIssueReport/${this.uuid}`);
        if (loggedIssuePayload) {
          await Promise.all(loggedIssuePayload.map(async (issue) => {
            issue.payload.action_params['homekeeper_job_id'] = response.result_data.homekeeper_job.id;
            await this.sharedLinksProvider.updateSharedLink(this.uuid, issue.payload);
          }));
        }
        await this.storage.delete(`sharedJobToDos/${this.uuid}`);
        await this.storage.delete(`sharedJob/${this.uuid}/start-moment`);
        await this.storage.delete(`sharedListIssueReport/${this.uuid}`);
        await this.storage.delete(`sharedList/${this.uuid}`)
        this.syncStatus = 'syncSuccess';
        this.title = 'To-Dos Completed';
        break;
      case 'SharedJobPage':
        await this.storage.delete(this.storageKey);
        await this.storage.delete(`sharedJob/${this.uuid}/start-moment`);
        await this.storage.delete(`sharedJobIssueReport/${this.uuid}`);
        await this.storage.delete('lastJobSyncTime');
        await this.storage.delete(`sharedJob/${this.uuid}`)
        this.sharedJobProState = await this.storage.retrieve('sharedJobProState');
        this.isLoggedIn = await this.auth.isAuthenticated();
        this.syncStatus = 'syncSuccess';
        this.title = 'Job Completed';
        break;
      case 'MWToDosPage':
        this.mwService.sendMomentToBackend(mwMoments.jobSynced, 'sync-data-modal', this.syncData.job.jobId);
        this.mwStore.clearStore();
        if (!this.syncData?.pastJobNeedsSyncing && this.syncData?.job?.isRepeatClient && this.syncData?.data?.leftEarly) {
          this.syncStatus = 'syncSuccessFeeWaived';
          this.title = 'Job Completed & Client Fee Waived';
        } else {
          this.syncStatus = 'syncSuccess';
          this.title = 'Job Completed';
        }
        break;
    }
  }

  async goToPageAfterSync() {
    switch (this.page) {
      case 'PrivateJobPage':
        this.navCtrl.navigateForward(`private-job/${this.jobId}`);
        break;
      case 'SharedToDoListPage':
        this.navCtrl.navigateForward('introduction');
        break;
      case 'SharedJobPage':
        const sharedJobIsPrivate = await this.storage.retrieve('sharedJobIsPrivate');
        if (this.isLoggedIn) {
          localStorage.setItem('pastJobBackPage', 'jobs');
          localStorage.setItem('privateJobBackPage', 'jobs');
          const buttonRoute = sharedJobIsPrivate ? `private-job/${this.jobId}` : `past-job/${this.jobId}`;
          this.navCtrl.navigateForward(buttonRoute);
        } else {
          const email = await this.storage.retrieve('sharedJobProEmail');
          const params = {
            cameFromJobLink: true,
            email: email,
            isPrivateJob: sharedJobIsPrivate
          }
          const hasAccount = this.sharedJobProState == 'active';
          if (hasAccount) {
            this.navCtrl.navigateForward('login', params);
          } else {
            this.navCtrl.navigateForward(`create-account/${email}`, params);
          }
        }
        break;
      case 'MWToDosPage':
        this.navCtrl.navigateForward('jobs');
        break;
    }
    this.modalCtrl.dismiss();
  }

  getEndpointUrl() {
    switch (this.page) {
      case 'PrivateJobPage':
        return `api/v1/homekeeper/private/jobs/${this.jobId}/complete`
        break;
      case 'SharedToDoListPage':
        return `api/v1/tidy-website/shared-links/${this.uuid}/execute-action`;
        break;
      case 'SharedJobPage':
        return `api/v1/tidy-website/shared-links/${this.uuid}/execute-action`;
        break;
      case 'MWToDosPage':
        const tooLate = moment().isAfter(moment(this.syncData.job.jobEndTime).add(2, 'hours'));
        return (tooLate || this.syncData?.pastJobNeedsSyncing)  ?
          `api/v2/homekeeper/cleanings/${this.syncData.job.jobId}/close-cleaning` :
          `api/v2/homekeeper/cleanings/${this.syncData.job.jobId}/complete-cleaning`;
        break;
    }
  }

  showClearStore() {
    this.title = 'Clear Job Data?';
    this.syncStatus = 'clearStore';
  }

  async showConfirmedClearStore() {
    this.title = 'Job Data Cleared';
    this.syncStatus = 'clearStoreConfirmed';
    if (this.page == 'MWToDosPage') {
      this.mwStore.clearStore();
    }
    await this.storage.delete('isCompletingJob');
  }

  setProgressBarValue() {
    if (!this.percentComplete) {
      this.percentComplete = 0;
    }
    setTimeout(() => {
      this.percentComplete = Math.min(this.percentComplete + 5, 95);
    }, 1000);
    return this.percentComplete;
  }

}
