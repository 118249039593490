import { Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { AppState } from 'src/shared/providers/http/app-state';
import { Cleanings } from 'src/providers/cleanings/cleanings';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Logger } from 'src/shared/providers/logger';
import { Me } from 'src/shared/providers/me';
import { OnRails } from 'src/providers/on-rails/on-rails';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { TidySelectStringValueModel } from 'src/models/tidy-select-item.model';
import { HomekeeperModel } from 'src/shared/models/homekeeper.model';
import { Loading } from 'src/shared/components/loading/loading';
import { MySchedule } from 'src/providers/my-schedule/my-schedule';
import { forkJoin } from 'rxjs';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { ActivatedRoute, Router } from '@angular/router';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'past-weekly-job-history',
  templateUrl: 'past-weekly-job-history.html',
  encapsulation: ViewEncapsulation.None
})

export class PastWeeklyJobHistoryPage extends Timeout {

  REFRESH_DELAY: number = 1000 * 60 * 2;
  dropdownWeeks: any;
  weekToDisplay: any;
  currentWeek: any = { days: [] };
  isPastWeek = false;
  isLimitedAccount: boolean;
  totals: any;
  today: string;
  permissions: any = {};
  fetchInterval: any;
  hasJobs = true;
  hkPendingDC = false;
  hkState: string;
  hkId: number;
  hkAvailableRepeat = true;
  selectForm: UntypedFormGroup;
  weeksItems: Array<TidySelectStringValueModel>;
  availability: Array<{date: string, time_ranges: Array<{start_time: string, end_time: string}>}>;
  calendarUrl: string;

  constructor(
    private appState: AppState,
    private cleanings: Cleanings,
    private fb: UntypedFormBuilder,
    private onRails: OnRails,
    private storage: TidyStorage,
    private me: Me,
    private customNavCtrl: CustomNavController,
    private logger: Logger,
    private mySchedule: MySchedule,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
    this.selectForm = this.fb.group({
      select: ['', Validators.required]
    });
  }

  async ionViewDidEnter() {
    this.loaded = false;
    this.isLimitedAccount = await this.storage.retrieve('isLimitedAccount');
    this.alreadyShowedOfflineOrTimeoutAlert = false;
    try {
      await this.fetchData();
    } catch (err) {
      this.logger.error(err, 'jobs-fetch');
    }
  }

  async fetchData(): Promise<any> {

    if (!await this.appState.isOnline()) {
      this.alreadyShowedOfflineOrTimeoutAlert = true;
      return this.timeoutErrorHandler.showOfflineAlert(this.successPageLoad, 'jobs', this.retrySubject);
    }

    const currentDate = new Date();
    const nextMonthDate = moment(currentDate).add(1, 'month').toDate();

    const forkJoinSubs = forkJoin({
      currentMonth: this.fetchForDate(currentDate, 'current-month').catch(e => this.timeoutHandler(e)),
      nextMonth: this.fetchForDate(nextMonthDate, 'next-month').catch(() => []),
      dropdownWeeks: this.cleanings.weeksDropdown().catch(e => this.timeoutHandler(e))
    }).pipe(
      takeWhile(() => !this.loaded)
    ).subscribe(fetchList => {

      this.availability = [...fetchList.currentMonth, ...fetchList.nextMonth];
      this.dropdownWeeks = this.filterForOnlyPastWeeks(fetchList.dropdownWeeks);
      this.weekToDisplay = this.checkWeekToDisplay(this.weekToDisplay, this.dropdownWeeks);
      this.selectForm.controls.select.setValue(this.weekToDisplay);
      this.weeksItems = this.parseWeeksDropdown(this.dropdownWeeks);
      this.today = moment().format('YYYY-MM-DD');
      if (!this.fetchInterval) {
        this.fetchInterval = setInterval( () => this.refresh(), this.REFRESH_DELAY );
      }
      const forkJoinPromiseSubs = forkJoin([
        this.refresh(this.weekToDisplay).catch(e => this.timeoutHandler(e)),
      ]).subscribe(promisesResult => {
        this.successPageLoad.emit(true);
        this.loaded = true;
        forkJoinPromiseSubs.unsubscribe();
      });
      forkJoinSubs.unsubscribe();
    });
  }

  filterForOnlyPastWeeks(weeks) {
    const today = moment();
    return weeks.filter(week => {
      const weekStart = moment(week.start_date);
      return weekStart.isBefore(today);
    });
  }

  async fetchForDate(date, fetchMonth): Promise<any> {
    const year: number = date.getFullYear();
    const month: number = date.getMonth() + 1;
    const result =  await this.mySchedule.fetchMonthAvailability(month, year, fetchMonth);
    return result;
  }

  @Loading()
  async changeWeekFilter(startDate) {
    await this.refresh(startDate);
  }

  async refresh(startDate: any = null): Promise<any> {
    if (!startDate) {
      startDate = localStorage.getItem('jobsStartDate');
    }
    localStorage.setItem('jobsStartDate', startDate);
    this.permissions = await this.onRails.cleaningsPermissions();
    if (this.permissions.show_cleaning_confirmation_page) {
      return this.customNavCtrl.navigateRoot('check-in');
    }
    await this.fetchCleanings(startDate);
  }

  async fetchCleanings(startDate: any): Promise<any> {
    this.isPastWeek = false;
    this.checkHkState();

    const endDate = moment(startDate).add(6, 'day').format('YYYY-MM-DD');
    const oneDayNextEndDate = moment(endDate).add(1, 'days');

    this.totals = await this.cleanings.totals(startDate, endDate);
    if (startDate && oneDayNextEndDate.isBefore(moment())) {
      this.isPastWeek = true;
    }
  }

  ionViewWillLeave(): void {
    this.successPageLoad.emit(true);
    this.loaded = true;
    clearInterval(this.fetchInterval);
    this.fetchInterval = null;
  }

  checkWeekToDisplay(weekToDisplay, dropdownWeeks): string {
    return weekToDisplay || dropdownWeeks.find( dropdown => dropdown.name === 'This Week' ).start_date;
  }

  async checkHkState(): Promise<any> {
    const hk: HomekeeperModel = await this.me.fetchWithoutCache();
    this.hkState = hk.user.state;
    this.hkAvailableRepeat = hk.custom_fields.available_for_repeat_clients;
    this.hkId = hk.user.id;
    this.calendarUrl = hk.custom_fields.calendar_url;
  }

  parseWeeksDropdown(weeksData) {
    return weeksData.map(week => {
      return {
        viewValue: week.name,
        value: week.start_date
      };
    });
  }
}
