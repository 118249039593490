<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Wrong Status'"
    [canGoBack]="true"
    [customBack]="'job-issue'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'We\'re sorry this status seems wrong.  What is the correct status?'">
      </tidy-text>
    </tidy-row>

    <tidy-card>
      <tidy-row>
        <tidy-card-button (action)="selectPerformance('completed')">
          <tidy-row>
            <tidy-text
              [body]="'Completed'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="selectPerformance('client_cancelled')">
          <tidy-row>
            <tidy-text
              [body]="'Client Cancelled'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="selectPerformance('could_not_access')">
          <tidy-row>
            <tidy-text
              [body]="'Could Not Access'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="selectPerformance('call_out')">
          <tidy-row>
            <tidy-text
              [body]="'Call Out'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-row>
    </tidy-card>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
