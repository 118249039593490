import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Team } from 'src/providers/team/team';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import validationUtils from 'src/shared/utils/validation-utils';
import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

import { ConfirmPageParamsModel, ConfirmPage } from 'src/pages/confirm/confirm';
import { SuccessPageParamsModel } from 'src/pages/success/success';

import { TeamModel, InviteModel, MemberModel } from 'src/shared/models/team.model';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

import { Loading } from 'src/shared/components/loading/loading';

@Component({
  templateUrl: 'team.html'
})

export class TeamPage extends Timeout
{

  certifiedForPage = false;
  errorMessage: string;
  form: UntypedFormGroup;
  invitesPending: InviteModel[];
  invitationsReceived: InviteModel[];
  isLimitedAccount: boolean;
  submitted: boolean;
  myTeam: TeamModel;
  myTeamMembers: MemberModel[];
  teamsIAmOn: TeamModel[];

  constructor(
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private iabUtils: InAppBrowserUtils,
    private modalCtrl: ModalController,
    private team: Team,
    private storage: TidyStorage,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.fb.group({
      email: ['', Validators.compose([Validators.required, validationUtils.validateEmail])],
    });
  }

  @Loading()
  async ionViewDidEnter() {
    try {
      this.isLimitedAccount = await this.storage.retrieve('isLimitedAccount');
      const hkId = await this.storage.retrieve('hk_id');
      const teams: TeamModel[] = await this.team.getMyTeams();
      this.myTeam = await this.parseMyTeam(hkId, teams);
      await this.parseTeamsIAmOn(hkId, teams);
      await this.parseMyTeamMembers(hkId);
      const invites: InviteModel[] = await this.team.getInvitationsSent();
      this.invitationsReceived = await this.team.getInvitationsReceives();
      this.parseInvitesPending(invites);
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  async parseMyTeam(hkId, teams): Promise<TeamModel> {
    return teams.find((team) => {
      return team.owner.id === hkId;
    })
  }

  async parseTeamsIAmOn(hkId, teams) {
    this.teamsIAmOn = [];
    return teams.forEach((team) => {
      if (team.owner.id !== hkId) {
        this.teamsIAmOn.push(team);
      }
    })
  }

  parseInvitesPending(invites) {
    this.invitesPending = [];
    invites.forEach((invite) => {
      if (invite.invitation_status === 'pending') {
        this.invitesPending.push(invite);
      }
    })
  }

  parseMyTeamMembers(hkId) {
    this.myTeamMembers = [];
    this.myTeam.members.forEach((member) => {
      if (member.homekeeper.id !== hkId) {
        this.myTeamMembers.push(member);
      }
    })
  }

  goToTeamMemberPage(member) {
    this.customNavCtrl.navigateForward('team-member', member);
  }

  goToTeamMemberInvitedPage(invite) {
    this.customNavCtrl.navigateForward('team-member-invited', invite);
  }

  goToTeamIAmOnPage(team) {
    this.customNavCtrl.navigateForward('team-i-am-on', team);
  }

  invite() {
    this.customNavCtrl.navigateForward('team-member-invite');
  }

  editBusinessDetail() {
    const params = {
      name: this.myTeam.name,
      description: this.myTeam.description,
      successPageRoute: 'team'
    };
      this.customNavCtrl.navigateForward('website-business-detail', params);
  }

  async resendInvite(id, email) {
    const params = {
      id: id,
      email: email
    }
    const confirmResend: ConfirmPageParamsModel = {
      title: 'Resend Invite?',
      body: `${new TranslationPipe().transform('Resend an invite to')} ${email}?`,
      backText: 'Go Back',
      confirmText: 'Resend Invite',
      confirmAction: this.confirmResendInvite.bind(this),
      confirmActionParams: params,
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: confirmResend,
      animated: false
    });
    confirmationModal.present();
  }

  async confirmResendInvite(params) {
    await this.team.resendInvite(params.id);
    const onSuccess: SuccessPageParamsModel = {
      header: 'Team Member Invite Resent',
      body: `${new TranslationPipe().transform('We resent an email to')} ${params.email}.`,
      buttonText: 'Ok',
      buttonRoute: 'team'
    };
    this.modalCtrl.dismiss();
    this.customNavCtrl.navigateForward('success', onSuccess);
  }

  async cancelInvite(id, email) {
    const params = {
      id: id,
      email: email
    }
    const confirmCancel: ConfirmPageParamsModel = {
      title: 'Cancel Invite?',
      body: `${new TranslationPipe().transform('Cancel an invite to')} ${email}?`,
      backText: 'Go Back',
      confirmText: 'Cancel Invite',
      confirmAction: this.confirmCancelInvite.bind(this),
      confirmActionParams: params,
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: confirmCancel,
      animated: false
    });
    confirmationModal.present();
  }

  async confirmCancelInvite(params) {
    await this.team.cancelInvite(params.id);
    const onSuccess: SuccessPageParamsModel = {
      header: 'Team Member Invite Cancelled',
      body: `${new TranslationPipe().transform('We cancelled the invite to')} ${params.email}.`,
      buttonText: 'Ok',
      buttonRoute: 'team'
    };
    this.modalCtrl.dismiss();
    this.customNavCtrl.navigateForward('success', onSuccess);
  }

  learnMore() {
    const url = 'https://help.tidy.com/pros/teams';
    this.iabUtils.openUrl(url);
  }

  goToUpcomingFeaturePage(feature) {
    this.customNavCtrl.navigateForward('upcoming-feature', {feature: feature});
  }

  async acceptTeamInvite(invite) {
    const confirmResend: ConfirmPageParamsModel = {
      title: 'Accept Invite?',
      body: `${new TranslationPipe().transform('Accept invite to')} ${invite.team.owner.name}${new TranslationPipe().transform('\'s team?')}`,
      backText: 'Go Back',
      confirmText: 'Accept Invite',
      confirmAction: this.confirmAcceptTeamInvite.bind(this),
      confirmActionParams: invite,
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: confirmResend,
      animated: false
    });
    confirmationModal.present();
  }

  async confirmAcceptTeamInvite(invite) {
    await this.team.acceptInvite(invite.id);
    const onSuccess: SuccessPageParamsModel = {
      header: 'Invite Accepted!',
      body: `${new TranslationPipe().transform('You are now part of')} ${invite.team.owner.name}${new TranslationPipe().transform('\'s team!')}`,
      buttonText: 'Ok',
      buttonRoute: 'team'
    };
    this.modalCtrl.dismiss();
    this.customNavCtrl.navigateForward('success', onSuccess);
  }

  async rejectTeamInvite(invite) {
    const confirmResend: ConfirmPageParamsModel = {
      title: 'Reject Invite?',
      body: `${new TranslationPipe().transform('Reject invite to')} ${invite.team.owner.name}${new TranslationPipe().transform('\'s team?')}`,
      backText: 'Go Back',
      confirmText: 'Reject Invite',
      confirmAction: this.confirmRejectTeamInvite.bind(this),
      confirmActionParams: invite,
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: confirmResend,
      animated: false
    });
    confirmationModal.present();
  }

  async confirmRejectTeamInvite(invite) {
    await this.team.rejectInvite(invite.id);
    const onSuccess: SuccessPageParamsModel = {
      header: 'Invite Rejected',
      body: `${new TranslationPipe().transform('You rejected')} ${invite.team.owner.name}${new TranslationPipe().transform('\'s invite.')}`,
      buttonText: 'Ok',
      buttonRoute: 'team'
    };
    this.modalCtrl.dismiss();
    this.customNavCtrl.navigateForward('success', onSuccess);
  }

  goToServiceProviderAgreement() {
    this.iabUtils.openUrl('https://www.tidy.com/legal/service-provider/');
  }
  
}
