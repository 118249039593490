import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Team } from 'src/providers/team/team';

import { ConfirmPageParamsModel, ConfirmPage } from 'src/pages/confirm/confirm';
import { SuccessPageParamsModel } from 'src/pages/success/success';
import { InviteModel } from 'src/shared/models/team.model';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: 'team-member-invited.html'
})

export class TeamMemberInvitedPage {

  invitedMember: InviteModel;
  errorMessage: string;
  loaded = false;

  constructor(
    private customNavCtrl: CustomNavController,
    private modalCtrl: ModalController,
    private team: Team,
  ) {}

  async ionViewDidEnter() {
    this.invitedMember = this.customNavCtrl.getParams();
    this.loaded = true;
  }

  async resendInvite() {
    const confirmResend: ConfirmPageParamsModel = {
      title: 'Resend Invite?',
      body: `${new TranslationPipe().transform('Resend an invite to')} ${this.invitedMember.email}?`,
      backText: 'Go Back',
      confirmText: 'Resend Invite',
      confirmAction: this.confirmResendInvite.bind(this)
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: confirmResend,
      animated: false
    });
    confirmationModal.present();
  }

  async confirmResendInvite() {
    await this.team.resendInvite(this.invitedMember.id);
    const onSuccess: SuccessPageParamsModel = {
      header: 'Team Member Invite Resent',
      body: `${new TranslationPipe().transform('We resent an email to')} ${this.invitedMember.email}.`,
      buttonText: 'Ok',
      buttonRoute: 'team'
    };
    this.modalCtrl.dismiss();
    this.customNavCtrl.navigateForward('success', onSuccess);
  }

  async cancelInvite() {
    const confirmCancel: ConfirmPageParamsModel = {
      title: 'Cancel Invite?',
      body: `${new TranslationPipe().transform('Cancel an invite to')} ${this.invitedMember.email}?`,
      backText: 'Go Back',
      confirmText: 'Cancel Invite',
      confirmAction: this.confirmCancelInvite.bind(this)
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: confirmCancel,
      animated: false
    });
    confirmationModal.present();
  }

  async confirmCancelInvite() {
    await this.team.cancelInvite(this.invitedMember.id);
    const onSuccess: SuccessPageParamsModel = {
      header: 'Team Member Invite Cancelled',
      body: `${new TranslationPipe().transform('We cancelled the invite to')} ${this.invitedMember.email}.`,
      buttonText: 'Ok',
      buttonRoute: 'team'
    };
    this.modalCtrl.dismiss();
    this.customNavCtrl.navigateForward('success', onSuccess);
  }
  
}
