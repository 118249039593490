import { Component, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { AddressForm } from 'src/providers/address-form/address-form';
import { Certification } from 'src/providers/certification/certification';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Me } from 'src/shared/providers/me';
import { Team } from 'src/providers/team/team';

import { Loading } from 'src/shared/components/loading/loading';

import { AutoCompleteAddressModel } from 'src/shared/models/autocomplete-address.model';
import { HomekeeperModel } from 'src/shared/models/homekeeper.model';
import { ServiceTypeModel } from 'src/shared/models/team.model';
import { TeamServiceTypeModel } from 'src/shared/models/team.model';
import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';

import { MultipleCountryService } from 'src/providers/addresses/multiple-country.service';
import { RadioButtonModel } from '@tidyapp/tidy-ui-components';
import { keepOnlyText } from 'src/shared/utils/text-utils';
import { AddressMetadata } from 'src/shared/components/autocomplete-address/autocomplete-address';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

@Component({
  templateUrl: 'account-info.html',
})

export class OnboardingAccountInfoPage extends Timeout {

  autocompleteAddress: AutoCompleteAddressModel[];
  categoryItems: TidySelectNumberValueModel[];
  didNotSelectServiceError: boolean;
  didChooseCategories: boolean;
  errorMessage: string;
  finishPage: string;
  form: UntypedFormGroup;
  hasAddress: boolean;
  pro: HomekeeperModel;
  submitted = false;
  countryList: RadioButtonModel[];
  zipCodeMask = {
    mask: '00000'
  }
  zipValue: string;
  hasName: boolean;
  noAddressPickedTemplate: ElementRef;
  invalidAddressError: string;
  serviceTypesModel: any = {};
  teamServiceTypes: TeamServiceTypeModel[];

  constructor(
    private addressForm: AddressForm,
    private certification: Certification,
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private me: Me,
    private multipleCountryService: MultipleCountryService,
    private team: Team,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.fb.group({
      countryCode: ['US', Validators.required],
      firstName: [''],
      lastName: [''],
      address: ['', Validators.required],
      unit: [''],
      zip: ['', this.multipleCountryService.getCountrySettings('US').validator],
      gender: ['', Validators.required],
      previouslyCertified: ['', Validators.required],
      latitude: '',
      longitude: '',
      city: [''],
      state: ['', Validators.required],
    });
    this.countryList = this.multipleCountryService.countryRadioButtonList;
  }

  @Loading()
  async ionViewDidEnter() {
    try {
      this.loaded = false;
      this.teamServiceTypes = await this.team.getTeamServiceTypes();
      if (this.teamServiceTypes.length > 0) {
        this.didChooseCategories = true;
      } else {
        this.categoryItems = await this.getCategoryItems();
      }
      this.finishPage = this.customNavCtrl.getParam('finishPage') || 'new-clients';
      this.pro = await this.me.fetchWithoutCache();
      this.hasAddress = this.pro.profile.address.address !== null && this.pro.profile.address.address !== undefined;
      if (this.hasAddress) {
        this.removeValidators('address');
        this.removeValidators('countryCode');
        this.removeValidators('zip');
        this.removeValidators('city');
        this.removeValidators('state');
      }
      this.hasName = this.pro.profile.name !== null && this.pro.profile.name !== undefined && this.pro.profile.name !== ' ';
      if (!this.hasName) {
        this.form.controls.firstName.setValidators([Validators.required]);
        this.form.controls.lastName.setValidators([Validators.required]);
      }
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  removeValidators(controlName) {
    this.form.controls[controlName].clearValidators();
    this.form.controls[controlName].updateValueAndValidity();
  }

  async getCategoryItems() {
    const serviceTypes: ServiceTypeModel[] = await this.team.getServiceTypes();
    const array = serviceTypes.map((type) => {
      this.serviceTypesModel[type.id] = false;
      return {
        viewValue: type.name == 'Cleaning' ? 'Cleaning (most common)' : type.name,
        value: type.id
      };
    });

    const CleaningTypeId = 1;
    this.serviceTypesModel[CleaningTypeId] = true;
    return array;
  }

  choseItem(addressMetadata: AddressMetadata) {
    this.form.patchValue({ zip: addressMetadata.zipcode, ...addressMetadata });
    this.errorMessage = '';
  }

  next() {
    const didSelectService = Object.values(this.serviceTypesModel).find((selectedService) => {
      return selectedService;
    });
    if (!didSelectService) {
      return this.didNotSelectServiceError = true;
    }
    this.didChooseCategories = true;
  }

  async submit() {
    this.submitted = true;
    this.errorMessage = '';
    if (!this.hasAddress) {
      if (this.addressForm.addressError(this.form.value.address)) {
        this.form.controls.address.setErrors({'incorrect': true});
        return this.errorMessage = 'Unfortunately you must have a physical address in order to accurately determine your service area, and PO boxes cannot be used. Clients do not see your address.  Please add a different address.';
      }
      if (this.invalidAddressError) {
        this.errorMessage = this.invalidAddressError;
        return;
      }
    }
    if (this.formErrors()) {
      return this.errorMessage = 'There were errors in your submission.  Please scroll up to fix errors.';
    }
    try {
      const chosenCategories = this.getChosenCategories();
      if (this.teamServiceTypes.length == 0) {
        await this.team.addTeamServiceTypes({ service_type_ids: chosenCategories });
      }
      await this.certification.finishStep('profile', this.prepareData());
      this.customNavCtrl.navigateForward(this.finishPage);
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  getChosenCategories() {
    const categoryIds = Object.keys(this.serviceTypesModel);
    const chosenIds = categoryIds.filter(catedoryId => this.serviceTypesModel[catedoryId]);
    return chosenIds.map(id => parseInt(id));
  }

  formErrors(): boolean {
    return (
      !this.form.valid ||
      this.form.value.previouslyCertified === 'yes'
    );
  }

  prepareData() {
    const data: any = {
      gender:               this.form.value.gender,
      smartphone:           true,
      car:                  true,
      bank_account:         true,
      felony:               false,
      legally_eligible:     true,
      terms_agree:          true,
      referred_by:          'existing_client'
    }
    if (!this.hasAddress) {
      data['zipcode'] = keepOnlyText(this.form.value.zip);
      data['address'] = this.form.value.address;
      data['address2'] = this.form.value.unit;
      data['latitude'] = this.form.value.latitude;
      data['longitude'] = this.form.value.longitude;
      data['add_state'] = this.form.value.state;
      data['country_code'] = this.form.value.countryCode;
      if (this.form.value.city !== '') {
        data['city'] = this.form.value.city;
      }
    } else {
      data['zipcode'] = this.pro.profile.address.zipcode;
      data['address'] = this.pro.profile.address.address;
      data['address2'] = this.pro.profile.address.address2;
      data['latitude'] = this.pro.user.latitude;
      data['longitude'] = this.pro.user.longitude;
      data['city'] = this.pro.profile.address.city;
      data['add_state'] = this.pro.profile.address.add_state;
      data['country_code'] = this.pro.profile.address.country_code;
    }
    if (!this.hasName) {
      data['first_name'] = this.form.controls.firstName.value;
      data['last_name'] = this.form.controls.lastName.value;
    }
    return data;
  }

  changeCountrySelected(countryCodeSelected: string) {
    const validatorObject = this.multipleCountryService.getCountrySettings(countryCodeSelected);
    this.form.get('zip').setValidators(validatorObject.validator);
    this.form.get('zip').updateValueAndValidity();
    this.zipCodeMask = validatorObject.mask;

    setTimeout(() => {
      this.form.patchValue({
        address: '',
        unit: '',
        zip: ''
      });
    });
  }

  goToRequestNewServiceType() {
    this.customNavCtrl.navigateForward('upcoming-feature', {feature: 'other_services'});
  }
}
