<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav [class]="extraClass" [canGoBack]="true" [title]="'Instant Certification'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card>
      <form [formGroup]="form" novalidate action="#">
        <tidy-row class="field-top-padding">
          <tidy-input
            formControlName="url"
            [label]="'What is your Yelp/Google link?'"
            [form]="form.controls.url"
            [submitted]="submitted"
            [errorMessage]="'Please enter a valid URL'"
            [icon]="'assets/svg/globe-outline.svg'"
            [activeIcon]="'assets/svg/globe-outline-tidy-green.svg'">
          </tidy-input>
        </tidy-row>
      </form>
    </tidy-card>

    <tidy-button
      item-right
      [action]="submit.bind(this)"
      [text]="'Submit for Instant Certification'"
      class="primary">
    </tidy-button>

    <tidy-error-message
      [text]="errorMessage">
    </tidy-error-message>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
