import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BankInfo } from 'src/providers/bank-info/bank-info';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { Loading } from 'src/shared/components/loading/loading';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

@Component({
  selector: 'past-payout-page',
  templateUrl: 'past-payout.html'
})
export class PastPayoutPage extends Timeout {

  details: any;
  errorMessage = '';
  status: string;

  constructor(
    private bankInfo: BankInfo,
    private navCtrl: CustomNavController,
    private iabUtils: InAppBrowserUtils,
    public route: ActivatedRoute,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router
  ) {
    super(timeoutErrorHandler, router, route);
  }

  @Loading()
  async ionViewDidEnter() {
    try {
      const paymentId = this.route.snapshot.paramMap.get('paymentId');
      this.details = await this.bankInfo.paymentDetails(paymentId);
      this.status = this.getStatus(this.details.current_status);
      this.mountTotals();
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
      this.errorMessage = err.message || err.error.message;
    }
  }

  getStatus(status) {
    const viewValues = {
      in_transit: 'in transit',
      paid: 'paid'
    };
    if (viewValues[status]) {
      return viewValues[status];
    } else {
      return status;
    }
  }

  learnMoreBank = () => {
    this.iabUtils.openUrl('https://help.tidy.com/pros/payouts#3EppX');
  }

  mountTotals() {
    this.details.payment_items.map(payItem => {

      const total = payItem.items.reduce((acc, crr) => {
        return acc + crr.amount;
      }, 0);

      payItem.total = total;
    });
  }

  goToWaiveFees() {
    this.navCtrl.navigateRoot('waive-fee');
  }

  goToContactConcierge(payment_title){
    const params = {
      title: "Payment Issue",
      type: 'support.homekeeper_job_issue',
      metadata: {
        subtype: 'payment_Issue',
        payment_title
      }
    };
    return this.navCtrl.navigateForward('contact-concierge', params);
  }

  goToPrivateClientPage(clientId) {
    this.navCtrl.navigateForward(`private-client/${clientId}`);
  }

  goToItemDetail(item) {
    const params = {
      item: item,
      clientId: item.job_data.service_type_details.proposal?.customer_id
    };
    this.navCtrl.navigateForward('item-detail', params);
  }

  goToPastJobPage(jobId, isPrivate) {
    if (isPrivate) {
      this.navCtrl.navigateForward(`private-job/${jobId}`);
    } else {
      this.navCtrl.navigateForward(`past-job/${jobId}`);
    }
  }
}
