<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    title="Edit Job">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'What do you want to do?'">
      </tidy-text>
    </tidy-row>

    <tidy-error-message [text]="errorMessage"></tidy-error-message>

    <tidy-button
      [text]="confirmButtonText"
      [action]="confirm.bind(this)"
      class="primary">
    </tidy-button>

    <tidy-button
      *ngIf="jobStatus.includes('cancelled')"
      [text]="'Change Who Cancelled'"
      [action]="goToPrivateCancellation.bind(this)"
      class="primary">
    </tidy-button>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
