import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'performanceText'})
export class PerformanceTextPipe implements PipeTransform {

  transform(value: string): string {

    const performanceText = {
      auto_no_show: 'Client Fee',
      call_out: 'Client Fee',
      call_out_all_day: 'Client Fee',
      client_cancellation: 'Client Cancelled',
      homekeeper_cancellation: 'Client Fee',
      homekeeper_cancelled: 'Client Fee',
      homekeeper_cancelled_no_fee: 'Cancelled',
      homekeeper_reported_client_cancellation: 'Client Cancelled',
      last_minute_call_out: 'Client Fee',
      late: 'Late',
      left_early: 'Not Full Time',
      no_access: 'No Access',
      no_access_client_called: 'Couldn\'t Access Home',
      no_show: 'Client Fee',
      on_time: 'On Time',
      standby_cancellation: 'Standby Cancelled',
      very_bad_cleaning: 'Lost Dispute'
    };

    return performanceText[value];
  }

}
