<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Add Job'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="loaded">
      <tidy-card>
        <form [formGroup]="form">
          <tidy-row class="field-bottom-padding" [alignLastItemRight]="true">
            <tidy-image
              [src]="'assets/svg/person-circle.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Add Job For'">
            </tidy-text>
            <tidy-select
              [items]="[
                {
                  viewValue: 'Existing Client',
                  value: 'existing'
                },
                {
                  viewValue: 'New Client',
                  value: 'new'
                }
              ]"
              formControlName="clientOptions"
              [form]="form.controls.clientOptions"
              (optionChange)="changeClientOption($event)"
              style="width:20px">
            </tidy-select>
          </tidy-row>

          <ng-container *ngIf="!addingNewClient">

            <tidy-row *ngIf="!clients?.length">
              <tidy-text
                [body]="'You don\'t have any existing Clients.'">
              </tidy-text>
            </tidy-row>

            <ng-container *ngIf="clients?.length">
              <tidy-row [ngClass]="addresses?.length == 0 ? 'field-bottom-padding' : ''">
                <tidy-auto-complete
                  [showGoogleIcon]="false"
                  formControlName="clientId"
                  [options]="clients"
                  [form]="form.controls.clientId"
                  [icon]="'assets/svg/search.svg'"
                  [selected]="selectedClient"
                  (action)="selectClient($event)"
                  [isToSearchBetweenOptions]="true"
                  [submitted]="submitted"
                  [label]="'Client'"
                  data-cy="search-clientsName"
                  [errorMessage]="'Please enter Client\'s name'">
                </tidy-auto-complete>
              </tidy-row>
              <tidy-row class="extra-field-top-padding" *ngIf="clientSelected && addresses?.length !== 0">
                <tidy-select
                  [icon]="'assets/svg/home-outline.svg'"
                  [label]="'Address'"
                  [form]="form.controls.addressId"
                  formControlName="addressId"
                  [items]="addresses"
                  [submitted]="submitted"
                  [errorMessage]="'Please select the Client\'s address'">
                </tidy-select>
              </tidy-row>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="addingNewClient">
            <tidy-row class="field-bottom-padding">
              <tidy-input
                [label]="'First Name *'"
                formControlName="firstName"
                data-cy="firstName"
                [form]="form.controls.firstName"
                [icon]="'assets/svg/person-circle-outline.svg'"
                [activeIcon]="'assets/svg/person-circle-outline-tidy-green.svg'"
                [submitted]="submitted"
                [errorMessage]="'Please enter the client\'s first name'">
              </tidy-input>
            </tidy-row>
            <tidy-row class="field-bottom-padding">
              <tidy-input
                [label]="'Last Name *'"
                formControlName="lastName"
                data-cy="lastName"
                [form]="form.controls.lastName"
                [icon]="'assets/svg/person-circle-outline.svg'"
                [activeIcon]="'assets/svg/person-circle-outline-tidy-green.svg'"
                [submitted]="submitted"
                [errorMessage]="'Please enter the client\'s last name'">
              </tidy-input>
            </tidy-row>
            <tidy-row class="field-bottom-padding">
              <tidy-input
                [label]="'Email'"
                formControlName="email"
                data-cy="email"
                type="email"
                [form]="form.controls.email"
                [icon]="'assets/svg/mail-outline.svg'"
                [activeIcon]="'assets/svg/mail-outline-tidy-green.svg'"
                [submitted]="submitted"
                [errorMessage]="'Please enter a valid email'">
              </tidy-input>
            </tidy-row>
            <tidy-row class="field-bottom-padding">
              <tidy-input-international-phone
                formControlName="phone"
                [label]="'Phone Number'"
                data-cy="phone"
                [form]="form.controls.phone"
                [inputMode]="'numeric'"
                [submitted]="submitted"
                [errorMessage]="'Please enter a valid phone number.'"
                [icon]="'assets/svg/phone-portrait-outline.svg'"
                [defaultCountryCode]="countryCode"
                [activeIcon]="'assets/svg/phone-portrait-outline-tidy-green.svg'">
              </tidy-input-international-phone>
            </tidy-row>
          </ng-container>
          <ng-container *ngIf="addingNewClient || addresses?.length == 0">
            <tidy-row class="field-bottom-padding">
              <tidy-auto-complete-address
              [submitted]="submitted"
              [addressFormControl]="form.controls.address"
              [countryCode]="countryCode"
              (onAddressChosen)="choseItem($event)"
              (onError)="errorMessage = $event"
              (noAddressPickedUpdate)="noAddressPickedTemplate = $event"
              (invalidAddressError)="invalidAddressError = $event"
            ></tidy-auto-complete-address>
            </tidy-row>
            <tidy-row class="field-bottom-padding">
              <tidy-input
                [label]="'Unit / Suite'"
                formControlName="unit"
                data-cy="unit"
                [form]="form.controls.unit"
                [icon]="'assets/svg/home-outline.svg'"
                [activeIcon]="'assets/svg/home-outline-tidy-green.svg'">
              </tidy-input>
            </tidy-row>
            <tidy-row>
              <tidy-input
                [customMask]="zipCodeMask"
                [label]="'Zipcode'"
                formControlName="zip"
                [inputMode]="'numeric'"
                data-cy="zipcode"
                [form]="form.controls.zip"
                [icon]="'assets/svg/home-outline.svg'"
                [activeIcon]="'assets/svg/home-outline-tidy-green.svg'"
                [submitted]="submitted"
                [errorMessage]="'Please enter a valid zip code'">
              </tidy-input>
            </tidy-row>
          </ng-container>
        </form>
      </tidy-card>

      <tidy-error-message
        [text]="errorMessage">
      </tidy-error-message>

      <ng-container *ngTemplateOutlet="noAddressPickedTemplate">
      </ng-container>

      <tidy-button
        [text]="'Next'"
        [action]="next.bind(this)"
        class="primary"
        [disabled]="isLoading">
      </tidy-button>
      <footer-informations></footer-informations>

    </ng-container>

    <ng-container *ngIf="!loaded">

      <tidy-card>
        <tidy-row class="field-bottom-padding">
          <tidy-grid>
            <ion-row>
              <ion-col size="5">
                <ion-skeleton-text animated style="width: 140px;  height: 20px"></ion-skeleton-text>
              </ion-col>
              <ion-col size="7">
                <ion-skeleton-text animated style="width: 105px; float: right"></ion-skeleton-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
        <tidy-row class="field-top-padding">
          <ion-skeleton-text animated style="width: 140px; margin-bottom: 5px"></ion-skeleton-text>
          <tidy-divider></tidy-divider>
        </tidy-row>
      </tidy-card>

      <tidy-row>
        <ion-skeleton-text animated style="height: 70px"></ion-skeleton-text>
      </tidy-row>

      <tidy-alert>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 220px; height: 20px; margin: auto"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 100%; margin: auto"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 230px; margin: auto"></ion-skeleton-text>
        </tidy-row>
      </tidy-alert>

    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
