import { Injector } from '@angular/core';

export class ExternalInjector {

  private static injector: Injector;

  static get(): Injector {
    if (ExternalInjector.injector) {
      return ExternalInjector.injector;
    }
    throw new Error('Error: Injector was not registered.');
  }

  static register(injector: Injector) {
    if (!ExternalInjector.injector) {
      ExternalInjector.injector = injector;
    }
  }

}
