import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { AppConfig } from './shared/providers/config';
import * as Sentry from "@sentry/angular";

if (AppConfig.PRODUCTION) {
  const script = document.createElement('script');
  script.innerHTML = `!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '${AppConfig.FACEBOOK_PIXEL_ID}');
  fbq('track', 'PageView');`;
  document.head.appendChild(script);
}

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  environment: environment.production ? 'production' : 'sandbox3',
  dsn: "https://9d6f03a332dbde8e165e54b1e842ded1@o4508852947845120.ingest.us.sentry.io/4508852958920704",
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  tracesSampleRate: 1.0,
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

defineCustomElements(window);
