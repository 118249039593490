<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Edit Plan'"
    >
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        body="What would you like to do?">
      </tidy-text>
    </tidy-row>
    <tidy-row>
      <tidy-button
        [text]="'Reschedule Plan'"
        data-cy="btn-reschedulePlan"
        [action]="reschedulePlan.bind(this)"
        class="secondary"
      >
      </tidy-button>
    </tidy-row>
    <tidy-row>
      <tidy-button
        [text]="'Cancel Plan'"
        data-cy="btn-cancelPlan"
        [action]="cancelPlan.bind(this)"
        class="secondary"
      >
      </tidy-button>
    </tidy-row>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
