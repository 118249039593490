<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Certify Equipment'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form" novalidate action="#">
    <tidy-wrapper *ngIf="loaded">
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'You’ve been <b>pre-approved</b>!  There are clients in your area needing your help.'">
          </tidy-text>
        </tidy-row>

        <tidy-alert>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [header]="'Already Have a Web Presence?'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
            [body]="'If you are on Yelp or Google Places, you can provide that URL to validate yourself instead of a photo.'">
            </tidy-text>

          <tidy-text
          [body]="'Get Instant Certification'"
          (action)="goToWebCertification()"
          class="link">
        </tidy-text>
      </tidy-row>
        </tidy-alert>

        <tidy-card>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [header]="'Confirm Supplies'">
            </tidy-text>
          </tidy-row>

          <tidy-row>
            <tidy-text
            [body]="'Confirm that you have the suppiles and materials necessary to provide the services listed on my profile. For example, for cleaning this includes a vaccum and mop.'">
            </tidy-text>
          </tidy-row>

          <tidy-row>
            <tidy-checkbox
              formControlName="supplies"
              [items]="[{viewValue: 'I Have Supplies', value: false}]">
            </tidy-checkbox>
          </tidy-row>

          <tidy-error-message
            *ngIf="
              submitted &&
              (!form.controls.supplies.valid)"
            [text]="'You must have these supplies'"
          >
          </tidy-error-message>
        </tidy-card>

        <tidy-card>
          <tidy-row>
            <tidy-text
              [header]="'Equipment Profile Photo'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Take a photo of your equipment. Clients may see it so make it look good!'">
            </tidy-text>
          </tidy-row>
          <tidy-row [ngClass]="equipmentPhoto ? 'extra-bottom-padding' : ''">
            <tidy-button
              [text]="equipmentPhoto ? 'Retake Photo' : 'Take Photo'"
              [action]="takeEquipmentPhoto.bind(this)"
              class="secondary">
            </tidy-button>
          </tidy-row>

          <tidy-row *ngIf="equipmentPhoto">
            <tidy-image
              class="hero-size"
              [src]="equipmentPhoto"
            >
            </tidy-image>
          </tidy-row>

          <tidy-error-message
            *ngIf="photoErrorMessage"
            [text]="photoErrorMessage"
          >
          </tidy-error-message>

          <tidy-error-message
            *ngIf="!photoToUpload?.length && submitted"
            [text]="'Please take a picture of your equipment.'"
          >
          </tidy-error-message>
        </tidy-card>

        <tidy-row>
          <tidy-text
            [body]="'Example Equipment Photo:'">
          </tidy-text>
        </tidy-row>

        <tidy-row>
          <tidy-image
            [src]="'assets/img/equipment.png'"
            class="hero-size">
          </tidy-image>
        </tidy-row>

        <tidy-error-message
          *ngIf="errorMessage"
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-button
          [text]="'Submit'"
          [action]="save.bind(this)"
          class="primary">
        </tidy-button>

    </tidy-wrapper>
  </form>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
