import { Component } from '@angular/core';
import { ShareOptions } from '@capacitor/share';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Me } from 'src/shared/providers/me';
import { SocialShare } from 'src/shared/providers/share/share';
import { Team } from 'src/providers/team/team';

import { Loading } from 'src/shared/components/loading/loading';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';
import UrlUtils from 'src/shared/utils/url-utils';

import { ProfileData, parseProfileData } from 'src/pages/profile/profile-data-parser';

import { TeamModel } from 'src/shared/models/team.model';
import { TeamServiceTypeModel } from 'src/shared/models/team.model';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

@Component({
  selector: 'website',
  templateUrl: 'website.html',
})
export class WebsitePage extends Timeout {

  copied: boolean;
  profileData: ProfileData;
  website: TeamModel;
  teamServiceTypes: TeamServiceTypeModel[];

  constructor(
    private customNavCtrl: CustomNavController,
    private iabUtils: InAppBrowserUtils,
    private me: Me,
    private socialShare: SocialShare,
    private team: Team,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
  }

  @Loading()
  async ionViewDidEnter() {
    try {
      this.website = await this.team.getMyTeam();
      this.teamServiceTypes = await this.team.getTeamServiceTypes();
      const homekeeper = await this.me.fetchWithoutCache();
      this.profileData = parseProfileData(homekeeper);
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  goTo = (page) => {
    this.customNavCtrl.navigateForward(page);
  }

  goToBusinessDetail = () => {
    const params = {
      name: this.website.name,
      description: this.website.description,
      successPageRoute: 'website'
    };
    this.customNavCtrl.navigateForward('website-business-detail', params);
  }

  copyWebsite = () => {
    const url = `www.tidy.com/${this.website.url_suffix}`;
    UrlUtils.copyToClipBoard(url);
    this.copied = true;
    setTimeout(() => this.copied = false, 3000);
  }

  viewWebsite = () => {
    this.iabUtils.openUrl(`https://www.tidy.com/${this.website.url_suffix}`);
  }

  editWebsite = () => {
    const params = {
      url: this.website.url_suffix,
    };
    this.customNavCtrl.navigateForward('website-url', params);
  }

  shareWebsite(): void {
    const shareOpts: ShareOptions = {
      title: 'Share Website',
      url: `www.tidy.com/${this.website.url_suffix}`
    };
    this.socialShare.shareUrl(shareOpts);
  }
}
