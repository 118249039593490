<ng-template #content>
  <div
    class="desktop-content-padding"
    [class.mobile-content-padding]="isMobileResolution">
    <ion-row class="progress-bar-row">
      <ng-container *ngFor="let item of progressBars">
        <ion-col class="identification-progress-bar">
          <mat-progress-bar [mode]="item.mode" [value]="item.value">
          </mat-progress-bar>
        </ion-col>
      </ng-container>
    </ion-row>
    <tidy-row [align]="'center'" class="img-row">
      <div class="circle-div">
        <tidy-image
          [src]="currentIdentificationStep.icon"
          class="step-img"></tidy-image>
      </div>
    </tidy-row>
    <tidy-row [align]="'center'">
      <tidy-text class="step-title" [header]="currentIdentificationStep.title">
      </tidy-text>
    </tidy-row>
    <tidy-row [align]="'center'">
      <tidy-text [body]="currentIdentificationStep.subtitle"> </tidy-text>
    </tidy-row>
    <ng-container *ngIf="currentIdentificationStep === identificationSteps[0]">
      <div style="padding-top: 1rem;">
        <tidy-alert style="margin-bottom: 1rem;">
          <tidy-row>
            <tidy-image
              [src]="'assets/svg/alert.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [header]="'For users in the US, a US identification document is required.'">
            </tidy-text>
          </tidy-row>
        </tidy-alert>
      </div>
    </ng-container>
    <tidy-row
      class="step-button-row"
      [align]="'center'"
      *ngIf="currentIdentificationStep.displayButton">
      <tidy-button
        [text]="currentIdentificationStep.buttonText"
        [action]="currentIdentificationStep.action.bind(this)"
        class="primary">
      </tidy-button>
    </tidy-row>

    <ng-container *ngIf="identificationError">
      <tidy-alert>
        <tidy-row [align]="'center'">
          <tidy-text [body]="'<b>Error: </b>'">
          </tidy-text>
          <tidy-text [body]="identificationError?.reason">
          </tidy-text>
        </tidy-row>
        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'Verify again.'"
            class="link"
            (action)="this.goToVerificationPage()">
          </tidy-text>
        </tidy-row>
      </tidy-alert>
    </ng-container>
  </div>
</ng-template>

<ion-content *ngIf="isMobileResolution">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ion-content>

<ng-container *ngIf="!isMobileResolution">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>
