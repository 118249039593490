<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Job Issue'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="!didChooseConciergeType">
      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Who do you want to log an issue for?'">
        </tidy-text>
      </tidy-row>

      <tidy-card style="padding: 15px 15px 15px 5px">
        <tidy-card-button (action)="chooseType('client')">
          <tidy-grid>
            <tidy-row>
              <ion-row>
                <ion-col size="2" class="vertical-align-center">
                  <div style="margin:auto">
                    <tidy-image
                      [src]="'assets/img/happy-person.svg'"
                      style="width: 35px">
                    </tidy-image>
                  </div>
                </ion-col>
                <ion-col>
                  <tidy-row>
                    <tidy-text
                      [header]="'Log for Client'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [body]="'Damage, low inventory or other issue'">
                    </tidy-text>
                  </tidy-row>
                </ion-col>
              </ion-row>
            </tidy-row>
          </tidy-grid>
        </tidy-card-button>
      </tidy-card>

      <tidy-card style="padding: 15px 15px 15px 5px">
        <tidy-card-button (action)="chooseType('concierge')">
          <tidy-grid>
            <tidy-row>
              <ion-row>
                <ion-col size="2" class="vertical-align-center">
                  <div style="margin:auto">
                    <tidy-image
                      [src]="'assets/img/concierge-bell.svg'"
                      style="width: 30px">
                    </tidy-image>
                  </div>
                </ion-col>
                <ion-col>
                  <tidy-row>
                    <tidy-text
                      [header]="'Log for TIDY Concierge'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [body]="'Wrong status, safety or other issue'">
                    </tidy-text>
                  </tidy-row>
                </ion-col>
              </ion-row>
            </tidy-row>
          </tidy-grid>
        </tidy-card-button>
      </tidy-card>
    </ng-container>

    <ng-container *ngIf="didChooseConciergeType">
      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'What can we help with?'">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <tidy-card-button (action)="selectIssue('wrong_performance')" *ngIf="!isMainWorkflow">
          <tidy-row>
            <tidy-text
              [header]="'Wrong Status'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Current status: '">
            </tidy-text>
            <tidy-text
              [body]="(job?.homekeeper_job?.performance | performanceText)">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="selectIssue('safety_issue')">
          <tidy-row>
            <tidy-text
              [header]="'Safety Issue'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="selectIssue('other')">
          <tidy-row>
            <tidy-text
              [header]="'Other Issue'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
