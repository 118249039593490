export const bottomMenuItems = [
  {
    icon: 'assets/img/grid-outline-white.svg',
    activeIcon: 'assets/img/grid-tidy-green.svg',
    label: 'Dashboard',
    target: '/dashboard',
  },
  {
    icon: 'assets/img/calendar-outline-white.svg',
    activeIcon: 'assets/img/calendar-tidy-green.svg',
    label: 'Jobs',
    target: '/jobs',
  },
  {
    icon: 'assets/img/add-circle-outline-white.svg',
    activeIcon: 'assets/img/add-circle-tidy-green.svg',
    label: 'Add',
    target: '/add',
  },
  {
    icon: 'assets/img/messages-white.svg',
    activeIcon: 'assets/img/messages-green.svg',
    label: 'Messages',
    target: '/messages'
  },
  {
    icon: 'assets/img/menu-outline-white.svg',
    activeIcon: 'assets/img/menu-tidy-green.svg',
    label: 'More',
    target: '/more',
  }
];

export const desktopMenuItems = [
  {
    icon: 'assets/img/grid-outline-white.svg',
    activeIcon: 'assets/img/grid-tidy-green.svg',
    label: 'Dashboard',
    target: '/dashboard',
  },
  {
    icon: 'assets/img/calendar-outline-white.svg',
    activeIcon: 'assets/img/calendar-tidy-green.svg',
    label: 'Jobs',
    target: '/jobs',
  },
  {
    icon: 'assets/img/add-circle-outline-white.svg',
    activeIcon: 'assets/img/add-circle-tidy-green.svg',
    label: 'Add',
    target: '/add',
  },
  {
    icon: 'assets/img/messages-white.svg',
    activeIcon: 'assets/img/messages-green.svg',
    label: 'Messages',
    target: '/messages'
  },
  {
    icon: 'assets/img/concierge-white.svg',
    activeIcon: 'assets/img/concierge-green.svg',
    label: 'Concierge',
    target: '/concierge'
  },
  {
    icon: 'assets/img/menu-outline-white.svg',
    activeIcon: 'assets/img/menu-tidy-green.svg',
    label: 'More',
    target: '/more',
  }
];
