import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, FormControl } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

import { SuccessPageParamsModel } from 'src/pages/success/success';

import { TidySelectStringValueModel } from 'src/models/tidy-select-item.model';

@Component({
  selector: 'booking-restrictions',
  templateUrl: 'booking-restrictions.html',
})

export class BookingRestrictionsPage {

  form: UntypedFormGroup;
  changesSaved = 'All Changes Saved.';
  serviceLengths: TidySelectStringValueModel[];

  constructor(
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private iabUtils: InAppBrowserUtils
  ) {
    this.serviceLengths = this.getServiceLengths();
    this.form = this.fb.group({
      paymentProcessing: [false],
      cancellationPolicy: [true],
      contractTerms: [''],
      disputeResolution: [true],
      discounts: [''],
      rateCommitment: [''],
      locationTracking: [true],
      minLength: ['1 hour'],
      maxLength: ['8 hours'],
      twoHomekeeper: [true],
      residential: [true],
      rental: [true],
      office: [true],
      repeat: [true],
      new: [true],
      payRestrictions: [true],
      driveTime: [true],
      other: [true]
    });
  }

  goTo(page) {
    this.customNavCtrl.navigateForward(page);
  }

  updateCheckboxSetting() {
    if (this.changesSaved === 'Saving Changes...') {
      return;
    }
    this.changesSaved = 'Saving Changes...';
    setTimeout(() => this.showAlert('checkbox'), 1500);
  }

  showAlert(type) {
    const params: SuccessPageParamsModel = this.getAlert(type);
    this.customNavCtrl.navigateForward('success', params);
    this.resetFields();
    this.changesSaved = 'All Changes Saved.';
  }

  getAlert(type): SuccessPageParamsModel {
    const alerts = {
      checkbox: {
        header: 'Error Saving',
        body: '7481 Error: Please contact us in the Concierge section for support.',
        buttonText: 'Ok',
        buttonRoute: `booking-restrictions`
      },
      other: {
        header: 'Error',
        body: '7481 Error: Please contact us in the Concierge section for support.',
        buttonText: 'Ok',
        buttonRoute: `booking-restrictions`
      }
    };
    return alerts[type];
  }

  resetFields() {
    const fieldsToRest = [
      'cancellationPolicy',
      'disputeResolution',
      'locationTracking',
      'twoHomekeeper',
      'residential',
      'rental',
      'office',
      'repeat',
      'new',
      'payRestrictions',
      'driveTime',
      'other'
    ];
    fieldsToRest.map((field) => {
      this.form.controls[field].setValue(true);
    });
    this.form.controls.paymentProcessing.setValue(false);
    this.form.controls.minLength.setValue('1 hour');
    this.form.controls.maxLength.setValue('8 hours');
  }

  viewDefault() {
    this.iabUtils.openUrl('https://d3u243wj43t2hu.cloudfront.net/Optional+Service+Contract+Terms.pdf')
  }

  getServiceLengths(): TidySelectStringValueModel[] {
    let array = [];
    for(let i=1; i<17; i++) {
      array.push({
        viewValue: `${i/2} hour${i < 3 ? '' : 's'}`,
        value: `${i/2} hour${i < 3 ? '' : 's'}`
      })
    }
    return array;
  }
}
