import { Component, Input, ChangeDetectionStrategy, OnChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { PrivateJobFieldsService } from 'src/shared/providers/private-job/private-job-fields';

import { SelectModel } from '@tidyapp/tidy-ui-components';
import { TeamServiceTypeModel } from 'src/shared/models/team.model';

@Component({
  selector: 'private-reschedule-form',
  templateUrl: './private-reschedule-form.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivateRescheduleFormComponent implements OnChanges {

  @Input() disableFrequencyDropdown = false;
  @Input() rescheduleType: string;
  @Input() submitted = false;
  @Input() teamServiceItems: SelectModel[];
  @Input() teamServiceTypes: any;
  @Input() formData = null;

  frequencies: SelectModel[];
  formGroupVar: UntypedFormGroup;
  priceLabel: string;
  selectedService: boolean;
  times: SelectModel[];
  tomorrow: Date;
  showPriceForm: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private privateJobFieldsService: PrivateJobFieldsService,
  ) {
    this.frequencies = this.privateJobFieldsService.mountFrequencies();
    this.times = this.privateJobFieldsService.mountTimes();
    this.tomorrow = this.privateJobFieldsService.setTomorrowDate();
    this.formGroupVar = this.fb.group({
      price: ['', Validators.required],
      date: [this.tomorrow, Validators.required],
      frequency: ['once', Validators.required],
      service: ['', Validators.required],
      startTime: ['8:00', Validators.required],
      description: ['', Validators.required],
    });
  }

  populatePriceInput(selectedService) {
    this.selectedService = true;
    this.teamServiceTypes.map((service) => {
      if (service.key == selectedService) {
        this.showPriceForm = service.price != 0 && service.billingType !== 'price_later';
        this.priceLabel = service.billingType == 'hourly' ? 'Hourly Rate' : 'Price';
        this.formGroupVar.patchValue({
          price: (service.price / 100).toString(),
          description: service.description
        });
      }
    });
  }

  ngOnChanges() {
    if (this.disableFrequencyDropdown) {
      this.formGroupVar.get('frequency').disable();
    } else {
      this.formGroupVar.get('frequency').enable();
    }

    if (this.formData) {
      this.formGroupVar.patchValue(this.formData);
      this.formData = null;
    }
  }

  public getValueFromFormGroup() {
    return this.formGroupVar.value;
  }

  public isInValid() {
    return this.formGroupVar.invalid;
  }
}
