import { Injectable } from '@angular/core';

import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { MWStore } from 'src/main-workflow/mw.store';
import { Aws } from 'src/shared/providers/aws';
import { HttpClient } from 'src/shared/providers/http/http-client';
import { DeviceInfoProvider } from 'src/shared/providers/device-info';

@Injectable()

export class ToDosProvider {

  totalNumberOfMediaToUpload: number;
  numberOfMediaUploaded: number;
  parkingDescriptions = {
    'meter' : 'at a meter',
    'paidlot' : 'in a paid lot',
    'street' : 'on the street',
    'myspot' : 'in their spot/driveway',
    'guest' : 'in guest parking'
  };
  didStartJob: boolean = false;
  deletedUuids: string[] = [];
  constructor(
    private storage: TidyStorage,
    private mwStore: MWStore,
    private aws: Aws,
    private httpClient: HttpClient,
    private deviceInfo: DeviceInfoProvider
  ) {}

  async uploadAllMediaAndStoreResponse() {
    this.totalNumberOfMediaToUpload = 0;
    this.numberOfMediaUploaded = 0;
    await this.storage.save('mediaFailedToUpload', false);
    await this.storage.save('didUploadNewMedia', false);
    const page = await this.storage.retrieve('page');
    const storageKey = await this.storage.retrieve('storageKey');
    let storedBeforePhotos, storedAfterPhotos, storedToDos, toDosPayload;
    [{ storedBeforePhotos, storedAfterPhotos }, { storedToDos, toDosPayload }] = await Promise.all([
      this.uploadBAPhotosVideos(),
      this.uploadToDoPhotosAndBuildPayload(page)
    ]);
    if (page == 'MWToDosPage') {
      storedToDos.data.beforePhotos = storedBeforePhotos;
      storedToDos.data.afterPhotos = storedAfterPhotos;
    } else {
      storedToDos.before_photos = storedBeforePhotos;
      storedToDos.after_photos = storedAfterPhotos;
    }
    await this.storage.save(storageKey, storedToDos);
    const jobMediasPayload = await this.buildJobMediasPayload(storedToDos);
    await this.storage.save(storageKey + 'jobMediasPayload', jobMediasPayload);
    await this.storage.save(storageKey + 'toDoPayload', toDosPayload);
  }

  async syncJob() {
    const storageKey = await this.storage.retrieve('storageKey');
    const page = await this.storage.retrieve('page');
    const storedToDos = await this.storage.retrieve(storageKey);
    const deviceId = await this.deviceInfo.uuid();
    const commonParams = {
      device_id: deviceId,
      job_medias:  await this.storage.retrieve(storageKey + 'jobMediasPayload'),
      sync_job_tasks: await this.storage.retrieve(storageKey + 'toDoPayload')
    };
    switch (page) {
      case 'MWToDosPage': {
        const params = {
          ...commonParams,
          job_rating: storedToDos.data.jobRating,
          did_to_dos: storedToDos.data.didToDos,
          left_early: !storedToDos.job.isRepeatClient && storedToDos.data.leftEarly,
          location_verification_photo_key: await this.uploadLocationPicture(),
          moments: storedToDos.data.moments
        };
        return this.httpClient.post(`api/v2/homekeeper/cleanings/${storedToDos.job.jobId}/sync-cleaning`, params);
      }
      case 'SharedJobPage': {
        const uuid = await this.storage.retrieve('uuid');
        const moments = await this.storage.retrieve(`sharedJob/${uuid}/moments`) || [];
        const params = {
          action: 'sync_job',
          action_params: {
            ...commonParams,
            moments
          }
        }
        return this.httpClient.post(`api/v1/tidy-website/shared-links/${uuid}/execute-action`, params);
      }
      case 'PrivateJobPage': {
        const jobId = await this.storage.retrieve('jobId');
        return this.httpClient.post(`api/v1/homekeeper/private/jobs/${jobId}/sync`, commonParams);
      }
    }
  }

  async uploadLocationPicture() {
    const locationPicture = await this.mwStore.getLocationPicture();
    if (locationPicture) {
      try {
        const job = await this.mwStore.getJob();
        const awsObjectKey = `location-verification-photo/${job.id}`
        await this.aws.uploadImageToS3(locationPicture.base64String, awsObjectKey);
        return awsObjectKey;
      } catch (error) {
        //TODO
      }
    }
    return null;
  }

  async buildJobMediasPayload(storedToDos) {
    const parsedBeforePhotos = this.parseMedias(storedToDos?.data?.beforePhotos || storedToDos?.before_photos, 'before_job');
    const parsedAfterPhotos = this.parseMedias(storedToDos?.data?.afterPhotos || storedToDos?.after_photos, 'after_job');
    const jobMedias = [...parsedBeforePhotos, ...parsedAfterPhotos];
    return jobMedias;
  }

  parseMedias(medias: any[], sourceType: string) {
    if (!medias) {
      return [];
    }
    const parsedMediasData = medias.filter(media => (media?.media_url)).map((media) => {
      let parsedMedia: any = {
        media_url: media?.media_url,
        media_format: media?.media_format,
        category: sourceType,
        uuid: media?.uuid || this.getMediaUUIDFromURLReturnedByBackend(media?.media_url),
        deleted: media?.deleted,
      };
      if (media?.room_or_group?.id && media?.room_or_group?.type) {
        parsedMedia[media.room_or_group.type === 'AddressRoom' ? 'address_room_id' : 'address_task_group_id'] = media?.room_or_group?.id;
      }
      if (media?.description) {
        parsedMedia.description = media?.description;
      }
      return parsedMedia;
    });
    return parsedMediasData;
  }

  getMediaUUIDFromURLReturnedByBackend(url) {
    //When FE saves a URL AWS returns it like: "https://samantha-temp-file-uploads.s3-us-west-2.amazonaws.com/sandbox3/private-job-before-after-photos/1763533/before_photos/fa41e206-1352-43e1-848b-2077efba301a.jpeg"
    //When BE returns a URL from job-medias it appears like: "https://samantha-file-uploads.s3.us-west-2.amazonaws.com/sandbox3/jobs/1763533/job-medias/904d1a43-a21e-4ba5-9804-01ec635f8778.jpeg"
    //When patching the form from BE response and not stored response, FE needs to get the UUID from this returned BE string
    if (!url) {
      return null;
    }
    const uuidMatch = url.match(/job-medias\/([^.]+)/);
    return uuidMatch ? uuidMatch[1] : null;
  }

  async uploadBAPhotosVideos() {
    try {
      const storageKey = await this.storage.retrieve('storageKey');
      const isMainWorkflow = storageKey == 'syncData';
      const syncData = await this.storage.retrieve(storageKey);
      let storedBeforePhotos = isMainWorkflow ? syncData.data.beforePhotos : syncData.before_photos;
      let storedAfterPhotos = isMainWorkflow ? syncData.data.afterPhotos : syncData.after_photos;
      const allMedias = [...storedBeforePhotos, ...storedAfterPhotos];
      this.totalNumberOfMediaToUpload += allMedias.length;
      allMedias.filter(media => typeof media.media_url === 'string' && media?.media_url?.includes('https://') && !media?.media_url?.includes('&deleted=true')).map(() => {
        this.numberOfMediaUploaded += 1;
      });
      const uploadPromises = allMedias
        .filter(media =>
            (media.media_url instanceof Blob ||
              media.media_url instanceof File ||
              (typeof media.media_url === 'string' && !media?.media_url?.includes('https://'))
            )
        )
        .map(async (media) => {
          try {
            const uuid = await this.storage.retrieve('uuid');
            const url = await this.mountS3ObjectUrl(uuid, media.media_format);
            const s3Object = await this.aws.saveTodoMediaToAws(media.media_url, media.media_format, url);
            await this.storage.save('didUploadNewMedia', true);
            this.numberOfMediaUploaded += 1;
            media.media_url = s3Object.Location;
          } catch (error) {
            console.error(error);
            await this.storage.save('mediaFailedToUpload', true);
          }
      });
      await Promise.all(uploadPromises);
      storedBeforePhotos = [];
      storedAfterPhotos = [];
      allMedias.map((media) => {
        media.category == 'before_job' ? storedBeforePhotos.push(media) : storedAfterPhotos.push(media);
      });
      return { storedBeforePhotos, storedAfterPhotos };
    } catch (error) {
      console.error(error);
      await this.storage.save('mediaFailedToUpload', true);
    }
  }

  async mountS3ObjectUrl(jobUuid: string, mediaType): Promise<string> {
    const randomHash =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    const page = await this.storage.retrieve('page');
    const mimeType = mediaType === 'photo' ? 'jpeg' : 'mp4';
    if (page === 'SharedToDoListPage' || page === 'SharedJobPage') {
      return `shared-link-before-after-photos/${jobUuid}/${page}/${mediaType}/${randomHash}.${mimeType}`;
    }
    if (page === 'PrivateJobPage') {
      return `private-job-before-after-photos/${jobUuid}/${mediaType}/${randomHash}.${mimeType}`;
    }
    if (page === 'MWToDosPage') {
      const job = await this.mwStore.getJob();
      return `before-after-photos/customers/${job.customerId}/jobs/${job.id}/homekeeper-jobs/${job.homekeeper_job_id}/${mediaType}/${randomHash}.${mimeType}`;
    }
    return `shared-link-before-after-photos/${jobUuid}/${page}/${mediaType}/${randomHash}.${mimeType}`;
  }

  async uploadToDoPhotosAndBuildPayload(page: string) {
    const storageKey = await this.storage.retrieve('storageKey');
    const storedToDos = await this.storage.retrieve(storageKey);
    const uuid = await this.storage.retrieve('uuid');
    const jobId = await this.storage.retrieve('jobId');
    const isMwJob = page === 'MWToDosPage';

    const toDosPayload = await Promise.all(
      (isMwJob ? storedToDos?.data?.todos : storedToDos?.todos).map(task => this.processTask(task, page, storageKey, uuid, jobId, isMwJob))
    );

    return { storedToDos, toDosPayload };
  }

  private async processTask(task, page, storageKey, uuid, jobId, isMwJob) {
    const taskOptions = !isMwJob ? task.task_options : task.job_task_options;
    this.deletedUuids = await this.storage.retrieve(storageKey + 'deletedUuids');
    if (taskOptions) {
      await Promise.all(taskOptions.map(option => this.processTaskOption(option, page, storageKey, uuid, jobId)));
    }
    return this.buildTaskPayload(task, page);
  }

  private async processTaskOption(option, page, storageKey, uuid, jobId) {
    const optionId = option.id || option.address_task_option_id || option.job_task_option_id;
    const isTakePhotoTask = this.isTakePhotoTask(option);

    if (isTakePhotoTask) {
      option.payload_value = await this.uploadPhotos(option, page, storageKey, uuid, jobId, optionId);
    } else {
      option.payload_value = option.value;
    }
  }

  private isTakePhotoTask(option) {
    return option.value?.url || (Array.isArray(option.value) && option.value.some(item => item?.url));
  }

  private async uploadPhotos(option, page, storageKey, uuid, jobId, optionId) {
    let uploadedAWSUrls = await this.storage.retrieve(storageKey + 'uploadedAWSUrls' + optionId) || [];

    await Promise.all(option.value.map(async (item, index) => {
      try {
        this.totalNumberOfMediaToUpload += 1;
        const alreadyUploadedAwsUrl = uploadedAWSUrls.find(url => url.includes(item.uuid));
        if (alreadyUploadedAwsUrl) {
          this.numberOfMediaUploaded += 1;
          return alreadyUploadedAwsUrl;
        }
        if (!item?.url || item.url === '') return null;

        const urlPath = this.getMediaUrlPath(page, uuid || jobId, optionId, index);
        const aws = await this.aws.saveTodoMediaToAws(item?.url, 'photo', urlPath, item?.file, item?.exif);
        await this.storage.save('didUploadNewMedia', true);
        this.numberOfMediaUploaded += 1;

        const url = this.addUuidToUrl(aws?.Location, item.uuid);
        uploadedAWSUrls.push(url);
        await this.storage.save(storageKey + 'uploadedAWSUrls' + optionId, uploadedAWSUrls);
        return url;
      } catch (err) {
        console.error('Error uploading photo:', err);
        await this.storage.save('mediaFailedToUpload', true);
        return null;
      }
    }));

    return uploadedAWSUrls;
  }

  private buildTaskPayload(task, page) {
    return {
      ...task,
      type: page === 'MWToDosPage' ? 'JobTask' : 'AddressTask',
      id: task?.job_task_id || task.address_task_id,
      performance: task.performance,
      task_end_time: task.task_end_time,
      task_options: (task?.task_options || task.job_task_options).map(option => {
        let jsonValue = option.payload_value === '' ? null : option.payload_value;
        if (Array.isArray(option.payload_value)) {
          jsonValue = this.filterUrls(option.payload_value, this.deletedUuids);
        }
        return {
          id: option?.id || option.address_task_option_id,
          json_value: jsonValue
        }
      })
    };
  }

  private filterUrls(data, deletedUuids) {
    data = data.map(item => {
      const uuid = item.split('uuid=')[1];
      const isDeletedKey = '&deleted=true';
      if (deletedUuids?.includes(uuid) && !item?.includes(isDeletedKey)) {
        return item + isDeletedKey;
      }
      return item;
    });
    return data;
  }

  private getMediaUrlPath(page: string, identifier: string, optionId: string, index?: number) {
    const extension = '.jpeg';
    const indexSuffix = typeof index === 'number' ? `/${index}` : '';
    switch (page) {
      case 'MWToDosPage':
        return `mw-job-to-dos/${identifier}/address-task/${optionId}${indexSuffix}${extension}`;
      case 'SharedToDoListPage':
      case 'SharedJobPage':
        return `sharedJobToDos/${identifier}/addressTask/${optionId}${indexSuffix}${extension}`;
      case 'PrivateJobPage':
        return `privateJobToDos/${identifier}/addressTask/${optionId}${indexSuffix}${extension}`;
      default:
        return '';
    }
  }

  getSliderText(page, job = null) {
    if (page == 'MWToDosPage') {
      return 'Finish Job';
    }
    if (page == 'SharedToDoListPage') {
      if (this.didStartJob) {
        return 'Tell Client I\'ve Finished';
      } else {
        return 'Tell Client I\'ve Started';
      }
    }
    if (page == 'SharedJobPage') {
      const lastSharedJobMoment = localStorage.getItem(`sharedJob/${job.uuid}/last-moment`);
      if (job.shared_link_data.job.is_private) {
        if (!lastSharedJobMoment) {
          return 'Tell Client I\'ve Started';
        } else {
          return 'Tell Client I\'ve Finished';
        }
      } else {
        if (!lastSharedJobMoment) {
          return 'Tell Client I\'m on the Way';
        } else if (lastSharedJobMoment == 'on_the_way') {
          return 'Tell Client I\'ve Arrived';
        } else if (lastSharedJobMoment == 'arrived') {
          return 'Tell Client I\'ve Started';
        } else {
          return 'Tell Client I\'ve Finished';
        }
      }
    }
    if (page == 'PrivateJobPage') {
      const isStarted = localStorage.getItem(`privateJobStarted/${job.job.id}`);
      if (isStarted == 'true') {
        return 'Tell Client I\'ve Finished';
      } else {
        return 'Tell Client I\'ve Started';
      }
    }
  }

  addUuidToUrl(urlStr, uuid) {
    const url = new URL(urlStr);
    url.searchParams.append('uuid', uuid);
    return url.toString();
  }

}
