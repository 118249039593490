<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Other Jobs'"
    [customBack]="'add'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="clientName"
          [translate]="false">
        </tidy-text>
        <tidy-text
          [body]="' needs a job during these times:'">
        </tidy-text>
      </tidy-row>

      <tidy-card *ngFor="let day of jobsFromSameClient; let i = index">
        <tidy-row class="extra-bottom-padding" class="vertical-align-center">
          <tidy-image
            src="assets/style-guide-images/bowtie-black.svg"
            class="header-size">
          </tidy-image>
          <ng-container *ngIf="today === day.formatedDate">
            <tidy-text
              [header]="'Today, '">
            </tidy-text>
            <tidy-text
              [header]="(day?.date | tdate:'dddd MM/DD/YY')">
            </tidy-text>
          </ng-container>
          <tidy-text
            *ngIf="today !== day?.formatedDate"
            [header]="day?.dateLabel">
          </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let cleaning of day?.cleanings; let i = index">
          <tidy-card-button (action)="goToJob(cleaning)">
            <tidy-grid>
              <ion-row>
                <ion-col size="6">
                  <tidy-text
                    [body]="cleaning?.time | ttime">
                  </tidy-text><br>
                  <tidy-text
                    [body]="parseServiceLength(cleaning.service_type) | titlecase">
                  </tidy-text>
                </ion-col>
                <ion-col size="6" >
                  <payment-tag
                    [billingType]="cleaning?.billing_type"
                    [totalValue]="cleaning?.cleaning_amount"
                    [bonusValue]="cleaning?.bonus_amount">
                  </payment-tag><br>
                  <tidy-text
                    *ngIf="cleaning?.type === 'plan'"
                    [body]="'Recurring Plan'">
                  </tidy-text>
                </ion-col>
              </ion-row>
            </tidy-grid>
          </tidy-card-button>
        </ng-container>
      </tidy-card>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
