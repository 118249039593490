<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Delete My Account'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    
    <tidy-row>
      <tidy-text
        [body]="'You can delete your account and data by completing this form.'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'<b>We strongly recommend you not take this action until all support or payment issues are 100% resolved.</b>'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'Please note that some data is required by law and cannot be deleted, for example if you received payouts from customers in the US we are required to issue a 1099 with appropriate information. This is also outlined in our terms, which are not modified by the deletion of your account:'">
      </tidy-text>&nbsp;
      <tidy-text
        [actionBody]="'https://www.tidy.com/legal/service-provider'"
        class="link"
        (action)="openUrl('https://www.tidy.com/legal/service-provider')">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'Deleting your account may take up to 1 business week to complete. If you take any action in your account after completing this form, you may have issues.'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'<b>YOU MUST CANCEL ALL JOBS AND RESOLVE ALL ISSUES BEFORE CONTINUING, OR YOU WILL NEED TO RESTART THE PROCESS.</b>'">
      </tidy-text>
    </tidy-row>

    <tidy-card>
      <form [formGroup]="form" novalidate action="#">
        <tidy-row class="field-bottom-padding">
          <tidy-input
            formControlName="email"
            [label]="'Your Email on Your Account'"
            [form]="form.controls.email"
            type="email"
            [submitted]="submitted"
            [errorMessage]="'Please enter your email'"
            [icon]="'assets/svg/mail-outline.svg'"
            [activeIcon]="'assets/svg/mail-outline-tidy-green.svg'">
          </tidy-input>
        </tidy-row>
        <tidy-row>
          <tidy-input-international-phone
            formControlName="phone"
            [label]="'Your Phone Number on Your Account'"
            [form]="form.controls.phone"
            [inputMode]="'numeric'"
            [icon]="'assets/svg/phone-portrait-outline.svg'"
            [activeIcon]="'assets/svg/phone-portrait-outline-tidy-green.svg'"
            [submitted]="submitted"
            [errorMessage]="'Please enter your phone number.'"
            [icon]="'assets/svg/phone-portrait-outline.svg'"
            [activeIcon]="'assets/svg/phone-portrait-outline-tidy-green.svg'"
            [defaultCountryCode]="proCountryCode">
          </tidy-input-international-phone>
        </tidy-row>
      </form>
    </tidy-card>

    <tidy-button
      [text]="'Delete My Account'"
      class="primary"
      [action]="deleteAccount.bind(this)">
    </tidy-button>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>






