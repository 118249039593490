import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import Fuse from 'fuse.js';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Search } from 'src/providers/search/search';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

import { Loading } from 'src/shared/components/loading/loading';

import { SearchableResourcesModel, AppSectionsModel, PrivateClientModel, HelpCenterArticleModel } from 'src/shared/models/search.model';
import { InputComponent } from '@tidyapp/tidy-ui-components';
import { asapScheduler, Subscription } from 'rxjs';
import { FuseSearchProvider } from 'src/shared/providers/fuse-search';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { Timeout } from 'src/shared/components/timeout/timeout';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-search',
  templateUrl: './search.html',
})

export  class SearchPage extends Timeout {
  @ViewChild('searchInput', {static: false}) searchInput: InputComponent;

  appSections: AppSectionsModel[];
  appSectionResults: AppSectionsModel[];
  clientsResult: PrivateClientModel[]
  readonly exampleSearches = [
    'Questions: \"How do Clients book?\"',
    'Names: \"Alice Smith\"',
    'App Sections: \"Schedule\"'
  ];
  form: UntypedFormGroup;
  helpResult: HelpCenterArticleModel[];
  pastSearches = [];
  searchableResources: SearchableResourcesModel;
  subscriptions: Subscription[] = [];

  constructor(
    private customNavController: CustomNavController,
    private inAppBrowserUtils: InAppBrowserUtils,
    private fb: UntypedFormBuilder,
    private search: Search,
    private storage: TidyStorage,
    private fuseSearchProvider: FuseSearchProvider,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.fb.group({
      search: ['', Validators.required],
    });
  }

  @Loading()
  async ionViewDidEnter() {
    try {
      this.searchableResources = await this.search.searchableResources();
      this.appSections = this.search.appSections();
      this.pastSearches = await this.storage.retrieve('pastSearches');
      if (!this.pastSearches) {
        this.pastSearches = [];
      }

      const formSub = this.subscribeSearchs(this.appSections, this.searchableResources);
      this.subscriptions.push(formSub);
      // Trick to show ios/android Keyboard
      asapScheduler.schedule(() => this.searchInput.focusNativeInput(), 0)

      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  subscribeSearchs(appSections: AppSectionsModel[], searchableRsrc: SearchableResourcesModel): Subscription {
    const appSectionSearch = this.fuseSearchProvider.createFuseSearch(appSections, {keys: ['searchTerm']});
    const privateClientsSearch = this.fuseSearchProvider.createFuseSearch(searchableRsrc.private_clients,
      {
        keys: [
          'first_name',
          'last_name'
        ]
      }
      );
    const helpCenterArticlesSearch = this.fuseSearchProvider.createFuseSearch(searchableRsrc.help_center_articles, {keys: ['title']});

    return this.form.valueChanges.pipe(
      debounceTime(300)
    ).subscribe(val => this.changeForm(val, appSectionSearch, privateClientsSearch, helpCenterArticlesSearch));
  }

  ionViewDidLeave() {
    // Trick to hide ios/android Keyboard
    this.searchInput.hideNativeInput();
  }

  changeForm(
    {search},
    appSectionSearch: Fuse<AppSectionsModel>,
    privateClientsSearch: Fuse<PrivateClientModel>,
    helpCenterArticlesSearch: Fuse<HelpCenterArticleModel>) {

    if (search.length < 2) {
      return this.clearResults();
    }
    this.updatePastSearchStore(search);
    this.getResults(search, appSectionSearch, privateClientsSearch, helpCenterArticlesSearch);
  }

  clearResults() {
    this.appSectionResults = [];
    this.helpResult = [];
    this.clientsResult = []
  }

  getResults(
    search,
    appSectionSearch: Fuse<AppSectionsModel>,
    privateClientsSearch: Fuse<PrivateClientModel>,
    helpCenterArticlesSearch: Fuse<HelpCenterArticleModel>) {

    this.loaded = false;
    this.appSectionResults = appSectionSearch.search(search).map(result => result.item);
    this.helpResult = helpCenterArticlesSearch.search(search).map(result => result.item);
    this.clientsResult = privateClientsSearch.search(search).map(result => result.item);
    this.loaded = true;
  }

  selectPastSearch(search) {
    this.form.patchValue({
      search
    });
  }

  async updatePastSearchStore(search) {
    if (search === '' || this.pastSearches.includes(search)) {
      return;
    }
    if (this.pastSearches.length > 9) {
      this.pastSearches.pop();
    }
    this.pastSearches.unshift(search);
    this.storage.save('pastSearches', this.pastSearches);
  }

  gotoCustomer(id) {
    this.customNavController.navigateForward(`/private-client/${id}`);
  }

  goToHelp(url) {
    this.inAppBrowserUtils.openUrl(url);
  }

  gotoAppSection(path) {
    this.customNavController.navigateForward(path);
  }

  onDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
