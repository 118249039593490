import { Pipe, PipeTransform } from '@angular/core';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Pipe({
  name: 'duration'
})
export class TidyDurationPipe implements PipeTransform {
  
  transform(hours: number): string {
    const duration = hours / 60;
    const hoursLabel = duration === 1 ? (new TranslationPipe().transform('hour')) : (new TranslationPipe().transform('hours'));
    return `${duration} ${hoursLabel}`;
  }

}
