import { Component, ViewEncapsulation } from '@angular/core';

import { Concierge } from 'src/providers/concierge/concierge';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Component({
  templateUrl: 'wrong-status.html',
  encapsulation: ViewEncapsulation.None
})

export class WrongStatusPage {

  job: any;
  loaded: boolean;

  constructor(
    private concierge: Concierge,
    private navCtrl: CustomNavController
  ) {}

  async ionViewDidEnter() {
    this.loaded = false;
    if (!this.job) {
      this.job = this.navCtrl.getParam('job', true);
    }
    this.loaded = true;
  }

  selectPerformance(performance) {
    const params = {
      title: 'Wrong Status',
      type: 'support.homekeeper_job_issue',
      correctPerformanceViewValue: this.getPerformanceViewValue(performance),
      metadata: {
        correct_performance: performance,
        job_id: this.job.job.id,
        job_date: this.job.job.date,
        job_time: this.job.job.start_time
      }
    };
    return this.navCtrl.navigateForward('contact-concierge', params);
  }

  getPerformanceViewValue(value) {
    const viewValues = {
      completed: 'Completed',
      client_cancelled: 'Client Cancelled',
      could_not_access: 'Completed',
      call_out: 'Call Out'
    };
    return viewValues[value];
  }
}
