import { Component, ViewEncapsulation } from '@angular/core';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

@Component({
  selector: 'invalid-location-permission-page',
  templateUrl: './invalid-location-permission.page.html',
  encapsulation: ViewEncapsulation.None
})
export class InvalidLocationPermissionPage {

  constructor(
    private iabUtils: InAppBrowserUtils,
    private navCtrl: CustomNavController
  ) {}

  dismissModal() {
    this.navCtrl.navigateRoot('dashboard');
  }

  openHelpLink() {
    this.iabUtils.openUrl('https://help.tidy.com/pros/faqs#how-do-i-resolve-a-location-services-error');
  }

}
