<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="title ? title : ''">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper *ngIf="loaded">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        class="red"
        [actionBody]="'Cancelling on clients is the most common way to lose them.'">
      </tidy-text>
    </tidy-row>

    <tidy-card>
      <tidy-row class="extra-bottom-padding vertical-align-center">
        <tidy-image
          src="assets/style-guide-images/bowtie-black.svg"
          class="header-size">
        </tidy-image>
        <tidy-text
          header="{{job?.startTime | formatDateTitle}}">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-grid>
          <ion-row>
            <ion-col size="4">
              <tidy-text
                [body]="job?.startTime | tdate:'h:mma'">
              </tidy-text><br>
              <tidy-text
                [body]="job?.duration">
              </tidy-text>
            </ion-col>
            <ion-col size="1">
              <tidy-image
                *ngIf="job?.repeat"
                [src]="'assets/svg/star-yellow.svg'"
                class="body-size">
              </tidy-image>
            </ion-col>
            <ion-col size="7" >
              <tidy-text
                [body]="job?.firstName + ' ' + (job?.lastName | abbrLastName: true)"
                [translate]="false">
              </tidy-text><br>
              <tidy-text
                class="red"
                [actionBody]="((job?.performance | performanceText) | translate) + ': ' + (job?.fee | tcurrency)">
              </tidy-text>
            </ion-col>
          </ion-row>
        </tidy-grid>
      </tidy-row>
    </tidy-card>

    <tidy-card>
      <form [formGroup]="form" novalidate action="#">
        <tidy-row class="field-top-padding">
          <tidy-select
            [label]="'Why are you cancelling?'"
            [form]="form.controls.cancelReason"
            formControlName="cancelReason"
            [errorMessage]="'Please let your client know why you are cancelling on them'"
            [submitted]="submitted"
            [items]="cancelReasons">
          </tidy-select>
        </tidy-row>
      </form>
    </tidy-card>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-button
      [text]="title"
      [action]="cancel.bind(this)"
      class="secondary">
    </tidy-button>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
