<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [noBow]="true"
    [title]="cameFromCompleteJob ? 'Complete Job?' : 'Request Payment'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-card>
        <form [formGroup]="form" novalidate action="#">
          <tidy-row>
            <tidy-text
              [header]="'Job Details'">
            </tidy-text>
          </tidy-row>
          <ng-container *ngIf="delegationRole !== 'delegatee'">
            <tidy-row>
              <tidy-text
                [body]="'What did the job cost?'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="field-top-padding">
              <tidy-input
                formControlName="price"
                inputMode="numeric"
                [label]="'Job Price'"
                [form]="form.controls.price"
                [icon]="'assets/svg/cash-outline.svg'"
                [mask]="'currencyMask'"
                [type]="'text'"
                (keyup)="updateTotalOwed()"
                [errorMessage]="'Please enter a price.'"
                [submitted]="submitted">
              </tidy-input>
            </tidy-row>
          </ng-container>
          <tidy-row>
            <tidy-text
              [body]="'How long did the job take?'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="field-top-padding">
            <tidy-select
              [icon]="'assets/svg/time-outline.svg'"
              [label]="'Hours'"
              [form]="form.controls.jobHours"
              formControlName="jobHours"
              [items]="jobHoursItems">
            </tidy-select>
          </tidy-row>
          <tidy-row>
            <tidy-select
              [icon]="'assets/svg/time-outline.svg'"
              [label]="'Minutes'"
              [form]="form.controls.jobMinutes"
              formControlName="jobMinutes"
              [items]="jobMinutesItems">
            </tidy-select>
          </tidy-row>
        </form>
      </tidy-card>

      <tidy-card *ngIf="delegationRole !== 'delegatee'">
        <tidy-row>
          <tidy-text
            [header]="'Request Payment'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="'Send an invoice to request payment. '">
          </tidy-text>
          <tidy-text
            [body]="(client?.account_name !== '' && client?.account_name) ?
            client?.account_name :
            (client?.first_name + ' ' + (client?.last_name | abbrLastName: true))"
            [translate]="false">
          </tidy-text>
          <tidy-text
            [body]="' will receive an email with a link. '">
          </tidy-text>
          <tidy-text
            [body]="'Copy Link'"
            (action)="privatePayments.copyWebsite(client?.client_hub_uuid)"
            class="link">
          </tidy-text>
          <tidy-text
            *ngIf="privatePayments.isWebsiteCopied()"
            [helper]="' Copied'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngIf="cameFromCompleteJob">
          <tidy-row>
            <form [formGroup]="form" novalidate action="#">
              <tidy-radio-button
                [label]="'Do you want to send an invoice?'"
                formControlName="sendInvoice"
                [form]="form.controls.sendInvoice"
                [items]="sendInvoiceItems">
              </tidy-radio-button>
            </form>
          </tidy-row>
          <tidy-row class="extra-bottom-padding extra-top-padding" *ngIf="form.value.sendInvoice == 'yes'">
            <tidy-divider></tidy-divider>
          </tidy-row>
        </ng-container>
        <ng-container *ngIf="form.value.sendInvoice == 'yes' || !cameFromCompleteJob">
          <tidy-row class="extra-bottom-padding">
            <tidy-grid>
              <tidy-row>
                <ion-row>
                  <ion-col size="6">
                    <tidy-row>
                      <tidy-text
                        [title]="'Invoice From'">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row>
                      <tidy-text
                        [body]="proName"
                        [translate]="false">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row>
                      <tidy-text
                        [body]="proEmail"
                        [translate]="false">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row>
                      <tidy-text
                        [body]="proPhoneNumber | telephone:'format'">
                      </tidy-text>
                    </tidy-row>
                  </ion-col>
                </ion-row>
              </tidy-row>
            </tidy-grid>
          </tidy-row>

          <tidy-row class="extra-bottom-padding">
            <!--TODO divider to have [color] input and add dynamic color-->
            <tidy-divider
              class="full-card-width thick"
              style="background: #4DAEED">
            </tidy-divider>
          </tidy-row>

          <div style="margin-top: -15px; background: #F4F4F4; margin-left: -15px; margin-right: -15px; padding: 15px; padding-top:10px ">
            <tidy-row [alignLastItemRight]="true">
              <div>
                <tidy-text
                  [body]="'To: '">
                </tidy-text>
                <tidy-text
                  [body]="(client?.account_name !== '' && client?.account_name) ?
                  client?.account_name :
                  (client?.first_name + ' ' + (client?.last_name | abbrLastName: true))"
                  [translate]="false">
                </tidy-text>
              </div>
              <div style="align-self: start">
                <tidy-text
                  [body]="('Sent ' | translate) +
                  (today | tdate:'M/D/YY')">
                </tidy-text>
              </div>
            </tidy-row>
          </div>

          <tidy-row class="extra-top-padding" [alignLastItemRight]="true">
            <tidy-text
              [body]="'Service'">
            </tidy-text>
            <tidy-text
              [body]="'Owed'">
            </tidy-text>
          </tidy-row>

          <tidy-row class="extra-bottom-padding">
            <tidy-divider>
            </tidy-divider>
          </tidy-row>

          <ng-container *ngFor="let job of selectedJobs; let last = last">
            <tidy-row [alignLastItemRight]="true">
              <div style="width:85%">
                <tidy-text
                  [header]="job?.service_type_details?.name | titlecase">
                </tidy-text>
              </div>
              <div>
                <tidy-text
                  [body]="form?.value?.price">
                </tidy-text>
              </div>
            </tidy-row>

            <tidy-row>
              <tidy-text
                [body]="(job?.date | tdate: 'M/D') +
                ' ' +
                (job?.start_time | tdate:'h:mma':'HH:mm') +
                (' at ' | translate) +
                (job?.address?.address | titlecase) +
                (job?.address?.unit !== '' ? (' #' + job?.address?.unit) : '')">
              </tidy-text>
            </tidy-row>

            <tidy-row>
              <tidy-text
                [body]="'Job Duration: '">
              </tidy-text>
              <tidy-text
                *ngIf="form?.value?.jobHours > 0"
                [body]="form?.value?.jobHours/60 + (form?.value?.jobHours/60 == 1 ? (' hour ' | translate) : (' hours ' | translate))">
              </tidy-text>
              <tidy-text
                *ngIf="form?.value?.jobMinutes > 0"
                [body]="form?.value?.jobMinutes + (form?.value?.jobMinutes == 1 ? (' minute ' | translate) : (' minutes ' | translate))">
              </tidy-text>
            </tidy-row>

            <tidy-row class="extra-top-padding" [ngClass] = "last ? '' : 'extra-bottom-padding'">
              <tidy-divider>
              </tidy-divider>
            </tidy-row>
          </ng-container>


          <tidy-row [alignLastItemRight]="true" class="extra-top-padding" *ngIf="creditsApplied > 0">
            <tidy-text
              [header]="'Credit Applied'">
            </tidy-text>
            <tidy-text
              [body]="creditsApplied | tcurrency">
            </tidy-text>
          </tidy-row>

          <tidy-row [alignLastItemRight]="true" class="extra-top-padding">
            <tidy-text
              [header]="'Total'">
            </tidy-text>
            <tidy-text
              [body]="'<b>' + (totalOwed | tcurrency) + '</b>'">
            </tidy-text>
          </tidy-row>

          <tidy-row [align]="'center'" class="extra-top-padding">
            <tidy-text
              [body]="'We thank you for your business!'">
            </tidy-text>
          </tidy-row>

          <tidy-row class="no-padding" [align]="'center'">
            <tidy-text
              [body]="proName"
              class="signature"
              [translate]="false">
            </tidy-text>
          </tidy-row>

          <tidy-divider
            style="margin-top:-15px">
          </tidy-divider>

          <ng-container class="no-padding">
            <tidy-row class="extra-top-padding">
              <tidy-text
                [header]="'Invoice Notes'">
              </tidy-text>
            </tidy-row>
            <tidy-row style="padding-bottom: 0px">
              <form [formGroup]="form" novalidate action="#">
                <tidy-select
                  style="width:35%; font-size: 8px"
                  [form]="form.controls.messageType"
                  formControlName="messageType"
                  [items]="messageSelectItems"
                  [smallText]="true"
                  [selectedItem]="selectedMessageType"
                  (optionChange)="updateIsInternalComment($event)">
                </tidy-select>
              </form>
            </tidy-row>
            <tidy-row style="padding-top: 3px">
              <form [formGroup]="form" novalidate action="#">
                <tidy-textarea
                  [label]="isInternalComment ? 'Add Internal Note' : 'Add Note'"
                  formControlName="message"
                  [form]="form.controls.message"
                  [submitted]="submitted"
                  [errorMessage]="'Please enter a message to send.'"
                  [rightIconAction]="sendMessage.bind(this)"
                  [leftIconAction]="sendAttachment.bind(this)"
                  [showBlackButtons]="isInternalComment">
                </tidy-textarea>
              </form>
            </tidy-row>
            <tidy-row *ngIf="invoiceNotes?.length == 0" [align]="'center'">
              <tidy-text
                [helper]="'No Invoice Notes'">
              </tidy-text>
            </tidy-row>
            <ng-container *ngFor="let note of invoiceNotes; let last = last">
              <message
                [message]="note"
                [last]="last"
                [isInvoiceNote]="true">
              </message>
            </ng-container>
          </ng-container>
        </ng-container>
      </tidy-card>

      <tidy-card *ngIf="!client?.email && delegationRole !== 'delegatee'">
        <form [formGroup]="form">
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [header]="'Send Invoice to'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="field-top-padding">
            <tidy-input
              formControlName="email"
              [label]="'Client\'s Email'"
              [form]="form.controls.email"
              type="email"
              [submitted]="submitted"
              [errorMessage]="'Please enter a valid email.'"
              [icon]="'assets/svg/mail-outline.svg'">
            </tidy-input>
          </tidy-row>
        </form>
      </tidy-card>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-button
        [action]="submit.bind(this)"
        [text]="getSubmitButtonText()"
        class="primary">
      </tidy-button>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
