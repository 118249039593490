class UrlUtils {

  getQueryString(key) {
    const url = location.search.substr(1);
    let value = '';
    if (key) {
      url.split('&').map(item => {
        const param = item.split('=');
        if (param.length > 1 && param[0] === key) {
          value = param[1];
        }
      });
    }
    return value;
  }

  copyToClipBoard(text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    document.execCommand('copy');
    document.body.removeChild(textArea);
  }
}

export default new UrlUtils();
