import { Component, ViewEncapsulation } from '@angular/core';

import { Concierge } from 'src/providers/concierge/concierge';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { Loading } from 'src/shared/components/loading/loading';

import { ConciergeItemModel } from 'src/models/concierge.model';
import { Logger } from 'src/shared/providers/logger';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { Timeout } from 'src/shared/components/timeout/timeout';

import { WindowService } from 'src/shared/providers/window.service';

@Component({
  templateUrl: 'concierge.html',
  encapsulation: ViewEncapsulation.None
})

export class ConciergePage extends Timeout {

  contentHeight: number;
  items: ConciergeItemModel[];
  errorMessage: string;

  constructor(
    public concierge: Concierge,
    private navCtrl: CustomNavController,
    private logger: Logger,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute,
    public windowService: WindowService
  ) {
    super(timeoutErrorHandler, router, route);
  }

  async ionViewDidEnter() {
    try {
      this.loaded = false;
      this.items = await this.concierge.getConciergeItems();
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  @Loading()
  async goToConciergeMessage(conciergeItem) {
    try {
      await this.concierge.setProInfo();
      const params = {
        item: conciergeItem
      }
      this.navCtrl.navigateForward(`concierge-message/${conciergeItem.id}`, params);
    } catch (err) {
      this.handleError(err);
    }
  }

  @Loading()
  async getHelp() {
    try {
      await this.concierge.setProInfo();
      this.navCtrl.navigateForward('get-help');
    } catch (err) {
      this.handleError(err);
    }
  }

  @Loading()
  async goToDispute(dispute){
    try {
      await this.concierge.setProInfo();
      const params = {
        item: dispute
      }
      this.navCtrl.navigateForward(`dispute-resolution/${dispute.id}`, params);
    } catch (err) {
      this.handleError(err);
    }
  }

  handleError(err) {
    this.logger.error(err);
    this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
  }
}
