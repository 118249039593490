import { Injectable } from '@angular/core';
import { HttpClient } from '../../shared/providers/http/http-client';
import { ReimbursementRequestModel } from '../../shared/models/parking-reimbursement.model';
import { SuccessPageParamsModel } from 'src/pages/success/success';

@Injectable()
export class ParkingReimbursement {
  constructor(private httpClient: HttpClient) {}

  sendRequest(params: ReimbursementRequestModel) {
    const url = `api/v1/homekeeper/cleanings/${params.jobId}/parking-reimbursements`;
    const data = {
      job_id: params.jobId,
      amount: params.amount,
      s3_object_key: params.s3ObjectKey
    };

    return this.httpClient.post(url, data);
  }

  getRequests(jobId: number) {
    const url = `api/v1/homekeeper/cleanings/${jobId}/parking-reimbursements`;

    return this.httpClient.get(url);
  }

  resquestPageContent(status: string): SuccessPageParamsModel {
    const pageContent = {
      pending: {
        header: 'Request Submitted',
        body: 'Your parking reimbursement request has been submitted to the Client. The app will be updated automatically when the Client responds.',
        buttonText: 'Ok',
        buttonRoute: 'jobs'
      },
      approved: {
        header: 'Request Accepted',
        body: 'The Client has 72 hours to cancel, after which this will be added to your payment.',
        buttonText: 'Ok',
        buttonRoute: 'jobs'
      }
    };
    return pageContent[status];
  }

  updatePrivateClient(data, clientId) {
    const url = `api/v1/homekeeper/private/customers/${clientId}`;
    return this.httpClient.put(url, data);
  }
}
