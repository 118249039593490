<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Team Member Invited'"
    [customBack]="'team'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper *ngIf="loaded">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="('You invited ' | translate) +
        invitedMember?.email +
        (' to be part of your team.  They can accept by clicking the link in the email they received.' | translate)">
      </tidy-text>
    </tidy-row>

    <tidy-card>
      <tidy-card-button (action)="resendInvite()">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/mail-outline.svg'">
          </tidy-image>
          <tidy-text
            [body]="'Resend Email'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="cancelInvite()">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/close-circle-outline.svg'">
          </tidy-image>
          <tidy-text
            [body]="'Cancel Invite'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
