import { Injectable } from '@angular/core';
import { Photo } from '@capacitor/camera';

import { PhotosCache } from 'src/shared/providers/tidy-photos-cache';
import { TidyStorage } from "src/shared/providers/tidy-storage";

import * as moment from 'moment';
import cleaningTypeUtils from 'src/shared/utils/cleaning-type-utils';
import { BeforeAfterPhotoType } from './mw.enums';

@Injectable()
export class MWStore {

  constructor(
    private store: TidyStorage,
    private photosCache: PhotosCache,
  ) {}

  async getRoute(): Promise<string> {
    return await this.store.retrieve('route');
  }

  setRoute(route) {
    return this.store.save('route', route);
  }

  async getStoredIssues(): Promise<Array<any>> {
    return await this.store.retrieve('storedIssues');
  }

  setStoredIssues(issues) {
    return this.store.save('storedIssues', issues);
  }

  async getJobId(): Promise<any> {
    return await this.store.retrieve('jobId');
  }

  setJobId(jobId) {
    return this.store.save('jobId', jobId);
  }

  async getJob(): Promise<any> {
    return await this.store.retrieve('job');
  }

  setJob(job) {
    return this.store.save('job', job);
  }

  async getSyncData(): Promise<any> {
    return await this.store.retrieve('syncData');
  }

  setSyncData(syncData) {
    return this.store.save('syncData', syncData);
  }

  async storeToDo(todoItem) {
    const syncData = await this.getSyncData();
    const storedToDo = syncData.data.todos.find(todo => todo.job_task_id === todoItem.job_task_id);
    if (storedToDo) {
      storedToDo.performance = todoItem.performance;
      storedToDo.task_end_time = `${new Date()}`;
      todoItem.origin.assigned_job_task.options?.map(option => {
        const storedTaskOption = storedToDo.job_task_options.find(taskOption => taskOption.id === option.id);
        if (storedTaskOption) {
          storedTaskOption.value = this.parseCustomFormFieldValue(todoItem, option);
        } else {
          storedToDo.job_task_options.push({
            id: option.id,
            value: this.parseCustomFormFieldValue(todoItem, option),
          });
        }
      });
    } else {
      const todoToStore = {
        job_task_id: todoItem.job_task_id,
        performance: todoItem.performance,
        task_end_time: `${new Date()}`,
        job_task_options: todoItem.origin.assigned_job_task.options?.map(option => {
          return {
            id: option.id,
            value: this.parseCustomFormFieldValue(todoItem, option),
          }
        }),
      }
      syncData.data.todos.push(todoToStore);
    }
    await this.setSyncData(syncData);
  }

  parseCustomFormFieldValue(toDo, option) {
    if (option.name == 'Checkbox(es)') {
      let selections = '';
      option.allowed_values.map((value, index) => {
        if (toDo.customFieldForm?.value[option.id + '-' + value.viewValue]) {
          selections += value.viewValue + ', ';
        }
      });
      return selections.substring(0, selections.length - 2);
    } else {
      return toDo.customFieldForm?.value[option.id];
    }
  }

  async addBeforeAfterPhoto(photoData, photoType: BeforeAfterPhotoType) {
    const syncData = await this.getSyncData();
    const photosKey = `${photoType}Photos`;
    const index = syncData.data[photosKey].findIndex(photo => photo.roomId === photoData.roomId);

    if (index !== -1) {
      syncData.data[photosKey][index] = photoData;
    } else {
      syncData.data[photosKey].push(photoData);
    }
    await this.setSyncData(syncData);
  }

  async addBeforeAfterPhotoPayload(payload) {
    let syncData = await this.getSyncData();
    if (!syncData[payload.beforeOrAfterType]) {
      syncData[payload.beforeOrAfterType] = [];
    }
    syncData[payload.beforeOrAfterType].push(payload);
    await this.setSyncData(syncData);
  }

  async setLocationPicture(pictureData: Photo) {
    const syncData = await this.getSyncData();
    syncData.data.locationPicture = pictureData;
    return this.setSyncData(syncData);
  }

  async getLocationPicture() {
    const syncData = await this.getSyncData();
    return syncData.data.locationPicture;
  }

  async getStartTime(): Promise<moment.Moment> {
    const job = await this.getJob();
    const duration = cleaningTypeUtils.getCleaningDuration(job?.type);
    let timer = moment(await this.store.retrieve('timer')).subtract(duration, 'h');
    return moment(timer);
  }

  async getTimer(): Promise<moment.Moment> {
    const job = await this.getJob();
    let timer = await this.store.retrieve('timer');
    if (!timer) {
      const duration = cleaningTypeUtils.getCleaningDuration(job?.type);
      timer = moment().add(duration, 'h');

      const noAccessTimeCredit = await this.getCreditTime();
      if (noAccessTimeCredit) {
        timer.subtract(noAccessTimeCredit, 'seconds');
      }

      await this.setTimer(timer.toString());
    }

    return moment(timer);
  }

  setTimer(timer) {
    return this.store.save('timer', timer);
  }

  async getFarFromHomeTimer(): Promise<moment.Moment> {
    let farFromHomeTimer = await this.store.retrieve('farFromHomeTimer');
    if (!farFromHomeTimer) {
      const now = new Date();
      farFromHomeTimer = moment(now).add(6, 'm');
      await this.setFarFromHomeTimer(farFromHomeTimer.toString());
    }
    return moment(farFromHomeTimer);
  }

  setFarFromHomeTimer(farFromHomeTimer) {
    return this.store.save('farFromHomeTimer', farFromHomeTimer);
  }

  async getCantGetInTimeout() {
    return this.store.retrieve('cantGetInTimer');
  }

  async setCantGetInTimeout(targetTime) {
    return this.store.save('cantGetInTimer', targetTime);
  }

  async getCreditTime() {
    return this.store.retrieve('noAccessTimeCredit');
  }

  async setCreditTime(timeToCredit) {
    return this.store.save('noAccessTimeCredit', timeToCredit);
  }

  async getEligibleForPlan(): Promise<any> {
    return await this.store.retrieve('eligibleForPlan');
  }

  setEligibleForPlan(eligible) {
    return this.store.save('eligibleForPlan', eligible);
  }

  async getToDos() {
    return await this.store.retrieve('toDos');
  }

  setToDos(toDos) {
    this.store.save('toDos', toDos);
  }

  async getPhoneNumber() {
    return await this.store.retrieve('phoneNumber');
  }

  setPhoneNumber(phoneNumber) {
    return this.store.save('phoneNumber', phoneNumber);
  }

  clearStore() {
    const storageKeys = [
      'syncData',
      'jobId',
      'timer',
      'toDos',
      'job',
      'route',
      'cantGetInTimer',
      'noAccessTimeCredit',
      'farFromHomeTimer',
      'eligibleForPlan',
      'calledOrTexted',
      'lastJobSyncTime',
      'uploadedLocationPicture'
    ];
    storageKeys.map(key => this.store.delete(key));
    this.photosCache.clear();
  }

  setCalledTexted(calledOrTexted: boolean): Promise<void> {
    return this.store.save('calledOrTexted', calledOrTexted);
  }

  async getCalledTexted(): Promise<boolean> {
    return await this.store.retrieve('calledOrTexted') ?? false;
  }
}
