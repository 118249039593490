import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PrivateClient } from 'src/providers/private-client/private-client';
import { PrivatePayments } from 'src/providers/private-payments/private-payments';

import { Loading } from 'src/shared/components/loading/loading';

import { AddressModel } from 'src/shared/models/address.model';
import { BillingHistoryModel } from 'src/models/private-payments.model';
import { ClientModel } from 'src/shared/models/client.model';
import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';
import { PrivateJobModel } from 'src/shared/models/private-job.model';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

@Component({
  templateUrl: 'client-billing-history.html',
  encapsulation: ViewEncapsulation.None
})

export class ClientBillingHistoryPage extends Timeout {

  addressSelected: AddressModel;
  addresses: Array<TidySelectNumberValueModel>;
  billingHistory: BillingHistoryModel;
  client: ClientModel;
  detailHistory: any;
  loadingHistory: boolean;
  noBillingHistory: boolean;
  unpaidJobs: PrivateJobModel[];

  constructor(
    private navCtrl: CustomNavController,
    private privateClient: PrivateClient,
    private privatePayments: PrivatePayments,
    public route: ActivatedRoute,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router
  ) {
    super(timeoutErrorHandler, router, route);
  }

  async ionViewDidEnter() {
    await this.getPageData();
  }

  @Loading()
  async getPageData() {
    this.loaded = false;
    const clientId = this.route.snapshot.paramMap.get('clientId');
    try {
      this.client = this.navCtrl.getParam('client') || await this.privateClient.getPrivateClientInfo(clientId);
      this.unpaidJobs = this.navCtrl.getParam('unpaidJobs') || await this.privateClient.getUnpaidJobsForClient(clientId);
      this.billingHistory = this.navCtrl.getParam('billingHistory') || await this.privatePayments.getBillingHistory(clientId);
      this.addressSelected = this.navCtrl.getParam('addressSelected');
      this.detailHistory = await this.privatePayments.getDetailHistory(clientId, this.addressSelected.id);
      this.addresses = this.navCtrl.getParam('addresses');
    } catch (err) {
      this.noBillingHistory = true;
      this.timeoutHandler(err);
      //TODO remove this once BE supports billing history for pending Clients
    }
    this.loaded = true;
  }

  @Loading()
  async voidCredit(credit) {
    const payload = {
      amount: credit.amount_left,
      customer_id: this.client.id,
      currency: 'usd'
    }
    await this.privatePayments.voidCredit(credit.credit_id, payload);
    const clientId = this.route.snapshot.paramMap.get('clientId');
    this.billingHistory = await this.privatePayments.getBillingHistory(clientId);
  }

  async changeAddress(addressId) {
    this.loadingHistory = true;
    this.detailHistory = await this.privatePayments.getDetailHistory(this.client.id, addressId);
    this.loadingHistory = false
  }

  goToJobPage(id) {
    this.navCtrl.navigateForward(`private-job/${id}`);
  }

  goToRefundChargePage(detail) {
    const params = {
      clientName: this.client.first_name,
      clientId: this.client.id,
      jobId: detail.cleaning_id,
      refundable: detail.refundable
    }
    this.navCtrl.navigateForward('refund-charge', params);
  }

}
