import { Component } from '@angular/core';
import { RespondDisputeParams, ContestDispute } from 'src/providers/contest-dispute/contest-dispute';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Logger } from 'src/shared/providers/logger';
import { mwMoments } from 'src/main-workflow/mw.moments';

@Component({
  selector: 'no-dispute-page',
  templateUrl: 'no-dispute.html'
})
export class NoDisputePage {

  navParams: any;
  errorMessage: any;

  constructor(
    private customNavCtrl: CustomNavController,
    private contestDispute: ContestDispute,
    private logger: Logger
  ) {
  }

  ionViewDidEnter() {
    this.navParams = this.customNavCtrl.getParams();
  }

  confirm = async () => {
    try {
      const params: RespondDisputeParams = this.mountResponseParams();
      await this.contestDispute.respondDispute(params);
      this.customNavCtrl.navigateRoot('jobs');
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
      this.logger.error(err, 'no-dispute-contest-submission');
    }
  }

  mountResponseParams(): RespondDisputeParams {
    return {
      disputeId: this.navParams.disputeId,
      homekeeperResponse: 'Opted to not contest the dispute',
      contested: false,
      jobId: this.navParams.jobId,
      source: this.navParams.source,
      moment: mwMoments.notContestDispute,
      attachments: []
    };
  }

  goBack = () => {
    this.customNavCtrl.navigateBack(['past-job', this.navParams.jobId]);
  }
}
