import { Component, ViewEncapsulation } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Component({
  templateUrl: 'get-help.html',
  encapsulation: ViewEncapsulation.None
})

export class GetHelpPage {

  constructor(
    private navCtrl: CustomNavController
  ) {}

  goToContactConcierge(title: string, type: string) {
    const params = {
      title: title,
      type: type
    }
    this.navCtrl.navigateForward('contact-concierge', params);
  }

  howToUseTidy() {
    this.goToContactConcierge('How to Use TIDY', 'support.homekeeper_how_to');
  }

  async jobIssue() {
    const params = {
      header: 'Job Issue',
      body: 'We are happy to help with any job issues. Please tap on the job, then \“Log Issue\”, and we typically help within 2 business days.',
      buttonText: 'View Jobs',
      buttonRoute: 'jobs'
    };
    this.navCtrl.navigateForward('success', params);
  }

  paymentIssue() {
    const params = {
      header: 'Payment Issue',
      body: 'We are happy to help with any payment issues. Please tap \“Log Issue\” on the payment item you have questions on, and we typically help within 4 business hours.',
      buttonText: 'View Payments',
      buttonRoute: 'payouts'
    };
    this.navCtrl.navigateForward('success', params);
  }

  managingTeam() {
    this.goToContactConcierge('Managing My Team', 'support.homekeeper_team');
  }

  managingPrivateClients() {
    this.goToContactConcierge('Private Clients', 'support.homekeeper_private_client');
  }

  otherQuestion() {
    this.goToContactConcierge('Other Question', 'support.homekeeper_other');
  }
}
