import { Component } from '@angular/core';
import { Photo } from '@capacitor/camera';

import { Camera } from 'src/shared/providers/camera/camera';
import { ContestDispute, RespondDisputeParams } from 'src/providers/contest-dispute/contest-dispute';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { disputesOptions } from 'src/shared/constants/camera';
import { Loading } from 'src/shared/components/loading/loading';
import { Logger } from 'src/shared/providers/logger';

import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SuccessPageParamsModel } from 'src/pages/success/success';

import { mwMoments } from 'src/main-workflow/mw.moments';

@Component({
  selector: 'dispute',
  templateUrl: 'dispute.html'
})
export class DisputePage {

  disputePhotos: any = [];
  form: UntypedFormGroup;
  errorMessage: any;
  navParams: any;
  submitted = false;

  constructor(
    private camera: Camera,
    private customNavCtrl: CustomNavController,
    private contestDispute: ContestDispute,
    private logger: Logger,
    private fb: UntypedFormBuilder
  ) {
    this.form = this.fb.group({
      hkResponse: ['', Validators.required]
    });
  }

  ionViewDidEnter() {
    this.navParams = this.customNavCtrl.getParams();
  }

  respondDispute = async () => {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    try {
      const params: RespondDisputeParams = this.mountResponseParams();
      await this.contestDispute.respondDispute(params);

      const sucessParams: SuccessPageParamsModel = this.mountSuccessPageParams();
      this.customNavCtrl.navigateForward('success', sucessParams);
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
      this.logger.error(err, 'dispute-contest-submission');
    }
  }

  mountResponseParams(): RespondDisputeParams {
    const attachments = this.contestDispute.extractAwsKeys(
      this.disputePhotos
    );
    return {
      disputeId: this.navParams.disputeId,
      homekeeperResponse: this.form.value.hkResponse,
      contested: true,
      jobId: this.navParams.jobId,
      source: this.navParams.source,
      moment: mwMoments.contestDispute,
      attachments
    };
  }

  mountSuccessPageParams(): SuccessPageParamsModel {
    return {
      header: 'Dispute Contested',
      body: 'Our Concierge will review and respond in 2-3 days',
      buttonText: 'Go to Jobs',
      buttonRoute: 'jobs'
    };
  }

  capturePhoto = async () => {
    try {
      this.camera.getPhoto(disputesOptions).then((photo: Photo) => {
        if (!photo?.base64String) {
          return;
        }
        this.addPhoto(photo);
      });
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
      this.logger.error(err, 'dispute-photo');
    }
  }

  @Loading()
  async addPhoto(cameraPhoto: Photo) {
    try {
      const awsKey = await this.contestDispute.uploadPicture(cameraPhoto.base64String, this.navParams.disputeId);
      const photoItem = {
        photo_url: cameraPhoto.dataUrl,
        awsKey
      };

      this.disputePhotos.push(photoItem);
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
      this.logger.error(err, 'dispute-photo');
    }
  }
}
