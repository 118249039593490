<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Services'"
    [customBack]="'more'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <ng-container *ngIf="teamServiceTypes?.length == 0">
        <tidy-card>
          <tidy-row>
            <tidy-text
              [body]="'What types of services do you offer?'">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngFor="let item of categoryItems">
            <tidy-checkbox
              [items]="[item]"
              [value]="serviceTypesModel[item.value]"
              [(checked)]="serviceTypesModel[item.value]"
              [initValue]="serviceTypesModel[item.value]">
            </tidy-checkbox>
           </tidy-row>
           <tidy-row class="extra-bottom-padding">
             <tidy-divider></tidy-divider>
           </tidy-row>
           <tidy-row [align]="'center'">
             <tidy-text
               [helper]="'Don\'t see what you offer? '">
             </tidy-text>
             <tidy-text
               [helper]="'Request Type'"
               (action)="goToRequestNewServiceType()"
               class="link">
             </tidy-text>
           </tidy-row>
        </tidy-card>
        <tidy-error-message
          *ngIf="didNotSelectServiceError"
          [text]="'Please select the types of services you offer.'">
        </tidy-error-message>
        <tidy-error-message
          [text]="errorMessage">
        </tidy-error-message>
        <tidy-button
          [action]="save.bind(this)"
          [text]="'Save'"
          class="primary">
        </tidy-button>
      </ng-container>

      <ng-container *ngIf="teamServiceTypes?.length > 0">
        <tidy-card *ngFor="let teamServiceType of teamServiceTypes">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="teamServiceType?.team_services[0]?.service_type?.icon_url"
               class="title-size">
             </tidy-image>
            <tidy-text
              [header]="teamServiceType?.name">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="teamServiceType?.team_services?.length == 0" class="extra-top-padding">
            <tidy-text
              [body]="'No Services Added'">
            </tidy-text>
          </tidy-row>
          <ng-container *ngFor="let teamService of teamServiceType?.team_services">
            <tidy-card-button (action)="goToService(teamService)">
              <tidy-row>
                <tidy-text
                  [body]="teamService?.name | titlecase">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </ng-container>
        </tidy-card>

        <tidy-button
          [text]="'Add Service'"
          [action]="goToAddService.bind(this)"
          class="primary">
        </tidy-button>
      </ng-container>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
