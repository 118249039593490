<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="false"
    [title]="'Messages'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-alert *ngIf="!messages?.length">
        <tidy-row>
          <tidy-text
            [helper]="'No messages in the last 30 days'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <ng-container *ngFor="let message of messages">

        <tidy-card>
          <tidy-card-button (action)="goToMessagesPage(message)" class="arrow-to-top" [darkArrow]="!message?.chatRoom?.last_chat_room_message?.is_read">
            <tidy-row class="vertical-align-center extra-bottom-padding">
              <tidy-image
                *ngIf="!message?.chatRoom?.last_chat_room_message?.is_read"
                [src]="'assets/img/messages-dark.svg'"
                class="header-size">
              </tidy-image>
              <tidy-image
                *ngIf="message?.chatRoom?.last_chat_room_message?.is_read"
                [src]="'assets/img/message.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                *ngIf="message?.type == 'homekeeper_job'"
                [header]="getJobDateTime(message?.job?.start_datetime, message?.job?.timezone_name)">
              </tidy-text>
              <tidy-text
                *ngIf="message?.type == 'homekeeper_job'"
                [header]="' Job Message'">
              </tidy-text>
              <tidy-text
                *ngIf="message?.type == 'account'"
                [header]="message?.chatRoom?.last_chat_room_message?.from_member?.first_name">
              </tidy-text>
              <tidy-text
              *ngIf="message?.type == 'account'"
              [header]="' Messages'">
            </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-center">
              <tidy-initials-icon
                *ngIf="message?.chatRoom?.last_chat_room_message?.type !== 'call'"
                [fullName]="message?.chatRoom?.last_chat_room_message?.from_member?.first_name + ' ' + message?.chatRoom?.last_chat_room_message?.from_member?.last_name">
              </tidy-initials-icon>
              <tidy-image
                *ngIf="message?.chatRoom?.last_chat_room_message?.type == 'call'"
                [src]="'assets/img/phone-black.svg'"
                class="phone-icon"
                [ngClass]="(message?.chatRoom?.content?.text == 'Calling...' || message?.chatRoom?.content?.text == 'Call in-progress...') ? 'wiggle-animation' : ''">
              </tidy-image>
              <tidy-text
                *ngIf="message?.chatRoom?.last_chat_room_message?.is_read"
                [body]="message?.chatRoom?.last_chat_room_message?.from_member?.first_name"
                [translate]="false">
              </tidy-text>
              <tidy-text
                *ngIf="!message?.chatRoom?.last_chat_room_message?.is_read"
                [body]="'<b>' + message?.chatRoom?.last_chat_room_message?.from_member?.first_name + '</b>'"
                [translate]="false">
              </tidy-text>
              <div
              style="font-family: Helvetica Neue;
              font-size: 13px;
              font-weight: 300;
              margin: 3px 0px 0px 5px"
              [ngStyle]="message?.chatRoom?.last_chat_room_message?.is_read ? {'color': '#666'} : {'color': 'black'}">
                {{getMessageDateTime(message)}}
              </div>
            </tidy-row>
            <tidy-row *ngIf="message?.chatRoom?.last_chat_room_message?.content?.text && (message?.chatRoom?.last_chat_room_message?.content?.files?.length == 0 || !message?.chatRoom?.last_chat_room_message?.content?.files?.length)">
              <tidy-text
                *ngIf="message?.chatRoom?.last_chat_room_message?.type !== 'call'"
                [body]="message?.chatRoom?.last_chat_room_message?.is_read ? message?.chatRoom?.last_chat_room_message?.content?.text : '<b>' + message?.chatRoom?.last_chat_room_message?.content?.text + '</b>'"
                style="overflow: hidden;
                 text-overflow: ellipsis;
                 display: -webkit-box;
                 -webkit-line-clamp: 3;
                 line-clamp: 3;
                 -webkit-box-orient: vertical;">
              </tidy-text>
              <ng-container *ngIf="message?.chatRoom?.last_chat_room_message?.type == 'call'">
                <tidy-text
                  [body]="'<i>' + message?.chatRoom?.last_chat_room_message?.content?.text + '</i>'">
                </tidy-text>
                <tidy-text
                  *ngIf="message?.chatRoom?.last_chat_room_message?.content?.text == 'Call finished.'"
                  [body]="'<i>' + communication.getCallMinutes(message?.chatRoom?.last_chat_room_message?.duration) + '</i>'">
                </tidy-text>
              </ng-container>
            </tidy-row>
          </tidy-card-button>
          <ng-container *ngIf="message?.chatRoom?.last_chat_room_message?.content?.files?.length > 0">
            <tidy-row style="margin-top:-5px">
              <tidy-photo-note
                style="max-width: 150px"
                [src]="message?.chatRoom?.last_chat_room_message?.content?.files">
              </tidy-photo-note>
            </tidy-row>
            <tidy-row *ngIf="message?.chatRoom?.last_chat_room_message?.content?.text">
              <tidy-text
                [body]="message?.chatRoom?.last_chat_room_message?.content?.text"
                style="overflow: hidden;
                 text-overflow: ellipsis;
                 display: -webkit-box;
                 -webkit-line-clamp: 3;
                 line-clamp: 3;
                 -webkit-box-orient: vertical;">
              </tidy-text>
            </tidy-row>
          </ng-container>
          <tidy-row *ngIf="message?.chatRoom?.count_chat_room_messages > 1" style="margin-top:-5px">
            <tidy-text
              [helper]="'+ ' + (message?.chatRoom?.count_chat_room_messages - 1)">
            </tidy-text>
            <tidy-text
              [helper]="' More'">
            </tidy-text>
          </tidy-row>
        </tidy-card>

      </ng-container>

    </ng-container>

    <ng-container *ngIf="!loaded">

      <ng-container *ngFor="let item of [1,2,3,4]">
        <tidy-card>
          <tidy-card-button class="arrow-to-top">
            <tidy-row class="extra-bottom-padding">
              <ion-skeleton-text animated style="width: 75%; height: 16px;"></ion-skeleton-text>
            </tidy-row>
            <tidy-row class="extra-bottom-padding">
              <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
              <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
            </tidy-row>
            <tidy-row>
              <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
        <tidy-card>
          <tidy-card-button class="arrow-to-top">
            <tidy-row class="extra-bottom-padding">
              <ion-skeleton-text animated style="width: 68%; height: 16px;"></ion-skeleton-text>
            </tidy-row>
            <tidy-row class="extra-bottom-padding">
              <ion-skeleton-text animated style="width: 55%"></ion-skeleton-text>
              <ion-skeleton-text animated style="width: 34%"></ion-skeleton-text>
            </tidy-row>
            <tidy-row>
              <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
        <tidy-card>
          <tidy-card-button class="arrow-to-top">
            <tidy-row class="extra-bottom-padding">
              <ion-skeleton-text animated style="width: 80%; height: 16px;"></ion-skeleton-text>
            </tidy-row>
            <tidy-row class="extra-bottom-padding">
              <ion-skeleton-text animated style="width: 47%"></ion-skeleton-text>
              <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
            </tidy-row>
            <tidy-row>
              <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
        <tidy-card>
          <tidy-card-button class="arrow-to-top">
            <tidy-row class="extra-bottom-padding">
              <ion-skeleton-text animated style="width: 73%; height: 16px;"></ion-skeleton-text>
            </tidy-row>
            <tidy-row class="extra-bottom-padding">
              <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
              <ion-skeleton-text animated style="width: 95%"></ion-skeleton-text>
            </tidy-row>
            <tidy-row>
              <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
      </ng-container>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

<tidy-bottom-nav activeRoute="/messages">
</tidy-bottom-nav>
