<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Set Bookable Hours'"
    [customBack]="'/new-clients'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper *ngIf="loaded">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container>
      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Select the times Clients can book with you every week. '">
        </tidy-text>
        <tidy-text
          [body]="'Learn More'"
          (action)="learnMore()"
          class="link">
        </tidy-text>
      </tidy-row>

      <tidy-card  *ngFor="let weekday of weekSchedule; let dayIndex = index">

        <tidy-row class="vertical-align-center">
          <ng-container [formGroup]="weekDayCheckBox[dayIndex]">
          <tidy-checkbox
            [items]="[{viewValue: 'Every ' +  weekday?.day, value: true}]"
            (checkedChange)="markCheckbox($event, dayIndex)"
            formControlName="checked"
            >
          </tidy-checkbox>
          </ng-container>
        </tidy-row>

        <ng-container *ngFor="let formGrp of timeRangeForms[dayIndex]; let i = index">

          <tidy-two-column-row [formGroup]="formGrp" style="padding-top: 30px;">
            <tidy-select
              style="max-width: 35%; margin: 0;"
              [icon]="'assets/svg/time-outline.svg'"
              [label]="'Time'"
              [form]="formGrp.controls.start_time"
              formControlName="start_time"
              errorMessage="Select a valid time"
              [submitted]="true"
              (optionChange)="updateValidity(dayIndex)"
              [items]="timeOpts">
            </tidy-select>
            <span style="align-self: center; justify-content: space-around; max-width: 10%;">-</span>
            <tidy-select
              style="max-width: 35%; margin-right: 10px;"
              [icon]="'assets/svg/time-outline.svg'"
              [label]="'Time'"
              [form]="formGrp.controls.end_time"
              formControlName="end_time"
              errorMessage="Select a valid time"
              [submitted]="true"
              (optionChange)="updateValidity(dayIndex)"
              [items]="endTimes(formGrp.controls.start_time.value)">
            </tidy-select>
            <tidy-image (action)="removeTimeRange(dayIndex, i)" src="assets/img/trash-icon-outline.svg" class="icon body-size" style="max-width: 16px; align-self: center;"></tidy-image>
            <tidy-image (action)="addNewTimeRange(dayIndex)" src="assets/img/add.svg" class="icon body-size" style="max-width: 16px; align-self: center; opacity: .6; margin-left: auto;"></tidy-image>
          </tidy-two-column-row>
        </ng-container>
      </tidy-card>

      <tidy-error-message *ngIf="errorMessage"
        [text]="errorMessage">
       </tidy-error-message>

      <tidy-button
        [text]="'Save Bookable Hours'"
        [action]="saveSchedule.bind(this)"
        class="primary">
      </tidy-button>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'You authorize Clients to book with you during these times until 8pm the night before.'">
        </tidy-text>
      </tidy-row>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
