<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [backButton]="true"
    [title]="'Forgot Password'"
  >
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card>
      <form [formGroup]="forgotPwdForm" novalidate action="#">
        <tidy-row class="field-top-padding">
          <tidy-input
            formControlName="email"
            [form]="forgotPwdForm.controls.email"
            label="Email"
            type="email"
            data-cy="email-forgotPwd"
            [icon]="'assets/svg/mail-outline.svg'"
            [activeIcon]="'assets/svg/mail-outline-tidy-green.svg'"
            [errorMessage]="'Please enter a valid email'"
            [submitted]="submitted"
          >
          </tidy-input>
        </tidy-row>
      </form>
    </tidy-card>

    <tidy-error-message [text]="errorMessage"></tidy-error-message>

    <tidy-row>
      <tidy-button
        [text]="'Send Reset Password Email'"
        [action]="sendRecoveryPasswordEmail.bind(this)"
        data-cy="sendBtn-forgotPwd"
        class="primary"
      >
      </tidy-button>
    </tidy-row>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
