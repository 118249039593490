import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PrivateClient } from 'src/providers/private-client/private-client';

import { PrivateJobModel } from 'src/shared/models/private-job.model';

import { Loading } from 'src/shared/components/loading/loading';

@Component({
  templateUrl: 'past-jobs.html'
})

export class PrivatePastJobsPage {

  accountName: string;
  clientName: string;
  pastJobs: PrivateJobModel[];

  constructor(
    private customNavCtrl: CustomNavController,
    private privateClient: PrivateClient,
    private route: ActivatedRoute
  ) {}

  @Loading()
  async ionViewDidEnter() {
    this.clientName = await this.customNavCtrl.getParam('clientName', true);
    this.accountName = await this.customNavCtrl.getParam('accountName');
    const addressId = this.route.snapshot.paramMap.get('addressId');
    try {
      this.pastJobs = await this.privateClient.getPastJobsForAddress(addressId);
    } catch (err) {
      this.customNavCtrl.navigateRoot('jobs');
    }
  }

  goToJob(job) {
    this.customNavCtrl.navigateForward(`private-job/${job.id}`);
  }
}
