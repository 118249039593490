<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Add Credit'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'Credit is used when you complete private jobs.'">
      </tidy-text>
    </tidy-row>

    <tidy-card>
      <tidy-row class="field-top-padding">
        <form [formGroup]="form" novalidate action="#">
          <tidy-input
            formControlName="amount"
            inputMode="numeric"
            [label]="'Amount of Credit'"
            [form]="form.controls.amount"
            [icon]="'assets/svg/cash.svg'"
            [mask]="'currencyMask'"
            [type]="'text'"
            [submitted]="submitted"
            [errorMessage]="'Please enter the amount of credit.'">
          </tidy-input>
        </form>
      </tidy-row>
    </tidy-card>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-button
      [text]="('Add Credit for ' | translate) + (client?.account_name && client?.account_name !== '' ? client?.account_name : (client?.first_name | titlecase))"
      [action]="addCredit.bind(this)"
      class="primary">
    </tidy-button>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
