<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Concierge'"
    [canGoBack]="!windowService.isDesktopRes">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [hasBottomNav]="true" (contentHeight)="contentHeight = $event">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">

      <ng-container *ngFor="let item of items">

        <ng-container *ngIf="item?.type.includes('dispute')">
          <tidy-card>
            <tidy-card-button (action)="goToDispute(item)" [hideArrow]="item?.type === 'dispute.homekeeper_dispute_initiated' ? true : false" [customIcon]=" item?.type === 'dispute.homekeeper_dispute_initiated' ? 'assets/img/play-black.svg' : ''" [customArrowPositon]="{ top: '18px', width: '11px'}">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/help-attention.svg'"
                  class="header-size">
                </tidy-image>
                <tidy-text
                  [header]="item?.header_text">
                </tidy-text>
              </tidy-row>
              <tidy-row *ngIf="item?.type === 'dispute.homekeeper_dispute_counter_waiting'">
                <tidy-text
                  [body]="'<i>Waiting on Client</i>'">
                </tidy-text>
              </tidy-row>
              <tidy-row *ngIf="item?.type === 'dispute.homekeeper_dispute_initiated'">
                <tidy-text
                  [body]="'<b><i>Waiting on You</i><b>'">
                </tidy-text>
              </tidy-row>
              <tidy-row *ngIf="item?.type === ('dispute.homekeeper_dispute_lost' || 'dispute.homekeeper_dispute_won')">
                <tidy-text
                  [body]="'<i>Dispute Closed</i>'">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>
        </ng-container>

        <ng-container *ngIf="item?.type.includes('support')">
          <tidy-card *ngIf="item?.components[0]?.value?.status === 'waiting_on_customer'">
            <tidy-card-button (action)="goToConciergeMessage(item)" [hideArrow]="true" [customIcon]="'assets/img/play-black.svg'" [customArrowPositon]="{ top: '18px', width: '11px'}">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="concierge.getStatusIcon(item?.components[0]?.value?.status)"
                  class="header-size">
                </tidy-image>
                <tidy-text
                  [header]="(item?.metadata?.request_data?.subject | slice:0:30) + (item?.metadata?.request_data?.subject?.length > 30 ? '..' : '')">
                </tidy-text>
              </tidy-row>
              <tidy-row *ngIf="item?.components[0]?.value?.status !== 'completed' && item?.components[0]?.value?.status !== 'closed'">
                <tidy-text
                  [body]="'<b><i>Waiting on You</i><b>'">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="'<i>' + (item?.components[0]?.value?.last_update_at | tdate:'M/D/YY h:mma') + '</i>'">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>
          <tidy-card *ngIf="item?.components[0]?.value?.status !== 'waiting_on_customer'">
            <tidy-card-button (action)="goToConciergeMessage(item)">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="concierge.getStatusIcon(item?.components[0]?.value?.status)"
                  class="header-size">
                </tidy-image>
                <tidy-text
                  [header]="(item?.metadata?.request_data?.subject | slice:0:30) + (item?.metadata?.request_data?.subject?.length > 30 ? '..' : '')">
                </tidy-text>
              </tidy-row>
              <tidy-row *ngIf="item?.components[0]?.value?.status !== 'completed' && item?.components[0]?.value?.status !== 'closed'">
                <tidy-text
                  [body]="'<i>Waiting on TIDY</i>'">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="'<i>' + (item?.components[0]?.value?.last_update_at | tdate:'M/D/YY h:mma') + '</i>'">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>
        </ng-container>
      </ng-container>

      <tidy-card>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/icon/concierge.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'TIDY Concierge'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="'Helping your business get running successfully.'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [header]="'Answer any Questions'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="'Easy to ask just about anything related to using TIDY.'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [header]="'Setting up for Success'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'We will provide personalized recommendations on how to improve your business to earn more and save more.'">
          </tidy-text>
        </tidy-row>
      </tidy-card>
    </ng-container>

    <ng-container *ngIf="!loaded">
      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 80%; height: 20px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 25%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>
      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 70%; height: 20px"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>
      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 80%; height: 20px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 25%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>
      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 70%; height: 20px"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>
      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 70%; height: 20px"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

<tidy-fab class="bottom-nav-padding" [disableToggle]="true" [top]="contentHeight">
  <tidy-fab-button class="main-btn" (click)="getHelp()">
    <tidy-text largeBody="Get Help" class="white"></tidy-text>
  </tidy-fab-button>
</tidy-fab>

<tidy-bottom-nav
  activeRoute="/concierge">
</tidy-bottom-nav>
