export interface MWJobModel {
  id: number;
  introVideo: string;
  date: string;
  time: string;
  startTime: string;
  endTime: string;
  type: string;
  before_after_photos: string;
  block_complete_on_missing_fields: boolean;
  client: string;
  last_name: string;
  addressId: any;
  address: string;
  addressPhotosParking: any[];
  addressPhotosClosing: any[];
  addressPhotosAccess: any[];
  addressUnit: string;
  deviceId: string;
  zip: string;
  parking: string;
  access: string;
  city: string;
  fee: string;
  performance: string;
  homekeeper_job_id: number;
  routeAddress: string;
  latitude: string;
  longitude: string;
  jobHasToDoList: boolean;
  state: string;
  isRepeatClient: boolean;
  isStandby: boolean;
  isStandbyCancelled: boolean;
  gateway_phone?: string;
  todosData?: MWTodosModel;
  todosPartnerData?: MWTodosModel;
  paid_parking: string;
  parking_spot: string;
  payment_instructions: string;
  payment_price: number;
  parking_notes: string;
  customerId: number;
  accountType: string;
  inactive?: boolean;
  cancelled?: boolean;
  isBookingProtection?: boolean;
  checkTime?: number;
  planId: number;
  job_delegation_request: any;
  other_pro: string;
  remoteAccessPin: any;
  showTimer: any;
  categoryName: any;
  duration: any;
  bookingNote: any;
  isPremium: any;
  guest_reservation_data: any;
  bookingFormAnswers: any;
  hasSameDayCheckIn: boolean;
}

export interface MWTodosModel {
  onLeave: string;
  rooms: MWTodosRoomModel[];
  allRoomsAreEmpty: boolean;
}

interface MWTodosRoomModel {
  category: string;
  complete?: boolean;
  doneTodos?: number;
  id: number;
  notes: any[],
  photo_notes: PhotoNoteModel[];
  room: string;
  is_do_not_clean_room: boolean;
  estimated_time: any;
  room_tasks: any;
  todos: any;
  type: string;
  is_pending_upload: boolean;
}

interface PhotoNoteModel {
  addressable_id: number;
  id: number;
  photo_note_caption: string;
  photo_url: string;
  type: string;
  dataUrl?: string;
}

export interface MWSyncDataModel {
  beforePhotos: PhotoModel[];
  afterPhotos: PhotoModel[];
  rooms: MWTodosRoomModel[];
  confirmAddressPhoto: any;
  shouldUpgradePlan: any;
  moments: any[];
  leftEarly: boolean;
}

interface PhotoModel {
  photoPageType: string;
  photoData: PhotoDataModel;
}

interface PhotoDataModel {
  roomId: number;
  url: string;
  dataUrl: string;
  base64String: string;
}

export interface JobMediaPayload {
  media_url: string;
  media_format: string;
  description?: string;
  category?: string;
  address_room_id?: number;
  address_task_group_id?: number;
  deleted?: boolean;
  uuid?: string;
}
