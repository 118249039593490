import { Component } from "@angular/core";
import { Photo } from '@capacitor/camera';

import { MWService } from "src/main-workflow/mw.service";
import { MWStore } from "src/main-workflow/mw.store";
import { MWJobModel } from 'src/main-workflow/mw.models';
import { mwMoments } from "src/main-workflow/mw.moments";

import { Camera } from "src/shared/providers/camera/camera";
import { CustomNavController } from "src/shared/providers/navigation/custom-nav-controller";
import { Logger } from "src/shared/providers/logger";

import { locationConfirmOptions } from "src/shared/constants/camera";

@Component({
  templateUrl: 'mw.finish-far-from-client-photo.page.html'
})
export class FinishFarFromClientPhotoPage {

  cameraPhoto: Photo;
  errorMessage: string;
  job: MWJobModel;
  timeoutTime: any;

  constructor(
    private camera: Camera,
    private logger: Logger,
    private mwService: MWService,
    private mwStore: MWStore,
    private navCtrl: CustomNavController
  ){}

  async ionViewDidEnter() {
    this.job = await this.mwStore.getJob();
    this.timeoutTime = await this.mwStore.getFarFromHomeTimer();
  }

  async takePhoto() {
    this.camera.getPhoto(locationConfirmOptions).then((cameraPhoto) => {
      try {
        if (!cameraPhoto?.base64String) {
          return;
        }
        this.cameraPhoto = cameraPhoto;
      } catch (err) {
        this.mwService.addMomentToSyncData(mwMoments.errorTakingPhoto, 'finish-far-from-client-photo', this.job.id);
        this.errorMessage = err;
        this.logger.error(err, 'take-confirm-address-photo');
      }
    });
  }

  async submitPhoto() {
    await this.mwStore.setLocationPicture(this.cameraPhoto);
    await this.mwService.addMomentToSyncData(mwMoments.takeLocationPicture, 'finish-far-from-client-photo', this.job.id);
    await this.mwStore.setRoute('finish-job');
    this.navCtrl.navigateForward('finish-job');
  }

  async onTimeout() {
    await this.mwService.addMomentToSyncData(mwMoments.finishedFarFromClientTimeout, 'finish-far-from-client-photo', this.job.id);
    await this.mwStore.setRoute('finish-job');
    const params = {
      header: 'Not at the Home',
      body: 'You confirmed you are not at the client\'s home.',
      buttonText: 'Ok',
      buttonRoute: 'finish-job'
    };
    this.navCtrl.navigateForward('success', params);
  }
}
