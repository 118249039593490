import { Component } from '@angular/core';

import { Certification } from 'src/providers/certification/certification';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Me } from 'src/shared/providers/me';

import { SuccessPageParamsModel } from 'src/pages/success/success';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  selector: 'validate-email',
  templateUrl: 'validate-email.html',
})
export class OnboardingValidateEmailPage {

  email: string;
  errorMessage: string;

  constructor(
    private certification: Certification,
    private customNavCtrl: CustomNavController,
    private me: Me
  ) {}

  async ionViewDidEnter() {
    const hk = await this.me.load();
    this.email = hk.profile.email;
  }

  async resend() {
    try {
      await this.certification.resendVerificationEmail();
      const params = this.resendSuccessParams();
      this.customNavCtrl.navigateForward('success', params);
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  resendSuccessParams(): SuccessPageParamsModel {
    return {
      header: 'Email Resent',
      body: `${new TranslationPipe().transform('We resent an email to')} <b>${this.email}</b> ${new TranslationPipe().transform('from an @tidy.com email address to verify your email.')}<br><br>
      ${new TranslationPipe().transform('Please tap the link in that email to verify your email address.')}<br><br>
      ${new TranslationPipe().transform('Check your junk or spam messages if you cannot find the email.')}`,
      buttonText: 'Ok',
      buttonRoute: `dashboard`
    };
  }
  
}
