import { Component } from '@angular/core';

import { MWService } from "src/main-workflow/mw.service";
import { mwMoments } from 'src/main-workflow/mw.moments';

import { LostItem } from 'src/providers/lost-item/lost-item';
import { LostItemParamsModel } from 'src/shared/models/lost-item-interface.model';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'page-lost-item',
  templateUrl: 'lost-item.html'
})
export class LostItemPage {

  errorMessage: string;
  itemLost: LostItemParamsModel;
  lostItemForm: UntypedFormGroup;
  submitted = false;

  constructor(
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private lostItem: LostItem,
    private mwService: MWService,
    private route: ActivatedRoute
  ) {
    this.lostItemForm = this.fb.group({
      description: ['', Validators.required]
    });
  }

  async ngOnInit() {
    this.itemLost = {
      jobId: +this.route.snapshot.paramMap.get('jobId'),
      hkJobId: +this.route.snapshot.paramMap.get('hkJobId'),
      description: null
    };
  }

  initiateLostItem = async () => {
    this.submitted = true;
    this.itemLost.description = this.lostItemForm.value.description;
    if (this.itemLost.description) {
      try {
        await this.lostItem.initiateLostItem(this.itemLost);
        await this.mwService.sendMomentToBackend(mwMoments.initiatedLostItem, 'lost-item', this.itemLost.jobId);
        this.customNavCtrl.navigateForward('lost-item-success');
      } catch (err) {
        this.errorMessage = err.message || err.error.message;
      }
    }
  }

  goToMyCleanings() {
    this.customNavCtrl.navigateRoot('jobs');
  }

}
