<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="pageContent.title">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container>
      <tidy-row *ngIf="finishPage == 'ready-to-leave'" [align]="'center'">
        <tidy-text
          [body]="'Please add your phone number to contact the client or notify them that you are on the way.'">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <form [formGroup]="form" novalidate>
          <tidy-row class="field-top-padding">
            <tidy-input-international-phone
              *ngIf="currentPageState === PageState.EDIT"
              formControlName="phone"
              [label]="'Phone'"
              [form]="form.controls.phone"
              [inputMode]="'numeric'"
              [icon]="'assets/svg/phone-portrait-outline.svg'"
              [activeIcon]="'assets/svg/phone-portrait-outline-tidy-green.svg'"
              [submitted]="submitted"
              [defaultCountryCode]="proCountryCode"
              [errorMessage]="'Please enter a valid phone.'"
              [icon]="'assets/svg/phone-portrait-outline.svg'"
              [activeIcon]="'assets/svg/phone-portrait-outline-tidy-green.svg'">
            </tidy-input-international-phone>

            <tidy-input
              *ngIf="currentPageState === PageState.CODE"
              formControlName="code"
              [inputMode]="'numeric'"
              [label]="pageContent.label"
              [form]="form.controls.code"
              [icon]="'assets/svg/phone-portrait-outline.svg'"
              [activeIcon]="'assets/svg/phone-portrait-outline-tidy-green.svg'"
              [errorMessage]="'Please enter a valid Code'"
              [mask]="pageContent.inputMask">
            </tidy-input>
          </tidy-row>
        </form>
      </tidy-card>

      <tidy-error-message
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-button
        [text]="pageContent.buttonText"
        class="primary"
        [action]="buttonAction.bind(this)">
      </tidy-button>

      <tidy-row [align]="'center'">
        <tidy-text [body]="pageContent.textBellowButton">
        </tidy-text>
      </tidy-row>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
