<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Complete Job'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="loaded">

      <tidy-row *ngIf="billingType == 'price_later'" [align]="'center'">
        <tidy-text
          [body]="'Please add the job price to complete it.'">
        </tidy-text>
      </tidy-row>

      <tidy-row *ngIf="billingType == 'hourly'" [align]="'center'">
        <tidy-text
          [body]="'Please add the job length to complete it.'">
        </tidy-text>
      </tidy-row>

      <form [formGroup]="form" novalidate action="#">
        <tidy-card>
          <ng-container *ngIf="billingType == 'price_later'">
            <tidy-row class="field-top-padding">
              <tidy-text
                [header]="'What did the job cost?'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="field-top-padding">
              <tidy-input
                formControlName="price"
                inputMode="numeric"
                [label]="'Job Price'"
                [form]="form.controls.price"
                [icon]="'assets/svg/cash-outline.svg'"
                [mask]="'currencyMask'"
                [type]="'text'"
                [submitted]="submitted">
              </tidy-input>
            </tidy-row>
          </ng-container>
          <tidy-row class="field-top-padding">
            <tidy-text
              [header]="'How long did the job take?'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="field-top-padding">
            <tidy-select
              [icon]="'assets/svg/time-outline.svg'"
              [label]="'Hours'"
              [form]="form.controls.jobHours"
              formControlName="jobHours"
              [items]="jobHoursItems">
            </tidy-select>
          </tidy-row>
          <tidy-row>
            <tidy-select
              [icon]="'assets/svg/time-outline.svg'"
              [label]="'Minutes'"
              [form]="form.controls.jobMinutes"
              formControlName="jobMinutes"
              [items]="jobMinutesItems">
            </tidy-select>
          </tidy-row>
        </tidy-card>
      </form>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-button
        [text]="'Complete Job'"
        [action]="completeJob.bind(this)"
        class="primary">
      </tidy-button>

    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
