<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [customBack]="'more'"
    [title]="'Compliance'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'TIDY for Pros is only available for businesses, please confirm your business with any of the below easy verification options:'">
      </tidy-text>
    </tidy-row>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'1. Attach a link to your business advertising.'">
      </tidy-text>
    </tidy-row>
    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'2. Need alternate verification? Contact '">
      </tidy-text>
      <tidy-text
        class="link"
        [body]="'concierge@tidy.com'"
        >
      </tidy-text>
    </tidy-row>
    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'This is required within provide this confirmation within 10 days. Thank you for your cooperation.'">
      </tidy-text>
    </tidy-row>
    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'(Bonus: Feel free to promote your use of TIDY to potential customers in reviews on the TIDY Apps)'">
      </tidy-text>
    </tidy-row>

    <ng-container *ngIf="loaded">

      <tidy-card *ngFor="let place of places">
        <tidy-card-button (action)="addReview(place.destination)">
          <tidy-image
            [src]="'assets/svg/' + (completeList[place?.destination] ? 'checkmark-circle-outline' : 'radio-button-off-outline') + '.svg'">
          </tidy-image>
          <tidy-text
            [header]="place.title">
          </tidy-text>
        </tidy-card-button>
      </tidy-card>
    </ng-container>

    <ng-container *ngIf="!loaded">
      <tidy-row>
        <ion-skeleton-text animated style="height: 20px; width: 60%; margin: auto"></ion-skeleton-text>
      </tidy-row>

      <tidy-card>
        <tidy-card-button
         [hideArrow]="true">
         <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-card-button
         [hideArrow]="true">
         <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-card-button
         [hideArrow]="true">
         <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-card-button
         [hideArrow]="true">
         <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-card-button
         [hideArrow]="true">
         <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-card-button
         [hideArrow]="true">
         <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-card-button
         [hideArrow]="true">
         <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-card-button
         [hideArrow]="true">
         <ion-skeleton-text animated style="width: 45%"></ion-skeleton-text>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-card-button
         [hideArrow]="true">
         <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-card-button
         [hideArrow]="true">
         <ion-skeleton-text animated style="width: 83%"></ion-skeleton-text>
        </tidy-card-button>
      </tidy-card>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
