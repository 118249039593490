import { Component } from '@angular/core';
import { InAppBrowserUtils } from '../../shared/utils/in-app-browser-utils';
import { CustomNavController } from '../../shared/providers/navigation/custom-nav-controller';

@Component({
  selector: 'desktop-warning',
  templateUrl: 'desktop-warning-page.html'
})
export class DesktopWarningPage {

  constructor(
    private customNavController: CustomNavController,
    private iabUtils: InAppBrowserUtils
  ) {}

  linkToGooglePlay() {
    this.iabUtils.openUrl('https://play.google.com/store/apps/details?id=com.tidy.pro&hl=en_US');
  }

  linkToIOS() {
    this.iabUtils.openUrl('https://apps.apple.com/us/app/itunes-u/id1518099748');
  }

  linkToDashboard() {
    this.customNavController.navigateRoot('dashboard');
  }
}
