import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';

import { Auth } from 'src/providers/auth/auth';
import { Authenticated } from 'src/behaviors/authenticated';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Events } from 'src/providers/events/events';
import { Me } from 'src/shared/providers/me';

import validationUtils from 'src/shared/utils/validation-utils';

@Component({
  selector: 'page-login',
  templateUrl: 'login.html',
  encapsulation: ViewEncapsulation.None
})
export class LoginPage implements OnInit {

  cameFromJobLink: boolean;
  errorMessage = '';
  isPrivateJob: boolean;
  loaded: boolean;
  loginForm: UntypedFormGroup;
  submitted = false;

  constructor(
    private auth: Auth,
    private authenticated: Authenticated,
    private customNavCtrl: CustomNavController,
    private events: Events,
    private formBuilder: UntypedFormBuilder,
    private me: Me,
    private route: ActivatedRoute
  ) {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, validationUtils.validateEmail]],
      password: ['', Validators.required]
    });
  }

  async ngOnInit() {
    this.loaded = false;
    const token = this.route.snapshot.paramMap.get('token');
    this.cameFromJobLink = this.customNavCtrl.getParam('cameFromJobLink');
    if (this.cameFromJobLink) {
      this.isPrivateJob = this.customNavCtrl.getParam('isPrivateJob');
      const email = this.customNavCtrl.getParam('email');
      this.loginForm.patchValue({ username: email });
    }
    if (token) {
      this.authorize({ auth_token: token })
    }
    this.loaded = true;
  }

  async onSubmit () {
    this.errorMessage = '';
    this.submitted = true;
    if (this.loginForm.valid) {
      await this.closeKeyboard();
      await this.authorize({
        username: this.loginForm.value.username,
        password: this.loginForm.value.password
      });
    }
  }

  async authorize(credentials) {
    try {
      await this.auth.login(credentials);
      const me = await this.me.fetchWithoutCache();
      try {
        const currentLanguage = await this.me.getMetadata(['external.language']);
        const language = currentLanguage?.[0]?.value || 'en';
        localStorage.setItem('language', language);
      } catch(err) {
        localStorage.setItem('language', 'en');
      }
      await this.authenticated.onAuthenticated(me);
    } catch (err) {
      this.unauthorized(err);
    }
  }

  unauthorized(err): void {
    if (err === 'invalid_grant') {
      this.errorMessage = 'The email and password you entered did not match our records. Please double-check and try again.';
    } else {
      this.errorMessage = err?.message || err?.error?.message;
    }
  }

  goToForgotPassword() {
    this.customNavCtrl.navigateForward('forgot-password');
  }

  async closeKeyboard() {
    if (Capacitor.getPlatform() !== 'web') {
      await Keyboard.hide();
    }
  }

}
