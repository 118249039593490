import { Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PrivateClient } from 'src/providers/private-client/private-client';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { Loading } from 'src/shared/components/loading/loading';
import { PrivatePayments } from 'src/providers/private-payments/private-payments';

@Component({
  templateUrl: 'sent-invoice.html',
  encapsulation: ViewEncapsulation.None
})

export class SentInvoicePage {

  errorMessage: string;
  invoice: any;
  isInternalComment: boolean;
  clientName: string;
  creditsApplied: number;
  form: UntypedFormGroup;
  invoiceNotes: any;
  loaded: boolean;
  messageSelectItems: any;
  proName: any;
  proPhoneNumber: any;
  proEmail: any;
  selectedMessageType: any;
  submitted: boolean;
  totalOwed: number;
  jobHours: any;
  jobMinutes: any;

  constructor(
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private privatePayments: PrivatePayments,
    private privateClient: PrivateClient,
    private storage: TidyStorage
  ) {
    this.form = this.fb.group({
      message: [''],
      messageType: ['public_reply']
    });
  }

  @Loading()
  async ionViewDidEnter() {
    try {
      this.loaded = false;
      this.invoice = this.navCtrl.getParam('invoice') || await this.getInvoice();
      this.clientName = this.navCtrl.getParam('clientName');
      this.proName = await this.storage.retrieve('hk_name');
      this.proPhoneNumber = await this.storage.retrieve('hk_phone_number');
      this.proEmail = await this.storage.retrieve('hk_email');
      this.invoiceNotes = await this.privatePayments.getInvoiceNotes(this.invoice.id);
      this.getAmounts();
      this.messageSelectItems = this.getMessageSelectItems();
      await this.getJobDuration();
      this.loaded = true;
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async getJobDuration() {
    const jobDetails = await this.privateClient.getJobDetailsForAddress(this.invoice.items[0].item.id, true);
    if (!jobDetails.job.job_durations.from_start_to_end_moment && !jobDetails.job.job_durations.reported_by_homekeeper) {
      return this.jobHours = null;
    }
    let minutes = jobDetails.job.job_durations.reported_by_homekeeper ? jobDetails.job.job_durations.reported_by_homekeeper : jobDetails.job.job_durations.from_start_to_end_moment;
    this.jobHours = Math.floor(minutes / 60);
    this.jobMinutes = Math.round((minutes - (this.jobHours * 60)) / 5) * 5;
  }

  async getInvoice() {
    const invoiceId = this.navCtrl.getParam('invoiceId');
    const clientId = this.navCtrl.getParam('clientId');
    const invoices = await this.privatePayments.getInvoices(clientId);
    return invoices.find((invoice) => invoice.id == invoiceId);
  }

  getMessageSelectItems() {
    return [
      {
        value: 'is_internal',
        viewValue: 'Internal note',
        icon: 'assets/svg/create-outline.svg'
      },
      {
        value: 'public_reply',
        viewValue: 'Public note',
        icon: 'assets/svg/arrow-redo-outline.svg'
      }
    ]
  }

  updateIsInternalComment(selection) {
    this.isInternalComment = selection == 'is_internal';
    this.selectedMessageType = this.isInternalComment ? this.messageSelectItems[0] : this.messageSelectItems[1];
  }

  getAmounts() {
    this.totalOwed = 0;
    let jobAmounts = 0
    this.invoice.items.map((job) =>  {
      this.totalOwed += job.item.billing.amount_due;
      jobAmounts += job.item.amount;
    });
    this.creditsApplied = jobAmounts - this.totalOwed;
  }

  @Loading()
  async sendMessage() {
    this.errorMessage = '';
    try {
      const payload = {
        invoice_id: this.invoice.id,
        type: 'text',
        is_internal: this.isInternalComment,
        text: this.form.value.message
      }
      await this.createInvoiceNote(payload);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async sendAttachment() {
    this.errorMessage = '';
    try {
      const attachment = await this.privatePayments.addAttachment();
      if (attachment == '') {
        return this.errorMessage = 'Unable to attach photo. Please upload a PNG or JPEG file.';
      }
      const payload = {
        invoice_id: this.invoice.id,
        type: 'photo',
        is_internal: this.isInternalComment,
        media_url: attachment
      }
      await this.createInvoiceNote(payload);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async createInvoiceNote(payload) {
    await this.privatePayments.createInvoiceNote(payload);
    this.form.patchValue({
      message: ''
    });
    this.invoiceNotes = await this.privatePayments.getInvoiceNotes(this.invoice.id);
  }
}
