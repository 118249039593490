<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="false"
    [title]="'Unpause Account'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'Your account is currently paused.'">
      </tidy-text>
    </tidy-row>

    <tidy-card>
      <form [formGroup]="form" novalidate action="#">
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="'Confirm that you agree to the '">
          </tidy-text>
          <tidy-text
            (action)="goToTerms()"
            [body]="'Terms of Use'">
          </tidy-text>
          <tidy-text
            [body]="' to unpause your account.'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-checkbox
            formControlName="terms"
            [items]="[{viewValue: 'I agree to the terms', value: false}]">
          </tidy-checkbox>
        </tidy-row>
      </form>
    </tidy-card>

    <tidy-error-message
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-button
      [text]="'Unpause My Account'"
      [action]="confirmUnpause.bind(this)"
      class="primary">
    </tidy-button>

  </tidy-wrapper>

</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
