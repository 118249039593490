<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Future Job'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-alert *ngIf="cleaning?.job_delegation_request?.status === 'requested' && cleaning?.job_delegation_request?.role === 'delegator'">
      <tidy-row>
        <tidy-text
          [body]="'You requested that '">
        </tidy-text>
        <tidy-text
          [body]="cleaning?.job_delegation_request?.delegator?.name ? cleaning?.job_delegation_request?.delegator?.name : 'a newly invited team member'">
        </tidy-text>
        <tidy-text
          [body]="' accept this job. We sent an email to '">
        </tidy-text>
        <tidy-text
          [body]="cleaning?.job_delegation_request?.delegatee?.email + '.'">
        </tidy-text>
        <tidy-text
          (action)="cancelDelegationRequest('cancel')"
          [body]="'Cancel Request'"
          class="link">
        </tidy-text>
      </tidy-row>
    </tidy-alert>

    <ng-container *ngIf="cleaning?.job_delegation_request?.status === 'accepted'">
      <tidy-alert *ngIf="cleaning?.job_delegation_request?.role === 'delegatee'">
        <tidy-row>
          <tidy-text
            [body]="'This job was delegated to you by '">
          </tidy-text>
          <tidy-text
            [body]="cleaning?.job_delegation_request?.delegator?.name">
          </tidy-text>
          <tidy-text
            [body]="'.  All payment will be made to them. '">
          </tidy-text>
          <tidy-text
            (action)="cancelDelegationRequest('undo')"
            [body]="'Undo'"
            class="link">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <tidy-alert *ngIf="cleaning?.job_delegation_request?.role === 'delegator'">
        <tidy-row>
          <tidy-text
            [body]="'You delegated this job to '">
          </tidy-text>
          <tidy-text
            [body]="cleaning?.job_delegation_request?.delegator?.name">
          </tidy-text>
          <tidy-text
            [body]="'.  All payment will be made to you. '">
          </tidy-text>
          <tidy-text
            (action)="cancelDelegationRequest('getJobBack')"
            [body]="'Undo'"
            class="link">
          </tidy-text>
        </tidy-row>
      </tidy-alert>
    </ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'This job is for a future date. Check the morning of the job to get client\'s address, to dos list, and more information..'">
      </tidy-text>
    </tidy-row>

    <tidy-card>
      <tidy-row class="extra-bottom-padding vertical-align-center">
        <tidy-image
          class="helper-size"
          src="assets/style-guide-images/bowtie-black.svg">
        </tidy-image>
        <tidy-text
          [header]="cleaning.job.date | tdate: 'dddd M/D/YYYY':'YYYY-MM-DD'">
        </tidy-text>
      </tidy-row>

      <tidy-row>
        <tidy-grid>
          <ion-row>
            <ion-col size="4">
              <tidy-text
                [body]="cleaning.job.start_time | ttime">
              </tidy-text><br>
              <tidy-text
                [body]="(cleaning?.job?.duration_in_minutes / 60)">
              </tidy-text>
              <tidy-text
                [body]="(cleaning?.job?.duration_in_minutes > 60 ? ' hours' : ' hour')">
              </tidy-text>
            </ion-col>
            <ion-col size="1">
              <tidy-image
                *ngIf="cleaning?.homekeeper_job?.repeat_client"
                [src]="'assets/svg/refresh-circle.svg'"
                >
              </tidy-image>
            </ion-col>
            <ion-col size="7" >
              <tidy-text
                [body]="'Client '">
              </tidy-text>
              <tidy-text
                [body]="cleaning?.customer?.first_name + ' ' + (cleaning?.customer?.last_name | abbrLastName: true)">
              </tidy-text>
              <br>
              <tidy-text
                *ngIf="cleaning?.job_delegation_request?.role !== 'delegatee'"
                [body]="(cleaning?.service_type_details?.service_category?.name == 'Regular Cleaning' ? 'Cleaning' : cleaning?.service_type_details?.service_category?.name)">
              </tidy-text>
              <tidy-text
                *ngIf="cleaning?.job_delegation_request?.role !== 'delegatee'"
                [body]="': '">
              </tidy-text>
              <tidy-text
                *ngIf="cleaning?.job_delegation_request?.role !== 'delegatee'"
                [body]="cleaning?.payment_price | tcurrency">
              </tidy-text>
              <tidy-text
                *ngIf="cleaning?.job_delegation_request?.role === 'delegatee'"
                [body]="cleaning?.service_type_details?.service_category?.name == 'Regular Cleaning' ? 'Cleaning' : cleaning?.service_type_details?.service_category?.name">
              </tidy-text>
            </ion-col>
          </ion-row>
        </tidy-grid>
      </tidy-row>
      <tidy-row class="extra-bottom-padding">
        <tidy-divider></tidy-divider>
      </tidy-row>
      <tidy-row class="vertical-align-center">
       <tidy-image [src]="'assets/svg/location-outline.svg'"></tidy-image>
        <tidy-text
          [body]="'Zip: '">
        </tidy-text>
        <tidy-text
          [body]="cleaning?.address?.zip">
        </tidy-text>
      </tidy-row>
      <tidy-row *ngIf="cleaning?.job_delegation_request?.status !== 'requested' && cleaning?.job_delegation_request?.status !== 'accepted'">
        <tidy-image [src]="'assets/svg/people-outline.svg'"></tidy-image>
        <tidy-text
          (action)="delegateJob()"
          [body]="'Delegate to Team Member'"
          class="link">
        </tidy-text>
      </tidy-row>
    </tidy-card>

    <tidy-grid>
      <ion-row>
        <ion-col size="3">
          <tidy-round-button
            class="black"
            [icon]="'assets/svg/close-circle-white.svg'"
            (action)="cancel()"
            [label]="'Cancel and Mark Me Unavailable'" >
          </tidy-round-button>
        </ion-col>
      </ion-row>
    </tidy-grid>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
