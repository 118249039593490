import { HttpClient } from 'src/shared/providers/http/http-client';
import { Injectable } from "@angular/core";

@Injectable()
export class IdentificationCheck {

  constructor(
    private httpClient: HttpClient,
  ) {}

  async getIdentityCheck() {
    return this.httpClient.get('api/v1/homekeeper/identity-check');
  }
}
