import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Events } from 'src/providers/events/events';
import { Me } from 'src/shared/providers/me';
import { Team } from 'src/providers/team/team';
import { Router } from '@angular/router';

import { desktopMenuItems } from 'src/shared/components/bottom-nav/bottom-nav-items';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-desktop-nav',
  templateUrl: './desktop-nav.component.html',
  styleUrls: ['./desktop-nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DesktopNavComponent implements OnInit {

  userName$: Observable<string>;
  teamName$: Observable<string>;
  menuItems = desktopMenuItems;

  constructor(
    private customNavCtrl: CustomNavController,
    private events: Events,
    private me: Me,
    public router: Router,
    private team: Team
  ) {}

  ngOnInit() {
    this.loadNavData();
    this.events.subscribe('auth:authenticated', () => this.loadNavData());
    this.events.subscribe('team-details:updated', ({ details }) => this.teamDetailsUpdated(details));
  }

  loadNavData() {
    this.userName$ = forkJoin([this.me.fetchWithoutCache()]).pipe(
      map(data => data[0]?.profile.name),
    )

    this.teamName$ = forkJoin([this.team.getMyTeam()]).pipe(
      map(data => data[0]?.name)
    )
  }

  teamDetailsUpdated(details) {
    this.teamName$ = of(details.name);
  }

  goToLink (link: string) {
    this.customNavCtrl.navigateForward(link);
  }
}
