<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Mileage Tracking'"
    [canGoBack]="true"
    [customBack]="'more'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="loaded">
      <tidy-row>
        <form [formGroup]="form">
          <tidy-filter
            [initValue]="filterItems[0]?.value"
            [items]="filterItems"
            [fitContent]="true"
            (optionChange)="changeRange($event)">
          </tidy-filter>
        </form>
      </tidy-row>

      <tidy-card *ngIf="customRange">
        <tidy-row class="field-top-padding">
          <form [formGroup]="form">
            <tidy-two-column-row>
              <tidy-datepicker
                style="width: 50%;"
                item-left
                [label]="'Start'"
                [icon]="'calendar-outline'"
                formControlName="startDate"
                [form]="form.controls.startDate">
              </tidy-datepicker>
              <tidy-datepicker
                item-right
                style="width: 50%;"
                [label]="'End'"
                [icon]="'calendar-outline'"
                formControlName="endDate"
                [form]="form.controls.endDate">
              </tidy-datepicker>
            </tidy-two-column-row>
          </form>
        </tidy-row>
        <tidy-row>
          <tidy-error-message
            [text]="errorMessage">
          </tidy-error-message>
        </tidy-row>
        <tidy-row class="extra-top-padding">
          <tidy-button
            [text]="'Show Results'"
            [action]="showResults.bind(this)"
            class="secondary">
          </tidy-button>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row [alignLastItemRight]="true">
          <tidy-image
            [src]="'assets/svg/car.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="distanceFormat === 'mi' ? 'Miles Travelled' : 'Kilometers Travelled'">
          </tidy-text>
          <tidy-text
            [title]="milesTravelled"
            class="green">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-row [align]="'center'">
        <tidy-text
          *ngIf="distanceFormat === 'mi'"
          [body]="'TIDY automatically tracks jobs as you add them with estimated miles.
          All numbers are estimates. TIDY does not give tax advice, please consult a professional. '">
        </tidy-text>
        <tidy-text
          *ngIf="distanceFormat === 'km'"
          [body]="'TIDY automatically tracks jobs as you add them with estimated kilometers.
          All numbers are estimates. TIDY does not give tax advice, please consult a professional. '">
        </tidy-text>
        <tidy-text
          [body]="'Learn More'"
          (action)="learnMore()"
          class="link">
        </tidy-text>
      </tidy-row>

      <tidy-button
        [text]="'Add Job to Track'"
        [action]="addJob.bind(this)"
        class="primary">
      </tidy-button>

      <tidy-row class="extra-top-padding">
        <tidy-text
          [header]="'Mileage Report'">
        </tidy-text>
      </tidy-row>

      <tidy-row>
        <tidy-card-button [hideArrow]="true">
          <tidy-grid>
            <ion-row>
              <ion-col size="3">
                <tidy-text
                  [helper]="'Start'">
                </tidy-text>
              </ion-col>
              <ion-col size="3">
                <tidy-text
                  [helper]="'Job'">
                </tidy-text>
              </ion-col>
              <ion-col size="3">
                <tidy-text
                  [helper]="'End'">
                </tidy-text>
              </ion-col>
              <ion-col size="3">
                <tidy-text
                  [helper]="distanceFormat === 'mi' ? 'Miles' : 'Kilometers'">
                </tidy-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-card-button>
      </tidy-row>

      <tidy-row *ngFor="let job of mileage">
        <tidy-card-button (action)="goToJob(job)">
          <tidy-grid>
            <ion-row>
              <ion-col size="3">
                <tidy-text
                  [body]="job?.start_location_name"
                  [translate]="false">
                </tidy-text>
              </ion-col>
              <ion-col size="3">
                <tidy-text
                  [body]="job.homekeeper_job?.client_name"
                  [translate]="false">
                </tidy-text>
              </ion-col>
              <ion-col size="3">
                <tidy-text
                  [body]="job?.end_location_name"
                  [translate]="false">
                </tidy-text>
              </ion-col>
              <ion-col size="3">
                <tidy-text
                  *ngIf="distanceFormat === 'mi'"
                  [body]="job?.estimated_mileage | number : '1.2-2'">
                </tidy-text>
                <tidy-text
                  *ngIf="distanceFormat === 'km'"
                  [body]="((job?.estimated_mileage * 1.60934) | number : '1.2-2') || '0'">
                </tidy-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-card-button>
      </tidy-row>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
