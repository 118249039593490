<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Messages'"
    [canGoBack]="true"
    [customBack]="backPage">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">

      <tidy-card>
        <tidy-row class="extra-bottom-padding" [alignLastItemRight]="true">
          <tidy-image
            [src]="'assets/img/account.svg'"
            class="title-size">
          </tidy-image>
          <tidy-text
            [header]="client?.first_name"
            [translate]="false">
          </tidy-text>
          <tidy-text
            [body]="'Call'"
            class="link"
            (action)="callClient()">
          </tidy-text>
        </tidy-row>
        <tidy-row class="field-top-padding extra-bottom-padding">
          <form [formGroup]="form" novalidate action="#">
            <tidy-textarea
              [label]="'Send Message'"
              formControlName="message"
              [form]="form.controls.message"
              [submitted]="submitted"
              [errorMessage]="'Please enter a message to send.'"
              [rightIconAction]="sendMessage.bind(this)"
              [leftIconAction]="sendAttachment.bind(this)">
            </tidy-textarea>
          </form>
        </tidy-row>
        <tidy-row *ngIf="messages?.length == 0" [align]="'center'">
          <tidy-text
            [helper]="'No Messages'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let message of messages; let last = last">
          <ng-container *ngIf="message?.type == 'text'">
            <tidy-row class="vertical-align-center">
              <tidy-initials-icon
                [fullName]="message?.from_member?.first_name + ' ' + message?.from_member?.last_name">
              </tidy-initials-icon>
              <tidy-text
                [body]="message?.from_member?.first_name"
                [translate]="false">
              </tidy-text>
              <div
              style="color: #666;
              font-family: Helvetica Neue;
              font-size: 13px;
              font-weight: 300;
              margin: 3px 0px 0px 5px;
              cursor: pointer">
                {{getMessageDateTime(message?.sent_at)}}
              </div>
              <ng-container *ngIf="message?.metadata?.job?.id">
                <tidy-image
                  [src]="'assets/svg/briefcase-outline.svg'"
                  style="font-size: 13px; margin-left: 10px; margin-top: 3px; opacity: 30%">
                </tidy-image>
                <div
                style="color: #00AABA;
                font-family: Helvetica Neue;
                font-size: 13px;
                font-weight: 400;
                text-decoration: underline;
                margin: 3px 0px 0px 0px;
                cursor: pointer"
                (click)="goToJobPage(message?.metadata?.job?.id, message?.metadata?.job?.is_private, message?.metadata?.job?.start_datetime)">
                  {{getJobDate(message?.metadata?.job?.start_datetime, message?.job?.timezone_name)}} {{'Job' | translate}}
                </div>
              </ng-container>
            </tidy-row>
            <tidy-row *ngFor="let media of message?.content?.files" [alignLastItemRight]="true" [ngClass]="last ? '' : 'extra-bottom-padding'" style="margin-top:-5px">
              <tidy-photo-note
                style="max-width: 150px"
                [src]="media">
              </tidy-photo-note>
            </tidy-row>
            <tidy-row *ngIf="message?.content?.text !== '' && message?.content?.text" [ngClass]="last ? '' : 'extra-bottom-padding'">
              <tidy-text
                [body]="message?.content?.text">
              </tidy-text>
            </tidy-row>
          </ng-container>
          <ng-container *ngIf="message?.type == 'phone_call'">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/phone-black.svg'"
                class="phone-icon"
                [ngClass]="(message?.content?.text == 'Calling...' || message?.content?.text == 'Call in-progress...') ? 'wiggle-animation' : ''">
              </tidy-image>
              <tidy-text
                [body]="message?.from_member?.first_name"
                [translate]="false">
              </tidy-text>
              <div
              style="color: #666;
              font-family: Helvetica Neue;
              font-size: 13px;
              font-weight: 300;
              margin: 3px 0px 0px 5px;
              cursor: pointer">
                {{getMessageDateTime(message?.sent_at)}}
              </div>
              <ng-container *ngIf="message?.metadata?.job?.id">
                <tidy-image
                  [src]="'assets/svg/briefcase-outline.svg'"
                  style="font-size: 13px; margin-left: 10px; margin-top: 3px; opacity: 30%">
                </tidy-image>
                <div
                style="color: #00AABA;
                font-family: Helvetica Neue;
                font-size: 13px;
                font-weight: 400;
                text-decoration: underline;
                margin: 3px 0px 0px 0px;
                cursor: pointer"
                (click)="goToJobPage(message?.metadata?.job?.id, message?.metadata?.job?.is_private, message?.metadata?.job?.start_datetime)">
                  {{getJobDate(message?.metadata?.job?.start_datetime, message?.job?.timezone_name)}} Job
                </div>
              </ng-container>
            </tidy-row>
            <tidy-row [ngClass]="!last ? 'extra-bottom-padding' : ''">
              <tidy-text
                [body]="'<i>' + message?.content?.text + '</i>'">
              </tidy-text>
              <tidy-text
                *ngIf="message?.content?.text == 'Call finished.'"
                [body]="'<i>' + communication.getCallMinutes(message?.content?.phone_status?.duration) + '</i>'">
              </tidy-text>
            </tidy-row>
          </ng-container>
        </ng-container>
      </tidy-card>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
