<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="pageTitle ? pageTitle : ''">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">
      <tidy-alert>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="concierge.getStatusIcon(status)"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="item?.header_text">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="isJobIssue" class="extra-bottom-padding">
          <tidy-text
            [body]="'You reported this issue on your '">
          </tidy-text>
          <tidy-text
            [body]="(item?.metadata?.custom?.job_date | tdate:'MMMM  D, YYYY ') + (item?.metadata?.custom?.job_time | ttime)">
          </tidy-text>
          <tidy-text
            [body]="' job. '">
          </tidy-text>
          <tidy-text
            [body]="'Tap here'"
            (action)="goToJobDetails()"
            class="link">
          </tidy-text>
          <tidy-text
            [body]="' for job details.'">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="status !== 'completed' && status !== 'closed'">
          <tidy-text
            *ngIf="item?.components[0]?.value?.status === 'waiting_on_tidy'"
            [body]="'<i>Waiting on TIDY</i>'">
          </tidy-text>
          <tidy-text
            *ngIf="item?.components[0]?.value?.status === 'waiting_on_customer'"
            [body]="'<b><i>Waiting on You</i><b>'">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="status === 'waiting_on_tidy'" class="extra-top-padding">
          <tidy-text
            [body]="'We expect to reply within '">
          </tidy-text>
          <tidy-text
            [body]="replyTime">
          </tidy-text>
          <tidy-text
            [body]="'. We will reply both in the app and to your email '">
          </tidy-text>
          <tidy-text
            [body]="email + '.'"
            [translate]="false">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <tidy-button
        [text]="'Reply'"
        [action]="reply.bind(this)"
        class="primary">
      </tidy-button>

      <tidy-card *ngFor="let comment of item?.components[0]?.value?.comments">
        <tidy-row [alignLastItemRight]="true">
          <tidy-text
            [header]="((comment?.user_type === 'Homekeeper' || comment?.user_type === 'homekeeper') ? 'You' : 'TIDY Concierge')">
          </tidy-text>
          <tidy-text
            [helper]="comment?.created_at | tdate:'M/D h:mma'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [checkLineBreaks]="true"
            [linkify]="true"
            [body]="comment?.content">
          </tidy-text>
        </tidy-row>
      </tidy-card>
      <ng-container *ngIf="item?.attachments?.length > 0">
        <tidy-row>
          <tidy-text
            [header]="'Attachments:'">
          </tidy-text>
        </tidy-row>
        <div class="attachment-container">
          <tidy-row *ngFor="let attachment of item?.attachments">
            <div>
              <img
                [src]="attachment?.file_url"
                (click)="openAttachment(attachment?.file_url)"
                class="attachment-image">
            </div>
          </tidy-row>
        </div>
      </ng-container>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
