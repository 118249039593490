import { Injectable } from "@angular/core";
import { HttpClient } from "../http/http-client";

@Injectable()
export class CustomFieldsService {
  constructor(private httpClient: HttpClient) {}

  async getCustomFields(bookingId: string, modelType: string) {
    const url = `api/v1/homekeeper/customer-custom-fields/${bookingId}?model_type=${modelType}`;
    return this.httpClient.get(url);
  }

  getCustomFieldsForDetails(customFields, details) {
    try {
      if (!details || customFields?.length === 0) {
        return [];
      }
      return Object.keys(details).map((key) => {
        const customField = customFields.find((field) => field.field_key == key);
        const isCustomFieldVisibleToHomekeeper = customField?.is_visible_to_homekeeper;
        if (details[key] === null || !isCustomFieldVisibleToHomekeeper) {
          return '';
        }
        return `${customField.field_name}: ${details[key]}`;
      });
    } catch (err) {
      console.error(err);
      return [];
    }
  }
}
