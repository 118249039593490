import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PrivateClient } from 'src/providers/private-client/private-client';

import { Timeout } from 'src/shared/components/timeout/timeout';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { removeCurrencyMask } from 'src/shared/utils/currency-utils';

@Component({
  templateUrl: 'edit-private-job-price.html'
})

export class EditPrivateJobPricePage extends Timeout {

  date: any;
  errorMessage: string;
  form: UntypedFormGroup;
  formLabel: string;
  formErrorMessage: string;
  job: any;
  pageTitle: string;
  submitted: boolean;
  startTime: any;

  constructor(
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private privateClient: PrivateClient,
    timeoutErrorHandler: TimeoutErrorHandler,
    public route: ActivatedRoute,
    router: Router
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.fb.group({
      price: ['', Validators.required]
    });
  }

  async ionViewDidEnter() {
    this.loaded = false;
    this.job = this.navCtrl.getParam('job');
    this.date = this.navCtrl.getParam('date');
    this.startTime = this.navCtrl.getParam('startTime');
    this.form.patchValue({
      price: (this.job.job.amount / 100).toString(),
    });
    if (this.job.job.service_type_details.billing_type == 'hourly') {
      this.pageTitle = 'Edit Hourly Rate';
      this.formLabel = 'Hourly rate';
      this.formErrorMessage = 'Please select a rate.';
    } else {
      this.pageTitle = 'Edit Job Price';
      this.formLabel = 'Job price'
      this.formErrorMessage = 'Please select a price.';
    }
    this.loaded = true;
  }

  async save() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    try {
      const payload = {
        amount: removeCurrencyMask(this.form.value.price)
      };
      await this.privateClient.editPrivateJobPrice(payload, this.job.job.id);
      this.navCtrl.navigateForward(`private-job/${this.job.job.id}`);
    } catch(err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

}
