import { Component, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { AddressForm } from 'src/providers/address-form/address-form';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PrivateClient } from 'src/providers/private-client/private-client';

import { ConfirmPageParamsModel, ConfirmPage } from 'src/pages/confirm/confirm';

import { AutoCompleteAddressModel } from 'src/shared/models/autocomplete-address.model';
import { AddressModel } from 'src/shared/models/address.model';
import { SuccessPageParamsModel } from 'src/pages/success/success';

import { RadioButtonModel } from '@tidyapp/tidy-ui-components';
import { MultipleCountryService } from 'src/providers/addresses/multiple-country.service';
import { keepOnlyText } from 'src/shared/utils/text-utils';
import { Me } from 'src/shared/providers/me';
import { AddressMetadata } from 'src/shared/components/autocomplete-address/autocomplete-address';
import { Loading } from 'src/shared/components/loading/loading';

@Component({
  templateUrl: 'edit-private-address.html'
})

export class EditPrivateAddressPage {

  autocompleteAddress: AutoCompleteAddressModel[];
  errorMessage: string;
  form: UntypedFormGroup;
  params: {
    address: AddressModel;
    clientId: number;
  };
  submitted: boolean;
  zipCodeMask = {
    mask: '00000'
  }
  countryList: RadioButtonModel[];
  countryCode: string;
  noAddressPickedTemplate: ElementRef;
  invalidAddressError: string;
  loaded: boolean;

  constructor(
    private addressForm: AddressForm,
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private modalCtrl: ModalController,
    private privateClient: PrivateClient,
    private multipleCountryService: MultipleCountryService,
    private me: Me
  ) {
    this.form = this.fb.group({
      address: ['', Validators.required],
      unit: [''],
      zipcode: ['', Validators.required],
      latitude: [''],
      longitude: [''],
      address_state: ['', Validators.required],
      city: ['', Validators.required]
    });

    this.countryList = this.multipleCountryService.countryRadioButtonList;
  }

  @Loading()
  async ionViewDidEnter() {
    this.loaded = false;
    this.params = await this.customNavCtrl.getParams();
    await this.populateForm(this.params.address);
    this.loaded = true;
  }

  async populateForm(address: AddressModel) {
    const me = await this.me.fetchWithoutCache();
    const countryCode = me.profile.address.country_code;
    this.setValidators(countryCode);
    this.countryCode = countryCode;
    const params = {
      ...address,
    };
    this.form.patchValue(params);
  }

  setValidators(proCountryCode: string) {
    const validatorObject = this.multipleCountryService.getCountrySettings(proCountryCode);
    this.form.get('zipcode').setValidators(validatorObject.validator);
    this.form.get('zipcode').updateValueAndValidity();
    this.zipCodeMask = validatorObject.mask;
  }

  updateSearch = async () => {
    this.autocompleteAddress = await this.addressForm.updateSearch(this.form.value.address, this.countryCode);
  }

  choseItem(addressMetadata: AddressMetadata) {
    this.form.patchValue(addressMetadata);
    this.errorMessage = '';
  }

  saveChanges = async () =>  {
    this.submitted = true;
    if(this.invalidAddressError) {
      this.errorMessage = this.invalidAddressError;
      return;
    }
    if (!this.form.valid) {
      return;
    }
    const zipcode = keepOnlyText(this.form.value.zipcode);
    const address = this.parseAddressName();
    const data = {
      address,
      unit: this.form.value.unit,
      zipcode,
      country_code: this.countryCode,
      add_state: this.form.value.address_state
    };
    try {
      await this.privateClient.updatePrivateAddress(data, this.params.address.id);
      const params = this.mountSuccessPageParams();
      this.customNavCtrl.navigateForward('success', params);
    } catch (err) {
      this.errorMessage = err.message || err.error.message;
    }
  }

  parseAddressName() {
    const array = this.form.value.address.split(',');
    return array?.shift();
  }

  mountSuccessPageParams(): SuccessPageParamsModel {
    return {
      header: `Address Saved`,
      body: `The address for this Client has been updated.`,
      buttonText: `Ok`,
      buttonRoute: `private-client/${this.params.clientId}`
    };
  }

  async confirmDeleteAddress() {
    const confirmDelete: ConfirmPageParamsModel = {
      title: 'Delete Address?',
      body: 'This will cancel all jobs at this address.',
      backText: 'Go Back',
      confirmText: 'Delete Address',
      confirmAction: this.deleteAddress.bind(this)
    };

    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: confirmDelete,
      animated: false
    });
    confirmationModal.present();
  }

  async deleteAddress() {
    await this.privateClient.deletePrivateClientAddress(this.params.address.id);

    const onSuccessDelete: SuccessPageParamsModel = {
      header: 'Address Deleted',
      body: 'You can add a new address in the Client page',
      buttonText: 'Go to Client',
      buttonRoute: `private-client/${this.params.clientId}`
    };

    this.modalCtrl.dismiss();
    this.customNavCtrl.navigateForward('success', onSuccessDelete);
  }
}
