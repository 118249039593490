import { Component, ViewEncapsulation, OnInit, Output, EventEmitter } from '@angular/core';

import { MySchedule } from 'src/providers/my-schedule/my-schedule';

import { Loading } from 'src/shared/components/loading/loading';

import * as moment from 'moment';

import { ActivatedRoute } from '@angular/router';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Component({
  selector: 'tidy-calendar',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {

  currentDate = new Date();
  lockSwipes: boolean;
  eventSource: any[] = [];
  changeHistory: any;
  availability: Array<{date: string, time_ranges: Array<{start_time: string, end_time: string}>}>;
  @Output() calendarLoaded = new EventEmitter<boolean>();
  loaded: boolean = false;

  constructor(
    private navCtrl: CustomNavController,
    private mySchedule: MySchedule,
    private route: ActivatedRoute,
  ) {}

  async ngOnInit(): Promise<any> {
    const jumpToDay = this.route.snapshot.paramMap.get('jumpToDay');
    if (jumpToDay) {
      this.onTimeSelected( {selectedTime: jumpToDay} );
    }
    await this.updateMonthAvailability();
    setTimeout( () => this.lockSwipes = true, 1000);
    this.loaded = true;
    this.calendarLoaded.emit(this.loaded);
  }

  @Loading()
  async updateMonthAvailability(): Promise<any> {
    const currentMonth = await this.fetchForDate(this.currentDate);
    const nextMonthDate = moment(this.currentDate).add(1, 'month').toDate();
    const nextMonth = await this.fetchForDate(nextMonthDate);
    this.availability = [...currentMonth, ...nextMonth];
    this.eventSource = this.parseAvailability(this.availability);
    console.log(this.eventSource);
  }

  async fetchForDate(date): Promise<any> {
    const year: number = date.getFullYear();
    const month: number = date.getMonth() + 1;
    const fetchMonth = null;
    const result =  await this.mySchedule.fetchMonthAvailability(month, year, fetchMonth);
    return result;
  }

  onTimeSelected(date): void {
    this.currentDate = new Date(this.currentDate);
    if (date.selectedTime > new Date()) {

      const targetDate = moment(date.selectedTime).format('YYYY-MM-DD');
      const dayData = this.availability.find(availabilityDay => {
        return availabilityDay.date === targetDate;
      });
      this.navCtrl.navigateForward('edit-schedule', dayData);
    }
  }

  displayPreviousMonthArrow(): boolean {
    const today = moment();
    const selected = moment(this.currentDate);
    return selected.isAfter(today) && (
      selected.get('month') !== today.get('month')
      || selected.get('year') !== today.get('year')
    );
  }

  isDisabledDate = (date: Date): boolean => {
    return moment(date).isBefore(moment(), 'day');
  }

  previousMonth(): void {
    this.currentDate = moment(this.currentDate).add(-1, 'month').toDate();
    this.updateMonthAvailability();
  }

  isToday(date) {
    const today = moment();
    const selected = moment(date);
    return selected.isSame(today, 'day');
  }

  setDateClass(data) {
    const { date,  disabled, hasEvent } = data;
    const today = moment();
    const selected = moment(date);
    const notAvailable = (!hasEvent || disabled);

    return {
      available: !notAvailable,
      'not-available': notAvailable,
      'is-today': this.isToday(date),
      'is-future': selected.isAfter(today, 'day'),
      'is-past': selected.isBefore(today, 'day') || selected.isBefore(this.currentDate, 'month')
    };
  }

  nextMonth(): void {
    this.currentDate = moment(this.currentDate).add(1, 'month').toDate();
    this.updateMonthAvailability();
  }

  parseAvailability(availability: any): Array<any> {
    return availability.reduce((result, item) => {
      const hasTimesRanges = item.time_ranges.length;
      if (hasTimesRanges) {
        result.push(this.createCalendarEvent(item.date));
      }
      return result;
    }, []);
  }

  createCalendarEvent(dateStr): any {
    const date = moment.utc(dateStr);
    return {
      title: dateStr,
      startTime: date.toDate(),
      endTime: date.add(1, 'day').toDate(),
      allDay: true
    };
  }

}
