export class TodosAnimation {
  public static roomTodosDone(todos) {
    return todos.length && todos.every(todo => todo.performance === 'performed');
  }

  public static countDoneTodos(todos) {
    const doneTodos = todos.filter(todo => {
      return todo.performance === 'performed';
    });
    return doneTodos.length;
  }
}
