/**
 * This method is to remove the decimal part from the number
 * input value 10.54 output 1054
 * ```ts
 * removeDecimal(10.82)
 * ```
 */
export function removeDecimal(value: number) {
  return value * 100;
}

/**
 * This method is to remove the currency mask from a string generally comming from imask input
 * input value "10.54" output 1054
 * ```ts
 * removeCurrencyMask("10.82")
 * ```
 */
export function removeCurrencyMask(value: string) : number {
  if(value.length === 1) {
    return  parseInt(value) * 100;
  }
  const rawValue = value ? value.replace(/[$.,]/g, '') : null;

  return parseInt(rawValue);
}
