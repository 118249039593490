import { Component, ElementRef, ViewChild } from "@angular/core";
import { HtmlVideoRecorder } from "src/shared/providers/camera/html-video-recorder";

@Component({
  templateUrl: 'video-recorder-modal.component.html',
  styleUrls: ['./video-recorder-modal.component.scss']
})
export class VideoRecorderModalComponent {
  @ViewChild('videoElem') videoElementRef: ElementRef;

  constructor(public htmlVideoRec: HtmlVideoRecorder) {}

  btnAction() {
    if(!this.htmlVideoRec.isRecording) {
      this.htmlVideoRec.recordVideo(this.videoElementRef.nativeElement)
    } else {
      this.htmlVideoRec.stopVideoRecording();
    }
  }

  save() {
    const videoBlob = this.htmlVideoRec.videoBlob;
    this.htmlVideoRec.videoModal.dismiss(videoBlob);
  }
}
