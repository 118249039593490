import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class DateProvider {

  parseDateTime(date: string, format: string = 'YYYY-MM-DD HH:mm') {
    return this.parse(date, format);
  }

  now() {
    return moment();
  }

  todayNowStr(): string {
    return moment().format('YYYY-MM-DD HH:mm');
  }

  private parse(date: string, format: string): moment.Moment {
    return moment(date, format);
  }

}
