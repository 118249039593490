<tidy-modal [title]="title">

  <ng-container *ngIf="syncStatus == 'isSyncing'">
    <ng-container *ngIf="toDosProvider.totalNumberOfMediaToUpload > 0">
      <mat-progress-bar
        class="media-upload-progress-bar"
        mode="determinate"
        [value]="(toDosProvider.numberOfMediaUploaded / (toDosProvider.totalNumberOfMediaToUpload + 1))*100"
        style="margin: 16px 0; width: 100%;">
      </mat-progress-bar>
      <tidy-row class="extra-bottom-padding">
        <tidy-text 
          [body]="toDosProvider.numberOfMediaUploaded + (' of ' | translate) + toDosProvider.totalNumberOfMediaToUpload + (' photos uploaded' | translate)">
        </tidy-text>
        <ng-container *ngIf="toDosProvider.numberOfMediaUploaded == toDosProvider.totalNumberOfMediaToUpload">
          <br>
          <tidy-text 
            [body]="'Sync completing...'">
          </tidy-text>
        </ng-container>
      </tidy-row>
    </ng-container>
    <ng-container *ngIf="toDosProvider.totalNumberOfMediaToUpload == 0">
      <mat-progress-bar
        class="media-upload-progress-bar"
        mode="determinate"
        [value]="percentComplete"
        style="margin: 16px 0; width: 100%;">
      </mat-progress-bar>
      <tidy-row class="extra-bottom-padding">
        <tidy-text
          [body]="'Syncing job...'">
        </tidy-text>
      </tidy-row>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="syncStatus.includes('Success')">
    <tidy-row *ngIf="syncStatus == 'syncSuccessFeeWaived'" class="extra-bottom-padding">
      <tidy-text
        [body]="'Since this was a repeat client of yours, this job was marked completed (not leaving early), unless the Client disputes this. Have a great day!.'">
      </tidy-text>
    </tidy-row>
    <tidy-row *ngIf="syncStatus == 'syncSuccess'" class="extra-bottom-padding">
      <tidy-text
        *ngIf="page == 'MWToDosPage'"
        [body]="'All updates have been saved.'">
      </tidy-text>
      <tidy-text
        *ngIf="page == 'SharedToDoListPage'"
        [body]="'Send an invoice & instantly request payment for this job by logging in or creating an account with TIDY.'">
      </tidy-text>
      <ng-container *ngIf="page == 'SharedJobPage'">
        <tidy-text
          *ngIf="isLoggedIn"
          [body]="'Send an invoice & instantly request payment in the next page.'">
        </tidy-text>
        <tidy-text
          *ngIf="!isLoggedIn && sharedJobProState == 'active'"
          [body]="'Send an invoice & instantly request payment by logging in.'">
        </tidy-text>
        <tidy-text
          *ngIf="!isLoggedIn && sharedJobProState !== 'active'"
          [body]="'Send an invoice & instantly request payment by creating an account.'">
        </tidy-text>
      </ng-container>
      <ng-container *ngIf="page == 'PrivateJobPage'">
        <tidy-text
          *ngIf="didSendInvoice"
          [body]="'All updates have been saved.'">
        </tidy-text>
        <tidy-text
          *ngIf="!didSendInvoice"
          [body]="delegationRole == 'delegatee' ? '' : 'You can send an invoice from the past job page if you\'d like.'">
        </tidy-text>
      </ng-container>
    </tidy-row>
    <tidy-row>
      <tidy-button
        [text]="'Ok'"
        [action]="goToPageAfterSync?.bind(this)"
        class="primary">
      </tidy-button>
    </tidy-row>
  </ng-container>

  <ng-container *ngIf="syncStatus == 'APIError'">
    <tidy-row class="extra-bottom-padding">
      <tidy-text
        [body]="'We were unable to sync data for your last job due to a server error. We recommend trying again. If you still see the error, you can clear that data, which will remove the data from your device but allow you to continue.'">
      </tidy-text>
    </tidy-row>
    <tidy-row>
      <tidy-text
        [body]="'<b>Error:<b>'"
        class="red">
      </tidy-text>
    </tidy-row>
    <tidy-row class="extra-bottom-padding">
      <tidy-text
        [body]="'<b>' + errorMessage + '<b>'"
        class="red">
      </tidy-text>
    </tidy-row>
    <tidy-row>
      <tidy-button
        [text]="'Try Sync Again'"
        [action]="completeJob?.bind(this)"
        class="primary">
      </tidy-button>
    </tidy-row>
    <tidy-row class="extra-top-padding" *ngIf="page == 'MWToDosPage'">
      <tidy-text
        (action)="showClearStore()"
        [helper]="'Clear Job Data'"
        class="link">
      </tidy-text>
    </tidy-row>
  </ng-container>

  <ng-container *ngIf="syncStatus == 'badInternetError'">
    <tidy-row class="extra-bottom-padding">
      <tidy-text
        [body]="'We were unable to sync data for your last job due to poor/spotty internet connection. We recommend trying again when you have a good connection. If you are really stuck, you can clear that data, which will remove the data from your device but allow you to continue.'">
      </tidy-text>
    </tidy-row>
    <tidy-row>
      <tidy-button
        [text]="'Try Sync Again'"
        [action]="completeJob?.bind(this)"
        class="primary">
      </tidy-button>
    </tidy-row>
    <tidy-row class="extra-top-padding" *ngIf="page == 'MWToDosPage'">
      <tidy-text
        (action)="showClearStore()"
        [helper]="'Clear Job Data'"
        class="link">
      </tidy-text>
    </tidy-row>
  </ng-container>

  <ng-container *ngIf="syncStatus == 'clearStore'">
    <tidy-row class="extra-bottom-padding">
      <tidy-text
        [body]="'This means we will lose all updates after you started the job.  You can still expect to get paid in full if the Client says the job was complete.'">
      </tidy-text>
    </tidy-row>
    <tidy-row>
      <tidy-button
        [text]="'Try Sync Again'"
        [action]="completeJob?.bind(this)"
        class="primary">
      </tidy-button>
    </tidy-row>
    <tidy-row class="extra-top-padding">
      <tidy-text
        (action)="showConfirmedClearStore()"
        [helper]="'Clear Job Data'"
        class="link">
      </tidy-text>
    </tidy-row>
  </ng-container>

  <ng-container *ngIf="syncStatus == 'clearStoreConfirmed'">
    <tidy-row class="extra-bottom-padding">
      <tidy-text
        [body]="'If you need to contact Concierge about this job, please do so by clicking on the job in the jobs page.'">
      </tidy-text>
    </tidy-row>
    <tidy-row>
      <tidy-button
        [text]="'Ok'"
        [action]="goToPageAfterSync?.bind(this)"
        class="primary">
      </tidy-button>
    </tidy-row>
  </ng-container>

</tidy-modal>
