<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Account Info'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
    <tidy-wrapper>
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
      <ng-container *ngIf="loaded">

        <ng-container *ngIf="!didChooseCategories">
          <tidy-card>
            <tidy-row>
              <tidy-text
                [body]="'What types of services do you offer?'">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngFor="let item of categoryItems">
              <tidy-checkbox
                [items]="[item]"
                [value]="serviceTypesModel[item.value]"
                [(checked)]="serviceTypesModel[item.value]"
                [initValue]="serviceTypesModel[item.value]">
              </tidy-checkbox>
             </tidy-row>
             <tidy-row class="extra-bottom-padding">
               <tidy-divider></tidy-divider>
             </tidy-row>
             <tidy-row [align]="'center'">
               <tidy-text
                 [helper]="'Don\'t see what you offer? '">
               </tidy-text>
               <tidy-text
                 [helper]="'Request Type'"
                 (action)="goToRequestNewServiceType()"
                 class="link">
               </tidy-text>
             </tidy-row>
          </tidy-card>
          <tidy-error-message
            *ngIf="didNotSelectServiceError"
            [text]="'Please select the types of services you offer.'">
          </tidy-error-message>
          <tidy-button
            [action]="next.bind(this)"
            [text]="'Next'"
            class="primary">
          </tidy-button>
        </ng-container>

        <ng-container *ngIf="didChooseCategories">
          <tidy-card>
            <form [formGroup]="form" novalidate action="#">
              <ng-container *ngIf="!hasName">
                <tidy-row class="field-bottom-padding field-top-padding">
                  <tidy-input
                    [label]="'First name'"
                    [icon]="'assets/svg/person-outline.svg'"
                    [activeIcon]="'assets/svg/person-outline-tidy-green.svg'"
                    formControlName="firstName"
                    [form]="form.controls.firstName"
                    [submitted]="submitted"
                    [errorMessage]="'Please enter your first name.'">
                  </tidy-input>
                </tidy-row>
                <tidy-row class="field-bottom-padding">
                 <tidy-input
                   [label]="'Last name'"
                   [icon]="'assets/svg/person-outline.svg'"
                   [activeIcon]="'assets/svg/person-outline-tidy-green.svg'"
                   formControlName="lastName"
                   [form]="form.controls.lastName"
                   [submitted]="submitted"
                   [errorMessage]="'Please enter your last name.'">
                 </tidy-input>
                </tidy-row>
              </ng-container>
              <ng-container *ngIf="!hasAddress">
                <tidy-row class="field-bottom-padding" [ngClass]="hasName ? 'field-top-padding' : ''">
                  <tidy-select
                    [icon]="'home-outline'"
                    [label]="'Country'"
                    [items]="countryList"
                    [form]="form.get('countryCode')"
                    [icon]="'assets/svg/home-outline.svg'"
                    formControlName="countryCode"
                    (optionChange)="changeCountrySelected($event)">
                  </tidy-select>
                </tidy-row>
                <tidy-row class="field-bottom-padding">
                  <tidy-auto-complete-address
                    [submitted]="submitted"
                    [addressFormControl]="form.controls.address"
                    [countryCode]="form.controls.countryCode.value"
                    (onAddressChosen)="choseItem($event)"
                    (onError)="errorMessage = $event"
                    (noAddressPickedUpdate)="noAddressPickedTemplate = $event"
                    (invalidAddressError)="invalidAddressError = $event">
                  </tidy-auto-complete-address>
                </tidy-row>
                <tidy-row class="field-bottom-padding">
                  <tidy-input
                    label="Unit / Apartment"
                    formControlName="unit"
                    [form]="form.controls.unit"
                    [submitted]="submitted"
                    [icon]="'assets/svg/home-outline.svg'"
                    [activeIcon]="'assets/svg/home-outline-tidy-green.svg'"
                    [submitted]="submitted"
                    [errorMessage]="'Please enter a valid address'">
                  </tidy-input>
                </tidy-row>
                <tidy-row class="extra-bottom-padding">
                  <tidy-input
                    [customMask]="zipCodeMask"
                    label="Zip Code"
                    [inputMode]="'numeric'"
                    [form]="form.controls.zip"
                    [submitted]="submitted"
                    formControlName="zip"
                    [icon]="'assets/svg/home-outline.svg'"
                    [activeIcon]="'assets/svg/home-outline-tidy-green.svg'"
                    [errorMessage]="'Please enter a valid zipcode'">
                  </tidy-input>
                </tidy-row>
              </ng-container>
              <tidy-row class="extra-bottom-padding">
                <tidy-radio-button
                  [label]="'What is your gender?'"
                  formControlName="gender"
                  [form]="form.controls.gender"
                  [items]="[{viewValue: 'Male', value: 'male'}, {viewValue: 'Female', value: 'female'}, {viewValue: 'Other', value: 'other'}]"
                  [submitted]="submitted"
                  [errorMessage]="'Please select your gender.'">
                </tidy-radio-button>
              </tidy-row>
              <tidy-row class="extra-bottom-padding">
                <tidy-radio-button
                  [label]="'Have you previously been certified with TIDY?'"
                  formControlName="previouslyCertified"
                  [form]="form.controls.previouslyCertified"
                  [items]="[{viewValue: 'Yes - I am back', value: 'yes'},{viewValue: 'No - I am new', value: 'no'}]"
                  [selectError]="form.value.previouslyCertified === 'yes'"
                  [selectMessage]="'No need to reapply. Please log into your previous account to see if you are eligible to reapply.'"
                  [submitted]="submitted"
                  [errorMessage]="'Please select an answer.'">
                </tidy-radio-button>
              </tidy-row>
            </form>
          </tidy-card>

          <ng-container *ngTemplateOutlet="noAddressPickedTemplate">
          </ng-container>

          <tidy-error-message
            [text]="errorMessage">
          </tidy-error-message>

          <tidy-button
            [action]="submit.bind(this)"
            [text]="'Submit'"
            class="primary">
          </tidy-button>

          <tidy-row [align]="'center'">
            <tidy-text
              [body]="'Emails and phones are used for Client communication and notifications about things like new jobs. By clicking, you agree that TIDY may contact you by email, phone, or SMS at the email or number you provide.'">
            </tidy-text>
          </tidy-row>
        </ng-container>

      </ng-container>
    </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
