<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Payment Settings'"
    [customBack]="'more'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-alert *ngIf="cameFromOnboardingPage">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/checkmark-circle.svg'">
          </tidy-image>
          <tidy-text
            [header]="'Payment Settings Confirmed'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'Your settings below are confirmed but you can make changes if you\'d like.'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Allow Clients to pay invoices with:'">
        </tidy-text>
      </tidy-row>

      <tidy-card *ngFor="let option of paymentOptions">
        <tidy-card-button (action)="goToEditPaymentOption(option)" [ngClass]="(option?.notes || option?.third_party_account_name) ? 'arrow-to-top' : ''">
          <tidy-row>
            <tidy-text
              [header]="option?.team_payment_option_type?.name + ((option?.team_payment_option_type?.name == 'Card' && !allowsCardPayments) ? '*' : '')">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding" *ngIf="option?.third_party_account_name">
            <tidy-text
              [body]="option?.third_party_account_name">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="option?.notes">
            <tidy-text
              [body]="option?.notes">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="option?.team_payment_option_type?.name == 'Card' && !allowsCardPayments">
            <tidy-text
              [body]="'*Contact concierge@tidy.com or tap the concierge tab to request access to credit card processing. We will be happy to help enable this on your account as soon as possible'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-button
        [action]="addNewOption.bind(this)"
        [text]="'Add Payment Option'"
        class="primary">
      </tidy-button>

    </ng-container>

    <ng-container *ngIf="!loaded">

      <tidy-row>
        <ion-skeleton-text animated style="width: 65%; margin: auto"></ion-skeleton-text>
      </tidy-row>
      <tidy-card>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated style="width: 18%"></ion-skeleton-text>
         </tidy-row>
        </tidy-card-button>
      </tidy-card>
      <tidy-card>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated style="width: 18%"></ion-skeleton-text>
         </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 18%"></ion-skeleton-text>
       </tidy-row>
       <tidy-row>
         <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
         <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
      </tidy-row>
        </tidy-card-button>
      </tidy-card>
      <tidy-card>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated style="width: 18%"></ion-skeleton-text>
         </tidy-row>
        </tidy-card-button>
      </tidy-card>
      <tidy-card>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated style="width: 18%"></ion-skeleton-text>
         </tidy-row>
        </tidy-card-button>
      </tidy-card>
      <tidy-card>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated style="width: 18%"></ion-skeleton-text>
         </tidy-row>
        </tidy-card-button>
      </tidy-card>
      <tidy-row>
        <ion-skeleton-text animated style="height: 70px"></ion-skeleton-text>
      </tidy-row>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper><ng-template #content>
