import { CommonUtils } from './../../shared/utils/common-utils';
import { Homekeepers } from './../../providers/homekeepers/homekeepers';
import { Aws } from './../../shared/providers/aws';
import { Camera } from './../../shared/providers/camera/camera';
import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';

import { AppReview } from 'src/providers/app-review/app-review';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { AppState } from 'src/shared/providers/http/app-state';
import { OnRails } from 'src/providers/on-rails/on-rails';

import { SuccessPageParamsModel } from 'src/pages/success/success';
import { CheckLocationPermission } from 'src/behaviors/check-location-permission';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { ActivatedRoute, Router } from '@angular/router';
import { IdentificationCheck } from 'src/shared/providers/identification-check';
import { Subscription, timer } from 'rxjs'
import { tap } from 'rxjs/operators';
import { beforeAfterOptions } from 'src/shared/constants/camera';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { DateTime } from 'luxon';
import { Me } from 'src/shared/providers/me';
import { Concierge } from 'src/providers/concierge/concierge';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

const IDENTIFICATION_CHECK_STATUSES = {
  0: 'requires_input',
  1: 'processing',
  2: 'verified',
}

@Component({
  templateUrl: 'check-in.html',
  styleUrls: ['./check-in.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CheckInPage extends Timeout implements OnDestroy {

  todayCleanings: any;
  errorMessage: any;
  isOnIdentificationCheck: boolean;
  isOnFaceUnlock: boolean;
  progressBars = [
    {
      mode: 'determinate',
      value: 100,
    },
    {
      mode: 'determinate',
      value: 0,
    },
    {
      mode: 'determinate',
      value: 0,
    },
  ];
  identificationSteps = [
    {
      icon: 'assets/icon/id.png',
      title: 'These jobs require a quick identification check.',
      subtitle: 'All you need is your drivers license.',
      displayButton: true,
      buttonText: 'Get Verified',
      action: () => this.getVerified(),
    },
    {
      icon: 'assets/icon/identify.png',
      title: 'Identification in progress',
      subtitle: 'This typically takes less than 1 minute.',
      displayButton: false,
      buttonText: '',
      action: () => {},
    },
    {
      icon: 'assets/icon/verify.png',
      title: 'Verification Completed!',
      subtitle: 'Your identity has been succesfully verified.',
      displayButton: true,
      buttonText: 'Go to Check In',
      action: () => this.goToCheckIn(),
    }
  ];
  currentIdentificationStep = this.identificationSteps[0];
  verificationUrl = '';
  verificationSubscription = new Subscription();
  identificationError: any;

  constructor(
    private appReview: AppReview,
    private checkLocationPermission: CheckLocationPermission,
    private customNavCtrl: CustomNavController,
    private onRails: OnRails,
    private appState: AppState,
    private identificationCheck: IdentificationCheck,
    private camera: Camera,
    private storage: TidyStorage,
    private aws: Aws,
    private homekeeper: Homekeepers,
    private me: Me,
    private utils: CommonUtils,
    private concierge: Concierge,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
  }

  async ionViewDidEnter() {
    this.loaded = false;
    this.alreadyShowedOfflineOrTimeoutAlert = false;

    if (!await this.appState.isOnline()) {
      this.alreadyShowedOfflineOrTimeoutAlert = true;
      return this.timeoutErrorHandler.showOfflineAlert(this.successPageLoad, 'dashboard', this.retrySubject);
    }
    this.todayCleanings = await this.onRails.getTodayCleaningsToCheckin()
      .catch(err => this.timeoutHandler(err));
    if (this.todayCleanings.length === 0) {
      this.customNavCtrl.navigateForward('jobs');
      return;
    }
    await this.handleIdentificationCheck();

    this.successPageLoad.emit(true);
    this.loaded = true;
  }

  ngOnDestroy() {
    this.verificationSubscription?.unsubscribe();
  }

  async handleIdentificationCheck(): Promise<void> {
    this.resetProgressBar();
    const identificationCheck = await this.getIdentificationCheck();
    this.isOnIdentificationCheck = identificationCheck?.status !== IDENTIFICATION_CHECK_STATUSES[2];
    if (this.isOnIdentificationCheck) {
      this.currentIdentificationStep = (this.identificationError || identificationCheck?.status === IDENTIFICATION_CHECK_STATUSES[1]) ? this.identificationSteps[1] : this.identificationSteps[0];
      if (this.currentIdentificationStep === this.identificationSteps[1]) {
        this.progressBars[1] = {
          mode: 'indeterminate',
          value: 0,
        }
        this.startVerificationCheck();
      }
      return;
    }
    this.handleFaceUnlock();
  }

  handleFaceUnlock(): void {
    const hasAtLeastOneTidyJob = this.todayCleanings.some((cleaning) => cleaning?.job?.type?.toLowerCase()?.includes('tidy'));
    if (!hasAtLeastOneTidyJob) {
      this.goToCheckIn();
      return;
    }
    this.isOnFaceUnlock = true;
    this.identificationSteps = [
      {
        icon: 'assets/icon/face-auth.png',
        title: 'Face Unlock for Jobs.',
        subtitle: 'Unlock your jobs by taking a quick selfie.',
        displayButton: true,
        buttonText: 'Take Selfie',
        action: () => this.takeSelfie(),
      },
      {
        icon: 'assets/icon/identify.png',
        title: 'Identification in progress',
        subtitle: 'This typically takes less than 1 minute.',
        displayButton: false,
        buttonText: '',
        action: () => {},
      },
      {
        icon: 'assets/icon/verify.png',
        title: 'Verification Completed!',
        subtitle: 'Your identity has been succesfully verified.',
        displayButton: true,
        buttonText: 'Go to Check In',
        action: () => this.goToCheckIn(),
      }
    ];
    this.currentIdentificationStep = this.identificationSteps[0];
  }

  async takeSelfie(): Promise<void> {
    this.currentIdentificationStep = this.identificationSteps[1];
    this.progressBars[1] = {
      mode: 'indeterminate',
      value: 0,
    }
      let me = await this.storage.retrieve('me');
      if (!me) {
        me = await this.me.load();
        this.storage.save('me', me);
      }
      this.camera.getPhoto(beforeAfterOptions).then(async (cameraPhoto) => {
        try {
          if (!cameraPhoto?.base64String) {
            this.handleErrorOnSelfie();
            return;
          }
          const date = DateTime.local().toFormat('yyyy-MM-dd');
          const objKey = `homekeepers/${me.user.id}/face-scans/${date}`;
          const s3Object = await this.aws.uploadImageToS3(cameraPhoto.base64String, objKey);
          const payload = {
            photo_url: s3Object.Location,
            type: 'photo',
            desc: `${new TranslationPipe().transform('Face scan for pro')} ${me.user.id} ${new TranslationPipe().transform('at')} ${date}`
          }
          await this.homekeeper.createFaceScan(payload);
          this.finishVerificationCheck()
        } catch (error) {
          console.error(error);
          this.handleErrorOnSelfie();
        }
      });
  }

  handleErrorOnSelfie(): void {
    this.resetProgressBar();
    this.currentIdentificationStep = this.identificationSteps[0];
  }

  resetProgressBar(): void {
    this.progressBars[1] = {
      mode: 'determinate',
      value: 0,
    }
    this.progressBars[2] = {
      mode: 'determinate',
      value: 0,
    }
  }

  goToVerificationPage(): void {
    window.open(this.verificationUrl, '_blank');
  }

  getVerified(): void {
    this.startVerificationCheck();
    this.goToVerificationPage();
    this.currentIdentificationStep = this.identificationSteps[1];
    this.progressBars[1] = {
      mode: 'indeterminate',
      value: 0,
    }
  }

  async startVerificationCheck(): Promise<void> {
    const fifteenSeconds = 15000;
    this.verificationSubscription.add(timer(0, fifteenSeconds)
      .pipe(
        tap(async () => {
          const identificationCheck = await this.getIdentificationCheck()
          if (identificationCheck.status === IDENTIFICATION_CHECK_STATUSES[2]) {
            this.finishVerificationCheck();
            this.verificationSubscription.unsubscribe();
          }
        })
      )
      .subscribe());
  }

  finishVerificationCheck(): void {
    this.currentIdentificationStep = this.identificationSteps[2];
      this.progressBars[1] = {
        mode: 'determinate',
        value: 100,
      }
      this.progressBars[2] = {
        mode: 'determinate',
        value: 100,
      }
  }

  async getIdentificationCheck(): Promise<any> {
    const identificationCheck = await this.identificationCheck.getIdentityCheck();
    this.verificationUrl = identificationCheck?.redirect_url;
    this.identificationError = identificationCheck?.last_error_message;
    console.log(identificationCheck);
    return identificationCheck;
  }

  goToCheckIn(): void {
    this.isOnIdentificationCheck = false;
    this.isOnFaceUnlock = false;
  }

  async confirm() {
    const locationCheck = await this.checkLocationPermission.requestLocationPermission();

    try {
      await this.onRails.confirmCleanings(this.extractJobIds());
      const params = this.successParams(locationCheck.success);
      this.customNavCtrl.navigateForward('success', params);
    } catch (err) {
      if (!await this.appState.isOnline()) {
        this.errorMessage = 'You Must Be Online To Check In';
      } else {
        this.errorMessage = err.message || err.error.message;
      }
    }
  }

  extractJobIds(): number[] {
    return this.todayCleanings.map( item => item.job.id );
  }

  callOut = () => {
    this.customNavCtrl.navigateForward('check-in-learn-about-teams', {cleanings: this.todayCleanings});
  }

  successParams(successLocation: boolean): SuccessPageParamsModel {
    return {
      header: 'Checked in for Work',
      body: 'Remember: Clients usually book based on your retention score.\
      The best thing you can do to get more Clients is to keep existing Clients.<br><br>\
      Clients tell us the most important reasons they will book with you again are:<br><br>\
      1) Keep your appointments<br>\
      2) Work hard the full job time<br>\
      3) Maintain a positive Attitude',
      buttonText: 'Ok',
      buttonRoute: successLocation ? 'dashboard' : 'location-warning'
    };
  }

  async reportFaceUnlockIssues(): Promise<void> {
    const loading = await this.utils.showLoading();
    const payload = {
      type: 'support.homekeeper_other',
    	data: {
        subject: 'Face Unlock Issues',
    		body: 'I am not able to take a photo of my face to unlock my jobs.',
        emails: [],
        attachments: [],
    	}
    };
    try {
      await this.concierge.addConciergeItem(payload);
      loading.dismiss();
      this.utils.showSuccess('Your issue has been reported. We will get back to you shortly.');
      this.goToCheckIn();
    } catch (err) {
      console.error(err);
      loading.dismiss();
      this.utils.showError('There was an error reporting your issue. Please try again later.');
    }
  }
}
