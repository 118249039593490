<ion-header>
  <ion-toolbar black hideBackButton="true"></ion-toolbar>
</ion-header>

<ng-template #content align-self-center class="report-issue-confirmation" padding>

  <ion-card class="tidy-cleaning-navbar">
    <ion-card-content class="center-top">
      <tidy-text
        [body]="'You haven\'t updated your app in a while. Please update immediately as app versions are only supported for a limited time.'">
      </tidy-text>
    </ion-card-content>
  </ion-card>

  <ion-button (click)="close()" expand="block" color="tidy_black">Close</ion-button>

</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
