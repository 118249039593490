import { Component } from '@angular/core';

import { MWStore } from "src/main-workflow/mw.store";
import { MWService } from "src/main-workflow/mw.service";
import { MWJobModel, MWTodosModel } from 'src/main-workflow/mw.models';
import { mwMoments } from 'src/main-workflow/mw.moments';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { Loading } from 'src/shared/components/loading/loading';

import { SuccessPageParamsModel } from 'src/pages/success/success';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';
import { CustomFieldsService } from 'src/shared/providers/custom-fields/custom-fields';

@Component({
  templateUrl: 'mw.on-the-way.page.html'
})

export class OnTheWayPage {

  errorMessage: string;
  job: MWJobModel;
  loaded: boolean;
  toDos: MWTodosModel;
  previousGuestReservationDetails: any;
  nextGuestReservationDetails: any;
  customFields: any;

  constructor(
    private iabUtils: InAppBrowserUtils,
    private mwStore: MWStore,
    public mwService: MWService,
    private navCtrl: CustomNavController,
    private customFieldsService: CustomFieldsService
  ) {}

  async ionViewDidEnter() {
    this.loaded = false;
    this.job = await this.mwStore.getJob();
    if (this.job?.guest_reservation_data?.previous_guest_reservation?.details) {
      this.previousGuestReservationDetails = {
        ...this.job?.guest_reservation_data?.previous_guest_reservation?.details,
        customFields: this.customFieldsService.getCustomFieldsForDetails(this.customFields, this.job?.guest_reservation_data?.previous_guest_reservation?.custom_fields)
      }
    }
    if (this.job?.guest_reservation_data?.next_guest_reservation?.details) {
      this.nextGuestReservationDetails = {
        ...this.job?.guest_reservation_data?.next_guest_reservation?.details,
        customFields: this.customFieldsService.getCustomFieldsForDetails(this.customFields, this.job?.guest_reservation_data?.next_guest_reservation?.custom_fields)
      }
    }
    this.loaded = true;
  }

  async getCustomFields() {
    try {
      this.customFields = await this.customFieldsService.getCustomFields(this.job?.guest_reservation_data?.booking?.id, 'GuestReservation');
    } catch (err) {
      console.error(err);
    }
  }

  @Loading()
  async notifyArrived() {
    this.errorMessage = null;
    try {
      const job = await this.mwService.getJob(this.job.id);
      if (this.job?.guest_reservation_data?.previous_guest_reservation?.details) {
        this.previousGuestReservationDetails = {
          ...this.job?.guest_reservation_data?.previous_guest_reservation?.details,
          customFields: this.customFieldsService.getCustomFieldsForDetails(this.customFields, this.job?.guest_reservation_data?.previous_guest_reservation?.custom_fields)
        }
      }
      if (this.job?.guest_reservation_data?.next_guest_reservation?.details) {
        this.nextGuestReservationDetails = {
          ...this.job?.guest_reservation_data?.next_guest_reservation?.details,
          customFields: this.customFieldsService.getCustomFieldsForDetails(this.customFields, this.job?.guest_reservation_data?.next_guest_reservation?.custom_fields)
        }
      }
      this.mwStore.setJob(job);
      if (await this.mwService.isTooLateForJob(job.endTime)) {
        return this.mwService.showTooLateAlert();
      }
      if (job.cancelled) {
        return this.mwService.showJobCancelledAlert(true);
      }
      if (job.isStandbyCancelled) {
        return this.mwService.showStandbyCancelledAlert(true);
      }
      if (job.inactive) {
        return this.mwService.showInactiveJobAlert(true);
      }
      if (job.isStandby && this.mwService.isTooEarlyForJobStandby(job.startTime)) {
        this.mwService.sendMomentToBackend(mwMoments.tooEarlyArrivedStandby, 'on-the-way', job.id);
        return this.showTooEarlyStandbyAlert();
      }
      await this.addToDosToStore();
      await this.addPhotosToCache();
      await this.addEligibleForPlanToStore();
      this.mwService.sendMomentToBackend(mwMoments.arrivedAtJob, 'on-the-way', job.id);
      this.mwStore.setRoute('arrived-at-job');
      this.navCtrl.navigateForward('arrived-at-job');
    } catch (err) {
      const message = await this.mwService.buildErrorAlert(err);
      this.showErrorMessage(message);
    }
  }

  async addToDosToStore() {
    this.toDos = await this.mwService.getToDos();
    await this.mwStore.setToDos(this.toDos);
  }

  async addPhotosToCache() {
    await this.mwService.fetchTodosPhotoNotes(this.toDos);
    await this.mwService.fetchAddressPhotoNotes(this.job);
  }

  async addEligibleForPlanToStore() {
    const eligibleForPlan = await this.mwService.getEligibleForPlan(this.job.planId);
    await this.mwStore.setEligibleForPlan(eligibleForPlan);
  }

  showTooEarlyStandbyAlert() {
    const successParams: SuccessPageParamsModel = {
      header: 'Too Early for Action',
      body: 'You are on standby for this job.  You cannot do this action until 5 minutes prior to the job for standby jobs.',
      buttonText: 'Ok',
      buttonRoute: 'on-the-way'
    };
    this.navCtrl.navigateForward('success', successParams);
  }

  learnMoreStandby() {
    this.iabUtils.openUrl('https://help.tidy.com/pros/how-to-get-clients-from-tidy#select-clients-who-need-you');
  }

  showErrorMessage(message) {
    this.errorMessage = message;
    setTimeout(() => this.errorMessage = null, 10000);
  }

  goToContactPage() {
    if (this.job.isStandby && this.mwService.isTooEarlyForJobStandby(this.job.startTime)) {
      return this.showTooEarlyStandbyAlert();
    }
    this.navCtrl.navigateForward('job-messages');
  }

  goToClientRefusedServicePage() {
    if (this.job.isStandby && this.mwService.isTooEarlyForJobStandby(this.job.startTime)) {
      return this.showTooEarlyStandbyAlert();
    }
    this.navCtrl.navigateForward('client-refused-service');
  }

  goToExitJobPage() {
    this.navCtrl.navigateForward('exit-job');
  }

  goToCallOutPage() {
    this.navCtrl.navigateForward('cancel-job');
  }
}
