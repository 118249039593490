import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { MWStore } from "src/main-workflow/mw.store";
import { MWService } from "src/main-workflow/mw.service";
import { MWJobModel } from 'src/main-workflow/mw.models';
import { mwMoments } from 'src/main-workflow/mw.moments';
import cleaningTypeUtils from 'src/shared/utils/cleaning-type-utils';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: 'mw.finish-early.page.html'
})

export class FinishEarlyPage {

  job: MWJobModel;
  jobTimer: any;
  startTime: any;
  loaded: boolean;
  toldToLeave: boolean;
  duration: any;

  constructor(
    private navCtrl: CustomNavController,
    private modalCtrl: ModalController,
    private mwStore: MWStore,
    private mwService: MWService
  ) {}

  async ionViewDidEnter() {
    this.loaded = false;
    this.jobTimer = await this.mwStore.getTimer();
    this.job = await this.mwStore.getJob();
    this.startTime = await this.mwStore.getStartTime();
    this.loaded = true;
  }

  async goToFinishJob() {
    const syncData = await this.mwStore.getSyncData();
    syncData.data.leftEarly = true;
    await this.mwStore.setSyncData(syncData);
    if (this.mwService.isTooLateForJob(this.job.endTime)) {
      return this.mwService.showTooLateAlert();
    }
    if (await this.mwService.isFarFromHome()) {
      await this.mwService.addMomentToSyncData(mwMoments.finishedToDosFarFromHome, 'to-dos', this.job.id);
      this.navCtrl.navigateForward('finish-far-from-client');
      return this.modalCtrl.dismiss();
    }
    await this.mwStore.setRoute('finish-job');
    this.navCtrl.navigateForward('finish-job');
    this.modalCtrl.dismiss();
  }

  async cantStay() {
    const params: ConfirmPageParamsModel = {
      title: 'Tell Client I Can\'t Stay',
      body: `Please confirm that you are leaving early because you can\'t stay.`,
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: await this.confirmCantStay.bind(this),
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });
    await confirmationModal.present();
  }

  async confirmCantStay() {
    await this.mwService.addMomentToSyncData(mwMoments.finishedToDosEarlyCantStay, 'to-dos', this.job.id);
    this.goToFinishJob();
  }

  async clientToldToLeave() {
    this.toldToLeave = true;
  }

  async theyWereUnhappy() {
    const params: ConfirmPageParamsModel = {
      title: 'Client Asked You to Leave',
      body: `If the client is unhappy with your work and asks you to leave early they might dispute the job. This is rare.`,
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: await this.goToFinishJob.bind(this),
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });
    await confirmationModal.present();
  }

  async confirmTheyWereUnhappy() {
    await this.mwService.addMomentToSyncData(mwMoments.finishedTodosEarlyClientUnhappy, 'to-dos', this.job.id);
    this.goToFinishJob();
  }

  getHourString(unit){
    return unit == 1? new TranslationPipe().transform('hour') : new TranslationPipe().transform('hours');
  }

  async theyDidNotWant() {
    const clientName = `${this.job.client} ${this.job.last_name[0]}.`
    var duration = cleaningTypeUtils.getCleaningDuration(this.job?.type);
    const durationString = this.getHourString(duration);
    var distance = (new Date().getTime()) - (new Date(this.startTime).getTime());
    var hoursTimer = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const hoursTimerString = this.getHourString(hoursTimer);
    var minutesTimer = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

    const params: ConfirmPageParamsModel = {
      title: 'Client Didn\'t Want Full Job Time',
      body: `${clientName} ${new TranslationPipe().transform('paid for')} ${duration} ${durationString} ${new TranslationPipe().transform('and you started the job')} ${hoursTimer} ${hoursTimerString} ${minutesTimer} ${new TranslationPipe().transform('minutes ago')}
      <br><br>
      ${new TranslationPipe().transform('The client can still dispute the job. Leaving early, or asking to leave early, is always a risk. Since clients pay for a specific length of time, it is rare for them to authorize early departures. `Top complaints are leaving early or requesting to leave early')}.
      <br><br>
      ${new TranslationPipe().transform('Manage your clients however you like, just be aware of the risks of leaving early.')}`,
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: await this.goToFinishJob.bind(this),
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });
    await confirmationModal.present();
  }

  async confirmTheyDidNotWant() {
    await this.mwService.addMomentToSyncData(mwMoments.finishedTodosEarlyClientHappy, 'to-dos', this.job.id);
    this.goToFinishJob();
  }

  goBack() {
    this.navCtrl.back();
  }
}
