import { Injectable } from '@angular/core';

import { AppConfig } from 'src/shared/providers/config';
import { Events } from 'src/providers/events/events';
import { Logger } from 'src/shared/providers/logger';
import { HttpClient } from 'src/shared/providers/http/http-client';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { PositionModel } from 'src/shared/models/position.model';

import { Geolocation, PositionOptions } from '@capacitor/geolocation';

import * as geolib from 'geolib';
import { DateTime as LuxonDateTime } from 'luxon';
declare const google: any;
declare const window: any;

interface DistanceToData {
  distance: {
    text?: string,
    value?: number
  },
  duration: {
    text?: string,
    value?: number,
    withMargin?: string
  }
};

const defaultOptions: PositionOptions = {
  enableHighAccuracy: true,
  timeout: 10000,
  maximumAge: 50000
};

@Injectable({
  providedIn: 'root'
})

export class Location {

  constructor(
    private events: Events,
    private httpClient: HttpClient,
    private logger: Logger,
    private storage: TidyStorage
  ) {
    this.get(true);
    setInterval(() => {
      this.get(true);
    }, 60000);
  }

  public async get(trueLocation: boolean = false): Promise<PositionModel> {
    try {
      const locationPermission = await Geolocation.checkPermissions();
      if (locationPermission.location !== 'granted') {
        return this.returnNoLocation({message: 'Location permission not granted'});
      } else {
        return await new Promise<PositionModel>(async (resolve, reject) => {
          const failTimeout = setTimeout(() => {
            reject({});
          }, 15000);
          let location = await this.storage.retrieve('location');
          const secondsSinceLastCache = await this.getSecondsSinceLastCache();
          if (trueLocation || !location || secondsSinceLastCache > 180) {
            location = await this.getLocationFromCapacitor(defaultOptions);
            await this.storage.save('locationTimeStamp', LuxonDateTime.now());
            await this.storage.save('location', location);
          }
          resolve(location);
        });
      }
    } catch(err) {
      return this.getLowAccuracy();
    }
  }

  public async getLowAccuracy(): Promise<PositionModel> {
    try {
      return await new Promise<PositionModel>(async (resolve, reject) => {
        const failTimeout = setTimeout(() => {
          reject({message: 'fail safe timeout triggered'});
        }, 15000);
        const options = {
          enableHighAccuracy: false,
          timeout: 10000,
          maximumAge: 50000
        };
        const location = await this.getLocationFromCapacitor(options);
        await this.storage.save('locationTimeStamp', LuxonDateTime.now());
        await this.storage.save('location', location);
        resolve(location);
      });
    } catch(err) {
      return this.returnNoLocation(err);
    }
  }

  async getLocationFromCapacitor(options) {
    const currentLocation = await Geolocation.getCurrentPosition(options);
    return {
      latitude: currentLocation.coords.latitude,
      longitude: currentLocation.coords.longitude
    };
  }

  async getSecondsSinceLastCache() {
    const locationTimeStamp = await this.storage.retrieve('locationTimeStamp');
    const luxonLocationTimeStamp = LuxonDateTime.fromISO(locationTimeStamp);
    const luxonCurrentTime = LuxonDateTime.now();
    const timeSinceLastCache = luxonCurrentTime.diff(luxonLocationTimeStamp, ["seconds"]);
    const timeSinceLastCacheObject = timeSinceLastCache.toObject();
    return timeSinceLastCacheObject.seconds;
  }

  private async returnNoLocation(err) {
    const error = err.message ? err.message : 'Error acquiring user location';
    this.logger.error(err, `Get position error: ${error}`);
    const location: any = {
      latitude: 0,
      longitude: 0
    }
    await this.storage.save('locationTimeStamp', LuxonDateTime.now());
    await this.storage.save('location', location);
    location['error'] = error;
    return location;
  }

  public async distanceTo(address: string): Promise<DistanceToData> {
    try {
      const position = await this.get();
      const data = await this.fetchDistanceFromGoogle(position, address);
      return this.parseGoogleData(data);
    } catch (err) {
      this.logger.error(err);
      return { distance: {}, duration: {} };
    }
  }

  public kmDistanceBetween(source: PositionModel, destiny: PositionModel): number {
    const metersDistance = geolib.getDistance(source, destiny);
    return geolib.convertDistance(metersDistance, 'km');
  }

  public loadIsochroneMapbox(profile, longitude, latitude, minutesDistance) {
    const base = 'https://api.mapbox.com/isochrone/v1/mapbox';
    const coordinates = `${longitude},${latitude}`;
    const accessToken = AppConfig.MAPBOX_KEY;
    // Mapbox documentation allows a maximum value of 60min
    const countorMinutes = minutesDistance > 60 ? 60 : minutesDistance;
    const url = `${base}/${profile}/${coordinates}.json?contours_minutes=${countorMinutes}&access_token=${accessToken}`;

    return this.httpClient.get(url);
  }

  private fetchDistanceFromGoogle(position, destiny): Promise<google.maps.DistanceMatrixResponse> {
    return new Promise( resolve => {
      new google.maps.DistanceMatrixService().getDistanceMatrix({
        origins: [`${position.latitude},${position.longitude}`],
        destinations: [destiny],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.IMPERIAL
      }, resolve);
    });
  }

  private parseGoogleData(data: google.maps.DistanceMatrixResponse): DistanceToData {
    const result = data?.rows?.at(0)?.elements?.at(0);

    if (!result || result.status !== 'OK') {
      return { distance: {}, duration: {} };
    }

    return {
      distance: result.distance,
      duration: {
        text: result.duration.text,
        value: result.duration.value,
        withMargin: this.calculateDurationWithTolerance(result.duration.value)
      }
    };
  }

  private calculateDurationWithTolerance(seconds: number): string {
    const minutesWithTolerance = Math.ceil( (seconds / 60) * 1.1);

    const minutesText = minutesWithTolerance > 1 ? minutesWithTolerance + ' minutes' : minutesWithTolerance + ' minute';

    return minutesText;
  }

  throwErrorMessage() {
    const errorMsg = `We aren't getting your location!  Its important for clients to get accurate updates during cleanings.  Please check your settings.`;
    this.events.publish('message:show', {messageStr: errorMsg, styleClass: 'error'});
  }
  
}
