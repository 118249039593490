import { Injectable } from '@angular/core';
import { HttpClient } from 'src/shared/providers/http/http-client';

import { FilePicker } from 'src/shared/providers/file-picker/file-picker';
import { Aws } from 'src/shared/providers/aws';

import { AttachmentModel } from 'src/models/concierge.model';

import { generateUuid } from '../tidy-session/session-uuid';

@Injectable()
export class ReportIssue {

  constructor(
    private aws: Aws,
    private filePicker: FilePicker,
    private httpClient: HttpClient
  ) {}

  getEligibleTypes() {
    const url = `api/v1/homekeeper/issue-report-types`;
    return this.httpClient.get(url);
  }

  getReportedIssues(homekeeperJobIds) {
    const url = `api/v1/homekeeper/issue-reports?filters[homekeeper_job_ids]=${homekeeperJobIds}`;
    return this.httpClient.get(url);
  }

  reportIssue(data) {
    const url = 'api/v1/homekeeper/issue-reports';
    return this.httpClient.post(url, data);
  }

  async addAttachment(): Promise<AttachmentModel> {
    const uuid = generateUuid();
    const file = await this.filePicker.pickFile();
    const fileKey = `report-issue/${uuid}/${file.filename}`;
    const imagesAllowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (imagesAllowedTypes.includes(file.format)) {
      await this.aws.uploadFileToS3(file.dataUrl, fileKey, file.format);
      return {
        filename: file.filename,
        fileKey
      };
    } else {
      return {
        filename: '',
        fileKey: ''
      };
    }
  }

}
