import { AddressModel } from './address.model';
import { ClientModel } from './client.model';
import { PrivateJobModel } from './private-job.model';

export interface PhotoNoteModel {
  id: number;
  type: string;
  photo_url: string;
  addressable_id: number;
  photo_note_caption: string;
}

export interface TaskModel {
  id: number;
  title: string;
  note: string;
  important: boolean;
  task_end_time?: string;
  performance?: string;
  photo_notes: PhotoNoteModel[];
  assigned_job_task: any;
  address_task: any;
  customFieldForm: any;
  options: any;
}

export interface DoNotModel {
  id: number;
  body: string;
  category: string;
}

export interface TodosModel {
  id: number;
  name: string;
  category: string;
  complete?: boolean;
  doneTodos?: number;
  tasks: TaskModel[];
  do_nots?: DoNotModel[];
  todos: any;
  type: string;
  before_after_photos: any;
  is_do_not_clean_room?: boolean;
}

export interface JobDetailsModel {
  address: AddressModel;
  customer: ClientModel;
  job: PrivateJobModel;
  todos: TodosModel[];
  homekeeper_job: any;
  service_type_details: {
    name: string;
    description: string;
    key: string;
    category: {
      name: string;
    }
    service_category: any;
  }
  remote_access_information: {
    pin: string;
  }
  booking: any;
  guest_reservation_data: any;
  booking_form_answers: any;
}

export interface ActivePrivateJob {
  jobId: string;
  todos: TodosModel[],
  beforeAfterPhotos?: PrivateJobBeforeAfterPhoto[];
}

export interface PrivateJobBeforeAfterPhoto {
  roomId: number;
  roomName: string;
  category: string;
  before: string | null;
  after: string | null;
};
