<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Notification Settings'">
  </tidy-top-nav>
</ng-template>

<ng-container
  *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container
      *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text [body]="'Customize notifications the way you want.'">
      </tidy-text>
    </tidy-row>

    <ng-container *ngIf="!loaded">
      <ng-container *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
        <tidy-row>
          <tidy-card>
            <tidy-row>
              <ion-skeleton-text
                [animated]="true"
                [style]="{ width: '100%', height: '22px', borderRadius: '8px' }"></ion-skeleton-text>
            </tidy-row>
            <tidy-row>
              <ion-skeleton-text
                [animated]="true"
                [style]="{ width: '100%', height: '48px', borderRadius: '8px' }"></ion-skeleton-text>
            </tidy-row>
            <tidy-row>
              <ion-skeleton-text
                [animated]="true"
                [style]="{ width: '100%', height: '48px', borderRadius: '8px' }"></ion-skeleton-text>
            </tidy-row>
          </tidy-card>
        </tidy-row>
      </ng-container>
    </ng-container>

    <tidy-row>
      <form [formGroup]="searchForm" novalidate action="#">
        <tidy-search-input
          formControlName="search"
          [placeholder]="'Search'"
          [icon]="'assets/svg/search-outline.svg'">
        </tidy-search-input>
      </form>
    </tidy-row>

    <tidy-row style="margin-top: 1rem">
      <tidy-text [body]="savingText"> </tidy-text>
    </tidy-row>

    <ng-container
      *ngFor="let notification of filteredNotificationsSettings; let i = index">
      <tidy-row class="no-padding">
        <tidy-card>
          <tidy-row>
            <tidy-text
              [body]="notification.title">
            </tidy-text>
          </tidy-row>
          <ion-accordion-group>
            <ng-container *ngFor="let channel of notification.channels">
              <ion-accordion class="custom-accordion">
                <ion-item slot="header" color="light" (click)="openChannelSettings(channel)">
                  <tidy-checkbox
                    (click)="toggleChannel($event, channel, notification)"
                    class="custom-checkbox"
                    [(checked)]="channel.checked"
                    [initValue]="channel.checked"
                    [watchOnChange]="true"
                    [items]="[{viewValue: '', value: ''}]">
                  </tidy-checkbox>
                  <tidy-text
                    [body]="channel.name | titlecase"
                    style="margin-left: 10px">
                  </tidy-text>
                </ion-item>
                <div slot="content">
                  <ng-container *ngIf="notification.allowsTimeRangeConfig">
                    <ng-container *ngIf="channel.isOpened">
                      <ion-accordion-group>
                        <ng-container
                          *ngFor="let formGrp of channel.timeRangeForms; let timeRangeIndex = index">
                          <ion-accordion [disabled]="!channel.checked">
                            <ion-item
                              slot="header"
                              (click)="onAccordionChange(i, timeRangeIndex)">
                              <tidy-checkbox
                                (click)="toggleDay($event, notification, channel, formGrp)"
                                class="custom-checkbox"
                                [(checked)]="formGrp.dayForm.controls.checked.value"
                                [initValue]="formGrp.dayForm.controls.checked.value"
                                [watchOnChange]="true"
                                [items]="[{viewValue: '', value: ''}]">
                              </tidy-checkbox>
                              <tidy-text
                                [body]="formGrp.dayForm.controls.day.value  | titlecase"
                                style="margin-left: 10px">
                              </tidy-text>
                            </ion-item>
                            <ng-container
                              *ngIf="expandedIndex === i + '-' + timeRangeIndex">
                              <div class="ion-padding" slot="content">
                                <ng-container
                                  *ngFor="let control of formGrp.timeRangeForm.controls; let last = last; let controlIndex = index">
                                  <tidy-two-column-row
                                    [formGroup]="control"
                                    style="padding-bottom: 1rem">
                                    <tidy-select
                                      style="max-width: 40%; margin: 0"
                                      [icon]="'assets/svg/time-outline.svg'"
                                      [label]="'Earliest'"
                                      [form]="control.controls.start_time"
                                      formControlName="start_time"
                                      errorMessage="Select a valid time"
                                      [submitted]="true"
                                      (optionChange)="updateValidity()"
                                      [items]="timeOpts">
                                    </tidy-select>
                                    <span
                                      style="
                                        align-self: center;
                                        justify-content: space-around;
                                        max-width: 10%;
                                      "
                                      >-</span
                                    >
                                    <tidy-select
                                      style="max-width: 40%; margin-right: 10px"
                                      [icon]="'assets/svg/time-outline.svg'"
                                      [label]="'Latest'"
                                      [form]="control.controls.end_time"
                                      formControlName="end_time"
                                      errorMessage="Select a valid time"
                                      [submitted]="true"
                                      (optionChange)="updateValidity()"
                                      [items]="endTimes(control.controls.start_time.value)">
                                    </tidy-select>
                                    <tidy-image
                                      (action)="removeTimeRange(formGrp.timeRangeForm, controlIndex)"
                                      src="assets/img/trash-icon-outline.svg"
                                      class="icon body-size"
                                      style="
                                        max-width: 16px;
                                        align-self: center;
                                      "></tidy-image>
                                  </tidy-two-column-row>
                                </ng-container>
                                <tidy-row>
                                  <tidy-text
                                    [body]="'+ Add Time Restriction'"
                                    (action)="addTimeRange(formGrp.timeRangeForm)"
                                    class="link">
                                  </tidy-text>
                                </tidy-row>
                              </div>
                            </ng-container>
                          </ion-accordion>
                        </ng-container>
                      </ion-accordion-group>
                    </ng-container>
                  </ng-container>
                </div>
              </ion-accordion>
            </ng-container>
          </ion-accordion-group>
        </tidy-card>
      </tidy-row>
    </ng-container>

    <ion-infinite-scroll (ionInfinite)="loadMore($event)">
      <ion-infinite-scroll-content
        loadingSpinner="bubbles"
        loadingText="Loading more settings...">
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
