import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTime as LuxonDateTime } from 'luxon';
import * as moment from 'moment';
import { Communication } from 'src/providers/communication/communication';
import { Cleanings } from 'src/providers/cleanings/cleanings';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Me } from 'src/shared/providers/me';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

import { Loading } from 'src/shared/components/loading/loading';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MWService } from 'src/main-workflow/mw.service';

@Component({
  templateUrl: 'past-job-messages.html'
})
export class PastJobMessagesPage extends Timeout {

  errorMessage: string;
  job: any;
  messages: any;
  submitted: boolean;
  form: UntypedFormGroup;

  constructor(
    public communication: Communication,
    private cleanings: Cleanings,
    private me: Me,
    private navCtrl: CustomNavController,
    public route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private mwService: MWService,
    router: Router,
    timeoutErrorHandler: TimeoutErrorHandler,
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.fb.group({
      message: ['', Validators.required],
    });
  }

  @Loading()
  async ionViewDidEnter() {
    try {
      this.loaded = false;
      const jobId = parseInt(this.route.snapshot.paramMap.get('jobId'));
      this.job = this.navCtrl.getParam('cleaning') || await this.cleanings.fetchCleaning(jobId);
      this.job.homekeeper_job_id = this.job.homekeeper_job.id;
      this.messages = await this.communication.getSharedInboxForHomekeeperJob(this.job.homekeeper_job.id);
      await this.markMessagesAsRead();
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async markMessagesAsRead() {
    let array: any = [];
    this.messages.map((message) => {
      if (!message.is_read && message.from_member.type == 'CustomerMember' && message?.chat_room_key) {
        array.push((message?.chat_room_key));
      }
    });
    if (array.length > 0) {
      await this.communication.markMessagesAsRead(array);
    }
  }

  hasPassed14DaysFromJobStartDate() {
    const diff = moment().diff(moment(this.job.job.date));
    const duration = moment.duration(diff);
    return (duration.days() > 14);
  }

  contactClient(type) {
    if (type == 'attachment') {
      this.attachment();
      return;
    }
    if (type == 'message') {
      this.message();
      return;
    }
    this.call();
  }

  @Loading()
  async message(attachment = []) {
    this.errorMessage = '';
    this.submitted = true;
    if (attachment.length == 0 && !this.form.get('message').valid) {
      return;
    }
    const payload = {
      chat: {
        type: 'homekeeper_job',
        data: {
          homekeeper_job_id: this.job.homekeeper_job.id
        }
      },
      message: {
        text: this.form.value.message,
        files: attachment
      }
    };
    try {
      await this.mwService.startCommunication(this.job);
      const response = await this.communication.sendSharedInboxMessage(payload);
      if (response?.data?.message == 'We couldn\'t send your message, please try again later!') {
        return this.errorMessage = response.data.message;
      }
      this.form.patchValue({
        message: ''
      });
      this.messages = await this.communication.getSharedInboxForHomekeeperJob(this.job.homekeeper_job.id);
      this.submitted = false;
    } catch (err) {
      const error = await this.mwService.buildErrorAlert(err);
      return this.showErrorMessage(error);
    }
  }

  async attachment() {
    this.errorMessage = '';
    try {
      const attachment = await this.communication.addAttachment();
      if (attachment == '') {
        return this.errorMessage = 'Unable to attach photo. Please upload a PNG or JPEG file.';
      }
      await this.message([attachment]);
    } catch (err) {
      this.errorMessage =  err.error ? err.error.message : err.message;
    }
  }

  async call() {
    try {
      await this.mwService.startCommunication(this.job);
    } catch (err) {
      const error = await this.mwService.buildErrorAlert(err);
      return this.showErrorMessage(error);
    }
    window.location.href = `tel:${this.job.job.gateway_phone}`;
  }

  showErrorMessage(message) {
    this.errorMessage = message;
    setTimeout(() => this.errorMessage = null, 10000);
  }
}
