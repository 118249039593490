import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { UndoCancellation } from 'src/providers/undo-cancellation/undo-cancellation';

import { Loading } from 'src/shared/components/loading/loading';

import { SuccessPageParamsModel } from 'src/pages/success/success';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { Timeout } from 'src/shared/components/timeout/timeout';

@Component({
  templateUrl: 'undo-cancellation.html'
})
export class UndoCancellationPage extends Timeout {

  errorMessage: string;
  jobId: number;
  title: string;
  undoable: boolean;

  constructor(
    private customNavCtrl: CustomNavController,
    public route: ActivatedRoute,
    private undoCancellation: UndoCancellation,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router
  ) {
    super(timeoutErrorHandler, router, route);
  }

  @Loading()
  async ionViewWillEnter() {
    try {
      this.jobId = +this.route.snapshot.paramMap.get('jobId');
      const undoData = await this.undoCancellation.checkUndoable(this.jobId);
      this.undoable = undoData.eligible;
      this.title = this.undoable ? 'Undo Cancellation?' : 'Not Eligible';
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  @Loading()
  async confirm() {
    try {
      await this.undoCancellation.undoCancellation(this.jobId);
      const params = this.mountSuccessPageParams();
      this.customNavCtrl.navigateForward('success', params);
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  mountSuccessPageParams(): SuccessPageParamsModel {
    return {
      header: `Cancellation Undone`,
      body: `This job is now back on your schedule.`,
      buttonText: `Ok`,
      buttonRoute: `jobs`
    };
  }

  goBack() {
    this.customNavCtrl.navigateForward('jobs');
  }
}
