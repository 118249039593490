import { Injectable } from '@angular/core';
import { HttpClient } from '../../shared/providers/http/http-client';
import { TidyStorage } from '../../shared/providers/tidy-storage';

@Injectable()
export class PendingDecertification {

  constructor(
    private httpClient: HttpClient,
    private storage: TidyStorage
  ) {}

  async undoPendingDecertification(comment) {
    const hkId = await this.storage.retrieve('hk_id');
    const url: any = `api/v1/homekeeper/${hkId}/undo-pending-decertification`;
    await this.httpClient.put(url, {comment});
  }
}
