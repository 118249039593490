import { Injectable } from '@angular/core';

import { AppConfig } from 'src/shared/providers/config';

@Injectable()
export class Logger {

  constructor(
  ) {}

  requestError(err: any, url: string, payload: any) {
    this.log({ url, payload, err });
  }

  error(err: any = null, message: string = ''): void {
    const tags = err && err.request_id ? {request_id: err.request_id.split('-').slice(-1)[0]} : {};
    this.log({ err, message, tags } );
  }

  log(...data: Array<string|object> ): void {
    //LOGGLY was deprecated. FE kept this method to keep the deprecation simple and make the data easily available if we want to revisit it
    //console.log('LOGGED ERROR')
    //console.log('version: ' + AppConfig.VERSION)
    //console.log(data)
  }

}
