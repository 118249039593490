import { Injectable } from '@angular/core';

import { MWService } from 'src/main-workflow/mw.service';

import { Aws } from 'src/shared/providers/aws';
import { HttpClient } from 'src/shared/providers/http/http-client';

import * as moment from 'moment';

@Injectable()
export class ContestDispute {
  constructor(
    private aws: Aws,
    private httpClient: HttpClient,
    private mwService: MWService
  ) {}

  async respondDispute(params: RespondDisputeParams) {
    const data = {
      id: params.disputeId,
      homekeeper_contest: params.contested,
      homekeeper_response : params.homekeeperResponse,
      attachments: params.attachments
    };
    const urlDisputeResponse: any = `api/v1/homekeeper/cleanings/${params.jobId}/disputes/${params.disputeId}/reply`;
    await this.httpClient.post(urlDisputeResponse, data);
    params.moment.description = params.homekeeperResponse;
    await this.mwService.sendMomentToBackend(params.moment, params.source, params.jobId);
  }

  async uploadPicture(photoData, disputeId): Promise<any> {
    const awsKey = `dispute-photos/dispute-${disputeId}-${moment()}`;
    const awsResponse = await this.aws.uploadImageToS3(photoData, awsKey);

    return awsResponse.Key;
  }

  extractAwsKeys(photos) {
    const awsKeys = [];
    photos.forEach(photo => {
      awsKeys.push(photo.awsKey);
    });

    return awsKeys;
  }
}

export interface RespondDisputeParams {
  disputeId: number;
  homekeeperResponse: string;
  contested: boolean;
  jobId: number;
  source: string;
  moment: any;
  attachments: any;
}
