import { Component, ViewEncapsulation } from '@angular/core';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { ScheduleCardModel } from 'src/models/schedule-card.model';
import { Me } from 'src/shared/providers/me';
import { Loading } from 'src/shared/components/loading/loading';
import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';
import { SuccessPageParamsModel } from 'src/pages/success/success';

@Component({
  selector: 'confirm-travel-time-cancellation',
  templateUrl: 'confirm-travel-time-cancellation.html',
  encapsulation: ViewEncapsulation.None
})
export class ConfirmTravelTimeCancellationPage {

  errorMessage: string;
  cancellations: Array<ScheduleCardModel>;
  travelTime: string;

  constructor(
    private navCtrl: CustomNavController,
    private iabUtils: InAppBrowserUtils,
    private me: Me
  ) {
  }

  @Loading()
  async ionViewDidEnter() {
    const params = this.navCtrl.getParams();
    this.cancellations = params.cancellations;
    this.travelTime = params.travelTime;
  }

  confirmUpdate = async () => {
    this.errorMessage = '';
    try {
      const address = this.navCtrl.getParam('address');
      if (address) {
        await this.me.updateAddress(address);
      }
      await this.me.updateTravelTime(this.travelTime);
      const params = this.mountSuccessPageParams();
      this.navCtrl.navigateForward('success', params);
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  mountSuccessPageParams(): SuccessPageParamsModel {
    return {
      header: `Max Drive Updated`,
      body: `Your max drive time has been updated.`,
      buttonText: `Ok`,
      buttonRoute: `more`
    };
  }

  goBack = () => {
    this.navCtrl.back();
  }

  learnMoreTravelTime = () => {
    this.iabUtils.openUrl(
      'https://help.tidy.com/pros/set-service-area-max-drive-time#setting-your-max-drive-time'
    );
  }
}
