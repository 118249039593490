import { Component, ViewEncapsulation } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { NotificationSettings, NotificationSettingsDayModel } from 'src/providers/notification-settings/notification-settings';

import { Loading } from 'src/shared/components/loading/loading';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

@Component({
  selector: 'notification-settings',
  templateUrl: './notification-settings.html'
})

export class NotificationSettingsPage extends Timeout {

  errorMessage: string;
  daysSms: NotificationSettingsDayModel[];
  daysApp: NotificationSettingsDayModel[];

  constructor(
    private customNavCtrl: CustomNavController,
    private notificationSettings: NotificationSettings,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
  }

  @Loading()
  async ionViewDidEnter(): Promise<any> {
    try {
      const notificationSettings = await this.notificationSettings.getNotificationSettings();
      this.daysApp = await this.notificationSettings.buildNotificationDays(notificationSettings, 'app_time_notification_setting');
      this.daysSms = await this.notificationSettings.buildNotificationDays(notificationSettings, 'sms_time_notification_setting');
      this.loaded = true;
    } catch(error) {
      this.timeoutHandler(error);
      this.errorMessage = error.message;
    }
  }

  editDay(day, channel) {
    this.customNavCtrl.navigateForward('notification-settings-day', {
      day: day,
      channel: channel,
    });
  }

  notFirstTime(i, day) {
    return ((i+1) < day.times.length);
  }
}
