import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { BankInfo } from 'src/providers/bank-info/bank-info';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

import { Loading } from 'src/shared/components/loading/loading';
import { Timeout } from 'src/shared/components/timeout/timeout';

@Component({
  selector: 'payout-settings',
  templateUrl: './payout-settings.html',
})
export class PayoutSettingsPage extends Timeout {

  errorMessage: string;
  form: UntypedFormGroup;
  stripeResponse: any;
  payoutSetting: any;
  submitted = false;

  constructor(
    private bankInfo: BankInfo,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private iabUtils: InAppBrowserUtils,
    router: Router,
    route: ActivatedRoute,
    public timeoutErrorHandler: TimeoutErrorHandler
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.fb.group({
      payoutSetting: ['']
    });
  }

  @Loading()
  async ionViewDidEnter(): Promise<any> {
    this.errorMessage = '';
    this.stripeResponse = null;
    try {
      this.loaded = false;
      this.payoutSetting = localStorage.getItem('payoutSetting');
      if (!this.payoutSetting) {
        const settings = await this.bankInfo.getPayoutSettings();
        this.payoutSetting = settings[0]?.value;
      }
      if (this.payoutSetting == 'direct_deposit') {
        this.stripeResponse = await this.bankInfo.getStripeStatus();
      }
    } catch (err) {
      if (err && err.code === 404) {
        this.errorMessage = '';
        return;
      }
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.timeoutHandler(err);
    } finally {
      this.loaded = true;
    }
  }

  changePayoutSetting(setting) {
    this.form.patchValue({ payoutSetting: setting });
  }

  selectPayoutSetting() {
    const params = {
      payoutSetting: this.form.value.payoutSetting
    }
    this.navCtrl.navigateForward('payout-settings-detail', params);
  }

  async editStripeInfo() {
    try {
      const response = await this.bankInfo.getStripeLink();
      const url = response.connect_onboard_account_link;
      this.iabUtils.openUrl(url);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  goToEditPayoutSettingsPage() {
    const params = {
      currentPayoutSetting: this.payoutSetting
    }
    this.navCtrl.navigateForward('edit-payout-settings', params);
  }

  editBank() {
    const params = {
      payoutSetting: 'direct_deposit',
      alreadyHasBank: true
    }
    this.navCtrl.navigateForward('payout-settings-detail', params);
  }

  learnMoreTaxes() {
    this.iabUtils.openUrl('https://help.tidy.com/pros/taxes-and-1099');
  }

}
