import { Injectable } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { NavigationExtras, UrlTree, UrlSerializer, Router } from '@angular/router';
import { Location } from '@angular/common';
import { RouterOutletInjector } from './router-outlet-injector';


@Injectable({
  providedIn: 'root'
})
export class CustomNavController extends NavController {

  readonly redirectPage: string = 'jobs';

  constructor(
    platform: Platform,
    location: Location,
    serializer: UrlSerializer,
    router: Router,
    private routerOutlet: RouterOutletInjector
  ) {
    super(platform, location, serializer, router);
  }

  navigateBack(url: string | any[] | UrlTree, params?: any) {
    const navigationExtras = this.mountNavivationExtras(params);

    return super.navigateBack(url, navigationExtras);
  }

  navigateForward(url: string | any[] | UrlTree, params?: any) {
    const navigationExtras = this.mountNavivationExtras(params);

    return super.navigateForward(url, navigationExtras);
  }

  navigateRoot(url: string | any[] | UrlTree, params?: any) {
    const navigationExtras = this.mountNavivationExtras(params);

    return super.navigateRoot(url, navigationExtras);
  }

  mountNavivationExtras(params) {
    const navigationExtras: NavigationExtras = {
      state: params
    };

    return navigationExtras;
  }

  getParam(key: string, noParamRedirect: boolean = false) {
    if (!history.state || !key || !history.state[key]) {
      if(noParamRedirect) {
        this.navigateRoot(this.redirectPage);
      }
      return null;
    }
    return history.state[key];
  }

  getParams(noParamsRedirect: boolean = true) {
    const historyState = history.state;
    const noParams = Object.keys(historyState).length < 2;
    if (noParamsRedirect && noParams) {
      this.navigateRoot(this.redirectPage);
      return null;
    }
    return historyState;
  }

  async back() {
    const sucessfullPop = await this.routerOutlet.ionRouterOutlet?.pop();

    if(!sucessfullPop) {
      super.back();
    }
  }
}
