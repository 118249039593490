import { Injectable } from '@angular/core';

import { HttpClient } from 'src/shared/providers/http/http-client';
import { Me } from 'src/shared/providers/me';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { TeamModel, InviteModel, EligibilityModel, DelegationModel, ServiceTypeModel } from 'src/shared/models/team.model';
import { TidySelectNumberValueModel, TidySelectStringValueModel } from 'src/models/tidy-select-item.model';

@Injectable()
export class Team {

  constructor(
    private httpClient: HttpClient,
    private me: Me,
    private storage: TidyStorage
  ) {}

  async getMyTeam(): Promise<TeamModel> {
    const hk = await this.me.load();
    const mainTeamId = hk.main_team.id;
    const url = `api/v1/homekeeper/teams/${mainTeamId}`;
    return this.httpClient.get(url);
  }

  getMyTeams(): Promise<TeamModel[]> {
    const url = `api/v1/homekeeper/teams`;
    return this.httpClient.get(url);
  }

  getTeam(teamId): Promise<TeamModel> {
    const url = `api/v1/homekeeper/teams/${teamId}`;
    return this.httpClient.get(url);
  }

  getTeamServices() {
    const url = `api/v1/homekeeper/team-services`;
    return this.httpClient.get(url);
  }

  getTeamServicesForAddress(addressId) {
    const url = `api/v1/homekeeper/team-services?address_id=${addressId}`;
    return this.httpClient.get(url);
  }

  getServiceTypes() {
    const url = `api/v1/homekeeper/service-types`;
    return this.httpClient.get(url);
  }

  addTeamServiceTypes(payload) {
    const url = `api/v1/homekeeper/team-service-types`;
    return this.httpClient.put(url, payload);
  }

  getTeamServiceTypes() {
    const url = `api/v1/homekeeper/team-service-types`;
    return this.httpClient.get(url);
  }

  addService(payload) {
    const url = 'api/v1/homekeeper/team-services';
    return this.httpClient.post(url, payload);
  }

  updateService(teamServiceId, payload) {
    const url = `api/v1/homekeeper/team-services/${teamServiceId}`;
    return this.httpClient.put(url, payload);
  }

  deleteService(teamServiceId) {
    const url = `api/v1/homekeeper/team-services/${teamServiceId}/inactivate`;
    return this.httpClient.post(url, {});
  }

  getInvitationsSent(): Promise<InviteModel[]> {
    const url = `api/v1/homekeeper/team-member-invitations/sent`;
    return this.httpClient.get(url);
  }

  getInvitationsReceives() {
    const url = `api/v1/homekeeper/team-member-invitations/received`;
    return this.httpClient.get(url);
  }

  createInvite(payload) {
    const url = `api/v1/homekeeper/team-member-invitations`;
    return this.httpClient.post(url, payload);
  }

  resendInvite(teamMemberInvitationId) {
    const url = `api/v1/homekeeper/team-member-invitations/${teamMemberInvitationId}/resend`;
    return this.httpClient.post(url, {});
  }

  cancelInvite(teamMemberInvitationId) {
    const url = `api/v1/homekeeper/team-member-invitations/${teamMemberInvitationId}`;
    return this.httpClient.delete(url);
  }

  acceptInvite(teamMemberInvitationId) {
    const url = `api/v1/homekeeper/team-member-invitations/${teamMemberInvitationId}/accept`;
    return this.httpClient.post(url, {});
  }

  rejectInvite(teamMemberInvitationId) {
    const url = `api/v1/homekeeper/team-member-invitations/${teamMemberInvitationId}/decline`;
    return this.httpClient.post(url, {});
  }

  leaveTeam(teamId) {
    const url = `api/v1/homekeeper/teams/${teamId}/leave`;
    return this.httpClient.post(url, {});
  }

  removeTeamMember(teamMemberId) {
    const url = `api/v1/homekeeper/team-members/${teamMemberId}/remove`;
    return this.httpClient.post(url, {});
  }

  async updateMyTeamDetail(data) {
    const hk = await this.me.load();
    const mainTeamId = hk.main_team.id;
    const url = `api/v1/homekeeper/teams/${mainTeamId}`;
    return this.httpClient.put(url, data);
  }

  async updateMyTeamUrl(data) {
    const hk = await this.me.load();
    const mainTeamId = hk.main_team.id;
    const url = `api/v1/homekeeper/teams/${mainTeamId}/url`;
    return this.httpClient.put(url, data);
  }

  getEligiblePros(hkJobId): Promise<EligibilityModel[]> {
    const url = `api/v1/homekeeper/eligible-homekeepers/job-delegation-request?homekeeper_job_id=${hkJobId}`;
    return this.httpClient.get(url);
  }

  delegateJob(teamMemberId, hkJobId): Promise<DelegationModel> {
    const data = {
      team_member_id: teamMemberId,
      homekeeper_job_id: hkJobId
    }
    const url = 'api/v1/homekeeper/job-delegation-requests';
    return this.httpClient.post(url, data);
  }

  acceptJob(jobDelegationRequestId): Promise<DelegationModel> {
    const url = `api/v1/homekeeper/job-delegation-requests/${jobDelegationRequestId}/accept`;
    return this.httpClient.post(url, {});
  }

  rejectJob(jobDelegationRequestId): Promise<DelegationModel> {
    const url = `api/v1/homekeeper/job-delegation-requests/${jobDelegationRequestId}/reject`;
    return this.httpClient.post(url, {});
  }

  cancelDelegationRequest(jobDelegationRequestId): Promise<DelegationModel> {
    const url = `api/v1/homekeeper/job-delegation-requests/${jobDelegationRequestId}/cancel`;
    return this.httpClient.post(url, {});
  }

  buildBillingTypeItems(): TidySelectStringValueModel[] {
    return [
      {
        viewValue: 'Flat Rate',
        value: 'flat_rate'
      },
      {
        viewValue: 'Hourly',
        value: 'hourly'
      },
      {
        viewValue: 'Price Later',
        value: 'price_later'
      },
      {
        viewValue: 'Free',
        value: 'free'
      }
    ];
  }

  async buildCategoryItems(serviceTypes): Promise<TidySelectNumberValueModel[]> {
    let array = [];
    serviceTypes.map((type) => {
      array.push({
        viewValue: type.name,
        value: type.id
      })
    });
    return array;
  }

  buildFrequencyItems(): TidySelectStringValueModel[] {
    return [
      {
        viewValue: 'One Time Only',
        value: 'one_time'
      },
      {
        viewValue: 'One Time or Recurring',
        value: 'one_time_or_recurring'
      },
      {
        viewValue: 'Recurring Only',
        value: 'recurring'
      }
    ];
  }

  buildJobHourItems(): TidySelectNumberValueModel[] {
    let array = [];
    for (let i = 1; i < 21; i++) {
      array.push({
        viewValue: i == 2 ? `${i/2} hour` : `${i/2} hours`,
        value: i/2*60
      });
    }
    return array;
  }

  buildJobWholeHourItems(): TidySelectNumberValueModel[] {
    let array = [];
    for (let i = 0; i < 12; i++) {
      array.push({
        viewValue: i == 1 ? `${i} hour` : `${i} hours`,
        value: i*60
      });
    }
    return array;
  }

  buildJobMinuteItems(): TidySelectNumberValueModel[] {
    let array = [];
    for (let i = 0; i < 12; i++) {
      array.push({
        viewValue: i*5 + ' minutes',
        value: i*5
      });
    }
    return array;
  }

  async getIfHasTeamMembers() {
    const teams = await this.getMyTeams();
    const hkId = await this.storage.retrieve('hk_id');
    const myTeam = teams.find((team) => {
      return team.owner.id === hkId;
    });
    let hasTeamMembers = false;
    myTeam.members.forEach((member) => {
      if (member.homekeeper.id !== hkId) {
        hasTeamMembers = true;
      }
    });
    return hasTeamMembers;
  }

}
