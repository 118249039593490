import { Component, ViewEncapsulation, OnInit, ElementRef} from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { AddressForm } from 'src/providers/address-form/address-form';
import { Cleanings } from 'src/providers/cleanings/cleanings';
import { Certification } from 'src/providers/certification/certification';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Me } from 'src/shared/providers/me';
import { MultipleCountryService } from 'src/providers/addresses/multiple-country.service';

import { Loading } from 'src/shared/components/loading/loading';
import { AddressMetadata } from 'src/shared/components/autocomplete-address/autocomplete-address';

import { ProfileFormState } from '../profile-form-state';

import { keepOnlyText } from 'src/shared/utils/text-utils';
import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm';
import { SuccessPageParamsModel } from 'src/pages/success/success';

import { SelectModel } from '@tidyapp/tidy-ui-components';
import { RadioButtonModel } from '@tidyapp/tidy-ui-components';
import { HomekeeperAddressModel } from 'src/models/homekeeper-profile.model';
import { ScheduleCardModel } from 'src/models/schedule-card.model';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  selector: 'profile-travel-time-page',
  templateUrl: 'profile-travel-time.html',
  encapsulation: ViewEncapsulation.None
})

export class ProfileTravelPage {

  countryList: RadioButtonModel[];
  chosenType: string = '';
  homekeeper: any;
  howFarItems: Array<SelectModel>;
  invalidAddressError: string;
  isUpdatingMaxDriveTime: boolean;
  loaded: boolean;
  noAddressPickedTemplate: ElementRef;
  typeItems: Array<SelectModel>;
  form: any;
  travelTimesOpts: Array<SelectModel>;
  errorMessage: string;
  submitted: boolean;
  zipCodeMask = {
    mask: '00000'
  };

  constructor(
    private addressForm: AddressForm,
    private certification: Certification,
    private cleanings: Cleanings,
    private navCtrl: CustomNavController,
    private formBuilder: UntypedFormBuilder,
    private iabUtils: InAppBrowserUtils,
    private me: Me,
    private multipleCountryService: MultipleCountryService,
    private modalCtrl: ModalController
  ) {
    this.init();
  }

  async init() {
    this.loaded = false;
    this.isUpdatingMaxDriveTime = this.navCtrl.getParam('isUpdatingMaxDriveTime');
    this.typeItems = this.getTypeItems();
    this.travelTimesOpts = this.getTravelTimesOptions();
    this.howFarItems = this.getHowFarItems();
    this.form = this.formBuilder.group({
      time: ['', Validators.required],
      address: [''],
      countryCode: ['US'],
      zip: ['', this.multipleCountryService.getCountrySettings('US').validator]
    });
    this.countryList = this.multipleCountryService.countryRadioButtonList;
    this.loaded = true;
  }

  async onSubmit() {
    try {
      this.errorMessage = '';
      this.submitted = true;
      if (!this.form.valid) {
        return;
      }
      const isWalking = this.form.value.time < 10;
      if (isWalking) {
        if (this.addressForm.addressError(this.form.value.address)) {
          this.form.controls.address.setErrors({'incorrect': true});
          return this.errorMessage = 'Unfortunately you must have a physical address in order to accurately determine your service area, and PO boxes cannot be used. Clients do not see your address. Please add a different address.';
        }
        if (this.invalidAddressError) {
          return this.errorMessage = this.invalidAddressError;
        }
      }
      if (!this.isUpdatingMaxDriveTime) {
        if (this.chosenType == 'walk') {
          await this.saveNewAddress();
        }
        await this.certification.finishStep('max_drive_time', {max_travel_time: this.form.value.time});
        const finishPage = this.navCtrl.getParam('finishPage', true);
        this.navCtrl.navigateForward(finishPage);
      } else {
        let cancellations: Array<ScheduleCardModel>;
        if (this.chosenType == 'car') {
          cancellations = await this.cleanings.previewCancellationsDrivableDistance(this.form.value.time);
        } else {
          cancellations = await this.cleanings.previewCancellationsZipcodeAndDrivableDistance(this.form.value.zip, this.form.value.time);
        }
        if (cancellations.length > 0) {
          this.goToConfirmCancelation(cancellations);
        } else {
          this.goToConfirmChangeTime();
        }
      }
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  goToConfirmCancelation(cancellations: Array<ScheduleCardModel>) {
    const params: any = {
      time: this.form.value.time,
      cancellations: cancellations,
      travelTime: this.form.value.time
    };
    const isWalking = this.form.value.time < 10;
    if (isWalking) {
      params['address'] = {
        ...this.form.value,
        country_code: this.form.value.countryCode,
        zipcode: keepOnlyText(this.form.value.zip),
        add_state: this.form.value.state
      };
    }
    this.navCtrl.navigateForward('profile/travel-time/confirm-schedule', params);
  }

  async goToConfirmChangeTime() {
    const params: ConfirmPageParamsModel = {
      title: 'Change Max Drive?',
      body: `${new TranslationPipe().transform('Change max drive time to')} ${this.form.value.time} ${new TranslationPipe().transform('minutes? Your work area will now change.')}`,
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: this.confirmChangeTime.bind(this)
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });
    confirmationModal.present();
  }

  async confirmChangeTime() {
    await this.me.updateTravelTime(this.form.value.time);
    this.modalCtrl.dismiss();
    const params = {
      header: `Max Drive Updated`,
      body: `Your max drive time has been updated.`,
      buttonText: `Ok`,
      buttonRoute: `more`
    };
    this.navCtrl.navigateForward('success', params);
  }

  async goToConfirmChangeAddressAndTime() {
    const params: ConfirmPageParamsModel = {
      title: 'Change Address & Time?',
      body: `${new TranslationPipe().transform('Change address to')} ${this.form.value.address}}, ${this.form.value.zipcode}} ${new TranslationPipe().transform('and max walk time from there to')} ${this.form.value.time} ${new TranslationPipe().transform('minutes? Your work area will now change.')}`,
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: this.confirmChangeAddressAndTime.bind(this)
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });
    confirmationModal.present();
  }

  async confirmChangeAddressAndTime() {
    await this.saveNewAddress();
    await this.me.updateTravelTime(this.form.value.time);
    this.modalCtrl.dismiss();
    const params = {
      header: 'Max Drive & Address Updated',
      body: 'Your max drive time & address has been updated.',
      buttonText: 'Ok',
      buttonRoute: 'more'
    };
    this.navCtrl.navigateForward('success', params);
  }

  async saveNewAddress() {
    const zipcode = keepOnlyText(this.form.value.zip);
    const newAddress: HomekeeperAddressModel = {
      ...this.form.value,
      country_code: this.form.value.countryCode,
      zipcode,
      add_state: this.form.value.state
    };
    await this.me.updateAddress(newAddress);
  }

  getTravelTimesOptions(): Array<SelectModel> {
    return Array(14).fill(0).map((item, index) => {
      const time = 10 + index * 5;
      return {
        value: `${time}`,
        viewValue: `${time} minutes`
      };
    });
  }

  chooseType(chosenType) {
    this.chosenType = chosenType.value;
    if (this.chosenType == 'car') {
      this.removeValidators('address');
      this.removeValidators('countryCode');
      this.removeValidators('zip');
    } else if (this.chosenType == 'walk') {
      this.addRequiredValidator('address');
      this.addRequiredValidator('countryCode');
    }
  }

  addRequiredValidator(controlName) {
    this.form.controls[controlName].setValidators([Validators.required]);
    this.form.controls[controlName].updateValueAndValidity();
  }

  removeValidators(controlName) {
    this.form.controls[controlName].clearValidators();
    this.form.controls[controlName].updateValueAndValidity();
  }

  getTypeItems() {
    return [
      {
        viewValue: 'By car (recommended except in very dense areas)',
        value: 'car'
      },
      {
        viewValue: 'By public transit or walk',
        value: 'walk'
      },
    ];
  }

  getHowFarItems() {
    return [
      {
        viewValue: '3 Minutes',
        value: '3'
      },
      {
        viewValue: '6 Minutes',
        value: '6'
      },
      {
        viewValue: '9 Minutes',
        value: '9'
      }
    ];
  }

  choseItem(addressMetadata: AddressMetadata) {
    this.form.patchValue({ zip: addressMetadata.zipcode, ...addressMetadata });
    this.errorMessage = '';
  }

  changeCountrySelected(countryCodeSelected: string) {
    const validatorObject = this.multipleCountryService.getCountrySettings(countryCodeSelected);
    this.form.get('zip').setValidators(validatorObject.validator);
    this.form.get('zip').updateValueAndValidity();
    this.zipCodeMask = validatorObject.mask;
    setTimeout(() => {
      this.form.patchValue({
        address: '',
        zip: ''
      });
    });
  }

  learnMore() {
    this.iabUtils.openUrl('https://help.tidy.com/pros/set-service-area-max-drive-time#setting-your-max-drive-time');
  }
}
