<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="false"
    [title]="'Add'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [hasBottomNav]="true">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
              
    <ng-container *ngIf="isLimitedAccount && loaded">

      <tidy-alert *ngIf="delegationRequests?.length == 0">
        <tidy-text
          [body]="'No requests'">
        </tidy-text>
      </tidy-alert>

      <tidy-card *ngFor="let delegation of delegationRequests">
        <tidy-card-button (action)="goToDelegationRequest(delegation)">
          <tidy-row class="vertical-align-center">
            <tidy-image
               [src]="'assets/svg/alert-circle.svg'"
               class="header-size">
             </tidy-image>
            <tidy-text
              [header]="delegation.delegated_by_team_member.homekeeper.name.substr(0,delegation.delegated_by_team_member.homekeeper.name.indexOf(' ')) + ' asked you to accept a job'"
              [translate]="false">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Any questions? Contact '">
            </tidy-text>
            <tidy-text
              [body]="delegation.delegated_by_team_member.homekeeper.name.substr(0,delegation.delegated_by_team_member.homekeeper.name.indexOf(' ')) + '.'"
              [translate]="false">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

    </ng-container>

    <ng-container *ngIf="!isLimitedAccount">

      <tidy-row>
        <tidy-grid>
          <ion-row>
            <ion-col size="4">
              <tidy-round-button
                class="orange"
                [image]="'assets/img/add_private_client.svg'"
                (action)="addClient()"
                [label]="'Add Client'" >
              </tidy-round-button>
            </ion-col>
            <ion-col size="4">
              <tidy-round-button
                class="green"
                [image]="'assets/img/add_private_job.svg'"
                (action)="addJob()"
                [label]="'Add Job'" >
              </tidy-round-button>
            </ion-col>
            <ion-col size="4">
              <tidy-round-button
                class="cyan"
                [image]="'assets/img/proposal.svg'"
                (action)="sendProposal()"
                [label]="'Send Proposal'" >
              </tidy-round-button>
            </ion-col>
          </ion-row>
        </tidy-grid>
      </tidy-row>

      <tidy-row [align]="'center'">
        <tidy-text
          [helper]="'or'"
          class="divider">
        </tidy-text>
      </tidy-row>

      <tidy-row [align]="'center'">
        <tidy-text
          [title]="'Accept Jobs'">
        </tidy-text>
      </tidy-row>

      <ng-container *ngIf="loaded">

        <tidy-error-message
          *ngIf="errorMessage && !isTimeOut"
          [text]="errorMessage">
        </tidy-error-message>

        <ng-container *ngIf="errorMessage && isTimeOut">
          <tidy-text
            [body]="'It looks like you aren’t connected to the internet. '">
          </tidy-text>
          <tidy-text
            [body]="'Try Again'"
            (action)="getInitialData()"
            class="link">
          </tidy-text>
        </ng-container>

        <ng-container *ngIf="!didGoLive && hasNoExampleCoops && currentWeek?.days?.length === 0 && clientRequests?.length === 0">
          <tidy-alert *ngIf="addedZipcode">
            <tidy-row>
              <tidy-text
                [body]="'There are no clients from TIDY within your max drive time at your current rates right now. Check back later and consider '">
              </tidy-text>
              <tidy-text
                (action)="goToDriveTime()"
                [body]="'increasing your max drive time'"
                class="link">
              </tidy-text>
              <tidy-text
                [body]="' or '">
              </tidy-text>
              <tidy-text
                (action)="goToRates()"
                [body]="'making sure your rates are competitive'"
                class="link">
              </tidy-text>
              <tidy-text
                [body]="'.'">
              </tidy-text>
            </tidy-row>
          </tidy-alert>
          <tidy-alert *ngIf="!addedZipcode">
            <tidy-row>
              <tidy-text
                [body]="'Add account info to see Clients near you! '">
              </tidy-text>
              <tidy-text
                (action)="addAccountInfo()"
                [body]="'Add Account Info'"
                class="link">
              </tidy-text>
            </tidy-row>
          </tidy-alert>
        </ng-container>

        <tidy-alert *ngIf="didGoLive && currentWeek?.days?.length === 0 && clientRequests?.length === 0">
          <tidy-row>
            <tidy-text
              [body]="'There are no clients from TIDY within your max drive time at your current rates right now. Check back later and consider '">
            </tidy-text>
            <tidy-text
              (action)="goToDriveTime()"
              [body]="'increasing your max drive time'"
              class="link">
            </tidy-text>
            <tidy-text
              [body]="' or '">
            </tidy-text>
            <tidy-text
              (action)="goToRates()"
              [body]="'making sure your rates are competitive'"
              class="link">
            </tidy-text>
            <tidy-text
              [body]="'.'">
            </tidy-text>
          </tidy-row>
        </tidy-alert>

        <tidy-card *ngFor="let delegation of delegationRequests">
          <tidy-card-button (action)="goToDelegationRequest(delegation)">
            <tidy-row class="vertical-align-center">
              <tidy-image
                 [src]="'assets/svg/alert-circle.svg'"
                 class="header-size">
               </tidy-image>
              <tidy-text
                [header]="delegation.delegated_by_team_member.homekeeper.name.substr(0,delegation.delegated_by_team_member.homekeeper.name.indexOf(' '))"
                [translate]="false">
              </tidy-text>
              <tidy-text
                [header]="' asked you to accept a job'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'Any questions? Contact '">
              </tidy-text>
              <tidy-text
                [body]="delegation.delegated_by_team_member.homekeeper.name.substr(0,delegation.delegated_by_team_member.homekeeper.name.indexOf(' ')) + '.'"
                [translate]="false">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>

        <ng-container *ngFor="let request of clientRequests">
          <tidy-card *ngIf="request?.initiated_by === 'customer'">
            <tidy-row class="vertical-align-center">
             <tidy-image
                [src]="'assets/svg/person.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="(request?.address?.customer?.account_name !== '' && request?.address?.customer?.account_name ? request?.address?.customer?.account_name : (request?.address?.customer?.first_name + ' ' + request?.address?.customer?.last_name)) + ' Requests You'"
                [translate]="false">
              </tidy-text>
            </tidy-row>
            <ng-container *ngIf="request?.address?.customer?.is_private">
              <tidy-row *ngIf="request?.address?.address1">
                <tidy-text
                  [body]="request?.address?.address1 + (request?.address?.address2 ? (' ' + request?.address?.address2) : '') + (request?.address_name ? (' - ' + request?.address_name) : '')"
                  [translate]="false">
                </tidy-text>
              </tidy-row>
            </ng-container>
            <tidy-row>
              <tidy-text
                [body]="(request?.service_type_details?.customer_name +
                ' ' +
                (request?.frequency | planFrequency)) | titlecase">
              </tidy-text>
            </tidy-row>
            <tidy-row class="extra-bottom-padding" *ngIf="request?.homekeeper_rate">
              <tidy-text
                [body]="(request?.homekeeper_rate | tcurrency) + ' Total'">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngFor="let day of request?.request_times">
              <tidy-text
                [body]="day[0].date | tdate: 'M/DD/YY'">
              </tidy-text>
              <tidy-text
                [body]="': '">
              </tidy-text>
              <ng-container *ngFor="let time of day; let last = last">
                <tidy-text
                  [body]="time?.time | ttime">
                </tidy-text>
                <tidy-text *ngIf="!last"
                  [body]="', '">
                </tidy-text>
              </ng-container>
            </tidy-row>
            <tidy-row class="extra-top-padding">
              <tidy-button
                [action]="navigateToAcceptRequest.bind(this, request)"
                [text]="'Accept One of These Times'"
                class="primary">
              </tidy-button>
            </tidy-row>
            <tidy-row>
              <tidy-button
                [action]="navigateToProposeAlternateRequest.bind(this, request)"
                [text]="'Propose Alternate Times'"
                class="secondary">
              </tidy-button>
            </tidy-row>
            <tidy-row>
              <tidy-button
                [action]="declineRequest.bind(this, request)"
                [text]="'Decline Request'"
                class="secondary">
              </tidy-button>
            </tidy-row>
          </tidy-card>

          <tidy-card *ngIf="request?.initiated_by === 'homekeeper'">
            <tidy-row class="vertical-align-center">
             <tidy-image
                [src]="'assets/svg/person.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="'You Proposed Alternate Times'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="(request?.service_type_details?.name | titlecase) +
                ' ' +
                (request.frequency | planFrequency)">
              </tidy-text>
              <tidy-text
                [body]="' request from '">
              </tidy-text>
              <tidy-text
                [body]="request?.address?.customer?.account_name && request?.address?.customer?.account_name !== '' ? request?.address?.customer?.account_name : request?.address?.customer?.first_name"
                [translate]="false">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngFor="let day of request?.request_times">
              <tidy-text
                [body]="day[0].date | tdate: 'M/DD/YY'">
              </tidy-text>
              <tidy-text
                [body]="': '">
              </tidy-text>
              <ng-container *ngFor="let time of day; let last = last">
                <tidy-text
                  [body]="time?.time | ttime">
                </tidy-text>
                <tidy-text *ngIf="!last"
                  [body]="', '">
                </tidy-text>
              </ng-container>
            </tidy-row>
            <tidy-row class="extra-top-padding">
              <tidy-button
                [action]="cancelCounterRequest.bind(this, request)"
                [text]="'Cancel Proposed Times'"
                class="secondary">
              </tidy-button>
            </tidy-row>
          </tidy-card>
        </ng-container>

        <tidy-card *ngFor="let day of currentWeek?.days; let i = index">
          <tidy-row [alignLastItemRight]="true" [ngClass]="!day?.showMap ? '' : 'extra-bottom-padding'">
            <tidy-image
              src="assets/style-guide-images/bowtie-black.svg"
              class="header-size">
            </tidy-image>
            <ng-container *ngIf="today === day.formatedDate">
              <tidy-text
                [header]="'Today, '">
              </tidy-text>
              <tidy-text
                [header]="(day.date | tdate:'dddd MM/DD/YY')">
              </tidy-text>
            </ng-container>
            <tidy-text
              *ngIf="today !== day.formatedDate"
              [header]="day.dateLabel">
            </tidy-text>
            <tidy-text
              *ngIf="!day?.showMap"
              [body]="'Show Map'"
              (action)="setMap(day, i)"
              class="link">
            </tidy-text>
            <tidy-text
              *ngIf="day?.showMap === true"
              [body]="'Hide Map'"
              (action)="day.showMap = false"
              class="link">
            </tidy-text>
          </tidy-row>
          <tidy-row class="flex extra-bottom-padding" *ngIf="legends && legends[i]?.length > 0">
            <tidy-text
              [body]="'You have jobs at'">
            </tidy-text>
            <span class="map-marker-date" *ngFor="let cleaningTime of legends[i]">
              {{cleaningTime | ttime}}
            </span>
          </tidy-row>
          <tidy-row>
            <div id="map{{i}}" [attr.id]="'map' + i"  style="width: 100%; border-radius: 6px;" [ngStyle]="day?.showMap === true ?{'height': '300px'} : {'height': '0px'}">
            </div>
          </tidy-row>
          <tidy-row>
            <tidy-text
              *ngIf="day?.cleanings.length > 1"
              [body]="'These Clients need you, tap a job to view:'">
            </tidy-text>
            <tidy-text
              *ngIf="day?.cleanings.length === 1"
              [body]="'This Client needs you, tap to view:'">
            </tidy-text>
          </tidy-row>
          <ng-container *ngFor="let cleaning of day?.filteredCleanings; let i = index">
            <tidy-card-button (action)="navigateToCleaning(cleaning)">
              <tidy-grid>
                <ion-row>
                  <ion-col size="2" *ngIf="day?.showMap === true">
                    <tidy-marker [letter]="i"></tidy-marker>
                  </ion-col>
                  <ion-col>
                    <tidy-row class="vertical-align-center">
                      <tidy-text
                        [header]="cleaning.customer_first_name"
                        [translate]="false">
                      </tidy-text>
                      <tidy-image
                        *ngIf="cleaning?.is_private || cleaning?.is_repeat"
                        [src]="'assets/img/star.svg'"
                        class="header-size"
                        style="margin-left:5px">
                      </tidy-image>
                    </tidy-row>
                    <tidy-row>
                      <tidy-text
                        *ngIf="!cleaning?.lastTimeThatDay"
                        [body]="'Starts '">
                      </tidy-text>
                      <tidy-text
                        *ngIf="!cleaning?.lastTimeThatDay"
                        [body]="(cleaning?.time | ttime)">
                      </tidy-text>
                      <tidy-text
                        *ngIf="cleaning?.lastTimeThatDay"
                        [body]="'Flexible start '">
                      </tidy-text>
                      <tidy-text
                        *ngIf="cleaning?.lastTimeThatDay"
                        [body]="(cleaning?.time | ttime) + ' - ' + (cleaning?.lastTimeThatDay | ttime)">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row *ngIf="!(cleaning?.address && cleaning?.is_private)">
                      <tidy-text
                        [body]="parseServiceLength(cleaning.service_type) | titlecase">
                      </tidy-text>
                      <tidy-text
                        [body]="' Regular Cleaning '">
                      </tidy-text>
                    </tidy-row>
                    <ng-container *ngIf="cleaning?.address && cleaning?.is_private">
                      <tidy-row>
                        <tidy-text
                          [body]="cleaning?.service_type_details?.customer_name">
                        </tidy-text>
                      </tidy-row>
                      <tidy-row>
                        <tidy-text
                          [body]="cleaning?.address?.address1 + (cleaning?.address?.address2 ? (' ' + cleaning?.address?.address2) : '') + (cleaning?.address_name ? (' - ' + cleaning?.address_name) : '')"
                          [translate]="false">
                        </tidy-text>
                      </tidy-row>
                    </ng-container>
                  </ion-col>
                </ion-row>
              </tidy-grid>
              <tidy-grid style="margin-top:5px">
                <ion-row>
                  <ion-col size="2" *ngIf="day?.showMap === true">
                  </ion-col>
                  <ion-col>
                    <tidy-row>
                      <payment-tag
                        [billingType]="cleaning.billing_type"
                        [totalValue]="cleaning.cleaning_amount"
                        [bonusValue]="cleaning.bonus_amount">
                      </payment-tag>
                      <tidy-text
                        *ngIf="cleaning.type == 'plan'"
                        [body]="' / Recurring'"
                        style="margin-right:10px; margin-left: 5px">
                      </tidy-text>
                    </tidy-row>
                  </ion-col>
                </ion-row>
              </tidy-grid>
              <tidy-grid style="margin-top:5px" *ngIf="cleaning?.customer_is_premium">
                <ion-row>
                  <ion-col size="2">
                  </ion-col>
                  <ion-col>
                    <tidy-row>
                      <tidy-text
                        [actionBody]="'Big Spender: They book many jobs.'"
                        class="green">
                      </tidy-text>
                    </tidy-row>
                  </ion-col>
                </ion-row>
              </tidy-grid>
              <tidy-grid style="margin-top:5px" *ngIf="(cleaning?.homekeeper_preferences?.gender == 'female' ||  cleaning?.homekeeper_preferences?.gender == 'male') && hasTeamMembers">
                <ion-row>
                  <ion-col size="2">
                  </ion-col>
                  <ion-col>
                    <tidy-row>
                      <tidy-text
                        [body]="cleaning?.homekeeper_preferences?.gender == 'female' ? 'Females Only' : 'Males Only'">
                      </tidy-text>
                    </tidy-row>
                  </ion-col>
                </ion-row>
              </tidy-grid>
            </tidy-card-button>
          </ng-container>
        </tidy-card>

        <ng-container *ngIf="!didGoLive && currentWeek?.days?.length === 0 && clientRequests?.length === 0">
          <ng-container *ngFor="let day of examples; let i = index">
            <tidy-card *ngIf="day?.examples?.length > 0">
              <tidy-row class="extra-bottom-padding vertical-align-center">
                <tidy-image
                  src="assets/style-guide-images/bowtie-black.svg"
                  class="header-size">
                </tidy-image>
                <tidy-text
                  [header]="day.day | tdate:'dddd MM/DD/YY'">
                </tidy-text>
              </tidy-row>
              <tidy-row [align]="'center'" *ngIf="!day?.showMap">
                <tidy-text
                  [body]="'Show Map'"
                  (action)="setExamplesMap(day, i)"
                  class="link">
                </tidy-text>
              </tidy-row>
              <tidy-row [align]="'center'" *ngIf="day.showMap">
                <tidy-text
                  [body]="'Hide Map'"
                  (action)="hideExampleMap(i)"
                  class="link">
                </tidy-text>
              </tidy-row>
              <tidy-row class="extra-bottom-padding">
                <div id="map{{i}}" [attr.id]="'map' + i"  style="width: 100%; border-radius: 6px;" [ngStyle]="day?.showMap === true ?{'height': '300px'} : {'height': '0px'}">
                </div>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  *ngIf="day?.examples.length > 1"
                  [body]="'These Clients need you, tap a job to view:'">
                </tidy-text>
                <tidy-text
                  *ngIf="day?.examples.length === 1"
                  [body]="'This Client needs you, tap to view:'">
                </tidy-text>
              </tidy-row>
              <ng-container *ngFor="let example of day?.examples; let i = index">
                <tidy-card-button (action)="showExampleRequestAlert()">
                  <tidy-grid>
                    <ion-row>
                      <ion-col size="2">
                        <tidy-marker [letter]="i"></tidy-marker>
                      </ion-col>
                      <ion-col size="4">
                        <tidy-text
                          [body]="example.time | ttime">
                        </tidy-text><br>
                        <tidy-text
                          [body]="(example.duration_in_minutes / 60) + ' hours'">
                        </tidy-text>
                      </ion-col>
                      <ion-col size="6" >
                        <tidy-text
                          [body]="'Earn '">
                        </tidy-text>
                        <tidy-text
                          [body]="(example.base_rate + example.avg_bonus_amount*.8  | tcurrencyRound)">
                        </tidy-text>
                      </ion-col>
                    </ion-row>
                  </tidy-grid>
                </tidy-card-button>
              </ng-container>
            </tidy-card>
          </ng-container>
          <tidy-alert *ngIf="!hasNoExampleCoops">
            <tidy-row>
              <tidy-text
                [body]="'*Until you complete all steps to get Clients from TIDY, data on this page is estimated based on recent requests. We cannot determine the exact available leads until you have all your booking constraints and data.'">
              </tidy-text>
            </tidy-row>
          </tidy-alert>
        </ng-container>

      </ng-container>

    </ng-container>

    <ng-container *ngIf="!loaded">
      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 100%; height: 300px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 85%"></ion-skeleton-text>
        </tidy-row>
        <tidy-card-button [hideArrow]="true">
          <tidy-grid>
            <ion-row>
              <ion-col size="2">
                <ion-skeleton-text animated style="width: 80%; height: 25px"></ion-skeleton-text>
              </ion-col>
              <ion-col size="4">
                <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 52%"></ion-skeleton-text>
              </ion-col>
              <ion-col size="6">
                <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-card-button>
        <tidy-card-button [hideArrow]="true">
          <tidy-grid>
            <ion-row>
              <ion-col size="2">
                <ion-skeleton-text animated style="width: 80%; height: 25px"></ion-skeleton-text>
              </ion-col>
              <ion-col size="4">
                <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 52%"></ion-skeleton-text>
              </ion-col>
              <ion-col size="6">
                <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-card-button>
        <tidy-card-button [hideArrow]="true">
          <tidy-grid>
            <ion-row>
              <ion-col size="2">
                <ion-skeleton-text animated style="width: 80%; height: 25px"></ion-skeleton-text>
              </ion-col>
              <ion-col size="4">
                <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 52%"></ion-skeleton-text>
              </ion-col>
              <ion-col size="6">
                <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-card-button>
      </tidy-card>
      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 100%; height: 300px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 85%"></ion-skeleton-text>
        </tidy-row>
        <tidy-card-button [hideArrow]="true">
          <tidy-grid>
            <ion-row>
              <ion-col size="2">
                <ion-skeleton-text animated style="width: 80%; height: 25px"></ion-skeleton-text>
              </ion-col>
              <ion-col size="4">
                <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 52%"></ion-skeleton-text>
              </ion-col>
              <ion-col size="6">
                <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-card-button>
      </tidy-card>
      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 100%; height: 300px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 85%"></ion-skeleton-text>
        </tidy-row>
        <tidy-card-button [hideArrow]="true">
          <tidy-grid>
            <ion-row>
              <ion-col size="2">
                <ion-skeleton-text animated style="width: 80%; height: 25px"></ion-skeleton-text>
              </ion-col>
              <ion-col size="4">
                <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 52%"></ion-skeleton-text>
              </ion-col>
              <ion-col size="6">
                <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-card-button>
        <tidy-card-button [hideArrow]="true">
          <tidy-grid>
            <ion-row>
              <ion-col size="2">
                <ion-skeleton-text animated style="width: 80%; height: 25px"></ion-skeleton-text>
              </ion-col>
              <ion-col size="4">
                <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 52%"></ion-skeleton-text>
              </ion-col>
              <ion-col size="6">
                <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-card-button>
      </tidy-card>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

<tidy-bottom-nav activeRoute="/add">
</tidy-bottom-nav>
