import { Routes } from '@angular/router';

// Route Guards
import { configureRouteGuards } from 'src/app/configure-route-guards';

import { AcceptRejectJobPage } from 'src/pages/team/accept-reject-job/accept-reject-job';
import { ActiveInCommunityPage } from 'src/pages/active-in-community/active-in-community';
import { AddPrivateClientPage } from 'src/pages/private-client/add-private-client/add-private-client';
import { AddClientPaymentSettingPage } from 'src/pages/client-payment-settings/add-client-payment-setting/add-client-payment-setting';
import { AddCommunityReviewPage } from 'src/pages/active-in-community/add-review/add-community-review';
import { AddPrivateJobConfirmationPage } from 'src/pages/private-job/add-private-job/add-private-job-confirmation/add-private-job-confirmation';
import { AddPrivateJobDetailsPage } from 'src/pages/private-job/add-private-job/add-private-job-details/add-private-job-details';
import { AddPrivateJobPage } from 'src/pages/private-job/add-private-job/add-private-job/add-private-job';
import { AddPrivateAddressPage } from 'src/pages/private-client/add-private-address/add-private-address';
import { AddServicePage } from 'src/pages/services/add-service/add-service';
import { AdvancedSettingsPage } from 'src/pages/advanced-settings/advanced-settings';
import { AddPage } from 'src/pages/add/add';
import { AddCreditPage } from "src/pages/private-payments/add-credit/add-credit";
import { AgreementManagementPage } from "src/pages/agreement-management/agreement-management";

import { BookingRestrictionsPage } from 'src/pages/advanced-settings/booking-restrictions/booking-restrictions';
import { CalendarComponent } from 'src/pages/my-schedule/calendar/calendar.component';

import { ConfirmAddressCancellationPage } from 'src/pages/profile/address/confirm-address-cancellation/confirm-address-cancellation';
import { ConfirmTravelTimeCancellationPage } from 'src/pages/profile/travel-time/confirm-travel-time-cancellation/confirm-travel-time-cancellation';
import { CompleteJobPage } from 'src/pages/complete-job/complete-job';
import { ClientAndAdressSectionComponent } from 'src/shared/components/private-job/client-and-adress-section/client-and-adress-section';
import { ClientAddedPage } from 'src/pages/private-client/private-client-added/private-client-added';
import { ClientBillingHistoryPage } from 'src/pages/private-payments/client-billing-history/client-billing-history';
import { ClientBillingOptionsPage } from 'src/pages/private-payments/client-billing-options/client-billing-options';
import { ClientRequestCounterPage } from 'src/pages/add/client-request/client-request-counter/client-request-counter';
import { ClientRequestPage } from 'src/pages/add/client-request/client-request';
import { ClientsFromTidyFeesPage } from 'src/pages/services/clients-from-tidy-fees/clients-from-tidy-fees';
import { ClientsFromTidyServicesPage } from 'src/pages/services/clients-from-tidy-services/clients-from-tidy-services';
import { ClientsFromTidyServicesPreviewPage } from 'src/pages/services/clients-from-tidy-services/clients-from-tidy-services-preview/clients-from-tidy-services-preview';
import { ClientsPage } from 'src/pages/clients/clients';
import { ClientPaymentSettingsPage } from 'src/pages/client-payment-settings/client-payment-settings';
import { ConfirmFeeWaivePage } from 'src/pages/payouts/waive-fee/confirm-fee-waive/confirm-fee-waive';
import { ConfirmSendProposalPage } from 'src/pages/proposals/confirm-send-proposal/confirm-send-proposal';
import { ConfirmPage } from 'src/pages/confirm/confirm';
import { ConfirmSchedulePage } from 'src/pages/my-schedule/confirm-schedule/confirm-schedule';
import { ClientMessagesPage } from 'src/pages/client-messages/client-messages';
import { CheckForUpdatesPage } from 'src/pages/check-for-updates/check-for-updates';
import { ClaimJobPage } from 'src/pages/add/claim-job/claim-job';
import { ContactSupportPage } from 'src/pages/contact-support/contact-support';
import { CheckInCallOutPage } from 'src/pages/check-in/check-in-call-out/check-in-call-out';
import { CheckInPage } from 'src/pages/check-in/check-in';
import { CheckInLearnAboutTeamsPage } from 'src/pages/check-in/check-in-learn-about-teams/check-in-learn-about-teams.page';
import { CurrentBalancePage } from 'src/pages/payouts/current-balance/current-balance';

import { DesktopWarningPage } from 'src/pages/desktop-warning/desktop-warning-page';
import { DelegateJobPage } from 'src/pages/team/delegate-job/delegate-job';
import { DisputePage } from 'src/pages/job/dispute/dispute/dispute';
import { DashboardPage } from 'src/pages/dashboard/dashboard';

import { EditClientPaymentSettingPage } from 'src/pages/client-payment-settings/edit-client-payment-setting/edit-client-payment-setting';
import { EditPrivateAddressPage } from 'src/pages/private-client/edit-private-address/edit-private-address';
import { EditPrivateJobPricePage } from 'src/pages/private-job/edit-private-job-price/edit-private-job-price';
import { EditAccessNotesPage } from 'src/pages/private-client/edit-access-notes/edit-access-notes';
import { EditPrivateClientInfoPage } from 'src/pages/private-client/edit-private-client-info/edit-private-client-info';
import { EditPrivatePlanPage } from 'src/pages/private-client/edit-private-plan/edit-private-plan';
import { EditSchedulePage } from 'src/pages/my-schedule/edit-schedule/edit-schedule';
import { EmailVerificationPage } from 'src/pages/email-confirmations/email-verification/email-verification';
import { ExportCalendarPage } from 'src/pages/export-calendar/export-calendar';
import { EditLanguagePage } from 'src/pages/edit-language/edit-language';

import { FooterInformationsComponent } from 'src/shared/components/private-job/footer-informations/footer-informations';
import { ForgotPasswordPage } from 'src/pages/login/forgot-password/forgot-password';
import { FutureJobPage } from 'src/pages/job/future-job/future-job';
import { FeedbackPage } from 'src/pages/feedback/feedback';

import { GoLivePage } from 'src/pages/go-live/go-live';

import { IncompleteRegisterPage } from 'src/pages/incomplete-register/incomplete-register';
import { IntroductionPage } from 'src/pages/login/introduction/introduction';
import { InvoicesPage } from 'src/pages/private-payments/invoices/invoices';
import { ItemDetailPage } from 'src/pages/payouts/item-detail/item-detail';

import { JobMileagePage } from 'src/pages/mileage-tracking/job-mileage/job-mileage';
import { JobsDetailsSectionComponent } from 'src/shared/components/private-job/jobs-details-section/jobs-details-section';
import { JobsPage } from 'src/pages/jobs/jobs';
import { JobMessagesPage } from 'src/pages/messages/job-messages/job-messages';

import { LearnAboutTidyPage } from 'src/pages/learn-about-tidy/learn-about-tidy';
import { LoginPage } from 'src/pages/login/login/login';
import { LostItemPage } from 'src/pages/job/lost-item/lost-item';
import { LostItemSuccessPage } from 'src/pages/job/lost-item/lost-item-success/lost-item-success';

import { MessagesPage } from 'src/pages/messages/messages';
import { MileageTrackingPage } from 'src/pages/mileage-tracking/mileage-tracking';
import { MySchedulePage } from 'src/pages/my-schedule/my-schedule';
import { MorePage } from 'src/pages/more/more';
import { MyAccountPage } from 'src/pages/my-account/my-account';
import { DeleteAccountPage } from 'src/pages/delete-account/delete-account';

import { NoDisputePage } from 'src/pages/job/dispute/no-dispute/no-dispute';
import { NotificationSettingsPage } from 'src/pages/notification-settings/notification-settings';
import { NotificationSettingsDayPage } from 'src/pages/notification-settings/notification-settings-day/notification-settings-day';

import { OnboardingAccountInfoPage } from 'src/pages/onboarding/account-info/account-info';
import { OnboardingBackgroundCheckPage } from 'src/pages/onboarding/background-check/background-check';
import { OnboardingCreateAccountPage } from 'src/pages/onboarding/create-account/create-account';
import { OnboardingEquipmentPage } from 'src/pages/onboarding/equipment/equipment';
import { OnboardingExistingClientsPage } from 'src/pages/onboarding/existing-clients/existing-clients';
import { OnboardingLearnClientsFromTidyPage } from 'src/pages/onboarding/learn-clients-from-tidy/learn-clients-from-tidy';
import { OnboardingMakeBookablePage } from 'src/pages/onboarding/make-bookable/make-bookable';
import { OnboardingNewClientsPage } from 'src/pages/onboarding/new-clients/new-clients';
import { OnboardingReviewTermsPage } from 'src/pages/onboarding/review-terms/review-terms';
import { OnboardingValidateEmailPage } from 'src/pages/onboarding/validate-email/validate-email';
import { OnboardingValidatePhonePage } from 'src/pages/onboarding/validate-phone/validate-phone';
import { OnboardingInstantCertification } from 'src/pages/onboarding/equipment/instant-certification/instant-certification';
import { OnboardingReceivePaymentsFromClientsPage } from 'src/pages/onboarding/receive-payments-from-clients/receive-payments-from-clients';
import { OtherOptionsPage } from 'src/pages/add/other-options/other-options';

import { PastJobMessagesPage } from 'src/pages/messages/past-job-messages/past-job-messages';
import { PrivateJobMessagesPage } from 'src/pages/messages/private-job-messages/private-job-messages';
import { ParkingReimbursementPage } from 'src/pages/parking-reimbursement/parking-reimbursement';
import { PasswordUpdatePage } from 'src/pages/email-confirmations/password-update/password-update';
import { PastJobPage } from 'src/pages/job/past-job/past-job';
import { PastPayoutPage } from 'src/pages/payouts/past-payout/past-payout';
import { PauseScheduleDatePage } from 'src/pages/my-schedule/pause-schedule/pause-schedule-date/pause-schedule-date';
import { PauseSchedulePage } from 'src/pages/my-schedule/pause-schedule/pause-schedule';
import { PayoutSettingsPage } from 'src/pages/payouts/payout-settings/payout-settings';
import { PayoutSettingsEditPage } from 'src/pages/payouts/payout-settings-edit/payout-settings-edit';
import { PayoutSettingsDetailPage } from 'src/pages/payouts/payout-settings-detail/payout-settings-detail';
import { PendingDecertificationPage } from 'src/pages/pending-decertification/pending-decertification';
import { PayoutsPage } from 'src/pages/payouts/payouts';
import { PrivateCancellationPage } from 'src/pages/private-job/private-cancellation/private-cancellation';
import { PrivateChangeStatusPage } from 'src/pages/private-job/private-change-status/private-change-status';
import { PrivateClientPage } from 'src/pages/private-client/private-client';
import { PrivateFutureJobsPage } from 'src/pages/private-client/future-jobs/future-jobs';
import { PrivateJobPage } from 'src/pages/private-job/private-job';
import { PrivatePastJobsPage } from 'src/pages/private-client/past-jobs/past-jobs';
import { PrivateRescheduleAskSubjectPage } from 'src/pages/private-job/private-reschedule/private-reschedule-ask-subject/private-reschedule-ask-subject';
import { PrivateRescheduleConfirmPage } from 'src/pages/private-job/private-reschedule/private-reschedule-confirm/private-reschedule-confirm';
import { PrivateRescheduleFormComponent } from 'src/shared/components/private-job/private-reschedule-form/private-reschedule-form';
import { PrivateReschedulePage } from 'src/pages/private-job/private-reschedule/private-reschedule';
import { PrivateServicesPage } from 'src/pages/services/private-services/private-services';
import { ProfileAddressPage } from 'src/pages/profile/address/profile-address';
import { ProfileEmailPage } from 'src/pages/profile/email/profile-email';
import { ProfileTelephonePage } from 'src/pages/profile/telephone/profile-telephone';
import { ProfileTravelPage } from 'src/pages/profile/travel-time/profile-travel-time';
import { ProfileContactInfoPage } from 'src/pages/profile/contact-info/contact-info';
import { ProfileLocationInfoPage } from 'src/pages/profile/location-info/location-info';
import { ProposalPage } from 'src/pages/proposals/proposal/proposal';
import { ProposalsPage } from 'src/pages/proposals/proposals/proposals';
import { ProfileBlockZipcodePage } from 'src/pages/profile/location-info/block-zipcode/block-zipcode';

import { QuestsPage } from 'src/pages/quests/quests';

import { ReapplyPage } from 'src/pages/reapply/reapply';
import { RedoAlertSectionComponent } from 'src/shared/components/private-job/redo-alert-section/redo-alert-section';
import { RecordPaymentPage } from 'src/pages/private-payments/record-payment/record-payment';
import { RequestPaymentPage } from "src/pages/private-payments/request-payment/request-payment";
import { InvalidLocationPermissionPage } from 'src/pages/request-location-permission/invalid-location-permission.page';
import { RefundChargePage } from 'src/pages/private-payments/refund-charge/refund-charge';
import { RetentionScorePage } from 'src/pages/feedback/retention-score/retention-score';
import { ReviewsPage } from 'src/pages/reviews/reviews';
import { ReportIssuePage } from 'src/pages/report-issue/report-issue';
import { RescheduleCancelPage } from 'src/pages/reschedule-cancel/reschedule-cancel';

import { SentInvoicePage } from 'src/pages/private-payments/sent-invoice/sent-invoice';
import { ServicesPage } from 'src/pages/services/services';
import { SendInvoicePage } from "src/pages/private-payments/send-invoice/send-invoice";
import { ServicePage } from 'src/pages/services/service/service';
import { SendProposalPage } from 'src/pages/proposals/send-proposal/send-proposal';
import { StartEarningPage } from 'src/pages/start-earning/start-earning';
import { SuccessPage } from 'src/pages/success/success';
import { SearchPage } from 'src/pages/search/search';
import { SharedToDoListPage } from 'src/pages/shared-to-do-list/shared-to-do-list';
import { SharedCompleteToDoListPage } from 'src/pages/shared-to-do-list/shared-complete-to-do-list/shared-complete-to-do-list';
import { SharedJobPage } from 'src/pages/shared-job/shared-job';
import { SharedContactClientPage } from 'src/pages/shared-contact-client/shared-contact-client';
import { SharedCompleteJobPage } from 'src/pages/shared-job/shared-complete-job/shared-complete-job';

import { TeamIAmOnPage } from 'src/pages/team/team-i-am-on/team-i-am-on';
import { TeamMemberPage } from 'src/pages/team/team-member/team-member';
import { TeamMemberInvitePage } from 'src/pages/team/team-member-invite/team-member-invite';
import { TeamMemberInvitedPage } from 'src/pages/team/team-member-invited/team-member-invited';
import { TeamPage } from 'src/pages/team/team';
import { TidyHomekeeperAppPage } from 'src/app/app.component';

import { UndoCancellationPage } from 'src/pages/undo-cancellation/undo-cancellation';
import { UnpaidJobsPage } from 'src/pages/unpaid-jobs/unpaid-jobs';
import { UnpausePage } from 'src/pages/unpause/unpause';
import { UnpauseSchedulePage } from 'src/pages/unpause/unpause-update-schedule/unpause-update-schedule';
import { UnpauseAddressPage } from 'src/pages/unpause/unpause-update-address/unpause-update-address';
import { UpcomingFeaturePage } from 'src/pages/upcoming-feature/upcoming-feature';

import { WaiveFeePage } from 'src/pages/payouts/waive-fee/waive-fee';
import { WebsiteBusinessDetailPage } from 'src/pages/website/website-business-detail/website-business-detail';
import { WebsitePage } from 'src/pages/website/website';
import { WebsiteUrlPage } from 'src/pages/website/website-url/website-url';

//Main Workflow
import { MWCanEnterGuard } from 'src/main-workflow/guards/mw.can-enter.guard';

import { ReadyToLeavePage } from 'src/main-workflow/pages/ready-to-leave/mw.ready-to-leave.page';
import { OnTheWayPage } from 'src/main-workflow/pages/on-the-way/mw.on-the-way.page';
import { ArrivedAtJobPage } from 'src/main-workflow/pages/arrived-at-job/mw.arrived-at-job.page';
import { ToDosPage } from 'src/main-workflow/pages/to-dos/mw.to-dos.page';
import { ClientRefusedServicePage } from 'src/main-workflow/pages/client-refused-service/mw.client-refused-service.page';
import { CantAccessPage } from 'src/main-workflow/pages/cant-access/mw.cant-access.page';
import { FinishEarlyPage } from 'src/main-workflow/pages/finish-early/mw.finish-early.page';
import { FinishFarFromClientPage } from 'src/main-workflow/pages/finish-far-from-client/mw.finish-far-from-client.page';
import { FinishFarFromClientPhotoPage } from 'src/main-workflow/pages/finish-far-from-client/finish-far-from-client-photo/mw.finish-far-from-client-photo.page';
import { FinishJobPage } from 'src/main-workflow/pages/finish-job/mw.finish-job.page';
import { JobSyncProblemPage } from 'src/main-workflow/pages/job-sync-problem/mw.job-sync-problem.page';
import { ExitJobPage } from 'src/main-workflow/pages/exit-job/mw.exit-job.page';
import { LearnAboutTeamsPage } from 'src/main-workflow/pages/learn-about-teams/mw.learn-about-teams.page';
import { OtherJobInProgressPage } from 'src/main-workflow/pages/other-job-in-progress/mw.other-job-in-progress.page';

import { MWCallOutPage } from 'src/main-workflow/pages/call-out/mw.call-out.page';


//Concierge
import { ConciergePage } from 'src/pages/concierge/concierge';
import { ContactConciergePage } from 'src/pages/concierge/contact-concierge/contact-concierge';
import { ConciergeMessagePage } from 'src/pages/concierge/concierge-message/concierge-message';
import { ConciergeReplyPage } from 'src/pages/concierge/concierge-message/concierge-reply/concierge-reply';
import { GetHelpPage } from 'src/pages/concierge/get-help/get-help';
import { JobIssuePage } from 'src/pages/concierge/job-issue/job-issue';
import { WrongStatusPage } from 'src/pages/concierge/wrong-status/wrong-status';
import { DisputeResolutionPage } from 'src/pages/concierge/dispute-resolution/dispute-resolution';
import { CounterDisputePage } from 'src/pages/concierge/dispute-resolution/counter-dispute/counter-dispute';
import { JobsCalendarComponent } from 'src/pages/jobs/jobs-calendar-component/jobs-calendar.component';
import { PastWeeklyJobHistoryPage } from 'src/pages/past-weekly-job-history/past-weekly-job-history';
import { IdentityVerificationModalComponent } from 'src/pages/onboarding/identity-verification-modal/identity-verification';
import { CustomNotificationsPage } from 'src/pages/custom-notifications/custom-notifications';
import { EditDistanceFormatPage } from 'src/pages/edit-distance-format/edit-distance-format';

export const pages = [

  AcceptRejectJobPage,
  AddPage,
  ActiveInCommunityPage,
  AddClientPaymentSettingPage,
  AddCreditPage,
  AddPrivateClientPage,
  AddCommunityReviewPage,
  AddPrivateJobConfirmationPage,
  AddPrivateJobDetailsPage,
  AddPrivateJobPage,
  AddPrivateAddressPage,
  AddServicePage,
  AdvancedSettingsPage,
  AgreementManagementPage,

  BookingRestrictionsPage,

  CalendarComponent,
  ContactSupportPage,
  CompleteJobPage,
  ClientMessagesPage,
  CheckForUpdatesPage,
  ClaimJobPage,
  ClientAndAdressSectionComponent,
  ClientAddedPage,
  ClientBillingHistoryPage,
  ClientBillingOptionsPage,
  ClientRequestCounterPage,
  ClientRequestPage,
  ClientsFromTidyFeesPage,
  ClientsFromTidyServicesPage,
  ClientsFromTidyServicesPreviewPage,
  ClientsPage,
  ClientPaymentSettingsPage,
  ConfirmFeeWaivePage,
  ConfirmSendProposalPage,
  ConfirmPage,
  ConfirmSchedulePage,
  CheckInCallOutPage,
  CheckInPage,
  CheckInLearnAboutTeamsPage,
  ConfirmAddressCancellationPage,
  ConfirmTravelTimeCancellationPage,
  CurrentBalancePage,

  DesktopWarningPage,
  DelegateJobPage,
  DisputePage,
  DashboardPage,

  EditClientPaymentSettingPage,
  EditPrivateAddressPage,
  EditPrivateJobPricePage,
  EditAccessNotesPage,
  EditPrivateClientInfoPage,
  EditPrivatePlanPage,
  EditSchedulePage,
  EmailVerificationPage,
  ExportCalendarPage,
  EditLanguagePage,

  FooterInformationsComponent,
  ForgotPasswordPage,
  FutureJobPage,
  FeedbackPage,

  GoLivePage,

  IncompleteRegisterPage,
  IntroductionPage,
  InvoicesPage,
  ItemDetailPage,

  JobMileagePage,
  JobsDetailsSectionComponent,
  JobsPage,
  JobMessagesPage,

  LearnAboutTeamsPage,
  LearnAboutTidyPage,
  LoginPage,
  LostItemPage,
  LostItemSuccessPage,

  MessagesPage,
  MileageTrackingPage,
  MySchedulePage,
  MorePage,
  MyAccountPage,
  DeleteAccountPage,

  NoDisputePage,
  NotificationSettingsPage,
  NotificationSettingsDayPage,

  OnboardingBackgroundCheckPage,
  OnboardingAccountInfoPage,
  OnboardingCreateAccountPage,
  OnboardingEquipmentPage,
  OnboardingExistingClientsPage,
  OnboardingLearnClientsFromTidyPage,
  OnboardingMakeBookablePage,
  OnboardingNewClientsPage,
  OnboardingReviewTermsPage,
  OnboardingValidateEmailPage,
  OnboardingValidatePhonePage,
  OnboardingInstantCertification,
  OnboardingReceivePaymentsFromClientsPage,
  OtherOptionsPage,

  PastJobPage,
  PastJobMessagesPage,
  PrivateJobMessagesPage,
  ParkingReimbursementPage,
  PasswordUpdatePage,
  PastPayoutPage,
  PauseScheduleDatePage,
  PauseSchedulePage,
  PayoutSettingsPage,
  PayoutSettingsEditPage,
  PayoutSettingsDetailPage,
  PendingDecertificationPage,
  PayoutsPage,
  PrivateCancellationPage,
  PrivateChangeStatusPage,
  PrivateClientPage,
  PrivateFutureJobsPage,
  PrivateJobPage,
  PrivatePastJobsPage,
  PrivateRescheduleAskSubjectPage,
  PrivateRescheduleConfirmPage,
  PrivateRescheduleFormComponent,
  PrivateReschedulePage,
  PrivateServicesPage,
  ProfileEmailPage,
  ProfileAddressPage,
  ProfileContactInfoPage,
  ProfileTelephonePage,
  ProfileTravelPage,
  ProfileLocationInfoPage,
  ProposalPage,
  ProposalsPage,
  ProfileBlockZipcodePage,

  QuestsPage,

  ReapplyPage,
  RedoAlertSectionComponent,
  RecordPaymentPage,
  RequestPaymentPage,
  InvalidLocationPermissionPage,
  RefundChargePage,
  RetentionScorePage,
  ReviewsPage,
  ReportIssuePage,
  RescheduleCancelPage,

  SentInvoicePage,
  SearchPage,
  SendInvoicePage,
  SendProposalPage,
  ServicesPage,
  ServicePage,
  SharedToDoListPage,
  SharedCompleteToDoListPage,
  SharedJobPage,
  SharedContactClientPage,
  SharedCompleteJobPage,
  StartEarningPage,
  SuccessPage,

  TeamIAmOnPage,
  TeamMemberPage,
  TeamMemberInvitePage,
  TeamMemberInvitedPage,
  TeamPage,
  TidyHomekeeperAppPage,

  UndoCancellationPage,
  UnpaidJobsPage,
  UnpausePage,
  UnpauseSchedulePage,
  UnpauseAddressPage,
  UpcomingFeaturePage,

  WaiveFeePage,
  WebsiteBusinessDetailPage,
  WebsitePage,
  WebsiteUrlPage,

  //Main Workflow
  ReadyToLeavePage,
  OnTheWayPage,
  ArrivedAtJobPage,
  ToDosPage,
  ClientRefusedServicePage,
  CantAccessPage,
  FinishEarlyPage,
  FinishFarFromClientPage,
  FinishFarFromClientPhotoPage,
  FinishJobPage,
  JobSyncProblemPage,
  MWCallOutPage,
  ExitJobPage,
  OtherJobInProgressPage,

  ConciergePage,
  ContactConciergePage,
  ConciergeMessagePage,
  ConciergeReplyPage,
  GetHelpPage,
  JobIssuePage,
  WrongStatusPage,
  DisputeResolutionPage,
  CounterDisputePage,

  JobsCalendarComponent,
  PastWeeklyJobHistoryPage,
  IdentityVerificationModalComponent,
  CustomNotificationsPage,
  EditDistanceFormatPage,
];

const routesConfig: any = [

  { component: EditDistanceFormatPage, path: 'distance-format'},
  { component: PastWeeklyJobHistoryPage, path: 'past-weekly-job-history'},
  { component: AcceptRejectJobPage, path: 'accept-reject-job'},
  { component: AddPage, path: 'add' },
  { component: AddCreditPage, path: 'add-credit' },
  { component: ActiveInCommunityPage, path: 'active-in-community'},
  { component: AddPrivateClientPage, path: 'add-private-client'},
  { component: AddClientPaymentSettingPage, path: 'add-client-payment-setting'},
  { component: AddCommunityReviewPage, path: 'add-community-review/:destination'},
  { component: AddPrivateJobConfirmationPage, path: 'add-private-job-confirmation', redirectWhenIncompleteRegister: true, data: { title: 'add-job', requirement: 'existing_clients' }},
  { component: AddPrivateJobDetailsPage, path: 'add-private-job-details', redirectWhenIncompleteRegister: true, data: { title: 'add-job', requirement: 'existing_clients' }},
  { component: AddPrivateJobPage, path: 'add-private-job', redirectWhenIncompleteRegister: true, data: { title: 'add-job', requirement: 'existing_clients' }},
  { component: AddPrivateJobPage, path: 'add-private-job/:clientId', redirectWhenIncompleteRegister: true, data: { title: 'add-job', requirement: 'existing_clients' }},
  { component: AddPrivateAddressPage, path: 'add-private-address'},
  { component: AddServicePage, path: 'add-service'},
  { component: AdvancedSettingsPage, path: 'clients-from-tidy-settings'},
  { component: AgreementManagementPage, path: 'agreement-management'},

  { component: BookingRestrictionsPage, path: 'booking-restrictions', redirectWhenIncompleteRegister: true, data: { title: 'booking-restrictions', requirement: 'go_live' }},

  { component: ClientMessagesPage, path: 'client-messages/:clientId'},
  { component: CheckForUpdatesPage, path: 'check-for-updates'},
  { component: ClaimJobPage, path: 'claim-job'},
  { component: ClaimJobPage, path: 'claim-job/:cleaningId'},
  { component: ClientAddedPage, path: 'client-added'},
  { component: ClientBillingHistoryPage, path: 'client-billing-history/:clientId'},
  { component: ClientBillingOptionsPage, path: 'client-billing-options/:clientId'},
  { component: ClientRequestCounterPage, path: 'client-request-counter'},
  { component: ClientRequestPage, path: 'client-request'},
  { component: ClientsFromTidyFeesPage, path: 'clients-from-tidy-fees-and-booking-protection'},
  { component: ClientsFromTidyServicesPage, path: 'clients-from-tidy-services'},
  { component: ClientsFromTidyServicesPreviewPage, path: 'clients-from-tidy-services/preview-change' },
  { component: ClientsPage, path: 'clients', data: { title: 'private-clients', requirement: 'existing_clients' }},
  { component: ClientPaymentSettingsPage, path: 'client-payment-settings'},
  { component: CompleteJobPage, path: 'complete-job', data: { title: "complete-job"} },
  { component: ConfirmFeeWaivePage, path: 'confirm-fee-waive'},
  { component: ConfirmSendProposalPage, path: 'confirm-send-proposal' },
  { component: ConfirmPage, path: 'confirm' },
  { component: ConfirmSchedulePage, path: 'confirm-schedule'},
  { component: ContactSupportPage, path: 'contact-support'},
  { component: CheckInCallOutPage, path: 'check-in-call-out'},
  { component: CheckInPage, path: 'check-in'},
  { component: CheckInLearnAboutTeamsPage, path: 'check-in-learn-about-teams'},
  { component: ConfirmAddressCancellationPage, path: 'profile/address/confirm-address-schedule' },
  { component: ConfirmTravelTimeCancellationPage, path: 'profile/travel-time/confirm-schedule' },

  { component: DesktopWarningPage, path: 'desktop-warning'},
  { component: DelegateJobPage, path: 'delegate-job'},
  { component: DisputePage, path: 'dispute'},
  { component: DashboardPage, path: 'dashboard', syncData: true },

  { component: EditClientPaymentSettingPage, path: 'edit-client-payment-setting'},
  { component: EditPrivateAddressPage, path: 'edit-private-address'},
  { component: EditPrivateJobPricePage, path: 'edit-private-job-price'},
  { component: EditAccessNotesPage, path: 'edit-access-notes'},
  { component: EditPrivateClientInfoPage, path: 'edit-private-client-info'},
  { component: EditPrivatePlanPage, path: 'edit-private-plan'},
  { component: EditSchedulePage, path: 'edit-schedule'},
  { component: EmailVerificationPage, path: 'email/:type/:token', public: true },
  { component: ExportCalendarPage, path: 'export-calendar'},
  { component: EditLanguagePage, path: 'edit-language'},

  { component: ForgotPasswordPage, path: 'forgot-password', public: true, skipWhenAuthenticated: true },
  { component: FutureJobPage, path: 'future-job'},
  { component: FeedbackPage, path: 'feedback'},

  { component: GoLivePage, path: 'go-live'},

  { component: IncompleteRegisterPage, path: 'complete-your-register/:pageTitle/:requirement' },
  { component: IntroductionPage, path: 'introduction', public: true, skipWhenAuthenticated: true },
  { component: InvalidLocationPermissionPage, path: 'location-warning'},
  { component: InvoicesPage, path: 'invoices/:clientId'},
  { component: ItemDetailPage, path: 'item-detail'},

  { component: JobMileagePage, path: 'job-mileage' },
  { component: JobsPage, path: 'jobs', syncData: true },
  { component: JobsPage, path: 'jobs/:redirectJobId' },
  { component: JobMessagesPage, path: 'job-messages' },

  { component: LearnAboutTeamsPage, path: 'learn-about-teams'},
  { component: LearnAboutTidyPage, path: 'learn-about-tidy'},
  { component: LoginPage, path: 'login', public: true, skipWhenAuthenticated: true },
  { component: LoginPage, path: 'login/:token', public: true },
  { component: LostItemPage, path: 'lost-item/:jobId/:hkJobId'},
  { component: LostItemSuccessPage, path: 'lost-item-success'},

  { component: MessagesPage, path: 'messages'},
  { component: MySchedulePage, path: 'bookable-hours', data: { title: 'schedule', requirement: 'bookable_times' }},
  { component: MileageTrackingPage, path: 'mileage-tracking'},
  { component: MorePage, path: 'more' },
  { component: MyAccountPage, path: 'my-account' },
  { component: DeleteAccountPage, path: 'delete-account' },

  { component: NoDisputePage, path: 'no-dispute'},
  { component: NotificationSettingsPage, path: 'old-notification-settings'},
  { component: NotificationSettingsDayPage, path: 'notification-settings-day'},

  { component: OnboardingBackgroundCheckPage, path: 'background-check'},
  { component: OnboardingAccountInfoPage, path: 'account-info'},
  { component: OnboardingCreateAccountPage, path: 'create-account', public: true, skipWhenAuthenticated: true },
  { component: OnboardingCreateAccountPage, path: 'create-account/:email', public: true, skipWhenAuthenticated: true },
  { component: OnboardingCreateAccountPage, path: 'create-account/:email/:emailValidationToken', public: true, skipWhenAuthenticated: true },
  { component: OnboardingEquipmentPage, path: 'equipment'},
  { component: OnboardingExistingClientsPage, path: 'existing-clients'},
  { component: OnboardingLearnClientsFromTidyPage, path: 'learn-clients-from-tidy'},
  { component: OnboardingMakeBookablePage, path: 'make-bookable'},
  { component: OnboardingNewClientsPage, path: 'new-clients'},
  { component: OnboardingReviewTermsPage, path: 'review-terms'},
  { component: OnboardingValidateEmailPage, path: 'validate-email'},
  { component: OnboardingValidatePhonePage, path: 'validate-phone'},
  { component: OnboardingInstantCertification, path: 'instant-certification'},
  { component: OnboardingReceivePaymentsFromClientsPage, path: 'receive-payments-from-clients'},
  { component: OtherOptionsPage, path: 'other-options'},

  { component: PastJobPage, path: 'past-job/:jobId'},
  { component: PastJobMessagesPage, path: 'past-job-messages/:jobId'},
  { component: PrivateJobMessagesPage, path: 'private-job-messages/:jobId'},
  { component: ParkingReimbursementPage, path: 'parking-reimbursement/:jobId'},
  { component: PasswordUpdatePage, path: 'password-update/:token', public: true },
  { component: PastPayoutPage, path: 'past-payout/:paymentId'},
  { component: PauseScheduleDatePage, path: 'pause-schedule-date'},
  { component: PauseSchedulePage, path: 'pause-schedule'},
  { component: PayoutSettingsPage, path: 'payout-settings'},
  { component: PayoutSettingsEditPage, path: 'edit-payout-settings'},
  { component: PayoutSettingsDetailPage, path: 'payout-settings-detail'},
  { component: PendingDecertificationPage, path: 'pending-decertification'},
  { component: PayoutsPage, path: 'payouts'},
  { component: PrivateCancellationPage, path: 'private-cancellation/:cancelType/:srcId'},
  { component: PrivateChangeStatusPage, path: 'private-change-status/:jobId'},
  { component: PrivateClientPage, path: 'private-client/:clientId'},
  { component: PrivateFutureJobsPage, path: 'future-jobs'},
  { component: PrivateJobPage, path: 'private-job/:jobId'},
  { component: PrivatePastJobsPage, path: 'past-jobs/:addressId'},
  { component: PrivateRescheduleAskSubjectPage, path: 'private-reschedule-ask-subject'},
  { component: PrivateRescheduleConfirmPage, path: 'private-reschedule-confirm'},
  { component: PrivateReschedulePage, path: 'private-reschedule'},
  { component: PrivateServicesPage, path: 'private-services'},
  { component: ProfileAddressPage, path: 'profile/address' },
  { component: ProfileContactInfoPage, path: 'profile/contact', data: { title: 'contact-info', requirement: 'existing_clients' } },
  { component: ProfileEmailPage, path: 'profile/email' },
  { component: ProfileLocationInfoPage, path: 'profile/location', redirectWhenIncompleteRegister: true, data: { title: 'locations-i-serve', requirement: 'existing_clients' } },
  { component: ProfileBlockZipcodePage, path: 'profile/location/block-zipcode', redirectWhenIncompleteRegister: true, data: { title: 'block-zipcode', requirement: 'existing_clients' } },
  { component: ProfileTelephonePage, path: 'profile/phone' },
  { component: ProfileTravelPage, path: 'profile/travel-time' },
  { component: ProposalPage, path: 'proposal/:proposalId' },
  { component: ProposalsPage, path: 'proposals' },
  { component: ProposalsPage, path: 'proposals/:clientId' },

  { component: QuestsPage, path: 'quests' },


  { component: RefundChargePage, path: 'refund-charge'},
  { component: ReapplyPage, path: 'reapply'},
  { component: RecordPaymentPage, path: 'record-payment'},
  { component: RetentionScorePage, path: 'retention-score'},
  { component: RequestPaymentPage, path: 'request-payment'},
  { component: ReviewsPage, path: 'reviews'},
  { component: ReportIssuePage, path: 'report-issue', public: true},
  { component: RescheduleCancelPage, path: 'reschedule-cancel'},

  { component: SentInvoicePage, path: 'sent-invoice'},
  { component: ServicesPage, path: 'services'},
  { component: ServicePage, path: 'service'},
  { component: SendProposalPage, path: 'send-proposal', redirectWhenIncompleteRegister: true, data: { title: 'send-proposal', requirement: 'existing_clients' } },
  { component: SendProposalPage, path: 'send-proposal/:clientId' },
  { component: StartEarningPage, path: 'start-earning' },
  { component: SuccessPage, path: 'success', public: true },
  { component: SearchPage, path: 'search' },
  { component: SendInvoicePage, path: 'send-invoice' },
  { component: SharedToDoListPage, path: 'to-do-list/:uuid', public: true },
  { component: SharedToDoListPage, path: 'shared-to-do-list/:uuid', public: true },
  { component: SharedCompleteToDoListPage, path: 'complete-to-do-list', public: true },
  { component: SharedJobPage, path: 'job/:uuid', public: true },
  { component: SharedJobPage, path: 'shared-job/:uuid', public: true },
  { component: SharedContactClientPage, path: 'shared-contact-client', public: true },
  { component: SharedCompleteJobPage, path: 'shared-complete-job', public: true },

  { component: TeamIAmOnPage, path: 'team-i-am-on'},
  { component: TeamMemberPage, path: 'team-member'},
  { component: TeamMemberInvitePage, path: 'team-member-invite'},
  { component: TeamMemberInvitedPage, path: 'team-member-invited'},
  { component: TeamPage, path: 'team'},
  { component: TidyHomekeeperAppPage, path: 'tidy-homekeeper-app'},
  { component: CurrentBalancePage, path: 'current-balance'},

  { component: UnpaidJobsPage, path: 'unpaid-jobs'},
  { component: UndoCancellationPage, path: 'undo-cancellation/:jobId'},
  { component: UnpausePage, path: 'unpause'},
  { component: UnpauseSchedulePage, path: 'unpause/update-schedule'},
  { component: UnpauseAddressPage, path: 'unpause/update-address'},
  { component: UpcomingFeaturePage, path: 'upcoming-feature'},

  { component: WaiveFeePage, path: 'waive-fee'},
  { component: WebsiteBusinessDetailPage, path: 'website-business-detail'},
  { component: WebsitePage, path: 'website', redirectWhenIncompleteRegister: true, data: { title: 'my-website', requirement: 'existing_clients' }},
  { component: WebsiteUrlPage, path: 'website-url'},

  //Main Workflow
  { component: MWCallOutPage, path: 'cancel-job'},
  { component: ClientRefusedServicePage, path: 'client-refused-service'},
  { component: FinishEarlyPage, path: 'finish-early'},
  { component: FinishFarFromClientPage, path: 'finish-far-from-client'},
  { component: FinishFarFromClientPhotoPage, path: 'finish-far-from-client-photo'},
  { component: JobSyncProblemPage, path: 'job-sync-problem'},
  { component: ExitJobPage, path: 'exit-job'},
  { component: ReadyToLeavePage, path: 'ready-to-leave', canActivate: [MWCanEnterGuard]},
  { component: OnTheWayPage, path: 'on-the-way', canActivate: [MWCanEnterGuard]},
  { component: ArrivedAtJobPage, path: 'arrived-at-job', canActivate: [MWCanEnterGuard]},
  { component: ToDosPage, path: 'to-dos', canActivate: [MWCanEnterGuard]},
  { component: CantAccessPage, path: 'cant-access', canActivate: [MWCanEnterGuard]},
  { component: FinishJobPage, path: 'finish-job', canActivate: [MWCanEnterGuard]},
  { component: OtherJobInProgressPage, path: 'other-job-in-progress'},

  //Concierge
  { component: ConciergePage, path: 'concierge'},
  { component: ContactConciergePage, path: 'contact-concierge'},
  { component: ConciergeMessagePage, path: 'concierge-message/:conciergeItemId'},
  { component: ConciergeReplyPage, path: 'concierge-reply'},
  { component: GetHelpPage, path: 'get-help'},
  { component: JobIssuePage, path: 'job-issue'},
  { component: WrongStatusPage, path: 'wrong-status'},
  { component: DisputeResolutionPage, path: 'dispute-resolution/:disputeItemId'},
  { component: CounterDisputePage, path: 'counter-dispute/:disputeItemId'},

  { component: CustomNotificationsPage, path: 'notification-settings'},

  { path: '', pathMatch: 'full', redirectTo: 'dashboard', public: true }
]

routesConfig.forEach(configureRouteGuards);

export const routes: Routes = routesConfig
