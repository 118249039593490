<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [customBack]="'/more'"
    [canGoBack]="true"
    [title]="'Private Clients'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Your data will be kept private and your Clients will remain your Clients. '">
        </tidy-text>
        <tidy-text
          [body]="'Learn More'"
          (action)="learnMore()"
          class="link">
        </tidy-text>
      </tidy-row>

      <ng-container *ngFor="let client of clients">
        <tidy-card>
          <tidy-card-button (action)="goToClient(client)">
            <tidy-row class="vertical-align-center">
              <tidy-image
                src="assets/style-guide-images/private-client-black-white.svg"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="(client?.account_name !== '' && client?.account_name) ?
                client?.account_name :
                (client?.first_name + ' ' + (client?.last_name | abbrLastName: true))"
                [translate]="false">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
      </ng-container>

      <tidy-alert *ngIf="clients.length === 0">
        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'You haven\'t added any Clients.'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <tidy-button
        [text]="'Add Client'"
        [action]="addClient.bind(this)"
        class="primary">
      </tidy-button>

      <tidy-row [align]="'center'" class="extra-top-padding">
        <tidy-text
          [title]="'Why Add Clients?'">
        </tidy-text>
      </tidy-row>

      <tidy-alert style="padding: 15px 15px 15px 10px" *ngFor="let card of noClientsCards; let last = last; let first = first">
          <tidy-grid>
            <tidy-row>
              <ion-row>
                <ion-col size="2" class="vertical-align-center">
                  <div style="margin: auto">
                    <tidy-image
                      [src]="card?.image"
                      style="width:35px">
                    </tidy-image>
                  </div>
                </ion-col>
                <ion-col>
                  <tidy-row>
                    <tidy-text
                      [header]="card?.title">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [body]="card?.text">
                    </tidy-text>
                  </tidy-row>
                </ion-col>
              </ion-row>
            </tidy-row>
          </tidy-grid>
      </tidy-alert>

    </ng-container>

    <ng-container *ngIf="!loaded">
      <tidy-card>
        <tidy-card-button [hideArrow]="true">
         <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-card-button [hideArrow]="true">
         <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-card-button [hideArrow]="true">
         <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-card-button [hideArrow]="true">
         <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-card-button [hideArrow]="true">
         <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-card-button [hideArrow]="true">
         <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-card-button [hideArrow]="true">
         <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-card-button [hideArrow]="true">
         <ion-skeleton-text animated style="width: 45%"></ion-skeleton-text>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-card-button [hideArrow]="true">
         <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-card-button [hideArrow]="true">
         <ion-skeleton-text animated style="width: 83%"></ion-skeleton-text>
        </tidy-card-button>
      </tidy-card>

      <tidy-row>
        <ion-skeleton-text animated style="height: 70px"></ion-skeleton-text>
      </tidy-row>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
