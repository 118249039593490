import { Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConciergeItemModel } from 'src/models/concierge.model';

import { Concierge } from 'src/providers/concierge/concierge';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { Logger } from 'src/shared/providers/logger';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Component({
  templateUrl: 'counter-dispute.html',
  encapsulation: ViewEncapsulation.None
})

export class CounterDisputePage extends Timeout {

  percentageMask = {
    lazy: false,
    maxLength: 3,
    mask: 'num%',
    blocks: {
      num: {
        mask: Number,
      }
    }
  };
  errorMessage: string;
  form: UntypedFormGroup;
  submitted: boolean;
  dispute: ConciergeItemModel;

  constructor(
    private concierge: Concierge,
    private logger: Logger,
    private navCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    public route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.fb.group({
      message: ['', Validators.required],
      percentage: ['', Validators.required],
    });
  }

  async ionViewDidEnter() {
    this.loaded = false;
    this.dispute = this.navCtrl.getParam('item');
    if (!this.dispute) {
      const disputeItemId = this.route.snapshot.paramMap.get('disputeItemId');
      try {
        this.dispute = await this.concierge.getConciergeItemById(disputeItemId);
      } catch (err) {
        this.timeoutHandler(err);
        this.logger.error(err);
        this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      }
    }
    this.loaded = true;
  }

  async counterDisputeProposal() {
    this.submitted = true;
    let percentage = parseInt(this.form.value.percentage, 10);
    if (!this.form.valid) {
      return;
    }
    let data = {
      concierge_item_id: this.dispute.id,
	    action_name: 'send_dispute_counter_proposal',
      params: {
        concierge_item_id: this.dispute.id,
		    concierge_item_type_component_id: this.dispute.components[0].id,
        customer_cleaning_dispute_id: this.dispute.metadata?.customer_cleaning_dispute_id,
        percentage,
        message: this.form.value.message
      }
    };
    try {
      await this.concierge.replyDisputeCounter(this.dispute.id ,data);
      this.goToSuccessPage();
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  goToSuccessPage() {
    const params = {
      header: 'Counter Proposal Sent',
      body: `We expect the client to reply within 3 days. They can accept your dispute or reject it.`,
      buttonText: 'Ok',
      buttonRoute: 'concierge'
    };
    this.navCtrl.navigateForward('success', params);
  }
}
