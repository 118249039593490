<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="false"
    [title]="'Client Added'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-card>
        <tidy-row [align]="'center'">
          <tidy-text
            [header]="'Send '">
          </tidy-text>
          <tidy-text
            [header]="client?.first_name">
          </tidy-text>
          <tidy-text
            [header]="' an Email'">
          </tidy-text>
        </tidy-row>

        <tidy-row [align]="'center'">
          <tidy-text
            [body]="client?.first_name">
          </tidy-text>
          <tidy-text
            [body]="' can book jobs, pay invoices, accept proposals and more from their personal page. You can send them an email with a link below. Here is a popular template but edit as you like:'">
          </tidy-text>
        </tidy-row>

        <tidy-row class="extra-top-padding extra-bottom-padding">
          <tidy-divider>
          </tidy-divider>
        </tidy-row>

        <form [formGroup]="form" novalidate action="#">
          <tidy-row class="field-bottom-padding field-top-padding" *ngIf="isMissingEmail">
            <tidy-input
              [label]="'Client Email'"
              formControlName="email"
              type="email"
              [form]="form.controls.email"
              [icon]="'assets/svg/mail-outline.svg'"
              [submitted]="submitted"
              [errorMessage]="'Please enter a valid email'">
            </tidy-input>
          </tidy-row>
          <tidy-row class="field-bottom-padding" [ngClass]="isMissingEmail ? '' : 'field-top-padding'">
            <tidy-textarea
              label="Subject"
              icon="assets/svg/mail-outline.svg"
              formControlName="subject"
              [form]="form.controls.subject"
              [submitted]="submitted"
              [errorMessage]="errorMessage">
            </tidy-textarea>
          </tidy-row>
          <tidy-row>
            <tidy-textarea
              label="Body"
              icon="assets/svg/information-circle-outline.svg"
              formControlName="body"
              [form]="form.controls.body"
              [submitted]="submitted"
              [errorMessage]="errorMessage">
            </tidy-textarea>
          </tidy-row>
        </form>
      </tidy-card>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-row>
        <tidy-button
          [text]="'Send Email With Link'"
          [action]="sendEmail.bind(this)"
          class="primary">
        </tidy-button>
      </tidy-row>

      <tidy-row>
        <tidy-button
          [text]="'Do This Later'"
          [action]="goToClientPage.bind(this)"
          class="secondary">
        </tidy-button>
      </tidy-row>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
