<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Scores'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">
      <ng-container>
        <tidy-card>
          <tidy-row>
            <tidy-text
              [title]="'Quality Score'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [helper]="'This score is how much clients like your cleanings. '">
            </tidy-text>
            <tidy-text
              [helper]="'Learn More'"
              (action)="learnMoreQualityScore()"
              class="link">
            </tidy-text>
          </tidy-row>
          <ng-container *ngIf="isQualityScore">
            <tidy-row class="extra-bottom-padding">
              <tidy-bar
                [value]="highlights?.dashboard_highlight_score?.quality_score">
              </tidy-bar>
            </tidy-row>
            <tidy-row *ngIf="metricsFeedback?.retention_score !== null && metricsFeedback?.retention_score !== undefined">
              <tidy-text
                [body]="(metricsFeedback?.retention_score | roundWholeNumber)">
              </tidy-text>
              <tidy-text
                [body]="'% retention rate'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="(metricsFeedback?.positive_feedback_percent | roundWholeNumber)">
              </tidy-text>
              <tidy-text
                [body]="'% of feedback is positive'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="(metricsFeedback?.blacklist_percent | roundWholeNumber)">
              </tidy-text>
              <tidy-text
                [body]="'% blacklist rate'">
              </tidy-text>
            </tidy-row>
          </ng-container>
          <tidy-row>
            <tidy-text
              *ngIf="!isQualityScore"
              [body]="'Not enough data - complete more cleanings to build a score.'">
            </tidy-text>
          </tidy-row>
        </tidy-card>

        <tidy-card>
          <tidy-row>
            <tidy-text
              [title]="'Reliability Score'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [helper]="'This score is how consistently you show up to cleanings. '">
            </tidy-text>
            <tidy-text
              [helper]="'Learn More'"
              (action)="learnMoreReliabilityScore()"
              class="link">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              *ngIf="!isReliabilityScore"
              [body]="'Not enough data - complete more cleanings to build a score.'">
            </tidy-text>
          </tidy-row>
          <ng-container *ngIf="isReliabilityScore">
            <tidy-row class="extra-bottom-padding">
              <tidy-bar
                [value]="highlights?.dashboard_highlight_score?.reliability_score">
              </tidy-bar>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="(metricsFeedback?.cancellation_windows_breakdown?.total_cancellation_percent | roundWholeNumber)">
              </tidy-text>
              <tidy-text
                [body]="'% cleanings canceled by you'">
              </tidy-text>
            </tidy-row>
            <ng-container *ngIf="metricsFeedback?.cancellation_windows_breakdown?.total_cancellation_percent > 0">
              <tidy-row>
                <tidy-text
                  [body]="(metricsFeedback?.cancellation_windows_breakdown?.under_one_hour_percent | roundWholeNumber)">
                </tidy-text>
                <tidy-text
                  [body]="'% with < 1 hour notice'">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="(metricsFeedback?.cancellation_windows_breakdown?.one_to_twenty_four_hour_percent | roundWholeNumber)">
                </tidy-text>
                <tidy-text
                  [body]="'% with 1-24 hour notice'">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="(metricsFeedback?.cancellation_windows_breakdown?.twenty_four_hour_to_seventy_two_hour_percent | roundWholeNumber)">
                </tidy-text>
                <tidy-text
                  [body]="'% with 24-72 hour notice'">
                </tidy-text>
              </tidy-row>
            </ng-container>
          </ng-container>
        </tidy-card>

        <tidy-card>
          <tidy-row>
            <tidy-text
              [title]="'Bookability Score'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [helper]="'This score is how easy it is for clients to book you. '">
            </tidy-text>
            <tidy-text
              [helper]="'Learn More'"
              (action)="learnMoreBookabilityScore()"
              class="link">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-bar
              [value]="highlights?.bookability_scores?.bookability_score">
            </tidy-bar>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              *ngIf="!isBookabilityScore"
              [body]="'Not enough data - complete more cleanings to build a score.'">
            </tidy-text>
          </tidy-row>
          <ng-container  *ngIf="isBookabilityScore">
            <tidy-row>
              <tidy-grid  class="vertical-align">
                <ion-row>
                  <ion-col size="5">
                    <tidy-text
                      [header]="'Consistency'">
                    </tidy-text>
                  </ion-col>
                  <ion-col size="1">
                  </ion-col>
                  <ion-col size="6">
                    <tidy-bar
                      [value]="highlights?.bookability_scores?.consistency_score">
                    </tidy-bar>
                  </ion-col>
                </ion-row>
              </tidy-grid>
            </tidy-row>
            <tidy-row class="extra-bottom-padding">
              <tidy-text
                [body]="'Increase by not changing your schedule often. Many repeat clients say this is the <b>most important</b> thing!'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-grid  class="vertical-align">
                <ion-row>
                  <ion-col size="5">
                    <tidy-text
                      [header]="'Continuity'">
                    </tidy-text>
                  </ion-col>
                  <ion-col size="1">
                  </ion-col>
                  <ion-col size="6">
                    <tidy-bar
                      [value]="highlights?.bookability_scores?.continuity_score">
                    </tidy-bar>
                  </ion-col>
                </ion-row>
              </tidy-grid>
            </tidy-row>
            <tidy-row class="extra-bottom-padding">
              <tidy-text
                [body]="'Increase by being available for multiple times in a row (you need 3 blocks in a row for a 4 hour job).'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-grid  class="vertical-align">
                <ion-row>
                  <ion-col size="5">
                    <tidy-text
                      [header]="'Times'">
                    </tidy-text>
                  </ion-col>
                  <ion-col size="1">
                  </ion-col>
                  <ion-col size="6">
                    <tidy-bar
                      [value]="highlights?.bookability_scores?.times_score">
                    </tidy-bar>
                  </ion-col>
                </ion-row>
              </tidy-grid>
            </tidy-row>
          </ng-container>
          <tidy-row>
            <tidy-text
              [body]="'Increase by being available for times that have more demand.  See below for the best available times:'">
            </tidy-text>
          </tidy-row>
          <tidy-best-times-calendar></tidy-best-times-calendar>
        </tidy-card>

        <tidy-card>
          <tidy-row>
            <tidy-text
              [title]="'Job Streaks'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [helper]="'Complete cleanings in a row without a cancelation to extend your streak. '">
            </tidy-text>
            <tidy-text
              [helper]="'Learn More'"
              (action)="learnMoreCleaningStreak()"
              class="link">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-grid class="vertical-align">
              <ion-row>
                <ion-col size="7">
                  <tidy-text
                    [header]="'Current Streak'">
                  </tidy-text>
                </ion-col>
                <ion-col size="5">
                  <tidy-text
                    [body]="streaksFeedback?.current_happened_streak">
                  </tidy-text>
                  <tidy-text
                    [body]="' Jobs'">
                  </tidy-text>
                </ion-col>
              </ion-row>
            </tidy-grid>
          </tidy-row>
          <tidy-row>
            <tidy-grid class="vertical-align">
              <ion-row>
                <ion-col size="7">
                  <tidy-text
                    [header]="'Longest Streak'">
                  </tidy-text>
                </ion-col>
                <ion-col size="5">
                  <tidy-text
                    [body]="streaksFeedback?.longest_happened_streak || 0">
                  </tidy-text>
                  <tidy-text
                    [body]="' Jobs'">
                  </tidy-text>
                </ion-col>
              </ion-row>
            </tidy-grid>
          </tidy-row>
        </tidy-card>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!loaded">
      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
        </tidy-row>
        <ng-container>
          <tidy-row class="extra-bottom-padding">
            <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
          </tidy-row>
          <tidy-row>
            <ion-skeleton-text animated style="width: 35%"></ion-skeleton-text>
          </tidy-row>
          <tidy-row>
            <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
          </tidy-row>
          <tidy-row>
            <ion-skeleton-text animated style="width: 33%"></ion-skeleton-text>
          </tidy-row>
        </ng-container>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 45%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
        </tidy-row>
        <ng-container>
          <tidy-row class="extra-bottom-padding">
            <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
          </tidy-row>
          <tidy-row>
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
          </tidy-row>
          <tidy-row>
            <ion-skeleton-text animated style="width: 45%"></ion-skeleton-text>
          </tidy-row>
          <tidy-row>
            <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
          </tidy-row>
          <tidy-row>
            <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
          </tidy-row>
        </ng-container>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 55%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
        </tidy-row>
        <ng-container>
          <tidy-row>
            <tidy-grid>
              <ion-row>
                <ion-col size="5">
                  <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
                </ion-col>
                <ion-col size="1">
                </ion-col>
                <ion-col size="6">
                  <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
                </ion-col>
              </ion-row>
            </tidy-grid>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 15%"></ion-skeleton-text>
          </tidy-row>
          <tidy-row>
            <tidy-grid>
              <ion-row>
                <ion-col size="5">
                  <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
                </ion-col>
                <ion-col size="1">
                </ion-col>
                <ion-col size="6">
                  <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
                </ion-col>
              </ion-row>
            </tidy-grid>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 15%"></ion-skeleton-text>
          </tidy-row>
          <tidy-row>
            <tidy-grid>
              <ion-row>
                <ion-col size="5">
                  <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
                </ion-col>
                <ion-col size="1">
                </ion-col>
                <ion-col size="6">
                  <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
                </ion-col>
              </ion-row>
            </tidy-grid>
          </tidy-row>
        </ng-container>
        <tidy-row>
          <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 15%"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
