/**
 * This method is to put string in titlecase
 * input hour text output Text
 * ```ts
 * titleCase("text")
 * ```
 */
export function titleCase(text: string) {
  if (!text.length) {
    return;
  }

  return `${text[0].toLocaleUpperCase()}${text.substr(1, text.length)}`;
}

export function keepOnlyText(str: string): string {
  if (!str) {
    return '';
  }

  return str.replace(/[^a-zA-Z0-9]/g, '');
}
