import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Certification } from 'src/providers/certification/certification';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Me } from 'src/shared/providers/me';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

import { SuccessPageParamsModel } from 'src/pages/success/success';

import { CertificationsModel } from 'src/shared/models/certification.model';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  selector: 'background-check',
  templateUrl: 'background-check.html',
})
export class OnboardingBackgroundCheckPage extends Timeout {

  backgroundCheckPending: boolean;
  email: string;
  errorMessage: string;
  form: UntypedFormGroup;
  submitted: boolean;

  constructor(
    private certification: Certification,
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private iabUtils: InAppBrowserUtils,
    private me: Me,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.fb.group({
      directDeposit: ['', Validators.required],
      smartPhone: ['', Validators.required],
      car: ['', Validators.required],
      felony: ['', Validators.required],
      allergies: ['', Validators.required],
      english: ['', Validators.required],
    });
  }

  async ionViewDidEnter() {
    try {
      this.loaded = false;
      const data: CertificationsModel = await this.certification.getCertificationsNoCache();
      this.backgroundCheckPending = data.steps.background_check === 'pending';
      const hkData = await this.me.load();
      this.email = hkData.profile.email;
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  async startBackgroundCheck() {
    this.submitted = true;
    this.errorMessage = '';
    if (this.formErrors()) {
      return this.errorMessage = 'There were errors in your submission.  Please scroll up to fix errors.';
    }
    this.errorMessage = '';
    try {
      await this.certification.finishStep('background_check', {});
      const params = this.mountSuccessPageParams();
      this.customNavCtrl.navigateForward('success', params);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async resendBackgroundCheckEmail() {
    this.errorMessage = '';
    try {
      await this.certification.resendBackgroundCheckEmail();
      const params = this.mountResentSuccessPageParams();
      this.customNavCtrl.navigateForward('success', params);
    } catch (err) {
      if( err.code == '403'){
        this.errorMessage = 'Our background check partner has already gotten your information and is processing the check. This can take up to 2 weeks, depending on the counties involved.'
      } else {
        this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      }
    }
  }

  formErrors(): boolean {
    return (
      !this.form.valid ||
      this.form.value.directDeposit === 'no' ||
      this.form.value.smartPhone === 'no' ||
      this.form.value.car === 'no' ||
      this.form.value.felony === 'yes' ||
      this.form.value.allergies === 'yes' ||
      this.form.value.english === 'no'
    );
  }

  mountSuccessPageParams(): SuccessPageParamsModel {
    return {
      header: 'Email Sent',
      body: `${new TranslationPipe().transform('We sent an email to')} ${this.email} ${new TranslationPipe().transform('from our background check provider "Turn" (from a @turning.io email). Check your email and complete the check to proceed. Please allow 24 hours for the email to arrive.')}`,
      buttonText: 'Ok',
      buttonRoute: 'new-clients'
    };
  }

  mountResentSuccessPageParams(): SuccessPageParamsModel {
    return {
      header: 'Email Resent',
      body: `${new TranslationPipe().transform('We resent an email to')} ${this.email} ${new TranslationPipe().transform('from our background check provider "Turn" (from a @turning.io email). Check your email and complete the check to proceed. Please allow 24 hours for the email to arrive.')}`,
      buttonText: 'Ok',
      buttonRoute: 'new-clients'
    };
  }

  learnMoreDirectDeposits() {
    this.iabUtils.openUrl('https://help.tidy.com/pros/payments#where-are-payouts-sent');
  }

  learnMoreFelony() {
    this.iabUtils.openUrl('https://help.tidy.com/pros/certifications#background-check-certification');
  }
}
