import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PrivateClient } from 'src/providers/private-client/private-client';

import validationUtils from 'src/shared/utils/validation-utils';

import { SuccessPageParamsModel } from 'src/pages/success/success';

import { ClientModel } from 'src/shared/models/client.model';
import { ConfirmPageParamsModel, ConfirmPage } from 'src/pages/confirm/confirm';
import { PrivateJobModel } from 'src/shared/models/private-job.model';
import { Me } from 'src/shared/providers/me';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { Router, ActivatedRoute } from '@angular/router';

import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

@Component({
  templateUrl: 'edit-private-client-info.html'
})

export class EditPrivateClientInfoPage extends Timeout {

  errorMessage: string;
  client: ClientModel;
  form: UntypedFormGroup;
  nextJob: PrivateJobModel;
  submitted: boolean;
  proCountryCode = '';

  constructor(
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private modalCtrl: ModalController,
    private privateClient: PrivateClient,
    private me: Me,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phone: [null],
      email: ['', validationUtils.validateEmail],
    });
  }

  async ionViewDidEnter() {
    try {
      this.loaded = false;
      this.client = this.customNavCtrl.getParam('client', true);
      this.nextJob = this.customNavCtrl.getParam('nextJob');
      this.populateForm();
      const me = await this.me.load();
      const countryCode = me.profile.address.country_code;
      this.proCountryCode = countryCode;
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  populateForm() {
    this.form.patchValue({
      firstName: this.client.first_name,
      lastName: this.client.last_name,
      phone: this.client.phone_number,
      email: this.client.email
    });
  }

  saveChanges = async () =>  {
    this.submitted = true;

    if (!this.form.valid) {
      return;
    }
    let data = {
      first_name: this.form.value.firstName,
      last_name: this.form.value.lastName
    };
    if (this.form.value.phone) {
      data['phone_number'] = this.form.value.phone;
    }
    if (this.form.value.email) {
      data['email'] = this.form.value.email;
    }
    try {
      await this.privateClient.updatePrivateClient(data, this.client.id);
      const params = this.mountSuccessPageParams();
      this.customNavCtrl.navigateForward('success', params);
    } catch (err) {
      this.errorMessage = err.message || err.error.message;
    }
  }

  mountSuccessPageParams(): SuccessPageParamsModel {
    return {
      header: `Client Contact Saved`,
      body: `The contact info for this Client has been updated.`,
      buttonText: `Ok`,
      buttonRoute: `private-client/${this.client.id}`
    };
  }

  async confirmDeleteClient() {
    if (this.nextJob && this.nextJob.date) {
      return this.showCantDeleteClient();
    }
    const confirmDelete: ConfirmPageParamsModel = {
      title: 'Delete Client?',
      body: 'This will cancel all jobs for this Client.',
      backText: 'Go Back',
      confirmText: 'Delete Client',
      confirmAction: this.deleteClient.bind(this)
    };

    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: confirmDelete,
      animated: false
    });
    confirmationModal.present();
  }

  async deleteClient() {
    await this.privateClient.deletePrivateClient(this.client.id);

    const onSuccessDelete: SuccessPageParamsModel = {
      header: 'Client Deleted',
      body: 'You can add a new Client in the "Add" page',
      buttonText: 'Ok',
      buttonRoute: 'clients'
    };

    this.modalCtrl.dismiss();
    this.customNavCtrl.navigateForward('success', onSuccessDelete);
  }

  showCantDeleteClient() {
    const modal: SuccessPageParamsModel = {
      header: 'Cannot Delete Client',
      body: 'You cannot delete a Client while they have jobs scheduled.  Please go back and cancel any jobs before deleting the Client.',
      buttonText: 'Ok',
      buttonRoute: `private-client/${this.client.id}`
    };
    this.customNavCtrl.navigateForward('success', modal);
  }
}
