<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Pause All Bookable Hours'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text [body]="
        'If you are just taking a short time off, mark yourself off.
        If you are leaving for an extended period, then pausing is a good option.
        You can schedule your pause for a future date.'">
      </tidy-text>
    </tidy-row>

    <tidy-button
      [action]="goBack.bind(this)"
      [text]="'Go Back'"
      class="primary">
    </tidy-button>

    <tidy-button
      [action]="goBack.bind(this)"
      [text]="'I Just Need To Adjust My Hours'"
      class="primary">
    </tidy-button>

    <tidy-button
      [action]="startPausing.bind(this)"
      [text]="'I Need to Pause for An Extended Time'"
      class="secondary">
    </tidy-button>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
