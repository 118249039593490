<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Who is Canceling?'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
    <tidy-wrapper *ngIf="loaded">
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <tidy-row [align]="'center'">
        <tidy-text
          *ngIf="cancelType === 'job'"
          [body]="'Who is canceling this job on '">
        </tidy-text>
        <tidy-text
          *ngIf="cancelType === 'plan'"
          [body]="'Who is canceling this plan with next job '">
        </tidy-text>
        <tidy-text
          [body]="(jobData.date | tdate:'dddd M/D')">
        </tidy-text>
        <tidy-text
          [body]="' at '">
        </tidy-text>
        <tidy-text
          [body]="(jobData.start_time | ttime) +
          '?'">
        </tidy-text>
      </tidy-row>

      <tidy-error-message
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-button
        [text]="('Client ' | translate) + clientName"
        [action]="cancellation.bind(this, 'customer')"
        class="secondary">
      </tidy-button>

      <tidy-button
        [text]="'Me'"
        [action]="cancellation.bind(this, 'homekeeper')"
        class="secondary">
      </tidy-button>

    </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
