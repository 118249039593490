<div class="search-input-container" [class.fixed-width]="fixedWidth">
  <div class="search-icon">
    <tidy-image [src]="icon"></tidy-image>
  </div>
  <input
    class="search-input"
    [ngModel]="value"
    (ngModelChange)="onInputChange($event)"
    [placeholder]="placeholder"
    type="text"
  />
  <button
    *ngIf="!isInputEmpty"
    class="clear-button"
    (click)="clearInput()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
