import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tcurrencyRound'
})
export class TidyCurrencyRoundPipe implements PipeTransform {

  transform(value: number): string {
    const prefix = '$';
    const minusPrefix = '-$';
    const normalizedValue = Math.sqrt( value * value );
    const valueWithDecimal = normalizedValue / 100;
    const valueRounded = Math.round(valueWithDecimal);

    if (value < 0) {
      return minusPrefix + valueRounded;
    }

    return prefix + valueRounded;
  }
}
