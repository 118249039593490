import { Component, Injector, OnInit, ViewChild, ViewEncapsulation, NgZone } from '@angular/core';
import { IonRouterOutlet } from '@ionic/angular';
import { App, URLOpenListenerEvent } from '@capacitor/app';

import { Events } from 'src/providers/events/events';
import { TidySession } from 'src/providers/tidy-session/tidy-session';
import { RouterOutletInjector } from 'src/shared/providers/navigation/router-outlet-injector';
import { ExternalInjector } from 'src/shared/utils/external-injector';
import { GlobalSubscriptions } from './global-subscriptions';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { translations } from 'src/shared/constants/language-translations';

@Component({
  selector: 'app-root',
  templateUrl: 'app.html',
  styleUrls: ['./app.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TidyHomekeeperAppPage implements OnInit {

  isAuthenticated = false;
  @ViewChild(IonRouterOutlet, { static : true }) routerOutlet: IonRouterOutlet;
  translations = translations;

  constructor(
    private events: Events,
    private globalSubscriptions: GlobalSubscriptions,
    private injector: Injector,
    private tidySession: TidySession,
    private routerOutletInjector: RouterOutletInjector,
    private zone: NgZone,
    private navCtrl: CustomNavController,
    private tidyStorage: TidyStorage
  ) {
    this.initDeeplinks();
  }

  async ngOnInit() {
    ExternalInjector.register(this.injector);
    await this.tidyStorage.init();

    this.globalSubscriptions.subscribe();

    this.tidySession.setup();

    this.subscribeAuthEvents();

    this.routerOutletInjector.setup(this.routerOutlet);
  }

  initDeeplinks() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const domain = 'pro.tidy.com/#/';
        const pathArray = event.url.split(domain);
        // The pathArray is now like ['https://pro.tidy.com/#/', '/more']
        // Get the last element with pop()
        const appPath = pathArray.pop();
        if (appPath) {
          this.navCtrl.navigateForward(appPath);
        }
        else{
          this.navCtrl.navigateForward('login');
        }
      });
    });
	}

  async subscribeAuthEvents() {
    this.events.subscribe( 'auth:authenticated', () => this.isAuthenticated = true );
    this.events.subscribe( 'auth:logout', () => this.isAuthenticated = false );
  }
}
