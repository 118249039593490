<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [backButton]="true"
    [title]="contentCommunity?.title">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper *ngIf="loaded">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <tidy-card>
      <tidy-row class="extra-bottom-padding">
        <tidy-text
          [header]="'Steps to Complete:'">
        </tidy-text>
      </tidy-row>

      <tidy-row>
        <tidy-text *ngIf="contentCommunity?.postHere"
          [body]="'Post '">
        </tidy-text>
        <tidy-text *ngIf="contentCommunity?.postHere"
          [body]="'here'"
          (action)="openPostHere()"
          class="link">
        </tidy-text>
        <tidy-text
          [body]="' ' + (contentCommunity?.description | translate)">
        </tidy-text>
      </tidy-row>

      <ion-row>
        <ion-col size="1">
          <tidy-checkbox
            [(checked)]="checked"
            [initValue]="checked"
            [items]="[{viewValue: '', value: ''}]">
          </tidy-checkbox>
        </ion-col>
        <ion-col>
          <tidy-text
            [header]="'I have my own service business and advertise that business elsewhere from TIDY.'">
          </tidy-text>
        </ion-col>
      </ion-row>

      <tidy-row class="field-top-padding">
        <form [formGroup]="form" novalidate action="#">
          <tidy-input
            formControlName="post"
            [label]="'Link'"
            [form]="form"
            [icon]="'assets/svg/text.svg'"
            [activeIcon]="'assets/svg/text-tidy-green.svg'"
            [errorMessage]="'Please enter the post text'"
            [submitted]="submitted">
          </tidy-input>
        </form>
      </tidy-row>
    </tidy-card>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-button
      [text]="'Confirm'"
      [action]="submit.bind(this)"
      class="secondary">
    </tidy-button>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
