import { Component, ViewEncapsulation } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Component({
  selector: 'continue-certification-link',
  encapsulation: ViewEncapsulation.None,
  template: `
  <tidy-text
    [body]="'View Getting Started Steps in the Dashboard'"
    (action)="continueCertification()"
    class="link">
  </tidy-text>
  `
})

export class ContinueCertificationLinkComponent {

  constructor(
    private customNavCtrl: CustomNavController
  ) {}

  async continueCertification() {
    this.customNavCtrl.navigateForward('dashboard');
  }
}
