<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Billing History'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Viewing billing history for '">
        </tidy-text>
        <tidy-text
          [body]="(client?.account_name && client?.account_name !== '' ? client?.account_name : (client?.first_name | titlecase)) + '.'"
          [translate]="false">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <tidy-row [alignLastItemRight]="true">
          <div class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/cash.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Balance'">
            </tidy-text>
          </div>
          <tidy-text
            [body]="(billingHistory?.balance | tcurrency)">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngFor="let item of billingHistory?.balance_details" [alignLastItemRight]="true" class="extra-top-padding">
          <tidy-image
            [src]="item?.credit_type == 'credit' ? 'assets/svg/add-circle-outline.svg' : 'assets/svg/card-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="(item?.amount_left | tcurrency) + (' left of ' | translate) + (item?.amount | tcurrency) + ' ' + item?.credit_type">
          </tidy-text>
          <ng-container *ngIf="item?.credit_type == 'credit'">
            <tidy-text
              style="margin-left: 5px"
              [body]="'Void'"
              (action)="voidCredit(item)"
              class="link">
            </tidy-text>
          </ng-container>
          <tidy-text
            [helper]="item?.date | tdate:'M/D/YY'">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row [alignLastItemRight]="unpaidJobs?.length == 0" [ngClass]="unpaidJobs?.length == 0 ? '' : 'vertical-align-center'">
          <tidy-image
            [src]="'assets/svg/alert-circle.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Unpaid Jobs'">
          </tidy-text>
          <tidy-text
            *ngIf="unpaidJobs?.length == 0"
            [body]="'0 Unpaid'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let job of unpaidJobs">
          <tidy-card-button (action)="goToJobPage(job.id)">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="!job?.invoice ? 'assets/svg/alert-circle-outline.svg' : 'assets/svg/send-outline.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                *ngIf="job?.invoice"
                [body]="(job?.invoice?.amount_due | tcurrency) + (' owed for ' | translate) + (job?.date | tdate: 'M/D') + (' job' | translate)">
              </tidy-text>
              <tidy-text
                *ngIf="!job?.invoice"
                [body]="(job?.billing?.amount_due | tcurrency) + (' owed for ' | translate) + (job?.date | tdate: 'M/D') + (' job' | translate)">
              </tidy-text>
            </tidy-row>
            <tidy-text
              *ngIf="job?.invoice !== null && job.invoice !== undefined"
              [actionBody]="'(Invoice Sent)'"
              class="green">
            </tidy-text>
            <tidy-text
              *ngIf="job?.private_status == 'scheduled'"
              [actionBody]="'(Not Complete)'"
              class="red">
            </tidy-text>
          </tidy-card-button>
        </ng-container>
      </tidy-card>

      <tidy-row [align]="'center'">
        <tidy-text
          [header]="'Payments & Credits'">
        </tidy-text>
      </tidy-row>

      <tidy-row *ngIf="!noBillingHistory">
        <tidy-filter
          [items]="addresses"
          [initValue]="addressSelected.id"
          (optionChange)="changeAddress($event)"
          [fitContent]="true">
        </tidy-filter>
      </tidy-row>

      <tidy-alert *ngIf="detailHistory?.length == 0 || noBillingHistory">
        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'No History'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <ng-container *ngIf="!loadingHistory">
        <ng-container *ngFor="let item of detailHistory">
          <tidy-card>
            <tidy-row [alignLastItemRight]="true" class="extra-bottom-padding">
              <tidy-image
                *ngIf="item?.credit_type === 'credit'"
                [src]="'assets/svg/add-circle.svg'"
                class="body-size">
              </tidy-image>
              <tidy-image
                *ngIf="!item?.credit_type || item?.credit_type === 'charge'"
                [src]="'assets/svg/card.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [header]="(item?.amount | tcurrency) + ' ' + (item?.credit_type | titlecase)">
              </tidy-text>
              <tidy-text
                [helper]="item?.date | tdate:'ddd M/D'">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngFor="let detail of item?.details">
              <ng-container *ngIf="detail.debit_type !== 'refund'">
                <tidy-text
                  [body]="(detail?.amount_used | tcurrency) + (' used by job on ' | translate)">
                </tidy-text>
                <tidy-text
                  [body]="detail?.cleaning_date | tdate: 'M/D/YY'"
                  class="link"
                  (action)="goToJobPage(detail?.cleaning_id)">
                </tidy-text>
              </ng-container>
              <ng-container *ngIf="detail.debit_type == 'refund'">
                <tidy-text
                  [body]="(detail?.amount_used | tcurrency) + (' refunded' | translate)">
                </tidy-text>
              </ng-container>
            </tidy-row>
            <tidy-card-button *ngIf="item?.credit_type === 'charge' && item?.refundable" (action)="goToRefundChargePage(item)">
              <tidy-row class="vertical-align-center">
                <tidy-text
                  [body]="'Refund Charge'">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>
        </ng-container>
      </ng-container>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
