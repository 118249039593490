import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { BankInfo } from 'src/providers/bank-info/bank-info';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Loading } from 'src/shared/components/loading/loading';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

import { Timeout } from 'src/shared/components/timeout/timeout';

import { validateEqual } from 'src/pages/common/validator/validate-equal';

@Component({
  selector: 'payout-settings-detail',
  templateUrl: './payout-settings-detail.html',
})
export class PayoutSettingsDetailPage extends Timeout {

  errorMessage: string;
  form: UntypedFormGroup;
  alreadyAddedBank: boolean;
  payoutSetting: any;
  submitted = false;
  ssnOrEinItems: any;
  ssnOrEinLabel: string;

  constructor(
    private bankInfo: BankInfo,
    private fb: UntypedFormBuilder,
    private iabUtils: InAppBrowserUtils,
    private navCtrl: CustomNavController,
    router: Router,
    route: ActivatedRoute,
    public timeoutErrorHandler: TimeoutErrorHandler
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.fb.group({
      ssnOrEin: [''],
      personalNumber: [''],
      routing_number: ['', Validators.required],
      account_number: ['', Validators.required],
      confirm_account_number: ['', Validators.required],
    }, {validator: validateEqual('account_number', 'confirm_account_number')});
  }

  @Loading()
  async ionViewDidEnter() {
    this.payoutSetting = this.navCtrl.getParam('payoutSetting');
    if (this.payoutSetting == 'check') {
      this.ssnOrEinItems = this.buildSsnOrEinItems();
      this.ssnOrEinLabel = 'SSN';
      this.form.patchValue({ssnOrEin: 'ssn'});
      this.form.controls.routing_number.clearValidators();
      this.form.controls.routing_number.updateValueAndValidity();
      this.form.controls.account_number.clearValidators();
      this.form.controls.account_number.updateValueAndValidity();
      this.form.controls.confirm_account_number.clearValidators();
      this.form.controls.confirm_account_number.updateValueAndValidity();
      this.form.controls.ssnOrEin.setValidators([Validators.required]);
      this.form.controls.personalNumber.setValidators([Validators.required]);
    } else {
      this.alreadyAddedBank = this.navCtrl.getParam('alreadyHasBank');
    }
  }

  buildSsnOrEinItems() {
    return [
      {
        value: 'ssn',
        viewValue: 'SSN (social security number)'
      },
      {
        value: 'ein',
        viewValue: 'EIN (employer identification number)'
      },
    ]
  }

  async addDirectDepostInfo() {
    this.errorMessage = '';
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    try {
      const stripeResponse = await this.bankInfo.saveBankAccount(this.form.value.routing_number.substring(0, 9), this.form.value.account_number, this.alreadyAddedBank);
      await this.bankInfo.updatePayoutSetting({
        key: 'payout_setting',
        value: 'direct_deposit'
      });
      localStorage.setItem('payoutSetting', 'direct_deposit');
      if (!this.alreadyAddedBank) {
        this.iabUtils.openUrl(stripeResponse.connect_onboard_account_link);
      }
      this.navCtrl.navigateForward('payout-settings');
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  changePersonalNumberLabel(selection) {
    this.ssnOrEinLabel = selection.value == 'ssn' ? 'SSN' : 'EIN';
  }

  async addCheckInfo() {
    this.errorMessage = '';
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    try {
      const numberPayload = {
        key: this.form.value.ssnOrEin,
        value: this.form.value.personalNumber
      };
      await this.bankInfo.updatePayoutSetting(numberPayload);
      const typePayload = {
        key: 'payout_setting',
        value: 'check'
      }
      await this.bankInfo.updatePayoutSetting(typePayload);
      localStorage.setItem('payoutSetting', 'check');
      this.navCtrl.navigateForward('payout-settings');
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  learnMoreStripe() {
    this.iabUtils.openUrl('https://stripe.com/docs/security/stripe');
  }

  openIRSLink() {
    this.iabUtils.openUrl('https://www.irs.gov/pub/irs-pdf/fw9.pdf');
  }

}
