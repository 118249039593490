import { Component, ElementRef } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { PrivateClient } from 'src/providers/private-client/private-client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Me } from 'src/shared/providers/me';
import { MultipleCountryService } from 'src/providers/addresses/multiple-country.service';

import { SelectModel } from '@tidyapp/tidy-ui-components';
import { AutocompleteModel } from '@tidyapp/tidy-ui-components';

import { keepOnlyText } from 'src/shared/utils/text-utils';
import validationUtils from 'src/shared/utils/validation-utils';

import { AddressMetadata } from 'src/shared/components/autocomplete-address/autocomplete-address';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

@Component({
  selector: 'add-private-job',
  templateUrl: './add-private-job.html',
})
export class AddPrivateJobPage extends Timeout {

  addingNewClient: boolean;
  form: UntypedFormGroup;
  addresses: SelectModel[];
  addressId: number;
  countryCode: string;
  clientName: string;
  clients: AutocompleteModel[];
  clientSelected: boolean;
  errorMessage: string;
  invalidAddressError: string;
  isLoading = false;
  noAddressPickedTemplate: ElementRef;
  submitted = false;
  routeClientId: string;
  routeClientName: string;
  selectedClient: AutocompleteModel;
  zipCodeMask = {
    mask: '00000'
  }

  constructor(
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private me: Me,
    private multipleCountryService: MultipleCountryService,
    private privateClient: PrivateClient,
    public route: ActivatedRoute,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.fb.group({
      clientOptions: ['existing'],
      addressId: ['', Validators.required],
      clientId: ['', Validators.required],
      firstName: [''],
      lastName: [''],
      phone: [null],
      email: [''],
      address: [''],
      unit: [''],
      zip: [''],
      latitude: '',
      longitude: '',
      city: [''],
      state: ['']
    });
  }

  async ionViewDidEnter() {
    try {
      this.loaded = false;
      const allClients = await this.privateClient.getPrivateClients();
      this.clients = allClients.map(client => ({ display: (client.account_name && client.account_name !== '' ? client.account_name : client.first_name), value: `${client.id}` }));
      if (!this.clients.length) {
        this.addingNewClient = true;
        this.form.patchValue({ clientOptions: 'new' });
        this.changeClientOption('new');
      }
      this.routeClientId = this.route.snapshot.paramMap.get('clientId');
      this.routeClientName = this.customNavCtrl.getParam('clientName');
      this.setSelectedClient();
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  setSelectedClient() {
    const { routeClientName, routeClientId } = this;

    if (routeClientId && routeClientName) {
      this.selectedClient = {
        display: routeClientName,
        value: routeClientId
      };
    }
  }

  gotToAddClientPage() {
    this.customNavCtrl.navigateForward('add-private-client');
  }

  async selectClient(item: AutocompleteModel) {
    await this.getAddresses(this.form.get('clientId').value);
    if (this.addresses.length == 0) {
      this.setNewAddressRequired();
    } else {
      this.setNewAddressNotRequired();
    }
    this.clientName = item.display;
    this.clientSelected = true;
  }

  async getAddresses(clientId: string) {
    let allAddress = [];
    this.isLoading = true;

    try {
      allAddress = await this.privateClient.getPrivateClientAddress(clientId);
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    } finally {
      this.isLoading = false;
    }

    this.addresses = allAddress.map((address) => ({ viewValue: address.address, value: `${address.id}` }));

    if(allAddress.length === 0) {
      this.addresses = [];
      return;
    }

    this.form.get('addressId').patchValue(`${allAddress[0].id}`);
    this.addresses = allAddress.map((address) => ({ viewValue: this.parseAddress(address), value: `${address.id}` }));
  }

  parseAddress(address: any): string {
    return address.address + (address?.address_name ? (' - ' + address?.address_name) : '');
  }

  async next() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    try {
      if (this.addingNewClient) {
        if (this.invalidAddressError) {
          return this.errorMessage = this.invalidAddressError;
        }
        const payload = this.buildNewClientPayload();
        const client = await this.privateClient.addPrivateClient(payload);
        const addresses = await this.privateClient.getPrivateClientAddress(client.id);
        this.clientName = this.form.value.firstName;
        this.form.patchValue({clientId: client.id, addressId: addresses[0].id});
      }
      if (!this.addingNewClient && this.addresses.length == 0) {
        if (this.invalidAddressError) {
          return this.errorMessage = this.invalidAddressError;
        }
        const payload = this.buildNewAddressPayload();
        const response = await this.privateClient.addPrivateAddress(payload);
        this.form.patchValue({addressId: response.id});
      }
      let params = {
        ...this.form.value,
        clientName: this.clientName,
        addressName: this.addingNewClient || this.addresses.length == 0 ?  this.form.value.address : this.getAddressName(),
        finishCreationJobRoute: this.routeClientId ? `private-client/${this.routeClientId}` : '/add'
      };
      this.addingNewClient = false;
      this.customNavCtrl.navigateForward(['add-private-job-details'], params);
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  getAddressName() {
    const selectedAddress = this.addresses.find(address => {
      return address.value === `${this.form.value.addressId}`
    }) || {} as SelectModel;
    return selectedAddress.viewValue;
  }

  buildNewClientPayload() {
    let payload = {
      customer: {
        first_name: this.form.value.firstName,
        last_name: this.form.value.lastName,
        email: this.form.value.email
      },
      address: this.buildAddressPayload(),
    }
    if (this.form.value.phone_number) {
      payload.customer['phone_number'] = this.form.value.phone;
    }
    return payload;
  }

  buildNewAddressPayload() {
    return {
      address: this.buildAddressPayload(),
      customer_id: this.form.value.clientId,
      latitude: this.form.value.latitude,
      longitude: this.form.value.longitude
    };
  }

  buildAddressPayload() {
    return {
      address: this.form.value.address,
      zipcode: keepOnlyText(this.form.value.zip),
      unit: this.form.value.unit,
      latitude: this.form.value.latitude,
      longitude: this.form.value.longitude,
      country_code: this.countryCode,
      city: this.form.value.city,
      address_state: this.form.value.state
    };
  }

  changeClientOption(option) {
    if (option === 'new') {
      this.setNewAddressRequired();
      this.addNewClient();
    } else {
      this.setNewAddressNotRequired();
      this.stopAddNewClient();
    }
  }

  choseItem(addressMetadata: AddressMetadata) {
    this.form.patchValue({ zip: addressMetadata.zipcode, ...addressMetadata });
    this.errorMessage = '';
  }

  setNewAddressRequired() {
    this.setCountryCode();
    this.form.get('addressId').clearValidators();
    this.form.get('addressId').updateValueAndValidity();
    this.form.controls.address.setValidators([Validators.required]);
    this.form.controls.zip.setValidators([Validators.required]);
  }

  setNewAddressNotRequired() {
    this.form.controls.addressId.setValidators([Validators.required]);
    this.form.get('address').clearValidators();
    this.form.get('address').updateValueAndValidity();
    this.form.get('zip').clearValidators();
    this.form.get('zip').updateValueAndValidity();
  }

  async addNewClient() {
    await this.setCountryCode();
    this.addingNewClient = true;
    this.form.get('clientId').clearValidators();
    this.form.get('clientId').updateValueAndValidity();
    this.form.controls.email.setValidators([validationUtils.validateEmail]);
    this.form.controls.firstName.setValidators([Validators.required]);
    this.form.controls.lastName.setValidators([Validators.required]);
    this.form.controls.email.setValidators([Validators.required, validationUtils.validateEmail]);
  }

  async setCountryCode() {
    const me = await this.me.fetchWithoutCache();
    const countryCode = me.profile.address.country_code;
    const validatorObject = this.multipleCountryService.getCountrySettings(countryCode);
    this.zipCodeMask = validatorObject.mask;
    this.countryCode = countryCode;
  }

  stopAddNewClient() {
    this.addingNewClient = false;
    this.form.controls.clientId.setValidators([Validators.required]);
    const fields = ['email', 'firstName', 'lastName'];
    fields.forEach(field => {
      this.form.get(field).clearValidators();
      this.form.get(field).updateValueAndValidity();
    });
  }
}
