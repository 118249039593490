import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { UpcomingFeature } from 'src/providers/upcoming-feature/upcoming-feature';

import { SuccessPageParamsModel } from 'src/pages/success/success';
import { Loading } from 'src/shared/components/loading/loading';

@Component({
  selector: 'upcoming-feature',
  templateUrl: 'upcoming-feature.html'
})

export class UpcomingFeaturePage {

  errorMessage: string;
  feature: string;
  form: UntypedFormGroup;
  loaded: boolean;
  pageContent: {
    title: string;
    body: string;
    image: string;
  };

  constructor(
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private upcomingFeature: UpcomingFeature
  ) {
    this.form = this.fb.group({
      note: ['']
    });
  }

  @Loading()
  async ionViewDidEnter() {
    this.feature = this.customNavCtrl.getParam('feature', true);
    this.pageContent = this.buildPageContent();
    this.loaded = true;
  }

  buildPageContent() {
    const pageContents = {
      pro_cancellation_fees: {
        title: 'After Cutoff Cancellation Fees',
        body: 'Charge your clients a fee for canceling jobs after 7:50pm the day prior.',
        image: 'assets/img/credit-card.svg'
      },
      private_client_payments: {
        title: 'Client Payment Settings',
        body: 'Use TIDY to invoice & collect credit or debit card payments for private jobs.  It\'s fast & easy to set up.',
        image: 'assets/img/credit-card.svg'
      },
      email_marketing: {
        title: 'Email Marketing',
        body: 'Send amazing marketing emails to your Clients! Pick from one of several templates including: Thank you, Christmas, Discounts, & more.<br><br>Easily send to Clients on your existing Client list, past Clients, or Clients who have not booked yet.',
        image: 'assets/img/email.svg'
      },
      postcard_marketing: {
        title: 'Postcard Marketing',
        body: 'Send beautiful marketing postcards to your Clients in the mail! Pick from one of several templates including: Thank you, Christmas, Discounts, & more.<br><br>Easily send to Clients on your existing Client list, past Clients, or Clients who have not booked yet.',
        image: 'assets/img/postcard.svg'
      },
      local_marketing: {
        title: 'Local Service Ads',
        body: 'Be booked from the top of Google!<br><br>You can easily create Google Ads in TIDY. These sync with your Google account & Clients can instantly book your services directly from the search results on your calendar. Paying only for results is the way to go.',
        image: 'assets/img/local-service-ad.png'
      },
      equipment: {
        title: 'Equipment Tracking',
        body: 'Save $ by using TIDY to automatically track the equipment you use.  TIDY provides a monthy & yearly summary for you to use when paying taxes.',
        image: 'assets/img/choose-equipment.png'
      },
      tax_center: {
        title: 'Tax Center',
        body: 'TIDY helps track write offs to help you save!<br><br>Easily track your income, mileage, equipment, utility expenses & more through TIDY.',
        image: 'assets/img/accounting.svg'
      },
      accounting: {
        title: 'Accounting',
        body: 'Easily stay on top of your business accounting through TIDY.  Get started & take the hassle out of staying organized!',
        image: 'assets/img/accounting.svg'
      },
      team_payments: {
        title: 'Team Payments',
        body: 'Use TIDY to pay your team members for the jobs you allocate to them.  It\'s fast & easy to set up.',
        image: 'assets/img/credit-card.svg'
      },
      add_past_jobs: {
        title: 'Add Past Jobs',
        body: 'You cannot add a job for a past date at this time.  Request this feature for more control over private jobs. ',
        image: 'assets/style-guide-images/private-client-black-background.svg'
      },
      create_to_dos_list: {
        title: 'Add To Dos',
        body: 'Add & manage To Dos lists for your private Clients.  Delight them with your attention to detail & send post job email summaries of what was done.',
        image: 'assets/style-guide-images/private-client-black-background.svg'
      },
      other_services: {
        title: 'New Service Types',
        body: 'Please let us know what service types your business offers that you didn\'t see in the TIDY app.',
        image: 'assets/img/briefcase-black.svg'
      },
      deposit_future_job: {
        title: 'Collect Deposit',
        body: 'You cannot deposit for a future job at this time.  Request this feature for more control over private payments.',
        image: 'assets/img/credit-card.svg'
      }
    }
    return pageContents[this.feature];
  }

  async requestFeature() {
    const nextPage = this.customNavCtrl.getParam('nextPage') || 'dashboard';
    try {
      await this.upcomingFeature.requestUpcomingFeature(this.feature, this.form.controls.note.value);
      const params = this.mountSuccessPage(nextPage);
      this.customNavCtrl.navigateForward('success', params);
    } catch(err) {
      if (err.message === 'You have already voted on this feature!') {
        const params = this.mountAlreadyRequestedSuccessPage(nextPage);
        this.customNavCtrl.navigateForward('success', params);
      } else {
        this.errorMessage = err.message;
      }
    }
  }

  mountSuccessPage(nextPage): SuccessPageParamsModel {
    return {
      header: 'Feature Requested',
      body: 'TIDY will let you know when this feature is available for you.',
      buttonText: 'Ok',
      buttonRoute: nextPage
    };
  }

  mountAlreadyRequestedSuccessPage(nextPage): SuccessPageParamsModel {
    return {
      header: 'Feature Already Requested',
      body: 'You already requested this feature.  TIDY will let you know when this feature is available for you.',
      buttonText: 'Ok',
      buttonRoute: nextPage
    };
  }

}
