import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { MWStore } from 'src/main-workflow/mw.store';
import { MWService } from 'src/main-workflow/mw.service';
import { MWJobModel } from 'src/main-workflow/mw.models';
import { mwMoments } from 'src/main-workflow/mw.moments';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Team } from 'src/providers/team/team';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

import { Loading } from 'src/shared/components/loading/loading';

import { SuccessPageParamsModel } from 'src/pages/success/success';
import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';
import { CustomFieldsService } from 'src/shared/providers/custom-fields/custom-fields';

@Component({
  templateUrl: 'mw.ready-to-leave.page.html'
})

export class ReadyToLeavePage extends Timeout {

  backPage: string;
  errorMessage: string;
  distanceFromJob: string;
  isTooEarly: boolean;
  isLoadingDistanceToJob: boolean;
  job: MWJobModel;
  route: string;
  showTooEarlyError: boolean;
  previousGuestReservationDetails: any;
  nextGuestReservationDetails: any;
  customFields: any;

  constructor(
    private iabUtils: InAppBrowserUtils,
    private mwStore: MWStore,
    public mwService: MWService,
    private modalCtrl: ModalController,
    private navCtrl: CustomNavController,
    private team: Team,
    private customFieldsService: CustomFieldsService,
    timeoutErrorHandler: TimeoutErrorHandler,
    activatedRoute: ActivatedRoute,
    router: Router
  ) {
    super(timeoutErrorHandler, router, activatedRoute);
  }

  @Loading()
  async ionViewDidEnter() {
    this.loaded = false;
    this.isLoadingDistanceToJob = true;
    this.job = await this.mwStore.getJob();
    await this.getCustomFields();
    if (this.job?.guest_reservation_data?.previous_guest_reservation?.details) {
      this.previousGuestReservationDetails = {
        ...this.job?.guest_reservation_data?.previous_guest_reservation?.details,
        customFields: this.customFieldsService.getCustomFieldsForDetails(this.customFields, this.job?.guest_reservation_data?.previous_guest_reservation?.custom_fields)
      }
    }
    if (this.job?.guest_reservation_data?.next_guest_reservation?.details) {
      this.nextGuestReservationDetails = {
        ...this.job?.guest_reservation_data?.next_guest_reservation?.details,
        customFields: this.customFieldsService.getCustomFieldsForDetails(this.customFields, this.job?.guest_reservation_data?.next_guest_reservation?.custom_fields)
      }
    }
    this.isTooEarly = this.mwService.isTooEarlyForJob(this.job.startTime);
    this.route = this.mwService.isTooEarlyForJob(this.job.startTime) ? 'not_ready_to_leave' : 'ready_to_leave';
    this.successPageLoad.emit(true);
    this.backPage = localStorage.getItem('readyToLeaveBackPage') || 'jobs';
    this.loaded = true;
    this.getDistanceToJob();
  }

  async getCustomFields() {
    try {
      this.customFields = await this.customFieldsService.getCustomFields(this.job?.guest_reservation_data?.booking?.id, 'GuestReservation');
    } catch (err) {
      console.error(err);
    }
  }

  async getDistanceToJob() {
    this.distanceFromJob = await this.mwService.getDistanceFromJob(this.job);
    this.isLoadingDistanceToJob = false;
  }

  @Loading()
  async notifyOnTheWay() {
    this.errorMessage = null;
    try {
      const phoneNumber = await this.mwStore.getPhoneNumber();
      if (!phoneNumber) {
        const params = {
          cameFromMainWorkflow: true,
          finishPage: 'ready-to-leave'
        }
       return this.navCtrl.navigateForward('validate-phone', params);
      }
      if (this.mwService.isTooEarlyForJob(this.job.startTime)) {
        this.showErrorMessage('It is too early to notify the client.  Please wait until 85 minutes before the job.');
        return this.mwService.sendMomentToBackend(mwMoments.tooEarlyOnTheWay, 'ready-to-leave', this.job.id);
      }
      if (await this.hasOtherJobInProgress()) {
        return this.navCtrl.navigateForward('other-job-in-progress');
      }
      const job = await this.mwService.getJob(this.job.id);
      await this.mwStore.setJob(job);
      if (this.mwService.isTooLateForJob(job.endTime)) {
        return this.mwService.showTooLateAlert();
      }
      if (job.cancelled) {
        return this.mwService.showJobCancelledAlert(false);
      }
      if (job.isStandbyCancelled) {
        return this.mwService.showStandbyCancelledAlert(false);
      }
      if (job.inactive) {
        return this.mwService.showInactiveJobAlert(false);
      }
      await this.mwService.sendMomentToBackend(mwMoments.onTheWay, 'ready-to-leave', job.id);
      await this.mwStore.setJobId(job.id);
      this.mwStore.setRoute('on-the-way');
      this.navCtrl.navigateForward('on-the-way');
    } catch (err) {
      const message = await this.mwService.buildErrorAlert(err);
      this.showErrorMessage(message);
    }
  }

  async hasOtherJobInProgress() {
    const jobId = await this.mwStore.getJobId();
    return jobId !== null && jobId !== undefined;
  }

  showTooEarlyStandbyAlert() {
    const successParams: SuccessPageParamsModel = {
      header: 'Too Early for Action',
      body: 'You are on standby for this job. You cannot do this action until 5 minutes prior to the job for standby jobs.',
      buttonText: 'Ok',
      buttonRoute: 'ready-to-leave'
    };
    this.navCtrl.navigateForward('success', successParams);
  }

  async cancelDelegationRequest(type) {
    const params = this.getDelegationConfirmParams(type);
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });
    confirmationModal.present();
  }

  getDelegationConfirmParams(type): ConfirmPageParamsModel {
    const params = {
      cancel: {
        title: 'Cancel Request?',
        body: `${new TranslationPipe().transform('Please confirm you want to cancel the request for')} ${this.job.job_delegation_request.delegatee.name} ${new TranslationPipe().transform('to accept this job.')}`,
        backText: 'Go Back',
        confirmText: 'Cancel Request',
        confirmAction: this.confirmCancelDelegationRequest.bind(this, type)
      },
      undo: {
        title: 'Undo Acceptance?',
        body: `${new TranslationPipe().transform('Please confirm you want to give this job back to')} ${this.job.job_delegation_request.delegator.name}.`,
        backText: 'Go Back',
        confirmText: 'Confirm',
        confirmAction: this.confirmCancelDelegationRequest.bind(this, type)
      },
      getJobBack: {
        title: 'Undo Delegation?',
        body: `${new TranslationPipe().transform('Please confirm you want to complete this job yourself.')}`,
        backText: 'Go Back',
        confirmText: 'Confirm',
        confirmAction: this.confirmCancelDelegationRequest.bind(this, type)
      }
    }
    return params[type];
  }

  async confirmCancelDelegationRequest(type) {
    await this.team.cancelDelegationRequest(this.job.job_delegation_request.id);
    const successParams = this.getDelegationSuccessParams(this.job.job_delegation_request.delegator.name, type);
    this.modalCtrl.dismiss();
    this.navCtrl.navigateForward('success', successParams);
  }

  getDelegationSuccessParams(delegatorName, type): SuccessPageParamsModel {
    const params = {
      cancel: {
        header: 'Request Cancelled',
        body: 'You are expected to complete this job.',
        buttonText: 'Go to Jobs',
        buttonRoute: `jobs`
      },
      undo: {
        header: 'Acceptance Undone',
        body: `${delegatorName} ${new TranslationPipe().transform('is now expected to complete this job.')}`,
        buttonText: 'Go to Jobs',
        buttonRoute: `jobs`
      },
      getJobBack: {
        header: 'Delegation Undone',
        body: `You are expected to complete this job.`,
        buttonText: 'Go to Jobs',
        buttonRoute: `jobs`
      }
    }
    return params[type];
  }

  showErrorMessage(message) {
    this.errorMessage = message;
    setTimeout(() => this.errorMessage = null, 10000);
  }

  async goToContactPage() {
    if (this.job.isStandby && this.mwService.isTooEarlyForJobStandby(this.job.startTime)) {
      return this.showTooEarlyStandbyAlert();
    }
    const phoneNumber = await this.mwStore.getPhoneNumber();
    if (!phoneNumber) {
      const params = {
        cameFromMainWorkflow: true,
        finishPage: 'ready-to-leave'
      }
      this.navCtrl.navigateForward('validate-phone', params);
   } else {
     this.navCtrl.navigateForward('job-messages');
   }
  }

  goToDelegatePage() {
    const params = {
      hkJobId: this.job.homekeeper_job_id
    }
    this.navCtrl.navigateForward('delegate-job', params);
  }

  learnMoreStandby() {
    this.iabUtils.openUrl('https://help.tidy.com/pros/how-to-get-clients-from-tidy#select-clients-who-need-you');
  }

  goToCallOutPage() {
    this.navCtrl.navigateForward('learn-about-teams');
  }
}
