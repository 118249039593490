<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Edit Service'"
    [showLinkIcon]="true"
    [linkIcon]="'trash'"
    (linkAction)="deleteService()">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <ng-container *ngIf="!teamService?.required_team_service_id && !teamService?.from_tidy">
        <tidy-card>
          <form [formGroup]="form" novalidate action="#">
            <tidy-row class="extra-bottom-padding">
              <tidy-text
                [header]="'Service Detail'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="field-top-padding field-bottom-padding">
              <tidy-input
                [label]="'Service Name'"
                [icon]="'assets/svg/information-circle-outline.svg'"
                formControlName="serviceName"
                [form]="form.controls.serviceName"
                [submitted]="submitted"
                [errorMessage]="'Please enter a service name.'">
              </tidy-input>
            </tidy-row>
            <tidy-row>
              <tidy-textarea
                label="Service Description"
                icon="assets/svg/chatbox-ellipses-outline.svg"
                formControlName="description"
                [form]="form.controls.description"
                [submitted]="submitted"
                [errorMessage]="'Please enter a service description.'">
              </tidy-textarea>
            </tidy-row>
             <tidy-row class="extra-top-padding">
               <tidy-checkbox
                  [(checked)]="allowOnWebsite"
                  [initValue]="allowOnWebsite"
                  [items]="[
                    {
                      viewValue: 'Allow Clients to book on my website', value: true
                    }
                  ]"
                  [value]="allowOnWebsite">
                </tidy-checkbox>
              </tidy-row>
            </form>
        </tidy-card>

        <tidy-card>
          <form [formGroup]="form" novalidate action="#">
            <tidy-row class="extra-bottom-padding">
              <tidy-text
                [header]="'Billing Type'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="field-top-padding" [ngClass]="form.value.billingType === '' ? '' : 'field-bottom-padding'">
              <tidy-select
                [icon]="'assets/svg/information-circle-outline.svg'"
                [label]="'Billing Type'"
                [form]="form.controls.billingType"
                formControlName="billingType"
                [items]="billingTypeItems"
                (optionChange)="changeBillingType($event)"
                [submitted]="submitted"
                [errorMessage]="'Please select a billing type.'">
              </tidy-select>
            </tidy-row>
            <ng-container *ngIf="showServiceCost">
              <tidy-row class="field-bottom-padding">
                <tidy-input
                  formControlName="serviceCost"
                  [label]="serviceCostLabel"
                  [form]="form.controls.serviceCost"
                  [icon]="'assets/svg/cash-outline.svg'"
                  [errorMessage]="'Please select a rate.'"
                  [submitted]="submitted"
                  inputMode="numeric"
                  [mask]="'currencyMask'">
                </tidy-input>
              </tidy-row>
            </ng-container>
            <tidy-row>
              <tidy-select
                [icon]="'assets/svg/time-outline.svg'"
                [label]="timeToBlockLabel"
                [form]="form.controls.timeToBlock"
                formControlName="timeToBlock"
                [items]="timeToBlockItems"
                [submitted]="submitted"
                [errorMessage]="'Please select a length.'">
              </tidy-select>
            </tidy-row>
          </form>
        </tidy-card>

        <tidy-row [align]="'center'" *ngIf="!showAdvancedSettings">
          <tidy-text
            [actionBody]="'Advanced Settings'"
            (action)="toggleShowAdvancedSettings()"
            class="link">
          </tidy-text>
        </tidy-row>

        <tidy-card *ngIf="showAdvancedSettings">
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [header]="'Advanced Settings'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="field-top-padding">
            <form [formGroup]="form" novalidate action="#">
              <tidy-select
                [icon]="'assets/svg/reload-circle-outline.svg'"
                [label]="'Frequency'"
                [form]="form.controls.frequency"
                formControlName="frequency"
                [items]="frequencyItems">
              </tidy-select>
            </form>
           </tidy-row>
        </tidy-card>

        <tidy-error-message
          *ngIf="errorMessage"
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-button
          [text]="'Save Changes'"
          [action]="saveChanges.bind(this)"
          class="primary">
        </tidy-button>

        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'Want to talk with our Concierge? '">
          </tidy-text><br>
          <tidy-text
            [actionBody]="'Schedule a Support Call'"
            (action)="goToScheduleCallPage()"
            class="link">
          </tidy-text>
        </tidy-row>
      </ng-container>

      <ng-container *ngIf="teamService?.from_tidy || teamService?.required_team_service_id">
        <tidy-alert [ngClass]="ratesPreview?.color + '-background'" *ngIf="ratesPreview?.header == 'Uncompetitive' || ratesPreview?.header == 'Very Uncompetitive'">
          <tidy-row>
            <tidy-text
              [header]="('Your rates are: ' | translate) + ratesPreview?.header">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="ratesPreview?.body + ' '">
            </tidy-text>
            <tidy-text
              (action)="learnMoreRates()"
              [body]="'Learn More'"
              class="link">
            </tidy-text>
          </tidy-row>
        </tidy-alert>

        <tidy-card>
          <tidy-row>
            <tidy-text
              [header]="teamService?.name | titlecase">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [body]="teamService?.description">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-divider>
            </tidy-divider>
          </tidy-row>
          <tidy-row class="extra-bottom-padding vertical-align-center">
            <tidy-image
              [src]="'assets/svg/information-circle-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="teamService?.service_type?.name | titlecase">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding vertical-align-center">
            <tidy-image
              [src]="'assets/svg/time-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="('Block Off Schedule For: ' | translate) + (teamService?.duration_to_block_off) + (' Minutes' | translate)">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding vertical-align-center">
            <tidy-image
              [src]="'assets/svg/cash-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="('Billing Type: ' | translate) + billingType">
            </tidy-text>
            <tidy-text
              *ngIf="teamService?.from_tidy && teamService?.billing_type == 'flat_rate'"
              (action)="goToEditRates()"
              [actionBody]="'Edit Rates'"
              class="link"
              style="margin-left: 5px">
            </tidy-text>
          </tidy-row>
          <ng-container *ngIf="billingType == 'Flat Rate'">
            <tidy-row class="extra-bottom-padding vertical-align-center">
              <tidy-image
                [src]="'assets/svg/cash-outline.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="('New Client Rate: ' | translate) + (teamService?.user_new_rate? (teamService?.user_new_rate | tcurrency) : '')">
              </tidy-text>
              <ng-container *ngIf="!teamService?.user_new_rate">
                <tidy-text
                  (action)="goToEditRates()"
                  [actionBody]="'Add location'"
                  class="link">
                </tidy-text>
                <tidy-text
                  [body]="' to see rates'">
                </tidy-text>
              </ng-container>
            </tidy-row>
            <tidy-row class="extra-bottom-padding vertical-align-center">
              <tidy-image
                [src]="'assets/svg/cash-outline.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="('Repeat Client Rate: ' | translate) + (teamService?.user_repeat_rate ? (teamService?.user_repeat_rate | tcurrency) : '')">
              </tidy-text>
              <ng-container *ngIf="!teamService?.user_repeat_rate">
                <tidy-text
                  (action)="goToEditRates()"
                  [actionBody]="'Add location'"
                  class="link">
                </tidy-text>
                <tidy-text
                  [body]="' to see rates'">
                </tidy-text>
              </ng-container>
            </tidy-row>
          </ng-container>
          <tidy-row class="extra-bottom-padding vertical-align-middle">
            <tidy-image
              [src]="'assets/svg/cash-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="('After Cutoff Cancellation Fee: ' | translate) + getCancellationFee()">
            </tidy-text>
            <tidy-text
              (action)="goToRequestAccessCancellationFees()"
              [actionBody]="'Edit Fee'"
              class="link"
              style="margin-left: 5px">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-top-padding">
            <tidy-text
              [body]="'* This is a default service. '">
            </tidy-text>
            <tidy-text
              [actionBody]="'Learn More'"
              class="link"
              (action)="learnMoreDefaultService()">
            </tidy-text>
          </tidy-row>
        </tidy-card>
      </ng-container>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
