import { Injectable } from '@angular/core';
import { HttpClient } from '../../shared/providers/http/http-client';

import { AppConfig } from 'src/shared/providers/config';
import { Aws } from 'src/shared/providers/aws';
import { FilePicker } from 'src/shared/providers/file-picker/file-picker';
import { PrivateClient } from 'src/providers/private-client/private-client';
import { Team } from 'src/providers/team/team';
import { generateUuid } from 'src/providers/tidy-session/session-uuid';

import { BillingHistoryModel } from 'src/models/private-payments.model';

import UrlUtils from 'src/shared/utils/url-utils';

@Injectable()
export class PrivatePayments {

  constructor(
    private aws: Aws,
    private appConfig: AppConfig,
    private filePicker: FilePicker,
    private httpClient: HttpClient,
    private privateClient: PrivateClient,
    private team: Team
  ) {}

  getBillingHistory(clientId): Promise<BillingHistoryModel> {
    const url = `api/v1/homekeeper/private/customers/${clientId}/billing-history`;
    return this.httpClient.get(url);
  }

  getDetailHistory(clientId, addressId) {
    const fromDate = new Date('1995-12-17T03:24:00');
    const url = `api/v1/homekeeper/private/customers/${clientId}/billing-detail-history?addresses[]=${addressId}&from_date=${fromDate}`;
    return this.httpClient.get(url);
  }

  refundCharge(payload, chargeToken) {
    const url = `api/v1/homekeeper/private/charges/${chargeToken}/refund`;
    return this.httpClient.post(url, payload);
  }

  sendInvoice(payload) {
    const url = `api/v1/homekeeper/invoices`;
    return this.httpClient.post(url, payload);
  }

  getInvoices(customerIds) {
    const url = `api/v1/homekeeper/invoices?customer_ids[]=${customerIds}`;
    return this.httpClient.get(url);
  }

  editInvoice() {
    //TODO use soon, see example payload:
    const payload = {
    	"customer_id": 1,
    	"invoice_items": [
    		{
    	    "id": 1,
    	    "type": "job",
    	  },
    		{
    	    "id": 2,
    	    "type": "job",
    	  }
      ],
    	"send_email": true
    };
    const url = `api/v1/homekeeper/invoices/edit`;
    return this.httpClient.post(url, payload);
  }

  cancelInvoice(invoiceId) {
    const url = `api/v1/homekeeper/invoices/${invoiceId}/cancel`;
    return this.httpClient.post(url, {});
  }

  recordPayment(payload) {
    const url = `api/v1/homekeeper/private/direct-payment`;
    return this.httpClient.post(url, payload);
  }

  voidDirectPayment(id) {
    const url = `api/v1/customer/private/direct-payment`;
    return this.httpClient.delete(url);
  }

  editPayment(jobId) {
    //TODO use soon, see example payload:
    const payload = {
      "credit_id": 1,
    	"pay_with": "cash",
    	"amount": 5000
    };
    const url = `api/v1/homekeeper/cleanings/${jobId}/payment/direct`;
    return this.httpClient.put(url, payload);
  }

  voidPayment(jobId) {
    //TODO use soon, see example payload:
    const payload = {
    	"credit_id": 1,
    	"amount": 5000,     // Amount of Direct Payment(in cents)
    };
    const url = `api/v1/homekeeper/cleanings/${jobId}/payment/direct/void`;
    return this.httpClient.put(url, payload);
  }

  addCredit(payload) {
    const url = `api/v1/homekeeper/private/customer-credits`;
    return this.httpClient.post(url, payload);
  }

  voidCredit(creditId, payload) {
    const url = `api/v1/homekeeper/private/customer-credits/${creditId}/void`;
    return this.httpClient.post(url, payload);
  }

  getPaymentOptions() {
    const url = 'api/v1/homekeeper/private/team-payment-options';
    return this.httpClient.get(url);
  }

  getEligiblePaymentTypes() {
    const url = 'api/v1/homekeeper/private/team-payment-options/eligible-types';
    return this.httpClient.get(url);
  }

  addPaymentOption(payload) {
    const url = 'api/v1/homekeeper/private/team-payment-options';
    return this.httpClient.post(url, payload);
  }

  editPaymentOption(payload) {
    const url = `api/v1/homekeeper/private/team-payment-options/${payload.team_payment_option_id}`;
    return this.httpClient.put(url, payload);
  }

  async copyWebsite(clientHubUuid) {
    const website = await this.team.getMyTeam();
    const url = this.getWebsiteUrl(website.url_suffix, clientHubUuid);
    UrlUtils.copyToClipBoard(url);
    localStorage.setItem('copied', 'true');
    setTimeout(() => localStorage.setItem('copied', 'false'), 3000);
  }

  getWebsiteUrl(urlSuffix, clientHubUuid) {
    const urls = {
      'https://api-sandbox3.tidy.com/': `ca-v2-sandbox3.tidy.com/#/client/${clientHubUuid}`,
      'https://api-staging.tidy.com/': `ca-v2-staging.tidy.com/#/client/${clientHubUuid}`,
      'https://api-production.gotidy.com/': `www.tidy.com/${urlSuffix}/client/${clientHubUuid}`
    }
    return urls[AppConfig.API];
  }

  isWebsiteCopied() {
    return localStorage.getItem('copied') == 'true';
  }

  getInvoiceNotes(invoiceId) {
    const url = `api/v1/homekeeper/invoice-notes?invoice_id=${invoiceId}`;
    return this.httpClient.get(url);
  }

  createInvoiceNote(payload) {
    const url = 'api/v1/homekeeper/invoice-notes';
    return this.httpClient.post(url, payload);
  }

  async addAttachment() {
    const uuid = generateUuid();
    const file = await this.filePicker.pickFile();
    const fileKey = `message/${uuid}/${file.filename}`;
    const imagesAllowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (imagesAllowedTypes.includes(file.format)) {
      const response = await this.aws.uploadFileToS3(file.dataUrl, fileKey, file.format);
      return response.Location;
    } else {
      return '';
    }
  }

}
