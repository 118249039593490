<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Payout Settings'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'TIDY pays out once a week. Select how you want to receive that payout:'">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <form [formGroup]="form">
          <ng-container *ngFor="let setting of payoutSettings; let last = last">
            <tidy-row>
              <tidy-radio-button
                class="radio-card-title"
                formControlName="payoutSetting"
                [form]="form.controls.payoutSetting"
                [items]="[{value: setting.value, viewValue: setting.viewValue}]"
                [submitted]="submitted"
                (optionChange)="changePayoutSetting(setting)"
                [errorMessage]="'Please select a setting.'">
              </tidy-radio-button>
            </tidy-row>
            <tidy-row [ngClass]="!last ? 'extra-bottom-padding' : ''">
              <tidy-text
                [body]="setting.description">
              </tidy-text>
            </tidy-row>
          </ng-container>
        </form>
      </tidy-card>

      <tidy-button
        [text]="'Next'"
        [action]="selectPayoutSetting.bind(this)"
        class="primary">
      </tidy-button>

    </ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>
    
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
