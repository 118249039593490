<ng-container *ngIf="!isInvoiceNote">
  <ng-container *ngIf="message?.type == 'text'">
    <tidy-row class="vertical-align-center">
      <tidy-initials-icon
        [fullName]="message?.from_member?.first_name + ' ' + message?.from_member?.last_name">
      </tidy-initials-icon>
      <tidy-text
        [body]="message?.from_member?.first_name"
        [translate]="false">
      </tidy-text>
      <div
      style="color: #666;
      font-family: Helvetica Neue;
      font-size: 13px;
      font-weight: 300;
      margin: 3px 0px 0px 5px">
        {{getDateTime(message)}}
      </div>
    </tidy-row>
    <tidy-row *ngFor="let media of message?.content?.files" [alignLastItemRight]="true" [ngClass]="last ? '' : 'extra-bottom-padding'" style="margin-top:-5px">
      <tidy-photo-note
        style="max-width: 150px"
        [src]="media">
      </tidy-photo-note>
    </tidy-row>
    <tidy-row *ngIf="message?.content?.text !== '' && message?.content?.text" [ngClass]="last ? '' : 'extra-bottom-padding'">
      <tidy-text
        [body]="message?.content?.text">
      </tidy-text>
    </tidy-row>
  </ng-container>
  <ng-container *ngIf="message?.type == 'phone_call'">
    <tidy-row class="vertical-align-center">
      <tidy-image
        [src]="'assets/img/phone-black.svg'"
        class="phone-icon"
        [ngClass]="(message?.content?.text == 'Calling...' || message?.content?.text == 'Call in-progress...') ? 'wiggle-animation' : ''">
      </tidy-image>
      <tidy-text
        [body]="message?.from_member?.first_name"
        [translate]="false">
      </tidy-text>
      <div
      style="color: #666;
      font-family: Helvetica Neue;
      font-size: 13px;
      font-weight: 300;
      margin: 3px 0px 0px 5px;
      cursor: pointer">
        {{getDateTime(message)}}
      </div>
    </tidy-row>
    <tidy-row [ngClass]="!last ? 'extra-bottom-padding' : ''">
      <tidy-text
        [body]="'<i>' + message?.content?.text + '</i>'">
      </tidy-text>
      <tidy-text
        *ngIf="message?.content?.text == 'Call finished.'"
        [body]="'<i>' + communication.getCallMinutes(message?.content?.phone_status?.duration) + '</i>'">
      </tidy-text>
    </tidy-row>
  </ng-container>
</ng-container>

<div *ngIf="isInvoiceNote" [ngStyle]="internalNoteStyle(message?.is_internal)">
  <tidy-row class="vertical-align-center" [ngClass]="{'extra-left-padding': addExtraPadding, 'extra-right-adding': addExtraPadding}">
    <tidy-image
      *ngIf="message?.is_internal"
      class="title-size"
      [src]="'assets/svg/create.svg'">
    </tidy-image>
    <tidy-initials-icon
      *ngIf="!message?.is_internal"
      [fullName]="message?.created_by">
    </tidy-initials-icon>
    <tidy-text
      [body]="message?.created_by"
      [translate]="false">
    </tidy-text>
    <div
    *ngIf="message?.sent_at"
    style="color: #666;
    font-family: Helvetica Neue;
    font-size: 13px;
    font-weight: 300;
    margin: 3px 0px 0px 5px">
      {{getDateTime(message)}}
    </div>
  </tidy-row>
  <tidy-row *ngIf="message?.media_url" [ngClass]="{'extra-left-padding': addExtraPadding, 'extra-right-adding': addExtraPadding}" [alignLastItemRight]="true" style="padding-bottom: 0px; margin-top:-5px">
    <tidy-photo-note
      style="max-width: 150px"
      [src]="message?.media_url">
    </tidy-photo-note>
  </tidy-row>
  <tidy-row *ngIf="message?.text !== '' && message?.text" [ngClass]="{'extra-left-padding': addExtraPadding, 'extra-right-adding': addExtraPadding}" style="padding-bottom: 0px">
    <tidy-text
      [body]="message?.text">
    </tidy-text>
  </tidy-row>
</div>

<tidy-row *ngIf="!last" class="extra-bottom-padding" [ngClass]="{'extra-left-padding': addExtraPadding, 'extra-right-adding': addExtraPadding}">
</tidy-row>
