import { Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ModalController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PrivateClient } from 'src/providers/private-client/private-client';
import { PrivatePayments } from 'src/providers/private-payments/private-payments';
import { PrivateJobService } from 'src/providers/private-job/private-job';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Team } from 'src/providers/team/team';
import { ToDosProvider } from 'src/providers/to-dos/to-dos';

import { Loading } from 'src/shared/components/loading/loading';

import { BillingHistoryModel } from 'src/models/private-payments.model';
import { ClientModel } from 'src/shared/models/client.model';
import { SuccessPageParamsModel } from 'src/pages/success/success';
import { PrivateJobModel } from 'src/shared/models/private-job.model';
import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';

import validationUtils from 'src/shared/utils/validation-utils';
import { removeCurrencyMask } from 'src/shared/utils/currency-utils';
import { CommonUtils } from 'src/shared/utils/common-utils';
import { CompleteJobModal } from 'src/shared/components/complete-job-modal/complete-job-modal';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: 'send-invoice.html',
  encapsulation: ViewEncapsulation.None
})

export class SendInvoicePage {

  billingHistory: BillingHistoryModel;
  cameFromCompleteJob: boolean;
  client: ClientModel;
  creditsApplied: number;
  copied: boolean;
  delegationRole: any;
  errorMessage: string;
  form: UntypedFormGroup;
  invoiceNotes: any;
  isInternalComment: boolean;
  jobCost: any;
  jobHoursItems: TidySelectNumberValueModel[];
  jobMinutesItems: TidySelectNumberValueModel[];
  jobId: any;
  loaded: boolean;
  messageSelectItems: any;
  proName: any;
  proPhoneNumber: any;
  proEmail: any;
  selectedMessageType: any;
  selectedJobs: PrivateJobModel[];
  sendInvoiceItems: any;
  submitted: boolean;
  totalOwed: number;
  today: any;

  constructor(
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private privateClient: PrivateClient,
    public privatePayments: PrivatePayments,
    private privateJobService: PrivateJobService,
    private storage: TidyStorage,
    private team: Team,
    private toDos: ToDosProvider,
    private utils: CommonUtils,
    private modalCtrl: ModalController
  ) {
    this.form = this.fb.group({
      email: [''],
      message: [''],
      messageType: ['public_reply'],
      jobHours: [''],
      jobMinutes: [''],
      price: [''],
      sendInvoice: ['']
    });
  }

  @Loading()
  async ionViewDidEnter() {
    this.loaded = false;
    this.today = moment();
    this.client = this.navCtrl.getParam('client');
    this.delegationRole = this.navCtrl.getParam('delegationRole');
    await this.storage.save('delegationRole', this.delegationRole);
    this.cameFromCompleteJob = this.navCtrl.getParam('cameFromCompleteJob');
    this.selectedJobs = this.navCtrl.getParam('selectedJobs');
    this.proName = await this.storage.retrieve('hk_name');
    this.proPhoneNumber = await this.storage.retrieve('hk_phone_number');
    this.proEmail = await this.storage.retrieve('hk_email');
    this.checkIfNeedsEmail();
    this.messageSelectItems = this.getMessageSelectItems();
    this.invoiceNotes = [];
    this.isInternalComment = false;
    this.jobHoursItems = this.team.buildJobWholeHourItems();
    this.jobMinutesItems = this.team.buildJobMinuteItems();
    const duration = this.selectedJobs[0].duration;
    this.patchDurationForm(duration);
    this.sendInvoiceItems = this.getSendInvoiceItems();
    this.form.patchValue({
      sendInvoice: this.delegationRole == 'delegatee' ? 'no' : 'yes'
    });
    if (this.selectedJobs[0].amount) {
      this.form.patchValue({
        price: (this.delegationRole == 'delegatee' ? this.selectedJobs[0].amount : (this.selectedJobs[0].amount / 100)).toString(),
      });
    }
    if (this.delegationRole !== 'delegatee') {
      this.form.controls.price.setValidators([Validators.required]);
      this.form.controls.price.updateValueAndValidity();
    }
    this.getAmounts();
    this.loaded = true;
  }

  getSendInvoiceItems() {
    return [
      {
        viewValue: 'Yes',
        value: 'yes'
      },
      {
        viewValue: 'No',
        value: 'no'
      }
    ];
  }

  patchDurationForm(minutes) {
    const hours = Math.floor(minutes / 60);
    minutes = Math.round((minutes - (hours * 60)) / 5) * 5;
    this.form.patchValue({
      jobHours: hours * 60,
      jobMinutes: minutes
    });
  }

  getMessageSelectItems() {
    return [
      {
        value: 'is_internal',
        viewValue: 'Internal note',
        icon: 'assets/svg/create-outline.svg'
      },
      {
        value: 'public_reply',
        viewValue: 'Public note',
        icon: 'assets/svg/arrow-redo-outline.svg'
      }
    ]
  }

  checkIfNeedsEmail() {
    if (!this.client.email) {
      this.form.controls.email.setValidators(Validators.compose([Validators.required, validationUtils.validateEmail]));
      this.form.controls.email.updateValueAndValidity();
    }
  }

  getAmounts() {
    this.totalOwed = 0;
    let jobAmounts = '';
    this.selectedJobs.map((job) =>  {
      this.totalOwed += job.billing.amount_due;
      jobAmounts += this.form.value.price;
    });
    this.creditsApplied = (jobAmounts ? parseInt(jobAmounts)*100 : 0) - this.totalOwed;
  }

  updateTotalOwed() {
    this.totalOwed = parseInt(this.form.value?.price.substring(1))*100 - this.creditsApplied;
  }

  @Loading()
  async submit() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    if (!this.client.email) {
      try {
        await this.privateClient.updatePrivateClient({email: this.form.value.email}, this.client.id);
      } catch(err) {
        this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      }
    }
    await this.storage.save('jobHours', this.form.value.jobHours);
    await this.storage.save('jobMinutes', this.form.value.jobMinutes);
    await this.storage.save('selectedJobs', this.selectedJobs);
    await this.storage.save('price', this.form.value.price);
    await this.storage.save('clientId', this.client.id);
    await this.storage.save('invoiceNotes', this.invoiceNotes);
    if (this.cameFromCompleteJob) {
      await this.storage.save('shouldSendInvoice', this.form.value.sendInvoice == 'yes');
      if (this.form.value.price && this.form.value.price !== '') {
        await this.storage.save('privateJobBillingAmount', removeCurrencyMask(this.form.value.price));
      }
      const modal = await this.modalCtrl.create({
        component: CompleteJobModal
      });
      return modal.present();
    } else {
      try {
        await this.privateJobService.sendPrivateJobInvoice();
        this.showSuccessInvoiceSent();
      } catch(err) {
        this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      }
    }
  }

  showSuccessInvoiceSent() {
    const successParams: SuccessPageParamsModel = {
      header: 'Invoice Sent',
      body: `${this.client.first_name} ${this.client.last_name} ${new TranslationPipe().transform('received an email at')} ${this.client.email}.`,
      buttonText: 'Ok',
      buttonRoute: `private-job/${this.selectedJobs[0].id}`
    };
    this.navCtrl.navigateForward('success', successParams);
  }

  skipInvoice() {
    this.navCtrl.navigateForward(`private-job/${this.selectedJobs[0].id}`);
  }

  updateIsInternalComment(selection) {
    this.isInternalComment = selection == 'is_internal';
    this.selectedMessageType = this.isInternalComment ? this.messageSelectItems[0] : this.messageSelectItems[1];
  }

  @Loading()
  async sendMessage() {
    this.errorMessage = '';
    try {
      const payload = {
        type: 'text',
        is_internal: this.isInternalComment,
        text: this.form.value.message,
        created_by: this.proName
      }
      await this.createInvoiceNote(payload);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async sendAttachment() {
    this.errorMessage = '';
    try {
      const attachment = await this.privatePayments.addAttachment();
      if (attachment == '') {
        return this.errorMessage = 'Unable to attach photo. Please upload a PNG or JPEG file.';
      }
      const payload = {
        type: 'photo',
        is_internal: this.isInternalComment,
        media_url: attachment,
        created_by: this.proName
      }
      await this.createInvoiceNote(payload);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async createInvoiceNote(payload) {
    this.invoiceNotes.unshift(payload);
    this.form.patchValue({
      message: ''
    });
  }

  getSubmitButtonText() {
    if (this.cameFromCompleteJob && this.form.value.sendInvoice == 'yes') {
      return 'Complete Job & Send Invoice';
    } else if (this.cameFromCompleteJob && this.form.value.sendInvoice == 'no') {
      return 'Complete Job';
    } else {
      return 'Send Invoice';
    }
  }

}
