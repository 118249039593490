import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { SharedLinksProvider } from 'src/providers/shared-links/shared-links';

@Component({
  templateUrl: 'shared-contact-client.html'
})

export class SharedContactClientPage {

  data: any;
  errorMessage: string;
  form: UntypedFormGroup;
  isPrivate: boolean;
  loaded: boolean;
  proNeedsPhone: boolean;
  submitted: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private sharedLinks: SharedLinksProvider,
    private navCtrl: CustomNavController
  ) {
    this.form = this.fb.group({
      phone: [null],
    });
  }

  async ionViewDidEnter() {
    this.loaded = false;
    this.data = this.navCtrl.getParam('data');
    this.isPrivate = !this.data.shared_link_data.job || this.data.shared_link_data.job.is_private;
    this.proNeedsPhone = !this.isPrivate && this.data.shared_link_data?.homekeepers[0]?.phone !== null && this.data.shared_link_data?.homekeepers[0]?.phone !== '';
    if (this.proNeedsPhone) {
      this.form.controls.phone.setValidators([Validators.required]);
    }
    this.loaded = true;
  }

  async contact(type) {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    let phone = this.data.shared_link_data.customer.phone_number;
    try {
      if (this.proNeedsPhone) {
        const response = await this.sharedLinks.updateSharedLink(this.data.uuid, {action: 'start_communication', phone_number: this.form.value.phone});
        phone = response.shared_link_data.gateway_phone;
      } else if (!this.isPrivate) {
        const response = await this.sharedLinks.updateSharedLink(this.data.uuid, {action: 'start_communication'});
        phone = response.shared_link_data.gateway_phone;
      }
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
    window.location.href = `${type}:${phone}`;
  }

}
