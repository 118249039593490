import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'slider-trigger',
  templateUrl: 'slider-trigger.html',
  styleUrls: ['./slider-trigger.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SliderTriggerComponent  {

  @Input() label: string;
  @Input() onTriggeredAction = null;

  value = 0;
  disabled = false;
  loading = false;

  constructor() {}

  onMultiClick(): void {
    if (this.value !== 1) {
      this.value = 1;
      this.onChanged();
    }
  }

  onChanged(): void {
    if (this.value < 1) {
      this.clear();
    } else {
      this.executeAction();
    }
  }

  async executeAction() {
    if (this.loading || this.onTriggeredAction == null) {
      return;
    }

    try {
      this.disabled = true;
      this.loading = true;
      await this.onTriggeredAction();
    } finally {
      this.reset();
    }
  }

  public reset(): void {
    this.value = 0;
    this.loading = false;
    this.disabled = false;
  }

  private clear(): void {
    if (this.value > 0) {
      this.value -= 0.01;
      setTimeout(() => {
        this.clear();
      }, 1);
    }
  }

}
