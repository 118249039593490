<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="pageTitle"
    [backButton]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">
      <ng-container *ngIf="!confirmingPlanCoop">

        <tidy-row [align]="'center'">
          <tidy-text
            *ngIf="cleaning?.type === 'cleaning' && !cleaning?.standby"
            [body]="'This Client needs you for their cleaning.'">
          </tidy-text>
          <tidy-text
            *ngIf="cleaning?.type === 'plan' && !cleaning?.standby"
            [body]="'This Client needs you for their recurring cleaning.'">
          </tidy-text>
          <tidy-text
            *ngIf="cleaning?.standby"
            body="This is a <u>standby opportunity</u>, which means there is about a 50% chance you will be needed. Assume the job will happen and go to it. You will receive the Booking Protection Minimum Payment">
          </tidy-text>
          <tidy-text
            *ngIf="cleaning?.standby"
            [body]="'(+' +
            (cleaning?.booking_protection_minimum_payment | tcurrency)">
          </tidy-text>
          <tidy-text
            *ngIf="') if you are not needed. '">
          </tidy-text>
          <tidy-text
            *ngIf="cleaning?.standby"
            (action)="openStandbyLearnMore()"
            body="Learn More"
            class="link">
          </tidy-text>
        </tidy-row>

        <tidy-card>
          <tidy-row>
            <tidy-text
              [header]="cleaning.customer_first_name"
              [translate]="false">
            </tidy-text>
            <tidy-image
              *ngIf="cleaning?.is_private || cleaning?.is_repeat"
              [src]="'assets/img/star.svg'"
              class="header-size"
              style="margin-left:5px">
            </tidy-image>
          </tidy-row>
          <tidy-row *ngIf="jobTimeItems?.length == 1">
            <tidy-text
              [body]="'Starts '">
            </tidy-text>
            <tidy-text
              [body]="(cleaning?.time | ttime)">
            </tidy-text>
            <tidy-text
              [body]="' on '">
            </tidy-text>
            <tidy-text
              [body]="(cleaning?.date | tdate: 'ddd M/DD/YY')">
            </tidy-text>
          </tidy-row>
          <ng-container *ngIf="cleaning?.address && cleaning?.is_private">
            <tidy-row>
              <tidy-text
                [body]="cleaning?.service_type_details?.customer_name || cleaning?.service_type_details?.name">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'Blocking '">
              </tidy-text>
              <tidy-text
                [body]="(cleaning?.service_type_details?.duration_in_minutes / 60)">
              </tidy-text>
              <tidy-text
                [body]="' hour' + ((cleaning?.service_type_details?.duration_in_minutes / 60) == 1 ? '' : 's') + ' on calendar'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="cleaning?.address?.address1 + (cleaning?.address?.address2 ? (' ' + cleaning?.address?.address2) : '') + (cleaning?.address_name ? (' - ' + cleaning?.address_name) : '')"
                [translate]="false">
              </tidy-text>
            </tidy-row>
          </ng-container>
          <tidy-row *ngIf="!(cleaning?.address && cleaning?.is_private)">
            <tidy-text
              [body]="(parseServiceLength(cleaning.service_type) | titlecase)">
            </tidy-text>
            <tidy-text
              [body]="' Regular Cleaning'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <payment-tag
              [billingType]="cleaning.billing_type"
              [totalValue]="cleaning.cleaning_amount"
              [bonusValue]="cleaning.bonus_amount">
            </payment-tag>
            <tidy-text
              *ngIf="cleaning?.is_private"
              [body]="'* '">
            </tidy-text>
            <tidy-text
              *ngIf="cleaning?.pay_rate_origin == 'job_amount_percentage'"
              [body]="'* '">
            </tidy-text>
            <tidy-text
              *ngIf="cleaning.type == 'plan'"
              [body]="' / Recurring'"
              style="margin-right:10px; margin-left: 5px">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="(cleaning?.homekeeper_preferences?.gender == 'female' ||  cleaning?.homekeeper_preferences?.gender == 'male') && hasTeamMembers">
            <tidy-text
              [body]="cleaning?.homekeeper_preferences?.gender == 'female' ? 'Females Only' : 'Males Only'">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="!(cleaning?.address && cleaning?.is_private)">
            <tidy-text
              [body]="'Zip: '">
            </tidy-text>
            <tidy-text
              [body]="cleaning?.zipcode">
            </tidy-text>
          </tidy-row>

          <tidy-row *ngIf="cleaning?.pay_rate_origin == 'job_amount_percentage'">
            <tidy-text
              [body]="'*Lower your public rates to allow clients to automatically book you at these rates in the future.'">
            </tidy-text>
          </tidy-row>

          <tidy-row *ngIf="cleaning?.is_private">
            <tidy-text
              [body]="'*This is a private job so you can edit the price after accepting.'">
            </tidy-text>
          </tidy-row>

          <tidy-row *ngIf="cleaning?.customer_is_premium">
            <tidy-text
              [actionBody]="'Big Spender: They book many jobs.'"
              class="green">
            </tidy-text>
          </tidy-row>

          <ng-container *ngIf="jobTimeItems?.length > 1">
            <tidy-row class="extra-top-padding extra-bottom-padding">
              <tidy-divider></tidy-divider>
            </tidy-row>

            <form [formGroup]="form">
              <tidy-row>
                <tidy-select
                  [label]="'Select which start time you want:'"
                  [items]="jobTimeItems"
                  [form]="form.controls.jobTime"
                  formControlName="jobTime">
                </tidy-select>
              </tidy-row>
            </form>
          </ng-container>

          <tidy-row *ngIf="cleaning?.standby" class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/people-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="'Standby'">
            </tidy-text>
          </tidy-row>
        </tidy-card>

        <tidy-card *ngIf="bookingFormAnswers?.length > 0">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/information.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [title]="'Job Note'">
            </tidy-text>
          </tidy-row>
          <ng-container *ngFor="let answer of bookingFormAnswers">
            <ng-container *ngIf="answer?.template_input?.type == 'text' || answer?.template_input?.type == 'text_area'">
              <tidy-row>
                <tidy-text
                  [body]="answer?.template_input?.label + ': ' + answer?.data?.value">
                </tidy-text>
              </tidy-row>
              <tidy-photo-note
                *ngIf="answer?.template_input?.image_url"
                [src]="answer?.template_input?.image_url">
              </tidy-photo-note>
            </ng-container>
            <ng-container *ngIf="answer?.template_input?.type == 'select'">
              <tidy-row>
                <tidy-text
                  [body]="answer?.template_input?.label + ': ' + me.getBookingFormSelectValue(answer?.data?.value)">
                </tidy-text>
              </tidy-row>
              <tidy-photo-note
                *ngIf="answer?.template_input?.image_url"
                [src]="answer?.template_input?.image_url">
              </tidy-photo-note>
            </ng-container>
            <ng-container *ngIf="answer?.template_input?.type == 'multiselect'">
              <tidy-row>
                <tidy-text
                  [body]="answer?.template_input?.label + ': ' + me.getBookingFormMultiselectValue(answer?.data?.value)">
                </tidy-text>
              </tidy-row>
              <tidy-photo-note
                *ngIf="answer?.template_input?.image_url"
                [src]="answer?.template_input?.image_url">
              </tidy-photo-note>
            </ng-container>
            <ng-container *ngIf="answer?.template_input?.type == 'file' && answer?.template_input?.data?.type == 'image'">
              <tidy-row>
                <tidy-text
                  [body]="answer?.template_input?.label">
                </tidy-text>
              </tidy-row>
              <tidy-carrousel
                class="photo-notes-carrousel only-padding-bottom"
                [scaleImages]="true">
                <tidy-photo-note
                  *tidyCarrousel
                  [src]="answer?.data?.value">
                </tidy-photo-note>
              </tidy-carrousel>
            </ng-container>
            <ng-container *ngIf="answer?.template_input?.type == 'files'  && answer?.template_input?.data?.type == 'images'">
              <tidy-row>
                <tidy-text
                  [body]="answer?.template_input?.label">
                </tidy-text>
              </tidy-row>
              <tidy-carrousel
                class="photo-notes-carrousel only-padding-bottom"
                [scaleImages]="true">
                <ng-container *ngFor="let imageUrl of answer?.data?.value">
                  <tidy-photo-note
                    *tidyCarrousel
                    [src]="imageUrl">
                  </tidy-photo-note>
                </ng-container>
              </tidy-carrousel>
            </ng-container>
          </ng-container>
        </tidy-card>

        <tidy-alert *ngIf="jobsFromClientCount > 1">
          <tidy-text
            [body]="cleaning?.customer_first_name"
            [translate]="false">
          </tidy-text>
          <tidy-text
            [body]="' also requested '">
          </tidy-text>
          <tidy-text
            [body]="(jobsFromClientCount - 1)">
          </tidy-text>
          <tidy-text
            [body]="' other jobs. '">
          </tidy-text>
          <tidy-text
            (action)="openOtherOptionsPage()"
            [body]="'View'"
            class="link">
          </tidy-text>
        </tidy-alert>

        <tidy-row class="extra-bottom-padding">
          <div [attr.id]="mapUid" style="width: 100%; height: 300px">
          </div>
        </tidy-row>

        <tidy-alert *ngIf="didGoLive">
          <tidy-row style="margin-top: -5px">
            <tidy-checkbox
              [(checked)]="bookingProtection"
              [items]="[
                {
                  viewValue: 'Booking Protection', value: true
                }
              ]"
              [initValue]="bookingProtection">
            </tidy-checkbox>
          </tidy-row>

          <tidy-row>
            <tidy-text
              [body]="'If Client cancels, find me a new Client during this time or earn '">
            </tidy-text>
            <tidy-text
              [body]="(cleaning?.booking_protection_minimum_payment | tcurrency) +
              '. '">
            </tidy-text>
            <tidy-text
              (action)="openBookingProtectionLearnMore()"
              [body]="'Learn More'"
              class="link">
            </tidy-text>
          </tidy-row>
        </tidy-alert>

        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'Please take '">
          </tidy-text>
          <countdown
            (onTimeout)="enableClaimButton()"
            [target]="countdownTarget">
          </countdown>
          <tidy-text
            [body]="' to review.'">
          </tidy-text>
        </tidy-row>

        <tidy-error-message
          [text]="apiError">
        </tidy-error-message>

        <ng-container *ngIf="cleaning?.type === 'cleaning'">
          <ion-row>
            <div style="width: 100%;">
              <tidy-button
                [disabled]="claimButtonDisabled"
                [text]="'Add Job'"
                [action]="claimCleaning.bind(this)"
                class="primary">
              </tidy-button>
            </div>
          </ion-row>
          <ion-row *ngIf="cleaning?.allow_decline">
            <div style="width: 100%;">
              <tidy-button
                [disabled]="claimButtonDisabled"
                [text]="'Decline Request'"
                [action]="showConfirmDeclineCleaningAlert.bind(this)"
                class="secondary">
              </tidy-button>
            </div>
          </ion-row>
        </ng-container>

        <ng-container *ngIf="cleaning?.type === 'plan'">
          <ion-row>
            <div style="width: 100%;">
              <tidy-button
                [disabled]="claimButtonDisabled"
                [text]="'Add Plan'"
                [action]="confirmPlanCoop.bind(this)"
                class="primary">
              </tidy-button>
            </div>
          </ion-row>
          <ion-row *ngIf="cleaning?.allow_decline">
            <div style="width: 100%;">
              <tidy-button
                [disabled]="claimButtonDisabled"
                [text]="'Decline Request'"
                [action]="showConfirmDeclineCleaningAlert.bind(this)"
                class="secondary">
              </tidy-button>
            </div>
          </ion-row>

          <tidy-row [align]="'center'">
            <tidy-text
              *ngIf="cleaning?.bonus_amount === 0"
              [body]="(cleaning?.date | tdate:'dddd') +
              's ' +
              (cleaning?.time | ttime) +
              ' - ' +
              (cleaningEndTime | ttime) +
              '.'">
            </tidy-text>
            <tidy-text
              *ngIf="cleaning?.bonus_amount === 0"
              [body]="'Makes you bookable '">
            </tidy-text>
            <tidy-text
              *ngIf="cleaning?.bonus_amount > 0"
              [body]="'Keep this time bookable 4 weeks to keep the bonus. $'">
            </tidy-text>
            <tidy-text
              *ngIf="cleaning?.bonus_amount > 0"
              [body]="(cleaning?.bonus_amount | tcurrency)">
            </tidy-text>
            <tidy-text
              *ngIf="cleaning?.bonus_amount > 0"
              [body]="' bonus paid with first job only.'">
            </tidy-text>
          </tidy-row>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="confirmingPlanCoop">
        <tidy-row [align]="'center'" *ngIf="cleaning.bonus_amount <= 0">
          <tidy-text
            [body]="'Claiming this opportunity will make you bookable '">
          </tidy-text>
          <tidy-text
            [body]="(cleaning?.date | tdate:'dddd') + 's'">
          </tidy-text>
          <tidy-text
            [body]="' from '">
          </tidy-text>
          <tidy-text
            [body]="(cleaning?.time | ttime)">
          </tidy-text>
          <tidy-text
            [body]="' to '">
          </tidy-text>
          <tidy-text
            [body]="(cleaningEndTime | ttime) + '. '">
          </tidy-text>
          <tidy-text
            (action)="openBonusLearnMore()"
            [body]="'Learn More'"
            class="link">
          </tidy-text>
        </tidy-row>

        <ng-container *ngIf="cleaning.bonus_amount > 0">
          <tidy-row [align]="'center'">
            <tidy-text
              [body]="'You will be paid the '">
            </tidy-text>
            <tidy-text
              [body]="(cleaning?.bonus_amount | tcurrency)">
            </tidy-text>
            <tidy-text
              [body]="'bonus if you complete the '">
            </tidy-text>
            <tidy-text
              [body]="(cleaning?.date | tdate: 'dddd MM/DD')">
            </tidy-text>
            <tidy-text
              [body]="' cleaning or the Client cancels after 8pm the night before.'">
            </tidy-text>
          </tidy-row>

          <tidy-row [align]="'center'">
            <tidy-text
              [body]="'To keep this bonus you must <b>remain bookable</b> from '">
            </tidy-text>
            <tidy-text
              [body]="(cleaning?.time | ttime)">
            </tidy-text>
            <tidy-text
              [body]="' to '">
            </tidy-text>
            <tidy-text
              [body]="(cleaningEndTime | ttime)">
            </tidy-text>
            <tidy-text
              [body]="' on:'">
            </tidy-text>
          </tidy-row>

          <tidy-row *ngFor="let date of planDates" [align]="'center'">
            <tidy-text
              [body]="(date | tdate: 'dddd MM/DD')">
            </tidy-text>
          </tidy-row>

          <tidy-row class="extra-bottom-padding" [align]="'center'">
            <tidy-text
              [body]="'You will lose the bonus if you are unbookable for this time on any of those days. '">
            </tidy-text>
            <tidy-text
              (action)="openBonusLearnMore()"
              [body]="'Learn More'"
              class="link">
            </tidy-text>
          </tidy-row>
        </ng-container>

        <tidy-error-message
          [text]="apiError">
        </tidy-error-message>

        <ion-row>
          <div style="width: 100%;">
            <tidy-button
              [disabled]="claimButtonDisabled"
              [action]="claimCleaning.bind(this)"
              [text]="'Confirm'"
              class="primary">
            </tidy-button>
          </div>
        </ion-row>
        <ion-row *ngIf="cleaning?.allow_decline">
          <div style="width: 100%;">
            <tidy-button
              [disabled]="claimButtonDisabled"
              [text]="'Decline Request'"
              [action]="showConfirmDeclineCleaningAlert.bind(this)"
              class="secondary">
            </tidy-button>
          </div>
        </ion-row>

      </ng-container>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
