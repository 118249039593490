<ng-template
  let-extraClass="extraClass"
  #topNav
  >
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="false"
    [title]="'Update Password'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper *ngIf="loaded">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'Please enter your new password.'">
      </tidy-text>
    </tidy-row>

    <tidy-card>
      <form [formGroup]="form" novalidate action="#">
        <tidy-row class="field-top-padding field-bottom-padding">
          <tidy-input
            formControlName="password"
            [form]="form.controls.password"
            [label]="'New Password'"
            [submitted]="submitted"
            [form]="form"
            [icon]="'assets/svg/lock-closed-outline.svg'"
            [activeIcon]="'assets/svg/lock-closed-outline-tidy-green.svg'"
            [errorMessage]="'Please enter new a password.'">
          </tidy-input>
        </tidy-row>
        <tidy-row>
          <tidy-input
            formControlName="confirm_password"
            [form]="form.controls.confirm_password"
            [label]="'Confirm New Password'"
            [submitted]="submitted"
            [form]="form"
            [icon]="'assets/svg/lock-closed-outline.svg'"
            [activeIcon]="'assets/svg/lock-closed-outline-tidy-green.svg'"
            [errorMessage]="'Please confirm your new a password.'">
          </tidy-input>
        </tidy-row>
      </form>
    </tidy-card>

    <tidy-error-message
      *ngIf="form.controls.confirm_password.dirty && form.hasError('mismatchedPasswords') && form.controls.password.valid"
      [text]="'The password you entered do not match.'">
    </tidy-error-message>

    <tidy-error-message
      *ngIf="form.controls.confirm_password.dirty && form.hasError('minLength')"
      [text]="'Please enter a password with at least 8 characters.'">
    </tidy-error-message>

    <tidy-button
      [text]="'Save New Password'"
      [action]="save.bind(this)"
      class="primary">
    </tidy-button>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
