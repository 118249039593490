import { Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PrivatePayments } from 'src/providers/private-payments/private-payments';

import { ClientModel } from 'src/shared/models/client.model';
import { removeCurrencyMask } from 'src/shared/utils/currency-utils';

@Component({
  templateUrl: 'add-credit.html',
  encapsulation: ViewEncapsulation.None
})

export class AddCreditPage {

  client: ClientModel;
  errorMessage: string;
  form: UntypedFormGroup;
  submitted: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private privatePayments: PrivatePayments
  ) {
    this.form = this.fb.group({
      amount: ['', Validators.required]
    });
  }

  ionViewDidEnter() {
    this.client = this.navCtrl.getParam('client');
  }

  async addCredit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    const payload = {
      amount: removeCurrencyMask(this.form.value.amount),
      obs: "Direct",
      currency: 'USD',
      customer_id: this.client.id
    };
    try {
      await this.privatePayments.addCredit(payload);
      this.goToSuccessPage();
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  goToSuccessPage() {
    const params = {
      header: 'Credit Added',
      body: '',
      buttonText: 'Ok',
      buttonRoute: `private-client/${this.client.id}`
    };
    this.navCtrl.navigateForward('success', params);
  }
}
