import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'tdate'
})
export class TidyDatePipe implements PipeTransform {

  transform(value: string, resultMask: string, parseMask: string = null, fromUtc = false): string {
    return fromUtc
            ? this.fromUtc(value, resultMask, parseMask)
            : this.fromDefault(value, resultMask, parseMask);
  }

  private fromUtc(value: string, resultMask: string, parseMask: string = null): string {
    return moment.utc(value, parseMask).local().format(resultMask);
  }

  private fromDefault(value: string, resultMask: string, parseMask: string = null): string {
    return moment(value, parseMask).format(resultMask);
  }

}
