<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Accept Request'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'Your repeat Client '">
      </tidy-text>
      <tidy-text
        [body]="(request?.address?.customer?.account_name !== '' && request?.address?.customer?.account_name ? request?.address?.customer?.account_name : request?.address?.customer?.first_name)">
      </tidy-text>
      <tidy-text
        *ngIf="request?.address?.address1"
        [body]="' at '">
      </tidy-text>
      <tidy-text
        *ngIf="request?.address?.address1"
        [body]="request?.address?.address1 +
        (request?.address?.address2 ? (' ' + request?.address?.address2) : '') +
        (request?.address?.address_name ? (' - ' + request?.address?.address_name) : '')">
      </tidy-text>
      <tidy-text
        [body]="', requests you specifically for '">
      </tidy-text>
      <tidy-text
        [body]="(request?.service_type_details?.customer_name || request?.service_type_details?.name | titlecase) +
        ' ' +
        (request?.frequency | planFrequency) +
        '!'">
      </tidy-text>
      <tidy-text
        *ngIf="request?.homekeeper_rate"
        [body]="' You will be paid '">
      </tidy-text>
      <tidy-text
        *ngIf="request?.homekeeper_rate"
        [body]="(request?.homekeeper_rate | tcurrency)">
      </tidy-text>
      <tidy-text
        *ngIf="request?.homekeeper_rate"
        [body]="' total.'">
      </tidy-text>
    </tidy-row>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'Tap a time to accept:'">
      </tidy-text>
    </tidy-row>

    <ng-container *ngFor="let day of request?.request_times">
      <tidy-row>
        <tidy-text
          [header]="day[0]?.date | tdate: 'M/DD/YY'">
        </tidy-text>
      </tidy-row>
      <tidy-row *ngFor="let time of day; let even = even; let i = index">
        <tidy-two-column-row *ngIf="even">
          <tidy-card>
            <tidy-card-button (action)="acceptRequest(time)" [hideArrow]="true">
              <tidy-text
                [body]="time?.time | ttime">
              </tidy-text>
            </tidy-card-button>
          </tidy-card>
          <tidy-card *ngIf="day[i+1]">
            <tidy-card-button (action)="acceptRequest(day[i+1])" [hideArrow]="true">
              <tidy-text
                [body]="day[i+1]?.time | ttime">
              </tidy-text>
            </tidy-card-button>
          </tidy-card>
        </tidy-two-column-row>
      </tidy-row>
    </ng-container>

    <tidy-row class="extra-top-padding">
      <tidy-button
        [text]="'Propose Alternate Times'"
        [action]="goToProposeAlternate.bind(this)"
        class="secondary">
      </tidy-button>
    </tidy-row>
    <tidy-row>
      <tidy-button
        [text]="'Decline Request'"
        [action]="declineRequest.bind(this)"
        class="secondary">
      </tidy-button>
    </tidy-row>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-row *ngIf="certificationError">
      <tidy-button
        [text]="'Get New Clients'"
        [action]="goToFinishCertification.bind(this)"
        class="primary">
      </tidy-button>
    </tidy-row>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
