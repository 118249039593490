import { Component, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PrivateClient } from 'src/providers/private-client/private-client';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';
import validationUtils from 'src/shared/utils/validation-utils';

import { AutoCompleteAddressModel } from 'src/shared/models/autocomplete-address.model';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { MultipleCountryService } from 'src/providers/addresses/multiple-country.service';
import { RadioButtonModel } from '@tidyapp/tidy-ui-components';
import { keepOnlyText } from 'src/shared/utils/text-utils';
import { Me } from 'src/shared/providers/me';
import { AddressMetadata } from 'src/shared/components/autocomplete-address/autocomplete-address';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

@Component({
  templateUrl: 'add-private-client.html'
})

export class AddPrivateClientPage extends Timeout {

  autocompleteAddress: AutoCompleteAddressModel[];
  errorMessage: string;
  form: UntypedFormGroup;
  submitted: boolean;
  subscriptions: Array<Subscription> = [];
  countryList: RadioButtonModel[];
  zipCodeMask = {
    mask: '00000'
  }
  countryCode: string;
  noAddressPickedTemplate: ElementRef;
  invalidAddressError: string;

  constructor(
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private iabUtils: InAppBrowserUtils,
    private privateClient: PrivateClient,
    private multipleCountryService: MultipleCountryService,
    private me: Me,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phone: [null],
      email: ['', Validators.compose([validationUtils.validateEmail])],
      address: [''],
      unit: [''],
      zip: [''],
      latitude: '',
      longitude: '',
      city: [''],
      state: ['']
    });

    this.countryList = this.multipleCountryService.countryRadioButtonList
  }

  async ionViewDidEnter() {
    try {
      await this.populateForm();
      this.setZipAddressRequirements();
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  async populateForm() {
    const me = await this.me.fetchWithoutCache();
    const countryCode = me.profile.address.country_code;
    this.setValidators(countryCode);
    this.countryCode = countryCode;
  }

  setValidators(proCountryCode: string) {
    const validatorObject = this.multipleCountryService.getCountrySettings(proCountryCode);
    this.zipCodeMask = validatorObject.mask;
  }

  setZipAddressRequirements() {
    const dynamicValidations = [
      {
        subscription: 'address',
        target: 'zip',
        validator: this.multipleCountryService.getCountrySettings(this.countryCode).validator
      },
      {
        subscription: 'zip',
        target: 'address',
        validator: Validators.required
      }
    ];

    dynamicValidations.forEach(validation => {
      this.subscriptions.push(this.form.controls[validation.subscription].valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(value => {
        if (value === '') {
          this.form.controls[validation.target].clearValidators();
          this.form.controls[validation.target].updateValueAndValidity();
          return;
        }

        if (validation.subscription === 'address') {
          const selectedValidator = this.multipleCountryService.getCountrySettings(this.countryCode).validator;

          this.form.controls[validation.target].setValidators(selectedValidator);
        } else {
          this.form.controls[validation.target].setValidators(validation.validator);
        }

        this.form.controls[validation.target].updateValueAndValidity();
      }));
    });
  }

  addPrivateClient = async () => {
    this.submitted = true;

    if(this.invalidAddressError) {
      this.errorMessage = this.invalidAddressError;
      return;
    }

    if (this.form.invalid) {
      return;
    }
    const data = this.prepareData();
    try {
      const client = await this.privateClient.addPrivateClient(data);
      const params = {
        client: client
      }
      this.customNavCtrl.navigateForward('client-added', params);
    } catch (err) {
      this.errorMessage = err.message;
    }
  }

  prepareData() {
    let data = {
      customer: {
        first_name: this.form.value.firstName,
        last_name: this.form.value.lastName,
      }
    };
    if (this.form.value.phone_number) {
      data.customer['phone_number'] = this.form.value.phone;
    }
    if (this.form.value.email) {
      data.customer['email'] = this.form.value.email;
    }
    if (this.form.value.address) {
      data['address'] = {
        address: this.form.value.address,
        zipcode: keepOnlyText(this.form.value.zip),
        unit: this.form.value.unit,
        latitude: this.form.value.latitude,
        longitude: this.form.value.longitude,
        country_code: this.countryCode,
        city: this.form.value.city,
        address_state: this.form.value.state
      }
    }
    return data;
  }

  choseItem(addressMetadata: AddressMetadata) {
    this.form.patchValue({ zip: addressMetadata.zipcode, ...addressMetadata });
    this.errorMessage = '';
  }

  learnMore() {
    const url = 'https://help.tidy.com/pros/manage-private-clients#what-are-my-private-clients';
    this.iabUtils.openUrl(url);
  }

  openAgreement() {
    this.iabUtils.openUrl('https://www.tidy.com/legal/service-provider/');
  }

  onDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
