import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, FormControl } from '@angular/forms';
import { DateTime as LuxonDateTime } from 'luxon';
import { ActivatedRoute, Router } from '@angular/router';

import { Cleanings } from 'src/providers/cleanings/cleanings';
import { Communication } from 'src/providers/communication/communication';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Me } from 'src/shared/providers/me';
import { PrivateClient } from 'src/providers/private-client/private-client';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

import { MWStore } from 'src/main-workflow/mw.store';
import { MWService } from 'src/main-workflow/mw.service';
import { mwMoments } from 'src/main-workflow/mw.moments';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

import { Loading } from 'src/shared/components/loading/loading';
import { Timeout } from 'src/shared/components/timeout/timeout';

declare const window: any;

@Component({
  selector: 'client-messages',
  templateUrl: './client-messages.html'
})

export class ClientMessagesPage extends Timeout {

  backPage: string;
  client: any;
  errorMessage: string;
  form: UntypedFormGroup;
  messages: any;
  select: string;
  submitted: boolean;

  constructor(
    private cleanings: Cleanings,
    public communication: Communication,
    private fb: UntypedFormBuilder,
    private iabUtils: InAppBrowserUtils,
    private me: Me,
    private mwStore: MWStore,
    private mwService: MWService,
    private navCtrl: CustomNavController,
    public privateClient: PrivateClient,
    router: Router,
    public route: ActivatedRoute,
    timeoutErrorHandler: TimeoutErrorHandler
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.fb.group({
      message: ['', Validators.required],
    });
  }

  @Loading()
  async ionViewDidEnter() {
    try {
      this.loaded = false;
      this.client = this.navCtrl.getParam('client') || await this.getClient();
      this.backPage = localStorage.getItem('clientMessagesBackPage') || 'messages';
      this.messages = await this.communication.getSharedInbox(this.client.id);
      await this.markMessagesAsRead();
    } catch(err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
    this.loaded = true;
  }

  async markMessagesAsRead() {
    let array: any = [];
    this.messages.map((message) => {
      if (!message.is_read && message.from_member.type == 'CustomerMember' && message?.chat_room_key) {
        array.push((message?.chat_room_key));
      }
    });
    if (array.length > 0) {
      await this.communication.markMessagesAsRead(array);
    }
  }

  async getClient() {
    const clientId = this.route.snapshot.paramMap.get('clientId');
    return await this.privateClient.getPrivateClientInfo(clientId);
  }

  getJobDate(date, timezone) {
    return LuxonDateTime.fromISO(date).setZone(timezone).toFormat('L/d');
  }

  @Loading()
  async sendMessage(attachment = []) {
    this.errorMessage = '';
    this.submitted = true;
    if (attachment.length == 0 && !this.form.get('message').valid) {
      return;
    }
    const payload = {
      chat: {
        type: 'account',
        data: {
          customer_account_id: this.client.id
        }
      },
      message: {
        text: this.form.value.message,
        files: attachment
      }
    };
    try {
      const response = await this.communication.sendSharedInboxMessage(payload);
      if (response?.data?.message == 'We couldn\'t send your message, please try again later!') {
        return this.errorMessage = response.data.message;
      }
      this.messages = await this.communication.getSharedInbox(this.client.id);
      this.form.patchValue({
        message: ''
      });
      this.submitted = false;
    } catch (err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async sendAttachment() {
    this.errorMessage = '';
    try {
      const attachment = await this.communication.addAttachment();
      if (attachment == '') {
        return this.errorMessage = 'Unable to attach photo. Please upload a PNG or JPEG file.';
      }
      await this.sendMessage([attachment]);
    } catch (err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  getMessageDateTime(messageDateTime) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const relativeDate = LuxonDateTime.fromISO(messageDateTime).setZone(timezone).toRelative();
    if (relativeDate.includes('day') || relativeDate.includes('month') || relativeDate.includes('year')) {
      return LuxonDateTime.fromISO(messageDateTime).setZone(timezone).toFormat('EEE LLL d h:mm a');
    } else {
      return relativeDate;
    }
  }

  callClient() {
    this.iabUtils.openUrl(`tel://${this.client.phone_number}`);
  }

  @Loading()
  async goToJobPage(jobId, isPrivate, jobStartDate) {
    try {
      const jobsThatDay = await this.cleanings.fetch(jobStartDate, jobStartDate);
      const job = jobsThatDay[0].cards.find((job) => job.job.id == jobId);
      const backPage = `client-messages/${this.client.id}`;
      if (isPrivate) {
        localStorage.setItem('privateJobBackPage', backPage)
        this.navCtrl.navigateForward(`private-job/${job.job.id}`);
      } else if (job?.card_type === 'current_cleaning') {
        this.goToMainWorkflow(job);
      } else if (job?.card_type === 'past_cleaning') {
        localStorage.setItem('pastJobBackPage', backPage)
        this.navCtrl.navigateForward(`past-job/${job.job.id}`);
      } else {
        localStorage.setItem('futureJobBackPage', backPage)
        this.navCtrl.navigateForward('future-job', job);
      }
    } catch (err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  @Loading()
  async goToMainWorkflow(job) {
    try {
      const jobData = await this.mwService.getJob(job.job.id);
      this.mwStore.setJob(jobData);
      const storedJobId = await this.mwStore.getJobId();
      const syncData = await this.mwStore.getSyncData();
      const hasJobInProgress = storedJobId !== null && storedJobId !== undefined;
      const clickedJobInProgress = storedJobId == job.job.id;
      const startedJobInProgress = syncData !== null && syncData !== undefined;
      const clearStore = hasJobInProgress && clickedJobInProgress;
      if (this.mwService.isTooLateForJob(jobData.endTime)) {
        return this.mwService.showTooLateAlert();
      }
      if (jobData.cancelled) {
        return this.mwService.showJobCancelledAlert(clearStore);
      }
      if (jobData.isStandbyCancelled) {
        return this.mwService.showStandbyCancelledAlert(clearStore);
      }
      if (jobData.inactive) {
        return this.mwService.showInactiveJobAlert(clearStore);
      }
      if (startedJobInProgress && clickedJobInProgress) {
        this.mwService.addMomentToSyncData(mwMoments.viewedJob, 'jobs', job.job.id);
      } else {
        this.mwService.sendMomentToBackend(mwMoments.viewedJob, 'jobs', job.job.id);
      }
      if (hasJobInProgress && clickedJobInProgress) {
        const storedRoute = await this.mwStore.getRoute();
        this.navCtrl.navigateForward(storedRoute);
      } else {
        localStorage.setItem('readyToLeaveBackPage', `client-messages/${this.client.id}`)
        this.navCtrl.navigateForward('ready-to-leave');
      }
    } catch (err) {
      this.mwService.showErrorPage(err);
    }
  }

}
