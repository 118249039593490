import { Directive, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { Subject, TimeoutError } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

@Directive({})
export class Timeout implements OnDestroy {

  private destroy$: Subject<void> = new Subject();
  isActive: boolean;
  public loaded$ = false;
  protected alreadyShowedOfflineOrTimeoutAlert: boolean;
  protected successPageLoad: EventEmitter<boolean> = new EventEmitter<boolean>();
  protected retrySubject = new Subject<void>();

  constructor(
    public timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    activatedRoute: ActivatedRoute
  ) {
    router.events.pipe(
      takeUntil(this.destroy$),
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.isActive = this.isComponentActive(
        router.routerState.snapshot.root.pathFromRoot,
        activatedRoute.snapshot.component
      );
    });

    this.retrySubject.subscribe(() => {
      this.alreadyShowedOfflineOrTimeoutAlert = false;
    });
  }

  onDestroy() {
  }

  ngOnDestroy() {
    this.onDestroy();
    this.destroy$?.next();
    this.destroy$?.complete();
    this.isActive = false;
  }

  set loaded(value: boolean) {
    this.loaded$ = value;
    this.successPageLoad.emit(value);
  }

  get loaded(): boolean {
    return this.loaded$;
  }

  ionViewDidEnter() {
  }

  timeoutHandler(error): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.isActive && !this.loaded && !this.alreadyShowedOfflineOrTimeoutAlert && error instanceof TimeoutError) {
        this.alreadyShowedOfflineOrTimeoutAlert = true;
        this.timeoutErrorHandler.showTimeoutAlert(this.successPageLoad, this.ionViewDidEnter.bind(this), this.retrySubject);
      } else {
        resolve({});
      }
      reject(error);
    });
  }

  private isComponentActive(path: ActivatedRouteSnapshot[], component: any): boolean {
    return path.some((ss: ActivatedRouteSnapshot) => {
      return ss.component === component || this.isComponentActive(ss.children, component);
    });
  }
}
