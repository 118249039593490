<ng-container class="video-recorder-container">
  <video class="video" #videoElem autoplay controls></video>
  <div class="video-recorder-container__btn-wrapper">
    <tidy-button
      [text]="htmlVideoRec.isRecording ? 'Stop Recording' : htmlVideoRec.videoBlob ? 'Retry' : 'Start Recording'"
      [action]="btnAction.bind(this)"
      [class]="htmlVideoRec.videoBlob ? 'secondary rec-btn' : 'primary rec-btn'">
    </tidy-button>

    <tidy-button
      text="Save"
      [action]="save.bind(this)"
      [disabled]="!htmlVideoRec.videoBlob"
      [class]="htmlVideoRec.videoBlob ? 'primary' : 'secondary'">
    </tidy-button>
  </div>
</ng-container>
