<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Edit Address'"
    [showLinkIcon]="true"
    [linkIcon]="'trash'"
    (linkAction)="confirmDeleteAddress()">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-card>
        <form [formGroup]="form" novalidate action="#">
          <tidy-row class="field-top-padding field-bottom-padding">
            <tidy-auto-complete-address
              [submitted]="submitted"
              [addressFormControl]="form.controls.address"
              [countryCode]="countryCode"
              (onAddressChosen)="choseItem($event)"
              (onError)="errorMessage = $event"
              (noAddressPickedUpdate)="noAddressPickedTemplate = $event"
              (invalidAddressError)="invalidAddressError = $event"
            ></tidy-auto-complete-address>
          </tidy-row>
          <tidy-row class="field-bottom-padding">
            <tidy-input
              [label]="'Unit / Suite'"
              formControlName="unit"
              data-cy="unit"
              [form]="form.controls.unit"
              [icon]="'assets/svg/home-outline.svg'"
              [activeIcon]="'assets/svg/home-outline-tidy-green.svg'">
            </tidy-input>
          </tidy-row>
          <tidy-row>
            <tidy-input
              [label]="'Zipcode'"
              [customMask]="zipCodeMask"
              formControlName="zipcode"
              data-cy="zipcode"
              [inputMode]="'numeric'"
              [form]="form.controls.zipcode"
              [icon]="'assets/svg/home-outline.svg'"
              [activeIcon]="'assets/svg/home-outline-tidy-green.svg'"
              [submitted]="submitted"
              [errorMessage]="'Please enter a valid zip code'">
            </tidy-input>
          </tidy-row>
        </form>
      </tidy-card>

      <ng-container *ngTemplateOutlet="noAddressPickedTemplate">
      </ng-container>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-button
        [text]="'Save Changes'"
        data-cy="btn-saveChanges"
        [action]="saveChanges.bind(this)"
        class="primary">
      </tidy-button>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
