import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

import { Dashboard } from '../../providers/dashboard/dashboard';
import { Me } from '../../shared/providers/me';

import { InAppBrowserUtils } from '../../shared/utils/in-app-browser-utils';

@Component({
  selector: 'page-feedback',
  templateUrl: './feedback.html'
})
export class FeedbackPage extends Timeout {
  metricsFeedback: any;
  streaksFeedback: any;
  highlights: any;
  isReliabilityScore: boolean;
  isQualityScore: boolean;
  isBookabilityScore: boolean;

  constructor(
    private me: Me,
    private iabUtils: InAppBrowserUtils,
    private dashboard: Dashboard,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
  }

  async ionViewDidEnter() {
    try {
      this.highlights = await this.dashboard.getHighlights();
      this.metricsFeedback = await this.me.getMetricsData();
      this.streaksFeedback = await this.me.getStreaksData();
      this.isReliabilityScore = (this.highlights.dashboard_highlight_score && this.highlights.dashboard_highlight_score.reliability_score);
      this.isQualityScore = (this.highlights.dashboard_highlight_score && this.highlights.dashboard_highlight_score.quality_score);
      this.isBookabilityScore = (this.highlights.bookability_scores && this.highlights.bookability_scores.bookability_score);
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  learnMoreQualityScore = () => {
    this.iabUtils.openUrl('https://help.tidy.com/pros/scores-and-reviews#quality-score');
  }

  learnMoreReliabilityScore = () => {
    this.iabUtils.openUrl('https://help.tidy.com/pros/scores-and-reviews#reliability-score');
  }

  learnMoreBookabilityScore = () => {
    this.iabUtils.openUrl('https://help.tidy.com/pros/scores-and-reviews#bookability-score');
  }

  learnMoreCleaningStreak = () => {
    this.iabUtils.openUrl('https://help.tidy.com/pros/scores-and-reviews#streaks');
  }

  learnMoreConstructiveFeedback(){
    this.iabUtils.openUrl('https://help.tidy.com/pros/scores-and-reviews#constructive-feedback');
  }

}
