import { Component } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { NotificationSettings, NotificationSettingsDayModel, NotificationSettingsDayTimesModel } from 'src/providers/notification-settings/notification-settings';

import { Loading } from 'src/shared/components/loading/loading';

@Component({
  selector: 'notification-settings-day',
  templateUrl: './notification-settings-day.html'
})

export class NotificationSettingsDayPage {

  day: NotificationSettingsDayModel;
  times: NotificationSettingsDayTimesModel[];
  channel: string;
  type: string;
  loaded = false;
  errorMessage: string;

  constructor(
    private customNavCtrl: CustomNavController,
    private notificationSettings: NotificationSettings,
  ) {}

  async ionViewDidEnter(): Promise<any> {
    this.day = this.customNavCtrl.getParam('day', true);
    this.channel = this.customNavCtrl.getParam('channel', true);
    this.type = (this.channel === 'app') ? 'in app' : 'sms';
    this.times = this.notificationSettings.buildNotificationDayTimes(this.day.times);
    this.loaded = true;
  }

  @Loading()
  async saveDay() {
    try {
      const times = this.buildTimes();
      const data = {
        weekday: this.day.value,
        times: times,
        channels: [this.channel]
      }
      await this.notificationSettings.changeNotificationSetting(data);
      this.customNavCtrl.navigateForward('notification-settings');
    } catch(error) {
      this.errorMessage = error.message;
    }
  }

  buildTimes() {
    const times = this.times.filter(item => item.checked);
    const timesValue = times.map( item => item.value );
    return timesValue;
  }
}
