<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Payouts'"
    [customBack]="backButtonRoute">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'It may take 24 hours for balances to update.'">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <tidy-row [alignLastItemRight]="true">
          <tidy-text
            [title]="'Current Balance'">
          </tidy-text>
          <tidy-text
            [title]="(paymentBalance?.total_amount | tcurrency)">
          </tidy-text>
        </tidy-row>
        <!--TODO remove inline style https://trello.com/c/XpKJZqE7/9010-f-ca-address-inline-styles-->
        <tidy-card-button (action)="goToTotalBalance()" style="padding-bottom: 0px" [hideArrow]="paymentBalance?.total_amount == 0">
          <tidy-row>
            <tidy-text
              [body]="'Next payout: '">
            </tidy-text>
            <tidy-text
              [body]="(estimatedNextPayment?.total_amount | tcurrency)">
            </tidy-text>
            <tidy-text
              *ngIf="estimatedNextPayment?.total_amount > 0"
              [helper]="' (est. arrival '">
            </tidy-text>
            <tidy-text
              *ngIf="estimatedNextPayment?.total_amount > 0"
              [helper]="(estimatedNextPayment?.estimated_arrival) + ')'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Payout after: '">
            </tidy-text>
            <tidy-text
              [body]="(paymentBalance?.total_amount - estimatedNextPayment?.total_amount | tcurrency)">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <tidy-text
            [title]="'Payout History'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngIf="paymentHistory?.length > 0">
          <tidy-card-button
            (action)="paymentDetails(payment?.id)"
            *ngFor="let payment of paymentHistory">
            <tidy-row>
              <tidy-text
                [body]="payment?.amount | tcurrency">
              </tidy-text>
              <tidy-text
                [helper]="' on '">
              </tidy-text>
              <tidy-text
                [helper]="(payment?.transfer_date | tdate:'M/D/YY')">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="payment?.status === 'in_transit'">
              <tidy-text
                [body]="'Status: In Transit'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </ng-container>
        <tidy-row *ngIf="paymentHistory?.length === 0" class="extra-top-padding">
          <tidy-text
            [body]="'No Past Payments'">
          </tidy-text>
        </tidy-row>
      </tidy-card>

    </ng-container>

    <ng-container *ngIf="!loaded">
      <tidy-row>
        <ion-skeleton-text animated style="width: 80%; height:13px; margin: auto"></ion-skeleton-text>
      </tidy-row>

      <tidy-card>
        <tidy-row>
        <tidy-grid>
          <ion-row>
            <ion-col size="6">
              <ion-skeleton-text animated style="width: 95%; height:20px"></ion-skeleton-text>
            </ion-col>
            <ion-col size="6">
              <ion-skeleton-text animated style="width: 30%; height:20px; float: right"></ion-skeleton-text>
            </ion-col>
          </ion-row>
        </tidy-grid>
        </tidy-row>
        <tidy-card-button>
          <tidy-row>
            <ion-skeleton-text animated style="width: 72%; height:13px"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 65%; height:13px"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 45%; height:20px"></ion-skeleton-text>
        </tidy-row>
        <tidy-card-button *ngFor="let item of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]">
          <tidy-row>
            <ion-skeleton-text animated style="width: 28%; height:13px"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
