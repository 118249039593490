import { Component, ViewChild } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PrivateJobFieldsService } from 'src/shared/providers/private-job/private-job-fields';
import { PrivateClient } from 'src/providers/private-client/private-client';
import { Team } from 'src/providers/team/team';

import { PrivateRescheduleFormComponent } from 'src/shared/components/private-job/private-reschedule-form/private-reschedule-form';
import { SelectModel } from '@tidyapp/tidy-ui-components';

import { Loading } from 'src/shared/components/loading/loading';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { Timeout } from 'src/shared/components/timeout/timeout';

@Component({
  selector: 'app-private-rechedule',
  templateUrl: './private-reschedule.html',
  styleUrls: ['./private-reschedule.scss']
})
export class PrivateReschedulePage extends Timeout {

  addressName: string;
  addressId: string;
  clientName: string;
  cancelledBy: string;
  currentFrequency: string;
  currentDate: string;
  cannotChangeFrequency = false;
  jobDateAndHour: string;
  jobId: string;
  submitted = false;
  teamServiceTypes: any;
  teamServiceItems: SelectModel[];
  planId: string;
  rescheduleType: string;
  withFee: boolean;
  formInitData = null;
  @ViewChild(PrivateRescheduleFormComponent, {static: false}) privateJobFormComponent: PrivateRescheduleFormComponent;

  constructor(
    private customNavCtrl: CustomNavController,
    private privateJobFieldsService: PrivateJobFieldsService,
    private privateClient: PrivateClient,
    private team: Team,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
    const params = this.customNavCtrl.getParams();
    this.addressId = params.addressId;
    this.addressName = params.addressName;
    this.cancelledBy = params.cancelledBy;
    this.clientName = params.clientName;
    this.currentFrequency = params.currentFrequency;
    this.currentDate = params.currentDate;
    this.jobDateAndHour = params.jobDateAndHour;
    this.jobId = params.jobId;
    this.planId = params.planId;
    this.rescheduleType = params.rescheduleType;
    this.withFee = params.withFee;
    this.formInitData = this.getFormInitData(params);
    this.cannotChangeFrequency = this.rescheduleType === 'job';
  }

  @Loading()
  async ionViewDidEnter() {
    this.loaded = false;
    try {
      this.teamServiceTypes = await this.team.getTeamServicesForAddress(this.addressId);
      this.teamServiceItems = this.privateJobFieldsService.mountTeamServiceItems(this.teamServiceTypes);
    } catch (err) {
      this.timeoutHandler(err);
    }
    this.loaded = true;
  }

  save() {
    const formValue = this.privateJobFormComponent.getValueFromFormGroup();

    this.submitted = true;

    if (this.privateJobFormComponent.isInValid()) {
      return;
    }

    const startTimeValue = formValue.startTime;
    const selectedStartTime = this.privateJobFieldsService.getSelectedStartTime(startTimeValue);

    const selectedService = this.teamServiceItems.find((service) => {
      if (service.value == formValue.service) {
        return service;
      }
    });

    const params = {
      addressName: this.addressName,
      clientName: this.clientName,
      cancelledBy: this.cancelledBy,
      currentFrequency: this.currentFrequency,
      job: {
        ...formValue,
        frequency: this.cannotChangeFrequency ? 'not-changed': formValue.frequency
      },
      jobDateAndHour: this.jobDateAndHour,
      jobId: this.jobId,
      service: formValue.service,
      serviceName: selectedService.viewValue,
      description: formValue.description,
      startTimeLabel: selectedStartTime,
      planId: this.planId,
      rescheduleType: this.rescheduleType,
      withFee: this.withFee
    };

    this.customNavCtrl.navigateForward('private-reschedule-confirm', params);
  }

  getFormInitData(params) {
    const price = (params?.amount / 100)?.toString();
    return {
      price,
      description: params.serviceTypeDetails.description,
      service: params.serviceTypeDetails.key
    }
  }
}
