import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'privateStatus'})
export class PrivateStatusPipe implements PipeTransform {

  transform(value: string): string {
    const statusText = {
      cancelled_by_client: 'Cancelled by Client (with fee)',
      cancelled_by_client_no_fee: 'Cancelled by Client (no fee)',
      cancelled_by_me: 'Cancelled by Me',
      scheduled: 'Scheduled',
      completed: 'Completed'
    };
    return statusText[value];
  }
}
