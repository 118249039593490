import { Component } from '@angular/core';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Component({
  selector: 'lost-item-success',
  templateUrl: './lost-item-success.html',
})
export class LostItemSuccessPage {

  select: string;
  form: any;

  constructor(
    private customNavCtrl: CustomNavController,
  ) {
  }

  goToMyJobs = () => {
    this.customNavCtrl.navigateRoot('jobs');
  }
}
