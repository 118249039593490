<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Ready to Work?'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

  <ng-container *ngIf="(isOnIdentificationCheck || isOnFaceUnlock) && loaded">
    <ion-row>
      <ng-container *ngFor="let item of progressBars">
        <ion-col class="identification-progress-bar">
          <mat-progress-bar
            [mode]="item.mode"
            [value]="item.value"
            >
          </mat-progress-bar>
        </ion-col>
      </ng-container>
    </ion-row>
    <tidy-row [align]="'center'" class="img-row">
      <div class="circle-div">
        <tidy-image [src]="currentIdentificationStep.icon" class="step-img"></tidy-image>
      </div>
    </tidy-row>
    <tidy-row [align]="'center'">
      <tidy-text
        class="step-title"
        [header]="currentIdentificationStep.title">
      </tidy-text>
    </tidy-row>
    <tidy-row [align]="'center'">
      <tidy-text
        [body]="currentIdentificationStep.subtitle">
      </tidy-text>
    </tidy-row>
    <ng-container *ngIf="currentIdentificationStep === identificationSteps[0] && !isOnFaceUnlock">
      <div style="padding-top: 1rem;">
        <tidy-alert style="margin-bottom: 1rem;">
          <tidy-row>
            <tidy-image
              [src]="'assets/svg/alert.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [header]="'For users in the US, a US identification document is required.'">
            </tidy-text>
          </tidy-row>
        </tidy-alert>
      </div>
    </ng-container>
    <tidy-row class="step-button-row" [align]="'center'" *ngIf="currentIdentificationStep.displayButton">
      <tidy-button
        [text]="currentIdentificationStep.buttonText"
        [action]="currentIdentificationStep.action.bind(this)"
        class="primary">
      </tidy-button>
    </tidy-row>

    <ng-container *ngIf="identificationError">
      <tidy-alert>
        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'<b>Error: </b>'">
          </tidy-text>
          <tidy-text
            [body]="identificationError?.reason">
          </tidy-text>
        </tidy-row>
          <tidy-row [align]="'center'">
            <tidy-text
              [body]="'Verify again.'"
              class="link"
              (action)="this.goToVerificationPage()">
            </tidy-text>
          </tidy-row>
      </tidy-alert>
    </ng-container>
    <ng-container *ngIf="isOnFaceUnlock && currentIdentificationStep?.title !== 'Verification Completed!'">
      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Photo issues? Skip & notify concierge'"
          class="link"
          (action)="this.reportFaceUnlockIssues()">
        </tidy-text>
      </tidy-row>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!isOnIdentificationCheck && !isOnFaceUnlock">
    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'Please confirm you can make your jobs:'">
      </tidy-text>
    </tidy-row>

    <ng-container *ngIf="loaded">
      <tidy-card *ngFor="let cleaning of todayCleanings">
        <tidy-row class="extra-bottom-padding">
          <tidy-grid>
            <ion-row>
              <ion-col size="4">
                <tidy-text
                  [body]="cleaning?.job?.start_time | ttime">
                </tidy-text><br>
                <tidy-text
                  [body]="cleaning?.job?.duration_in_minutes + cleaning?.job?.duration_in_minutes > 60 ? ' hours' : ' hour'">
                </tidy-text>
              </ion-col>
              <ion-col size="1">
                <tidy-image
                  *ngIf="cleaning?.homekeeper_job?.repeat_client"
                  [src]="'assets/svg/star-yellow.svg'"
                  class="body-size">
                </tidy-image>
              </ion-col>
              <ion-col size="7" >
                <tidy-text
                  [body]="cleaning?.customer?.first_name">
                </tidy-text><br>
                <tidy-text
                  *ngIf="cleaning?.job_delegation_request?.role !== 'delegatee'"
                  [body]="(cleaning?.service_type_details?.service_category?.name == 'Regular Cleaning' ? 'Cleaning' : cleaning?.service_type_details?.service_category?.name) + ': ' + (cleaning?.payment_price | tcurrency)">
                </tidy-text>
                <tidy-text
                  *ngIf="cleaning?.job_delegation_request?.role === 'delegatee'"
                  [body]="cleaning?.service_type_details?.service_category?.name == 'Regular Cleaning' ? 'Cleaning' : cleaning?.service_type_details?.service_category?.name">
                </tidy-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-divider></tidy-divider>
        </tidy-row>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/location-outline.svg'">
          </tidy-image>
          <tidy-text
            [body]="'Zip: '"
            class="body-size">
          </tidy-text>
          <tidy-text
            [body]="cleaning?.address?.zip"
            class="body-size">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-error-message
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-button item-right
        [text]="'I Will Go to All My Jobs Today'"
        [action]="confirm.bind(this)"
        class="primary">
      </tidy-button>

      <tidy-button item-right
        [text]="'Call Out'"
        [action]="callOut.bind(this)"
        class="secondary">
      </tidy-button>
    </ng-container>
  </ng-container>

    <ng-container *ngIf="!loaded">
      <tidy-card>
        <tidy-card-button [hideArrow]="true">
          <tidy-grid>
            <ion-row>
              <ion-col size="4">
                <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 52%"></ion-skeleton-text>
              </ion-col>
              <ion-col size="1">
              </ion-col>
              <ion-col size="7">
                <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 45%"></ion-skeleton-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-card-button>
        <tidy-row class="extra-bottom-padding">
          <tidy-divider></tidy-divider>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 32%"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>
      <tidy-card>
        <tidy-card-button [hideArrow]="true">
          <tidy-grid>
            <ion-row>
              <ion-col size="4">
                <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 52%"></ion-skeleton-text>
              </ion-col>
              <ion-col size="1">
              </ion-col>
              <ion-col size="7">
                <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 45%"></ion-skeleton-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-card-button>
        <tidy-row class="extra-bottom-padding">
          <tidy-divider></tidy-divider>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 32%"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>
      <tidy-row>
        <ion-skeleton-text animated style="height: 45px"></ion-skeleton-text>
      </tidy-row>
      <tidy-row>
        <ion-skeleton-text animated style="height: 45px"></ion-skeleton-text>
      </tidy-row>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
