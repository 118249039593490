import { Component, ViewEncapsulation, NgZone, ElementRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AddressForm } from 'src/providers/address-form/address-form';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Me } from 'src/shared/providers/me';
import { Cleanings } from 'src/providers/cleanings/cleanings';
import { ScheduleCardModel } from 'src/models/schedule-card.model';
import { HomekeeperAddressModel } from 'src/models/homekeeper-profile.model';
import { AutoCompleteAddressModel } from 'src/shared/models/autocomplete-address.model';
import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm';
import { SuccessPageParamsModel } from 'src/pages/success/success';

import { HomekeeperModel } from 'src/shared/models/homekeeper.model';

import { RadioButtonModel } from '@tidyapp/tidy-ui-components';
import { MultipleCountryService } from 'src/providers/addresses/multiple-country.service';
import { keepOnlyText } from 'src/shared/utils/text-utils';
import { AddressMetadata } from 'src/shared/components/autocomplete-address/autocomplete-address';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  selector: 'profile-address-page',
  templateUrl: 'profile-address.html',
  encapsulation: ViewEncapsulation.None
})
export class ProfileAddressPage extends Timeout {

  errorMessage: string;
  hkAddressForm: UntypedFormGroup;
  homekeeper: HomekeeperModel;
  autocompleteAddress: AutoCompleteAddressModel[];
  submitted: boolean;
  countryList: RadioButtonModel[];
  zipCodeMask = {
    mask: '00000'
  }
  noAddressPickedTemplate: ElementRef;
  invalidAddressError: string;

  constructor(
    private addressForm: AddressForm,
    private cleanings: Cleanings,
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private me: Me,
    public _zone: NgZone,
    private modalCtrl: ModalController,
    private multipleCountryService: MultipleCountryService,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
    this.hkAddressForm = this.fb.group({
      countryCode: ['US', Validators.required],
      address: ['', Validators.compose([Validators.maxLength(100), Validators.required])],
      address2: ['', Validators.compose([Validators.maxLength(100)])],
      latitude: '',
      longitude: '',
      zipcode: ['', this.multipleCountryService.getCountrySettings('US').validator],
      city: ['', Validators.required],
      state: ['', Validators.required]
    });

    this.countryList = this.multipleCountryService.countryRadioButtonList;
  }

  async ionViewDidEnter() {
    try {
      this.homekeeper = await this.me.load();
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  checkChange = async () => {
    const zipcode = keepOnlyText(this.hkAddressForm.value.zipcode);

    this.submitted = true;
    this.errorMessage = '';
    const newAddress: HomekeeperAddressModel = {
      ...this.hkAddressForm.value,
      country_code: this.hkAddressForm.value.countryCode,
      zipcode,
      add_state: this.hkAddressForm.value.state
    };
    let previewCancellationResponse;

    if (this.addressForm.addressError(this.hkAddressForm.value.address)) {
      this.hkAddressForm.controls.address.setErrors({'incorrect': true});
      return this.errorMessage = 'Unfortunately you must have a physical address in order to accurately determine your service area, and PO boxes cannot be used. Clients do not see your address. Please add a different address.';
    }

    if(this.invalidAddressError) {
      this.errorMessage = this.invalidAddressError;
      return;
    }

    if (!this.hkAddressForm.valid) {
      return;
    }

    try {
      previewCancellationResponse = await this.cleanings.previewCancellations(this.hkAddressForm.value.zipcode);
      if (previewCancellationResponse.length > 0) {
        this.goToConfirmCancelation(previewCancellationResponse, newAddress);
      } else {
        this.goToConfirmUpdate(newAddress);
      }
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async goToConfirmUpdate(newAddress: HomekeeperAddressModel) {
    const params: ConfirmPageParamsModel = {
      title: 'Change Address',
      body: `${new TranslationPipe().transform('Change address to')} ${newAddress.address}, ${newAddress.zipcode}?  ${new TranslationPipe().transform('Your work area will now change.')}`,
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: this.confirmUpdate.bind(this),
      confirmActionParams: newAddress
    };

    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });

    confirmationModal.present();
  }

  goToConfirmCancelation(cancellations: Array<ScheduleCardModel>, newAddress: HomekeeperAddressModel) {
    this.customNavCtrl.navigateForward('profile/address/confirm-address-schedule', {cancellations, newAddress});
  }

  choseItem(addressMetadata: AddressMetadata) {
    this.hkAddressForm.patchValue(addressMetadata);
    this.errorMessage = '';
  }

  async confirmUpdate(newAddress) {
    await this.me.updateAddress(newAddress);
    this.modalCtrl.dismiss();
    const params = this.mountSuccessPageParams();
    this.customNavCtrl.navigateForward('success', params);
  }

  mountSuccessPageParams(): SuccessPageParamsModel {
    return {
      header: `Address Updated`,
      body: `Your Address has been updated for all jobs`,
      buttonText: `Ok`,
      buttonRoute: `more`
    };
  }

  changeCountrySelected(selectedCountry: string) {
    const validatorObject = this.multipleCountryService.getCountrySettings(selectedCountry);
    this.hkAddressForm.get('zipcode').setValidators(validatorObject.validator);
    this.hkAddressForm.get('zipcode').updateValueAndValidity();
    this.zipCodeMask = validatorObject.mask;

    setTimeout(() => {
      this.hkAddressForm.patchValue({
        address: '',
        address2: '',
        zipcode: ''
      });
    });
  }
}
