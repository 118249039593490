<tidy-wrapper *ngIf="loaded" style="margin: -10px -18px 0px -18px;">

  <tidy-row *ngIf="rooms?.length">
    <tidy-text
      [title]="'To-Dos'">
    </tidy-text>
  </tidy-row>

  <ng-container *ngIf="rooms?.length && showBeforePhotosButton">
    <ng-container *ngIf="page == 'PrivateJobPage' && privateJob?.job?.private_status !== 'scheduled'">
      <tidy-before-after
        [baState]="'before'"
        [jobIsCompleted]="true"
        [showDeleteButton]="false"
        [beforeAfterPhotos]="privateJobBeforePhotos"
        [type]="baMediaType == 'photo' ? 'before_photos' : 'before_videos'"
        [uuid]="privateJob?.homekeeper_job?.id"
        [page]="page">
      </tidy-before-after>
    </ng-container>
  
    <ng-container *ngIf="sharedJobStatus !== 'completed' && (privateJob?.job?.private_status == 'scheduled' || page !== 'PrivateJobPage')">
      <tidy-before-after
        [jobIsCompleted]="false"
        [type]="baMediaType == 'photo' ? 'before_photos' : 'before_videos'"
        [uuid]="uuid"
        [page]="page">
      </tidy-before-after>
    </ng-container>
  </ng-container>

  <ng-container *ngFor="let room of rooms; let roomIndex = index">
    <ng-container *ngIf="!room?.is_do_not_clean_room">
      <tidy-row *ngIf="room?.tasks?.length > 0 || room?.notes?.length > 0" [alignLastItemRight]="true">
        <tidy-image
          *ngIf="room?.category"
          [src]="'assets/img/rooms/' + room.category + '.png'"
          class="title-size">
        </tidy-image>
        <tidy-text
          [title]="room?.name">
        </tidy-text>
        <tidy-text
          [body]="'~' + (room.estimated_time || getRoomEstimatedTime(room)) + (' minutes'.substring(0, 3) | translate)">
        </tidy-text>
      </tidy-row>

      <ng-container *ngFor="let doNot of room?.notes">
        <tidy-card *ngIf="!room?.complete" [@inOutAnimation]>
          <div class="js-todo-group">
            <tidy-text
              class="red"
              [body]="('DO NOT ' | translate) + doNot?.body">
            </tidy-text>
          </div>
        </tidy-card>
      </ng-container>

      <tidy-card *ngIf="room?.photo_notes?.length > 0">
        <tidy-carrousel>
          <ng-container *ngFor="let photoNote of room?.photo_notes">
            <tidy-photo-note *tidyCarrousel
              [caption]="photoNote?.photo_note_caption"
              [src]="photoNote?.photo_url">
            </tidy-photo-note>
          </ng-container>
        </tidy-carrousel>
      </tidy-card>

      <ng-container *ngFor="let todoItem of room?.tasks; let toDoIndex = index">
        <tidy-card *ngIf="!room.complete" [id]="todoItem.id" [@inOutAnimation]>
          <ng-container *ngIf="page !== 'PrivateJobPage' || privateJob?.job?.private_status == 'scheduled'">
            <ng-container *ngIf="todoItem?.customFieldForm">
              <tidy-row class="vertical-align-middle">
                <div [ngStyle]="todoItem?.address_task?.important ? {'background': '#FDF8BF', 'padding': '2px'} : {}">
                  <tidy-image
                    [src]="'assets/svg/star.svg'"
                    class="text-top-align">
                  </tidy-image>
                  <tidy-text
                    [body]="todoItem?.title">
                  </tidy-text>
                </div>
              </tidy-row>
              <tidy-text
                [helper]="todoItem?.note">
              </tidy-text>
              <tidy-row class="extra-top-padding">
                <form [formGroup]="rooms[roomIndex]?.tasks[toDoIndex].customFieldForm">
                  <ng-container *ngFor="let field of todoItem?.options; let last = last">
                    <ng-container *ngIf="field?.name?.includes('Take Photo')">
                      <tidy-row>
                        <tidy-text
                          [body]="field?.notes">
                        </tidy-text>
                      </tidy-row>
                      <tidy-button
                        *ngIf="deviceInfo.isNativeMobile()"
                        [text]="'Take Photo'"
                        [action]="takeCustomToDoPhoto.bind(this, field, toDoIndex, roomIndex, todoItem, room)"
                        [class]="rooms[roomIndex].tasks[toDoIndex].customFieldForm.controls[field.id].invalid && rooms[roomIndex].tasks[toDoIndex].customFieldForm.controls[field.id].touched ? 'secondary take-photo-button-animation' : 'secondary'">
                      </tidy-button>
                      <tidy-file-upload
                        [label]="deviceInfo.isNativeMobile() ? 'Upload Photo' : 'Add Photo'"
                        [images]="rooms[roomIndex].tasks[toDoIndex].customFieldForm.controls[field.id].value"
                        (addedImagesChange)="uploadCustomToDoPhoto($event, field, toDoIndex, roomIndex, todoItem, room)"
                        (removedImageChange)="removeCustomToDoPhoto($event, field, toDoIndex, roomIndex, todoItem, room)">
                      </tidy-file-upload>
                      <tidy-error-message
                        *ngIf="rooms[roomIndex].tasks[toDoIndex].customFieldForm.controls[field.id].invalid && rooms[roomIndex].tasks[toDoIndex].customFieldForm.controls[field.id].touched"
                        class="error-message-padding"
                        [text]="'Please take a photo'">
                      ></tidy-error-message>
                    </ng-container>
                    <tidy-row [ngClass]="!last ? 'extra-bottom-padding' : ''" *ngIf="field?.name == 'Text Area' || field?.name == 'Count'">
                      <tidy-textarea
                        [label]="field?.notes"
                        [formControlName]="field.id"
                        [form]="rooms[roomIndex].tasks[toDoIndex].customFieldForm.controls[field.id]"
                        (keyup)="storeToDosAndSyncJob(todoItem, room)"
                        [submitted]="submitted"
                        [errorMessage]="'Please enter an answer.'">
                      </tidy-textarea>
                    </tidy-row>
                    <tidy-row [ngClass]="!last ? 'extra-bottom-padding' : ''" *ngIf="field?.name == 'Select'">
                      <tidy-radio-button
                        [label]="field?.notes"
                        [form]="rooms[roomIndex].tasks[toDoIndex].customFieldForm.controls[field.id]"
                        [formControlName]="field.id"
                        [items]="field?.allowed_values"
                        (optionChange)="storeToDosAndSyncJob(todoItem, room)"
                        [submitted]="submitted"
                        [errorMessage]="'Please select an option.'">
                      </tidy-radio-button>
                    </tidy-row>
                    <tidy-row [ngClass]="!last ? 'extra-bottom-padding' : ''" *ngIf="field?.name == 'Take Inventory'">
                      <tidy-input
                        [formControlName]="field.id"
                        [form]="rooms[roomIndex].tasks[toDoIndex].customFieldForm.controls[field.id]"
                        [label]="field?.notes"
                        inputMode="numeric"
                        [mask]="'number'"
                        (keyup)="storeToDosAndSyncJob(todoItem, room)"
                        [submitted]="submitted"
                        [errorMessage]="'Please enter an amount.'">
                      </tidy-input>
                    </tidy-row>
                    <tidy-row [ngClass]="!last ? 'extra-bottom-padding' : ''" *ngIf="field?.name?.includes('Checkbox')">
                      <tidy-row>
                        <tidy-text
                          [body]="field?.notes">
                        </tidy-text>
                      </tidy-row>
                      <ng-container *ngFor="let checkbox of field?.allowed_values">
                        <tidy-checkbox
                          [form]="rooms[roomIndex].tasks[toDoIndex].customFieldForm.controls[field.id + '-' + checkbox.viewValue]"
                          [formControlName]="field.id + '-' + checkbox.viewValue"
                          [items]="[checkbox]"
                          (checkedChange)="storeToDosAndSyncJob(todoItem, room)">
                        </tidy-checkbox>
                      </ng-container>
                    </tidy-row>
                  </ng-container>
                </form>
              </tidy-row>
              <tidy-row class="extra-top-padding extra-bottom-padding">
                <tidy-divider></tidy-divider>
              </tidy-row>
            </ng-container>
            <tidy-row [ngClass]="todoItem?.customFieldForm ? 'js-todo-group' : 'js-todo-group'">
              <tidy-radio-button
                *ngIf="todoItem?.customFieldForm"
                [(ngModel)]="todoItem.performance"
                (optionChange)="storeToDosAndSyncJob(todoItem, room)"
                class="horizontal-align no-wrap"
                [items]="[{viewValue: 'Not Done', value: 'not_performed'}, {viewValue: 'Done', value: 'performed'}]">
              </tidy-radio-button>
              <tidy-radio-button
                *ngIf="!todoItem?.customFieldForm"
                [(ngModel)]="todoItem.performance"
                (optionChange)="storeToDosAndSyncJob(todoItem, room)"
                class="horizontal-align no-wrap"
                [label]="todoItem.title"
                [helper]="todoItem.note"
                [icon]="todoItem.important ? 'assets/svg/star.svg' : ''"
                [highlightLabel]="todoItem.important"
                [items]="[{viewValue: 'Not Done', value: 'not_performed'}, {viewValue: 'Done', value: 'performed'}]">
              </tidy-radio-button>
            </tidy-row>
          </ng-container>
          <ng-container *ngIf="page == 'PrivateJobPage' && privateJob?.job?.private_status !== 'scheduled'">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="(todoItem.assigned_job_task?.performance === 'performed' || todoItem.address_task?.performance === 'performed') ? 'assets/svg/checkmark-circle-outline.svg' : 'assets/svg/close-circle-outline.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="todoItem.assigned_job_task?.title || todoItem.address_task.title">
              </tidy-text>
              <div style="position: absolute; right: 10px;" *ngIf="todoItem?.assigned_job_task ? !todoItem.assigned_job_task?.performance : !todoItem.address_task?.performance">
                <tidy-image
                  [matTooltip]="'Not marked' | translate"
                  [src]="'assets/icon/question-mark.png'"
                  class="body-size">
                </tidy-image>
              </div>
            </tidy-row>
            <tidy-row *ngIf="todoItem.assigned_job_task?.note || todoItem.address_task?.note">
              <tidy-text
                [helper]="todoItem.assigned_job_task?.note || todoItem.address_task?.note">
              </tidy-text>
            </tidy-row>
            <ng-container *ngIf="todoItem.assigned_job_task">
              <ng-container *ngFor="let field of todoItem.assigned_job_task?.options">
                <tidy-row *ngIf="!field?.name?.includes('Take Photo')">
                  <tidy-text
                    [body]="field?.notes + ': ' + (field?.json_value || field?.value || field?.value?.url || 'No value inputted')"
                    [translate]="false">
                  </tidy-text>
                </tidy-row>
                <tidy-row *ngIf="field?.name?.includes('Take Photo')">
                  <tidy-text
                    [body]="field?.notes"
                    [translate]="false">
                  </tidy-text>
                  <tidy-photo-note
                    *ngIf="(field?.json_value || field?.value || field?.value?.url)?.includes('https://')"
                    class="full-width no-margin"
                    [src]="field?.json_value || field?.value || field?.value?.url">
                  </tidy-photo-note>
                  <ng-container *ngFor="let value of field?.json_value">
                    <tidy-photo-note
                      *ngIf="value || value?.url"
                      class="full-width no-margin"
                      [src]="value || value?.url">
                    </tidy-photo-note>
                  </ng-container>
                  <ng-container *ngIf="!field?.value && !field?.json_value">
                    <br>
                    <tidy-text
                      [body]="'No photo taken'">
                    </tidy-text>
                  </ng-container>
                </tidy-row>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!todoItem.assigned_job_task">
              <ng-container *ngFor="let field of todoItem.address_task?.options">
                <tidy-row *ngIf="!field?.name?.includes('Take Photo')">
                  <tidy-text
                    [body]="field?.notes + ': ' + (field?.json_value || field?.value || field?.value?.url || 'No value inputted')"
                    [translate]="false">
                  </tidy-text>
                </tidy-row>
                <tidy-row *ngIf="field?.name?.includes('Take Photo')">
                  <tidy-text
                    [body]="field?.notes"
                    [translate]="false">
                  </tidy-text>
                  <tidy-photo-note
                    *ngIf="field?.json_value || field?.value || field?.value?.url"
                    class="full-width no-margin"
                    [src]="field?.json_value || field?.value || field?.value?.url">
                  </tidy-photo-note>
                  <ng-container *ngFor="let value of field?.json_value">
                    <tidy-photo-note
                      *ngIf="value || value?.url"
                      class="full-width no-margin"
                      [src]="value || value?.url">
                    </tidy-photo-note>
                  </ng-container>
                  <ng-container *ngIf="!field?.value && !field?.json_value && !field?.value?.url">
                    <br>
                    <tidy-text
                      [body]="'No photo taken'">
                    </tidy-text>
                  </ng-container>
                </tidy-row>
              </ng-container>
            </ng-container>
          </ng-container>
          <tidy-carrousel *ngIf="todoItem?.photo_notes?.length > 0" class="photo-notes-carrousel">
            <ng-container *ngFor="let photoNote of todoItem?.photo_notes">
              <tidy-photo-note
                *tidyCarrousel
                [caption]="photoNote.photo_note_caption"
                [src]="photoNote?.photo_url">
              </tidy-photo-note>
            </ng-container>
          </tidy-carrousel>
        </tidy-card>
      </ng-container>

      <todo-collapse
        [room]="room"
        [doneTodos]="room?.doneTodos"
        [currentRoomId]="currentRoomId"
        [showCollapse]="showCollapse"
        (expandRoom)="expandRoom($event)">
      </todo-collapse>
    </ng-container>

    <ng-container *ngIf="room?.is_do_not_clean_room">
      <tidy-row class="vertical-align-center">
        <tidy-image
          [src]="'assets/img/rooms/' + room?.category + '.png'"
          class="title-size">
        </tidy-image>
        <tidy-text
          [title]="room?.name">
        </tidy-text>
      </tidy-row>
      <tidy-card>
        <tidy-row>
          <tidy-text
            [body]="'DO NOT clean this room'"
            class="red">
          </tidy-text>
        </tidy-row>
      </tidy-card>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="page == 'PrivateJobPage' && showAfterPhotosButton && privateJob?.job?.private_status !== 'scheduled'">
    <tidy-before-after
      [baState]="'after'"
      [jobIsCompleted]="true"
      [showDeleteButton]="false"
      [beforeAfterPhotos]="privateJobAfterPhotos"
      [type]="baMediaType == 'photo' ? 'after_photos' : 'after_videos'"
      [uuid]="privateJob?.homekeeper_job?.id"
      [page]="page">
    </tidy-before-after>
  </ng-container>

  <tidy-row>
    <tidy-text
      [title]="'Final Steps'">
    </tidy-text>
  </tidy-row>

  <ng-container *ngIf="showAfterPhotosButton && rooms?.length && sharedJobStatus !== 'completed' && (privateJob?.job?.private_status == 'scheduled' || page !== 'PrivateJobPage')">
    <tidy-before-after
      [jobIsCompleted]="false"
      [type]="baMediaType == 'photo' ? 'after_photos' : 'after_videos'"
      [uuid]="uuid"
      [page]="page">
    </tidy-before-after>
  </ng-container>

  <tidy-card>
    <tidy-row class="extra-bottom-padding" [alignLastItemRight]="true" *ngIf="storedIssues">
      <tidy-image
        [src]="'assets/img/alert.svg'"
        class="header-size">
      </tidy-image>
      <tidy-text
        [header]="'Log Issue'">
      </tidy-text>
      <tidy-text
        [body]="'Add Issue'"
        (action)="goToLogIssue()"
        class="link">
      </tidy-text>
    </tidy-row>
    <tidy-row class="extra-bottom-padding vertical-align-center" *ngIf="!storedIssues">
      <tidy-image
        [src]="'assets/img/alert.svg'"
        class="header-size">
      </tidy-image>
      <tidy-text
        [header]="'Log Issue'">
      </tidy-text>
    </tidy-row>
    <ng-container *ngFor="let issue of storedIssues; let last = last">
      <tidy-row class="vertical-align-center">
        <tidy-image
          [src]="'assets/img/' + issue.type + '.svg'"
          class="body-size">
        </tidy-image>
        <tidy-text
          [body]="issue.title | titlecase">
        </tidy-text>
      </tidy-row>
      <tidy-row [ngClass]="!last ? 'extra-bottom-padding' : ''">
        <tidy-text
          [body]="issue.description">
        </tidy-text>
      </tidy-row>
    </ng-container>
    <ng-container *ngIf="!storedIssues">
      <tidy-card-button (action)="goToLogIssue('damage')">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/damage.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Damage'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="goToLogIssue('utility_issue')">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/utility_issue.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Utility Issue'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="goToLogIssue()">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/other.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'More Options'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </ng-container>
  </tidy-card>

  <tidy-card *ngIf="page == 'SharedToDoListPage'">
    <tidy-row class="vertical-align-center">
      <tidy-image
        [src]="'assets/img/lock.svg'"
        class="header-size">
      </tidy-image>
      <tidy-text
        [header]="'As You Leave'">
      </tidy-text>
    </tidy-row>
    <tidy-row>
      <tidy-text
        [body]="closingNotes">
      </tidy-text>
    </tidy-row>
    <tidy-row *ngIf="!closingNotes">
      <tidy-text
        [body]="'<i>No Closing Notes</i>'">
      </tidy-text>
    </tidy-row>
    <tidy-carrousel *ngIf="closingPhotos?.length > 0" >
      <ng-container *ngFor="let photo of closingPhotos">
        <tidy-photo-note
          class="full-width there-is-carrousel no-margin"
          *tidyCarrousel
          [caption]="photo?.photo_note_caption"
          [src]="getClosingPhotoURL(photo)">
        </tidy-photo-note>
      </ng-container>
    </tidy-carrousel>
  </tidy-card>

  <ng-container *ngIf="page !== 'PrivateJobPage' || privateJob?.job?.private_status == 'scheduled'">

    <ng-container *ngIf="screenwidth < 1000 && sharedJobStatus !== 'completed'">
      <slider-trigger
        [label]="toDos.getSliderText(page, sharedJob || privateJob)"
        [onTriggeredAction]="completeList.bind(this)">
      </slider-trigger>
    </ng-container>

    <ng-container *ngIf="screenwidth >= 1000 && sharedJobStatus !== 'completed'">
      <tidy-button
        [text]="toDos.getSliderText(page, sharedJob || privateJob)"
        class="primary"
        [action]="completeList.bind(this)">
      ></tidy-button>
    </ng-container>

  </ng-container>

</tidy-wrapper>
