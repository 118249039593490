import { Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Me } from 'src/shared/providers/me';
import validationUtils from 'src/shared/utils/validation-utils';
import { SuccessPageParamsModel } from 'src/pages/success/success';

@Component({
  selector: 'profile-email',
  templateUrl: 'profile-email.html',
  encapsulation: ViewEncapsulation.None
})

export class ProfileEmailPage {

  errorMessage: string;
  emailForm: UntypedFormGroup;
  submitted: boolean;

  constructor(
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private me: Me

  ) {
    this.emailForm = this.fb.group({
      input: ['', [Validators.required, validationUtils.validateEmail]]
    });
  }

  submitEmail = async () => {
    this.errorMessage = '';
    this.submitted = true;
    if (!this.emailForm.valid) {
      return;
    }
    try {
      await this.me.updateEmail(this.emailForm.value.input);
      const params = this.mountSuccessPageParams();
      this.customNavCtrl.navigateForward('success', params);
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  mountSuccessPageParams(): SuccessPageParamsModel {
    return {
      header: 'Confirmation Email Sent',
      body: 'Check your email for a verification link',
      buttonText: 'Ok',
      buttonRoute: 'more'
    };
  }

  goToProfile = () => {
    this.customNavCtrl.navigateForward('profile/contact');
  }
}
