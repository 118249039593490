import { Injectable } from '@angular/core';
import { ActivationEnd, Router, UrlSegment } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { encodeUrlSegment } from 'src/shared/utils/encode-utils';
import { first } from 'rxjs/operators';
import { generateUuid } from './session-uuid';

@Injectable({
  providedIn: 'root'
})
export class TidySession {


  firstUrlViewed: {
    value: string;
    viewedAt: string;
    referrer?: string;
  };

  constructor(
    private cookieService: CookieService,
    private router: Router
  ) {}

  setup() {
    this.router.events.pipe(
      first(event => event instanceof ActivationEnd)
    ).subscribe((event: ActivationEnd) => {
      this.configureTidySessionCookie();
      this.setfirstUrlViewed(event?.snapshot?.url);
    });
  }

  configureTidySessionCookie() {
    let tidySessionCookie = this.cookieService.get('TidySession');
    if (!tidySessionCookie) {
      tidySessionCookie = this.generateTidySession();
    }
    return tidySessionCookie;
  }

  generateTidySession () {
    const cookieDate = new Date;
    cookieDate.setFullYear(cookieDate.getFullYear() + 10);
    const cookieValue = generateUuid();
    this.cookieService.set('TidySession', cookieValue, cookieDate);
    return cookieValue;
  }

  setfirstUrlViewed(value: Array<UrlSegment>) {
    this.firstUrlViewed = this.getFirstUrlViewedFromCookie();

    if (!this.firstUrlViewed) {
      const url = encodeUrlSegment(value);
      this.firstUrlViewed = {
        value: url,
        viewedAt: (new Date).toString(),
        referrer: document.referrer
      }
    }
  }

  getFirstUrlViewedFromCookie(): { value: string; viewedAt: string} {
    const firstUrlCookie = this.cookieService.get('TidySessionFirstUrl');
    if (firstUrlCookie) {
      return JSON.parse(firstUrlCookie);
    }
    return undefined;
  }

  getfirstUrlViewed(): { value: string; viewedAt: string; referrer?: string } {
    return this.firstUrlViewed;
  }
}
