import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Me } from 'src/shared/providers/me';

import { SuccessPageParamsModel } from 'src/pages/success/success';

@Component({template: ''})

export class EmailVerificationPage {

   constructor(
    private customNavCtrl: CustomNavController,
    private me: Me,
    private route: ActivatedRoute
  ) {}

  async ionViewDidEnter() {
    let validation;
    let params;
    const type = this.route.snapshot.paramMap.get('type');
    const token = this.route.snapshot.paramMap.get('token');
    if (type === 'update') {
      params = await this.validateEmailUpdate(token);
    } else {
      params = await this.validateEmail(token);
    }
    this.customNavCtrl.navigateForward('success', params);
  }

  async validateEmailUpdate(token) {
    try {
      await this.me.validateEmailUpdate(token);
      return this.mountValidPageParams();
    } catch (err) {
      return this.mountInvalidPageParams();
    }
  }

  async validateEmail(token) {
    try {
      const validation = await this.me.validateEmail(token);
      if (validation.status === 'valid') {
        return this.mountValidPageParams();
      } else {
        return this.mountInvalidPageParams();
      }
    } catch (err) {
      return this.mountErrorPageParams(err);
    }
  }

  mountValidPageParams(): SuccessPageParamsModel {
    return {
      header: 'Email Confirmed',
      body: 'Your email was confirmed. Please continue in the app.',
      buttonText: 'Ok',
      buttonRoute: 'introduction'
    };
  }

  mountInvalidPageParams(): SuccessPageParamsModel {
    return {
      header: 'Link Expired',
      body: 'This link isn\'t valid.  Please try again or reach out to us in the Concierge section for support.',
      buttonText: 'Ok',
      buttonRoute: 'introduction'
    };
  }

  mountErrorPageParams(error): SuccessPageParamsModel {
    if (error.message === 'The verification token is invalid!') {
      return this.mountInvalidPageParams();
    }
    return {
      header: 'Error',
      body: error.message,
      buttonText: 'Ok',
      buttonRoute: 'introduction'
    };
  }
}
