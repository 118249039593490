import { Injectable } from '@angular/core';
import { HttpClient } from 'src/shared/providers/http/http-client';

@Injectable()
export class Blacklist {

  constructor(
    private httpClient: HttpClient
  ) {}

  async blacklistClient(customerId): Promise<any> {
    const url = `api/v1/homekeeper/customers/${customerId}/add-to-blacklist`;
    return this.httpClient.post(url, {});
  }

}
