<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [title]="'Job'"
    [class]="extraClass"
    [canGoBack]="false"
    [noBow]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-alert *ngIf="!job">
        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'Job not found, please talk to the property manager.'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <ng-container *ngIf="job">
        <tidy-row [align]="'center'">
          <tidy-text
            [body]="job?.shared_link_data?.customer?.first_name +
            (' uses TIDY to manage their home service jobs. ' | translate)">
          </tidy-text>
          <tidy-text
            [actionBody]="'Learn More'"
            class="link"
            (action)="goToLearnMore()">
          </tidy-text>
        </tidy-row>

        <tidy-alert *ngIf="job?.shared_link_data?.job?.private_status == 'completed'">
          <tidy-row [align]="'center'">
            <tidy-text
              [body]="'Job marked as complete.'">
            </tidy-text>
          </tidy-row>
        </tidy-alert>

        <tidy-row [align]="'center'" *ngIf="getLastSharedJobMoment() == 'on_the_way'">
          <tidy-text
            [body]="'Client was told you are <b>on the way</b>.'">
          </tidy-text>
        </tidy-row>

        <tidy-row [align]="'center'" *ngIf="getLastSharedJobMoment() == 'arrived_at_home'">
          <tidy-text
            [body]="'Client was told you have <b>arrived</b>.'">
          </tidy-text>
        </tidy-row>

        <tidy-row [align]="'center'" *ngIf="getLastSharedJobMoment() == 'start'">
          <tidy-text
            [body]="'Client was told you have <b>started the job</b>.'">
          </tidy-text>
        </tidy-row>

        <ng-container *ngIf="job?.shared_link_data?.job?.private_status !== 'completed'">
          <ng-container *ngIf="screenwidth < 1000">
            <slider-trigger
              [label]="toDos.getSliderText('SharedJobPage', job)"
              [onTriggeredAction]="completeJob.bind(this)">
            </slider-trigger>
          </ng-container>

          <ng-container *ngIf="screenwidth >= 1000">
            <tidy-button
              [text]="toDos.getSliderText('SharedJobPage', job)"
              class="primary"
              [action]="completeJob.bind(this)">
            </tidy-button>
          </ng-container>
        </ng-container>

        <tidy-error-message
          *ngIf="errorMessage"
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-alert *ngIf="hasSameDayCheckIn">
          <tidy-row class="extra-bottom-padding">
            <tidy-image
              [src]="'assets/img/green-alert.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Time Sensitive'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="job?.shared_link_data?.customer?.first_name"
              [translate]="false">
            </tidy-text>
            <tidy-text
              [body]="' has a guest checking in '">
            </tidy-text>
            <tidy-text
              *ngIf="job?.shared_link_data?.guest_reservation_data?.next_guest_reservation?.check_in_time"
              [body]="('at ' | translate) + formatReservationTime(job?.shared_link_data?.guest_reservation_data?.next_guest_reservation?.check_in_time)">
            </tidy-text>
            <tidy-text
              [body]="' on the same day as the job.'">
            </tidy-text>
          </tidy-row>
        </tidy-alert>

        <tidy-card>
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="job?.shared_link_data?.service_type_details?.service_category?.icon_url"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="job?.shared_link_data?.service_type_details?.name | titlecase">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [body]="job?.shared_link_data?.service_type_details?.description">
            </tidy-text>
          </tidy-row>
          <tidy-row class="vertical-align-center">
            <tidy-image
               [src]="'assets/img/account.svg'"
               class="body-size">
             </tidy-image>
             <tidy-text
               [body]="('Booked with ' | translate) + (job?.shared_link_data?.customer?.first_name | titlecase)"
               style="margin-right:5px">
             </tidy-text>
             <tidy-text
               *ngIf="showContactButton"
               [actionBody]="'Contact'"
               (action)="goToContactPage()"
               class="link">
             </tidy-text>
          </tidy-row>
          <tidy-row class="vertical-align-center">
            <tidy-image
               [src]="'assets/img/credit-card.svg'"
               class="body-size">
             </tidy-image>
             <tidy-text
               *ngIf="job?.shared_link_data?.job?.billing_type !== 'price_later' && job?.shared_link_data?.job?.billing_type !== 'hourly'"
               [body]="(job?.shared_link_data?.job?.amount | tcurrency) + (' cost' | translate)">
             </tidy-text>
             <tidy-text
               *ngIf="job?.shared_link_data?.job?.billing_type == 'hourly'"
               [body]="(job?.shared_link_data?.job?.amount | tcurrency) + (' / hour' | translate)">
             </tidy-text>
             <tidy-text
               *ngIf="job?.shared_link_data?.job?.billing_type == 'price_later'"
               [body]="'Priced later'">
             </tidy-text>
          </tidy-row>
          <tidy-row class="vertical-align-center extra-bottom-padding">
            <tidy-image
               [src]="'assets/img/reservation.svg'"
               class="body-size">
             </tidy-image>
            <tidy-text
              [body]="(job?.shared_link_data?.job?.date | tdate:'dddd M/D/YY') + (' at ' | translate) + (job?.shared_link_data?.job?.start_time | ttime)">
            </tidy-text>
          </tidy-row>
          <ng-container *ngIf="job?.shared_link_data?.job?.private_status !== 'completed'">
            <tidy-card-button (action)="showLogInAlert()">
              <tidy-row class="vertical-align-center">
               <tidy-image
                  [src]="'assets/img/pros.svg'"
                  class="body-size">
                </tidy-image>
                <tidy-text
                  [body]="'Delegate Job'">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
            <ng-container *ngIf="job?.shared_link_data?.job?.is_private">
              <tidy-card-button (action)="showLogInAlert()">
                <tidy-row class="vertical-align-center">
                 <tidy-image
                    [src]="'assets/img/arrow-forward.svg'"
                    class="body-size">
                  </tidy-image>
                  <tidy-text
                    [body]="'Reschedule Job'">
                  </tidy-text>
                </tidy-row>
              </tidy-card-button>
              <tidy-card-button (action)="cancelJob()">
                <tidy-row class="vertical-align-center">
                 <tidy-image
                    [src]="'assets/img/close-circle.svg'"
                    class="body-size">
                  </tidy-image>
                  <tidy-text
                    [body]="'Cancel Job'">
                  </tidy-text>
                </tidy-row>
              </tidy-card-button>
            </ng-container>
          </ng-container>
        </tidy-card>

        <tidy-card *ngIf="job?.shared_link_data?.guest_reservation_data?.next_guest_reservation || job?.shared_link_data?.guest_reservation_data?.previous_guest_reservation">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/reservation.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Rental Information'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [body]="job?.shared_link_data?.customer?.first_name | titlecase"
              [translate]="false">
            </tidy-text>
            <tidy-text
              [body]="' rents out their property.'">
            </tidy-text>
          </tidy-row>
          <tidy-row [ngClass]="job?.shared_link_data?.guest_reservation_data?.next_guest_reservation ? 'extra-bottom-padding' : ''" *ngIf="job?.shared_link_data?.guest_reservation_data?.previous_guest_reservation">
            <tidy-text
              [body]="'<strong>Last Reservation:</strong>'">
            </tidy-text><br>
            <tidy-text
              [body]="formatReservationDate(job?.shared_link_data?.guest_reservation_data?.previous_guest_reservation?.check_in_date) + ' - ' + formatReservationDate(job?.shared_link_data?.guest_reservation_data?.previous_guest_reservation?.check_out_date) + getReservationLength(job?.shared_link_data?.guest_reservation_data?.previous_guest_reservation?.check_in_date, job?.shared_link_data?.guest_reservation_data?.previous_guest_reservation?.check_out_date)">
            </tidy-text>
            <ng-container *ngIf="previousGuestReservationDetails">
              <br>
              <tidy-text
                [body]="'Guests: '"
                *ngIf="previousGuestReservationDetails?.total_guests || previousGuestReservationDetails?.total_adults || previousGuestReservationDetails?.total_children || previousGuestReservationDetails?.total_pets">
              </tidy-text>
              <tidy-text
                *ngIf="previousGuestReservationDetails?.total_guests"
                [body]="previousGuestReservationDetails?.total_guests + (' guest(s)'| translate) + (previousGuestReservationDetails?.total_adults || previousGuestReservationDetails?.total_children || previousGuestReservationDetails?.total_pets ? ' | ' : '')">
              </tidy-text>
              <tidy-text
                *ngIf="previousGuestReservationDetails?.total_adults"
                [body]="previousGuestReservationDetails?.total_adults + (' adult(s)' | translate) + (previousGuestReservationDetails?.total_children || previousGuestReservationDetails?.total_pets ? ' | ' : '')">
              </tidy-text>
              <tidy-text
                *ngIf="previousGuestReservationDetails?.total_children"
                [body]="previousGuestReservationDetails?.total_children + (' children' | translate) + (previousGuestReservationDetails?.total_pets ? ' | ' : '')">
              </tidy-text>
              <tidy-text
                *ngIf="previousGuestReservationDetails?.total_pets"
                [body]="previousGuestReservationDetails?.total_pets + (' pet(s)' | translate)">
              </tidy-text>
            </ng-container>
            <ng-container *ngIf="job?.shared_link_data?.guest_reservation_data?.previous_guest_reservation?.check_out_time">
              <br>
              <tidy-text
                [body]="formatReservationTime(job?.shared_link_data?.guest_reservation_data?.previous_guest_reservation?.check_out_time) + (' check out' | translate)">
              </tidy-text>
            </ng-container>
            <ng-container *ngIf="previousGuestReservationDetails?.customFields">
              <ng-container *ngFor="let customField of previousGuestReservationDetails?.customFields">
                <tidy-row>
                  <tidy-text
                    [body]="customField">
                  </tidy-text>
                </tidy-row>
              </ng-container>
            </ng-container>
          </tidy-row>
          <tidy-row *ngIf="job?.shared_link_data?.guest_reservation_data?.next_guest_reservation">
            <tidy-text
              [body]="'<strong>Next Reservation:</strong>'">
            </tidy-text><br>
            <tidy-text
              [body]="formatReservationDate(job?.shared_link_data?.guest_reservation_data?.next_guest_reservation?.check_in_date) + ' - ' + formatReservationDate(job?.shared_link_data?.guest_reservation_data?.next_guest_reservation?.check_out_date) + getReservationLength(job?.shared_link_data?.guest_reservation_data?.next_guest_reservation?.check_in_date, job?.shared_link_data?.guest_reservation_data?.next_guest_reservation?.check_out_date)">
            </tidy-text>
            <ng-container *ngIf="nextGuestReservationDetails">
              <br>
              <tidy-text
                [body]="'Guests: '"
                *ngIf="nextGuestReservationDetails?.total_guests || nextGuestReservationDetails?.total_adults || nextGuestReservationDetails?.total_children || nextGuestReservationDetails?.total_pets">
              </tidy-text>
              <tidy-text
                *ngIf="nextGuestReservationDetails?.total_guests"
                [body]="nextGuestReservationDetails?.total_guests + (' guest(s)' | translate) + (nextGuestReservationDetails?.total_adults || nextGuestReservationDetails?.total_children || nextGuestReservationDetails?.total_pets ? ' | ' : '')">
              </tidy-text>
              <tidy-text
                *ngIf="nextGuestReservationDetails?.total_adults"
                [body]="nextGuestReservationDetails?.total_adults + (' adult(s)' | translate) + (nextGuestReservationDetails?.total_children || nextGuestReservationDetails?.total_pets ? ' | ' : '')">
              </tidy-text>
              <tidy-text
                *ngIf="nextGuestReservationDetails?.total_children"
                [body]="nextGuestReservationDetails?.total_children + (' children' | translate) + (nextGuestReservationDetails?.total_pets ? ' | ' : '')">
              </tidy-text>
              <tidy-text
                *ngIf="nextGuestReservationDetails?.total_pets"
                [body]="nextGuestReservationDetails?.total_pets + (' pet(s)' | translate)">
              </tidy-text>
            </ng-container>
            <ng-container *ngIf="job?.shared_link_data?.guest_reservation_data?.next_guest_reservation?.check_in_time">
              <br>
              <tidy-text
                [body]="formatReservationTime(job?.shared_link_data?.guest_reservation_data?.next_guest_reservation?.check_in_time) + (' check in' | translate)">
              </tidy-text>
            </ng-container>
            <ng-container *ngIf="nextGuestReservationDetails?.customFields">
              <ng-container *ngFor="let customField of nextGuestReservationDetails?.customFields">
                <tidy-row>
                  <tidy-text
                    [body]="customField">
                  </tidy-text>
                </tidy-row>
              </ng-container>
            </ng-container>
          </tidy-row>
        </tidy-card>

        <tidy-card>
          <tidy-row [alignLastItemRight]="true">
            <tidy-image
              [src]="'assets/img/home.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Address'">
            </tidy-text>
            <tidy-text
              [body]="'Get Directions'"
              (action)="getDirections()"
              class="link">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="job?.shared_link_data?.address?.address + (job?.shared_link_data?.address?.address_name ? (' - ' + job?.shared_link_data?.address?.address_name) : '')">
            </tidy-text>
            <ng-container *ngIf="job?.shared_link_data?.address?.unit !== ''">
              <br>
              <tidy-text
                [body]="('Unit ' | translate) + job?.shared_link_data?.address?.unit">
              </tidy-text>
            </ng-container>
          </tidy-row>
        </tidy-card>

        <tidy-card>
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/message.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Messages'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Please '">
            </tidy-text>
            <tidy-text
              [body]="job.shared_link_data.homekeepers[0].state == 'active' ? 'log in' : 'create an account'"
              class="link"
              (action)="goToIntroductionPage()">
            </tidy-text>
            <tidy-text
              [body]="' to view messages.'">
            </tidy-text>
          </tidy-row>
        </tidy-card>

        <tidy-card>
          <tidy-row class="vertical-align-center">
           <tidy-image
              [src]="'assets/img/car.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Parking'">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="!job.shared_link_data.job.is_private && !getLastSharedJobMoment()">
            <tidy-text
              [header]="'<i>See parking notes after notifying that you are on the way.</i>'">
            </tidy-text>
          </tidy-row>
          <ng-container *ngIf="job.shared_link_data.job.is_private || getLastSharedJobMoment()">
            <tidy-row *ngIf="job?.shared_link_data?.address?.parking?.paid_parking !== null || job?.shared_link_data?.address?.parking?.parking_notes">
              <tidy-text
                *ngIf="job?.shared_link_data?.address?.parking?.paid_parking !== null"
                [body]="job?.shared_link_data?.address?.parking?.paid_parking ? 'Paid parking ' : 'Free parking '">
              </tidy-text>
              <tidy-text
                *ngIf="job?.shared_link_data?.address?.parking?.parking_spot"
                [body]="parkingDescriptions[job?.shared_link_data?.address?.parking?.parking_spot]">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="job?.shared_link_data?.address?.parking?.max_parking_cost">
              <tidy-text
                [body]="('Client will cover up to ' | translate) + (job?.shared_link_data?.address?.parking?.max_parking_cost | tcurrency)">
              </tidy-text>
              <tidy-text
                *ngIf="job?.shared_link_data?.address?.parking?.parking_pay_with"
                [body]="(' and reimburse ' | translate) + (job?.shared_link_data?.address?.parking?.parking_pay_with == 'with cash' ? ('cash.' | translate) : ('through the TIDY app.' | translate))">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="job?.shared_link_data?.address?.parking?.parking_notes">
              <tidy-text
                [body]="('Client Notes: ' | translate) + job?.shared_link_data?.address?.parking?.parking_notes"
                [translate]="false">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="!job?.shared_link_data?.address?.parking?.parking_notes && job?.shared_link_data?.address?.parking?.paid_parking == null">
              <tidy-text
                [body]="'<i>No Parking Notes</i>'">
              </tidy-text>
            </tidy-row>
            <tidy-carrousel *ngIf="parkingPhotos?.length > 0" >
              <ng-container *ngFor="let photo of parkingPhotos">
                <tidy-photo-note
                  class="full-width there-is-carrousel no-margin"
                  *tidyCarrousel
                  [caption]="photo?.photo_note_caption"
                  [src]="photo?.photo_url">
                </tidy-photo-note>
              </ng-container>
            </tidy-carrousel>
          </ng-container>
        </tidy-card>

        <tidy-card>
          <tidy-row class="vertical-align-center">
           <tidy-image
              [src]="'assets/img/notes.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Access'">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="!job.shared_link_data.job.is_private && (!getLastSharedJobMoment() || getLastSharedJobMoment() == 'on_the_way')">
            <tidy-text
              [header]="'<i>See access notes after notifying that you arrived.</i>'">
            </tidy-text>
          </tidy-row>
          <ng-container *ngIf="job.shared_link_data.job.is_private || (!job.shared_link_data.job.is_private && getLastSharedJobMoment() && getLastSharedJobMoment() !== 'on_the_way')">
            <tidy-row>
              <tidy-text
                [body]="job?.shared_link_data?.address?.home_access">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="!job?.shared_link_data?.address?.home_access">
              <tidy-text
                [body]="'<i>No Access Notes</i>'">
              </tidy-text>
            </tidy-row>
            <tidy-carrousel *ngIf="accessPhotos?.length > 0" >
              <ng-container *ngFor="let photo of accessPhotos">
                <tidy-photo-note
                  class="full-width there-is-carrousel no-margin"
                  *tidyCarrousel
                  [caption]="photo?.photo_note_caption"
                  [src]="photo?.photo_url">
                </tidy-photo-note>
              </ng-container>
            </tidy-carrousel>
          </ng-container>
        </tidy-card>

        <ng-container *ngIf="job.shared_link_data?.booking_notes?.length || job.shared_link_data?.booking_form_answers?.length">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/information.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [title]="'Job Note'">
            </tidy-text>
          </tidy-row>

          <tidy-card>
            <ng-container *ngFor="let answer of job.shared_link_data?.booking_form_answers">
              <ng-container *ngIf="answer?.template_input?.type == 'text' || answer?.template_input?.type == 'text_area'">
                <tidy-row>
                  <tidy-text
                    [body]="answer?.template_input?.label + ': ' + answer?.data?.value">
                  </tidy-text>
                </tidy-row>
                <tidy-photo-note
                  *ngIf="answer?.template_input?.image_url"
                  [src]="answer?.template_input?.image_url">
                </tidy-photo-note>
              </ng-container>
              <ng-container *ngIf="answer?.template_input?.type == 'select'">
                <tidy-row>
                  <tidy-text
                    [body]="answer?.template_input?.label + ': ' + me.getBookingFormSelectValue(answer?.data?.value)">
                  </tidy-text>
                </tidy-row>
                <tidy-photo-note
                  *ngIf="answer?.template_input?.image_url"
                  [src]="answer?.template_input?.image_url">
                </tidy-photo-note>
              </ng-container>
              <ng-container *ngIf="answer?.template_input?.type == 'multiselect'">
                <tidy-row>
                  <tidy-text
                    [body]="answer?.template_input?.label + ': ' + me.getBookingFormMultiselectValue(answer?.data?.value)">
                  </tidy-text>
                </tidy-row>
                <tidy-photo-note
                  *ngIf="answer?.template_input?.image_url"
                  [src]="answer?.template_input?.image_url">
                </tidy-photo-note>
              </ng-container>
              <ng-container *ngIf="answer?.template_input?.type == 'file' && answer?.template_input?.data?.type == 'image'">
                <tidy-row>
                  <tidy-text
                    [body]="answer?.template_input?.label">
                  </tidy-text>
                </tidy-row>
                <tidy-carrousel
                  class="photo-notes-carrousel only-padding-bottom"
                  [scaleImages]="true">
                  <tidy-photo-note
                    *tidyCarrousel
                    [src]="answer?.data?.value">
                  </tidy-photo-note>
                </tidy-carrousel>
              </ng-container>
              <ng-container *ngIf="answer?.template_input?.type == 'files'  && answer?.template_input?.data?.type == 'images'">
                <tidy-row>
                  <tidy-text
                    [body]="answer?.template_input?.label">
                  </tidy-text>
                </tidy-row>
                <tidy-carrousel
                  class="photo-notes-carrousel only-padding-bottom"
                  [scaleImages]="true">
                  <ng-container *ngFor="let imageUrl of answer?.data?.value">
                    <tidy-photo-note
                      *tidyCarrousel
                      [src]="imageUrl">
                    </tidy-photo-note>
                  </ng-container>
                </tidy-carrousel>
              </ng-container>
            </ng-container>
            <tidy-row *ngIf="job.shared_link_data?.booking_notes?.length">
              <tidy-text
                [body]="job.shared_link_data?.booking_notes[0]?.text">
              </tidy-text>
            </tidy-row>
          </tidy-card>
        </ng-container>

        <tidy-to-dos
          [page]="'SharedJobPage'"
          [sharedJob]="job"
          [rooms]="job?.shared_link_data?.address_task_list?.address_rooms">
        </tidy-to-dos>

      </ng-container>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
