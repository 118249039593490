import { Injectable } from '@angular/core';
import { openDB, IDBPDatabase } from 'idb';

@Injectable({
  providedIn: 'root'
})
export class TidyStorage {
  private db: IDBPDatabase | null = null;

  constructor() {}

  async init() {
    this.db = await openDB('TidyStorageDB', 1, {
      upgrade(db) {
        db.createObjectStore('keyvaluepairs');
      },
    });
  }

  async save(key: string, value: any): Promise<void> {
    if (!this.db) throw new Error('Database not initialized');
    await this.db.put('keyvaluepairs', value, key);
  }

  async retrieve(key: string): Promise<any> {
    if (!this.db) throw new Error('Database not initialized');
    return await this.db.get('keyvaluepairs', key);
  }

  async delete(key: string): Promise<void> {
    if (!this.db) throw new Error('Database not initialized');
    await this.db.delete('keyvaluepairs', key);
  }

  async clear(): Promise<void> {
    if (!this.db) throw new Error('Database not initialized');
    await this.db.clear('keyvaluepairs');
    localStorage.clear();
  }
}
