import { Component } from '@angular/core';

import { MWStore } from "src/main-workflow/mw.store";
import { MWService } from "src/main-workflow/mw.service";
import { MWJobModel } from 'src/main-workflow/mw.models';
import { mwMoments } from 'src/main-workflow/mw.moments';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Component({
  templateUrl: 'mw.finish-far-from-client.page.html'
})

export class FinishFarFromClientPage {

  job: MWJobModel;
  loaded: boolean;
  timeoutTime: any;

  constructor(
    private mwService: MWService,
    private mwStore: MWStore,
    private navCtrl: CustomNavController
  ) {}

  async ionViewDidEnter() {
    this.loaded = false;
    this.job = await this.mwStore.getJob();
    const now = new Date();
    this.timeoutTime = await this.mwStore.getFarFromHomeTimer();
    this.loaded = true;
  }

  async tryAgain(): Promise<any> {
    if (await this.mwService.isFarFromHome()) {
      await this.mwService.addMomentToSyncData(mwMoments.retryGPSFarFromHome, 'finish-far-from-client', this.job.id);
      const params = {
        header: 'GPS Not Near Home',
        body: 'Please try again or confirm you are not at the home.',
        buttonText: 'Ok',
        buttonRoute: 'finish-far-from-client'
      };
      this.navCtrl.navigateForward('success', params);
    } else {
      await this.mwService.addMomentToSyncData(mwMoments.finishedToDos, 'finish-far-from-client', this.job.id);
      await this.mwStore.setRoute('finish-job');
      const params = {
        header: 'Location Found',
        body: 'We found your location near the Client\'s home!  This job has been updated to On Time.',
        buttonText: 'Ok',
        buttonRoute: 'finish-job'
      };
      this.navCtrl.navigateForward('success', params);
    }
  }

  takePhoto() {
    this.navCtrl.navigateForward('finish-far-from-client-photo');
  }

  async onTimeout() {
    await this.mwService.addMomentToSyncData(mwMoments.finishedFarFromClientTimeout, 'finish-far-from-client', this.job.id);
    this.showNotAtHomeAlert();
  }

  async confirmNotAtHome() {
    await this.mwService.addMomentToSyncData(mwMoments.confirmedFinishedFarFromHome, 'finish-far-from-client', this.job.id);
    this.showNotAtHomeAlert();
  }

  async showNotAtHomeAlert() {
    await this.mwStore.setRoute('finish-job');
    const params = {
      header: 'Not at the Home',
      body: 'You confirmed you are not at the Client\'s home.',
      buttonText: 'Ok',
      buttonRoute: 'finish-job'
    };
    this.navCtrl.navigateForward('success', params);
  }
}
