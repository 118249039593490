import { Component } from '@angular/core';

import { Certification } from 'src/providers/certification/certification';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { Loading } from 'src/shared/components/loading/loading';

import { SuccessPageParamsModel } from 'src/pages/success/success';

import { CertificationsModel, SectionCardModel, SectionStylesModel } from 'src/shared/models/certification.model';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

@Component({
  templateUrl: 'existing-clients.html',
})
export class OnboardingExistingClientsPage extends Timeout {

  cards: SectionCardModel[];
  completedCount: number;

  constructor(
    private certification: Certification,
    private customNavCtrl: CustomNavController,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
  }

  @Loading()
  async ionViewDidEnter() {
    try {
      this.loaded = false;
      const data: CertificationsModel = await this.certification.getCertificationsNoCache();
      this.countCompleted(data);
      if (this.completedCount === 4) {
        return this.showSuccesFinishedPage();
      }
      this.cards = this.buildCards(data);
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  countCompleted(data) {
    this.completedCount = 0;
    const steps = [
      'profile',
      'email_validation',
      'phone_verification',
      'max_drive_time'
    ];
    steps.forEach((step) => {
      if (data.steps[step] === 'complete') {
        this.completedCount += 1;
      };
    });
  }

  buildCards(data): SectionCardModel[] {
    return [
      {
        header: 'Add Account Info',
        body: 'So Clients know who you are.',
        linkPage: 'account-info',
        finished: this.getFinishedStyles(data.steps.profile === 'complete')
      },
      {
        header: 'Confirm Email',
        body: 'To ensure your email is correct.',
        linkPage: 'validate-email',
        finished: this.getFinishedStyles(data.steps.email_validation === 'complete')
      },
      {
        header: 'Confirm Phone',
        body: 'For Client communication and notifications.',
        linkPage: 'validate-phone',
        finished: this.getFinishedStyles(data.steps.phone_verification === 'complete')
      },
      {
        header: 'Set Max Drive Time',
        body: 'So Clients know what your work area is.',
        linkPage: 'profile/travel-time',
        finished: this.getFinishedStyles(data.steps.max_drive_time === 'complete')
      }
    ];
  }

  getFinishedStyles(finished): SectionStylesModel {
    if (finished) {
      return {
        icon: 'checkbox',
        iconClass: 'green',
        hideArrow: true,
      };
    } else {
      return {
        icon: 'square-outline',
        iconClass: '',
        hideArrow: false,
      };
    }
  }

  goToPage(page, alreadyFinishedStep) {
    if (!alreadyFinishedStep) {
      this.customNavCtrl.navigateForward(page, {finishPage: 'existing-clients'});
    }
  }

  showSuccesFinishedPage() {
    const params: SuccessPageParamsModel = {
      header: 'Ready to Add Existing Clients!',
      body: 'You can add existing Clients in the add page.',
      buttonText: 'Ok',
      buttonRoute: `dashboard`
    };
    this.customNavCtrl.navigateForward('success', params);
  }
}
