<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="type ? title : 'Log Issue'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="!type">
      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'What do you want to log?'">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <tidy-card-button (action)="selectIssue('damage')">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/damage.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="'Damage'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="selectIssue('low_inventory')">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/low_inventory.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="'Low Inventory'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="selectIssue('pest_report')">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/pest_report.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="'Pest Report'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="selectIssue('utility_issue')">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/utility_issue.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="'Utility Issue'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="selectIssue('other')">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/other.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="'Other'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>
    </ng-container>

    <ng-container *ngIf="type">
      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Send this information directly to the client:'">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <form [formGroup]="form">
          <tidy-row class="field-top-padding">
            <tidy-input
              [label]="'Description'"
              [icon]="'assets/svg/information-circle-outline.svg'"
              formControlName="description"
              [form]="form.controls.description"
              [submitted]="submitted"
              [errorMessage]="'Please enter a description'">
            </tidy-input>
          </tidy-row>
        </form>
      </tidy-card>

      <tidy-row>
        <tidy-text
          [actionBody]="'Add Photo'"
          (action)="addAttachment()"
          class="link">
        </tidy-text>
      </tidy-row>

      <tidy-row *ngIf="attachments?.length" class="field-top-padding">
        <tidy-row>
          <tidy-text
            [body]="'Photos:'">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngFor="let attachment of attachments; let i = index">
          <tidy-text
            [body]="('Photo ' | translate) + (i + 1)">
          </tidy-text>
          <tidy-text
            [actionBody]="'remove'"
            (action)="removeAttachment(i)"
            class="link red">
          </tidy-text>
        </tidy-row>
      </tidy-row>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-button
        [text]="'Send to Client'"
        [action]="send.bind(this)"
        class="primary">
      </tidy-button>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
