import { Component } from '@angular/core';
import * as moment from 'moment';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { FeeWaivers } from 'src/providers/fee-waivers/fee-waivers';

import { Loading } from 'src/shared/components/loading/loading';

import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { Timeout } from 'src/shared/components/timeout/timeout';

@Component({
  selector: 'waive-fee-page',
  templateUrl: './waive-fee.html',
})
export class WaiveFeePage extends Timeout {

  errorMessage: string;
  submitted = false;
  feesToWaive: any;
  hasFeeLastThirtyDays: boolean;

  constructor(
    private customNavCtrl: CustomNavController,
    private feeWaivers: FeeWaivers,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
  }

  @Loading()
  async ionViewDidEnter() {
    try {
      this.feesToWaive = await this.feeWaivers.fetch();
      this.hasFeeLastThirtyDays = this.someFeesLastThirtyDays(this.feesToWaive);
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  someFeesLastThirtyDays(allFees) {
    return allFees.fees.some(fee => {
      const feeMoment = moment(fee.date, 'YYYY-MM-DD');
      const diff = moment().diff(feeMoment, 'days');
      return (fee.total_fees < 0) && (diff < 30);
    });
  }

  waiveFee = (fee) => {
    this.customNavCtrl.navigateForward('confirm-fee-waive', {fee});
  }
}
