import { Injectable } from '@angular/core';
import { TidyStorage } from '../tidy-storage';
import * as moment from 'moment';

@Injectable()
export class RequestCache {

  constructor(
    private storage: TidyStorage
  ) {}

  async store(url: string, data: any): Promise<void> {
    const cach = await this.getCache();
    cach[url] = {data, lastUsed: new Date()};
    await this.setCache(cach);
  }

  async retrieve(url): Promise<any> {
    const cache = await this.getCache();
    return cache[url] ? cache[url].data : null;
  }

  async recycle(): Promise<any> {
    const cach = await this.getCache();

    const filtered = Object.keys(cach)
                      .filter( k => moment().add(-3, 'day').isBefore(cach[k].lastUsed))
                      .reduce( (acc, k) => {
                        acc[k] = cach[k];
                        return acc;
                      }, {});

    await this.setCache(filtered);
  }

  private async getCache(): Promise<any> {
    const strCache = await this.storage.retrieve('offline_cache');
    return strCache ? strCache : {};
  }

  private async setCache(cach: any) {
    await this.storage.save('offline_cache', cach);
  }

}
