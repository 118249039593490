import { AddressService } from '../providers/address';
import { AppConfig } from '../providers/config';
import { AppState } from '../providers/http/app-state';
import { Aws } from '../providers/aws';
import { Camera } from 'src/shared/providers/camera/camera';
import { CustomNavController } from '../providers/navigation/custom-nav-controller';
import { DateProvider } from '../providers/date';
import { DeviceInfoProvider } from '../providers/device-info';
import { GoogleGeocode } from '../providers/google-geocode';
import { HttpClient } from '../providers/http/http-client';
import { InAppBrowserUtils } from '../utils/in-app-browser-utils';
import { Logger } from '../providers/logger';
import { Me } from '../providers/me';
import { RequestCache } from '../providers/http/request-cache';
import { ServerData } from '../providers/server-data';
import { SocialShare } from 'src/shared/providers/share/share';
import { StaticData } from '../providers/static-data';
import { TidyStorage } from '../providers/tidy-storage';

import { TidyCurrencyPipe } from '../pipes/tidy-currency';
import { TidyDatePipe } from '../pipes/tidy-date';
import { TimePipe } from '../pipes/tidy-time';
import { PlanFrequencyPipe } from 'src/shared/pipes/tidy-plan-frequency';
import { TidyDurationPipe } from '../pipes/tidy-duration';

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { MediaCapture } from '@awesome-cordova-plugins/media-capture/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { CommonUtils } from '../utils/common-utils';
import { JobMediasProvider } from 'src/providers/job-medias/job-medias.provider';

export const providers = [
  AddressService,
  AppConfig,
  AppState,
  Aws,
  Camera,
  CustomNavController,
  DateProvider,
  DeviceInfoProvider,
  GoogleGeocode,
  HttpClient,
  InAppBrowser,
  InAppBrowserUtils,
  LaunchNavigator,
  File,
  Logger,
  Me,
  MediaCapture,
  RequestCache,
  ServerData,
  SocialShare,
  StaticData,
  TidyStorage,
  TidyCurrencyPipe,
  TidyDatePipe,
  TimePipe,
  PlanFrequencyPipe,
  TidyDurationPipe,
  CommonUtils,
  JobMediasProvider,
];
