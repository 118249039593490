import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { StaticData } from 'src/shared/providers/static-data';
import validationUtils from 'src/shared/utils/validation-utils';
import { HttpClient } from '../../shared/providers/http/http-client';
import { TidyStorage } from '../../shared/providers/tidy-storage';

export interface Schedule {
  start_date: string;
  schedule_type: string;
  times: Array<any>;
  cancellation_reason_id?: number;
}

@Injectable()
export class MySchedule {

  constructor(
    private httpClient: HttpClient,
    private storage: TidyStorage,
    private formBuilder: UntypedFormBuilder,
    private staticData: StaticData
  ) {
  }

  async fetchMonthAvailability(month: number, year: number, fetchMonth): Promise<any> {
    let response = await this.storage.retrieve('schedule-' + fetchMonth);
    if (response) {
      return response;
    }
    const url = `api/v2/homekeeper/schedule?month=${month}&year=${year}`;
    response = await this.httpClient.get(url);
    if (fetchMonth !== null) {
      await this.storage.save('schedule-' + fetchMonth, response);
      setTimeout(() => this.storage.delete('schedule-current-month'), 120000);
      setTimeout(() => this.storage.delete('schedule-next-month'), 120000);
    }
    return response;
  }

  async fetchScheduleChangeHistory(): Promise<any> {
    const url = `api/v1/homekeeper/account-change-history`;
    return await this.httpClient.get(url);
  }

  fetchDateAvailability(date: string): Promise<any> {
    const url = `api/v1/homekeeper/schedule/${date}?includes=availability`;
    return this.httpClient.get(url);
  }

  loadCancellations(data): Promise<{bonuses_to_cancel: [], cleanings_to_cancel: []}>{
    const url = `api/v2/homekeeper/cleanings/preview-cancellations/schedule-changes?${this.cancelUrlParams(data)}`;
    return this.httpClient.get(url);
  }

  saveSchedule(schedule: any): Promise<any> {
    this.storage.delete('schedule-current-month');
    this.storage.delete('schedule-next-month');
    const url = `api/v2/homekeeper/schedule`;
    return this.httpClient.post(url, schedule);
  }

  cancelUrlParams(data): string {
    let params = `start_date=${data.start_date}&schedule_type=${data.schedule_type}`;
    if (data.time_blocks.length) {
      params += data.time_blocks.map(timeBlock => {
        return `&time_blocks[][start_time]=${timeBlock.start_time}&&time_blocks[][end_time]=${timeBlock.end_time}`;
      });
    }

    return params;
  }

  async pauseSchedule(data): Promise<any> {
    this.storage.delete('schedule-current-month');
    this.storage.delete('schedule-next-month');
    const hkId = await  this.storage.retrieve('hk_id');
    const url = `api/v1/homekeeper/${hkId}/pause`;
    return this.httpClient.put(url, data);
  }

  addTimeRange(startTime: string = '', endTime: string = '', timeRangeForms) {
    const blocksFormGroup = this.formBuilder.group({
      start_time: [startTime, Validators.required],
      end_time: [endTime, Validators.required]
    }, {
      validators: validationUtils.timeRangesValidation(timeRangeForms)
    });
    timeRangeForms.push(blocksFormGroup);
  }

  addNewTimeRange(index: number = 0, timeRangeForms) {
    index = index ? index : timeRangeForms.length - 1;
    const timeRanges = this.staticData.timeRanges();

    if (timeRangeForms.length > 0) {
      const lastTimeRange: {start_time: string, end_time: string} = timeRangeForms[timeRangeForms.length - 1].value;

      const endTimeElem = timeRanges.find(elem => {
        return elem.value === lastTimeRange.end_time;
      });
      const indexCurrTime = timeRanges.indexOf(endTimeElem);

      const newStartTime = timeRanges[indexCurrTime + 1]?.value;
      const newEndTime = timeRanges[indexCurrTime + 13]?.value ?? '23:30';
      this.addTimeRange(newStartTime, newEndTime, timeRangeForms);
    } else {
      const defaultFirstStartTime = '08:00';
      const defaultFirstEndTime = '17:00';
      this.addTimeRange(defaultFirstStartTime, defaultFirstEndTime, timeRangeForms);
    }
  }

}
