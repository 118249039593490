<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Propose Times'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">
      <tidy-row [align]="'center'">
        <tidy-text
          *ngIf="request?.address?.customer?.is_private"
          [body]="'Your repeat Client ' +
          (request?.address?.customer?.account_name !== '' && request?.address?.customer?.account_name ? request?.address?.customer?.account_name : request?.address?.customer?.first_name) +
          ' at ' +
          (request?.address?.address1 + (request?.address?.address2 ? (' ' + request?.address?.address2) : '')) + (request?.address?.address_name ? (' - ' + request?.address?.address_name) : '') +
          ' requests you specifically for a ' +
          (request?.service_type_details?.customer_name || request?.service_type_details?.name | titlecase) +
          ' ' +
          (request?.frequency | planFrequency) +
          '!'">
        </tidy-text>
        <tidy-text
          *ngIf="!request?.address?.customer?.is_private"
          [body]="'Your repeat Client ' +
          request?.address?.customer?.first_name +
          ' in zipcode ' +
          request?.address?.zip +
          ' requests you specifically for a ' +
          (request?.service_type_details?.customer_name || request?.service_type_details?.name | titlecase) +
          ' ' +
          (request?.frequency | planFrequency) +
          '!'">
        </tidy-text>
      </tidy-row>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Tap additional time(s) you can become available:'">
        </tidy-text>
      </tidy-row>

      <tidy-error-message [text]="apiError || pageError">
      </tidy-error-message>

      <tidy-row>
        <tidy-button
          [text]="'Propose Alternate Times'"
          [action]="proposeAlternate.bind(this)"
          class="primary">
        </tidy-button>
      </tidy-row>

      <ng-container *ngFor="let day of times">
        <tidy-row>
          <tidy-text
            [header]="day?.date">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngFor="let time of day?.times; let even = even; let i = index">
          <tidy-two-column-row *ngIf="even">
            <tidy-checkbox-button
              [label]="time.time | ttime"
              [value]="time"
              [(checked)]="time.checked">
            </tidy-checkbox-button>
            <tidy-checkbox-button
              *ngIf="day.times[i+1]"
              [label]="day.times[i+1].time | ttime"
              [value]="day.times[i+1]"
              [(checked)]="day.times[i+1].checked"
              item-right>
            </tidy-checkbox-button>
          </tidy-two-column-row>
        </tidy-row>
      </ng-container>
      <tidy-error-message [text]="apiError || pageError">
      </tidy-error-message>

      <tidy-row class="extra-top-padding">
        <tidy-button
          [text]="'Propose Alternate Times'"
          [action]="proposeAlternate.bind(this)"
          class="primary">
        </tidy-button>
      </tidy-row>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
