import * as moment from 'moment';

/**
 * This method is to put hour in pattern [iso8601](https://en.wikipedia.org/wiki/ISO_8601)
 * input hour 5:00 output 05:00
 * ```ts
 * formatHourToIso8601("5:00")
 * ```
 */
export function formatHourToIso8601(hour: string) {
  return moment(hour, 'h:mm').format('HH:mm');
}

/**
 * This method is to remove hour and timezone from date
 * and to put date in pattern [iso8601](https://en.wikipedia.org/wiki/ISO_8601)
 * input hour 2020-04-03T03:00:00.000Z output 2020-04-03
 * ```ts
 * removeHourAndTimezoneFromDate("2020-04-03T03:00:00.000Z")
 * ```
 */
export function removeHourAndTimezoneFromDate(date: string) {
  return moment(date).format('YYYY-MM-DD');
}

/**
 * This method is to put the am pm on a hour without date
 * input hour 08:00 output 08:00am
 * ```ts
 * formatHourToShowAmPm("08:00")
 * ```
 */
export function formatHourToShowAmPm(hour: string) {
  return moment(`2000-01-01 ${hour}`).format('h:mma');
}
