<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="title">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #templateContent>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row *ngFor="let item of content">
      <tidy-text
        [body]="item">
      </tidy-text>
    </tidy-row>

    <tidy-button
      *ngIf="!certified"
      [text]="'Get New Clients from TIDY'"
      [action]="goToNewClients.bind(this)"
      class="primary">
    </tidy-button>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="templateContent"></app-scroll-wrapper>
