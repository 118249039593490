import { Component, ViewEncapsulation } from '@angular/core';

import { BankInfo } from 'src/providers/bank-info/bank-info';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Events } from 'src/providers/events/events';
import { MWStore } from 'src/main-workflow/mw.store';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { CommonUtils } from 'src/shared/utils/common-utils';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

@Component({
  selector: 'more',
  templateUrl: './more.html',
  encapsulation: ViewEncapsulation.None
})
export class MorePage {

  isLimitedAccount: boolean;

  constructor(
    private bankInfo: BankInfo,
    private navCtrl: CustomNavController,
    private events: Events,
    private iabUtils: InAppBrowserUtils,
    private mwStore: MWStore,
    private storage: TidyStorage,
    private util: CommonUtils
  ) { }

  async ionViewDidEnter() {
    this.isLimitedAccount = await this.storage.retrieve('isLimitedAccount');
    this.refreshAfterLanguageUpdate();
  }

  refreshAfterLanguageUpdate() {
    const didRefreshAfterLanguageUpdate = localStorage.getItem('didRefreshAfterLanguageUpdate') == 'true';
    if (didRefreshAfterLanguageUpdate) {
      this.util.showSuccess('Language updated!');
      localStorage.removeItem('didUpdateLanguage');
      localStorage.removeItem('didRefreshAfterLanguageUpdate');
      return;
    }
    const didUpdateLanguage = localStorage.getItem('didUpdateLanguage') == 'true';
    if (didUpdateLanguage) {
      localStorage.setItem('didRefreshAfterLanguageUpdate', 'true');
      window.location.reload();
    }
  }

  goTo = (page) => {
    this.navCtrl.navigateForward(page);
  }

  goToUpcomingFeaturePage(feature) {
    this.navCtrl.navigateForward('upcoming-feature', {feature: feature});
  }

  async goToPayouts() {
    await this.storage.save('payoutsBackRoute', 'more');
    this.navCtrl.navigateForward('payouts');
  }

  async goToMileageTracking() {
    await this.storage.save('mileageTrackingBackRoute', 'more');
    this.navCtrl.navigateForward('mileage-tracking');
  }

  async goToQuests() {
    await this.storage.save('questsBackRoute', 'more');
    this.navCtrl.navigateForward('quests');
  }

  goToMyAccount() {
    this.navCtrl.navigateForward('my-account');
  }

  goToFeedback = () => {
    this.navCtrl.navigateForward('feedback');
  }

  goToActiveInCommunity = () => {
    this.navCtrl.navigateForward('active-in-community');
  }

  goToClients = () => {
    this.navCtrl.navigateForward('clients');
  }

  signOut() {
    this.events.publish('auth:logout');
  }

  goToSchedule = () => {
    this.navCtrl.navigateForward('bookable-hours');
  }

  goToPayments = () => {
    this.navCtrl.navigateForward('payments');
  }

  goToDocumentation() {
    this.iabUtils.openUrl('https://help.tidy.com/pros');
  }

  async goToPayoutSettingsPage() {
    let settings = localStorage.getItem('payoutSettings');
    if (!settings) {
      const response = await this.bankInfo.getPayoutSettings();
      settings = response[0]?.value;
    }
    const url = settings ? 'payout-settings' : 'edit-payout-settings';
    this.navCtrl.navigateForward(url);
  }

}
