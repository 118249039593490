<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="('Current Balance: ' | translate) + (paymentBalance?.total_amount | tcurrency)">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="paymentBalance?.payment_items?.length > 0 && loaded">

      <tidy-row>
        <tidy-text
          [body]="'Current Balance Breakdown:'">
        </tidy-text>
      </tidy-row>

      <tidy-card *ngFor="let payment of paymentBalance?.payment_items">
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            [src]="payment?.job_data?.total_amount > 0 ? 'assets/img/add-circle-green.svg' :  'assets/img/remove-circle-red.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="(payment?.job_data?.total_amount | tcurrency)">
          </tidy-text>
          <tidy-text
            [header]="(payment?.job_data?.total_amount > 0 ? ' Payment' : ' Client Fee')">
          </tidy-text>
        </tidy-row>
        <tidy-card-button (action)="goToPastJobPage(payment?.job_data?.id, payment?.job_data?.is_private)">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/information-circle-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="(payment?.job_data?.service_type_details?.customer_name || payment?.job_data?.service_type_details?.name | titlecase) + ' - ' + payment?.job_data?.date">
            </tidy-text>
            <tidy-text
              [body]="' at '">
            </tidy-text>
            <tidy-text
              [body]="payment?.job_data?.time">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToBalanceDetail(payment)" *ngIf="!payment?.job_data?.is_private">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/list-circle-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="'View ' + (payment?.job_data?.total_amount > 0 ? ' Payment' : ' Client Fee') + ' Detail'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToPrivateClientPage(payment?.job_data?.customer?.id)" *ngIf="payment?.job_data?.is_private">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/person-circle-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="'Private Client '">
            </tidy-text>
            <tidy-text
              [body]="payment?.job_data?.customer?.account_name && payment?.job_data?.customer?.account_name !== '' ? payment?.job_data?.customer?.account_name : payment?.job_data?.customer?.name"
              [translate]="false">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToContactConcierge(payment?.title)">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/help-circle-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="'Log Issue'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToWaiveFees()" *ngIf="payment?.able_to_waive_fee">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/alert-circle-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="'Waive Client Fee'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
