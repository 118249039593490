import { Component, ViewChild, OnInit } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PrivateJobFieldsService } from 'src/shared/providers/private-job/private-job-fields';
import { PrivateClient } from 'src/providers/private-client/private-client';
import { Proposals } from 'src/providers/proposals/proposals';
import { Team } from 'src/providers/team/team';

import { PrivateRescheduleFormComponent } from 'src/shared/components/private-job/private-reschedule-form/private-reschedule-form';
import { Loading } from 'src/shared/components/loading/loading';

import { SelectModel } from '@tidyapp/tidy-ui-components';
import { ProposalModel } from 'src/shared/models/proposals.model';

@Component({
  selector: 'add-private-job-details',
  templateUrl: './add-private-job-details.html',
})
export class AddPrivateJobDetailsPage implements OnInit{

  acceptedProposals: ProposalModel[];
  addressName: string;
  addressId: string;
  cleaningLength: SelectModel[];
  clientName: string;
  clientId: string;
  clientHasPlan = false;
  currentFrequency: string;
  frequencies: SelectModel[];
  finishCreationJobRoute: string;
  teamServiceTypes: any;
  teamServiceTypesResponse: any;
  teamServiceItems: SelectModel[];
  times: SelectModel[];
  tomorrow: Date;
  submitted = false;
  errorMessage: string;
  loaded = false;

  @ViewChild(PrivateRescheduleFormComponent, {static: false}) privateJobFormComponent: PrivateRescheduleFormComponent;

  constructor(
    private customNavCtrl: CustomNavController,
    private privateJobFieldsService: PrivateJobFieldsService,
    private privateClient: PrivateClient,
    private proposals: Proposals,
    private team: Team
  ) {
    const params = this.customNavCtrl.getParams();
    this.addressId = params.addressId;
    this.addressName = params.addressName;
    this.cleaningLength = this.privateJobFieldsService.mountCleaningsLength();
    this.clientId = params.clientId;
    this.clientName = params.clientName;
    this.frequencies = this.privateJobFieldsService.mountFrequencies();
    this.finishCreationJobRoute = params.finishCreationJobRoute;
    this.times = this.privateJobFieldsService.mountTimes();
    this.tomorrow = this.privateJobFieldsService.setTomorrowDate();
  }

  async ngOnInit() {
    this.clientHasPlan = true;

    try {
      const proposals = await this.proposals.getProposalsByClient(this.clientId);
      const acceptedProposals = this.filterAcceptedProposals(proposals);
      this.teamServiceTypesResponse = await this.team.getTeamServicesForAddress(this.addressId);
      this.teamServiceTypes = this.buildTeamServiceTypes(acceptedProposals, this.teamServiceTypesResponse);
      this.teamServiceItems = this.privateJobFieldsService.mountTeamServiceItems(this.teamServiceTypes);
      const address = await this.privateClient.getPrivateAddressInfo(this.addressId);
      this.clientHasPlan = !!address.active_plans.length;
      this.currentFrequency = this.clientHasPlan ? address.active_plans[0].frequency : '';
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }

    this.loaded = true;
  }

  filterAcceptedProposals(proposals) {
    const array = [];
    proposals.map((proposal) => {
      if (proposal.status == 'accepted') {
        array.push(proposal);
      }
    });
    return array;
  }

  buildTeamServiceTypes(acceptedProposals, teamServiceTypes) {
    const array = [];
    acceptedProposals.map((proposal) => {
      teamServiceTypes.map((service) => {
        if (service.key == proposal.proposable.team_service.key) {
          const item = this.buildServiceItem(proposal.proposable.team_service.name, proposal.proposable.team_service.key, proposal.description, proposal.proposable.user_new_rate,  proposal.proposable.billing_type);
          array.push(item);
        }
      });
    });
    teamServiceTypes.map((service) => {
      let isProposalForService = false;
      array.map((proposalService) => {
        if (service.key == proposalService.key) {
          isProposalForService = true;
        }
      });
      if (!isProposalForService) {
        const item = this.buildServiceItem(service.name, service.key, service.description, service.user_new_rate, service.billing_type);
        array.push(item);
      }
    });
    return array;
  }

  buildServiceItem(name, key, description, price, billingType) {
    return {name, key, description, price, billingType};
  }

  save() {
    this.submitted = true;
    const formValue = this.privateJobFormComponent.getValueFromFormGroup();
    const isPastDate = formValue.date < new Date().toJSON().slice(0, 10);
    if (isPastDate) {
      return this.customNavCtrl.navigateForward('upcoming-feature', {feature: 'add_past_jobs'});
    }
    if (this.privateJobFormComponent.isInValid()) {
      return;
    }
    const startTimeValue = formValue.startTime;
    const selectedStartTime = this.privateJobFieldsService.getSelectedStartTime(startTimeValue);
    const selectedService = this.teamServiceTypesResponse.find((service) => {
      if (service.key == formValue.service) {
        return service;
      }
    });
    const params = {
      addressId: this.addressId,
      addressName: this.addressName,
      clientName: this.clientName,
      clientId: this.clientId,
      finishCreationJobRoute: this.finishCreationJobRoute,
      startTimeLabel: selectedStartTime,
      service: formValue.service,
      serviceName: selectedService.name,
      billingType: selectedService.billing_type,
      description: formValue.description,
      price: formValue.price,
      ...formValue,
      frequency: this.clientHasPlan ? this.currentFrequency : formValue.frequency
    };
    this.customNavCtrl.navigateForward('add-private-job-confirmation', params)
  }
}
