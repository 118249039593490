import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Communication } from 'src/providers/communication/communication';
import { Me } from 'src/shared/providers/me';
import { PrivateClient } from 'src/providers/private-client/private-client';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

import { Loading } from 'src/shared/components/loading/loading';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Component({
  templateUrl: 'private-job-messages.html'
})
export class PrivateJobMessagesPage extends Timeout {

  card: any;
  clientName: string;
  errorMessage: string;
  form: UntypedFormGroup;
  job: any;
  messages: any;
  submitted: boolean;
  timezone: any;

  constructor(
    public communication: Communication,
    private fb: UntypedFormBuilder,
    private iabUtils: InAppBrowserUtils,
    private me: Me,
    private navCtrl: CustomNavController,
    private privateClient: PrivateClient,
    public route: ActivatedRoute,
    router: Router,
    timeoutErrorHandler: TimeoutErrorHandler,
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.fb.group({
      message: ['', Validators.required],
    });
  }

  @Loading()
  async ionViewDidEnter() {
    try {
      this.loaded = false;
      const jobId = this.route.snapshot.paramMap.get('jobId');
      this.job = this.navCtrl.getParam('job') || await this.privateClient.getJobDetailsForAddress(jobId, true);
      this.messages = await this.communication.getSharedInboxForHomekeeperJob(this.job.homekeeper_job.id);
      await this.markMessagesAsRead();
      this.clientName = this.job.customer.first_name + ' ' + this.job.customer.last_name;
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async markMessagesAsRead() {
    let array: any = [];
    this.messages.map((message) => {
      if (!message.is_read && message.from_member.type == 'CustomerMember' && message?.chat_room_key) {
        array.push((message?.chat_room_key));
      }
    });
    if (array.length > 0) {
      await this.communication.markMessagesAsRead(array);
    }
  }

  @Loading()
  async sendMessage(attachment = []) {
    this.errorMessage = '';
    this.submitted = true;
    if (attachment.length == 0 && !this.form.get('message').valid) {
      return;
    }
    const payload = {
      chat: {
        type: 'homekeeper_job',
        data: {
          homekeeper_job_id: this.job.homekeeper_job.id
        }
      },
      message: {
        text: this.form.value.message,
        files: attachment
      }
    };
    try {
      const response = await this.communication.sendSharedInboxMessage(payload);
      if (response?.data?.message == 'We couldn\'t send your message, please try again later!') {
        return this.errorMessage = response.data.message;
      }
      this.messages = await this.communication.getSharedInboxForHomekeeperJob(this.job.homekeeper_job.id);
      this.form.patchValue({
        message: ''
      });
      this.submitted = false;
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async sendAttachment() {
    this.errorMessage = '';
    try {
      const attachment = await this.communication.addAttachment();
      if (attachment == '') {
        return this.errorMessage = 'Unable to attach photo. Please upload a PNG or JPEG file.';
      }
      await this.sendMessage([attachment]);
    } catch (err) {
      this.errorMessage =  err.error ? err.error.message : err.message;
    }
  }

  callAction() {
    this.iabUtils.openUrl(`tel://${this.job.customer.phone_number}`);
  }

}
