<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [customBack]="'/client-billing-options/' + clientId"
    [title]="'Invoice History'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Viewing invoice history for '">
        </tidy-text>
        <tidy-text
          [body]="(clientName | titlecase) + '.'"
          [translate]="false">
        </tidy-text>
      </tidy-row>

      <tidy-row>
        <tidy-text
          [header]="'Pending Invoices'">
        </tidy-text>
      </tidy-row>

      <tidy-alert *ngIf="pendingInvoices?.length === 0">
        <tidy-text
          [body]="'No pending invoices'">
        </tidy-text>
      </tidy-alert>

      <ng-container *ngFor="let invoice of pendingInvoices; let i = index">
        <tidy-card style="padding: 15px 15px 15px 10px" *ngIf="i < showPendingInvoiceLimit">
          <tidy-card-button (action)="goToSentInvoicePage(invoice)">
            <tidy-grid>
              <tidy-row>
                <ion-row>
                  <ion-col>
                    <tidy-row>
                      <ng-container *ngFor="let job of invoice?.items; let last = last">
                        <tidy-text
                          [header]="(job?.service_details?.name | titlecase) +
                          (' on ' | translate) +
                          (job?.service_details?.date | tdate:'M/D/YY')">
                        </tidy-text>
                        <tidy-text
                          *ngIf ="!last"
                          [header]="', '">
                        </tidy-text>
                      </ng-container>
                    </tidy-row>
                    <tidy-row class="vertical-align-center">
                      <tidy-text
                        [body]="('Sent: ' | translate) +
                        (invoice?.created_at | tdate:'M/D/YY h:mma')">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row>
                      <tidy-text
                        [body]="'To: '">
                      </tidy-text>
                      <tidy-text
                        [body]="(invoice?.customer?.account_name !== '' && invoice?.customer?.account_name) ?
                        invoice?.customer?.account_name :
                        (invoice?.customer?.first_name + ' ' + (invoice?.customer?.last_name | abbrLastName: true))"
                        [translate]="false">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row *ngIf="invoice?.customer?.email">
                      <tidy-text
                        [body]="'Email: '">
                      </tidy-text>
                      <tidy-text
                        [body]="invoice?.customer?.email"
                        [translate]="false">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row>
                      <tidy-text
                        [body]="('Price: ' | translate) + (invoice?.amount | tcurrency)">
                      </tidy-text>
                    </tidy-row>
                  </ion-col>
                </ion-row>
              </tidy-row>
            </tidy-grid>
          </tidy-card-button>
        </tidy-card>
      </ng-container>

      <tidy-row *ngIf="pendingInvoices?.length > showPendingInvoiceLimit">
        <tidy-text
          [body]="'Show More'"
          class="link"
          (action)="increasePendingInvoiceLimit()">
        </tidy-text>
      </tidy-row>

      <tidy-row>
        <tidy-text
          [header]="'Paid Invoices'">
        </tidy-text>
      </tidy-row>

      <tidy-alert *ngIf="paidInvoices?.length === 0">
        <tidy-text
          [body]="'No paid invoices'">
        </tidy-text>
      </tidy-alert>

      <ng-container *ngFor="let invoice of paidInvoices; let i = index">
        <tidy-card style="padding: 15px 15px 15px 10px" *ngIf="i < showPaidInvoiceLimit">
          <tidy-card-button (action)="goToSentInvoicePage(invoice)">
            <tidy-grid>
              <tidy-row>
                <ion-row>
                  <ion-col>
                    <tidy-row>
                      <ng-container *ngFor="let job of invoice?.items; let last = last">
                        <tidy-text
                          [header]="(job?.service_details?.name | titlecase) +
                          (' on ' | translate) +
                          (job?.service_details?.date | tdate:'M/D/YY')">
                        </tidy-text>
                        <tidy-text
                          *ngIf ="!last"
                          [header]="', '">
                        </tidy-text>
                      </ng-container>
                    </tidy-row>
                    <tidy-row class="vertical-align-center">
                      <tidy-text
                        [body]="('Sent: ' | translate) +
                        (invoice?.created_at | tdate:'M/D/YY h:mma')">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row>
                      <tidy-text
                        [body]="'To: '">
                      </tidy-text>
                      <tidy-text
                        [body]="(invoice?.customer?.account_name !== '' && invoice?.customer?.account_name) ?
                        invoice?.customer?.account_name :
                        (invoice?.customer?.first_name + ' ' + (invoice?.customer?.last_name | abbrLastName: true))"
                        [translate]="false">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row>
                      <tidy-text
                        [body]="('Price: ' | translate) + (invoice?.amount | tcurrency)">
                      </tidy-text>
                    </tidy-row>
                  </ion-col>
                </ion-row>
              </tidy-row>
            </tidy-grid>
          </tidy-card-button>
        </tidy-card>
      </ng-container>

      <tidy-row *ngIf="paidInvoices?.length > showPaidInvoiceLimit" [align]="'center'">
        <tidy-text
          [body]="'Show More'"
          class="link"
          (action)="increasePaidInvoiceLimit()">
        </tidy-text>
      </tidy-row>

    </ng-container>

    <ng-container *ngIf="!loaded">

      <tidy-row >
        <ion-skeleton-text animated style="width: 210px; margin: auto" ></ion-skeleton-text>
      </tidy-row>

      <tidy-row >
        <ion-skeleton-text animated style="width: 140px; height: 15px" ></ion-skeleton-text>
      </tidy-row>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 140px; height: 15px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 120px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 130px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 130px"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

      <tidy-row >
        <ion-skeleton-text animated style="width: 110px; height: 15px" ></ion-skeleton-text>
      </tidy-row>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 140px; height: 15px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 120px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 130px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 130px"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 140px; height: 15px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 120px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 130px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 130px"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 140px; height: 15px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 120px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 130px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 130px"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
