<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Can\'t Access Property'"
    [noBow]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="loaded">
      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'You notified '">
        </tidy-text>
        <tidy-text
          [body]="job?.client +
          ' ' +
          (job?.last_name | abbrLastName: true)"
          [translate]="false">
        </tidy-text>
        <tidy-text
          [body]="'. Make best efforts to get in.'">
        </tidy-text>
      </tidy-row>

      <tidy-row [align]="'center'" class="extra-bottom-padding">
        <tidy-text
          [body]="'Use your number on file '">
        </tidy-text>
        <tidy-text
          [body]="phoneNumber | telephone:'format'"
        ></tidy-text>
      </tidy-row>

      <tidy-card>
        <tidy-card-button (action)="goToContactPage()">
          <tidy-row class="vertical-align-center">
           <tidy-image
              [src]="'assets/svg/call-black.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Call '">
            </tidy-text>
            <tidy-text
              [header]="job?.client + ' ' + (job?.last_name | abbrLastName: true)"
              [translate]="false">
            </tidy-text>
            <tidy-text
              [header]="' Many Times'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-card-button (action)="goToContactPage()">
          <tidy-row class="vertical-align-center">
           <tidy-image
              [src]="'assets/svg/chatbox-ellipses.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Text '">
            </tidy-text>
            <tidy-text
              [header]="job?.client + ' ' + (job?.last_name | abbrLastName: true)"
              [translate]="false">
            </tidy-text>
            <tidy-text
              [header]="' Many Times'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/time.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="('Try until ' | translate) +
            tryUntilTime">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="('Starting at ' | translate) +
            startCreditTime +
            (' the time counts against the Client.' | translate)">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-card *ngIf="stillHelping">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/time-outline.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Client still helping?'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'Your wait time counts against the client.  Stay as long as you’d like to make the client happy.'">
          </tidy-text>
        </tidy-row>
      </tidy-card>


      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Check that you followed '">
        </tidy-text>
        <tidy-text
          [body]="job?.client +
          ' ' +
          (job?.last_name | abbrLastName: true) + '\'s '"
          [translate]="false">
        </tidy-text>
        <tidy-text
          [body]="'notes:'">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <tidy-row [alignLastItemRight]="true">
          <tidy-image
            [src]="'assets/svg/home.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Address'">
          </tidy-text>
          <tidy-text
           [body]="'Get Directions'"
           (action)="mwService.getDirections(job, 'cant-access')"
           class="link">
         </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="job?.address"
            [translate]="false">
          </tidy-text>
          <ng-container *ngIf="job?.addressUnit">
            <br>
            <tidy-text
              [body]="job?.addressUnit">
            </tidy-text>
          </ng-container>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/car.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Parking'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            *ngIf="job?.parking_notes"
            [body]="job?.parking_notes">
          </tidy-text>
          <tidy-text
            *ngIf="!job?.parking_notes"
            [body]="'<i>No Parking Notes</i>'">
          </tidy-text>
        </tidy-row>
        <tidy-carrousel *ngIf="job?.addressPhotosParking?.length > 0">
          <ng-container *ngFor="let photo of job.addressPhotosParking">
            <img *tidyCarrousel [src]="photo?.photo_url">
          </ng-container>
        </tidy-carrousel>
      </tidy-card>

      <tidy-card>
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/lock-open.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Access'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <ng-container *ngIf="job?.remoteAccessPin">
            <tidy-text
              [body]="('Lockbox code: ' | translate) + job?.remoteAccessPin + (', Notes:' | translate)">
            </tidy-text>&nbsp;
          </ng-container>
          <tidy-text
            *ngIf="job?.access"
            [body]="job?.access">
          </tidy-text>
          <tidy-text
            *ngIf="!job?.access && !job?.remoteAccessPin"
            [helper]="'<i>No Access Notes</i>'">
          </tidy-text>
        </tidy-row>
        <tidy-carrousel *ngIf="job?.addressPhotosAccess?.length > 0">
          <ng-container *ngFor="let photo of job.addressPhotosAccess">
            <img *tidyCarrousel [src]="photo?.photo_url">
          </ng-container>
        </tidy-carrousel>
      </tidy-card>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-button
        [text]="'Got Access - Back to Job'"
        [action]="backToJob.bind(this)"
        class="primary">
      </tidy-button>

      <tidy-button
        [text]="'I Could Not Access the Home'"
        [action]="cantGetIn.bind(this)"
        class="secondary">
      </tidy-button>

      <tidy-grid>
        <ion-row>
          <ion-col size="3">
            <tidy-round-button
              class="black"
              [icon]="'assets/svg/close-circle-white.svg'"
              (action)="goToCallOutPage()"
              [label]="'Call Out'" >
            </tidy-round-button>
          </ion-col>
          <ion-col size="3" >
            <tidy-round-button
              class="black"
              [icon]="'assets/svg/hand-right-outline-white.svg'"
              (action)="goToClientRefusedServicePage()"
              [label]="'Client Refused Service'" >
            </tidy-round-button>
          </ion-col>
        </ion-row>
      </tidy-grid>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
