import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { CustomNotificationsService } from 'src/shared/providers/custom-notifications/custom-notifications';
import { StaticData } from 'src/shared/providers/static-data';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'custom-notifications',
  templateUrl: 'custom-notifications.html',
  styleUrls: ['./custom-notifications.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomNotificationsPage implements OnInit {
  notificationsSettings: any[];
  filteredNotificationsSettings: any[];
  queryNotificationsSettings: any[];
  timeOpts = [];
  expandedIndex: string | null = null;
  lastSavedSettings: any;
  savingText = 'All changes saved';
  loaded = false;
  searchForm: FormGroup;
  totalNotifications: number = 0;

  constructor(
    private customNotificationsService: CustomNotificationsService,
    private staticData: StaticData,
    private formBuilder: FormBuilder
  ) {}

  async ngOnInit() {
    try {
      this.searchForm = this.formBuilder.group({
        search: [''],
      });
      this.timeOpts = this.staticData.timeRanges();
      const notificationsSettings =
        await this.customNotificationsService.getNotificationsSettings();
      this.notificationsSettings = this.parseNotificationsSettings(
        notificationsSettings
      );
      this.initializeTimeRangeForms();
      this.filteredNotificationsSettings = this.notificationsSettings.slice(0, 10);
      this.totalNotifications = this.notificationsSettings.length;
      this.loaded = true;
      console.log(this.notificationsSettings);
      setTimeout(() => {
        this.watchTimeRangeForms();
        this.watchSearchForm();
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  }

  watchSearchForm() {
    this.searchForm.valueChanges.subscribe((value) => {
      if (value === '' || value === null) {
        this.filteredNotificationsSettings = this.notificationsSettings.slice(
          0,
          10
        );
        return;
      }
      this.queryNotificationsSettings = this.notificationsSettings.filter(
        (notification) =>
          notification.title.toLowerCase().includes(value.search.toLowerCase())
      );
      this.filteredNotificationsSettings = this.queryNotificationsSettings.slice(
        0,
        10
      );
    });
  }

  parseNotificationsSettings(notificationsSettings: any[]) {
    return notificationsSettings.map((setting) => ({
      ...setting,
      title: setting.title.replaceAll('_', ' ').replaceAll('.', ' '),
      channels: this.parseChannels(
        setting.active_channels,
        setting.available_channels
      ),
      allowsTimeRangeConfig: setting.possible_settings?.includes(
        'allowed_time_ranges'
      ),
    }));
  }

  parseChannels(activeChannels: any[], availableChannels: any[]) {
    return availableChannels.map((channel) => ({
      name: channel.replaceAll('_', ' ').replaceAll('.', ' '),
      checked: activeChannels.includes(channel),
      isOpened: false,
    }));
  }

  openChannelSettings(channel: any) {
    channel.isOpened = !channel.isOpened;
  }

  addUnderScore(channel: string) {
    return channel.replace(' ', '_');
  }

  initializeTimeRangeForms() {
    const daysOfWeek = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];
    this.notificationsSettings.forEach((notification) => {
      notification.channels.forEach((channel) => {
        channel.timeRangeForms = daysOfWeek.map((day) => {
          const channelName = this.addUnderScore(channel.name);
          const weekDay = this.getWeekDay(day);
          const foundTimeRangeSetting = notification?.allowed_time_ranges?.find(
            (range) =>
              range.week_day === weekDay && range.channel === channelName
          );
          const isChannelEnabled = notification?.active_channels?.includes(
            channel.name
          );
          const isDayChecked =
            isChannelEnabled &&
            (notification?.allowed_time_ranges === null ||
              !foundTimeRangeSetting ||
              foundTimeRangeSetting?.ranges?.length > 0);
          return {
            dayForm: this.formBuilder.group({
              day: [day],
              checked: [isDayChecked],
            }),
            timeRangeForm: this.getPatchedTimeRangeForm(
              foundTimeRangeSetting?.ranges
            ),
          };
        });
      });
    });
  }

  getPatchedTimeRangeForm(ranges: any[]) {
    if (!ranges) {
      return this.formBuilder.array([]);
    }
    return this.formBuilder.array(
      ranges.map((range) => {
        const startTime = range.split('-')[0];
        const endTime = range.split('-')[1];
        return this.formBuilder.group({
          start_time: [startTime],
          end_time: [endTime],
        });
      })
    );
  }

  watchTimeRangeForms() {
    this.notificationsSettings.forEach((notification) => {
      notification.channels.forEach((channel) => {
        channel.timeRangeForms.forEach((form) => {
          form.timeRangeForm.valueChanges
            .pipe(debounceTime(300))
            .subscribe((value) => {
              const isTimeRangesValid = value.every(
                (range) => range.start_time !== '' && range.end_time !== ''
              );
              if (isTimeRangesValid) {
                this.updateNotificationsSettingsArray();
                const payload = this.buildSettingsUpdatePayload(
                  notification.notification_type_id
                );
                this.saveSettings(payload);
              }
            });
        });
      });
    });
  }

  toggleChannel(event: any, channel: any, notification: any) {
    event.stopPropagation();
    const checked = !channel.checked;
    setTimeout(() => {
      channel.checked = checked;
      channel.timeRangeForms.forEach((form) => {
        form.dayForm.controls.checked.patchValue(checked);
        form.dayForm.controls.checked.value = checked;
        form.dayForm.updateValueAndValidity();
      });
      this.updateNotificationsSettingsArray();
      const payload = this.buildSettingsUpdatePayload(
        notification.notification_type_id
      );
      this.saveSettings(payload);
    }, 300);
  }

  updateValidity() {
    this.notificationsSettings.forEach((notification) => {
      notification.channels.forEach((channel) => {
        channel.timeRangeForms.forEach((form) => {
          form.updateValueAndValidity();
        });
      });
    });
  }

  removeTimeRange(timeRangeForm: FormArray, controlIndex: number) {
    timeRangeForm.removeAt(controlIndex);
  }

  endTimes(startTime: string) {
    if (!startTime) return [];

    return this.timeOpts.filter((opt) => {
      return opt.value > startTime;
    });
  }

  addTimeRange(timeRangeForm: FormArray) {
    timeRangeForm.push(
      this.formBuilder.group({
        start_time: [''],
        end_time: [''],
      })
    );
  }

  onAccordionChange(index: number, timeRangeIndex: number) {
    if (this.expandedIndex === index + '-' + timeRangeIndex) {
      this.expandedIndex = null;
    } else {
      this.expandedIndex = index + '-' + timeRangeIndex;
    }
  }

  toggleDay(event: any, notification: any, channel: any, formGrp: any) {
    event.stopPropagation();

    setTimeout(() => {
      channel.timeRangeForms.forEach((form) => {
        if (
          form.dayForm.controls.day.value === formGrp.dayForm.controls.day.value
        ) {
          form.dayForm.controls.checked.value =
            form.dayForm.controls.checked.value;
          form.dayForm.updateValueAndValidity();
        }
      });
      const isAllDaysUnchecked = channel.timeRangeForms.every(
        (form) => !form.dayForm.controls.checked.value
      );
      if (isAllDaysUnchecked) {
        channel.checked = false;
      } else {
        channel.checked = true;
      }

      this.updateNotificationsSettingsArray();

      const payload = this.buildSettingsUpdatePayload(
        notification.notification_type_id
      );
      this.saveSettings(payload);
    }, 300);
  }

  updateNotificationsSettingsArray() {
    try {
      this.notificationsSettings.forEach((originalNotification) => {
        const filteredNotification = this.filteredNotificationsSettings.find(
          (notification) =>
            notification.notification_type_id ===
            originalNotification.notification_type_id
        );

        if (filteredNotification) {
          originalNotification.channels.forEach((channel, index) => {
            const filteredChannel = filteredNotification.channels[index];
            channel.checked = filteredChannel.checked;
            channel.timeRangeForms.forEach((form, formIndex) => {
              const filteredForm = filteredChannel.timeRangeForms[formIndex];
              form.dayForm.controls.checked.setValue(
                filteredForm.dayForm.controls.checked.value
              );
            });
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  async saveSettings(payload: any) {
    console.log('payload', payload);
    try {
      if (JSON.stringify(payload) !== JSON.stringify(this.lastSavedSettings)) {
        this.savingText = 'Saving changes..';
        await this.customNotificationsService.updateNotificationSettings(
          payload
        );
        this.lastSavedSettings = payload;
        setTimeout(() => {
          this.savingText = 'All changes saved';
        }, 1000);
      }
    } catch (error) {
      console.error(error);
    }
  }

  buildSettingsUpdatePayload(notificationTypeId: number) {
    try {
      const notification = this.notificationsSettings.find(
        (n) => n.notification_type_id === notificationTypeId
      );
      const availableChannels = notification.channels
        .filter((channel) => channel.checked)
        .map((channel) => channel.name.replace(' ', '_'));
      const payload = {
        notification_type_id: notificationTypeId,
        settings: {
          channels: availableChannels,
          allowed_time_ranges: this.getParsedAllowedTimeRanges(
            notification.channels
          ),
        },
      };
      return payload;
    } catch (error) {
      console.error(error);
    }
  }

  private getWeekDay(day: string): number {
    const daysOfWeek = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];
    return daysOfWeek.indexOf(day);
  }

  private getParsedAllowedTimeRanges(channels: any[]) {
    console.log('channels', channels);
    const filteredChannels = channels.filter((channel) => channel.checked);
    return filteredChannels
      .map((channel) =>
        channel.timeRangeForms.map((form) => ({
          week_day: this.getWeekDay(form.dayForm.controls.day.value),
          ranges: this.getParsedRanges(
            form.timeRangeForm.value,
            form.dayForm.value.checked
          ),
          channel: channel.name.replaceAll(' ', '_'),
        }))
      )
      .flat();
  }

  private getParsedRanges(ranges: any[], enabled: boolean) {
    if (enabled === false) {
      return [];
    }
    if (!ranges || ranges.length === 0) {
      return null;
    }
    return ranges.map((range) => `${range.start_time}-${range.end_time}`);
  }

  loadMore(event) {
    setTimeout(() => {
      const newNotifications = this.fetchMoreNotifications();
      this.filteredNotificationsSettings = [
        ...this.filteredNotificationsSettings,
        ...newNotifications
      ];

      event.target.complete();

      if (this.filteredNotificationsSettings.length >= this.totalNotifications) {
        event.target.disabled = true;
      }
    }, 500);
  }

  fetchMoreNotifications() {
    const hasQuery = this.searchForm.value.search.length > 0;
    if (hasQuery) {
      return this.queryNotificationsSettings.slice(
        this.filteredNotificationsSettings.length,
        this.filteredNotificationsSettings.length + 10
      );
    }
    return this.notificationsSettings.slice(
      this.filteredNotificationsSettings.length,
      this.filteredNotificationsSettings.length + 10
    );
  }
}
