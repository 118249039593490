import { ModalController } from '@ionic/angular';
import { Component } from '@angular/core';

import { Certification } from 'src/providers/certification/certification';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { Loading } from 'src/shared/components/loading/loading';

import { CertificationsModel, SectionCardModel, SectionStylesModel } from 'src/shared/models/certification.model';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { IdentificationCheck } from 'src/shared/providers/identification-check';
import { IdentityVerificationModalComponent } from '../identity-verification-modal/identity-verification';
import { MatDialog } from '@angular/material/dialog';
import { CommonUtils } from 'src/shared/utils/common-utils';

const IDENTIFICATION_CHECK_STATUSES = {
  0: 'requires_input',
  1: 'processing',
  2: 'verified',
}

@Component({
  templateUrl: 'new-clients.html',
})
export class OnboardingNewClientsPage extends Timeout {

  cards: SectionCardModel[];
  completedCount: number;
  readyForBackgroundCheck: boolean;
  certifications: CertificationsModel;

  constructor(
    private certification: Certification,
    private customNavCtrl: CustomNavController,
    private identificationCheck: IdentificationCheck,
    private dialog: MatDialog,
    private modalCtrl: ModalController,
    private commonUtils: CommonUtils,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
  }

  @Loading()
  async ionViewDidEnter() {
    try {
      this.loaded = false;
      const data: CertificationsModel = await this.certification.getCertificationsNoCache();
      const identificationCheck = await this.identificationCheck.getIdentityCheck();
      data.steps.identity_verification = identificationCheck.status !== IDENTIFICATION_CHECK_STATUSES[2] ? 'incomplete' : 'complete';
      this.certifications = data;
      this.readyForBackgroundCheck = this.readyForBackground(data);
      this.countCompleted(data);
      this.cards = this.buildCards(data);
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  countCompleted(data) {
    this.completedCount = 0;
    const steps = [
      'profile',
      'email_validation',
      'phone_verification',
      'max_drive_time',
      'bookable_times',
      'walkthrough',
      'review_terms',
      'equipment_verification',
      'identity_verification',
      'background_check'
    ];
    steps.forEach((step) => {
      if (data.steps[step] === 'complete') {
        this.completedCount += 1;
      };
    });
  }

  buildCards(data): SectionCardModel[] {
    return [
      {
        header: 'Add Account Info',
        body: 'So Clients know who you are.',
        linkPage: 'account-info',
        finished: this.getFinishedStyles(data.steps.profile, 'profile')
      },
      {
        header: 'Confirm Email',
        body: 'To ensure your email is correct.',
        linkPage: 'validate-email',
        finished: this.getFinishedStyles(data.steps.email_validation, 'email_validation')
      },
      {
        header: 'Confirm Phone',
        body: 'For Client communication and notifications.',
        linkPage: 'validate-phone',
        finished: this.getFinishedStyles(data.steps.phone_verification, 'phone_verification')
      },
      {
        header: 'Set Max Drive Time',
        body: 'So Clients know what your work area is.',
        linkPage: 'profile/travel-time',
        finished: this.getFinishedStyles(data.steps.max_drive_time, 'max_drive_time')
      },
      {
        header: 'Make Yourself Bookable',
        body: 'So Clients can book you instantly.',
        linkPage: 'make-bookable',
        finished: this.getFinishedStyles(data.steps.bookable_times, 'bookable_times')
      },
      {
        header: 'Learn About Clients from TIDY',
        body: 'Learn about how Clients can find and book you via your listing on TIDY.',
        linkPage: 'learn-clients-from-tidy',
        finished: this.getFinishedStyles(data.steps.walkthrough, 'walkthrough')
      },
      {
        header: 'Review & Confirm Terms',
        body: 'Re-review the terms again to be sure you agree and understand.',
        linkPage: 'review-terms',
        finished: this.getFinishedStyles(data.steps.review_terms, 'review-terms')
      },
      {
        header: 'Certify Equipment',
        body: this.getEquipmentBody(data.steps.equipment_verification),
        linkPage: 'equipment',
        finished: this.getFinishedStyles(data.steps.equipment_verification, 'equipment_verification')
      },
      {
        header: 'Identity Verification',
        body: 'Do a 1 minute verification using your camera and a photo ID.',
        linkPage: 'identity-verification',
        finished: this.getFinishedStyles(data?.steps?.identity_verification, 'availability')
      },
      {
        header: 'Free Background Check',
        body: this.getBackgroundCheckBody(data),
        linkPage: 'background-check',
        finished: this.getFinishedStyles(data.steps.background_check, 'background-check')
      },
    ];
  }

  getFinishedStyles(status, step): SectionStylesModel {
    if (step === 'background-check') {
      return {
        icon: (status === 'complete') ? 'checkbox' : 'square-outline',
        iconClass: (status === 'complete') ? 'green' : '',
        hideArrow: !this.readyForBackgroundCheck || (status === 'complete')
      };
    } else if (status === 'complete') {
      return {
        icon: 'checkbox',
        iconClass: 'green',
        hideArrow: (step !== 'walkthrough'),
      };
    } else if (status === 'pending') {
        return {
          icon: 'reload-outline',
          iconClass: '',
          hideArrow: true,
        };
    } else {
        return {
          icon: 'square-outline',
          iconClass: '',
          hideArrow: false
      };
    }
  }

  goToPage(page, alreadyFinishedStep) {
    if (!alreadyFinishedStep) {
      if (page === 'identity-verification') {
        this.openIdentityVerificationModal();
        return
      }
      this.customNavCtrl.navigateForward(page, {finishPage: 'new-clients'});
    }
  }

  getEquipmentBody(step): string {
    if (step === 'pending') {
      return 'Our team is reviewing your equipment and will reply ASAP.';
    } else if (step === 'rejected') {
      return 'Based on what you submitted, we were not able to verify that your equipment met the minimums for certification.  Please re-submit your equipment.';
    } else {
      return 'Confirm you have equipment so you can get Clients from TIDY.';
    }
  }

  getBackgroundCheckBody(data): string {
    if (data.steps.background_check === 'complete') {
      return 'Check waived due to your positive online presence.';
    } else if (data.steps.background_check === 'pending') {
      return 'We sent an email to your email on file.  This has a link to complete your background check.  Check your email and complete the check to proceed. Please allow 24 hours for the email to arrive.';
    } else if (this.readyForBackgroundCheck) {
      return 'You’re ready for a background check!';
    } else {
      return 'Please complete all other steps before getting background check.';
    }
  }

  readyForBackground(data): boolean {
    return (
      data.existing_clients &&
      data.steps.bookable_times === 'complete' &&
      data.steps.walkthrough === 'complete' &&
      data.steps.review_terms === 'complete' &&
      data.steps.equipment_verification === 'complete' &&
      data.steps.identity_verification === 'complete'
    );
  }

  async openIdentityVerificationModal(): Promise<void> {
    const isMobileResolution = window.innerWidth <= 870;
    if (!isMobileResolution) {
      const dialog = this.dialog.open(IdentityVerificationModalComponent, {
        width: '500px',
      });
      dialog.afterClosed().subscribe(async () => {
        this.handleIdentityVerificationModalClose();
      });
    } else {
      this.modalCtrl
      .create({
        component: IdentityVerificationModalComponent,
        mode: 'ios',
        backdropDismiss: true,
        showBackdrop: true,
        breakpoints: [0, 0.4, 0.5, 0.6],
        initialBreakpoint: 0.75,
        presentingElement: await this.modalCtrl.getTop(),
        canDismiss: true,
      })
      .then((modal) => {
        modal.present();
        modal.onDidDismiss().then(async () => {
            this.handleIdentityVerificationModalClose();
          });
        });
      }
    }

  async handleIdentityVerificationModalClose(): Promise<void> {
    const loading = await this.commonUtils.showLoading();
    try {
      const identificationCheck = await this.identificationCheck.getIdentityCheck();
      this.certifications.steps.identity_verification = identificationCheck.status !== IDENTIFICATION_CHECK_STATUSES[2] ? 'incomplete' : 'complete';
      this.readyForBackgroundCheck = this.readyForBackground(this.certifications);
      this.countCompleted(this.certifications);
      this.cards = this.buildCards(this.certifications);
      loading.dismiss();
    } catch (err) {
      this.timeoutHandler(err);
      console.log(err);
      loading.dismiss();
    }
  }
}
