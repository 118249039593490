import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { MileageTracking } from 'src/providers/mileage-tracking/mileage-tracking';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

import { Loading } from 'src/shared/components/loading/loading';

import { TidySelectStringValueModel } from 'src/models/tidy-select-item.model';
import { MileageTrackingModel, MileageTrackingRecordModel } from 'src/shared/models/mileage-tracking.model';
import { Me } from 'src/shared/providers/me';

@Component({
  templateUrl: './mileage-tracking.html'
})
export class MileageTrackingPage {

  customRange: boolean;
  errorMessage: string;
  filterItems: TidySelectStringValueModel[];
  form: UntypedFormGroup;
  loaded: boolean;
  mileage: MileageTrackingRecordModel[];
  milesTravelled: number;
  distanceFormat: string;

  constructor(
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private iabUtils: InAppBrowserUtils,
    private mileageTracking: MileageTracking,
    private storage: TidyStorage,
    private me: Me
  ) {
    this.form = this.fb.group({
      filter: [''],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required]
    });
  }

  async ionViewDidEnter() {
    this.loaded = false;
    this.distanceFormat = await this.me.getDistanceFormatValue();
    await this.setFilter();
    await this.getThisYearMileage();
    this.loaded = true;
  }

  async setFilter() {
    this.filterItems = await this.getFilterItems();
    this.form.controls.filter.setValue(this.filterItems[0].value);
  }

  async getThisYearMileage() {
    const thisYear = new Date().getFullYear()
    const firstDayOfYear = new Date(thisYear, 0, 1);
    const lastDayOfYear = new Date(thisYear, 11, 31);
    await this.getMileage(firstDayOfYear, lastDayOfYear);
  }

  @Loading()
  async getMileage(startDate, endDate) {
    const mileage: MileageTrackingModel[] = await this.mileageTracking.getMileageRecordByDate(startDate, endDate);
    this.mileage = this.parseMileage(mileage);
    this.getTotals();
  }

  parseMileage(mileage) {
    let array = [];
    mileage.map((month) => {
      month.mileage_tracking_records.map((record) => {
        array.push(record);
      })
    });
    return array;
  }

  getTotals() {
    let milesTravelled = 0;
    this.mileage.map((job) => {
      milesTravelled += job.estimated_mileage;
    });
    if (this.distanceFormat === 'km') {
      this.milesTravelled = Math.round(milesTravelled * 1.60934);
    } else {
      this.milesTravelled = Math.round(milesTravelled);
    }
  }

  showResults() {
    if (!this.form.valid) {
      return this.errorMessage = 'Please select a start and end date.'
    }
    this.getMileage(this.form.controls.startDate.value, this.form.controls.endDate.value);
  }

  getFilterItems(): TidySelectStringValueModel[] {
    let items = [];
    let year = new Date().getFullYear();
    const yearsSinceLaunch = year - 2020;
    for (let i = 0; i <= yearsSinceLaunch; i++) {
      items.push({
        viewValue: `${year}`,
        value: `${year}`
      });
      year -= 1;
    };
    items.push({
      viewValue: 'Custom Range',
      value: 'Custom Range'
    });
    return items;
  }

  changeRange(value) {
    if (value === 'Custom Range') {
      return this.customRange = true;
    }
    this.customRange = false;
    const firstDayOfYear = new Date(value, 0, 1);
    const lastDayOfYear = new Date(value, 11, 31);
    this.getMileage(firstDayOfYear, lastDayOfYear);
  }

  addJob() {
    this.customNavCtrl.navigateForward('add-private-job');
  }

  async goToJob(job) {
    await this.storage.save('jobMileageBackRoute', 'mileage-tracking');
    this.customNavCtrl.navigateForward('job-mileage', job);
  }

  learnMore() {
    this.iabUtils.openUrl('https://help.tidy.com/pros/mileage-tracking');
  }
}
