import { Component } from '@angular/core';
import * as moment from 'moment';

import { ClientModel } from 'src/shared/models/client.model';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Proposals } from 'src/providers/proposals/proposals';
import { PrivateClient } from 'src/providers/private-client/private-client';
import { PrivatePayments } from 'src/providers/private-payments/private-payments';
import { Team } from 'src/providers/team/team';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { SuccessPageParamsModel } from 'src/pages/success/success';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: 'confirm-send-proposal.html'
})

export class ConfirmSendProposalPage extends Timeout {

  client: ClientModel;
  errorMessage: string;
  params: any;
  proName: string;
  proPhoneNumber: string;
  proEmail: string;
  today: any;

  constructor(
    private navCtrl: CustomNavController,
    private proposals: Proposals,
    private privateClient: PrivateClient,
    public privatePayments: PrivatePayments,
    private storage: TidyStorage,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
  }

  async ionViewDidEnter() {
    try {
      this.loaded = false;
      this.params = await this.navCtrl.getParams();
      this.today = moment();
      this.proName = await this.storage.retrieve('hk_name');
      this.proPhoneNumber = await this.storage.retrieve('hk_phone_number');
      this.proEmail = await this.storage.retrieve('hk_email');
      this.client = await this.privateClient.getPrivateClientInfo(this.params.proposalPayload.customer_id);
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  async sendProposal() {
    try {
      const isAddingEmailForClient = this.params?.clientUpdatePayload !== undefined
      if (isAddingEmailForClient) {
        await this.privateClient.updatePrivateClient(this.params.clientUpdatePayload, this.params.proposalPayload.customer_id);
      }
      await this.proposals.createProposal(this.params.proposalPayload);
      const onSuccess: SuccessPageParamsModel = {
        header: 'Proposal Sent',
        body: `${this.client.first_name} ${new TranslationPipe().transform(' will receive an email and see this proposal at your Client\'s Hub.')}`,
        buttonText: 'Ok',
        buttonRoute: `private-client/${this.params.proposalPayload.customer_id}`
      };
      this.navCtrl.navigateForward('success', onSuccess);
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }
  
}
