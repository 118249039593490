export const translations = {
   
    'Steps to Complete:': {
        pt: 'Passos para Completar:',
        es: 'Pasos para Completar:',
        ru: 'Шаги для Завершения:',
        fr: 'Étapes à Compléter:',
        de: 'Schritte zum Abschließen:'
    },
    'Post ': {
        pt: 'Postar ',
        es: 'Publicar ',
        ru: 'Опубликовать ',
        fr: 'Publier ',
        de: 'Posten '
    },
    'here': {
        pt: 'aqui',
        es: 'aquí',
        ru: 'здесь',
        fr: 'ici',
        de: 'hier'
    },
    'I have my own service business and advertise that business elsewhere from TIDY.': {
        pt: 'Tenho meu próprio negócio de serviços e anuncio esse negócio fora da TIDY.',
        es: 'Tengo mi propio negocio de servicios y anuncio ese negocio fuera de TIDY.',
        ru: 'У меня есть собственный бизнес по предоставлению услуг, и я рекламирую этот бизнес вне TIDY.',
        fr: 'J\'ai ma propre entreprise de services et je fais de la publicité pour cette entreprise en dehors de TIDY.',
        de: 'Ich habe mein eigenes Dienstleistungsgeschäft und werbe für dieses Geschäft außerhalb von TIDY.'
    },
    'Link': {
        pt: 'Link',
        es: 'Enlace',
        ru: 'Ссылка',
        fr: 'Lien',
        de: 'Link'
    },
    'Please enter the post text': {
        pt: 'Por favor, insira o texto da postagem',
        es: 'Por favor, ingrese el texto de la publicación',
        ru: 'Пожалуйста, введите текст сообщения',
        fr: 'Veuillez entrer le texte du message',
        de: 'Bitte geben Sie den Beitragstext ein'
    },
    'Confirm': {
        pt: 'Confirmar',
        es: 'Confirmar',
        ru: 'Подтвердить',
        fr: 'Confirmer',
        de: 'Bestätigen'
    },
    'Post Submitted': {
        pt: 'Postagem Enviada',
        es: 'Publicación Enviada',
        ru: 'Сообщение Отправлено',
        fr: 'Publication Soumise',
        de: 'Beitrag Eingereicht'
    },
    'Your post has been submitted successfully and is now pending review. Thank you for your contribution!': {
        pt: 'Sua postagem foi enviada com sucesso e agora está pendente de revisão. Obrigado pela sua contribuição!',
        es: 'Su publicación ha sido enviada con éxito y ahora está pendiente de revisión. ¡Gracias por su contribución!',
        ru: 'Ваше сообщение успешно отправлено и теперь ожидает проверки. Спасибо за ваш вклад!',
        fr: 'Votre publication a été soumise avec succès et est maintenant en attente de révision. Merci pour votre contribution!',
        de: 'Ihr Beitrag wurde erfolgreich eingereicht und wartet nun auf Überprüfung. Vielen Dank für Ihren Beitrag!'
    },
    'Ok': {
        pt: 'Ok',
        es: 'Ok',
        ru: 'Ок',
        fr: 'Ok',
        de: 'Ok'
    },
    'Google Places': {
        pt: 'Google Lugares',
        es: 'Google Lugares',
        ru: 'Google Места',
        fr: 'Google Lieux',
        de: 'Google Orte'
    },
    'promoting your business in a positive light.': {
        pt: 'promovendo seu negócio de forma positiva.',
        es: 'promocionando su negocio de manera positiva.',
        ru: 'продвигая ваш бизнес в положительном свете.',
        fr: 'promouvoir votre entreprise sous un jour positif.',
        de: 'Ihr Unternehmen in einem positiven Licht fördern.'
    },
    'Your own Yelp page': {
        pt: 'Sua própria página do Yelp',
        es: 'Su propia página de Yelp',
        ru: 'Ваша собственная страница на Yelp',
        fr: 'Votre propre page Yelp',
        de: 'Ihre eigene Yelp-Seite'
    },
    'Simply provide us a link to your Yelp page showing an active business.': {
        pt: 'Basta nos fornecer um link para sua página do Yelp mostrando um negócio ativo.',
        es: 'Simplemente proporciónenos un enlace a su página de Yelp que muestre un negocio activo.',
        ru: 'Просто предоставьте нам ссылку на вашу страницу Yelp, показывающую активный бизнес.',
        fr: 'Fournissez-nous simplement un lien vers votre page Yelp montrant une entreprise active.',
        de: 'Geben Sie uns einfach einen Link zu Ihrer Yelp-Seite, die ein aktives Geschäft zeigt.'
    },
    'Your own Facebook Page': {
        pt: 'Sua própria página do Facebook',
        es: 'Su propia página de Facebook',
        ru: 'Ваша собственная страница на Facebook',
        fr: 'Votre propre page Facebook',
        de: 'Ihre eigene Facebook-Seite'
    },
    'Simply provide us a link to your Facebook page showing an active business.': {
        pt: 'Basta nos fornecer um link para sua página do Facebook mostrando um negócio ativo.',
        es: 'Simplemente proporciónenos un enlace a su página de Facebook que muestre un negocio activo.',
        ru: 'Просто предоставьте нам ссылку на вашу страницу Facebook, показывающую активный бизнес.',
        fr: 'Fournissez-nous simplement un lien vers votre page Facebook montrant une entreprise active.',
        de: 'Geben Sie uns einfach einen Link zu Ihrer Facebook-Seite, die ein aktives Geschäft zeigt.'
    },
    'Your own Twitter': {
        pt: 'Seu próprio Twitter',
        es: 'Su propio Twitter',
        ru: 'Ваш собственный Twitter',
        fr: 'Votre propre Twitter',
        de: 'Ihr eigenes Twitter'
    },
    'Simply provide us a link to your Twitter page showing an active business.': {
        pt: 'Basta nos fornecer um link para sua página do Twitter mostrando um negócio ativo.',
        es: 'Simplemente proporciónenos un enlace a su página de Twitter que muestre un negocio activo.',
        ru: 'Просто предоставьте нам ссылку на вашу страницу Twitter, показывающую активный бизнес.',
        fr: 'Fournissez-nous simplement un lien vers votre page Twitter montrant une entreprise active.',
        de: 'Geben Sie uns einfach einen Link zu Ihrer Twitter-Seite, die ein aktives Geschäft zeigt.'
    },
    'Your own Instagram': {
        pt: 'Seu próprio Instagram',
        es: 'Su propio Instagram',
        ru: 'Ваш собственный Instagram',
        fr: 'Votre propre Instagram',
        de: 'Ihr eigenes Instagram'
    },
    'Simply provide us a link to your Instagram page showing an active business.': {
        pt: 'Basta nos fornecer um link para sua página do Instagram mostrando um negócio ativo.',
        es: 'Simplemente proporciónenos un enlace a su página de Instagram que muestre un negocio activo.',
        ru: 'Просто предоставьте нам ссылку на вашу страницу Instagram, показывающую активный бизнес.',
        fr: 'Fournissez-nous simplement un lien vers votre page Instagram montrant une entreprise active.',
        de: 'Geben Sie uns einfach einen Link zu Ihrer Instagram-Seite, die ein aktives Geschäft zeigt.'
    },
    'Your own webpage': {
        pt: 'Seu próprio site',
        es: 'Su propia página web',
        ru: 'Ваш собственный веб-сайт',
        fr: 'Votre propre site web',
        de: 'Ihre eigene Webseite'
    },
    'Simply provide us a link to your webpage showing an active business.': {
        pt: 'Basta nos fornecer um link para seu site mostrando um negócio ativo.',
        es: 'Simplemente proporciónenos un enlace a su página web que muestre un negocio activo.',
        ru: 'Просто предоставьте нам ссылку на ваш веб-сайт, показывающий активный бизнес.',
        fr: 'Fournissez-nous simplement un lien vers votre site web montrant une entreprise active.',
        de: 'Geben Sie uns einfach einen Link zu Ihrer Webseite, die ein aktives Geschäft zeigt.'
    },
    'Your consumer affairs page': {
        pt: 'Sua página de assuntos do consumidor',
        es: 'Su página de asuntos del consumidor',
        ru: 'Ваша страница по делам потребителей',
        fr: 'Votre page des affaires de consommation',
        de: 'Ihre Verbraucherangelegenheiten-Seite'
    },
    'Simply provide us a link to your Consumer Affairs profile showing an active business.': {
        pt: 'Basta nos fornecer um link para seu perfil de Assuntos do Consumidor mostrando um negócio ativo.',
        es: 'Simplemente proporciónenos un enlace a su perfil de Asuntos del Consumidor que muestre un negocio activo.',
        ru: 'Просто предоставьте нам ссылку на ваш профиль по делам потребителей, показывающий активный бизнес.',
        fr: 'Fournissez-nous simplement un lien vers votre profil des affaires de consommation montrant une entreprise active.',
        de: 'Geben Sie uns einfach einen Link zu Ihrem Verbraucherangelegenheiten-Profil, das ein aktives Geschäft zeigt.'
    },
    'Your own Angies List profile': {
        pt: 'Seu próprio perfil na Angies List',
        es: 'Su propio perfil en Angies List',
        ru: 'Ваш собственный профиль на Angies List',
        fr: 'Votre propre profil sur Angies List',
        de: 'Ihr eigenes Profil auf Angies List'
    },
    'Simply provide us a link to your Angies List profile showing an active business.': {
        pt: 'Basta nos fornecer um link para seu perfil na Angies List mostrando um negócio ativo.',
        es: 'Simplemente proporciónenos un enlace a su perfil en Angies List que muestre un negocio activo.',
        ru: 'Просто предоставьте нам ссылку на ваш профиль на Angies List, показывающий активный бизнес.',
        fr: 'Fournissez-nous simplement un lien vers votre profil sur Angies List montrant une entreprise active.',
        de: 'Geben Sie uns einfach einen Link zu Ihrem Angies List-Profil, das ein aktives Geschäft zeigt.'
    },
    'TIDY Client App': {
        pt: 'Aplicativo TIDY Client',
        es: 'Aplicación TIDY Client',
        ru: 'Приложение TIDY Client',
        fr: 'Application TIDY Client',
        de: 'TIDY Client App'
    },
    'TIDY Homekeeper App': {
        pt: 'Aplicativo TIDY Homekeeper',
        es: 'Aplicación TIDY Homekeeper',
        ru: 'Приложение TIDY Homekeeper',
        fr: 'Application TIDY Homekeeper',
        de: 'TIDY Homekeeper App'
    },

    'Compliance': {
        pt: 'Conformidade',
        es: 'Cumplimiento',
        ru: 'Соответствие',
        fr: 'Conformité',
        de: 'Einhaltung'
    },
    'TIDY for Pros is only available for businesses, please confirm your business with any of the below easy verification options:': {
        pt: 'TIDY para Profissionais está disponível apenas para empresas, por favor confirme seu negócio com qualquer uma das opções de verificação fáceis abaixo:',
        es: 'TIDY para Profesionales solo está disponible para empresas, por favor confirme su negocio con cualquiera de las opciones de verificación fáciles a continuación:',
        ru: 'TIDY для профессионалов доступен только для бизнеса, пожалуйста, подтвердите свой бизнес с помощью любого из приведенных ниже простых вариантов проверки:',
        fr: 'TIDY pour les Pros est uniquement disponible pour les entreprises, veuillez confirmer votre entreprise avec l\'une des options de vérification faciles ci-dessous :',
        de: 'TIDY für Profis ist nur für Unternehmen verfügbar, bitte bestätigen Sie Ihr Unternehmen mit einer der unten aufgeführten einfachen Verifizierungsoptionen:'
    },
    '1. Attach a link to your business advertising.': {
        pt: '1. Anexe um link para a publicidade do seu negócio.',
        es: '1. Adjunte un enlace a la publicidad de su negocio.',
        ru: '1. Прикрепите ссылку на рекламу вашего бизнеса.',
        fr: '1. Joignez un lien vers la publicité de votre entreprise.',
        de: '1. Fügen Sie einen Link zu Ihrer Unternehmenswerbung bei.'
    },
    '2. Need alternate verification? Contact ': {
        pt: '2. Precisa de verificação alternativa? Contate ',
        es: '2. ¿Necesita verificación alternativa? Contacto ',
        ru: '2. Нужна альтернативная проверка? Свяжитесь с ',
        fr: '2. Besoin d\'une vérification alternative ? Contactez ',
        de: '2. Benötigen Sie eine alternative Verifizierung? Kontaktieren Sie '
    },
    'This is required within provide this confirmation within 10 days. Thank you for your cooperation.': {
        pt: 'Isso é necessário dentro de 10 dias. Obrigado pela sua cooperação.',
        es: 'Esto es necesario dentro de los 10 días. Gracias por su cooperación.',
        ru: 'Это необходимо предоставить в течение 10 дней. Спасибо за ваше сотрудничество.',
        fr: 'Ceci est requis dans les 10 jours. Merci pour votre coopération.',
        de: 'Dies ist innerhalb von 10 Tagen erforderlich. Vielen Dank für Ihre Zusammenarbeit.'
    },
    '(Bonus: Feel free to promote your use of TIDY to potential customers in reviews on the TIDY Apps)': {
        pt: '(Bônus: Sinta-se à vontade para promover o uso da TIDY para clientes potenciais em avaliações nos aplicativos TIDY)',
        es: '(Bonificación: Siéntase libre de promover su uso de TIDY a clientes potenciales en reseñas en las aplicaciones TIDY)',
        ru: '(Бонус: Не стесняйтесь продвигать использование TIDY потенциальным клиентам в отзывах на приложениях TIDY)',
        fr: '(Bonus : N\'hésitez pas à promouvoir votre utilisation de TIDY auprès de clients potentiels dans les avis sur les applications TIDY)',
        de: '(Bonus: Fühlen Sie sich frei, Ihre Nutzung von TIDY potenziellen Kunden in Bewertungen auf den TIDY-Apps zu fördern)'
    },
    'Certification Under Review': {
        pt: 'Certificação em Revisão',
        es: 'Certificación en Revisión',
        ru: 'Сертификация на рассмотрении',
        fr: 'Certification en cours de révision',
        de: 'Zertifizierung in Überprüfung'
    },
    'Your application is currently under review. Check back soon!': {
        pt: 'Sua aplicação está atualmente em revisão. Volte em breve!',
        es: 'Su aplicación está actualmente en revisión. ¡Vuelve pronto!',
        ru: 'Ваше заявление в настоящее время рассматривается. Возвращайтесь скоро!',
        fr: 'Votre demande est actuellement en cours de révision. Revenez bientôt!',
        de: 'Ihre Bewerbung wird derzeit überprüft. Schauen Sie bald wieder vorbei!'
    },
    'Active in Community': {
        pt: 'Ativo na Comunidade',
        es: 'Activo en la Comunidad',
        ru: 'Активен в сообществе',
        fr: 'Actif dans la Communauté',
        de: 'Aktiv in der Gemeinschaft'
    },
    'This Certification helps clients feel more comfortable booking you, someone who takes pride in their public image. <br><br> You have earned the “Compliance” Certifications!  You’re awesome!': {
        pt: 'Esta Certificação ajuda os clientes a se sentirem mais confortáveis ao reservar você, alguém que se orgulha de sua imagem pública. <br><br> Você ganhou as Certificações de “Conformidade”! Você é incrível!',
        es: 'Esta Certificación ayuda a los clientes a sentirse más cómodos al reservarlo a usted, alguien que se enorgullece de su imagen pública. <br><br> ¡Has ganado las Certificaciones de “Cumplimiento”! ¡Eres increíble!',
        ru: 'Эта сертификация помогает клиентам чувствовать себя более комфортно, бронируя вас, человека, который гордится своим публичным имиджем. <br><br> Вы заработали сертификацию «Соответствие»! Вы потрясающие!',
        fr: 'Cette Certification aide les clients à se sentir plus à l\'aise pour vous réserver, quelqu\'un qui est fier de son image publique. <br><br> Vous avez obtenu les Certifications de “Conformité”! Vous êtes génial!',
        de: 'Diese Zertifizierung hilft den Kunden, sich wohler zu fühlen, wenn sie Sie buchen, jemanden, der stolz auf sein öffentliches Image ist. <br><br> Sie haben die „Einhaltungs“-Zertifizierungen verdient! Du bist großartig!'
    },

    'No requests': {
        pt: 'Sem solicitações',
        es: 'Sin solicitudes',
        ru: 'Нет запросов',
        fr: 'Aucune demande',
        de: 'Keine Anfragen'
    },
    'Add': {
        pt: 'Adicionar',
        es: 'Agregar',
        ru: 'Добавить',
        fr: 'Ajouter',
        de: 'Hinzufügen'
    },
    'Any questions? Contact ': {
        pt: 'Alguma dúvida? Contate ',
        es: '¿Alguna pregunta? Contacto ',
        ru: 'Есть вопросы? Свяжитесь с ',
        fr: 'Des questions? Contactez ',
        de: 'Fragen? Kontaktieren Sie '
    },
    'Add Client': {
        pt: 'Adicionar Cliente',
        es: 'Agregar Cliente',
        ru: 'Добавить клиента',
        fr: 'Ajouter un client',
        de: 'Kunden hinzufügen'
    },
    'Add Job': {
        pt: 'Adicionar Trabalho',
        es: 'Agregar Trabajo',
        ru: 'Добавить работу',
        fr: 'Ajouter un travail',
        de: 'Job hinzufügen'
    },
    'Send Proposal': {
        pt: 'Enviar Proposta',
        es: 'Enviar Propuesta',
        ru: 'Отправить предложение',
        fr: 'Envoyer une proposition',
        de: 'Vorschlag senden'
    },
    'or': {
        pt: 'ou',
        es: 'o',
        ru: 'или',
        fr: 'ou',
        de: 'oder'
    },
    'Accept Jobs': {
        pt: 'Aceitar Trabalhos',
        es: 'Aceptar Trabajos',
        ru: 'Принять работу',
        fr: 'Accepter des travaux',
        de: 'Jobs annehmen'
    },
    'It looks like you aren’t connected to the internet. ': {
        pt: 'Parece que você não está conectado à internet. ',
        es: 'Parece que no estás conectado a internet. ',
        ru: 'Похоже, вы не подключены к интернету. ',
        fr: 'Il semble que vous n\'êtes pas connecté à Internet. ',
        de: 'Es sieht so aus, als wären Sie nicht mit dem Internet verbunden. '
    },
    'Try Again': {
        pt: 'Tente novamente',
        es: 'Inténtalo de nuevo',
        ru: 'Попробуйте снова',
        fr: 'Réessayer',
        de: 'Erneut versuchen'
    },
    'There are no clients from TIDY within your max drive time at your current rates right now. Check back later and consider ': {
        pt: 'Não há clientes da TIDY dentro do seu tempo máximo de deslocamento nas suas tarifas atuais no momento. Verifique mais tarde e considere ',
        es: 'No hay clientes de TIDY dentro de su tiempo máximo de conducción a sus tarifas actuales en este momento. Vuelve más tarde y considera ',
        ru: 'В настоящее время нет клиентов от TIDY в пределах вашего максимального времени в пути по вашим текущим тарифам. Проверьте позже и рассмотрите ',
        fr: 'Il n\'y a actuellement aucun client de TIDY dans votre temps de trajet maximum à vos tarifs actuels. Revenez plus tard et envisagez ',
        de: 'Derzeit gibt es keine Kunden von TIDY innerhalb Ihrer maximalen Fahrzeit zu Ihren aktuellen Tarifen. Schauen Sie später noch einmal vorbei und überlegen Sie '
    },
    'increasing your max drive time': {
        pt: 'aumentar seu tempo máximo de deslocamento',
        es: 'aumentar su tiempo máximo de conducción',
        ru: 'увеличение вашего максимального времени в пути',
        fr: 'augmenter votre temps de trajet maximum',
        de: 'Erhöhung Ihrer maximalen Fahrzeit'
    },
    ' or ': {
        pt: ' ou ',
        es: ' o ',
        ru: ' или ',
        fr: ' ou ',
        de: ' oder '
    },
    'making sure your rates are competitive': {
        pt: 'garantir que suas tarifas sejam competitivas',
        es: 'asegurarse de que sus tarifas sean competitivas',
        ru: 'убедиться, что ваши тарифы конкурентоспособны',
        fr: 's\'assurer que vos tarifs sont compétitifs',
        de: 'sicherstellen, dass Ihre Tarife wettbewerbsfähig sind'
    },
    'Add account info to see Clients near you! ': {
        pt: 'Adicione informações da conta para ver Clientes perto de você! ',
        es: '¡Agregue información de la cuenta para ver Clientes cerca de usted! ',
        ru: 'Добавьте информацию об аккаунте, чтобы увидеть клиентов рядом с вами! ',
        fr: 'Ajoutez des informations de compte pour voir les clients près de chez vous! ',
        de: 'Fügen Sie Kontoinformationen hinzu, um Kunden in Ihrer Nähe zu sehen! '
    },
    ' asked you to accept a job': {
        pt: ' pediu para você aceitar um trabalho',
        es: ' te pidió que aceptaras un trabajo',
        ru: ' попросил вас принять работу',
        fr: ' vous a demandé d\'accepter un travail',
        de: ' bat Sie, einen Job anzunehmen'
    },
    'Accept One of These Times': {
        pt: 'Aceite um desses horários',
        es: 'Acepta uno de estos horarios',
        ru: 'Примите одно из этих времен',
        fr: 'Acceptez l\'un de ces horaires',
        de: 'Akzeptieren Sie eine dieser Zeiten'
    },
    'Propose Alternate Times': {
        pt: 'Propor horários alternativos',
        es: 'Proponer horarios alternativos',
        ru: 'Предложить альтернативные времена',
        fr: 'Proposer des horaires alternatifs',
        de: 'Alternative Zeiten vorschlagen'
    },
    'Decline Request': {
        pt: 'Recusar solicitação',
        es: 'Rechazar solicitud',
        ru: 'Отклонить запрос',
        fr: 'Refuser la demande',
        de: 'Anfrage ablehnen'
    },
    'You Proposed Alternate Times': {
        pt: 'Você propôs horários alternativos',
        es: 'Propusiste horarios alternativos',
        ru: 'Вы предложили альтернативные времена',
        fr: 'Vous avez proposé des horaires alternatifs',
        de: 'Sie haben alternative Zeiten vorgeschlagen'
    },
    ' request from ': {
        pt: ' solicitação de ',
        es: ' solicitud de ',
        ru: ' запрос от ',
        fr: ' demande de ',
        de: ' Anfrage von '
    },
    'Cancel Proposed Times': {
        pt: 'Cancelar horários propostos',
        es: 'Cancelar horarios propuestos',
        ru: 'Отменить предложенные времена',
        fr: 'Annuler les horaires proposés',
        de: 'Vorgeschlagene Zeiten stornieren'
    },
    'Today, ': {
        pt: 'Hoje, ',
        es: 'Hoy, ',
        ru: 'Сегодня, ',
        fr: 'Aujourd\'hui, ',
        de: 'Heute, '
    },
    'Show Map': {
        pt: 'Mostrar mapa',
        es: 'Mostrar mapa',
        ru: 'Показать карту',
        fr: 'Afficher la carte',
        de: 'Karte anzeigen'
    },
    'Hide Map': {
        pt: 'Ocultar mapa',
        es: 'Ocultar mapa',
        ru: 'Скрыть карту',
        fr: 'Masquer la carte',
        de: 'Karte ausblenden'
    },
    'You have jobs at': {
        pt: 'Você tem trabalhos em',
        es: 'Tienes trabajos en',
        ru: 'У вас есть работа в',
        fr: 'Vous avez des travaux à',
        de: 'Sie haben Jobs bei'
    },
    'Starts ': {
        pt: 'Começa ',
        es: 'Comienza ',
        ru: 'Начинается ',
        fr: 'Commence ',
        de: 'Beginnt '
    },
    'Flexible start ': {
        pt: 'Início flexível ',
        es: 'Inicio flexible ',
        ru: 'Гибкое начало ',
        fr: 'Début flexible ',
        de: 'Flexibler Start '
    },
    ' Regular Cleaning ': {
        pt: ' Limpeza Regular ',
        es: ' Limpieza Regular ',
        ru: ' Регулярная уборка ',
        fr: ' Nettoyage Régulier ',
        de: ' Regelmäßige Reinigung '
    },
    ' / Recurring': {
        pt: ' / Recorrente',
        es: ' / Recurrente',
        ru: ' / Повторяющийся',
        fr: ' / Récurrent',
        de: ' / Wiederkehrend'
    },
    'Big Spender: They book many jobs.': {
        pt: 'Grande Gastador: Eles reservam muitos trabalhos.',
        es: 'Gran Gastador: Reservan muchos trabajos.',
        ru: 'Большой транжира: Они бронируют много работ.',
        fr: 'Gros dépensier : Ils réservent de nombreux travaux.',
        de: 'Großer Ausgeber: Sie buchen viele Jobs.'
    },
    'These Clients need you, tap a job to view:': {
        pt: 'Esses clientes precisam de você, toque em um trabalho para ver:',
        es: 'Estos clientes te necesitan, toca un trabajo para ver:',
        ru: 'Этим клиентам вы нужны, нажмите на работу, чтобы просмотреть:',
        fr: 'Ces clients ont besoin de vous, appuyez sur un travail pour voir :',
        de: 'Diese Kunden brauchen Sie, tippen Sie auf einen Job, um ihn anzuzeigen:'
    },
    'This Client needs you, tap to view:': {
        pt: 'Este cliente precisa de você, toque para ver:',
        es: 'Este cliente te necesita, toca para ver:',
        ru: 'Этому клиенту вы нужны, нажмите, чтобы просмотреть:',
        fr: 'Ce client a besoin de vous, appuyez pour voir :',
        de: 'Dieser Kunde braucht Sie, tippen Sie, um zu sehen:'
    },
    'Earn ': {
        pt: 'Ganhar ',
        es: 'Ganar ',
        ru: 'Заработать ',
        fr: 'Gagner ',
        de: 'Verdienen '
    },
    '*Until you complete all steps to get Clients from TIDY, data on this page is estimated based on recent requests. We cannot determine the exact available leads until you have all your booking constraints and data.': {
        pt: '*Até que você complete todas as etapas para obter Clientes da TIDY, os dados nesta página são estimados com base em solicitações recentes. Não podemos determinar os leads disponíveis exatos até que você tenha todas as suas restrições de reserva e dados.',
        es: '*Hasta que complete todos los pasos para obtener Clientes de TIDY, los datos en esta página se estiman en función de solicitudes recientes. No podemos determinar los clientes potenciales exactos disponibles hasta que tenga todas sus restricciones de reserva y datos.',
        ru: '*Пока вы не завершите все шаги для получения клиентов от TIDY, данные на этой странице оцениваются на основе недавних запросов. Мы не можем определить точные доступные лиды, пока у вас не будет всех ваших ограничений и данных по бронированию.',
        fr: '*Jusqu\'à ce que vous ayez terminé toutes les étapes pour obtenir des clients de TIDY, les données de cette page sont estimées en fonction des demandes récentes. Nous ne pouvons pas déterminer les pistes exactes disponibles tant que vous n\'avez pas toutes vos contraintes de réservation et vos données.',
        de: '*Bis Sie alle Schritte abgeschlossen haben, um Kunden von TIDY zu erhalten, werden die Daten auf dieser Seite basierend auf den letzten Anfragen geschätzt. Wir können die genauen verfügbaren Leads nicht bestimmen, bis Sie alle Ihre Buchungseinschränkungen und Daten haben.'
    },
    'Finish Getting Started to Use This': {
        pt: 'Conclua a Introdução para Usar Isso',
        es: 'Termina de Empezar para Usar Esto',
        ru: 'Завершите Начало, чтобы Использовать Это',
        fr: 'Terminez le Démarrage pour Utiliser Cela',
        de: 'Beenden Sie die Einführung, um dies zu verwenden'
    },
    'Want to get Clients from TIDY?  First you\'ll need to get certified to get Clients from TIDY.  It\'s quick, easy and free!': {
        pt: 'Quer obter Clientes da TIDY? Primeiro você precisará se certificar para obter Clientes da TIDY. É rápido, fácil e gratuito!',
        es: '¿Quieres conseguir Clientes de TIDY? Primero necesitas certificarte para conseguir Clientes de TIDY. ¡Es rápido, fácil y gratis!',
        ru: 'Хотите получить клиентов от TIDY? Сначала вам нужно пройти сертификацию, чтобы получить клиентов от TIDY. Это быстро, легко и бесплатно!',
        fr: 'Vous voulez obtenir des clients de TIDY ? Vous devez d\'abord être certifié pour obtenir des clients de TIDY. C\'est rapide, facile et gratuit !',
        de: 'Möchten Sie Kunden von TIDY bekommen? Zuerst müssen Sie sich zertifizieren lassen, um Kunden von TIDY zu bekommen. Es ist schnell, einfach und kostenlos!'
    },
    'View Getting Started Steps in the Dashboard': {
        pt: 'Veja as Etapas de Introdução no Painel',
        es: 'Ver los Pasos para Empezar en el Tablero',
        ru: 'Просмотр шагов начала работы на панели управления',
        fr: 'Voir les étapes de démarrage dans le tableau de bord',
        de: 'Sehen Sie sich die ersten Schritte im Dashboard an'
    },
    'Decline Request?': {
        pt: 'Recusar Solicitação?',
        es: '¿Rechazar Solicitud?',
        ru: 'Отклонить запрос?',
        fr: 'Refuser la demande ?',
        de: 'Anfrage ablehnen?'
    },
    'Go Back': {
        pt: 'Voltar',
        es: 'Regresar',
        ru: 'Назад',
        fr: 'Retourner',
        de: 'Zurück'
    },
    'Request Declined': {
        pt: 'Solicitação Recusada',
        es: 'Solicitud Rechazada',
        ru: 'Запрос отклонен',
        fr: 'Demande refusée',
        de: 'Anfrage abgelehnt'
    },
    'We have sent a notification to this Client.': {
        pt: 'Enviamos uma notificação para este Cliente.',
        es: 'Hemos enviado una notificación a este Cliente.',
        ru: 'Мы отправили уведомление этому клиенту.',
        fr: 'Nous avons envoyé une notification à ce client.',
        de: 'Wir haben eine Benachrichtigung an diesen Kunden gesendet.'
    },
    'Cancel Request?': {
        pt: 'Cancelar Solicitação?',
        es: '¿Cancelar Solicitud?',
        ru: 'Отменить запрос?',
        fr: 'Annuler la demande ?',
        de: 'Anfrage stornieren?'
    },
    'Cancel Request': {
        pt: 'Cancelar Solicitação',
        es: 'Cancelar Solicitud',
        ru: 'Отменить запрос',
        fr: 'Annuler la demande',
        de: 'Anfrage stornieren'
    },
    'Request Cancelled': {
        pt: 'Solicitação Cancelada',
        es: 'Solicitud Cancelada',
        ru: 'Запрос отменен',
        fr: 'Demande annulée',
        de: 'Anfrage storniert'
    },

    'This Client needs you for their cleaning.': {
        pt: 'Este cliente precisa de você para sua limpeza.',
        es: 'Este cliente te necesita para su limpieza.',
        ru: 'Этому клиенту вам нужно для его уборки.',
        fr: 'Ce client a besoin de vous pour leur nettoyage.',
        de: 'Dieser Kunde braucht Sie für ihre Reinigung.'
    },
    'This Client needs you for their recurring cleaning.': {
        pt: 'Este cliente precisa de você para sua limpeza recorrente.',
        es: 'Este cliente te necesita para su limpieza recurrente.',
        ru: 'Этому клиенту вам нужно для его регулярной уборки.',
        fr: 'Ce client a besoin de vous pour leur nettoyage récurrent.',
        de: 'Dieser Kunde braucht Sie für ihre regelmäßige Reinigung.'
    },
    'This is a <u>standby opportunity</u>, which means there is about a 50% chance you will be needed. Assume the job will happen and go to it. You will receive the Booking Protection Minimum Payment': {
        pt: 'Esta é uma <u>oportunidade de espera</u>, o que significa que há cerca de 50% de chance de você ser necessário. Assuma que o trabalho acontecerá e vá até ele. Você receberá o Pagamento Mínimo de Proteção de Reserva',
        es: 'Esta es una <u>oportunidad de espera</u>, lo que significa que hay aproximadamente un 50% de probabilidad de que te necesiten. Asume que el trabajo sucederá y ve a él. Recibirás el Pago Mínimo de Protección de Reserva',
        ru: 'Это <u>возможность ожидания</u>, что означает, что есть около 50% шансов, что вы понадобитесь. Предположите, что работа состоится, и идите на нее. Вы получите минимальную оплату за защиту бронирования',
        fr: 'Ceci est une <u>opportunité de réserve</u>, ce qui signifie qu\'il y a environ 50% de chances que vous soyez nécessaire. Supposons que le travail aura lieu et allez-y. Vous recevrez le paiement minimum de protection de réservation',
        de: 'Dies ist eine <u>Standby-Möglichkeit</u>, was bedeutet, dass es etwa 50% Wahrscheinlichkeit gibt, dass Sie benötigt werden. Gehen Sie davon aus, dass der Job stattfinden wird, und gehen Sie hin. Sie erhalten die Mindestzahlung für Buchungsschutz'
    },
    ') if you are not needed. ': {
        pt: ') se você não for necessário. ',
        es: ') si no eres necesario. ',
        ru: ') если вы не нужны. ',
        fr: ') si vous n\'êtes pas nécessaire. ',
        de: ') wenn Sie nicht benötigt werden. '
    },
    'Learn More': {
        pt: 'Saiba mais',
        es: 'Aprende más',
        ru: 'Узнать больше',
        fr: 'En savoir plus',
        de: 'Erfahren Sie mehr'
    },
    ' on ': {
        pt: ' em ',
        es: ' en ',
        ru: ' на ',
        fr: ' sur ',
        de: ' auf '
    },
    'Blocking ': {
        pt: 'Bloqueando ',
        es: 'Bloqueando ',
        ru: 'Блокировка ',
        fr: 'Blocage ',
        de: 'Blockieren '
    },
    ' hour on calendar': {
        pt: ' hora no calendário',
        es: ' hora en el calendario',
        ru: ' час в календаре',
        fr: ' heure sur le calendrier',
        de: ' Stunde im Kalender'
    },
    ' hours on calendar': {
        pt: ' horas no calendário',
        es: ' horas en el calendario',
        ru: ' часов в календаре',
        fr: ' heures sur le calendrier',
        de: ' Stunden im Kalender'
    },
    ' Regular Cleaning': {
        pt: ' Limpeza Regular',
        es: ' Limpieza Regular',
        ru: ' Регулярная уборка',
        fr: ' Nettoyage régulier',
        de: ' Regelmäßige Reinigung'
    },
    'Females Only': {
        pt: 'Apenas Mulheres',
        es: 'Solo Mujeres',
        ru: 'Только женщины',
        fr: 'Femmes seulement',
        de: 'Nur Frauen'
    },
    'Males Only': {
        pt: 'Apenas Homens',
        es: 'Solo Hombres',
        ru: 'Только мужчины',
        fr: 'Hommes seulement',
        de: 'Nur Männer'
    },
    'Zip: ': {
        pt: 'CEP: ',
        es: 'Código Postal: ',
        ru: 'Почтовый индекс: ',
        fr: 'Code postal: ',
        de: 'Postleitzahl: '
    },
    'Lower your public rates to allow clients to automatically book you at these rates in the future.': {
        pt: 'Reduza suas tarifas públicas para permitir que os clientes reservem você automaticamente a essas tarifas no futuro.',
        es: 'Reduzca sus tarifas públicas para permitir que los clientes lo reserven automáticamente a estas tarifas en el futuro.',
        ru: 'Снизьте свои публичные ставки, чтобы клиенты могли автоматически бронировать вас по этим ставкам в будущем.',
        fr: 'Réduisez vos tarifs publics pour permettre aux clients de vous réserver automatiquement à ces tarifs à l\'avenir.',
        de: 'Senken Sie Ihre öffentlichen Tarife, damit Kunden Sie in Zukunft automatisch zu diesen Tarifen buchen können.'
    },
    
    
    '*This is a private job so you can edit the price after accepting.': {
        pt: '*Este é um trabalho privado, então você pode editar o preço após aceitar.',
        es: '*Este es un trabajo privado, por lo que puede editar el precio después de aceptar.',
        ru: '*Это частная работа, поэтому вы можете изменить цену после принятия.',
        fr: '*Ceci est un travail privé, vous pouvez donc modifier le prix après acceptation.',
        de: '*Dies ist ein privater Auftrag, daher können Sie den Preis nach der Annahme bearbeiten.'
    },
    'Select which start time you want:': {
        pt: 'Selecione o horário de início desejado:',
        es: 'Seleccione la hora de inicio que desea:',
        ru: 'Выберите время начала:',
        fr: 'Sélectionnez l\'heure de début souhaitée :',
        de: 'Wählen Sie die gewünschte Startzeit:'
    },
    'Standby': {
        pt: 'Em espera',
        es: 'En espera',
        ru: 'Режим ожидания',
        fr: 'En attente',
        de: 'Bereitschaft'
    },
    'Job Note': {
        pt: 'Nota de Trabalho',
        es: 'Nota de Trabajo',
        ru: 'Заметка о работе',
        fr: 'Note de travail',
        de: 'Arbeitsnotiz'
    },
    ' also requested ': {
        pt: ' também solicitou ',
        es: ' también solicitó ',
        ru: ' также запросил ',
        fr: ' a également demandé ',
        de: ' hat auch angefordert '
    },
    ' other jobs. ': {
        pt: ' outros trabalhos. ',
        es: ' otros trabajos. ',
        ru: ' другие работы. ',
        fr: ' autres travaux. ',
        de: ' andere Arbeiten. '
    },
    'View': {
        pt: 'Ver',
        es: 'Ver',
        ru: 'Просмотр',
        fr: 'Voir',
        de: 'Ansehen'
    },
    'If Client cancels, find me a new Client during this time or earn ': {
        pt: 'Se o cliente cancelar, encontre-me um novo cliente durante este tempo ou ganhe ',
        es: 'Si el cliente cancela, encuéntrame un nuevo cliente durante este tiempo o gana ',
        ru: 'Если клиент отменяет, найдите мне нового клиента в это время или заработайте ',
        fr: 'Si le client annule, trouvez-moi un nouveau client pendant ce temps ou gagnez ',
        de: 'Wenn der Kunde storniert, finden Sie mir einen neuen Kunden in dieser Zeit oder verdienen Sie '
    },
    'Please take ': {
        pt: 'Por favor, leve ',
        es: 'Por favor, tome ',
        ru: 'Пожалуйста, возьмите ',
        fr: 'Veuillez prendre ',
        de: 'Bitte nehmen Sie '
    },
    ' to review.': {
        pt: ' para revisar.',
        es: ' para revisar.',
        ru: ' для проверки.',
        fr: ' pour réviser.',
        de: ' zur Überprüfung.'
    },
    'Add Plan': {
        pt: 'Adicionar Plano',
        es: 'Agregar Plan',
        ru: 'Добавить план',
        fr: 'Ajouter un plan',
        de: 'Plan hinzufügen'
    },
    'Makes you bookable ': {
        pt: 'Torna você reservável ',
        es: 'Te hace reservable ',
        ru: 'Делает вас доступным для бронирования ',
        fr: 'Vous rend réservable ',
        de: 'Macht Sie buchbar '
    },
    'Keep this time bookable 4 weeks to keep the bonus. $': {
        pt: 'Mantenha este horário reservável por 4 semanas para manter o bônus. $',
        es: 'Mantenga este tiempo reservable 4 semanas para mantener el bono. $',
        ru: 'Держите это время доступным для бронирования 4 недели, чтобы сохранить бонус. $',
        fr: 'Gardez ce créneau réservable pendant 4 semaines pour conserver le bonus. $',
        de: 'Halten Sie diese Zeit 4 Wochen buchbar, um den Bonus zu behalten. $'
    },
    ' bonus paid with first job only.': {
        pt: ' bônus pago apenas com o primeiro trabalho.',
        es: ' bono pagado solo con el primer trabajo.',
        ru: ' бонус выплачивается только с первой работой.',
        fr: ' bonus payé uniquement avec le premier travail.',
        de: ' Bonus nur mit dem ersten Job bezahlt.'
    },
    'Claiming this opportunity will make you bookable ': {
        pt: 'Aproveitar esta oportunidade tornará você reservável ',
        es: 'Aprovechar esta oportunidad te hará reservable ',
        ru: 'Заявка на эту возможность сделает вас доступным для бронирования ',
        fr: 'Saisir cette opportunité vous rendra réservable ',
        de: 'Die Inanspruchnahme dieser Gelegenheit macht Sie buchbar '
    },
    ' from ': {
        pt: ' de ',
        es: ' de ',
        ru: ' с ',
        fr: ' de ',
        de: ' von '
    },
    ' to ': {
        pt: ' para ',
        es: ' a ',
        ru: ' до ',
        fr: ' à ',
        de: ' bis '
    },
    'You will be paid the ': {
        pt: 'Você será pago o ',
        es: 'Se le pagará el ',
        ru: 'Вам будет выплачен ',
        fr: 'Vous serez payé le ',
        de: 'Sie werden bezahlt '
    },
    'bonus if you complete the ': {
        pt: 'bônus se você completar o ',
        es: 'bono si completa el ',
        ru: 'бонус, если вы завершите ',
        fr: 'bonus si vous complétez le ',
        de: 'Bonus, wenn Sie das abschließen '
    },
    ' cleaning or the Client cancels after 8pm the night before.': {
        pt: ' limpeza ou o cliente cancelar após as 20h da noite anterior.',
        es: ' limpieza o el cliente cancela después de las 8pm de la noche anterior.',
        ru: ' уборку или клиент отменяет после 20:00 предыдущего вечера.',
        fr: ' nettoyage ou si le client annule après 20h la veille.',
        de: ' Reinigung oder der Kunde storniert nach 20 Uhr am Vorabend.'
    },
    'To keep this bonus you must <b>remain bookable</b> from ': {
        pt: 'Para manter este bônus, você deve <b>permanecer reservável</b> de ',
        es: 'Para mantener este bono, debe <b>permanecer reservable</b> de ',
        ru: 'Чтобы сохранить этот бонус, вы должны <b>оставаться доступным для бронирования</b> с ',
        fr: 'Pour conserver ce bonus, vous devez <b>rester réservable</b> de ',
        de: 'Um diesen Bonus zu behalten, müssen Sie <b>buchbar bleiben</b> von '
    },
    ' on:': {
        pt: ' em:',
        es: ' en:',
        ru: ' на:',
        fr: ' sur:',
        de: ' am:'
    },
    'You will lose the bonus if you are unbookable for this time on any of those days. ': {
        pt: 'Você perderá o bônus se não estiver reservável para este horário em qualquer um desses dias. ',
        es: 'Perderá el bono si no está reservable para este horario en cualquiera de esos días. ',
        ru: 'Вы потеряете бонус, если не будете доступны для бронирования в это время в любой из этих дней. ',
        fr: 'Vous perdrez le bonus si vous n\'êtes pas réservable pour ce créneau à l\'un de ces jours. ',
        de: 'Sie verlieren den Bonus, wenn Sie zu dieser Zeit an einem dieser Tage nicht buchbar sind. '
    },

    ' (preferred)': {
        pt: ' (preferido)',
        es: ' (preferido)',
        ru: ' (предпочтительно)',
        fr: ' (préféré)',
        de: ' (bevorzugt)'
    },
    'Are you sure you want to decline this job?': {
        pt: 'Tem certeza de que deseja recusar este trabalho?',
        es: '¿Está seguro de que desea rechazar este trabajo?',
        ru: 'Вы уверены, что хотите отказаться от этой работы?',
        fr: 'Êtes-vous sûr de vouloir refuser ce travail?',
        de: 'Sind Sie sicher, dass Sie diesen Job ablehnen möchten?'
    },
    'Job Added': {
        pt: 'Trabalho Adicionado',
        es: 'Trabajo Añadido',
        ru: 'Работа добавлена',
        fr: 'Travail ajouté',
        de: 'Job hinzugefügt'
    },
    'Nice work! You just booked with your private client.': {
        pt: 'Bom trabalho! Você acabou de reservar com seu cliente privado.',
        es: '¡Buen trabajo! Acabas de reservar con tu cliente privado.',
        ru: 'Отличная работа! Вы только что забронировали встречу с вашим частным клиентом.',
        fr: 'Bon travail! Vous venez de réserver avec votre client privé.',
        de: 'Gute Arbeit! Sie haben gerade mit Ihrem Privatkunden gebucht.'
    },
    'Nice work! You just gained a new client.': {
        pt: 'Bom trabalho! Você acabou de ganhar um novo cliente.',
        es: '¡Buen trabajo! Acabas de ganar un nuevo cliente.',
        ru: 'Отличная работа! Вы только что приобрели нового клиента.',
        fr: 'Bon travail! Vous venez de gagner un nouveau client.',
        de: 'Gute Arbeit! Sie haben gerade einen neuen Kunden gewonnen.'
    },
    'Opportunity No Longer Available': {
        pt: 'Oportunidade Não Disponível',
        es: 'Oportunidad Ya No Disponible',
        ru: 'Возможность больше недоступна',
        fr: 'Opportunité non disponible',
        de: 'Gelegenheit nicht mehr verfügbar'
    },
    'Unfortunately this opportunity was taken by someone else.  They can be quite competitive.': {
        pt: 'Infelizmente, essa oportunidade foi aproveitada por outra pessoa. Eles podem ser bastante competitivos.',
        es: 'Desafortunadamente, esta oportunidad fue tomada por otra persona. Pueden ser bastante competitivos.',
        ru: 'К сожалению, эту возможность использовал кто-то другой. Они могут быть довольно конкурентоспособными.',
        fr: 'Malheureusement, cette opportunité a été prise par quelqu\'un d\'autre. Ils peuvent être assez compétitifs.',
        de: 'Leider wurde diese Gelegenheit von jemand anderem genutzt. Sie können ziemlich wettbewerbsfähig sein.'
    },
    'Your repeat Client ': {
        pt: 'Seu cliente recorrente ',
        es: 'Tu cliente recurrente ',
        ru: 'Ваш постоянный клиент ',
        fr: 'Votre client régulier ',
        de: 'Ihr wiederkehrender Kunde '
    },
    ' at ': {
        pt: ' às ',
        es: ' a las ',
        ru: ' в ',
        fr: ' à ',
        de: ' um '
    },
    ', requests you specifically for ': {
        pt: ', solicita especificamente para ',
        es: ', te solicita específicamente para ',
        ru: ', запрашивает вас специально для ',
        fr: ', vous demande spécifiquement pour ',
        de: ', fordert Sie speziell für '
    },
    ' You will be paid ': {
        pt: ' Você será pago ',
        es: ' Se te pagará ',
        ru: ' Вам заплатят ',
        fr: ' Vous serez payé ',
        de: ' Sie werden bezahlt '
    },
    ' total.': {
        pt: ' total.',
        es: ' total.',
        ru: ' всего.',
        fr: ' total.',
        de: ' insgesamt.'
    },
    'Tap a time to accept:': {
        pt: 'Toque em um horário para aceitar:',
        es: 'Toca una hora para aceptar:',
        ru: 'Нажмите на время, чтобы принять:',
        fr: 'Appuyez sur une heure pour accepter:',
        de: 'Tippen Sie auf eine Uhrzeit, um zu akzeptieren:'
    },
    'Get New Clients': {
        pt: 'Obter Novos Clientes',
        es: 'Conseguir Nuevos Clientes',
        ru: 'Получить новых клиентов',
        fr: 'Obtenir de nouveaux clients',
        de: 'Neue Kunden gewinnen'
    },

    ' needs a job during these times:': {
        pt: ' precisa de um trabalho durante esses horários:',
        es: ' necesita un trabajo durante estos horarios:',
        ru: ' нуждается в работе в это время:',
        fr: ' a besoin d\'un travail pendant ces heures:',
        de: ' braucht einen Job zu diesen Zeiten:'
    },
    'Recurring Plan': {
        pt: 'Plano Recorrente',
        es: 'Plan Recurrente',
        ru: 'Повторяющийся план',
        fr: 'Plan Récurrent',
        de: 'Wiederkehrender Plan'
    },
    'Clients from TIDY Settings': {
        pt: 'Configurações de Clientes da TIDY',
        es: 'Configuraciones de Clientes de TIDY',
        ru: 'Настройки клиентов TIDY',
        fr: 'Paramètres des Clients de TIDY',
        de: 'Einstellungen von TIDY-Kunden'
    },
    'Client Fees & Booking Protection': {
        pt: 'Taxas de Cliente e Proteção de Reserva',
        es: 'Tarifas de Cliente y Protección de Reserva',
        ru: 'Сборы клиентов и защита бронирования',
        fr: 'Frais de Client et Protection de Réservation',
        de: 'Kundenentgelte und Buchungsschutz'
    },
    'Notification Settings': {
        pt: 'Configurações de Notificação',
        es: 'Configuraciones de Notificación',
        ru: 'Настройки уведомлений',
        fr: 'Paramètres de Notification',
        de: 'Benachrichtigungseinstellungen'
    },
    'Your Booking Settings': {
        pt: 'Suas Configurações de Reserva',
        es: 'Tus Configuraciones de Reserva',
        ru: 'Ваши настройки бронирования',
        fr: 'Vos Paramètres de Réservation',
        de: 'Ihre Buchungseinstellungen'
    },
    'Review Terms of Use': {
        pt: 'Revisar Termos de Uso',
        es: 'Revisar Términos de Uso',
        ru: 'Просмотр условий использования',
        fr: 'Revoir les Conditions d\'Utilisation',
        de: 'Nutzungsbedingungen überprüfen'
    },
    'You are only paired with Clients from TIDY that match your booking restrictions. Less restrictions usually means more Clients.': {
        pt: 'Você só é emparelhado com Clientes da TIDY que correspondem às suas restrições de reserva. Menos restrições geralmente significam mais Clientes.',
        es: 'Solo se te empareja con Clientes de TIDY que coinciden con tus restricciones de reserva. Menos restricciones generalmente significan más Clientes.',
        ru: 'Вы связаны только с клиентами TIDY, которые соответствуют вашим ограничениям бронирования. Меньше ограничений обычно означает больше клиентов.',
        fr: 'Vous êtes uniquement jumelé avec des Clients de TIDY qui correspondent à vos restrictions de réservation. Moins de restrictions signifient généralement plus de Clients.',
        de: 'Sie werden nur mit TIDY-Kunden gepaart, die Ihren Buchungsbeschränkungen entsprechen. Weniger Einschränkungen bedeuten in der Regel mehr Kunden.'
    },
    'Payment Processing': {
        pt: 'Processamento de Pagamento',
        es: 'Procesamiento de Pago',
        ru: 'Обработка платежей',
        fr: 'Traitement des Paiements',
        de: 'Zahlungsabwicklung'
    },
    'Require only clients that do not use TIDY Payment Processing': {
        pt: 'Exigir apenas clientes que não utilizam o Processamento de Pagamento TIDY',
        es: 'Requerir solo clientes que no usen el Procesamiento de Pago TIDY',
        ru: 'Требовать только клиентов, которые не используют обработку платежей TIDY',
        fr: 'Exiger uniquement des clients qui n\'utilisent pas le Traitement des Paiements TIDY',
        de: 'Nur Kunden zulassen, die die Zahlungsabwicklung von TIDY nicht nutzen'
    },
    'Cancellation Policy': {
        pt: 'Política de Cancelamento',
        es: 'Política de Cancelación',
        ru: 'Политика отмены',
        fr: 'Politique d\'Annulation',
        de: 'Stornierungsrichtlinie'
    },
    'Require cancellation policy from Clients': {
        pt: 'Exigir política de cancelamento dos Clientes',
        es: 'Requerir política de cancelación de los Clientes',
        ru: 'Требовать политику отмены от клиентов',
        fr: 'Exiger une politique d\'annulation des Clients',
        de: 'Stornierungsrichtlinie von Kunden verlangen'
    },
    'Default Contract': {
        pt: 'Contrato Padrão',
        es: 'Contrato Predeterminado',
        ru: 'Контракт по умолчанию',
        fr: 'Contrat par Défaut',
        de: 'Standardvertrag'
    },
    'Your default contract for your clients unless otherwise agreed in writing:': {
        pt: 'Seu contrato padrão para seus clientes, a menos que acordado de outra forma por escrito:',
        es: 'Tu contrato predeterminado para tus clientes, a menos que se acuerde lo contrario por escrito:',
        ru: 'Ваш контракт по умолчанию для ваших клиентов, если не согласовано иное в письменной форме:',
        fr: 'Votre contrat par défaut pour vos clients, sauf accord écrit contraire:',
        de: 'Ihr Standardvertrag für Ihre Kunden, sofern nicht schriftlich anders vereinbart:'
    },
    'Optional Service Contract Terms': {
        pt: 'Termos Opcionais do Contrato de Serviço',
        es: 'Términos Opcionales del Contrato de Servicio',
        ru: 'Условия необязательного контракта на обслуживание',
        fr: 'Conditions Optionnelles du Contrat de Service',
        de: 'Optionale Vertragsbedingungen für Dienstleistungen'
    },
    'View Your Current Default': {
        pt: 'Ver Seu Padrão Atual',
        es: 'Ver Tu Predeterminado Actual',
        ru: 'Просмотр вашего текущего по умолчанию',
        fr: 'Voir Votre Défaut Actuel',
        de: 'Aktuellen Standard anzeigen'
    },
    'Upload a Contract': {
        pt: 'Carregar um Contrato',
        es: 'Subir un Contrato',
        ru: 'Загрузить контракт',
        fr: 'Télécharger un Contrat',
        de: 'Vertrag hochladen'
    },
    'Dispute Resolution': {
        pt: 'Resolução de Disputas',
        es: 'Resolución de Disputas',
        ru: 'Разрешение споров',
        fr: 'Résolution des Conflits',
        de: 'Streitbeilegung'
    },
    'Allow TIDY Informal Dispute Resolution': {
        pt: 'Permitir Resolução Informal de Disputas TIDY',
        es: 'Permitir Resolución Informal de Disputas TIDY',
        ru: 'Разрешить неформальное разрешение споров TIDY',
        fr: 'Permettre la Résolution Informelle des Conflits TIDY',
        de: 'Informelle Streitbeilegung von TIDY zulassen'
    },
    'Discounts': {
        pt: 'Descontos',
        es: 'Descuentos',
        ru: 'Скидки',
        fr: 'Réductions',
        de: 'Rabatte'
    },
    'Provide discount for new Clients ': {
        pt: 'Fornecer desconto para novos Clientes ',
        es: 'Proporcionar descuento para nuevos Clientes ',
        ru: 'Предоставить скидку для новых клиентов ',
        fr: 'Fournir une réduction pour les nouveaux Clients ',
        de: 'Rabatt für neue Kunden gewähren '
    },
    'Edit Discount Amount': {
        pt: 'Editar Valor do Desconto',
        es: 'Editar Monto del Descuento',
        ru: 'Изменить сумму скидки',
        fr: 'Modifier le Montant de la Réduction',
        de: 'Rabattbetrag bearbeiten'
    },
    'Rate Commitment': {
        pt: 'Compromisso de Taxa',
        es: 'Compromiso de Tarifa',
        ru: 'Обязательство по ставке',
        fr: 'Engagement de Taux',
        de: 'Tarifverpflichtung'
    },
    'Length of time you honor your rate with clients: 1 year ': {
        pt: 'Duração do tempo que você honra sua taxa com os clientes: 1 ano ',
        es: 'Duración del tiempo que honras tu tarifa con los clientes: 1 año ',
        ru: 'Срок, в течение которого вы соблюдаете свою ставку с клиентами: 1 год ',
        fr: 'Durée pendant laquelle vous honorez votre tarif avec les clients : 1 an ',
        de: 'Dauer, die Sie Ihren Tarif bei Kunden einhalten: 1 Jahr '
    },
    'Edit': {
        pt: 'Editar',
        es: 'Editar',
        ru: 'Редактировать',
        fr: 'Modifier',
        de: 'Bearbeiten'
    },
    'Location Tracking': {
        pt: 'Rastreamento de Localização',
        es: 'Seguimiento de Ubicación',
        ru: 'Отслеживание местоположения',
        fr: 'Suivi de Localisation',
        de: 'Standortverfolgung'
    },
    'Allow location tracking during jobs': {
        pt: 'Permitir rastreamento de localização durante os trabalhos',
        es: 'Permitir seguimiento de ubicación durante los trabajos',
        ru: 'Разрешить отслеживание местоположения во время работы',
        fr: 'Permettre le suivi de localisation pendant les travaux',
        de: 'Standortverfolgung während der Arbeit zulassen'
    },
    'Service Restrictions': {
        pt: 'Restrições de Serviço',
        es: 'Restricciones de Servicio',
        ru: 'Ограничения на услуги',
        fr: 'Restrictions de Service',
        de: 'Dienstbeschränkungen'
    },
    'Minimum Job Length': {
        pt: 'Duração Mínima do Trabalho',
        es: 'Duración Mínima del Trabajo',
        ru: 'Минимальная продолжительность работы',
        fr: 'Durée Minimale du Travail',
        de: 'Mindestarbeitsdauer'
    },
    'Maximum Job Length': {
        pt: 'Duração Máxima do Trabalho',
        es: 'Duración Máxima del Trabajo',
        ru: 'Максимальная продолжительность работы',
        fr: 'Durée Maximale du Travail',
        de: 'Maximale Arbeitsdauer'
    },
    'Save Service Restrictions': {
        pt: 'Salvar Restrições de Serviço',
        es: 'Guardar Restricciones de Servicio',
        ru: 'Сохранить ограничения на услуги',
        fr: 'Enregistrer les Restrictions de Service',
        de: 'Dienstbeschränkungen speichern'
    },
    'Allow simultaneous jobs at the same site with other Pros.': {
        pt: 'Permitir trabalhos simultâneos no mesmo local com outros profissionais.',
        es: 'Permitir trabajos simultáneos en el mismo sitio con otros profesionales.',
        ru: 'Разрешить одновременные работы на одном объекте с другими профессионалами.',
        fr: 'Permettre des travaux simultanés sur le même site avec d\'autres professionnels.',
        de: 'Gleichzeitige Arbeiten am selben Standort mit anderen Fachleuten zulassen.'
    },
    'Client Restrictions': {
        pt: 'Restrições de Cliente',
        es: 'Restricciones de Cliente',
        ru: 'Ограничения для клиентов',
        fr: 'Restrictions de Client',
        de: 'Kundenbeschränkungen'
    },
    'Allow residential Clients': {
        pt: 'Permitir Clientes residenciais',
        es: 'Permitir Clientes residenciales',
        ru: 'Разрешить жилых клиентов',
        fr: 'Permettre aux clients résidentiels',
        de: 'Wohnkunden zulassen'
    },
    'Allow rental / AirBnB Clients': {
        pt: 'Permitir Clientes de aluguel / AirBnB',
        es: 'Permitir Clientes de alquiler / AirBnB',
        ru: 'Разрешить клиентов аренды / AirBnB',
        fr: 'Permettre aux clients de location / AirBnB',
        de: 'Miet- / AirBnB-Kunden zulassen'
    },
    'Allow office Clients': {
        pt: 'Permitir Clientes de escritório',
        es: 'Permitir Clientes de oficina',
        ru: 'Разрешить офисных клиентов',
        fr: 'Permettre aux clients de bureau',
        de: 'Bürokunden zulassen'
    },
    'Allow repeat Clients': {
        pt: 'Permitir Clientes recorrentes',
        es: 'Permitir Clientes recurrentes',
        ru: 'Разрешить повторных клиентов',
        fr: 'Permettre aux clients récurrents',
        de: 'Wiederholungskunden zulassen'
    },
    'Allow new Clients': {
        pt: 'Permitir novos Clientes',
        es: 'Permitir nuevos Clientes',
        ru: 'Разрешить новых клиентов',
        fr: 'Permettre aux nouveaux clients',
        de: 'Neue Kunden zulassen'
    },
    'Pay Restrictions': {
        pt: 'Restrições de Pagamento',
        es: 'Restricciones de Pago',
        ru: 'Ограничения по оплате',
        fr: 'Restrictions de Paiement',
        de: 'Zahlungsbeschränkungen'
    },
    'View My Pay Rate': {
        pt: 'Ver Minha Taxa de Pagamento',
        es: 'Ver Mi Tasa de Pago',
        ru: 'Посмотреть мою ставку оплаты',
        fr: 'Voir Mon Taux de Paiement',
        de: 'Meine Zahlungsrate anzeigen'
    },
    'Only allow Clients that meet my pay rate.': {
        pt: 'Permitir apenas Clientes que atendam à minha taxa de pagamento.',
        es: 'Permitir solo Clientes que cumplan con mi tasa de pago.',
        ru: 'Разрешить только клиентов, которые соответствуют моей ставке оплаты.',
        fr: 'Permettre uniquement aux clients qui respectent mon taux de paiement.',
        de: 'Nur Kunden zulassen, die meine Zahlungsrate erfüllen.'
    },
    'Drive Restrictions': {
        pt: 'Restrições de Condução',
        es: 'Restricciones de Conducción',
        ru: 'Ограничения на вождение',
        fr: 'Restrictions de Conduite',
        de: 'Fahrbeschränkungen'
    },
    'View Drive Time': {
        pt: 'Ver Tempo de Condução',
        es: 'Ver Tiempo de Conducción',
        ru: 'Посмотреть время вождения',
        fr: 'Voir le Temps de Conduite',
        de: 'Fahrzeit anzeigen'
    },
    'Only allow Clients that are within my max drive time.': {
        pt: 'Permitir apenas Clientes que estejam dentro do meu tempo máximo de condução.',
        es: 'Permitir solo Clientes que estén dentro de mi tiempo máximo de conducción.',
        ru: 'Разрешить только клиентов, которые находятся в пределах моего максимального времени вождения.',
        fr: 'Permettre uniquement aux clients qui sont dans mon temps de conduite maximum.',
        de: 'Nur Kunden zulassen, die innerhalb meiner maximalen Fahrzeit liegen.'
    },
    'Other Restrictions': {
        pt: 'Outras Restrições',
        es: 'Otras Restricciones',
        ru: 'Другие ограничения',
        fr: 'Autres Restrictions',
        de: 'Andere Beschränkungen'
    },
    'Allow Clients that require me to use TIDY.com.': {
        pt: 'Permitir Clientes que exigem que eu use o TIDY.com.',
        es: 'Permitir Clientes que requieren que use TIDY.com.',
        ru: 'Разрешить клиентов, которые требуют, чтобы я использовал TIDY.com.',
        fr: 'Permettre aux clients qui exigent que j\'utilise TIDY.com.',
        de: 'Kunden zulassen, die verlangen, dass ich TIDY.com verwende.'
    },
    'Error Saving': {
        pt: 'Erro ao Salvar',
        es: 'Error al Guardar',
        ru: 'Ошибка сохранения',
        fr: 'Erreur de Sauvegarde',
        de: 'Fehler beim Speichern'
    },
    '7481 Error: Please contact us in the Concierge section for support.': {
        pt: 'Erro 7481: Entre em contato conosco na seção Concierge para suporte.',
        es: 'Error 7481: Póngase en contacto con nosotros en la sección de Concierge para obtener ayuda.',
        ru: 'Ошибка 7481: Пожалуйста, свяжитесь с нами в разделе Консьерж для поддержки.',
        fr: 'Erreur 7481 : Veuillez nous contacter dans la section Concierge pour obtenir de l\'aide.',
        de: 'Fehler 7481: Bitte kontaktieren Sie uns im Concierge-Bereich für Unterstützung.'
    },
    'Saving Changes...': {
        pt: 'Salvando Alterações...',
        es: 'Guardando Cambios...',
        ru: 'Сохранение изменений...',
        fr: 'Enregistrement des modifications...',
        de: 'Änderungen speichern...'
    },
    'All Changes Saved.': {
        pt: 'Todas as Alterações Salvas.',
        es: 'Todos los Cambios Guardados.',
        ru: 'Все изменения сохранены.',
        fr: 'Toutes les modifications enregistrées.',
        de: 'Alle Änderungen gespeichert.'
    },

    'Agreement Management': {
        pt: 'Gestão de Acordos',
        es: 'Gestión de Acuerdos',
        ru: 'Управление соглашениями',
        fr: 'Gestion des accords',
        de: 'Vereinbarungsmanagement'
    },
    'Agreements with TIDY': {
        pt: 'Acordos com TIDY',
        es: 'Acuerdos con TIDY',
        ru: 'Соглашения с TIDY',
        fr: 'Accords avec TIDY',
        de: 'Vereinbarungen mit TIDY'
    },
    'You have opted to not use a custom agreement, and are currently subject to the terms available at https://www.tidy.com/legal/service-provider. To request a pdf copy of this agreement, please contact us. To request a custom agreement, contact legal@tidy.com at this time.': {
        pt: 'Você optou por não usar um acordo personalizado e está atualmente sujeito aos termos disponíveis em https://www.tidy.com/legal/service-provider. Para solicitar uma cópia em pdf deste acordo, entre em contato conosco. Para solicitar um acordo personalizado, entre em contato com legal@tidy.com neste momento.',
        es: 'Ha optado por no usar un acuerdo personalizado y actualmente está sujeto a los términos disponibles en https://www.tidy.com/legal/service-provider. Para solicitar una copia en pdf de este acuerdo, contáctenos. Para solicitar un acuerdo personalizado, comuníquese con legal@tidy.com en este momento.',
        ru: 'Вы решили не использовать индивидуальное соглашение и в настоящее время подчиняетесь условиям, доступным на https://www.tidy.com/legal/service-provider. Чтобы запросить копию этого соглашения в формате pdf, свяжитесь с нами. Чтобы запросить индивидуальное соглашение, свяжитесь с legal@tidy.com в настоящее время.',
        fr: 'Vous avez choisi de ne pas utiliser un accord personnalisé et êtes actuellement soumis aux termes disponibles sur https://www.tidy.com/legal/service-provider. Pour demander une copie pdf de cet accord, veuillez nous contacter. Pour demander un accord personnalisé, contactez legal@tidy.com pour le moment.',
        de: 'Sie haben sich entschieden, kein individuelles Abkommen zu verwenden und unterliegen derzeit den Bedingungen, die unter https://www.tidy.com/legal/service-provider verfügbar sind. Um eine PDF-Kopie dieses Abkommens anzufordern, kontaktieren Sie uns bitte. Um ein individuelles Abkommen anzufordern, kontaktieren Sie legal@tidy.com zu diesem Zeitpunkt.'
    },
    'Agreements with Clients': {
        pt: 'Acordos com Clientes',
        es: 'Acuerdos con Clientes',
        ru: 'Соглашения с клиентами',
        fr: 'Accords avec les clients',
        de: 'Vereinbarungen mit Kunden'
    },
    'Manage your agreements with clients here': {
        pt: 'Gerencie seus acordos com clientes aqui',
        es: 'Gestione sus acuerdos con clientes aquí',
        ru: 'Управляйте своими соглашениями с клиентами здесь',
        fr: 'Gérez vos accords avec les clients ici',
        de: 'Verwalten Sie hier Ihre Vereinbarungen mit Kunden'
    },
    'Start by uploading a contract, then you can assign it to your clients, jobs and more.': {
        pt: 'Comece carregando um contrato, depois você pode atribuí-lo aos seus clientes, trabalhos e mais.',
        es: 'Comience subiendo un contrato, luego puede asignarlo a sus clientes, trabajos y más.',
        ru: 'Начните с загрузки контракта, затем вы можете назначить его своим клиентам, работам и многому другому.',
        fr: 'Commencez par télécharger un contrat, puis vous pouvez l\'attribuer à vos clients, travaux et plus encore.',
        de: 'Beginnen Sie mit dem Hochladen eines Vertrags, dann können Sie ihn Ihren Kunden, Aufträgen und mehr zuweisen.'
    },
    
    'You haven\'t updated your app in a while. Please update immediately as app versions are only supported for a limited time.': {
        pt: 'Você não atualiza seu aplicativo há um tempo. Atualize imediatamente, pois as versões do aplicativo são suportadas apenas por um tempo limitado.',
        es: 'No has actualizado tu aplicación en un tiempo. Actualiza inmediatamente, ya que las versiones de la aplicación solo se admiten por un tiempo limitado.',
        ru: 'Вы давно не обновляли свое приложение. Пожалуйста, обновите его немедленно, так как версии приложений поддерживаются только в течение ограниченного времени.',
        fr: 'Vous n\'avez pas mis à jour votre application depuis un certain temps. Veuillez mettre à jour immédiatement car les versions de l\'application ne sont prises en charge que pour une durée limitée.',
        de: 'Sie haben Ihre App schon lange nicht mehr aktualisiert. Bitte aktualisieren Sie sofort, da App-Versionen nur für eine begrenzte Zeit unterstützt werden.'
    },

    'Ready to Work?': {
        pt: 'Pronto para Trabalhar?',
        es: '¿Listo para Trabajar?',
        ru: 'Готовы к Работе?',
        fr: 'Prêt à Travailler?',
        de: 'Bereit zu Arbeiten?'
    },
    'For users in the US, a US identification document is required.': {
        pt: 'Para usuários nos EUA, é necessário um documento de identificação dos EUA.',
        es: 'Para usuarios en los EE. UU., se requiere un documento de identificación de los EE. UU.',
        ru: 'Для пользователей в США требуется удостоверение личности США.',
        fr: 'Pour les utilisateurs aux États-Unis, un document d\'identification américain est requis.',
        de: 'Für Benutzer in den USA ist ein US-Ausweisdokument erforderlich.'
    },
    '<b>Error: </b>': {
        pt: '<b>Erro: </b>',
        es: '<b>Error: </b>',
        ru: '<b>Ошибка: </b>',
        fr: '<b>Erreur: </b>',
        de: '<b>Fehler: </b>'
    },
    'Verify again.': {
        pt: 'Verifique novamente.',
        es: 'Verificar de nuevo.',
        ru: 'Проверьте снова.',
        fr: 'Vérifiez à nouveau.',
        de: 'Erneut überprüfen.'
    },
    'Photo issues? Skip & notify concierge': {
        pt: 'Problemas com foto? Pule e notifique o concierge',
        es: '¿Problemas con la foto? Omita y notifique al conserje',
        ru: 'Проблемы с фото? Пропустите и уведомите консьержа',
        fr: 'Problèmes de photo? Passez et informez le concierge',
        de: 'Foto-Probleme? Überspringen und Concierge benachrichtigen'
    },
    'Please confirm you can make your jobs:': {
        pt: 'Por favor, confirme que você pode fazer seus trabalhos:',
        es: 'Por favor confirme que puede hacer sus trabajos:',
        ru: 'Пожалуйста, подтвердите, что вы можете выполнить свои работы:',
        fr: 'Veuillez confirmer que vous pouvez effectuer vos travaux :',
        de: 'Bitte bestätigen Sie, dass Sie Ihre Arbeiten ausführen können:'
    },
    'I Will Go to All My Jobs Today': {
        pt: 'Eu Irei a Todos os Meus Trabalhos Hoje',
        es: 'Iré a Todos Mis Trabajos Hoy',
        ru: 'Я Пойду на Все Мои Работы Сегодня',
        fr: 'Je Vais à Tous Mes Travaux Aujourd\'hui',
        de: 'Ich Gehe Heute zu Allen Meinen Jobs'
    },
    'Call Out': {
        pt: 'Chamar',
        es: 'Llamar',
        ru: 'Вызвать',
        fr: 'Appeler',
        de: 'Rufen'
    },
    'These jobs require a quick identification check.': {
        pt: 'Esses trabalhos exigem uma verificação rápida de identificação.',
        es: 'Estos trabajos requieren una verificación rápida de identificación.',
        ru: 'Эти работы требуют быстрой проверки идентификации.',
        fr: 'Ces travaux nécessitent une vérification rapide de l\'identification.',
        de: 'Diese Arbeiten erfordern eine schnelle Identitätsprüfung.'
    },
    'All you need is your drivers license.': {
        pt: 'Tudo o que você precisa é da sua carteira de motorista.',
        es: 'Todo lo que necesitas es tu licencia de conducir.',
        ru: 'Все, что вам нужно, это ваши водительские права.',
        fr: 'Tout ce dont vous avez besoin, c\'est de votre permis de conduire.',
        de: 'Alles, was Sie brauchen, ist Ihr Führerschein.'
    },
    'Identification in progress': {
        pt: 'Identificação em andamento',
        es: 'Identificación en progreso',
        ru: 'Идентификация в процессе',
        fr: 'Identification en cours',
        de: 'Identifizierung läuft'
    },
    'This typically takes less than 1 minute.': {
        pt: 'Isso geralmente leva menos de 1 minuto.',
        es: 'Esto generalmente toma menos de 1 minuto.',
        ru: 'Это обычно занимает менее 1 минуты.',
        fr: 'Cela prend généralement moins d\'une minute.',
        de: 'Dies dauert in der Regel weniger als 1 Minute.'
    },
    'Verification Completed!': {
        pt: 'Verificação Concluída!',
        es: '¡Verificación Completada!',
        ru: 'Проверка Завершена!',
        fr: 'Vérification Terminée!',
        de: 'Überprüfung Abgeschlossen!'
    },
    'Your identity has been succesfully verified.': {
        pt: 'Sua identidade foi verificada com sucesso.',
        es: 'Su identidad ha sido verificada con éxito.',
        ru: 'Ваша личность успешно подтверждена.',
        fr: 'Votre identité a été vérifiée avec succès.',
        de: 'Ihre Identität wurde erfolgreich überprüft.'
    },
    'Face Unlock for Jobs.': {
        pt: 'Desbloqueio Facial para Trabalhos.',
        es: 'Desbloqueo Facial para Trabajos.',
        ru: 'Разблокировка Лица для Работ.',
        fr: 'Déverrouillage Facial pour les Travaux.',
        de: 'Gesichtsentsperrung für Jobs.'
    },
    'Unlock your jobs by taking a quick selfie.': {
        pt: 'Desbloqueie seus trabalhos tirando uma selfie rápida.',
        es: 'Desbloquea tus trabajos tomando una selfie rápida.',
        ru: 'Разблокируйте свои работы, сделав быстрое селфи.',
        fr: 'Déverrouillez vos travaux en prenant un selfie rapide.',
        de: 'Entsperren Sie Ihre Jobs, indem Sie ein schnelles Selfie machen.'
    },
    'You Must Be Online To Check In': {
        pt: 'Você Deve Estar Online Para Fazer Check-In',
        es: 'Debe Estar En Línea Para Registrarse',
        ru: 'Вы Должны Быть Онлайн, Чтобы Зарегистрироваться',
        fr: 'Vous Devez Être En Ligne Pour Vous Enregistrer',
        de: 'Sie Müssen Online Sein, Um Einzuchecken'
    },

    'Remember: Clients usually book based on your retention score. The best thing you can do to get more Clients is to keep existing Clients.<br><br> Clients tell us the most important reasons they will book with you again are:<br><br> 1) Keep your appointments<br> 2) Work hard the full job time<br> 3) Maintain a positive Attitude': {
        pt: 'Lembre-se: os clientes geralmente reservam com base na sua pontuação de retenção. A melhor coisa que você pode fazer para conseguir mais clientes é manter os clientes existentes.<br><br> Os clientes nos dizem que as razões mais importantes pelas quais eles reservarão com você novamente são:<br><br> 1) Mantenha seus compromissos<br> 2) Trabalhe duro durante todo o tempo de trabalho<br> 3) Mantenha uma atitude positiva',
        es: 'Recuerde: los clientes generalmente reservan en función de su puntaje de retención. Lo mejor que puede hacer para conseguir más clientes es mantener a los clientes existentes.<br><br> Los clientes nos dicen que las razones más importantes por las que volverán a reservar con usted son:<br><br> 1) Mantenga sus citas<br> 2) Trabaje duro todo el tiempo de trabajo<br> 3) Mantenga una actitud positiva',
        ru: 'Помните: клиенты обычно бронируют на основе вашего рейтинга удержания. Лучшее, что вы можете сделать, чтобы получить больше клиентов, - это сохранить существующих клиентов.<br><br> Клиенты говорят нам, что самые важные причины, по которым они снова забронируют у вас, следующие:<br><br> 1) Соблюдайте свои встречи<br> 2) Работайте усердно все рабочее время<br> 3) Сохраняйте позитивный настрой',
        fr: 'Rappelez-vous: les clients réservent généralement en fonction de votre score de rétention. La meilleure chose que vous puissiez faire pour obtenir plus de clients est de garder les clients existants.<br><br> Les clients nous disent que les raisons les plus importantes pour lesquelles ils réserveront à nouveau avec vous sont:<br><br> 1) Respectez vos rendez-vous<br> 2) Travaillez dur pendant tout le temps de travail<br> 3) Maintenez une attitude positive',
        de: 'Denken Sie daran: Kunden buchen normalerweise basierend auf Ihrer Bindungsrate. Das Beste, was Sie tun können, um mehr Kunden zu gewinnen, ist, bestehende Kunden zu halten.<br><br> Kunden sagen uns, dass die wichtigsten Gründe, warum sie wieder bei Ihnen buchen werden, folgende sind:<br><br> 1) Halten Sie Ihre Termine ein<br> 2) Arbeiten Sie die gesamte Arbeitszeit hart<br> 3) Behalten Sie eine positive Einstellung'
    },

    'Checked in for Work': {
        pt: 'Registrado para o Trabalho',
        es: 'Registrado para el Trabajo',
        ru: 'Зарегистрирован для Работы',
        fr: 'Enregistré pour le Travail',
        de: 'Für die Arbeit eingecheckt'
    },
    'Face Unlock Issues': {
        pt: 'Problemas com Desbloqueio Facial',
        es: 'Problemas con Desbloqueo Facial',
        ru: 'Проблемы с Разблокировкой Лица',
        fr: 'Problèmes de Déverrouillage Facial',
        de: 'Probleme mit der Gesichtserkennung'
    },
    'I am not able to take a photo of my face to unlock my jobs.': {
        pt: 'Não consigo tirar uma foto do meu rosto para desbloquear meus trabalhos.',
        es: 'No puedo tomar una foto de mi cara para desbloquear mis trabajos.',
        ru: 'Я не могу сделать фото своего лица, чтобы разблокировать свои работы.',
        fr: 'Je ne peux pas prendre une photo de mon visage pour déverrouiller mes travaux.',
        de: 'Ich kann kein Foto von meinem Gesicht machen, um meine Jobs zu entsperren.'
    },
    'Your issue has been reported. We will get back to you shortly.': {
        pt: 'Seu problema foi relatado. Entraremos em contato com você em breve.',
        es: 'Su problema ha sido reportado. Nos pondremos en contacto con usted en breve.',
        ru: 'Ваша проблема была сообщена. Мы свяжемся с вами в ближайшее время.',
        fr: 'Votre problème a été signalé. Nous vous contacterons sous peu.',
        de: 'Ihr Problem wurde gemeldet. Wir werden uns in Kürze bei Ihnen melden.'
    },
    'There was an error reporting your issue. Please try again later.': {
        pt: 'Houve um erro ao relatar seu problema. Por favor, tente novamente mais tarde.',
        es: 'Hubo un error al reportar su problema. Por favor, inténtelo de nuevo más tarde.',
        ru: 'Произошла ошибка при сообщении о вашей проблеме. Пожалуйста, попробуйте позже.',
        fr: 'Une erreur s\'est produite lors de la signalisation de votre problème. Veuillez réessayer plus tard.',
        de: 'Beim Melden Ihres Problems ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.'
    },

    'Call Out?': {
        pt: 'Chamada?',
        es: '¿Llamar?',
        ru: 'Вызов?',
        fr: 'Appel?',
        de: 'Rufen?'
    },
    'Select jobs to call out:': {
        pt: 'Selecione trabalhos para chamar:',
        es: 'Seleccione trabajos para llamar:',
        ru: 'Выберите задания для вызова:',
        fr: 'Sélectionnez les travaux à appeler:',
        de: 'Wählen Sie Jobs zum Aufrufen aus:'
    },
    ' hour': {
        pt: ' hora',
        es: ' hora',
        ru: ' час',
        fr: ' heure',
        de: ' Stunde'
    },
    ' hours': {
        pt: ' horas',
        es: ' horas',
        ru: ' часов',
        fr: ' heures',
        de: ' Stunden'
    },
    'Call Out: ': {
        pt: 'Chamada: ',
        es: 'Llamar: ',
        ru: 'Вызов: ',
        fr: 'Appel: ',
        de: 'Rufen: '
    },
    'Please select jobs to call out, or go back and check in.': {
        pt: 'Selecione trabalhos para chamar ou volte e faça o check-in.',
        es: 'Seleccione trabajos para llamar o regrese y regístrese.',
        ru: 'Выберите задания для вызова или вернитесь и зарегистрируйтесь.',
        fr: 'Veuillez sélectionner des travaux à appeler ou revenir et vous enregistrer.',
        de: 'Bitte wählen Sie Jobs zum Aufrufen aus oder gehen Sie zurück und checken Sie ein.'
    },
    'Canceling on clients is the most common way to lose them.': {
        pt: 'Cancelar com os clientes é a maneira mais comum de perdê-los.',
        es: 'Cancelar con los clientes es la forma más común de perderlos.',
        ru: 'Отмена у клиентов - самый распространенный способ их потерять.',
        fr: 'Annuler avec les clients est le moyen le plus courant de les perdre.',
        de: 'Das Absagen bei Kunden ist der häufigste Weg, sie zu verlieren.'
    },
    'Why are you cancelling?': {
        pt: 'Por que você está cancelando?',
        es: '¿Por qué estás cancelando?',
        ru: 'Почему вы отменяете?',
        fr: 'Pourquoi annulez-vous?',
        de: 'Warum stornieren Sie?'
    },
    'Please select a reason': {
        pt: 'Por favor, selecione um motivo',
        es: 'Por favor seleccione una razón',
        ru: 'Пожалуйста, выберите причину',
        fr: 'Veuillez sélectionner une raison',
        de: 'Bitte wählen Sie einen Grund'
    },
    'You Successfully Called Out': {
        pt: 'Você chamou com sucesso',
        es: 'Llamaste con éxito',
        ru: 'Вы успешно вызвали',
        fr: 'Vous avez appelé avec succès',
        de: 'Sie haben erfolgreich angerufen'
    },
    'Your Clients have been notified.': {
        pt: 'Seus clientes foram notificados.',
        es: 'Sus clientes han sido notificados.',
        ru: 'Ваши клиенты были уведомлены.',
        fr: 'Vos clients ont été informés.',
        de: 'Ihre Kunden wurden benachrichtigt.'
    },

    'Add your team to easily delegate jobs.': {
        pt: 'Adicione sua equipe para delegar trabalhos facilmente.',
        es: 'Agregue su equipo para delegar trabajos fácilmente.',
        ru: 'Добавьте свою команду, чтобы легко делегировать задания.',
        fr: 'Ajoutez votre équipe pour déléguer facilement des tâches.',
        de: 'Fügen Sie Ihr Team hinzu, um Aufgaben einfach zu delegieren.'
    },
    'Takes Just a Few Minutes': {
        pt: 'Leva apenas alguns minutos',
        es: 'Toma solo unos minutos',
        ru: 'Занимает всего несколько минут',
        fr: 'Cela prend juste quelques minutes',
        de: 'Dauert nur ein paar Minuten'
    },
    'The person you send it to just has to fill out some basic info.': {
        pt: 'A pessoa para quem você enviar só precisa preencher algumas informações básicas.',
        es: 'La persona a la que se lo envíe solo tiene que completar alguna información básica.',
        ru: 'Человеку, которому вы это отправите, нужно просто заполнить основную информацию.',
        fr: 'La personne à qui vous l\'envoyez doit simplement remplir quelques informations de base.',
        de: 'Die Person, an die Sie es senden, muss nur einige grundlegende Informationen ausfüllen.'
    },
    'Avoid Client Fees': {
        pt: 'Evite taxas de clientes',
        es: 'Evite tarifas de clientes',
        ru: 'Избегайте клиентских сборов',
        fr: 'Évitez les frais de client',
        de: 'Vermeiden Sie Kunden Gebühren'
    },
    'Avoiding client fees is the biggest factor in earning more.': {
        pt: 'Evitar taxas de clientes é o maior fator para ganhar mais.',
        es: 'Evitar tarifas de clientes es el mayor factor para ganar más.',
        ru: 'Избегание клиентских сборов - это самый важный фактор для увеличения заработка.',
        fr: 'Éviter les frais de client est le plus grand facteur pour gagner plus.',
        de: 'Das Vermeiden von Kunden Gebühren ist der größte Faktor, um mehr zu verdienen.'
    },
    'Keep Your Clients Happy': {
        pt: 'Mantenha seus clientes felizes',
        es: 'Mantén a tus clientes felices',
        ru: 'Держите своих клиентов счастливыми',
        fr: 'Gardez vos clients heureux',
        de: 'Halten Sie Ihre Kunden glücklich'
    },
    'Keep a strong relationship with your clients by never cancelling.': {
        pt: 'Mantenha um relacionamento forte com seus clientes, nunca cancelando.',
        es: 'Mantén una relación fuerte con tus clientes sin cancelar nunca.',
        ru: 'Поддерживайте крепкие отношения с клиентами, никогда не отменяя.',
        fr: 'Gardez une relation forte avec vos clients en ne les annulant jamais.',
        de: 'Halten Sie eine starke Beziehung zu Ihren Kunden, indem Sie niemals absagen.'
    },
    'Go Back, I Want to Delegate': {
        pt: 'Voltar, quero delegar',
        es: 'Volver, quiero delegar',
        ru: 'Вернуться, я хочу делегировать',
        fr: 'Retourner, je veux déléguer',
        de: 'Zurück, ich möchte delegieren'
    },
    'Delegate Jobs': {
        pt: 'Delegar trabalhos',
        es: 'Delegar trabajos',
        ru: 'Делегировать задания',
        fr: 'Déléguer des tâches',
        de: 'Aufgaben delegieren'
    },
    'Great!  You can delegate any jobs by tapping the job in the Jobs Page.  You\'ll need to check in for those jobs first.': {
        pt: 'Ótimo! Você pode delegar qualquer trabalho tocando no trabalho na página de trabalhos. Você precisará fazer check-in para esses trabalhos primeiro.',
        es: '¡Genial! Puede delegar cualquier trabajo tocando el trabajo en la página de trabajos. Primero deberá registrarse para esos trabajos.',
        ru: 'Отлично! Вы можете делегировать любые задания, нажав на задание на странице заданий. Сначала вам нужно зарегистрироваться для этих заданий.',
        fr: 'Super! Vous pouvez déléguer n\'importe quel travail en appuyant sur le travail dans la page des travaux. Vous devrez d\'abord vous enregistrer pour ces travaux.',
        de: 'Großartig! Sie können Aufgaben delegieren, indem Sie auf der Aufgaben-Seite auf die Aufgabe tippen. Sie müssen sich zuerst für diese Aufgaben einchecken.'
    },
    'Delegate Job Instead?': {
        pt: 'Delegar trabalho em vez disso?',
        es: '¿Delegar trabajo en su lugar?',
        ru: 'Делегировать задание вместо этого?',
        fr: 'Déléguer le travail à la place?',
        de: 'Stattdessen Aufgabe delegieren?'
    },
    'Delegate Jobs Instead?': {
        pt: 'Delegar trabalhos em vez disso?',
        es: '¿Delegar trabajos en su lugar?',
        ru: 'Делегировать задания вместо этого?',
        fr: 'Déléguer les tâches à la place?',
        de: 'Stattdessen Aufgaben delegieren?'
    },
    'delegate this job': {
        pt: 'delegar este trabalho',
        es: 'delegar este trabajo',
        ru: 'делегировать это задание',
        fr: 'déléguer ce travail',
        de: 'diese Aufgabe delegieren'
    },
    'delegate these jobs': {
        pt: 'delegar esses trabalhos',
        es: 'delegar estos trabajos',
        ru: 'делегировать эти задания',
        fr: 'déléguer ces travaux',
        de: 'diese Aufgaben delegieren'
    },

    'Messages': {
        pt: 'Mensagens',
        es: 'Mensajes',
        ru: 'Сообщения',
        fr: 'Messages',
        de: 'Nachrichten'
    },
    'Call': {
        pt: 'Chamada',
        es: 'Llamada',
        ru: 'Звонок',
        fr: 'Appel',
        de: 'Anruf'
    },
    'Send Message': {
        pt: 'Enviar mensagem',
        es: 'Enviar mensaje',
        ru: 'Отправить сообщение',
        fr: 'Envoyer le message',
        de: 'Nachricht senden'
    },
    'Please enter a message to send.': {
        pt: 'Por favor, insira uma mensagem para enviar.',
        es: 'Por favor, ingrese un mensaje para enviar.',
        ru: 'Пожалуйста, введите сообщение для отправки.',
        fr: 'Veuillez entrer un message à envoyer.',
        de: 'Bitte geben Sie eine Nachricht ein, um sie zu senden.'
    },
    'No Messages': {
        pt: 'Sem mensagens',
        es: 'Sin mensajes',
        ru: 'Нет сообщений',
        fr: 'Pas de messages',
        de: 'Keine Nachrichten'
    },
    'Job': {
        pt: 'Trabalho',
        es: 'Trabajo',
        ru: 'Задание',
        fr: 'Travail',
        de: 'Aufgabe'
    },
    'We couldn\'t send your message, please try again later!': {
        pt: 'Não conseguimos enviar sua mensagem, por favor, tente novamente mais tarde!',
        es: 'No pudimos enviar su mensaje, por favor intente nuevamente más tarde!',
        ru: 'Мы не смогли отправить ваше сообщение, пожалуйста, попробуйте позже!',
        fr: 'Nous n\'avons pas pu envoyer votre message, veuillez réessayer plus tard!',
        de: 'Wir konnten Ihre Nachricht nicht senden, bitte versuchen Sie es später noch einmal!'
    },
    'Unable to attach photo. Please upload a PNG or JPEG file.': {
        pt: 'Não foi possível anexar a foto. Por favor, carregue um arquivo PNG ou JPEG.',
        es: 'No se pudo adjuntar la foto. Por favor, suba un archivo PNG o JPEG.',
        ru: 'Не удалось прикрепить фото. Пожалуйста, загрузите файл PNG или JPEG.',
        fr: 'Impossible de joindre la photo. Veuillez télécharger un fichier PNG ou JPEG.',
        de: 'Foto konnte nicht angehängt werden. Bitte laden Sie eine PNG- oder JPEG-Datei hoch.'
    },

    'Payment Settings': {
        pt: 'Configurações de Pagamento',
        es: 'Configuraciones de Pago',
        ru: 'Настройки оплаты',
        fr: 'Paramètres de paiement',
        de: 'Zahlungseinstellungen'
    },
    'Payment Settings Confirmed': {
        pt: 'Configurações de Pagamento Confirmadas',
        es: 'Configuraciones de Pago Confirmadas',
        ru: 'Настройки оплаты подтверждены',
        fr: 'Paramètres de paiement confirmés',
        de: 'Zahlungseinstellungen bestätigt'
    },
    'Your settings below are confirmed but you can make changes if you\'d like.': {
        pt: 'Suas configurações abaixo estão confirmadas, mas você pode fazer alterações se desejar.',
        es: 'Sus configuraciones a continuación están confirmadas, pero puede hacer cambios si lo desea.',
        ru: 'Ваши настройки ниже подтверждены, но вы можете внести изменения, если хотите.',
        fr: 'Vos paramètres ci-dessous sont confirmés, mais vous pouvez apporter des modifications si vous le souhaitez.',
        de: 'Ihre untenstehenden Einstellungen sind bestätigt, aber Sie können Änderungen vornehmen, wenn Sie möchten.'
    },
    'Allow Clients to pay invoices with:': {
        pt: 'Permitir que os clientes paguem faturas com:',
        es: 'Permitir que los clientes paguen facturas con:',
        ru: 'Разрешить клиентам оплачивать счета с помощью:',
        fr: 'Permettre aux clients de payer les factures avec :',
        de: 'Kunden erlauben, Rechnungen zu bezahlen mit:'
    },
    '*Contact concierge@tidy.com or tap the concierge tab to request access to credit card processing. We will be happy to help enable this on your account as soon as possible': {
        pt: '*Entre em contato com concierge@tidy.com ou toque na guia concierge para solicitar acesso ao processamento de cartão de crédito. Ficaremos felizes em ajudar a habilitar isso em sua conta o mais rápido possível',
        es: '*Contacte a concierge@tidy.com o toque la pestaña de conserje para solicitar acceso al procesamiento de tarjetas de crédito. Estaremos encantados de ayudar a habilitar esto en su cuenta lo antes posible',
        ru: '*Свяжитесь с concierge@tidy.com или нажмите на вкладку консьержа, чтобы запросить доступ к обработке кредитных карт. Мы будем рады помочь включить это на вашем аккаунте как можно скорее',
        fr: '*Contactez concierge@tidy.com ou appuyez sur l\'onglet concierge pour demander l\'accès au traitement des cartes de crédit. Nous serons heureux de vous aider à activer cela sur votre compte dès que possible',
        de: '*Kontaktieren Sie concierge@tidy.com oder tippen Sie auf die Concierge-Registerkarte, um Zugang zur Kreditkartenverarbeitung zu beantragen. Wir helfen Ihnen gerne, dies so schnell wie möglich auf Ihrem Konto zu aktivieren'
    },
    'Add Payment Option': {
        pt: 'Adicionar Opção de Pagamento',
        es: 'Agregar Opción de Pago',
        ru: 'Добавить вариант оплаты',
        fr: 'Ajouter une option de paiement',
        de: 'Zahlungsoption hinzufügen'
    },

    'Add a new option for Clients to pay invoices.': {
        pt: 'Adicionar uma nova opção para os clientes pagarem faturas.',
        es: 'Agregar una nueva opción para que los clientes paguen facturas.',
        ru: 'Добавить новый вариант для клиентов, чтобы оплачивать счета.',
        fr: 'Ajouter une nouvelle option pour que les clients paient les factures.',
        de: 'Fügen Sie eine neue Option hinzu, damit Kunden Rechnungen bezahlen können.'
    },
    'Payment Option*': {
        pt: 'Opção de Pagamento*',
        es: 'Opción de Pago*',
        ru: 'Вариант оплаты*',
        fr: 'Option de paiement*',
        de: 'Zahlungsoption*'
    },
    'Please select a payment option.': {
        pt: 'Por favor, selecione uma opção de pagamento.',
        es: 'Por favor, seleccione una opción de pago.',
        ru: 'Пожалуйста, выберите вариант оплаты.',
        fr: 'Veuillez sélectionner une option de paiement.',
        de: 'Bitte wählen Sie eine Zahlungsoption.'
    },
    'Account Name': {
        pt: 'Nome da Conta',
        es: 'Nombre de la Cuenta',
        ru: 'Имя учетной записи',
        fr: 'Nom du compte',
        de: 'Kontoname'
    },
    'Notes': {
        pt: 'Notas',
        es: 'Notas',
        ru: 'Заметки',
        fr: 'Remarques',
        de: 'Notizen'
    },
    'Preferred payment option': {
        pt: 'Opção de pagamento preferida',
        es: 'Opción de pago preferida',
        ru: 'Предпочтительный вариант оплаты',
        fr: 'Option de paiement préférée',
        de: 'Bevorzugte Zahlungsoption'
    },
    'Option Added': {
        pt: 'Opção Adicionada',
        es: 'Opción Agregada',
        ru: 'Вариант добавлен',
        fr: 'Option ajoutée',
        de: 'Option hinzugefügt'
    },
    'Edit Payment Option': {
        pt: 'Editar Opção de Pagamento',
        es: 'Editar Opción de Pago',
        ru: 'Изменить вариант оплаты',
        fr: 'Modifier l\'option de paiement',
        de: 'Zahlungsoption bearbeiten'
    },
    'Payment Type*': {
        pt: 'Tipo de Pagamento*',
        es: 'Tipo de Pago*',
        ru: 'Тип оплаты*',
        fr: 'Type de paiement*',
        de: 'Zahlungsart*'
    },
    'Please select a payment type.': {
        pt: 'Por favor, selecione um tipo de pagamento.',
        es: 'Por favor, seleccione un tipo de pago.',
        ru: 'Пожалуйста, выберите тип оплаты.',
        fr: 'Veuillez sélectionner un type de paiement.',
        de: 'Bitte wählen Sie eine Zahlungsart.'
    },
    'Remove Payment Option': {
        pt: 'Remover Opção de Pagamento',
        es: 'Eliminar Opción de Pago',
        ru: 'Удалить вариант оплаты',
        fr: 'Supprimer l\'option de paiement',
        de: 'Zahlungsoption entfernen'
    },
    'Save Changes': {
        pt: 'Salvar Alterações',
        es: 'Guardar Cambios',
        ru: 'Сохранить изменения',
        fr: 'Enregistrer les modifications',
        de: 'Änderungen speichern'
    },
    'Changes Saved': {
        pt: 'Alterações Salvas',
        es: 'Cambios Guardados',
        ru: 'Изменения сохранены',
        fr: 'Modifications enregistrées',
        de: 'Änderungen gespeichert'
    },
    'Remove Payment Option?': {
        pt: 'Remover Opção de Pagamento?',
        es: '¿Eliminar Opción de Pago?',
        ru: 'Удалить вариант оплаты?',
        fr: 'Supprimer l\'option de paiement?',
        de: 'Zahlungsoption entfernen?'
    },
    'Remove Option': {
        pt: 'Remover Opção',
        es: 'Eliminar Opción',
        ru: 'Удалить вариант',
        fr: 'Supprimer l\'option',
        de: 'Option entfernen'
    },
    'Option Removed': {
        pt: 'Opção Removida',
        es: 'Opción Eliminada',
        ru: 'Вариант удален',
        fr: 'Option supprimée',
        de: 'Option entfernt'
    },

    'Your data will be kept private and your Clients will remain your Clients. ': {
        pt: 'Seus dados serão mantidos privados e seus Clientes continuarão sendo seus Clientes.',
        es: 'Sus datos se mantendrán privados y sus Clientes seguirán siendo sus Clientes.',
        ru: 'Ваши данные будут оставаться конфиденциальными, и ваши клиенты останутся вашими клиентами.',
        fr: 'Vos données resteront privées et vos Clients resteront vos Clients.',
        de: 'Ihre Daten bleiben privat und Ihre Kunden bleiben Ihre Kunden.'
    },
    'You haven\'t added any Clients.': {
        pt: 'Você não adicionou nenhum Cliente.',
        es: 'No has añadido ningún Cliente.',
        ru: 'Вы не добавили ни одного клиента.',
        fr: 'Vous n\'avez ajouté aucun Client.',
        de: 'Sie haben keine Kunden hinzugefügt.'
    },
    'Why Add Clients?': {
        pt: 'Por que Adicionar Clientes?',
        es: '¿Por qué Agregar Clientes?',
        ru: 'Зачем добавлять клиентов?',
        fr: 'Pourquoi Ajouter des Clients?',
        de: 'Warum Kunden hinzufügen?'
    },
    'Manage Bookings': {
        pt: 'Gerenciar Reservas',
        es: 'Gestionar Reservas',
        ru: 'Управление бронированиями',
        fr: 'Gérer les Réservations',
        de: 'Buchungen verwalten'
    },
    'You and your Clients can reschedule, add To-Dos, notes, and more.': {
        pt: 'Você e seus Clientes podem reagendar, adicionar tarefas, notas e mais.',
        es: 'Tú y tus Clientes pueden reprogramar, agregar tareas, notas y más.',
        ru: 'Вы и ваши клиенты можете перенести, добавить задачи, заметки и многое другое.',
        fr: 'Vous et vos Clients pouvez reprogrammer, ajouter des tâches, des notes et plus encore.',
        de: 'Sie und Ihre Kunden können Termine verschieben, Aufgaben, Notizen und mehr hinzufügen.'
    },
    'Collect Payment': {
        pt: 'Coletar Pagamento',
        es: 'Cobrar Pago',
        ru: 'Сбор платежей',
        fr: 'Collecter le Paiement',
        de: 'Zahlung einziehen'
    },
    'Send invoices so your Clients can easily pay in 1 tap.': {
        pt: 'Envie faturas para que seus Clientes possam pagar facilmente com um toque.',
        es: 'Envía facturas para que tus Clientes puedan pagar fácilmente con un toque.',
        ru: 'Отправляйте счета, чтобы ваши клиенты могли легко оплатить одним нажатием.',
        fr: 'Envoyez des factures pour que vos Clients puissent payer facilement en un seul clic.',
        de: 'Senden Sie Rechnungen, damit Ihre Kunden mit einem Klick bezahlen können.'
    },
    'Send Proposals': {
        pt: 'Enviar Propostas',
        es: 'Enviar Propuestas',
        ru: 'Отправить предложения',
        fr: 'Envoyer des Propositions',
        de: 'Vorschläge senden'
    },
    'Send Clients beautiful proposals they are more likely to accept.': {
        pt: 'Envie propostas bonitas para os Clientes que eles têm mais chances de aceitar.',
        es: 'Envía propuestas hermosas a los Clientes que es más probable que acepten.',
        ru: 'Отправляйте клиентам красивые предложения, которые они с большей вероятностью примут.',
        fr: 'Envoyez de belles propositions aux Clients qu\'ils sont plus susceptibles d\'accepter.',
        de: 'Senden Sie Kunden schöne Vorschläge, die sie eher annehmen.'
    },
    'Track Mileage & Equipment': {
        pt: 'Rastrear Quilometragem e Equipamentos',
        es: 'Rastrear Kilometraje y Equipos',
        ru: 'Отслеживание пробега и оборудования',
        fr: 'Suivre le Kilométrage et l\'Équipement',
        de: 'Kilometerstand und Ausrüstung verfolgen'
    },
    'Earn more by tracking your writeoffs and saving on taxes.': {
        pt: 'Ganhe mais rastreando suas deduções e economizando em impostos.',
        es: 'Gana más rastreando tus deducciones y ahorrando en impuestos.',
        ru: 'Зарабатывайте больше, отслеживая свои списания и экономя на налогах.',
        fr: 'Gagnez plus en suivant vos déductions et en économisant sur les impôts.',
        de: 'Verdienen Sie mehr, indem Sie Ihre Abschreibungen verfolgen und Steuern sparen.'
    },

    'Complete Job': {
        pt: 'Concluir Trabalho',
        es: 'Completar Trabajo',
        ru: 'Завершить работу',
        fr: 'Terminer le travail',
        de: 'Aufgabe abschließen'
    },
    'Please add the job price to complete it.': {
        pt: 'Por favor, adicione o preço do trabalho para concluí-lo.',
        es: 'Por favor, agregue el precio del trabajo para completarlo.',
        ru: 'Пожалуйста, добавьте стоимость работы, чтобы завершить её.',
        fr: 'Veuillez ajouter le prix du travail pour le terminer.',
        de: 'Bitte fügen Sie den Preis der Arbeit hinzu, um sie abzuschließen.'
    },
    'Please add the job length to complete it.': {
        pt: 'Por favor, adicione a duração do trabalho para concluí-lo.',
        es: 'Por favor, agregue la duración del trabajo para completarlo.',
        ru: 'Пожалуйста, добавьте продолжительность работы, чтобы завершить её.',
        fr: 'Veuillez ajouter la durée du travail pour le terminer.',
        de: 'Bitte fügen Sie die Dauer der Arbeit hinzu, um sie abzuschließen.'
    },
    'What did the job cost?': {
        pt: 'Qual foi o custo do trabalho?',
        es: '¿Cuál fue el costo del trabajo?',
        ru: 'Сколько стоила работа?',
        fr: 'Quel a été le coût du travail?',
        de: 'Was hat die Arbeit gekostet?'
    },
    'Job Price': {
        pt: 'Preço do Trabalho',
        es: 'Precio del Trabajo',
        ru: 'Стоимость работы',
        fr: 'Prix du travail',
        de: 'Arbeitskosten'
    },
    'How long did the job take?': {
        pt: 'Quanto tempo durou o trabalho?',
        es: '¿Cuánto tiempo tomó el trabajo?',
        ru: 'Сколько времени заняла работа?',
        fr: 'Combien de temps a duré le travail?',
        de: 'Wie lange hat die Arbeit gedauert?'
    },
    'Hours': {
        pt: 'Horas',
        es: 'Horas',
        ru: 'Часы',
        fr: 'Heures',
        de: 'Stunden'
    },
    'Minutes': {
        pt: 'Minutos',
        es: 'Minutos',
        ru: 'Минуты',
        fr: 'Minutes',
        de: 'Minuten'
    },

    'Concierge': {
        pt: 'Concierge',
        es: 'Conserje',
        ru: 'Консьерж',
        fr: 'Concierge',
        de: 'Concierge'
    },
    '<i>Waiting on Client</i>': {
        pt: '<i>Aguardando o Cliente</i>',
        es: '<i>Esperando al Cliente</i>',
        ru: '<i>Ожидание клиента</i>',
        fr: '<i>En attente du client</i>',
        de: '<i>Warten auf den Kunden</i>'
    },
    '<b><i>Waiting on You</i><b>': {
        pt: '<b><i>Aguardando Você</i><b>',
        es: '<b><i>Esperando por Ti</i><b>',
        ru: '<b><i>Ожидание вас</i><b>',
        fr: '<b><i>En attente de vous</i><b>',
        de: '<b><i>Warten auf Sie</i><b>'
    },
    '<i>Dispute Closed</i>': {
        pt: '<i>Disputa Encerrada</i>',
        es: '<i>Disputa Cerrada</i>',
        ru: '<i>Спор закрыт</i>',
        fr: '<i>Litige Clos</i>',
        de: '<i>Streit geschlossen</i>'
    },
    '<i>Waiting on TIDY</i>': {
        pt: '<i>Aguardando TIDY</i>',
        es: '<i>Esperando a TIDY</i>',
        ru: '<i>Ожидание TIDY</i>',
        fr: '<i>En attente de TIDY</i>',
        de: '<i>Warten auf TIDY</i>'
    },
    'TIDY Concierge': {
        pt: 'Concierge TIDY',
        es: 'Conserje TIDY',
        ru: 'Консьерж TIDY',
        fr: 'Concierge TIDY',
        de: 'Concierge TIDY'
    },
    'Helping your business get running successfully.': {
        pt: 'Ajudando seu negócio a funcionar com sucesso.',
        es: 'Ayudando a que su negocio funcione con éxito.',
        ru: 'Помощь вашему бизнесу в успешном запуске.',
        fr: 'Aider votre entreprise à démarrer avec succès.',
        de: 'Helfen Sie Ihrem Unternehmen, erfolgreich zu starten.'
    },
    'Answer any Questions': {
        pt: 'Responder a quaisquer perguntas',
        es: 'Responder a cualquier pregunta',
        ru: 'Ответить на любые вопросы',
        fr: 'Répondre à toutes les questions',
        de: 'Beantworten Sie alle Fragen'
    },
    'Easy to ask just about anything related to using TIDY.': {
        pt: 'Fácil de perguntar sobre qualquer coisa relacionada ao uso da TIDY.',
        es: 'Fácil de preguntar sobre cualquier cosa relacionada con el uso de TIDY.',
        ru: 'Легко спросить о чем угодно, связанном с использованием TIDY.',
        fr: 'Facile de demander à peu près n\'importe quoi en rapport avec l\'utilisation de TIDY.',
        de: 'Einfach zu fragen, was mit der Nutzung von TIDY zu tun hat.'
    },
    'Setting up for Success': {
        pt: 'Preparando-se para o Sucesso',
        es: 'Preparándose para el Éxito',
        ru: 'Настройка на успех',
        fr: 'Se préparer au succès',
        de: 'Einrichtung für den Erfolg'
    },
    'We will provide personalized recommendations on how to improve your business to earn more and save more.': {
        pt: 'Forneceremos recomendações personalizadas sobre como melhorar seu negócio para ganhar mais e economizar mais.',
        es: 'Proporcionaremos recomendaciones personalizadas sobre cómo mejorar su negocio para ganar más y ahorrar más.',
        ru: 'Мы предоставим персонализированные рекомендации о том, как улучшить ваш бизнес, чтобы зарабатывать больше и экономить больше.',
        fr: 'Nous fournirons des recommandations personnalisées sur la façon d\'améliorer votre entreprise pour gagner plus et économiser plus.',
        de: 'Wir geben Ihnen personalisierte Empfehlungen, wie Sie Ihr Geschäft verbessern können, um mehr zu verdienen und mehr zu sparen.'
    },
    'Get Help': {
        pt: 'Obter Ajuda',
        es: 'Obtener Ayuda',
        ru: 'Получить помощь',
        fr: 'Obtenir de l\'aide',
        de: 'Hilfe bekommen'
    },

    'Reply to Concierge': {
        pt: 'Responder ao Concierge',
        es: 'Responder al Conserje',
        ru: 'Ответить консьержу',
        fr: 'Répondre au Concierge',
        de: 'Antwort an den Concierge'
    },
    'Reply': {
        pt: 'Responder',
        es: 'Responder',
        ru: 'Ответить',
        fr: 'Répondre',
        de: 'Antworten'
    },
    'Please enter a reply.': {
        pt: 'Por favor, insira uma resposta.',
        es: 'Por favor, ingrese una respuesta.',
        ru: 'Пожалуйста, введите ответ.',
        fr: 'Veuillez entrer une réponse.',
        de: 'Bitte geben Sie eine Antwort ein.'
    },
    'Add Attachment': {
        pt: 'Adicionar Anexo',
        es: 'Agregar Adjunto',
        ru: 'Добавить вложение',
        fr: 'Ajouter une pièce jointe',
        de: 'Anhang hinzufügen'
    },
    'Attachments:': {
        pt: 'Anexos:',
        es: 'Adjuntos:',
        ru: 'Вложения:',
        fr: 'Pièces jointes:',
        de: 'Anhänge:'
    },
    'remove': {
        pt: 'remover',
        es: 'eliminar',
        ru: 'удалить',
        fr: 'supprimer',
        de: 'entfernen'
    },
    'Send Reply': {
        pt: 'Enviar Resposta',
        es: 'Enviar Respuesta',
        ru: 'Отправить ответ',
        fr: 'Envoyer la réponse',
        de: 'Antwort senden'
    },
    'Message Sent': {
        pt: 'Mensagem Enviada',
        es: 'Mensaje Enviado',
        ru: 'Сообщение отправлено',
        fr: 'Message envoyé',
        de: 'Nachricht gesendet'
    },

    'You reported this issue on your ': {
        pt: 'Você relatou este problema no seu ',
        es: 'Informaste de este problema en tu ',
        ru: 'Вы сообщили об этой проблеме на вашем ',
        fr: 'Vous avez signalé ce problème sur votre ',
        de: 'Sie haben dieses Problem auf Ihrem '
    },
    ' job. ': {
        pt: ' trabalho. ',
        es: ' trabajo. ',
        ru: ' работа. ',
        fr: ' travail. ',
        de: ' Arbeit. '
    },
    'Tap here': {
        pt: 'Toque aqui',
        es: 'Toca aquí',
        ru: 'Нажмите здесь',
        fr: 'Appuyez ici',
        de: 'Tippen Sie hier'
    },
    ' for job details.': {
        pt: ' para detalhes do trabalho.',
        es: ' para detalles del trabajo.',
        ru: ' для деталей работы.',
        fr: ' pour les détails du travail.',
        de: ' für Arbeitsdetails.'
    },
    'We expect to reply within ': {
        pt: 'Esperamos responder dentro de ',
        es: 'Esperamos responder dentro de ',
        ru: 'Мы ожидаем ответить в течение ',
        fr: 'Nous prévoyons de répondre dans ',
        de: 'Wir erwarten eine Antwort innerhalb von '
    },
    '. We will reply both in the app and to your email ': {
        pt: '. Responderemos tanto no aplicativo quanto no seu e-mail ',
        es: '. Responderemos tanto en la aplicación como en tu correo electrónico ',
        ru: '. Мы ответим как в приложении, так и на вашу электронную почту ',
        fr: '. Nous répondrons à la fois dans l\'application et à votre e-mail ',
        de: '. Wir werden sowohl in der App als auch an Ihre E-Mail antworten '
    },
    'You': {
        pt: 'Você',
        es: 'Tú',
        ru: 'Вы',
        fr: 'Vous',
        de: 'Sie'
    },
    'Job Issue': {
        pt: 'Problema de Trabalho',
        es: 'Problema de Trabajo',
        ru: 'Проблема с Работой',
        fr: 'Problème de Travail',
        de: 'Arbeitsproblem'
    },
    'How to Use TIDY': {
        pt: 'Como Usar o TIDY',
        es: 'Cómo Usar TIDY',
        ru: 'Как Использовать TIDY',
        fr: 'Comment Utiliser TIDY',
        de: 'Wie man TIDY benutzt'
    },
    'Billing Issue': {
        pt: 'Problema de Cobrança',
        es: 'Problema de Facturación',
        ru: 'Проблема с Оплатой',
        fr: 'Problème de Facturation',
        de: 'Abrechnungsproblem'
    },
    'Managing My Team': {
        pt: 'Gerenciando Minha Equipe',
        es: 'Gestionando Mi Equipo',
        ru: 'Управление Моей Командой',
        fr: 'Gérer Mon Équipe',
        de: 'Mein Team Verwalten'
    },
    'Private Clients': {
        pt: 'Clientes Privados',
        es: 'Clientes Privados',
        ru: 'Частные Клиенты',
        fr: 'Clients Privés',
        de: 'Private Kunden'
    },
    'Other Question': {
        pt: 'Outra Pergunta',
        es: 'Otra Pregunta',
        ru: 'Другой Вопрос',
        fr: 'Autre Question',
        de: 'Andere Frage'
    },

    'You marked that the correct status is ': {
        pt: 'Você marcou que o status correto é ',
        es: 'Marcaste que el estado correcto es ',
        ru: 'Вы отметили, что правильный статус ',
        fr: 'Vous avez marqué que le statut correct est ',
        de: 'Sie haben markiert, dass der richtige Status '
    },
    ' Please let us know any other detail about the issue.': {
        pt: ' Por favor, informe-nos de qualquer outro detalhe sobre o problema.',
        es: ' Por favor, háganos saber cualquier otro detalle sobre el problema.',
        ru: ' Пожалуйста, сообщите нам любые другие детали о проблеме.',
        fr: ' Veuillez nous faire savoir tout autre détail concernant le problème.',
        de: ' Bitte lassen Sie uns alle weiteren Details zum Problem wissen.'
    },
    '<b>If you are in immediate danger please dial 911.</b>': {
        pt: '<b>Se você estiver em perigo imediato, disque 911.</b>',
        es: '<b>Si está en peligro inmediato, marque 911.</b>',
        ru: '<b>Если вы в непосредственной опасности, наберите 911.</b>',
        fr: '<b>Si vous êtes en danger immédiat, composez le 911.</b>',
        de: '<b>Wenn Sie in unmittelbarer Gefahr sind, wählen Sie 911.</b>'
    },
    'Want to talk with our Concierge?': {
        pt: 'Quer falar com o nosso Concierge?',
        es: '¿Quieres hablar con nuestro Concierge?',
        ru: 'Хотите поговорить с нашим Консьержем?',
        fr: 'Vous voulez parler à notre Concierge?',
        de: 'Möchten Sie mit unserem Concierge sprechen?'
    },
    'Schedule a Support Call': {
        pt: 'Agendar uma Chamada de Suporte',
        es: 'Programar una Llamada de Soporte',
        ru: 'Запланировать Звонок Поддержки',
        fr: 'Planifier un Appel de Support',
        de: 'Einen Support-Anruf Planen'
    },
    'Subject': {
        pt: 'Assunto',
        es: 'Asunto',
        ru: 'Тема',
        fr: 'Sujet',
        de: 'Betreff'
    },
    'Please enter your first name.': {
        pt: 'Por favor, insira seu primeiro nome.',
        es: 'Por favor, ingrese su primer nombre.',
        ru: 'Пожалуйста, введите ваше имя.',
        fr: 'Veuillez entrer votre prénom.',
        de: 'Bitte geben Sie Ihren Vornamen ein.'
    },
    'Message': {
        pt: 'Mensagem',
        es: 'Mensaje',
        ru: 'Сообщение',
        fr: 'Message',
        de: 'Nachricht'
    },
    'Please enter a message': {
        pt: 'Por favor, insira uma mensagem',
        es: 'Por favor, ingrese un mensaje',
        ru: 'Пожалуйста, введите сообщение',
        fr: 'Veuillez entrer un message',
        de: 'Bitte geben Sie eine Nachricht ein'
    },
    'Email CC': {
        pt: 'Email CC',
        es: 'Correo CC',
        ru: 'Электронная почта CC',
        fr: 'Email CC',
        de: 'E-Mail CC'
    },
    'Please enter a valid email': {
        pt: 'Por favor, insira um e-mail válido',
        es: 'Por favor, ingrese un correo electrónico válido',
        ru: 'Пожалуйста, введите действительный адрес электронной почты',
        fr: 'Veuillez entrer un email valide',
        de: 'Bitte geben Sie eine gültige E-Mail-Adresse ein'
    },
    'Add Email CC': {
        pt: 'Adicionar Email CC',
        es: 'Agregar Correo CC',
        ru: 'Добавить Электронную Почту CC',
        fr: 'Ajouter un Email CC',
        de: 'E-Mail CC Hinzufügen'
    },
    'Send to Concierge': {
        pt: 'Enviar para o Concierge',
        es: 'Enviar al Concierge',
        ru: 'Отправить Консьержу',
        fr: 'Envoyer au Concierge',
        de: 'An Concierge Senden'
    },
    '\"Other\" is good if none of the other categories work. ': {
        pt: '\"Outro\" é bom se nenhuma das outras categorias funcionar. ',
        es: '\"Otro\" es bueno si ninguna de las otras categorías funciona. ',
        ru: '\"Другое\" подходит, если ни одна из других категорий не подходит. ',
        fr: '\"Autre\" est bon si aucune des autres catégories ne fonctionne. ',
        de: '\"Andere\" ist gut, wenn keine der anderen Kategorien funktioniert. '
    },

    'Accept Dispute Proposal': {
        pt: 'Aceitar Proposta de Disputa',
        es: 'Aceptar Propuesta de Disputa',
        ru: 'Принять Предложение по Спору',
        fr: 'Accepter la Proposition de Litige',
        de: 'Streitvorschlag Annehmen'
    },
    'Send a Counter Proposal': {
        pt: 'Enviar uma Contra Proposta',
        es: 'Enviar una Contra Propuesta',
        ru: 'Отправить Контрпредложение',
        fr: 'Envoyer une Contre-Proposition',
        de: 'Gegenvorschlag Senden'
    },
    'Dispute Tips:': {
        pt: 'Dicas de Disputa:',
        es: 'Consejos de Disputa:',
        ru: 'Советы по Спорам:',
        fr: 'Conseils de Litige:',
        de: 'Streit-Tipps:'
    },
    'Try a partial resolution': {
        pt: 'Tente uma resolução parcial',
        es: 'Intente una resolución parcial',
        ru: 'Попробуйте частичное разрешение',
        fr: 'Essayez une résolution partielle',
        de: 'Versuchen Sie eine teilweise Lösung'
    },
    'Seeking "all" or "nothing" is often is rejected by the other party.': {
        pt: 'Buscar "tudo" ou "nada" muitas vezes é rejeitado pela outra parte.',
        es: 'Buscar "todo" o "nada" a menudo es rechazado por la otra parte.',
        ru: 'Поиск "всё" или "ничего" часто отвергается другой стороной.',
        fr: 'Chercher "tout" ou "rien" est souvent rejeté par l\'autre partie.',
        de: 'Die Suche nach "alles" oder "nichts" wird oft von der anderen Partei abgelehnt.'
    },
    'Be polite': {
        pt: 'Seja educado',
        es: 'Sea cortés',
        ru: 'Будьте вежливы',
        fr: 'Soyez poli',
        de: 'Seien Sie höflich'
    },
    'Parties will be more likely to accept your resolution.': {
        pt: 'As partes terão mais probabilidade de aceitar sua resolução.',
        es: 'Las partes tendrán más probabilidades de aceptar su resolución.',
        ru: 'Стороны с большей вероятностью примут ваше решение.',
        fr: 'Les parties seront plus susceptibles d\'accepter votre résolution.',
        de: 'Die Parteien werden eher bereit sein, Ihre Lösung zu akzeptieren.'
    },
    'Dispute Proposal Accepted': {
        pt: 'Proposta de Disputa Aceita',
        es: 'Propuesta de Disputa Aceptada',
        ru: 'Предложение по Спору Принято',
        fr: 'Proposition de Litige Acceptée',
        de: 'Streitvorschlag Akzeptiert'
    },

    'What can we help with?': {
        pt: 'Com o que podemos ajudar?',
        es: '¿Con qué podemos ayudar?',
        ru: 'Чем мы можем помочь?',
        fr: 'Avec quoi pouvons-nous vous aider?',
        de: 'Wobei können wir helfen?'
    },
    'e.g. How do Clients book with me?': {
        pt: 'por exemplo, Como os Clientes reservam comigo?',
        es: 'p.ej. ¿Cómo reservan los Clientes conmigo?',
        ru: 'например, Как клиенты бронируют у меня?',
        fr: 'par exemple, Comment les Clients réservent-ils avec moi?',
        de: 'z.B. Wie buchen Kunden bei mir?'
    },
    'e.g. The job is marked incorrectly.': {
        pt: 'por exemplo, O trabalho está marcado incorretamente.',
        es: 'p.ej. El trabajo está marcado incorrectamente.',
        ru: 'например, Работа отмечена неправильно.',
        fr: 'par exemple, Le travail est mal marqué.',
        de: 'z.B. Der Job ist falsch markiert.'
    },
    'Bank Payout Issue': {
        pt: 'Problema de Pagamento Bancário',
        es: 'Problema de Pago Bancario',
        ru: 'Проблема с Банковской Выплатой',
        fr: 'Problème de Paiement Bancaire',
        de: 'Problem mit Bankauszahlung'
    },
    'e.g. My payment hasn\'t arrived.': {
        pt: 'por exemplo, Meu pagamento não chegou.',
        es: 'p.ej. Mi pago no ha llegado.',
        ru: 'например, Мой платеж не пришел.',
        fr: 'par exemple, Mon paiement n\'est pas arrivé.',
        de: 'z.B. Meine Zahlung ist nicht angekommen.'
    },
    'e.g. How do I delegate a job?': {
        pt: 'por exemplo, Como delego um trabalho?',
        es: 'p.ej. ¿Cómo delego un trabajo?',
        ru: 'например, Как я делегирую работу?',
        fr: 'par exemple, Comment déléguer un travail?',
        de: 'z.B. Wie delegiere ich eine Aufgabe?'
    },
    'Managing Private Clients': {
        pt: 'Gerenciando Clientes Privados',
        es: 'Gestionando Clientes Privados',
        ru: 'Управление Частными Клиентами',
        fr: 'Gérer des Clients Privés',
        de: 'Private Kunden Verwalten'
    },
    'e.g. How do I add a private job?': {
        pt: 'por exemplo, Como adiciono um trabalho privado?',
        es: 'p.ej. ¿Cómo agrego un trabajo privado?',
        ru: 'например, Как добавить частную работу?',
        fr: 'par exemple, Comment ajouter un travail privé?',
        de: 'z.B. Wie füge ich einen privaten Job hinzu?'
    },
    'Other': {
        pt: 'Outro',
        es: 'Otro',
        ru: 'Другое',
        fr: 'Autre',
        de: 'Andere'
    },
    'Note: this is the slowest option': {
        pt: 'Nota: esta é a opção mais lenta',
        es: 'Nota: esta es la opción más lenta',
        ru: 'Примечание: это самый медленный вариант',
        fr: 'Remarque : c\'est l\'option la plus lente',
        de: 'Hinweis: Dies ist die langsamste Option'
    },
    'We are happy to help with any job issues. Please tap on the job, then “Log Issue”, and we typically help within 2 business days.': {
        pt: 'Estamos felizes em ajudar com quaisquer problemas de trabalho. Por favor, toque no trabalho, depois em “Registrar Problema”, e normalmente ajudamos dentro de 2 dias úteis.',
        es: 'Estamos encantados de ayudar con cualquier problema de trabajo. Por favor, toque en el trabajo, luego en “Registrar Problema”, y normalmente ayudamos dentro de 2 días hábiles.',
        ru: 'Мы рады помочь с любыми проблемами работы. Пожалуйста, нажмите на работу, затем «Зарегистрировать Проблему», и мы обычно помогаем в течение 2 рабочих дней.',
        fr: 'Nous sommes heureux d\'aider avec tout problème de travail. Veuillez appuyer sur le travail, puis sur « Enregistrer le Problème », et nous aidons généralement dans les 2 jours ouvrables.',
        de: 'Wir helfen gerne bei allen Arbeitsproblemen. Bitte tippen Sie auf die Arbeit, dann auf „Problem melden“, und wir helfen in der Regel innerhalb von 2 Werktagen.'
    },
    'Payment Issue': {
        pt: 'Problema de Pagamento',
        es: 'Problema de Pago',
        ru: 'Проблема с Платежом',
        fr: 'Problème de Paiement',
        de: 'Zahlungsproblem'
    },
    'We are happy to help with any payment issues. Please tap “Log Issue” on the payment item you have questions on, and we typically help within 4 business hours.': {
        pt: 'Estamos felizes em ajudar com quaisquer problemas de pagamento. Por favor, toque em “Registrar Problema” no item de pagamento sobre o qual você tem dúvidas, e normalmente ajudamos dentro de 4 horas úteis.',
        es: 'Estamos encantados de ayudar con cualquier problema de pago. Por favor, toque en “Registrar Problema” en el ítem de pago sobre el que tiene preguntas, y normalmente ayudamos dentro de 4 horas hábiles.',
        ru: 'Мы рады помочь с любыми проблемами с платежами. Пожалуйста, нажмите «Зарегистрировать Проблему» на элементе платежа, по которому у вас есть вопросы, и мы обычно помогаем в течение 4 рабочих часов.',
        fr: 'Nous sommes heureux d\'aider avec tout problème de paiement. Veuillez appuyer sur « Enregistrer le Problème » sur l\'élément de paiement sur lequel vous avez des questions, et nous aidons généralement dans les 4 heures ouvrables.',
        de: 'Wir helfen gerne bei allen Zahlungsproblemen. Bitte tippen Sie auf „Problem melden“ beim Zahlungselement, zu dem Sie Fragen haben, und wir helfen in der Regel innerhalb von 4 Geschäftszeiten.'
    },

    'Who do you want to log an issue for?': {
        pt: 'Para quem você quer registrar um problema?',
        es: '¿Para quién quieres registrar un problema?',
        ru: 'Для кого вы хотите зарегистрировать проблему?',
        fr: 'Pour qui voulez-vous enregistrer un problème?',
        de: 'Für wen möchten Sie ein Problem melden?'
    },
    'Log for Client': {
        pt: 'Registrar para Cliente',
        es: 'Registrar para Cliente',
        ru: 'Зарегистрировать для Клиента',
        fr: 'Enregistrer pour le Client',
        de: 'Für Kunden melden'
    },
    'Damage, low inventory or other issue': {
        pt: 'Dano, baixo estoque ou outro problema',
        es: 'Daño, bajo inventario u otro problema',
        ru: 'Повреждение, низкий запас или другая проблема',
        fr: 'Dommage, faible inventaire ou autre problème',
        de: 'Schaden, niedriger Bestand oder anderes Problem'
    },
    'Log for TIDY Concierge': {
        pt: 'Registrar para TIDY Concierge',
        es: 'Registrar para TIDY Concierge',
        ru: 'Зарегистрировать для TIDY Concierge',
        fr: 'Enregistrer pour TIDY Concierge',
        de: 'Für TIDY Concierge melden'
    },
    'Wrong status, safety or other issue': {
        pt: 'Status incorreto, segurança ou outro problema',
        es: 'Estado incorrecto, seguridad u otro problema',
        ru: 'Неправильный статус, безопасность или другая проблема',
        fr: 'Statut incorrect, sécurité ou autre problème',
        de: 'Falscher Status, Sicherheit oder anderes Problem'
    },
    'Wrong Status': {
        pt: 'Status Incorreto',
        es: 'Estado Incorrecto',
        ru: 'Неправильный Статус',
        fr: 'Statut Incorrect',
        de: 'Falscher Status'
    },
    'Current status: ': {
        pt: 'Status atual: ',
        es: 'Estado actual: ',
        ru: 'Текущий статус: ',
        fr: 'Statut actuel: ',
        de: 'Aktueller Status: '
    },
    'Safety Issue': {
        pt: 'Problema de Segurança',
        es: 'Problema de Seguridad',
        ru: 'Проблема Безопасности',
        fr: 'Problème de Sécurité',
        de: 'Sicherheitsproblem'
    },
    'Other Issue': {
        pt: 'Outro Problema',
        es: 'Otro Problema',
        ru: 'Другая Проблема',
        fr: 'Autre Problème',
        de: 'Anderes Problem'
    },
    'We\'re sorry this status seems wrong.  What is the correct status?': {
        pt: 'Desculpe, este status parece estar errado. Qual é o status correto?',
        es: 'Lo sentimos, este estado parece estar equivocado. ¿Cuál es el estado correcto?',
        ru: 'Извините, этот статус кажется неправильным. Какой правильный статус?',
        fr: 'Désolé, ce statut semble incorrect. Quel est le statut correct?',
        de: 'Entschuldigung, dieser Status scheint falsch zu sein. Was ist der richtige Status?'
    },
    'Completed': {
        pt: 'Concluído',
        es: 'Completado',
        ru: 'Завершено',
        fr: 'Terminé',
        de: 'Abgeschlossen'
    },
    'Client Cancelled': {
        pt: 'Cancelado pelo Cliente',
        es: 'Cancelado por el Cliente',
        ru: 'Отменено клиентом',
        fr: 'Annulé par le client',
        de: 'Vom Kunden storniert'
    },
    'Could Not Access': {
        pt: 'Não foi possível acessar',
        es: 'No se pudo acceder',
        ru: 'Не удалось получить доступ',
        fr: 'Impossible d\'accéder',
        de: 'Kein Zugang möglich'
    },

    'Contact Support': {
        pt: 'Contate o Suporte',
        es: 'Contactar Soporte',
        ru: 'Связаться с поддержкой',
        fr: 'Contacter le support',
        de: 'Support kontaktieren'
    },
    'Please contact us in the Concierge section for support, or visit our ': {
        pt: 'Por favor, entre em contato conosco na seção Concierge para suporte, ou visite nosso ',
        es: 'Por favor, contáctenos en la sección de Conserjería para soporte, o visite nuestro ',
        ru: 'Пожалуйста, свяжитесь с нами в разделе Консьерж для поддержки или посетите наш ',
        fr: 'Veuillez nous contacter dans la section Concierge pour obtenir de l\'aide, ou visitez notre ',
        de: 'Bitte kontaktieren Sie uns im Concierge-Bereich für Unterstützung oder besuchen Sie unser '
    },
    'Help Center': {
        pt: 'Centro de Ajuda',
        es: 'Centro de Ayuda',
        ru: 'Центр помощи',
        fr: 'Centre d\'aide',
        de: 'Hilfezentrum'
    },

    'Dashboard': {
        pt: 'Painel de Controle',
        es: 'Tablero',
        ru: 'Панель управления',
        fr: 'Tableau de bord',
        de: 'Armaturenbrett'
    },
    'Please contact your team leader for questions regarding jobs or payments.': {
        pt: 'Por favor, entre em contato com seu líder de equipe para perguntas sobre trabalhos ou pagamentos.',
        es: 'Por favor, póngase en contacto con su líder de equipo para preguntas sobre trabajos o pagos.',
        ru: 'Пожалуйста, свяжитесь с вашим руководителем команды по вопросам, касающимся работы или оплаты.',
        fr: 'Veuillez contacter votre chef d\'équipe pour toute question concernant les travaux ou les paiements.',
        de: 'Bitte wenden Sie sich an Ihren Teamleiter, wenn Sie Fragen zu Jobs oder Zahlungen haben.'
    },
    'Next Job': {
        pt: 'Próximo Trabalho',
        es: 'Próximo Trabajo',
        ru: 'Следующая работа',
        fr: 'Prochain travail',
        de: 'Nächster Job'
    },
    'None Scheduled': {
        pt: 'Nenhum Agendado',
        es: 'Ninguno Programado',
        ru: 'Не запланировано',
        fr: 'Aucun prévu',
        de: 'Keine geplant'
    },
    'Requests': {
        pt: 'Solicitações',
        es: 'Solicitudes',
        ru: 'Запросы',
        fr: 'Demandes',
        de: 'Anfragen'
    },
    'Job Delegation Request': {
        pt: 'Solicitação de Delegação de Trabalho',
        es: 'Solicitud de Delegación de Trabajo',
        ru: 'Запрос на делегирование работы',
        fr: 'Demande de délégation de travail',
        de: 'Anfrage zur Arbeitsdelegation'
    },
    ' hour job': {
        pt: ' trabalho de hora',
        es: ' trabajo de hora',
        ru: ' часовая работа',
        fr: ' travail d\'heure',
        de: ' Stundenjob'
    },
    ' invited you to join their team and accept this job. If you accept, you will be expected to complete the job. Contact ': {
        pt: ' convidou você para se juntar à equipe e aceitar este trabalho. Se você aceitar, será esperado que complete o trabalho. Contate ',
        es: ' te invitó a unirte a su equipo y aceptar este trabajo. Si aceptas, se espera que completes el trabajo. Contacta ',
        ru: ' пригласил вас присоединиться к их команде и принять эту работу. Если вы примете, от вас ожидается выполнение работы. Свяжитесь с ',
        fr: ' vous a invité à rejoindre leur équipe et à accepter ce travail. Si vous acceptez, vous devrez terminer le travail. Contactez ',
        de: ' hat Sie eingeladen, ihrem Team beizutreten und diesen Job anzunehmen. Wenn Sie akzeptieren, wird erwartet, dass Sie den Job abschließen. Kontaktieren Sie '
    },
    'Team Invite Pending': {
        pt: 'Convite de Equipe Pendente',
        es: 'Invitación de Equipo Pendiente',
        ru: 'Приглашение в команду ожидает',
        fr: 'Invitation d\'équipe en attente',
        de: 'Teameinladung ausstehend'
    },
    ' for any questions.': {
        pt: ' para quaisquer perguntas.',
        es: ' para cualquier pregunta.',
        ru: ' по любым вопросам.',
        fr: ' pour toute question.',
        de: ' für alle Fragen.'
    },
    ' invited you to their team. By joining, you agree that you are a subcontractor or employee of ': {
        pt: ' convidou você para a equipe deles. Ao se juntar, você concorda que é um subcontratado ou funcionário de ',
        es: ' te invitó a su equipo. Al unirte, aceptas que eres un subcontratista o empleado de ',
        ru: ' пригласил вас в свою команду. Присоединившись, вы соглашаетесь, что являетесь субподрядчиком или сотрудником ',
        fr: ' vous a invité à rejoindre leur équipe. En rejoignant, vous acceptez d\'être un sous-traitant ou un employé de ',
        de: ' hat Sie in ihr Team eingeladen. Durch den Beitritt stimmen Sie zu, dass Sie ein Subunternehmer oder Mitarbeiter von '
    },
    '\'s business, and they are responsible for safety, legal, insurance, and employment obligations as well as the ': {
        pt: ' e eles são responsáveis pela segurança, legalidade, seguro e obrigações trabalhistas, bem como o ',
        es: ' y son responsables de la seguridad, legalidad, seguro y obligaciones laborales, así como del ',
        ru: ' и они несут ответственность за безопасность, юридические, страховые и трудовые обязательства, а также за ',
        fr: ' et ils sont responsables de la sécurité, légalité, assurance et obligations d\'emploi ainsi que du ',
        de: ' und sie sind verantwortlich für Sicherheit, rechtliche, versicherungstechnische und arbeitsrechtliche Verpflichtungen sowie das '
    },
    'Service Provider Customer Agreement': {
        pt: 'Acordo de Cliente do Prestador de Serviços',
        es: 'Acuerdo de Cliente del Proveedor de Servicios',
        ru: 'Соглашение с клиентом поставщика услуг',
        fr: 'Accord de Client du Prestataire de Services',
        de: 'Dienstleister-Kundenvereinbarung'
    },
    'Accept Job & Join Team': {
        pt: 'Aceitar Trabalho e Juntar-se à Equipe',
        es: 'Aceptar Trabajo y Unirse al Equipo',
        ru: 'Принять работу и присоединиться к команде',
        fr: 'Accepter le Travail et Rejoindre l\'Équipe',
        de: 'Job annehmen und dem Team beitreten'
    },
    'Accept Invite': {
        pt: 'Aceitar Convite',
        es: 'Aceptar Invitación',
        ru: 'Принять приглашение',
        fr: 'Accepter l\'invitation',
        de: 'Einladung annehmen'
    },
    'Reject Job & Team Invite': {
        pt: 'Rejeitar Trabalho e Convite de Equipe',
        es: 'Rechazar Trabajo e Invitación de Equipo',
        ru: 'Отклонить работу и приглашение в команду',
        fr: 'Refuser le Travail et l\'Invitation d\'Équipe',
        de: 'Job und Teameinladung ablehnen'
    },
    'Reject Invite': {
        pt: 'Rejeitar Convite',
        es: 'Rechazar Invitación',
        ru: 'Отклонить приглашение',
        fr: 'Refuser l\'invitation',
        de: 'Einladung ablehnen'
    },
    'You\'re Ready for Clients!': {
        pt: 'Você está pronto para os clientes!',
        es: '¡Estás listo para los clientes!',
        ru: 'Вы готовы к клиентам!',
        fr: 'Vous êtes prêt pour les clients!',
        de: 'Sie sind bereit für Kunden!'
    },
    'You’re ready to get Client from TIDY!': {
        pt: 'Você está pronto para receber clientes da TIDY!',
        es: '¡Estás listo para recibir clientes de TIDY!',
        ru: 'Вы готовы получать клиентов от TIDY!',
        fr: 'Vous êtes prêt à recevoir des clients de TIDY!',
        de: 'Sie sind bereit, Kunden von TIDY zu erhalten!'
    },
    'Turn on Clients from TIDY': {
        pt: 'Ativar Clientes da TIDY',
        es: 'Activar Clientes de TIDY',
        ru: 'Включить клиентов от TIDY',
        fr: 'Activer les Clients de TIDY',
        de: 'Kunden von TIDY aktivieren'
    },
    'Certification No Longer Valid': {
        pt: 'Certificação Não é Mais Válida',
        es: 'Certificación Ya No Válida',
        ru: 'Сертификация больше не действительна',
        fr: 'Certification Non Valide',
        de: 'Zertifizierung nicht mehr gültig'
    },
    'You are unable to get Clients from TIDY.  Tap here to re-request Certification.': {
        pt: 'Você não pode obter clientes da TIDY. Toque aqui para solicitar novamente a certificação.',
        es: 'No puedes obtener clientes de TIDY. Toca aquí para volver a solicitar la certificación.',
        ru: 'Вы не можете получать клиентов от TIDY. Нажмите здесь, чтобы повторно запросить сертификацию.',
        fr: 'Vous ne pouvez pas obtenir de clients de TIDY. Appuyez ici pour redemander la certification.',
        de: 'Sie können keine Kunden von TIDY erhalten. Tippen Sie hier, um die Zertifizierung erneut anzufordern.'
    },
    'Request Certification': {
        pt: 'Solicitar Certificação',
        es: 'Solicitar Certificación',
        ru: 'Запросить сертификацию',
        fr: 'Demander la Certification',
        de: 'Zertifizierung anfordern'
    },
    'Not Available for Repeat Clients': {
        pt: 'Não Disponível para Clientes Repetidos',
        es: 'No Disponible para Clientes Recurrentes',
        ru: 'Недоступно для повторных клиентов',
        fr: 'Non Disponible pour les Clients Répétitifs',
        de: 'Nicht verfügbar für wiederkehrende Kunden'
    },
    'Open up recurring availability if you want repeat Clients. ': {
        pt: 'Abra disponibilidade recorrente se quiser clientes repetidos. ',
        es: 'Abre disponibilidad recurrente si quieres clientes recurrentes. ',
        ru: 'Откройте повторяющуюся доступность, если хотите повторных клиентов. ',
        fr: 'Ouvrez la disponibilité récurrente si vous voulez des clients répétitifs. ',
        de: 'Öffnen Sie wiederkehrende Verfügbarkeit, wenn Sie wiederkehrende Kunden möchten. '
    },
    'View My Availability': {
        pt: 'Ver Minha Disponibilidade',
        es: 'Ver Mi Disponibilidad',
        ru: 'Просмотреть мою доступность',
        fr: 'Voir Ma Disponibilité',
        de: 'Meine Verfügbarkeit anzeigen'
    },
    'Invoices': {
        pt: 'Faturas',
        es: 'Facturas',
        ru: 'Счета',
        fr: 'Factures',
        de: 'Rechnungen'
    },
    ' Unpaid Jobs': {
        pt: ' Trabalhos Não Pagos',
        es: ' Trabajos No Pagados',
        ru: ' Неоплаченные работы',
        fr: ' Travaux Non Payés',
        de: ' Unbezahlte Arbeiten'
    },
    'Balance': {
        pt: 'Saldo',
        es: 'Saldo',
        ru: 'Баланс',
        fr: 'Solde',
        de: 'Guthaben'
    },
    'Start Earning': {
        pt: 'Comece a Ganhar',
        es: 'Comienza a Ganar',
        ru: 'Начать зарабатывать',
        fr: 'Commencez à Gagner',
        de: 'Beginnen Sie zu verdienen'
    },
    'Quests': {
        pt: 'Missões',
        es: 'Misiones',
        ru: 'Задания',
        fr: 'Quêtes',
        de: 'Aufgaben'
    },
    ' Available': {
        pt: ' Disponível',
        es: ' Disponible',
        ru: ' Доступно',
        fr: ' Disponible',
        de: ' Verfügbar'
    },
    'Add Jobs': {
        pt: 'Adicionar Trabalhos',
        es: 'Agregar Trabajos',
        ru: 'Добавить работы',
        fr: 'Ajouter des Travaux',
        de: 'Arbeiten hinzufügen'
    },
    'Proposals': {
        pt: 'Propostas',
        es: 'Propuestas',
        ru: 'Предложения',
        fr: 'Propositions',
        de: 'Vorschläge'
    },
    ' Pending': {
        pt: ' Pendente',
        es: ' Pendiente',
        ru: ' В ожидании',
        fr: ' En attente',
        de: ' Ausstehend'
    },
    'Scores': {
        pt: 'Pontuações',
        es: 'Puntuaciones',
        ru: 'Оценки',
        fr: 'Scores',
        de: 'Punkte'
    },
    'Quality': {
        pt: 'Qualidade',
        es: 'Calidad',
        ru: 'Качество',
        fr: 'Qualité',
        de: 'Qualität'
    },
    'Reliability': {
        pt: 'Confiabilidade',
        es: 'Confiabilidad',
        ru: 'Надежность',
        fr: 'Fiabilité',
        de: 'Zuverlässigkeit'
    },
    'Bookability': {
        pt: 'Reservabilidade',
        es: 'Reservabilidad',
        ru: 'Бронируемость',
        fr: 'Réservabilité',
        de: 'Buchbarkeit'
    },
    'Learn About TIDY': {
        pt: 'Saiba Mais Sobre o TIDY',
        es: 'Aprende Sobre TIDY',
        ru: 'Узнайте о TIDY',
        fr: 'En savoir plus sur TIDY',
        de: 'Erfahren Sie mehr über TIDY'
    },
    'Existing Clients': {
        pt: 'Clientes Existentes',
        es: 'Clientes Existentes',
        ru: 'Существующие клиенты',
        fr: 'Clients Existants',
        de: 'Bestehende Kunden'
    },
    'New Clients': {
        pt: 'Novos Clientes',
        es: 'Nuevos Clientes',
        ru: 'Новые клиенты',
        fr: 'Nouveaux Clients',
        de: 'Neue Kunden'
    },
    'Get Started Now': {
        pt: 'Comece Agora',
        es: 'Comienza Ahora',
        ru: 'Начать сейчас',
        fr: 'Commencez Maintenant',
        de: 'Jetzt anfangen'
    },
    'Manage Existing Clients': {
        pt: 'Gerenciar Clientes Existentes',
        es: 'Gestionar Clientes Existentes',
        ru: 'Управление существующими клиентами',
        fr: 'Gérer les Clients Existants',
        de: 'Bestehende Kunden verwalten'
    },
    'Book jobs, track mileage & more!': {
        pt: 'Agende trabalhos, acompanhe a quilometragem e mais!',
        es: 'Reserva trabajos, rastrea el kilometraje y más!',
        ru: 'Бронируйте работы, отслеживайте пробег и многое другое!',
        fr: 'Réservez des travaux, suivez le kilométrage et plus encore!',
        de: 'Buchen Sie Jobs, verfolgen Sie Kilometer und mehr!'
    },
    'Clients in your area are looking for Pros!': {
        pt: 'Clientes na sua área estão procurando por profissionais!',
        es: '¡Clientes en tu área están buscando profesionales!',
        ru: 'Клиенты в вашем районе ищут профессионалов!',
        fr: 'Les clients de votre région recherchent des professionnels!',
        de: 'Kunden in Ihrer Gegend suchen nach Profis!'
    },
    'Receive Payments from Clients': {
        pt: 'Receber Pagamentos de Clientes',
        es: 'Recibir Pagos de Clientes',
        ru: 'Получать платежи от клиентов',
        fr: 'Recevoir des Paiements des Clients',
        de: 'Zahlungen von Kunden erhalten'
    },
    'TIDY makes it easier to earn more.': {
        pt: 'TIDY torna mais fácil ganhar mais.',
        es: 'TIDY hace que sea más fácil ganar más.',
        ru: 'TIDY упрощает зарабатывать больше.',
        fr: 'TIDY facilite le gain de plus.',
        de: 'TIDY macht es einfacher, mehr zu verdienen.'
    },
    'Updates': {
        pt: 'Atualizações',
        es: 'Actualizaciones',
        ru: 'Обновления',
        fr: 'Mises à jour',
        de: 'Aktualisierungen'
    },
    'No updates right now.': {
        pt: 'Sem atualizações no momento.',
        es: 'No hay actualizaciones en este momento.',
        ru: 'Нет обновлений на данный момент.',
        fr: 'Pas de mises à jour pour le moment.',
        de: 'Derzeit keine Updates.'
    },

    'You are on an older version of the app.  Please download the latest version to view this update.': {
        pt: 'Você está usando uma versão mais antiga do aplicativo. Por favor, baixe a versão mais recente para ver esta atualização.',
        es: 'Estás usando una versión anterior de la aplicación. Por favor, descarga la última versión para ver esta actualización.',
        ru: 'Вы используете более старую версию приложения. Пожалуйста, скачайте последнюю версию, чтобы увидеть это обновление.',
        fr: 'Vous utilisez une version plus ancienne de l\'application. Veuillez télécharger la dernière version pour voir cette mise à jour.',
        de: 'Sie verwenden eine ältere Version der App. Bitte laden Sie die neueste Version herunter, um dieses Update anzuzeigen.'
    },
    'Accept Job?': {
        pt: 'Aceitar Trabalho?',
        es: '¿Aceptar Trabajo?',
        ru: 'Принять работу?',
        fr: 'Accepter le Travail?',
        de: 'Job annehmen?'
    },
    'Job Accepted!': {
        pt: 'Trabalho Aceito!',
        es: '¡Trabajo Aceptado!',
        ru: 'Работа принята!',
        fr: 'Travail Accepté!',
        de: 'Job angenommen!'
    },
    'Accept Invite?': {
        pt: 'Aceitar Convite?',
        es: '¿Aceptar Invitación?',
        ru: 'Принять приглашение?',
        fr: 'Accepter l\'invitation?',
        de: 'Einladung annehmen?'
    },
    'Invite Accepted!': {
        pt: 'Convite Aceito!',
        es: '¡Invitación Aceptada!',
        ru: 'Приглашение принято!',
        fr: 'Invitation Acceptée!',
        de: 'Einladung angenommen!'
    },
    'Reject Job?': {
        pt: 'Rejeitar Trabalho?',
        es: '¿Rechazar Trabajo?',
        ru: 'Отклонить работу?',
        fr: 'Refuser le Travail?',
        de: 'Job ablehnen?'
    },
    'Reject Invite?': {
        pt: 'Rejeitar Convite?',
        es: '¿Rechazar Invitación?',
        ru: 'Отклонить приглашение?',
        fr: 'Refuser l\'invitation?',
        de: 'Einladung ablehnen?'
    },
    'Job Rejected': {
        pt: 'Trabalho Rejeitado',
        es: 'Trabajo Rechazado',
        ru: 'Работа отклонена',
        fr: 'Travail Rejeté',
        de: 'Job abgelehnt'
    },
    'Invite Rejected': {
        pt: 'Convite Rejeitado',
        es: 'Invitación Rechazada',
        ru: 'Приглашение отклонено',
        fr: 'Invitation Rejetée',
        de: 'Einladung abgelehnt'
    },

    'Delete My Account': {
        pt: 'Excluir Minha Conta',
        es: 'Eliminar Mi Cuenta',
        ru: 'Удалить Мой Аккаунт',
        fr: 'Supprimer Mon Compte',
        de: 'Mein Konto Löschen'
    },
    'You can delete your account and data by completing this form.': {
        pt: 'Você pode excluir sua conta e dados preenchendo este formulário.',
        es: 'Puede eliminar su cuenta y datos completando este formulario.',
        ru: 'Вы можете удалить свой аккаунт и данные, заполнив эту форму.',
        fr: 'Vous pouvez supprimer votre compte et vos données en remplissant ce formulaire.',
        de: 'Sie können Ihr Konto und Ihre Daten löschen, indem Sie dieses Formular ausfüllen.'
    },
    '<b>We strongly recommend you not take this action until all support or payment issues are 100% resolved.</b>': {
        pt: '<b>Recomendamos fortemente que você não tome esta ação até que todos os problemas de suporte ou pagamento estejam 100% resolvidos.</b>',
        es: '<b>Recomendamos encarecidamente que no tome esta acción hasta que todos los problemas de soporte o pago estén 100% resueltos.</b>',
        ru: '<b>Мы настоятельно рекомендуем не предпринимать это действие, пока все проблемы с поддержкой или оплатой не будут полностью решены.</b>',
        fr: '<b>Nous vous recommandons fortement de ne pas prendre cette mesure tant que tous les problèmes de support ou de paiement ne sont pas résolus à 100%.</b>',
        de: '<b>Wir empfehlen dringend, diese Aktion nicht durchzuführen, bis alle Support- oder Zahlungsprobleme zu 100% gelöst sind.</b>'
    },
    'Please note that some data is required by law and cannot be deleted, for example if you received payouts from customers in the US we are required to issue a 1099 with appropriate information. This is also outlined in our terms, which are not modified by the deletion of your account:': {
        pt: 'Observe que alguns dados são exigidos por lei e não podem ser excluídos, por exemplo, se você recebeu pagamentos de clientes nos EUA, somos obrigados a emitir um 1099 com as informações apropriadas. Isso também está descrito em nossos termos, que não são modificados pela exclusão de sua conta:',
        es: 'Tenga en cuenta que algunos datos son requeridos por ley y no se pueden eliminar, por ejemplo, si recibió pagos de clientes en los EE. UU., estamos obligados a emitir un 1099 con la información adecuada. Esto también se describe en nuestros términos, que no se modifican con la eliminación de su cuenta:',
        ru: 'Обратите внимание, что некоторые данные требуются по закону и не могут быть удалены, например, если вы получали выплаты от клиентов в США, мы обязаны выдать форму 1099 с соответствующей информацией. Это также изложено в наших условиях, которые не изменяются при удалении вашего аккаунта:',
        fr: 'Veuillez noter que certaines données sont requises par la loi et ne peuvent pas être supprimées, par exemple si vous avez reçu des paiements de clients aux États-Unis, nous sommes tenus de délivrer un 1099 avec les informations appropriées. Cela est également décrit dans nos conditions, qui ne sont pas modifiées par la suppression de votre compte :',
        de: 'Bitte beachten Sie, dass einige Daten gesetzlich vorgeschrieben sind und nicht gelöscht werden können. Wenn Sie beispielsweise Zahlungen von Kunden in den USA erhalten haben, sind wir verpflichtet, ein 1099 mit den entsprechenden Informationen auszustellen. Dies ist auch in unseren Bedingungen dargelegt, die durch die Löschung Ihres Kontos nicht geändert werden:'
    },
    'Deleting your account may take up to 1 business week to complete. If you take any action in your account after completing this form, you may have issues.': {
        pt: 'A exclusão de sua conta pode levar até 1 semana útil para ser concluída. Se você realizar qualquer ação em sua conta após preencher este formulário, poderá ter problemas.',
        es: 'Eliminar su cuenta puede tardar hasta 1 semana hábil en completarse. Si realiza alguna acción en su cuenta después de completar este formulario, puede tener problemas.',
        ru: 'Удаление вашего аккаунта может занять до 1 рабочей недели. Если вы предпримете какие-либо действия в своем аккаунте после заполнения этой формы, у вас могут возникнуть проблемы.',
        fr: 'La suppression de votre compte peut prendre jusqu\'à 1 semaine ouvrée. Si vous effectuez une action sur votre compte après avoir rempli ce formulaire, vous pourriez rencontrer des problèmes.',
        de: 'Das Löschen Ihres Kontos kann bis zu 1 Geschäftswoche dauern. Wenn Sie nach dem Ausfüllen dieses Formulars Maßnahmen in Ihrem Konto ergreifen, können Probleme auftreten.'
    },
    '<b>YOU MUST CANCEL ALL JOBS AND RESOLVE ALL ISSUES BEFORE CONTINUING, OR YOU WILL NEED TO RESTART THE PROCESS.</b>': {
        pt: '<b>VOCÊ DEVE CANCELAR TODOS OS TRABALHOS E RESOLVER TODOS OS PROBLEMAS ANTES DE CONTINUAR, OU TERÁ QUE REINICIAR O PROCESSO.</b>',
        es: '<b>DEBE CANCELAR TODOS LOS TRABAJOS Y RESOLVER TODOS LOS PROBLEMAS ANTES DE CONTINUAR, O DEBERÁ REINICIAR EL PROCESO.</b>',
        ru: '<b>ВЫ ДОЛЖНЫ ОТМЕНИТЬ ВСЕ ЗАДАЧИ И РЕШИТЬ ВСЕ ПРОБЛЕМЫ ПЕРЕД ПРОДОЛЖЕНИЕМ, ИНАЧЕ ВАМ ПРИДЕТСЯ НАЧАТЬ ПРОЦЕСС СНАЧАЛА.</b>',
        fr: '<b>VOUS DEVEZ ANNULER TOUS LES TRAVAUX ET RÉSOUDRE TOUS LES PROBLÈMES AVANT DE CONTINUER, OU VOUS DEVREZ RECOMMENCER LE PROCESSUS.</b>',
        de: '<b>SIE MÜSSEN ALLE AUFTRÄGE STORNIEREN UND ALLE PROBLEME LÖSEN, BEVOR SIE FORTFAHREN, ODER SIE MÜSSEN DEN PROZESS NEU STARTEN.</b>'
    },
    'Your Email on Your Account': {
        pt: 'Seu Email na Sua Conta',
        es: 'Su Correo Electrónico en Su Cuenta',
        ru: 'Ваш Email в Вашем Аккаунте',
        fr: 'Votre Email sur Votre Compte',
        de: 'Ihre E-Mail auf Ihrem Konto'
    },
    'Please enter your email': {
        pt: 'Por favor, insira seu email',
        es: 'Por favor, introduzca su correo electrónico',
        ru: 'Пожалуйста, введите ваш email',
        fr: 'Veuillez entrer votre email',
        de: 'Bitte geben Sie Ihre E-Mail ein'
    },
    'Your Phone Number on Your Account': {
        pt: 'Seu Número de Telefone na Sua Conta',
        es: 'Su Número de Teléfono en Su Cuenta',
        ru: 'Ваш Номер Телефона в Вашем Аккаунте',
        fr: 'Votre Numéro de Téléphone sur Votre Compte',
        de: 'Ihre Telefonnummer auf Ihrem Konto'
    },
    'Please enter your phone number.': {
        pt: 'Por favor, insira seu número de telefone.',
        es: 'Por favor, introduzca su número de teléfono.',
        ru: 'Пожалуйста, введите ваш номер телефона.',
        fr: 'Veuillez entrer votre numéro de téléphone.',
        de: 'Bitte geben Sie Ihre Telefonnummer ein.'
    },
    'The email you entered does not match your account email.': {
        pt: 'O email que você inseriu não corresponde ao email da sua conta.',
        es: 'El correo electrónico que ingresó no coincide con el correo electrónico de su cuenta.',
        ru: 'Введенный вами email не соответствует email вашего аккаунта.',
        fr: 'L\'email que vous avez entré ne correspond pas à l\'email de votre compte.',
        de: 'Die eingegebene E-Mail stimmt nicht mit der E-Mail Ihres Kontos überein.'
    },
    'The phone number you entered does not match your account phone number.': {
        pt: 'O número de telefone que você inseriu não corresponde ao número de telefone da sua conta.',
        es: 'El número de teléfono que ingresó no coincide con el número de teléfono de su cuenta.',
        ru: 'Введенный вами номер телефона не соответствует номеру телефона вашего аккаунта.',
        fr: 'Le numéro de téléphone que vous avez entré ne correspond pas au numéro de téléphone de votre compte.',
        de: 'Die eingegebene Telefonnummer stimmt nicht mit der Telefonnummer Ihres Kontos überein.'
    },
    'The email and phone number you entered do not match your account email and phone number.': {
        pt: 'O email e o número de telefone que você inseriu não correspondem ao email e número de telefone da sua conta.',
        es: 'El correo electrónico y el número de teléfono que ingresó no coinciden con el correo electrónico y el número de teléfono de su cuenta.',
        ru: 'Введенные вами email и номер телефона не соответствуют email и номеру телефона вашего аккаунта.',
        fr: 'L\'email et le numéro de téléphone que vous avez entrés ne correspondent pas à l\'email et au numéro de téléphone de votre compte.',
        de: 'Die eingegebene E-Mail und Telefonnummer stimmen nicht mit der E-Mail und Telefonnummer Ihres Kontos überein.'
    },
    'Are you sure you want to delete your account?': {
        pt: 'Tem certeza de que deseja excluir sua conta?',
        es: '¿Está seguro de que desea eliminar su cuenta?',
        ru: 'Вы уверены, что хотите удалить свой аккаунт?',
        fr: 'Êtes-vous sûr de vouloir supprimer votre compte?',
        de: 'Sind Sie sicher, dass Sie Ihr Konto löschen möchten?'
    },
    'Your account has been deleted.': {
        pt: 'Sua conta foi excluída.',
        es: 'Su cuenta ha sido eliminada.',
        ru: 'Ваш аккаунт был удален.',
        fr: 'Votre compte a été supprimé.',
        de: 'Ihr Konto wurde gelöscht.'
    },

    'Welcome to TIDY!': {
        pt: 'Bem-vindo ao TIDY!',
        es: '¡Bienvenido a TIDY!',
        ru: 'Добро пожаловать в TIDY!',
        fr: 'Bienvenue chez TIDY!',
        de: 'Willkommen bei TIDY!'
    },
    'The TIDY Business Manager App works best on your mobile phone.  After downloading the app, just log in to continue.': {
        pt: 'O aplicativo TIDY Business Manager funciona melhor no seu celular. Após baixar o aplicativo, basta fazer login para continuar.',
        es: 'La aplicación TIDY Business Manager funciona mejor en su teléfono móvil. Después de descargar la aplicación, simplemente inicie sesión para continuar.',
        ru: 'Приложение TIDY Business Manager лучше всего работает на вашем мобильном телефоне. После загрузки приложения просто войдите в систему, чтобы продолжить.',
        fr: 'L\'application TIDY Business Manager fonctionne mieux sur votre téléphone mobile. Après avoir téléchargé l\'application, connectez-vous simplement pour continuer.',
        de: 'Die TIDY Business Manager App funktioniert am besten auf Ihrem Mobiltelefon. Nach dem Herunterladen der App melden Sie sich einfach an, um fortzufahren.'
    },
    'Download on Google Play': {
        pt: 'Baixar no Google Play',
        es: 'Descargar en Google Play',
        ru: 'Скачать в Google Play',
        fr: 'Télécharger sur Google Play',
        de: 'Im Google Play herunterladen'
    },
    'Download on iOS': {
        pt: 'Baixar no iOS',
        es: 'Descargar en iOS',
        ru: 'Скачать на iOS',
        fr: 'Télécharger sur iOS',
        de: 'Auf iOS herunterladen'
    },
    'Skip and view on your browser': {
        pt: 'Pular e ver no seu navegador',
        es: 'Omitir y ver en su navegador',
        ru: 'Пропустить и просмотреть в браузере',
        fr: 'Passer et voir sur votre navigateur',
        de: 'Überspringen und im Browser ansehen'
    },

    'Edit Display Language': {
        pt: 'Editar Idioma de Exibição',
        es: 'Editar Idioma de Visualización',
        ru: 'Изменить язык отображения',
        fr: 'Modifier la langue d\'affichage',
        de: 'Anzeigesprache bearbeiten'
    },
    'Language': {
        pt: 'Idioma',
        es: 'Idioma',
        ru: 'Язык',
        fr: 'Langue',
        de: 'Sprache'
    },
    'Save Language': {
        pt: 'Salvar Idioma',
        es: 'Guardar Idioma',
        ru: 'Сохранить язык',
        fr: 'Enregistrer la langue',
        de: 'Sprache speichern'
    },
    'English': {
        pt: 'Inglês',
        es: 'Inglés',
        ru: 'Английский',
        fr: 'Anglais',
        de: 'Englisch'
    },
    'Portuguese': {
        pt: 'Português',
        es: 'Portugués',
        ru: 'Португальский',
        fr: 'Portugais',
        de: 'Portugiesisch'
    },
    'Spanish': {
        pt: 'Espanhol',
        es: 'Español',
        ru: 'Испанский',
        fr: 'Espagnol',
        de: 'Spanisch'
    },
    'Russian': {
        pt: 'Russo',
        es: 'Ruso',
        ru: 'Русский',
        fr: 'Russe',
        de: 'Russisch'
    },
    'French': {
        pt: 'Francês',
        es: 'Francés',
        ru: 'Французский',
        fr: 'Français',
        de: 'Französisch'
    },
    'Dutch': {
        pt: 'Holandês',
        es: 'Holandés',
        ru: 'Голландский',
        fr: 'Néerlandais',
        de: 'Niederländisch'
    },

    'Email Confirmed': {
        pt: 'Email Confirmado',
        es: 'Correo Electrónico Confirmado',
        ru: 'Электронная почта подтверждена',
        fr: 'Email Confirmé',
        de: 'E-Mail Bestätigt'
    },
    'Your email was confirmed. Please continue in the app.': {
        pt: 'Seu email foi confirmado. Por favor, continue no aplicativo.',
        es: 'Su correo electrónico fue confirmado. Por favor, continúe en la aplicación.',
        ru: 'Ваш адрес электронной почты был подтвержден. Пожалуйста, продолжайте в приложении.',
        fr: 'Votre email a été confirmé. Veuillez continuer dans l\'application.',
        de: 'Ihre E-Mail wurde bestätigt. Bitte fahren Sie in der App fort.'
    },
    'Link Expired': {
        pt: 'Link Expirado',
        es: 'Enlace Caducado',
        ru: 'Ссылка истекла',
        fr: 'Lien Expiré',
        de: 'Link Abgelaufen'
    },
    'This link isn\'t valid. Please try again or reach out to us in the Concierge section for support.': {
        pt: 'Este link não é válido. Por favor, tente novamente ou entre em contato conosco na seção Concierge para suporte.',
        es: 'Este enlace no es válido. Por favor, inténtelo de nuevo o comuníquese con nosotros en la sección de Concierge para obtener ayuda.',
        ru: 'Эта ссылка недействительна. Пожалуйста, попробуйте еще раз или свяжитесь с нами в разделе Консьерж для поддержки.',
        fr: 'Ce lien n\'est pas valide. Veuillez réessayer ou contactez-nous dans la section Concierge pour obtenir de l\'aide.',
        de: 'Dieser Link ist ungültig. Bitte versuchen Sie es erneut oder wenden Sie sich an uns im Concierge-Bereich für Unterstützung.'
    },
    'Error': {
        pt: 'Erro',
        es: 'Error',
        ru: 'Ошибка',
        fr: 'Erreur',
        de: 'Fehler'
    },

    'Update Password': {
        pt: 'Atualizar Senha',
        es: 'Actualizar Contraseña',
        ru: 'Обновить Пароль',
        fr: 'Mettre à jour le mot de passe',
        de: 'Passwort aktualisieren'
    },
    'Please enter your new password.': {
        pt: 'Por favor, insira sua nova senha.',
        es: 'Por favor, ingrese su nueva contraseña.',
        ru: 'Пожалуйста, введите новый пароль.',
        fr: 'Veuillez entrer votre nouveau mot de passe.',
        de: 'Bitte geben Sie Ihr neues Passwort ein.'
    },
    'New Password': {
        pt: 'Nova Senha',
        es: 'Nueva Contraseña',
        ru: 'Новый Пароль',
        fr: 'Nouveau Mot de Passe',
        de: 'Neues Passwort'
    },
    'Please enter new a password.': {
        pt: 'Por favor, insira uma nova senha.',
        es: 'Por favor, ingrese una nueva contraseña.',
        ru: 'Пожалуйста, введите новый пароль.',
        fr: 'Veuillez entrer un nouveau mot de passe.',
        de: 'Bitte geben Sie ein neues Passwort ein.'
    },
    'Confirm New Password': {
        pt: 'Confirme a Nova Senha',
        es: 'Confirme la Nueva Contraseña',
        ru: 'Подтвердите Новый Пароль',
        fr: 'Confirmez le Nouveau Mot de Passe',
        de: 'Neues Passwort bestätigen'
    },
    'Please confirm your new a password.': {
        pt: 'Por favor, confirme sua nova senha.',
        es: 'Por favor, confirme su nueva contraseña.',
        ru: 'Пожалуйста, подтвердите новый пароль.',
        fr: 'Veuillez confirmer votre nouveau mot de passe.',
        de: 'Bitte bestätigen Sie Ihr neues Passwort.'
    },
    'The password you entered do not match.': {
        pt: 'A senha que você digitou não corresponde.',
        es: 'La contraseña que ingresó no coincide.',
        ru: 'Введенный вами пароль не совпадает.',
        fr: 'Le mot de passe que vous avez entré ne correspond pas.',
        de: 'Das eingegebene Passwort stimmt nicht überein.'
    },
    'Please enter a password with at least 8 characters.': {
        pt: 'Por favor, insira uma senha com pelo menos 8 caracteres.',
        es: 'Por favor, ingrese una contraseña con al menos 8 caracteres.',
        ru: 'Пожалуйста, введите пароль не менее 8 символов.',
        fr: 'Veuillez entrer un mot de passe d\'au moins 8 caractères.',
        de: 'Bitte geben Sie ein Passwort mit mindestens 8 Zeichen ein.'
    },
    'Save New Password': {
        pt: 'Salvar Nova Senha',
        es: 'Guardar Nueva Contraseña',
        ru: 'Сохранить Новый Пароль',
        fr: 'Enregistrer le Nouveau Mot de Passe',
        de: 'Neues Passwort speichern'
    },
    'This link isn\'t valid. Please click "Forgot Password" in the log in page to initiate a new email, or contact us in the Concierge section for support.': {
        pt: 'Este link não é válido. Por favor, clique em "Esqueceu a Senha" na página de login para iniciar um novo email, ou entre em contato conosco na seção Concierge para suporte.',
        es: 'Este enlace no es válido. Por favor, haga clic en "Olvidó su Contraseña" en la página de inicio de sesión para iniciar un nuevo correo electrónico, o comuníquese con nosotros en la sección de Concierge para obtener ayuda.',
        ru: 'Эта ссылка недействительна. Пожалуйста, нажмите "Забыли пароль" на странице входа, чтобы инициировать новое письмо, или свяжитесь с нами в разделе Консьерж для поддержки.',
        fr: 'Ce lien n\'est pas valide. Veuillez cliquer sur "Mot de passe oublié" sur la page de connexion pour initier un nouvel email, ou contactez-nous dans la section Concierge pour obtenir de l\'aide.',
        de: 'Dieser Link ist ungültig. Bitte klicken Sie auf "Passwort vergessen" auf der Anmeldeseite, um eine neue E-Mail zu starten, oder wenden Sie sich an uns im Concierge-Bereich für Unterstützung.'
    },
    'Password Updated': {
        pt: 'Senha Atualizada',
        es: 'Contraseña Actualizada',
        ru: 'Пароль Обновлен',
        fr: 'Mot de Passe Mis à Jour',
        de: 'Passwort Aktualisiert'
    },
    'You can use this new password to log in.': {
        pt: 'Você pode usar esta nova senha para fazer login.',
        es: 'Puede usar esta nueva contraseña para iniciar sesión.',
        ru: 'Вы можете использовать этот новый пароль для входа.',
        fr: 'Vous pouvez utiliser ce nouveau mot de passe pour vous connecter.',
        de: 'Sie können dieses neue Passwort verwenden, um sich anzumelden.'
    },

    'Export Calendar': {
        pt: 'Exportar Calendário',
        es: 'Exportar Calendario',
        ru: 'Экспортировать Календарь',
        fr: 'Exporter le Calendrier',
        de: 'Kalender Exportieren'
    },
    'By tapping this button, your default calendar app (like Google Calendar) should ask you if you want to get jobs to show on your calendar.': {
        pt: 'Ao tocar neste botão, seu aplicativo de calendário padrão (como o Google Calendar) deve perguntar se você deseja que os trabalhos apareçam no seu calendário.',
        es: 'Al tocar este botón, su aplicación de calendario predeterminada (como Google Calendar) debería preguntarle si desea que los trabajos aparezcan en su calendario.',
        ru: 'Нажав эту кнопку, ваше приложение календаря по умолчанию (например, Google Calendar) должно спросить вас, хотите ли вы, чтобы задания отображались в вашем календаре.',
        fr: 'En appuyant sur ce bouton, votre application de calendrier par défaut (comme Google Calendar) devrait vous demander si vous souhaitez que les tâches apparaissent dans votre calendrier.',
        de: 'Wenn Sie auf diese Schaltfläche tippen, sollte Ihre Standard-Kalender-App (wie Google Calendar) Sie fragen, ob Sie möchten, dass die Aufgaben in Ihrem Kalender angezeigt werden.'
    },
    'Export Jobs to Calendar': {
        pt: 'Exportar Trabalhos para o Calendário',
        es: 'Exportar Trabajos al Calendario',
        ru: 'Экспортировать Задания в Календарь',
        fr: 'Exporter les Tâches vers le Calendrier',
        de: 'Aufgaben in den Kalender Exportieren'
    },
    'Not working? Copy and paste this ical link following your calendar app\'s instructions.': {
        pt: 'Não está funcionando? Copie e cole este link ical seguindo as instruções do seu aplicativo de calendário.',
        es: '¿No funciona? Copie y pegue este enlace ical siguiendo las instrucciones de su aplicación de calendario.',
        ru: 'Не работает? Скопируйте и вставьте эту ссылку ical, следуя инструкциям вашего календарного приложения.',
        fr: 'Ça ne fonctionne pas? Copiez et collez ce lien ical en suivant les instructions de votre application de calendrier.',
        de: 'Funktioniert nicht? Kopieren Sie diesen ical-Link und fügen Sie ihn gemäß den Anweisungen Ihrer Kalender-App ein.'
    },
    'Copy Calendar Link': {
        pt: 'Copiar Link do Calendário',
        es: 'Copiar Enlace del Calendario',
        ru: 'Скопировать Ссылку Календаря',
        fr: 'Copier le Lien du Calendrier',
        de: 'Kalenderlink Kopieren'
    },
    'Copied': {
        pt: 'Copiado',
        es: 'Copiado',
        ru: 'Скопировано',
        fr: 'Copié',
        de: 'Kopiert'
    },

    'Quality Score': {
        pt: 'Pontuação de Qualidade',
        es: 'Puntuación de Calidad',
        ru: 'Оценка Качества',
        fr: 'Score de Qualité',
        de: 'Qualitätspunktzahl'
    },
    'This score is how much clients like your cleanings. ': {
        pt: 'Esta pontuação é o quanto os clientes gostam das suas limpezas. ',
        es: 'Esta puntuación es cuánto les gustan sus limpiezas a los clientes. ',
        ru: 'Эта оценка показывает, насколько клиентам нравятся ваши уборки. ',
        fr: 'Ce score indique à quel point vos clients apprécient vos nettoyages. ',
        de: 'Diese Punktzahl zeigt, wie sehr Kunden Ihre Reinigungen mögen. '
    },
    '% retention rate': {
        pt: '% taxa de retenção',
        es: '% tasa de retención',
        ru: '% коэффициент удержания',
        fr: '% taux de rétention',
        de: '% Rückhalterate'
    },
    '% of feedback is positive': {
        pt: '% do feedback é positivo',
        es: '% de los comentarios son positivos',
        ru: '% отзывов положительные',
        fr: '% des retours sont positifs',
        de: '% des Feedbacks sind positiv'
    },
    '% blacklist rate': {
        pt: '% taxa de lista negra',
        es: '% tasa de lista negra',
        ru: '% уровень черного списка',
        fr: '% taux de liste noire',
        de: '% Schwarze Liste Rate'
    },
    'Not enough data - complete more cleanings to build a score.': {
        pt: 'Dados insuficientes - complete mais limpezas para construir uma pontuação.',
        es: 'Datos insuficientes: complete más limpiezas para obtener una puntuación.',
        ru: 'Недостаточно данных - выполните больше уборок, чтобы получить оценку.',
        fr: 'Pas assez de données - effectuez plus de nettoyages pour obtenir un score.',
        de: 'Nicht genügend Daten - führen Sie mehr Reinigungen durch, um eine Punktzahl zu erhalten.'
    },
    'Reliability Score': {
        pt: 'Pontuação de Confiabilidade',
        es: 'Puntuación de Fiabilidad',
        ru: 'Оценка Надежности',
        fr: 'Score de Fiabilité',
        de: 'Zuverlässigkeitspunktzahl'
    },
    'This score is how consistently you show up to cleanings. ': {
        pt: 'Esta pontuação é o quão consistentemente você comparece às limpezas. ',
        es: 'Esta puntuación es cuán consistentemente se presenta a las limpiezas. ',
        ru: 'Эта оценка показывает, насколько регулярно вы приходите на уборки. ',
        fr: 'Ce score indique à quel point vous vous présentez régulièrement aux nettoyages. ',
        de: 'Diese Punktzahl zeigt, wie konsequent Sie zu Reinigungen erscheinen. '
    },
    '% cleanings canceled by you': {
        pt: '% limpezas canceladas por você',
        es: '% limpiezas canceladas por usted',
        ru: '% уборок отменено вами',
        fr: '% nettoyages annulés par vous',
        de: '% Reinigungen von Ihnen storniert'
    },
    '% with < 1 hour notice': {
        pt: '% com aviso de < 1 hora',
        es: '% con aviso de < 1 hora',
        ru: '% с уведомлением менее чем за 1 час',
        fr: '% avec un préavis de < 1 heure',
        de: '% mit < 1 Stunde Vorankündigung'
    },
    '% with 1-24 hour notice': {
        pt: '% com aviso de 1-24 horas',
        es: '% con aviso de 1-24 horas',
        ru: '% с уведомлением за 1-24 часа',
        fr: '% avec un préavis de 1-24 heures',
        de: '% mit 1-24 Stunden Vorankündigung'
    },
    '% with 24-72 hour notice': {
        pt: '% com aviso de 24-72 horas',
        es: '% con aviso de 24-72 horas',
        ru: '% с уведомлением за 24-72 часа',
        fr: '% avec un préavis de 24-72 heures',
        de: '% mit 24-72 Stunden Vorankündigung'
    },
    'Bookability Score': {
        pt: 'Pontuação de Reservabilidade',
        es: 'Puntuación de Reservabilidad',
        ru: 'Оценка Бронируемости',
        fr: 'Score de Réservabilité',
        de: 'Buchbarkeitspunktzahl'
    },
    'This score is how easy it is for clients to book you. ': {
        pt: 'Esta pontuação é o quão fácil é para os clientes reservarem você. ',
        es: 'Esta puntuación es cuán fácil es para los clientes reservarle. ',
        ru: 'Эта оценка показывает, насколько легко клиентам вас забронировать. ',
        fr: 'Ce score indique à quel point il est facile pour les clients de vous réserver. ',
        de: 'Diese Punktzahl zeigt, wie einfach es für Kunden ist, Sie zu buchen. '
    },
    'Consistency': {
        pt: 'Consistência',
        es: 'Consistencia',
        ru: 'Последовательность',
        fr: 'Cohérence',
        de: 'Konsistenz'
    },
    'Increase by not changing your schedule often. Many repeat clients say this is the <b>most important</b> thing!': {
        pt: 'Aumente não mudando seu cronograma com frequência. Muitos clientes recorrentes dizem que isso é a coisa <b>mais importante</b>!',
        es: 'Aumente no cambiando su horario a menudo. ¡Muchos clientes recurrentes dicen que esto es lo <b>más importante</b>!',
        ru: 'Увеличьте, не меняя свой график часто. Многие постоянные клиенты говорят, что это <b>самое важное</b>!',
        fr: 'Augmentez en ne changeant pas souvent votre emploi du temps. De nombreux clients réguliers disent que c\'est la chose la <b>plus importante</b>!',
        de: 'Erhöhen Sie, indem Sie Ihren Zeitplan nicht oft ändern. Viele Stammkunden sagen, dass dies das <b>wichtigste</b> ist!'
    },
    'Continuity': {
        pt: 'Continuidade',
        es: 'Continuidad',
        ru: 'Непрерывность',
        fr: 'Continuité',
        de: 'Kontinuität'
    },
    'Increase by being available for multiple times in a row (you need 3 blocks in a row for a 4 hour job).': {
        pt: 'Aumente estando disponível várias vezes seguidas (você precisa de 3 blocos seguidos para um trabalho de 4 horas).',
        es: 'Aumente estando disponible varias veces seguidas (necesita 3 bloques seguidos para un trabajo de 4 horas).',
        ru: 'Увеличьте, будучи доступным несколько раз подряд (вам нужно 3 блока подряд для 4-часовой работы).',
        fr: 'Augmentez en étant disponible plusieurs fois de suite (vous avez besoin de 3 blocs d\'affilée pour un travail de 4 heures).',
        de: 'Erhöhen Sie, indem Sie mehrmals hintereinander verfügbar sind (Sie benötigen 3 Blöcke hintereinander für einen 4-Stunden-Job).'
    },
    'Times': {
        pt: 'Horários',
        es: 'Tiempos',
        ru: 'Времена',
        fr: 'Temps',
        de: 'Zeiten'
    },
    'Increase by being available for times that have more demand.  See below for the best available times:': {
        pt: 'Aumente estando disponível para horários que têm mais demanda. Veja abaixo os melhores horários disponíveis:',
        es: 'Aumente estando disponible para horarios que tienen más demanda. Vea a continuación los mejores horarios disponibles:',
        ru: 'Увеличьте, будучи доступным в периоды с большим спросом. См. ниже лучшие доступные времена:',
        fr: 'Augmentez en étant disponible pour les horaires qui ont plus de demande. Voir ci-dessous les meilleurs horaires disponibles :',
        de: 'Erhöhen Sie, indem Sie zu Zeiten verfügbar sind, die mehr Nachfrage haben. Siehe unten für die besten verfügbaren Zeiten:'
    },
    'Job Streaks': {
        pt: 'Sequências de Trabalhos',
        es: 'Rachas de Trabajos',
        ru: 'Серии Работ',
        fr: 'Séries de Travaux',
        de: 'Job-Serien'
    },
    'Complete cleanings in a row without a cancelation to extend your streak. ': {
        pt: 'Complete limpezas seguidas sem cancelamento para estender sua sequência. ',
        es: 'Complete limpiezas seguidas sin cancelación para extender su racha. ',
        ru: 'Выполняйте уборки подряд без отмены, чтобы продлить свою серию. ',
        fr: 'Effectuez des nettoyages consécutifs sans annulation pour prolonger votre série. ',
        de: 'Führen Sie Reinigungen in Folge ohne Stornierung durch, um Ihre Serie zu verlängern. '
    },
    'Current Streak': {
        pt: 'Sequência Atual',
        es: 'Racha Actual',
        ru: 'Текущая Серия',
        fr: 'Série Actuelle',
        de: 'Aktuelle Serie'
    },
    ' Jobs': {
        pt: ' Trabalhos',
        es: ' Trabajos',
        ru: ' Работы',
        fr: ' Travaux',
        de: ' Jobs'
    },
    'Longest Streak': {
        pt: 'Maior Sequência',
        es: 'Racha Más Larga',
        ru: 'Самая Длинная Серия',
        fr: 'Plus Longue Série',
        de: 'Längste Serie'
    },

    'Clients from TIDY': {
        pt: 'Clientes da TIDY',
        es: 'Clientes de TIDY',
        ru: 'Клиенты от TIDY',
        fr: 'Clients de TIDY',
        de: 'Kunden von TIDY'
    },
    'Start with the median rates in your area, or edit them to what matches your business.': {
        pt: 'Comece com as taxas medianas em sua área ou edite-as para o que corresponde ao seu negócio.',
        es: 'Comience con las tarifas medianas en su área o edítelas para que coincidan con su negocio.',
        ru: 'Начните со средних ставок в вашем районе или отредактируйте их в соответствии с вашим бизнесом.',
        fr: 'Commencez avec les tarifs médians dans votre région ou modifiez-les pour qu\'ils correspondent à votre entreprise.',
        de: 'Beginnen Sie mit den mittleren Preisen in Ihrer Region oder passen Sie sie an Ihr Unternehmen an.'
    },
    'Edit Rates': {
        pt: 'Editar Taxas',
        es: 'Editar Tarifas',
        ru: 'Редактировать ставки',
        fr: 'Modifier les tarifs',
        de: 'Preise bearbeiten'
    },
    'New Client Rate: ': {
        pt: 'Taxa de Novo Cliente: ',
        es: 'Tarifa de Nuevo Cliente: ',
        ru: 'Ставка нового клиента: ',
        fr: 'Tarif Nouveau Client: ',
        de: 'Neukundenrate: '
    },
    'Repeat Client Rate: ': {
        pt: 'Taxa de Cliente Repetido: ',
        es: 'Tarifa de Cliente Recurrente: ',
        ru: 'Ставка повторного клиента: ',
        fr: 'Tarif Client Répétitif: ',
        de: 'Wiederholungsrate: '
    },
    'You can now get Clients from TIDY. Please ensure your schedule, max driving distance, and other settings are correct.': {
        pt: 'Agora você pode obter clientes da TIDY. Por favor, certifique-se de que sua agenda, distância máxima de condução e outras configurações estão corretas.',
        es: 'Ahora puede obtener clientes de TIDY. Asegúrese de que su horario, la distancia máxima de conducción y otras configuraciones sean correctas.',
        ru: 'Теперь вы можете получать клиентов от TIDY. Пожалуйста, убедитесь, что ваш график, максимальное расстояние вождения и другие настройки правильны.',
        fr: 'Vous pouvez maintenant obtenir des clients de TIDY. Veuillez vous assurer que votre emploi du temps, la distance de conduite maximale et d\'autres paramètres sont corrects.',
        de: 'Sie können jetzt Kunden von TIDY erhalten. Bitte stellen Sie sicher, dass Ihr Zeitplan, die maximale Fahrstrecke und andere Einstellungen korrekt sind.'
    },

    'Finish the steps to add existing Clients to access this page, it\'s quick and easy. ': {
        pt: 'Conclua as etapas para adicionar clientes existentes para acessar esta página, é rápido e fácil. ',
        es: 'Termine los pasos para agregar clientes existentes para acceder a esta página, es rápido y fácil. ',
        ru: 'Завершите шаги по добавлению существующих клиентов для доступа к этой странице, это быстро и легко. ',
        fr: 'Terminez les étapes pour ajouter des clients existants pour accéder à cette page, c\'est rapide et facile. ',
        de: 'Schließen Sie die Schritte ab, um vorhandene Kunden hinzuzufügen, um auf diese Seite zuzugreifen, es ist schnell und einfach. '
    },
    'Make yourself bookable to allow Clients to book on your schedule. ': {
        pt: 'Torne-se reservável para permitir que os clientes reservem em sua agenda. ',
        es: 'Hágase reservable para permitir que los clientes reserven en su horario. ',
        ru: 'Сделайте себя доступным для бронирования, чтобы клиенты могли бронировать по вашему расписанию. ',
        fr: 'Rendez-vous réservable pour permettre aux clients de réserver sur votre emploi du temps. ',
        de: 'Machen Sie sich buchbar, damit Kunden in Ihrem Zeitplan buchen können. '
    },
    'Complete the steps to get new Clients from TIDY to access this page. It\'s quick, easy, and free. ': {
        pt: 'Conclua as etapas para obter novos clientes da TIDY para acessar esta página. É rápido, fácil e gratuito. ',
        es: 'Complete los pasos para obtener nuevos clientes de TIDY para acceder a esta página. Es rápido, fácil y gratuito. ',
        ru: 'Завершите шаги, чтобы получить новых клиентов от TIDY для доступа к этой странице. Это быстро, легко и бесплатно. ',
        fr: 'Complétez les étapes pour obtenir de nouveaux clients de TIDY pour accéder à cette page. C\'est rapide, facile et gratuit. ',
        de: 'Schließen Sie die Schritte ab, um neue Kunden von TIDY zu erhalten, um auf diese Seite zuzugreifen. Es ist schnell, einfach und kostenlos. '
    },

    'Contest Dispute': {
        pt: 'Contestar Disputa',
        es: 'Contestar Disputa',
        ru: 'Оспорить Спор',
        fr: 'Contester le Litige',
        de: 'Streit Anfechten'
    },
    'The Client said ': {
        pt: 'O Cliente disse ',
        es: 'El Cliente dijo ',
        ru: 'Клиент сказал ',
        fr: 'Le Client a dit ',
        de: 'Der Kunde sagte '
    },
    ' and is disputing this cleaning.': {
        pt: ' e está contestando esta limpeza.',
        es: ' y está disputando esta limpieza.',
        ru: ' и оспаривает эту уборку.',
        fr: ' et conteste ce nettoyage.',
        de: ' und bestreitet diese Reinigung.'
    },
    'We are here to help you so please provide as much information as possible.': {
        pt: 'Estamos aqui para ajudá-lo, por favor forneça o máximo de informações possível.',
        es: 'Estamos aquí para ayudarle, por favor proporcione tanta información como sea posible.',
        ru: 'Мы здесь, чтобы помочь вам, пожалуйста, предоставьте как можно больше информации.',
        fr: 'Nous sommes là pour vous aider, veuillez fournir autant d\'informations que possible.',
        de: 'Wir sind hier, um Ihnen zu helfen, bitte geben Sie so viele Informationen wie möglich an.'
    },
    'Do you have photos as evidence? ': {
        pt: 'Você tem fotos como evidência? ',
        es: '¿Tiene fotos como evidencia? ',
        ru: 'У вас есть фотографии в качестве доказательства? ',
        fr: 'Avez-vous des photos comme preuve? ',
        de: 'Haben Sie Fotos als Beweis? '
    },
    'Add Photo': {
        pt: 'Adicionar Foto',
        es: 'Agregar Foto',
        ru: 'Добавить Фото',
        fr: 'Ajouter une Photo',
        de: 'Foto Hinzufügen'
    },
    'Why are you contesting this dispute?': {
        pt: 'Por que você está contestando esta disputa?',
        es: '¿Por qué está contestando esta disputa?',
        ru: 'Почему вы оспариваете этот спор?',
        fr: 'Pourquoi contestez-vous ce litige?',
        de: 'Warum fechten Sie diesen Streit an?'
    },
    'Please let us know why you are contesting.': {
        pt: 'Por favor, deixe-nos saber por que você está contestando.',
        es: 'Por favor, háganos saber por qué está contestando.',
        ru: 'Пожалуйста, дайте нам знать, почему вы оспариваете.',
        fr: 'Veuillez nous faire savoir pourquoi vous contestez.',
        de: 'Bitte lassen Sie uns wissen, warum Sie anfechten.'
    },
    'Submit Evidence': {
        pt: 'Enviar Evidência',
        es: 'Enviar Evidencia',
        ru: 'Отправить Доказательства',
        fr: 'Soumettre des Preuves',
        de: 'Beweise Einreichen'
    },
    'Dispute Contested': {
        pt: 'Disputa Contestada',
        es: 'Disputa Contestada',
        ru: 'Спор Оспорен',
        fr: 'Litige Contesté',
        de: 'Streit Angefochten'
    },
    'Our Concierge will review and respond in 2-3 days': {
        pt: 'Nosso Concierge revisará e responderá em 2-3 dias',
        es: 'Nuestro Conserje revisará y responderá en 2-3 días',
        ru: 'Наш Консьерж рассмотрит и ответит в течение 2-3 дней',
        fr: 'Notre Concierge examinera et répondra dans 2-3 jours',
        de: 'Unser Concierge wird die Überprüfung durchführen und innerhalb von 2-3 Tagen antworten'
    },
    'Go to Jobs': {
        pt: 'Ir para Trabalhos',
        es: 'Ir a Trabajos',
        ru: 'Перейти к Работам',
        fr: 'Aller aux Travaux',
        de: 'Zu den Jobs Gehen'
    },

    'Not Contesting Dispute': {
        pt: 'Não Contestando a Disputa',
        es: 'No Contestando la Disputa',
        ru: 'Не Оспаривая Спор',
        fr: 'Ne Pas Contester le Litige',
        de: 'Den Streit Nicht Anfechten'
    },
    'Please confirm that you do NOT want to contest the dispute.': {
        pt: 'Por favor, confirme que você NÃO quer contestar a disputa.',
        es: 'Por favor, confirme que NO quiere contestar la disputa.',
        ru: 'Пожалуйста, подтвердите, что вы НЕ хотите оспаривать спор.',
        fr: 'Veuillez confirmer que vous NE souhaitez PAS contester le litige.',
        de: 'Bitte bestätigen Sie, dass Sie den Streit NICHT anfechten möchten.'
    },
    'Opted to not contest the dispute': {
        pt: 'Optou por não contestar a disputa',
        es: 'Optó por no contestar la disputa',
        ru: 'Решил не оспаривать спор',
        fr: 'A choisi de ne pas contester le litige',
        de: 'Entschied sich, den Streit nicht anzufechten'
    },

    'Future Job': {
        pt: 'Trabalho Futuro',
        es: 'Trabajo Futuro',
        ru: 'Будущая Работа',
        fr: 'Travail Futur',
        de: 'Zukünftiger Job'
    },
    'You requested that ': {
        pt: 'Você solicitou que ',
        es: 'Solicitaste que ',
        ru: 'Вы запросили, чтобы ',
        fr: 'Vous avez demandé que ',
        de: 'Sie haben angefordert, dass '
    },
    ' accept this job. We sent an email to ': {
        pt: ' aceite este trabalho. Enviamos um e-mail para ',
        es: ' acepte este trabajo. Enviamos un correo electrónico a ',
        ru: ' приняли эту работу. Мы отправили электронное письмо на ',
        fr: ' accepte ce travail. Nous avons envoyé un e-mail à ',
        de: ' diesen Job akzeptiert. Wir haben eine E-Mail an '
    },
    'This job was delegated to you by ': {
        pt: 'Este trabalho foi delegado a você por ',
        es: 'Este trabajo fue delegado a usted por ',
        ru: 'Эта работа была делегирована вам ',
        fr: 'Ce travail vous a été délégué par ',
        de: 'Dieser Job wurde Ihnen von '
    },
    '.  All payment will be made to them. ': {
        pt: '. Todo o pagamento será feito a eles. ',
        es: '. Todo el pago se realizará a ellos. ',
        ru: '. Все платежи будут сделаны им. ',
        fr: '. Tous les paiements leur seront versés. ',
        de: '. Alle Zahlungen werden an sie geleistet. '
    },
    'Undo': {
        pt: 'Desfazer',
        es: 'Deshacer',
        ru: 'Отменить',
        fr: 'Annuler',
        de: 'Rückgängig machen'
    },
    'You delegated this job to ': {
        pt: 'Você delegou este trabalho para ',
        es: 'Delegaste este trabajo a ',
        ru: 'Вы делегировали эту работу ',
        fr: 'Vous avez délégué ce travail à ',
        de: 'Sie haben diesen Job delegiert an '
    },
    '.  All payment will be made to you. ': {
        pt: '. Todo o pagamento será feito a você. ',
        es: '. Todo el pago se realizará a usted. ',
        ru: '. Все платежи будут сделаны вам. ',
        fr: '. Tous les paiements vous seront versés. ',
        de: '. Alle Zahlungen werden an Sie geleistet. '
    },
    'This job is for a future date. Check the morning of the job to get client\'s address, to dos list, and more information..': {
        pt: 'Este trabalho é para uma data futura. Verifique na manhã do trabalho para obter o endereço do cliente, lista de tarefas e mais informações..',
        es: 'Este trabajo es para una fecha futura. Verifique la mañana del trabajo para obtener la dirección del cliente, lista de tareas y más información..',
        ru: 'Эта работа на будущую дату. Проверьте утром в день работы, чтобы получить адрес клиента, список дел и дополнительную информацию..',
        fr: 'Ce travail est pour une date future. Vérifiez le matin du travail pour obtenir l\'adresse du client, la liste des tâches et plus d\'informations..',
        de: 'Dieser Job ist für ein zukünftiges Datum. Überprüfen Sie am Morgen des Jobs die Adresse des Kunden, die Aufgabenliste und weitere Informationen..'
    },
    'Client ': {
        pt: 'Cliente ',
        es: 'Cliente ',
        ru: 'Клиент ',
        fr: 'Client ',
        de: 'Kunde '
    },
    'Cleaning': {
        pt: 'Limpeza',
        es: 'Limpieza',
        ru: 'Уборка',
        fr: 'Nettoyage',
        de: 'Reinigung'
    },
    'Delegate to Team Member': {
        pt: 'Delegar para Membro da Equipe',
        es: 'Delegar a Miembro del Equipo',
        ru: 'Делегировать члену команды',
        fr: 'Déléguer à un membre de l\'équipe',
        de: 'An Teammitglied delegieren'
    },
    'Cancel and Mark Me Unavailable': {
        pt: 'Cancelar e Marcar-me como Indisponível',
        es: 'Cancelar y Marcarme como No Disponible',
        ru: 'Отменить и отметить меня как недоступного',
        fr: 'Annuler et me marquer comme indisponible',
        de: 'Stornieren und mich als nicht verfügbar markieren'
    },
    'Undo Acceptance?': {
        pt: 'Desfazer Aceitação?',
        es: '¿Deshacer Aceptación?',
        ru: 'Отменить Принятие?',
        fr: 'Annuler l\'Acceptation?',
        de: 'Annahme Rückgängig Machen?'
    },
    'Undo Delegation?': {
        pt: 'Desfazer Delegação?',
        es: '¿Deshacer Delegación?',
        ru: 'Отменить Делегирование?',
        fr: 'Annuler la Délégation?',
        de: 'Delegation Rückgängig Machen?'
    },
    'Please confirm you want to complete this job yourself.': {
        pt: 'Por favor, confirme que você deseja completar este trabalho você mesmo.',
        es: 'Por favor, confirme que desea completar este trabajo usted mismo.',
        ru: 'Пожалуйста, подтвердите, что вы хотите выполнить эту работу самостоятельно.',
        fr: 'Veuillez confirmer que vous souhaitez effectuer ce travail vous-même.',
        de: 'Bitte bestätigen Sie, dass Sie diesen Job selbst erledigen möchten.'
    },
    'You are expected to complete this job.': {
        pt: 'Espera-se que você complete este trabalho.',
        es: 'Se espera que completes este trabajo.',
        ru: 'Ожидается, что вы выполните эту работу.',
        fr: 'Vous êtes censé terminer ce travail.',
        de: 'Es wird erwartet, dass Sie diesen Job abschließen.'
    },
    'Acceptance Undone': {
        pt: 'Aceitação Desfeita',
        es: 'Aceptación Deshecha',
        ru: 'Принятие Отменено',
        fr: 'Acceptation Annulée',
        de: 'Annahme Rückgängig Gemacht'
    },
    'Delegation Undone': {
        pt: 'Delegação Desfeita',
        es: 'Delegación Deshecha',
        ru: 'Делегирование Отменено',
        fr: 'Délégation Annulée',
        de: 'Delegation Rückgängig Gemacht'
    },

    'Missing Item': {
        pt: 'Item Perdido',
        es: 'Artículo Perdido',
        ru: 'Пропавший Предмет',
        fr: 'Objet Manquant',
        de: 'Fehlender Gegenstand'
    },
    'Please describe the item': {
        pt: 'Por favor, descreva o item',
        es: 'Por favor, describa el artículo',
        ru: 'Пожалуйста, опишите предмет',
        fr: 'Veuillez décrire l\'objet',
        de: 'Bitte beschreiben Sie den Gegenstand'
    },
    'We will notify the other party.': {
        pt: 'Nós notificaremos a outra parte.',
        es: 'Notificaremos a la otra parte.',
        ru: 'Мы уведомим другую сторону.',
        fr: 'Nous informerons l\'autre partie.',
        de: 'Wir werden die andere Partei benachrichtigen.'
    },

    'Client Emailed': {
        pt: 'Cliente E-mail',
        es: 'Cliente Enviado por Correo Electrónico',
        ru: 'Клиент Отправлен по Электронной Почте',
        fr: 'Client Envoyé par Courriel',
        de: 'Kunde per E-Mail'
    },
    'We have emailed the other party requesting they call you to help coordinate a pickup. This temporary session will automatically close whenever both parties stop communicating for 48 hours.': {
        pt: 'Enviamos um e-mail para a outra parte solicitando que liguem para você para ajudar a coordenar a coleta. Esta sessão temporária será automaticamente encerrada sempre que ambas as partes pararem de se comunicar por 48 horas.',
        es: 'Hemos enviado un correo electrónico a la otra parte solicitando que lo llamen para ayudar a coordinar una recogida. Esta sesión temporal se cerrará automáticamente siempre que ambas partes dejen de comunicarse durante 48 horas.',
        ru: 'Мы отправили письмо другой стороне с просьбой позвонить вам, чтобы помочь скоординировать получение. Эта временная сессия автоматически закроется, если обе стороны перестанут общаться в течение 48 часов.',
        fr: 'Nous avons envoyé un courriel à l\'autre partie en leur demandant de vous appeler pour aider à coordonner un ramassage. Cette session temporaire se fermera automatiquement dès que les deux parties cesseront de communiquer pendant 48 heures.',
        de: 'Wir haben der anderen Partei eine E-Mail geschickt und sie gebeten, Sie anzurufen, um die Abholung zu koordinieren. Diese temporäre Sitzung wird automatisch geschlossen, wenn beide Parteien 48 Stunden lang nicht mehr kommunizieren.'
    },
    'Go to My Jobs': {
        pt: 'Ir para Meus Trabalhos',
        es: 'Ir a Mis Trabajos',
        ru: 'Перейти к Моим Работам',
        fr: 'Aller à Mes Travaux',
        de: 'Zu Meinen Jobs Gehen'
    },

    'Past Job': {
        pt: 'Trabalho Anterior',
        es: 'Trabajo Anterior',
        ru: 'Прошлая Работа',
        fr: 'Travail Précédent',
        de: 'Vergangener Job'
    },
    'Waiting on You': {
        pt: 'Esperando por Você',
        es: 'Esperando por Ti',
        ru: 'Ожидание Вас',
        fr: 'En Attente de Vous',
        de: 'Warten auf Sie'
    },
    'Waiting on TIDY': {
        pt: 'Esperando pelo TIDY',
        es: 'Esperando a TIDY',
        ru: 'Ожидание TIDY',
        fr: 'En Attente de TIDY',
        de: 'Warten auf TIDY'
    },
    'Status: ': {
        pt: 'Status: ',
        es: 'Estado: ',
        ru: 'Статус: ',
        fr: 'Statut: ',
        de: 'Status: '
    },
    'Job Delegated to You': {
        pt: 'Trabalho Delegado a Você',
        es: 'Trabajo Delegado a Ti',
        ru: 'Работа Делегирована Вам',
        fr: 'Travail Délégué à Vous',
        de: 'Job an Sie Delegiert'
    },
    'Job Delegated to ': {
        pt: 'Trabalho Delegado a ',
        es: 'Trabajo Delegado a ',
        ru: 'Работа Делегирована ',
        fr: 'Travail Délégué à ',
        de: 'Job Delegiert an '
    },
    ' and they completed it. All payment will be made to you. ': {
        pt: ' e eles o completaram. Todo o pagamento será feito a você. ',
        es: ' y lo completaron. Todo el pago se realizará a ti. ',
        ru: ' и они его завершили. Все платежи будут сделаны вам. ',
        fr: ' et ils l\'ont complété. Tous les paiements vous seront versés. ',
        de: ' und sie haben es abgeschlossen. Alle Zahlungen werden an Sie geleistet. '
    },
    '. All payment will be made to them. ': {
        pt: '. Todo o pagamento será feito a eles. ',
        es: '. Todo el pago se realizará a ellos. ',
        ru: '. Все платежи будут сделаны им. ',
        fr: '. Tous les paiements leur seront versés. ',
        de: '. Alle Zahlungen werden an sie geleistet. '
    },
    'Reimbursement Pending': {
        pt: 'Reembolso Pendente',
        es: 'Reembolso Pendiente',
        ru: 'Возмещение в Ожидании',
        fr: 'Remboursement en Attente',
        de: 'Erstattung Ausstehend'
    },
    'We received your parking reimbursement request and have contacted the Client. We\'ll update the cleaning when we receive their response.': {
        pt: 'Recebemos sua solicitação de reembolso de estacionamento e contatamos o Cliente. Atualizaremos a limpeza quando recebermos a resposta deles.',
        es: 'Recibimos su solicitud de reembolso de estacionamiento y hemos contactado al Cliente. Actualizaremos la limpieza cuando recibamos su respuesta.',
        ru: 'Мы получили ваш запрос на возмещение расходов на парковку и связались с клиентом. Мы обновим уборку, когда получим их ответ.',
        fr: 'Nous avons reçu votre demande de remboursement de stationnement et avons contacté le client. Nous mettrons à jour le nettoyage lorsque nous recevrons leur réponse.',
        de: 'Wir haben Ihre Anfrage zur Erstattung der Parkgebühren erhalten und den Kunden kontaktiert. Wir werden die Reinigung aktualisieren, wenn wir ihre Antwort erhalten.'
    },
    'Cleaning Disputed ': {
        pt: 'Limpeza Contestada ',
        es: 'Limpieza Disputada ',
        ru: 'Уборка Оспорена ',
        fr: 'Nettoyage Contesté ',
        de: 'Reinigung Angefochten '
    },
    '(Dispute Counter Sent)': {
        pt: '(Contador de Disputa Enviado)',
        es: '(Contador de Disputa Enviado)',
        ru: '(Счетчик Спора Отправлен)',
        fr: '(Compteur de Litige Envoyé)',
        de: '(Streit Zähler Gesendet)'
    },
    'The dispute has been contested and is waiting on the Client response. You will receive a response in 2-5 days.': {
        pt: 'A disputa foi contestada e está aguardando a resposta do Cliente. Você receberá uma resposta em 2-5 dias.',
        es: 'La disputa ha sido impugnada y está esperando la respuesta del Cliente. Recibirá una respuesta en 2-5 días.',
        ru: 'Спор был оспорен и ожидает ответа клиента. Вы получите ответ в течение 2-5 дней.',
        fr: 'Le litige a été contesté et est en attente de la réponse du client. Vous recevrez une réponse dans 2-5 jours.',
        de: 'Der Streit wurde angefochten und wartet auf die Antwort des Kunden. Sie erhalten eine Antwort in 2-5 Tagen.'
    },
    'The dispute has been Lost.': {
        pt: 'A disputa foi perdida.',
        es: 'La disputa se ha perdido.',
        ru: 'Спор проигран.',
        fr: 'Le litige a été perdu.',
        de: 'Der Streit wurde verloren.'
    },
    'The dispute has been contested and won.': {
        pt: 'A disputa foi contestada e vencida.',
        es: 'La disputa ha sido impugnada y ganada.',
        ru: 'Спор был оспорен и выигран.',
        fr: 'Le litige a été contesté et gagné.',
        de: 'Der Streit wurde angefochten und gewonnen.'
    },
    'Do you want to contest the dispute?': {
        pt: 'Você quer contestar a disputa?',
        es: '¿Quieres impugnar la disputa?',
        ru: 'Вы хотите оспорить спор?',
        fr: 'Voulez-vous contester le litige?',
        de: 'Möchten Sie den Streit anfechten?'
    },
    'Check Dispute Details': {
        pt: 'Verificar Detalhes da Disputa',
        es: 'Verificar Detalles de la Disputa',
        ru: 'Проверить Детали Спора',
        fr: 'Vérifier les Détails du Litige',
        de: 'Streitdetails Überprüfen'
    },
    'Reimbursement Approved': {
        pt: 'Reembolso Aprovado',
        es: 'Reembolso Aprobado',
        ru: 'Возмещение Одобрено',
        fr: 'Remboursement Approuvé',
        de: 'Erstattung Genehmigt'
    },
    'The Client has 72 hours to cancel, after which this will be added to your payment.': {
        pt: 'O Cliente tem 72 horas para cancelar, após o que isso será adicionado ao seu pagamento.',
        es: 'El Cliente tiene 72 horas para cancelar, después de lo cual esto se añadirá a su pago.',
        ru: 'У клиента есть 72 часа на отмену, после чего это будет добавлено к вашему платежу.',
        fr: 'Le client a 72 heures pour annuler, après quoi cela sera ajouté à votre paiement.',
        de: 'Der Kunde hat 72 Stunden Zeit, um zu stornieren, danach wird dies zu Ihrer Zahlung hinzugefügt.'
    },
    'Accelerated': {
        pt: 'Acelerado',
        es: 'Acelerado',
        ru: 'Ускорено',
        fr: 'Accéléré',
        de: 'Beschleunigt'
    },
    'Fee Waived': {
        pt: 'Taxa Dispensada',
        es: 'Tarifa Exonerada',
        ru: 'Плата Отменена',
        fr: 'Frais Exonérés',
        de: 'Gebühr Erlassen'
    },
    'Earnings Breakdown': {
        pt: 'Detalhamento dos Ganhos',
        es: 'Desglose de Ganancias',
        ru: 'Разбивка Доходов',
        fr: 'Répartition des Gains',
        de: 'Einkommensaufteilung'
    },
    'This client first booked you on ': {
        pt: 'Este cliente primeiro reservou você em ',
        es: 'Este cliente primero te reservó el ',
        ru: 'Этот клиент впервые забронировал вас ',
        fr: 'Ce client vous a d\'abord réservé le ',
        de: 'Dieser Kunde hat Sie zuerst am gebucht '
    },
    ', prior to your most recent rate change. With your current rates the base payment would be ': {
        pt: ', antes da sua alteração de tarifa mais recente. Com suas tarifas atuais, o pagamento base seria ',
        es: ', antes de su cambio de tarifa más reciente. Con sus tarifas actuales, el pago base sería ',
        ru: ', до вашего последнего изменения тарифа. С вашими текущими тарифами базовая оплата составила бы ',
        fr: ', avant votre dernier changement de tarif. Avec vos tarifs actuels, le paiement de base serait ',
        de: ', vor Ihrer letzten Tarifänderung. Mit Ihren aktuellen Tarifen wäre die Grundzahlung '
    },
    'Total ': {
        pt: 'Total ',
        es: 'Total ',
        ru: 'Итого ',
        fr: 'Total ',
        de: 'Gesamt '
    },
    'Waive Fee': {
        pt: 'Dispensar Taxa',
        es: 'Exonerar Tarifa',
        ru: 'Отменить Плату',
        fr: 'Exonérer les Frais',
        de: 'Gebühr Erlassen'
    },
    'Payment History': {
        pt: 'Histórico de Pagamentos',
        es: 'Historial de Pagos',
        ru: 'История Платежей',
        fr: 'Historique des Paiements',
        de: 'Zahlungshistorie'
    },
    'No payments for this job yet. ': {
        pt: 'Ainda não há pagamentos para este trabalho. ',
        es: 'Aún no hay pagos para este trabajo. ',
        ru: 'Платежей за эту работу пока нет. ',
        fr: 'Pas encore de paiements pour ce travail. ',
        de: 'Noch keine Zahlungen für diesen Job. '
    },
    'Estimated to be included in the ': {
        pt: 'Estimado para ser incluído no ',
        es: 'Estimado para ser incluido en el ',
        ru: 'Предполагается включение в ',
        fr: 'Estimé pour être inclus dans le ',
        de: 'Voraussichtlich enthalten in der '
    },
    ' payment.': {
        pt: ' pagamento.',
        es: ' pago.',
        ru: ' платеж.',
        fr: ' paiement.',
        de: ' Zahlung.'
    },
    'All payment will be made to ': {
        pt: 'Todo o pagamento será feito para ',
        es: 'Todo el pago se realizará a ',
        ru: 'Все платежи будут сделаны ',
        fr: 'Tous les paiements seront effectués à ',
        de: 'Alle Zahlungen werden an '
    },
    '. Contact them for details.': {
        pt: '. Contate-os para mais detalhes.',
        es: '. Contáctelos para más detalles.',
        ru: '. Свяжитесь с ними для получения подробной информации.',
        fr: '. Contactez-les pour plus de détails.',
        de: '. Kontaktieren Sie sie für weitere Details.'
    },
    'Mileage Tracking': {
        pt: 'Rastreamento de Quilometragem',
        es: 'Seguimiento de Kilometraje',
        ru: 'Отслеживание Пробега',
        fr: 'Suivi du Kilométrage',
        de: 'Kilometerverfolgung'
    },
    'Miles Travelled: ': {
        pt: 'Milhas Percorridas: ',
        es: 'Millas Recorridas: ',
        ru: 'Пройденные Мили: ',
        fr: 'Miles Parcourus: ',
        de: 'Gefahrene Meilen: '
    },
    'View ': {
        pt: 'Ver ',
        es: 'Ver ',
        ru: 'Просмотр ',
        fr: 'Voir ',
        de: 'Ansehen '
    },
    ' More Message': {
        pt: ' Mais Mensagem',
        es: ' Más Mensaje',
        ru: ' Больше Сообщений',
        fr: ' Plus de Message',
        de: ' Mehr Nachricht'
    },
    ' More Messages': {
        pt: ' Mais Mensagens',
        es: ' Más Mensajes',
        ru: ' Больше Сообщений',
        fr: ' Plus de Messages',
        de: ' Mehr Nachrichten'
    },
    'Cleaning Updates': {
        pt: 'Atualizações de Limpeza',
        es: 'Actualizaciones de Limpieza',
        ru: 'Обновления Уборки',
        fr: 'Mises à Jour de Nettoyage',
        de: 'Reinigungsaktualisierungen'
    },
    'No Updates': {
        pt: 'Sem Atualizações',
        es: 'Sin Actualizaciones',
        ru: 'Нет Обновлений',
        fr: 'Pas de Mises à Jour',
        de: 'Keine Aktualisierungen'
    },
    'Client Blacklisted': {
        pt: 'Cliente na Lista Negra',
        es: 'Cliente en la Lista Negra',
        ru: 'Клиент в Черном Списке',
        fr: 'Client sur Liste Noire',
        de: 'Kunde auf der Schwarzen Liste'
    },
    'You will no longer be paired with ': {
        pt: 'Você não será mais emparelhado com ',
        es: 'Ya no estarás emparejado con ',
        ru: 'Вы больше не будете связаны с ',
        fr: 'Vous ne serez plus jumelé avec ',
        de: 'Sie werden nicht mehr mit '
    },
    'Log Issue': {
        pt: 'Registrar Problema',
        es: 'Registrar Problema',
        ru: 'Записать Проблему',
        fr: 'Enregistrer le Problème',
        de: 'Problem Protokollieren'
    },
    'Missing an Item?': {
        pt: 'Faltando um Item?',
        es: '¿Falta un Artículo?',
        ru: 'Пропал Предмет?',
        fr: 'Un Objet Manquant?',
        de: 'Fehlt ein Gegenstand?'
    },
    'Request Parking Reimbursement': {
        pt: 'Solicitar Reembolso de Estacionamento',
        es: 'Solicitar Reembolso de Estacionamiento',
        ru: 'Запросить Возмещение за Парковку',
        fr: 'Demander un Remboursement de Stationnement',
        de: 'Parkgebühr Erstattung Anfordern'
    },
    'Blacklist this Client': {
        pt: 'Colocar este Cliente na Lista Negra',
        es: 'Poner a este Cliente en la Lista Negra',
        ru: 'Добавить этого Клиента в Черный Список',
        fr: 'Mettre ce Client sur Liste Noire',
        de: 'Diesen Kunden auf die Schwarze Liste Setzen'
    },
    'Change before photo': {
        pt: 'Alterar foto antes',
        es: 'Cambiar foto antes',
        ru: 'Изменить фото до',
        fr: 'Changer la photo avant',
        de: 'Vorherige Foto ändern'
    },
    'Change after photo': {
        pt: 'Alterar foto depois',
        es: 'Cambiar foto después',
        ru: 'Изменить фото после',
        fr: 'Changer la photo après',
        de: 'Nachher Foto ändern'
    },
    'Add before photo': {
        pt: 'Adicionar foto antes',
        es: 'Agregar foto antes',
        ru: 'Добавить фото до',
        fr: 'Ajouter la photo avant',
        de: 'Vorher Foto hinzufügen'
    },
    'Add after photo': {
        pt: 'Adicionar foto depois',
        es: 'Agregar foto después',
        ru: 'Добавить фото после',
        fr: 'Ajouter la photo après',
        de: 'Nachher Foto hinzufügen'
    },
    'Blacklist Client?': {
        pt: 'Cliente na Lista Negra?',
        es: '¿Cliente en la Lista Negra?',
        ru: 'Клиент в Черном Списке?',
        fr: 'Client sur Liste Noire?',
        de: 'Kunde auf der Schwarzen Liste?'
    },
    'Adding this Client to your blacklist will permanently remove them from your schedule.  The Client is not notified.': {
        pt: 'Adicionar este Cliente à sua lista negra irá removê-lo permanentemente da sua agenda. O Cliente não será notificado.',
        es: 'Agregar este Cliente a tu lista negra lo eliminará permanentemente de tu agenda. El Cliente no será notificado.',
        ru: 'Добавление этого Клиента в ваш черный список навсегда удалит его из вашего расписания. Клиент не будет уведомлен.',
        fr: 'Ajouter ce Client à votre liste noire le supprimera définitivement de votre emploi du temps. Le Client n\'est pas informé.',
        de: 'Das Hinzufügen dieses Kunden zu Ihrer schwarzen Liste wird ihn dauerhaft aus Ihrem Zeitplan entfernen. Der Kunde wird nicht benachrichtigt.'
    },
    'The Client was permanently removed from your schedule.': {
        pt: 'O Cliente foi removido permanentemente da sua agenda.',
        es: 'El Cliente fue eliminado permanentemente de tu agenda.',
        ru: 'Клиент был навсегда удален из вашего расписания.',
        fr: 'Le Client a été définitivement retiré de votre emploi du temps.',
        de: 'Der Kunde wurde dauerhaft aus Ihrem Zeitplan entfernt.'
    },
    'you left early': {
        pt: 'você saiu cedo',
        es: 'te fuiste temprano',
        ru: 'вы ушли рано',
        fr: 'vous êtes parti tôt',
        de: 'du bist früh gegangen'
    },
    'you didnt have all your equipment or brought the wrong equipment': {
        pt: 'você não tinha todo o seu equipamento ou trouxe o equipamento errado',
        es: 'no tenías todo tu equipo o trajiste el equipo equivocado',
        ru: 'у вас не было всего вашего оборудования или вы принесли неправильное оборудование',
        fr: 'vous n\'aviez pas tout votre équipement ou vous avez apporté le mauvais équipement',
        de: 'du hattest nicht alle deine Ausrüstung oder hast die falsche Ausrüstung mitgebracht'
    },
    'you did not show up to the cleaning': {
        pt: 'você não apareceu para a limpeza',
        es: 'no te presentaste a la limpieza',
        ru: 'вы не пришли на уборку',
        fr: 'vous ne vous êtes pas présenté au nettoyage',
        de: 'du bist nicht zur Reinigung erschienen'
    },
    'you showed up to the cleaning but did not clean at all': {
        pt: 'você apareceu para a limpeza, mas não limpou nada',
        es: 'te presentaste a la limpieza pero no limpiaste nada',
        ru: 'вы пришли на уборку, но совсем не убирались',
        fr: 'vous vous êtes présenté au nettoyage mais n\'avez rien nettoyé',
        de: 'du bist zur Reinigung erschienen, hast aber überhaupt nicht gereinigt'
    },
    'you brought an unauthorized third party to the home': {
        pt: 'você trouxe uma terceira parte não autorizada para a casa',
        es: 'trajiste a un tercero no autorizado a la casa',
        ru: 'вы привели в дом несанкционированное третье лицо',
        fr: 'vous avez amené une tierce partie non autorisée à la maison',
        de: 'du hast eine unbefugte dritte Person ins Haus gebracht'
    },
    'you provided false updates': {
        pt: 'você forneceu atualizações falsas',
        es: 'proporcionaste actualizaciones falsas',
        ru: 'вы предоставили ложные обновления',
        fr: 'vous avez fourni de fausses mises à jour',
        de: 'du hast falsche Updates bereitgestellt'
    },
    'you stole something': {
        pt: 'você roubou algo',
        es: 'robaste algo',
        ru: 'вы что-то украли',
        fr: 'vous avez volé quelque chose',
        de: 'du hast etwas gestohlen'
    },
    'you caused some damage': {
        pt: 'você causou algum dano',
        es: 'causaste algún daño',
        ru: 'вы нанесли некоторый ущерб',
        fr: 'vous avez causé des dommages',
        de: 'du hast einige Schäden verursacht'
    },
    'you were not wearing a mask': {
        pt: 'você não estava usando máscara',
        es: 'no estabas usando una máscara',
        ru: 'вы не носили маску',
        fr: 'vous ne portiez pas de masque',
        de: 'du hast keine Maske getragen'
    },
    'you did not have your vacuum with you': {
        pt: 'você não tinha seu aspirador com você',
        es: 'no tenías tu aspiradora contigo',
        ru: 'у вас не было с собой пылесоса',
        fr: 'vous n\'aviez pas votre aspirateur avec vous',
        de: 'du hattest deinen Staubsauger nicht dabei'
    },
    'Reported Issue: Low Inventory': {
        pt: 'Problema Relatado: Baixo Estoque',
        es: 'Problema Reportado: Bajo Inventario',
        ru: 'Сообщенная Проблема: Низкий Запас',
        fr: 'Problème Signalé: Faible Inventaire',
        de: 'Gemeldetes Problem: Niedriger Bestand'
    },
    'Reported Issue: Damage': {
        pt: 'Problema Relatado: Danos',
        es: 'Problema Reportado: Daños',
        ru: 'Сообщенная Проблема: Повреждение',
        fr: 'Problème Signalé: Dommages',
        de: 'Gemeldetes Problem: Schaden'
    },
    'Reported Issue: Pest Report': {
        pt: 'Problema Relatado: Relatório de Pragas',
        es: 'Problema Reportado: Informe de Plagas',
        ru: 'Сообщенная Проблема: Отчет о Вредителях',
        fr: 'Problème Signalé: Rapport de Nuisibles',
        de: 'Gemeldetes Problem: Schädlingsbericht'
    },
    'Reported Issue: Utility Issue': {
        pt: 'Problema Relatado: Problema de Utilidade',
        es: 'Problema Reportado: Problema de Utilidad',
        ru: 'Сообщенная Проблема: Проблема с Коммунальными Услугами',
        fr: 'Problème Signalé: Problème d\'Utilité',
        de: 'Gemeldetes Problem: Versorgungsproblem'
    },

    'Jobs': {
        pt: 'Trabalhos',
        es: 'Trabajos',
        ru: 'Работы',
        fr: 'Emplois',
        de: 'Jobs'
    },
    'No Jobs This Week': {
        pt: 'Nenhum Trabalho Esta Semana',
        es: 'No Hay Trabajos Esta Semana',
        ru: 'На Этой Неделе Работ Нет',
        fr: 'Pas de Travaux Cette Semaine',
        de: 'Keine Jobs Diese Woche'
    },
    'Add existing jobs by adding a client then jobs for them. To get more clients, we recommend getting certifications, opening  your schedule to be open, increasing your service area, & making your rates competitive.': {
        pt: 'Adicione trabalhos existentes adicionando um cliente e depois trabalhos para eles. Para obter mais clientes, recomendamos obter certificações, abrir sua agenda para estar disponível, aumentar sua área de serviço e tornar suas tarifas competitivas.',
        es: 'Agregue trabajos existentes agregando un cliente y luego trabajos para ellos. Para obtener más clientes, recomendamos obtener certificaciones, abrir su horario para estar disponible, aumentar su área de servicio y hacer que sus tarifas sean competitivas.',
        ru: 'Добавьте существующие работы, добавив клиента, а затем работы для него. Чтобы получить больше клиентов, мы рекомендуем получить сертификаты, открыть свое расписание, увеличить зону обслуживания и сделать свои тарифы конкурентоспособными.',
        fr: 'Ajoutez des travaux existants en ajoutant un client, puis des travaux pour eux. Pour obtenir plus de clients, nous recommandons d\'obtenir des certifications, d\'ouvrir votre emploi du temps pour être disponible, d\'augmenter votre zone de service et de rendre vos tarifs compétitifs.',
        de: 'Fügen Sie bestehende Jobs hinzu, indem Sie einen Kunden hinzufügen und dann Jobs für ihn. Um mehr Kunden zu gewinnen, empfehlen wir, Zertifizierungen zu erhalten, Ihren Zeitplan zu öffnen, Ihr Servicegebiet zu erweitern und Ihre Preise wettbewerbsfähig zu gestalten.'
    },
    'No Jobs Scheduled': {
        pt: 'Nenhum Trabalho Agendado',
        es: 'No Hay Trabajos Programados',
        ru: 'Работы Не Запланированы',
        fr: 'Aucun Travail Prévu',
        de: 'Keine Jobs Geplant'
    },
    ' (Big Spender)': {
        pt: ' (Grande Gastador)',
        es: ' (Gran Gastador)',
        ru: ' (Большой Транжира)',
        fr: ' (Gros Dépenseur)',
        de: ' (Großer Ausgeber)'
    },
    'Booking Protection Pending': {
        pt: 'Proteção de Reserva Pendente',
        es: 'Protección de Reserva Pendiente',
        ru: 'Ожидается Защита Бронирования',
        fr: 'Protection de Réservation en Attente',
        de: 'Buchungsschutz Ausstehend'
    },
    'Action Needed': {
        pt: 'Ação Necessária',
        es: 'Acción Necesaria',
        ru: 'Требуется Действие',
        fr: 'Action Nécessaire',
        de: 'Aktion Erforderlich'
    },
    'Under Review': {
        pt: 'Em Revisão',
        es: 'En Revisión',
        ru: 'На Рассмотрении',
        fr: 'En Cours de Révision',
        de: 'In Überprüfung'
    },
    'Time Sensitive Job': {
        pt: 'Trabalho Sensível ao Tempo',
        es: 'Trabajo Sensible al Tiempo',
        ru: 'Времязависимая Работа',
        fr: 'Travail Sensible au Temps',
        de: 'Zeitkritischer Job'
    },
    'Undo Cancellation': {
        pt: 'Desfazer Cancelamento',
        es: 'Deshacer Cancelación',
        ru: 'Отменить Отмену',
        fr: 'Annuler l\'Annulation',
        de: 'Stornierung Rückgängig Machen'
    },
    'Delegated to: ': {
        pt: 'Delegado para: ',
        es: 'Delegado a: ',
        ru: 'Делегировано: ',
        fr: 'Délégué à: ',
        de: 'Delegiert an: '
    },
    'Delegated from: ': {
        pt: 'Delegado de: ',
        es: 'Delegado de: ',
        ru: 'Делегировано от: ',
        fr: 'Délégué de: ',
        de: 'Delegiert von: '
    },
    'Pending delegation': {
        pt: 'Delegação Pendente',
        es: 'Delegación Pendiente',
        ru: 'Ожидается Делегирование',
        fr: 'Délégation en Attente',
        de: 'Delegierung Ausstehend'
    },
    ' to your invited team member': {
        pt: ' ao seu membro da equipe convidado',
        es: ' a su miembro del equipo invitado',
        ru: ' вашему приглашенному члену команды',
        fr: ' à votre membre d\'équipe invité',
        de: ' zu Ihrem eingeladenen Teammitglied'
    },
    'Nightly 8pm Cutoff': {
        pt: 'Corte Noturno às 20h',
        es: 'Corte Nocturno a las 8pm',
        ru: 'Ежедневный Окончание в 20:00',
        fr: 'Coupure Nocturne à 20h',
        de: 'Nächtliche 20 Uhr Frist'
    },
    'Jobs may change until 8pm ': {
        pt: 'Os trabalhos podem mudar até às 20h ',
        es: 'Los trabajos pueden cambiar hasta las 8pm ',
        ru: 'Работы могут измениться до 20:00 ',
        fr: 'Les travaux peuvent changer jusqu\'à 20h ',
        de: 'Jobs können sich bis 20 Uhr ändern '
    },
    'Address Pending': {
        pt: 'Endereço Pendente',
        es: 'Dirección Pendiente',
        ru: 'Адрес Ожидается',
        fr: 'Adresse en Attente',
        de: 'Adresse Ausstehend'
    },

    'Please check back later to see if a client has been found.  This is still considered a booked job at this time, and is NOT cancelled.  This job is covered by Booking Protection, which means we are trying to find the best client for you during this time.': {
        pt: 'Por favor, volte mais tarde para ver se um cliente foi encontrado. Isso ainda é considerado um trabalho reservado neste momento, e NÃO está cancelado. Este trabalho está coberto pela Proteção de Reserva, o que significa que estamos tentando encontrar o melhor cliente para você durante este tempo.',
        es: 'Por favor, vuelva más tarde para ver si se ha encontrado un cliente. Esto todavía se considera un trabajo reservado en este momento, y NO está cancelado. Este trabajo está cubierto por la Protección de Reservas, lo que significa que estamos tratando de encontrar el mejor cliente para usted durante este tiempo.',
        ru: 'Пожалуйста, вернитесь позже, чтобы узнать, найден ли клиент. Это все еще считается забронированной работой на данный момент и НЕ отменено. Эта работа покрывается Защитой Бронирования, что означает, что мы пытаемся найти для вас лучшего клиента в это время.',
        fr: 'Veuillez revenir plus tard pour voir si un client a été trouvé. Cela est toujours considéré comme un travail réservé à ce moment, et n\'est PAS annulé. Ce travail est couvert par la Protection de Réservation, ce qui signifie que nous essayons de trouver le meilleur client pour vous pendant ce temps.',
        de: 'Bitte kommen Sie später wieder, um zu sehen, ob ein Kunde gefunden wurde. Dies wird zu diesem Zeitpunkt immer noch als gebuchter Auftrag betrachtet und ist NICHT storniert. Dieser Auftrag ist durch den Buchungsschutz abgedeckt, was bedeutet, dass wir versuchen, den besten Kunden für Sie in dieser Zeit zu finden.'
    },

    ' jobs': {
        pt: ' trabalhos',
        es: ' trabajos',
        ru: ' работы',
        fr: ' travaux',
        de: ' Jobs'
    },
    'No jobs': {
        pt: 'Sem trabalhos',
        es: 'Sin trabajos',
        ru: 'Нет работ',
        fr: 'Pas de travaux',
        de: 'Keine Jobs'
    },
    'Forgot Password': {
        pt: 'Esqueceu a Senha',
        es: 'Olvidó la Contraseña',
        ru: 'Забыли Пароль',
        fr: 'Mot de Passe Oublié',
        de: 'Passwort Vergessen'
    },
    'Email': {
        pt: 'Email',
        es: 'Correo Electrónico',
        ru: 'Электронная Почта',
        fr: 'Email',
        de: 'E-Mail'
    },
    'Send Reset Password Email': {
        pt: 'Enviar Email de Redefinição de Senha',
        es: 'Enviar Correo Electrónico para Restablecer Contraseña',
        ru: 'Отправить письмо для сброса пароля',
        fr: 'Envoyer un Email de Réinitialisation de Mot de Passe',
        de: 'E-Mail zum Zurücksetzen des Passworts senden'
    },
    'We couldn\'t find an account matching this email.': {
        pt: 'Não conseguimos encontrar uma conta correspondente a este email.',
        es: 'No pudimos encontrar una cuenta que coincida con este correo electrónico.',
        ru: 'Мы не смогли найти учетную запись, соответствующую этому адресу электронной почты.',
        fr: 'Nous n\'avons pas pu trouver de compte correspondant à cet email.',
        de: 'Wir konnten kein Konto finden, das zu dieser E-Mail-Adresse passt.'
    },
    'Please Check Your Email': {
        pt: 'Por favor, verifique seu email',
        es: 'Por favor, revise su correo electrónico',
        ru: 'Пожалуйста, проверьте свою электронную почту',
        fr: 'Veuillez vérifier votre email',
        de: 'Bitte überprüfen Sie Ihre E-Mails'
    },
    'We\'ve sent a link to create a new password.': {
        pt: 'Enviamos um link para criar uma nova senha.',
        es: 'Hemos enviado un enlace para crear una nueva contraseña.',
        ru: 'Мы отправили ссылку для создания нового пароля.',
        fr: 'Nous avons envoyé un lien pour créer un nouveau mot de passe.',
        de: 'Wir haben einen Link zum Erstellen eines neuen Passworts gesendet.'
    },

    'TIDY.com for Pros': {
        pt: 'TIDY.com para Profissionais',
        es: 'TIDY.com para Profesionales',
        ru: 'TIDY.com для Профессионалов',
        fr: 'TIDY.com pour les Pros',
        de: 'TIDY.com für Profis'
    },
    'TIDY.com for Pros helps you earn more money on your terms.': {
        pt: 'TIDY.com para Profissionais ajuda você a ganhar mais dinheiro nos seus termos.',
        es: 'TIDY.com para Profesionales te ayuda a ganar más dinero en tus términos.',
        ru: 'TIDY.com для Профессионалов помогает вам зарабатывать больше денег на ваших условиях.',
        fr: 'TIDY.com pour les Pros vous aide à gagner plus d\'argent selon vos conditions.',
        de: 'TIDY.com für Profis hilft Ihnen, mehr Geld zu Ihren Bedingungen zu verdienen.'
    },
    'Earn More': {
        pt: 'Ganhe Mais',
        es: 'Gana Más',
        ru: 'Зарабатывайте Больше',
        fr: 'Gagnez Plus',
        de: 'Verdienen Sie Mehr'
    },
    'Get Clients when you want, let your customers book you online, and collect payments.': {
        pt: 'Consiga Clientes quando quiser, deixe seus clientes reservarem você online e colete pagamentos.',
        es: 'Consigue Clientes cuando quieras, deja que tus clientes te reserven en línea y cobra pagos.',
        ru: 'Получайте клиентов, когда хотите, позволяйте вашим клиентам бронировать вас онлайн и собирайте платежи.',
        fr: 'Obtenez des Clients quand vous le souhaitez, laissez vos clients vous réserver en ligne et collectez les paiements.',
        de: 'Erhalten Sie Kunden, wann Sie möchten, lassen Sie Ihre Kunden online buchen und Zahlungen einziehen.'
    },
    'Save Money': {
        pt: 'Economize Dinheiro',
        es: 'Ahorra Dinero',
        ru: 'Экономьте Деньги',
        fr: 'Économisez de l\'Argent',
        de: 'Sparen Sie Geld'
    },
    'Smart drive time optimization limits how much you drive. Track mileage and equipment usage to write off on taxes. Never pay more than you have to.': {
        pt: 'A otimização inteligente do tempo de condução limita o quanto você dirige. Acompanhe a quilometragem e o uso de equipamentos para deduzir nos impostos. Nunca pague mais do que o necessário.',
        es: 'La optimización inteligente del tiempo de conducción limita cuánto conduces. Rastrea el kilometraje y el uso de equipos para deducir en impuestos. Nunca pagues más de lo necesario.',
        ru: 'Умная оптимизация времени вождения ограничивает, сколько вы ездите. Отслеживайте пробег и использование оборудования для списания налогов. Никогда не платите больше, чем нужно.',
        fr: 'L\'optimisation intelligente du temps de conduite limite votre conduite. Suivez le kilométrage et l\'utilisation de l\'équipement pour déduire des impôts. Ne payez jamais plus que nécessaire.',
        de: 'Die intelligente Optimierung der Fahrzeit begrenzt, wie viel Sie fahren. Verfolgen Sie den Kilometerstand und die Nutzung der Ausrüstung, um Steuern abzuschreiben. Zahlen Sie niemals mehr, als Sie müssen.'
    },
    'Save Time': {
        pt: 'Economize Tempo',
        es: 'Ahorra Tiempo',
        ru: 'Экономьте Время',
        fr: 'Gagnez du Temps',
        de: 'Sparen Sie Zeit'
    },
    'Send reminders, collect payments from customers, let Clients download invoices and reschedule themselves, and more.': {
        pt: 'Envie lembretes, colete pagamentos de clientes, deixe os Clientes baixarem faturas e reagendarem-se, e mais.',
        es: 'Envía recordatorios, cobra pagos de clientes, deja que los Clientes descarguen facturas y se reprogramen, y más.',
        ru: 'Отправляйте напоминания, собирайте платежи от клиентов, позволяйте Клиентам загружать счета и перепланировать себя, и многое другое.',
        fr: 'Envoyez des rappels, collectez les paiements des clients, laissez les Clients télécharger des factures et se reprogrammer, et plus encore.',
        de: 'Senden Sie Erinnerungen, sammeln Sie Zahlungen von Kunden, lassen Sie Kunden Rechnungen herunterladen und sich selbst umplanen, und mehr.'
    },
    'Delight Clients': {
        pt: 'Encante os Clientes',
        es: 'Deleita a los Clientes',
        ru: 'Удовлетворяйте Клиентов',
        fr: 'Ravissez les Clients',
        de: 'Begeistern Sie Kunden'
    },
    'Let Clients see maps of you coming to them, track what they want done in their home, and let them do things in their online portal.': {
        pt: 'Deixe os Clientes verem mapas de você indo até eles, acompanhe o que eles querem que seja feito em sua casa e deixe-os fazer coisas em seu portal online.',
        es: 'Deja que los Clientes vean mapas de ti yendo hacia ellos, rastrea lo que quieren que se haga en su hogar y déjalos hacer cosas en su portal en línea.',
        ru: 'Позвольте Клиентам видеть карты вашего прибытия к ним, отслеживать, что они хотят сделать в своем доме, и позволять им делать вещи в своем онлайн-портале.',
        fr: 'Laissez les Clients voir des cartes de vous venant à eux, suivez ce qu\'ils veulent faire dans leur maison et laissez-les faire des choses dans leur portail en ligne.',
        de: 'Lassen Sie Kunden Karten sehen, auf denen Sie zu ihnen kommen, verfolgen Sie, was sie in ihrem Zuhause erledigt haben möchten, und lassen Sie sie Dinge in ihrem Online-Portal erledigen.'
    },
    'TIDY for pros helps you earn more with less time on admin work.': {
        pt: 'TIDY para profissionais ajuda você a ganhar mais com menos tempo em trabalho administrativo.',
        es: 'TIDY para profesionales te ayuda a ganar más con menos tiempo en trabajo administrativo.',
        ru: 'TIDY для профессионалов помогает вам зарабатывать больше с меньшими затратами времени на административную работу.',
        fr: 'TIDY pour les pros vous aide à gagner plus avec moins de temps sur le travail administratif.',
        de: 'TIDY für Profis hilft Ihnen, mehr zu verdienen, mit weniger Zeit für Verwaltungsarbeit.'
    },
    'Great pros earn up to $200/day more.*': {
        pt: 'Grandes profissionais ganham até $200/dia a mais.*',
        es: 'Grandes profesionales ganan hasta $200/día más.*',
        ru: 'Отличные профессионалы зарабатывают до $200/день больше.*',
        fr: 'Les grands pros gagnent jusqu\'à $200/jour de plus.*',
        de: 'Großartige Profis verdienen bis zu $200/Tag mehr.*'
    },
    'Control Everything': {
        pt: 'Controle Tudo',
        es: 'Controla Todo',
        ru: 'Контролируйте Всё',
        fr: 'Contrôlez Tout',
        de: 'Kontrollieren Sie Alles'
    },
    'Pick your schedule, area you serve, & services.': {
        pt: 'Escolha seu horário, área de atendimento e serviços.',
        es: 'Elige tu horario, área de servicio y servicios.',
        ru: 'Выберите свой график, область обслуживания и услуги.',
        fr: 'Choisissez votre emploi du temps, votre zone de service et vos services.',
        de: 'Wählen Sie Ihren Zeitplan, Ihr Einsatzgebiet und Ihre Dienstleistungen.'
    },
    'Save Time & Money': {
        pt: 'Economize Tempo e Dinheiro',
        es: 'Ahorra Tiempo y Dinero',
        ru: 'Экономьте Время и Деньги',
        fr: 'Économisez du Temps et de l\'Argent',
        de: 'Sparen Sie Zeit und Geld'
    },
    'Let Clients book & pay online. Optimize driving & deductions.': {
        pt: 'Deixe os Clientes reservarem e pagarem online. Otimize a condução e as deduções.',
        es: 'Deja que los Clientes reserven y paguen en línea. Optimiza la conducción y las deducciones.',
        ru: 'Позвольте Клиентам бронировать и оплачивать онлайн. Оптимизируйте вождение и вычеты.',
        fr: 'Laissez les Clients réserver et payer en ligne. Optimisez la conduite et les déductions.',
        de: 'Lassen Sie Kunden online buchen und bezahlen. Optimieren Sie das Fahren und die Abzüge.'
    },
    'Delight Your Clients': {
        pt: 'Encante Seus Clientes',
        es: 'Deleita a Tus Clientes',
        ru: 'Удовлетворяйте Ваших Клиентов',
        fr: 'Ravissez Vos Clients',
        de: 'Begeistern Sie Ihre Kunden'
    },
    'Share To Do lists & receipts with Clients.': {
        pt: 'Compartilhe listas de tarefas e recibos com os Clientes.',
        es: 'Comparte listas de tareas y recibos con los Clientes.',
        ru: 'Делитесь списками дел и квитанциями с Клиентами.',
        fr: 'Partagez des listes de tâches et des reçus avec les Clients.',
        de: 'Teilen Sie To-Do-Listen und Quittungen mit Kunden.'
    },
    'Fast, Easy, & Free': {
        pt: 'Rápido, Fácil e Gratuito',
        es: 'Rápido, Fácil y Gratis',
        ru: 'Быстро, Легко и Бесплатно',
        fr: 'Rapide, Facile et Gratuit',
        de: 'Schnell, Einfach und Kostenlos'
    },
    'Get your 1st Client in as little as 2 days.**': {
        pt: 'Consiga seu 1º Cliente em apenas 2 dias.**',
        es: 'Consigue tu 1er Cliente en tan solo 2 días.**',
        ru: 'Получите своего первого Клиента всего за 2 дня.**',
        fr: 'Obtenez votre 1er Client en seulement 2 jours.**',
        de: 'Gewinnen Sie Ihren ersten Kunden in nur 2 Tagen.**'
    },
    'Create Account': {
        pt: 'Criar Conta',
        es: 'Crear Cuenta',
        ru: 'Создать Аккаунт',
        fr: 'Créer un Compte',
        de: 'Konto Erstellen'
    },
    'Log In': {
        pt: 'Entrar',
        es: 'Iniciar Sesión',
        ru: 'Войти',
        fr: 'Se Connecter',
        de: 'Einloggen'
    },
    '* Potential earnings from our marketing tools vary by your location, skill, settings, and more.': {
        pt: '* Os ganhos potenciais de nossas ferramentas de marketing variam de acordo com sua localização, habilidade, configurações e mais.',
        es: '* Las ganancias potenciales de nuestras herramientas de marketing varían según su ubicación, habilidad, configuraciones y más.',
        ru: '* Потенциальный доход от наших маркетинговых инструментов зависит от вашего местоположения, навыков, настроек и других факторов.',
        fr: '* Les gains potentiels de nos outils marketing varient en fonction de votre localisation, de vos compétences, de vos paramètres, etc.',
        de: '* Potenzielle Einnahmen aus unseren Marketing-Tools variieren je nach Standort, Fähigkeiten, Einstellungen und mehr.'
    },
    '** Using the app is free, but there are some paid features you will be notified of in advance.': {
        pt: '** Usar o aplicativo é gratuito, mas existem alguns recursos pagos dos quais você será notificado com antecedência.',
        es: '** Usar la aplicación es gratis, pero hay algunas funciones de pago de las que se le notificará con anticipación.',
        ru: '** Использование приложения бесплатно, но есть некоторые платные функции, о которых вы будете уведомлены заранее.',
        fr: '** L\'utilisation de l\'application est gratuite, mais certaines fonctionnalités payantes vous seront notifiées à l\'avance.',
        de: '** Die Nutzung der App ist kostenlos, aber es gibt einige kostenpflichtige Funktionen, über die Sie im Voraus informiert werden.'
    },
    'TIDY.com for Pros is for bona-fide professionals with their own business (sole proprietorships & new businesses are ok). By using the software you are a customer & absolutely in no way a TIDY.com employee. This is not for you if you are a “gig worker” or looking for employment. We never employ home service workers, but may connect you with clients for your business. By signing up you agree to the ': {
        pt: 'TIDY.com para Profissionais é para profissionais genuínos com seu próprio negócio (empresas individuais e novos negócios são permitidos). Ao usar o software, você é um cliente e de forma alguma um funcionário da TIDY.com. Isso não é para você se você for um "trabalhador temporário" ou estiver procurando emprego. Nunca empregamos trabalhadores de serviços domésticos, mas podemos conectá-lo com clientes para o seu negócio. Ao se inscrever, você concorda com o ',
        es: 'TIDY.com para Profesionales es para profesionales genuinos con su propio negocio (se permiten empresas individuales y nuevos negocios). Al usar el software, usted es un cliente y de ninguna manera un empleado de TIDY.com. Esto no es para usted si es un "trabajador temporal" o está buscando empleo. Nunca empleamos trabajadores de servicios domésticos, pero podemos conectarlo con clientes para su negocio. Al registrarse, usted acepta el ',
        ru: 'TIDY.com для Профессионалов предназначен для добросовестных профессионалов с собственным бизнесом (индивидуальные предприниматели и новые предприятия допускаются). Используя программное обеспечение, вы являетесь клиентом и ни в коем случае не сотрудником TIDY.com. Это не для вас, если вы "временный работник" или ищете работу. Мы никогда не нанимаем работников домашнего обслуживания, но можем связать вас с клиентами для вашего бизнеса. Регистрируясь, вы соглашаетесь с ',
        fr: 'TIDY.com pour les Pros est destiné aux professionnels de bonne foi avec leur propre entreprise (les entreprises individuelles et les nouvelles entreprises sont acceptées). En utilisant le logiciel, vous êtes un client et en aucun cas un employé de TIDY.com. Ce n\'est pas pour vous si vous êtes un "travailleur temporaire" ou à la recherche d\'un emploi. Nous n\'employons jamais de travailleurs de services à domicile, mais nous pouvons vous mettre en relation avec des clients pour votre entreprise. En vous inscrivant, vous acceptez le ',
        de: 'TIDY.com für Profis ist für echte Profis mit eigenem Unternehmen (Einzelunternehmen und neue Unternehmen sind in Ordnung). Durch die Nutzung der Software sind Sie ein Kunde und in keiner Weise ein Mitarbeiter von TIDY.com. Dies ist nicht für Sie, wenn Sie ein "Gelegenheitsarbeiter" sind oder eine Anstellung suchen. Wir beschäftigen niemals Hausdienstleister, können Sie jedoch mit Kunden für Ihr Unternehmen verbinden. Durch die Anmeldung stimmen Sie dem '
    },
    'Service Provider Customer Agreement.': {
        pt: 'Acordo de Cliente do Prestador de Serviços.',
        es: 'Acuerdo de Cliente del Proveedor de Servicios.',
        ru: 'Соглашение с Клиентом Поставщика Услуг.',
        fr: 'Accord de Client du Fournisseur de Services.',
        de: 'Kundenvereinbarung für Dienstleister.'
    },

    'Job Completed!': {
        pt: 'Trabalho Concluído!',
        es: '¡Trabajo Completado!',
        ru: 'Работа Завершена!',
        fr: 'Travail Terminé!',
        de: 'Arbeit Abgeschlossen!'
    },
    'Log in to see details for this job. You can also browse available jobs. It\'s free to use.': {
        pt: 'Faça login para ver os detalhes deste trabalho. Você também pode procurar trabalhos disponíveis. É grátis para usar.',
        es: 'Inicia sesión para ver los detalles de este trabajo. También puedes buscar trabajos disponibles. Es gratis para usar.',
        ru: 'Войдите, чтобы увидеть детали этой работы. Вы также можете просматривать доступные вакансии. Это бесплатно.',
        fr: 'Connectez-vous pour voir les détails de ce travail. Vous pouvez également parcourir les emplois disponibles. C\'est gratuit à utiliser.',
        de: 'Melden Sie sich an, um Details zu diesem Job zu sehen. Sie können auch verfügbare Jobs durchsuchen. Es ist kostenlos zu benutzen.'
    },
    'Log in to send an invoice & instantly request payment. You can also browse available jobs. It\’s free to use.': {
        pt: 'Faça login para enviar uma fatura e solicitar pagamento instantaneamente. Você também pode procurar trabalhos disponíveis. É grátis para usar.',
        es: 'Inicia sesión para enviar una factura y solicitar el pago instantáneamente. También puedes buscar trabajos disponibles. Es gratis para usar.',
        ru: 'Войдите, чтобы отправить счет и мгновенно запросить оплату. Вы также можете просматривать доступные вакансии. Это бесплатно.',
        fr: 'Connectez-vous pour envoyer une facture et demander un paiement instantanément. Vous pouvez également parcourir les emplois disponibles. C\'est gratuit à utiliser.',
        de: 'Melden Sie sich an, um eine Rechnung zu senden und sofortige Zahlung anzufordern. Sie können auch verfügbare Jobs durchsuchen. Es ist kostenlos zu benutzen.'
    },
    'Password': {
        pt: 'Senha',
        es: 'Contraseña',
        ru: 'Пароль',
        fr: 'Mot de passe',
        de: 'Passwort'
    },
    'Please enter a password': {
        pt: 'Por favor, insira uma senha',
        es: 'Por favor, ingrese una contraseña',
        ru: 'Пожалуйста, введите пароль',
        fr: 'Veuillez entrer un mot de passe',
        de: 'Bitte geben Sie ein Passwort ein'
    },
    'The email and password you entered did not match our records. Please double-check and try again.': {
        pt: 'O e-mail e a senha que você inseriu não correspondem aos nossos registros. Por favor, verifique e tente novamente.',
        es: 'El correo electrónico y la contraseña que ingresaste no coinciden con nuestros registros. Por favor, verifica e intenta nuevamente.',
        ru: 'Введенные вами адрес электронной почты и пароль не совпадают с нашими записями. Пожалуйста, проверьте и попробуйте снова.',
        fr: 'L\'e-mail et le mot de passe que vous avez entrés ne correspondent pas à nos dossiers. Veuillez vérifier et réessayer.',
        de: 'Die von Ihnen eingegebene E-Mail-Adresse und das Passwort stimmen nicht mit unseren Aufzeichnungen überein. Bitte überprüfen Sie und versuchen Sie es erneut.'
    },

    'No messages in the last 30 days': {
        pt: 'Nenhuma mensagem nos últimos 30 dias',
        es: 'No hay mensajes en los últimos 30 días',
        ru: 'Нет сообщений за последние 30 дней',
        fr: 'Aucun message au cours des 30 derniers jours',
        de: 'Keine Nachrichten in den letzten 30 Tagen'
    },
    ' Job Message': {
        pt: ' Mensagem de Trabalho',
        es: ' Mensaje de Trabajo',
        ru: ' Сообщение о Работе',
        fr: ' Message de Travail',
        de: ' Arbeitsnachricht'
    },
    ' Messages': {
        pt: ' Mensagens',
        es: ' Mensajes',
        ru: ' Сообщения',
        fr: ' Messages',
        de: ' Nachrichten'
    },
    ' More': {
        pt: ' Mais',
        es: ' Más',
        ru: ' Больше',
        fr: ' Plus',
        de: ' Mehr'
    },
    'Today ': {
        pt: 'Hoje ',
        es: 'Hoy ',
        ru: 'Сегодня ',
        fr: 'Aujourd\'hui ',
        de: 'Heute '
    },
    'You are on standby for this job. You can call/text the Client after indicating you arrived.': {
        pt: 'Você está em espera para este trabalho. Você pode ligar / enviar uma mensagem de texto para o cliente após indicar que chegou.',
        es: 'Estás en espera para este trabajo. Puedes llamar / enviar un mensaje de texto al cliente después de indicar que has llegado.',
        ru: 'Вы находитесь в режиме ожидания для этой работы. Вы можете позвонить / отправить сообщение клиенту после того, как укажете, что прибыли.',
        fr: 'Vous êtes en attente pour ce travail. Vous pouvez appeler / envoyer un SMS au client après avoir indiqué que vous êtes arrivé.',
        de: 'Sie sind für diesen Job in Bereitschaft. Sie können den Kunden anrufen / eine SMS senden, nachdem Sie angegeben haben, dass Sie angekommen sind.'
    },
    'It is too early to contact the Client. Please wait until 85 minutes before the job.': {
        pt: 'É muito cedo para entrar em contato com o cliente. Por favor, espere até 85 minutos antes do trabalho.',
        es: 'Es demasiado temprano para contactar al cliente. Por favor, espere hasta 85 minutos antes del trabajo.',
        ru: 'Слишком рано связываться с клиентом. Пожалуйста, подождите до 85 минут до начала работы.',
        fr: 'Il est trop tôt pour contacter le client. Veuillez attendre jusqu\'à 85 minutes avant le travail.',
        de: 'Es ist zu früh, um den Kunden zu kontaktieren. Bitte warten Sie bis 85 Minuten vor dem Job.'
    },

    'Job Messages': {
        pt: 'Mensagens de Trabalho',
        es: 'Mensajes de Trabajo',
        ru: 'Сообщения о Работе',
        fr: 'Messages de Travail',
        de: 'Arbeitsnachrichten'
    },

    'Start': {
        pt: 'Início',
        es: 'Inicio',
        ru: 'Начало',
        fr: 'Début',
        de: 'Start'
    },
    'End': {
        pt: 'Fim',
        es: 'Fin',
        ru: 'Конец',
        fr: 'Fin',
        de: 'Ende'
    },
    'Show Results': {
        pt: 'Mostrar Resultados',
        es: 'Mostrar Resultados',
        ru: 'Показать Результаты',
        fr: 'Afficher les Résultats',
        de: 'Ergebnisse Anzeigen'
    },
    'Miles Travelled': {
        pt: 'Milhas Percorridas',
        es: 'Millas Recorridas',
        ru: 'Пройденные Мили',
        fr: 'Miles Parcourus',
        de: 'Gefahrene Meilen'
    },
    'TIDY automatically tracks jobs as you add them with estimated miles. All numbers are estimates. TIDY does not give tax advice, please consult a professional.': {
        pt: 'TIDY rastreia automaticamente os trabalhos à medida que você os adiciona com milhas estimadas. Todos os números são estimativas. TIDY não dá conselhos fiscais, consulte um profissional.',
        es: 'TIDY rastrea automáticamente los trabajos a medida que los agrega con millas estimadas. Todos los números son estimaciones. TIDY no da consejos fiscales, consulte a un profesional.',
        ru: 'TIDY автоматически отслеживает задания по мере их добавления с расчетными милями. Все числа являются оценочными. TIDY не дает налоговых консультаций, пожалуйста, проконсультируйтесь с профессионалом.',
        fr: 'TIDY suit automatiquement les travaux au fur et à mesure que vous les ajoutez avec des miles estimés. Tous les chiffres sont des estimations. TIDY ne donne pas de conseils fiscaux, veuillez consulter un professionnel.',
        de: 'TIDY verfolgt automatisch Jobs, wenn Sie sie mit geschätzten Meilen hinzufügen. Alle Zahlen sind Schätzungen. TIDY gibt keine Steuerberatung, bitte konsultieren Sie einen Fachmann.'
    },
    'Add Job to Track': {
        pt: 'Adicionar Trabalho para Rastrear',
        es: 'Agregar Trabajo para Rastrear',
        ru: 'Добавить Задание для Отслеживания',
        fr: 'Ajouter un Travail à Suivre',
        de: 'Job zum Verfolgen Hinzufügen'
    },
    'Mileage Report': {
        pt: 'Relatório de Quilometragem',
        es: 'Informe de Kilometraje',
        ru: 'Отчет о Пробеге',
        fr: 'Rapport de Kilométrage',
        de: 'Kilometerbericht'
    },
    'Miles': {
        pt: 'Milhas',
        es: 'Millas',
        ru: 'Мили',
        fr: 'Miles',
        de: 'Meilen'
    },
    'Please select a start and end date.': {
        pt: 'Por favor, selecione uma data de início e término.',
        es: 'Por favor, seleccione una fecha de inicio y fin.',
        ru: 'Пожалуйста, выберите дату начала и окончания.',
        fr: 'Veuillez sélectionner une date de début et de fin.',
        de: 'Bitte wählen Sie ein Start- und Enddatum.'
    },
    'Custom Range': {
        pt: 'Intervalo Personalizado',
        es: 'Rango Personalizado',
        ru: 'Пользовательский Диапазон',
        fr: 'Plage Personnalisée',
        de: 'Benutzerdefinierter Bereich'
    },

    'Edit Job Mileage': {
        pt: 'Editar Quilometragem do Trabalho',
        es: 'Editar Kilometraje del Trabajo',
        ru: 'Редактировать Пробег Работы',
        fr: 'Modifier le Kilométrage du Travail',
        de: 'Job-Kilometer bearbeiten'
    },
    'Edit mileage for your job with ': {
        pt: 'Edite a quilometragem do seu trabalho com ',
        es: 'Edite el kilometraje de su trabajo con ',
        ru: 'Редактировать пробег для вашей работы с ',
        fr: 'Modifier le kilométrage de votre travail avec ',
        de: 'Bearbeiten Sie den Kilometerstand für Ihren Job mit '
    },
    'View Job': {
        pt: 'Ver Trabalho',
        es: 'Ver Trabajo',
        ru: 'Просмотр Работы',
        fr: 'Voir le Travail',
        de: 'Job anzeigen'
    },
    'Start Point': {
        pt: 'Ponto de Partida',
        es: 'Punto de Inicio',
        ru: 'Начальная Точка',
        fr: 'Point de Départ',
        de: 'Startpunkt'
    },
    'Please enter a start point': {
        pt: 'Por favor, insira um ponto de partida',
        es: 'Por favor, ingrese un punto de inicio',
        ru: 'Пожалуйста, введите начальную точку',
        fr: 'Veuillez entrer un point de départ',
        de: 'Bitte geben Sie einen Startpunkt ein'
    },
    'End Point': {
        pt: 'Ponto Final',
        es: 'Punto Final',
        ru: 'Конечная Точка',
        fr: 'Point Final',
        de: 'Endpunkt'
    },
    'Please enter an end point': {
        pt: 'Por favor, insira um ponto final',
        es: 'Por favor, ingrese un punto final',
        ru: 'Пожалуйста, введите конечную точку',
        fr: 'Veuillez entrer un point final',
        de: 'Bitte geben Sie einen Endpunkt ein'
    },
    'Please enter miles travelled': {
        pt: 'Por favor, insira as milhas percorridas',
        es: 'Por favor, ingrese las millas recorridas',
        ru: 'Пожалуйста, введите пройденные мили',
        fr: 'Veuillez entrer les miles parcourus',
        de: 'Bitte geben Sie die gefahrenen Meilen ein'
    },
    'Mileage Updated': {
        pt: 'Quilometragem Atualizada',
        es: 'Kilometraje Actualizado',
        ru: 'Пробег Обновлен',
        fr: 'Kilométrage Mis à Jour',
        de: 'Kilometerstand Aktualisiert'
    },
    'The mileage for this job has been saved.': {
        pt: 'A quilometragem para este trabalho foi salva.',
        es: 'El kilometraje para este trabajo ha sido guardado.',
        ru: 'Пробег для этой работы был сохранен.',
        fr: 'Le kilométrage pour ce travail a été enregistré.',
        de: 'Der Kilometerstand für diesen Job wurde gespeichert.'
    },

    'More': {
        pt: 'Mais',
        es: 'Más',
        ru: 'Больше',
        fr: 'Plus',
        de: 'Mehr'
    },
    'Search': {
        pt: 'Pesquisar',
        es: 'Buscar',
        ru: 'Поиск',
        fr: 'Recherche',
        de: 'Suche'
    },
    'My Settings': {
        pt: 'Minhas Configurações',
        es: 'Mis Configuraciones',
        ru: 'Мои Настройки',
        fr: 'Mes Paramètres',
        de: 'Meine Einstellungen'
    },
    'My Business': {
        pt: 'Meu Negócio',
        es: 'Mi Negocio',
        ru: 'Мой Бизнес',
        fr: 'Mon Entreprise',
        de: 'Mein Geschäft'
    },
    'Bookable Hours': {
        pt: 'Horas Reserváveis',
        es: 'Horas Reservables',
        ru: 'Часы Бронирования',
        fr: 'Heures Réservables',
        de: 'Buchbare Stunden'
    },
    'My Website': {
        pt: 'Meu Site',
        es: 'Mi Sitio Web',
        ru: 'Мой Сайт',
        fr: 'Mon Site Web',
        de: 'Meine Webseite'
    },
    'Teams': {
        pt: 'Equipes',
        es: 'Equipos',
        ru: 'Команды',
        fr: 'Équipes',
        de: 'Teams'
    },
    'Contact Info': {
        pt: 'Informações de Contato',
        es: 'Información de Contacto',
        ru: 'Контактная Информация',
        fr: 'Informations de Contact',
        de: 'Kontaktinformationen'
    },
    'Locations Served': {
        pt: 'Locais Atendidos',
        es: 'Ubicaciones Atendidas',
        ru: 'Обслуживаемые Места',
        fr: 'Lieux Desservis',
        de: 'Bediente Standorte'
    },
    'Services': {
        pt: 'Serviços',
        es: 'Servicios',
        ru: 'Услуги',
        fr: 'Services',
        de: 'Dienstleistungen'
    },
    'Payments': {
        pt: 'Pagamentos',
        es: 'Pagos',
        ru: 'Платежи',
        fr: 'Paiements',
        de: 'Zahlungen'
    },
    'Payouts': {
        pt: 'Pagamentos',
        es: 'Pagos',
        ru: 'Выплаты',
        fr: 'Paiements',
        de: 'Auszahlungen'
    },
    'Payout Settings': {
        pt: 'Configurações de Pagamento',
        es: 'Configuraciones de Pago',
        ru: 'Настройки Выплат',
        fr: 'Paramètres de Paiement',
        de: 'Auszahlungseinstellungen'
    },
    'Client Payment Settings': {
        pt: 'Configurações de Pagamento do Cliente',
        es: 'Configuraciones de Pago del Cliente',
        ru: 'Настройки Платежей Клиента',
        fr: 'Paramètres de Paiement du Client',
        de: 'Kundenzahlungseinstellungen'
    },
    'Past Weekly Job History': {
        pt: 'Histórico de Trabalhos de Semanas Passadas',
        es: 'Historial de Trabajos Semanales Pasados',
        ru: 'История Прошлых Еженедельных Работ',
        fr: 'Historique des Travaux Hebdomadaires Passés',
        de: 'Vergangene Wöchentliche Jobhistorie'
    },
    'Clients': {
        pt: 'Clientes',
        es: 'Clientes',
        ru: 'Клиенты',
        fr: 'Clients',
        de: 'Kunden'
    },
    'Client List': {
        pt: 'Lista de Clientes',
        es: 'Lista de Clientes',
        ru: 'Список Клиентов',
        fr: 'Liste des Clients',
        de: 'Kundenliste'
    },
    'Reviews': {
        pt: 'Avaliações',
        es: 'Reseñas',
        ru: 'Отзывы',
        fr: 'Avis',
        de: 'Bewertungen'
    },
    'Reports': {
        pt: 'Relatórios',
        es: 'Informes',
        ru: 'Отчеты',
        fr: 'Rapports',
        de: 'Berichte'
    },
    'Tax Center': {
        pt: 'Centro de Impostos',
        es: 'Centro de Impuestos',
        ru: 'Налоговый Центр',
        fr: 'Centre Fiscal',
        de: 'Steuerzentrum'
    },
    'Accounting': {
        pt: 'Contabilidade',
        es: 'Contabilidad',
        ru: 'Бухгалтерия',
        fr: 'Comptabilité',
        de: 'Buchhaltung'
    },
    'Equipment Tracking': {
        pt: 'Rastreamento de Equipamentos',
        es: 'Seguimiento de Equipos',
        ru: 'Отслеживание Оборудования',
        fr: 'Suivi de l\'Équipement',
        de: 'Ausrüstungsverfolgung'
    },
    'Marketing': {
        pt: 'Marketing',
        es: 'Marketing',
        ru: 'Маркетинг',
        fr: 'Marketing',
        de: 'Marketing'
    },
    'Email Marketing': {
        pt: 'Marketing por E-mail',
        es: 'Marketing por Correo Electrónico',
        ru: 'Email Маркетинг',
        fr: 'Marketing par E-mail',
        de: 'E-Mail-Marketing'
    },
    'Postcard Marketing': {
        pt: 'Marketing por Cartão Postal',
        es: 'Marketing por Tarjeta Postal',
        ru: 'Маркетинг по Открыткам',
        fr: 'Marketing par Carte Postale',
        de: 'Postkartenmarketing'
    },
    'Local Service Ads': {
        pt: 'Anúncios de Serviços Locais',
        es: 'Anuncios de Servicios Locales',
        ru: 'Реклама Местных Услуг',
        fr: 'Annonces de Services Locaux',
        de: 'Anzeigen für Lokale Dienstleistungen'
    },
    'Display Language': {
        pt: 'Idioma de Exibição',
        es: 'Idioma de Visualización',
        ru: 'Язык Отображения',
        fr: 'Langue d\'Affichage',
        de: 'Anzeigesprache'
    },
    'Help': {
        pt: 'Ajuda',
        es: 'Ayuda',
        ru: 'Помощь',
        fr: 'Aide',
        de: 'Hilfe'
    },
    'Documentation': {
        pt: 'Documentação',
        es: 'Documentación',
        ru: 'Документация',
        fr: 'Documentation',
        de: 'Dokumentation'
    },
    'My Account': {
        pt: 'Minha Conta',
        es: 'Mi Cuenta',
        ru: 'Мой Аккаунт',
        fr: 'Mon Compte',
        de: 'Mein Konto'
    },
    'Sign Out': {
        pt: 'Sair',
        es: 'Cerrar Sesión',
        ru: 'Выйти',
        fr: 'Déconnexion',
        de: 'Abmelden'
    },

    'Tap on a day to edit current bookability.': {
        pt: 'Toque em um dia para editar a disponibilidade atual.',
        es: 'Toque en un día para editar la disponibilidad actual.',
        ru: 'Нажмите на день, чтобы изменить текущую доступность.',
        fr: 'Appuyez sur un jour pour modifier la disponibilité actuelle.',
        de: 'Tippen Sie auf einen Tag, um die aktuelle Verfügbarkeit zu bearbeiten.'
    },
    'Bookable times are times clients can book you directly (like OpenTable). When choosing a pro, clients generally choose the pro with bookable times. ': {
        pt: 'Os horários reserváveis são horários que os clientes podem reservar diretamente (como o OpenTable). Ao escolher um profissional, os clientes geralmente escolhem o profissional com horários reserváveis.',
        es: 'Los horarios reservables son horarios que los clientes pueden reservar directamente (como OpenTable). Al elegir un profesional, los clientes generalmente eligen al profesional con horarios reservables.',
        ru: 'Доступное время - это время, которое клиенты могут забронировать напрямую (как OpenTable). При выборе профессионала клиенты обычно выбирают профессионала с доступным временем.',
        fr: 'Les heures réservables sont des heures que les clients peuvent réserver directement (comme OpenTable). Lors du choix d\'un professionnel, les clients choisissent généralement le professionnel avec des heures réservables.',
        de: 'Buchbare Zeiten sind Zeiten, die Kunden direkt buchen können (wie OpenTable). Bei der Wahl eines Profis wählen Kunden in der Regel den Profi mit buchbaren Zeiten.'
    },
    'Pause All Bookable Hours': {
        pt: 'Pausar Todas as Horas Reserváveis',
        es: 'Pausar Todas las Horas Reservables',
        ru: 'Приостановить Все Доступные Часы',
        fr: 'Suspendre Toutes les Heures Réservables',
        de: 'Alle Buchbaren Stunden Pausieren'
    },
    'Bookable Hours Change History': {
        pt: 'Histórico de Alterações de Horas Reserváveis',
        es: 'Historial de Cambios de Horas Reservables',
        ru: 'История Изменений Доступных Часов',
        fr: 'Historique des Modifications des Heures Réservables',
        de: 'Änderungsverlauf der Buchbaren Stunden'
    },
    'No bookable hours changes.': {
        pt: 'Sem alterações nas horas reserváveis.',
        es: 'Sin cambios en las horas reservables.',
        ru: 'Нет изменений в доступных часах.',
        fr: 'Aucun changement dans les heures réservables.',
        de: 'Keine Änderungen bei den buchbaren Stunden.'
    },
    'Change on ': {
        pt: 'Alterar em ',
        es: 'Cambiar en ',
        ru: 'Изменить на ',
        fr: 'Changer le ',
        de: 'Ändern am '
    },
    'New Bookable Hours: ': {
        pt: 'Novas Horas Reserváveis: ',
        es: 'Nuevas Horas Reservables: ',
        ru: 'Новые Доступные Часы: ',
        fr: 'Nouvelles Heures Réservables: ',
        de: 'Neue Buchbare Stunden: '
    },
    'Pay Rate Change: ': {
        pt: 'Alteração da Taxa de Pagamento: ',
        es: 'Cambio de Tarifa de Pago: ',
        ru: 'Изменение Ставки Оплаты: ',
        fr: 'Changement de Taux de Paiement: ',
        de: 'Änderung des Zahlungsraten: '
    },
    ' rates changed to ': {
        pt: ' taxas alteradas para ',
        es: ' tarifas cambiadas a ',
        ru: ' ставки изменены на ',
        fr: ' taux modifiés à ',
        de: ' Raten geändert zu '
    },
    ' for new clients and ': {
        pt: ' para novos clientes e ',
        es: ' para nuevos clientes y ',
        ru: ' для новых клиентов и ',
        fr: ' pour les nouveaux clients et ',
        de: ' für neue Kunden und '
    },
    ' for repeat clients.': {
        pt: ' para clientes recorrentes.',
        es: ' para clientes recurrentes.',
        ru: ' для постоянных клиентов.',
        fr: ' pour les clients réguliers.',
        de: ' für wiederkehrende Kunden.'
    },

    'Change Schedule?': {
        pt: 'Alterar Horário?',
        es: 'Cambiar Horario?',
        ru: 'Изменить Расписание?',
        fr: 'Changer l\'horaire?',
        de: 'Zeitplan ändern?'
    },
    'Schedule for ': {
        pt: 'Horário para ',
        es: 'Horario para ',
        ru: 'Расписание для ',
        fr: 'Horaire pour ',
        de: 'Zeitplan für '
    },
    ' and all ': {
        pt: ' e todos ',
        es: ' y todos ',
        ru: ' и все ',
        fr: ' et tous ',
        de: ' und alle '
    },
    '\'s after:': {
        pt: ' depois de:',
        es: ' después de:',
        ru: ' после:',
        fr: ' après:',
        de: ' nach:'
    },
    'Bookable: ': {
        pt: 'Reservável: ',
        es: 'Reservable: ',
        ru: 'Доступно для бронирования: ',
        fr: 'Réservable: ',
        de: 'Buchbar: '
    },
    'Not Bookable': {
        pt: 'Não Reservável',
        es: 'No Reservable',
        ru: 'Не доступно для бронирования',
        fr: 'Non Réservable',
        de: 'Nicht buchbar'
    },
    'Cancelled Cleanings': {
        pt: 'Limpezas Canceladas',
        es: 'Limpiezas Canceladas',
        ru: 'Отмененные уборки',
        fr: 'Nettoyages Annulés',
        de: 'Stornierte Reinigungen'
    },
    'You will lose the following bonus:': {
        pt: 'Você perderá o seguinte bônus:',
        es: 'Perderás el siguiente bono:',
        ru: 'Вы потеряете следующий бонус:',
        fr: 'Vous perdrez le bonus suivant:',
        de: 'Sie verlieren den folgenden Bonus:'
    },
    'You lose this bonus if you do not remain bookable ': {
        pt: 'Você perde este bônus se não permanecer reservável ',
        es: 'Pierdes este bono si no sigues siendo reservable ',
        ru: 'Вы потеряете этот бонус, если не останетесь доступным для бронирования ',
        fr: 'Vous perdez ce bonus si vous ne restez pas réservable ',
        de: 'Sie verlieren diesen Bonus, wenn Sie nicht buchbar bleiben '
    },
    ' between ': {
        pt: ' entre ',
        es: ' entre ',
        ru: ' между ',
        fr: ' entre ',
        de: ' zwischen '
    },
    ' and ': {
        pt: ' e ',
        es: ' y ',
        ru: ' и ',
        fr: ' et ',
        de: ' und '
    },
    'Please select why are you canceling?': {
        pt: 'Por favor, selecione por que você está cancelando?',
        es: 'Por favor, seleccione por qué está cancelando?',
        ru: 'Пожалуйста, выберите, почему вы отменяете?',
        fr: 'Veuillez sélectionner pourquoi vous annulez?',
        de: 'Bitte wählen Sie, warum Sie stornieren?'
    },
    'Change Schedule': {
        pt: 'Alterar Horário',
        es: 'Cambiar Horario',
        ru: 'Изменить Расписание',
        fr: 'Changer l\'horaire',
        de: 'Zeitplan ändern'
    },
    'Schedule saved': {
        pt: 'Horário salvo',
        es: 'Horario guardado',
        ru: 'Расписание сохранено',
        fr: 'Horaire enregistré',
        de: 'Zeitplan gespeichert'
    },
    'Go To My Schedule': {
        pt: 'Ir para Meu Horário',
        es: 'Ir a Mi Horario',
        ru: 'Перейти к моему расписанию',
        fr: 'Aller à Mon Horaire',
        de: 'Zu meinem Zeitplan gehen'
    },

    'Toggle the times clients can book with you:': {
        pt: 'Alterne os horários que os clientes podem reservar com você:',
        es: 'Cambie los horarios en los que los clientes pueden reservar con usted:',
        ru: 'Переключите время, когда клиенты могут забронировать у вас:',
        fr: 'Basculez les horaires auxquels les clients peuvent réserver avec vous:',
        de: 'Schalten Sie die Zeiten um, zu denen Kunden bei Ihnen buchen können:'
    },
    'Not Bookable on ': {
        pt: 'Não reservável em ',
        es: 'No reservable en ',
        ru: 'Не доступно для бронирования на ',
        fr: 'Non réservable le ',
        de: 'Nicht buchbar am '
    },
    'Earliest Start': {
        pt: 'Início mais cedo',
        es: 'Inicio más temprano',
        ru: 'Самое раннее начало',
        fr: 'Début le plus tôt',
        de: 'Frühester Start'
    },
    'Select a valid time': {
        pt: 'Selecione um horário válido',
        es: 'Seleccione una hora válida',
        ru: 'Выберите действительное время',
        fr: 'Sélectionnez une heure valide',
        de: 'Wählen Sie eine gültige Zeit'
    },
    'Latest Finish': {
        pt: 'Término mais tarde',
        es: 'Final más tarde',
        ru: 'Самое позднее окончание',
        fr: 'Fin la plus tardive',
        de: 'Spätestes Ende'
    },
    '+ Add Bookability': {
        pt: '+ Adicionar Reservabilidade',
        es: '+ Agregar Reservabilidad',
        ru: '+ Добавить возможность бронирования',
        fr: '+ Ajouter la possibilité de réservation',
        de: '+ Buchbarkeit hinzufügen'
    },

    'If you are just taking a short time off, mark yourself off. If you are leaving for an extended period, then pausing is a good option. You can schedule your pause for a future date.': {
        pt: 'Se você está apenas tirando um curto período de folga, marque-se fora. Se você estiver saindo por um período prolongado, pausar é uma boa opção. Você pode agendar sua pausa para uma data futura.',
        es: 'Si solo se está tomando un breve descanso, márquese fuera. Si se va por un período prolongado, pausar es una buena opción. Puede programar su pausa para una fecha futura.',
        ru: 'Если вы просто берете короткий перерыв, отметьте себя. Если вы уезжаете на длительный период, то приостановка - хороший вариант. Вы можете запланировать свою паузу на будущую дату.',
        fr: 'Si vous prenez juste un court congé, marquez-vous absent. Si vous partez pour une période prolongée, alors faire une pause est une bonne option. Vous pouvez programmer votre pause pour une date ultérieure.',
        de: 'Wenn Sie nur eine kurze Auszeit nehmen, markieren Sie sich als abwesend. Wenn Sie für einen längeren Zeitraum weg sind, ist eine Pause eine gute Option. Sie können Ihre Pause für ein zukünftiges Datum planen.'
    },
    'I Need to Pause for An Extended Time': {
        pt: 'Preciso pausar por um longo período',
        es: 'Necesito pausar por un período prolongado',
        ru: 'Мне нужно приостановить на длительное время',
        fr: 'J\'ai besoin de faire une pause prolongée',
        de: 'Ich muss für eine längere Zeit pausieren'
    },
    'I Just Need To Adjust My Hours': {
        pt: 'Eu só preciso ajustar minhas horas',
        es: 'Solo necesito ajustar mis horas',
        ru: 'Мне просто нужно скорректировать свои часы',
        fr: 'J\'ai juste besoin d\'ajuster mes heures',
        de: 'Ich muss nur meine Stunden anpassen'
    },
    'Please let us know when and why you are pausing.': {
        pt: 'Por favor, informe-nos quando e por que você está pausando.',
        es: 'Por favor, háganos saber cuándo y por qué está pausando.',
        ru: 'Пожалуйста, сообщите нам, когда и почему вы приостанавливаете.',
        fr: 'Veuillez nous faire savoir quand et pourquoi vous faites une pause.',
        de: 'Bitte lassen Sie uns wissen, wann und warum Sie pausieren.'
    },
    'All scheduled jobs on this date and after will be cancelled.  Normal cancellation fees apply.': {
        pt: 'Todos os trabalhos agendados nesta data e depois serão cancelados. Taxas de cancelamento normais se aplicam.',
        es: 'Todos los trabajos programados en esta fecha y después serán cancelados. Se aplican tarifas de cancelación normales.',
        ru: 'Все запланированные работы на эту дату и после будут отменены. Применяются обычные сборы за отмену.',
        fr: 'Tous les travaux programmés à cette date et après seront annulés. Les frais d\'annulation normaux s\'appliquent.',
        de: 'Alle geplanten Arbeiten an diesem Datum und danach werden storniert. Es gelten die normalen Stornogebühren.'
    },
    'Pause Start Date': {
        pt: 'Data de início da pausa',
        es: 'Fecha de inicio de la pausa',
        ru: 'Дата начала паузы',
        fr: 'Date de début de la pause',
        de: 'Pausenstartdatum'
    },
    'Please select a date.': {
        pt: 'Por favor, selecione uma data.',
        es: 'Por favor seleccione una fecha.',
        ru: 'Пожалуйста, выберите дату.',
        fr: 'Veuillez sélectionner une date.',
        de: 'Bitte wählen Sie ein Datum.'
    },
    'Why Are You Pausing?': {
        pt: 'Por que você está pausando?',
        es: '¿Por qué estás pausando?',
        ru: 'Почему вы приостанавливаете?',
        fr: 'Pourquoi faites-vous une pause?',
        de: 'Warum pausieren Sie?'
    },
    'Please let us know why you are pausing.': {
        pt: 'Por favor, informe-nos por que você está pausando.',
        es: 'Por favor, háganos saber por qué está pausando.',
        ru: 'Пожалуйста, сообщите нам, почему вы приостанавливаете.',
        fr: 'Veuillez nous faire savoir pourquoi vous faites une pause.',
        de: 'Bitte lassen Sie uns wissen, warum Sie pausieren.'
    },
    'Pause': {
        pt: 'Pausa',
        es: 'Pausa',
        ru: 'Пауза',
        fr: 'Pause',
        de: 'Pause'
    },

    'Pause Schedule?': {
        pt: 'Pausar Agenda?',
        es: '¿Pausar Horario?',
        ru: 'Приостановить расписание?',
        fr: 'Pause de l\'horaire?',
        de: 'Zeitplan pausieren?'
    },
    'Schedule Paused': {
        pt: 'Agenda Pausada',
        es: 'Horario Pausado',
        ru: 'Расписание приостановлено',
        fr: 'Horaire en pause',
        de: 'Zeitplan pausiert'
    },

    'Set when you want to be notified for job opportunities.': {
        pt: 'Defina quando você quer ser notificado sobre oportunidades de trabalho.',
        es: 'Establezca cuándo desea ser notificado sobre oportunidades de trabajo.',
        ru: 'Установите, когда вы хотите получать уведомления о возможностях работы.',
        fr: 'Définissez quand vous souhaitez être informé des opportunités d\'emploi.',
        de: 'Legen Sie fest, wann Sie über Jobmöglichkeiten benachrichtigt werden möchten.'
    },
    'In App Notifications': {
        pt: 'Notificações no Aplicativo',
        es: 'Notificaciones en la Aplicación',
        ru: 'Уведомления в приложении',
        fr: 'Notifications dans l\'application',
        de: 'Benachrichtigungen in der App'
    },
    '<i>Do Not Notify</i>': {
        pt: '<i>Não Notificar</i>',
        es: '<i>No Notificar</i>',
        ru: '<i>Не уведомлять</i>',
        fr: '<i>Ne pas notifier</i>',
        de: '<i>Nicht benachrichtigen</i>'
    },
    'SMS Notifications': {
        pt: 'Notificações por SMS',
        es: 'Notificaciones por SMS',
        ru: 'SMS уведомления',
        fr: 'Notifications par SMS',
        de: 'SMS-Benachrichtigungen'
    },

    'Send me ': {
        pt: 'Envie-me ',
        es: 'Envíame ',
        ru: 'Отправьте мне ',
        fr: 'Envoyez-moi ',
        de: 'Senden Sie mir '
    },
    ' notifications if there are job opportunities at these times on ': {
        pt: ' notificações se houver oportunidades de trabalho nesses horários em ',
        es: ' notificaciones si hay oportunidades de trabajo en estos horarios en ',
        ru: ' уведомления, если есть возможности для работы в это время на ',
        fr: ' notifications s\'il y a des opportunités de travail à ces heures sur ',
        de: ' Benachrichtigungen, wenn es zu diesen Zeiten Jobmöglichkeiten gibt am '
    },
    'Send': {
        pt: 'Enviar',
        es: 'Enviar',
        ru: 'Отправить',
        fr: 'Envoyer',
        de: 'Senden'
    },
    'Do Not Send': {
        pt: 'Não Enviar',
        es: 'No Enviar',
        ru: 'Не отправлять',
        fr: 'Ne pas envoyer',
        de: 'Nicht senden'
    },

    'Account Info': {
        pt: 'Informações da Conta',
        es: 'Información de la Cuenta',
        ru: 'Информация об аккаунте',
        fr: 'Informations du Compte',
        de: 'Kontoinformationen'
    },
    'What types of services do you offer?': {
        pt: 'Quais tipos de serviços você oferece?',
        es: '¿Qué tipos de servicios ofreces?',
        ru: 'Какие виды услуг вы предлагаете?',
        fr: 'Quels types de services offrez-vous?',
        de: 'Welche Arten von Dienstleistungen bieten Sie an?'
    },
    'Don\'t see what you offer? ': {
        pt: 'Não vê o que você oferece? ',
        es: '¿No ves lo que ofreces? ',
        ru: 'Не видите, что вы предлагаете? ',
        fr: 'Vous ne voyez pas ce que vous offrez? ',
        de: 'Sehen Sie nicht, was Sie anbieten? '
    },
    'Request Type': {
        pt: 'Tipo de Solicitação',
        es: 'Tipo de Solicitud',
        ru: 'Тип запроса',
        fr: 'Type de Demande',
        de: 'Anfragetyp'
    },
    'Please select the types of services you offer.': {
        pt: 'Por favor, selecione os tipos de serviços que você oferece.',
        es: 'Por favor, seleccione los tipos de servicios que ofrece.',
        ru: 'Пожалуйста, выберите виды услуг, которые вы предлагаете.',
        fr: 'Veuillez sélectionner les types de services que vous offrez.',
        de: 'Bitte wählen Sie die Arten von Dienstleistungen aus, die Sie anbieten.'
    },
    'Next': {
        pt: 'Próximo',
        es: 'Siguiente',
        ru: 'Далее',
        fr: 'Suivant',
        de: 'Weiter'
    },
    'First name': {
        pt: 'Primeiro nome',
        es: 'Nombre',
        ru: 'Имя',
        fr: 'Prénom',
        de: 'Vorname'
    },
    'Last name': {
        pt: 'Sobrenome',
        es: 'Apellido',
        ru: 'Фамилия',
        fr: 'Nom de famille',
        de: 'Nachname'
    },
    'Please enter your last name.': {
        pt: 'Por favor, insira seu sobrenome.',
        es: 'Por favor, ingrese su apellido.',
        ru: 'Пожалуйста, введите вашу фамилию.',
        fr: 'Veuillez entrer votre nom de famille.',
        de: 'Bitte geben Sie Ihren Nachnamen ein.'
    },
    'Country': {
        pt: 'País',
        es: 'País',
        ru: 'Страна',
        fr: 'Pays',
        de: 'Land'
    },
    'Unit / Apartment': {
        pt: 'Unidade / Apartamento',
        es: 'Unidad / Apartamento',
        ru: 'Квартира / Апартаменты',
        fr: 'Unité / Appartement',
        de: 'Einheit / Wohnung'
    },
    'Please enter a valid address': {
        pt: 'Por favor, insira um endereço válido',
        es: 'Por favor, ingrese una dirección válida',
        ru: 'Пожалуйста, введите действительный адрес',
        fr: 'Veuillez entrer une adresse valide',
        de: 'Bitte geben Sie eine gültige Adresse ein'
    },
    'Zip Code': {
        pt: 'Código Postal',
        es: 'Código Postal',
        ru: 'Почтовый индекс',
        fr: 'Code Postal',
        de: 'Postleitzahl'
    },
    'Please enter a valid zipcode': {
        pt: 'Por favor, insira um código postal válido',
        es: 'Por favor, ingrese un código postal válido',
        ru: 'Пожалуйста, введите действительный почтовый индекс',
        fr: 'Veuillez entrer un code postal valide',
        de: 'Bitte geben Sie eine gültige Postleitzahl ein'
    },
    'What is your gender?': {
        pt: 'Qual é o seu gênero?',
        es: '¿Cuál es tu género?',
        ru: 'Какой у вас пол?',
        fr: 'Quel est votre sexe?',
        de: 'Was ist Ihr Geschlecht?'
    },
    'Female': {
        pt: 'Feminino',
        es: 'Femenino',
        ru: 'Женский',
        fr: 'Féminin',
        de: 'Weiblich'
    },
    'Male': {
        pt: 'Masculino',
        es: 'Masculino',
        ru: 'Мужской',
        fr: 'Masculin',
        de: 'Männlich'
    },
    'Please select your gender.': {
        pt: 'Por favor, selecione seu gênero.',
        es: 'Por favor, seleccione su género.',
        ru: 'Пожалуйста, выберите ваш пол.',
        fr: 'Veuillez sélectionner votre sexe.',
        de: 'Bitte wählen Sie Ihr Geschlecht aus.'
    },
    'Have you previously been certified with TIDY?': {
        pt: 'Você já foi certificado pela TIDY?',
        es: '¿Ha sido certificado anteriormente con TIDY?',
        ru: 'Вы ранее были сертифицированы с TIDY?',
        fr: 'Avez-vous déjà été certifié avec TIDY?',
        de: 'Waren Sie zuvor bei TIDY zertifiziert?'
    },
    'Yes - I am back': {
        pt: 'Sim - Estou de volta',
        es: 'Sí - Estoy de vuelta',
        ru: 'Да - Я вернулся',
        fr: 'Oui - Je suis de retour',
        de: 'Ja - Ich bin zurück'
    },
    'No - I am new': {
        pt: 'Não - Eu sou novo',
        es: 'No - Soy nuevo',
        ru: 'Нет - Я новый',
        fr: 'Non - Je suis nouveau',
        de: 'Nein - Ich bin neu'
    },
    'No need to reapply. Please log into your previous account to see if you are eligible to reapply.': {
        pt: 'Não é necessário reaplicar. Por favor, faça login na sua conta anterior para ver se você é elegível para reaplicar.',
        es: 'No es necesario volver a aplicar. Por favor, inicie sesión en su cuenta anterior para ver si es elegible para volver a aplicar.',
        ru: 'Нет необходимости повторно подавать заявку. Пожалуйста, войдите в свою предыдущую учетную запись, чтобы узнать, имеете ли вы право повторно подать заявку.',
        fr: 'Pas besoin de postuler à nouveau. Veuillez vous connecter à votre compte précédent pour voir si vous êtes éligible pour postuler à nouveau.',
        de: 'Keine Notwendigkeit, sich erneut zu bewerben. Bitte melden Sie sich bei Ihrem vorherigen Konto an, um zu sehen, ob Sie sich erneut bewerben können.'
    },
    'Please select an answer.': {
        pt: 'Por favor, selecione uma resposta.',
        es: 'Por favor, seleccione una respuesta.',
        ru: 'Пожалуйста, выберите ответ.',
        fr: 'Veuillez sélectionner une réponse.',
        de: 'Bitte wählen Sie eine Antwort aus.'
    },
    'Emails and phones are used for Client communication and notifications about things like new jobs. By clicking, you agree that TIDY may contact you by email, phone, or SMS at the email or number you provide.': {
        pt: 'E-mails e telefones são usados para comunicação com o cliente e notificações sobre coisas como novos trabalhos. Ao clicar, você concorda que a TIDY pode contatá-lo por e-mail, telefone ou SMS no e-mail ou número que você fornecer.',
        es: 'Los correos electrónicos y teléfonos se utilizan para la comunicación con el cliente y notificaciones sobre cosas como nuevos trabajos. Al hacer clic, acepta que TIDY puede contactarlo por correo electrónico, teléfono o SMS al correo electrónico o número que proporcione.',
        ru: 'Электронные письма и телефоны используются для связи с клиентами и уведомлений о таких вещах, как новые работы. Нажимая, вы соглашаетесь с тем, что TIDY может связаться с вами по электронной почте, телефону или SMS на указанный вами адрес электронной почты или номер.',
        fr: 'Les e-mails et les téléphones sont utilisés pour la communication avec le client et les notifications concernant des choses comme de nouveaux emplois. En cliquant, vous acceptez que TIDY puisse vous contacter par e-mail, téléphone ou SMS à l\'adresse e-mail ou au numéro que vous fournissez.',
        de: 'E-Mails und Telefone werden für die Kommunikation mit dem Kunden und Benachrichtigungen über Dinge wie neue Jobs verwendet. Durch Klicken stimmen Sie zu, dass TIDY Sie per E-Mail, Telefon oder SMS unter der von Ihnen angegebenen E-Mail-Adresse oder Nummer kontaktieren darf.'
    },
    'Submit': {
        pt: 'Enviar',
        es: 'Enviar',
        ru: 'Отправить',
        fr: 'Soumettre',
        de: 'Einreichen'
    },
    'Cleaning (most common)': {
        pt: 'Limpeza (mais comum)',
        es: 'Limpieza (más común)',
        ru: 'Уборка (наиболее распространенная)',
        fr: 'Nettoyage (le plus courant)',
        de: 'Reinigung (am häufigsten)'
    },
    'Unfortunately you must have a physical address in order to accurately determine your service area, and PO boxes cannot be used. Clients do not see your address.  Please add a different address.': {
        pt: 'Infelizmente, você deve ter um endereço físico para determinar com precisão sua área de serviço, e caixas postais não podem ser usadas. Os clientes não veem seu endereço. Por favor, adicione um endereço diferente.',
        es: 'Desafortunadamente, debe tener una dirección física para determinar con precisión su área de servicio, y no se pueden usar apartados postales. Los clientes no ven su dirección. Por favor, agregue una dirección diferente.',
        ru: 'К сожалению, у вас должен быть физический адрес, чтобы точно определить вашу зону обслуживания, и почтовые ящики не могут быть использованы. Клиенты не видят ваш адрес. Пожалуйста, добавьте другой адрес.',
        fr: 'Malheureusement, vous devez avoir une adresse physique pour déterminer avec précision votre zone de service, et les boîtes postales ne peuvent pas être utilisées. Les clients ne voient pas votre adresse. Veuillez ajouter une autre adresse.',
        de: 'Leider müssen Sie eine physische Adresse haben, um Ihr Servicegebiet genau zu bestimmen, und Postfächer können nicht verwendet werden. Kunden sehen Ihre Adresse nicht. Bitte fügen Sie eine andere Adresse hinzu.'
    },
    'There were errors in your submission.  Please scroll up to fix errors.': {
        pt: 'Houve erros na sua submissão. Por favor, role para cima para corrigir os erros.',
        es: 'Hubo errores en su envío. Por favor, desplácese hacia arriba para corregir los errores.',
        ru: 'В вашей отправке были ошибки. Пожалуйста, прокрутите вверх, чтобы исправить ошибки.',
        fr: 'Il y a eu des erreurs dans votre soumission. Veuillez faire défiler vers le haut pour corriger les erreurs.',
        de: 'Es gab Fehler in Ihrer Einreichung. Bitte scrollen Sie nach oben, um die Fehler zu beheben.'
    },

    'Background Check': {
        pt: 'Verificação de Antecedentes',
        es: 'Verificación de Antecedentes',
        ru: 'Проверка Биографии',
        fr: 'Vérification des Antécédents',
        de: 'Hintergrundüberprüfung'
    },
    'In order to get Clients from TIDY you\'ll need to complete a background check. TIDY uses a third party called TURN for background checks, the email will come from a @turning.io email.': {
        pt: 'Para obter clientes da TIDY, você precisará completar uma verificação de antecedentes. A TIDY usa um terceiro chamado TURN para verificações de antecedentes, o e-mail virá de um e-mail @turning.io.',
        es: 'Para obtener clientes de TIDY, necesitará completar una verificación de antecedentes. TIDY utiliza un tercero llamado TURN para verificaciones de antecedentes, el correo electrónico vendrá de un correo @turning.io.',
        ru: 'Чтобы получить клиентов от TIDY, вам нужно будет пройти проверку биографии. TIDY использует третью сторону под названием TURN для проверок биографии, электронное письмо придет с адреса @turning.io.',
        fr: 'Pour obtenir des clients de TIDY, vous devrez compléter une vérification des antécédents. TIDY utilise un tiers appelé TURN pour les vérifications des antécédents, l\'e-mail proviendra d\'un e-mail @turning.io.',
        de: 'Um Kunden von TIDY zu erhalten, müssen Sie eine Hintergrundüberprüfung abschließen. TIDY verwendet einen Drittanbieter namens TURN für Hintergrundüberprüfungen, die E-Mail wird von einer @turning.io E-Mail kommen.'
    },
    'Please confirm detail about you before initiating a background check:': {
        pt: 'Por favor, confirme os detalhes sobre você antes de iniciar uma verificação de antecedentes:',
        es: 'Por favor, confirme los detalles sobre usted antes de iniciar una verificación de antecedentes:',
        ru: 'Пожалуйста, подтвердите информацию о себе перед началом проверки биографии:',
        fr: 'Veuillez confirmer les détails vous concernant avant de commencer une vérification des antécédents :',
        de: 'Bitte bestätigen Sie die Details über sich, bevor Sie eine Hintergrundüberprüfung einleiten:'
    },
    'Can you accept direct deposits?': {
        pt: 'Você pode aceitar depósitos diretos?',
        es: '¿Puede aceptar depósitos directos?',
        ru: 'Можете ли вы принимать прямые депозиты?',
        fr: 'Pouvez-vous accepter les dépôts directs ?',
        de: 'Können Sie Direktüberweisungen akzeptieren?'
    },
    'You must be setup to accept direct deposits to accept payments.': {
        pt: 'Você deve estar configurado para aceitar depósitos diretos para aceitar pagamentos.',
        es: 'Debe estar configurado para aceptar depósitos directos para aceptar pagos.',
        ru: 'Вы должны быть настроены на прием прямых депозитов для приема платежей.',
        fr: 'Vous devez être configuré pour accepter les dépôts directs pour accepter les paiements.',
        de: 'Sie müssen eingerichtet sein, um Direktüberweisungen zu akzeptieren, um Zahlungen zu akzeptieren.'
    },
    'Do you have a smartphone?': {
        pt: 'Você tem um smartphone?',
        es: '¿Tienes un smartphone?',
        ru: 'У вас есть смартфон?',
        fr: 'Avez-vous un smartphone ?',
        de: 'Haben Sie ein Smartphone?'
    },
    'You must have a smartphone.': {
        pt: 'Você deve ter um smartphone.',
        es: 'Debe tener un smartphone.',
        ru: 'У вас должен быть смартфон.',
        fr: 'Vous devez avoir un smartphone.',
        de: 'Sie müssen ein Smartphone haben.'
    },
    'Do you have a car?': {
        pt: 'Você tem um carro?',
        es: '¿Tienes un coche?',
        ru: 'У вас есть машина?',
        fr: 'Avez-vous une voiture ?',
        de: 'Haben Sie ein Auto?'
    },
    'You must have a car.': {
        pt: 'Você deve ter um carro.',
        es: 'Debe tener un coche.',
        ru: 'У вас должна быть машина.',
        fr: 'Vous devez avoir une voiture.',
        de: 'Sie müssen ein Auto haben.'
    },
    'Do you have a felony or misdemeanor related to theft or violence on your background check? If so, do not apply, platform rules do not allow it and we do check thoroughly.': {
        pt: 'Você tem um crime ou contravenção relacionado a roubo ou violência na sua verificação de antecedentes? Se sim, não se inscreva, as regras da plataforma não permitem e verificamos minuciosamente.',
        es: '¿Tiene un delito grave o menor relacionado con robo o violencia en su verificación de antecedentes? Si es así, no se postule, las reglas de la plataforma no lo permiten y verificamos minuciosamente.',
        ru: 'У вас есть уголовное преступление или проступок, связанный с кражей или насилием, в вашей проверке биографии? Если да, не подавайте заявку, правила платформы этого не позволяют, и мы тщательно проверяем.',
        fr: 'Avez-vous un crime ou un délit lié au vol ou à la violence dans votre vérification des antécédents ? Si oui, ne postulez pas, les règles de la plateforme ne le permettent pas et nous vérifions minutieusement.',
        de: 'Haben Sie ein Verbrechen oder Vergehen im Zusammenhang mit Diebstahl oder Gewalt in Ihrer Hintergrundüberprüfung? Wenn ja, bewerben Sie sich nicht, die Plattformregeln erlauben dies nicht und wir überprüfen gründlich.'
    },
    'Applicants with felonies are not allowed.': {
        pt: 'Candidatos com crimes não são permitidos.',
        es: 'No se permiten solicitantes con delitos graves.',
        ru: 'Заявители с уголовными преступлениями не допускаются.',
        fr: 'Les candidats ayant des crimes ne sont pas autorisés.',
        de: 'Bewerber mit Straftaten sind nicht erlaubt.'
    },
    'Do you have allergies that would prevent you from going to homes (including dog/cat allergies)?': {
        pt: 'Você tem alergias que o impediriam de ir a casas (incluindo alergias a cães/gatos)?',
        es: '¿Tiene alergias que le impedirían ir a casas (incluidas alergias a perros/gatos)?',
        ru: 'У вас есть аллергии, которые помешали бы вам посещать дома (включая аллергию на собак/кошек)?',
        fr: 'Avez-vous des allergies qui vous empêcheraient d\'aller dans des maisons (y compris des allergies aux chiens/chats) ?',
        de: 'Haben Sie Allergien, die Sie daran hindern würden, Häuser zu betreten (einschließlich Hunde-/Katzenallergien)?'
    },
    'We cannot accommodate allergies that might prevent going to homes at this time.': {
        pt: 'Não podemos acomodar alergias que possam impedir a ida a casas neste momento.',
        es: 'No podemos acomodar alergias que puedan impedir ir a casas en este momento.',
        ru: 'Мы не можем учитывать аллергии, которые могут помешать посещению домов в настоящее время.',
        fr: 'Nous ne pouvons pas accommoder les allergies qui pourraient empêcher d\'aller dans des maisons pour le moment.',
        de: 'Wir können derzeit keine Allergien berücksichtigen, die den Besuch von Häusern verhindern könnten.'
    },
    'Do you read and speak English well enough to understand Client notes?': {
        pt: 'Você lê e fala inglês bem o suficiente para entender as notas do cliente?',
        es: '¿Lees y hablas inglés lo suficientemente bien como para entender las notas del cliente?',
        ru: 'Вы достаточно хорошо читаете и говорите по-английски, чтобы понимать заметки клиента?',
        fr: 'Lisez-vous et parlez-vous suffisamment bien l\'anglais pour comprendre les notes du client ?',
        de: 'Lesen und sprechen Sie gut genug Englisch, um die Notizen des Kunden zu verstehen?'
    },
    'Applicants must be able to read and speak English to apply.': {
        pt: 'Os candidatos devem ser capazes de ler e falar inglês para se inscrever.',
        es: 'Los solicitantes deben poder leer y hablar inglés para postularse.',
        ru: 'Заявители должны уметь читать и говорить по-английски, чтобы подать заявку.',
        fr: 'Les candidats doivent être capables de lire et de parler anglais pour postuler.',
        de: 'Bewerber müssen in der Lage sein, Englisch zu lesen und zu sprechen, um sich zu bewerben.'
    },
    'Start Background Check': {
        pt: 'Iniciar Verificação de Antecedentes',
        es: 'Iniciar Verificación de Antecedentes',
        ru: 'Начать Проверку Биографии',
        fr: 'Commencer la Vérification des Antécédents',
        de: 'Hintergrundüberprüfung Starten'
    },
    'We sent an email to ': {
        pt: 'Enviamos um e-mail para ',
        es: 'Enviamos un correo electrónico a ',
        ru: 'Мы отправили электронное письмо на ',
        fr: 'Nous avons envoyé un e-mail à ',
        de: 'Wir haben eine E-Mail gesendet an '
    },
    ' from our background check provider TURN (from a @turning.io email). Check your email and complete the check to proceed. Please allow 24 hours for the email to arrive.': {
        pt: ' do nosso provedor de verificação de antecedentes TURN (de um e-mail @turning.io). Verifique seu e-mail e complete a verificação para prosseguir. Por favor, aguarde 24 horas para o e-mail chegar.',
        es: ' de nuestro proveedor de verificación de antecedentes TURN (de un correo electrónico @turning.io). Verifique su correo electrónico y complete la verificación para continuar. Por favor, espere 24 horas para que llegue el correo electrónico.',
        ru: ' от нашего поставщика проверок биографии TURN (с адреса @turning.io). Проверьте свою электронную почту и завершите проверку, чтобы продолжить. Пожалуйста, подождите 24 часа для получения электронного письма.',
        fr: ' de notre fournisseur de vérification des antécédents TURN (d\'un e-mail @turning.io). Vérifiez votre e-mail et complétez la vérification pour continuer. Veuillez prévoir 24 heures pour que l\'e-mail arrive.',
        de: ' von unserem Hintergrundüberprüfungsanbieter TURN (von einer @turning.io E-Mail). Überprüfen Sie Ihre E-Mails und schließen Sie die Überprüfung ab, um fortzufahren. Bitte erlauben Sie 24 Stunden für die Ankunft der E-Mail.'
    },
    'Resend Email': {
        pt: 'Reenviar E-mail',
        es: 'Reenviar Correo Electrónico',
        ru: 'Повторно Отправить Электронное Письмо',
        fr: 'Renvoyer l\'E-mail',
        de: 'E-Mail Erneut Senden'
    },
    'Our background check partner has already gotten your information and is processing the check. This can take up to 2 weeks, depending on the counties involved.': {
        pt: 'Nosso parceiro de verificação de antecedentes já recebeu suas informações e está processando a verificação. Isso pode levar até 2 semanas, dependendo dos condados envolvidos.',
        es: 'Nuestro socio de verificación de antecedentes ya ha recibido su información y está procesando la verificación. Esto puede tardar hasta 2 semanas, dependiendo de los condados involucrados.',
        ru: 'Наш партнер по проверке биографии уже получил вашу информацию и обрабатывает проверку. Это может занять до 2 недель, в зависимости от задействованных округов.',
        fr: 'Notre partenaire de vérification des antécédents a déjà reçu vos informations et traite la vérification. Cela peut prendre jusqu\'à 2 semaines, selon les comtés impliqués.',
        de: 'Unser Partner für Hintergrundüberprüfungen hat Ihre Informationen bereits erhalten und bearbeitet die Überprüfung. Dies kann je nach beteiligten Landkreisen bis zu 2 Wochen dauern.'
    },
    'Email Sent': {
        pt: 'E-mail Enviado',
        es: 'Correo Electrónico Enviado',
        ru: 'Электронное Письмо Отправлено',
        fr: 'E-mail Envoyé',
        de: 'E-Mail Gesendet'
    },
    'Email Resent': {
        pt: 'E-mail Reenviado',
        es: 'Correo Electrónico Reenviado',
        ru: 'Электронное Письмо Повторно Отправлено',
        fr: 'E-mail Renvoyé',
        de: 'E-Mail Erneut Gesendet'
    },

    'Create an account to connect your bank account and get paid for this job. You can also browse available jobs. It\'s free to use.': {
        pt: 'Crie uma conta para conectar sua conta bancária e receber por este trabalho. Você também pode navegar por trabalhos disponíveis. É gratuito para usar.',
        es: 'Crea una cuenta para conectar tu cuenta bancaria y recibir el pago por este trabajo. También puedes buscar trabajos disponibles. Es gratis de usar.',
        ru: 'Создайте аккаунт, чтобы подключить свой банковский счет и получить оплату за эту работу. Вы также можете просматривать доступные вакансии. Это бесплатно.',
        fr: 'Créez un compte pour connecter votre compte bancaire et être payé pour ce travail. Vous pouvez également parcourir les emplois disponibles. C\'est gratuit à utiliser.',
        de: 'Erstellen Sie ein Konto, um Ihr Bankkonto zu verbinden und für diesen Job bezahlt zu werden. Sie können auch verfügbare Jobs durchsuchen. Es ist kostenlos zu nutzen.'
    },
    'Create an account to an send invoice & instantly request payment. You can also browse available jobs. It’s free to use.': {
        pt: 'Crie uma conta para enviar faturas e solicitar pagamento instantaneamente. Você também pode navegar por trabalhos disponíveis. É gratuito para usar.',
        es: 'Crea una cuenta para enviar facturas y solicitar pagos instantáneamente. También puedes buscar trabajos disponibles. Es gratis de usar.',
        ru: 'Создайте аккаунт, чтобы отправлять счета и мгновенно запрашивать оплату. Вы также можете просматривать доступные вакансии. Это бесплатно.',
        fr: 'Créez un compte pour envoyer des factures et demander un paiement instantanément. Vous pouvez également parcourir les emplois disponibles. C\'est gratuit à utiliser.',
        de: 'Erstellen Sie ein Konto, um Rechnungen zu senden und sofortige Zahlungen anzufordern. Sie können auch verfügbare Jobs durchsuchen. Es ist kostenlos zu nutzen.'
    },
    'First Name': {
        pt: 'Primeiro Nome',
        es: 'Nombre',
        ru: 'Имя',
        fr: 'Prénom',
        de: 'Vorname'
    },
    'Last Name': {
        pt: 'Sobrenome',
        es: 'Apellido',
        ru: 'Фамилия',
        fr: 'Nom de Famille',
        de: 'Nachname'
    },
    'Please enter a password (at least 8 characters)': {
        pt: 'Por favor, insira uma senha (pelo menos 8 caracteres)',
        es: 'Por favor, ingrese una contraseña (al menos 8 caracteres)',
        ru: 'Пожалуйста, введите пароль (не менее 8 символов)',
        fr: 'Veuillez entrer un mot de passe (au moins 8 caractères)',
        de: 'Bitte geben Sie ein Passwort ein (mindestens 8 Zeichen)'
    },
    'Do you already have existing clients you work with?': {
        pt: 'Você já tem clientes com quem trabalha?',
        es: '¿Ya tienes clientes con los que trabajas?',
        ru: 'У вас уже есть клиенты, с которыми вы работаете?',
        fr: 'Avez-vous déjà des clients avec lesquels vous travaillez?',
        de: 'Haben Sie bereits bestehende Kunden, mit denen Sie arbeiten?'
    },
    'No clients yet': {
        pt: 'Ainda não há clientes',
        es: 'Aún no hay clientes',
        ru: 'Пока нет клиентов',
        fr: 'Pas encore de clients',
        de: 'Noch keine Kunden'
    },
    'Yes I have existing clients': {
        pt: 'Sim, eu tenho clientes existentes',
        es: 'Sí, tengo clientes existentes',
        ru: 'Да, у меня есть существующие клиенты',
        fr: 'Oui, j\'ai des clients existants',
        de: 'Ja, ich habe bestehende Kunden'
    },
    'What does your team look like?': {
        pt: 'Como é a sua equipe?',
        es: '¿Cómo es tu equipo?',
        ru: 'Как выглядит ваша команда?',
        fr: 'À quoi ressemble votre équipe?',
        de: 'Wie sieht Ihr Team aus?'
    },
    'Just me': {
        pt: 'Apenas eu',
        es: 'Solo yo',
        ru: 'Только я',
        fr: 'Juste moi',
        de: 'Nur ich'
    },
    'Me + others (anyone you might work with or send jobs to)': {
        pt: 'Eu + outros (qualquer pessoa com quem você possa trabalhar ou enviar trabalhos)',
        es: 'Yo + otros (cualquiera con quien puedas trabajar o enviar trabajos)',
        ru: 'Я + другие (любой, с кем вы можете работать или отправлять задания)',
        fr: 'Moi + d\'autres (toute personne avec qui vous pourriez travailler ou envoyer des travaux)',
        de: 'Ich + andere (jeder, mit dem Sie arbeiten oder Jobs senden könnten)'
    },
    'I agree to the ': {
        pt: 'Eu concordo com o ',
        es: 'Estoy de acuerdo con el ',
        ru: 'Я согласен с ',
        fr: 'Je suis d\'accord avec le ',
        de: 'Ich stimme dem zu '
    },
    ', which includes agreeing to individual arbitration.*': {
        pt: ', que inclui concordar com a arbitragem individual.*',
        es: ', que incluye aceptar el arbitraje individual.*',
        ru: ', что включает согласие на индивидуальный арбитраж.*',
        fr: ', ce qui inclut l\'acceptation de l\'arbitrage individuel.*',
        de: ', was die Zustimmung zur individuellen Schiedsgerichtsbarkeit beinhaltet.*'
    },
    'You must agree to the terms to continue.': {
        pt: 'Você deve concordar com os termos para continuar.',
        es: 'Debe aceptar los términos para continuar.',
        ru: 'Вы должны согласиться с условиями, чтобы продолжить.',
        fr: 'Vous devez accepter les termes pour continuer.',
        de: 'Sie müssen den Bedingungen zustimmen, um fortzufahren.'
    },
    'Over 10,000 Pros use TIDY': {
        pt: 'Mais de 10.000 profissionais usam o TIDY',
        es: 'Más de 10,000 profesionales usan TIDY',
        ru: 'Более 10 000 профессионалов используют TIDY',
        fr: 'Plus de 10 000 professionnels utilisent TIDY',
        de: 'Über 10.000 Profis nutzen TIDY'
    },
    '*If you would like an alternate agreement, contact sales@tidy.com instead of agreeing to these terms.': {
        pt: '*Se você gostaria de um acordo alternativo, entre em contato com sales@tidy.com em vez de concordar com estes termos.',
        es: '*Si desea un acuerdo alternativo, comuníquese con sales@tidy.com en lugar de aceptar estos términos.',
        ru: '*Если вы хотите альтернативное соглашение, свяжитесь с sales@tidy.com вместо того, чтобы соглашаться с этими условиями.',
        fr: '*Si vous souhaitez un accord alternatif, contactez sales@tidy.com au lieu d\'accepter ces termes.',
        de: '*Wenn Sie eine alternative Vereinbarung wünschen, kontaktieren Sie sales@tidy.com anstatt diesen Bedingungen zuzustimmen.'
    },
    'There is already an account under this email. Please log in there or use a different email.': {
        pt: 'Já existe uma conta com este e-mail. Por favor, faça login lá ou use um e-mail diferente.',
        es: 'Ya existe una cuenta con este correo electrónico. Por favor, inicie sesión allí o use un correo electrónico diferente.',
        ru: 'Уже существует аккаунт с этой электронной почтой. Пожалуйста, войдите там или используйте другую электронную почту.',
        fr: 'Il existe déjà un compte avec cet e-mail. Veuillez vous connecter là-bas ou utiliser un e-mail différent.',
        de: 'Es gibt bereits ein Konto mit dieser E-Mail. Bitte melden Sie sich dort an oder verwenden Sie eine andere E-Mail.'
    },
    'TIDY lets you manage your existing clients and get new clients for free! We know your work is hard, and its our mission to help. <br><br> Make sure to check out the "Getting Started" steps on the dashboard for more details, and if you ever need help, contact our Concierge team.': {
        pt: 'A TIDY permite que você gerencie seus clientes existentes e obtenha novos clientes gratuitamente! Sabemos que seu trabalho é difícil e nossa missão é ajudar. <br><br> Certifique-se de verificar as etapas de "Primeiros Passos" no painel para mais detalhes, e se você precisar de ajuda, entre em contato com nossa equipe de Concierge.',
        es: 'TIDY te permite gestionar tus clientes existentes y conseguir nuevos clientes de forma gratuita! Sabemos que tu trabajo es duro y nuestra misión es ayudar. <br><br> Asegúrate de revisar los pasos de "Primeros Pasos" en el panel para más detalles, y si necesitas ayuda, contacta a nuestro equipo de Concierge.',
        ru: 'TIDY позволяет вам управлять вашими существующими клиентами и получать новых клиентов бесплатно! Мы знаем, что ваша работа трудна, и наша миссия - помочь. <br><br> Обязательно ознакомьтесь с шагами "Начало работы" на панели для получения дополнительной информации, и если вам нужна помощь, свяжитесь с нашей командой консьержей.',
        fr: 'TIDY vous permet de gérer vos clients existants et d\'obtenir de nouveaux clients gratuitement! Nous savons que votre travail est difficile et notre mission est d\'aider. <br><br> Assurez-vous de consulter les étapes "Pour commencer" sur le tableau de bord pour plus de détails, et si vous avez besoin d\'aide, contactez notre équipe de conciergerie.',
        de: 'TIDY ermöglicht es Ihnen, Ihre bestehenden Kunden zu verwalten und neue Kunden kostenlos zu gewinnen! Wir wissen, dass Ihre Arbeit hart ist, und es ist unsere Mission zu helfen. <br><br> Stellen Sie sicher, dass Sie die Schritte "Erste Schritte" auf dem Dashboard für weitere Details überprüfen, und wenn Sie Hilfe benötigen, wenden Sie sich an unser Concierge-Team.'
    },

    'Thanks for Signing Up!': {
        pt: 'Obrigado por se inscrever!',
        es: '¡Gracias por registrarte!',
        ru: 'Спасибо за регистрацию!',
        fr: 'Merci pour votre inscription!',
        de: 'Danke für Ihre Anmeldung!'
    },
    'Go To Dashboard': {
        pt: 'Ir para o Painel',
        es: 'Ir al Panel',
        ru: 'Перейти к панели управления',
        fr: 'Aller au tableau de bord',
        de: 'Zum Dashboard gehen'
    },
    'Wow... I made over $1,000 more in a week with clients who booked me on tidy.com. Off to a great start!': {
        pt: 'Uau... Ganhei mais de $1.000 em uma semana com clientes que me reservaram no tidy.com. Um ótimo começo!',
        es: 'Wow... Gané más de $1,000 en una semana con clientes que me reservaron en tidy.com. ¡Un gran comienzo!',
        ru: 'Вау... Я заработал на $1,000 больше за неделю с клиентами, которые забронировали меня на tidy.com. Отличное начало!',
        fr: 'Wow... J\'ai gagné plus de 1 000 $ de plus en une semaine avec des clients qui m\'ont réservé sur tidy.com. Un excellent début!',
        de: 'Wow... Ich habe in einer Woche über 1.000 $ mehr verdient mit Kunden, die mich auf tidy.com gebucht haben. Ein großartiger Start!'
    },
    'It\'s awesome to set my preferences and only let clients book me who match my rules.': {
        pt: 'É incrível definir minhas preferências e permitir que apenas clientes que correspondam às minhas regras me reservem.',
        es: 'Es increíble establecer mis preferencias y permitir que solo los clientes que coincidan con mis reglas me reserven.',
        ru: 'Это здорово - устанавливать свои предпочтения и позволять бронировать меня только клиентам, которые соответствуют моим правилам.',
        fr: 'C\'est génial de définir mes préférences et de ne laisser les clients me réserver que s\'ils correspondent à mes règles.',
        de: 'Es ist großartig, meine Präferenzen festzulegen und nur Kunden zu erlauben, mich zu buchen, die meinen Regeln entsprechen.'
    },
    'Do you already have existing Clients you work with?': {
        pt: 'Você já tem clientes existentes com quem trabalha?',
        es: '¿Ya tienes clientes existentes con los que trabajas?',
        ru: 'У вас уже есть существующие клиенты, с которыми вы работаете?',
        fr: 'Avez-vous déjà des clients existants avec lesquels vous travaillez?',
        de: 'Haben Sie bereits bestehende Kunden, mit denen Sie arbeiten?'
    },

    'Certify Equipment': {
        pt: 'Certificar Equipamento',
        es: 'Certificar Equipo',
        ru: 'Сертифицировать Оборудование',
        fr: 'Certifier l\'Équipement',
        de: 'Ausrüstung Zertifizieren'
    },
    'You’ve been <b>pre-approved</b>!  There are clients in your area needing your help.': {
        pt: 'Você foi <b>pré-aprovado</b>! Há clientes na sua área precisando da sua ajuda.',
        es: 'Has sido <b>pre-aprobado</b>! Hay clientes en tu área que necesitan tu ayuda.',
        ru: 'Вы были <b>предварительно одобрены</b>! В вашем районе есть клиенты, которым нужна ваша помощь.',
        fr: 'Vous avez été <b>pré-approuvé</b>! Il y a des clients dans votre région qui ont besoin de votre aide.',
        de: 'Sie wurden <b>vorab genehmigt</b>! Es gibt Kunden in Ihrer Nähe, die Ihre Hilfe benötigen.'
    },
    'Already Have a Web Presence?': {
        pt: 'Já tem uma Presença na Web?',
        es: '¿Ya tienes una Presencia en la Web?',
        ru: 'Уже есть Веб-присутствие?',
        fr: 'Vous avez déjà une Présence sur le Web?',
        de: 'Haben Sie bereits eine Webpräsenz?'
    },
    'If you are on Yelp or Google Places, you can provide that URL to validate yourself instead of a photo.': {
        pt: 'Se você está no Yelp ou Google Places, pode fornecer esse URL para se validar em vez de uma foto.',
        es: 'Si estás en Yelp o Google Places, puedes proporcionar esa URL para validarte en lugar de una foto.',
        ru: 'Если вы находитесь на Yelp или Google Places, вы можете предоставить этот URL для подтверждения вместо фотографии.',
        fr: 'Si vous êtes sur Yelp ou Google Places, vous pouvez fournir cette URL pour vous valider au lieu d\'une photo.',
        de: 'Wenn Sie auf Yelp oder Google Places sind, können Sie diese URL angeben, um sich anstelle eines Fotos zu validieren.'
    },
    'Get Instant Certification': {
        pt: 'Obtenha Certificação Instantânea',
        es: 'Obtén Certificación Instantánea',
        ru: 'Получите Мгновенную Сертификацию',
        fr: 'Obtenez une Certification Instantanée',
        de: 'Erhalten Sie Sofortige Zertifizierung'
    },
    'Confirm Supplies': {
        pt: 'Confirmar Suprimentos',
        es: 'Confirmar Suministros',
        ru: 'Подтвердите Запасы',
        fr: 'Confirmer les Fournitures',
        de: 'Vorräte Bestätigen'
    },
    'Confirm that you have the supplies and materials necessary to provide the services listed on my profile. For example, for cleaning this includes a vacuum and mop.': {
        pt: 'Confirme que você tem os suprimentos e materiais necessários para fornecer os serviços listados no meu perfil. Por exemplo, para limpeza, isso inclui um aspirador e um esfregão.',
        es: 'Confirma que tienes los suministros y materiales necesarios para proporcionar los servicios listados en mi perfil. Por ejemplo, para la limpieza, esto incluye una aspiradora y una fregona.',
        ru: 'Подтвердите, что у вас есть необходимые запасы и материалы для предоставления услуг, указанных в моем профиле. Например, для уборки это включает в себя пылесос и швабру.',
        fr: 'Confirmez que vous avez les fournitures et matériaux nécessaires pour fournir les services listés sur mon profil. Par exemple, pour le nettoyage, cela inclut un aspirateur et une serpillière.',
        de: 'Bestätigen Sie, dass Sie die notwendigen Vorräte und Materialien haben, um die in meinem Profil aufgeführten Dienstleistungen zu erbringen. Zum Beispiel umfasst dies für die Reinigung einen Staubsauger und einen Mopp.'
    },
    'I Have Supplies': {
        pt: 'Eu Tenho Suprimentos',
        es: 'Tengo Suministros',
        ru: 'У Меня Есть Запасы',
        fr: 'J\'ai des Fournitures',
        de: 'Ich Habe Vorräte'
    },
    'You must have these supplies': {
        pt: 'Você deve ter esses suprimentos',
        es: 'Debes tener estos suministros',
        ru: 'У вас должны быть эти запасы',
        fr: 'Vous devez avoir ces fournitures',
        de: 'Sie müssen diese Vorräte haben'
    },
    'Equipment Profile Photo': {
        pt: 'Foto de Perfil do Equipamento',
        es: 'Foto de Perfil del Equipo',
        ru: 'Фото Профиля Оборудования',
        fr: 'Photo de Profil de l\'Équipement',
        de: 'Ausrüstungsprofilfoto'
    },
    'Take a photo of your equipment. Clients may see it so make it look good!': {
        pt: 'Tire uma foto do seu equipamento. Os clientes podem vê-la, então faça com que pareça boa!',
        es: 'Toma una foto de tu equipo. Los clientes pueden verla, ¡así que haz que se vea bien!',
        ru: 'Сделайте фото вашего оборудования. Клиенты могут его увидеть, так что сделайте его красивым!',
        fr: 'Prenez une photo de votre équipement. Les clients peuvent la voir, alors faites en sorte qu\'elle soit belle!',
        de: 'Machen Sie ein Foto von Ihrer Ausrüstung. Kunden können es sehen, also lassen Sie es gut aussehen!'
    },
    'Retake Photo': {
        pt: 'Retirar Foto',
        es: 'Volver a Tomar Foto',
        ru: 'Переснять Фото',
        fr: 'Reprendre la Photo',
        de: 'Foto Erneut Aufnehmen'
    },
    'Take Photo': {
        pt: 'Tirar Foto',
        es: 'Tomar Foto',
        ru: 'Сделать Фото',
        fr: 'Prendre la Photo',
        de: 'Foto Machen'
    },
    'Please take a picture of your equipment.': {
        pt: 'Por favor, tire uma foto do seu equipamento.',
        es: 'Por favor, toma una foto de tu equipo.',
        ru: 'Пожалуйста, сделайте фото вашего оборудования.',
        fr: 'Veuillez prendre une photo de votre équipement.',
        de: 'Bitte machen Sie ein Foto von Ihrer Ausrüstung.'
    },
    'Example Equipment Photo:': {
        pt: 'Exemplo de Foto de Equipamento:',
        es: 'Ejemplo de Foto de Equipo:',
        ru: 'Пример Фото Оборудования:',
        fr: 'Exemple de Photo d\'Équipement:',
        de: 'Beispiel für ein Ausrüstungsfoto:'
    },
    'D or F (Not Approved)': {
        pt: 'D ou F (Não Aprovado)',
        es: 'D o F (No Aprobado)',
        ru: 'D или F (Не Одобрено)',
        fr: 'D ou F (Non Approuvé)',
        de: 'D oder F (Nicht Genehmigt)'
    },
    'Not Rated but TIDY Approved': {
        pt: 'Não Avaliado mas Aprovado pelo TIDY',
        es: 'No Calificado pero Aprobado por TIDY',
        ru: 'Не Оценено, но Одобрено TIDY',
        fr: 'Non Évalué mais Approuvé par TIDY',
        de: 'Nicht Bewertet, aber von TIDY Genehmigt'
    },
    'Invalid grade': {
        pt: 'Nota inválida',
        es: 'Calificación inválida',
        ru: 'Недействительная оценка',
        fr: 'Note invalide',
        de: 'Ungültige Note'
    },
    'You have not granted the TIDY app access to location services. Please grant the TIDY app access to location services in your phone settings to submit this photo.': {
        pt: 'Você não concedeu acesso aos serviços de localização para o aplicativo TIDY. Por favor, conceda acesso aos serviços de localização nas configurações do seu telefone para enviar esta foto.',
        es: 'No has concedido acceso a los servicios de ubicación para la aplicación TIDY. Por favor, concede acceso a los servicios de ubicación en la configuración de tu teléfono para enviar esta foto.',
        ru: 'Вы не предоставили приложению TIDY доступ к службам геолокации. Пожалуйста, предоставьте доступ к службам геолокации в настройках вашего телефона, чтобы отправить это фото.',
        fr: 'Vous n\'avez pas accordé l\'accès aux services de localisation à l\'application TIDY. Veuillez accorder l\'accès aux services de localisation dans les paramètres de votre téléphone pour soumettre cette photo.',
        de: 'Sie haben der TIDY-App keinen Zugriff auf die Ortungsdienste gewährt. Bitte gewähren Sie der TIDY-App Zugriff auf die Ortungsdienste in den Einstellungen Ihres Telefons, um dieses Foto einzureichen.'
    },
    'Equipment Submitted': {
        pt: 'Equipamento Enviado',
        es: 'Equipo Enviado',
        ru: 'Оборудование Отправлено',
        fr: 'Équipement Soumis',
        de: 'Ausrüstung Eingereicht'
    },
    'Our Concierge will review and let you know if your equipment is approved within 12 hours.': {
        pt: 'Nosso Concierge revisará e informará se seu equipamento foi aprovado dentro de 12 horas.',
        es: 'Nuestro Concierge revisará y te informará si tu equipo ha sido aprobado dentro de las 12 horas.',
        ru: 'Наш консьерж проверит и сообщит вам, если ваше оборудование будет одобрено в течение 12 часов.',
        fr: 'Notre Concierge examinera et vous informera si votre équipement est approuvé dans les 12 heures.',
        de: 'Unser Concierge wird es überprüfen und Ihnen innerhalb von 12 Stunden mitteilen, ob Ihre Ausrüstung genehmigt wurde.'
    },
    'No image selected': {
        pt: 'Nenhuma imagem selecionada',
        es: 'Ninguna imagen seleccionada',
        ru: 'Изображение не выбрано',
        fr: 'Aucune image sélectionnée',
        de: 'Kein Bild ausgewählt'
    },
    'Unable to save this file type. Please upload a JPEG or PNG image.': {
        pt: 'Não é possível salvar este tipo de arquivo. Por favor, carregue uma imagem JPEG ou PNG.',
        es: 'No se puede guardar este tipo de archivo. Por favor, sube una imagen JPEG o PNG.',
        ru: 'Невозможно сохранить этот тип файла. Пожалуйста, загрузите изображение в формате JPEG или PNG.',
        fr: 'Impossible d\'enregistrer ce type de fichier. Veuillez télécharger une image JPEG ou PNG.',
        de: 'Dieser Dateityp kann nicht gespeichert werden. Bitte laden Sie ein JPEG- oder PNG-Bild hoch.'
    },
    'No Image Selected': {
        pt: 'Nenhuma Imagem Selecionada',
        es: 'Ninguna Imagen Seleccionada',
        ru: 'Изображение Не Выбрано',
        fr: 'Aucune Image Sélectionnée',
        de: 'Kein Bild Ausgewählt'
    },

    'Instant Certification': {
        pt: 'Certificação Instantânea',
        es: 'Certificación Instantánea',
        ru: 'Мгновенная Сертификация',
        fr: 'Certification Instantanée',
        de: 'Sofortige Zertifizierung'
    },
    'What is your Yelp/Google link?': {
        pt: 'Qual é o seu link do Yelp/Google?',
        es: '¿Cuál es tu enlace de Yelp/Google?',
        ru: 'Какова ваша ссылка на Yelp/Google?',
        fr: 'Quel est votre lien Yelp/Google?',
        de: 'Was ist Ihr Yelp/Google-Link?'
    },
    'Please enter a valid URL': {
        pt: 'Por favor, insira uma URL válida',
        es: 'Por favor, ingrese una URL válida',
        ru: 'Пожалуйста, введите действительный URL',
        fr: 'Veuillez entrer une URL valide',
        de: 'Bitte geben Sie eine gültige URL ein'
    },
    'Submit for Instant Certification': {
        pt: 'Enviar para Certificação Instantânea',
        es: 'Enviar para Certificación Instantánea',
        ru: 'Отправить для мгновенной сертификации',
        fr: 'Soumettre pour Certification Instantanée',
        de: 'Zur sofortigen Zertifizierung einreichen'
    },
    'Please enter a valid Yelp/Google URL': {
        pt: 'Por favor, insira uma URL válida do Yelp/Google',
        es: 'Por favor, ingrese una URL válida de Yelp/Google',
        ru: 'Пожалуйста, введите действительный URL Yelp/Google',
        fr: 'Veuillez entrer une URL Yelp/Google valide',
        de: 'Bitte geben Sie eine gültige Yelp/Google-URL ein'
    },
    'Link Submitted': {
        pt: 'Link Enviado',
        es: 'Enlace Enviado',
        ru: 'Ссылка Отправлена',
        fr: 'Lien Soumis',
        de: 'Link Eingereicht'
    },
    'Your equipment has been certified.': {
        pt: 'Seu equipamento foi certificado.',
        es: 'Tu equipo ha sido certificado.',
        ru: 'Ваше оборудование было сертифицировано.',
        fr: 'Votre équipement a été certifié.',
        de: 'Ihr Gerät wurde zertifiziert.'
    },

    'Complete steps to use TIDY to manage existing Clients (we call them Private Clients).': {
        pt: 'Complete as etapas para usar o TIDY para gerenciar Clientes existentes (nós os chamamos de Clientes Privados).',
        es: 'Complete los pasos para usar TIDY para gestionar Clientes existentes (los llamamos Clientes Privados).',
        ru: 'Выполните шаги, чтобы использовать TIDY для управления существующими клиентами (мы называем их частными клиентами).',
        fr: 'Complétez les étapes pour utiliser TIDY pour gérer les Clients existants (nous les appelons Clients Privés).',
        de: 'Schritte abschließen, um TIDY zur Verwaltung bestehender Kunden zu verwenden (wir nennen sie Privatkunden).'
    },
    '/4 Completed': {
        pt: '/4 Concluído',
        es: '/4 Completado',
        ru: '/4 Завершено',
        fr: '/4 Terminé',
        de: '/4 Abgeschlossen'
    },
    'Add Account Info': {
        pt: 'Adicionar Informações da Conta',
        es: 'Agregar Información de la Cuenta',
        ru: 'Добавить информацию об аккаунте',
        fr: 'Ajouter des Informations de Compte',
        de: 'Kontoinformationen hinzufügen'
    },
    'So Clients know who you are.': {
        pt: 'Para que os Clientes saibam quem você é.',
        es: 'Para que los Clientes sepan quién eres.',
        ru: 'Чтобы клиенты знали, кто вы.',
        fr: 'Pour que les Clients sachent qui vous êtes.',
        de: 'Damit die Kunden wissen, wer Sie sind.'
    },
    'Confirm Email': {
        pt: 'Confirmar Email',
        es: 'Confirmar Correo Electrónico',
        ru: 'Подтвердить электронную почту',
        fr: 'Confirmer l\'Email',
        de: 'E-Mail bestätigen'
    },
    'To ensure your email is correct.': {
        pt: 'Para garantir que seu email está correto.',
        es: 'Para asegurar que tu correo electrónico es correcto.',
        ru: 'Чтобы убедиться, что ваша электронная почта верна.',
        fr: 'Pour vous assurer que votre email est correct.',
        de: 'Um sicherzustellen, dass Ihre E-Mail korrekt ist.'
    },
    'Confirm Phone': {
        pt: 'Confirmar Telefone',
        es: 'Confirmar Teléfono',
        ru: 'Подтвердить телефон',
        fr: 'Confirmer le Téléphone',
        de: 'Telefon bestätigen'
    },
    'For Client communication and notifications.': {
        pt: 'Para comunicação e notificações do Cliente.',
        es: 'Para comunicación y notificaciones del Cliente.',
        ru: 'Для связи с клиентом и уведомлений.',
        fr: 'Pour la communication et les notifications du Client.',
        de: 'Für die Kommunikation und Benachrichtigungen des Kunden.'
    },
    'Set Max Drive Time': {
        pt: 'Definir Tempo Máximo de Condução',
        es: 'Establecer Tiempo Máximo de Conducción',
        ru: 'Установить максимальное время в пути',
        fr: 'Définir le Temps de Conduite Maximal',
        de: 'Maximale Fahrzeit festlegen'
    },
    'So Clients know what your work area is.': {
        pt: 'Para que os Clientes saibam qual é a sua área de trabalho.',
        es: 'Para que los Clientes sepan cuál es tu área de trabajo.',
        ru: 'Чтобы клиенты знали, какая у вас рабочая зона.',
        fr: 'Pour que les Clients sachent quelle est votre zone de travail.',
        de: 'Damit die Kunden wissen, welches Ihr Arbeitsbereich ist.'
    },
    'Ready to Add Existing Clients!': {
        pt: 'Pronto para Adicionar Clientes Existentes!',
        es: '¡Listo para Agregar Clientes Existentes!',
        ru: 'Готовы добавить существующих клиентов!',
        fr: 'Prêt à Ajouter des Clients Existants!',
        de: 'Bereit, bestehende Kunden hinzuzufügen!'
    },
    'You can add existing Clients in the add page.': {
        pt: 'Você pode adicionar Clientes existentes na página de adição.',
        es: 'Puedes agregar Clientes existentes en la página de agregar.',
        ru: 'Вы можете добавить существующих клиентов на странице добавления.',
        fr: 'Vous pouvez ajouter des Clients existants sur la page d\'ajout.',
        de: 'Sie können bestehende Kunden auf der Hinzufügen-Seite hinzufügen.'
    },

    'New clients require a quick identification check': {
        pt: 'Novos clientes exigem uma verificação rápida de identificação',
        es: 'Los nuevos clientes requieren una verificación rápida de identificación',
        ru: 'Новые клиенты требуют быстрой проверки идентификации',
        fr: 'Les nouveaux clients nécessitent une vérification rapide de l\'identité',
        de: 'Neue Kunden erfordern eine schnelle Identitätsprüfung'
    },
    'Get Verified': {
        pt: 'Seja Verificado',
        es: 'Ser Verificado',
        ru: 'Пройдите проверку',
        fr: 'Soyez vérifié',
        de: 'Verifiziert werden'
    },

    'Base Rates:': {
        pt: 'Taxas Base:',
        es: 'Tarifas Base:',
        ru: 'Базовые тарифы:',
        fr: 'Tarifs de Base:',
        de: 'Grundtarife:'
    },
    '1 Hour New Client: ': {
        pt: '1 Hora Novo Cliente: ',
        es: '1 Hora Cliente Nuevo: ',
        ru: '1 Час Новый Клиент: ',
        fr: '1 Heure Nouveau Client: ',
        de: '1 Stunde Neukunde: '
    },
    '1 Hour Repeat Client: ': {
        pt: '1 Hora Cliente Recorrente: ',
        es: '1 Hora Cliente Recurrente: ',
        ru: '1 Час Постоянный Клиент: ',
        fr: '1 Heure Client Régulier: ',
        de: '1 Stunde Stammkunde: '
    },
    '2.5 Hour New Client: ': {
        pt: '2,5 Horas Novo Cliente: ',
        es: '2,5 Horas Cliente Nuevo: ',
        ru: '2,5 Часа Новый Клиент: ',
        fr: '2,5 Heures Nouveau Client: ',
        de: '2,5 Stunden Neukunde: '
    },
    '2.5 Hour Repeat Client: ': {
        pt: '2,5 Horas Cliente Recorrente: ',
        es: '2,5 Horas Cliente Recurrente: ',
        ru: '2,5 Часа Постоянный Клиент: ',
        fr: '2,5 Heures Client Régulier: ',
        de: '2,5 Stunden Stammkunde: '
    },
    '4 Hour New Client: ': {
        pt: '4 Horas Novo Cliente: ',
        es: '4 Horas Cliente Nuevo: ',
        ru: '4 Часа Новый Клиент: ',
        fr: '4 Heures Nouveau Client: ',
        de: '4 Stunden Neukunde: '
    },
    '4 Hour Repeat Client: ': {
        pt: '4 Horas Cliente Recorrente: ',
        es: '4 Horas Cliente Recurrente: ',
        ru: '4 Часа Постоянный Клиент: ',
        fr: '4 Heures Client Régulier: ',
        de: '4 Stunden Stammkunde: '
    },
    'Client Fees:': {
        pt: 'Taxas do Cliente:',
        es: 'Tarifas del Cliente:',
        ru: 'Сборы с Клиентов:',
        fr: 'Frais Client:',
        de: 'Kundengebühren:'
    },
    '$5  - Didn\'t Start Correct Time': {
        pt: '$5 - Não Começou no Horário Correto',
        es: '$5 - No Comenzó en el Horario Correcto',
        ru: '$5 - Не Начал в Правильное Время',
        fr: '$5 - N\'a Pas Commencé à l\'Heure Prévue',
        de: '$5 - Nicht zur Korrekten Zeit Begonnen'
    },
    '50% of job cost - Did not clean Client\'s full time': {
        pt: '50% do custo do trabalho - Não limpou o tempo completo do Cliente',
        es: '50% del costo del trabajo - No limpió el tiempo completo del Cliente',
        ru: '50% стоимости работы - Не отработал полное время клиента',
        fr: '50% du coût du travail - N\'a pas nettoyé pendant tout le temps du Client',
        de: '50% der Arbeitskosten - Hat nicht die volle Zeit des Kunden gereinigt'
    },
    '$10 - Cancelling within 72 hours': {
        pt: '$10 - Cancelamento dentro de 72 horas',
        es: '$10 - Cancelación dentro de 72 horas',
        ru: '$10 - Отмена в течение 72 часов',
        fr: '$10 - Annulation dans les 72 heures',
        de: '$10 - Stornierung innerhalb von 72 Stunden'
    },
    '$20 - Cancelling within 24 hours': {
        pt: '$20 - Cancelamento dentro de 24 horas',
        es: '$20 - Cancelación dentro de 24 horas',
        ru: '$20 - Отмена в течение 24 часов',
        fr: '$20 - Annulation dans les 24 heures',
        de: '$20 - Stornierung innerhalb von 24 Stunden'
    },
    '$30 - Cancelling within 3 hours': {
        pt: '$30 - Cancelamento dentro de 3 horas',
        es: '$30 - Cancelación dentro de 3 horas',
        ru: '$30 - Отмена в течение 3 часов',
        fr: '$30 - Annulation dans les 3 heures',
        de: '$30 - Stornierung innerhalb von 3 Stunden'
    },
    '$40 - No show': {
        pt: '$40 - Não comparecimento',
        es: '$40 - No presentarse',
        ru: '$40 - Неявка',
        fr: '$40 - Absence',
        de: '$40 - Nichterscheinen'
    },
    '<i>Add account info & set your max drive time to see rates!</i>': {
        pt: '<i>Adicione informações da conta e defina seu tempo máximo de condução para ver as taxas!</i>',
        es: '<i>¡Agrega información de la cuenta y establece tu tiempo máximo de conducción para ver las tarifas!</i>',
        ru: '<i>Добавьте информацию об аккаунте и установите максимальное время в пути, чтобы увидеть тарифы!</i>',
        fr: '<i>Ajoutez les informations du compte et définissez votre temps de conduite maximal pour voir les tarifs!</i>',
        de: '<i>Fügen Sie Kontoinformationen hinzu und legen Sie Ihre maximale Fahrzeit fest, um die Tarife zu sehen!</i>'
    },
    'Got It': {
        pt: 'Entendi',
        es: 'Entendido',
        ru: 'Понятно',
        fr: 'Compris',
        de: 'Verstanden'
    },
    'You have already finished this step of certification.': {
        pt: 'Você já concluiu esta etapa da certificação.',
        es: 'Ya has terminado este paso de la certificación.',
        ru: 'Вы уже завершили этот этап сертификации.',
        fr: 'Vous avez déjà terminé cette étape de certification.',
        de: 'Sie haben diesen Zertifizierungsschritt bereits abgeschlossen.'
    },
    'What are Clients from TIDY?': {
        pt: 'O que são Clientes da TIDY?',
        es: '¿Qué son los Clientes de TIDY?',
        ru: 'Что такое Клиенты от TIDY?',
        fr: 'Que sont les Clients de TIDY?',
        de: 'Was sind Kunden von TIDY?'
    },
    'By turning on clients from TIDY, you let clients searching for a pro like you using TIDY book you. It is a great way to earn more.': {
        pt: 'Ao ativar clientes da TIDY, você permite que clientes procurando por um profissional como você usando o TIDY reservem seus serviços. É uma ótima maneira de ganhar mais.',
        es: 'Al activar clientes de TIDY, permites que los clientes que buscan un profesional como tú usando TIDY te reserven. Es una excelente manera de ganar más.',
        ru: 'Включив клиентов от TIDY, вы позволяете клиентам, ищущим профессионала как вы через TIDY, бронировать ваши услуги. Это отличный способ заработать больше.',
        fr: 'En activant les clients de TIDY, vous permettez aux clients recherchant un professionnel comme vous via TIDY de vous réserver. C\'est un excellent moyen de gagner plus.',
        de: 'Durch die Aktivierung von TIDY-Kunden können Kunden, die über TIDY nach einem Profi wie Ihnen suchen, Sie buchen. Es ist eine großartige Möglichkeit, mehr zu verdienen.'
    },
    'Earn $1,000+ More Per Week': {
        pt: 'Ganhe +$1.000 Por Semana',
        es: 'Gana +$1.000 Por Semana',
        ru: 'Зарабатывайте +$1.000 В Неделю',
        fr: 'Gagnez +1.000$ Par Semaine',
        de: 'Verdienen Sie +1.000$ Pro Woche'
    },
    'Top performers in great regions have earned over $1,000 per week.': {
        pt: 'Os melhores profissionais em ótimas regiões ganharam mais de $1.000 por semana.',
        es: 'Los mejores profesionales en grandes regiones han ganado más de $1.000 por semana.',
        ru: 'Лучшие исполнители в хороших регионах зарабатывают более $1.000 в неделю.',
        fr: 'Les meilleurs performeurs dans les grandes régions ont gagné plus de 1.000$ par semaine.',
        de: 'Top-Performer in guten Regionen haben über 1.000$ pro Woche verdient.'
    },
    'Control Your Settings': {
        pt: 'Controle Suas Configurações',
        es: 'Controla Tus Configuraciones',
        ru: 'Управляйте Настройками',
        fr: 'Contrôlez Vos Paramètres',
        de: 'Kontrollieren Sie Ihre Einstellungen'
    },
    'You control your schedule, driving distance, and pay rate for clients. Clients can book directly on your available schedule, or you can view requests from people needing help.': {
        pt: 'Você controla seu horário, distância de condução e taxa de pagamento para clientes. Os clientes podem reservar diretamente em seu horário disponível, ou você pode ver solicitações de pessoas precisando de ajuda.',
        es: 'Controlas tu horario, distancia de conducción y tarifa de pago para clientes. Los clientes pueden reservar directamente en tu horario disponible, o puedes ver solicitudes de personas que necesitan ayuda.',
        ru: 'Вы контролируете свое расписание, расстояние поездки и тариф оплаты для клиентов. Клиенты могут бронировать напрямую по вашему доступному расписанию, или вы можете просматривать запросы от людей, нуждающихся в помощи.',
        fr: 'Vous contrôlez votre emploi du temps, la distance de conduite et le tarif pour les clients. Les clients peuvent réserver directement sur votre horaire disponible, ou vous pouvez voir les demandes des personnes ayant besoin d\'aide.',
        de: 'Sie kontrollieren Ihren Zeitplan, die Fahrentfernung und den Zahlungssatz für Kunden. Kunden können direkt in Ihrem verfügbaren Zeitplan buchen, oder Sie können Anfragen von Menschen sehen, die Hilfe benötigen.'
    },
    'Win Clients With Quality & Rates': {
        pt: 'Conquiste Clientes Com Qualidade e Taxas',
        es: 'Gana Clientes Con Calidad y Tarifas',
        ru: 'Привлекайте Клиентов Качеством и Тарифами',
        fr: 'Gagnez des Clients Avec Qualité et Tarifs',
        de: 'Gewinnen Sie Kunden Mit Qualität und Tarifen'
    },
    'You win new clients based on your Quality Score x Rate. This means the better you do for clients, the more clients you get. You don\'t compete only on price.': {
        pt: 'Você conquista novos clientes com base em sua Pontuação de Qualidade x Taxa. Isso significa que quanto melhor você atende os clientes, mais clientes você obtém. Você não compete apenas no preço.',
        es: 'Ganas nuevos clientes basado en tu Puntuación de Calidad x Tarifa. Esto significa que cuanto mejor lo hagas para los clientes, más clientes obtienes. No compites solo en precio.',
        ru: 'Вы привлекаете новых клиентов на основе вашего Рейтинга Качества x Тариф. Это означает, что чем лучше вы работаете для клиентов, тем больше клиентов вы получаете. Вы конкурируете не только по цене.',
        fr: 'Vous gagnez de nouveaux clients en fonction de votre Score de Qualité x Tarif. Cela signifie que plus vous êtes performant pour les clients, plus vous obtenez de clients. Vous ne concurrencez pas uniquement sur le prix.',
        de: 'Sie gewinnen neue Kunden basierend auf Ihrer Qualitätsbewertung x Tarif. Das bedeutet, je besser Sie für Kunden arbeiten, desto mehr Kunden bekommen Sie. Sie konkurrieren nicht nur über den Preis.'
    },
    'Earn More With Repeat Clients': {
        pt: 'Ganhe Mais Com Clientes Recorrentes',
        es: 'Gana Más Con Clientes Recurrentes',
        ru: 'Зарабатывайте Больше с Постоянными Клиентами',
        fr: 'Gagnez Plus Avec des Clients Réguliers',
        de: 'Verdienen Sie Mehr Mit Stammkunden'
    },
    'The number 1 way clients see/choose pros is who keeps clients repeatedly booking. So keeping clients happy is more important than reviews.': {
        pt: 'A maneira número 1 como os clientes veem/escolhem profissionais é quem mantém clientes fazendo reservas repetidamente. Então, manter os clientes felizes é mais importante que avaliações.',
        es: 'La forma número 1 en que los clientes ven/eligen profesionales es quién mantiene clientes reservando repetidamente. Por lo tanto, mantener a los clientes felices es más importante que las reseñas.',
        ru: 'Главный способ, как клиенты видят/выбирают профессионалов - это кто сохраняет клиентов, регулярно бронирующих услуги. Поэтому поддержание довольных клиентов важнее отзывов.',
        fr: 'La façon numéro 1 dont les clients voient/choisissent les pros est celui qui garde les clients qui réservent régulièrement. Donc, garder les clients satisfaits est plus important que les avis.',
        de: 'Die Nummer 1 Art und Weise, wie Kunden Profis sehen/auswählen, ist wer Kunden behält, die wiederholt buchen. Daher ist es wichtiger, Kunden zufrieden zu halten als Bewertungen.'
    },
    'Set Your Rates': {
        pt: 'Defina Suas Taxas',
        es: 'Establece Tus Tarifas',
        ru: 'Установите Свои Тарифы',
        fr: 'Définissez Vos Tarifs',
        de: 'Legen Sie Ihre Tarife Fest'
    },
    'You set your rates, and can change them anytime. Here are median payments and fees for pros/clients near you:': {
        pt: 'Você define suas taxas e pode alterá-las a qualquer momento. Aqui estão os pagamentos e taxas médias para profissionais/clientes próximos a você:',
        es: 'Estableces tus tarifas y puedes cambiarlas en cualquier momento. Aquí están los pagos y tarifas medias para profesionales/clientes cerca de ti:',
        ru: 'Вы устанавливаете свои тарифы и можете изменять их в любое время. Вот средние платежи и сборы для профессионалов/клиентов рядом с вами:',
        fr: 'Vous fixez vos tarifs et pouvez les modifier à tout moment. Voici les paiements et frais médians pour les pros/clients près de chez vous:',
        de: 'Sie legen Ihre Tarife fest und können sie jederzeit ändern. Hier sind die durchschnittlichen Zahlungen und Gebühren für Profis/Kunden in Ihrer Nähe:'
    },
    'Clients from TIDY Are Easy': {
        pt: 'Clientes da TIDY São Fáceis',
        es: 'Los Clientes de TIDY Son Fáciles',
        ru: 'Клиенты от TIDY - Это Просто',
        fr: 'Les Clients de TIDY Sont Faciles',
        de: 'Kunden von TIDY Sind Einfach'
    },
    'Clients from TIDY should be a great way to earn more with almost no extra setup. Clients follow your existing settings and schedule. Your schedule automatically syncs, so that Clients from TIDY can\'t book over your existing Clients. If you book a Private Client, that will automatically block off your schedule.': {
        pt: 'Clientes da TIDY devem ser uma ótima maneira de ganhar mais sem quase nenhuma configuração extra. Os clientes seguem suas configurações e agenda existentes. Sua agenda sincroniza automaticamente, para que os Clientes da TIDY não possam reservar sobre seus Clientes existentes. Se você reservar um Cliente Privado, isso bloqueará automaticamente sua agenda.',
        es: 'Los clientes de TIDY deberían ser una excelente manera de ganar más sin casi ninguna configuración adicional. Los clientes siguen tu configuración y horario existentes. Tu horario se sincroniza automáticamente, para que los Clientes de TIDY no puedan reservar sobre tus Clientes existentes. Si reservas un Cliente Privado, eso bloqueará automáticamente tu horario.',
        ru: 'Клиенты от TIDY должны быть отличным способом заработать больше практически без дополнительной настройки. Клиенты следуют вашим существующим настройкам и расписанию. Ваше расписание автоматически синхронизируется, чтобы Клиенты от TIDY не могли бронировать поверх ваших существующих Клиентов. Если вы бронируете Частного Клиента, это автоматически блокирует ваше расписание.',
        fr: 'Les clients de TIDY devraient être un excellent moyen de gagner plus sans presque aucune configuration supplémentaire. Les clients suivent vos paramètres et horaires existants. Votre horaire se synchronise automatiquement, afin que les Clients de TIDY ne puissent pas réserver par-dessus vos Clients existants. Si vous réservez un Client Privé, cela bloquera automatiquement votre horaire.',
        de: 'Kunden von TIDY sollten eine großartige Möglichkeit sein, mehr zu verdienen, fast ohne zusätzliche Einrichtung. Kunden folgen Ihren bestehenden Einstellungen und Zeitplan. Ihr Zeitplan synchronisiert sich automatisch, sodass TIDY-Kunden nicht über Ihre bestehenden Kunden hinweg buchen können. Wenn Sie einen Privatkunden buchen, wird das automatisch Ihren Zeitplan blockieren.'
    },
    'We Are Here To Help': {
        pt: 'Estamos Aqui Para Ajudar',
        es: 'Estamos Aquí Para Ayudar',
        ru: 'Мы Здесь, Чтобы Помочь',
        fr: 'Nous Sommes Là Pour Aider',
        de: 'Wir Sind Hier Um Zu Helfen'
    },
    'If you ever have any questions about clients from TIDY please contact us in the Concierge section.': {
        pt: 'Se você tiver alguma dúvida sobre clientes da TIDY, entre em contato conosco na seção Concierge.',
        es: 'Si alguna vez tienes preguntas sobre los clientes de TIDY, contáctanos en la sección de Concierge.',
        ru: 'Если у вас есть какие-либо вопросы о клиентах от TIDY, пожалуйста, свяжитесь с нами в разделе Консьерж.',
        fr: 'Si vous avez des questions sur les clients de TIDY, veuillez nous contacter dans la section Concierge.',
        de: 'Wenn Sie jemals Fragen zu Kunden von TIDY haben, kontaktieren Sie uns bitte im Concierge-Bereich.'
    },

    'Set Bookable Hours': {
        pt: 'Definir Horários Disponíveis',
        es: 'Establecer Horarios Disponibles',
        ru: 'Установить Доступное Время',
        fr: 'Définir les Heures Disponibles',
        de: 'Buchbare Zeiten Festlegen'
    },
    'Select the times Clients can book with you every week.': {
        pt: 'Selecione os horários em que os Clientes podem fazer reservas com você toda semana.',
        es: 'Seleccione los horarios en que los Clientes pueden reservar con usted cada semana.',
        ru: 'Выберите время, когда Клиенты могут бронировать ваши услуги каждую неделю.',
        fr: 'Sélectionnez les horaires auxquels les Clients peuvent réserver avec vous chaque semaine.',
        de: 'Wählen Sie die Zeiten aus, zu denen Kunden jede Woche bei Ihnen buchen können.'
    },
    'Every': {
        pt: 'Toda',
        es: 'Cada',
        ru: 'Каждый',
        fr: 'Chaque',
        de: 'Jeden'
    },
    'Time': {
        pt: 'Horário',
        es: 'Hora',
        ru: 'Время',
        fr: 'Heure',
        de: 'Zeit'
    },
    'Save Bookable Hours': {
        pt: 'Salvar Horários Disponíveis',
        es: 'Guardar Horarios Disponibles',
        ru: 'Сохранить Доступное Время',
        fr: 'Enregistrer les Heures Disponibles',
        de: 'Buchbare Zeiten Speichern'
    },
    'You authorize Clients to book with you during these times until 8pm the night before.': {
        pt: 'Você autoriza os Clientes a fazerem reservas com você durante estes horários até às 20h da noite anterior.',
        es: 'Usted autoriza a los Clientes a reservar con usted durante estos horarios hasta las 8pm de la noche anterior.',
        ru: 'Вы разрешаете Клиентам бронировать ваши услуги в это время до 20:00 предыдущего дня.',
        fr: 'Vous autorisez les Clients à réserver avec vous pendant ces heures jusqu\'à 20h la veille.',
        de: 'Sie ermächtigen Kunden, während dieser Zeiten bis 20 Uhr am Vorabend bei Ihnen zu buchen.'
    },
    'Availability Set!': {
        pt: 'Disponibilidade Definida!',
        es: '¡Disponibilidad Establecida!',
        ru: 'Доступность Установлена!',
        fr: 'Disponibilité Définie !',
        de: 'Verfügbarkeit Festgelegt!'
    },
    'You added your availability.': {
        pt: 'Você adicionou sua disponibilidade.',
        es: 'Has añadido tu disponibilidad.',
        ru: 'Вы добавили свою доступность.',
        fr: 'Vous avez ajouté votre disponibilité.',
        de: 'Sie haben Ihre Verfügbarkeit hinzugefügt.'
    },

    'Complete all these steps to get the maximum number Clients from TIDY, at the times, locations, and rates you want.': {
        pt: 'Complete todas estas etapas para obter o número máximo de Clientes da TIDY, nos horários, locais e taxas que você deseja.',
        es: 'Complete todos estos pasos para obtener el número máximo de Clientes de TIDY, en los horarios, lugares y tarifas que desee.',
        ru: 'Выполните все эти шаги, чтобы получить максимальное количество Клиентов от TIDY в удобное для вас время, места и по желаемым тарифам.',
        fr: 'Complétez toutes ces étapes pour obtenir le nombre maximum de Clients de TIDY, aux horaires, lieux et tarifs que vous souhaitez.',
        de: 'Schließen Sie alle diese Schritte ab, um die maximale Anzahl von Kunden von TIDY zu den gewünschten Zeiten, Orten und Preisen zu erhalten.'
    },
    '/10 Completed': {
        pt: '/10 Concluído',
        es: '/10 Completado',
        ru: '/10 Завершено',
        fr: '/10 Terminé',
        de: '/10 Abgeschlossen'
    },
    'Make Yourself Bookable': {
        pt: 'Torne-se Disponível para Reservas',
        es: 'Hazte Disponible para Reservas',
        ru: 'Сделайте Себя Доступным для Бронирования',
        fr: 'Rendez-vous Disponible pour les Réservations',
        de: 'Machen Sie Sich Buchbar'
    },
    'So Clients can book you instantly.': {
        pt: 'Para que os Clientes possam reservar seus serviços instantaneamente.',
        es: 'Para que los Clientes puedan reservarte instantáneamente.',
        ru: 'Чтобы Клиенты могли мгновенно забронировать ваши услуги.',
        fr: 'Pour que les Clients puissent vous réserver instantanément.',
        de: 'Damit Kunden Sie sofort buchen können.'
    },
    'Learn About Clients from TIDY': {
        pt: 'Aprenda Sobre Clientes da TIDY',
        es: 'Aprende Sobre Clientes de TIDY',
        ru: 'Узнайте о Клиентах TIDY',
        fr: 'En Savoir Plus sur les Clients de TIDY',
        de: 'Erfahren Sie Mehr über TIDY-Kunden'
    },
    'Learn about how Clients can find and book you via your listing on TIDY.': {
        pt: 'Saiba como os Clientes podem encontrar e reservar seus serviços através do seu perfil na TIDY.',
        es: 'Aprende cómo los Clientes pueden encontrarte y reservarte a través de tu perfil en TIDY.',
        ru: 'Узнайте, как Клиенты могут найти и забронировать вас через ваш профиль на TIDY.',
        fr: 'Découvrez comment les Clients peuvent vous trouver et vous réserver via votre profil sur TIDY.',
        de: 'Erfahren Sie, wie Kunden Sie über Ihr TIDY-Profil finden und buchen können.'
    },
    'Review & Confirm Terms': {
        pt: 'Revisar e Confirmar Termos',
        es: 'Revisar y Confirmar Términos',
        ru: 'Просмотреть и Подтвердить Условия',
        fr: 'Revoir et Confirmer les Conditions',
        de: 'Bedingungen Überprüfen und Bestätigen'
    },
    'Re-review the terms again to be sure you agree and understand.': {
        pt: 'Revise os termos novamente para ter certeza que você concorda e entende.',
        es: 'Revisa los términos nuevamente para asegurarte de que estás de acuerdo y los entiendes.',
        ru: 'Пересмотрите условия еще раз, чтобы убедиться, что вы согласны и понимаете их.',
        fr: 'Relisez les conditions pour vous assurer que vous les acceptez et les comprenez.',
        de: 'Überprüfen Sie die Bedingungen erneut, um sicherzustellen, dass Sie einverstanden sind und sie verstehen.'
    },
    'Identity Verification': {
        pt: 'Verificação de Identidade',
        es: 'Verificación de Identidad',
        ru: 'Проверка Личности',
        fr: 'Vérification d\'Identité',
        de: 'Identitätsprüfung'
    },
    'Do a 1 minute verification using your camera and a photo ID.': {
        pt: 'Faça uma verificação de 1 minuto usando sua câmera e um documento com foto.',
        es: 'Realiza una verificación de 1 minuto usando tu cámara y una identificación con foto.',
        ru: 'Сделайте 1-минутную проверку, используя камеру и удостоверение личности с фото.',
        fr: 'Effectuez une vérification d\'une minute à l\'aide de votre caméra et d\'une pièce d\'identité avec photo.',
        de: 'Führen Sie eine einminütige Überprüfung mit Ihrer Kamera und einem Lichtbildausweis durch.'
    },
    'Free Background Check': {
        pt: 'Verificação de Antecedentes Gratuita',
        es: 'Verificación de Antecedentes Gratuita',
        ru: 'Бесплатная Проверка Биографии',
        fr: 'Vérification des Antécédents Gratuite',
        de: 'Kostenlose Hintergrundüberprüfung'
    },
    'Our team is reviewing your equipment and will reply ASAP.': {
        pt: 'Nossa equipe está analisando seu equipamento e responderá o mais rápido possível.',
        es: 'Nuestro equipo está revisando su equipo y responderá lo antes posible.',
        ru: 'Наша команда проверяет ваше оборудование и ответит как можно скорее.',
        fr: 'Notre équipe examine votre équipement et vous répondra dès que possible.',
        de: 'Unser Team prüft Ihre Ausrüstung und wird sich schnellstmöglich bei Ihnen melden.'
    },
    'Based on what you submitted, we were not able to verify that your equipment met the minimums for certification. Please re-submit your equipment.': {
        pt: 'Com base no que você enviou, não conseguimos verificar se seu equipamento atende aos requisitos mínimos para certificação. Por favor, reenvie seu equipamento.',
        es: 'Según lo que envió, no pudimos verificar que su equipo cumpliera con los mínimos para la certificación. Por favor, vuelva a enviar su equipo.',
        ru: 'На основании того, что вы отправили, мы не смогли подтвердить, что ваше оборудование соответствует минимальным требованиям для сертификации. Пожалуйста, отправьте информацию об оборудовании повторно.',
        fr: 'D\'après ce que vous avez soumis, nous n\'avons pas pu vérifier que votre équipement répondait aux minimums requis pour la certification. Veuillez soumettre à nouveau votre équipement.',
        de: 'Basierend auf Ihren Angaben konnten wir nicht überprüfen, ob Ihre Ausrüstung die Mindestanforderungen für die Zertifizierung erfüllt. Bitte reichen Sie Ihre Ausrüstung erneut ein.'
    },
    'Confirm you have equipment so you can get Clients from TIDY.': {
        pt: 'Confirme que você tem equipamento para poder receber Clientes da TIDY.',
        es: 'Confirme que tiene equipo para poder recibir Clientes de TIDY.',
        ru: 'Подтвердите наличие оборудования, чтобы получать Клиентов от TIDY.',
        fr: 'Confirmez que vous avez l\'équipement nécessaire pour recevoir des Clients de TIDY.',
        de: 'Bestätigen Sie, dass Sie über die Ausrüstung verfügen, um Kunden von TIDY zu erhalten.'
    },
    'Check waived due to your positive online presence.': {
        pt: 'Verificação dispensada devido à sua presença online positiva.',
        es: 'Verificación exenta debido a su presencia en línea positiva.',
        ru: 'Проверка отменена благодаря вашему положительному онлайн-присутствию.',
        fr: 'Vérification annulée en raison de votre présence positive en ligne.',
        de: 'Überprüfung aufgrund Ihrer positiven Online-Präsenz erlassen.'
    },
    'We sent an email to your email on file. This has a link to complete your background check. Check your email and complete the check to proceed. Please allow 24 hours for the email to arrive.': {
        pt: 'Enviamos um email para seu endereço registrado. Ele contém um link para completar sua verificação de antecedentes. Verifique seu email e complete a verificação para prosseguir. Por favor, aguarde até 24 horas para o email chegar.',
        es: 'Enviamos un correo electrónico a su dirección registrada. Contiene un enlace para completar su verificación de antecedentes. Revise su correo y complete la verificación para continuar. Por favor, espere hasta 24 horas para que llegue el correo.',
        ru: 'Мы отправили письмо на ваш зарегистрированный email. В нем есть ссылка для завершения проверки биографии. Проверьте почту и завершите проверку, чтобы продолжить. Пожалуйста, подождите до 24 часов для получения письма.',
        fr: 'Nous avons envoyé un email à votre adresse enregistrée. Il contient un lien pour compléter votre vérification des antécédents. Vérifiez votre email et complétez la vérification pour continuer. Veuillez prévoir jusqu\'à 24 heures pour la réception de l\'email.',
        de: 'Wir haben eine E-Mail an Ihre hinterlegte Adresse gesendet. Diese enthält einen Link zur Durchführung Ihrer Hintergrundüberprüfung. Prüfen Sie Ihre E-Mails und schließen Sie die Überprüfung ab, um fortzufahren. Bitte warten Sie bis zu 24 Stunden auf den Eingang der E-Mail.'
    },
    'You\'re ready for a background check!': {
        pt: 'Você está pronto para a verificação de antecedentes!',
        es: '¡Está listo para la verificación de antecedentes!',
        ru: 'Вы готовы к проверке биографии!',
        fr: 'Vous êtes prêt pour la vérification des antécédents !',
        de: 'Sie sind bereit für die Hintergrundüberprüfung!'
    },
    'Please complete all other steps before getting background check.': {
        pt: 'Por favor, complete todas as outras etapas antes de fazer a verificação de antecedentes.',
        es: 'Por favor, complete todos los otros pasos antes de obtener la verificación de antecedentes.',
        ru: 'Пожалуйста, выполните все остальные шаги перед прохождением проверки биографии.',
        fr: 'Veuillez compléter toutes les autres étapes avant d\'obtenir la vérification des antécédents.',
        de: 'Bitte schließen Sie alle anderen Schritte ab, bevor Sie die Hintergrundüberprüfung durchführen.'
    },
    'Receive Payments': {
        pt: 'Receber Pagamentos',
        es: 'Recibir Pagos',
        ru: 'Получить Платежи',
        fr: 'Recevoir des Paiements',
        de: 'Zahlungen Erhalten'
    },
    'Complete steps to receive payments from your clients and get paid out through TIDY:': {
        pt: 'Complete as etapas para receber pagamentos de seus clientes e ser pago através da TIDY:',
        es: 'Complete los pasos para recibir pagos de sus clientes y cobrar a través de TIDY:',
        ru: 'Выполните шаги для получения платежей от клиентов и выплат через TIDY:',
        fr: 'Complétez les étapes pour recevoir les paiements de vos clients et être payé via TIDY :',
        de: 'Führen Sie die Schritte aus, um Zahlungen von Ihren Kunden zu erhalten und über TIDY ausgezahlt zu werden:'
    },
    '/2 Completed': {
        pt: '/2 Concluído',
        es: '/2 Completado',
        ru: '/2 Завершено',
        fr: '/2 Terminé',
        de: '/2 Abgeschlossen'
    },
    'Confirm Payment Settings': {
        pt: 'Confirmar Configurações de Pagamento',
        es: 'Confirmar Configuración de Pagos',
        ru: 'Подтвердить Настройки Оплаты',
        fr: 'Confirmer les Paramètres de Paiement',
        de: 'Zahlungseinstellungen Bestätigen'
    },
    'You can allow private clients to pay your invoices with cash, check, or through TIDY with card.': {
        pt: 'Você pode permitir que clientes particulares paguem suas faturas em dinheiro, cheque ou através da TIDY com cartão.',
        es: 'Puede permitir que los clientes privados paguen sus facturas en efectivo, cheque o a través de TIDY con tarjeta.',
        ru: 'Вы можете разрешить частным клиентам оплачивать ваши счета наличными, чеком или через TIDY картой.',
        fr: 'Vous pouvez autoriser les clients privés à payer vos factures en espèces, par chèque ou via TIDY par carte.',
        de: 'Sie können privaten Kunden erlauben, Ihre Rechnungen bar, per Scheck oder über TIDY mit Karte zu bezahlen.'
    },
    'Choose Payout Type': {
        pt: 'Escolher Tipo de Pagamento',
        es: 'Elegir Tipo de Pago',
        ru: 'Выбрать Тип Выплаты',
        fr: 'Choisir le Type de Paiement',
        de: 'Auszahlungsart Wählen'
    },
    'TIDY pays out payments collected from your clients once a week. You can get paid out via direct deposit, credit card or check.': {
        pt: 'A TIDY paga os pagamentos coletados de seus clientes uma vez por semana. Você pode receber via depósito direto, cartão de crédito ou cheque.',
        es: 'TIDY paga los pagos cobrados de sus clientes una vez por semana. Puede recibir el pago mediante depósito directo, tarjeta de crédito o cheque.',
        ru: 'TIDY выплачивает собранные от ваших клиентов платежи раз в неделю. Вы можете получать выплаты прямым переводом, на кредитную карту или чеком.',
        fr: 'TIDY verse les paiements collectés auprès de vos clients une fois par semaine. Vous pouvez être payé par virement direct, carte de crédit ou chèque.',
        de: 'TIDY zahlt die von Ihren Kunden eingezogenen Zahlungen einmal pro Woche aus. Sie können per Direktüberweisung, Kreditkarte oder Scheck ausgezahlt werden.'
    },

    'Review Terms': {
        pt: 'Revisar Termos',
        es: 'Revisar Términos',
        ru: 'Просмотр Условий',
        fr: 'Examiner les Conditions',
        de: 'Bedingungen Überprüfen'
    },
    'Please Review Terms of Use': {
        pt: 'Por Favor, Revise os Termos de Uso',
        es: 'Por Favor, Revise los Términos de Uso',
        ru: 'Пожалуйста, Ознакомьтесь с Условиями Использования',
        fr: 'Veuillez Examiner les Conditions d\'Utilisation',
        de: 'Bitte Überprüfen Sie die Nutzungsbedingungen'
    },
    'You previously agreed to these terms. Please certify that you have read and agree to these terms, which include representing you have a bona-fide business and agreeing to arbitrate disputes on an individual and not class basis: ': {
        pt: 'Você concordou anteriormente com estes termos. Por favor, certifique que leu e concorda com estes termos, que incluem declarar que você tem um negócio legítimo e concordar em arbitrar disputas individualmente e não como classe: ',
        es: 'Anteriormente aceptó estos términos. Por favor, certifique que ha leído y acepta estos términos, que incluyen declarar que tiene un negocio de buena fe y aceptar arbitrar disputas de forma individual y no colectiva: ',
        ru: 'Вы ранее согласились с этими условиями. Пожалуйста, подтвердите, что вы прочитали и согласны с этими условиями, которые включают заявление о том, что у вас есть добросовестный бизнес, и согласие на арбитражное разбирательство споров в индивидуальном, а не коллективном порядке: ',
        fr: 'Vous avez précédemment accepté ces conditions. Veuillez certifier que vous avez lu et acceptez ces conditions, qui incluent la déclaration que vous avez une entreprise de bonne foi et l\'accord d\'arbitrer les litiges sur une base individuelle et non collective : ',
        de: 'Sie haben diesen Bedingungen zuvor zugestimmt. Bitte bestätigen Sie, dass Sie diese Bedingungen gelesen haben und ihnen zustimmen, einschließlich der Erklärung, dass Sie ein seriöses Unternehmen haben und der Zustimmung, Streitigkeiten individuell und nicht als Sammelklage zu schlichten: '
    },
    'Terms of Use': {
        pt: 'Termos de Uso',
        es: 'Términos de Uso',
        ru: 'Условия Использования',
        fr: 'Conditions d\'Utilisation',
        de: 'Nutzungsbedingungen'
    },
    'I Still Agree To The Terms': {
        pt: 'Ainda Concordo com os Termos',
        es: 'Todavía Estoy de Acuerdo con los Términos',
        ru: 'Я По-Прежнему Согласен с Условиями',
        fr: 'Je Suis Toujours d\'Accord avec les Conditions',
        de: 'Ich Stimme den Bedingungen Weiterhin Zu'
    },
    'You must tap "Terms of Use" and review the terms before confirming.': {
        pt: 'Você deve tocar em "Termos de Uso" e revisar os termos antes de confirmar.',
        es: 'Debe tocar "Términos de Uso" y revisar los términos antes de confirmar.',
        ru: 'Вы должны нажать "Условия Использования" и просмотреть условия перед подтверждением.',
        fr: 'Vous devez appuyer sur "Conditions d\'Utilisation" et examiner les conditions avant de confirmer.',
        de: 'Sie müssen auf "Nutzungsbedingungen" tippen und die Bedingungen überprüfen, bevor Sie bestätigen.'
    },

    'Please Check Email': {
        pt: 'Por Favor, Verifique o Email',
        es: 'Por Favor, Revise su Correo',
        ru: 'Пожалуйста, Проверьте Почту',
        fr: 'Veuillez Vérifier Votre Email',
        de: 'Bitte Überprüfen Sie Ihre E-Mail'
    },
    'When you signed up we sent an email to ': {
        pt: 'Quando você se cadastrou, enviamos um email para ',
        es: 'Cuando se registró, enviamos un correo a ',
        ru: 'Когда вы зарегистрировались, мы отправили письмо на ',
        fr: 'Lors de votre inscription, nous avons envoyé un email à ',
        de: 'Bei Ihrer Anmeldung haben wir eine E-Mail gesendet an '
    },
    ' from an @tidy.com email address to verify your email.': {
        pt: ' de um endereço de email @tidy.com para verificar seu email.',
        es: ' desde una dirección @tidy.com para verificar su correo.',
        ru: ' с адреса @tidy.com для подтверждения вашей почты.',
        fr: ' depuis une adresse @tidy.com pour vérifier votre email.',
        de: ' von einer @tidy.com E-Mail-Adresse zur Verifizierung Ihrer E-Mail.'
    },
    'Please tap the link in that email to verify your email address. Check your junk or spam messages if you cannot find the email.': {
        pt: 'Por favor, clique no link nesse email para verificar seu endereço de email. Verifique suas mensagens de lixo eletrônico ou spam se não encontrar o email.',
        es: 'Por favor, haga clic en el enlace de ese correo para verificar su dirección. Revise su carpeta de correo no deseado o spam si no encuentra el correo.',
        ru: 'Пожалуйста, нажмите на ссылку в этом письме, чтобы подтвердить ваш адрес. Проверьте папку спам или нежелательная почта, если не можете найти письмо.',
        fr: 'Veuillez cliquer sur le lien dans cet email pour vérifier votre adresse. Vérifiez vos messages indésirables ou spam si vous ne trouvez pas l\'email.',
        de: 'Bitte klicken Sie auf den Link in dieser E-Mail, um Ihre E-Mail-Adresse zu verifizieren. Überprüfen Sie Ihren Spam-Ordner, wenn Sie die E-Mail nicht finden können.'
    },
    'Resend Verification Email': {
        pt: 'Reenviar Email de Verificação',
        es: 'Reenviar Correo de Verificación',
        ru: 'Отправить Письмо с Подтверждением Повторно',
        fr: 'Renvoyer l\'Email de Vérification',
        de: 'Bestätigungs-E-Mail Erneut Senden'
    },

    'Please add your phone number to contact the client or notify them that you are on the way.': {
        pt: 'Adicione seu número de telefone para contatar o cliente ou notificá-lo que está a caminho.',
        es: 'Agregue su número de teléfono para contactar al cliente o notificarle que está en camino.',
        ru: 'Добавьте свой номер телефона, чтобы связаться с клиентом или уведомить его, что вы в пути.',
        fr: 'Veuillez ajouter votre numéro de téléphone pour contacter le client ou l\'informer que vous êtes en route.',
        de: 'Bitte fügen Sie Ihre Telefonnummer hinzu, um den Kunden zu kontaktieren oder ihm mitzuteilen, dass Sie unterwegs sind.'
    },
    'Please enter a valid Code': {
        pt: 'Por favor, insira um código válido',
        es: 'Por favor, ingrese un código válido',
        ru: 'Пожалуйста, введите действительный код',
        fr: 'Veuillez entrer un code valide',
        de: 'Bitte geben Sie einen gültigen Code ein'
    },
    'Phone Added': {
        pt: 'Telefone Adicionado',
        es: 'Teléfono Agregado',
        ru: 'Телефон Добавлен',
        fr: 'Téléphone Ajouté',
        de: 'Telefon Hinzugefügt'
    },
    'You can now contact the client or notify them that you are on the way to the job.': {
        pt: 'Agora você pode contatar o cliente ou notificá-lo que está a caminho do trabalho.',
        es: 'Ahora puede contactar al cliente o notificarle que está en camino al trabajo.',
        ru: 'Теперь вы можете связаться с клиентом или уведомить его, что направляетесь на работу.',
        fr: 'Vous pouvez maintenant contacter le client ou l\'informer que vous êtes en route pour le travail.',
        de: 'Sie können jetzt den Kunden kontaktieren oder ihm mitteilen, dass Sie auf dem Weg zur Arbeit sind.'
    },
    'What is your number?': {
        pt: 'Qual é o seu número?',
        es: '¿Cuál es su número?',
        ru: 'Какой у вас номер?',
        fr: 'Quel est votre numéro?',
        de: 'Wie ist Ihre Nummer?'
    },
    'Send Verification SMS': {
        pt: 'Enviar SMS de Verificação',
        es: 'Enviar SMS de Verificación',
        ru: 'Отправить SMS для Подтверждения',
        fr: 'Envoyer un SMS de Vérification',
        de: 'Verifizierungs-SMS Senden'
    },
    'Code Sent': {
        pt: 'Código Enviado',
        es: 'Código Enviado',
        ru: 'Код Отправлен',
        fr: 'Code Envoyé',
        de: 'Code Gesendet'
    },
    'Verification code': {
        pt: 'Código de verificação',
        es: 'Código de verificación',
        ru: 'Код подтверждения',
        fr: 'Code de vérification',
        de: 'Verifizierungscode'
    },
    'Check your SMS messages for a verification code.': {
        pt: 'Verifique suas mensagens SMS para obter um código de verificação.',
        es: 'Revise sus mensajes SMS para obtener un código de verificación.',
        ru: 'Проверьте свои SMS-сообщения на наличие кода подтверждения.',
        fr: 'Vérifiez vos messages SMS pour obtenir un code de vérification.',
        de: 'Überprüfen Sie Ihre SMS-Nachrichten auf einen Verifizierungscode.'
    },

    'Request a Parking Reimbursement from the Client by entering the amount and a photo of the receipt or meter as proof of payment. This will be automatically approved if equal to or below the Client\'s max on their account. It will go to the Client for approval if above the Client\'s max.': {
        pt: 'Solicite um reembolso de estacionamento do cliente inserindo o valor e uma foto do recibo ou medidor como comprovante de pagamento. Isso será aprovado automaticamente se for igual ou inferior ao máximo do cliente em sua conta. Será enviado ao cliente para aprovação se estiver acima do máximo do cliente.',
        es: 'Solicite un reembolso de estacionamiento del cliente ingresando el monto y una foto del recibo o medidor como comprobante de pago. Esto se aprobará automáticamente si es igual o inferior al máximo del cliente en su cuenta. Se enviará al cliente para su aprobación si está por encima del máximo del cliente.',
        ru: 'Запросите возмещение расходов на парковку у клиента, указав сумму и фотографию чека или счетчика в качестве подтверждения оплаты. Это будет автоматически одобрено, если сумма равна или ниже максимальной суммы клиента на их счете. Если сумма превышает максимум клиента, она будет отправлена клиенту на утверждение.',
        fr: 'Demandez un remboursement de stationnement au client en entrant le montant et une photo du reçu ou du compteur comme preuve de paiement. Cela sera automatiquement approuvé si égal ou inférieur au maximum du client sur leur compte. Cela sera envoyé au client pour approbation si supérieur au maximum du client.',
        de: 'Fordern Sie eine Parkplatzerstattung vom Kunden an, indem Sie den Betrag und ein Foto der Quittung oder des Parkzählers als Zahlungsnachweis eingeben. Dies wird automatisch genehmigt, wenn es gleich oder unter dem Maximum des Kunden auf seinem Konto liegt. Es wird zur Genehmigung an den Kunden weitergeleitet, wenn es über dem Maximum des Kunden liegt.'
    },

    'Reimbursement': {
        pt: 'Reembolso',
        es: 'Reembolso',
        ru: 'Возмещение',
        fr: 'Remboursement',
        de: 'Erstattung'
    },
    'Please add a photo of the receipt or meter.': {
        pt: 'Adicione uma foto do recibo ou medidor.',
        es: 'Agregue una foto del recibo o medidor.',
        ru: 'Добавьте фотографию чека или счетчика.',
        fr: 'Veuillez ajouter une photo du reçu ou du compteur.',
        de: 'Bitte fügen Sie ein Foto der Quittung oder des Zählers hinzu.'
    },
    'Amount': {
        pt: 'Valor',
        es: 'Monto',
        ru: 'Сумма',
        fr: 'Montant',
        de: 'Betrag'
    },
    'Please enter the amount': {
        pt: 'Por favor, insira o valor',
        es: 'Por favor, ingrese el monto',
        ru: 'Пожалуйста, введите сумму',
        fr: 'Veuillez saisir le montant',
        de: 'Bitte geben Sie den Betrag ein'
    },
    'Submit Request': {
        pt: 'Enviar Solicitação',
        es: 'Enviar Solicitud',
        ru: 'Отправить Запрос',
        fr: 'Soumettre la Demande',
        de: 'Anfrage Senden'
    },
    'Please enter an amount greater than $0.': {
        pt: 'Por favor, insira um valor maior que $0.',
        es: 'Por favor, ingrese un monto mayor a $0.',
        ru: 'Пожалуйста, введите сумму больше $0.',
        fr: 'Veuillez saisir un montant supérieur à 0$.',
        de: 'Bitte geben Sie einen Betrag größer als $0 ein.'
    },

    'Payment Summary': {
        pt: 'Resumo do Pagamento',
        es: 'Resumen de Pago',
        ru: 'Сводка Платежей',
        fr: 'Résumé des Paiements',
        de: 'Zahlungsübersicht'
    },

    'Accelerated Amount': {
        pt: 'Valor Acelerado',
        es: 'Monto Acelerado',
        ru: 'Ускоренная Сумма',
        fr: 'Montant Accéléré',
        de: 'Beschleunigter Betrag'
    },

    'Normal Amount': {
        pt: 'Valor Normal',
        es: 'Monto Normal',
        ru: 'Обычная Сумма',
        fr: 'Montant Normal',
        de: 'Normaler Betrag'
    },

    'Total Earned': {
        pt: 'Total Ganho',
        es: 'Total Ganado',
        ru: 'Всего Заработано',
        fr: 'Total Gagné',
        de: 'Gesamtverdienst'
    },

    'This Week': {
        pt: 'Esta Semana',
        es: 'Esta Semana',
        ru: 'Эта Неделя',
        fr: 'Cette Semaine',
        de: 'Diese Woche'
    },

    'It may take 24 hours for balances to update.': {
        pt: 'Pode levar 24 horas para atualizar os saldos.',
        es: 'Puede tomar 24 horas para que los saldos se actualicen.',
        ru: 'Обновление балансов может занять до 24 часов.',
        fr: 'La mise à jour des soldes peut prendre jusqu\'à 24 heures.',
        de: 'Die Aktualisierung der Salden kann bis zu 24 Stunden dauern.'
    },
    'Current Balance': {
        pt: 'Saldo Atual',
        es: 'Saldo Actual',
        ru: 'Текущий Баланс',
        fr: 'Solde Actuel',
        de: 'Aktueller Kontostand'
    },
    'Next payout: ': {
        pt: 'Próximo pagamento: ',
        es: 'Próximo pago: ',
        ru: 'Следующая выплата: ',
        fr: 'Prochain paiement: ',
        de: 'Nächste Auszahlung: '
    },
    ' (est. arrival ': {
        pt: ' (chegada est. ',
        es: ' (llegada est. ',
        ru: ' (ожидаемое прибытие ',
        fr: ' (arrivée est. ',
        de: ' (voraus. Ankunft '
    },
    'Payout after: ': {
        pt: 'Pagamento após: ',
        es: 'Pago después de: ',
        ru: 'Выплата после: ',
        fr: 'Paiement après: ',
        de: 'Auszahlung nach: '
    },
    'Payout History': {
        pt: 'Histórico de Pagamentos',
        es: 'Historial de Pagos',
        ru: 'История Выплат',
        fr: 'Historique des Paiements',
        de: 'Auszahlungsverlauf'
    },
    'Status: In Transit': {
        pt: 'Status: Em Trânsito',
        es: 'Estado: En Tránsito',
        ru: 'Статус: В Пути',
        fr: 'Statut: En Transit',
        de: 'Status: In Bearbeitung'
    },
    'No Past Payments': {
        pt: 'Sem Pagamentos Anteriores',
        es: 'Sin Pagos Anteriores',
        ru: 'Нет Прошлых Платежей',
        fr: 'Aucun Paiement Antérieur',
        de: 'Keine vergangenen Zahlungen'
    },

    'Current Balance Breakdown:': {
        pt: 'Detalhamento do Saldo Atual:',
        es: 'Desglose del Saldo Actual:',
        ru: 'Разбивка Текущего Баланса:',
        fr: 'Détail du Solde Actuel:',
        de: 'Aufschlüsselung des Aktuellen Kontostands:'
    },
    ' Payment': {
        pt: ' Pagamento',
        es: ' Pago',
        ru: ' Платеж',
        fr: ' Paiement',
        de: ' Zahlung'
    },
    ' Client Fee': {
        pt: ' Taxa do Cliente',
        es: ' Tarifa del Cliente',
        ru: ' Комиссия Клиента',
        fr: ' Frais du Client',
        de: ' Kundengebühr'
    },
    'View Payment Detail': {
        pt: 'Ver Detalhes do Pagamento',
        es: 'Ver Detalles del Pago',
        ru: 'Просмотр Деталей Платежа',
        fr: 'Voir les Détails du Paiement',
        de: 'Zahlungsdetails Anzeigen'
    },
    'View Client Fee Detail': {
        pt: 'Ver Detalhes da Taxa do Cliente',
        es: 'Ver Detalles de la Tarifa del Cliente',
        ru: 'Просмотр Деталей Комиссии Клиента',
        fr: 'Voir les Détails des Frais du Client',
        de: 'Kundengebührdetails Anzeigen'
    },
    'Private Client ': {
        pt: 'Cliente Privado ',
        es: 'Cliente Privado ',
        ru: 'Частный Клиент ',
        fr: 'Client Privé ',
        de: 'Privatkunde '
    },
    'Waive Client Fee': {
        pt: 'Dispensar Taxa do Cliente',
        es: 'Eximir Tarifa del Cliente',
        ru: 'Отменить Комиссию Клиента',
        fr: 'Annuler les Frais du Client',
        de: 'Kundengebühr Erlassen'
    },

    'Item Detail': {
        pt: 'Detalhe do Item',
        es: 'Detalle del Artículo',
        ru: 'Детали Элемента',
        fr: 'Détail de l\'Article',
        de: 'Artikeldetail'
    },
    ' Fee': {
        pt: ' Taxa',
        es: ' Tarifa',
        ru: ' Комиссия',
        fr: ' Frais',
        de: ' Gebühr'
    },
    '<b>*</b> This client first booked you on ': {
        pt: '<b>*</b> Este cliente fez sua primeira reserva em ',
        es: '<b>*</b> Este cliente te reservó por primera vez el ',
        ru: '<b>*</b> Этот клиент впервые забронировал вас ',
        fr: '<b>*</b> Ce client vous a réservé pour la première fois le ',
        de: '<b>*</b> Dieser Kunde hat Sie erstmals am '
    },
    'Cleaning Base Payment': {
        pt: 'Pagamento Base de Limpeza',
        es: 'Pago Base de Limpieza',
        ru: 'Базовая Оплата Уборки',
        fr: 'Paiement de Base du Nettoyage',
        de: 'Basis-Reinigungszahlung'
    },

    'Past Payout': {
        pt: 'Pagamento Anterior',
        es: 'Pago Anterior',
        ru: 'Прошлый Платёж',
        fr: 'Paiement Précédent',
        de: 'Vergangene Auszahlung'
    },
    ' (Sent ': {
        pt: ' (Enviado ',
        es: ' (Enviado ',
        ru: ' (Отправлено ',
        fr: ' (Envoyé ',
        de: ' (Gesendet '
    },
    'Current Status: ': {
        pt: 'Status Atual: ',
        es: 'Estado Actual: ',
        ru: 'Текущий Статус: ',
        fr: 'Statut Actuel: ',
        de: 'Aktueller Status: '
    },
    'How long to show in my bank?': {
        pt: 'Quanto tempo para aparecer no meu banco?',
        es: '¿Cuánto tiempo tarda en aparecer en mi banco?',
        ru: 'Когда отобразится в моем банке?',
        fr: 'Combien de temps pour apparaître dans ma banque?',
        de: 'Wie lange dauert die Anzeige in meiner Bank?'
    },
    'Payout Breakdown:': {
        pt: 'Detalhamento do Pagamento:',
        es: 'Desglose del Pago:',
        ru: 'Разбивка Платежа:',
        fr: 'Détail du Paiement:',
        de: 'Auszahlungsaufschlüsselung:'
    },
    ' Detail': {
        pt: ' Detalhe',
        es: ' Detalle',
        ru: ' Подробности',
        fr: ' Détail',
        de: ' Detail'
    },
    'in transit': {
        pt: 'em trânsito',
        es: 'en tránsito',
        ru: 'в пути',
        fr: 'en transit',
        de: 'in Bearbeitung'
    },
    'paid': {
        pt: 'pago',
        es: 'pagado',
        ru: 'оплачено',
        fr: 'payé',
        de: 'bezahlt'
    },
    'TIDY pays out once a week. Your payout setting:': {
        pt: 'TIDY paga uma vez por semana. Sua configuração de pagamento:',
        es: 'TIDY paga una vez por semana. Su configuración de pago:',
        ru: 'TIDY выплачивает раз в неделю. Ваши настройки выплат:',
        fr: 'TIDY paie une fois par semaine. Vos paramètres de paiement:',
        de: 'TIDY zahlt einmal pro Woche aus. Ihre Auszahlungseinstellung:'
    },
    'Check': {
        pt: 'Cheque',
        es: 'Cheque',
        ru: 'Чек',
        fr: 'Chèque',
        de: 'Scheck'
    },
    'We will send your payouts via check.': {
        pt: 'Enviaremos seus pagamentos via cheque.',
        es: 'Enviaremos sus pagos mediante cheque.',
        ru: 'Мы отправим ваши выплаты чеком.',
        fr: 'Nous enverrons vos paiements par chèque.',
        de: 'Wir senden Ihre Auszahlungen per Scheck.'
    },
    'Credit Card': {
        pt: 'Cartão de Crédito',
        es: 'Tarjeta de Crédito',
        ru: 'Кредитная Карта',
        fr: 'Carte de Crédit',
        de: 'Kreditkarte'
    },
    'We will provide you with a one time use card to charge clients. This typically incurs fees by your credit card processor.': {
        pt: 'Forneceremos um cartão de uso único para cobrar os clientes. Isso geralmente incorre em taxas do seu processador de cartão de crédito.',
        es: 'Le proporcionaremos una tarjeta de un solo uso para cobrar a los clientes. Esto generalmente implica cargos de su procesador de tarjetas de crédito.',
        ru: 'Мы предоставим вам карту одноразового использования для оплаты клиентами. Обычно это влечет комиссию вашего обработчика кредитных карт.',
        fr: 'Nous vous fournirons une carte à usage unique pour facturer les clients. Cela entraîne généralement des frais de votre processeur de carte de crédit.',
        de: 'Wir stellen Ihnen eine Einmalkarte zur Verfügung, um Kunden zu belasten. Dies verursacht in der Regel Gebühren durch Ihren Kreditkartenprozessor.'
    },
    'Direct Deposit': {
        pt: 'Depósito Direto',
        es: 'Depósito Directo',
        ru: 'Прямой Депозит',
        fr: 'Virement Direct',
        de: 'Direktüberweisung'
    },
    'We will deposit your payouts directly into your bank account.': {
        pt: 'Depositaremos seus pagamentos diretamente em sua conta bancária.',
        es: 'Depositaremos sus pagos directamente en su cuenta bancaria.',
        ru: 'Мы переведем ваши выплаты прямо на ваш банковский счет.',
        fr: 'Nous déposerons vos paiements directement sur votre compte bancaire.',
        de: 'Wir überweisen Ihre Auszahlungen direkt auf Ihr Bankkonto.'
    },
    'Verification Needed': {
        pt: 'Verificação Necessária',
        es: 'Verificación Necesaria',
        ru: 'Требуется Проверка',
        fr: 'Vérification Nécessaire',
        de: 'Verifizierung Erforderlich'
    },
    'Stripe (the payment provider handling your account) requires additional information in order to process payments in a timely fashion.': {
        pt: 'Stripe (o provedor de pagamentos que gerencia sua conta) requer informações adicionais para processar pagamentos em tempo hábil.',
        es: 'Stripe (el proveedor de pagos que maneja su cuenta) requiere información adicional para procesar los pagos de manera oportuna.',
        ru: 'Stripe (платежный провайдер, обслуживающий ваш счет) требует дополнительную информацию для своевременной обработки платежей.',
        fr: 'Stripe (le fournisseur de paiement gérant votre compte) nécessite des informations supplémentaires pour traiter les paiements en temps voulu.',
        de: 'Stripe (der Zahlungsanbieter, der Ihr Konto verwaltet) benötigt zusätzliche Informationen, um Zahlungen zeitnah zu verarbeiten.'
    },
    'Account ending in ': {
        pt: 'Conta terminando em ',
        es: 'Cuenta que termina en ',
        ru: 'Счет, оканчивающийся на ',
        fr: 'Compte se terminant par ',
        de: 'Konto endend auf '
    },
    'Verify Information': {
        pt: 'Verificar Informação',
        es: 'Verificar Información',
        ru: 'Проверить Информацию',
        fr: 'Vérifier les Informations',
        de: 'Informationen Überprüfen'
    },
    'Verification Pending': {
        pt: 'Verificação Pendente',
        es: 'Verificación Pendiente',
        ru: 'Проверка в Процессе',
        fr: 'Vérification en Attente',
        de: 'Verifizierung Ausstehend'
    },
    'Stripe is verifying your information, no action needed.': {
        pt: 'Stripe está verificando suas informações, nenhuma ação necessária.',
        es: 'Stripe está verificando su información, no se requiere ninguna acción.',
        ru: 'Stripe проверяет вашу информацию, никаких действий не требуется.',
        fr: 'Stripe vérifie vos informations, aucune action nécessaire.',
        de: 'Stripe überprüft Ihre Informationen, keine Aktion erforderlich.'
    },
    'Bank Account': {
        pt: 'Conta Bancária',
        es: 'Cuenta Bancaria',
        ru: 'Банковский Счет',
        fr: 'Compte Bancaire',
        de: 'Bankkonto'
    },
    'Your ID has been verified by Stripe.': {
        pt: 'Seu ID foi verificado pelo Stripe.',
        es: 'Su ID ha sido verificado por Stripe.',
        ru: 'Ваше удостоверение личности подтверждено Stripe.',
        fr: 'Votre ID a été vérifié par Stripe.',
        de: 'Ihre ID wurde von Stripe verifiziert.'
    },
    'Click here': {
        pt: 'Clique aqui',
        es: 'Haga clic aquí',
        ru: 'Нажмите здесь',
        fr: 'Cliquez ici',
        de: 'Klicken Sie hier'
    },
    ' for 1099 or Tax Questions': {
        pt: ' para Questões sobre 1099 ou Impostos',
        es: ' para Preguntas sobre 1099 o Impuestos',
        ru: ' для вопросов по форме 1099 или налогам',
        fr: ' pour les Questions sur le 1099 ou les Impôts',
        de: ' für 1099 oder Steuerfragen'
    },


    'US law requires us to collect info from you for tax reporting purposes, typically using a form W-9: ': {
        pt: 'A lei dos EUA exige que coletemos informações suas para fins de declaração fiscal, normalmente usando um formulário W-9: ',
        es: 'La ley de EE. UU. requiere que recopilemos información suya para fines de declaración de impuestos, típicamente usando un formulario W-9: ',
        ru: 'Закон США требует, чтобы мы собирали от вас информацию для налоговой отчетности, обычно используя форму W-9: ',
        fr: 'La loi américaine nous oblige à collecter vos informations à des fins de déclaration fiscale, généralement via un formulaire W-9: ',
        de: 'Das US-Gesetz verlangt, dass wir Informationen von Ihnen für Steuerzwecke sammeln, typischerweise mittels Formular W-9: '
    },
    'We already have your name, address, on file. If any of these are incorrect, please go back. The last remaining info for the IRS W-9 is a SSN or EIN.': {
        pt: 'Já temos seu nome e endereço registrados. Se alguma dessas informações estiver incorreta, volte. A última informação necessária para o IRS W-9 é um SSN ou EIN.',
        es: 'Ya tenemos su nombre y dirección registrados. Si alguno de estos datos es incorrecto, regrese. La última información restante para el IRS W-9 es un SSN o EIN.',
        ru: 'У нас уже есть ваше имя и адрес в файле. Если что-то из этого неверно, вернитесь назад. Последняя необходимая информация для IRS W-9 - это SSN или EIN.',
        fr: 'Nous avons déjà votre nom et adresse dans nos fichiers. Si ces informations sont incorrectes, veuillez revenir en arrière. La dernière information requise pour l\'IRS W-9 est un SSN ou EIN.',
        de: 'Wir haben bereits Ihren Namen und Ihre Adresse in der Datei. Wenn diese Angaben nicht korrekt sind, gehen Sie bitte zurück. Die letzte fehlende Information für das IRS W-9 ist eine SSN oder EIN.'
    },
    'Which number do you want to use?': {
        pt: 'Qual número você quer usar?',
        es: '¿Qué número desea usar?',
        ru: 'Какой номер вы хотите использовать?',
        fr: 'Quel numéro voulez-vous utiliser?',
        de: 'Welche Nummer möchten Sie verwenden?'
    },
    'Please select a type': {
        pt: 'Por favor selecione um tipo',
        es: 'Por favor seleccione un tipo',
        ru: 'Пожалуйста, выберите тип',
        fr: 'Veuillez sélectionner un type',
        de: 'Bitte wählen Sie einen Typ'
    },
    'Please enter a valid number': {
        pt: 'Por favor insira um número válido',
        es: 'Por favor ingrese un número válido',
        ru: 'Пожалуйста, введите действительный номер',
        fr: 'Veuillez entrer un numéro valide',
        de: 'Bitte geben Sie eine gültige Nummer ein'
    },
    'Save Setting': {
        pt: 'Salvar Configuração',
        es: 'Guardar Configuración',
        ru: 'Сохранить Настройки',
        fr: 'Enregistrer les Paramètres',
        de: 'Einstellung Speichern'
    },
    'Add bank info for your direct deposit:': {
        pt: 'Adicione informações bancárias para seu depósito direto:',
        es: 'Agregue información bancaria para su depósito directo:',
        ru: 'Добавьте банковскую информацию для прямого депозита:',
        fr: 'Ajoutez vos informations bancaires pour le virement direct:',
        de: 'Fügen Sie Bankinformationen für Ihre Direktüberweisung hinzu:'
    },
    'Routing (ABA) Number': {
        pt: 'Número de Roteamento (ABA)',
        es: 'Número de Ruta (ABA)',
        ru: 'Маршрутный номер (ABA)',
        fr: 'Numéro de Routage (ABA)',
        de: 'Routing-Nummer (ABA)'
    },
    'Please enter a valid routing number': {
        pt: 'Por favor insira um número de roteamento válido',
        es: 'Por favor ingrese un número de ruta válido',
        ru: 'Пожалуйста, введите действительный маршрутный номер',
        fr: 'Veuillez entrer un numéro de routage valide',
        de: 'Bitte geben Sie eine gültige Routing-Nummer ein'
    },
    'Account number': {
        pt: 'Número da conta',
        es: 'Número de cuenta',
        ru: 'Номер счета',
        fr: 'Numéro de compte',
        de: 'Kontonummer'
    },
    'Please enter a valid account number': {
        pt: 'Por favor insira um número de conta válido',
        es: 'Por favor ingrese un número de cuenta válido',
        ru: 'Пожалуйста, введите действительный номер счета',
        fr: 'Veuillez entrer un numéro de compte valide',
        de: 'Bitte geben Sie eine gültige Kontonummer ein'
    },
    'Confirm account number': {
        pt: 'Confirmar número da conta',
        es: 'Confirmar número de cuenta',
        ru: 'Подтвердить номер счета',
        fr: 'Confirmer le numéro de compte',
        de: 'Kontonummer bestätigen'
    },
    'We use Stripe to securely process all payments. Stripe is a large multinational payment processing company with bank grade security ': {
        pt: 'Usamos o Stripe para processar todos os pagamentos com segurança. Stripe é uma grande empresa multinacional de processamento de pagamentos com segurança de nível bancário ',
        es: 'Utilizamos Stripe para procesar todos los pagos de forma segura. Stripe es una gran empresa multinacional de procesamiento de pagos con seguridad de nivel bancario ',
        ru: 'Мы используем Stripe для безопасной обработки всех платежей. Stripe - это крупная международная компания по обработке платежей с банковским уровнем безопасности ',
        fr: 'Nous utilisons Stripe pour traiter en toute sécurité tous les paiements. Stripe est une grande entreprise multinationale de traitement des paiements avec une sécurité de niveau bancaire ',
        de: 'Wir verwenden Stripe für die sichere Abwicklung aller Zahlungen. Stripe ist ein großes multinationales Zahlungsabwicklungsunternehmen mit banküblicher Sicherheit '
    },
    'After you add your bank info, Stripe will ask standard identification information required by banks as a part of Know Your Customer laws.': {
        pt: 'Depois de adicionar suas informações bancárias, o Stripe solicitará informações de identificação padrão exigidas pelos bancos como parte das leis Conheça Seu Cliente.',
        es: 'Después de agregar su información bancaria, Stripe solicitará la información de identificación estándar requerida por los bancos como parte de las leyes Conozca a Su Cliente.',
        ru: 'После добавления банковской информации Stripe запросит стандартную идентификационную информацию, требуемую банками в рамках законов о знании своего клиента.',
        fr: 'Après avoir ajouté vos informations bancaires, Stripe demandera les informations d\'identification standard requises par les banques dans le cadre des lois sur la connaissance du client.',
        de: 'Nachdem Sie Ihre Bankinformationen hinzugefügt haben, wird Stripe die von Banken im Rahmen der Know-Your-Customer-Gesetze erforderlichen Standardidentifikationsinformationen anfordern.'
    },
    'Add Bank Info & Go to Verify': {
        pt: 'Adicionar Informações Bancárias e Verificar',
        es: 'Agregar Información Bancaria e Ir a Verificar',
        ru: 'Добавить Банковскую Информацию и Перейти к Проверке',
        fr: 'Ajouter les Informations Bancaires et Aller Vérifier',
        de: 'Bankinformationen Hinzufügen & Zur Verifizierung'
    },
    'SSN (social security number)': {
        pt: 'SSN (número de segurança social)',
        es: 'SSN (número de seguro social)',
        ru: 'SSN (номер социального страхования)',
        fr: 'SSN (numéro de sécurité sociale)',
        de: 'SSN (Sozialversicherungsnummer)'
    },
    'EIN (employer identification number)': {
        pt: 'EIN (número de identificação do empregador)',
        es: 'EIN (número de identificación del empleador)',
        ru: 'EIN (идентификационный номер работодателя)',
        fr: 'EIN (numéro d\'identification d\'employeur)',
        de: 'EIN (Arbeitgeber-Identifikationsnummer)'
    },

    'TIDY pays out once a week. Select how you want to receive that payout:': {
        pt: 'TIDY paga uma vez por semana. Selecione como você quer receber esse pagamento:',
        es: 'TIDY paga una vez por semana. Seleccione cómo desea recibir ese pago:',
        ru: 'TIDY выплачивает раз в неделю. Выберите, как вы хотите получать эту выплату:',
        fr: 'TIDY paie une fois par semaine. Sélectionnez comment vous souhaitez recevoir ce paiement:',
        de: 'TIDY zahlt einmal pro Woche aus. Wählen Sie aus, wie Sie diese Auszahlung erhalten möchten:'
    },
    'Please select a setting.': {
        pt: 'Por favor, selecione uma configuração.',
        es: 'Por favor, seleccione una configuración.',
        ru: 'Пожалуйста, выберите настройку.',
        fr: 'Veuillez sélectionner un paramètre.',
        de: 'Bitte wählen Sie eine Einstellung.'
    },
    'Direct deposit (most common)': {
        pt: 'Depósito direto (mais comum)',
        es: 'Depósito directo (más común)',
        ru: 'Прямой депозит (наиболее распространенный)',
        fr: 'Dépôt direct (le plus courant)',
        de: 'Direktüberweisung (am häufigsten)'
    },
    'This is typically the fastest, with no fees.': {
        pt: 'Geralmente é o mais rápido, sem taxas.',
        es: 'Esto suele ser lo más rápido, sin comisiones.',
        ru: 'Обычно это самый быстрый способ, без комиссий.',
        fr: 'C\'est généralement le plus rapide, sans frais.',
        de: 'Dies ist typischerweise die schnellste Option, ohne Gebühren.'
    },
    'Credit card': {
        pt: 'Cartão de crédito',
        es: 'Tarjeta de crédito',
        ru: 'Кредитная карта',
        fr: 'Carte de crédit',
        de: 'Kreditkarte'
    },
    'If you accept credit card, we can provide you with a one time use card to charge customers. This typically incurs fees by your credit card processor.': {
        pt: 'Se você aceita cartão de crédito, podemos fornecer um cartão de uso único para cobrar os clientes. Isso geralmente incorre em taxas do seu processador de cartão de crédito.',
        es: 'Si acepta tarjeta de crédito, podemos proporcionarle una tarjeta de un solo uso para cobrar a los clientes. Esto generalmente incurre en tarifas de su procesador de tarjetas de crédito.',
        ru: 'Если вы принимаете кредитные карты, мы можем предоставить вам карту одноразового использования для оплаты клиентами. Обычно это влечет комиссию вашего обработчика кредитных карт.',
        fr: 'Si vous acceptez la carte de crédit, nous pouvons vous fournir une carte à usage unique pour facturer les clients. Cela entraîne généralement des frais de votre processeur de carte de crédit.',
        de: 'Wenn Sie Kreditkarten akzeptieren, können wir Ihnen eine Einmalkarte zur Verfügung stellen, um Kunden zu belasten. Dies führt typischerweise zu Gebühren durch Ihren Kreditkartenprozessor.'
    },
    'This is typically the slowest, with no fees.': {
        pt: 'Geralmente é o mais lento, sem taxas.',
        es: 'Esto suele ser lo más lento, sin comisiones.',
        ru: 'Обычно это самый медленный способ, без комиссий.',
        fr: 'C\'est généralement le plus lent, sans frais.',
        de: 'Dies ist typischerweise die langsamste Option, ohne Gebühren.'
    },

    'Waive Client Fees': {
        pt: 'Renunciar às Taxas do Cliente',
        es: 'Renunciar a las Tarifas del Cliente',
        ru: 'Отказ от Комиссий Клиента',
        fr: 'Renoncer aux Frais du Client',
        de: 'Kundengebühren Erlassen'
    },
    'Many clients allow you to waive infrequent fees. The median client allows fee waivers for up to 10% of completed jobs.': {
        pt: 'Muitos clientes permitem que você renuncie a taxas pouco frequentes. O cliente médio permite isenções de taxas em até 10% dos trabalhos concluídos.',
        es: 'Muchos clientes permiten renunciar a tarifas poco frecuentes. El cliente promedio permite exenciones de tarifas hasta el 10% de los trabajos completados.',
        ru: 'Многие клиенты позволяют отказаться от нечастых комиссий. Средний клиент разрешает отказ от комиссий до 10% выполненных работ.',
        fr: 'De nombreux clients vous permettent de renoncer aux frais peu fréquents. Le client médian autorise les renonciations de frais jusqu\'à 10% des travaux terminés.',
        de: 'Viele Kunden erlauben es Ihnen, gelegentliche Gebühren zu erlassen. Der durchschnittliche Kunde erlaubt Gebührenerlasse für bis zu 10% der abgeschlossenen Aufträge.'
    },
    'Your last fee waiver was on': {
        pt: 'Sua última isenção de taxa foi em',
        es: 'Su última exención de tarifa fue el',
        ru: 'Ваш последний отказ от комиссии был',
        fr: 'Votre dernière renonciation aux frais était le',
        de: 'Ihr letzter Gebührenerlass war am'
    },
    'You have never used a fee waiver.': {
        pt: 'Você nunca usou uma isenção de taxa.',
        es: 'Nunca ha utilizado una exención de tarifa.',
        ru: 'Вы никогда не использовали отказ от комиссии.',
        fr: 'Vous n\'avez jamais utilisé de renonciation aux frais.',
        de: 'Sie haben noch nie einen Gebührenerlass genutzt.'
    },
    'You are not eligible for any fee waiver from your clients.': {
        pt: 'Você não está elegível para nenhuma isenção de taxa de seus clientes.',
        es: 'No es elegible para ninguna exención de tarifa de sus clientes.',
        ru: 'Вы не имеете права на отказ от комиссии от ваших клиентов.',
        fr: 'Vous n\'êtes pas éligible à une renonciation aux frais de vos clients.',
        de: 'Sie sind für keinen Gebührenerlass von Ihren Kunden berechtigt.'
    },
    'You have no client fees within the last 30 days.': {
        pt: 'Você não tem taxas de cliente nos últimos 30 dias.',
        es: 'No tiene tarifas de cliente en los últimos 30 días.',
        ru: 'У вас нет комиссий клиентов за последние 30 дней.',
        fr: 'Vous n\'avez pas de frais client au cours des 30 derniers jours.',
        de: 'Sie haben keine Kundengebühren in den letzten 30 Tagen.'
    },
    'The following fees have been authorized for waiving by the client:': {
        pt: 'As seguintes taxas foram autorizadas para renúncia pelo cliente:',
        es: 'Las siguientes tarifas han sido autorizadas para su exención por el cliente:',
        ru: 'Следующие комиссии были разрешены клиентом для отказа:',
        fr: 'Les frais suivants ont été autorisés pour renonciation par le client:',
        de: 'Die folgenden Gebühren wurden vom Kunden zum Erlass genehmigt:'
    },

    'Waive': {
        pt: 'Renunciar',
        es: 'Renunciar',
        ru: 'Отказаться от',
        fr: 'Renoncer à',
        de: 'Erlassen'
    },
    'in fees on': {
        pt: 'em taxas em',
        es: 'en tarifas el',
        ru: 'комиссий',
        fr: 'de frais le',
        de: 'an Gebühren am'
    },
    '?  You can waive up to 3 fees every 90 days.': {
        pt: '? Você pode renunciar até 3 taxas a cada 90 dias.',
        es: '? Puede renunciar hasta 3 tarifas cada 90 días.',
        ru: '? Вы можете отказаться от 3 комиссий каждые 90 дней.',
        fr: '? Vous pouvez renoncer jusqu\'à 3 frais tous les 90 jours.',
        de: '? Sie können alle 90 Tage bis zu 3 Gebühren erlassen.'
    },
    'Fee waived': {
        pt: 'Taxa renunciada',
        es: 'Tarifa renunciada',
        ru: 'Комиссия отменена',
        fr: 'Frais annulés',
        de: 'Gebühr erlassen'
    },
    'You can waive up to 3 fees every 90 days.': {
        pt: 'Você pode renunciar até 3 taxas a cada 90 dias.',
        es: 'Puede renunciar hasta 3 tarifas cada 90 días.',
        ru: 'Вы можете отказаться от 3 комиссий каждые 90 дней.',
        fr: 'Vous pouvez renoncer jusqu\'à 3 frais tous les 90 jours.',
        de: 'Sie können alle 90 Tage bis zu 3 Gebühren erlassen.'
    },
    'Go to Dashboard': {
        pt: 'Ir para o Painel',
        es: 'Ir al Panel',
        ru: 'Перейти в Панель управления',
        fr: 'Aller au Tableau de bord',
        de: 'Zum Dashboard'
    },

    'You have failed to show up to jobs for at least 2 days. Your account settings have set to automatically clear your schedule 48 hours in the future until your account is unlocked.': {
        pt: 'Você não compareceu aos trabalhos por pelo menos 2 dias. Suas configurações de conta foram definidas para limpar automaticamente sua agenda 48 horas no futuro até que sua conta seja desbloqueada.',
        es: 'No se ha presentado a trabajos durante al menos 2 días. La configuración de su cuenta se ha establecido para borrar automáticamente su horario 48 horas en el futuro hasta que su cuenta sea desbloqueada.',
        ru: 'Вы не явились на работу как минимум 2 дня. Настройки вашей учетной записи установлены на автоматическую очистку расписания через 48 часов, пока ваша учетная запись не будет разблокирована.',
        fr: 'Vous ne vous êtes pas présenté au travail pendant au moins 2 jours. Les paramètres de votre compte ont été configurés pour effacer automatiquement votre emploi du temps 48 heures à l\'avance jusqu\'à ce que votre compte soit déverrouillé.',
        de: 'Sie sind mindestens 2 Tage nicht zur Arbeit erschienen. Ihre Kontoeinstellungen wurden so eingestellt, dass Ihr Zeitplan automatisch 48 Stunden im Voraus gelöscht wird, bis Ihr Konto entsperrt wird.'
    },
    'I Want to Continue Getting Clients from TIDY': {
        pt: 'Quero Continuar Recebendo Clientes da TIDY',
        es: 'Quiero Seguir Recibiendo Clientes de TIDY',
        ru: 'Я Хочу Продолжать Получать Клиентов от TIDY',
        fr: 'Je Veux Continuer à Recevoir des Clients de TIDY',
        de: 'Ich Möchte Weiterhin Kunden von TIDY Erhalten'
    },
    'Let us know what happened': {
        pt: 'Nos diga o que aconteceu',
        es: 'Cuéntenos qué sucedió',
        ru: 'Расскажите, что произошло',
        fr: 'Dites-nous ce qui s\'est passé',
        de: 'Lassen Sie uns wissen, was passiert ist'
    },
    'Please describe what happened': {
        pt: 'Por favor, descreva o que aconteceu',
        es: 'Por favor, describa lo que sucedió',
        ru: 'Пожалуйста, опишите, что произошло',
        fr: 'Veuillez décrire ce qui s\'est passé',
        de: 'Bitte beschreiben Sie, was passiert ist'
    },
    'Submit and Become Active Again': {
        pt: 'Enviar e Tornar-se Ativo Novamente',
        es: 'Enviar y Volver a Estar Activo',
        ru: 'Отправить и Снова Стать Активным',
        fr: 'Soumettre et Redevenir Actif',
        de: 'Absenden und Wieder Aktiv Werden'
    },
    'Succesfully Activated': {
        pt: 'Ativado com Sucesso',
        es: 'Activado con Éxito',
        ru: 'Успешно Активировано',
        fr: 'Activé avec Succès',
        de: 'Erfolgreich Aktiviert'
    },
    'You are now Active! More cancellations can lead to fees even decertification form TIDY. Make sure to check \"My Schedule\" to confirm your \"Bookable\" schedule is up-to-date.': {
        pt: 'Você está agora Ativo! Mais cancelamentos podem levar a taxas e até descredenciamento da TIDY. Certifique-se de verificar \"Minha Agenda\" para confirmar se sua agenda \"Reservável\" está atualizada.',
        es: '¡Ahora está Activo! Más cancelaciones pueden llevar a tarifas e incluso descertificación de TIDY. Asegúrese de revisar \"Mi Horario\" para confirmar que su horario \"Reservable\" está actualizado.',
        ru: 'Теперь вы Активны! Дополнительные отмены могут привести к штрафам и даже к лишению сертификации TIDY. Обязательно проверьте \"Мое Расписание\", чтобы подтвердить, что ваше \"Доступное\" расписание актуально.',
        fr: 'Vous êtes maintenant Actif ! Plus d\'annulations peuvent entraîner des frais et même une décertification de TIDY. Assurez-vous de vérifier \"Mon Emploi du temps\" pour confirmer que votre horaire \"Réservable\" est à jour.',
        de: 'Sie sind jetzt Aktiv! Weitere Stornierungen können zu Gebühren und sogar zur Dezertifizierung von TIDY führen. Überprüfen Sie \"Mein Zeitplan\", um zu bestätigen, dass Ihr \"Buchbarer\" Zeitplan auf dem neuesten Stand ist.'
    },
    'You must agree to all of the above to reapply.': {
        pt: 'Você deve concordar com todos os itens acima para se recandidatar.',
        es: 'Debe aceptar todo lo anterior para volver a aplicar.',
        ru: 'Вы должны согласиться со всем вышеперечисленным, чтобы подать заявку повторно.',
        fr: 'Vous devez accepter tout ce qui précède pour postuler à nouveau.',
        de: 'Sie müssen allem oben Genannten zustimmen, um sich erneut zu bewerben.'
    },
    'Request Payment': {
        pt: 'Solicitar Pagamento',
        es: 'Solicitar Pago',
        ru: 'Запросить Оплату',
        fr: 'Demander le Paiement',
        de: 'Zahlung Anfordern'
    },
    'More Billing Options': {
        pt: 'Mais Opções de Faturamento',
        es: 'Más Opciones de Facturación',
        ru: 'Дополнительные Варианты Оплаты',
        fr: 'Plus d\'Options de Facturation',
        de: 'Weitere Abrechnungsoptionen'
    },
    'Unpaid Jobs': {
        pt: 'Trabalhos Não Pagos',
        es: 'Trabajos Sin Pagar',
        ru: 'Неоплаченные Работы',
        fr: 'Travaux Non Payés',
        de: 'Unbezahlte Aufträge'
    },
    '0 Unpaid': {
        pt: '0 Não Pagos',
        es: '0 Sin Pagar',
        ru: '0 Неоплаченных',
        fr: '0 Non Payé',
        de: '0 Unbezahlt'
    },
    ' owed for ': {
        pt: ' devido por ',
        es: ' adeudado por ',
        ru: ' должен за ',
        fr: ' dû pour ',
        de: ' geschuldet für '
    },
    ' job': {
        pt: ' trabalho',
        es: ' trabajo',
        ru: ' работу',
        fr: ' travail',
        de: ' Auftrag'
    },
    'Invoice Sent - Waiting on Client': {
        pt: 'Fatura Enviada - Aguardando Cliente',
        es: 'Factura Enviada - Esperando al Cliente',
        ru: 'Счет Отправлен - Ожидание Клиента',
        fr: 'Facture Envoyée - En Attente du Client',
        de: 'Rechnung Gesendet - Warten auf Kunde'
    },
    'No Invoice - Waiting on You to Send': {
        pt: 'Sem Fatura - Aguardando Seu Envio',
        es: 'Sin Factura - Esperando que Usted la Envíe',
        ru: 'Нет Счета - Ожидание Вашей Отправки',
        fr: 'Pas de Facture - En Attente de Votre Envoi',
        de: 'Keine Rechnung - Warten auf Ihre Sendung'
    },
    'Not Complete  - Mark Complete to Get Paid': {
        pt: 'Incompleto - Marque como Concluído para Receber',
        es: 'Incompleto - Marcar como Completado para Recibir Pago',
        ru: 'Не Завершено - Отметьте как Выполнено для Получения Оплаты',
        fr: 'Non Terminé - Marquer comme Terminé pour Être Payé',
        de: 'Nicht Abgeschlossen - Als Erledigt Markieren um Bezahlt zu Werden'
    },
    ' can see your proposals & invoices at their link. ': {
        pt: ' pode ver suas propostas e faturas no link deles. ',
        es: ' puede ver sus propuestas y facturas en su enlace. ',
        ru: ' может видеть ваши предложения и счета по их ссылке. ',
        fr: ' peut voir vos propositions et factures sur leur lien. ',
        de: ' kann Ihre Angebote und Rechnungen über ihren Link einsehen. '
    },
    'Copy URL': {
        pt: 'Copiar URL',
        es: 'Copiar URL',
        ru: 'Копировать URL',
        fr: 'Copier l\'URL',
        de: 'URL Kopieren'
    },
    ' Copied': {
        pt: ' Copiado',
        es: ' Copiado',
        ru: ' Скопировано',
        fr: ' Copié',
        de: ' Kopiert'
    },
    'Edit Plan': {
        pt: 'Editar Plano',
        es: 'Editar Plan',
        ru: 'Редактировать План',
        fr: 'Modifier le Plan',
        de: 'Plan Bearbeiten'
    },
    'No Plan Scheduled': {
        pt: 'Nenhum Plano Agendado',
        es: 'Ningún Plan Programado',
        ru: 'Нет Запланированных Планов',
        fr: 'Aucun Plan Programmé',
        de: 'Kein Plan Geplant'
    },
    'Future Jobs': {
        pt: 'Trabalhos Futuros',
        es: 'Trabajos Futuros',
        ru: 'Будущие Работы',
        fr: 'Travaux Futurs',
        de: 'Zukünftige Aufträge'
    },
    'Next ': {
        pt: 'Próximo ',
        es: 'Siguiente ',
        ru: 'Следующий ',
        fr: 'Suivant ',
        de: 'Nächste '
    },
    'No Future Jobs': {
        pt: 'Sem Trabalhos Futuros',
        es: 'Sin Trabajos Futuros',
        ru: 'Нет Будущих Работ',
        fr: 'Pas de Travaux Futurs',
        de: 'Keine Zukünftigen Aufträge'
    },
    'Last ': {
        pt: 'Último ',
        es: 'Último ',
        ru: 'Последний ',
        fr: 'Dernier ',
        de: 'Letzte '
    },
    'No Past Jobs': {
        pt: 'Sem Trabalhos Anteriores',
        es: 'Sin Trabajos Pasados',
        ru: 'Нет Прошлых Работ',
        fr: 'Pas de Travaux Passés',
        de: 'Keine Vergangenen Aufträge'
    },
    'Address Info': {
        pt: 'Informações do Endereço',
        es: 'Información de Dirección',
        ru: 'Информация об Адресе',
        fr: 'Informations d\'Adresse',
        de: 'Adressinformationen'
    },
    'Unit: ': {
        pt: 'Unidade: ',
        es: 'Unidad: ',
        ru: 'Квартира: ',
        fr: 'Unité: ',
        de: 'Einheit: '
    },
    'Access Notes': {
        pt: 'Notas de Acesso',
        es: 'Notas de Acceso',
        ru: 'Примечания о Доступе',
        fr: 'Notes d\'Accès',
        de: 'Zugangshinweise'
    },
    'How to park: ': {
        pt: 'Como estacionar: ',
        es: 'Cómo estacionar: ',
        ru: 'Как припарковаться: ',
        fr: 'Comment se garer: ',
        de: 'Parkanweisungen: '
    },
    'Paid Parking on the ': {
        pt: 'Estacionamento Pago na ',
        es: 'Estacionamiento Pagado en la ',
        ru: 'Платная Парковка на ',
        fr: 'Stationnement Payant sur la ',
        de: 'Kostenpflichtiges Parken auf der '
    },
    'Free Parking in my ': {
        pt: 'Estacionamento Gratuito no meu ',
        es: 'Estacionamiento Gratuito en mi ',
        ru: 'Бесплатная Парковка в моем ',
        fr: 'Stationnement Gratuit dans mon ',
        de: 'Kostenloses Parken in meinem '
    },
    'No parking notes added.': {
        pt: 'Nenhuma nota de estacionamento adicionada.',
        es: 'No se agregaron notas de estacionamiento.',
        ru: 'Примечания о парковке не добавлены.',
        fr: 'Aucune note de stationnement ajoutée.',
        de: 'Keine Parkhinweise hinzugefügt.'
    },
    'How to get in: ': {
        pt: 'Como entrar: ',
        es: 'Cómo entrar: ',
        ru: 'Как войти: ',
        fr: 'Comment entrer: ',
        de: 'Wie man hineinkommt: '
    },
    'No access notes added.': {
        pt: 'Nenhuma nota de acesso adicionada.',
        es: 'No se agregaron notas de acceso.',
        ru: 'Примечания о доступе не добавлены.',
        fr: 'Aucune note d\'accès ajoutée.',
        de: 'Keine Zugangshinweise hinzugefügt.'
    },
    'How to close up: ': {
        pt: 'Como fechar: ',
        es: 'Cómo cerrar: ',
        ru: 'Как закрыть: ',
        fr: 'Comment fermer: ',
        de: 'Wie man abschließt: '
    },
    'No closing notes added.': {
        pt: 'Nenhuma nota de fechamento adicionada.',
        es: 'No se agregaron notas de cierre.',
        ru: 'Примечания о закрытии не добавлены.',
        fr: 'Aucune note de fermeture ajoutée.',
        de: 'Keine Abschlusshinweise hinzugefügt.'
    },
    'To-Dos': {
        pt: 'Tarefas',
        es: 'Tareas',
        ru: 'Задачи',
        fr: 'À Faire',
        de: 'Aufgaben'
    },
    'Add To-Dos': {
        pt: 'Adicionar Tarefas',
        es: 'Agregar Tareas',
        ru: 'Добавить Задачи',
        fr: 'Ajouter des Tâches',
        de: 'Aufgaben Hinzufügen'
    },
    'None Added': {
        pt: 'Nada Adicionado',
        es: 'Nada Agregado',
        ru: 'Ничего не Добавлено',
        fr: 'Rien Ajouté',
        de: 'Nichts Hinzugefügt'
    },
    'Add Address': {
        pt: 'Adicionar Endereço',
        es: 'Agregar Dirección',
        ru: 'Добавить Адрес',
        fr: 'Ajouter une Adresse',
        de: 'Adresse Hinzufügen'
    },
    'Add an address to add private jobs for this Client.': {
        pt: 'Adicione um endereço para adicionar trabalhos privados para este Cliente.',
        es: 'Agregue una dirección para agregar trabajos privados para este Cliente.',
        ru: 'Добавьте адрес, чтобы добавить частные работы для этого Клиента.',
        fr: 'Ajoutez une adresse pour ajouter des travaux privés pour ce Client.',
        de: 'Fügen Sie eine Adresse hinzu, um private Aufträge für diesen Kunden hinzuzufügen.'
    },
    'My Spot/Driveway': {
        pt: 'Minha Vaga/Garagem',
        es: 'Mi Lugar/Entrada',
        ru: 'Мое Место/Подъезд',
        fr: 'Ma Place/Allée',
        de: 'Mein Platz/Einfahrt'
    },

    'Unit / Suite': {
        pt: 'Unidade / Suíte',
        es: 'Unidad / Suite',
        ru: 'Квартира / Офис',
        fr: 'Unité / Suite',
        de: 'Einheit / Suite'
    },
    'Zipcode': {
        pt: 'CEP',
        es: 'Código Postal',
        ru: 'Почтовый Индекс',
        fr: 'Code Postal',
        de: 'Postleitzahl'
    },
    'Please enter a valid zip code': {
        pt: 'Por favor, insira um CEP válido',
        es: 'Por favor, ingrese un código postal válido',
        ru: 'Пожалуйста, введите действительный почтовый индекс',
        fr: 'Veuillez entrer un code postal valide',
        de: 'Bitte geben Sie eine gültige Postleitzahl ein'
    },
    'Closing Notes': {
        pt: 'Notas de Fechamento',
        es: 'Notas de Cierre',
        ru: 'Примечания о Закрытии',
        fr: 'Notes de Fermeture',
        de: 'Abschlussnotizen'
    },
    'Address Added': {
        pt: 'Endereço Adicionado',
        es: 'Dirección Agregada',
        ru: 'Адрес Добавлен',
        fr: 'Adresse Ajoutée',
        de: 'Adresse Hinzugefügt'
    },
    'The address for this Client has been added.': {
        pt: 'O endereço para este Cliente foi adicionado.',
        es: 'La dirección para este Cliente ha sido agregada.',
        ru: 'Адрес для этого Клиента был добавлен.',
        fr: 'L\'adresse pour ce Client a été ajoutée.',
        de: 'Die Adresse für diesen Kunden wurde hinzugefügt.'
    },

    'Add Private Client': {
        pt: 'Adicionar Cliente Privado',
        es: 'Agregar Cliente Privado',
        ru: 'Добавить Частного Клиента',
        fr: 'Ajouter un Client Privé',
        de: 'Privatkunden Hinzufügen'
    },
    'First Name *': {
        pt: 'Nome *',
        es: 'Nombre *',
        ru: 'Имя *',
        fr: 'Prénom *',
        de: 'Vorname *'
    },
    'Please enter Client\'s first name': {
        pt: 'Por favor, insira o nome do Cliente',
        es: 'Por favor, ingrese el nombre del Cliente',
        ru: 'Пожалуйста, введите имя Клиента',
        fr: 'Veuillez entrer le prénom du Client',
        de: 'Bitte geben Sie den Vornamen des Kunden ein'
    },
    'Last Name *': {
        pt: 'Sobrenome *',
        es: 'Apellido *',
        ru: 'Фамилия *',
        fr: 'Nom *',
        de: 'Nachname *'
    },
    'Please enter Client\'s last name': {
        pt: 'Por favor, insira o sobrenome do Cliente',
        es: 'Por favor, ingrese el apellido del Cliente',
        ru: 'Пожалуйста, введите фамилию Клиента',
        fr: 'Veuillez entrer le nom du Client',
        de: 'Bitte geben Sie den Nachnamen des Kunden ein'
    },
    'Phone Number': {
        pt: 'Número de Telefone',
        es: 'Número de Teléfono',
        ru: 'Номер Телефона',
        fr: 'Numéro de Téléphone',
        de: 'Telefonnummer'
    },
    'Please enter a valid phone number.': {
        pt: 'Por favor, insira um número de telefone válido.',
        es: 'Por favor, ingrese un número de teléfono válido.',
        ru: 'Пожалуйста, введите действительный номер телефона.',
        fr: 'Veuillez entrer un numéro de téléphone valide.',
        de: 'Bitte geben Sie eine gültige Telefonnummer ein.'
    },
    'By adding I accept the terms (': {
        pt: 'Ao adicionar, aceito os termos (',
        es: 'Al agregar acepto los términos (',
        ru: 'Добавляя, я принимаю условия (',
        fr: 'En ajoutant j\'accepte les conditions (',
        de: 'Durch Hinzufügen akzeptiere ich die Bedingungen ('
    },
    'Private Client Guarantee': {
        pt: 'Garantia de Cliente Privado',
        es: 'Garantía de Cliente Privado',
        ru: 'Гарантия Частного Клиента',
        fr: 'Garantie Client Privé',
        de: 'Privatkunden-Garantie'
    },




    'Edit Client': {
        pt: 'Editar Cliente',
        es: 'Editar Cliente',
        ru: 'Редактировать Клиента',
        fr: 'Modifier Client',
        de: 'Kunde Bearbeiten'
    },
    'Please enter the client\'s first name': {
        pt: 'Por favor, insira o nome do cliente',
        es: 'Por favor, ingrese el nombre del cliente',
        ru: 'Пожалуйста, введите имя клиента',
        fr: 'Veuillez entrer le prénom du client',
        de: 'Bitte geben Sie den Vornamen des Kunden ein'
    },
    'Please enter the client\'s last name': {
        pt: 'Por favor, insira o sobrenome do cliente',
        es: 'Por favor, ingrese el apellido del cliente',
        ru: 'Пожалуйста, введите фамилию клиента',
        fr: 'Veuillez entrer le nom du client',
        de: 'Bitte geben Sie den Nachnamen des Kunden ein'
    },
    'Client Contact Saved': {
        pt: 'Contato do Cliente Salvo',
        es: 'Contacto del Cliente Guardado',
        ru: 'Контакт Клиента Сохранен',
        fr: 'Contact Client Enregistré',
        de: 'Kundenkontakt Gespeichert'
    },
    'The contact info for this Client has been updated.': {
        pt: 'As informações de contato deste Cliente foram atualizadas.',
        es: 'La información de contacto de este Cliente ha sido actualizada.',
        ru: 'Контактная информация этого Клиента была обновлена.',
        fr: 'Les informations de contact de ce Client ont été mises à jour.',
        de: 'Die Kontaktinformationen dieses Kunden wurden aktualisiert.'
    },
    'Delete Client?': {
        pt: 'Excluir Cliente?',
        es: '¿Eliminar Cliente?',
        ru: 'Удалить Клиента?',
        fr: 'Supprimer le Client?',
        de: 'Kunde Löschen?'
    },
    'This will cancel all jobs for this Client.': {
        pt: 'Isso cancelará todos os trabalhos para este Cliente.',
        es: 'Esto cancelará todos los trabajos para este Cliente.',
        ru: 'Это отменит все работы для этого Клиента.',
        fr: 'Cela annulera tous les travaux pour ce Client.',
        de: 'Dies wird alle Aufträge für diesen Kunden stornieren.'
    },
    'Client Deleted': {
        pt: 'Cliente Excluído',
        es: 'Cliente Eliminado',
        ru: 'Клиент Удален',
        fr: 'Client Supprimé',
        de: 'Kunde Gelöscht'
    },
    'You can add a new Client in the "Add" page': {
        pt: 'Você pode adicionar um novo Cliente na página "Adicionar"',
        es: 'Puede agregar un nuevo Cliente en la página "Agregar"',
        ru: 'Вы можете добавить нового Клиента на странице "Добавить"',
        fr: 'Vous pouvez ajouter un nouveau Client dans la page "Ajouter"',
        de: 'Sie können einen neuen Kunden auf der "Hinzufügen"-Seite hinzufügen'
    },
    'Cannot Delete Client': {
        pt: 'Não é Possível Excluir o Cliente',
        es: 'No se Puede Eliminar el Cliente',
        ru: 'Невозможно Удалить Клиента',
        fr: 'Impossible de Supprimer le Client',
        de: 'Kunde Kann Nicht Gelöscht Werden'
    },
    'You cannot delete a Client while they have jobs scheduled. Please go back and cancel any jobs before deleting the Client.': {
        pt: 'Você não pode excluir um Cliente enquanto houver trabalhos agendados. Por favor, volte e cancele quaisquer trabalhos antes de excluir o Cliente.',
        es: 'No puede eliminar un Cliente mientras tenga trabajos programados. Por favor, regrese y cancele cualquier trabajo antes de eliminar el Cliente.',
        ru: 'Вы не можете удалить Клиента, пока у него есть запланированные работы. Пожалуйста, вернитесь и отмените все работы перед удалением Клиента.',
        fr: 'Vous ne pouvez pas supprimer un Client tant qu\'il a des travaux programmés. Veuillez revenir en arrière et annuler tous les travaux avant de supprimer le Client.',
        de: 'Sie können einen Kunden nicht löschen, während noch Aufträge geplant sind. Bitte gehen Sie zurück und stornieren Sie alle Aufträge, bevor Sie den Kunden löschen.'
    },

    'Address Saved': {
        pt: 'Endereço Salvo',
        es: 'Dirección Guardada',
        ru: 'Адрес Сохранен',
        fr: 'Adresse Enregistrée',
        de: 'Adresse Gespeichert'
    },
    'The address for this Client has been updated.': {
        pt: 'O endereço deste Cliente foi atualizado.',
        es: 'La dirección de este Cliente ha sido actualizada.',
        ru: 'Адрес этого Клиента был обновлен.',
        fr: 'L\'adresse de ce Client a été mise à jour.',
        de: 'Die Adresse dieses Kunden wurde aktualisiert.'
    },
    'Delete Address?': {
        pt: 'Excluir Endereço?',
        es: '¿Eliminar Dirección?',
        ru: 'Удалить Адрес?',
        fr: 'Supprimer l\'Adresse?',
        de: 'Adresse Löschen?'
    },
    'This will cancel all jobs at this address.': {
        pt: 'Isso cancelará todos os trabalhos neste endereço.',
        es: 'Esto cancelará todos los trabajos en esta dirección.',
        ru: 'Это отменит все работы по этому адресу.',
        fr: 'Cela annulera tous les travaux à cette adresse.',
        de: 'Dies wird alle Aufträge an dieser Adresse stornieren.'
    },
    'Address Deleted': {
        pt: 'Endereço Excluído',
        es: 'Dirección Eliminada',
        ru: 'Адрес Удален',
        fr: 'Adresse Supprimée',
        de: 'Adresse Gelöscht'
    },
    'You can add a new address in the Client page': {
        pt: 'Você pode adicionar um novo endereço na página do Cliente',
        es: 'Puede agregar una nueva dirección en la página del Cliente',
        ru: 'Вы можете добавить новый адрес на странице Клиента',
        fr: 'Vous pouvez ajouter une nouvelle adresse dans la page Client',
        de: 'Sie können eine neue Adresse auf der Kundenseite hinzufügen'
    },
    'Go to Client': {
        pt: 'Ir para Cliente',
        es: 'Ir al Cliente',
        ru: 'Перейти к Клиенту',
        fr: 'Aller au Client',
        de: 'Zum Kunden'
    },
    'Delete Address': {
        pt: 'Excluir Endereço',
        es: 'Eliminar Dirección',
        ru: 'Удалить Адрес',
        fr: 'Supprimer l\'Adresse',
        de: 'Adresse Löschen'
    },
    'How to Park': {
        pt: 'Como Estacionar',
        es: 'Cómo Estacionar',
        ru: 'Как Припарковаться',
        fr: 'Comment Stationner',
        de: 'Wie man Parkt'
    },
    'What kind of parking?': {
        pt: 'Que tipo de estacionamento?',
        es: '¿Qué tipo de estacionamiento?',
        ru: 'Какой тип парковки?',
        fr: 'Quel type de stationnement?',
        de: 'Welche Art von Parkplatz?'
    },
    'What kind of spot?': {
        pt: 'Que tipo de vaga?',
        es: '¿Qué tipo de lugar?',
        ru: 'Какое место?',
        fr: 'Quel type de place?',
        de: 'Welche Art von Stellplatz?'
    },
    'How will they pay?': {
        pt: 'Como eles vão pagar?',
        es: '¿Cómo pagarán?',
        ru: 'Как они будут платить?',
        fr: 'Comment vont-ils payer?',
        de: 'Wie werden sie bezahlen?'
    },
    'How much will they reimburse?': {
        pt: 'Quanto eles vão reembolsar?',
        es: '¿Cuánto reembolsarán?',
        ru: 'Сколько они возместят?',
        fr: 'Combien vont-ils rembourser?',
        de: 'Wie viel werden sie erstatten?'
    },
    'Any notes to help park?': {
        pt: 'Alguma nota para ajudar a estacionar?',
        es: '¿Alguna nota para ayudar a estacionar?',
        ru: 'Есть заметки для помощи с парковкой?',
        fr: 'Des notes pour aider à stationner?',
        de: 'Notizen zum Parken?'
    },
    'Please detail any helpful notes on parking near this property': {
        pt: 'Por favor, detalhe quaisquer notas úteis sobre estacionamento próximo a esta propriedade',
        es: 'Por favor, detalle cualquier nota útil sobre el estacionamiento cerca de esta propiedad',
        ru: 'Пожалуйста, укажите любые полезные заметки о парковке рядом с этим объектом',
        fr: 'Veuillez détailler toutes les notes utiles sur le stationnement près de cette propriété',
        de: 'Bitte geben Sie hilfreiche Hinweise zum Parken in der Nähe dieser Immobilie an'
    },
    'How to Get In': {
        pt: 'Como Entrar',
        es: 'Cómo Entrar',
        ru: 'Как Войти',
        fr: 'Comment Entrer',
        de: 'Wie man Hineinkommt'
    },
    'How to access the property?': {
        pt: 'Como acessar a propriedade?',
        es: '¿Cómo acceder a la propiedad?',
        ru: 'Как получить доступ к объекту?',
        fr: 'Comment accéder à la propriété?',
        de: 'Wie erreicht man die Immobilie?'
    },
    'Please detail on how to access their property': {
        pt: 'Por favor, detalhe como acessar a propriedade deles',
        es: 'Por favor, detalle cómo acceder a su propiedad',
        ru: 'Пожалуйста, опишите, как получить доступ к их объекту',
        fr: 'Veuillez détailler comment accéder à leur propriété',
        de: 'Bitte erläutern Sie, wie man Zugang zu ihrer Immobilie erhält'
    },
    'How to Close Up': {
        pt: 'Como Fechar',
        es: 'Cómo Cerrar',
        ru: 'Как Закрыть',
        fr: 'Comment Fermer',
        de: 'Wie man Abschließt'
    },
    'What to do as you leave the property?': {
        pt: 'O que fazer ao sair da propriedade?',
        es: '¿Qué hacer al salir de la propiedad?',
        ru: 'Что делать при выходе с объекта?',
        fr: 'Que faire en quittant la propriété?',
        de: 'Was ist beim Verlassen der Immobilie zu tun?'
    },
    'Notes Saved': {
        pt: 'Notas Salvas',
        es: 'Notas Guardadas',
        ru: 'Заметки Сохранены',
        fr: 'Notes Enregistrées',
        de: 'Notizen Gespeichert'
    },
    'The Access Notes for this Client has been updated.': {
        pt: 'As Notas de Acesso para este Cliente foram atualizadas.',
        es: 'Las Notas de Acceso para este Cliente han sido actualizadas.',
        ru: 'Заметки о доступе для этого Клиента были обновлены.',
        fr: 'Les Notes d\'Accès pour ce Client ont été mises à jour.',
        de: 'Die Zugriffsnotizen für diesen Kunden wurden aktualisiert.'
    },
    'Free': {
        pt: 'Grátis',
        es: 'Gratis',
        ru: 'Бесплатно',
        fr: 'Gratuit',
        de: 'Kostenlos'
    },
    'Paid': {
        pt: 'Pago',
        es: 'Pagado',
        ru: 'Платно',
        fr: 'Payant',
        de: 'Bezahlt'
    },
    'Meter': {
        pt: 'Parquímetro',
        es: 'Parquímetro',
        ru: 'Счетчик',
        fr: 'Parcmètre',
        de: 'Parkuhr'
    },
    'Paid Lot': {
        pt: 'Estacionamento Pago',
        es: 'Estacionamiento de Pago',
        ru: 'Платная Стоянка',
        fr: 'Parking Payant',
        de: 'Kostenpflichtiger Parkplatz'
    },
    'Street': {
        pt: 'Rua',
        es: 'Calle',
        ru: 'Улица',
        fr: 'Rue',
        de: 'Straße'
    },
    'Their Spot / Driveway': {
        pt: 'Vaga Deles / Garagem',
        es: 'Su Lugar / Entrada',
        ru: 'Их Место / Подъездная дорожка',
        fr: 'Leur Place / Allée',
        de: 'Ihr Platz / Einfahrt'
    },
    'Guest Parking': {
        pt: 'Estacionamento para Visitantes',
        es: 'Estacionamiento para Visitantes',
        ru: 'Гостевая Парковка',
        fr: 'Parking Visiteurs',
        de: 'Besucherparkplatz'
    },
    'Cash': {
        pt: 'Dinheiro',
        es: 'Efectivo',
        ru: 'Наличные',
        fr: 'Espèces',
        de: 'Bargeld'
    },
    'Card': {
        pt: 'Cartão',
        es: 'Tarjeta',
        ru: 'Карта',
        fr: 'Carte',
        de: 'Karte'
    },

    'What would you like to do?': {
        pt: 'O que você gostaria de fazer?',
        es: '¿Qué te gustaría hacer?',
        ru: 'Что бы вы хотели сделать?',
        fr: 'Que voulez-vous faire?',
        de: 'Was möchten Sie tun?'
    },
    'Reschedule Plan': {
        pt: 'Reagendar Plano',
        es: 'Reprogramar Plan',
        ru: 'Перепланировать План',
        fr: 'Replanifier le Plan',
        de: 'Plan Neu Terminieren'
    },
    'Cancel Plan': {
        pt: 'Cancelar Plano',
        es: 'Cancelar Plan',
        ru: 'Отменить План',
        fr: 'Annuler le Plan',
        de: 'Plan Stornieren'
    },
    ' cost': {
        pt: ' custo',
        es: ' costo',
        ru: ' стоимость',
        fr: ' coût',
        de: ' Kosten'
    },
    ' / hour': {
        pt: ' / hora',
        es: ' / hora',
        ru: ' / час',
        fr: ' / heure',
        de: ' / Stunde'
    },
    'Price later': {
        pt: 'Preço depois',
        es: 'Precio después',
        ru: 'Цена позже',
        fr: 'Prix plus tard',
        de: 'Preis später'
    },
    ' has no future jobs at this address.': {
        pt: ' não tem trabalhos futuros neste endereço.',
        es: ' no tiene trabajos futuros en esta dirección.',
        ru: ' нет будущих работ по этому адресу.',
        fr: ' n\'a pas de travaux futurs à cette adresse.',
        de: ' hat keine zukünftigen Aufträge an dieser Adresse.'
    },

    'Past Jobs': {
        pt: 'Trabalhos Anteriores',
        es: 'Trabajos Anteriores',
        ru: 'Прошлые Работы',
        fr: 'Travaux Passés',
        de: 'Vergangene Aufträge'
    },
    'Booked with ': {
        pt: 'Reservado com ',
        es: 'Reservado con ',
        ru: 'Забронировано с ',
        fr: 'Réservé avec ',
        de: 'Gebucht mit '
    },
    'Send ': {
        pt: 'Enviar ',
        es: 'Enviar ',
        ru: 'Отправить ',
        fr: 'Envoyer ',
        de: 'Senden '
    },
    ' an Email': {
        pt: ' um Email',
        es: ' un Correo',
        ru: ' Письмо',
        fr: ' un Email',
        de: ' eine Email'
    },
    ' can book jobs, pay invoices, accept proposals and more from their personal page. You can send them an email with a link below. Here is a popular template but edit as you like:': {
        pt: ' pode agendar trabalhos, pagar faturas, aceitar propostas e mais em sua página pessoal. Você pode enviar um email com um link abaixo. Aqui está um modelo popular, mas edite como quiser:',
        es: ' puede reservar trabajos, pagar facturas, aceptar propuestas y más desde su página personal. Puede enviarles un correo electrónico con un enlace a continuación. Aquí hay una plantilla popular pero edítela como desee:',
        ru: ' может бронировать работы, оплачивать счета, принимать предложения и многое другое на своей личной странице. Вы можете отправить им письмо со ссылкой ниже. Вот популярный шаблон, но отредактируйте его как вам нравится:',
        fr: ' peut réserver des travaux, payer des factures, accepter des propositions et plus encore depuis sa page personnelle. Vous pouvez leur envoyer un email avec un lien ci-dessous. Voici un modèle populaire mais modifiez-le comme vous le souhaitez:',
        de: ' kann Aufträge buchen, Rechnungen bezahlen, Vorschläge annehmen und mehr von ihrer persönlichen Seite aus. Sie können ihnen eine E-Mail mit einem Link unten senden. Hier ist eine beliebte Vorlage, aber bearbeiten Sie sie wie Sie möchten:'
    },
    'Client Email': {
        pt: 'Email do Cliente',
        es: 'Correo del Cliente',
        ru: 'Email Клиента',
        fr: 'Email du Client',
        de: 'Kunden-Email'
    },
    'Body': {
        pt: 'Corpo',
        es: 'Cuerpo',
        ru: 'Текст',
        fr: 'Corps',
        de: 'Inhalt'
    },
    'Send Email With Link': {
        pt: 'Enviar Email Com Link',
        es: 'Enviar Correo Con Enlace',
        ru: 'Отправить Письмо Со Ссылкой',
        fr: 'Envoyer Email Avec Lien',
        de: 'Email Mit Link Senden'
    },
    'Do This Later': {
        pt: 'Fazer Isso Depois',
        es: 'Hacer Esto Después',
        ru: 'Сделать Это Позже',
        fr: 'Faire Cela Plus Tard',
        de: 'Später Machen'
    },

    'Private Job': {
        pt: 'Trabalho Privado',
        es: 'Trabajo Privado',
        ru: 'Частная Работа',
        fr: 'Travail Privé',
        de: 'Privater Auftrag'
    },
    'Time Sensitive': {
        pt: 'Sensível ao Tempo',
        es: 'Sensible al Tiempo',
        ru: 'Срочно',
        fr: 'Urgent',
        de: 'Zeitkritisch'
    },
    ' has a guest checking in': {
        pt: ' tem um hóspede fazendo check-in',
        es: ' tiene un huésped haciendo check-in',
        ru: ' ожидается заезд гостя',
        fr: ' a un invité qui arrive',
        de: ' hat einen Gast zum Check-in'
    },
    ' on the same day as the job.': {
        pt: ' no mesmo dia do trabalho.',
        es: ' el mismo día del trabajo.',
        ru: ' в тот же день, что и работа.',
        fr: ' le même jour que le travail.',
        de: ' am selben Tag wie der Auftrag.'
    },
    'an invited team member': {
        pt: 'um membro da equipe convidado',
        es: 'un miembro del equipo invitado',
        ru: 'приглашенный член команды',
        fr: 'un membre invité de l\'équipe',
        de: 'ein eingeladenes Teammitglied'
    },
    ' and they completed it.  All payment will be made to you. ': {
        pt: ' e eles completaram.  Todo o pagamento será feito a você. ',
        es: ' y ellos lo completaron.  Todo el pago se le realizará a usted. ',
        ru: ' и они завершили его.  Вся оплата будет произведена вам. ',
        fr: ' et ils l\'ont terminé.  Tout le paiement vous sera versé. ',
        de: ' und sie haben es abgeschlossen.  Die gesamte Zahlung geht an Sie. '
    },
    'Client was told you have <b>started the job</b>.': {
        pt: 'O cliente foi informado que você <b>iniciou o trabalho</b>.',
        es: 'Se le informó al cliente que usted <b>comenzó el trabajo</b>.',
        ru: 'Клиенту сообщили, что вы <b>начали работу</b>.',
        fr: 'Le client a été informé que vous avez <b>commencé le travail</b>.',
        de: 'Der Kunde wurde informiert, dass Sie <b>mit der Arbeit begonnen haben</b>.'
    },
    'Already Finished? ': {
        pt: 'Já Terminou? ',
        es: '¿Ya Terminó? ',
        ru: 'Уже Закончили? ',
        fr: 'Déjà Terminé? ',
        de: 'Schon Fertig? '
    },
    'Mark Complete': {
        pt: 'Marcar como Concluído',
        es: 'Marcar como Completado',
        ru: 'Отметить как Выполнено',
        fr: 'Marquer comme Terminé',
        de: 'Als Abgeschlossen Markieren'
    },
    'Marked as ': {
        pt: 'Marcado como ',
        es: 'Marcado como ',
        ru: 'Отмечено как ',
        fr: 'Marqué comme ',
        de: 'Markiert als '
    },
    'Job Duration: ': {
        pt: 'Duração do Trabalho: ',
        es: 'Duración del Trabajo: ',
        ru: 'Продолжительность Работы: ',
        fr: 'Durée du Travail: ',
        de: 'Auftragsdauer: '
    },
    'Stop Editing': {
        pt: 'Parar de Editar',
        es: 'Dejar de Editar',
        ru: 'Прекратить Редактирование',
        fr: 'Arrêter l\'Édition',
        de: 'Bearbeitung Beenden'
    },
    'Save Change': {
        pt: 'Salvar Alteração',
        es: 'Guardar Cambio',
        ru: 'Сохранить Изменение',
        fr: 'Enregistrer le Changement',
        de: 'Änderung Speichern'
    },
    'Delegate Job': {
        pt: 'Delegar Trabalho',
        es: 'Delegar Trabajo',
        ru: 'Делегировать Работу',
        fr: 'Déléguer le Travail',
        de: 'Auftrag Delegieren'
    },
    'Reschedule / Cancel Job': {
        pt: 'Reagendar / Cancelar Trabalho',
        es: 'Reprogramar / Cancelar Trabajo',
        ru: 'Перенести / Отменить Работу',
        fr: 'Reprogrammer / Annuler le Travail',
        de: 'Auftrag Verschieben / Stornieren'
    },
    'Rental Information': {
        pt: 'Informações do Aluguel',
        es: 'Información del Alquiler',
        ru: 'Информация об Аренде',
        fr: 'Informations de Location',
        de: 'Mietinformationen'
    },
    ' rents out their property.': {
        pt: ' aluga sua propriedade.',
        es: ' alquila su propiedad.',
        ru: ' сдает свою недвижимость.',
        fr: ' loue leur propriété.',
        de: ' vermietet ihr Eigentum.'
    },
    'Last Reservation:': {
        pt: 'Última Reserva:',
        es: 'Última Reserva:',
        ru: 'Последнее Бронирование:',
        fr: 'Dernière Réservation:',
        de: 'Letzte Reservierung:'
    },
    ' guest(s)': {
        pt: ' hóspede(s)',
        es: ' huésped(es)',
        ru: ' гость(я/ей)',
        fr: ' invité(s)',
        de: ' Gast/Gäste'
    },
    ' adult(s)': {
        pt: ' adulto(s)',
        es: ' adulto(s)',
        ru: ' взрослый(х)',
        fr: ' adulte(s)',
        de: ' Erwachsene(r)'
    },
    ' children': {
        pt: ' crianças',
        es: ' niños',
        ru: ' дети',
        fr: ' enfants',
        de: ' Kinder'
    },
    ' pet(s)': {
        pt: ' animal(is) de estimação',
        es: ' mascota(s)',
        ru: ' питомец(цы)',
        fr: ' animal/animaux',
        de: ' Haustier(e)'
    },
    'Next Reservation:': {
        pt: 'Próxima Reserva:',
        es: 'Próxima Reserva:',
        ru: 'Следующее Бронирование:',
        fr: 'Prochaine Réservation:',
        de: 'Nächste Reservierung:'
    },
    
    ' check in': {
        pt: ' check in',
        es: ' check in', 
        ru: ' заселение',
        fr: ' arrivée',
        de: ' Check-in'
    },
    ' check out': {
        pt: ' check out',
        es: ' check out',
        ru: ' выезд',
        fr: ' départ',
        de: ' Check-out'
    },
    'Client': {
        pt: 'Cliente',
        es: 'Cliente',
        ru: 'Клиент',
        fr: 'Client',
        de: 'Kunde'
    },
    'View Client': {
        pt: 'Ver Cliente',
        es: 'Ver Cliente',
        ru: 'Посмотреть Клиента',
        fr: 'Voir le Client',
        de: 'Kunde anzeigen'
    },
    'Loading Messages...': {
        pt: 'Carregando Mensagens...',
        es: 'Cargando Mensajes...',
        ru: 'Загрузка Сообщений...',
        fr: 'Chargement des Messages...',
        de: 'Nachrichten werden geladen...'
    },
    ' owed of ': {
        pt: ' devido de ',
        es: ' adeudado de ',
        ru: ' должно из ',
        fr: ' dû sur ',
        de: ' geschuldet von '
    },
    ' price': {
        pt: ' preço',
        es: ' precio',
        ru: ' цена',
        fr: ' prix',
        de: ' Preis'
    },
    ' / hour ': {
        pt: ' / hora ',
        es: ' / hora ',
        ru: ' / час ',
        fr: ' / heure ',
        de: ' / Stunde '
    },
    'Price later ': {
        pt: 'Preço depois ',
        es: 'Precio después ',
        ru: 'Цена позже ',
        fr: 'Prix plus tard ',
        de: 'Preis später '
    },
    '<i>No Payments for this Job</i>': {
        pt: '<i>Sem Pagamentos para este Trabalho</i>',
        es: '<i>Sin Pagos para este Trabajo</i>',
        ru: '<i>Нет Платежей за эту Работу</i>',
        fr: '<i>Aucun Paiement pour ce Travail</i>',
        de: '<i>Keine Zahlungen für diesen Auftrag</i>'
    },
    ' used of ': {
        pt: ' usado de ',
        es: ' usado de ',
        ru: ' использовано из ',
        fr: ' utilisé sur ',
        de: ' verwendet von '
    },
    'Refund': {
        pt: 'Reembolso',
        es: 'Reembolso',
        ru: 'Возврат',
        fr: 'Remboursement',
        de: 'Rückerstattung'
    },
    'Mark Complete & Request Payment': {
        pt: 'Marcar como Concluído e Solicitar Pagamento',
        es: 'Marcar como Completado y Solicitar Pago',
        ru: 'Отметить как Выполнено и Запросить Оплату',
        fr: 'Marquer comme Terminé et Demander le Paiement',
        de: 'Als Abgeschlossen markieren & Zahlung anfordern'
    },
    'Invoice Sent': {
        pt: 'Fatura Enviada',
        es: 'Factura Enviada',
        ru: 'Счет Отправлен',
        fr: 'Facture Envoyée',
        de: 'Rechnung Gesendet'
    },
    'View Invoice': {
        pt: 'Ver Fatura',
        es: 'Ver Factura',
        ru: 'Посмотреть Счет',
        fr: 'Voir la Facture',
        de: 'Rechnung anzeigen'
    },
    ' received an email at ': {
        pt: ' recebeu um email em ',
        es: ' recibió un correo electrónico en ',
        ru: ' получил(а) письмо на ',
        fr: ' a reçu un email à ',
        de: ' hat eine E-Mail erhalten an '
    },
    ' and can see your invoice at your Client\'s Hub. ': {
        pt: ' e pode ver sua fatura no Hub do Cliente. ',
        es: ' y puede ver su factura en el Hub del Cliente. ',
        ru: ' и может увидеть ваш счет в Центре Клиента. ',
        fr: ' et peut voir votre facture dans le Hub Client. ',
        de: ' und kann Ihre Rechnung im Kunden-Hub sehen. '
    },
    'Mark as Paid': {
        pt: 'Marcar como Pago',
        es: 'Marcar como Pagado',
        ru: 'Отметить как Оплачено',
        fr: 'Marquer comme Payé',
        de: 'Als bezahlt markieren'
    },
    'Tax Deduction: ': {
        pt: 'Dedução Fiscal: ',
        es: 'Deducción de Impuestos: ',
        ru: 'Налоговый Вычет: ',
        fr: 'Déduction Fiscale: ',
        de: 'Steuerabzug: '
    },
    'Address': {
        pt: 'Endereço',
        es: 'Dirección',
        ru: 'Адрес',
        fr: 'Adresse',
        de: 'Adresse'
    },
    'Get Directions': {
        pt: 'Obter Direções',
        es: 'Obtener Direcciones',
        ru: 'Получить Маршрут',
        fr: 'Obtenir l\'Itinéraire',
        de: 'Route anzeigen'
    },
    'Unit ': {
        pt: 'Unidade ',
        es: 'Unidad ',
        ru: 'Квартира ',
        fr: 'Unité ',
        de: 'Einheit '
    },
    'Parking': {
        pt: 'Estacionamento',
        es: 'Estacionamiento',
        ru: 'Парковка',
        fr: 'Stationnement',
        de: 'Parken'
    },
    'Paid parking ': {
        pt: 'Estacionamento pago ',
        es: 'Estacionamiento pagado ',
        ru: 'Платная парковка ',
        fr: 'Stationnement payant ',
        de: 'Kostenpflichtiges Parken '
    },
    'Free parking ': {
        pt: 'Estacionamento gratuito ',
        es: 'Estacionamiento gratuito ',
        ru: 'Бесплатная парковка ',
        fr: 'Stationnement gratuit ',
        de: 'Kostenloses Parken '
    },
    'Client will cover up to ': {
        pt: 'O cliente cobrirá até ',
        es: 'El cliente cubrirá hasta ',
        ru: 'Клиент покроет до ',
        fr: 'Le client couvrira jusqu\'à ',
        de: 'Der Kunde übernimmt bis zu '
    },
    ' and reimburse through the TIDY app.': {
        pt: ' e reembolsará através do aplicativo TIDY.',
        es: ' y reembolsará a través de la aplicación TIDY.',
        ru: ' и возместит через приложение TIDY.',
        fr: ' et remboursera via l\'application TIDY.',
        de: ' und erstattet über die TIDY-App.'
    },
    ' and reimburse with cash.': {
        pt: ' e reembolsará em dinheiro.',
        es: ' y reembolsará en efectivo.',
        ru: ' и возместит наличными.',
        fr: ' et remboursera en espèces.',
        de: ' und erstattet in bar.'
    },
    'Client Notes: ': {
        pt: 'Notas do Cliente: ',
        es: 'Notas del Cliente: ',
        ru: 'Примечания Клиента: ',
        fr: 'Notes du Client: ',
        de: 'Kundennotizen: '
    },
    '<i>No Parking Notes</i>': {
        pt: '<i>Sem Notas de Estacionamento</i>',
        es: '<i>Sin Notas de Estacionamiento</i>',
        ru: '<i>Нет Заметок о Парковке</i>',
        fr: '<i>Pas de Notes de Stationnement</i>',
        de: '<i>Keine Parkhinweise</i>'
    },
    'Access': {
        pt: 'Acesso',
        es: 'Acceso',
        ru: 'Доступ',
        fr: 'Accès',
        de: 'Zugang'
    },
    'Lockbox code: ': {
        pt: 'Código do cofre: ',
        es: 'Código de la caja fuerte: ',
        ru: 'Код замка: ',
        fr: 'Code du boîtier: ',
        de: 'Schlüsselbox-Code: '
    },
    ', Notes:': {
        pt: ', Notas:',
        es: ', Notas:',
        ru: ', Примечания:',
        fr: ', Notes:',
        de: ', Notizen:'
    },
    '<i>No Access Notes</i>': {
        pt: '<i>Sem Notas de Acesso</i>',
        es: '<i>Sin Notas de Acceso</i>',
        ru: '<i>Нет Заметок о Доступе</i>',
        fr: '<i>Pas de Notes d\'Accès</i>',
        de: '<i>Keine Zugangshinweise</i>'
    },
    ' did not add any To-Dos for this job.': {
        pt: ' não adicionou nenhuma Tarefa para este trabalho.',
        es: ' no agregó ninguna Tarea para este trabajo.',
        ru: ' не добавил(а) Задач для этой работы.',
        fr: ' n\'a pas ajouté de Tâches pour ce travail.',
        de: ' hat keine Aufgaben für diesen Auftrag hinzugefügt.'
    },
    'Mark as Paid?': {
        pt: 'Marcar como Pago?',
        es: '¿Marcar como Pagado?',
        ru: 'Отметить как Оплачено?',
        fr: 'Marquer comme Payé?',
        de: 'Als bezahlt markieren?'
    },
    'This will mark the job as paid in full.': {
        pt: 'Isto marcará o trabalho como pago integralmente.',
        es: 'Esto marcará el trabajo como pagado en su totalidad.',
        ru: 'Это отметит работу как полностью оплаченную.',
        fr: 'Cela marquera le travail comme entièrement payé.',
        de: 'Dies markiert den Auftrag als vollständig bezahlt.'
    },
    'Payment Recorded': {
        pt: 'Pagamento Registrado',
        es: 'Pago Registrado',
        ru: 'Платеж Записан',
        fr: 'Paiement Enregistré',
        de: 'Zahlung erfasst'
    },
    'Are you sure you want to mark this job as complete?': {
        pt: 'Tem certeza que deseja marcar este trabalho como concluído?',
        es: '¿Está seguro de que desea marcar este trabajo como completado?',
        ru: 'Вы уверены, что хотите отметить эту работу как выполненную?',
        fr: 'Êtes-vous sûr de vouloir marquer ce travail comme terminé?',
        de: 'Sind Sie sicher, dass Sie diesen Auftrag als abgeschlossen markieren möchten?'
    },

    'Add Job For': {
        pt: 'Adicionar Trabalho Para',
        es: 'Agregar Trabajo Para',
        ru: 'Добавить Работу Для',
        fr: 'Ajouter un Travail Pour',
        de: 'Auftrag Hinzufügen Für'
    },
    'Existing Client': {
        pt: 'Cliente Existente',
        es: 'Cliente Existente',
        ru: 'Существующий Клиент',
        fr: 'Client Existant',
        de: 'Bestehender Kunde'
    },
    'New Client': {
        pt: 'Novo Cliente',
        es: 'Cliente Nuevo',
        ru: 'Новый Клиент',
        fr: 'Nouveau Client',
        de: 'Neuer Kunde'
    },
    'You don\'t have any existing Clients.': {
        pt: 'Você não tem nenhum Cliente existente.',
        es: 'No tiene ningún Cliente existente.',
        ru: 'У вас нет существующих Клиентов.',
        fr: 'Vous n\'avez aucun Client existant.',
        de: 'Sie haben keine bestehenden Kunden.'
    },
    'Please enter Client\'s name': {
        pt: 'Por favor, insira o nome do Cliente',
        es: 'Por favor, ingrese el nombre del Cliente',
        ru: 'Пожалуйста, введите имя Клиента',
        fr: 'Veuillez entrer le nom du Client',
        de: 'Bitte geben Sie den Namen des Kunden ein'
    },
    'Please select the Client\'s address': {
        pt: 'Por favor, selecione o endereço do Cliente',
        es: 'Por favor, seleccione la dirección del Cliente',
        ru: 'Пожалуйста, выберите адрес Клиента',
        fr: 'Veuillez sélectionner l\'adresse du Client',
        de: 'Bitte wählen Sie die Adresse des Kunden'
    },
    'Plan': {
        pt: 'Plano',
        es: 'Plan',
        ru: 'План',
        fr: 'Plan',
        de: 'Plan'
    },
    'Starting on': {
        pt: 'Começando em',
        es: 'Comenzando el',
        ru: 'Начиная с',
        fr: 'Commençant le',
        de: 'Beginnt am'
    },
    'Price: ': {
        pt: 'Preço: ',
        es: 'Precio: ',
        ru: 'Цена: ',
        fr: 'Prix: ',
        de: 'Preis: '
    },
    'Hourly Rate: ': {
        pt: 'Taxa Horária: ',
        es: 'Tarifa por Hora: ',
        ru: 'Почасовая Ставка: ',
        fr: 'Taux Horaire: ',
        de: 'Stundensatz: '
    },
    'Details': {
        pt: 'Detalhes',
        es: 'Detalles',
        ru: 'Детали',
        fr: 'Détails',
        de: 'Details'
    },
    'Date': {
        pt: 'Data',
        es: 'Fecha',
        ru: 'Дата',
        fr: 'Date',
        de: 'Datum'
    },
    'Please enter the job\'s start date.': {
        pt: 'Por favor, insira a data de início do trabalho.',
        es: 'Por favor, ingrese la fecha de inicio del trabajo.',
        ru: 'Пожалуйста, введите дату начала работы.',
        fr: 'Veuillez entrer la date de début du travail.',
        de: 'Bitte geben Sie das Startdatum des Auftrags ein.'
    },
    'Start Time': {
        pt: 'Hora de Início',
        es: 'Hora de Inicio',
        ru: 'Время Начала',
        fr: 'Heure de Début',
        de: 'Startzeit'
    },
    'Please enter the job\'s start time.': {
        pt: 'Por favor, insira a hora de início do trabalho.',
        es: 'Por favor, ingrese la hora de inicio del trabajo.',
        ru: 'Пожалуйста, введите время начала работы.',
        fr: 'Veuillez entrer l\'heure de début du travail.',
        de: 'Bitte geben Sie die Startzeit des Auftrags ein.'
    },
    'Frequency': {
        pt: 'Frequência',
        es: 'Frecuencia',
        ru: 'Частота',
        fr: 'Fréquence',
        de: 'Häufigkeit'
    },
    'Please enter the job\'s frequency.': {
        pt: 'Por favor, insira a frequência do trabalho.',
        es: 'Por favor, ingrese la frecuencia del trabajo.',
        ru: 'Пожалуйста, введите частоту работы.',
        fr: 'Veuillez entrer la fréquence du travail.',
        de: 'Bitte geben Sie die Häufigkeit des Auftrags ein.'
    },
    'Service': {
        pt: 'Serviço',
        es: 'Servicio',
        ru: 'Услуга',
        fr: 'Service',
        de: 'Dienstleistung'
    },
    'Please enter the service.': {
        pt: 'Por favor, insira o serviço.',
        es: 'Por favor, ingrese el servicio.',
        ru: 'Пожалуйста, введите услугу.',
        fr: 'Veuillez entrer le service.',
        de: 'Bitte geben Sie die Dienstleistung ein.'
    },
    'Please enter the service price.': {
        pt: 'Por favor, insira o preço do serviço.',
        es: 'Por favor, ingrese el precio del servicio.',
        ru: 'Пожалуйста, введите цену услуги.',
        fr: 'Veuillez entrer le prix du service.',
        de: 'Bitte geben Sie den Preis der Dienstleistung ein.'
    },
    'Please enter the service description.': {
        pt: 'Por favor, insira a descrição do serviço.',
        es: 'Por favor, ingrese la descripción del servicio.',
        ru: 'Пожалуйста, введите описание услуги.',
        fr: 'Veuillez entrer la description du service.',
        de: 'Bitte geben Sie die Beschreibung der Dienstleistung ein.'
    },
    'Description': {
        pt: 'Descrição',
        es: 'Descripción',
        ru: 'Описание',
        fr: 'Description',
        de: 'Beschreibung'
    },
    'Hourly Rate': {
        pt: 'Taxa Horária',
        es: 'Tarifa por Hora',
        ru: 'Почасовая Ставка',
        fr: 'Taux Horaire',
        de: 'Stundensatz'
    },
    'Price': {
        pt: 'Preço',
        es: 'Precio',
        ru: 'Цена',
        fr: 'Prix',
        de: 'Preis'
    },

    'Edit Hourly Rate': {
        pt: 'Editar Taxa Horária',
        es: 'Editar Tarifa por Hora',
        ru: 'Изменить Почасовую Ставку',
        fr: 'Modifier le Taux Horaire',
        de: 'Stundensatz Bearbeiten'
    },
    'Hourly rate': {
        pt: 'Taxa horária',
        es: 'Tarifa por hora',
        ru: 'Почасовая ставка',
        fr: 'Taux horaire',
        de: 'Stundensatz'
    },
    'Please select a rate.': {
        pt: 'Por favor, selecione uma taxa.',
        es: 'Por favor, seleccione una tarifa.',
        ru: 'Пожалуйста, выберите ставку.',
        fr: 'Veuillez sélectionner un taux.',
        de: 'Bitte wählen Sie einen Satz.'
    },
    'Edit Job Price': {
        pt: 'Editar Preço do Trabalho',
        es: 'Editar Precio del Trabajo',
        ru: 'Изменить Цену Работы',
        fr: 'Modifier le Prix du Travail',
        de: 'Auftragspreis Bearbeiten'
    },
    'Job price': {
        pt: 'Preço do trabalho',
        es: 'Precio del trabajo',
        ru: 'Цена работы',
        fr: 'Prix du travail',
        de: 'Auftragspreis'
    },
    'Who is Canceling?': {
        pt: 'Quem está Cancelando?',
        es: '¿Quién está Cancelando?',
        ru: 'Кто Отменяет?',
        fr: 'Qui Annule?',
        de: 'Wer Storniert?'
    },
    'Who is canceling this job on ': {
        pt: 'Quem está cancelando este trabalho em ',
        es: '¿Quién está cancelando este trabajo el ',
        ru: 'Кто отменяет эту работу ',
        fr: 'Qui annule ce travail le ',
        de: 'Wer storniert diesen Auftrag am '
    },
    'Who is canceling this plan with next job ': {
        pt: 'Quem está cancelando este plano com o próximo trabalho ',
        es: '¿Quién está cancelando este plan con el próximo trabajo ',
        ru: 'Кто отменяет этот план со следующей работой ',
        fr: 'Qui annule ce plan avec le prochain travail ',
        de: 'Wer storniert diesen Plan mit dem nächsten Auftrag '
    },
    'Me': {
        pt: 'Eu',
        es: 'Yo',
        ru: 'Я',
        fr: 'Moi',
        de: 'Ich'
    },
    'Bill Cancellation Fee?': {
        pt: 'Cobrar Taxa de Cancelamento?',
        es: '¿Cobrar Tarifa de Cancelación?',
        ru: 'Выставить Счет за Отмену?',
        fr: 'Facturer des Frais d\'Annulation?',
        de: 'Stornierungsgebühr Berechnen?'
    },
    'Do you want to bill them a cancellation fee per your settings?': {
        pt: 'Você quer cobrar uma taxa de cancelamento conforme suas configurações?',
        es: '¿Desea cobrarles una tarifa de cancelación según sus configuraciones?',
        ru: 'Хотите выставить им счет за отмену согласно вашим настройкам?',
        fr: 'Voulez-vous leur facturer des frais d\'annulation selon vos paramètres?',
        de: 'Möchten Sie ihnen eine Stornierungsgebühr gemäß Ihren Einstellungen berechnen?'
    },
    'No': {
        pt: 'Não',
        es: 'No',
        ru: 'Нет',
        fr: 'Non',
        de: 'Nein'
    },
    'Yes': {
        pt: 'Sim',
        es: 'Sí',
        ru: 'Да',
        fr: 'Oui',
        de: 'Ja'
    },
    'Cancel Job?': {
        pt: 'Cancelar Trabalho?',
        es: '¿Cancelar Trabajo?',
        ru: 'Отменить Работу?',
        fr: 'Annuler le Travail?',
        de: 'Auftrag Stornieren?'
    },
    'Cancel Plan?': {
        pt: 'Cancelar Plano?',
        es: '¿Cancelar Plan?',
        ru: 'Отменить План?',
        fr: 'Annuler le Plan?',
        de: 'Plan Stornieren?'
    },
    'You can search for this Client to add new jobs.': {
        pt: 'Você pode procurar este Cliente para adicionar novos trabalhos.',
        es: 'Puede buscar este Cliente para agregar nuevos trabajos.',
        ru: 'Вы можете найти этого Клиента, чтобы добавить новые работы.',
        fr: 'Vous pouvez rechercher ce Client pour ajouter de nouveaux travaux.',
        de: 'Sie können nach diesem Kunden suchen, um neue Aufträge hinzuzufügen.'
    },
    'You can search for this Client to add a new plan.': {
        pt: 'Você pode procurar este Cliente para adicionar um novo plano.',
        es: 'Puede buscar este Cliente para agregar un nuevo plan.',
        ru: 'Вы можете найти этого Клиента, чтобы добавить новый план.',
        fr: 'Vous pouvez rechercher ce Client pour ajouter un nouveau plan.',
        de: 'Sie können nach diesem Kunden suchen, um einen neuen Plan hinzuzufügen.'
    },

    'Edit Job': {
        pt: 'Editar Trabalho',
        es: 'Editar Trabajo',
        ru: 'Редактировать Работу',
        fr: 'Modifier le Travail',
        de: 'Auftrag Bearbeiten'
    },
    'What do you want to do?': {
        pt: 'O que você quer fazer?',
        es: '¿Qué quieres hacer?',
        ru: 'Что вы хотите сделать?',
        fr: 'Que voulez-vous faire?',
        de: 'Was möchten Sie tun?'
    },
    'Change Who Cancelled': {
        pt: 'Alterar Quem Cancelou',
        es: 'Cambiar Quién Canceló',
        ru: 'Изменить, Кто Отменил',
        fr: 'Changer Qui a Annulé',
        de: 'Ändern Wer Storniert Hat'
    },
    'Mark Job as Complete': {
        pt: 'Marcar Trabalho como Concluído',
        es: 'Marcar Trabajo como Completado',
        ru: 'Отметить Работу как Завершенную',
        fr: 'Marquer le Travail comme Terminé',
        de: 'Auftrag als Abgeschlossen Markieren'
    },
    'Mark Job as Cancelled': {
        pt: 'Marcar Trabalho como Cancelado',
        es: 'Marcar Trabajo como Cancelado',
        ru: 'Отметить Работу как Отмененную',
        fr: 'Marquer le Travail comme Annulé',
        de: 'Auftrag als Storniert Markieren'
    },
    'Are you sure you want to complete this job?': {
        pt: 'Tem certeza que deseja concluir este trabalho?',
        es: '¿Está seguro de que desea completar este trabajo?',
        ru: 'Вы уверены, что хотите завершить эту работу?',
        fr: 'Êtes-vous sûr de vouloir terminer ce travail?',
        de: 'Sind Sie sicher, dass Sie diesen Auftrag abschließen möchten?'
    },
    'The Client is rescheduling': {
        pt: 'O Cliente está reagendando',
        es: 'El Cliente está reprogramando',
        ru: 'Клиент переносит',
        fr: 'Le Client reprogramme',
        de: 'Der Kunde plant um'
    },
    'and will be charged a cancellation fee.': {
        pt: 'e será cobrada uma taxa de cancelamento.',
        es: 'y se le cobrará una tarifa de cancelación.',
        ru: 'и будет взиматься плата за отмену.',
        fr: 'et des frais d\'annulation seront facturés.',
        de: 'und es wird eine Stornierungsgebühr berechnet.'
    },
    'I am rescheduling': {
        pt: 'Estou reagendando',
        es: 'Estoy reprogramando',
        ru: 'Я переношу',
        fr: 'Je reprogramme',
        de: 'Ich plane um'
    },


    'Who is rescheduling this ': {
        pt: 'Quem está reagendando isto ',
        es: 'Quién está reprogramando esto ',
        ru: 'Кто переносит это ',
        fr: 'Qui reprogramme cela ',
        de: 'Wer plant dies um '
    },
    'on ': {
        pt: 'em ',
        es: 'el ',
        ru: 'на ',
        fr: 'le ',
        de: 'am '
    },
    'Job Rescheduled': {
        pt: 'Trabalho Reagendado',
        es: 'Trabajo Reprogramado',
        ru: 'Работа Перенесена',
        fr: 'Travail Reprogrammé',
        de: 'Auftrag Umgeplant'
    },
    'Plan Rescheduled': {
        pt: 'Plano Reagendado',
        es: 'Plan Reprogramado',
        ru: 'План Перенесен',
        fr: 'Plan Reprogrammé',
        de: 'Plan Umgeplant'
    },
    'Add Credit': {
        pt: 'Adicionar Crédito',
        es: 'Agregar Crédito',
        ru: 'Добавить Кредит',
        fr: 'Ajouter un Crédit',
        de: 'Guthaben Hinzufügen'
    },
    'Credit is used when you complete private jobs.': {
        pt: 'O crédito é usado quando você completa trabalhos privados.',
        es: 'El crédito se usa cuando completa trabajos privados.',
        ru: 'Кредит используется при выполнении частных работ.',
        fr: 'Le crédit est utilisé lorsque vous terminez des travaux privés.',
        de: 'Guthaben wird verwendet, wenn Sie private Aufträge abschließen.'
    },
    'Amount of Credit': {
        pt: 'Quantidade de Crédito',
        es: 'Cantidad de Crédito',
        ru: 'Сумма Кредита',
        fr: 'Montant du Crédit',
        de: 'Höhe des Guthabens'
    },
    'Please enter the amount of credit.': {
        pt: 'Por favor, insira o valor do crédito.',
        es: 'Por favor, ingrese el monto del crédito.',
        ru: 'Пожалуйста, введите сумму кредита.',
        fr: 'Veuillez entrer le montant du crédit.',
        de: 'Bitte geben Sie den Guthabenbetrag ein.'
    },
    'Add Credit for ': {
        pt: 'Adicionar Crédito para ',
        es: 'Agregar Crédito para ',
        ru: 'Добавить Кредит для ',
        fr: 'Ajouter un Crédit pour ',
        de: 'Guthaben Hinzufügen für '
    },
    'Credit Added': {
        pt: 'Crédito Adicionado',
        es: 'Crédito Agregado',
        ru: 'Кредит Добавлен',
        fr: 'Crédit Ajouté',
        de: 'Guthaben Hinzugefügt'
    },
    'Billing History': {
        pt: 'Histórico de Cobrança',
        es: 'Historial de Facturación',
        ru: 'История Платежей',
        fr: 'Historique de Facturation',
        de: 'Abrechnungsverlauf'
    },
    'Viewing billing history for ': {
        pt: 'Visualizando histórico de cobrança para ',
        es: 'Viendo historial de facturación para ',
        ru: 'Просмотр истории платежей для ',
        fr: 'Affichage de l\'historique de facturation pour ',
        de: 'Abrechnungsverlauf anzeigen für '
    },
    'left of ': {
        pt: 'restante de ',
        es: 'restante de ',
        ru: 'осталось от ',
        fr: 'reste de ',
        de: 'übrig von '
    },
    'Void': {
        pt: 'Anular',
        es: 'Anular',
        ru: 'Аннулировать',
        fr: 'Annuler',
        de: 'Ungültig'
    },
    '(Invoice Sent)': {
        pt: '(Fatura Enviada)',
        es: '(Factura Enviada)',
        ru: '(Счет Отправлен)',
        fr: '(Facture Envoyée)',
        de: '(Rechnung Gesendet)'
    },
    '(Not Complete)': {
        pt: '(Não Concluído)',
        es: '(No Completado)',
        ru: '(Не Завершено)',
        fr: '(Non Terminé)',
        de: '(Nicht Abgeschlossen)'
    },
    'Payments & Credits': {
        pt: 'Pagamentos e Créditos',
        es: 'Pagos y Créditos',
        ru: 'Платежи и Кредиты',
        fr: 'Paiements et Crédits',
        de: 'Zahlungen & Guthaben'
    },
    'No History': {
        pt: 'Sem Histórico',
        es: 'Sin Historial',
        ru: 'Нет Истории',
        fr: 'Aucun Historique',
        de: 'Keine Historie'
    },
    'used by job on ': {
        pt: 'usado pelo trabalho em ',
        es: 'usado por el trabajo el ',
        ru: 'использовано для работы ',
        fr: 'utilisé par le travail le ',
        de: 'verwendet für Auftrag am '
    },
    'refunded': {
        pt: 'reembolsado',
        es: 'reembolsado',
        ru: 'возвращено',
        fr: 'remboursé',
        de: 'erstattet'
    },
    'Refund Charge': {
        pt: 'Reembolsar Cobrança',
        es: 'Reembolsar Cargo',
        ru: 'Возврат Платежа',
        fr: 'Rembourser les Frais',
        de: 'Gebühr Erstatten'
    },

    'Viewing billing options for ': {
        pt: 'Visualizando opções de faturamento para ',
        es: 'Viendo opciones de facturación para ',
        ru: 'Просмотр параметров оплаты для ',
        fr: 'Affichage des options de facturation pour ',
        de: 'Abrechnungsoptionen anzeigen für '
    },
    'Invoice History': {
        pt: 'Histórico de Faturas',
        es: 'Historial de Facturas',
        ru: 'История Счетов',
        fr: 'Historique des Factures',
        de: 'Rechnungsverlauf'
    },
    'Viewing invoice history for ': {
        pt: 'Visualizando histórico de faturas para ',
        es: 'Viendo historial de facturas para ',
        ru: 'Просмотр истории счетов для ',
        fr: 'Affichage de l\'historique des factures pour ',
        de: 'Rechnungsverlauf anzeigen für '
    },
    'Pending Invoices': {
        pt: 'Faturas Pendentes',
        es: 'Facturas Pendientes',
        ru: 'Ожидающие Счета',
        fr: 'Factures en Attente',
        de: 'Ausstehende Rechnungen'
    },
    'No pending invoices': {
        pt: 'Sem faturas pendentes',
        es: 'Sin facturas pendientes',
        ru: 'Нет ожидающих счетов',
        fr: 'Aucune facture en attente',
        de: 'Keine ausstehenden Rechnungen'
    },
    'Sent: ': {
        pt: 'Enviado: ',
        es: 'Enviado: ',
        ru: 'Отправлено: ',
        fr: 'Envoyé: ',
        de: 'Gesendet: '
    },
    'To: ': {
        pt: 'Para: ',
        es: 'Para: ',
        ru: 'Кому: ',
        fr: 'À: ',
        de: 'An: '
    },
    'Email: ': {
        pt: 'Email: ',
        es: 'Correo: ',
        ru: 'Email: ',
        fr: 'Email: ',
        de: 'E-Mail: '
    },
    'Show More': {
        pt: 'Mostrar Mais',
        es: 'Mostrar Más',
        ru: 'Показать Больше',
        fr: 'Afficher Plus',
        de: 'Mehr Anzeigen'
    },
    'Paid Invoices': {
        pt: 'Faturas Pagas',
        es: 'Facturas Pagadas',
        ru: 'Оплаченные Счета',
        fr: 'Factures Payées',
        de: 'Bezahlte Rechnungen'
    },
    'No paid invoices': {
        pt: 'Sem faturas pagas',
        es: 'Sin facturas pagadas',
        ru: 'Нет оплаченных счетов',
        fr: 'Aucune facture payée',
        de: 'Keine bezahlten Rechnungen'
    },
    'Record Payment': {
        pt: 'Registrar Pagamento',
        es: 'Registrar Pago',
        ru: 'Записать Платёж',
        fr: 'Enregistrer le Paiement',
        de: 'Zahlung Erfassen'
    },
    'Payment Amount': {
        pt: 'Valor do Pagamento',
        es: 'Monto del Pago',
        ru: 'Сумма Платежа',
        fr: 'Montant du Paiement',
        de: 'Zahlungsbetrag'
    },
    'Please enter the payment amount.': {
        pt: 'Por favor, insira o valor do pagamento.',
        es: 'Por favor, ingrese el monto del pago.',
        ru: 'Пожалуйста, введите сумму платежа.',
        fr: 'Veuillez saisir le montant du paiement.',
        de: 'Bitte geben Sie den Zahlungsbetrag ein.'
    },

    'Refunding a charge back to ': {
        pt: 'Reembolsando de volta para ',
        es: 'Reembolsando de vuelta a ',
        ru: 'Возврат платежа на ',
        fr: 'Remboursement sur ',
        de: 'Rückerstattung an '
    },
    '\'s Card.': {
        pt: 'o cartão.',
        es: 'la tarjeta.',
        ru: 'карту.',
        fr: 'la carte.',
        de: 'die Karte.'
    },
    'Amount to refund (up to ': {
        pt: 'Valor a reembolsar (até ',
        es: 'Cantidad a reembolsar (hasta ',
        ru: 'Сумма возврата (до ',
        fr: 'Montant à rembourser (jusqu\'à ',
        de: 'Rückerstattungsbetrag (bis zu '
    },
    'Please enter the amount to refund.': {
        pt: 'Por favor, insira o valor a reembolsar.',
        es: 'Por favor, ingrese el monto a reembolsar.',
        ru: 'Пожалуйста, введите сумму возврата.',
        fr: 'Veuillez saisir le montant à rembourser.',
        de: 'Bitte geben Sie den Rückerstattungsbetrag ein.'
    },
    'Why are you refunding?': {
        pt: 'Por que você está reembolsando?',
        es: '¿Por qué está reembolsando?',
        ru: 'Почему вы делаете возврат?',
        fr: 'Pourquoi effectuez-vous un remboursement?',
        de: 'Warum erstatten Sie zurück?'
    },
    'Please enter the reason.': {
        pt: 'Por favor, insira o motivo.',
        es: 'Por favor, ingrese el motivo.',
        ru: 'Пожалуйста, укажите причину.',
        fr: 'Veuillez saisir la raison.',
        de: 'Bitte geben Sie den Grund ein.'
    },
    'Submit Refund': {
        pt: 'Enviar Reembolso',
        es: 'Enviar Reembolso',
        ru: 'Отправить Возврат',
        fr: 'Soumettre le Remboursement',
        de: 'Rückerstattung Einreichen'
    },
    'Charge Refunded': {
        pt: 'Cobrança Reembolsada',
        es: 'Cargo Reembolsado',
        ru: 'Платёж Возвращен',
        fr: 'Paiement Remboursé',
        de: 'Zahlung Erstattet'
    },
    'You can only refund up to': {
        pt: 'Você só pode reembolsar até',
        es: 'Solo puede reembolsar hasta',
        ru: 'Вы можете вернуть только до',
        fr: 'Vous ne pouvez rembourser que jusqu\'à',
        de: 'Sie können nur bis zu erstatten'
    },
    'Invoice Pending': {
        pt: 'Fatura Pendente',
        es: 'Factura Pendiente',
        ru: 'Ожидающий Счет',
        fr: 'Facture en Attente',
        de: 'Rechnung Ausstehend'
    },
    ' with ': {
        pt: ' com ',
        es: ' con ',
        ru: ' с ',
        fr: ' avec ',
        de: ' mit '
    },
    ' unpaid job': {
        pt: ' trabalho não pago',
        es: ' trabajo sin pagar',
        ru: ' неоплаченная работа',
        fr: ' travail non payé',
        de: ' unbezahlte Arbeit'
    },
    'You already sent an invoice for ': {
        pt: 'Você já enviou uma fatura para ',
        es: 'Ya envió una factura para ',
        ru: 'Вы уже отправили счет за ',
        fr: 'Vous avez déjà envoyé une facture pour ',
        de: 'Sie haben bereits eine Rechnung gesendet für '
    },
    'Select which job(s) to send invoice:': {
        pt: 'Selecione qual(is) trabalho(s) para enviar fatura:',
        es: 'Seleccione qué trabajo(s) para enviar factura:',
        ru: 'Выберите работу(ы) для отправки счета:',
        fr: 'Sélectionnez quel(s) travail(travaux) pour envoyer la facture:',
        de: 'Wählen Sie welche Arbeit(en) für die Rechnungsstellung:'
    },
    'Don\'t see the job? You can only request for completed unpaid jobs. ': {
        pt: 'Não vê o trabalho? Você só pode solicitar trabalhos concluídos não pagos. ',
        es: '¿No ve el trabajo? Solo puede solicitar trabajos completados sin pagar. ',
        ru: 'Не видите работу? Вы можете запрашивать только завершенные неоплаченные работы. ',
        fr: 'Vous ne voyez pas le travail? Vous ne pouvez demander que pour les travaux terminés non payés. ',
        de: 'Sehen Sie die Arbeit nicht? Sie können nur für abgeschlossene unbezahlte Arbeiten anfordern. '
    },
    'Add job': {
        pt: 'Adicionar trabalho',
        es: 'Agregar trabajo',
        ru: 'Добавить работу',
        fr: 'Ajouter un travail',
        de: 'Arbeit hinzufügen'
    },
    'collect deposit': {
        pt: 'coletar depósito',
        es: 'cobrar depósito',
        ru: 'собрать депозит',
        fr: 'collecter un dépôt',
        de: 'Anzahlung einsammeln'
    },
    ' for a future job.': {
        pt: ' para um trabalho futuro.',
        es: ' para un trabajo futuro.',
        ru: ' для будущей работы.',
        fr: ' pour un travail futur.',
        de: ' für eine zukünftige Arbeit.'
    },
    'Preview Invoice': {
        pt: 'Visualizar Fatura',
        es: 'Vista Previa de Factura',
        ru: 'Предпросмотр Счета',
        fr: 'Aperçu de la Facture',
        de: 'Rechnung Vorschau'
    },
    'Steps to Request Payment': {
        pt: 'Passos para Solicitar Pagamento',
        es: 'Pasos para Solicitar Pago',
        ru: 'Шаги для Запроса Оплаты',
        fr: 'Étapes pour Demander le Paiement',
        de: 'Schritte zur Zahlungsanforderung'
    },
    'Add a Job': {
        pt: 'Adicionar um Trabalho',
        es: 'Agregar un Trabajo',
        ru: 'Добавить Работу',
        fr: 'Ajouter un Travail',
        de: 'Eine Arbeit Hinzufügen'
    },
    'Or ': {
        pt: 'Ou ',
        es: 'O ',
        ru: 'Или ',
        fr: 'Ou ',
        de: 'Oder '
    },
    'Pick a job you need payment for.': {
        pt: 'Escolha um trabalho que precisa de pagamento.',
        es: 'Elija un trabajo que necesita pago.',
        ru: 'Выберите работу, за которую нужна оплата.',
        fr: 'Choisissez un travail pour lequel vous avez besoin de paiement.',
        de: 'Wählen Sie eine Arbeit aus, für die Sie eine Zahlung benötigen.'
    },
    'Send your Client an invoice by email, by SMS, or by link.': {
        pt: 'Envie uma fatura ao seu Cliente por email, SMS ou link.',
        es: 'Envíe una factura a su Cliente por correo electrónico, SMS o enlace.',
        ru: 'Отправьте счет вашему Клиенту по электронной почте, SMS или ссылке.',
        fr: 'Envoyez une facture à votre Client par email, SMS ou lien.',
        de: 'Senden Sie Ihrem Kunden eine Rechnung per E-Mail, SMS oder Link.'
    },
    'Accept credit cards for faster payments & easy accounting.': {
        pt: 'Aceite cartões de crédito para pagamentos mais rápidos e contabilidade fácil.',
        es: 'Acepte tarjetas de crédito para pagos más rápidos y contabilidad fácil.',
        ru: 'Принимайте кредитные карты для быстрых платежей и простого учета.',
        fr: 'Acceptez les cartes de crédit pour des paiements plus rapides et une comptabilité facile.',
        de: 'Akzeptieren Sie Kreditkarten für schnellere Zahlungen und einfache Buchhaltung.'
    },
    'Please select job(s) to request.': {
        pt: 'Por favor, selecione trabalho(s) para solicitar.',
        es: 'Por favor, seleccione trabajo(s) para solicitar.',
        ru: 'Пожалуйста, выберите работу(ы) для запроса.',
        fr: 'Veuillez sélectionner le(s) travail(travaux) à demander.',
        de: 'Bitte wählen Sie Arbeit(en) zum Anfordern aus.'
    },
    

    'Complete Job?': {
        pt: 'Completar Trabalho?',
        es: '¿Completar Trabajo?',
        ru: 'Завершить Работу?',
        fr: 'Terminer le Travail?',
        de: 'Arbeit Abschließen?'
    },
    'Job Details': {
        pt: 'Detalhes do Trabalho',
        es: 'Detalles del Trabajo',
        ru: 'Детали Работы',
        fr: 'Détails du Travail',
        de: 'Arbeitsdetails'
    },
    'Please enter a price.': {
        pt: 'Por favor, insira um preço.',
        es: 'Por favor, ingrese un precio.',
        ru: 'Пожалуйста, введите цену.',
        fr: 'Veuillez entrer un prix.',
        de: 'Bitte geben Sie einen Preis ein.'
    },
    'will receive an email with a link.': {
        pt: 'receberá um email com um link.',
        es: 'recibirá un correo electrónico con un enlace.',
        ru: 'получит электронное письмо со ссылкой.',
        fr: 'recevra un email avec un lien.',
        de: 'wird eine E-Mail mit einem Link erhalten.'
    },
    'Copy Link': {
        pt: 'Copiar Link',
        es: 'Copiar Enlace',
        ru: 'Копировать Ссылку',
        fr: 'Copier le Lien',
        de: 'Link Kopieren'
    },
    'Do you want to send an invoice?': {
        pt: 'Deseja enviar uma fatura?',
        es: '¿Desea enviar una factura?',
        ru: 'Хотите отправить счет?',
        fr: 'Voulez-vous envoyer une facture?',
        de: 'Möchten Sie eine Rechnung senden?'
    },
    'To:': {
        pt: 'Para:',
        es: 'Para:',
        ru: 'Кому:',
        fr: 'À:',
        de: 'An:'
    },
    'Sent': {
        pt: 'Enviado',
        es: 'Enviado',
        ru: 'Отправлено',
        fr: 'Envoyé',
        de: 'Gesendet'
    },
    'Owed': {
        pt: 'Devido',
        es: 'Adeudado',
        ru: 'Задолженность',
        fr: 'Dû',
        de: 'Geschuldet'
    },
    'Job Duration:': {
        pt: 'Duração do Trabalho:',
        es: 'Duración del Trabajo:',
        ru: 'Продолжительность Работы:',
        fr: 'Durée du Travail:',
        de: 'Arbeitsdauer:'
    },
    'hour': {
        pt: 'hora',
        es: 'hora',
        ru: 'час',
        fr: 'heure',
        de: 'Stunde'
    },
    'hours': {
        pt: 'horas',
        es: 'horas',
        ru: 'часов',
        fr: 'heures',
        de: 'Stunden'
    },
    'minute': {
        pt: 'minuto',
        es: 'minuto',
        ru: 'минута',
        fr: 'minute',
        de: 'Minute'
    },
    'minutes': {
        pt: 'minutos',
        es: 'minutos',
        ru: 'минут',
        fr: 'minutes',
        de: 'Minuten'
    },
    'Credit Applied': {
        pt: 'Crédito Aplicado',
        es: 'Crédito Aplicado',
        ru: 'Применен Кредит',
        fr: 'Crédit Appliqué',
        de: 'Gutschrift Angewendet'
    },
    'Total': {
        pt: 'Total',
        es: 'Total',
        ru: 'Итого',
        fr: 'Total',
        de: 'Gesamt'
    },
    'We thank you for your business!': {
        pt: 'Agradecemos seu negócio!',
        es: '¡Gracias por su negocio!',
        ru: 'Благодарим вас за сотрудничество!',
        fr: 'Nous vous remercions pour votre confiance!',
        de: 'Wir danken Ihnen für Ihr Geschäft!'
    },
    'Invoice Notes': {
        pt: 'Notas da Fatura',
        es: 'Notas de la Factura',
        ru: 'Примечания к Счету',
        fr: 'Notes de Facture',
        de: 'Rechnungsnotizen'
    },
    'Add Internal Note': {
        pt: 'Adicionar Nota Interna',
        es: 'Agregar Nota Interna',
        ru: 'Добавить Внутреннюю Заметку',
        fr: 'Ajouter une Note Interne',
        de: 'Interne Notiz Hinzufügen'
    },
    'Add Note': {
        pt: 'Adicionar Nota',
        es: 'Agregar Nota',
        ru: 'Добавить Заметку',
        fr: 'Ajouter une Note',
        de: 'Notiz Hinzufügen'
    },
    'No Invoice Notes': {
        pt: 'Sem Notas de Fatura',
        es: 'Sin Notas de Factura',
        ru: 'Нет Примечаний к Счету',
        fr: 'Pas de Notes de Facture',
        de: 'Keine Rechnungsnotizen'
    },
    'Send Invoice to': {
        pt: 'Enviar Fatura para',
        es: 'Enviar Factura a',
        ru: 'Отправить Счет',
        fr: 'Envoyer la Facture à',
        de: 'Rechnung Senden an'
    },
    'Client\'s Email': {
        pt: 'Email do Cliente',
        es: 'Correo del Cliente',
        ru: 'Email Клиента',
        fr: 'Email du Client',
        de: 'E-Mail des Kunden'
    },
    'Please enter a valid email.': {
        pt: 'Por favor, insira um email válido.',
        es: 'Por favor, ingrese un correo válido.',
        ru: 'Пожалуйста, введите действительный email.',
        fr: 'Veuillez entrer un email valide.',
        de: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.'
    },
    'Internal note': {
        pt: 'Nota interna',
        es: 'Nota interna',
        ru: 'Внутренняя заметка',
        fr: 'Note interne',
        de: 'Interne Notiz'
    },
    'Public note': {
        pt: 'Nota pública',
        es: 'Nota pública',
        ru: 'Публичная заметка',
        fr: 'Note publique',
        de: 'Öffentliche Notiz'
    },
    'received an email at': {
        pt: 'recebeu um email em',
        es: 'recibió un correo en',
        ru: 'получил email на',
        fr: 'a reçu un email à',
        de: 'hat eine E-Mail erhalten an'
    },
    'Complete Job & Send Invoice': {
        pt: 'Completar Trabalho e Enviar Fatura',
        es: 'Completar Trabajo y Enviar Factura',
        ru: 'Завершить Работу и Отправить Счет',
        fr: 'Terminer le Travail et Envoyer la Facture',
        de: 'Arbeit Abschließen und Rechnung Senden'
    },
    'Send Invoice': {
        pt: 'Enviar Fatura',
        es: 'Enviar Factura',
        ru: 'Отправить Счет',
        fr: 'Envoyer la Facture',
        de: 'Rechnung Senden'
    },

    'Sent Invoice': {
        pt: 'Fatura Enviada',
        es: 'Factura Enviada',
        ru: 'Отправленный Счет',
        fr: 'Facture Envoyée',
        de: 'Gesendete Rechnung'
    },
    'Invoice From': {
        pt: 'Fatura De',
        es: 'Factura De',
        ru: 'Счет От',
        fr: 'Facture De',
        de: 'Rechnung Von'
    },
    
    'Clients and TIDY Concierge will use this info to communicate with you.': {
        pt: 'Os clientes e o Concierge TIDY usarão essas informações para se comunicar com você.',
        es: 'Los clientes y el Concierge TIDY usarán esta información para comunicarse con usted.',
        ru: 'Клиенты и Консьерж TIDY будут использовать эту информацию для связи с вами.',
        fr: 'Les clients et le Concierge TIDY utiliseront ces informations pour communiquer avec vous.',
        de: 'Kunden und TIDY Concierge werden diese Informationen nutzen, um mit Ihnen zu kommunizieren.'
    },
    'Name': {
        pt: 'Nome',
        es: 'Nombre',
        ru: 'Имя',
        fr: 'Nom',
        de: 'Name'
    },
    'Change Email': {
        pt: 'Alterar Email',
        es: 'Cambiar Correo',
        ru: 'Изменить Email',
        fr: 'Changer l\'Email',
        de: 'E-Mail Ändern'
    },
    'What is your new email?': {
        pt: 'Qual é seu novo email?',
        es: '¿Cuál es su nuevo correo electrónico?',
        ru: 'Какой ваш новый email?',
        fr: 'Quelle est votre nouvelle adresse email?',
        de: 'Wie lautet Ihre neue E-Mail-Adresse?'
    },
    'Please enter a valid Email': {
        pt: 'Por favor, insira um email válido',
        es: 'Por favor, ingrese un correo válido',
        ru: 'Пожалуйста, введите действительный email',
        fr: 'Veuillez entrer une adresse email valide',
        de: 'Bitte geben Sie eine gültige E-Mail-Adresse ein'
    },
    'Send Verification Email': {
        pt: 'Enviar Email de Verificação',
        es: 'Enviar Correo de Verificación',
        ru: 'Отправить Email для Подтверждения',
        fr: 'Envoyer un Email de Vérification',
        de: 'Bestätigungs-E-Mail Senden'
    },
    'Check your Email for a verification link.': {
        pt: 'Verifique seu email para um link de verificação.',
        es: 'Revise su correo para un enlace de verificación.',
        ru: 'Проверьте свою почту на наличие ссылки для подтверждения.',
        fr: 'Vérifiez votre email pour un lien de vérification.',
        de: 'Überprüfen Sie Ihre E-Mail auf einen Bestätigungslink.'
    },
    'Confirmation Email Sent': {
        pt: 'Email de Confirmação Enviado',
        es: 'Correo de Confirmación Enviado',
        ru: 'Письмо с Подтверждением Отправлено',
        fr: 'Email de Confirmation Envoyé',
        de: 'Bestätigungs-E-Mail Gesendet'
    },
    'Check your email for a verification link': {
        pt: 'Verifique seu email para um link de verificação',
        es: 'Revise su correo para un enlace de verificación',
        ru: 'Проверьте свою почту на наличие ссылки для подтверждения',
        fr: 'Vérifiez votre email pour un lien de vérification',
        de: 'Überprüfen Sie Ihre E-Mail auf einen Bestätigungslink'
    },

    'Note: Your work area is based off this address, so updating your address will change your work area. Do not use a PO Box. Your physical location is used to pair you with clients, and changing to a PO Box will cause you to lose 100% of clients and be un-bookable.': {
        pt: 'Nota: Sua área de trabalho é baseada neste endereço, então atualizar seu endereço mudará sua área de trabalho. Não use uma caixa postal. Sua localização física é usada para conectá-lo com clientes, e mudar para uma caixa postal fará com que você perca 100% dos clientes e fique impossibilitado de receber reservas.',
        es: 'Nota: Su área de trabajo se basa en esta dirección, por lo que actualizar su dirección cambiará su área de trabajo. No use un apartado postal. Su ubicación física se utiliza para emparejarlo con clientes, y cambiar a un apartado postal hará que pierda el 100% de los clientes y no pueda recibir reservas.',
        ru: 'Примечание: Ваша рабочая зона основана на этом адресе, поэтому обновление адреса изменит вашу рабочую зону. Не используйте абонентский ящик. Ваше физическое местоположение используется для подбора клиентов, и переход на абонентский ящик приведет к потере 100% клиентов и невозможности бронирования.',
        fr: 'Note : Votre zone de travail est basée sur cette adresse, donc la mise à jour de votre adresse changera votre zone de travail. N\'utilisez pas de boîte postale. Votre emplacement physique est utilisé pour vous mettre en relation avec des clients, et passer à une boîte postale vous fera perdre 100% des clients et vous empêchera d\'être réservé.',
        de: 'Hinweis: Ihr Arbeitsbereich basiert auf dieser Adresse, daher ändert sich Ihr Arbeitsbereich bei Aktualisierung Ihrer Adresse. Verwenden Sie kein Postfach. Ihr physischer Standort wird verwendet, um Sie mit Kunden zu verbinden, und der Wechsel zu einem Postfach führt dazu, dass Sie 100% der Kunden verlieren und nicht buchbar sind.'
    },
    'Confirm Change': {
        pt: 'Confirmar Alteração',
        es: 'Confirmar Cambio',
        ru: 'Подтвердить Изменение',
        fr: 'Confirmer le Changement',
        de: 'Änderung Bestätigen'
    },
    'Unfortunately you must have a physical address in order to accurately determine your service area, and PO boxes cannot be used. Clients do not see your address. Please add a different address.': {
        pt: 'Infelizmente, você deve ter um endereço físico para determinar com precisão sua área de serviço, e caixas postais não podem ser usadas. Os clientes não veem seu endereço. Por favor, adicione um endereço diferente.',
        es: 'Desafortunadamente, debe tener una dirección física para determinar con precisión su área de servicio, y no se pueden usar apartados postales. Los clientes no ven su dirección. Por favor, agregue una dirección diferente.',
        ru: 'К сожалению, у вас должен быть физический адрес, чтобы точно определить вашу зону обслуживания, и абонентские ящики использовать нельзя. Клиенты не видят ваш адрес. Пожалуйста, добавьте другой адрес.',
        fr: 'Malheureusement, vous devez avoir une adresse physique pour déterminer avec précision votre zone de service, et les boîtes postales ne peuvent pas être utilisées. Les clients ne voient pas votre adresse. Veuillez ajouter une adresse différente.',
        de: 'Leider müssen Sie eine physische Adresse haben, um Ihr Servicegebiet genau bestimmen zu können, und Postfächer können nicht verwendet werden. Kunden sehen Ihre Adresse nicht. Bitte fügen Sie eine andere Adresse hinzu.'
    },
    'Change Address': {
        pt: 'Alterar Endereço',
        es: 'Cambiar Dirección',
        ru: 'Изменить Адрес',
        fr: 'Changer d\'Adresse',
        de: 'Adresse Ändern'
    },
    'Change address to': {
        pt: 'Alterar endereço para',
        es: 'Cambiar dirección a',
        ru: 'Изменить адрес на',
        fr: 'Changer l\'adresse pour',
        de: 'Adresse ändern zu'
    },
    'Your work area will now change.': {
        pt: 'Sua área de trabalho agora mudará.',
        es: 'Su área de trabajo cambiará ahora.',
        ru: 'Ваша рабочая зона теперь изменится.',
        fr: 'Votre zone de travail va maintenant changer.',
        de: 'Ihr Arbeitsbereich wird sich jetzt ändern.'
    },
    'Address Updated': {
        pt: 'Endereço Atualizado',
        es: 'Dirección Actualizada',
        ru: 'Адрес Обновлен',
        fr: 'Adresse Mise à Jour',
        de: 'Adresse Aktualisiert'
    },
    'Your Address has been updated for all jobs': {
        pt: 'Seu endereço foi atualizado para todos os trabalhos',
        es: 'Su dirección ha sido actualizada para todos los trabajos',
        ru: 'Ваш адрес был обновлен для всех работ',
        fr: 'Votre adresse a été mise à jour pour tous les travaux',
        de: 'Ihre Adresse wurde für alle Aufträge aktualisiert'
    },
    'By changing your address, you will immediately cancel all these clients who are outside your new address area. You may not be able to get clients back.': {
        pt: 'Ao alterar seu endereço, você cancelará imediatamente todos esses clientes que estão fora da sua nova área de endereço. Você pode não conseguir recuperar os clientes.',
        es: 'Al cambiar su dirección, cancelará inmediatamente todos estos clientes que están fuera de su nueva área de dirección. Es posible que no pueda recuperar los clientes.',
        ru: 'Изменив свой адрес, вы немедленно отмените всех этих клиентов, которые находятся за пределами вашей новой области адреса. Возможно, вы не сможете вернуть клиентов.',
        fr: 'En changeant votre adresse, vous annulerez immédiatement tous ces clients qui sont en dehors de votre nouvelle zone d\'adresse. Vous ne pourrez peut-être pas récupérer les clients.',
        de: 'Durch die Änderung Ihrer Adresse werden alle diese Kunden, die außerhalb Ihres neuen Adressbereichs liegen, sofort storniert. Sie können die Kunden möglicherweise nicht zurückgewinnen.'
    },

    'Business Location': {
        pt: 'Localização do Negócio',
        es: 'Ubicación del Negocio',
        ru: 'Местоположение Бизнеса',
        fr: 'Emplacement de l\'Entreprise',
        de: 'Geschäftsstandort'
    },
    'The location you serve is based on your address plus your max estimated driving distance.': {
        pt: 'O local que você atende é baseado em seu endereço mais sua distância máxima estimada de condução.',
        es: 'La ubicación que atiende se basa en su dirección más su distancia máxima estimada de conducción.',
        ru: 'Обслуживаемое место основано на вашем адресе плюс максимальное расчетное расстояние поездки.',
        fr: 'L\'emplacement que vous desservez est basé sur votre adresse plus votre distance de conduite maximale estimée.',
        de: 'Der von Ihnen bediente Standort basiert auf Ihrer Adresse plus Ihrer maximalen geschätzten Fahrzeit.'
    },
    'Max Drive Time': {
        pt: 'Tempo Máximo de Condução',
        es: 'Tiempo Máximo de Conducción',
        ru: 'Максимальное Время в Пути',
        fr: 'Temps de Conduite Maximum',
        de: 'Maximale Fahrzeit'
    },
    'Max Walk Time': {
        pt: 'Tempo Máximo de Caminhada',
        es: 'Tiempo Máximo de Caminata',
        ru: 'Максимальное Время Ходьбы',
        fr: 'Temps de Marche Maximum',
        de: 'Maximale Gehzeit'
    },
    'Block Zipcodes': {
        pt: 'Bloquear CEPs',
        es: 'Bloquear Códigos Postales',
        ru: 'Заблокировать Почтовые Индексы',
        fr: 'Bloquer les Codes Postaux',
        de: 'Postleitzahlen Sperren'
    },
    'Add a zipcode to prevent jobs in that zipcode.': {
        pt: 'Adicione um CEP para impedir trabalhos nesse CEP.',
        es: 'Agregue un código postal para evitar trabajos en ese código postal.',
        ru: 'Добавьте почтовый индекс, чтобы предотвратить работу в этом индексе.',
        fr: 'Ajoutez un code postal pour empêcher les travaux dans ce code postal.',
        de: 'Fügen Sie eine Postleitzahl hinzu, um Aufträge in dieser Postleitzahl zu verhindern.'
    },
    'No zipcodes blocked.': {
        pt: 'Nenhum CEP bloqueado.',
        es: 'No hay códigos postales bloqueados.',
        ru: 'Нет заблокированных почтовых индексов.',
        fr: 'Aucun code postal bloqué.',
        de: 'Keine Postleitzahlen gesperrt.'
    },
    'Block New Zipcode': {
        pt: 'Bloquear Novo CEP',
        es: 'Bloquear Nuevo Código Postal',
        ru: 'Заблокировать Новый Почтовый Индекс',
        fr: 'Bloquer Nouveau Code Postal',
        de: 'Neue Postleitzahl Sperren'
    },
    'Approximate Area You Serve': {
        pt: 'Área Aproximada que Você Atende',
        es: 'Área Aproximada que Usted Atiende',
        ru: 'Примерная Область Обслуживания',
        fr: 'Zone Approximative que Vous Desservez',
        de: 'Ungefähres Gebiet, das Sie Bedienen'
    },
    'Note this map is not 100% accurate, but gives a general area.': {
        pt: 'Observe que este mapa não é 100% preciso, mas fornece uma área geral.',
        es: 'Tenga en cuenta que este mapa no es 100% preciso, pero proporciona un área general.',
        ru: 'Обратите внимание, что эта карта не является 100% точной, но дает общее представление о территории.',
        fr: 'Notez que cette carte n\'est pas précise à 100%, mais donne une zone générale.',
        de: 'Beachten Sie, dass diese Karte nicht 100% genau ist, aber einen allgemeinen Bereich angibt.'
    },
    'Allow Jobs in Zipcode': {
        pt: 'Permitir Trabalhos no CEP',
        es: 'Permitir Trabajos en Código Postal',
        ru: 'Разрешить Работы по Почтовому Индексу',
        fr: 'Autoriser les Travaux dans le Code Postal',
        de: 'Aufträge in Postleitzahl Erlauben'
    },
    'Are you sure you want to serve the zipcode': {
        pt: 'Tem certeza que deseja atender o CEP',
        es: '¿Está seguro de que desea atender el código postal',
        ru: 'Вы уверены, что хотите обслуживать почтовый индекс',
        fr: 'Êtes-vous sûr de vouloir desservir le code postal',
        de: 'Sind Sie sicher, dass Sie die Postleitzahl bedienen möchten'
    },
    'Allow': {
        pt: 'Permitir',
        es: 'Permitir',
        ru: 'Разрешить',
        fr: 'Autoriser',
        de: 'Erlauben'
    },
    'Invalid zipcode': {
        pt: 'CEP inválido',
        es: 'Código postal inválido',
        ru: 'Неверный почтовый индекс',
        fr: 'Code postal invalide',
        de: 'Ungültige Postleitzahl'
    },
    'Cancel current jobs in this zipcode': {
        pt: 'Cancelar trabalhos atuais neste CEP',
        es: 'Cancelar trabajos actuales en este código postal',
        ru: 'Отменить текущие работы в этом почтовом индексе',
        fr: 'Annuler les travaux en cours dans ce code postal',
        de: 'Aktuelle Aufträge in dieser Postleitzahl stornieren'
    },
    'You can also leave it unchecked to keep your scheduled jobs within the zipcode area': {
        pt: 'Você também pode deixar desmarcado para manter seus trabalhos agendados dentro da área do CEP',
        es: 'También puede dejarlo sin marcar para mantener sus trabajos programados dentro del área del código postal',
        ru: 'Вы также можете оставить его неотмеченным, чтобы сохранить запланированные работы в пределах почтового индекса',
        fr: 'Vous pouvez également le laisser décoché pour conserver vos travaux programmés dans la zone du code postal',
        de: 'Sie können es auch unmarkiert lassen, um Ihre geplanten Aufträge im Postleitzahlenbereich zu behalten'
    },
    'Block This Zipcode': {
        pt: 'Bloquear Este CEP',
        es: 'Bloquear Este Código Postal',
        ru: 'Заблокировать Этот Почтовый Индекс',
        fr: 'Bloquer Ce Code Postal',
        de: 'Diese Postleitzahl Sperren'
    },
    'Blocked': {
        pt: 'Bloqueado',
        es: 'Bloqueado',
        ru: 'Заблокировано',
        fr: 'Bloqué',
        de: 'Gesperrt'
    },
    'Clients can no longer book jobs in this zipcode.': {
        pt: 'Os clientes não podem mais agendar trabalhos neste CEP.',
        es: 'Los clientes ya no pueden reservar trabajos en este código postal.',
        ru: 'Клиенты больше не могут бронировать работы в этом почтовом индексе.',
        fr: 'Les clients ne peuvent plus réserver de travaux dans ce code postal.',
        de: 'Kunden können keine Aufträge mehr in dieser Postleitzahl buchen.'
    },

    ' minutes from that address.': {
        pt: ' minutos deste endereço.',
        es: ' minutos desde esa dirección.',
        ru: ' минут от этого адреса.',
        fr: ' minutes de cette adresse.',
        de: ' Minuten von dieser Adresse entfernt.'
    },
    'You noted that you will travel by public transit to the above address and that you can walk ': {
        pt: 'Você indicou que viajará de transporte público para o endereço acima e que pode caminhar ',
        es: 'Usted indicó que viajará en transporte público a la dirección anterior y que puede caminar ',
        ru: 'Вы отметили, что будете добираться общественным транспортом до указанного адреса и сможете пройти пешком ',
        fr: 'Vous avez indiqué que vous vous rendrez en transport en commun à l\'adresse ci-dessus et que vous pouvez marcher ',
        de: 'Sie haben angegeben, dass Sie mit öffentlichen Verkehrsmitteln zur oben genannten Adresse fahren und zu Fuß gehen können '
    },

    'Please enter a valid code': {
        pt: 'Por favor, insira um código válido',
        es: 'Por favor, introduzca un código válido',
        ru: 'Пожалуйста, введите действительный код',
        fr: 'Veuillez entrer un code valide',
        de: 'Bitte geben Sie einen gültigen Code ein'
    },
    'Change Phone Number': {
        pt: 'Alterar Número de Telefone',
        es: 'Cambiar Número de Teléfono',
        ru: 'Изменить Номер Телефона',
        fr: 'Changer le Numéro de Téléphone',
        de: 'Telefonnummer Ändern'
    },
    'What is your new number?': {
        pt: 'Qual é seu novo número?',
        es: '¿Cuál es su nuevo número?',
        ru: 'Какой у вас новый номер?',
        fr: 'Quel est votre nouveau numéro?',
        de: 'Was ist Ihre neue Nummer?'
    },
    'Phone Updated': {
        pt: 'Telefone Atualizado',
        es: 'Teléfono Actualizado',
        ru: 'Телефон Обновлен',
        fr: 'Téléphone Mis à Jour',
        de: 'Telefon Aktualisiert'
    },
    'Your Phone has been updated.': {
        pt: 'Seu telefone foi atualizado.',
        es: 'Su teléfono ha sido actualizado.',
        ru: 'Ваш телефон был обновлен.',
        fr: 'Votre téléphone a été mis à jour.',
        de: 'Ihr Telefon wurde aktualisiert.'
    },

    'Change Max Drive': {
        pt: 'Alterar Tempo Máximo de Direção',
        es: 'Cambiar Tiempo Máximo de Conducción',
        ru: 'Изменить Максимальное Время в Пути',
        fr: 'Modifier le Temps de Trajet Maximum',
        de: 'Maximale Fahrzeit Ändern'
    },
    'Time between jobs is typically 15 minutes. The further you can travel, the more clients you can get.': {
        pt: 'O tempo entre trabalhos é tipicamente 15 minutos. Quanto mais longe você puder viajar, mais clientes poderá ter.',
        es: 'El tiempo entre trabajos es típicamente 15 minutos. Cuanto más lejos pueda viajar, más clientes podrá conseguir.',
        ru: 'Время между работами обычно составляет 15 минут. Чем дальше вы можете ездить, тем больше клиентов вы сможете получить.',
        fr: 'Le temps entre les travaux est généralement de 15 minutes. Plus vous pouvez voyager loin, plus vous pouvez avoir de clients.',
        de: 'Die Zeit zwischen den Aufträgen beträgt typischerweise 15 Minuten. Je weiter Sie reisen können, desto mehr Kunden können Sie bekommen.'
    },
    'How do you plan to travel?': {
        pt: 'Como você planeja viajar?',
        es: '¿Cómo planea viajar?',
        ru: 'Как вы планируете передвигаться?',
        fr: 'Comment prévoyez-vous de voyager?',
        de: 'Wie planen Sie zu reisen?'
    },
    'What is your new max drive time?': {
        pt: 'Qual é seu novo tempo máximo de direção?',
        es: '¿Cuál es su nuevo tiempo máximo de conducción?',
        ru: 'Какое ваше новое максимальное время в пути?',
        fr: 'Quel est votre nouveau temps de trajet maximum?',
        de: 'Was ist Ihre neue maximale Fahrzeit?'
    },
    '(45 minutes is what most people pick)': {
        pt: '(45 minutos é o que a maioria das pessoas escolhe)',
        es: '(45 minutos es lo que la mayoría de la gente elige)',
        ru: '(45 минут - это то, что выбирает большинство людей)',
        fr: '(45 minutes est ce que la plupart des gens choisissent)',
        de: '(45 Minuten ist das, was die meisten Leute wählen)'
    },
    'Please choose a valid value': {
        pt: 'Por favor, escolha um valor válido',
        es: 'Por favor, elija un valor válido',
        ru: 'Пожалуйста, выберите допустимое значение',
        fr: 'Veuillez choisir une valeur valide',
        de: 'Bitte wählen Sie einen gültigen Wert'
    },
    'Pick an address at the center of the area you serve. Then, pick how far you can walk from this address. To remove a specific area, block a zipcode in the next page.': {
        pt: 'Escolha um endereço no centro da área que você atende. Depois, escolha até onde você pode caminhar deste endereço. Para remover uma área específica, bloqueie um CEP na próxima página.',
        es: 'Elija una dirección en el centro del área que sirve. Luego, elija qué tan lejos puede caminar desde esta dirección. Para eliminar un área específica, bloquee un código postal en la siguiente página.',
        ru: 'Выберите адрес в центре обслуживаемой вами территории. Затем выберите, как далеко вы можете пройти от этого адреса. Чтобы удалить определенную область, заблокируйте почтовый индекс на следующей странице.',
        fr: 'Choisissez une adresse au centre de la zone que vous servez. Ensuite, choisissez jusqu\'où vous pouvez marcher depuis cette adresse. Pour supprimer une zone spécifique, bloquez un code postal dans la page suivante.',
        de: 'Wählen Sie eine Adresse im Zentrum Ihres Servicegebiets. Wählen Sie dann, wie weit Sie von dieser Adresse aus gehen können. Um einen bestimmten Bereich zu entfernen, sperren Sie eine Postleitzahl auf der nächsten Seite.'
    },
    'How far from this address can you walk?': {
        pt: 'Qual a distância que você pode caminhar deste endereço?',
        es: '¿Qué tan lejos de esta dirección puede caminar?',
        ru: 'Как далеко от этого адреса вы можете пройти пешком?',
        fr: 'Jusqu\'où pouvez-vous marcher depuis cette adresse?',
        de: 'Wie weit können Sie von dieser Adresse aus gehen?'
    },
    'Please choose an option.': {
        pt: 'Por favor, escolha uma opção.',
        es: 'Por favor, elija una opción.',
        ru: 'Пожалуйста, выберите вариант.',
        fr: 'Veuillez choisir une option.',
        de: 'Bitte wählen Sie eine Option.'
    },
    'Change Max Drive?': {
        pt: 'Alterar Tempo Máximo de Direção?',
        es: '¿Cambiar Tiempo Máximo de Conducción?',
        ru: 'Изменить Максимальное Время в Пути?',
        fr: 'Modifier le Temps de Trajet Maximum?',
        de: 'Maximale Fahrzeit Ändern?'
    },
    'minutes? Your work area will now change.': {
        pt: 'minutos? Sua área de trabalho agora mudará.',
        es: '¿minutos? Su área de trabajo cambiará ahora.',
        ru: 'минут? Ваша рабочая зона теперь изменится.',
        fr: 'minutes? Votre zone de travail va maintenant changer.',
        de: 'Minuten? Ihr Arbeitsbereich wird sich jetzt ändern.'
    },
    'Change max drive time to': {
        pt: 'Alterar tempo máximo de direção para',
        es: 'Cambiar tiempo máximo de conducción a',
        ru: 'Изменить максимальное время в пути на',
        fr: 'Modifier le temps de trajet maximum à',
        de: 'Maximale Fahrzeit ändern auf'
    },
    'Max Drive Updated': {
        pt: 'Tempo Máximo de Direção Atualizado',
        es: 'Tiempo Máximo de Conducción Actualizado',
        ru: 'Максимальное Время в Пути Обновлено',
        fr: 'Temps de Trajet Maximum Mis à Jour',
        de: 'Maximale Fahrzeit Aktualisiert'
    },
    'Your max drive time has been updated.': {
        pt: 'Seu tempo máximo de direção foi atualizado.',
        es: 'Su tiempo máximo de conducción ha sido actualizado.',
        ru: 'Ваше максимальное время в пути было обновлено.',
        fr: 'Votre temps de trajet maximum a été mis à jour.',
        de: 'Ihre maximale Fahrzeit wurde aktualisiert.'
    },
    'Change Address & Time?': {
        pt: 'Alterar Endereço e Tempo?',
        es: '¿Cambiar Dirección y Tiempo?',
        ru: 'Изменить Адрес и Время?',
        fr: 'Modifier l\'Adresse et le Temps?',
        de: 'Adresse und Zeit Ändern?'
    },
    'and max walk time from there to': {
        pt: 'e tempo máximo de caminhada de lá para',
        es: 'y tiempo máximo de caminata desde allí hasta',
        ru: 'и максимальное время ходьбы оттуда до',
        fr: 'et temps de marche maximum de là à',
        de: 'und maximale Gehzeit von dort bis'
    },
    'Max Drive & Address Updated': {
        pt: 'Tempo Máximo de Direção e Endereço Atualizados',
        es: 'Tiempo Máximo de Conducción y Dirección Actualizados',
        ru: 'Максимальное Время в Пути и Адрес Обновлены',
        fr: 'Temps de Trajet Maximum et Adresse Mis à Jour',
        de: 'Maximale Fahrzeit und Adresse Aktualisiert'
    },
    'Your max drive time & address has been updated.': {
        pt: 'Seu tempo máximo de direção e endereço foram atualizados.',
        es: 'Su tiempo máximo de conducción y dirección han sido actualizados.',
        ru: 'Ваше максимальное время в пути и адрес были обновлены.',
        fr: 'Votre temps de trajet maximum et votre adresse ont été mis à jour.',
        de: 'Ihre maximale Fahrzeit und Adresse wurden aktualisiert.'
    },
    'By car (recommended except in very dense areas)': {
        pt: 'De carro (recomendado exceto em áreas muito densas)',
        es: 'En coche (recomendado excepto en áreas muy densas)',
        ru: 'На машине (рекомендуется кроме очень густонаселенных районов)',
        fr: 'En voiture (recommandé sauf dans les zones très denses)',
        de: 'Mit dem Auto (empfohlen außer in sehr dichten Gebieten)'
    },
    'By public transit or walk': {
        pt: 'De transporte público ou a pé',
        es: 'En transporte público o caminando',
        ru: 'На общественном транспорте или пешком',
        fr: 'En transport en commun ou à pied',
        de: 'Mit öffentlichen Verkehrsmitteln oder zu Fuß'
    },
    '3 Minutes': {
        pt: '3 Minutos',
        es: '3 Minutos',
        ru: '3 Минуты',
        fr: '3 Minutes',
        de: '3 Minuten'
    },
    '6 Minutes': {
        pt: '6 Minutos',
        es: '6 Minutos',
        ru: '6 Минут',
        fr: '6 Minutes',
        de: '6 Minuten'
    },
    '9 Minutes': {
        pt: '9 Minutos',
        es: '9 Minutos',
        ru: '9 Минут',
        fr: '9 Minutes',
        de: '9 Minuten'
    },


    'By reducing your max estimated drive time, you will immediately cancel all these clients who are outside your new max. You may not be able to get clients back.': {
        pt: 'Ao reduzir seu tempo máximo estimado de direção, você cancelará imediatamente todos os clientes que estão fora do seu novo máximo. Você pode não conseguir recuperar os clientes.',
        es: 'Al reducir su tiempo máximo estimado de conducción, cancelará inmediatamente todos los clientes que estén fuera de su nuevo máximo. Es posible que no pueda recuperar los clientes.',
        ru: 'Уменьшив максимальное расчетное время в пути, вы немедленно отмените всех клиентов, которые находятся за пределами вашего нового максимума. Возможно, вы не сможете вернуть клиентов.',
        fr: 'En réduisant votre temps de trajet maximum estimé, vous annulerez immédiatement tous les clients qui se trouvent en dehors de votre nouvelle limite. Vous ne pourrez peut-être pas récupérer ces clients.',
        de: 'Durch die Reduzierung Ihrer maximalen geschätzten Fahrzeit werden alle Kunden außerhalb Ihres neuen Maximums sofort storniert. Sie können möglicherweise keine Kunden zurückgewinnen.'
    },
    'Confirm Proposal': {
        pt: 'Confirmar Proposta',
        es: 'Confirmar Propuesta',
        ru: 'Подтвердить Предложение',
        fr: 'Confirmer la Proposition',
        de: 'Angebot Bestätigen'
    },
    'will receive an email with a link to this proposal.': {
        pt: 'receberá um email com um link para esta proposta.',
        es: 'recibirá un correo electrónico con un enlace a esta propuesta.',
        ru: 'получит электронное письмо со ссылкой на это предложение.',
        fr: 'recevra un e-mail avec un lien vers cette proposition.',
        de: 'wird eine E-Mail mit einem Link zu diesem Angebot erhalten.'
    },
    'Proposal From': {
        pt: 'Proposta De',
        es: 'Propuesta De',
        ru: 'Предложение От',
        fr: 'Proposition De',
        de: 'Angebot Von'
    },
    'Valid At:': {
        pt: 'Válido Em:',
        es: 'Válido En:',
        ru: 'Действительно В:',
        fr: 'Valable À:',
        de: 'Gültig Bei:'
    },
    'Cost': {
        pt: 'Custo',
        es: 'Costo',
        ru: 'Стоимость',
        fr: 'Coût',
        de: 'Kosten'
    },
    'We are proud to offer this option for 30 days:': {
        pt: 'Temos o prazer de oferecer esta opção por 30 dias:',
        es: 'Nos enorgullece ofrecer esta opción durante 30 días:',
        ru: 'Мы рады предложить этот вариант на 30 дней:',
        fr: 'Nous sommes fiers d\'offrir cette option pendant 30 jours:',
        de: 'Wir freuen uns, diese Option für 30 Tage anzubieten:'
    },
    'Proposal Sent': {
        pt: 'Proposta Enviada',
        es: 'Propuesta Enviada',
        ru: 'Предложение Отправлено',
        fr: 'Proposition Envoyée',
        de: 'Angebot Gesendet'
    },
    'will receive an email and see this proposal at your Client\'s Hub.': {
        pt: 'receberá um email e verá esta proposta no Hub do Cliente.',
        es: 'recibirá un correo electrónico y verá esta propuesta en el Hub del Cliente.',
        ru: 'получит электронное письмо и увидит это предложение в Центре Клиента.',
        fr: 'recevra un e-mail et verra cette proposition dans votre Hub Client.',
        de: 'wird eine E-Mail erhalten und dieses Angebot in Ihrem Kunden-Hub sehen.'
    },
    'This proposal was accepted on': {
        pt: 'Esta proposta foi aceita em',
        es: 'Esta propuesta fue aceptada el',
        ru: 'Это предложение было принято',
        fr: 'Cette proposition a été acceptée le',
        de: 'Dieses Angebot wurde angenommen am'
    },
    'Pending Proposal': {
        pt: 'Proposta Pendente',
        es: 'Propuesta Pendiente',
        ru: 'Ожидающее Предложение',
        fr: 'Proposition en Attente',
        de: 'Ausstehende Angebot'
    },
    'Accepted Proposal': {
        pt: 'Proposta Aceita',
        es: 'Propuesta Aceptada',
        ru: 'Принятое Предложение',
        fr: 'Proposition Acceptée',
        de: 'Angenommenes Angebot'
    },
    'This proposal was cancelled on': {
        pt: 'Esta proposta foi cancelada em',
        es: 'Esta propuesta fue cancelada el',
        ru: 'Это предложение было отменено',
        fr: 'Cette proposition a été annulée le',
        de: 'Dieses Angebot wurde storniert am'
    },
    'can see your proposal at their link.': {
        pt: 'pode ver sua proposta no link deles.',
        es: 'puede ver su propuesta en su enlace.',
        ru: 'может увидеть ваше предложение по их ссылке.',
        fr: 'peut voir votre proposition sur leur lien.',
        de: 'kann Ihr Angebot über ihren Link sehen.'
    },
    'Valid At: All Addresses': {
        pt: 'Válido Em: Todos os Endereços',
        es: 'Válido En: Todas las Direcciones',
        ru: 'Действительно В: Все Адреса',
        fr: 'Valable À: Toutes les Adresses',
        de: 'Gültig Bei: Alle Adressen'
    },
    'Mark as Accepted': {
        pt: 'Marcar como Aceito',
        es: 'Marcar como Aceptado',
        ru: 'Отметить как Принято',
        fr: 'Marquer comme Accepté',
        de: 'Als Angenommen Markieren'
    },
    'Schedule Job': {
        pt: 'Agendar Trabalho',
        es: 'Programar Trabajo',
        ru: 'Запланировать Работу',
        fr: 'Planifier le Travail',
        de: 'Arbeit Planen'
    },
    'Cancel Proposal': {
        pt: 'Cancelar Proposta',
        es: 'Cancelar Propuesta',
        ru: 'Отменить Предложение',
        fr: 'Annuler la Proposition',
        de: 'Angebot Stornieren'
    },
    'Cancel Proposal?': {
        pt: 'Cancelar Proposta?',
        es: '¿Cancelar Propuesta?',
        ru: 'Отменить Предложение?',
        fr: 'Annuler la Proposition?',
        de: 'Angebot Stornieren?'
    },
    'Proposal Cancelled': {
        pt: 'Proposta Cancelada',
        es: 'Propuesta Cancelada',
        ru: 'Предложение Отменено',
        fr: 'Proposition Annulée',
        de: 'Angebot Storniert'
    },
    'Mark Accepted?': {
        pt: 'Marcar como Aceito?',
        es: '¿Marcar como Aceptado?',
        ru: 'Отметить как Принято?',
        fr: 'Marquer comme Accepté?',
        de: 'Als Angenommen Markieren?'
    },
    'Proposal Accepted': {
        pt: 'Proposta Aceita',
        es: 'Propuesta Aceptada',
        ru: 'Предложение Принято',
        fr: 'Proposition Acceptée',
        de: 'Angebot Angenommen'
    },

    'Email a beautiful proposal to your Client so they can agree with 1 tap': {
        pt: 'Envie uma bela proposta ao seu Cliente para que eles possam concordar com 1 toque',
        es: 'Envíe una hermosa propuesta a su Cliente para que puedan aceptar con 1 toque',
        ru: 'Отправьте красивое предложение вашему Клиенту, чтобы они могли согласиться в 1 клик',
        fr: 'Envoyez une belle proposition à votre Client pour qu\'ils puissent accepter en 1 clic',
        de: 'Senden Sie Ihrem Kunden einen schönen Vorschlag, den sie mit 1 Klick annehmen können'
    },
    'Pending': {
        pt: 'Pendente',
        es: 'Pendiente',
        ru: 'В ожидании',
        fr: 'En attente',
        de: 'Ausstehend'
    },
    'Accepted': {
        pt: 'Aceito',
        es: 'Aceptado',
        ru: 'Принято',
        fr: 'Accepté',
        de: 'Angenommen'
    },
    'Rejected': {
        pt: 'Rejeitado',
        es: 'Rechazado',
        ru: 'Отклонено',
        fr: 'Rejeté',
        de: 'Abgelehnt'
    },
    'Expired': {
        pt: 'Expirado',
        es: 'Expirado',
        ru: 'Истекло',
        fr: 'Expiré',
        de: 'Abgelaufen'
    },
    'Cancelled': {
        pt: 'Cancelado',
        es: 'Cancelado',
        ru: 'Отменено',
        fr: 'Annulé',
        de: 'Storniert'
    },
    'proposals': {
        pt: 'propostas',
        es: 'propuestas',
        ru: 'предложений',
        fr: 'propositions',
        de: 'Angebote'
    },
    'Win More Clients': {
        pt: 'Ganhe Mais Clientes',
        es: 'Gane Más Clientes',
        ru: 'Привлекайте Больше Клиентов',
        fr: 'Gagnez Plus de Clients',
        de: 'Gewinnen Sie Mehr Kunden'
    },
    'Use TIDY proposals to get more Clients today': {
        pt: 'Use propostas TIDY para conseguir mais Clientes hoje',
        es: 'Use propuestas TIDY para obtener más Clientes hoy',
        ru: 'Используйте предложения TIDY, чтобы получить больше Клиентов сегодня',
        fr: 'Utilisez les propositions TIDY pour obtenir plus de Clients aujourd\'hui',
        de: 'Nutzen Sie TIDY-Angebote, um heute mehr Kunden zu gewinnen'
    },
    'Finish the steps to manage existing clients to use proposals, it\'s quick and easy': {
        pt: 'Complete as etapas para gerenciar clientes existentes e usar propostas, é rápido e fácil',
        es: 'Complete los pasos para administrar clientes existentes y usar propuestas, es rápido y fácil',
        ru: 'Выполните шаги для управления существующими клиентами и использования предложений, это быстро и легко',
        fr: 'Terminez les étapes pour gérer les clients existants et utiliser les propositions, c\'est rapide et facile',
        de: 'Schließen Sie die Schritte zur Verwaltung bestehender Kunden ab, um Angebote zu nutzen, es ist schnell und einfach'
    },
    'Finish Getting Started': {
        pt: 'Concluir Primeiros Passos',
        es: 'Terminar Primeros Pasos',
        ru: 'Завершить Начало Работы',
        fr: 'Terminer la Mise en Route',
        de: 'Erste Schritte Abschließen'
    },
    'Send Clients a beautiful proposal they can accept later': {
        pt: 'Envie aos Clientes uma bela proposta que eles podem aceitar depois',
        es: 'Envíe a los Clientes una hermosa propuesta que pueden aceptar más tarde',
        ru: 'Отправьте Клиентам красивое предложение, которое они могут принять позже',
        fr: 'Envoyez aux Clients une belle proposition qu\'ils peuvent accepter plus tard',
        de: 'Senden Sie Kunden ein schönes Angebot, das sie später annehmen können'
    },
    'Clients can review and eSign easily': {
        pt: 'Clientes podem revisar e assinar eletronicamente facilmente',
        es: 'Los Clientes pueden revisar y firmar electrónicamente fácilmente',
        ru: 'Клиенты могут легко просмотреть и подписать электронно',
        fr: 'Les Clients peuvent facilement examiner et signer électroniquement',
        de: 'Kunden können einfach prüfen und elektronisch unterschreiben'
    },
    'Automatic reminders increase acceptance up to 50%': {
        pt: 'Lembretes automáticos aumentam a aceitação em até 50%',
        es: 'Los recordatorios automáticos aumentan la aceptación hasta un 50%',
        ru: 'Автоматические напоминания повышают принятие до 50%',
        fr: 'Les rappels automatiques augmentent l\'acceptation jusqu\'à 50%',
        de: 'Automatische Erinnerungen erhöhen die Akzeptanz um bis zu 50%'
    },
    'Send Proposal to': {
        pt: 'Enviar Proposta para',
        es: 'Enviar Propuesta a',
        ru: 'Отправить Предложение',
        fr: 'Envoyer la Proposition à',
        de: 'Angebot Senden an'
    },

    'Email a beautiful proposal to your Client so they can agree with 1 tap.': {
        pt: 'Envie uma bela proposta ao seu Cliente para que eles possam concordar com 1 toque.',
        es: 'Envíe una hermosa propuesta a su Cliente para que puedan aceptar con 1 toque.',
        ru: 'Отправьте красивое предложение вашему Клиенту, чтобы они могли согласиться одним нажатием.',
        fr: 'Envoyez une belle proposition à votre Client pour qu\'ils puissent accepter en 1 clic.',
        de: 'Senden Sie Ihrem Kunden ein schönes Angebot, das sie mit 1 Klick annehmen können.'
    },
    'Please select a Client from your list.': {
        pt: 'Por favor, selecione um Cliente da sua lista.',
        es: 'Por favor, seleccione un Cliente de su lista.',
        ru: 'Пожалуйста, выберите Клиента из вашего списка.',
        fr: 'Veuillez sélectionner un Client de votre liste.',
        de: 'Bitte wählen Sie einen Kunden aus Ihrer Liste.'
    },
    'Choose Client': {
        pt: 'Escolher Cliente',
        es: 'Elegir Cliente',
        ru: 'Выбрать Клиента',
        fr: 'Choisir le Client',
        de: 'Kunde Auswählen'
    },
    'Proposal Valid at': {
        pt: 'Proposta Válida em',
        es: 'Propuesta Válida en',
        ru: 'Предложение Действительно в',
        fr: 'Proposition Valable à',
        de: 'Angebot Gültig bei'
    },
    'Service Detail': {
        pt: 'Detalhe do Serviço',
        es: 'Detalle del Servicio',
        ru: 'Детали Услуги',
        fr: 'Détail du Service',
        de: 'Service-Details'
    },
    'Service Type': {
        pt: 'Tipo de Serviço',
        es: 'Tipo de Servicio',
        ru: 'Тип Услуги',
        fr: 'Type de Service',
        de: 'Service-Typ'
    },
    'Please select a service type.': {
        pt: 'Por favor, selecione um tipo de serviço.',
        es: 'Por favor, seleccione un tipo de servicio.',
        ru: 'Пожалуйста, выберите тип услуги.',
        fr: 'Veuillez sélectionner un type de service.',
        de: 'Bitte wählen Sie einen Service-Typ.'
    },
    'Service Description': {
        pt: 'Descrição do Serviço',
        es: 'Descripción del Servicio',
        ru: 'Описание Услуги',
        fr: 'Description du Service',
        de: 'Service-Beschreibung'
    },
    'Please enter a description.': {
        pt: 'Por favor, insira uma descrição.',
        es: 'Por favor, ingrese una descripción.',
        ru: 'Пожалуйста, введите описание.',
        fr: 'Veuillez entrer une description.',
        de: 'Bitte geben Sie eine Beschreibung ein.'
    },
    'Service Price': {
        pt: 'Preço do Serviço',
        es: 'Precio del Servicio',
        ru: 'Цена Услуги',
        fr: 'Prix du Service',
        de: 'Service-Preis'
    },
    'Valid at all addresses': {
        pt: 'Válido em todos os endereços',
        es: 'Válido en todas las direcciones',
        ru: 'Действительно для всех адресов',
        fr: 'Valable à toutes les adresses',
        de: 'Gültig für alle Adressen'
    },
    'Earn rewards by completing TIDY quests!': {
        pt: 'Ganhe recompensas completando missões TIDY!',
        es: '¡Gane recompensas completando misiones TIDY!',
        ru: 'Получайте награды, выполняя задания TIDY!',
        fr: 'Gagnez des récompenses en complétant les quêtes TIDY !',
        de: 'Verdienen Sie Belohnungen durch das Abschließen von TIDY-Quests!'
    },
    'Active Quests:': {
        pt: 'Missões Ativas:',
        es: 'Misiones Activas:',
        ru: 'Активные Задания:',
        fr: 'Quêtes Actives:',
        de: 'Aktive Quests:'
    },
    'None': {
        pt: 'Nenhum',
        es: 'Ninguno',
        ru: 'Нет',
        fr: 'Aucun',
        de: 'Keine'
    },
    'Challenge: ': {
        pt: 'Desafio: ',
        es: 'Desafío: ',
        ru: 'Испытание: ',
        fr: 'Défi: ',
        de: 'Herausforderung: '
    },
    'Active on:': {
        pt: 'Ativo em:',
        es: 'Activo en:',
        ru: 'Активно с:',
        fr: 'Actif le:',
        de: 'Aktiv am:'
    },
    'Available Quests:': {
        pt: 'Missões Disponíveis:',
        es: 'Misiones Disponibles:',
        ru: 'Доступные Задания:',
        fr: 'Quêtes Disponibles:',
        de: 'Verfügbare Quests:'
    },
    'Reward: ': {
        pt: 'Recompensa: ',
        es: 'Recompensa: ',
        ru: 'Награда: ',
        fr: 'Récompense: ',
        de: 'Belohnung: '
    },
    'Start Quest': {
        pt: 'Iniciar Missão',
        es: 'Iniciar Misión',
        ru: 'Начать Задание',
        fr: 'Commencer la Quête',
        de: 'Quest Starten'
    },
    'Past Quests:': {
        pt: 'Missões Anteriores:',
        es: 'Misiones Anteriores:',
        ru: 'Прошлые Задания:',
        fr: 'Quêtes Passées:',
        de: 'Vergangene Quests:'
    },
    'Failed': {
        pt: 'Falhou',
        es: 'Fallido',
        ru: 'Не Удалось',
        fr: 'Échoué',
        de: 'Gescheitert'
    },
    'Ended on:': {
        pt: 'Terminado em:',
        es: 'Terminado el:',
        ru: 'Закончилось:',
        fr: 'Terminé le:',
        de: 'Beendet am:'
    },
    'Quest Started': {
        pt: 'Missão Iniciada',
        es: 'Misión Iniciada',
        ru: 'Задание Начато',
        fr: 'Quête Commencée',
        de: 'Quest Gestartet'
    },

    'Your certification is no longer valid. To re-request certification, please confirm the following.': {
        pt: 'Sua certificação não é mais válida. Para solicitar novamente a certificação, confirme o seguinte.',
        es: 'Su certificación ya no es válida. Para volver a solicitar la certificación, confirme lo siguiente.',
        ru: 'Ваша сертификация больше недействительна. Чтобы повторно запросить сертификацию, подтвердите следующее.',
        fr: 'Votre certification n\'est plus valide. Pour demander à nouveau la certification, veuillez confirmer ce qui suit.',
        de: 'Ihre Zertifizierung ist nicht mehr gültig. Um die Zertifizierung erneut zu beantragen, bestätigen Sie bitte Folgendes.'
    },
    'I represent and warrant the following:': {
        pt: 'Eu declaro e garanto o seguinte:',
        es: 'Declaro y garantizo lo siguiente:',
        ru: 'Я заявляю и гарантирую следующее:',
        fr: 'Je déclare et garantis ce qui suit:',
        de: 'Ich erkläre und garantiere Folgendes:'
    },
    'I agree to the terms currently on': {
        pt: 'Eu concordo com os termos atualmente em',
        es: 'Estoy de acuerdo con los términos actuales en',
        ru: 'Я согласен с текущими условиями на',
        fr: 'J\'accepte les conditions actuellement sur',
        de: 'Ich stimme den aktuellen Bedingungen zu auf'
    },
    'I Agree - Request Certification': {
        pt: 'Eu Concordo - Solicitar Certificação',
        es: 'Estoy de Acuerdo - Solicitar Certificación',
        ru: 'Я Согласен - Запросить Сертификацию',
        fr: 'J\'accepte - Demander la Certification',
        de: 'Ich Stimme zu - Zertifizierung Beantragen'
    },
    'I had my own business before, and that business is still active.': {
        pt: 'Eu tinha meu próprio negócio antes, e esse negócio ainda está ativo.',
        es: 'Tenía mi propio negocio antes, y ese negocio todavía está activo.',
        ru: 'У меня раньше был свой бизнес, и этот бизнес все еще активен.',
        fr: 'J\'avais ma propre entreprise avant, et cette entreprise est toujours active.',
        de: 'Ich hatte vorher ein eigenes Unternehmen, und dieses Unternehmen ist noch aktiv.'
    },
    'I have not had any changes to my background check.': {
        pt: 'Não houve alterações na minha verificação de antecedentes.',
        es: 'No he tenido cambios en mi verificación de antecedentes.',
        ru: 'У меня не было изменений в моей проверке биографии.',
        fr: 'Je n\'ai eu aucun changement dans ma vérification des antécédents.',
        de: 'Ich hatte keine Änderungen an meiner Hintergrundüberprüfung.'
    },
    'I have never had any safety or theft related misdimeanor or felonies.': {
        pt: 'Nunca tive contravenções ou crimes relacionados à segurança ou roubo.',
        es: 'Nunca he tenido delitos menores o graves relacionados con la seguridad o el robo.',
        ru: 'У меня никогда не было правонарушений или преступлений, связанных с безопасностью или кражей.',
        fr: 'Je n\'ai jamais eu de délits ou de crimes liés à la sécurité ou au vol.',
        de: 'Ich hatte nie Vergehen oder Verbrechen im Zusammenhang mit Sicherheit oder Diebstahl.'
    },
    'In consideration of TIDY re-reviewing my account to see if I am eligible to be certified, I agree to release TIDY and its agents, affiliates, and employees from any and all actions, suits, damages, claims, and demands, of every kind whatsoever which I have or may have against TIDY for anything whatsoever from the beginning of time through the date of this release, except for any claim that cannot be waived by law. I knowingly waive any and all rights and protections under California Civil Code Section 1542, which section has been duly explained and read as follows: "A general release does not extend to claims that the creditor or releasing party does not know or suspect to exist in his favor at the time of executing the release, and that if known by him would have materially affected his settlement with the debtor or released party."': {
        pt: 'Em consideração à TIDY reanalisar minha conta para ver se sou elegível para ser certificado, concordo em liberar a TIDY e seus agentes, afiliados e funcionários de quaisquer ações, processos, danos, reivindicações e demandas, de qualquer tipo que eu tenha ou possa ter contra a TIDY por qualquer motivo desde o início dos tempos até a data desta liberação, exceto por qualquer reivindicação que não possa ser renunciada por lei. Conscientemente renuncio a todos os direitos e proteções sob a Seção 1542 do Código Civil da Califórnia, cuja seção foi devidamente explicada e lida como segue: "Uma liberação geral não se estende a reivindicações que o credor ou parte liberadora não conhece ou suspeita existir a seu favor no momento da execução da liberação, e que, se conhecidas por ele, teriam afetado materialmente seu acordo com o devedor ou parte liberada."',
        es: 'En consideración a que TIDY revise nuevamente mi cuenta para ver si soy elegible para ser certificado, acepto liberar a TIDY y sus agentes, afiliados y empleados de cualquier acción, demanda, daño, reclamo y demanda, de cualquier tipo que tenga o pueda tener contra TIDY por cualquier motivo desde el principio de los tiempos hasta la fecha de esta liberación, excepto por cualquier reclamo que no pueda ser renunciado por ley. Conscientemente renuncio a todos los derechos y protecciones bajo la Sección 1542 del Código Civil de California, cuya sección ha sido debidamente explicada y leída de la siguiente manera: "Una liberación general no se extiende a los reclamos que el acreedor o la parte liberadora no conoce o sospecha que existen a su favor al momento de ejecutar la liberación, y que si los hubiera conocido, habrían afectado materialmente su acuerdo con el deudor o la parte liberada."',
        ru: 'В связи с тем, что TIDY пересматривает мою учетную запись, чтобы определить, имею ли я право на сертификацию, я соглашаюсь освободить TIDY и ее агентов, филиалы и сотрудников от любых действий, исков, ущерба, претензий и требований любого рода, которые я имею или могу иметь против TIDY за что-либо с начала времен до даты этого освобождения, за исключением любых претензий, от которых нельзя отказаться по закону. Я сознательно отказываюсь от всех прав и защиты в соответствии с разделом 1542 Гражданского кодекса Калифорнии, который был должным образом объяснен и прочитан следующим образом: "Общее освобождение не распространяется на претензии, о которых кредитор или освобождающая сторона не знает или не подозревает об их существовании в свою пользу на момент выполнения освобождения, и которые, если бы были известны ему, существенно повлияли бы на его соглашение с должником или освобожденной стороной."',
        fr: 'En considération du réexamen par TIDY de mon compte pour voir si je suis éligible à la certification, j\'accepte de libérer TIDY et ses agents, affiliés et employés de toutes actions, poursuites, dommages, réclamations et demandes, de quelque nature que ce soit que j\'ai ou pourrais avoir contre TIDY pour quoi que ce soit depuis le début des temps jusqu\'à la date de cette libération, à l\'exception de toute réclamation qui ne peut être abandonnée par la loi. Je renonce sciemment à tous les droits et protections en vertu de la section 1542 du Code civil de Californie, dont la section a été dûment expliquée et lue comme suit : "Une libération générale ne s\'étend pas aux réclamations que le créancier ou la partie libératrice ne connaît pas ou ne soupçonne pas exister en sa faveur au moment de l\'exécution de la libération, et qui, s\'il les avait connues, auraient matériellement affecté son règlement avec le débiteur ou la partie libérée."',
        de: 'In Anbetracht der Überprüfung meines Kontos durch TIDY, um festzustellen, ob ich für eine Zertifizierung in Frage komme, stimme ich zu, TIDY und seine Vertreter, Partner und Mitarbeiter von allen Handlungen, Klagen, Schäden, Ansprüchen und Forderungen jeglicher Art freizustellen, die ich gegen TIDY habe oder haben könnte, von Anbeginn der Zeit bis zum Datum dieser Freigabe, mit Ausnahme von Ansprüchen, auf die gesetzlich nicht verzichtet werden kann. Ich verzichte wissentlich auf alle Rechte und Schutzmaßnahmen gemäß Abschnitt 1542 des kalifornischen Zivilgesetzbuchs, dessen Abschnitt ordnungsgemäß erklärt und wie folgt gelesen wurde: "Eine allgemeine Freigabe erstreckt sich nicht auf Ansprüche, von denen der Gläubiger oder die freigebende Partei zum Zeitpunkt der Ausführung der Freigabe nichts weiß oder vermutet, dass sie zu seinen Gunsten bestehen, und die, wenn sie ihm bekannt gewesen wären, seine Einigung mit dem Schuldner oder der freigegebenen Partei wesentlich beeinflusst hätten."'
    },
    'I understand that reviewing the account is not guaranteed to lead to re-certification, it is subject to your account history.': {
        pt: 'Entendo que a revisão da conta não garante a recertificação, está sujeita ao histórico da sua conta.',
        es: 'Entiendo que la revisión de la cuenta no garantiza la recertificación, está sujeta al historial de su cuenta.',
        ru: 'Я понимаю, что проверка учетной записи не гарантирует повторную сертификацию, это зависит от истории вашей учетной записи.',
        fr: 'Je comprends que l\'examen du compte ne garantit pas la recertification, elle est soumise à l\'historique de votre compte.',
        de: 'Ich verstehe, dass die Überprüfung des Kontos nicht garantiert zur Rezertifizierung führt, sie unterliegt Ihrer Kontohistorie.'
    },
    'Successfully Re-Certified': {
        pt: 'Recertificado com Sucesso',
        es: 'Recertificado Exitosamente',
        ru: 'Успешно Пересертифицирован',
        fr: 'Recertifié avec Succès',
        de: 'Erfolgreich Rezertifiziert'
    },
    'Congratulations, you have been re-certified. Please ensure your calendar and drive time are up to date.': {
        pt: 'Parabéns, você foi recertificado. Por favor, certifique-se de que seu calendário e tempo de viagem estejam atualizados.',
        es: 'Felicitaciones, ha sido recertificado. Asegúrese de que su calendario y tiempo de conducción estén actualizados.',
        ru: 'Поздравляем, вы прошли повторную сертификацию. Пожалуйста, убедитесь, что ваш календарь и время в пути актуальны.',
        fr: 'Félicitations, vous avez été recertifié. Veuillez vous assurer que votre calendrier et votre temps de trajet sont à jour.',
        de: 'Herzlichen Glückwunsch, Sie wurden rezertifiziert. Bitte stellen Sie sicher, dass Ihr Kalender und Ihre Fahrzeit aktuell sind.'
    },
    'Unable to Re-Certify': {
        pt: 'Não foi Possível Recertificar',
        es: 'No se Pudo Recertificar',
        ru: 'Не Удалось Пройти Повторную Сертификацию',
        fr: 'Impossible de Recertifier',
        de: 'Rezertifizierung Nicht Möglich'
    },
    'We were not able to certify you at this time. Please try again in at least 14 days.': {
        pt: 'Não foi possível certificá-lo neste momento. Por favor, tente novamente em pelo menos 14 dias.',
        es: 'No pudimos certificarlo en este momento. Intente nuevamente en al menos 14 días.',
        ru: 'Мы не смогли сертифицировать вас в данный момент. Пожалуйста, попробуйте снова через 14 дней.',
        fr: 'Nous n\'avons pas pu vous certifier pour le moment. Veuillez réessayer dans au moins 14 jours.',
        de: 'Wir konnten Sie zu diesem Zeitpunkt nicht zertifizieren. Bitte versuchen Sie es in mindestens 14 Tagen erneut.'
    },
    'What do you want to log?': {
        pt: 'O que você quer registrar?',
        es: '¿Qué quieres registrar?',
        ru: 'Что вы хотите зарегистрировать?',
        fr: 'Que voulez-vous signaler?',
        de: 'Was möchten Sie melden?'
    },
    'Damage': {
        pt: 'Dano',
        es: 'Daño',
        ru: 'Повреждение',
        fr: 'Dommage',
        de: 'Schaden'
    },
    'Low Inventory': {
        pt: 'Baixo Estoque',
        es: 'Inventario Bajo',
        ru: 'Низкий Запас',
        fr: 'Stock Faible',
        de: 'Niedriger Bestand'
    },
    'Pest Report': {
        pt: 'Relatório de Pragas',
        es: 'Informe de Plagas',
        ru: 'Отчет о Вредителях',
        fr: 'Rapport sur les Nuisibles',
        de: 'Schädlingsbericht'
    },
    'Utility Issue': {
        pt: 'Problema de Utilidade',
        es: 'Problema de Servicios',
        ru: 'Проблема с Коммунальными Услугами',
        fr: 'Problème de Services',
        de: 'Versorgungsproblem'
    },
    'Send this information directly to the client:': {
        pt: 'Envie esta informação diretamente ao cliente:',
        es: 'Envíe esta información directamente al cliente:',
        ru: 'Отправьте эту информацию напрямую клиенту:',
        fr: 'Envoyez cette information directement au client:',
        de: 'Senden Sie diese Information direkt an den Kunden:'
    },
    'Please enter a description': {
        pt: 'Por favor, insira uma descrição',
        es: 'Por favor, ingrese una descripción',
        ru: 'Пожалуйста, введите описание',
        fr: 'Veuillez entrer une description',
        de: 'Bitte geben Sie eine Beschreibung ein'
    },
    'Photos:': {
        pt: 'Fotos:',
        es: 'Fotos:',
        ru: 'Фотографии:',
        fr: 'Photos:',
        de: 'Fotos:'
    },
    'Photo': {
        pt: 'Foto',
        es: 'Foto',
        ru: 'Фото',
        fr: 'Photo',
        de: 'Foto'
    },
    'Send to Client': {
        pt: 'Enviar ao Cliente',
        es: 'Enviar al Cliente',
        ru: 'Отправить Клиенту',
        fr: 'Envoyer au Client',
        de: 'An Kunden Senden'
    },
    'Report Damage': {
        pt: 'Relatar Dano',
        es: 'Reportar Daño',
        ru: 'Сообщить о Повреждении',
        fr: 'Signaler un Dommage',
        de: 'Schaden Melden'
    },
    'Report Low Inventory': {
        pt: 'Relatar Baixo Estoque',
        es: 'Reportar Inventario Bajo',
        ru: 'Сообщить о Низком Запасе',
        fr: 'Signaler un Stock Faible',
        de: 'Niedrigen Bestand Melden'
    },
    'Issue Logged': {
        pt: 'Problema Registrado',
        es: 'Problema Registrado',
        ru: 'Проблема Зарегистрирована',
        fr: 'Problème Enregistré',
        de: 'Problem Protokolliert'
    },
    'We will notify the client of the issue after you complete the To-Do list.': {
        pt: 'Notificaremos o cliente sobre o problema após você completar a lista de tarefas.',
        es: 'Notificaremos al cliente sobre el problema después de que complete la lista de tareas.',
        ru: 'Мы уведомим клиента о проблеме после того, как вы выполните список задач.',
        fr: 'Nous informerons le client du problème après que vous ayez terminé la liste des tâches.',
        de: 'Wir werden den Kunden über das Problem informieren, nachdem Sie die To-Do-Liste abgeschlossen haben.'
    },
    'We will notify the client of the issue.': {
        pt: 'Notificaremos o cliente sobre o problema.',
        es: 'Notificaremos al cliente sobre el problema.',
        ru: 'Мы уведомим клиента о проблеме.',
        fr: 'Nous informerons le client du problème.',
        de: 'Wir werden den Kunden über das Problem informieren.'
    },
    'Please Contact the Client': {
        pt: 'Por Favor, Contate o Cliente',
        es: 'Por Favor, Contacte al Cliente',
        ru: 'Пожалуйста, Свяжитесь с Клиентом',
        fr: 'Veuillez Contacter le Client',
        de: 'Bitte Kontaktieren Sie den Kunden'
    },
    'For other issues it is best to contact the client directly.': {
        pt: 'Para outros problemas, é melhor contatar o cliente diretamente.',
        es: 'Para otros problemas, es mejor contactar al cliente directamente.',
        ru: 'По другим вопросам лучше связаться с клиентом напрямую.',
        fr: 'Pour d\'autres problèmes, il est préférable de contacter directement le client.',
        de: 'Bei anderen Problemen ist es am besten, den Kunden direkt zu kontaktieren.'
    },

    'Warning Location Services Off': {
        pt: 'Aviso Serviços de Localização Desativados',
        es: 'Advertencia Servicios de Ubicación Desactivados',
        ru: 'Предупреждение Службы Геолокации Отключены',
        fr: 'Avertissement Services de Localisation Désactivés',
        de: 'Warnung Standortdienste Deaktiviert'
    },
    'We can\'t get your location. GPS isn\'t available here or your phone has location disabled.': {
        pt: 'Não podemos obter sua localização. O GPS não está disponível aqui ou seu telefone está com a localização desativada.',
        es: 'No podemos obtener su ubicación. El GPS no está disponible aquí o su teléfono tiene la ubicación desactivada.',
        ru: 'Мы не можем определить ваше местоположение. GPS недоступен здесь или на вашем телефоне отключена геолокация.',
        fr: 'Nous ne pouvons pas obtenir votre position. Le GPS n\'est pas disponible ici ou votre téléphone a la localisation désactivée.',
        de: 'Wir können Ihren Standort nicht ermitteln. GPS ist hier nicht verfügbar oder Ihr Telefon hat die Standortdienste deaktiviert.'
    },
    'Reschedule / Cancel': {
        pt: 'Reagendar / Cancelar',
        es: 'Reprogramar / Cancelar',
        ru: 'Перенести / Отменить',
        fr: 'Reprogrammer / Annuler',
        de: 'Neu Terminieren / Stornieren'
    },
    'Reschedule Job': {
        pt: 'Reagendar Trabalho',
        es: 'Reprogramar Trabajo',
        ru: 'Перенести Работу',
        fr: 'Reprogrammer le Travail',
        de: 'Arbeit Neu Terminieren'
    },
    'Skip Job': {
        pt: 'Pular Trabalho',
        es: 'Saltar Trabajo',
        ru: 'Пропустить Работу',
        fr: 'Sauter le Travail',
        de: 'Arbeit Überspringen'
    },
    'You don\'t have any reviews yet.': {
        pt: 'Você ainda não tem avaliações.',
        es: 'Aún no tienes reseñas.',
        ru: 'У вас пока нет отзывов.',
        fr: 'Vous n\'avez pas encore d\'avis.',
        de: 'Sie haben noch keine Bewertungen.'
    },
    'Show on my Website': {
        pt: 'Mostrar no Meu Site',
        es: 'Mostrar en Mi Sitio Web',
        ru: 'Показать на Моем Сайте',
        fr: 'Afficher sur Mon Site Web',
        de: 'Auf Meiner Website Anzeigen'
    },
    'All Changes Saved': {
        pt: 'Todas as Alterações Salvas',
        es: 'Todos los Cambios Guardados',
        ru: 'Все Изменения Сохранены',
        fr: 'Tous les Changements Sauvegardés',
        de: 'Alle Änderungen Gespeichert'
    },
    'Example Searches': {
        pt: 'Exemplos de Pesquisas',
        es: 'Búsquedas de Ejemplo',
        ru: 'Примеры Поиска',
        fr: 'Exemples de Recherches',
        de: 'Beispielsuchen'
    },
    'Recent Searches': {
        pt: 'Pesquisas Recentes',
        es: 'Búsquedas Recientes',
        ru: 'Недавние Поиски',
        fr: 'Recherches Récentes',
        de: 'Letzte Suchen'
    },
    'App Sections': {
        pt: 'Seções do Aplicativo',
        es: 'Secciones de la Aplicación',
        ru: 'Разделы Приложения',
        fr: 'Sections de l\'Application',
        de: 'App-Bereiche'
    },
    'How do Clients book?': {
        pt: 'Como os Clientes fazem reservas?',
        es: '¿Cómo reservan los Clientes?',
        ru: 'Как Клиенты делают бронирование?',
        fr: 'Comment les Clients réservent-ils?',
        de: 'Wie buchen Kunden?'
    },
    'Schedule': {
        pt: 'Agenda',
        es: 'Calendario',
        ru: 'Расписание',
        fr: 'Programme',
        de: 'Zeitplan'
    },
    
    'Don\'t see what you offer?': {
        pt: 'Não vê o que você oferece?',
        es: '¿No ves lo que ofreces?',
        ru: 'Не видите то, что предлагаете?',
        fr: 'Vous ne voyez pas ce que vous proposez?',
        de: 'Sie sehen nicht, was Sie anbieten?'
    },
    'No Services Added': {
        pt: 'Nenhum Serviço Adicionado',
        es: 'No Hay Servicios Agregados',
        ru: 'Услуги Не Добавлены',
        fr: 'Aucun Service Ajouté',
        de: 'Keine Dienstleistungen Hinzugefügt'
    },
    'Add Service': {
        pt: 'Adicionar Serviço',
        es: 'Agregar Servicio',
        ru: 'Добавить Услугу',
        fr: 'Ajouter un Service',
        de: 'Dienstleistung Hinzufügen'
    },
    'Please select a service category.': {
        pt: 'Por favor, selecione uma categoria de serviço.',
        es: 'Por favor, seleccione una categoría de servicio.',
        ru: 'Пожалуйста, выберите категорию услуги.',
        fr: 'Veuillez sélectionner une catégorie de service.',
        de: 'Bitte wählen Sie eine Dienstleistungskategorie.'
    },
    'Service Name': {
        pt: 'Nome do Serviço',
        es: 'Nombre del Servicio',
        ru: 'Название Услуги',
        fr: 'Nom du Service',
        de: 'Name der Dienstleistung'
    },
    'Please enter a service name.': {
        pt: 'Por favor, insira um nome para o serviço.',
        es: 'Por favor, ingrese un nombre para el servicio.',
        ru: 'Пожалуйста, введите название услуги.',
        fr: 'Veuillez entrer un nom de service.',
        de: 'Bitte geben Sie einen Namen für die Dienstleistung ein.'
    },
    'Please enter a service description.': {
        pt: 'Por favor, insira uma descrição do serviço.',
        es: 'Por favor, ingrese una descripción del servicio.',
        ru: 'Пожалуйста, введите описание услуги.',
        fr: 'Veuillez entrer une description du service.',
        de: 'Bitte geben Sie eine Beschreibung der Dienstleistung ein.'
    },
    'Allow Clients to book on my website': {
        pt: 'Permitir que os clientes reservem em meu site',
        es: 'Permitir que los clientes reserven en mi sitio web',
        ru: 'Разрешить клиентам бронировать на моем сайте',
        fr: 'Permettre aux clients de réserver sur mon site web',
        de: 'Kunden erlauben auf meiner Website zu buchen'
    },
    'Billing Type': {
        pt: 'Tipo de Cobrança',
        es: 'Tipo de Facturación',
        ru: 'Тип Оплаты',
        fr: 'Type de Facturation',
        de: 'Abrechnungsart'
    },
    'Please select a billing type.': {
        pt: 'Por favor, selecione um tipo de cobrança.',
        es: 'Por favor, seleccione un tipo de facturación.',
        ru: 'Пожалуйста, выберите тип оплаты.',
        fr: 'Veuillez sélectionner un type de facturation.',
        de: 'Bitte wählen Sie eine Abrechnungsart.'
    },
    'Please select a length.': {
        pt: 'Por favor, selecione uma duração.',
        es: 'Por favor, seleccione una duración.',
        ru: 'Пожалуйста, выберите продолжительность.',
        fr: 'Veuillez sélectionner une durée.',
        de: 'Bitte wählen Sie eine Dauer.'
    },
    'Advanced Settings': {
        pt: 'Configurações Avançadas',
        es: 'Configuración Avanzada',
        ru: 'Расширенные Настройки',
        fr: 'Paramètres Avancés',
        de: 'Erweiterte Einstellungen'
    },
    'Flat Rate': {
        pt: 'Taxa Fixa',
        es: 'Tarifa Fija',
        ru: 'Фиксированная Ставка',
        fr: 'Forfait',
        de: 'Pauschale'
    },
    'Block Off My Schedule For': {
        pt: 'Bloquear Minha Agenda Por',
        es: 'Bloquear Mi Horario Por',
        ru: 'Заблокировать Мое Расписание На',
        fr: 'Bloquer Mon Emploi du Temps Pour',
        de: 'Meinen Zeitplan Blockieren Für'
    },
    'Minimum Length': {
        pt: 'Duração Mínima',
        es: 'Duración Mínima',
        ru: 'Минимальная Продолжительность',
        fr: 'Durée Minimale',
        de: 'Mindestdauer'
    },
    'Service Added': {
        pt: 'Serviço Adicionado',
        es: 'Servicio Agregado',
        ru: 'Услуга Добавлена',
        fr: 'Service Ajouté',
        de: 'Dienstleistung Hinzugefügt'
    },

    'Max Cancellation Fees': {
        pt: 'Taxas Máximas de Cancelamento',
        es: 'Tarifas Máximas de Cancelación',
        ru: 'Максимальные Сборы за Отмену',
        fr: 'Frais Maximaux d\'Annulation',
        de: 'Maximale Stornierungsgebühren'
    },
    'There are the max cancellation fees you allow clients to charge. Edit these anytime with an account manager. ': {
        pt: 'Estas são as taxas máximas de cancelamento que você permite que os clientes cobrem. Edite-as a qualquer momento com um gerente de conta.',
        es: 'Estas son las tarifas máximas de cancelación que permite cobrar a los clientes. Edítelas en cualquier momento con un gerente de cuenta.',
        ru: 'Это максимальные сборы за отмену, которые вы разрешаете взимать с клиентов. Редактируйте их в любое время с менеджером аккаунта.',
        fr: 'Ce sont les frais d\'annulation maximaux que vous autorisez les clients à facturer. Modifiez-les à tout moment avec un gestionnaire de compte.',
        de: 'Dies sind die maximalen Stornierungsgebühren, die Sie Kunden erlauben zu berechnen. Bearbeiten Sie diese jederzeit mit einem Account Manager.'
    },
    'Booking Protection': {
        pt: 'Proteção de Reserva',
        es: 'Protección de Reserva',
        ru: 'Защита Бронирования',
        fr: 'Protection des Réservations',
        de: 'Buchungsschutz'
    },
    'Repeat Rate': {
        pt: 'Taxa de Repetição',
        es: 'Tarifa de Repetición',
        ru: 'Повторная Ставка',
        fr: 'Tarif de Répétition',
        de: 'Wiederholungstarif'
    },
    'This is your rate for customers who book you directly on the times you choose. No advertising costs! The lower your rates the more business you may get.': {
        pt: 'Esta é sua taxa para clientes que reservam diretamente nos horários que você escolhe. Sem custos de publicidade! Quanto menores suas taxas, mais negócios você pode conseguir.',
        es: 'Esta es su tarifa para clientes que reservan directamente en los horarios que elija. ¡Sin costos de publicidad! Cuanto más bajas sean sus tarifas, más negocios puede obtener.',
        ru: 'Это ваша ставка для клиентов, которые бронируют вас напрямую в выбранное вами время. Без рекламных расходов! Чем ниже ваши ставки, тем больше заказов вы можете получить.',
        fr: 'C\'est votre tarif pour les clients qui vous réservent directement aux horaires que vous choisissez. Pas de frais de publicité ! Plus vos tarifs sont bas, plus vous pouvez obtenir de clients.',
        de: 'Dies ist Ihr Tarif für Kunden, die Sie direkt zu den von Ihnen gewählten Zeiten buchen. Keine Werbekosten! Je niedriger Ihre Tarife, desto mehr Geschäft können Sie bekommen.'
    },
    'Discount Amount': {
        pt: 'Valor do Desconto',
        es: 'Monto del Descuento',
        ru: 'Сумма Скидки',
        fr: 'Montant de la Réduction',
        de: 'Rabattbetrag'
    },
    'Please enter a discount that is less than your total rate.': {
        pt: 'Por favor, insira um desconto menor que sua taxa total.',
        es: 'Por favor, ingrese un descuento menor que su tarifa total.',
        ru: 'Пожалуйста, введите скидку меньше вашей общей ставки.',
        fr: 'Veuillez entrer une réduction inférieure à votre tarif total.',
        de: 'Bitte geben Sie einen Rabatt ein, der niedriger ist als Ihr Gesamttarif.'
    },
    'Preview Change': {
        pt: 'Visualizar Alteração',
        es: 'Vista Previa del Cambio',
        ru: 'Предварительный Просмотр Изменений',
        fr: 'Aperçu du Changement',
        de: 'Vorschau der Änderung'
    },
    'Unable to Change Rates': {
        pt: 'Não é Possível Alterar as Taxas',
        es: 'No se Pueden Cambiar las Tarifas',
        ru: 'Невозможно Изменить Тарифы',
        fr: 'Impossible de Modifier les Tarifs',
        de: 'Tarife Können Nicht Geändert Werden'
    },
    'You\'ve exceeded the number of times you can edit this in a period, please wait and try again later. It\'s unusual to increase rates more than 1x per year. Speak with the Concierge if you need help.': {
        pt: 'Você excedeu o número de vezes que pode editar isso em um período, aguarde e tente novamente mais tarde. É incomum aumentar as taxas mais de 1x por ano. Fale com o Concierge se precisar de ajuda.',
        es: 'Ha excedido el número de veces que puede editar esto en un período, espere e intente nuevamente más tarde. Es inusual aumentar las tarifas más de 1 vez por año. Hable con el Concierge si necesita ayuda.',
        ru: 'Вы превысили количество раз, когда можно редактировать это за период, подождите и повторите попытку позже. Необычно повышать тарифы более 1 раза в год. Обратитесь к Консьержу, если вам нужна помощь.',
        fr: 'Vous avez dépassé le nombre de fois où vous pouvez modifier ceci dans une période, veuillez attendre et réessayer plus tard. Il est inhabituel d\'augmenter les tarifs plus d\'une fois par an. Parlez au Concierge si vous avez besoin d\'aide.',
        de: 'Sie haben die Anzahl der Bearbeitungen in einem Zeitraum überschritten, bitte warten Sie und versuchen Sie es später erneut. Es ist ungewöhnlich, die Tarife mehr als 1x pro Jahr zu erhöhen. Sprechen Sie mit dem Concierge, wenn Sie Hilfe benötigen.'
    },
    'Confirm Rate Change': {
        pt: 'Confirmar Alteração de Taxa',
        es: 'Confirmar Cambio de Tarifa',
        ru: 'Подтвердить Изменение Тарифа',
        fr: 'Confirmer le Changement de Tarif',
        de: 'Tarifänderung Bestätigen'
    },
    'First Job Discount': {
        pt: 'Desconto no Primeiro Trabalho',
        es: 'Descuento en el Primer Trabajo',
        ru: 'Скидка на Первую Работу',
        fr: 'Réduction Premier Travail',
        de: 'Erstauftrags-Rabatt'
    },
    'These rates are: ': {
        pt: 'Estas taxas são: ',
        es: 'Estas tarifas son: ',
        ru: 'Эти тарифы: ',
        fr: 'Ces tarifs sont: ',
        de: 'Diese Tarife sind: '
    },
    'Cancel current clients below these rates': {
        pt: 'Cancelar clientes atuais abaixo dessas taxas',
        es: 'Cancelar clientes actuales por debajo de estas tarifas',
        ru: 'Отменить текущих клиентов ниже этих тарифов',
        fr: 'Annuler les clients actuels en dessous de ces tarifs',
        de: 'Aktuelle Kunden unter diesen Tarifen kündigen'
    },
    'Select Client(s) to Change Rates': {
        pt: 'Selecionar Cliente(s) para Alterar Taxas',
        es: 'Seleccionar Cliente(s) para Cambiar Tarifas',
        ru: 'Выберите Клиента(ов) для Изменения Тарифов',
        fr: 'Sélectionner Client(s) pour Modifier les Tarifs',
        de: 'Kunden für Tarifänderung Auswählen'
    },
    'Clients you don\'t select will keep your current rate.': {
        pt: 'Os clientes que você não selecionar manterão sua taxa atual.',
        es: 'Los clientes que no seleccione mantendrán su tarifa actual.',
        ru: 'Клиенты, которых вы не выберете, сохранят текущий тариф.',
        fr: 'Les clients que vous ne sélectionnez pas garderont votre tarif actuel.',
        de: 'Nicht ausgewählte Kunden behalten Ihren aktuellen Tarif.'
    },
    'You will lose ': {
        pt: 'Você perderá ',
        es: 'Perderá ',
        ru: 'Вы потеряете ',
        fr: 'Vous perdrez ',
        de: 'Sie werden verlieren '
    },
    'Repeat Rate: ': {
        pt: 'Taxa de Repetição: ',
        es: 'Tarifa de Repetición: ',
        ru: 'Повторная Ставка: ',
        fr: 'Tarif de Répétition: ',
        de: 'Wiederholungstarif: '
    },
    'Last Job: ': {
        pt: 'Último Trabalho: ',
        es: 'Último Trabajo: ',
        ru: 'Последняя Работа: ',
        fr: 'Dernier Travail: ',
        de: 'Letzter Auftrag: '
    },
    'No Completed Jobs': {
        pt: 'Nenhum Trabalho Concluído',
        es: 'Sin Trabajos Completados',
        ru: 'Нет Завершенных Работ',
        fr: 'Aucun Travail Terminé',
        de: 'Keine Abgeschlossenen Aufträge'
    },
    'Confirm Changes': {
        pt: 'Confirmar Alterações',
        es: 'Confirmar Cambios',
        ru: 'Подтвердить Изменения',
        fr: 'Confirmer les Changements',
        de: 'Änderungen Bestätigen'
    },
    'Very Competitive': {
        pt: 'Muito Competitivo',
        es: 'Muy Competitivo',
        ru: 'Очень Конкурентоспособный',
        fr: 'Très Compétitif',
        de: 'Sehr Wettbewerbsfähig'
    },
    'Increasing your rates would likely lead to more earnings.': {
        pt: 'Aumentar suas taxas provavelmente levará a mais ganhos.',
        es: 'Aumentar sus tarifas probablemente conducirá a mayores ganancias.',
        ru: 'Повышение ваших тарифов, вероятно, приведет к увеличению заработка.',
        fr: 'Augmenter vos tarifs mènerait probablement à plus de revenus.',
        de: 'Eine Erhöhung Ihrer Tarife würde wahrscheinlich zu mehr Einnahmen führen.'
    },
    'Competitive': {
        pt: 'Competitivo',
        es: 'Competitivo',
        ru: 'Конкурентоспособный',
        fr: 'Compétitif',
        de: 'Wettbewerbsfähig'
    },
    'Increasing or decreasing your rates would likely lead to less earnings.': {
        pt: 'Aumentar ou diminuir suas taxas provavelmente levará a menos ganhos.',
        es: 'Aumentar o disminuir sus tarifas probablemente conducirá a menos ganancias.',
        ru: 'Повышение или понижение ваших тарифов, вероятно, приведет к меньшему заработку.',
        fr: 'Augmenter ou diminuer vos tarifs mènerait probablement à moins de revenus.',
        de: 'Eine Erhöhung oder Senkung Ihrer Tarife würde wahrscheinlich zu weniger Einnahmen führen.'
    },
    'Uncompetitive': {
        pt: 'Não Competitivo',
        es: 'No Competitivo',
        ru: 'Неконкурентоспособный',
        fr: 'Non Compétitif',
        de: 'Nicht Wettbewerbsfähig'
    },
    'Your rates are higher than others in your area with a similar quality score. <b>It is likely fewer clients will book you, reducing your total earnings.</b> Increasing your quality score would increase the number of clients willing to book you at these rates, you may want to do that first.': {
        pt: 'Suas taxas são mais altas que outras em sua área com pontuação de qualidade similar. <b>É provável que menos clientes reservem seus serviços, reduzindo seus ganhos totais.</b> Aumentar sua pontuação de qualidade aumentaria o número de clientes dispostos a reservar com você nessas taxas, você pode querer fazer isso primeiro.',
        es: 'Sus tarifas son más altas que otras en su área con una puntuación de calidad similar. <b>Es probable que menos clientes lo reserven, reduciendo sus ganancias totales.</b> Aumentar su puntuación de calidad aumentaría el número de clientes dispuestos a reservarlo a estas tarifas, es posible que desee hacer eso primero.',
        ru: 'Ваши тарифы выше, чем у других в вашем районе с аналогичным показателем качества. <b>Вероятно, меньше клиентов будут бронировать ваши услуги, что снизит ваш общий заработок.</b> Повышение показателя качества увеличит количество клиентов, готовых бронировать ваши услуги по этим тарифам, возможно, стоит сначала сделать это.',
        fr: 'Vos tarifs sont plus élevés que les autres dans votre région avec un score de qualité similaire. <b>Il est probable que moins de clients vous réserveront, réduisant vos revenus totaux.</b> Augmenter votre score de qualité augmenterait le nombre de clients prêts à vous réserver à ces tarifs, vous voudrez peut-être faire cela d\'abord.',
        de: 'Ihre Tarife sind höher als andere in Ihrer Region mit ähnlicher Qualitätsbewertung. <b>Es ist wahrscheinlich, dass weniger Kunden Sie buchen werden, was Ihre Gesamteinnahmen reduziert.</b> Eine Erhöhung Ihrer Qualitätsbewertung würde die Anzahl der Kunden erhöhen, die bereit sind, Sie zu diesen Tarifen zu buchen, Sie möchten das vielleicht zuerst tun.'
    },
    'Very Uncompetitive': {
        pt: 'Muito Não Competitivo',
        es: 'Muy No Competitivo',
        ru: 'Очень Неконкурентоспособный',
        fr: 'Très Non Compétitif',
        de: 'Sehr Nicht Wettbewerbsfähig'
    },
    'Your rates are much higher than others in your area with a similar quality score. <b>It is likely few clients will book you, reducing your total earnings.</b> Increasing your quality score would increase the number of clients willing to book you at these rates, you may want to do that first.': {
        pt: 'Suas taxas são muito mais altas que outras em sua área com pontuação de qualidade similar. <b>É provável que poucos clientes reservem seus serviços, reduzindo seus ganhos totais.</b> Aumentar sua pontuação de qualidade aumentaria o número de clientes dispostos a reservar com você nessas taxas, você pode querer fazer isso primeiro.',
        es: 'Sus tarifas son mucho más altas que otras en su área con una puntuación de calidad similar. <b>Es probable que pocos clientes lo reserven, reduciendo sus ganancias totales.</b> Aumentar su puntuación de calidad aumentaría el número de clientes dispuestos a reservarlo a estas tarifas, es posible que desee hacer eso primero.',
        ru: 'Ваши тарифы намного выше, чем у других в вашем районе с аналогичным показателем качества. <b>Вероятно, мало клиентов будут бронировать ваши услуги, что снизит ваш общий заработок.</b> Повышение показателя качества увеличит количество клиентов, готовых бронировать ваши услуги по этим тарифам, возможно, стоит сначала сделать это.',
        fr: 'Vos tarifs sont beaucoup plus élevés que les autres dans votre région avec un score de qualité similaire. <b>Il est probable que peu de clients vous réserveront, réduisant vos revenus totaux.</b> Augmenter votre score de qualité augmenterait le nombre de clients prêts à vous réserver à ces tarifs, vous voudrez peut-être faire cela d\'abord.',
        de: 'Ihre Tarife sind viel höher als andere in Ihrer Region mit ähnlicher Qualitätsbewertung. <b>Es ist wahrscheinlich, dass nur wenige Kunden Sie buchen werden, was Ihre Gesamteinnahmen reduziert.</b> Eine Erhöhung Ihrer Qualitätsbewertung würde die Anzahl der Kunden erhöhen, die bereit sind, Sie zu diesen Tarifen zu buchen, Sie möchten das vielleicht zuerst tun.'
    },
    'Not Valid': {
        pt: 'Não Válido',
        es: 'No Válido',
        ru: 'Недействительно',
        fr: 'Non Valide',
        de: 'Nicht Gültig'
    },
    'You set your rates too low.  Please increase your rates.': {
        pt: 'Você definiu suas taxas muito baixas. Por favor, aumente suas taxas.',
        es: 'Ha establecido sus tarifas demasiado bajas. Por favor, aumente sus tarifas.',
        ru: 'Вы установили слишком низкие тарифы. Пожалуйста, увеличьте ваши тарифы.',
        fr: 'Vous avez fixé vos tarifs trop bas. Veuillez augmenter vos tarifs.',
        de: 'Sie haben Ihre Tarife zu niedrig angesetzt. Bitte erhöhen Sie Ihre Tarife.'
    },
    'Rates Changed': {
        pt: 'Taxas Alteradas',
        es: 'Tarifas Cambiadas',
        ru: 'Тарифы Изменены',
        fr: 'Tarifs Modifiés',
        de: 'Tarife Geändert'
    },
    'Your rates will have changed for future Clients. Remember, anyone who has already booked with you will not be affected by this change.': {
        pt: 'Suas taxas terão mudado para futuros Clientes. Lembre-se, qualquer pessoa que já reservou com você não será afetada por esta mudança.',
        es: 'Sus tarifas habrán cambiado para futuros Clientes. Recuerde, cualquier persona que ya haya reservado con usted no se verá afectada por este cambio.',
        ru: 'Ваши тарифы изменятся для будущих Клиентов. Помните, что те, кто уже забронировал ваши услуги, не будут затронуты этим изменением.',
        fr: 'Vos tarifs auront changé pour les futurs Clients. N\'oubliez pas que toute personne ayant déjà réservé avec vous ne sera pas affectée par ce changement.',
        de: 'Ihre Tarife werden sich für zukünftige Kunden geändert haben. Denken Sie daran, dass jeder, der bereits bei Ihnen gebucht hat, von dieser Änderung nicht betroffen sein wird.'
    },

    'Services & Pay Rates': {
        pt: 'Serviços e Taxas de Pagamento',
        es: 'Servicios y Tarifas de Pago',
        ru: 'Услуги и Тарифы',
        fr: 'Services et Taux de Rémunération',
        de: 'Dienstleistungen und Vergütungssätze'
    },
    'These rates will be the default when you add jobs for a client or when they book you through your website: ': {
        pt: 'Estas taxas serão o padrão quando você adicionar trabalhos para um cliente ou quando eles reservarem através do seu site: ',
        es: 'Estas tarifas serán las predeterminadas cuando agregue trabajos para un cliente o cuando lo reserven a través de su sitio web: ',
        ru: 'Эти тарифы будут установлены по умолчанию, когда вы добавляете работу для клиента или когда они бронируют вас через ваш сайт: ',
        fr: 'Ces tarifs seront les tarifs par défaut lorsque vous ajoutez des travaux pour un client ou lorsqu\'ils vous réservent via votre site web: ',
        de: 'Diese Tarife werden standardmäßig verwendet, wenn Sie Aufträge für einen Kunden hinzufügen oder wenn sie Sie über Ihre Website buchen: '
    },
    'Cleaning Services': {
        pt: 'Serviços de Limpeza',
        es: 'Servicios de Limpieza',
        ru: 'Услуги по Уборке',
        fr: 'Services de Nettoyage',
        de: 'Reinigungsdienstleistungen'
    },
    'Maximum Length': {
        pt: 'Duração Máxima',
        es: 'Duración Máxima', 
        ru: 'Максимальная Продолжительность',
        fr: 'Durée Maximale',
        de: 'Maximale Dauer'
    },
    'Minimum Cost': {
        pt: 'Custo Mínimo',
        es: 'Costo Mínimo',
        ru: 'Минимальная Стоимость',
        fr: 'Coût Minimum',
        de: 'Mindestkosten'
    },
    'Other Services': {
        pt: 'Outros Serviços',
        es: 'Otros Servicios',
        ru: 'Другие Услуги',
        fr: 'Autres Services',
        de: 'Andere Dienstleistungen'
    },
    'Edit Service': {
        pt: 'Editar Serviço',
        es: 'Editar Servicio',
        ru: 'Редактировать Услугу',
        fr: 'Modifier le Service',
        de: 'Dienstleistung Bearbeiten'
    },
    'Your rates are: ': {
        pt: 'Suas taxas são: ',
        es: 'Sus tarifas son: ',
        ru: 'Ваши тарифы: ',
        fr: 'Vos tarifs sont: ',
        de: 'Ihre Tarife sind: '
    },
    'Block Off Schedule For: ': {
        pt: 'Bloquear Agenda Por: ',
        es: 'Bloquear Horario Por: ',
        ru: 'Заблокировать Расписание На: ',
        fr: 'Bloquer l\'Horaire Pour: ',
        de: 'Zeitplan Blockieren Für: '
    },
    ' Minutes': {
        pt: ' Minutos',
        es: ' Minutos',
        ru: ' Минут',
        fr: ' Minutes',
        de: ' Minuten'
    },
    'Billing Type: ': {
        pt: 'Tipo de Cobrança: ',
        es: 'Tipo de Facturación: ',
        ru: 'Тип Оплаты: ',
        fr: 'Type de Facturation: ',
        de: 'Abrechnungsart: '
    },
    'Add location': {
        pt: 'Adicionar localização',
        es: 'Agregar ubicación',
        ru: 'Добавить местоположение',
        fr: 'Ajouter un emplacement',
        de: 'Standort hinzufügen'
    },
    ' to see rates': {
        pt: ' para ver as taxas',
        es: ' para ver las tarifas',
        ru: ' чтобы увидеть тарифы',
        fr: ' pour voir les tarifs',
        de: ' um die Tarife zu sehen'
    },
    'After Cutoff Cancellation Fee: ': {
        pt: 'Taxa de Cancelamento Após o Prazo: ',
        es: 'Cargo por Cancelación Después del Límite: ',
        ru: 'Плата за Отмену После Крайнего Срока: ',
        fr: 'Frais d\'Annulation Après la Date Limite: ',
        de: 'Stornierungsgebühr Nach Fristablauf: '
    },
    '* This is a default service. ': {
        pt: '* Este é um serviço padrão. ',
        es: '* Este es un servicio predeterminado. ',
        ru: '* Это стандартная услуга. ',
        fr: '* Ceci est un service par défaut. ',
        de: '* Dies ist ein Standarddienst. '
    },
    'Service Updated': {
        pt: 'Serviço Atualizado',
        es: 'Servicio Actualizado',
        ru: 'Услуга Обновлена',
        fr: 'Service Mis à Jour',
        de: 'Dienstleistung Aktualisiert'
    },
    'Delete Service?': {
        pt: 'Excluir Serviço?',
        es: '¿Eliminar Servicio?',
        ru: 'Удалить Услугу?',
        fr: 'Supprimer le Service?',
        de: 'Dienstleistung Löschen?'
    },
    'Service Deleted': {
        pt: 'Serviço Excluído',
        es: 'Servicio Eliminado',
        ru: 'Услуга Удалена',
        fr: 'Service Supprimé',
        de: 'Dienstleistung Gelöscht'
    },
    'Unable to Delete': {
        pt: 'Não é Possível Excluir',
        es: 'No se Puede Eliminar',
        ru: 'Невозможно Удалить',
        fr: 'Impossible de Supprimer',
        de: 'Löschen Nicht Möglich'
    },
    'This is a default service and can\'t be deleted. Default services are the best way to get more work.': {
        pt: 'Este é um serviço padrão e não pode ser excluído. Serviços padrão são a melhor maneira de conseguir mais trabalho.',
        es: 'Este es un servicio predeterminado y no se puede eliminar. Los servicios predeterminados son la mejor manera de conseguir más trabajo.',
        ru: 'Это стандартная услуга, и ее нельзя удалить. Стандартные услуги - лучший способ получить больше работы.',
        fr: 'C\'est un service par défaut et ne peut pas être supprimé. Les services par défaut sont le meilleur moyen d\'obtenir plus de travail.',
        de: 'Dies ist ein Standarddienst und kann nicht gelöscht werden. Standarddienste sind der beste Weg, um mehr Arbeit zu bekommen.'
    },
    'Undetermined': {
        pt: 'Indeterminado',
        es: 'Indeterminado',
        ru: 'Неопределено',
        fr: 'Indéterminé',
        de: 'Unbestimmt'
    },

    'Hourly': {
        pt: 'Por Hora',
        es: 'Por Hora',
        ru: 'Почасовая',
        fr: 'Par Heure',
        de: 'Stündlich'
    },
    'Price Later': {
        pt: 'Preço Depois',
        es: 'Precio Después',
        ru: 'Цена Позже',
        fr: 'Prix Plus Tard',
        de: 'Preis Später'
    },
    'Be Careful Changing Rates': {
        pt: 'Tenha Cuidado ao Alterar as Taxas',
        es: 'Tenga Cuidado al Cambiar las Tarifas',
        ru: 'Будьте Осторожны при Изменении Тарифов',
        fr: 'Soyez Prudent en Modifiant les Tarifs',
        de: 'Seien Sie Vorsichtig beim Ändern der Tarife'
    },
    'Increase rates with caution. Most businesses change do this less than 1x per year. Volatile rates can cause you to lose customers. Speak with the Concierge if you need help.': {
        pt: 'Aumente as taxas com cautela. A maioria das empresas faz isso menos de 1x por ano. Taxas voláteis podem fazer você perder clientes. Fale com o Concierge se precisar de ajuda.',
        es: 'Aumente las tarifas con precaución. La mayoría de las empresas lo hacen menos de 1 vez al año. Las tarifas volátiles pueden hacer que pierda clientes. Hable con el Concierge si necesita ayuda.',
        ru: 'Повышайте тарифы с осторожностью. Большинство компаний делают это реже 1 раза в год. Нестабильные тарифы могут привести к потере клиентов. Обратитесь к консьержу, если вам нужна помощь.',
        fr: 'Augmentez les tarifs avec prudence. La plupart des entreprises le font moins d\'une fois par an. Des tarifs volatils peuvent vous faire perdre des clients. Parlez au Concierge si vous avez besoin d\'aide.',
        de: 'Erhöhen Sie die Tarife mit Vorsicht. Die meisten Unternehmen tun dies weniger als 1x pro Jahr. Volatile Tarife können zum Verlust von Kunden führen. Sprechen Sie mit dem Concierge, wenn Sie Hilfe benötigen.'
    },
    'Please Add Location': {
        pt: 'Por Favor Adicione Localização',
        es: 'Por Favor Agregue Ubicación',
        ru: 'Пожалуйста, Добавьте Местоположение',
        fr: 'Veuillez Ajouter un Emplacement',
        de: 'Bitte Standort Hinzufügen'
    },
    'Add your location to see how your rates compare to the average in your area.': {
        pt: 'Adicione sua localização para ver como suas taxas se comparam à média em sua área.',
        es: 'Agregue su ubicación para ver cómo sus tarifas se comparan con el promedio en su área.',
        ru: 'Добавьте свое местоположение, чтобы увидеть, как ваши тарифы сравниваются со средними в вашем районе.',
        fr: 'Ajoutez votre emplacement pour voir comment vos tarifs se comparent à la moyenne dans votre région.',
        de: 'Fügen Sie Ihren Standort hinzu, um zu sehen, wie Ihre Tarife im Vergleich zum Durchschnitt in Ihrer Region sind.'
    },
    'Contact ': {
        pt: 'Contato ',
        es: 'Contacto ',
        ru: 'Контакт ',
        fr: 'Contact ',
        de: 'Kontakt '
    },
    'We generate a temporary phone number for each job. ': {
        pt: 'Geramos um número de telefone temporário para cada trabalho. ',
        es: 'Generamos un número de teléfono temporal para cada trabajo. ',
        ru: 'Мы создаем временный номер телефона для каждой работы. ',
        fr: 'Nous générons un numéro de téléphone temporaire pour chaque travail. ',
        de: 'Wir generieren eine temporäre Telefonnummer für jeden Auftrag. '
    },
    ' will not see your phone number.': {
        pt: ' não verá seu número de telefone.',
        es: ' no verá su número de teléfono.',
        ru: ' не увидит ваш номер телефона.',
        fr: ' ne verra pas votre numéro de téléphone.',
        de: ' wird Ihre Telefonnummer nicht sehen.'
    },
    'You can only call using the phone number on your account:': {
        pt: 'Você só pode ligar usando o número de telefone em sua conta:',
        es: 'Solo puede llamar usando el número de teléfono en su cuenta:',
        ru: 'Вы можете звонить только с номера телефона в вашей учетной записи:',
        fr: 'Vous ne pouvez appeler qu\'avec le numéro de téléphone de votre compte:',
        de: 'Sie können nur mit der Telefonnummer in Ihrem Konto anrufen:'
    },
    'You can only call using the phone number on your account. Please add a phone number below.': {
        pt: 'Você só pode ligar usando o número de telefone em sua conta. Por favor, adicione um número de telefone abaixo.',
        es: 'Solo puede llamar usando el número de teléfono en su cuenta. Por favor, agregue un número de teléfono abajo.',
        ru: 'Вы можете звонить только с номера телефона в вашей учетной записи. Пожалуйста, добавьте номер телефона ниже.',
        fr: 'Vous ne pouvez appeler qu\'avec le numéro de téléphone de votre compte. Veuillez ajouter un numéro de téléphone ci-dessous.',
        de: 'Sie können nur mit der Telefonnummer in Ihrem Konto anrufen. Bitte fügen Sie unten eine Telefonnummer hinzu.'
    },
    '\'s phone number: ': {
        pt: ' número de telefone: ',
        es: ' número de teléfono: ',
        ru: ' номер телефона: ',
        fr: ' numéro de téléphone: ',
        de: ' Telefonnummer: '
    },
    'Text': {
        pt: 'Mensagem',
        es: 'Mensaje',
        ru: 'Сообщение',
        fr: 'Message',
        de: 'Nachricht'
    },
    'Job not found, please talk to the property manager.': {
        pt: 'Trabalho não encontrado, por favor fale com o gerente da propriedade.',
        es: 'Trabajo no encontrado, por favor hable con el administrador de la propiedad.',
        ru: 'Работа не найдена, пожалуйста, обратитесь к менеджеру недвижимости.',
        fr: 'Travail non trouvé, veuillez parler au gestionnaire de la propriété.',
        de: 'Auftrag nicht gefunden, bitte sprechen Sie mit dem Immobilienverwalter.'
    },
    ' uses TIDY to manage their home service jobs. ': {
        pt: ' usa TIDY para gerenciar seus trabalhos de serviço doméstico. ',
        es: ' usa TIDY para administrar sus trabajos de servicio doméstico. ',
        ru: ' использует TIDY для управления работами по обслуживанию дома. ',
        fr: ' utilise TIDY pour gérer leurs travaux de service à domicile. ',
        de: ' verwendet TIDY zur Verwaltung ihrer Haushaltsdienstleistungen. '
    },
    'Job marked as complete.': {
        pt: 'Trabalho marcado como concluído.',
        es: 'Trabajo marcado como completado.',
        ru: 'Работа отмечена как завершенная.',
        fr: 'Travail marqué comme terminé.',
        de: 'Auftrag als abgeschlossen markiert.'
    },
    'Client was told you are <b>on the way</b>.': {
        pt: 'O cliente foi informado que você está <b>a caminho</b>.',
        es: 'Se le informó al cliente que usted está <b>en camino</b>.',
        ru: 'Клиенту сообщили, что вы <b>в пути</b>.',
        fr: 'Le client a été informé que vous êtes <b>en route</b>.',
        de: 'Der Kunde wurde informiert, dass Sie <b>unterwegs</b> sind.'
    },
    'Client was told you have <b>arrived</b>.': {
        pt: 'O cliente foi informado que você <b>chegou</b>.',
        es: 'Se le informó al cliente que usted ha <b>llegado</b>.',
        ru: 'Клиенту сообщили, что вы <b>прибыли</b>.',
        fr: 'Le client a été informé que vous êtes <b>arrivé</b>.',
        de: 'Der Kunde wurde informiert, dass Sie <b>angekommen</b> sind.'
    },
    ' has a guest checking in ': {
        pt: ' tem um hóspede fazendo check-in ',
        es: ' tiene un huésped haciendo check-in ',
        ru: ' ожидается заезд гостя ',
        fr: ' a un invité qui arrive ',
        de: ' hat einen Gast, der eincheckt '
    },
    'at ': {
        pt: 'às ',
        es: 'a las ',
        ru: 'в ',
        fr: 'à ',
        de: 'um '
    },
    'Priced later': {
        pt: 'Preço depois',
        es: 'Precio después',
        ru: 'Цена позже',
        fr: 'Prix plus tard',
        de: 'Preis später'
    },
    'Cancel Job': {
        pt: 'Cancelar Trabalho',
        es: 'Cancelar Trabajo',
        ru: 'Отменить Работу',
        fr: 'Annuler le Travail',
        de: 'Arbeit Stornieren'
    },
    'Please ': {
        pt: 'Por favor ',
        es: 'Por favor ',
        ru: 'Пожалуйста ',
        fr: 'S\'il vous plaît ',
        de: 'Bitte '
    },
    ' to view messages.': {
        pt: ' para ver mensagens.',
        es: ' para ver mensajes.',
        ru: ' чтобы просмотреть сообщения.',
        fr: ' pour voir les messages.',
        de: ' um Nachrichten anzuzeigen.'
    },
    'log in': {
        pt: 'entrar',
        es: 'iniciar sesión',
        ru: 'войти',
        fr: 'connectez-vous',
        de: 'einloggen'
    },
    'create an account': {
        pt: 'criar uma conta',
        es: 'crear una cuenta',
        ru: 'создать аккаунт',
        fr: 'créer un compte',
        de: 'Konto erstellen'
    },
    '<i>See parking notes after notifying that you are on the way.</i>': {
        pt: '<i>Veja as notas de estacionamento após notificar que está a caminho.</i>',
        es: '<i>Ver notas de estacionamiento después de notificar que está en camino.</i>',
        ru: '<i>Посмотрите примечания о парковке после уведомления о том, что вы в пути.</i>',
        fr: '<i>Voir les notes de stationnement après avoir notifié que vous êtes en route.</i>',
        de: '<i>Siehe Parkhinweise nach Benachrichtigung, dass Sie unterwegs sind.</i>'
    },
    'Please Log In': {
        pt: 'Por Favor Entre',
        es: 'Por Favor Inicie Sesión',
        ru: 'Пожалуйста Войдите',
        fr: 'Veuillez Vous Connecter',
        de: 'Bitte Einloggen'
    },
    'You must be logged into the TIDY Pro app to use this feature.': {
        pt: 'Você deve estar logado no aplicativo TIDY Pro para usar este recurso.',
        es: 'Debe estar conectado en la aplicación TIDY Pro para usar esta función.',
        ru: 'Вы должны войти в приложение TIDY Pro, чтобы использовать эту функцию.',
        fr: 'Vous devez être connecté à l\'application TIDY Pro pour utiliser cette fonctionnalité.',
        de: 'Sie müssen in der TIDY Pro App eingeloggt sein, um diese Funktion zu nutzen.'
    },
    'We will notify': {
        pt: 'Nós notificaremos',
        es: 'Notificaremos',
        ru: 'Мы уведомим',
        fr: 'Nous notifierons',
        de: 'Wir werden benachrichtigen'
    },
    'Job Cancelled': {
        pt: 'Trabalho Cancelado',
        es: 'Trabajo Cancelado',
        ru: 'Работа Отменена',
        fr: 'Travail Annulé',
        de: 'Arbeit Storniert'
    },
    'Please enter a price': {
        pt: 'Por favor, insira um preço',
        es: 'Por favor, ingrese un precio',
        ru: 'Пожалуйста, введите цену',
        fr: 'Veuillez entrer un prix',
        de: 'Bitte geben Sie einen Preis ein'
    },
    'Please enter a length': {
        pt: 'Por favor, insira uma duração',
        es: 'Por favor, ingrese una duración',
        ru: 'Пожалуйста, введите продолжительность',
        fr: 'Veuillez entrer une durée',
        de: 'Bitte geben Sie eine Dauer ein'
    },
    'Job Length': {
        pt: 'Duração do Trabalho',
        es: 'Duración del Trabajo',
        ru: 'Продолжительность Работы',
        fr: 'Durée du Travail',
        de: 'Arbeitsdauer'
    },

    'To-Do List': {
        pt: 'Lista de Tarefas',
        es: 'Lista de Tareas',
        ru: 'Список Дел',
        fr: 'Liste des Tâches',
        de: 'Aufgabenliste'
    },
    'uses TIDY to manage their home service jobs.': {
        pt: 'usa o TIDY para gerenciar seus serviços domésticos.',
        es: 'usa TIDY para administrar sus servicios domésticos.',
        ru: 'использует TIDY для управления домашними услугами.',
        fr: 'utilise TIDY pour gérer ses services à domicile.',
        de: 'verwendet TIDY zur Verwaltung ihrer Haushaltsdienstleistungen.'
    },
    'Address: ': {
        pt: 'Endereço: ',
        es: 'Dirección: ',
        ru: 'Адрес: ',
        fr: 'Adresse: ',
        de: 'Adresse: '
    },
    'Client: ': {
        pt: 'Cliente: ',
        es: 'Cliente: ',
        ru: 'Клиент: ',
        fr: 'Client: ',
        de: 'Kunde: '
    },
    'Contact': {
        pt: 'Contato',
        es: 'Contacto',
        ru: 'Контакт',
        fr: 'Contact',
        de: 'Kontakt'
    },
    'Property Map': {
        pt: 'Mapa da Propriedade',
        es: 'Mapa de la Propiedad',
        ru: 'Карта Объекта',
        fr: 'Plan de la Propriété',
        de: 'Objektplan'
    },
    'Level': {
        pt: 'Nível',
        es: 'Nivel',
        ru: 'Уровень',
        fr: 'Niveau',
        de: 'Ebene'
    },
    'Exterior': {
        pt: 'Exterior',
        es: 'Exterior',
        ru: 'Экстерьер',
        fr: 'Extérieur',
        de: 'Außenbereich'
    },

    'Enter your info to send to ': {
        pt: 'Digite suas informações para enviar para ',
        es: 'Ingrese su información para enviar a ',
        ru: 'Введите свои данные для отправки ',
        fr: 'Entrez vos informations à envoyer à ',
        de: 'Geben Sie Ihre Informationen ein, die Sie senden möchten an '
    },
    'Complete To-Do List': {
        pt: 'Completar Lista de Tarefas',
        es: 'Completar Lista de Tareas',
        ru: 'Завершить Список Дел',
        fr: 'Compléter la Liste des Tâches',
        de: 'To-Do-Liste Abschließen'
    },
    'Job Detail': {
        pt: 'Detalhes do Trabalho',
        es: 'Detalles del Trabajo',
        ru: 'Детали Работы',
        fr: 'Détails du Travail',
        de: 'Job-Details'
    },
    'Please enter the price you charge': {
        pt: 'Por favor, insira o preço que você cobra',
        es: 'Por favor, ingrese el precio que cobra',
        ru: 'Пожалуйста, введите вашу цену',
        fr: 'Veuillez entrer le prix que vous facturez',
        de: 'Bitte geben Sie Ihren Preis ein'
    },
    'This will send an email to ': {
        pt: 'Isto enviará um email para ',
        es: 'Esto enviará un correo electrónico a ',
        ru: 'Это отправит электронное письмо ',
        fr: 'Cela enverra un e-mail à ',
        de: 'Dies sendet eine E-Mail an '
    },
    ' with a list of what was done and not done.': {
        pt: ' com uma lista do que foi feito e não foi feito.',
        es: ' con una lista de lo que se hizo y no se hizo.',
        ru: ' со списком того, что было сделано и не сделано.',
        fr: ' avec une liste de ce qui a été fait et pas fait.',
        de: ' mit einer Liste was erledigt wurde und was nicht.'
    },
    'Get New Clients from TIDY': {
        pt: 'Obtenha Novos Clientes da TIDY',
        es: 'Obtenga Nuevos Clientes de TIDY',
        ru: 'Получите Новых Клиентов от TIDY',
        fr: 'Obtenez de Nouveaux Clients de TIDY',
        de: 'Neue Kunden von TIDY gewinnen'
    },
    '(1) Improve your Quality Scores. Clients tend to book with pros with great scores.': {
        pt: '(1) Melhore suas Pontuações de Qualidade. Os clientes tendem a reservar com profissionais com ótimas pontuações.',
        es: '(1) Mejore sus Puntajes de Calidad. Los clientes tienden a reservar con profesionales con excelentes puntajes.',
        ru: '(1) Улучшите свои Показатели Качества. Клиенты предпочитают бронировать специалистов с высокими оценками.',
        fr: '(1) Améliorez vos Scores de Qualité. Les clients ont tendance à réserver avec des pros ayant d\'excellents scores.',
        de: '(1) Verbessern Sie Ihre Qualitätsbewertungen. Kunden buchen bevorzugt bei Profis mit guten Bewertungen.'
    },
    '(2) Make rates reasonable. Try lowering your rate. You can always raise them again after boosting your scores.': {
        pt: '(2) Mantenha as taxas razoáveis. Tente reduzir sua taxa. Você sempre pode aumentá-las novamente depois de melhorar suas pontuações.',
        es: '(2) Haga que las tarifas sean razonables. Intente bajar su tarifa. Siempre puede subirlas nuevamente después de mejorar sus puntajes.',
        ru: '(2) Установите разумные тарифы. Попробуйте снизить ваш тариф. Вы всегда можете повысить их снова после улучшения показателей.',
        fr: '(2) Rendez vos tarifs raisonnables. Essayez de baisser votre tarif. Vous pourrez toujours les augmenter après avoir amélioré vos scores.',
        de: '(2) Setzen Sie angemessene Preise. Versuchen Sie, Ihren Tarif zu senken. Sie können ihn später nach Verbesserung Ihrer Bewertungen wieder erhöhen.'
    },
    '(3) Accept credit card. Many customers love paying by credit card so accepting them can increase business. ': {
        pt: '(3) Aceite cartão de crédito. Muitos clientes adoram pagar com cartão de crédito, então aceitá-los pode aumentar os negócios.',
        es: '(3) Acepte tarjeta de crédito. Muchos clientes adoran pagar con tarjeta de crédito, así que aceptarlas puede aumentar el negocio.',
        ru: '(3) Принимайте кредитные карты. Многие клиенты любят платить кредитной картой, поэтому их прием может увеличить бизнес.',
        fr: '(3) Acceptez les cartes de crédit. De nombreux clients aiment payer par carte de crédit, donc les accepter peut augmenter l\'activité.',
        de: '(3) Akzeptieren Sie Kreditkarten. Viele Kunden zahlen gerne mit Kreditkarte, daher kann deren Akzeptanz das Geschäft steigern.'
    },
    '(4) Collect reviews. Get your existing Clients to review you to make your business look better.': {
        pt: '(4) Colete avaliações. Peça aos seus Clientes existentes para avaliá-lo para melhorar a aparência do seu negócio.',
        es: '(4) Recopile reseñas. Haga que sus Clientes existentes lo reseñen para que su negocio se vea mejor.',
        ru: '(4) Собирайте отзывы. Попросите существующих Клиентов оставить отзыв, чтобы улучшить имидж вашего бизнеса.',
        fr: '(4) Collectez des avis. Demandez à vos Clients existants de vous évaluer pour améliorer l\'image de votre entreprise.',
        de: '(4) Sammeln Sie Bewertungen. Lassen Sie sich von Ihren bestehenden Kunden bewerten, um Ihr Geschäft attraktiver zu machen.'
    },
    '(5) Increase your availability. Make yourself bookable during the times Clients most want.': {
        pt: '(5) Aumente sua disponibilidade. Torne-se disponível nos horários que os Clientes mais desejam.',
        es: '(5) Aumente su disponibilidad. Hágase reservable durante los horarios que los Clientes más desean.',
        ru: '(5) Увеличьте свою доступность. Сделайте себя доступным для бронирования в то время, когда Клиенты больше всего хотят.',
        fr: '(5) Augmentez votre disponibilité. Rendez-vous réservable pendant les heures les plus demandées par les Clients.',
        de: '(5) Erhöhen Sie Ihre Verfügbarkeit. Machen Sie sich zu den von Kunden gewünschten Zeiten buchbar.'
    },
    '(6) Increase max drive time. Drive further to get more business, you can shrink it later after you boost your scores.': {
        pt: '(6) Aumente o tempo máximo de viagem. Dirija mais longe para conseguir mais negócios, você pode reduzir depois de melhorar suas pontuações.',
        es: '(6) Aumente el tiempo máximo de conducción. Conduzca más lejos para obtener más negocios, puede reducirlo más tarde después de mejorar sus puntajes.',
        ru: '(6) Увеличьте максимальное время в пути. Езжайте дальше для получения большего количества заказов, вы можете сократить его позже после улучшения показателей.',
        fr: '(6) Augmentez le temps de trajet maximum. Conduisez plus loin pour avoir plus d\'activité, vous pourrez le réduire plus tard après avoir amélioré vos scores.',
        de: '(6) Erhöhen Sie die maximale Fahrzeit. Fahren Sie weiter für mehr Aufträge, Sie können sie später nach Verbesserung Ihrer Bewertungen wieder reduzieren.'
    },
    'Clients from TIDY are a great way to get new Clients and make more money.': {
        pt: 'Clientes da TIDY são uma ótima maneira de conseguir novos Clientes e ganhar mais dinheiro.',
        es: 'Los clientes de TIDY son una excelente manera de obtener nuevos Clientes y ganar más dinero.',
        ru: 'Клиенты от TIDY - отличный способ получить новых Клиентов и заработать больше денег.',
        fr: 'Les clients de TIDY sont un excellent moyen d\'obtenir de nouveaux Clients et de gagner plus d\'argent.',
        de: 'Kunden von TIDY sind eine großartige Möglichkeit, neue Kunden zu gewinnen und mehr Geld zu verdienen.'
    },
    '(1) Complete a certification process, then Clients who use TIDY can book directly on your schedule or request you.': {
        pt: '(1) Complete um processo de certificação, então os Clientes que usam TIDY podem reservar diretamente em sua agenda ou solicitar você.',
        es: '(1) Complete un proceso de certificación, luego los Clientes que usan TIDY pueden reservar directamente en su horario o solicitarlo.',
        ru: '(1) Пройдите процесс сертификации, затем Клиенты, использующие TIDY, смогут бронировать напрямую по вашему расписанию или запрашивать вас.',
        fr: '(1) Complétez un processus de certification, puis les Clients utilisant TIDY pourront réserver directement sur votre agenda ou vous demander.',
        de: '(1) Schließen Sie einen Zertifizierungsprozess ab, dann können TIDY-Kunden direkt in Ihrem Zeitplan buchen oder Sie anfragen.'
    },
    '(2) You set your own rates, max driving distance, schedule, and other terms.': {
        pt: '(2) Você define suas próprias taxas, distância máxima de direção, agenda e outros termos.',
        es: '(2) Usted establece sus propias tarifas, distancia máxima de conducción, horario y otros términos.',
        ru: '(2) Вы устанавливаете свои собственные тарифы, максимальное расстояние поездки, расписание и другие условия.',
        fr: '(2) Vous fixez vos propres tarifs, distance maximale de conduite, horaires et autres conditions.',
        de: '(2) Sie legen Ihre eigenen Preise, maximale Fahrentfernung, Zeitplan und andere Bedingungen fest.'
    },
    '(3) Automatically compete to win new Clients based on your scores and rates.': {
        pt: '(3) Compita automaticamente para ganhar novos Clientes com base em suas pontuações e taxas.',
        es: '(3) Compita automáticamente para ganar nuevos Clientes según sus puntajes y tarifas.',
        ru: '(3) Автоматически конкурируйте за новых Клиентов на основе ваших показателей и тарифов.',
        fr: '(3) Participez automatiquement pour gagner de nouveaux Clients en fonction de vos scores et tarifs.',
        de: '(3) Konkurrieren Sie automatisch um neue Kunden basierend auf Ihren Bewertungen und Preisen.'
    },
    'It\'s easy with no upfront costs. Just pay a part of what you would earn.': {
        pt: 'É fácil sem custos iniciais. Apenas pague uma parte do que você ganharia.',
        es: 'Es fácil sin costos iniciales. Solo pague una parte de lo que ganaría.',
        ru: 'Это легко без предварительных затрат. Просто платите часть того, что вы заработаете.',
        fr: 'C\'est facile sans frais initiaux. Payez simplement une partie de ce que vous gagneriez.',
        de: 'Es ist einfach ohne Vorabkosten. Zahlen Sie einfach einen Teil dessen, was Sie verdienen würden.'
    },

    'Send jobs to your team members or join other teams to get jobs from them!': {
        pt: 'Envie trabalhos para os membros da sua equipe ou junte-se a outras equipes para receber trabalhos deles!',
        es: '¡Envíe trabajos a los miembros de su equipo o únase a otros equipos para recibir trabajos de ellos!',
        ru: 'Отправляйте задания членам своей команды или присоединяйтесь к другим командам, чтобы получать от них задания!',
        fr: 'Envoyez des travaux aux membres de votre équipe ou rejoignez d\'autres équipes pour obtenir des travaux d\'eux !',
        de: 'Senden Sie Aufträge an Ihre Teammitglieder oder treten Sie anderen Teams bei, um Aufträge von ihnen zu erhalten!'
    },
    'invited you to their team. By joining, you agree that you are a subcontractor or employee of': {
        pt: 'convidou você para a equipe deles. Ao se juntar, você concorda que é um subcontratado ou funcionário de',
        es: 'le invitó a su equipo. Al unirse, acepta que es un subcontratista o empleado de',
        ru: 'пригласил вас в свою команду. Присоединяясь, вы соглашаетесь, что являетесь субподрядчиком или сотрудником',
        fr: 'vous a invité dans son équipe. En rejoignant, vous acceptez d\'être un sous-traitant ou un employé de',
        de: 'hat Sie in ihr Team eingeladen. Mit dem Beitritt stimmen Sie zu, dass Sie ein Subunternehmer oder Mitarbeiter von'
    },
    '\'s business, and they are responsible for safety, legal, insurance, and employment obligations as well as the': {
        pt: 'e eles são responsáveis pela segurança, obrigações legais, de seguro e trabalhistas, bem como pelo',
        es: 'y ellos son responsables de la seguridad, obligaciones legales, de seguro y laborales, así como del',
        ru: 'и они несут ответственность за безопасность, юридические, страховые и трудовые обязательства, а также за',
        fr: 'et ils sont responsables de la sécurité, des obligations légales, d\'assurance et d\'emploi ainsi que de',
        de: 'sind und sie für Sicherheit, rechtliche, Versicherungs- und Beschäftigungsverpflichtungen sowie für die'
    },
    'My Team': {
        pt: 'Minha Equipe',
        es: 'Mi Equipo',
        ru: 'Моя Команда',
        fr: 'Mon Équipe',
        de: 'Mein Team'
    },
    'Members': {
        pt: 'Membros',
        es: 'Miembros',
        ru: 'Участники',
        fr: 'Membres',
        de: 'Mitglieder'
    },
    ' (pending)': {
        pt: ' (pendente)',
        es: ' (pendiente)',
        ru: ' (ожидается)',
        fr: ' (en attente)',
        de: ' (ausstehend)'
    },
    'Invite Team Member': {
        pt: 'Convidar Membro da Equipe',
        es: 'Invitar Miembro del Equipo',
        ru: 'Пригласить Члена Команды',
        fr: 'Inviter un Membre d\'Équipe',
        de: 'Teammitglied Einladen'
    },
    'Teams I Am On': {
        pt: 'Equipes das Quais Faço Parte',
        es: 'Equipos en los Que Estoy',
        ru: 'Команды, в Которых Я Состою',
        fr: 'Équipes Dont Je Fais Partie',
        de: 'Teams, in Denen Ich Bin'
    },
    'Set Up Team Payments': {
        pt: 'Configurar Pagamentos da Equipe',
        es: 'Configurar Pagos del Equipo',
        ru: 'Настроить Командные Платежи',
        fr: 'Configurer les Paiements d\'Équipe',
        de: 'Team-Zahlungen Einrichten'
    },
    'Resend Invite?': {
        pt: 'Reenviar Convite?',
        es: '¿Reenviar Invitación?',
        ru: 'Отправить Приглашение Повторно?',
        fr: 'Renvoyer l\'Invitation?',
        de: 'Einladung Erneut Senden?'
    },
    'Team Member Invite Resent': {
        pt: 'Convite de Membro da Equipe Reenviado',
        es: 'Invitación de Miembro del Equipo Reenviada',
        ru: 'Приглашение Члена Команды Отправлено Повторно',
        fr: 'Invitation de Membre d\'Équipe Renvoyée',
        de: 'Team-Mitglied Einladung Erneut Gesendet'
    },
    'Resend an invite to': {
        pt: 'Reenviar um convite para',
        es: 'Reenviar una invitación a',
        ru: 'Отправить повторное приглашение',
        fr: 'Renvoyer une invitation à',
        de: 'Eine Einladung erneut senden an'
    },
    'We resent an email to': {
        pt: 'Reenviamos um email para',
        es: 'Reenviamos un correo electrónico a',
        ru: 'Мы отправили повторное письмо',
        fr: 'Nous avons renvoyé un email à',
        de: 'Wir haben eine E-Mail erneut gesendet an'
    },
    'Cancel Invite?': {
        pt: 'Cancelar Convite?',
        es: '¿Cancelar Invitación?',
        ru: 'Отменить Приглашение?',
        fr: 'Annuler l\'Invitation?',
        de: 'Einladung Abbrechen?'
    },
    'Cancel an invite to': {
        pt: 'Cancelar um convite para',
        es: 'Cancelar una invitación a',
        ru: 'Отменить приглашение для',
        fr: 'Annuler une invitation à',
        de: 'Eine Einladung abbrechen für'
    },
    'Team Member Invite Cancelled': {
        pt: 'Convite de Membro da Equipe Cancelado',
        es: 'Invitación de Miembro del Equipo Cancelada',
        ru: 'Приглашение Члена Команды Отменено',
        fr: 'Invitation de Membre d\'Équipe Annulée',
        de: 'Team-Mitglied Einladung Abgebrochen'
    },
    'We cancelled the invite to': {
        pt: 'Cancelamos o convite para',
        es: 'Cancelamos la invitación a',
        ru: 'Мы отменили приглашение для',
        fr: 'Nous avons annulé l\'invitation à',
        de: 'Wir haben die Einladung abgebrochen für'
    },
    'Accept invite to': {
        pt: 'Aceitar convite para',
        es: 'Aceptar invitación a',
        ru: 'Принять приглашение в',
        fr: 'Accepter l\'invitation à',
        de: 'Einladung annehmen zu'
    },
    '\'s team?': {
        pt: ' equipe?',
        es: ' equipo?',
        ru: ' команду?',
        fr: ' équipe?',
        de: ' Team?'
    },
    'You are now part of': {
        pt: 'Agora você faz parte da',
        es: 'Ahora eres parte del',
        ru: 'Теперь вы часть',
        fr: 'Vous faites maintenant partie de',
        de: 'Sie sind jetzt Teil von'
    },
    '\'s team!': {
        pt: ' equipe!',
        es: ' ¡equipo!',
        ru: ' команды!',
        fr: ' équipe!',
        de: ' Team!'
    },
    'Reject invite to': {
        pt: 'Rejeitar convite para',
        es: 'Rechazar invitación a',
        ru: 'Отклонить приглашение в',
        fr: 'Refuser l\'invitation à',
        de: 'Einladung ablehnen zu'
    },
    'You rejected': {
        pt: 'Você rejeitou',
        es: 'Rechazaste',
        ru: 'Вы отклонили',
        fr: 'Vous avez refusé',
        de: 'Sie haben abgelehnt'
    },
    '\'s invite.': {
        pt: ' o convite.',
        es: ' la invitación.',
        ru: ' приглашение.',
        fr: ' l\'invitation.',
        de: ' die Einladung.'
    },
    'You haven\'t added any team members yet. Add one to delegate them a job.': {
        pt: 'Você ainda não adicionou membros à equipe. Adicione um para delegar um trabalho.',
        es: 'No has añadido miembros al equipo todavía. Añade uno para delegarles un trabajo.',
        ru: 'Вы еще не добавили членов команды. Добавьте одного, чтобы делегировать работу.',
        fr: 'Vous n\'avez pas encore ajouté de membres à l\'équipe. Ajoutez-en un pour leur déléguer un travail.',
        de: 'Sie haben noch keine Teammitglieder hinzugefügt. Fügen Sie eines hinzu, um ihnen Arbeit zu delegieren.'
    },
    'Eligible Team Members': {
        pt: 'Membros da Equipe Elegíveis',
        es: 'Miembros del Equipo Elegibles',
        ru: 'Подходящие Члены Команды',
        fr: 'Membres d\'Équipe Éligibles',
        de: 'Berechtigte Teammitglieder'
    },
    'No team members eligible for this job.': {
        pt: 'Nenhum membro da equipe elegível para este trabalho.',
        es: 'No hay miembros del equipo elegibles para este trabajo.',
        ru: 'Нет подходящих членов команды для этой работы.',
        fr: 'Aucun membre d\'équipe éligible pour ce travail.',
        de: 'Keine Teammitglieder für diese Arbeit berechtigt.'
    },
    'Select Team Member': {
        pt: 'Selecionar Membro da Equipe',
        es: 'Seleccionar Miembro del Equipo',
        ru: 'Выбрать Члена Команды',
        fr: 'Sélectionner un Membre d\'Équipe',
        de: 'Teammitglied Auswählen'
    },
    'Please select a team member.': {
        pt: 'Por favor, selecione um membro da equipe.',
        es: 'Por favor, selecciona un miembro del equipo.',
        ru: 'Пожалуйста, выберите члена команды.',
        fr: 'Veuillez sélectionner un membre d\'équipe.',
        de: 'Bitte wählen Sie ein Teammitglied aus.'
    },
    'Add New Team Member': {
        pt: 'Adicionar Novo Membro da Equipe',
        es: 'Añadir Nuevo Miembro del Equipo',
        ru: 'Добавить Нового Члена Команды',
        fr: 'Ajouter un Nouveau Membre d\'Équipe',
        de: 'Neues Teammitglied Hinzufügen'
    },
    'I understand I\'m responsible for jobs I delegate to team members, including insurance, safety, compliance, legal, and employment obligations.': {
        pt: 'Entendo que sou responsável pelos trabalhos que delego aos membros da equipe, incluindo seguro, segurança, conformidade, obrigações legais e trabalhistas.',
        es: 'Entiendo que soy responsable de los trabajos que delego a los miembros del equipo, incluyendo seguros, seguridad, cumplimiento, obligaciones legales y laborales.',
        ru: 'Я понимаю, что несу ответственность за работу, которую делегирую членам команды, включая страхование, безопасность, соответствие требованиям, юридические и трудовые обязательства.',
        fr: 'Je comprends que je suis responsable des travaux que je délègue aux membres de l\'équipe, y compris les obligations d\'assurance, de sécurité, de conformité, légales et d\'emploi.',
        de: 'Ich verstehe, dass ich für die Arbeiten verantwortlich bin, die ich an Teammitglieder delegiere, einschließlich Versicherung, Sicherheit, Compliance, rechtliche und arbeitsrechtliche Verpflichtungen.'
    },
    'Add & Ask to Accept': {
        pt: 'Adicionar e Pedir para Aceitar',
        es: 'Añadir y Pedir que Acepte',
        ru: 'Добавить и Попросить Принять',
        fr: 'Ajouter et Demander d\'Accepter',
        de: 'Hinzufügen und um Annahme Bitten'
    },
    'Ask Team Member to Accept': {
        pt: 'Pedir ao Membro da Equipe para Aceitar',
        es: 'Pedir al Miembro del Equipo que Acepte',
        ru: 'Попросить Члена Команды Принять',
        fr: 'Demander au Membre d\'Équipe d\'Accepter',
        de: 'Teammitglied um Annahme Bitten'
    },
    'How it works:': {
        pt: 'Como funciona:',
        es: 'Cómo funciona:',
        ru: 'Как это работает:',
        fr: 'Comment ça marche:',
        de: 'So funktioniert es:'
    },
    '1) The Team Member will receive an SMS / email & see a notification in their app.': {
        pt: '1) O membro da equipe receberá um SMS / email e verá uma notificação no aplicativo.',
        es: '1) El miembro del equipo recibirá un SMS / email y verá una notificación en su aplicación.',
        ru: '1) Член команды получит SMS / email и увидит уведомление в приложении.',
        fr: '1) Le membre d\'équipe recevra un SMS / email et verra une notification dans son application.',
        de: '1) Das Teammitglied erhält eine SMS / E-Mail und sieht eine Benachrichtigung in der App.'
    },
    
    '2) You are still assigned to the job until the team member accepts the job.': {
        pt: '2) Você ainda está designado para o trabalho até que o membro da equipe aceite o trabalho.',
        es: '2) Sigues asignado al trabajo hasta que el miembro del equipo acepte el trabajo.',
        ru: '2) Вы все еще назначены на работу, пока член команды не примет ее.',
        fr: '2) Vous êtes toujours assigné au travail jusqu\'à ce que le membre de l\'équipe l\'accepte.',
        de: '2) Sie sind dem Job weiterhin zugewiesen, bis das Teammitglied den Job annimmt.'
    },
    '3) All payment or fees incurred will be made to your profile': {
        pt: '3) Todos os pagamentos ou taxas incorridos serão feitos em seu perfil',
        es: '3) Todos los pagos o tarifas incurridos se realizarán en su perfil',
        ru: '3) Все платежи или понесенные сборы будут произведены на ваш профиль',
        fr: '3) Tous les paiements ou frais encourus seront effectués sur votre profil',
        de: '3) Alle anfallenden Zahlungen oder Gebühren werden auf Ihr Profil gebucht'
    },
    'Add new team member': {
        pt: 'Adicionar novo membro da equipe',
        es: 'Añadir nuevo miembro del equipo',
        ru: 'Добавить нового члена команды',
        fr: 'Ajouter un nouveau membre d\'équipe',
        de: 'Neues Teammitglied hinzufügen'
    },
    'Request Sent': {
        pt: 'Solicitação Enviada',
        es: 'Solicitud Enviada',
        ru: 'Запрос Отправлен',
        fr: 'Demande Envoyée',
        de: 'Anfrage Gesendet'
    },
    'We have sent an SMS / email to your team member asking if they will accept.': {
        pt: 'Enviamos um SMS / email ao seu membro da equipe perguntando se ele aceitará.',
        es: 'Hemos enviado un SMS / email a tu miembro del equipo preguntando si aceptará.',
        ru: 'Мы отправили SMS / email вашему члену команды с вопросом, примет ли он.',
        fr: 'Nous avons envoyé un SMS / email à votre membre d\'équipe lui demandant s\'il acceptera.',
        de: 'Wir haben eine SMS / E-Mail an Ihr Teammitglied gesendet und fragen, ob sie/er annehmen wird.'
    },
    'Owner Name': {
        pt: 'Nome do Proprietário',
        es: 'Nombre del Propietario',
        ru: 'Имя Владельца',
        fr: 'Nom du Propriétaire',
        de: 'Name des Besitzers'
    },
    'Owner Email': {
        pt: 'Email do Proprietário',
        es: 'Correo del Propietario',
        ru: 'Email Владельца',
        fr: 'Email du Propriétaire', 
        de: 'Email des Besitzers'
    },
    'Team Description': {
        pt: 'Descrição da Equipe',
        es: 'Descripción del Equipo',
        ru: 'Описание Команды',
        fr: 'Description de l\'Équipe',
        de: 'Team Beschreibung'
    },
    'Leave Team': {
        pt: 'Sair da Equipe',
        es: 'Abandonar Equipo',
        ru: 'Покинуть Команду',
        fr: 'Quitter l\'Équipe',
        de: 'Team Verlassen'
    },
    'Leave Team?': {
        pt: 'Sair da Equipe?',
        es: '¿Abandonar Equipo?',
        ru: 'Покинуть Команду?',
        fr: 'Quitter l\'Équipe?',
        de: 'Team Verlassen?'
    },
    'Team Left': {
        pt: 'Equipe Abandonada',
        es: 'Equipo Abandonado',
        ru: 'Команда Покинута',
        fr: 'Équipe Quittée',
        de: 'Team Verlassen'
    },
    'Are you sure you want to leave': {
        pt: 'Tem certeza que deseja sair',
        es: '¿Estás seguro que quieres abandonar',
        ru: 'Вы уверены, что хотите покинуть',
        fr: 'Êtes-vous sûr de vouloir quitter',
        de: 'Sind Sie sicher, dass Sie verlassen möchten'
    },
    'team': {
        pt: 'equipe',
        es: 'equipo',
        ru: 'команду',
        fr: 'équipe',
        de: 'Team'
    },
    'You left': {
        pt: 'Você saiu',
        es: 'Has abandonado',
        ru: 'Вы покинули',
        fr: 'Vous avez quitté',
        de: 'Sie haben verlassen'
    },
    ' is on your team.': {
        pt: ' está na sua equipe.',
        es: ' está en tu equipo.',
        ru: ' в вашей команде.',
        fr: ' est dans votre équipe.',
        de: ' ist in Ihrem Team.'
    },
    'Remove Team Member': {
        pt: 'Remover Membro da Equipe',
        es: 'Eliminar Miembro del Equipo',
        ru: 'Удалить Члена Команды',
        fr: 'Supprimer le Membre d\'Équipe',
        de: 'Teammitglied Entfernen'
    },
    'Job Requested': {
        pt: 'Trabalho Solicitado',
        es: 'Trabajo Solicitado',
        ru: 'Запрос на Работу',
        fr: 'Travail Demandé',
        de: 'Job Angefragt'
    },
    'Your team leader ': {
        pt: 'Seu líder de equipe ',
        es: 'Tu líder de equipo ',
        ru: 'Ваш руководитель команды ',
        fr: 'Votre chef d\'équipe ',
        de: 'Ihr Teamleiter '
    },
    ' invited you to accept this job. ': {
        pt: ' convidou você para aceitar este trabalho. ',
        es: ' te invitó a aceptar este trabajo. ',
        ru: ' пригласил вас принять эту работу. ',
        fr: ' vous a invité à accepter ce travail. ',
        de: ' hat Sie eingeladen, diesen Job anzunehmen. '
    },
    'If you accept, you will be expected to complete the job. ': {
        pt: 'Se você aceitar, você deverá concluir o trabalho. ',
        es: 'Si aceptas, se espera que completes el trabajo. ',
        ru: 'Если вы примете, от вас ожидается выполнение работы. ',
        fr: 'Si vous acceptez, vous devrez terminer le travail. ',
        de: 'Wenn Sie annehmen, wird von Ihnen erwartet, dass Sie den Job abschließen. '
    },
    'Accept Job': {
        pt: 'Aceitar Trabalho',
        es: 'Aceptar Trabajo',
        ru: 'Принять Работу',
        fr: 'Accepter le Travail',
        de: 'Job Annehmen'
    },
    'Reject Job': {
        pt: 'Rejeitar Trabalho',
        es: 'Rechazar Trabajo',
        ru: 'Отклонить Работу',
        fr: 'Refuser le Travail',
        de: 'Job Ablehnen'
    },
    'You will be expected to complete the job. All payment will be made to': {
        pt: 'Você deverá concluir o trabalho. Todo o pagamento será feito para',
        es: 'Se espera que completes el trabajo. Todo el pago se realizará a',
        ru: 'От вас ожидается выполнение работы. Вся оплата будет произведена',
        fr: 'Vous devrez terminer le travail. Tout le paiement sera effectué à',
        de: 'Von Ihnen wird erwartet, dass Sie den Job abschließen. Die gesamte Zahlung geht an'
    },
    'View this job in the Jobs section. All payment will be made to': {
        pt: 'Veja este trabalho na seção Trabalhos. Todo o pagamento será feito para',
        es: 'Ver este trabajo en la sección Trabajos. Todo el pago se realizará a',
        ru: 'Просмотрите эту работу в разделе Работы. Вся оплата будет произведена',
        fr: 'Voir ce travail dans la section Travaux. Tout le paiement sera effectué à',
        de: 'Sehen Sie diesen Job im Bereich Jobs. Die gesamte Zahlung geht an'
    },
    'We have notified': {
        pt: 'Notificamos',
        es: 'Hemos notificado a',
        ru: 'Мы уведомили',
        fr: 'Nous avons informé',
        de: 'Wir haben benachrichtigt'
    },

    'Remove Member?': {
        pt: 'Remover Membro?',
        es: '¿Eliminar Miembro?',
        ru: 'Удалить Участника?',
        fr: 'Supprimer le Membre?',
        de: 'Mitglied Entfernen?'
    },
    'Are you sure you want to remove': {
        pt: 'Tem certeza que deseja remover',
        es: '¿Está seguro que desea eliminar a',
        ru: 'Вы уверены, что хотите удалить',
        fr: 'Êtes-vous sûr de vouloir supprimer',
        de: 'Sind Sie sicher, dass Sie entfernen möchten'
    },
    'from your team': {
        pt: 'da sua equipe',
        es: 'de su equipo',
        ru: 'из вашей команды',
        fr: 'de votre équipe',
        de: 'aus Ihrem Team'
    },
    'We removed': {
        pt: 'Removemos',
        es: 'Eliminamos a',
        ru: 'Мы удалили',
        fr: 'Nous avons supprimé',
        de: 'Wir haben entfernt'
    },
    'Member Removed': {
        pt: 'Membro Removido',
        es: 'Miembro Eliminado',
        ru: 'Участник Удален',
        fr: 'Membre Supprimé',
        de: 'Mitglied Entfernt'
    },
    'This asks someone to join your team, so you can send them to your jobs.': {
        pt: 'Isso convida alguém para se juntar à sua equipe, para que você possa enviá-los para seus trabalhos.',
        es: 'Esto invita a alguien a unirse a su equipo, para que pueda enviarlos a sus trabajos.',
        ru: 'Это приглашает кого-то присоединиться к вашей команде, чтобы вы могли отправлять их на ваши работы.',
        fr: 'Cela invite quelqu\'un à rejoindre votre équipe, afin que vous puissiez les envoyer à vos travaux.',
        de: 'Dies lädt jemanden ein, Ihrem Team beizutreten, damit Sie sie zu Ihren Jobs schicken können.'
    },
    'Limit their permissions (optional)': {
        pt: 'Limitar suas permissões (opcional)',
        es: 'Limitar sus permisos (opcional)',
        ru: 'Ограничить их права (необязательно)',
        fr: 'Limiter leurs permissions (optionnel)',
        de: 'Berechtigungen einschränken (optional)'
    },
    'If you want to prevent this user from adding or managing clients, instead just let them only accept and view jobs you assign, tap here. This is good if they are your employees. Note: if they already have a TIDY account this will not limit them.': {
        pt: 'Se você quiser impedir que este usuário adicione ou gerencie clientes, em vez disso, permita que eles apenas aceitem e visualizem os trabalhos que você atribui, toque aqui. Isso é bom se eles forem seus funcionários. Nota: se eles já tiverem uma conta TIDY, isso não os limitará.',
        es: 'Si desea evitar que este usuario agregue o administre clientes, en su lugar, permítales solo aceptar y ver los trabajos que asigne, toque aquí. Esto es bueno si son sus empleados. Nota: si ya tienen una cuenta TIDY, esto no los limitará.',
        ru: 'Если вы хотите запретить этому пользователю добавлять или управлять клиентами, вместо этого позвольте им только принимать и просматривать назначенные вами работы, нажмите здесь. Это хорошо, если они ваши сотрудники. Примечание: если у них уже есть учетная запись TIDY, это их не ограничит.',
        fr: 'Si vous souhaitez empêcher cet utilisateur d\'ajouter ou de gérer des clients, laissez-les plutôt uniquement accepter et voir les travaux que vous attribuez, appuyez ici. C\'est bien s\'ils sont vos employés. Remarque : s\'ils ont déjà un compte TIDY, cela ne les limitera pas.',
        de: 'Wenn Sie verhindern möchten, dass dieser Benutzer Kunden hinzufügt oder verwaltet, lassen Sie sie stattdessen nur die von Ihnen zugewiesenen Jobs akzeptieren und ansehen, tippen Sie hier. Das ist gut, wenn sie Ihre Mitarbeiter sind. Hinweis: Wenn sie bereits ein TIDY-Konto haben, wird dies sie nicht einschränken.'
    },
    'I still agree to the ': {
        pt: 'Ainda concordo com os ',
        es: 'Todavía estoy de acuerdo con los ',
        ru: 'Я по-прежнему согласен с ',
        fr: 'Je suis toujours d\'accord avec les ',
        de: 'Ich stimme weiterhin den '
    },
    ', and that my team members are employees/subcontractors and subject to these terms.': {
        pt: ', e que meus membros da equipe são funcionários/subcontratados e estão sujeitos a estes termos.',
        es: ', y que mis miembros del equipo son empleados/subcontratistas y están sujetos a estos términos.',
        ru: ', и что члены моей команды являются сотрудниками/субподрядчиками и подчиняются этим условиям.',
        fr: ', et que les membres de mon équipe sont des employés/sous-traitants et sont soumis à ces conditions.',
        de: ', und dass meine Teammitglieder Angestellte/Subunternehmer sind und diesen Bedingungen unterliegen.'
    },
    'You must confirm both of the above to invite a team member.': {
        pt: 'Você deve confirmar ambos os itens acima para convidar um membro da equipe.',
        es: 'Debe confirmar ambos puntos anteriores para invitar a un miembro del equipo.',
        ru: 'Вы должны подтвердить оба вышеуказанных пункта, чтобы пригласить члена команды.',
        fr: 'Vous devez confirmer les deux points ci-dessus pour inviter un membre de l\'équipe.',
        de: 'Sie müssen beide oben genannten Punkte bestätigen, um ein Teammitglied einzuladen.'
    },
    'Invite Team Member?': {
        pt: 'Convidar Membro da Equipe?',
        es: '¿Invitar Miembro del Equipo?',
        ru: 'Пригласить Участника Команды?',
        fr: 'Inviter un Membre d\'Équipe?',
        de: 'Teammitglied Einladen?'
    },
    'Send an invite to': {
        pt: 'Enviar um convite para',
        es: 'Enviar una invitación a',
        ru: 'Отправить приглашение',
        fr: 'Envoyer une invitation à',
        de: 'Eine Einladung senden an'
    },
    'Team Member Invited': {
        pt: 'Membro da Equipe Convidado',
        es: 'Miembro del Equipo Invitado',
        ru: 'Участник Команды Приглашен',
        fr: 'Membre d\'Équipe Invité',
        de: 'Teammitglied Eingeladen'
    },
    'We sent an email to': {
        pt: 'Enviamos um email para',
        es: 'Enviamos un correo electrónico a',
        ru: 'Мы отправили email',
        fr: 'Nous avons envoyé un email à',
        de: 'Wir haben eine E-Mail gesendet an'
    },
    
    'Resend Invite': {
        pt: 'Reenviar Convite',
        es: 'Reenviar Invitación',
        ru: 'Повторно Отправить Приглашение',
        fr: 'Renvoyer l\'Invitation',
        de: 'Einladung Erneut Senden'
    },
    'Cancel Invite': {
        pt: 'Cancelar Convite',
        es: 'Cancelar Invitación',
        ru: 'Отменить Приглашение',
        fr: 'Annuler l\'Invitation',
        de: 'Einladung Abbrechen'
    },
    'You invited': {
        pt: 'Você convidou',
        es: 'Has invitado a',
        ru: 'Вы пригласили',
        fr: 'Vous avez invité',
        de: 'Sie haben eingeladen'
    },
    'to be part of your team. They can accept by clicking the link in the email they received.': {
        pt: 'para fazer parte da sua equipe. Eles podem aceitar clicando no link que receberam por email.',
        es: 'para ser parte de tu equipo. Pueden aceptar haciendo clic en el enlace que recibieron por correo electrónico.',
        ru: 'стать частью вашей команды. Они могут принять приглашение, перейдя по ссылке в полученном email.',
        fr: 'à faire partie de votre équipe. Ils peuvent accepter en cliquant sur le lien reçu dans l\'email.',
        de: 'Teil Ihres Teams zu werden. Sie können durch Klicken auf den Link in der erhaltenen E-Mail annehmen.'
    },
    
    'Not Eligible to Undo': {
        pt: 'Não Elegível para Desfazer',
        es: 'No Elegible para Deshacer',
        ru: 'Невозможно Отменить',
        fr: 'Non Éligible pour Annuler',
        de: 'Nicht Rückgängig zu Machen'
    },
    'If another pro accepts the job, or the client already cancelled this because of your actions, then this cannot be un-done.': {
        pt: 'Se outro profissional aceitar o trabalho, ou se o cliente já cancelou devido às suas ações, isso não poderá ser desfeito.',
        es: 'Si otro profesional acepta el trabajo, o si el cliente ya lo canceló debido a sus acciones, esto no se puede deshacer.',
        ru: 'Если другой специалист примет работу, или клиент уже отменил её из-за ваших действий, это нельзя будет отменить.',
        fr: 'Si un autre professionnel accepte le travail, ou si le client l\'a déjà annulé en raison de vos actions, cela ne peut pas être annulé.',
        de: 'Wenn ein anderer Profi den Auftrag annimmt oder der Kunde ihn bereits wegen Ihrer Handlungen storniert hat, kann dies nicht rückgängig gemacht werden.'
    },
    'If you proceed, your schedule will be marked available for this timeblock and you will be placed back onto this job.': {
        pt: 'Se você prosseguir, seu horário será marcado como disponível para este período e você será recolocado neste trabalho.',
        es: 'Si continúa, su horario se marcará como disponible para este bloque de tiempo y se le volverá a asignar a este trabajo.',
        ru: 'Если вы продолжите, ваше расписание будет помечено как доступное для этого временного блока, и вы будете возвращены на эту работу.',
        fr: 'Si vous continuez, votre emploi du temps sera marqué comme disponible pour ce créneau et vous serez replacé sur ce travail.',
        de: 'Wenn Sie fortfahren, wird Ihr Zeitplan für diesen Zeitblock als verfügbar markiert und Sie werden wieder für diesen Auftrag eingeplant.'
    },
    'Get this Job Back': {
        pt: 'Recuperar este Trabalho',
        es: 'Recuperar este Trabajo',
        ru: 'Вернуть эту Работу',
        fr: 'Récupérer ce Travail',
        de: 'Diesen Auftrag Zurückholen'
    },
    'The following jobs are not fully paid. Send an invoice to collect payment:': {
        pt: 'Os seguintes trabalhos não estão totalmente pagos. Envie uma fatura para receber o pagamento:',
        es: 'Los siguientes trabajos no están pagados en su totalidad. Envíe una factura para cobrar el pago:',
        ru: 'Следующие работы не полностью оплачены. Отправьте счет для получения оплаты:',
        fr: 'Les travaux suivants ne sont pas entièrement payés. Envoyez une facture pour recevoir le paiement:',
        de: 'Die folgenden Aufträge sind nicht vollständig bezahlt. Senden Sie eine Rechnung, um die Zahlung einzufordern:'
    },
    'owed for': {
        pt: 'devido por',
        es: 'adeudado por',
        ru: 'долг за',
        fr: 'dû pour',
        de: 'geschuldet für'
    },
    'job': {
        pt: 'trabalho',
        es: 'trabajo',
        ru: 'работу',
        fr: 'travail',
        de: 'Auftrag'
    },
    'Get Paid Faster': {
        pt: 'Receba Mais Rápido',
        es: 'Cobre Más Rápido',
        ru: 'Получайте Оплату Быстрее',
        fr: 'Soyez Payé Plus Rapidement',
        de: 'Schneller Bezahlt Werden'
    },
    'Use TIDY Invoices to supercharge your business today.': {
        pt: 'Use as Faturas TIDY para impulsionar seu negócio hoje.',
        es: 'Use las Facturas TIDY para potenciar su negocio hoy.',
        ru: 'Используйте Счета TIDY чтобы улучшить свой бизнес сегодня.',
        fr: 'Utilisez les Factures TIDY pour dynamiser votre entreprise dès aujourd\'hui.',
        de: 'Nutzen Sie TIDY Rechnungen, um Ihr Geschäft heute zu beschleunigen.'
    },
    'Add Jobs to Request Payment': {
        pt: 'Adicione Trabalhos para Solicitar Pagamento',
        es: 'Agregar Trabajos para Solicitar Pago',
        ru: 'Добавьте Работы для Запроса Оплаты',
        fr: 'Ajouter des Travaux pour Demander le Paiement',
        de: 'Aufträge Hinzufügen um Zahlung Anzufordern'
    },
    'You have no unpaid jobs. Add a job then complete it to request payment.': {
        pt: 'Você não tem trabalhos não pagos. Adicione um trabalho e conclua-o para solicitar pagamento.',
        es: 'No tiene trabajos sin pagar. Agregue un trabajo y complételo para solicitar el pago.',
        ru: 'У вас нет неоплаченных работ. Добавьте работу и завершите её, чтобы запросить оплату.',
        fr: 'Vous n\'avez pas de travaux impayés. Ajoutez un travail puis terminez-le pour demander le paiement.',
        de: 'Sie haben keine unbezahlten Aufträge. Fügen Sie einen Auftrag hinzu und schließen Sie ihn ab, um die Zahlung anzufordern.'
    },
    'Send payment requests (invoices) instantly': {
        pt: 'Envie solicitações de pagamento (faturas) instantaneamente',
        es: 'Envíe solicitudes de pago (facturas) al instante',
        ru: 'Отправляйте запросы на оплату (счета) мгновенно',
        fr: 'Envoyez des demandes de paiement (factures) instantanément',
        de: 'Senden Sie Zahlungsanforderungen (Rechnungen) sofort'
    },
    'Clients pay the way you want (including credit card)': {
        pt: 'Clientes pagam da maneira que você preferir (incluindo cartão de crédito)',
        es: 'Los clientes pagan como usted prefiera (incluyendo tarjeta de crédito)',
        ru: 'Клиенты платят удобным для вас способом (включая кредитную карту)',
        fr: 'Les clients paient comme vous le souhaitez (carte de crédit incluse)',
        de: 'Kunden zahlen wie Sie es möchten (einschließlich Kreditkarte)'
    },
    'Automatic reminders ensure you get paid': {
        pt: 'Lembretes automáticos garantem que você receba',
        es: 'Los recordatorios automáticos aseguran que le paguen',
        ru: 'Автоматические напоминания гарантируют получение оплаты',
        fr: 'Les rappels automatiques assurent votre paiement',
        de: 'Automatische Erinnerungen stellen sicher, dass Sie bezahlt werden'
    },
        

    'Unpause Account': {
        pt: 'Desbloquear Conta',
        es: 'Reactivar Cuenta',
        ru: 'Разблокировать Аккаунт',
        fr: 'Réactiver le Compte',
        de: 'Konto Aktivieren'
    },
    'Your account is currently paused.': {
        pt: 'Sua conta está atualmente pausada.',
        es: 'Su cuenta está actualmente pausada.',
        ru: 'Ваш аккаунт в настоящее время приостановлен.',
        fr: 'Votre compte est actuellement en pause.',
        de: 'Ihr Konto ist derzeit pausiert.'
    },
    'Confirm that you agree to the': {
        pt: 'Confirme que você concorda com os',
        es: 'Confirme que está de acuerdo con los',
        ru: 'Подтвердите, что вы согласны с',
        fr: 'Confirmez que vous acceptez les',
        de: 'Bestätigen Sie, dass Sie den'
    },
    'to unpause your account.': {
        pt: 'para desbloquear sua conta.',
        es: 'para reactivar su cuenta.',
        ru: 'чтобы разблокировать ваш аккаунт.',
        fr: 'pour réactiver votre compte.',
        de: 'zustimmen, um Ihr Konto zu aktivieren.'
    },
    'I agree to the terms': {
        pt: 'Eu concordo com os termos',
        es: 'Acepto los términos',
        ru: 'Я согласен с условиями',
        fr: 'J\'accepte les conditions',
        de: 'Ich stimme den Bedingungen zu'
    },
    'Unpause My Account': {
        pt: 'Desbloquear Minha Conta',
        es: 'Reactivar Mi Cuenta',
        ru: 'Разблокировать Мой Аккаунт',
        fr: 'Réactiver Mon Compte',
        de: 'Mein Konto Aktivieren'
    },
    'You must agree to the terms to unpause your account.': {
        pt: 'Você deve concordar com os termos para desbloquear sua conta.',
        es: 'Debe aceptar los términos para reactivar su cuenta.',
        ru: 'Вы должны согласиться с условиями, чтобы разблокировать ваш аккаунт.',
        fr: 'Vous devez accepter les conditions pour réactiver votre compte.',
        de: 'Sie müssen den Bedingungen zustimmen, um Ihr Konto zu aktivieren.'
    },
    'Account Unpaused': {
        pt: 'Conta Desbloqueada',
        es: 'Cuenta Reactivada',
        ru: 'Аккаунт Разблокирован',
        fr: 'Compte Réactivé',
        de: 'Konto Aktiviert'
    },
    'Your account is now unpaused.': {
        pt: 'Sua conta agora está desbloqueada.',
        es: 'Su cuenta ahora está reactivada.',
        ru: 'Ваш аккаунт теперь разблокирован.',
        fr: 'Votre compte est maintenant réactivé.',
        de: 'Ihr Konto ist jetzt aktiviert.'
    },
    'Your account is paused. To unpause your account, confirm your address:': {
        pt: 'Sua conta está pausada. Para desbloquear sua conta, confirme seu endereço:',
        es: 'Su cuenta está pausada. Para reactivar su cuenta, confirme su dirección:',
        ru: 'Ваш аккаунт приостановлен. Чтобы разблокировать аккаунт, подтвердите свой адрес:',
        fr: 'Votre compte est en pause. Pour réactiver votre compte, confirmez votre adresse:',
        de: 'Ihr Konto ist pausiert. Um Ihr Konto zu aktivieren, bestätigen Sie Ihre Adresse:'
    },
    'Your certification is no longer valid. To re-request certification, confirm your address:': {
        pt: 'Sua certificação não é mais válida. Para solicitar nova certificação, confirme seu endereço:',
        es: 'Su certificación ya no es válida. Para volver a solicitar la certificación, confirme su dirección:',
        ru: 'Ваша сертификация больше недействительна. Чтобы повторно запросить сертификацию, подтвердите свой адрес:',
        fr: 'Votre certification n\'est plus valide. Pour demander à nouveau la certification, confirmez votre adresse:',
        de: 'Ihre Zertifizierung ist nicht mehr gültig. Um die Zertifizierung erneut zu beantragen, bestätigen Sie Ihre Adresse:'
    },
    'What is your max drive time?': {
        pt: 'Qual é seu tempo máximo de viagem?',
        es: '¿Cuál es su tiempo máximo de conducción?',
        ru: 'Какое максимальное время в пути?',
        fr: 'Quel est votre temps de conduite maximum?',
        de: 'Was ist Ihre maximale Fahrzeit?'
    },
    'Please ensure the address you enterred is at the center of the area you serve. To remove a specific area, block a zipcode in the \'Locations I Serve\' page.': {
        pt: 'Por favor, certifique-se de que o endereço inserido está no centro da área que você atende. Para remover uma área específica, bloqueie um CEP na página \'Locais que Atendo\'.',
        es: 'Asegúrese de que la dirección ingresada esté en el centro del área que sirve. Para eliminar un área específica, bloquee un código postal en la página \'Ubicaciones que Sirvo\'.',
        ru: 'Пожалуйста, убедитесь, что введенный адрес находится в центре обслуживаемой вами области. Чтобы удалить определенную область, заблокируйте почтовый индекс на странице \'Места, которые я обслуживаю\'.',
        fr: 'Veuillez vous assurer que l\'adresse saisie est au centre de la zone que vous servez. Pour supprimer une zone spécifique, bloquez un code postal dans la page \'Lieux que je sers\'.',
        de: 'Bitte stellen Sie sicher, dass die eingegebene Adresse im Zentrum Ihres Servicegebiets liegt. Um einen bestimmten Bereich zu entfernen, sperren Sie eine Postleitzahl auf der Seite \'Orte, die ich bediene\'.'
    },
    'To un-pause, ': {
        pt: 'Para desbloquear, ',
        es: 'Para reactivar, ',
        ru: 'Чтобы разблокировать, ',
        fr: 'Pour réactiver, ',
        de: 'Um zu aktivieren, '
    },
    'To re-request certification, ': {
        pt: 'Para solicitar nova certificação, ',
        es: 'Para volver a solicitar la certificación, ',
        ru: 'Чтобы повторно запросить сертификацию, ',
        fr: 'Pour demander à nouveau la certification, ',
        de: 'Um die Zertifizierung erneut zu beantragen, '
    },
    'you must set your availability. What is your current availability for clients to book you? Remember, clients see these times and can book with you. Change this anytime in the Schedule section.': {
        pt: 'você deve definir sua disponibilidade. Qual é sua disponibilidade atual para os clientes agendarem com você? Lembre-se, os clientes veem esses horários e podem fazer reservas com você. Altere isso a qualquer momento na seção Agenda.',
        es: 'debe establecer su disponibilidad. ¿Cuál es su disponibilidad actual para que los clientes lo reserven? Recuerde, los clientes ven estos horarios y pueden reservar con usted. Cambie esto en cualquier momento en la sección Horario.',
        ru: 'вы должны установить свою доступность. Какова ваша текущая доступность для бронирования клиентами? Помните, клиенты видят это время и могут бронировать вас. Измените это в любое время в разделе Расписание.',
        fr: 'vous devez définir votre disponibilité. Quelle est votre disponibilité actuelle pour que les clients vous réservent? Rappelez-vous, les clients voient ces horaires et peuvent réserver avec vous. Modifiez cela à tout moment dans la section Horaire.',
        de: 'müssen Sie Ihre Verfügbarkeit festlegen. Was ist Ihre aktuelle Verfügbarkeit für Buchungen durch Kunden? Denken Sie daran, Kunden sehen diese Zeiten und können bei Ihnen buchen. Ändern Sie dies jederzeit im Zeitplan-Bereich.'
    },
    'Every ': {
        pt: 'Cada ',
        es: 'Cada ',
        ru: 'Каждый ',
        fr: 'Chaque ',
        de: 'Jeden '
    },
    'You must add available times to save your schedule.': {
        pt: 'Você deve adicionar horários disponíveis para salvar sua agenda.',
        es: 'Debe agregar horarios disponibles para guardar su horario.',
        ru: 'Вы должны добавить доступное время, чтобы сохранить свое расписание.',
        fr: 'Vous devez ajouter des horaires disponibles pour sauvegarder votre emploi du temps.',
        de: 'Sie müssen verfügbare Zeiten hinzufügen, um Ihren Zeitplan zu speichern.'
    },

    'What services types do you offer?': {
        pt: 'Que tipos de serviços você oferece?',
        es: '¿Qué tipos de servicios ofrece?',
        ru: 'Какие виды услуг вы предлагаете?',
        fr: 'Quels types de services proposez-vous ?',
        de: 'Welche Arten von Dienstleistungen bieten Sie an?'
    },
    'Why do you want this feature?': {
        pt: 'Por que você quer este recurso?',
        es: '¿Por qué quiere esta función?',
        ru: 'Почему вы хотите эту функцию?',
        fr: 'Pourquoi voulez-vous cette fonctionnalité ?',
        de: 'Warum möchten Sie diese Funktion?'
    },
    'Request New Service Types': {
        pt: 'Solicitar Novos Tipos de Serviço',
        es: 'Solicitar Nuevos Tipos de Servicio',
        ru: 'Запросить Новые Типы Услуг',
        fr: 'Demander de Nouveaux Types de Services',
        de: 'Neue Servicetypen Anfragen'
    },
    'Request this Feature': {
        pt: 'Solicitar este Recurso',
        es: 'Solicitar esta Función',
        ru: 'Запросить эту Функцию',
        fr: 'Demander cette Fonctionnalité',
        de: 'Diese Funktion Anfragen'
    },
    'After Cutoff Cancellation Fees': {
        pt: 'Taxas de Cancelamento Após o Prazo',
        es: 'Tarifas de Cancelación Después del Límite',
        ru: 'Комиссия за Отмену После Крайнего Срока',
        fr: 'Frais d\'Annulation Après la Date Limite',
        de: 'Stornierungsgebühren Nach Ablauf der Frist'
    },
    'Charge your clients a fee for canceling jobs after 7:50pm the day prior.': {
        pt: 'Cobre uma taxa de seus clientes por cancelarem trabalhos após as 19:50 do dia anterior.',
        es: 'Cobre a sus clientes una tarifa por cancelar trabajos después de las 7:50 pm del día anterior.',
        ru: 'Взимайте плату с клиентов за отмену заказов после 19:50 предыдущего дня.',
        fr: 'Facturez des frais à vos clients pour l\'annulation de travaux après 19h50 la veille.',
        de: 'Berechnen Sie Ihren Kunden eine Gebühr für die Stornierung von Aufträgen nach 19:50 Uhr am Vortag.'
    },
    'Send amazing marketing emails to your Clients! Pick from one of several templates including: Thank you, Christmas, Discounts, & more.<br><br>Easily send to Clients on your existing Client list, past Clients, or Clients who have not booked yet.': {
        pt: 'Envie emails de marketing incríveis para seus Clientes! Escolha entre vários modelos, incluindo: Agradecimento, Natal, Descontos e mais.<br><br>Envie facilmente para Clientes em sua lista atual, Clientes anteriores ou Clientes que ainda não fizeram reservas.',
        es: '¡Envíe increíbles correos electrónicos de marketing a sus Clientes! Elija entre varias plantillas que incluyen: Agradecimiento, Navidad, Descuentos y más.<br><br>Envíe fácilmente a Clientes en su lista actual, Clientes anteriores o Clientes que aún no han reservado.',
        ru: 'Отправляйте потрясающие маркетинговые письма своим Клиентам! Выбирайте из нескольких шаблонов, включая: Благодарность, Рождество, Скидки и многое другое.<br><br>Легко отправляйте письма Клиентам из вашего текущего списка, прошлым Клиентам или Клиентам, которые еще не делали заказов.',
        fr: 'Envoyez des emails marketing exceptionnels à vos Clients ! Choisissez parmi plusieurs modèles incluant : Remerciements, Noël, Réductions et plus encore.<br><br>Envoyez facilement à vos Clients actuels, anciens Clients ou Clients qui n\'ont pas encore réservé.',
        de: 'Senden Sie fantastische Marketing-E-Mails an Ihre Kunden! Wählen Sie aus verschiedenen Vorlagen wie: Danke, Weihnachten, Rabatte und mehr.<br><br>Einfacher Versand an Kunden aus Ihrer bestehenden Kundenliste, ehemalige Kunden oder Kunden, die noch nicht gebucht haben.'
    },
    'Send beautiful marketing postcards to your Clients in the mail! Pick from one of several templates including: Thank you, Christmas, Discounts, & more.<br><br>Easily send to Clients on your existing Client list, past Clients, or Clients who have not booked yet.': {
        pt: 'Envie lindos cartões postais de marketing para seus Clientes pelo correio! Escolha entre vários modelos, incluindo: Agradecimento, Natal, Descontos e mais.<br><br>Envie facilmente para Clientes em sua lista atual, Clientes anteriores ou Clientes que ainda não fizeram reservas.',
        es: '¡Envíe hermosas tarjetas postales de marketing a sus Clientes por correo! Elija entre varias plantillas que incluyen: Agradecimiento, Navidad, Descuentos y más.<br><br>Envíe fácilmente a Clientes en su lista actual, Clientes anteriores o Clientes que aún no han reservado.',
        ru: 'Отправляйте красивые маркетинговые открытки своим Клиентам по почте! Выбирайте из нескольких шаблонов, включая: Благодарность, Рождество, Скидки и многое другое.<br><br>Легко отправляйте открытки Клиентам из вашего текущего списка, прошлым Клиентам или Клиентам, которые еще не делали заказов.',
        fr: 'Envoyez de belles cartes postales marketing à vos Clients par la poste ! Choisissez parmi plusieurs modèles incluant : Remerciements, Noël, Réductions et plus encore.<br><br>Envoyez facilement à vos Clients actuels, anciens Clients ou Clients qui n\'ont pas encore réservé.',
        de: 'Senden Sie schöne Marketing-Postkarten an Ihre Kunden per Post! Wählen Sie aus verschiedenen Vorlagen wie: Danke, Weihnachten, Rabatte und mehr.<br><br>Einfacher Versand an Kunden aus Ihrer bestehenden Kundenliste, ehemalige Kunden oder Kunden, die noch nicht gebucht haben.'
    },
    
    'Be booked from the top of Google!<br><br>You can easily create Google Ads in TIDY. These sync with your Google account & Clients can instantly book your services directly from the search results on your calendar. Paying only for results is the way to go.': {
        pt: 'Seja reservado no topo do Google!<br><br>Você pode facilmente criar anúncios do Google no TIDY. Eles sincronizam com sua conta do Google e os clientes podem reservar seus serviços instantaneamente diretamente dos resultados de pesquisa no seu calendário. Pagar apenas por resultados é o caminho a seguir.',
        es: '¡Sea reservado desde la parte superior de Google!<br><br>Puede crear fácilmente anuncios de Google en TIDY. Estos se sincronizan con su cuenta de Google y los clientes pueden reservar sus servicios instantáneamente directamente desde los resultados de búsqueda en su calendario. Pagar solo por resultados es el camino a seguir.',
        ru: 'Бронируйте с вершины Google!<br><br>Вы можете легко создавать объявления Google в TIDY. Они синхронизируются с вашей учетной записью Google, и клиенты могут мгновенно бронировать ваши услуги прямо из результатов поиска в вашем календаре. Платить только за результаты - это правильный путь.',
        fr: 'Soyez réservé depuis le haut de Google!<br><br>Vous pouvez facilement créer des annonces Google dans TIDY. Celles-ci se synchronisent avec votre compte Google et les clients peuvent réserver vos services instantanément directement depuis les résultats de recherche sur votre calendrier. Payer uniquement pour les résultats est la voie à suivre.',
        de: 'Werden Sie von der Spitze von Google gebucht!<br><br>Sie können ganz einfach Google-Anzeigen in TIDY erstellen. Diese synchronisieren sich mit Ihrem Google-Konto und Kunden können Ihre Dienste direkt aus den Suchergebnissen in Ihrem Kalender buchen. Nur für Ergebnisse zu zahlen ist der richtige Weg.'
    },
    'Save $ by using TIDY to automatically track the equipment you use.  TIDY provides a monthy & yearly summary for you to use when paying taxes.': {
        pt: 'Economize $ usando o TIDY para rastrear automaticamente o equipamento que você usa. A TIDY fornece um resumo mensal e anual para você usar ao pagar impostos.',
        es: 'Ahorre $ usando TIDY para rastrear automáticamente el equipo que usa. TIDY proporciona un resumen mensual y anual para que lo use al pagar impuestos.',
        ru: 'Экономьте $, используя TIDY для автоматического отслеживания используемого оборудования. TIDY предоставляет ежемесячный и годовой отчет для использования при уплате налогов.',
        fr: 'Économisez $ en utilisant TIDY pour suivre automatiquement l\'équipement que vous utilisez. TIDY fournit un résumé mensuel et annuel à utiliser lors du paiement des impôts.',
        de: 'Sparen Sie $, indem Sie TIDY verwenden, um die von Ihnen verwendete Ausrüstung automatisch zu verfolgen. TIDY bietet eine monatliche und jährliche Zusammenfassung, die Sie bei der Steuerzahlung verwenden können.'
    },
    'TIDY helps track write offs to help you save!<br><br>Easily track your income, mileage, equipment, utility expenses & more through TIDY.': {
        pt: 'A TIDY ajuda a rastrear deduções para ajudá-lo a economizar!<br><br>Rastreie facilmente sua renda, quilometragem, equipamentos, despesas com serviços públicos e mais através da TIDY.',
        es: '¡TIDY ayuda a rastrear deducciones para ayudarlo a ahorrar!<br><br>Rastree fácilmente sus ingresos, kilometraje, equipos, gastos de servicios públicos y más a través de TIDY.',
        ru: 'TIDY помогает отслеживать списания, чтобы помочь вам сэкономить!<br><br>Легко отслеживайте свои доходы, пробег, оборудование, коммунальные расходы и многое другое с помощью TIDY.',
        fr: 'TIDY aide à suivre les déductions pour vous aider à économiser!<br><br>Suivez facilement vos revenus, kilométrage, équipements, dépenses de services publics et plus encore grâce à TIDY.',
        de: 'TIDY hilft, Abschreibungen zu verfolgen, um Ihnen beim Sparen zu helfen!<br><br>Verfolgen Sie einfach Ihr Einkommen, Ihre Kilometerleistung, Ausrüstung, Nebenkosten und mehr mit TIDY.'
    },
    'Easily stay on top of your business accounting through TIDY.  Get started & take the hassle out of staying organized!': {
        pt: 'Fique facilmente no controle da contabilidade do seu negócio através da TIDY. Comece e elimine o incômodo de se manter organizado!',
        es: 'Manténgase fácilmente al tanto de la contabilidad de su negocio a través de TIDY. ¡Comience y elimine la molestia de mantenerse organizado!',
        ru: 'Легко держите бухгалтерию вашего бизнеса под контролем с помощью TIDY. Начните и избавьтесь от хлопот, связанных с поддержанием порядка!',
        fr: 'Restez facilement au top de la comptabilité de votre entreprise grâce à TIDY. Commencez et éliminez les tracas de rester organisé!',
        de: 'Bleiben Sie mit TIDY ganz einfach über Ihre Unternehmensbuchhaltung informiert. Beginnen Sie und nehmen Sie den Aufwand aus der Organisation!'
    },
    'Team Payments': {
        pt: 'Pagamentos de Equipe',
        es: 'Pagos de Equipo',
        ru: 'Платежи Команде',
        fr: 'Paiements d\'Équipe',
        de: 'Teamzahlungen'
    },
    'Use TIDY to pay your team members for the jobs you allocate to them.  It\'s fast & easy to set up.': {
        pt: 'Use o TIDY para pagar seus membros da equipe pelos trabalhos que você lhes atribui. É rápido e fácil de configurar.',
        es: 'Use TIDY para pagar a los miembros de su equipo por los trabajos que les asigna. Es rápido y fácil de configurar.',
        ru: 'Используйте TIDY для оплаты членам вашей команды за работы, которые вы им поручаете. Это быстро и легко настроить.',
        fr: 'Utilisez TIDY pour payer les membres de votre équipe pour les travaux que vous leur attribuez. C\'est rapide et facile à configurer.',
        de: 'Verwenden Sie TIDY, um Ihre Teammitglieder für die ihnen zugewiesenen Arbeiten zu bezahlen. Es ist schnell und einfach einzurichten.'
    },
    'Add Past Jobs': {
        pt: 'Adicionar Trabalhos Anteriores',
        es: 'Agregar Trabajos Anteriores',
        ru: 'Добавить Прошлые Работы',
        fr: 'Ajouter des Travaux Passés',
        de: 'Vergangene Arbeiten Hinzufügen'
    },
    'You cannot add a job for a past date at this time.  Request this feature for more control over private jobs.': {
        pt: 'Você não pode adicionar um trabalho para uma data passada neste momento. Solicite este recurso para ter mais controle sobre trabalhos privados.',
        es: 'No puede agregar un trabajo para una fecha pasada en este momento. Solicite esta función para tener más control sobre trabajos privados.',
        ru: 'Вы не можете добавить работу на прошедшую дату в данный момент. Запросите эту функцию для большего контроля над частными работами.',
        fr: 'Vous ne pouvez pas ajouter un travail pour une date passée pour le moment. Demandez cette fonctionnalité pour plus de contrôle sur les travaux privés.',
        de: 'Sie können derzeit keinen Job für ein vergangenes Datum hinzufügen. Fordern Sie diese Funktion an, um mehr Kontrolle über private Arbeiten zu haben.'
    },
    'Add To Dos': {
        pt: 'Adicionar Tarefas',
        es: 'Agregar Tareas',
        ru: 'Добавить Задачи',
        fr: 'Ajouter des Tâches',
        de: 'Aufgaben Hinzufügen'
    },

    'Add & manage To Dos lists for your private Clients.  Delight them with your attention to detail & send post job email summaries of what was done.': {
        pt: 'Adicione e gerencie listas de tarefas para seus clientes privados. Encante-os com sua atenção aos detalhes e envie resumos por e-mail do que foi feito após o trabalho.',
        es: 'Agregue y gestione listas de tareas para sus clientes privados. Deléitelos con su atención al detalle y envíe resúmenes por correo electrónico de lo que se hizo después del trabajo.',
        ru: 'Добавляйте и управляйте списками дел для ваших частных клиентов. Порадуйте их своим вниманием к деталям и отправляйте по электронной почте резюме о выполненной работе.',
        fr: 'Ajoutez et gérez des listes de tâches pour vos clients privés. Enchantez-les avec votre attention aux détails et envoyez des résumés par e-mail de ce qui a été fait après le travail.',
        de: 'Fügen Sie Aufgabenlisten für Ihre privaten Kunden hinzu und verwalten Sie sie. Begeistern Sie sie mit Ihrer Liebe zum Detail und senden Sie E-Mail-Zusammenfassungen der erledigten Arbeiten.'
    },
    'New Service Types': {
        pt: 'Novos Tipos de Serviço',
        es: 'Nuevos Tipos de Servicio',
        ru: 'Новые Типы Услуг',
        fr: 'Nouveaux Types de Service',
        de: 'Neue Dienstleistungstypen'
    },
    'Please let us know what service types your business offers that you didn\'t see in the TIDY app.': {
        pt: 'Por favor, informe-nos quais tipos de serviço sua empresa oferece que você não viu no aplicativo TIDY.',
        es: 'Por favor, háganos saber qué tipos de servicio ofrece su empresa que no vio en la aplicación TIDY.',
        ru: 'Пожалуйста, сообщите нам, какие типы услуг предлагает ваш бизнес, которые вы не видели в приложении TIDY.',
        fr: 'Veuillez nous faire savoir quels types de services votre entreprise propose que vous n\'avez pas vus dans l\'application TIDY.',
        de: 'Bitte lassen Sie uns wissen, welche Dienstleistungstypen Ihr Unternehmen anbietet, die Sie in der TIDY-App nicht gesehen haben.'
    },
    'Collect Deposit': {
        pt: 'Coletar Depósito',
        es: 'Cobrar Depósito',
        ru: 'Собрать Депозит',
        fr: 'Collecter un Dépôt',
        de: 'Anzahlung Erheben'
    },
    'You cannot deposit for a future job at this time.  Request this feature for more control over private payments.': {
        pt: 'Você não pode depositar para um trabalho futuro neste momento. Solicite este recurso para ter mais controle sobre pagamentos privados.',
        es: 'No puede depositar para un trabajo futuro en este momento. Solicite esta función para tener más control sobre los pagos privados.',
        ru: 'Вы не можете внести депозит за будущую работу в данный момент. Запросите эту функцию для большего контроля над частными платежами.',
        fr: 'Vous ne pouvez pas déposer pour un travail futur pour le moment. Demandez cette fonctionnalité pour plus de contrôle sur les paiements privés.',
        de: 'Sie können derzeit keine Anzahlung für einen zukünftigen Auftrag leisten. Fordern Sie diese Funktion an, um mehr Kontrolle über private Zahlungen zu haben.'
    },
    'Feature Requested': {
        pt: 'Recurso Solicitado',
        es: 'Función Solicitada',
        ru: 'Функция Запрошена',
        fr: 'Fonctionnalité Demandée',
        de: 'Funktion Angefordert'
    },
    'TIDY will let you know when this feature is available for you.': {
        pt: 'OA TIDY informará quando este recurso estiver disponível para você.',
        es: 'TIDY le informará cuando esta función esté disponible para usted.',
        ru: 'TIDY сообщит вам, когда эта функция станет доступной для вас.',
        fr: 'TIDY vous informera lorsque cette fonctionnalité sera disponible pour vous.',
        de: 'TIDY wird Sie informieren, wenn diese Funktion für Sie verfügbar ist.'
    },
    'Feature Already Requested': {
        pt: 'Recurso Já Solicitado',
        es: 'Función Ya Solicitada',
        ru: 'Функция Уже Запрошена',
        fr: 'Fonctionnalité Déjà Demandée',
        de: 'Funktion Bereits Angefordert'
    },
    'You already requested this feature.  TIDY will let you know when this feature is available for you.': {
        pt: 'Você já solicitou este recurso. A TIDY informará quando este recurso estiver disponível para você.',
        es: 'Ya solicitó esta función. TIDY le informará cuando esta función esté disponible para usted.',
        ru: 'Вы уже запросили эту функцию. TIDY сообщит вам, когда эта функция станет доступной для вас.',
        fr: 'Vous avez déjà demandé cette fonctionnalité. TIDY vous informera lorsque cette fonctionnalité sera disponible pour vous.',
        de: 'Sie haben diese Funktion bereits angefordert. TIDY wird Sie informieren, wenn diese Funktion für Sie verfügbar ist.'
    },
    'Clients Use Your Website to:': {
        pt: 'Os Clientes Usam Seu Site para:',
        es: 'Los Clientes Usan Su Sitio Web para:',
        ru: 'Клиенты Используют Ваш Вебсайт для:',
        fr: 'Les Clients Utilisent Votre Site Web pour :',
        de: 'Kunden Nutzen Ihre Webseite, um:'
    },
    '<li>Learn about your business</li>': {
        pt: '<li>Conhecer seu negócio</li>',
        es: '<li>Conocer su negocio</li>',
        ru: '<li>Узнать о вашем бизнесе</li>',
        fr: '<li>En savoir plus sur votre entreprise</li>',
        de: '<li>Mehr über Ihr Unternehmen erfahren</li>'
    },
    '<li>Book & manage jobs with you</li>': {
        pt: '<li>Reservar e gerenciar trabalhos com você</li>',
        es: '<li>Reservar y gestionar trabajos con usted</li>',
        ru: '<li>Бронировать и управлять работами с вами</li>',
        fr: '<li>Réserver et gérer des travaux avec vous</li>',
        de: '<li>Jobs mit Ihnen buchen und verwalten</li>'
    },
    '<li>Add to dos & notes for you</li>': {
        pt: '<li>Adicionar tarefas e notas para você</li>',
        es: '<li>Agregar tareas y notas para usted</li>',
        ru: '<li>Добавить задачи и заметки для вас</li>',
        fr: '<li>Ajouter des tâches et des notes pour vous</li>',
        de: '<li>Aufgaben und Notizen für Sie hinzufügen</li>'
    },
    '<li>Pay you for a job</li>': {
        pt: '<li>Pagar por um trabalho</li>',
        es: '<li>Pagarle por un trabajo</li>',
        ru: '<li>Оплатить вам за работу</li>',
        fr: '<li>Vous payer pour un travail</li>',
        de: '<li>Sie für einen Job bezahlen</li>'
    },
    '<li>Leave reviews for your business</li>': {
        pt: '<li>Deixar avaliações para o seu negócio</li>',
        es: '<li>Dejar reseñas para su negocio</li>',
        ru: '<li>Оставить отзывы о вашем бизнесе</li>',
        fr: '<li>Laisser des avis pour votre entreprise</li>',
        de: '<li>Bewertungen für Ihr Unternehmen hinterlassen</li>'
    },
    'Edit your website:': {
        pt: 'Edite seu site:',
        es: 'Edite su sitio web:',
        ru: 'Редактируйте ваш вебсайт:',
        fr: 'Modifiez votre site web :',
        de: 'Bearbeiten Sie Ihre Webseite:'
    },
    'URL': {
        pt: 'URL',
        es: 'URL',
        ru: 'URL',
        fr: 'URL',
        de: 'URL'
    },
    'View Website': {
        pt: 'Ver Site',
        es: 'Ver Sitio Web',
        ru: 'Просмотр Вебсайта',
        fr: 'Voir le Site Web',
        de: 'Webseite ansehen'
    },
    'Edit URL': {
        pt: 'Editar URL',
        es: 'Editar URL',
        ru: 'Редактировать URL',
        fr: 'Modifier l\'URL',
        de: 'URL bearbeiten'
    },
    'Business Detail': {
        pt: 'Detalhe do Negócio',
        es: 'Detalle del Negocio',
        ru: 'Детали Бизнеса',
        fr: 'Détail de l\'Entreprise',
        de: 'Geschäftsdetails'
    },
    'Locations I Serve': {
        pt: 'Locais que Atendo',
        es: 'Ubicaciones que Atiendo',
        ru: 'Места, которые я обслуживаю',
        fr: 'Lieux que je sers',
        de: 'Standorte, die ich bediene'
    },
    'Certifications': {
        pt: 'Certificações',
        es: 'Certificaciones',
        ru: 'Сертификаты',
        fr: 'Certifications',
        de: 'Zertifizierungen'
    },
    '<li>Background Check</li>': {
        pt: '<li>Verificação de Antecedentes</li>',
        es: '<li>Verificación de Antecedentes</li>',
        ru: '<li>Проверка Биографии</li>',
        fr: '<li>Vérification des Antécédents</li>',
        de: '<li>Hintergrundüberprüfung</li>'
    },
    '<li>Cleaning Equipment</li>': {
        pt: '<li>Equipamento de Limpeza</li>',
        es: '<li>Equipo de Limpieza</li>',
        ru: '<li>Оборудование для Уборки</li>',
        fr: '<li>Équipement de Nettoyage</li>',
        de: '<li>Reinigungsgeräte</li>'
    },
    '<li>English Speaking</li>': {
        pt: '<li>Fala Inglês</li>',
        es: '<li>Habla Inglés</li>',
        ru: '<li>Говорит по-английски</li>',
        fr: '<li>Parle Anglais</li>',
        de: '<li>Spricht Englisch</li>'
    },
    '<li>Recently Active</li>': {
        pt: '<li>Ativo Recentemente</li>',
        es: '<li>Recientemente Activo</li>',
        ru: '<li>Недавно Активен</li>',
        fr: '<li>Récemment Actif</li>',
        de: '<li>Kürzlich Aktiv</li>'
    },
    'Share Website': {
        pt: 'Compartilhar Site',
        es: 'Compartir Sitio Web',
        ru: 'Поделиться Вебсайтом',
        fr: 'Partager le Site Web',
        de: 'Webseite Teilen'
    },
    'Business Name': {
        pt: 'Nome do Negócio',
        es: 'Nombre del Negocio',
        ru: 'Название Бизнеса',
        fr: 'Nom de l\'Entreprise',
        de: 'Geschäftsname'
    },
    'Business Description': {
        pt: 'Descrição do Negócio',
        es: 'Descripción del Negocio',
        ru: 'Описание Бизнеса',
        fr: 'Description de l\'Entreprise',
        de: 'Geschäftsbeschreibung'
    },
    'Please enter a business description.': {
        pt: 'Por favor, insira uma descrição do negócio.',
        es: 'Por favor, ingrese una descripción del negocio.',
        ru: 'Пожалуйста, введите описание бизнеса.',
        fr: 'Veuillez entrer une description de l\'entreprise.',
        de: 'Bitte geben Sie eine Geschäftsbeschreibung ein.'
    },
    'You can\'t use any variation of the term "tidy" in your team or website url.': {
        pt: 'Você não pode usar nenhuma variação do termo "tidy" no nome da sua equipe ou URL do site.',
        es: 'No puede usar ninguna variación del término "tidy" en el nombre de su equipo o URL del sitio web.',
        ru: 'Вы не можете использовать никакие вариации термина "tidy" в названии вашей команды или URL вебсайта.',
        fr: 'Vous ne pouvez pas utiliser de variation du terme "tidy" dans le nom de votre équipe ou l\'URL de votre site web.',
        de: 'Sie können keine Variation des Begriffs "tidy" im Namen Ihres Teams oder in der URL Ihrer Webseite verwenden.'
    },
    'Please enter your business name.': {
        pt: 'Por favor, insira o nome do seu negócio.',
        es: 'Por favor, ingrese el nombre de su negocio.',
        ru: 'Пожалуйста, введите название вашего бизнеса.',
        fr: 'Veuillez entrer le nom de votre entreprise.',
        de: 'Bitte geben Sie den Namen Ihres Unternehmens ein.'
    },
    'Detail Updated': {
        pt: 'Detalhe Atualizado',
        es: 'Detalle Actualizado',
        ru: 'Детали Обновлены',
        fr: 'Détail Mis à Jour',
        de: 'Detail Aktualisiert'
    },
    'This detail will now display on your website.': {
        pt: 'Este detalhe agora será exibido no seu site.',
        es: 'Este detalle ahora se mostrará en su sitio web.',
        ru: 'Эта информация теперь будет отображаться на вашем вебсайте.',
        fr: 'Ce détail sera désormais affiché sur votre site web.',
        de: 'Dieses Detail wird nun auf Ihrer Webseite angezeigt.'
    },
    'Website URL': {
        pt: 'URL do Site',
        es: 'URL del Sitio Web',
        ru: 'URL Вебсайта',
        fr: 'URL du Site Web',
        de: 'Webseiten-URL'
    },
    'Please enter the URL.': {
        pt: 'Por favor, insira o URL.',
        es: 'Por favor, ingrese la URL.',
        ru: 'Пожалуйста, введите URL.',
        fr: 'Veuillez entrer l\'URL.',
        de: 'Bitte geben Sie die URL ein.'
    },
    'URL Updated': {
        pt: 'URL Atualizada',
        es: 'URL Actualizada',
        ru: 'URL Обновлен',
        fr: 'URL Mise à Jour',
        de: 'URL Aktualisiert'
    },
    'You can now access your website at': {
        pt: 'Você pode agora acessar seu site em',
        es: 'Ahora puede acceder a su sitio web en',
        ru: 'Теперь вы можете получить доступ к вашему вебсайту по адресу',
        fr: 'Vous pouvez maintenant accéder à votre site web à',
        de: 'Sie können jetzt auf Ihre Webseite zugreifen unter'
    },

    'Too Late for Action': {
        pt: 'Tarde Demais para Ação',
        es: 'Demasiado Tarde para la Acción',
        ru: 'Слишком Поздно для Действия',
        fr: 'Trop Tard pour Agir',
        de: 'Zu Spät für Aktion'
    },
    'It is too late to perform this action.  You can report any issues by clicking on the past job in the "Jobs" page.': {
        pt: 'É tarde demais para realizar esta ação. Você pode relatar quaisquer problemas clicando no trabalho passado na página "Trabalhos".',
        es: 'Es demasiado tarde para realizar esta acción. Puede informar cualquier problema haciendo clic en el trabajo pasado en la página "Trabajos".',
        ru: 'Слишком поздно выполнять это действие. Вы можете сообщить о любых проблемах, нажав на прошлую работу на странице "Работы".',
        fr: 'Il est trop tard pour effectuer cette action. Vous pouvez signaler tout problème en cliquant sur le travail passé dans la page "Travaux".',
        de: 'Es ist zu spät, um diese Aktion auszuführen. Sie können Probleme melden, indem Sie auf den vergangenen Job auf der Seite "Jobs" klicken.'
    },
    'This was cancelled by the client.  You will receive a last minute cancellation fee.': {
        pt: 'Isso foi cancelado pelo cliente. Você receberá uma taxa de cancelamento de última hora.',
        es: 'Esto fue cancelado por el cliente. Recibirá una tarifa de cancelación de último minuto.',
        ru: 'Это было отменено клиентом. Вы получите плату за отмену в последнюю минуту.',
        fr: 'Cela a été annulé par le client. Vous recevrez des frais d\'annulation de dernière minute.',
        de: 'Dies wurde vom Kunden storniert. Sie erhalten eine Last-Minute-Stornogebühr.'
    },
    'Standby Cancelled': {
        pt: 'Cancelamento de Standby',
        es: 'Cancelación de Reserva',
        ru: 'Отмена Резерва',
        fr: 'Annulation de la Réserve',
        de: 'Standby Abgesagt'
    },
    'You are no longer needed as a Standby.  You will receive a last minute cancellation fee.': {
        pt: 'Você não é mais necessário como Standby. Você receberá uma taxa de cancelamento de última hora.',
        es: 'Ya no se necesita como Reserva. Recibirá una tarifa de cancelación de último minuto.',
        ru: 'Вы больше не нужны как резерв. Вы получите плату за отмену в последнюю минуту.',
        fr: 'Vous n\'êtes plus nécessaire en tant que Réserve. Vous recevrez des frais d\'annulation de dernière minute.',
        de: 'Sie werden nicht mehr als Standby benötigt. Sie erhalten eine Last-Minute-Stornogebühr.'
    },
    'Go To Jobs': {
        pt: 'Ir para Trabalhos',
        es: 'Ir a Trabajos',
        ru: 'Перейти к Работам',
        fr: 'Aller aux Travaux',
        de: 'Zu den Jobs Gehen'
    },
    'Job No Longer Available': {
        pt: 'Trabalho Não Disponível',
        es: 'Trabajo Ya No Disponible',
        ru: 'Работа Больше Недоступна',
        fr: 'Travail Plus Disponible',
        de: 'Job Nicht Mehr Verfügbar'
    },
    'This job is no longer available. Tap on the past job in the "Jobs" page for more detail.': {
        pt: 'Este trabalho não está mais disponível. Toque no trabalho passado na página "Trabalhos" para mais detalhes.',
        es: 'Este trabajo ya no está disponible. Toque en el trabajo pasado en la página "Trabajos" para más detalles.',
        ru: 'Эта работа больше недоступна. Нажмите на прошлую работу на странице "Работы" для получения дополнительной информации.',
        fr: 'Ce travail n\'est plus disponible. Appuyez sur le travail passé dans la page "Travaux" pour plus de détails.',
        de: 'Dieser Job ist nicht mehr verfügbar. Tippen Sie auf den vergangenen Job auf der Seite "Jobs" für weitere Details.'
    },
    'You are offline.  The app works offline for some things, but not everything.  Connect to the internet to take the next steps.': {
        pt: 'Você está offline. O aplicativo funciona offline para algumas coisas, mas não para tudo. Conecte-se à internet para dar os próximos passos.',
        es: 'Estás desconectado. La aplicación funciona sin conexión para algunas cosas, pero no para todo. Conéctese a Internet para dar los siguientes pasos.',
        ru: 'Вы офлайн. Приложение работает офлайн для некоторых вещей, но не для всего. Подключитесь к интернету, чтобы предпринять следующие шаги.',
        fr: 'Vous êtes hors ligne. L\'application fonctionne hors ligne pour certaines choses, mais pas pour tout. Connectez-vous à Internet pour passer aux étapes suivantes.',
        de: 'Sie sind offline. Die App funktioniert offline für einige Dinge, aber nicht für alles. Verbinden Sie sich mit dem Internet, um die nächsten Schritte zu unternehmen.'
    },
    'There was a "timeout error". Most of the time this means a bad internet connection so please try again. Contact the TIDY Concierge if you think it is a different issue.': {
        pt: 'Houve um "erro de tempo limite". Na maioria das vezes, isso significa uma conexão ruim com a internet, então tente novamente. Entre em contato com o Concierge TIDY se achar que é um problema diferente.',
        es: 'Hubo un "error de tiempo de espera". La mayoría de las veces esto significa una mala conexión a Internet, así que inténtelo de nuevo. Póngase en contacto con el Concierge TIDY si cree que es un problema diferente.',
        ru: 'Произошла "ошибка тайм-аута". В большинстве случаев это означает плохое интернет-соединение, поэтому попробуйте снова. Свяжитесь с консьержем TIDY, если вы думаете, что это другая проблема.',
        fr: 'Il y a eu une "erreur de délai d\'attente". La plupart du temps, cela signifie une mauvaise connexion Internet, alors veuillez réessayer. Contactez le Concierge TIDY si vous pensez qu\'il s\'agit d\'un autre problème.',
        de: 'Es gab einen "Timeout-Fehler". Meistens bedeutet dies eine schlechte Internetverbindung, also versuchen Sie es bitte erneut. Kontaktieren Sie den TIDY Concierge, wenn Sie denken, dass es ein anderes Problem ist.'
    },
    'There was a server error:': {
        pt: 'Houve um erro no servidor:',
        es: 'Hubo un error en el servidor:',
        ru: 'Произошла ошибка сервера:',
        fr: 'Il y a eu une erreur de serveur :',
        de: 'Es gab einen Serverfehler:'
    },
    'Timeout Error': {
        pt: 'Erro de Tempo Limite',
        es: 'Error de Tiempo de Espera',
        ru: 'Ошибка Тайм-аута',
        fr: 'Erreur de Délai d\'attente',
        de: 'Zeitüberschreitungsfehler'
    },
    'Server Error': {
        pt: 'Erro do Servidor',
        es: 'Error del Servidor',
        ru: 'Ошибка Сервера',
        fr: 'Erreur du Serveur',
        de: 'Serverfehler'
    },
    'There was an error:': {
        pt: 'Houve um erro:',
        es: 'Hubo un error:',
        ru: 'Произошла ошибка:',
        fr: 'Il y a eu une erreur :',
        de: 'Es gab einen Fehler:'
    },
    'Error uploading confirm address photo.': {
        pt: 'Erro ao enviar foto de confirmação de endereço.',
        es: 'Error al subir la foto de confirmación de dirección.',
        ru: 'Ошибка загрузки фото подтверждения адреса.',
        fr: 'Erreur lors du téléchargement de la photo de confirmation d\'adresse.',
        de: 'Fehler beim Hochladen des Bestätigungsfotos der Adresse.'
    },

    'Connect to the Internet to Continue': {
        pt: 'Conecte-se à Internet para continuar',
        es: 'Conéctese a Internet para continuar',
        ru: 'Подключитесь к интернету, чтобы продолжить',
        fr: 'Connectez-vous à Internet pour continuer',
        de: 'Verbinden Sie sich mit dem Internet, um fortzufahren'
    },
    'The app works offline for some things, but not everything.  Connect to the internet to take the next steps.': {
        pt: 'O aplicativo funciona offline para algumas coisas, mas não para tudo. Conecte-se à internet para dar os próximos passos.',
        es: 'La aplicación funciona sin conexión para algunas cosas, pero no para todo. Conéctese a Internet para dar los siguientes pasos.',
        ru: 'Приложение работает офлайн для некоторых вещей, но не для всего. Подключитесь к интернету, чтобы предпринять следующие шаги.',
        fr: 'L\'application fonctionne hors ligne pour certaines choses, mais pas pour tout. Connectez-vous à Internet pour passer aux étapes suivantes.',
        de: 'Die App funktioniert offline für einige Dinge, aber nicht für alles. Verbinden Sie sich mit dem Internet, um die nächsten Schritte zu unternehmen.'
    },
    'Try Again - I\'m Online': {
        pt: 'Tente novamente - Estou online',
        es: 'Inténtalo de nuevo - Estoy en línea',
        ru: 'Попробуйте снова - я в сети',
        fr: 'Réessayez - Je suis en ligne',
        de: 'Versuchen Sie es erneut - Ich bin online'
    },
    'Continue Job Offline?': {
        pt: 'Continuar trabalho offline?',
        es: '¿Continuar trabajo sin conexión?',
        ru: 'Продолжить работу офлайн?',
        fr: 'Continuer le travail hors ligne?',
        de: 'Arbeit offline fortsetzen?'
    },
    'You appear to be offline. Would you like to continue your job offline or try to go to the dashboard once online?': {
        pt: 'Você parece estar offline. Gostaria de continuar seu trabalho offline ou tentar ir para o painel assim que estiver online?',
        es: 'Parece que estás desconectado. ¿Te gustaría continuar tu trabajo sin conexión o intentar ir al panel una vez en línea?',
        ru: 'Похоже, вы офлайн. Хотите продолжить работу офлайн или попробовать перейти на панель управления, когда будете онлайн?',
        fr: 'Vous semblez être hors ligne. Souhaitez-vous continuer votre travail hors ligne ou essayer d\'accéder au tableau de bord une fois en ligne?',
        de: 'Sie scheinen offline zu sein. Möchten Sie Ihre Arbeit offline fortsetzen oder versuchen, zum Dashboard zu gelangen, sobald Sie online sind?'
    },
    'Continue Job Offline': {
        pt: 'Continuar trabalho offline',
        es: 'Continuar trabajo sin conexión',
        ru: 'Продолжить работу офлайн',
        fr: 'Continuer le travail hors ligne',
        de: 'Arbeit offline fortsetzen'
    },
    'Regular Cleaning': {
        pt: 'Limpeza Regular',
        es: 'Limpieza Regular',
        ru: 'Регулярная уборка',
        fr: 'Nettoyage Régulier',
        de: 'Regelmäßige Reinigung'
    },
    'Client will reimburse up to': {
        pt: 'O cliente reembolsará até',
        es: 'El cliente reembolsará hasta',
        ru: 'Клиент возместит до',
        fr: 'Le client remboursera jusqu\'à',
        de: 'Der Kunde erstattet bis zu'
    },
    'lot': {
        pt: 'lote',
        es: 'lote',
        ru: 'участок',
        fr: 'lot',
        de: 'Grundstück'
    },
    'meter': {
        pt: 'metro',
        es: 'metro',
        ru: 'метр',
        fr: 'mètre',
        de: 'Meter'
    },
    'client spot / driveway': {
        pt: 'vaga do cliente / entrada de automóveis',
        es: 'lugar del cliente / entrada de coches',
        ru: 'место клиента / подъездная дорожка',
        fr: 'emplacement client / allée',
        de: 'Kundenplatz / Einfahrt'
    },
    'guest': {
        pt: 'convidado',
        es: 'invitado',
        ru: 'гость',
        fr: 'invité',
        de: 'Gast'
    },
    'street': {
        pt: 'rua',
        es: 'calle',
        ru: 'улица',
        fr: 'rue',
        de: 'Straße'
    },
    'on their card.  Send a picture of your receipt to us in the Concierge section.': {
        pt: 'no cartão deles. Envie uma foto do seu recibo para nós na seção Concierge.',
        es: 'en su tarjeta. Envíenos una foto de su recibo en la sección de Concierge.',
        ru: 'на их карте. Отправьте нам фотографию вашего чека в разделе Консьерж.',
        fr: 'sur leur carte. Envoyez-nous une photo de votre reçu dans la section Concierge.',
        de: 'auf ihrer Karte. Senden Sie uns ein Bild Ihres Belegs im Concierge-Bereich.'
    },
    'with cash in person.': {
        pt: 'com dinheiro em espécie.',
        es: 'con efectivo en persona.',
        ru: 'наличными лично.',
        fr: 'en espèces en personne.',
        de: 'mit Bargeld persönlich.'
    },

    'Arrived at Job': {
        pt: 'Chegou ao Trabalho',
        es: 'Llegó al Trabajo',
        ru: 'Прибыл на работу',
        fr: 'Arrivé au travail',
        de: 'Am Arbeitsplatz angekommen'
    },
    'Client was notified you <b>arrived</b>.': {
        pt: 'O cliente foi notificado de que você <b>chegou</b>.',
        es: 'El cliente fue notificado de que <b>llegaste</b>.',
        ru: 'Клиент был уведомлен, что вы <b>прибыли</b>.',
        fr: 'Le client a été informé que vous êtes <b>arrivé</b>.',
        de: 'Der Kunde wurde benachrichtigt, dass Sie <b>angekommen</b> sind.'
    },
    'I\'m Inside - Start Job': {
        pt: 'Estou Dentro - Começar Trabalho',
        es: 'Estoy Dentro - Comenzar Trabajo',
        ru: 'Я внутри - Начать работу',
        fr: 'Je suis à l\'intérieur - Commencer le travail',
        de: 'Ich bin drinnen - Arbeit beginnen'
    },
    'You will be cleaning with ': {
        pt: 'Você estará limpando com ',
        es: 'Estarás limpiando con ',
        ru: 'Вы будете убирать с ',
        fr: 'Vous allez nettoyer avec ',
        de: 'Sie werden mit reinigen '
    },
    'Intro Video': {
        pt: 'Vídeo de Introdução',
        es: 'Vídeo de Introducción',
        ru: 'Вводное видео',
        fr: 'Vidéo d\'introduction',
        de: 'Einführungsvideo'
    },
    'Client Refused Service': {
        pt: 'Cliente Recusou Serviço',
        es: 'Cliente Rechazó el Servicio',
        ru: 'Клиент отказался от услуги',
        fr: 'Le client a refusé le service',
        de: 'Kunde hat den Service abgelehnt'
    },
    'Cant Access Property': {
        pt: 'Não Pode Acessar a Propriedade',
        es: 'No Puede Acceder a la Propiedad',
        ru: 'Не могу получить доступ к собственности',
        fr: 'Impossible d\'accéder à la propriété',
        de: 'Kein Zugang zur Immobilie'
    },
    'Can\'t Access Home?': {
        pt: 'Não Consegue Acessar a Casa?',
        es: '¿No Puede Acceder a la Casa?',
        ru: 'Не можете попасть домой?',
        fr: 'Vous ne pouvez pas accéder à la maison?',
        de: 'Kein Zugang zum Haus?'
    },
    'Notify the client that you are having trouble accessing their home?': {
        pt: 'Notifique o cliente que você está tendo problemas para acessar a casa deles?',
        es: '¿Notifique al cliente que está teniendo problemas para acceder a su casa?',
        ru: 'Уведомить клиента о том, что у вас проблемы с доступом к его дому?',
        fr: 'Notifier le client que vous avez des difficultés à accéder à sa maison?',
        de: 'Den Kunden benachrichtigen, dass Sie Schwierigkeiten haben, sein Haus zu betreten?'
    },

    'Cancelling on clients is the most common way to lose them.': {
        pt: 'Cancelar com os clientes é a maneira mais comum de perdê-los.',
        es: 'Cancelar con los clientes es la forma más común de perderlos.',
        ru: 'Отмена у клиентов - самый распространенный способ их потерять.',
        fr: 'Annuler avec les clients est la façon la plus courante de les perdre.',
        de: 'Das Absagen bei Kunden ist die häufigste Art, sie zu verlieren.'
    },
    'Please let your client know why you are cancelling on them': {
        pt: 'Por favor, informe ao seu cliente por que você está cancelando.',
        es: 'Por favor, informe a su cliente por qué está cancelando.',
        ru: 'Пожалуйста, сообщите своему клиенту, почему вы отменяете.',
        fr: 'Veuillez informer votre client de la raison pour laquelle vous annulez.',
        de: 'Bitte lassen Sie Ihren Kunden wissen, warum Sie stornieren.'
    },
    'Successfully Cancelled Job': {
        pt: 'Trabalho Cancelado com Sucesso',
        es: 'Trabajo Cancelado con Éxito',
        ru: 'Работа успешно отменена',
        fr: 'Travail annulé avec succès',
        de: 'Arbeit erfolgreich storniert'
    },
    'Successfully Called Out': {
        pt: 'Chamado com Sucesso',
        es: 'Llamado con Éxito',
        ru: 'Успешно вызвано',
        fr: 'Appel réussi',
        de: 'Erfolgreich gerufen'
    },
    'Your clients have been notified.': {
        pt: 'Seus clientes foram notificados.',
        es: 'Sus clientes han sido notificados.',
        ru: 'Ваши клиенты были уведомлены.',
        fr: 'Vos clients ont été informés.',
        de: 'Ihre Kunden wurden benachrichtigt.'
    },
    'You notified ': {
        pt: 'Você notificou ',
        es: 'Usted notificó ',
        ru: 'Вы уведомили ',
        fr: 'Vous avez notifié ',
        de: 'Sie haben benachrichtigt '
    },
    '. Make best efforts to get in.': {
        pt: '. Faça o melhor para entrar.',
        es: '. Haga el mejor esfuerzo para entrar.',
        ru: '. Сделайте все возможное, чтобы войти.',
        fr: '. Faites de votre mieux pour entrer.',
        de: '. Bemühen Sie sich, hineinzukommen.'
    },
    'Use your number on file ': {
        pt: 'Use seu número registrado ',
        es: 'Use su número registrado ',
        ru: 'Используйте свой зарегистрированный номер ',
        fr: 'Utilisez votre numéro enregistré ',
        de: 'Verwenden Sie Ihre registrierte Nummer '
    },
    'Call ': {
        pt: 'Ligar ',
        es: 'Llamar ',
        ru: 'Позвонить ',
        fr: 'Appeler ',
        de: 'Anrufen '
    },
    ' Many Times': {
        pt: ' Muitas Vezes',
        es: ' Muchas Veces',
        ru: ' Много раз',
        fr: ' Plusieurs fois',
        de: ' Viele Male'
    },
    'Text ': {
        pt: 'Texto ',
        es: 'Texto ',
        ru: 'Текст ',
        fr: 'Texte ',
        de: 'Text '
    },
    'Try until ': {
        pt: 'Tente até ',
        es: 'Intente hasta ',
        ru: 'Пробуйте до ',
        fr: 'Essayez jusqu\'à ',
        de: 'Versuchen Sie bis '
    },
    'Starting at ': {
        pt: 'Começando às ',
        es: 'Comenzando a las ',
        ru: 'Начало в ',
        fr: 'Commence à ',
        de: 'Beginn um '
    },
    'Client still helping?': {
        pt: 'Cliente ainda ajudando?',
        es: '¿Cliente todavía ayudando?',
        ru: 'Клиент все еще помогает?',
        fr: 'Client toujours en train d\'aider?',
        de: 'Kunde hilft noch?'
    },
    'Your wait time counts against the client.  Stay as long as you’d like to make the client happy.': {
        pt: 'Seu tempo de espera conta contra o cliente. Fique o tempo que quiser para deixar o cliente feliz.',
        es: 'Su tiempo de espera cuenta contra el cliente. Quédese todo el tiempo que desee para hacer feliz al cliente.',
        ru: 'Ваше время ожидания засчитывается против клиента. Оставайтесь столько, сколько хотите, чтобы сделать клиента счастливым.',
        fr: 'Votre temps d\'attente est compté contre le client. Restez aussi longtemps que vous le souhaitez pour rendre le client heureux.',
        de: 'Ihre Wartezeit zählt gegen den Kunden. Bleiben Sie so lange, wie Sie möchten, um den Kunden glücklich zu machen.'
    },
    'Check that you followed ': {
        pt: 'Verifique se você seguiu ',
        es: 'Verifique que haya seguido ',
        ru: 'Проверьте, что вы следовали ',
        fr: 'Vérifiez que vous avez suivi ',
        de: 'Überprüfen Sie, ob Sie gefolgt sind '
    },
    'Got Access - Back to Job': {
        pt: 'Acesso Concedido - Voltar ao Trabalho',
        es: 'Acceso Concedido - Volver al Trabajo',
        ru: 'Доступ получен - Вернуться к работе',
        fr: 'Accès obtenu - Retour au travail',
        de: 'Zugang erhalten - Zurück zur Arbeit'
    },
    'I Could Not Access the Home': {
        pt: 'Não Consegui Acessar a Casa',
        es: 'No Pude Acceder a la Casa',
        ru: 'Я не смог получить доступ к дому',
        fr: 'Je n\'ai pas pu accéder à la maison',
        de: 'Ich konnte nicht auf das Haus zugreifen'
    },
    'Not enough calls to the Client. Try calling or texting for help.': {
        pt: 'Não há chamadas suficientes para o Cliente. Tente ligar ou enviar mensagens para obter ajuda.',
        es: 'No hay suficientes llamadas al Cliente. Intente llamar o enviar mensajes para obtener ayuda.',
        ru: 'Недостаточно звонков клиенту. Попробуйте позвонить или отправить сообщение для получения помощи.',
        fr: 'Pas assez d\'appels au client. Essayez d\'appeler ou d\'envoyer un message pour obtenir de l\'aide.',
        de: 'Nicht genug Anrufe beim Kunden. Versuchen Sie, anzurufen oder eine Nachricht zu senden, um Hilfe zu erhalten.'
    },
    'Please wait at least 20 minutes to cancel the job.': {
        pt: 'Por favor, aguarde pelo menos 20 minutos para cancelar o trabalho.',
        es: 'Por favor, espere al menos 20 minutos para cancelar el trabajo.',
        ru: 'Пожалуйста, подождите не менее 20 минут, чтобы отменить работу.',
        fr: 'Veuillez attendre au moins 20 minutes pour annuler le travail.',
        de: 'Bitte warten Sie mindestens 20 Minuten, um die Arbeit abzubrechen.'
    },
    'We are so sorry that the client didn\'t help you get into their home.': {
        pt: 'Lamentamos muito que o cliente não tenha ajudado você a entrar em sua casa.',
        es: 'Lamentamos mucho que el cliente no le haya ayudado a entrar en su casa.',
        ru: 'Нам очень жаль, что клиент не помог вам попасть в его дом.',
        fr: 'Nous sommes désolés que le client ne vous ait pas aidé à entrer chez lui.',
        de: 'Es tut uns sehr leid, dass der Kunde Ihnen nicht geholfen hat, in sein Haus zu gelangen.'
    },

    'How did ': {
        pt: 'Como ',
        es: '¿Cómo ',
        ru: 'Как ',
        fr: 'Comment ',
        de: 'Wie '
    },
    ' tell you they refused service?': {
        pt: ' lhe disse que recusou o serviço?',
        es: ' le dijo que rechazó el servicio?',
        ru: ' сказал вам, что отказался от услуги?',
        fr: ' vous a-t-il dit qu\'il a refusé le service?',
        de: ' hat Ihnen gesagt, dass er den Dienst abgelehnt hat?'
    },
    'In Person': {
        pt: 'Pessoalmente',
        es: 'En Persona',
        ru: 'Лично',
        fr: 'En personne',
        de: 'Persönlich'
    },
    'Text / Phone': {
        pt: 'Texto / Telefone',
        es: 'Texto / Teléfono',
        ru: 'Текст / Телефон',
        fr: 'Texte / Téléphone',
        de: 'Text / Telefon'
    },
    "Can't Access Property": {
        pt: 'Não é possível acessar a propriedade',
        es: 'No se puede acceder a la propiedad',
        ru: 'Невозможно получить доступ к собственности',
        fr: 'Impossible d\'accéder à la propriété',
        de: 'Zugriff auf das Grundstück nicht möglich'
    },
    "Can't Contact Them": {
        pt: 'Não é possível contatá-los',
        es: 'No se puede contactarlos',
        ru: 'Невозможно связаться с ними',
        fr: 'Impossible de les contacter',
        de: 'Kann sie nicht kontaktieren'
    },
    'Client Refused Service?': {
        pt: 'Serviço Recusado pelo Cliente?',
        es: '¿Servicio Rechazado por el Cliente?',
        ru: 'Клиент отказался от услуги?',
        fr: 'Service refusé par le client?',
        de: 'Dienstleistung vom Kunden abgelehnt?'
    },
    'Send Client notice they cancelled & may be charged a fee? Only do this if the Client says that they cancel. <strong>If you cannot contact the Client do not use this.</strong> Go back and use the button that best describes the issue.': {
        pt: 'Enviar aviso ao Cliente de que cancelaram e podem ser cobrados? Faça isso apenas se o Cliente disser que cancelou. <strong>Se você não conseguir contatar o Cliente, não use isso.</strong> Volte e use o botão que melhor descreve o problema.',
        es: '¿Enviar aviso al Cliente de que cancelaron y pueden ser cobrados? Haga esto solo si el Cliente dice que canceló. <strong>Si no puede contactar al Cliente, no use esto.</strong> Vuelva y use el botón que mejor describa el problema.',
        ru: 'Отправить клиенту уведомление о том, что они отменили и могут быть взиматься? Делайте это только в том случае, если клиент говорит, что отменяет. <strong>Если вы не можете связаться с клиентом, не используйте это.</strong> Вернитесь и используйте кнопку, которая лучше всего описывает проблему.',
        fr: 'Envoyer un avis au client qu\'ils ont annulé et peuvent être facturés? Ne faites cela que si le client dit qu\'il annule. <strong>Si vous ne pouvez pas contacter le client, n\'utilisez pas cela.</strong> Revenez en arrière et utilisez le bouton qui décrit le mieux le problème.',
        de: 'Kundenbenachrichtigung senden, dass sie storniert haben und möglicherweise eine Gebühr erhoben wird? Tun Sie dies nur, wenn der Kunde sagt, dass er storniert. <strong>Wenn Sie den Kunden nicht kontaktieren können, verwenden Sie dies nicht.</strong> Gehen Sie zurück und verwenden Sie die Schaltfläche, die das Problem am besten beschreibt.'
    },
    'Confirm & Send': {
        pt: 'Confirmar e Enviar',
        es: 'Confirmar y Enviar',
        ru: 'Подтвердить и отправить',
        fr: 'Confirmer et envoyer',
        de: 'Bestätigen und senden'
    },
    'We have sent a notification to this Client': {
        pt: 'Enviamos uma notificação para este Cliente',
        es: 'Hemos enviado una notificación a este Cliente',
        ru: 'Мы отправили уведомление этому клиенту',
        fr: 'Nous avons envoyé une notification à ce client',
        de: 'Wir haben eine Benachrichtigung an diesen Kunden gesendet'
    },
    'Not a Valid Option': {
        pt: 'Opção Inválida',
        es: 'Opción No Válida',
        ru: 'Недопустимый вариант',
        fr: 'Option non valide',
        de: 'Keine gültige Option'
    },
    'Client refusing service is only authorized when clients tell you they cancel. If you are unable to access or find the home, tap "Can\'t Access Home" or "Can\'t Find Home" and follow the steps. This includes if you cannot contact them.': {
        pt: 'A recusa de serviço pelo cliente só é autorizada quando os clientes informam que cancelam. Se você não conseguir acessar ou encontrar a casa, toque em "Não é possível acessar a casa" ou "Não é possível encontrar a casa" e siga as etapas. Isso inclui se você não puder contatá-los.',
        es: 'La negativa del cliente al servicio solo está autorizada cuando los clientes le dicen que cancelan. Si no puede acceder o encontrar la casa, toque "No se puede acceder a la casa" o "No se puede encontrar la casa" y siga los pasos. Esto incluye si no puede contactarlos.',
        ru: 'Отказ клиента от услуги разрешен только в том случае, если клиенты сообщают, что они отменяют. Если вы не можете получить доступ или найти дом, нажмите "Невозможно получить доступ к дому" или "Невозможно найти дом" и следуйте инструкциям. Это включает в себя, если вы не можете с ними связаться.',
        fr: 'Le refus de service par le client n\'est autorisé que lorsque les clients vous disent qu\'ils annulent. Si vous ne pouvez pas accéder ou trouver la maison, appuyez sur "Impossible d\'accéder à la maison" ou "Impossible de trouver la maison" et suivez les étapes. Cela inclut si vous ne pouvez pas les contacter.',
        de: 'Die Ablehnung des Dienstes durch den Kunden ist nur zulässig, wenn die Kunden Ihnen mitteilen, dass sie stornieren. Wenn Sie nicht auf das Haus zugreifen oder es finden können, tippen Sie auf "Kein Zugriff auf das Haus" oder "Haus nicht finden" und folgen Sie den Schritten. Dies gilt auch, wenn Sie sie nicht kontaktieren können.'
    },
    'Back To Job': {
        pt: 'Voltar ao Trabalho',
        es: 'Volver al Trabajo',
        ru: 'Вернуться к работе',
        fr: 'Retour au travail',
        de: 'Zurück zur Arbeit'
    },
    'Exit Job?': {
        pt: 'Sair do Trabalho?',
        es: '¿Salir del Trabajo?',
        ru: 'Выйти из работы?',
        fr: 'Quitter le travail?',
        de: 'Arbeit beenden?'
    },
    'Why do you need to exit the job?': {
        pt: 'Por que você precisa sair do trabalho?',
        es: '¿Por qué necesitas salir del trabajo?',
        ru: 'Почему вам нужно выйти из работы?',
        fr: 'Pourquoi devez-vous quitter le travail?',
        de: 'Warum müssen Sie die Arbeit beenden?'
    },
    'Resume Job': {
        pt: 'Retomar Trabalho',
        es: 'Reanudar Trabajo',
        ru: 'Возобновить работу',
        fr: 'Reprendre le travail',
        de: 'Arbeit fortsetzen'
    },
    'Not Able to Finish?': {
        pt: 'Não Consegue Terminar?',
        es: '¿No Puede Terminar?',
        ru: 'Не можете закончить?',
        fr: 'Pas capable de finir?',
        de: 'Nicht in der Lage zu beenden?'
    },
    '<b>Time Left: </b>': {
        pt: '<b>Tempo Restante: </b>',
        es: '<b>Tiempo Restante: </b>',
        ru: '<b>Оставшееся время: </b>',
        fr: '<b>Temps restant : </b>',
        de: '<b>Verbleibende Zeit: </b>'
    },
    'The client might dispute the job if you don\'t clean the full time they booked and they are unhappy with the job.': {
        pt: 'O cliente pode contestar o trabalho se você não limpar o tempo total que eles reservaram e ficarem insatisfeitos com o trabalho.',
        es: 'El cliente puede disputar el trabajo si no limpia todo el tiempo que reservaron y están descontentos con el trabajo.',
        ru: 'Клиент может оспорить работу, если вы не уберете все время, которое они забронировали, и они недовольны работой.',
        fr: 'Le client peut contester le travail si vous ne nettoyez pas tout le temps qu\'ils ont réservé et qu\'ils ne sont pas satisfaits du travail.',
        de: 'Der Kunde könnte die Arbeit anfechten, wenn Sie nicht die gesamte gebuchte Zeit reinigen und mit der Arbeit unzufrieden sind.'
    },
    'Tell Client I Can\'t Stay': {
        pt: 'Diga ao Cliente que Não Posso Ficar',
        es: 'Dile al Cliente que No Puedo Quedarme',
        ru: 'Скажите клиенту, что я не могу остаться',
        fr: 'Dites au client que je ne peux pas rester',
        de: 'Sagen Sie dem Kunden, dass ich nicht bleiben kann'
    },
    'Client Told Me to Leave': {
        pt: 'Cliente Me Disse para Sair',
        es: 'Cliente Me Dijo que Me Fuera',
        ru: 'Клиент сказал мне уйти',
        fr: 'Le client m\'a dit de partir',
        de: 'Kunde sagte mir zu gehen'
    },
    'Why did they ask you to leave?': {
        pt: 'Por que eles pediram para você sair?',
        es: '¿Por qué te pidieron que te fueras?',
        ru: 'Почему они попросили вас уйти?',
        fr: 'Pourquoi vous ont-ils demandé de partir?',
        de: 'Warum haben sie Sie gebeten zu gehen?'
    },
    'They Were Unhappy With Me': {
        pt: 'Eles Estavam Insatisfeitos Comigo',
        es: 'Estaban Descontentos Conmigo',
        ru: 'Они были недовольны мной',
        fr: 'Ils étaient mécontents de moi',
        de: 'Sie waren unzufrieden mit mir'
    },
    'They Did Not Want the Full Time': {
        pt: 'Eles Não Queriam o Tempo Completo',
        es: 'No Querían el Tiempo Completo',
        ru: 'Они не хотели полное время',
        fr: 'Ils ne voulaient pas le temps complet',
        de: 'Sie wollten nicht die volle Zeit'
    },
    'Please confirm that you are leaving early because you can\'t stay.': {
        pt: 'Por favor, confirme que você está saindo mais cedo porque não pode ficar.',
        es: 'Por favor, confirme que se va temprano porque no puede quedarse.',
        ru: 'Пожалуйста, подтвердите, что вы уходите рано, потому что не можете остаться.',
        fr: 'Veuillez confirmer que vous partez tôt parce que vous ne pouvez pas rester.',
        de: 'Bitte bestätigen Sie, dass Sie früh gehen, weil Sie nicht bleiben können.'
    },
    'Client Asked You to Leave': {
        pt: 'Cliente Pediu para Você Sair',
        es: 'Cliente Te Pidió que Te Fueras',
        ru: 'Клиент попросил вас уйти',
        fr: 'Le client vous a demandé de partir',
        de: 'Kunde hat Sie gebeten zu gehen'
    },
    'If the client is unhappy with your work and asks you to leave early they might dispute the job. This is rare.': {
        pt: 'Se o cliente estiver insatisfeito com o seu trabalho e pedir para você sair mais cedo, ele pode contestar o trabalho. Isso é raro.',
        es: 'Si el cliente está descontento con su trabajo y le pide que se vaya temprano, puede disputar el trabajo. Esto es raro.',
        ru: 'Если клиент недоволен вашей работой и просит вас уйти раньше, он может оспорить работу. Это редко.',
        fr: 'Si le client n\'est pas satisfait de votre travail et vous demande de partir plus tôt, il peut contester le travail. C\'est rare.',
        de: 'Wenn der Kunde mit Ihrer Arbeit unzufrieden ist und Sie bittet, früher zu gehen, könnte er die Arbeit anfechten. Das ist selten.'
    },
    'Client Didn\'t Want Full Job Time': {
        pt: 'Cliente Não Queria o Tempo Completo do Trabalho',
        es: 'Cliente No Quería el Tiempo Completo del Trabajo',
        ru: 'Клиент не хотел полное время работы',
        fr: 'Le client ne voulait pas le temps de travail complet',
        de: 'Kunde wollte nicht die volle Arbeitszeit'
    },
    'paid for': {
        pt: 'pago por',
        es: 'pagado por',
        ru: 'оплачено за',
        fr: 'payé pour',
        de: 'bezahlt für'
    },
    'and you started the job': {
        pt: 'e você começou o trabalho',
        es: 'y comenzaste el trabajo',
        ru: 'и вы начали работу',
        fr: 'et vous avez commencé le travail',
        de: 'und Sie haben die Arbeit begonnen'
    },
    'minutes ago': {
        pt: 'minutos atrás',
        es: 'hace minutos',
        ru: 'минут назад',
        fr: 'il y a minutes',
        de: 'vor Minuten'
    },
    'The client can still dispute the job. Leaving early, or asking to leave early, is always a risk. Since clients pay for a specific length of time, it is rare for them to authorize early departures. `Top complaints are leaving early or requesting to leave early': {
        pt: 'O cliente ainda pode contestar o trabalho. Sair mais cedo, ou pedir para sair mais cedo, é sempre um risco. Como os clientes pagam por um tempo específico, é raro que eles autorizem saídas antecipadas. As principais reclamações são sair mais cedo ou pedir para sair mais cedo',
        es: 'El cliente aún puede disputar el trabajo. Salir temprano, o pedir salir temprano, siempre es un riesgo. Dado que los clientes pagan por un tiempo específico, es raro que autoricen salidas anticipadas. Las principales quejas son salir temprano o pedir salir temprano',
        ru: 'Клиент все еще может оспорить работу. Уход раньше времени или просьба уйти раньше всегда является риском. Поскольку клиенты платят за определенное время, редко когда они разрешают ранние отъезды. Основные жалобы - это уход раньше времени или просьба уйти раньше',
        fr: 'Le client peut encore contester le travail. Partir tôt, ou demander à partir tôt, est toujours un risque. Étant donné que les clients paient pour une durée spécifique, il est rare qu\'ils autorisent les départs anticipés. Les principales plaintes concernent le départ anticipé ou la demande de départ anticipé',
        de: 'Der Kunde kann die Arbeit immer noch anfechten. Früher gehen oder um früheres Gehen bitten, ist immer ein Risiko. Da Kunden für eine bestimmte Zeit bezahlen, ist es selten, dass sie frühe Abreisen genehmigen. Die häufigsten Beschwerden sind frühes Gehen oder die Bitte, früher zu gehen'
    },
    'Manage your clients however you like, just be aware of the risks of leaving early.': {
        pt: 'Gerencie seus clientes como quiser, apenas esteja ciente dos riscos de sair mais cedo.',
        es: 'Gestione a sus clientes como desee, solo sea consciente de los riesgos de salir temprano.',
        ru: 'Управляйте своими клиентами, как вам нравится, просто будьте в курсе рисков раннего ухода.',
        fr: 'Gérez vos clients comme vous le souhaitez, soyez simplement conscient des risques de partir tôt.',
        de: 'Verwalten Sie Ihre Kunden, wie Sie möchten, seien Sie sich nur der Risiken des frühen Gehens bewusst.'
    },

    'Finish Far From Client': {
        pt: 'Concluir Longe do Cliente',
        es: 'Terminar Lejos del Cliente',
        ru: 'Завершить вдали от клиента',
        fr: 'Terminer loin du client',
        de: 'Abschluss fern vom Kunden'
    },
    'Your GPS indicates you are not near the Client\'s location.': {
        pt: 'Seu GPS indica que você não está perto da localização do cliente.',
        es: 'Su GPS indica que no está cerca de la ubicación del cliente.',
        ru: 'Ваш GPS указывает, что вы не находитесь рядом с местоположением клиента.',
        fr: 'Votre GPS indique que vous n\'êtes pas près de l\'emplacement du client.',
        de: 'Ihr GPS zeigt an, dass Sie sich nicht in der Nähe des Standorts des Kunden befinden.'
    },
    'I\'m Here - Try GPS Again': {
        pt: 'Estou Aqui - Tentar GPS Novamente',
        es: 'Estoy Aquí - Intentar GPS de Nuevo',
        ru: 'Я здесь - попробуйте GPS снова',
        fr: 'Je suis ici - Réessayer le GPS',
        de: 'Ich bin hier - GPS erneut versuchen'
    },
    'I\'m Here - Take Photo': {
        pt: 'Estou Aqui - Tirar Foto',
        es: 'Estoy Aquí - Tomar Foto',
        ru: 'Я здесь - сделать фото',
        fr: 'Je suis ici - Prendre une photo',
        de: 'Ich bin hier - Foto machen'
    },
    'I\'m Not Here Anymore': {
        pt: 'Não Estou Mais Aqui',
        es: 'Ya No Estoy Aquí',
        ru: 'Я больше не здесь',
        fr: 'Je ne suis plus ici',
        de: 'Ich bin nicht mehr hier'
    },
    'You have ': {
        pt: 'Você tem ',
        es: 'Tienes ',
        ru: 'У вас есть ',
        fr: 'Vous avez ',
        de: 'Sie haben '
    },
    ' to complete this page.': {
        pt: ' para completar esta página.',
        es: ' para completar esta página.',
        ru: ' чтобы завершить эту страницу.',
        fr: ' pour compléter cette page.',
        de: ' um diese Seite zu vervollständigen.'
    },
    'GPS Not Near Home': {
        pt: 'GPS Não Perto de Casa',
        es: 'GPS No Cerca de Casa',
        ru: 'GPS не рядом с домом',
        fr: 'GPS Pas Près de la Maison',
        de: 'GPS Nicht in der Nähe von Zuhause'
    },
    'Please try again or confirm you are not at the home.': {
        pt: 'Por favor, tente novamente ou confirme que você não está em casa.',
        es: 'Por favor, intente de nuevo o confirme que no está en casa.',
        ru: 'Пожалуйста, попробуйте снова или подтвердите, что вы не дома.',
        fr: 'Veuillez réessayer ou confirmer que vous n\'êtes pas à la maison.',
        de: 'Bitte versuchen Sie es erneut oder bestätigen Sie, dass Sie nicht zu Hause sind.'
    },
    'Location Found': {
        pt: 'Localização Encontrada',
        es: 'Ubicación Encontrada',
        ru: 'Местоположение найдено',
        fr: 'Emplacement Trouvé',
        de: 'Standort Gefunden'
    },
    'We found your location near the Client\'s home!  This job has been updated to On Time.': {
        pt: 'Encontramos sua localização perto da casa do cliente! Este trabalho foi atualizado para No Prazo.',
        es: '¡Encontramos su ubicación cerca de la casa del cliente! Este trabajo ha sido actualizado a A Tiempo.',
        ru: 'Мы нашли ваше местоположение рядом с домом клиента! Эта работа была обновлена до Вовремя.',
        fr: 'Nous avons trouvé votre emplacement près de la maison du client! Ce travail a été mis à jour à l\'heure.',
        de: 'Wir haben Ihren Standort in der Nähe des Hauses des Kunden gefunden! Dieser Auftrag wurde auf Pünktlich aktualisiert.'
    },
    'Not at the Home': {
        pt: 'Não Está em Casa',
        es: 'No Está en Casa',
        ru: 'Не дома',
        fr: 'Pas à la Maison',
        de: 'Nicht zu Hause'
    },
    'You confirmed you are not at the Client\'s home.': {
        pt: 'Você confirmou que não está na casa do cliente.',
        es: 'Confirmó que no está en la casa del cliente.',
        ru: 'Вы подтвердили, что не находитесь в доме клиента.',
        fr: 'Vous avez confirmé que vous n\'êtes pas chez le client.',
        de: 'Sie haben bestätigt, dass Sie nicht im Haus des Kunden sind.'
    },
    'Submit Photo': {
        pt: 'Enviar Foto',
        es: 'Enviar Foto',
        ru: 'Отправить фото',
        fr: 'Soumettre la photo',
        de: 'Foto einreichen'
    },

    'Finish Job': {
        pt: 'Concluir Trabalho',
        es: 'Terminar Trabajo',
        ru: 'Завершить работу',
        fr: 'Terminer le travail',
        de: 'Arbeit beenden'
    },
    'Please let us know how the job went.': {
        pt: 'Por favor, deixe-nos saber como foi o trabalho.',
        es: 'Por favor, háganos saber cómo fue el trabajo.',
        ru: 'Пожалуйста, дайте нам знать, как прошла работа.',
        fr: 'Veuillez nous faire savoir comment s\'est déroulé le travail.',
        de: 'Bitte lassen Sie uns wissen, wie die Arbeit verlaufen ist.'
    },
    'Job Feedback': {
        pt: 'Feedback do Trabalho',
        es: 'Comentarios del Trabajo',
        ru: 'Отзыв о работе',
        fr: 'Retour sur le travail',
        de: 'Rückmeldung zur Arbeit'
    },
    'Rate (they don\'t see this):': {
        pt: 'Avaliar (eles não veem isso):',
        es: 'Calificar (ellos no ven esto):',
        ru: 'Оцените (они этого не видят):',
        fr: 'Évaluer (ils ne voient pas cela) :',
        de: 'Bewerten (sie sehen das nicht):'
    },
    'Did you do the To-Dos?': {
        pt: 'Você fez as tarefas?',
        es: '¿Hiciste las tareas?',
        ru: 'Вы выполнили задачи?',
        fr: 'Avez-vous fait les tâches?',
        de: 'Haben Sie die Aufgaben erledigt?'
    },
    'Add Future Jobs?': {
        pt: 'Adicionar Trabalhos Futuros?',
        es: '¿Agregar Trabajos Futuros?',
        ru: 'Добавить будущие работы?',
        fr: 'Ajouter des travaux futurs?',
        de: 'Zukünftige Arbeiten hinzufügen?'
    },
    ' needs someone for their full plan.  Would you like them as your repeat Client?': {
        pt: ' precisa de alguém para seu plano completo. Gostaria de tê-los como seu cliente recorrente?',
        es: ' necesita a alguien para su plan completo. ¿Le gustaría tenerlos como su cliente recurrente?',
        ru: ' нуждается в ком-то для их полного плана. Хотите, чтобы они были вашим постоянным клиентом?',
        fr: ' a besoin de quelqu\'un pour leur plan complet. Souhaitez-vous les avoir comme client régulier?',
        de: ' benötigt jemanden für ihren vollständigen Plan. Möchten Sie sie als Ihren wiederkehrenden Kunden haben?'
    },
    'This means you will be available for them ': {
        pt: 'Isso significa que você estará disponível para eles ',
        es: 'Esto significa que estará disponible para ellos ',
        ru: 'Это означает, что вы будете доступны для них ',
        fr: 'Cela signifie que vous serez disponible pour eux ',
        de: 'Das bedeutet, dass Sie für sie verfügbar sind '
    },
    'No Thank You': {
        pt: 'Não, obrigado',
        es: 'No, gracias',
        ru: 'Нет, спасибо',
        fr: 'Non merci',
        de: 'Nein danke'
    },
    'Save & Finish Job': {
        pt: 'Salvar e Concluir Trabalho',
        es: 'Guardar y Terminar Trabajo',
        ru: 'Сохранить и завершить работу',
        fr: 'Enregistrer et terminer le travail',
        de: 'Speichern und Arbeit beenden'
    },

    'Yes - Accept ': {
        pt: 'Sim - Aceitar ',
        es: 'Sí - Aceptar ',
        ru: 'Да - Принять ',
        fr: 'Oui - Accepter ',
        de: 'Ja - Akzeptieren '
    },
    'Full Plan': {
        pt: 'Plano Completo',
        es: 'Plan Completo',
        ru: 'Полный план',
        fr: 'Plan Complet',
        de: 'Vollständiger Plan'
    },

    'Keep a strong relationship with your Clients by never cancelling.': {
        pt: 'Mantenha um relacionamento forte com seus clientes nunca cancelando.',
        es: 'Mantén una relación fuerte con tus clientes nunca cancelando.',
        ru: 'Поддерживайте крепкие отношения с клиентами, никогда не отменяя.',
        fr: 'Gardez une relation forte avec vos clients en ne jamais annulant.',
        de: 'Halten Sie eine starke Beziehung zu Ihren Kunden, indem Sie niemals absagen.'
    },
    'Client Fee': {
        pt: 'Taxa do Cliente',
        es: 'Tarifa del Cliente',
        ru: 'Клиентская плата',
        fr: 'Frais de client',
        de: 'Kundenhonorar'
    },
    'On the Way': {
        pt: 'A Caminho',
        es: 'En Camino',
        ru: 'В пути',
        fr: 'En route',
        de: 'Unterwegs'
    },
    'Tell Client I\'ve Arrived': {
        pt: 'Diga ao Cliente que Cheguei',
        es: 'Dile al Cliente que He Llegado',
        ru: 'Скажите клиенту, что я прибыл',
        fr: 'Dites au client que je suis arrivé',
        de: 'Sagen Sie dem Kunden, dass ich angekommen bin'
    },
    'This is a standby job.': {
        pt: 'Este é um trabalho de espera.',
        es: 'Este es un trabajo de espera.',
        ru: 'Это резервная работа.',
        fr: 'C\'est un travail de réserve.',
        de: 'Dies ist ein Bereitschaftsjob.'
    },
    'See access notes on next screen': {
        pt: 'Veja as notas de acesso na próxima tela',
        es: 'Vea las notas de acceso en la siguiente pantalla',
        ru: 'Смотрите заметки о доступе на следующем экране',
        fr: 'Voir les notes d\'accès à l\'écran suivant',
        de: 'Siehe Zugangshinweise auf dem nächsten Bildschirm'
    },
    'Too Early for Action': {
        pt: 'Muito Cedo para Ação',
        es: 'Demasiado Temprano para la Acción',
        ru: 'Слишком рано для действий',
        fr: 'Trop tôt pour agir',
        de: 'Zu früh für Maßnahmen'
    },
    'You are on standby for this job.  You cannot do this action until 5 minutes prior to the job for standby jobs.': {
        pt: 'Você está em espera para este trabalho. Você não pode realizar esta ação até 5 minutos antes do trabalho para trabalhos de espera.',
        es: 'Estás en espera para este trabajo. No puedes realizar esta acción hasta 5 minutos antes del trabajo para trabajos de espera.',
        ru: 'Вы находитесь в режиме ожидания для этой работы. Вы не можете выполнить это действие до 5 минут до начала работы для резервных работ.',
        fr: 'Vous êtes en attente pour ce travail. Vous ne pouvez pas effectuer cette action avant 5 minutes avant le travail pour les travaux de réserve.',
        de: 'Sie sind für diesen Job in Bereitschaft. Sie können diese Aktion erst 5 Minuten vor dem Job für Bereitschaftsjobs ausführen.'
    },
    'Unable to Notify': {
        pt: 'Incapaz de Notificar',
        es: 'Incapaz de Notificar',
        ru: 'Не удается уведомить',
        fr: 'Impossible de notifier',
        de: 'Nicht in der Lage zu benachrichtigen'
    },
    'You already have another job in progress.  Please tap on that job in the Jobs Page to finish it.': {
        pt: 'Você já tem outro trabalho em andamento. Por favor, toque nesse trabalho na página de trabalhos para finalizá-lo.',
        es: 'Ya tienes otro trabajo en progreso. Por favor, toca ese trabajo en la página de trabajos para terminarlo.',
        ru: 'У вас уже есть другая работа в процессе. Пожалуйста, нажмите на эту работу на странице работ, чтобы завершить её.',
        fr: 'Vous avez déjà un autre travail en cours. Veuillez appuyer sur ce travail dans la page des travaux pour le terminer.',
        de: 'Sie haben bereits einen anderen Job in Bearbeitung. Bitte tippen Sie auf diesen Job auf der Jobseite, um ihn zu beenden.'
    },
    'Having an issue with the other job?': {
        pt: 'Tendo um problema com o outro trabalho?',
        es: '¿Tienes un problema con el otro trabajo?',
        ru: 'Проблемы с другой работой?',
        fr: 'Avez-vous un problème avec l\'autre travail?',
        de: 'Haben Sie ein Problem mit der anderen Arbeit?'
    },
    'Clear Other Job Data': {
        pt: 'Limpar Dados de Outro Trabalho',
        es: 'Borrar Datos de Otro Trabajo',
        ru: 'Очистить данные другой работы',
        fr: 'Effacer les données d\'un autre travail',
        de: 'Andere Arbeitsdaten löschen'
    },
    'Clear Other Job Data?': {
        pt: 'Limpar Dados de Outro Trabalho?',
        es: '¿Borrar Datos de Otro Trabajo?',
        ru: 'Очистить данные другой работы?',
        fr: 'Effacer les données d\'un autre travail?',
        de: 'Andere Arbeitsdaten löschen?'
    },
    'Any unsynced updates will be lost, but the status of the job is not affected by clearing your cache.': {
        pt: 'Quaisquer atualizações não sincronizadas serão perdidas, mas o status do trabalho não é afetado ao limpar seu cache.',
        es: 'Cualquier actualización no sincronizada se perderá, pero el estado del trabajo no se ve afectado al borrar su caché.',
        ru: 'Любые несинхронизированные обновления будут потеряны, но статус работы не будет затронут очисткой вашего кэша.',
        fr: 'Toutes les mises à jour non synchronisées seront perdues, mais le statut du travail n\'est pas affecté par le nettoyage de votre cache.',
        de: 'Alle nicht synchronisierten Updates gehen verloren, aber der Status des Jobs wird durch das Löschen Ihres Caches nicht beeinflusst.'
    },
    'Other Job Data Cleared': {
        pt: 'Dados de Outro Trabalho Limpados',
        es: 'Datos de Otro Trabajo Borrados',
        ru: 'Данные другой работы очищены',
        fr: 'Données d\'un autre travail effacées',
        de: 'Andere Arbeitsdaten gelöscht'
    },
    'You can now notify on the way for this job.  Just tap on the job in the Jobs Page.': {
        pt: 'Você pode agora notificar no caminho para este trabalho. Basta tocar no trabalho na página de trabalhos.',
        es: 'Ahora puedes notificar en el camino para este trabajo. Simplemente toca en el trabajo en la página de trabajos.',
        ru: 'Теперь вы можете уведомить по пути для этой работы. Просто нажмите на работу на странице работ.',
        fr: 'Vous pouvez maintenant notifier en route pour ce travail. Appuyez simplement sur le travail dans la page des travaux.',
        de: 'Sie können jetzt auf dem Weg zu diesem Job benachrichtigen. Tippen Sie einfach auf den Job auf der Jobseite.'
    },
    'Clear Data': {
        pt: 'Limpar Dados',
        es: 'Borrar Datos',
        ru: 'Очистить данные',
        fr: 'Effacer les données',
        de: 'Daten löschen'
    },

    'Not Ready to Leave': {
        pt: 'Não Pronto para Sair',
        es: 'No Listo para Salir',
        ru: 'Не готов к выходу',
        fr: 'Pas prêt à partir',
        de: 'Nicht bereit zu gehen'
    },
    'Ready to Leave': {
        pt: 'Pronto para Sair',
        es: 'Listo para Salir',
        ru: 'Готов к выходу',
        fr: 'Prêt à partir',
        de: 'Bereit zu gehen'
    },
    'You are located <b>': {
        pt: 'Você está localizado <b>',
        es: 'Estás ubicado <b>',
        ru: 'Вы находитесь <b>',
        fr: 'Vous êtes situé <b>',
        de: 'Sie befinden sich <b>'
    },
    ' </b> away.': {
        pt: ' </b> de distância.',
        es: ' </b> de distancia.',
        ru: ' </b> вдали.',
        fr: ' </b> loin.',
        de: ' </b> entfernt.'
    },
    'Tell Client I\'m On the Way': {
        pt: 'Diga ao Cliente que Estou a Caminho',
        es: 'Dile al Cliente que Estoy en Camino',
        ru: 'Скажите клиенту, что я в пути',
        fr: 'Dites au client que je suis en route',
        de: 'Sagen Sie dem Kunden, dass ich unterwegs bin'
    },
    'Pending Delegation': {
        pt: 'Delegação Pendente',
        es: 'Delegación Pendiente',
        ru: 'Ожидающая делегация',
        fr: 'Délégation en Attente',
        de: 'Ausstehende Delegation'
    },
    'a newly invited team member': {
        pt: 'um membro recém-convidado da equipe',
        es: 'un miembro del equipo recién invitado',
        ru: 'новоприглашенный член команды',
        fr: 'un membre de l\'équipe nouvellement invité',
        de: 'ein neu eingeladenes Teammitglied'
    },
    'You invited a new team member and requested that they accept this job.': {
        pt: 'Você convidou um novo membro da equipe e solicitou que ele aceitasse este trabalho.',
        es: 'Invitaste a un nuevo miembro del equipo y solicitaste que aceptara este trabajo.',
        ru: 'Вы пригласили нового члена команды и попросили его принять эту работу.',
        fr: 'Vous avez invité un nouveau membre de l\'équipe et demandé qu\'il accepte ce travail.',
        de: 'Sie haben ein neues Teammitglied eingeladen und gebeten, diesen Job zu akzeptieren.'
    },
    '. All payment will be made to them.': {
        pt: '. Todo o pagamento será feito a eles.',
        es: '. Todo el pago se realizará a ellos.',
        ru: '. Все платежи будут произведены им.',
        fr: '. Tous les paiements leur seront versés.',
        de: '. Alle Zahlungen werden an sie geleistet.'
    },
    'Job Delegated': {
        pt: 'Trabalho Delegado',
        es: 'Trabajo Delegado',
        ru: 'Работа делегирована',
        fr: 'Travail Délégué',
        de: 'Job delegiert'
    },
    '. All payment will be made to you.': {
        pt: '. Todo o pagamento será feito a você.',
        es: '. Todo el pago se realizará a ti.',
        ru: '. Все платежи будут произведены вам.',
        fr: '. Tous les paiements vous seront versés.',
        de: '. Alle Zahlungen werden an Sie geleistet.'
    },
    'It is too early to notify the client.  Please wait until 85 minutes before the job.': {
        pt: 'É muito cedo para notificar o cliente. Por favor, aguarde até 85 minutos antes do trabalho.',
        es: 'Es demasiado pronto para notificar al cliente. Por favor, espere hasta 85 minutos antes del trabajo.',
        ru: 'Слишком рано уведомлять клиента. Пожалуйста, подождите до 85 минут до начала работы.',
        fr: 'Il est trop tôt pour notifier le client. Veuillez attendre jusqu\'à 85 minutes avant le travail.',
        de: 'Es ist zu früh, um den Kunden zu benachrichtigen. Bitte warten Sie bis 85 Minuten vor dem Job.'
    },
    'You are on standby for this job. You cannot do this action until 5 minutes prior to the job for standby jobs.': {
        pt: 'Você está em espera para este trabalho. Você não pode realizar esta ação até 5 minutos antes do trabalho para trabalhos em espera.',
        es: 'Estás en espera para este trabajo. No puedes realizar esta acción hasta 5 minutos antes del trabajo para trabajos en espera.',
        ru: 'Вы находитесь в режиме ожидания для этой работы. Вы не можете выполнить это действие до 5 минут до начала работы для резервных работ.',
        fr: 'Vous êtes en attente pour ce travail. Vous ne pouvez pas effectuer cette action avant 5 minutes avant le travail pour les travaux en attente.',
        de: 'Sie sind für diesen Job in Bereitschaft. Sie können diese Aktion erst 5 Minuten vor dem Job für Bereitschaftsjobs ausführen.'
    },
    'Please confirm you want to cancel the request for': {
        pt: 'Por favor, confirme que deseja cancelar a solicitação para',
        es: 'Por favor, confirma que deseas cancelar la solicitud para',
        ru: 'Пожалуйста, подтвердите, что вы хотите отменить запрос на',
        fr: 'Veuillez confirmer que vous souhaitez annuler la demande pour',
        de: 'Bitte bestätigen Sie, dass Sie die Anfrage für stornieren möchten'
    },
    'to accept this job.': {
        pt: 'aceitar este trabalho.',
        es: 'aceptar este trabajo.',
        ru: 'принять эту работу.',
        fr: 'accepter ce travail.',
        de: 'diesen Job annehmen.'
    },
    'Please confirm you want to give this job back to': {
        pt: 'Por favor, confirme que deseja devolver este trabalho para',
        es: 'Por favor, confirma que deseas devolver este trabajo a',
        ru: 'Пожалуйста, подтвердите, что вы хотите вернуть эту работу',
        fr: 'Veuillez confirmer que vous souhaitez rendre ce travail à',
        de: 'Bitte bestätigen Sie, dass Sie diesen Job zurückgeben möchten an'
    },
    'is now expected to complete this job.': {
        pt: 'agora é esperado para completar este trabalho.',
        es: 'ahora se espera que complete este trabajo.',
        ru: 'теперь ожидается, что выполнит эту работу.',
        fr: 'est maintenant censé terminer ce travail.',
        de: 'soll nun diesen Job abschließen.'
    },

    'This is the client\'s full To-Dos list.  You may want to speak with the other pro about dividing this up.': {
        pt: 'Esta é a lista completa de tarefas do cliente. Você pode querer falar com o outro profissional sobre como dividi-las.',
        es: 'Esta es la lista completa de tareas del cliente. Puede que quieras hablar con el otro profesional sobre cómo dividirlas.',
        ru: 'Это полный список задач клиента. Возможно, вам стоит поговорить с другим специалистом о разделении этих задач.',
        fr: 'Ceci est la liste complète des tâches du client. Vous voudrez peut-être parler avec l\'autre professionnel pour les diviser.',
        de: 'Dies ist die vollständige Aufgabenliste des Kunden. Sie sollten mit dem anderen Fachmann darüber sprechen, wie Sie diese aufteilen können.'
    },
    'Entering the Property': {
        pt: 'Entrando na Propriedade',
        es: 'Entrando en la Propiedad',
        ru: 'Вход на территорию',
        fr: 'Entrer dans la Propriété',
        de: 'Betreten des Grundstücks'
    },
    'To-Dos Off': {
        pt: 'Tarefas Desligadas',
        es: 'Tareas Desactivadas',
        ru: 'Задачи выключены',
        fr: 'Tâches Désactivées',
        de: 'Aufgaben Aus'
    },
    ' wants you to follow any verbal or written instructions and use your best judgement.': {
        pt: ' quer que você siga quaisquer instruções verbais ou escritas e use seu melhor julgamento.',
        es: ' quiere que sigas cualquier instrucción verbal o escrita y uses tu mejor juicio.',
        ru: ' хочет, чтобы вы следовали любым устным или письменным инструкциям и использовали свое лучшее суждение.',
        fr: ' veut que vous suiviez toutes les instructions verbales ou écrites et que vous fassiez preuve de votre meilleur jugement.',
        de: ' möchte, dass Sie alle mündlichen oder schriftlichen Anweisungen befolgen und Ihr bestes Urteilsvermögen einsetzen.'
    },
    ' minutes': {
        pt: ' minutos',
        es: ' minutos',
        ru: ' минут',
        fr: ' minutes',
        de: ' Minuten'
    },
    'DO NOT ': {
        pt: 'NÃO ',
        es: 'NO ',
        ru: 'НЕ ',
        fr: 'NE PAS ',
        de: 'NICHT '
    },
    'Upload Photo': {
        pt: 'Carregar Foto',
        es: 'Subir Foto',
        ru: 'Загрузить Фото',
        fr: 'Télécharger la Photo',
        de: 'Foto Hochladen'
    },
    'Please take a photo': {
        pt: 'Por favor, tire uma foto',
        es: 'Por favor, toma una foto',
        ru: 'Пожалуйста, сделайте фото',
        fr: 'Veuillez prendre une photo',
        de: 'Bitte machen Sie ein Foto'
    },
    'Please enter an answer.': {
        pt: 'Por favor, insira uma resposta.',
        es: 'Por favor, ingresa una respuesta.',
        ru: 'Пожалуйста, введите ответ.',
        fr: 'Veuillez entrer une réponse.',
        de: 'Bitte geben Sie eine Antwort ein.'
    },
    'Please select an option.': {
        pt: 'Por favor, selecione uma opção.',
        es: 'Por favor, selecciona una opción.',
        ru: 'Пожалуйста, выберите вариант.',
        fr: 'Veuillez sélectionner une option.',
        de: 'Bitte wählen Sie eine Option.'
    },
    'Please enter an amount.': {
        pt: 'Por favor, insira um valor.',
        es: 'Por favor, ingresa una cantidad.',
        ru: 'Пожалуйста, введите сумму.',
        fr: 'Veuillez entrer un montant.',
        de: 'Bitte geben Sie einen Betrag ein.'
    },
    'Done': {
        pt: 'Concluído',
        es: 'Hecho',
        ru: 'Готово',
        fr: 'Fait',
        de: 'Erledigt'
    },
    'Not Done': {
        pt: 'Não Concluído',
        es: 'No Hecho',
        ru: 'Не выполнено',
        fr: 'Non Fait',
        de: 'Nicht Erledigt'
    },
    'Not marked': {
        pt: 'Não Marcado',
        es: 'No Marcado',
        ru: 'Не отмечено',
        fr: 'Non Marqué',
        de: 'Nicht Markiert'
    },
    'No value inputted': {
        pt: 'Nenhum valor inserido',
        es: 'Ningún valor ingresado',
        ru: 'Значение не введено',
        fr: 'Aucune valeur saisie',
        de: 'Kein Wert eingegeben'
    },
    'No photo taken': {
        pt: 'Nenhuma foto tirada',
        es: 'No se tomó foto',
        ru: 'Фото не сделано',
        fr: 'Aucune photo prise',
        de: 'Kein Foto gemacht'
    },
    'DO NOT clean this room': {
        pt: 'NÃO limpe este quarto',
        es: 'NO limpies esta habitación',
        ru: 'НЕ убирайте эту комнату',
        fr: 'NE PAS nettoyer cette pièce',
        de: 'DIESEN Raum NICHT reinigen'
    },
    'Final Steps': {
        pt: 'Passos Finais',
        es: 'Pasos Finales',
        ru: 'Последние шаги',
        fr: 'Étapes Finales',
        de: 'Letzte Schritte'
    },
    'More Options': {
        pt: 'Mais Opções',
        es: 'Más Opciones',
        ru: 'Больше опций',
        fr: 'Plus d\'Options',
        de: 'Mehr Optionen'
    },
    'As You Leave': {
        pt: 'Ao Sair',
        es: 'Al Salir',
        ru: 'При уходе',
        fr: 'En Partant',
        de: 'Beim Verlassen'
    },
    '<i>No Closing Notes</i>': {
        pt: '<i>Sem Notas Finais</i>',
        es: '<i>Sin Notas Finales</i>',
        ru: '<i>Нет заключительных заметок</i>',
        fr: '<i>Pas de Notes de Clôture</i>',
        de: '<i>Keine Abschlussnotizen</i>'
    },
    'Remove Photo': {
        pt: 'Remover Foto',
        es: 'Eliminar Foto',
        ru: 'Удалить Фото',
        fr: 'Supprimer la Photo',
        de: 'Foto Entfernen'
    },
    'Are you sure you want to remove this photo?': {
        pt: 'Tem certeza de que deseja remover esta foto?',
        es: '¿Estás seguro de que deseas eliminar esta foto?',
        ru: 'Вы уверены, что хотите удалить это фото?',
        fr: 'Êtes-vous sûr de vouloir supprimer cette photo?',
        de: 'Sind Sie sicher, dass Sie dieses Foto entfernen möchten?'
    },
    'Remove': {
        pt: 'Remover',
        es: 'Eliminar',
        ru: 'Удалить',
        fr: 'Supprimer',
        de: 'Entfernen'
    },
    'Skip Required To-Dos?': {
        pt: 'Pular Tarefas Obrigatórias?',
        es: '¿Omitir Tareas Requeridas?',
        ru: 'Пропустить обязательные задачи?',
        fr: 'Passer les Tâches Obligatoires?',
        de: 'Erforderliche Aufgaben überspringen?'
    },
    'You did not complete To-Dos that were required by the client. Do you want to finish those To-Dos or skip what you did not finish?': {
        pt: 'Você não concluiu as tarefas que eram exigidas pelo cliente. Você quer terminar essas tarefas ou pular o que não terminou?',
        es: 'No completaste las tareas requeridas por el cliente. ¿Quieres terminar esas tareas o saltarte lo que no terminaste?',
        ru: 'Вы не выполнили задачи, которые требовал клиент. Хотите завершить эти задачи или пропустить то, что не закончили?',
        fr: 'Vous n\'avez pas terminé les tâches requises par le client. Voulez-vous terminer ces tâches ou passer ce que vous n\'avez pas terminé?',
        de: 'Sie haben die vom Kunden geforderten Aufgaben nicht abgeschlossen. Möchten Sie diese Aufgaben abschließen oder das, was Sie nicht abgeschlossen haben, überspringen?'
    },
    'Go Back & Finish To-Dos': {
        pt: 'Voltar e Concluir Tarefas',
        es: 'Volver y Terminar Tareas',
        ru: 'Вернуться и завершить задачи',
        fr: 'Retourner et Terminer les Tâches',
        de: 'Zurückgehen und Aufgaben abschließen'
    },
    'Skip To-Dos': {
        pt: 'Pular Tarefas',
        es: 'Omitir Tareas',
        ru: 'Пропустить задачи',
        fr: 'Passer les Tâches',
        de: 'Aufgaben überspringen'
    },

    'Tell Client I\'ve Finished': {
        pt: 'Informar Cliente que Terminei',
        es: 'Decir al Cliente que He Terminado',
        ru: 'Сообщить клиенту, что я закончил',
        fr: 'Dire au Client que J\'ai Terminé',
        de: 'Kunden Sagen, dass Ich Fertig Bin'
    },
    'Tell Client I\'ve Started': {
        pt: 'Informar Cliente que Comecei',
        es: 'Decir al Cliente que He Comenzado',
        ru: 'Сообщить клиенту, что я начал',
        fr: 'Dire au Client que J\'ai Commencé',
        de: 'Kunden Sagen, dass Ich Angefangen Habe'
    },
    'Tell Client I\'m on the Way': {
        pt: 'Informar Cliente que Estou a Caminho',
        es: 'Decir al Cliente que Estoy en Camino',
        ru: 'Сообщить клиенту, что я в пути',
        fr: 'Dire au Client que Je Suis en Route',
        de: 'Kunden Sagen, dass Ich Unterwegs Bin'
    },
    'Your client requested videos ': {
        pt: 'Seu cliente solicitou vídeos ',
        es: 'Su cliente solicitó videos ',
        ru: 'Ваш клиент запросил видео ',
        fr: 'Votre client a demandé des vidéos ',
        de: 'Ihr Kunde hat Videos angefordert '
    },
    ' your job. We generally recommend 1 or more videos of every room to capture its current state.': {
        pt: ' do seu trabalho. Geralmente recomendamos 1 ou mais vídeos de cada cômodo para capturar seu estado atual.',
        es: ' de su trabajo. Generalmente recomendamos 1 o más videos de cada habitación para capturar su estado actual.',
        ru: ' вашей работы. Мы обычно рекомендуем 1 или более видео каждой комнаты, чтобы зафиксировать ее текущее состояние.',
        fr: ' de votre travail. Nous recommandons généralement 1 ou plusieurs vidéos de chaque pièce pour capturer son état actuel.',
        de: ' Ihrer Arbeit. Wir empfehlen in der Regel 1 oder mehr Videos von jedem Raum, um seinen aktuellen Zustand festzuhalten.'
    },
    'Take Video': {
        pt: 'Gravar Vídeo',
        es: 'Tomar Video',
        ru: 'Снять видео',
        fr: 'Prendre Vidéo',
        de: 'Video Aufnehmen'
    },
    'Upload Video': {
        pt: 'Carregar Vídeo',
        es: 'Subir Video',
        ru: 'Загрузить видео',
        fr: 'Télécharger Vidéo',
        de: 'Video Hochladen'
    },
    'Add Video': {
        pt: 'Adicionar Vídeo',
        es: 'Agregar Video',
        ru: 'Добавить видео',
        fr: 'Ajouter Vidéo',
        de: 'Video Hinzufügen'
    },
    'Video ': {
        pt: 'Vídeo ',
        es: 'Video ',
        ru: 'Видео ',
        fr: 'Vidéo ',
        de: 'Video '
    },
    ' of ': {
        pt: ' de ',
        es: ' de ',
        ru: ' из ',
        fr: ' de ',
        de: ' von '
    },
    'Delete': {
        pt: 'Excluir',
        es: 'Eliminar',
        ru: 'Удалить',
        fr: 'Supprimer',
        de: 'Löschen'
    },
    'Your client requested photos ': {
        pt: 'Seu cliente solicitou fotos ',
        es: 'Su cliente solicitó fotos ',
        ru: 'Ваш клиент запросил фотографии ',
        fr: 'Votre client a demandé des photos ',
        de: 'Ihr Kunde hat Fotos angefordert '
    },
    ' your job. We generally recommend 1 or more photos of every room to capture its current state.': {
        pt: ' do seu trabalho. Geralmente recomendamos 1 ou mais fotos de cada cômodo para capturar seu estado atual.',
        es: ' de su trabajo. Generalmente recomendamos 1 o más fotos de cada habitación para capturar su estado actual.',
        ru: ' вашей работы. Мы обычно рекомендуем 1 или более фотографий каждой комнаты, чтобы зафиксировать ее текущее состояние.',
        fr: ' de votre travail. Nous recommandons généralement 1 ou plusieurs photos de chaque pièce pour capturer son état actuel.',
        de: ' Ihrer Arbeit. Wir empfehlen in der Regel 1 oder mehr Fotos von jedem Raum, um seinen aktuellen Zustand festzuhalten.'
    },
    'Error uploading photo. Please try again.': {
        pt: 'Erro ao carregar foto. Por favor, tente novamente.',
        es: 'Error al subir la foto. Por favor, inténtelo de nuevo.',
        ru: 'Ошибка загрузки фото. Пожалуйста, попробуйте еще раз.',
        fr: 'Erreur lors du téléchargement de la photo. Veuillez réessayer.',
        de: 'Fehler beim Hochladen des Fotos. Bitte versuchen Sie es erneut.'
    },
    'Are you sure you want to remove this media?': {
        pt: 'Tem certeza de que deseja remover este mídia?',
        es: '¿Está seguro de que desea eliminar este medio?',
        ru: 'Вы уверены, что хотите удалить этот медиафайл?',
        fr: 'Êtes-vous sûr de vouloir supprimer ce média?',
        de: 'Sind Sie sicher, dass Sie dieses Medium entfernen möchten?'
    },
    'Remove Media': {
        pt: 'Remover Mídia',
        es: 'Eliminar Medio',
        ru: 'Удалить медиа',
        fr: 'Supprimer Média',
        de: 'Medium Entfernen'
    },
    'Error removing photo. Please try again.': {
        pt: 'Erro ao remover foto. Por favor, tente novamente.',
        es: 'Error al eliminar la foto. Por favor, inténtelo de nuevo.',
        ru: 'Ошибка удаления фото. Пожалуйста, попробуйте еще раз.',
        fr: 'Erreur lors de la suppression de la photo. Veuillez réessayer.',
        de: 'Fehler beim Entfernen des Fotos. Bitte versuchen Sie es erneut.'
    },

    'Sync completing...': {
        pt: 'Sincronização concluindo...',
        es: 'Sincronización completando...',
        ru: 'Синхронизация завершение...',
        fr: 'Synchronisation en cours...',
        de: 'Synchronisierung abschließen...'
    },
    'photos uploaded': {
        pt: 'fotos carregadas',
        es: 'fotos subidas',
        ru: 'фотографии загружены',
        fr: 'photos téléchargées',
        de: 'Fotos hochgeladen'
    },
    'Syncing job...': {
        pt: 'Sincronizando trabalho...',
        es: 'Sincronizando trabajo...',
        ru: 'Синхронизация работы...',
        fr: 'Synchronisation du travail...',
        de: 'Job synchronisieren...'
    },
    'Since this was a repeat client of yours, this job was marked completed (not leaving early), unless the Client disputes this. Have a great day!.': {
        pt: 'Como este foi um cliente recorrente seu, este trabalho foi marcado como concluído (não saindo cedo), a menos que o Cliente conteste isso. Tenha um ótimo dia!',
        es: 'Como este fue un cliente recurrente suyo, este trabajo fue marcado como completado (no saliendo temprano), a menos que el Cliente lo dispute. ¡Que tenga un gran día!',
        ru: 'Поскольку это был ваш постоянный клиент, эта работа была отмечена как завершенная (не уходя рано), если только клиент не оспорит это. Хорошего дня!',
        fr: 'Comme il s\'agissait d\'un client régulier, ce travail a été marqué comme terminé (sans partir tôt), sauf si le client conteste cela. Passez une bonne journée!',
        de: 'Da dies ein wiederkehrender Kunde von Ihnen war, wurde dieser Auftrag als abgeschlossen markiert (nicht frühzeitig verlassen), es sei denn, der Kunde bestreitet dies. Einen schönen Tag noch!'
    },
    'All updates have been saved.': {
        pt: 'Todas as atualizações foram salvas.',
        es: 'Todas las actualizaciones han sido guardadas.',
        ru: 'Все обновления сохранены.',
        fr: 'Toutes les mises à jour ont été enregistrées.',
        de: 'Alle Aktualisierungen wurden gespeichert.'
    },
    'Send an invoice & instantly request payment for this job by logging in or creating an account with TIDY.': {
        pt: 'Envie uma fatura e solicite instantaneamente o pagamento por este trabalho fazendo login ou criando uma conta no TIDY.',
        es: 'Envíe una factura y solicite instantáneamente el pago por este trabajo iniciando sesión o creando una cuenta en TIDY.',
        ru: 'Отправьте счет и мгновенно запросите оплату за эту работу, войдя в систему или создав учетную запись в TIDY.',
        fr: 'Envoyez une facture et demandez instantanément le paiement pour ce travail en vous connectant ou en créant un compte avec TIDY.',
        de: 'Senden Sie eine Rechnung und fordern Sie sofort die Zahlung für diesen Auftrag an, indem Sie sich bei TIDY anmelden oder ein Konto erstellen.'
    },
    'Send an invoice & instantly request payment in the next page.': {
        pt: 'Envie uma fatura e solicite instantaneamente o pagamento na próxima página.',
        es: 'Envíe una factura y solicite instantáneamente el pago en la siguiente página.',
        ru: 'Отправьте счет и мгновенно запросите оплату на следующей странице.',
        fr: 'Envoyez une facture et demandez instantanément le paiement à la page suivante.',
        de: 'Senden Sie eine Rechnung und fordern Sie sofort die Zahlung auf der nächsten Seite an.'
    },
    'Send an invoice & instantly request payment by logging in.': {
        pt: 'Envie uma fatura e solicite instantaneamente o pagamento fazendo login.',
        es: 'Envíe una factura y solicite instantáneamente el pago iniciando sesión.',
        ru: 'Отправьте счет и мгновенно запросите оплату, войдя в систему.',
        fr: 'Envoyez une facture et demandez instantanément le paiement en vous connectant.',
        de: 'Senden Sie eine Rechnung und fordern Sie sofort die Zahlung an, indem Sie sich anmelden.'
    },
    'Send an invoice & instantly request payment by creating an account.': {
        pt: 'Envie uma fatura e solicite instantaneamente o pagamento criando uma conta.',
        es: 'Envíe una factura y solicite instantáneamente el pago creando una cuenta.',
        ru: 'Отправьте счет и мгновенно запросите оплату, создав учетную запись.',
        fr: 'Envoyez une facture et demandez instantanément le paiement en créant un compte.',
        de: 'Senden Sie eine Rechnung und fordern Sie sofort die Zahlung an, indem Sie ein Konto erstellen.'
    },
    'You can send an invoice from the past job page if you\'d like.': {
        pt: 'Você pode enviar uma fatura da página de trabalhos anteriores, se desejar.',
        es: 'Puede enviar una factura desde la página de trabajos anteriores, si lo desea.',
        ru: 'Вы можете отправить счет со страницы предыдущих работ, если хотите.',
        fr: 'Vous pouvez envoyer une facture depuis la page des travaux passés, si vous le souhaitez.',
        de: 'Sie können eine Rechnung von der Seite der vergangenen Aufträge senden, wenn Sie möchten.'
    },
    'We were unable to sync data for your last job due to a server error. We recommend trying again. If you still see the error, you can clear that data, which will remove the data from your device but allow you to continue.': {
        pt: 'Não conseguimos sincronizar os dados do seu último trabalho devido a um erro no servidor. Recomendamos tentar novamente. Se você ainda vir o erro, pode limpar esses dados, o que os removerá do seu dispositivo, mas permitirá que você continue.',
        es: 'No pudimos sincronizar los datos de su último trabajo debido a un error del servidor. Recomendamos intentarlo de nuevo. Si aún ve el error, puede borrar esos datos, lo que los eliminará de su dispositivo pero le permitirá continuar.',
        ru: 'Мы не смогли синхронизировать данные для вашей последней работы из-за ошибки сервера. Рекомендуем попробовать снова. Если ошибка все еще появляется, вы можете очистить эти данные, что удалит их с вашего устройства, но позволит продолжить.',
        fr: 'Nous n\'avons pas pu synchroniser les données de votre dernier travail en raison d\'une erreur de serveur. Nous vous recommandons d\'essayer à nouveau. Si l\'erreur persiste, vous pouvez effacer ces données, ce qui les supprimera de votre appareil mais vous permettra de continuer.',
        de: 'Wir konnten die Daten für Ihren letzten Auftrag aufgrund eines Serverfehlers nicht synchronisieren. Wir empfehlen, es erneut zu versuchen. Wenn der Fehler weiterhin auftritt, können Sie diese Daten löschen, wodurch sie von Ihrem Gerät entfernt werden, Sie jedoch fortfahren können.'
    },
    'Error:': {
        pt: 'Erro:',
        es: 'Error:',
        ru: 'Ошибка:',
        fr: 'Erreur:',
        de: 'Fehler:'
    },
    'Try Sync Again': {
        pt: 'Tente Sincronizar Novamente',
        es: 'Intente Sincronizar Nuevamente',
        ru: 'Попробуйте Синхронизировать Снова',
        fr: 'Essayez de Synchroniser à Nouveau',
        de: 'Versuchen Sie es Erneut zu Synchronisieren'
    },
    'Clear Job Data': {
        pt: 'Limpar Dados do Trabalho',
        es: 'Borrar Datos del Trabajo',
        ru: 'Очистить Данные Работы',
        fr: 'Effacer les Données du Travail',
        de: 'Jobdaten Löschen'
    },
    'We were unable to sync data for your last job due to poor/spotty internet connection. We recommend trying again when you have a good connection. If you are really stuck, you can clear that data, which will remove the data from your device but allow you to continue.': {
        pt: 'Não conseguimos sincronizar os dados do seu último trabalho devido a uma conexão de internet fraca/irregular. Recomendamos tentar novamente quando você tiver uma boa conexão. Se você estiver realmente preso, pode limpar esses dados, o que os removerá do seu dispositivo, mas permitirá que você continue.',
        es: 'No pudimos sincronizar los datos de su último trabajo debido a una conexión a internet deficiente/irregular. Recomendamos intentarlo de nuevo cuando tenga una buena conexión. Si realmente está atascado, puede borrar esos datos, lo que los eliminará de su dispositivo pero le permitirá continuar.',
        ru: 'Мы не смогли синхронизировать данные для вашей последней работы из-за плохого/нестабильного интернет-соединения. Рекомендуем попробовать снова, когда у вас будет хорошее соединение. Если вы действительно застряли, вы можете очистить эти данные, что удалит их с вашего устройства, но позволит продолжить.',
        fr: 'Nous n\'avons pas pu synchroniser les données de votre dernier travail en raison d\'une connexion Internet médiocre/irrégulière. Nous vous recommandons d\'essayer à nouveau lorsque vous avez une bonne connexion. Si vous êtes vraiment bloqué, vous pouvez effacer ces données, ce qui les supprimera de votre appareil mais vous permettra de continuer.',
        de: 'Wir konnten die Daten für Ihren letzten Auftrag aufgrund einer schlechten/instabilen Internetverbindung nicht synchronisieren. Wir empfehlen, es erneut zu versuchen, wenn Sie eine gute Verbindung haben. Wenn Sie wirklich feststecken, können Sie diese Daten löschen, wodurch sie von Ihrem Gerät entfernt werden, Sie jedoch fortfahren können.'
    },
    'This means we will lose all updates after you started the job. You can still expect to get paid in full if the Client says the job was complete.': {
        pt: 'Isso significa que perderemos todas as atualizações após você iniciar o trabalho. Você ainda pode esperar receber o pagamento integral se o Cliente disser que o trabalho foi concluído.',
        es: 'Esto significa que perderemos todas las actualizaciones después de que comience el trabajo. Aún puede esperar recibir el pago completo si el Cliente dice que el trabajo fue completado.',
        ru: 'Это означает, что мы потеряем все обновления после того, как вы начали работу. Вы все еще можете рассчитывать на полную оплату, если клиент скажет, что работа была завершена.',
        fr: 'Cela signifie que nous perdrons toutes les mises à jour après que vous ayez commencé le travail. Vous pouvez toujours vous attendre à être payé en totalité si le client dit que le travail a été terminé.',
        de: 'Das bedeutet, dass wir alle Aktualisierungen verlieren, nachdem Sie den Auftrag begonnen haben. Sie können immer noch erwarten, vollständig bezahlt zu werden, wenn der Kunde sagt, dass der Auftrag abgeschlossen war.'
    },
    'If you need to contact Concierge about this job, please do so by clicking on the job in the jobs page.': {
        pt: 'Se você precisar entrar em contato com o Concierge sobre este trabalho, faça-o clicando no trabalho na página de trabalhos.',
        es: 'Si necesita contactar al Concierge sobre este trabajo, hágalo haciendo clic en el trabajo en la página de trabajos.',
        ru: 'Если вам нужно связаться с Консьержем по поводу этой работы, сделайте это, нажав на работу на странице работ.',
        fr: 'Si vous devez contacter le Concierge à propos de ce travail, veuillez le faire en cliquant sur le travail dans la page des travaux.',
        de: 'Wenn Sie den Concierge bezüglich dieses Auftrags kontaktieren müssen, tun Sie dies bitte, indem Sie auf den Auftrag auf der Auftragsseite klicken.'
    },
    'Error Completing Job': {
        pt: 'Erro ao Concluir Trabalho',
        es: 'Error al Completar el Trabajo',
        ru: 'Ошибка Завершения Работы',
        fr: 'Erreur lors de la Complétion du Travail',
        de: 'Fehler beim Abschließen des Auftrags'
    },
    'Syncing Data': {
        pt: 'Sincronizando Dados',
        es: 'Sincronizando Datos',
        ru: 'Синхронизация Данных',
        fr: 'Synchronisation des Données',
        de: 'Daten Synchronisieren'
    },
    'Job Completed & Invoice Sent': {
        pt: 'Trabalho Concluído e Fatura Enviada',
        es: 'Trabajo Completado y Factura Enviada',
        ru: 'Работа Завершена и Счет Отправлен',
        fr: 'Travail Terminé et Facture Envoyée',
        de: 'Auftrag Abgeschlossen und Rechnung Gesendet'
    },
    'Job Completed': {
        pt: 'Trabalho Concluído',
        es: 'Trabajo Completado',
        ru: 'Работа Завершена',
        fr: 'Travail Terminé',
        de: 'Auftrag Abgeschlossen'
    },
    'To-Dos Completed': {
        pt: 'Tarefas Concluídas',
        es: 'Tareas Completadas',
        ru: 'Задачи Завершены',
        fr: 'Tâches Terminées',
        de: 'Aufgaben Abgeschlossen'
    },
    'Clear Job Data?': {
        pt: 'Limpar Dados do Trabalho?',
        es: '¿Borrar Datos del Trabajo?',
        ru: 'Очистить Данные Работы?',
        fr: 'Effacer les Données du Travail?',
        de: 'Jobdaten Löschen?'
    },
    'Job Data Cleared': {
        pt: 'Dados do Trabalho Limpados',
        es: 'Datos del Trabajo Borrados',
        ru: 'Данные Работы Очищены',
        fr: 'Données du Travail Effacées',
        de: 'Jobdaten Gelöscht'
    },

    'Please confirm your address:': {
        pt: 'Por favor, confirme seu endereço:',
        es: 'Por favor, confirme su dirección:',
        ru: 'Пожалуйста, подтвердите ваш адрес:',
        fr: 'Veuillez confirmer votre adresse :',
        de: 'Bitte bestätigen Sie Ihre Adresse:'
    },
    'If you do not see your address please retype it above': {
        pt: 'Se você não vir seu endereço, por favor, digite-o novamente acima',
        es: 'Si no ve su dirección, por favor, vuelva a escribirla arriba',
        ru: 'Если вы не видите ваш адрес, пожалуйста, введите его заново выше',
        fr: 'Si vous ne voyez pas votre adresse, veuillez la retaper ci-dessus',
        de: 'Wenn Sie Ihre Adresse nicht sehen, geben Sie sie bitte oben erneut ein'
    },
    'We could not validate your address with Google. Please enter an address that can be found with Google Maps and select an option.': {
        pt: 'Não conseguimos validar seu endereço com o Google. Por favor, insira um endereço que possa ser encontrado no Google Maps e selecione uma opção.',
        es: 'No pudimos validar su dirección con Google. Por favor, ingrese una dirección que se pueda encontrar en Google Maps y seleccione una opción.',
        ru: 'Мы не смогли подтвердить ваш адрес с помощью Google. Пожалуйста, введите адрес, который можно найти в Google Maps, и выберите вариант.',
        fr: 'Nous n\'avons pas pu valider votre adresse avec Google. Veuillez entrer une adresse qui peut être trouvée sur Google Maps et sélectionner une option.',
        de: 'Wir konnten Ihre Adresse nicht mit Google validieren. Bitte geben Sie eine Adresse ein, die bei Google Maps gefunden werden kann, und wählen Sie eine Option.'
    },
    'An error occurred when getting address information. Please type another address.': {
        pt: 'Ocorreu um erro ao obter informações do endereço. Por favor, digite outro endereço.',
        es: 'Ocurrió un error al obtener la información de la dirección. Por favor, escriba otra dirección.',
        ru: 'Произошла ошибка при получении информации об адресе. Пожалуйста, введите другой адрес.',
        fr: 'Une erreur s\'est produite lors de l\'obtention des informations d\'adresse. Veuillez saisir une autre adresse.',
        de: 'Beim Abrufen der Adressinformationen ist ein Fehler aufgetreten. Bitte geben Sie eine andere Adresse ein.'
    },
    'Bonus': {
        pt: 'Bônus',
        es: 'Bono',
        ru: 'Бонус',
        fr: 'Bonus',
        de: 'Bonus'
    },
    '/hour': {
        pt: '/hora',
        es: '/hora',
        ru: '/час',
        fr: '/heure',
        de: '/Stunde'
    },
    'There was a "timeout error". Most of the time this means a bad internet connection so please try again. Please contact us in the Concierge section if you think it is a different issue.': {
        pt: 'Houve um "erro de tempo limite". Na maioria das vezes, isso significa uma conexão de internet ruim, então, por favor, tente novamente. Entre em contato conosco na seção Concierge se achar que é um problema diferente.',
        es: 'Hubo un "error de tiempo de espera". La mayoría de las veces esto significa una mala conexión a internet, así que por favor intente de nuevo. Contáctenos en la sección de Conserjería si cree que es un problema diferente.',
        ru: 'Произошла "ошибка тайм-аута". Чаще всего это означает плохое интернет-соединение, поэтому, пожалуйста, попробуйте снова. Свяжитесь с нами в разделе Консьерж, если вы считаете, что это другая проблема.',
        fr: 'Il y a eu une "erreur de délai d\'attente". La plupart du temps, cela signifie une mauvaise connexion Internet, alors veuillez réessayer. Veuillez nous contacter dans la section Concierge si vous pensez qu\'il s\'agit d\'un problème différent.',
        de: 'Es gab einen "Timeout-Fehler". Meistens bedeutet dies eine schlechte Internetverbindung, also versuchen Sie es bitte erneut. Bitte kontaktieren Sie uns im Concierge-Bereich, wenn Sie denken, dass es sich um ein anderes Problem handelt.'
    },
    'Error during synchronization': {
        pt: 'Erro durante a sincronização',
        es: 'Error durante la sincronización',
        ru: 'Ошибка во время синхронизации',
        fr: 'Erreur lors de la synchronisation',
        de: 'Fehler bei der Synchronisation'
    },
    'Retry': {
        pt: 'Tentar Novamente',
        es: 'Reintentar',
        ru: 'Повторить',
        fr: 'Réessayer',
        de: 'Erneut versuchen'
    },
    'Exit to Dashboard': {
        pt: 'Sair para o Painel',
        es: 'Salir al Tablero',
        ru: 'Выйти на Панель управления',
        fr: 'Quitter vers le Tableau de bord',
        de: 'Zum Dashboard beenden'
    },

    'Expand': {
        pt: 'Expandir',
        es: 'Expandir',
        ru: 'Развернуть',
        fr: 'Développer',
        de: 'Erweitern'
    },
    'of': {
        pt: 'de',
        es: 'de',
        ru: 'из',
        fr: 'de',
        de: 'von'
    },
    'done': {
        pt: 'feito',
        es: 'hecho',
        ru: 'сделано',
        fr: 'terminé',
        de: 'fertig'
    },
    'Time Left: ': {
        pt: 'Tempo Restante: ',
        es: 'Tiempo Restante: ',
        ru: 'Оставшееся Время: ',
        fr: 'Temps Restant: ',
        de: 'Verbleibende Zeit: '
    },
    'Stop Recording': {
        pt: 'Parar Gravação',
        es: 'Detener Grabación',
        ru: 'Остановить Запись',
        fr: 'Arrêter l\'enregistrement',
        de: 'Aufnahme Stoppen'
    },
    'Start Recording': {
        pt: 'Iniciar Gravação',
        es: 'Iniciar Grabación',
        ru: 'Начать Запись',
        fr: 'Commencer l\'enregistrement',
        de: 'Aufnahme Starten'
    },
    'Today': {
        pt: 'Hoje',
        es: 'Hoy',
        ru: 'Сегодня',
        fr: 'Aujourd\'hui',
        de: 'Heute'
    },
    'Tomorrow': {
        pt: 'Amanhã',
        es: 'Mañana',
        ru: 'Завтра',
        fr: 'Demain',
        de: 'Morgen'
    },
    'Monday': {
        pt: 'Segunda-feira',
        es: 'Lunes',
        ru: 'Понедельник',
        fr: 'Lundi',
        de: 'Montag'
    },
    'Tuesday': {
        pt: 'Terça-feira',
        es: 'Martes',
        ru: 'Вторник',
        fr: 'Mardi',
        de: 'Dienstag'
    },
    'Wednesday': {
        pt: 'Quarta-feira',
        es: 'Miércoles',
        ru: 'Среда',
        fr: 'Mercredi',
        de: 'Mittwoch'
    },
    'Thursday': {
        pt: 'Quinta-feira',
        es: 'Jueves',
        ru: 'Четверг',
        fr: 'Jeudi',
        de: 'Donnerstag'
    },
    'Friday': {
        pt: 'Sexta-feira',
        es: 'Viernes',
        ru: 'Пятница',
        fr: 'Vendredi',
        de: 'Freitag'
    },
    'Saturday': {
        pt: 'Sábado',
        es: 'Sábado',
        ru: 'Суббота',
        fr: 'Samedi',
        de: 'Samstag'
    },
    'Sunday': {
        pt: 'Domingo',
        es: 'Domingo',
        ru: 'Воскресенье',
        fr: 'Dimanche',
        de: 'Sonntag'
    },
    'scheduled': {
        pt: 'Agendado',
        es: 'Programado',
        ru: 'Запланировано',
        fr: 'Planifié',
        de: 'Geplant'
    },
    'completed': {
        pt: 'Concluído',
        es: 'Completado',
        ru: 'Завершено',
        fr: 'Terminé',
        de: 'Abgeschlossen'
    },

    'Late': {
        pt: 'Atrasado',
        es: 'Tarde',
        ru: 'Опоздал',
        fr: 'En Retard',
        de: 'Spät'
    },
    'Not Full Time': {
        pt: 'Não Integral',
        es: 'No a Tiempo Completo',
        ru: 'Не полный рабочий день',
        fr: 'Pas à Temps Plein',
        de: 'Nicht Vollzeit'
    },
    'No Access': {
        pt: 'Sem Acesso',
        es: 'Sin Acceso',
        ru: 'Нет доступа',
        fr: 'Pas d\'Accès',
        de: 'Kein Zugang'
    },
    'Couldn\'t Access Home': {
        pt: 'Não Pôde Acessar a Casa',
        es: 'No Pudo Acceder a la Casa',
        ru: 'Не удалось получить доступ к дому',
        fr: 'Impossible d\'Accéder à la Maison',
        de: 'Konnte nicht auf das Haus zugreifen'
    },
    'Lost Dispute': {
        pt: 'Disputa Perdida',
        es: 'Disputa Perdida',
        ru: 'Проигранный спор',
        fr: 'Litige Perdu',
        de: 'Streit Verloren'
    },
    'On Time': {
        pt: 'No Horário',
        es: 'A Tiempo',
        ru: 'Вовремя',
        fr: 'À l\'Heure',
        de: 'Pünktlich'
    },

    'Notify': {
        pt: 'Notificar',
        es: 'Notificar',
        ru: 'Уведомить',
        fr: 'Notifier',
        de: 'Benachrichtigen'
    },
    'that you cannot help them at those times, but they are free to book you on your current bookable schedule.': {
        pt: 'que você não pode ajudá-los nesses horários, mas eles podem agendar você no seu horário disponível atual.',
        es: 'que no puedes ayudarlos en esos momentos, pero son libres de reservarte en tu horario disponible actual.',
        ru: 'что вы не можете помочь им в это время, но они могут записаться к вам в ваше текущее доступное время.',
        fr: 'que vous ne pouvez pas les aider à ces moments-là, mais ils sont libres de vous réserver sur votre emploi du temps disponible actuel.',
        de: 'dass Sie ihnen zu diesen Zeiten nicht helfen können, aber sie können Sie in Ihrem aktuellen buchbaren Zeitplan buchen.'
    },
    '(preferred)': {
        pt: '(preferido)',
        es: '(preferido)',
        ru: '(предпочтительно)',
        fr: '(préféré)',
        de: '(bevorzugt)'
    },
    'on': {
        pt: 'em',
        es: 'en',
        ru: 'на',
        fr: 'sur',
        de: 'auf'
    },
    'Face scan for pro': {
        pt: 'Escaneamento facial para profissional',
        es: 'Escaneo facial para profesional',
        ru: 'Сканирование лица для профессионала',
        fr: 'Scan facial pour professionnel',
        de: 'Gesichtsscan für Profi'
    },
    'at': {
        pt: 'às',
        es: 'a las',
        ru: 'в',
        fr: 'à',
        de: 'um'
    },
    'We expect to reply within': {
        pt: 'Esperamos responder dentro de',
        es: 'Esperamos responder dentro de',
        ru: 'Мы ожидаем ответить в течение',
        fr: 'Nous prévoyons de répondre dans',
        de: 'Wir erwarten, innerhalb von'
    },
    'We will reply both in the app and to your email': {
        pt: 'Responderemos tanto no aplicativo quanto no seu e-mail',
        es: 'Responderemos tanto en la aplicación como a tu correo electrónico',
        ru: 'Мы ответим как в приложении, так и на вашу электронную почту',
        fr: 'Nous répondrons à la fois dans l\'application et à votre e-mail',
        de: 'Wir werden sowohl in der App als auch an Ihre E-Mail antworten'
    },
    'You will be added to': {
        pt: 'Você será adicionado a',
        es: 'Serás añadido a',
        ru: 'Вы будете добавлены в',
        fr: 'Vous serez ajouté à',
        de: 'Sie werden hinzugefügt zu'
    },
    'team and will be expected to complete this job.': {
        pt: 'equipe e será esperado que complete este trabalho.',
        es: 'equipo y se espera que completes este trabajo.',
        ru: 'команду, и от вас ожидается выполнение этой работы.',
        fr: 'équipe et il est attendu que vous complétiez ce travail.',
        de: 'Team und es wird erwartet, dass Sie diese Aufgabe erledigen.'
    },
    'team and expected to complete this job.': {
        pt: 'equipe e esperado que complete este trabalho.',
        es: 'equipo y se espera que completes este trabajo.',
        ru: 'команды и ожидается, что вы выполните эту работу.',
        fr: 'équipe et il est attendu que vous complétiez ce travail.',
        de: 'Team und es wird erwartet, dass Sie diese Aufgabe erledigen.'
    },
    'View this job in the Jobs section and reach out to': {
        pt: 'Veja este trabalho na seção de Trabalhos e entre em contato com',
        es: 'Ve este trabajo en la sección de Trabajos y comunícate con',
        ru: 'Посмотрите эту работу в разделе Работы и свяжитесь с',
        fr: 'Consultez ce travail dans la section Emplois et contactez',
        de: 'Sehen Sie sich diesen Job im Abschnitt Jobs an und wenden Sie sich an'
    },
    'with any questions.': {
        pt: 'com quaisquer perguntas.',
        es: 'con cualquier pregunta.',
        ru: 'с любыми вопросами.',
        fr: 'pour toute question.',
        de: 'bei Fragen.'
    },
    'team?': {
        pt: 'equipe?',
        es: 'equipo?',
        ru: 'команду?',
        fr: 'équipe?',
        de: 'Team?'
    },
    'team!': {
        pt: 'equipe!',
        es: 'equipo!',
        ru: 'команды!',
        fr: 'équipe!',
        de: 'Team!'
    },
    'Reject job delegation request & invite to': {
        pt: 'Rejeitar solicitação de delegação de trabalho e convite para',
        es: 'Rechazar solicitud de delegación de trabajo e invitación a',
        ru: 'Отклонить запрос на делегирование работы и приглашение в',
        fr: 'Rejeter la demande de délégation de travail et l\'invitation à',
        de: 'Anfrage zur Arbeitsdelegation und Einladung ablehnen zu'
    },
    'invite.': {
        pt: 'convite.',
        es: 'invitación.',
        ru: 'приглашение.',
        fr: 'invitation.',
        de: 'Einladung.'
    },

    'Apply to <u>only</u> ': {
        pt: 'Aplicar para <u>somente</u> ',
        es: 'Aplicar a <u>solo</u> ',
        ru: 'Применить к <u>только</u> ',
        fr: 'Postuler à <u>seulement</u> ',
        de: 'Bewerben Sie sich auf <u>nur</u> '
    },
    'Apply to ': {
        pt: 'Aplicar para ',
        es: 'Aplicar a ',
        ru: 'Применить к ',
        fr: 'Postuler à ',
        de: 'Bewerben Sie sich auf '
    },
    ' and <u>All ': {
        pt: ' e <u>Todos ',
        es: ' y <u>Todos ',
        ru: ' и <u>Все ',
        fr: ' et <u>Tout ',
        de: ' und <u>Alle '
    },
    'After</u>': {
        pt: 'Depois</u>',
        es: 'Después</u>',
        ru: 'После</u>',
        fr: 'Après</u>',
        de: 'Nach</u>'
    },

    'All scheduled jobs on': {
        pt: 'Todos os trabalhos agendados em',
        es: 'Todos los trabajos programados en',
        ru: 'Все запланированные работы на',
        fr: 'Tous les travaux programmés le',
        de: 'Alle geplanten Arbeiten am'
    },
    'and after will be cancelled.': {
        pt: 'e depois serão cancelados.',
        es: 'y después serán cancelados.',
        ru: 'и после будут отменены.',
        fr: 'et après seront annulés.',
        de: 'und danach werden storniert.'
    },
    'Normal client fees apply.': {
        pt: 'Taxas normais de cliente aplicam-se.',
        es: 'Se aplican tarifas normales de cliente.',
        ru: 'Применяются обычные клиентские сборы.',
        fr: 'Les frais normaux du client s\'appliquent.',
        de: 'Normale Kundenentgelte gelten.'
    },
    'Your schedule is paused starting on': {
        pt: 'Seu cronograma está pausado a partir de',
        es: 'Su horario está pausado a partir de',
        ru: 'Ваш график приостановлен с',
        fr: 'Votre emploi du temps est en pause à partir de',
        de: 'Ihr Zeitplan ist pausiert ab'
    },
    ' Settings': {
        pt: ' Configurações',
        es: ' Configuraciones',
        ru: ' Настройки',
        fr: ' Paramètres',
        de: ' Einstellungen'
    },
    'Apply to all ': {
        pt: 'Aplicar a todos ',
        es: 'Aplicar a todos ',
        ru: 'Применить ко всем ',
        fr: 'Appliquer à tous ',
        de: 'Auf alle anwenden '
    },
    'from our background check provider "Turn" (from a @turning.io email). Check your email and complete the check to proceed. Please allow 24 hours for the email to arrive.': {
        pt: 'do nosso provedor de verificação de antecedentes "Turn" (de um e-mail @turning.io). Verifique seu e-mail e complete a verificação para prosseguir. Por favor, aguarde 24 horas para o e-mail chegar.',
        es: 'de nuestro proveedor de verificación de antecedentes "Turn" (de un correo electrónico @turning.io). Verifique su correo electrónico y complete la verificación para continuar. Por favor, espere 24 horas para que llegue el correo electrónico.',
        ru: 'от нашего поставщика проверки биографических данных "Turn" (с адреса электронной почты @turning.io). Проверьте свою электронную почту и завершите проверку, чтобы продолжить. Пожалуйста, подождите 24 часа для получения письма.',
        fr: 'de notre fournisseur de vérification des antécédents "Turn" (d\'un e-mail @turning.io). Vérifiez votre e-mail et complétez la vérification pour continuer. Veuillez prévoir 24 heures pour que l\'e-mail arrive.',
        de: 'von unserem Hintergrundprüfungsanbieter "Turn" (von einer @turning.io E-Mail). Überprüfen Sie Ihre E-Mail und schließen Sie die Überprüfung ab, um fortzufahren. Bitte erlauben Sie 24 Stunden für die Ankunft der E-Mail.'
    },
    'from an @tidy.com email address to verify your email.': {
        pt: 'de um endereço de e-mail @tidy.com para verificar seu e-mail.',
        es: 'de una dirección de correo electrónico @tidy.com para verificar su correo electrónico.',
        ru: 'с адреса электронной почты @tidy.com для проверки вашей электронной почты.',
        fr: 'd\'une adresse e-mail @tidy.com pour vérifier votre e-mail.',
        de: 'von einer @tidy.com E-Mail-Adresse, um Ihre E-Mail zu verifizieren.'
    },
    'Please tap the link in that email to verify your email address.': {
        pt: 'Por favor, toque no link nesse e-mail para verificar seu endereço de e-mail.',
        es: 'Por favor, toque el enlace en ese correo electrónico para verificar su dirección de correo electrónico.',
        ru: 'Пожалуйста, нажмите на ссылку в этом письме, чтобы подтвердить свой адрес электронной почты.',
        fr: 'Veuillez cliquer sur le lien dans cet e-mail pour vérifier votre adresse e-mail.',
        de: 'Bitte tippen Sie auf den Link in dieser E-Mail, um Ihre E-Mail-Adresse zu verifizieren.'
    },
    'Check your junk or spam messages if you cannot find the email.': {
        pt: 'Verifique suas mensagens de lixo ou spam se não conseguir encontrar o e-mail.',
        es: 'Revise sus mensajes de correo no deseado o spam si no puede encontrar el correo electrónico.',
        ru: 'Проверьте папку "Спам" или "Нежелательная почта", если не можете найти письмо.',
        fr: 'Vérifiez vos messages indésirables ou spam si vous ne trouvez pas l\'e-mail.',
        de: 'Überprüfen Sie Ihre Junk- oder Spam-Nachrichten, wenn Sie die E-Mail nicht finden können.'
    },
    'Current Balance: ': {
        pt: 'Saldo Atual: ',
        es: 'Saldo Actual: ',
        ru: 'Текущий Баланс: ',
        fr: 'Solde Actuel: ',
        de: 'Aktueller Kontostand: '
    },
    'Reschedule ': {
        pt: 'Reagendar ',
        es: 'Reprogramar ',
        ru: 'Переназначить ',
        fr: 'Replanifier ',
        de: 'Umplanen '
    },
    'Hi': {
        pt: 'Oi',
        es: 'Hola',
        ru: 'Привет',
        fr: 'Salut',
        de: 'Hallo'
    },
    'Here is a link to my website with a personal page for you where you can see upcoming jobs with me, see my invoices, and pay me all online. You don\'t have to use it, but some clients like it so I thought I would share it.': {
        pt: 'Aqui está um link para o meu site com uma página pessoal para você, onde você pode ver os próximos trabalhos comigo, ver minhas faturas e me pagar tudo online. Você não precisa usá-lo, mas alguns clientes gostam, então pensei em compartilhar.',
        es: 'Aquí hay un enlace a mi sitio web con una página personal para usted donde puede ver los próximos trabajos conmigo, ver mis facturas y pagarme todo en línea. No tiene que usarlo, pero a algunos clientes les gusta, así que pensé en compartirlo.',
        ru: 'Вот ссылка на мой сайт с личной страницей для вас, где вы можете увидеть предстоящие работы со мной, посмотреть мои счета и оплатить все онлайн. Вам не обязательно использовать это, но некоторым клиентам это нравится, поэтому я решил поделиться.',
        fr: 'Voici un lien vers mon site Web avec une page personnelle pour vous où vous pouvez voir les prochains travaux avec moi, voir mes factures et me payer tout en ligne. Vous n\'êtes pas obligé de l\'utiliser, mais certains clients l\'aiment, alors j\'ai pensé le partager.',
        de: 'Hier ist ein Link zu meiner Website mit einer persönlichen Seite für Sie, auf der Sie bevorstehende Arbeiten mit mir sehen, meine Rechnungen einsehen und alles online bezahlen können. Sie müssen es nicht verwenden, aber einige Kunden mögen es, also dachte ich, ich teile es.'
    },
    'Are you sure you want to cancel this job on': {
        pt: 'Tem certeza de que deseja cancelar este trabalho em',
        es: '¿Está seguro de que desea cancelar este trabajo en',
        ru: 'Вы уверены, что хотите отменить эту работу на',
        fr: 'Êtes-vous sûr de vouloir annuler ce travail le',
        de: 'Sind Sie sicher, dass Sie diesen Job am stornieren möchten'
    },
    'Are you sure you want to cancel this plan with next cleaning on': {
        pt: 'Tem certeza de que deseja cancelar este plano com a próxima limpeza em',
        es: '¿Está seguro de que desea cancelar este plan con la próxima limpieza en',
        ru: 'Вы уверены, что хотите отменить этот план с следующей уборкой на',
        fr: 'Êtes-vous sûr de vouloir annuler ce plan avec le prochain nettoyage le',
        de: 'Sind Sie sicher, dass Sie diesen Plan mit der nächsten Reinigung am stornieren möchten'
    },
    'Cancel': {
        pt: 'Cancelar',
        es: 'Cancelar',
        ru: 'Отменить',
        fr: 'Annuler',
        de: 'Stornieren'
    },

    'Questions: "How do Clients book?"': {
        pt: 'Perguntas: "Como os Clientes fazem reservas?"',
        es: 'Preguntas: "¿Cómo reservan los Clientes?"',
        ru: 'Вопросы: "Как Клиенты делают бронирование?"',
        fr: 'Questions: "Comment les Clients réservent-ils?"',
        de: 'Fragen: "Wie buchen Kunden?"'
    },
    'Names: "Alice Smith"': {
        pt: 'Nomes: "Alice Smith"',
        es: 'Nombres: "Alice Smith"',
        ru: 'Имена: "Alice Smith"',
        fr: 'Noms: "Alice Smith"',
        de: 'Namen: "Alice Smith"'
    },
    'App Sections: "Schedule"': {
        pt: 'Seções do App: "Agenda"',
        es: 'Secciones de la App: "Horario"',
        ru: 'Разделы приложения: "Расписание"',
        fr: 'Sections de l\'App: "Calendrier"',
        de: 'App-Bereiche: "Zeitplan"'
    },
    'Send jobs to your team members or join other teams to get jobs from them! ': {
        pt: 'Envie trabalhos para os membros da sua equipe ou junte-se a outras equipes para receber trabalhos deles! ',
        es: '¡Envíe trabajos a los miembros de su equipo o únase a otros equipos para recibir trabajos de ellos! ',
        ru: 'Отправляйте задания членам своей команды или присоединяйтесь к другим командам, чтобы получать от них задания! ',
        fr: 'Envoyez des travaux aux membres de votre équipe ou rejoignez d\'autres équipes pour obtenir des travaux d\'eux ! ',
        de: 'Senden Sie Aufträge an Ihre Teammitglieder oder treten Sie anderen Teams bei, um Aufträge von ihnen zu erhalten! '
    },

    'Before Photos': {
        pt: 'Fotos Antes',
        es: 'Fotos Antes',
        ru: 'Фото До',
        fr: 'Photos Avant',
        de: 'Vorher-Fotos'
    },
    'After Photos': {
        pt: 'Fotos Depois',
        es: 'Fotos Después',
        ru: 'Фото После',
        fr: 'Photos Après',
        de: 'Nachher-Fotos'
    },
    'Before Videos': {
        pt: 'Vídeos Antes',
        es: 'Videos Antes',
        ru: 'Видео До',
        fr: 'Vidéos Avant',
        de: 'Vorher-Videos'
    },
    'After Videos': {
        pt: 'Vídeos Depois',
        es: 'Videos Después',
        ru: 'Видео После',
        fr: 'Vidéos Après',
        de: 'Nachher-Videos'
    },
    'Error uploading video. Please try again.': {
        pt: 'Erro ao enviar vídeo. Por favor, tente novamente.',
        es: 'Error al subir el video. Por favor, inténtelo de nuevo.',
        ru: 'Ошибка при загрузке видео. Пожалуйста, попробуйте снова.',
        fr: 'Erreur lors du téléchargement de la vidéo. Veuillez réessayer.',
        de: 'Fehler beim Hochladen des Videos. Bitte versuchen Sie es erneut.'
    },

    'Bookable': {
        pt: 'Disponível',
        es: 'Disponible',
        ru: 'Доступно',
        fr: 'Disponible',
        de: 'Verfügbar'
    },
    'Loading...': {
        pt: 'Carregando...',
        es: 'Cargando...',
        ru: 'Загрузка...',
        fr: 'Chargement...',
        de: 'Laden...'
    },
    'Are you sure you want to do this?': {
        pt: 'Tem certeza de que deseja fazer isso?',
        es: '¿Estás seguro de que quieres hacer esto?',
        ru: 'Вы уверены, что хотите сделать это?',
        fr: 'Êtes-vous sûr de vouloir faire cela?',
        de: 'Sind Sie sicher, dass Sie dies tun möchten?'
    },
    'Language updated!': {
        pt: 'Idioma atualizado!',
        es: 'Idioma actualizado!',
        ru: 'Язык обновлен!',
        fr: 'Langue mise à jour!',
        de: 'Sprache aktualisiert!'
    },

    'Edit Distance Format': {
        pt: 'Editar Formato de Distância',
        es: 'Editar Formato de Distancia',
        ru: 'Изменить Формат Расстояния',
        fr: 'Modifier le Format de Distance',
        de: 'Entfernungsformat Bearbeiten'
    },

    'Distance Format': {
        pt: 'Formato de Distância',
        es: 'Formato de Distancia',
        ru: 'Формат Расстояния',
        fr: 'Format de Distance',
        de: 'Entfernungsformat'
    },

    'Kilometers': {
        pt: 'Quilômetros',
        es: 'Kilómetros',
        ru: 'Километры',
        fr: 'Kilomètres',
        de: 'Kilometer'
    },

    'Save Distance Format': {
        pt: 'Salvar Formato de Distância',
        es: 'Guardar Formato de Distancia',
        ru: 'Сохранить Формат Расстояния',
        fr: 'Enregistrer le Format de Distance',
        de: 'Entfernungsformat Speichern'
    },

    'Distance format updated': {
        pt: 'Formato de distância atualizado',
        es: 'Formato de distancia actualizado',
        ru: 'Формат расстояния обновлен',
        fr: 'Format de distance mis à jour',
        de: 'Entfernungsformat aktualisiert'
    },

    'Something went wrong': {
        pt: 'Algo deu errado',
        es: 'Algo salió mal',
        ru: 'Что-то пошло не так',
        fr: 'Quelque chose s\'est mal passé',
        de: 'Etwas ist schief gelaufen'
    },

    'Kilometers Travelled': {
        pt: 'Quilômetros Percorridos',
        es: 'Kilómetros Recorridos',
        ru: 'Пройдено Километров',
        fr: 'Kilomètres Parcourus',
        de: 'Zurückgelegte Kilometer'
    },

    'Kilometers Travelled: ': {
        pt: 'Quilômetros Percorridos: ',
        es: 'Kilómetros Recorridos: ',
        ru: 'Пройдено Километров: ',
        fr: 'Kilomètres Parcourus: ',
        de: 'Zurückgelegte Kilometer: '
    },

    'Miles from Home': {
        pt: 'Milhas de Casa',
        es: 'Millas desde Casa',
        ru: 'Миль от Дома',
        fr: 'Miles de la Maison',
        de: 'Meilen von Zuhause'
    },

    'Kilometers from Home': {
        pt: 'Quilômetros de Casa',
        es: 'Kilómetros desde Casa',
        ru: 'Километров от Дома',
        fr: 'Kilomètres de la Maison',
        de: 'Kilometer von Zuhause'
    },

    'TIDY automatically tracks jobs as you add them with estimated kilometers. All numbers are estimates. TIDY does not give tax advice, please consult a professional.': {
        pt: 'TIDY rastreia automaticamente os trabalhos à medida que você os adiciona com quilômetros estimados. Todos os números são estimativas. TIDY não dá conselhos fiscais, consulte um profissional.',
        es: 'TIDY rastrea automáticamente los trabajos a medida que los agrega con kilómetros estimados. Todos los números son estimaciones. TIDY no da consejos fiscales, consulte a un profesional.',
        ru: 'TIDY автоматически отслеживает задания по мере их добавления с расчетными километрами. Все числа являются оценочными. TIDY не дает налоговых консультаций, пожалуйста, проконсультируйтесь с профессионалом.',
        fr: 'TIDY suit automatiquement les travaux au fur et à mesure que vous les ajoutez avec des kilomètres estimés. Tous les chiffres sont des estimations. TIDY ne donne pas de conseils fiscaux, veuillez consulter un professionnel.',
        de: 'TIDY verfolgt automatisch Jobs, wenn Sie sie mit geschätzten Kilometern hinzufügen. Alle Zahlen sind Schätzungen. TIDY gibt keine Steuerberatung, bitte konsultieren Sie einen Fachmann.'
    },


    

}
