<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="!cameFromJobLink"
    [title]="loaded ? (cameFromJobLink ? 'Job Completed!' : 'Create Account') : ''">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'" *ngIf="cameFromJobLink">
        <tidy-text
          *ngIf="!isPrivateJob"
          [body]="'Create an account to connect your bank account and get paid for this job. You can also browse available jobs. It\'s free to use.'">
        </tidy-text>
        <tidy-text
          *ngIf="isPrivateJob"
          [body]="'Create an account to an send invoice & instantly request payment. You can also browse available jobs. It\’s free to use.'">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <form [formGroup]="signupForm" novalidate>
          <tidy-row class="field-top-padding field-bottom-padding">
            <tidy-input
              formControlName="firstName"
              [form]="signupForm.controls.firstName"
              label="First Name"
              [errorMessage]="'Please enter your first name.'"
              [submitted]="submitted"
              [icon]="'assets/svg/person-outline.svg'"
              [activeIcon]="'assets/svg/person-outline-tidy-green.svg'">
            </tidy-input>
          </tidy-row>

          <tidy-row class="field-bottom-padding">
            <tidy-input
              formControlName="lastName"
              [form]="signupForm.controls.lastName"
              label="Last Name"
              [errorMessage]="'Please enter your last name.'"
              [submitted]="submitted"
              [icon]="'assets/svg/person-outline.svg'"
              [activeIcon]="'assets/svg/person-outline-tidy-green.svg'">
            </tidy-input>
          </tidy-row>

          <tidy-row class="field-bottom-padding">
            <tidy-input
              formControlName="email"
              [form]="signupForm.controls.email"
              label="Email"
              type="email"
              data-cy="email-field"
              [errorMessage]="'Please enter a valid email'"
              [submitted]="submitted"
              [icon]="'assets/svg/mail-outline.svg'"
              [activeIcon]="'assets/svg/mail-outline-tidy-green.svg'"
              [autoComplete]="'new-password'">
            </tidy-input>
          </tidy-row>

          <tidy-row class="extra-bottom-padding">
            <tidy-input
              formControlName="password"
              [form]="signupForm.controls.password"
              label="Password"
              data-cy="password-field"
              [errorMessage]="'Please enter a password (at least 8 characters)'"
              [submitted]="submitted"
              type="password"
              [icon]="'assets/svg/lock-closed-outline.svg'"
              [activeIcon]="'assets/svg/lock-closed-outline-tidy-green.svg'"
              [autoComplete]="'new-password'">
            </tidy-input>
          </tidy-row>

          <tidy-row [ngClass]="signupForm?.value?.hasClients == 'yes' ? 'extra-bottom-padding' : ''">
            <tidy-radio-button
              [label]="'Do you already have existing clients you work with?'"
              formControlName="hasClients"
              [form]="signupForm.controls.hasClients"
              [items]="[{viewValue: 'No clients yet', value: 'no'}, {viewValue: 'Yes I have existing clients', value: 'yes'}]"
              [submitted]="submitted"
              [errorMessage]="'Please select an answer.'">
            </tidy-radio-button>
          </tidy-row>

          <tidy-row *ngIf="signupForm?.value?.hasClients == 'yes'">
            <tidy-radio-button
              [label]="'What does your team look like?'"
              formControlName="aboutTeam"
              [form]="signupForm.controls.aboutTeam"
              [items]="[{viewValue: 'Just me', value: 'me'}, {viewValue: 'Me + others (anyone you might work with or send jobs to)', value: 'others'}]"
              [submitted]="submitted"
              [errorMessage]="'Please select an answer.'"
              (optionChange)="selectTeamAnswer($event)">
            </tidy-radio-button>
          </tidy-row>

          <tidy-row class="extra-top-padding">
            <tidy-grid>
              <ion-row>
                <ion-col size="1">
                  <tidy-checkbox
                    style="margin-right: 5px"
                    formControlName="terms"
                    [form]="signupForm.controls.terms"
                    [items]="[{viewValue: '', value: true}]">
                  </tidy-checkbox>
                </ion-col>
                <ion-col>
                  <tidy-text
                    [body]="'I agree to the '">
                  </tidy-text>
                  <tidy-text
                    (action)="openAgreement()"
                    [body]="'Service Provider Customer Agreement'"
                    class="link">
                  </tidy-text>
                  <tidy-text
                    [body]="', which includes agreeing to individual arbitration.*'">
                  </tidy-text>
                </ion-col>
              </ion-row>
            </tidy-grid>
          </tidy-row>

          <tidy-row *ngIf="termsError">
            <tidy-error-message
              [text]="'You must agree to the terms to continue.'">
            </tidy-error-message>
          </tidy-row>
        </form>
      </tidy-card>

      <tidy-error-message
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-button item-right
        [text]="'Create Account'"
        data-cy="createAccount-button"
        [action]="onSubmit.bind(this)"
        class="primary">
      </tidy-button>

      <tidy-alert *ngIf="isMobile">
        <tidy-row [align]="'center'" class="extra-bottom-padding">
          <tidy-text
            [header]="'Over 10,000 Pros use TIDY'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let quote of quotes; let first = first">
          <tidy-row [ngClass]="first ? 'extra-bottom-padding' : ''">
            <tidy-grid>
              <ion-row>
                <ion-col size="3">
                  <tidy-image
                    style="margin: auto; width: 40px"
                    [src]="quote?.icon">
                  </tidy-image>
                </ion-col>
                <ion-col>
                  <tidy-text
                    [body]="quote?.text">
                  </tidy-text>
                </ion-col>
              </ion-row>
              <tidy-row [align]="'right'">
                <tidy-text
                  [body]="'- ' + quote?.person">
                </tidy-text>
              </tidy-row>
            </tidy-grid>
          </tidy-row>
        </ng-container>
      </tidy-alert>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'*If you would like an alternate agreement, contact sales@tidy.com instead of agreeing to these terms.'">
        </tidy-text>
      </tidy-row>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-template #rightColumn>
  <tidy-wrapper *ngIf="!isMobile">

    <tidy-row [align]="'center'" class="extra-bottom-padding">
      <tidy-text
        [header]="'Over 10,000 Pros use TIDY'">
      </tidy-text>
    </tidy-row>
    <ng-container *ngFor="let quote of quotes; let first = first">
      <tidy-row [ngClass]="first ? 'extra-bottom-padding' : ''">
        <tidy-grid>
          <ion-row>
            <ion-col size="3">
              <tidy-image
                style="margin: auto; width: 40px"
                [src]="quote?.icon">
              </tidy-image>
            </ion-col>
            <ion-col>
              <tidy-text
                [body]="quote?.text">
              </tidy-text>
            </ion-col>
          </ion-row>
          <tidy-row [align]="'right'">
            <tidy-text
              [body]="'- ' + quote?.person">
            </tidy-text>
          </tidy-row>
        </tidy-grid>
      </tidy-row>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateRightColumn]="rightColumn" [templateContent]="content"></app-scroll-wrapper>
