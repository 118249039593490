import { CommonUtils } from './../../shared/utils/common-utils';
import { Component, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { ModalController } from '@ionic/angular';
import { DateTime as LuxonDateTime } from 'luxon';

import { AppState } from 'src/shared/providers/http/app-state';
import { Cleanings } from 'src/providers/cleanings/cleanings';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Logger } from 'src/shared/providers/logger';
import { Me } from 'src/shared/providers/me';
import { OnRails } from 'src/providers/on-rails/on-rails';
import { ServerData } from 'src/shared/providers/server-data';
import { MWService } from 'src/main-workflow/mw.service';
import { MWStore } from 'src/main-workflow/mw.store';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

import { HomekeeperModel } from 'src/shared/models/homekeeper.model';
import { Loading } from 'src/shared/components/loading/loading';
import { CompleteJobModal } from 'src/shared/components/complete-job-modal/complete-job-modal';

import { mwMoments } from 'src/main-workflow/mw.moments';
import { MySchedule } from 'src/providers/my-schedule/my-schedule';

import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { ActivatedRoute, Router } from '@angular/router';
import { ISelectedPeriod, ISelectedWeek } from 'src/shared/models/jobs-calendar.model';
import { AppConfig } from 'src/shared/providers/config';
import promiseUtils from 'src/shared/utils/promise-utils';

declare const mapboxgl: any;

@Component({
  selector: 'jobs',
  templateUrl: 'jobs.html',
  encapsulation: ViewEncapsulation.None
})

export class JobsPage extends Timeout {

  endDate: any;
  REFRESH_DELAY: number = 1000 * 60 * 2;
  dropdownWeeks: any;
  weekToDisplay: any;
  currentWeek: any = { days: [] };
  isPastWeek = false;
  isLimitedAccount: boolean;
  upcomingCleanings: any;
  today: string;
  permissions: any = {};
  fetchInterval: any;
  hasJobs = true;
  hkPendingDC = false;
  hkState: string;
  hkId: number;
  hkAvailableRepeat = true;
  startDate: any;
  availability: Array<{date: string, time_ranges: Array<{start_time: string, end_time: string}>}>;
  calendarUrl: string;
  calendarJobCounts: any;
  imutableCleanings = [];
  homeLongitude: number;
  homeLatitude: number;
  cleaningsCache: { [key: string]: any } = {};

  constructor(
    private appState: AppState,
    private cleanings: Cleanings,
    private iabUtils: InAppBrowserUtils,
    private onRails: OnRails,
    private storage: TidyStorage,
    private me: Me,
    private modalCtrl: ModalController,
    private mwService: MWService,
    private mwStore: MWStore,
    private customNavCtrl: CustomNavController,
    private logger: Logger,
    private mySchedule: MySchedule,
    private utils: CommonUtils,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    public route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
  }

  async ionViewDidEnter() {
    this.loaded = false;
    this.isLimitedAccount = await this.storage.retrieve('isLimitedAccount');
    this.alreadyShowedOfflineOrTimeoutAlert = false;
    this.loaded = true;
    try {
      const redirectJobId = this.route.snapshot.paramMap.get('redirectJobId');
      if (redirectJobId) {
        await this.redirectToJob(redirectJobId);
      } else {
        await this.fetchData();
      }
    } catch (err) {
      this.logger.error(err, 'jobs-fetch');
      this.timeoutHandler(err);
    }
  }

  async redirectToJob(jobId: string) {
    const timelineCard = await this.cleanings.fetchCleaning(+jobId);

    await this.onCardClicked(timelineCard);
  }

  async fetchData(): Promise<any> {
    if (!await this.appState.isOnline()) {
      this.alreadyShowedOfflineOrTimeoutAlert = true;
      return this.timeoutErrorHandler.showOfflineAlert(this.successPageLoad, 'jobs', this.retrySubject);
    }

    const currentDate = new Date();
    const nextMonthDate = moment(currentDate).add(1, 'month').toDate();

    const [currentMonth, nextMonth, dropdownWeeks] = await Promise.all([
      this.fetchForDate(currentDate, 'current-month'),
      this.fetchForDate(nextMonthDate, 'next-month'),
      this.cleanings.weeksDropdown()
    ]);

    this.availability = [...currentMonth, ...nextMonth];
    this.dropdownWeeks = dropdownWeeks;
    this.weekToDisplay = this.checkWeekToDisplay(this.weekToDisplay, this.dropdownWeeks);
    this.today = moment().format('YYYY-MM-DD');
    if (!this.fetchInterval) {
      this.fetchInterval = setInterval( () => this.refresh(), this.REFRESH_DELAY );
    }
    await this.refresh(this.weekToDisplay);
    this.successPageLoad.emit(true);

  }

  checkIfHasJobs() {
    this.hasJobs = this.upcomingCleanings.some((day) => {
      return day.cards.some((card) => {
        return card;
      });
    });
  }

  async fetchForDate(date, fetchMonth): Promise<any> {
    const year: number = date.getFullYear();
    const month: number = date.getMonth() + 1;
    const result =  await this.mySchedule.fetchMonthAvailability(month, year, fetchMonth);
    return result;
  }

  async checkIfPastJobNeedsSyncing() {
    const jobId = await this.mwStore.getJobId();
    if (jobId !== null && jobId !== undefined) {
      this.upcomingCleanings.map(async day => {
        day.cards.map(async job => {
          if (job.job.id == jobId && job.card_type == 'past_cleaning') {
            const syncData = await this.mwStore.getSyncData();
            if (syncData !== null && syncData !== undefined) {
              syncData.pastJobNeedsSyncing = true;
              await this.mwStore.setSyncData(syncData);
              const modal = await this.modalCtrl.create({
                component: CompleteJobModal
              });
              modal.present();
            } else {
              await this.mwStore.clearStore();
            }
          }
        });
      });
    }
  }

  @Loading()
  async selectedWeekEmitter(event: ISelectedWeek): Promise<void> {
    if (moment(event.selectedDate).isSameOrBefore(event.startWeekDate)) {
      event.selectedDate = event.startWeekDate;
    }
    const endDate = moment(event.startWeekDate).add(7, 'days').format('YYYY-MM-DD');
    await this.getCleanings(event.startWeekDate, endDate);
    this.filterCleaningsByWeek(event.selectedDate);
  }

  @Loading()
  onSelectStartDate(startDate: string): void {
    this.filterCleaningsByWeek(startDate);
  }

  filterCleaningsByWeek(startDate: string): void {
    const endDate = moment(startDate).add(7, 'days').format('YYYY-MM-DD');
    const filteredCleanings = this.imutableCleanings.filter((day) => {
      return moment(day.date).isBetween(startDate, endDate, null, '[]');
    })
    this.upcomingCleanings = filteredCleanings;
    this.checkIfHasJobs();
    this.addTimeSensitiveIndicators();
  }

  @Loading()
  async onSelectedPeriodChange(event: ISelectedPeriod): Promise<void> {
    this.startDate = moment(event.startDate).format('YYYY-MM-DD');
    this.endDate = moment(event.endDate).format('YYYY-MM-DD');
    this.clearCache();
    this.calendarJobCounts = await this.thisGetCalendarJobCounts(this.startDate, this.endDate);
  }

  clearCache(): void {
    this.cleaningsCache = {};
  }

  async refresh(startDate: any = null, endDate?: any): Promise<any> {
    if (!startDate) {
      startDate = localStorage.getItem('jobsStartDate');
    }
    localStorage.setItem('jobsStartDate', startDate);
    this.permissions = await this.onRails.cleaningsPermissions();
    if (this.permissions.show_cleaning_confirmation_page) {
      return this.customNavCtrl.navigateRoot('check-in');
    }
    this.fetchCleanings(startDate, endDate);
  }

  fetchCleanings(startDate: string, endDate: string): void {
    this.isPastWeek = false;
    this.checkHkState();
    const oneDayNextEndDate = moment(endDate).add(1, 'days');

    if (startDate && oneDayNextEndDate.isBefore(moment())) {
      this.isPastWeek = true;
    }
    this.loaded = true;
  }

  async getCleanings(startDate: string, endDate: string): Promise<void> {
    const parsedStartDate = moment(startDate).format('YYYY-MM-DD');
    const parsedEndDate = moment(endDate).format('YYYY-MM-DD');
    const cacheKey = `${parsedStartDate}_${parsedEndDate}`;
    let loading: HTMLIonLoadingElement;
    // INFO: Check if data for this period is already cached
    if (this.cleaningsCache[cacheKey]) {
      this.imutableCleanings = this.cleaningsCache[cacheKey];
    } else {
      loading = await this.utils.showLoading();
      const cleanings = await this.cleanings.fetch(parsedStartDate, parsedEndDate);
      this.imutableCleanings = cleanings;
      // INFO: Cache the fetched data
      this.cleaningsCache[cacheKey] = cleanings;
    }

    const previousMonth = moment().subtract(1, 'month').month();
    const isStartMonthToday = moment(startDate).month() === previousMonth;
    const currentMonth = moment().month();
    const isEndMonthToday = moment(endDate).month() === currentMonth;
    if (isStartMonthToday && !isEndMonthToday) {
      const today = new Date().toISOString().slice(0, 10);
      this.filterCleaningsByWeek(today);
    } else {
      this.upcomingCleanings = this.imutableCleanings;
    }
    this.checkIfPastJobNeedsSyncing()
    this.checkIfHasJobs();
    loading?.dismiss();
  }

  async thisGetCalendarJobCounts(parsedStartDate, parsedEndDate) {
    const counts = await this.cleanings.getCalendarJobCounts(parsedStartDate, parsedEndDate);
    const newObject = Object.entries(counts).reduce((acc, [key, value]) => {
      const newKey = key.slice(5);
      acc[newKey] = value;
      return acc;
    }, {});
    return newObject;
  }

  addTimeSensitiveIndicators() {
    this.upcomingCleanings.map((day) => {
      day?.cards.map((card) => {
        if (this.isTimeSensitiveJob(card)) {
          card['isTimeSensitiveJob'] = true;
        }
      })
    });
  }

  isTimeSensitiveJob(job) {
    if (!job?.guest_reservation_data?.next_guest_reservation?.check_in_date) {
      return false;
    }
    const checkInDate = LuxonDateTime.fromISO(job?.guest_reservation_data?.next_guest_reservation.check_in_date);
    const jobDate = LuxonDateTime.fromISO(job?.job?.date);
    return checkInDate.hasSame(jobDate, 'day');
  }

  @Loading()
  async onCardClicked(cleaning: any) {
    if (cleaning.job.type === 'custom') {
      localStorage.setItem('privateJobBackPage', 'jobs')
      this.customNavCtrl.navigateForward(`private-job/${cleaning.job.id}`);

    } else if (this.isPastCleaning(cleaning)) {
      localStorage.setItem('pastJobBackPage', 'jobs')
      this.customNavCtrl.navigateForward(`past-job/${cleaning.job.id}`);

    } else if (this.isCurrentCleaning(cleaning)) {
      this.goToMainWorkflow(cleaning);

    } else if (this.isFutureCleaning(cleaning)) {
      localStorage.setItem('futureJobBackPage', 'jobs')
      this.customNavCtrl.navigateForward('future-job', cleaning);

    } else if (this.isBookingProtectionCleaning(cleaning)) {
      const jobData = await this.mwService.getJob(cleaning.job.id);
      this.customNavCtrl.navigateForward('success', {
        header: 'Address Pending',
        buttonRoute: 'jobs',
        buttonText: 'Go to Jobs',
        learnMoreLink: 'https://help.tidy.com/pros/booking-protection',
        body: `Please check back later to see if a client has been found.  This is still considered a booked job at this time,
        and is NOT cancelled.  This job is covered by Booking Protection,
        which means we are trying to find the best client for you during this time.`
      });
    }
  }

  goToConciergePage() {
    this.customNavCtrl.navigateForward('concierge');
  }

  goToCalendarPage(){
    const params = {
      calendarUrl: this.calendarUrl
    }
    this.customNavCtrl.navigateForward('export-calendar', params);
  }

  displayArrow(cleaning: any): boolean {
    return this.permissions.show_today_cleanings
            || cleaning.card_type !== 'current_cleaning';
  }

  isCurrentCleaning(cleaning: any): boolean {
    return cleaning.card_type === 'current_cleaning' && this.permissions.show_today_cleanings;
  }

  isFutureCleaning(cleaning: any): boolean {
    return cleaning.card_type === 'future_cleaning';
  }

  isPastCleaning(cleaning: any): boolean {
    return cleaning.card_type === 'past_cleaning';
  }

  isBookingProtectionCleaning(cleaning: any): boolean {
    return cleaning.card_type === 'booking_protection_cleaning';
  }

  scheduleEditable(day: any): boolean {
    return moment().isBefore(moment(day.date));
  }

  ionViewWillLeave(): void {
    this.successPageLoad.emit(true);
    this.loaded = true;
    clearInterval(this.fetchInterval);
    this.fetchInterval = null;
  }

  checkWeekToDisplay(weekToDisplay, dropdownWeeks): string {
    return weekToDisplay || dropdownWeeks.find( dropdown => dropdown.name === 'This Week' ).start_date;
  }

  async checkHkState(): Promise<any> {
    const foundSavedData = await this.storage.retrieve('me');
    let hk: HomekeeperModel = foundSavedData;
    if (!foundSavedData) {
      hk = await this.me.fetchWithoutCache();
      this.storage.save('me', hk);
    }
    this.homeLongitude = hk.user.longitude;
    this.homeLatitude = hk.user.latitude;
    this.hkState = hk.user.state;
    this.hkAvailableRepeat = hk.custom_fields.available_for_repeat_clients;
    this.hkId = hk.user.id;
    this.calendarUrl = hk.custom_fields.calendar_url;
  }

  @Loading()
  async goToMainWorkflow(job) {
    try {
      const jobData = await this.mwService.getJob(job.job.id);
      this.mwStore.setJob(jobData);

      const storedJobId = await this.mwStore.getJobId();
      const syncData = await this.mwStore.getSyncData();

      const hasJobInProgress = storedJobId !== null && storedJobId !== undefined;
      const clickedJobInProgress = storedJobId == job.job.id;
      const startedJobInProgress = syncData !== null && syncData !== undefined;
      const clearStore = hasJobInProgress && clickedJobInProgress;

      if (this.mwService.isTooLateForJob(jobData.endTime)) {
        return this.mwService.showTooLateAlert();
      }
      if (jobData.cancelled) {
        return this.mwService.showJobCancelledAlert(clearStore);
      }
      if (jobData.isStandbyCancelled) {
        return this.mwService.showStandbyCancelledAlert(clearStore);
      }
      if (jobData.inactive) {
        return this.mwService.showInactiveJobAlert(clearStore);
      }

      if (startedJobInProgress && clickedJobInProgress) {
        this.mwService.addMomentToSyncData(mwMoments.viewedJob, 'jobs', job.job.id);
      } else {
        this.mwService.sendMomentToBackend(mwMoments.viewedJob, 'jobs', job.job.id);
      }

      if (hasJobInProgress && clickedJobInProgress) {
        const storedRoute = await this.mwStore.getRoute();
        this.customNavCtrl.navigateForward(storedRoute);
      } else {
        localStorage.setItem('readyToLeaveBackPage', 'jobs')
        this.customNavCtrl.navigateForward('ready-to-leave');
      }
    } catch (err) {
      this.mwService.showErrorPage(err);
    }
  }

  unpause() {
    this.customNavCtrl.navigateForward(`unpause/${this.hkId}`);
  }

  showUndoLink(job): boolean {
    const momentLimit = moment().add(29, 'm');
    const possiblePerformances = ['homekeeper_cancelled', 'last_minute_call_out', 'call_out', 'auto_no_show', 'call_out_all_day', 'homekeeper_cancelled_no_fee'];

    const {date, start_time} = job.job;
    const {performance} = job.homekeeper_job;

    const stateCondition = this.hkState === 'active';
    const timeCondition = moment(`${date} ${start_time}`).isAfter(momentLimit);
    const performanceCondition = possiblePerformances.indexOf(performance) > -1;

    return stateCondition && timeCondition && performanceCondition;
  }

  redirToUndo(job) {
    this.customNavCtrl.navigateForward(`undo-cancellation/${job.job.id}`);
  }

  learnMoreRepeat() {
    this.iabUtils.openUrl('https://help.tidy.com/pros/get-clients-from-tidy');
  }

  learnMoreNightlyCutoff() {
    this.iabUtils.openUrl('https://help.tidy.com/pros/set-a-schedule');
  }

  @Loading()
  async editAvailability(date) {
    let dayData = this.availability.find(availabilityDay => {
      return availabilityDay.date === date;
    });
    if (!dayData) {
      const dateObj = new Date(date);
      const month = dateObj.getMonth() + 1;
      const year = dateObj.getFullYear();
      const availability = await this.mySchedule.fetchMonthAvailability(month, year, month);
      dayData = availability.find(availabilityDay => {
        return availabilityDay.date === date;
      });
    }
    this.customNavCtrl.navigateForward('edit-schedule', dayData);
  }

  parseWeeksDropdown(weeksData) {
    return weeksData.map(week => {
      return {
        viewValue: week.name,
        value: week.start_date
      };
    });
  }

  getCenterCoordinates(cards: any[], hasHome: boolean) {
    let centerLongitude: number;
    let centerLatitude: number;

    if (hasHome) {
      centerLongitude = this.homeLongitude;
      centerLatitude = this.homeLatitude;
    } else {
      let totalLongitude = 0;
      let totalLatitude = 0;
      cards.forEach((cleaning) => {
        totalLongitude += cleaning.address?.longitude;
        totalLatitude += cleaning.address?.latitude;
      });
      centerLongitude = totalLongitude / cards.length;
      centerLatitude = totalLatitude / cards.length;
    }
    return { centerLatitude, centerLongitude };
  }

  async setMap(item: any, index: number): Promise<void> {
    try {
      const hasHome = this.homeLongitude !== null && this.homeLongitude !== undefined;
      const { centerLatitude, centerLongitude } = this.getCenterCoordinates(item.cards, hasHome);

      mapboxgl.accessToken = AppConfig.MAPBOX_KEY;
      const mapElementId = `map-jobs${index}`;
      await this.awaitMapElement(mapElementId);

      const map = new mapboxgl.Map({
        container: mapElementId,
        style: 'mapbox://styles/mapbox/light-v10',
        center: [centerLongitude, centerLatitude],
        zoom: 5,
      });
      let bounds = new mapboxgl.LngLatBounds();
      item.cards.forEach((cleaning, i) => {
        this.addOppMarker(cleaning, map, i);
        bounds.extend([cleaning.address?.longitude, cleaning.address?.latitude]);
      });
      if (hasHome) {
        this.addHomeMarker(map);
        bounds.extend([this.homeLongitude, this.homeLatitude]);
      }
      map.fitBounds(bounds, {
        padding: 20,
        maxZoom: 11,
        animate: false
      });

      item.showMap = true;
    } catch (err) {
      console.error(err);
      this.timeoutHandler(err);
      this.handleLoadErrors(err);
    }
  }

  async awaitMapElement(mapId, atempts = 1) {
    const mapElement = document.getElementById(mapId);

    if(!mapElement && atempts < 4) {
      await promiseUtils.sleep(300);
      await this.awaitMapElement(mapId, (atempts + 1));
    }
  }

  createMarkerInstance(obj){
    const {el, longLat, map} = obj;
    new mapboxgl.Marker(el)
      .setLngLat(longLat)
      .addTo(map);
  }

  async addHomeMarker(map) {
    const el = document.createElement('div');
    el.className = 'marker';
    el.innerHTML = `<image src="assets/img/home-black.svg" style="height: 30px"></image>`;
    this.createMarkerInstance({map, el, longLat: [ this.homeLongitude, this.homeLatitude ]});
  }

  addOppMarker(cleaning, map, i) {
    const el = document.createElement('div');
    const letter = String.fromCharCode(65+i);
    el.className = 'marker';
    el.innerHTML = `<span class="map-marker" style="margin-top: ${i * 2}0px">${letter}</span>`;
    this.createMarkerInstance({map, el, longLat: [ cleaning.address?.longitude, cleaning.address?.latitude ]})
  }

  handleLoadErrors(error: any): void {
    const route = window.location.pathname;
    if (!route.includes('jobs')) {
      return;
    }
    console.error(error);
    this.timeoutHandler(error);
    const errorMessage = (error.error && error.error.message) ? error.error.message : error.message;
    this.utils.showError(errorMessage, 10000);
  }
}
