import { Injectable } from '@angular/core';
import { Location } from 'src/shared/providers/location';

@Injectable({
  providedIn: 'root'
})
export class CheckLocationPermission {

  constructor(
    private location: Location
  ) { }

  async requestLocationPermission(): Promise<{success: boolean}> {
    const hasPermission = await this.hasLocationPermission();

    return { success: hasPermission };
  }

  async hasLocationPermission() {
    const location = await this.location.get();

    return !location.error;
  }

}
