<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Request Certification'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'Your certification is no longer valid. To re-request certification, please confirm the following.'">
      </tidy-text>
    </tidy-row>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'I represent and warrant the following:'">
      </tidy-text>
    </tidy-row>

    <tidy-card>
      <form [formGroup]="form" novalidate action="#">
        <ng-container *ngFor="let question of questions">
          <tidy-row class="extra-top-padding">
            <tidy-grid>
              <ion-row>
                <ion-col size="1">
                  <tidy-checkbox
                    style="margin-right: 5px"
                    formControlName="{{question?.formValue}}"
                    [items]="[{viewValue: '', value: false}]">
                  </tidy-checkbox>
                </ion-col>
                <ion-col>
                  <ng-container *ngIf="question?.formValue !== 'agreeTerms'">
                    <tidy-text
                      [body]="question?.viewValue">
                    </tidy-text>
                  </ng-container>
                  <ng-container *ngIf="question?.formValue == 'agreeTerms'">
                    <tidy-text
                      [body]="'I agree to the terms currently on '">
                    </tidy-text>
                    <tidy-text
                      (action)="openAgreement()"
                      [body]="'https://www.tidy.com/legal/service-provider'"
                      class="link">
                    </tidy-text>
                  </ng-container>
                </ion-col>
              </ion-row>
            </tidy-grid>
          </tidy-row>
        </ng-container>
      </form>
    </tidy-card>

    <tidy-error-message
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-button
      [text]="'I Agree - Request Certification'"
      [action]="confirmReapply.bind(this)"
      class="primary">
    </tidy-button>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
