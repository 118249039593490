import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { HttpClient } from 'src/shared/providers/http/http-client';
import { Location } from 'src/shared/providers/location';
import { randomUUID } from 'src/shared/utils/secure-utils';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Aws } from 'src/shared/providers/aws';
import { DeviceInfoProvider } from 'src/shared/providers/device-info';
import { Auth } from 'src/providers/auth/auth';
import { JobMediasProvider } from 'src/providers/job-medias/job-medias.provider';
import { ToDosProvider } from 'src/providers/to-dos/to-dos';

@Injectable()
export class SharedLinksProvider {

  constructor(
    private httpClient: HttpClient,
    private location: Location,
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private deviceInfo: DeviceInfoProvider,
    private auth: Auth,
    private aws: Aws,
    private jobMediasProvider: JobMediasProvider,
    private toDosProvider: ToDosProvider
  ) {}

  getSharedLink(sharedLinkUuid) {
    const url = `api/v1/tidy-website/shared-links/${sharedLinkUuid}`;
    return this.httpClient.get(url);
  }

  updateSharedLink(sharedLinkUuid, payload) {
    const url = `api/v1/tidy-website/shared-links/${sharedLinkUuid}/execute-action`;
    return this.httpClient.post(url, payload);
  }

  async buildPrivateJobMoment(data, route) {
    const location = await this.location.get();
    const response: any = {
      date: moment.utc().format('YYYY-MM-DD'),
      time: moment.utc().format('HH:mm:ss'),
      latitude: location.latitude,
      longitude: location.longitude,
      moment: data.name,
      description: data.description,
      source: route,
      momment_uuid: randomUUID()
    };
    if (location?.error) {
      response['location_error_message'] = location?.error;
    }
    return response;
  }

  goToCompleteSharedListPage(rooms, sharedList, clientName, uuid) {
    rooms.map((room) => {
      room.tasks.map((task) => {
        task['customFieldForm'] = null;
      });
    });
    sharedList.shared_link_data.address_task_list.address_rooms = rooms;
    const params = {
      clientName: clientName,
      uuid: uuid,
      list: sharedList
    }
    this.navCtrl.navigateForward('complete-to-do-list', params);
  }

  async addSharedJobMoment(uuid, currentMoments, name, description) {
    const data = {
      name: name,
      description: description
    }
    const moment: any = await this.buildPrivateJobMoment(data, `shared_job/${uuid}`);
    const moments = [
      ...currentMoments,
      moment
    ];
    this.storage.save(`sharedJob/${uuid}/moments`, moments);
    localStorage.setItem(`sharedJob/${uuid}/last-moment`, moment.moment);
  }

}
