import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PrivatePayments } from 'src/providers/private-payments/private-payments';

import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  templateUrl: 'add-client-payment-setting.html'
})

export class AddClientPaymentSettingPage extends Timeout {

  addressItems: TidySelectNumberValueModel[];
  errorMessage: string;
  form: UntypedFormGroup;
  isPreferred: boolean;
  paymentTypeItems: TidySelectNumberValueModel[]
  submitted: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private privatePayments: PrivatePayments,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.formBuilder.group({
      notes: [''],
      accountName: [''],
      paymentType: ['', Validators.required]
    })
  }

  async ionViewDidEnter() {
    try {
      this.loaded = false;
      this.isPreferred = true;
      const paymentOptions = this.navCtrl.getParam('paymentOptions');
      const paymentTypes = await this.privatePayments.getEligiblePaymentTypes();
      this.paymentTypeItems = this.buildPaymentTypeItems(paymentTypes, paymentOptions);
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  buildPaymentTypeItems(paymentTypes, paymentOptions) {
    const alreadyOfferedTypes = [];
    paymentOptions.map((option) => {
      paymentTypes.map((type) => {
        if (type.name == option.team_payment_option_type.name && !alreadyOfferedTypes.includes(type)) {
          alreadyOfferedTypes.push(type);
        }
      })
    })
    const array = [];
    paymentTypes.map((type) => {
      if (!alreadyOfferedTypes.includes(type)) {
        array.push({
          viewValue: type.name,
          value: type.id
        });
      }
    });
    return array;
  }

  async addSetting() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    try {
      const payload = this.buildPayload();
      await this.privatePayments.addPaymentOption(payload);
      const params = {
        header: 'Option Added',
        body: '',
        buttonText: 'Ok',
        buttonRoute: 'client-payment-settings'
      };
      this.navCtrl.navigateForward('success', params);
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  buildPayload() {
    return {
      team_payment_options: [{
        team_payment_option_type_id: this.form.value.paymentType,
        third_party_account_name: this.form.value.accountName,
        notes: this.form.value.notes,
        is_preferred: this.isPreferred
      }]
    };
  }

  showAccountNameField() {
    let showField = true;
    switch(this.form.value.paymentType) {
      case '':
      case 1:
      case 2:
      case 3:
        showField = false;
    }
    return showField;
  }
}
