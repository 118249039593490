import { Component, OnInit } from '@angular/core';

import { ScheduleCardModel } from '../../../models/schedule-card.model';

import * as moment from 'moment';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { ServerData } from 'src/shared/providers/server-data';
import { Loading } from 'src/shared/components/loading/loading';
import { SelectModel } from '@tidyapp/tidy-ui-components';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OnRails } from 'src/providers/on-rails/on-rails';
import { SuccessPageParamsModel } from 'src/pages/success/success';

@Component({
  templateUrl: 'check-in-call-out.html'
})
export class CheckInCallOutPage implements OnInit {

  callOutCleanings: any = [];
  cancelReasonsItems: Array<SelectModel>;
  loaded: boolean;
  reasonForm: UntypedFormGroup;
  submitted = false;
  choseCleaning = true;
  apiError: string;

  constructor(
    private customNavCtrl: CustomNavController,
    private serverData: ServerData,
    private fb: UntypedFormBuilder,
    private onRails: OnRails
  ) {
    this.reasonForm = this.fb.group({
      reason: ['', Validators.required]
    });
  }

  async ngOnInit() {
    this.loaded = false;
    const { cleanings } = this.customNavCtrl.getParams();
    this.callOutCleanings = cleanings;
    this.callOutCleanings.forEach( c => c.checked = false );
    await this.loadCancelReasons();
    this.loaded = true;
  }

  confirm = async () => {
    this.submitted = true;
    this.apiError = '';
    if (this.countChecked() === 0) {
      return this.choseCleaning = false;
    }
    if (!this.reasonForm.valid) {
      return;
    }
    const jobIds = this.filterConfirmCleanings();
    try {
      await this.onRails.confirmCleanings(jobIds, this.reasonForm.value.reason);
      this.customNavCtrl.navigateForward('success', this.successParams());
    } catch (err) {
      this.apiError = err.error ? err.error.message : err.message;
    }
  }

  successParams(): SuccessPageParamsModel {
    return {
      header: 'You Successfully Called Out',
      body: 'Your Clients have been notified.',
      buttonRoute: 'jobs',
      buttonText: 'Go To Jobs'
    };
  }

  filterConfirmCleanings() {
    const cleanings = this.callOutCleanings.filter(cleaning => !cleaning.checked);
    return cleanings.map(cleaning => cleaning.job.id);
  }

  countChecked(): number {
    if (!this.callOutCleanings || !this.callOutCleanings.length) {
      return 0;
    }

    return this.callOutCleanings.filter( i => i.checked === true ).length;
  }

  @Loading()
  async loadCancelReasons() {
    const cancelReasons = await this.serverData.cancelReasons(true);
    this.cancelReasonsItems = cancelReasons.map(reason => {
      return {
        viewValue: reason.title,
        value: reason.id
      };
    });
  }

}
