import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { Photo } from '@capacitor/camera';

import { Aws } from 'src/shared/providers/aws';

import { Camera } from 'src/shared/providers/camera/camera';
import { Certification } from 'src/providers/certification/certification';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Logger } from 'src/shared/providers/logger';
import { Me } from 'src/shared/providers/me';

import { SuccessPageParamsModel } from 'src/pages/success/success';
import { Loading } from 'src/shared/components/loading/loading';

import { SelectModel } from '@tidyapp/tidy-ui-components';

import { equipmentOptions } from 'src/shared/constants/camera';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

@Component({
  selector: 'equipment',
  templateUrl: 'equipment.html',
})
export class OnboardingEquipmentPage extends Timeout {

  equipmentGrades: SelectModel[];
  form: UntypedFormGroup;
  errorMessage: string;
  apiError: string;
  submitted: boolean;
  photoToUpload: string;
  equipmentPhoto: any;
  hkId: number;
  photoErrorMessage: string;
  awsImageKey: string;

  constructor(
    private aws: Aws,
    private camera: Camera,
    private certification: Certification,
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private logger: Logger,
    private me: Me,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.fb.group({
      supplies: [false, Validators.requiredTrue]
    });
    this.equipmentGrades = this.formatEquipmentGradeList();
  }

  gradeValidator(formControl: UntypedFormControl): ValidationErrors {
    const { value } = formControl;
    const valid = value && value !== `D or F (Not Approved)`;
    return valid ? null : { grade: 'Invalid grade' };
  }

  async ionViewDidEnter() {
    try {
      this.loaded = false;
      this.submitted = false;
      this.photoToUpload = '';
      this.awsImageKey = '';
      const data = await this.me.load();
      this.hkId = data.user.id;
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  formatEquipmentGradeList(): { value: string; viewValue: string; }[] {
    return ['A', 'B', 'C', `D or F (Not Approved)`, `Not Rated but TIDY Approved`]
      .map(item => ({ value: item, viewValue: item }));
  }

  save = async () => {
    this.submitted = true;
    this.errorMessage = '';
    if (this.form.invalid || !this.photoToUpload.length) {
      return this.errorMessage = 'There were errors in your submission.  Please scroll up to fix errors.';
    }
    try {
      await this.uploadPicture();
      await this.certification.finishStep('equipment_verification', this.prepareData());
      const params = this.mountSuccessPageParams();
      this.customNavCtrl.navigateForward('success', params);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      if (this.errorMessage.includes('kCLErrorDomain error 1')) {
        this.errorMessage = 'You have not granted the TIDY app access to location services. Please grant the TIDY app access to location services in your phone settings to submit this photo.'
      }
      this.logger.error(err, 'equipment-photo');
    }
  }

  prepareData(): EquipmentModel {
    return {
      equipment: {
        mop:                       '',
        mop_sku:                   '',
        towel:                     '',
        towel_sku:                 '',
        sponge:                    '',
        sponge_sku:                '',
        carry_case:                '',
        other_items:               '',
        toilet_brush:              '',
        toilet_brush_sku:          '',
        vacuum:                    '',
        vacuum_sku:                '',
        heavy_scrubber:            '',
        heavy_scrubber_sku:        '',
        glass_cleaner:             '',
        glass_cleaner_sku:         '',
        glass_cleaner_grade:       `A`,
        bathroom_cleaner:          '',
        bathroom_cleaner_sku:      '',
        bathroom_cleaner_grade:    `A`,
        all_purpose_cleaner:       '',
        all_purpose_cleaner_sku:   '',
        all_purpose_cleaner_grade: `A`,
        certified_min_ew:          true,
        s3_full_kit_picture_key:   this.awsImageKey
      }
    };
  }

  mountSuccessPageParams(): SuccessPageParamsModel {
    return {
      header: 'Equipment Submitted',
      body: 'Our Concierge will review and let you know if your equipment is approved within 12 hours.',
      buttonText: 'Ok',
      buttonRoute: 'new-clients'
    };
  }

  @Loading()
  async uploadPicture(): Promise<any> {
    const awsResponse = await this.aws.uploadImageToS3(this.photoToUpload, `equipment-photo/${this.hkId}`);
    this.awsImageKey = awsResponse.Key;
  }

  takeEquipmentPhoto() {
    this.photoErrorMessage = '';
    this.camera.getPhoto(equipmentOptions).then((cameraPhoto) => {
      try {
        if (!cameraPhoto?.base64String) {
          return this.photoErrorMessage = 'No image selected';
        }
        const imagesAllowedTypes = ['jpeg', 'jpg', 'png'];
        if (!imagesAllowedTypes.includes(cameraPhoto.format)) {
          return this.photoErrorMessage = 'Unable to save this filed type. Please upload a JPEG or PNG image.'
        }
        this.photoToUpload = cameraPhoto.base64String;
        this.equipmentPhoto = cameraPhoto.dataUrl;
      } catch (err) {
        if (err !== 'No Image Selected') {
          this.photoErrorMessage = err.error ? err.error.message : err.message;
        }
        this.logger.error(err, 'equipment-photo-camera');
      }
    });
  }

  goToWebCertification(){
    this.customNavCtrl.navigateForward('instant-certification');
  }
}

interface EquipmentModel {
  equipment: {
    mop:                       string;
    mop_sku:                   string;
    towel:                     string;
    towel_sku:                 string;
    sponge:                    string;
    sponge_sku:                string;
    carry_case:                string;
    other_items:               string;
    toilet_brush:              string;
    toilet_brush_sku:          string;
    vacuum:                    string;
    vacuum_sku:                string;
    heavy_scrubber:            string;
    heavy_scrubber_sku:        string;
    glass_cleaner:             string;
    glass_cleaner_sku:         string;
    glass_cleaner_grade:       string;
    bathroom_cleaner:          string;
    bathroom_cleaner_sku:      string;
    bathroom_cleaner_grade:    string;
    all_purpose_cleaner:       string;
    all_purpose_cleaner_sku:   string;
    all_purpose_cleaner_grade: string;
    certified_min_ew:          boolean;
    s3_full_kit_picture_key:   string;
  }
}
