import { Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Concierge } from 'src/providers/concierge/concierge';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { AttachmentModel, ConciergeItemModel } from 'src/models/concierge.model';
import { HomekeeperModel } from 'src/shared/models/homekeeper.model';
import { Me } from 'src/shared/providers/me';
import { Loading } from 'src/shared/components/loading/loading';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: 'concierge-reply.html',
  encapsulation: ViewEncapsulation.None
})

export class ConciergeReplyPage {

  errorMessage: string;
  email: string;
  form: UntypedFormGroup;
  item: ConciergeItemModel;
  submitted: boolean;
  attachments: AttachmentModel[] = [];

  constructor(
    public concierge: Concierge,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private me: Me,
  ) {
    this.form = this.fb.group({
      content: ['', Validators.required]
    });
  }

  async ionViewDidEnter() {
    this.item = this.navCtrl.getParam('item', true);
  }

  async send() {
    this.errorMessage = '';
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    let data = {
    	support_ticket_id: this.item.components[0].value.id,
      content: this.form.value.content,
      attachments: this.attachments.map(item => item.fileKey)
    };
    try {
      await this.concierge.addConciergeTicketComment(data);
      this.goToSuccessPage();
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  goToSuccessPage() {
    const email = localStorage.getItem('proEmail');
    const replyTime = this.concierge.getReplyTime(this.item.type);
    const params = {
      header: 'Message Sent',
      body: `${new TranslationPipe().transform('We expect to reply within')} ${replyTime}. ${new TranslationPipe().transform('We will reply both in the app and to your email')} ${email}`,
      buttonText: 'Ok',
      buttonRoute: 'concierge'
    };
    this.navCtrl.navigateForward('success', params);
  }

  @Loading()
  async addAttachment() {
    this.errorMessage = '';
    try {
      const attachment = await this.concierge.addAttachment();
      this.attachments.push(attachment);
    } catch (err) {
      this.errorMessage = err;
    }
  }

  removeAttachment(attachIndex: number) {
    this.attachments.splice(attachIndex, 1);
  }
}
