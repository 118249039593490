<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Edit Payment Option'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Add a new option for Clients to pay invoices.'">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <form [formGroup]="form">
          <tidy-row  class="field-top-padding field-bottom-padding">
            <tidy-select
              [label]="'Payment Type*'"
              [form]="form.controls.paymentType"
              formControlName="paymentType"
              [errorMessage]="'Please select a payment type.'"
              [submitted]="submitted"
              [items]="paymentTypeItems"
              [icon]="'assets/svg/information-circle-outline.svg'">
            </tidy-select>
          </tidy-row>
          <tidy-row class="field-bottom-padding" *ngIf="showAccountNameField()">
            <tidy-input
              [label]="'Account Name'"
              [icon]="'assets/svg/person-outline.svg'"
              formControlName="accountName"
              [form]="form.controls.accountName">
            </tidy-input>
           </tidy-row>
           <tidy-row>
            <tidy-textarea
              [label]="'Notes'"
              formControlName="notes"
              [form]="form.controls.notes"
              [icon]="'assets/svg/create-outline.svg'">
            </tidy-textarea>
          </tidy-row>
          <tidy-row class="extra-top-padding">
            <tidy-checkbox
              [(checked)]="isPreferred"
              [items]="[
                {
                  viewValue: 'Preferred payment method', value: true
                }
              ]"
              [initValue]="isPreferred">
            </tidy-checkbox>
          </tidy-row>
        </form>
      </tidy-card>

      <tidy-button
        [text]="'Save Changes'"
        [action]="saveChanges.bind(this)"
        class="primary">
      </tidy-button>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Remove Payment Option'"
          (action)="removeOption()"
          class="link red">
        </tidy-text>
      </tidy-row>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper><ng-template #content>
