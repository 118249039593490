<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Not Able to Finish?'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <ng-container *ngIf="!toldToLeave">
        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'<b>Time Left: </b>'">
          </tidy-text>
          <countdown
            [target]="jobTimer">
          </countdown>
        </tidy-row>

        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'The client might dispute the job if you don\'t clean the full time they booked and they are unhappy with the job.'">
          </tidy-text>
        </tidy-row>

        <tidy-button
          [text]="'Back to Job'"
          [action]="goBack.bind(this)"
          class="primary">
        </tidy-button>

        <tidy-button
          [text]="'Tell Client I Can\'t Stay'"
          [action]="cantStay.bind(this)"
          class="secondary">
        </tidy-button>

        <tidy-button
          [text]="'Client Told Me to Leave'"
          [action]="clientToldToLeave.bind(this)"
          class="secondary">
        </tidy-button>
      </ng-container>

      <ng-container *ngIf="toldToLeave">
        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'Why did they ask you to leave?'">
          </tidy-text>
        </tidy-row>

        <tidy-button
          [text]="'They Were Unhappy With Me'"
          [action]="theyWereUnhappy.bind(this)"
          class="secondary">
        </tidy-button>

        <tidy-button
          [text]="'They Did Not Want the Full Time'"
          [action]="theyDidNotWant.bind(this)"
          class="secondary">
        </tidy-button>
      </ng-container>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
