import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'isFuture'
})
export class TidyDateIsFuturePipe implements PipeTransform {

  transform(value: string): boolean {
    return moment(value).isAfter();
  }
}
