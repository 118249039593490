import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Me } from 'src/shared/providers/me';

import { Loading } from 'src/shared/components/loading/loading';

import validationUtils from 'src/shared/utils/validation-utils';

import { SuccessPageParamsModel } from 'src/pages/success/success';

@Component({
  selector: 'password-update',
  templateUrl: 'password-update.html'
})

export class PasswordUpdatePage {

  errorMessage: string;
  form: UntypedFormGroup;
  loaded: boolean;
  submitted: boolean;
  token: string;

  constructor(
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private me: Me,
    private route: ActivatedRoute
  ) {
    this.form = this.fb.group({
      password: ['', Validators.required],
      confirm_password: ['', Validators.required]
    }, { validator: [validationUtils.validateSize('password', 8), validationUtils.validateEqual('password', 'confirm_password')] });
  }

  async ionViewDidEnter() {
    const token = this.route.snapshot.paramMap.get('token');
    try {
      const validation = await this.me.validateResetPwdToken(token);
      if (validation.status !== 'valid') {
        const params = this.mountInvalidTokenPageParams();
        this.customNavCtrl.navigateForward('success', params);
      }
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
    this.loaded = true;
  }

  async save() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    try {
      await this.me.changePassword({
        reset_password_token: this.route.snapshot.paramMap.get('token'),
        ...this.form.value
      });

      const params = this.mountSuccessPageParams();
      this.customNavCtrl.navigateForward('success', params);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  mountInvalidTokenPageParams(): SuccessPageParamsModel {
    return {
      header: 'Link Expired',
      body: 'This link isn\'t valid.  Please click \"Forgot Password\" in the log in page to initiate a new email, or contact us in the Concierge section for support.',
      buttonText: 'Ok',
      buttonRoute: 'introduction'
    };
  }

  mountSuccessPageParams(): SuccessPageParamsModel {
    return {
      header: 'Password Updated',
      body: 'You can use this new password to log in.',
      buttonText: 'Ok',
      buttonRoute: 'introduction'
    };
  }
}
