import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { HttpClient } from 'src/shared/providers/http/http-client';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Location } from 'src/shared/providers/location';
import { Aws } from 'src/shared/providers/aws';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Loading } from 'src/shared/components/loading/loading';
import { SyncJobParams } from 'src/shared/models/sync-job.model';
import { DeviceInfoProvider } from 'src/shared/providers/device-info';
import { CommonUtils } from 'src/shared/utils/common-utils';
import { JobMediasProvider } from 'src/providers/job-medias/job-medias.provider';
import { ToDosProvider } from 'src/providers/to-dos/to-dos';
import { PrivateClient } from 'src/providers/private-client/private-client';
import { removeCurrencyMask } from 'src/shared/utils/currency-utils';
import { PrivatePayments } from 'src/providers/private-payments/private-payments';

@Injectable({
  providedIn: 'root'
})

export class PrivateJobService {

  constructor(
    private deviceInfo: DeviceInfoProvider,
    private storage: TidyStorage,
    private httpClient: HttpClient,
    private location: Location,
    private aws: Aws,
    private navCtrl: CustomNavController,
    private utils: CommonUtils,
    private jobMediasProvider: JobMediasProvider,
    private toDosProvider: ToDosProvider,
    private privateClient: PrivateClient,
    private privatePayments: PrivatePayments
  ) {}

  async setPrivateJobSyncData(syncData, jobId) {
    await this.storage.save(`privateJobSyncData/${jobId}`, syncData);
  }

  async clearPrivateJobSyncData(jobId) {
    await this.storage.delete('lastJobSyncTime');
    await this.storage.delete(`privateJobSyncData/${jobId}`);
    await this.storage.delete(`privateJobPayloadValues/${jobId}`);
  }

  async createPrivateJobMoments(data: any[], route, jobId) {
    const moments = await Promise.all(data.map(async (moment) => {
      return await this.buildPrivateJobMoment(moment, route, jobId);
    }));
    const payload = {
      job_id: jobId,
      moments
    }
    const url = `api/v1/homekeeper/private/jobs/${jobId}/create-moments`;
    try {
      this.storage.delete(`privateJobMomentsPayload/${jobId}`);
      await this.httpClient.post(url, payload);
    } catch (error) {
      this.storage.save(`privateJobMomentsPayload/${jobId}`, payload);
    }
  }

  async buildPrivateJobMoment(data, route, jobId) {
    const location = await this.location.get();
    const response: any = {
      date: moment.utc().format('YYYY-MM-DD'),
      time: moment.utc().format('HH:mm:ss'),
      latitude: location.latitude,
      longitude: location.longitude,
      moment: data.name,
      description: data.description,
      source: route
    };
    if (location?.error) {
      response['location_error_message'] = location?.error;
    }
    return response;
  }

  updateJobDuration(payload, jobId) {
    const url = `api/v1/homekeeper/private/jobs/${jobId}/change-duration?includes=job_durations`;
    return this.httpClient.post(url, payload);
  }

  async completePrivateJob(jobId, params) {
    try {
      await this.toDosProvider.syncJob();
    } catch (error) {
      this.utils.showError('Error completing job, please try again later.');
      return;
    }
    const data = {
      name: 'end',
      description: 'Complete Cleaning'
    }
    const momentData = await this.buildPrivateJobMoment(data, 'private-job', jobId);
    const payload = {
      ...params,
      device_id: await this.deviceInfo.uuid(),
      moments: [momentData]
    }
    const url = `api/v1/homekeeper/private/jobs/${jobId}/complete`;
    return this.httpClient.post(url, payload);
  }

  checkIfToDosWereChangedByClient(todos, syncData) {
    if (!syncData) {
      return false;
    }
    let didChange = false;
    let allBackendToDos = [];
    todos.map((room) => {
      room.tasks.find((BEToDo) => {
        allBackendToDos.push(BEToDo);
      });
    });
    syncData.todos.map((syncDataToDo) => {
      const syncDataToDoStillExistsInBackend = allBackendToDos.find((BEToDo) => {
        return BEToDo?.address_task?.id == syncDataToDo?.address_task_id;
      });
      if (!syncDataToDoStillExistsInBackend) {
        didChange = true;
      }
    });
    allBackendToDos.map((BEToDo) => {
      const backendToDoStillExistsInSyncData = syncData.todos.find((syncDataToDo) => {
        return BEToDo?.address_task?.id == syncDataToDo?.address_task_id;
      });
      if (!backendToDoStillExistsInSyncData) {
        didChange = true;
      }
    });
    return didChange;
  }

  getParsedBeforeAfterPhotos(jobMedias: any[], category: string): any[] {
    if (!jobMedias) {
      return [];
    }
    let parsedJobMedias = jobMedias.filter((media) => media.category === category);
    parsedJobMedias = parsedJobMedias.map((media) => {
      return {
        ...media,
        mediaType: media?.media_format,
        url: media.media_url,
        category: null,
      };
    })
    return parsedJobMedias;
  }

  @Loading()
  async goToSendInvoicePage(cameFromCompleteJob, privateJob) {
    let params = {
      selectedJobs: [{
        id: privateJob.job.id,
        service_type_details: privateJob.job.service_type_details,
        date: privateJob.job.date,
        start_time: privateJob.job.start_time,
        address: privateJob.address,
        amount: privateJob.job.amount,
        billing: {
          amount_due: privateJob.job.billing.amount_due
        },
        duration: privateJob.job.job_durations.reported_by_homekeeper || privateJob.job.job_durations.from_start_to_end_moment
      }],
      client: privateJob.customer,
      addressUnit: privateJob.address.unit,
      jobCost: privateJob.job.amount,
      jobId: privateJob.job.id,
      cameFromCompleteJob,
      delegationRole: this.getDelegationRole(privateJob)
    };
    this.navCtrl.navigateForward('send-invoice', params);
  }

  async getDelegationRole(jobDetails) {
    const hkId = await this.storage.retrieve('hk_id');
    if (!jobDetails.job.job_delegation_request) {
      return '';
    } else if (jobDetails.job.job_delegation_request?.delegated_by_team_member?.homekeeper?.id == hkId) {
      return 'delegator';
    } else {
      return 'delegatee';
    }
  }

  async sendPrivateJobInvoice() {
    const jobHours = await this.storage.retrieve('jobHours');
    const jobMinutes = await this.storage.retrieve('jobMinutes');
    const selectedJobs = await this.storage.retrieve('selectedJobs');
    const price = await this.storage.retrieve('price');
    const clientId = await this.storage.retrieve('clientId');
    const invoiceNotes = await this.storage.retrieve('invoiceNotes');
    const durationPayload = {
      duration: jobHours + jobMinutes
    }
    await this.updateJobDuration(durationPayload, selectedJobs[0].id);
    if (price !== '' && price) {
      const pricePayload = {
        amount: removeCurrencyMask(price)
      };
      await this.privateClient.editPrivateJobPrice(pricePayload, selectedJobs[0].id);
    }
    const invoicePayload = {
      customer_id: clientId,
      invoice_items: [],
      send_email: true
    }
    selectedJobs.map((job) => {
      invoicePayload.invoice_items.push({
        id: job.id,
        type: 'Job'
      });
    });
    const invoice = await this.privatePayments.sendInvoice(invoicePayload);
    if (invoiceNotes.length > 0) {
      await this.sendInvoiceNotes(invoice.id, invoiceNotes);
    }
  }

  async sendInvoiceNotes(invoiceId, invoiceNotes) {
    let payloads = [];
    invoiceNotes.map((note) => {
      let payload = {
        invoice_id: invoiceId,
        type: note.type,
        is_internal: note.is_internal
      };
      if (note.media_url) {
        payload['media_url']  = note.media_url;
      }
      if (note.text) {
        payload['text']  = note.text;
      }
      payloads.push(payload);
    });
    payloads.map(async (payload) => {
      await this.privatePayments.createInvoiceNote(payload);
    });
  }

}
