import { Injectable } from '@angular/core';
import { Share, ShareOptions, ShareResult } from '@capacitor/share';
import { Platform, ToastController } from '@ionic/angular';

@Injectable()
export class SocialShare {

  constructor(
    private platform: Platform,
    private toastCtrl: ToastController
  ) {
  }

  async shareUrl(opts: ShareOptions): Promise<ShareResult | void> {
    const mobile = this.platform.is('mobile');
    if (mobile) {
      return await this.nativeShare(opts);
    } else {
      return this.webShare(opts);
    }
  }

  private async nativeShare(opts: ShareOptions) {
    return await Share.share(opts);
  }

  private async webShare(opts: ShareOptions): Promise<void> {
    this.copyToClipBoard(opts.url);
    const toast = await this.toastCtrl.create({ message: 'Copied to clipboard!', duration: 3000 });
    toast.present();
  }

  private copyToClipBoard(text): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    document.execCommand('copy');
    document.body.removeChild(textArea);
  }
}
