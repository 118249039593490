<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="pageTitle">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <ng-container *ngIf="certifications?.existing_clients && allProposals?.length > 0">
        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'Email a beautiful proposal to your Client so they can agree with 1 tap. '">
          </tidy-text>
          <tidy-text
            (action)="learnMore()"
            [body]="'Learn More'"
            class="link">
          </tidy-text>
        </tidy-row>

        <tidy-row>
          <tidy-filter
            [initValue]="'pending'"
            [items]="[
              {
                viewValue: 'Pending',
                value: 'pending'
              },
              {
                viewValue: 'Accepted',
                value: 'accepted'
              },
              {
                viewValue: 'Rejected',
                value: 'rejected'
              },
              {
                viewValue: 'Expired',
                value: 'expired'
              },
              {
                viewValue: 'Cancelled',
                value: 'cancelled'
              }
            ]"
            [fitContent]="true"
            (optionChange)="updateFilter($event)">
          </tidy-filter>
        </tidy-row>

        <ng-container *ngIf="proposalsLoaded">
          <tidy-alert *ngIf="currentProposals?.length === 0">
            <tidy-text
              [body]="('No ' | translate) + currentStatus + (' proposals' | translate)">
            </tidy-text>
          </tidy-alert>

          <tidy-card *ngFor="let proposal of currentProposals">
            <tidy-card-button (action)="goToProposalPage(proposal)">
              <tidy-grid>
                <tidy-row>
                  <ion-row>
                    <ion-col>
                      <tidy-row>
                        <tidy-text
                          [header]="proposal?.proposable?.team_service?.name | titlecase">
                        </tidy-text>
                      </tidy-row>
                      <tidy-row class="vertical-align-center">
                        <tidy-text
                          [body]="('Sent: ' | translate) +
                          (proposal?.created_at | tdate:'M/D')">
                        </tidy-text>
                      </tidy-row>
                      <tidy-row>
                        <tidy-text
                          [body]="'To: '">
                        </tidy-text>
                        <tidy-text
                          [body]="(proposal?.customer?.account_name !== '' && proposal?.customer?.account_name) ?
                          proposal?.customer?.account_name :
                          (proposal?.customer?.first_name + ' ' + (proposal?.customer?.last_name | abbrLastName: true))"
                          [translate]="false">
                        </tidy-text>
                      </tidy-row>
                      <tidy-row *ngIf="proposal?.customer?.email">
                        <tidy-text
                          [body]="'Email: '">
                        </tidy-text>
                        <tidy-text
                          [body]="proposal?.customer?.email"
                          [translate]="false">
                        </tidy-text>
                      </tidy-row>
                      <tidy-row>
                        <tidy-text
                          [body]="('Price: ' | translate) + (proposal?.proposable?.client_price | tcurrency)">
                        </tidy-text>
                      </tidy-row>
                    </ion-col>
                  </ion-row>
                </tidy-row>
              </tidy-grid>
            </tidy-card-button>
          </tidy-card>
        </ng-container>
      </ng-container>

      <tidy-card style="padding: 15px 15px 15px 10px" *ngIf="!certifications?.existing_clients || allProposals?.length == 0">
        <tidy-row [align]="'center'">
          <tidy-text
            [title]="'Win More Clients'">
          </tidy-text>
        </tidy-row>
        <tidy-row [align]="'center'" class="extra-bottom-padding">
          <tidy-text
            [body]="'Use TIDY proposals to get more Clients today. '">
          </tidy-text>
          <tidy-text
            (action)="learnMore()"
            [body]="'Learn More'"
            class="link">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <video id="introVideo" preload="true" playsinline="" autoplay="" loop="" muted="" src="https://d30thx9uw6scot.cloudfront.net/web-images/proposaloverview.mp4" poster="" style="max-width: 100%;" async></video>
        </tidy-row>
        <ng-container *ngFor="let card of noProposalsCards; let first = first; let last = last">
          <tidy-grid>
            <tidy-row [ngClass]="first ? 'extra-top-padding' : ''">
              <ion-row>
                <ion-col size="2" class="vertical-align-center">
                  <div style="margin: auto">
                    <tidy-image
                      [src]="card?.image"
                      style="width:30px">
                    </tidy-image>
                  </div>
                </ion-col>
                <ion-col>
                  <tidy-row>
                    <tidy-text
                      [body]="card?.text">
                    </tidy-text>
                  </tidy-row>
                </ion-col>
              </ion-row>
            </tidy-row>
          </tidy-grid>
          <tidy-row class="extra-top-padding extra-bottom-padding" *ngIf="!last">
            <tidy-divider></tidy-divider>
          </tidy-row>
        </ng-container>
      </tidy-card>

      <tidy-button
        *ngIf="certifications?.existing_clients"
        [action]="goToSendProposal.bind(this)"
        [text]="buttonText"
        class="primary">
      </tidy-button>

      <ng-container *ngIf="!certifications?.existing_clients">
        <tidy-alert>
          <tidy-row>
            <tidy-text
              [header]="'Finish Getting Started to Use This'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Finish the steps to manage existing clients to use proposals, it\'s quick and easy.'">
            </tidy-text>
          </tidy-row>
        </tidy-alert>

        <tidy-button
          [text]="'Finish Getting Started'"
          [action]="goToExistingClientsPage.bind(this)"
          class="primary">
        </tidy-button>
      </ng-container>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
