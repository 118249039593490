import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { VideoRecorderModalComponent } from "src/shared/components/video-recorder-modal/video-recorder-modal.component";
const VIDEO_LENGTH_LIMIT: number = 240000;

@Injectable({
  providedIn: 'root'
})
export class HtmlVideoRecorder {

  videoModal: HTMLIonModalElement;
  videoBlob: Blob;
  isRecording: boolean = false;
  mimeType: string;
  private stream: MediaStream;
  private videoElement: HTMLVideoElement;
  private mediaRecorder: any;
  private videoBlobs: Blob[];
  private videoUrl: string;

  constructor(
    private modalCtrl: ModalController
    ) {
  }

  async openVideoRecorderModal(): Promise<Blob> {
    return new Promise(async (resolve, reject) => {
      try {
        const modal = await this.modalCtrl.create({component: VideoRecorderModalComponent});
        this.videoModal = modal;
        modal.present();
        const { data } = await modal.onWillDismiss();
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async recordVideo(videoElement: HTMLVideoElement) {
    try {
      this.stream = await this.initVideoStream();
      videoElement.srcObject = this.stream;
      this.videoElement = videoElement;
      this.startVideoRecording(this.stream);
    } catch (error) {
      console.log('error on trying to record video:', error);
    }
  }

  initVideoStream(): Promise<MediaStream> {
    try {
      // INFO: Try to get the back camera
      return navigator.mediaDevices.getUserMedia({
        video: {
          width: 480,
          facingMode: 'environment'
        }
      });
    } catch (error) {
      // INFO: If that fails, try to get any available camera
      return navigator.mediaDevices.getUserMedia({
        video: {
          width: 480
        }
      });
    }
  }

  startVideoRecording(stream: MediaStream) {
    this.isRecording = true;
    this.videoBlobs = [];
    let options: any;
    if (MediaRecorder.isTypeSupported('video/webm')) {
      options = { mimeType: 'video/webm' };
    } else {
      options = { mimeType: 'video/mp4' };
    }
    this.mimeType = options.mimeType;
    this.mediaRecorder = new MediaRecorder(stream, options);
    this.mediaRecorder.start();
    this.onDataAvailable(this.mediaRecorder);
    this.onStop(this.mediaRecorder);

    setTimeout(() => {
      this.stopVideoRecording();
    }, VIDEO_LENGTH_LIMIT);
  }

  onDataAvailable(mediaRecorder: MediaRecorder) {
    mediaRecorder.ondataavailable = (e: any) => {
            if (e.data && e.data.size > 0) {
                this.videoBlobs.push(e.data);
            }
        };
  }

  onStop(mediaRecorder: MediaRecorder) {
    mediaRecorder.onstop = (e: Event) => {
        const videoBuffer = new Blob(this.videoBlobs, {
            type: 'video/webm'
        });
        this.videoBlob = videoBuffer;
        this.videoUrl = window.URL.createObjectURL(videoBuffer);
        this.videoElement.srcObject = null;
        this.videoElement.src = this.videoUrl;
    };
  }

  getVideoBlob(): Blob {
    const videoBuffer = new Blob(this.videoBlobs, {
      type: this.mimeType,
    });
    return videoBuffer;
  }

  stopVideoRecording() {
    this.isRecording = false;
    this.mediaRecorder?.stop();
    if (!this.videoBlob) {
      this.videoBlob = this.getVideoBlob();
    }
    this.stream.getVideoTracks().forEach((track: MediaStreamTrack) => track.stop());
  }
}
