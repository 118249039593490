import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { TidyStorage } from "src/shared/providers/tidy-storage";

import validationUtils from 'src/shared/utils/validation-utils';
import { CompleteJobModal } from 'src/shared/components/complete-job-modal/complete-job-modal';

@Component({
  templateUrl: 'shared-complete-to-do-list.html'
})

export class SharedCompleteToDoListPage {

  clientName: string;
  errorMessage: string;
  form: UntypedFormGroup;
  list: any;
  loaded: boolean;
  submitted: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private store: TidyStorage,
    private modalCtrl: ModalController
  ) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, validationUtils.validateEmail]],
      price: ['', Validators.required],
    });
  }

  async ionViewDidEnter() {
    this.loaded = false;
    this.clientName = this.navCtrl.getParam('clientName');
    this.list = this.navCtrl.getParam('list');
    this.loaded = true;
  }

  async completeList() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    const sharedListFormPayload = {
      name: this.form.value.name,
      email: this.form.value.email,
      amount: this.form.value.price*100,
    }
    await this.store.save('sharedListFormPayload', sharedListFormPayload)
    const modal = await this.modalCtrl.create({
      component: CompleteJobModal
    });
    modal.present();
  }

}
