import { Injectable } from '@angular/core';
import frequencies from 'src/shared/constants/frequencies';
import * as moment from 'moment';
import { TidyDurationPipe } from 'src/shared/pipes/tidy-duration';

@Injectable()
export class PrivateJobFieldsService {
  constructor(private tidyDurationPipe: TidyDurationPipe) { }

  mountCleaningsLength() {
    const startTime = 30;

    const cleaningsLength = Array.from({ length: 16 }, (_, index) => startTime + (index * startTime)).map((minutes) => {
      return {
        value: `${minutes}`,
        viewValue: this.tidyDurationPipe.transform(minutes)
      };
    });

    return cleaningsLength;
  }

  mountFrequencies() {
    const allFrequencies = frequencies;

    return Object.keys(allFrequencies)
      .filter(frequency => frequency !== 'twice_a_week' && frequency !== 'thrice_a_week')
      .map(frequency => ({value: frequency, viewValue: allFrequencies[frequency]}));
  }

  mountTimes() {
    const startHour = 5;

    return Array.from({ length: 37 }, (_, index) => {
      const momentDateTimeObject = moment({ hours: startHour }).add(index * 30, 'minutes');
      const hour12Format = momentDateTimeObject.format('h:mma');
      const isOneHourAndAHalfIncrement = index % 3 === 0;
      const isToAddSuggestedSuffix =
        (momentDateTimeObject.hours() >= 8 && momentDateTimeObject.hours() <= 21) && isOneHourAndAHalfIncrement;
      const suffix = isToAddSuggestedSuffix ? ' (suggested)' : '';

      const militaryHourFormat = momentDateTimeObject.format('H:mm');

      return { viewHour: hour12Format + suffix, valueHour: militaryHourFormat };
    }).map(({viewHour, valueHour}) => ({viewValue: viewHour, value: valueHour }));
  }

  setTomorrowDate() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    return tomorrow;
  }

  getSelectedStartTime(value: string) {
    const times = this.mountTimes();
    const returnedTime = times.find(date => date.value === value);

    return returnedTime.viewValue.replace(/[ (suggested)]/g, '');
  }

  mountTeamServiceItems(teamServiceTypes) {
    let array = [];
    teamServiceTypes.map((service) => {
      array.push({
        viewValue: service.name,
        value: service.key
      })
    });
    return array;
  }
}
