import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import * as moment from 'moment';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm';

import { PrivateJobModel } from 'src/shared/models/private-job.model';

@Component({
  selector: 'app-private-reschedule-ask-subject',
  templateUrl: './private-reschedule-ask-subject.html',
  styleUrls: ['./private-reschedule-ask-subject.scss'],
})
export class PrivateRescheduleAskSubjectPage {

  addressId: string;
  addressName: string;
  clientName: string;
  currentFrequency: string;
  currentDate: string;
  errorMessage: string;
  jobDateAndHour: string;
  jobId: string;
  jobData: PrivateJobModel;
  planId: string;
  rescheduleType: string;
  serviceTypeDetails: {
    description: string
    key: string 
    name: string
  };

  constructor(
    private customNavCtrl: CustomNavController,
    private modalCtrl: ModalController
  ) {
    const params = this.customNavCtrl.getParams();
    this.addressId = params.addressId;
    this.addressName = params.addressName;
    this.clientName = params.clientName;
    this.currentFrequency = params.currentFrequency;
    this.currentDate = params.currentDate;
    this.jobId = params.jobId;
    this.jobDateAndHour = params.jobDateAndHour;
    this.jobData = params.jobData as PrivateJobModel;
    this.planId = params.planId;
    this.rescheduleType = params.rescheduleType;
    this.serviceTypeDetails = params.serviceTypeDetails;
  }

  ionViewWillLeave() {
    this.modalCtrl.dismiss();
  }

  async clientConfirm() {
    if (this.checkLessThan24h()) {
      const params: ConfirmPageParamsModel = {
        title: 'Bill Cancellation Fee?',
        body: 'Do you want to bill them a cancellation fee per your settings?',
        backText: 'No',
        confirmText: 'Yes',
        confirmAction: this.billFeeConfirm.bind(this),
        customBackAction: this.noBillFeeConfirm.bind(this),
        sameRowButtons: false,
        backButtonClass: 'secondary',
        confirmButtonClass: 'secondary'
      };
      const clientAksModal = await this.modalCtrl.create({
        component: ConfirmPage,
        componentProps: params,
        animated: false
      });
      clientAksModal.present();
    } else {
      this.noBillFeeConfirm();
    }
  }

  checkLessThan24h(): boolean {
    const cleaningStartTime = `${this.jobData.date} ${this.jobData.start_time}`;
    const cleaningMoment = moment(cleaningStartTime, 'YYYY-MM-DD HH:mm');
    const diffHours = cleaningMoment.diff(moment(), 'hours');
    return diffHours < 24;
  }

  billFeeConfirm() {
    const params = this.buildPrivateRescheduleParams('customer', true);
    this.customNavCtrl.navigateForward('private-reschedule', params);
  }

  noBillFeeConfirm() {
    const params = this.buildPrivateRescheduleParams('customer', false);
    this.customNavCtrl.navigateForward('private-reschedule', params);
  }

  meConfirm() {
    const params = this.buildPrivateRescheduleParams('homekeeper', false);
    this.customNavCtrl.navigateForward('private-reschedule', params);
  }

  buildPrivateRescheduleParams(cancelledBy: string, withFee: boolean) {
    return {
      addressId: this.addressId,
      addressName: this.addressName,
      clientName: this.clientName,
      cancelledBy,
      currentFrequency: this.currentFrequency,
      currentDate: this.currentDate,
      jobDateAndHour: this.jobDateAndHour,
      jobId: this.jobId,
      planId: this.planId,
      rescheduleType: this.rescheduleType,
      serviceTypeDetails: this.serviceTypeDetails,
      amount: this.jobData.amount,
      withFee
    }
  }
}
