let loadingBarStackRef = (window as any).loadingBarStack;

function toggleLoadingBars(visibility: string) {
  const allLoadingBars = document.querySelectorAll('.js-tidy-loading') as NodeListOf<HTMLElement>;

  allLoadingBars.forEach((loadingBar) => {
    loadingBar.style.display = visibility;
  });
}

export function Loading({ timeout = 20, countdown = false} = {}) {
  return (target: object, propertyKey: string, descriptor: TypedPropertyDescriptor<any>) => {
    const originalMethod = descriptor.value;

    descriptor.value = function(...args: any[]) {
      toggleLoadingBars('block');

      loadingBarStackRef = loadingBarStackRef || [];
      loadingBarStackRef.push('');

      const result = originalMethod.apply(this, args);
      return buildResult(result, timeout);
    };

    return descriptor;
  };
}

const buildResult = (result, timeout): Promise<any> => {
  let timeoutId: number;

  return new Promise( resolve => {
    const event = new CustomEvent('open-loading-overlay');
    window.dispatchEvent(event);

    timeoutId = window.setTimeout(() => dismiss(), timeout * 1000);
    resolve(result);
  }).then((result) => {
    dismiss();
    return result;
  }).catch( (err) => {
    dismiss();
    throw err;
  }).finally(() => {
    clearTimeout(timeoutId);
  });
};

const dismiss = (): void => {
  const event = new CustomEvent('close-loading-overlay');
  window.dispatchEvent(event);

  loadingBarStackRef.pop();
  if (loadingBarStackRef.length === 0) {
    toggleLoadingBars('none');
  }
};

export const clearStack = (): void => {
  loadingBarStackRef = [];
  toggleLoadingBars('none');
}
