import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Device, DeviceId, DeviceInfo } from '@capacitor/device';
import { App, AppInfo } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { AppConfig } from './config';

enum CapacitorPlatforms {
  WEB = 'web',
  ANDROID = 'android',
  IOS = 'ios'
}

@Injectable()
export class DeviceInfoProvider {

  readonly
  constructor(
    private platform: Platform
  ) {}

  get(): string {
    if (this.platform.is('ios')) {
      return 'ios_app';
    }

    if (this.platform.is('android')) {
      return 'android_app';
    }

    return 'web_app';
  }

  isNativeMobile(): boolean {
    return Capacitor.isNativePlatform();
  }

  isMobileWeb(): boolean {
    const userAgent = navigator.userAgent;
    return /android|iPad|iPhone|iPod/i.test(userAgent);
  }

  isIosMobileWeb(): boolean {
    const userAgent = navigator.userAgent;
    return /iPad|iPhone|iPod/i.test(userAgent);
  }

  async uuid(): Promise<string> {
    const deviceUniqueIdentifier: DeviceId = await Device.getId()
    return deviceUniqueIdentifier?.identifier || 'WEB';
  }

  getDeviceInfo(): Promise<DeviceInfo> {
    return Device.getInfo();
  }

  async getAppVersion(): Promise<string> {
    if (Capacitor.getPlatform() === CapacitorPlatforms.WEB) {
      return AppConfig.VERSION;
    }

    const appInfo: AppInfo = await App.getInfo();
    return appInfo.build;
  }

}
