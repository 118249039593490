<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Please Check Email'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row>
      <tidy-text
        [body]="'When you signed up we sent an email to '">
      </tidy-text>
      <tidy-text
        [body]="email">
      </tidy-text>
      <tidy-text
        [body]="' from an @tidy.com email address to verify your email.'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'Please tap the link in that email to verify your email address.
        Check your junk or spam messages if you cannot find the email.'">
      </tidy-text>
    </tidy-row>

    <tidy-error-message
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-button
      [text]="'Resend Verification Email'"
      [action]="resend.bind(this)"
      class="secondary">
    </tidy-button>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
