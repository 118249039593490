
import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tidy-card-old',
  template:
    `
      <div class="tidy-box-card">
        <ng-content select="[item]"></ng-content>
      </div>
    `,
    styleUrls: ['tidy-card.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TidyCard { }
