import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import { TodosModel } from 'src/shared/models/job-details.model';
import { ExtractValueModel } from './extract-value.model';
import { FormattedCollectionModel } from './formatted-collection.model';

@Component({
  selector: 'nested-form',
  templateUrl: './nested-form.component.html',
  styles: [`
    tidy-card {
      margin: inherit;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NestedFormComponent implements OnInit {
  // TODO currently that component is only used on the private-job.html page
  // if you need it in other places there is some necessaries change to do here like
  // use angular projection to put your form fileds inside that component using
  // ng-content and prepare that component to accept that
  // Change models/types to be more generic
  // after refactors remember to check if private-job.html continues working fine

  @Input() private rawCollection: TodosModel[];
  formattedCollection: FormattedCollectionModel[];
  constructor() { }

  ngOnInit() {
    this.formattedCollection = this.formatRawCollection();
  }

  private formatRawCollection() {
    return this.rawCollection.reduce((acc, item) => {
      const tasks = item.tasks.reduce((acc, subItem) => {
        return [
          ...acc,
          new UntypedFormGroup({
            performed: new UntypedFormControl(false),
            title: new UntypedFormControl(subItem.title)
          })
        ]
      }, []);

      return [...acc, { tasks, title: item.name, category: item.category }]
    }, []);
  }

  /**
   * It is a method to return the nested collection from formattedCollection on the same array
   * ex: input value [{title: 'title', category: 'category', tasks: [FormGroup[FormControl][performed]]}]
   * output [{performed: false, title: 'a title'}];
   * you need add to your componet `@ViewChild(NestedFormComponent, {static: false}) nestedFormComponent :NestedFormComponent;`
   *
   * use:
   *
   * ```ts
   *  this.nestedFormComponent.extractedValuesFromFormGroup();
   * ```
   */
  public extractedValuesFromFormGroup() : ExtractValueModel[] {
    return this.formattedCollection.reduce((acc, item) => {
      const formValues = item.tasks.map((item) => {
        return item.value
      })

      return [...acc, ...formValues]
    }, [])
  }
}
