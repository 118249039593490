import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Loading } from 'src/shared/components/loading/loading';
import { Me } from 'src/shared/providers/me';
import { Reviews, ReviewData } from 'src/providers/reviews/reviews';

import { HomekeeperModel } from 'src/shared/models/homekeeper.model';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { Timeout } from 'src/shared/components/timeout/timeout';

@Component({
  selector: 'reviews',
  templateUrl: 'reviews.html',
})
export class ReviewsPage extends Timeout {
  errorMessage: string;
  form: UntypedFormGroup;
  hkData: HomekeeperModel;
  reviewsData: Array<ReviewData>;
  savingText = 'All Changes Saved.';

  constructor(
    private customNavCtrl: CustomNavController,
    private reviews: Reviews,
    private fb: UntypedFormBuilder,
    private me: Me,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.fb.group({
      data: ['', Validators.required],
      name: ['', Validators.required],
      detail: ['', Validators.required]
    });
  }

  @Loading()
  async ionViewDidEnter() {
    try {
      this.hkData = await this.me.fetchWithoutCache();
      this.reviewsData = await this.reviews.getReviews(this.hkData.main_team.id);
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  async changeVisibility(reviewId, value) {
    this.savingText = 'Saving Changes...';
    this.errorMessage = '';
    try {
      await this.reviews.changeReviewVisibility(this.hkData.main_team.id, reviewId, value);
      this.savingText = 'All Changes Saved.';
    } catch (err) {
      this.errorMessage = err.message || err.error.message;
      this.savingText = '';
    }
  }
}
