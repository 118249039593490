<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [customBack]="'more'"
    [title]="'Locations Served'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper *ngIf="loaded">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container>

      <tidy-card>
        <tidy-row>
          <tidy-text
            [header]="'Business Location'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="default-row-padding">
          <tidy-text
            [helper]="'The location you serve is based on your address plus your max estimated driving distance.'">
          </tidy-text>
        </tidy-row>

        <tidy-card-button (action)="goToProfileAddress()">
          <tidy-text
            [helper]="'Address'">
          </tidy-text><br>
          <tidy-text
            [body]="profileData?.address"
            [translate]="false">
          </tidy-text>
        </tidy-card-button>
        <tidy-card-button (action)="goToProfileTravel()">
          <tidy-text
            [helper]="profileData?.driveTime > 10 ? 'Max Drive Time' : 'Max Walk Time'">
          </tidy-text><br>
          <tidy-text
            [body]="profileData?.driveTime + (' Minutes' | translate)">
          </tidy-text>
        </tidy-card-button>
        <tidy-row class="extra-top-padding" *ngIf="profileData?.driveTime < 10">
          <tidy-text
            [helper]="('You noted that you will travel by public transit to the above address and that you can walk ' | translate) + profileData?.driveTime + (' minutes from that address.' | translate)">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <tidy-text
            [header]="'Block Zipcodes'">
          </tidy-text>
        </tidy-row>
        <tidy-row [ngClass]="'extra-bottom-padding'">
          <tidy-text
            [body]="'Add a zipcode to prevent jobs in that zipcode.'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let zip of blockedZipcodes">
          <tidy-card-button (action)="confirmDeleteZipcode(zip?.id, zip?.zipcode)" [hideArrow]="true"
          [customIcon]="'assets/icon/close-circle-grey.svg'">
            <tidy-row>
              <tidy-text
                [body]="zip?.zipcode">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </ng-container>
        <ng-container *ngIf="blockedZipcodes.length == 0">
          <tidy-text
          [helper]="'No zipcodes blocked.'">
          </tidy-text>
        </ng-container >
        <tidy-row class="extra-top-padding">
          <tidy-text
            (action)="goToBlockZipcode()"
            [body]="'Block New Zipcode'"
            class="link">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <tidy-text
            [header]="'Approximate Area You Serve'"
          >
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'Note this map is not 100% accurate, but gives a general area.'"
          >
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <div id="location-i-serve-map" style="width: 100%; height: 300px">
          </div>
        </tidy-row>
      </tidy-card>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
