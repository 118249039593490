<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="pageTitle"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-card>
        <tidy-row>
          <ion-row>
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="job?.job?.service_type_details?.service_category?.icon_url"
                style="width:30px;">
              </tidy-image>
            </tidy-row>
            <ion-col>
              <tidy-row>
                <tidy-text
                  [header]="job?.job?.service_type_details?.name | titlecase">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="(date | tdate:'dddd M/D/YY')">
                </tidy-text>
                <tidy-text
                  [body]="' at '">
                </tidy-text>
                <tidy-text
                  [body]="(startTime | ttime)">
                </tidy-text>
              </tidy-row>
            </ion-col>
          </ion-row>
        </tidy-row>
        <tidy-row class="extra-top-padding extra-bottom-padding">
          <tidy-divider>
          </tidy-divider>
        </tidy-row>
        <tidy-row class="field-top-padding">
          <form [formGroup]="form">
            <tidy-input
              formControlName="price"
              [label]="formLabel"
              [form]="form.controls.price"
              [icon]="'assets/svg/cash-outline.svg'"
              [errorMessage]="formErrorMessage"
              [submitted]="submitted"
              inputMode="numeric"
              [mask]="'currencyMask'">
            </tidy-input>
          </form>
        </tidy-row>
      </tidy-card>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-button
        [text]="'Save'"
        [action]="save.bind(this)"
        class="primary">
      </tidy-button>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
