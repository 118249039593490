<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [closeButton]="true"
    [title]="null"
    [showLinkIcon]="true"
    [linkIcon]="'call'"
    (linkAction)="goToContactPage()"
    (closeItemAction)="goToExitJobPage()"
    [countdownTarget]="job?.showTimer ? jobTimer : null"
    [title]="job?.showTimer ? null : 'To-Dos'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-alert *ngIf="job?.other_pro">
        <tidy-row class="vertical-align-center" class="extra-bottom-padding">
          <tidy-image
            [src]="'assets/svg/person-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="('You will be cleaning with ' | translate) + job?.other_pro">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'This is the client\'s full To-Dos list.  You may want to speak with the other pro about dividing this up.'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <tidy-row class="vertical-align-center">
       <tidy-image
          [src]="'assets/svg/lock-open.svg'"
          class="header-size">
        </tidy-image>
        <tidy-text
          [title]="'Entering the Property'">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <tidy-row class="vertical-align-center">
          <tidy-text
            [header]="'Access Notes'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <ng-container *ngIf="job?.remoteAccessPin">
            <tidy-text
              [body]="('Lockbox code: ' | translate) + job?.remoteAccessPin + (', Notes:' | translate)">
            </tidy-text>&nbsp;
          </ng-container>
          <tidy-text
            *ngIf="job?.access"
            [body]="job?.access">
          </tidy-text>
          <tidy-text
            *ngIf="!job?.access && !job?.remoteAccessPin"
            [helper]="'<i>No Access Notes</i>'">
          </tidy-text>
        </tidy-row>
        <tidy-carrousel *ngIf="job?.addressPhotosAccess?.length > 0">
          <ng-container *ngFor="let photo of job?.addressPhotosAccess">
            <img *tidyCarrousel [src]="photosCacheData[photo?.id?.toString()]">
          </ng-container>
        </tidy-carrousel>
      </tidy-card>

      <tidy-card *ngIf="job?.introVideo">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/videocam.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Intro Video'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <video width="100%" height="100%" controls>
            <source [src]="job?.introVideo">
          </video>
        </tidy-row>
      </tidy-card>

      <ng-container *ngIf="job?.bookingNote?.length || job?.bookingFormAnswers?.length > 0">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/information.svg'"
            class="title-size">
          </tidy-image>
          <tidy-text
            [title]="'Job Note'">
          </tidy-text>
        </tidy-row>
        <tidy-card>
          <tidy-row>
            <tidy-text
              *ngIf="job?.bookingNote[0]?.text"
              [body]="job?.bookingNote[0]?.text">
            </tidy-text>
          </tidy-row>
          <ng-container *ngFor="let answer of job?.bookingFormAnswers">
            <ng-container *ngIf="answer?.template_input?.type == 'text' || answer?.template_input?.type == 'text_area'">
              <tidy-row>
                <tidy-text
                  [body]="answer?.template_input?.label + ': ' + answer?.data?.value">
                </tidy-text>
              </tidy-row>
              <tidy-photo-note
                *ngIf="answer?.template_input?.image_url"
                [src]="answer?.template_input?.image_url">
              </tidy-photo-note>
            </ng-container>
            <ng-container *ngIf="answer?.template_input?.type == 'select'">
              <tidy-row>
                <tidy-text
                  [body]="answer?.template_input?.label + ': ' + me.getBookingFormSelectValue(answer?.data?.value)">
                </tidy-text>
              </tidy-row>
              <tidy-photo-note
                *ngIf="answer?.template_input?.image_url"
                [src]="answer?.template_input?.image_url">
              </tidy-photo-note>
            </ng-container>
            <ng-container *ngIf="answer?.template_input?.type == 'multiselect'">
              <tidy-row>
                <tidy-text
                  [body]="answer?.template_input?.label + ': ' + me.getBookingFormMultiselectValue(answer?.data?.value)">
                </tidy-text>
              </tidy-row>
              <tidy-photo-note
                *ngIf="answer?.template_input?.image_url"
                [src]="answer?.template_input?.image_url">
              </tidy-photo-note>
            </ng-container>
          </ng-container>
        </tidy-card>
      </ng-container>

      <tidy-alert *ngIf="!job?.jobHasToDoList || toDos?.allRoomsAreEmpty">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/close-circle.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'To-Dos Off'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="job?.client +
            ' ' +
            (job?.last_name | abbrLastName: true)"
            [translate]="false">
          </tidy-text>
          <tidy-text
            [body]="' wants you to follow any verbal or written instructions and use your best judgement.'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <tidy-to-dos
        [page]="'MWToDosPage'"
        [rooms]="toDos?.rooms"
        [mwJob]="job">
      </tidy-to-dos>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
