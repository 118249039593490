<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [backButton]="true"
    [title]="'Export Calendar'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

        <tidy-card>
          <tidy-row>
            <tidy-row class="extra-bottom-padding">
              <tidy-text
                [body]="'By tapping this button, your default calendar app (like Google Calendar) should ask you if you want to get jobs to show on your calendar.'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-button
                [action]="learnMore.bind(this)"
                [text]="'Export Jobs to Calendar'"
                class="primary">
              </tidy-button>
            </tidy-row>
          </tidy-row>
          <tidy-row class="extra-top-padding extra-bottom-padding"><tidy-divider></tidy-divider></tidy-row>
          <tidy-row>
            <tidy-row class="extra-bottom-padding">
              <tidy-text
                [body]="'Not working? Copy and paste this ical link following your calendar app\'s instructions.'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="extra-bottom-padding">
              <tidy-text
                [body]="calendarUrl">
              </tidy-text>
              <tidy-text
                [body]="'Copy Calendar Link'"
                (action)="copyLink()"
                class="link">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="copied">
              <tidy-text
                [helper]="'Copied'">
              </tidy-text>
            </tidy-row>
          </tidy-row>
        </tidy-card>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
