import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { BankInfo } from 'src/providers/bank-info/bank-info';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Loading } from 'src/shared/components/loading/loading';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { Timeout } from 'src/shared/components/timeout/timeout';

@Component({
  selector: 'payout-settings-edit',
  templateUrl: './payout-settings-edit.html',
})
export class PayoutSettingsEditPage extends Timeout {

  form: UntypedFormGroup;
  errorMessage: string;
  submitted = false;
  payoutSettings: any;

  constructor(
    private fb: UntypedFormBuilder,
    private bankInfo: BankInfo,
    private navCtrl: CustomNavController,
    private iabUtils: InAppBrowserUtils,
    router: Router,
    route: ActivatedRoute,
    public timeoutErrorHandler: TimeoutErrorHandler
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.fb.group({
      payoutSetting: ['']
    });
  }

  @Loading()
  async ionViewDidEnter(): Promise<any> {
    this.errorMessage = '';
    try {
      this.loaded = false;
      this.payoutSettings = this.buildPayoutSettings();
      const currentPayoutSetting = this.navCtrl.getParam('currentPayoutSetting');
      if (currentPayoutSetting) {
        this.form.patchValue({payoutSetting: currentPayoutSetting.value});
      }
    } catch (err) {
      if (err && err.code === 404) {
        this.errorMessage = '';
        return;
      }
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.timeoutHandler(err);
    } finally {
      this.loaded = true;
    }
  }

  buildPayoutSettings() {
    return [
      {
        viewValue: 'Direct deposit (most common)',
        value: 'direct_deposit',
        description: 'This is typically the fastest, with no fees.'
      },
      {
        viewValue: 'Credit card',
        value: 'credit_card',
        description: 'If you accept credit card, we can provide you with a one time use card to charge customers. This typically incurs fees by your credit card processor.'
      },
      {
        viewValue: 'Check',
        value: 'check',
        description: 'This is typically the slowest, with no fees.'
      }
    ]
  }

  changePayoutSetting(setting) {
    this.form.patchValue({ payoutSetting: setting.value });
  }

  async selectPayoutSetting() {
    let params = {
      payoutSetting: this.form.value.payoutSetting
    }
    try {
      if (this.form.value.payoutSetting == 'credit_card') {
        const payload = {
          key: 'payout_setting',
          value: 'credit_card'
        };
        await this.bankInfo.updatePayoutSetting(payload);
        localStorage.setItem('payoutSetting', 'credit_card');
        this.navCtrl.navigateForward('payout-settings');
      } else if (this.form.value.payoutSetting == 'check') {
        this.navCtrl.navigateForward('payout-settings-detail', params);
      } else {
        //This will return an error if user hasn't added a bank account:
        const stripeStatus = await this.bankInfo.getStripeStatus();
        const payload = {
          key: 'payout_setting',
          value: 'direct_deposit'
        };
        await this.bankInfo.updatePayoutSetting(payload);
        localStorage.setItem('payoutSetting', 'direct_deposit');
        this.navCtrl.navigateForward('payout-settings');
      }
    } catch (err) {
      if (err.message == "ActiveRecord::RecordNotFound") {
        params['alreadyAddedBank'] = false;
        return this.navCtrl.navigateForward('payout-settings-detail', params);
      }
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.timeoutHandler(err);
    }
  }

  async editStripeInfo() {
    try {
      const response = await this.bankInfo.getStripeLink();
      const url = response.connect_onboard_account_link;
      this.iabUtils.openUrl(url);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  learnMoreTaxes() {
    this.iabUtils.openUrl('https://help.tidy.com/pros/taxes-and-1099');
  }

}
