<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="title">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'" *ngIf="correctPerformanceViewValue !== null && correctPerformanceViewValue !== undefined">
      <tidy-text
        [body]="'You marked that the correct status is '">
      </tidy-text>
      <tidy-text
        [body]="correctPerformanceViewValue">
      </tidy-text>
      <tidy-text
        [body]="' Please let us know any other detail about the issue.'">
      </tidy-text>
    </tidy-row>

    <tidy-row [align]="'center'" *ngIf="metadata?.subtype === 'safety_issue'">
      <tidy-text
        [body]="'<b>If you are in immediate danger please dial 911.</b>'">
      </tidy-text>
    </tidy-row>

    <tidy-row [align]="'center'" *ngIf="metadata?.payment_title !== null && metadata?.payment_title !== undefined">
      <tidy-text
        [header]="metadata?.payment_title">
      </tidy-text>
    </tidy-row>

    <tidy-row [align]="'center'">
      <tidy-text
        *ngIf="type === 'support.homekeeper_other'"
        [body]="otherText">
      </tidy-text>
      <tidy-text
        [body]="'We expect to reply within '">
      </tidy-text>
      <tidy-text
        [body]="replyTime">
      </tidy-text>
      <tidy-text
        [body]="'. We will reply both in the app and to your email '">
      </tidy-text>
      <tidy-text
        [body]="email + '.'">
      </tidy-text>
    </tidy-row>

    <tidy-row [align]="'center'" *ngIf="type === 'support.homekeeper_private_client' || type === 'support.homekeeper_team'">
      <tidy-text
        [body]="'Want to talk with our Concierge?'">
      </tidy-text><br>
      <tidy-text
        [actionBody]="'Schedule a Support Call'"
        (action)="goToScheduleCallPage()"
        class="link">
      </tidy-text>
    </tidy-row>

    <tidy-card>
      <form [formGroup]="form">
        <tidy-row class="field-top-padding field-bottom-padding">
          <tidy-input
            [label]="'Subject'"
            [icon]="'assets/svg/mail-outline.svg'"
            formControlName="subject"
            [form]="form.controls.subject"
            [submitted]="submitted"
            [errorMessage]="'Please enter your first name.'">
          </tidy-input>
         </tidy-row>
         <tidy-row class="field-bottom-padding">
          <tidy-textarea
            [label]="'Message'"
            formControlName="message"
            [form]="form.controls.message"
            [submitted]="submitted"
            [icon]="'assets/svg/information-circle-outline.svg'"
            [errorMessage]="'Please enter a message'">
          </tidy-textarea>
        </tidy-row>
        <tidy-row *ngIf="addingEmailCC">
          <tidy-input
            [label]="'Email CC'"
            [icon]="'assets/svg/person-outline.svg'"
            formControlName="email"
            [form]="form.controls.email"
            [submitted]="submitted"
            [errorMessage]="'Please enter a valid email'">
          </tidy-input>
       </tidy-row>
      </form>
      <tidy-row *ngIf="!addingEmailCC">
       <tidy-text
         [actionBody]="'Add Email CC'"
         (action)="addEmailCC()"
         class="link">
       </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [actionBody]="'Add Attachment'"
          (action)="addAttachment()"
          class="link">
        </tidy-text>
      </tidy-row>
      <tidy-row *ngIf="attachments.length" class="field-top-padding">
        <tidy-row>
          <tidy-text
            [body]="'Attachments:'"
          >
          </tidy-text>
        </tidy-row>
        <tidy-two-column-row *ngFor="let attachment of attachments; let i = index">
          <tidy-text
            [body]="attachment.filename"
            [translate]="false">
          </tidy-text>
          <tidy-text
            [actionBody]="'remove'"
            (action)="removeAttachment(i)"
            class="link red">
          </tidy-text>
        </tidy-two-column-row>
      </tidy-row>
    </tidy-card>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-button
      [text]="'Send to Concierge'"
      [action]="send.bind(this)"
      class="primary">
    </tidy-button>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
