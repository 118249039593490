import { Injectable } from '@angular/core';
import { HttpClient } from '../../shared/providers/http/http-client';
import { LostItemParamsModel } from '../../shared/models/lost-item-interface.model';

@Injectable()
export class LostItem {

  constructor(
    private httpClient: HttpClient
  ) {}

  async initiateLostItem(params: LostItemParamsModel): Promise<any> {
    const url = `api/v1/homekeeper/cleanings/${params.jobId}/lost-item`;
    const data = {
      description: params.description,
      homekeeper_job_id: params.hkJobId,
      job_id: params.jobId,
    };
    return this.httpClient.put(url, data);
  }
}
