<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Review Terms'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card>
      <tidy-row>
        <tidy-text
          [header]="'Please Review Terms of Use'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'You previously agreed to these terms. Please certify that you have read and agree to these terms, which include representing you have a bona-fide business and agreeing to arbitrate disputes on an individual and not class basis: '">
        </tidy-text>
        <tidy-text
          (action)="goToTerms()"
          [body]="'Terms of Use'"
          class="link">
        </tidy-text>
      </tidy-row>
    </tidy-card>

    <tidy-error-message
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-button
      [text]="'I Still Agree To The Terms'"
      [action]="confirm.bind(this)"
      class="primary">
    </tidy-button>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
