import { Component, ViewEncapsulation } from '@angular/core';
import { ActiveInCommunity } from '../../../providers/active-in-community/active-in-community';
import { Me } from '../../../shared/providers/me';
import { Loading } from '../../../shared/components/loading/loading';
import { InAppBrowserUtils } from '../../../shared/utils/in-app-browser-utils';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SuccessPageParamsModel } from 'src/pages/success/success';
import { Location } from '@angular/common';
import { HomekeeperModel } from 'src/shared/models/homekeeper.model';

import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { Timeout } from 'src/shared/components/timeout/timeout';

@Component({
  selector: 'add-community-review',
  templateUrl: 'add-community-review.html',
  encapsulation: ViewEncapsulation.None
})
export class AddCommunityReviewPage extends Timeout {
  hkId: any;
  destination: any;
  contentCommunity: any;
  errorMessage: any;
  form: UntypedFormGroup;
  submitted: boolean;
  checked: boolean;

  constructor(
    private activeInComm: ActiveInCommunity,
    private iabUtils: InAppBrowserUtils,
    private me: Me,
    private customNavCtrl: CustomNavController,
    public route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.fb.group({
      post: ['', Validators.required]
    });

  }

  @Loading()
  async ionViewDidEnter() {
    try {
      this.destination = this.route.snapshot.paramMap.get('destination');
      const me: HomekeeperModel = await this.me.load();
      this.hkId = me.user.id;
      this.contentCommunity = this.activeInComm.getDestinationContent(this.destination);
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  async confirm() {
    this.errorMessage = '';
    const data = {
      hkId: this.hkId,
      destination: this.destination,
      text: this.form.value.post
    };
    await this.activeInComm.saveCommunityReview(data);
    const params = this.getSuccesPageParams();
    this.customNavCtrl.navigateRoot('success', params);
  }

  submit = async () => {
    if (!this.checked) {
      this.errorMessage = 'Please confirm the checkbox to continue.';
      return;
    }
    this.errorMessage = '';
    this.submitted = true;
    try {
      if (this.form.value.post) {
        await this.confirm();
      }
    } catch (err) {
      this.errorMessage = err.message || err.error.message;
    }
  }

  getSuccesPageParams(): SuccessPageParamsModel {
    return {
      header: 'Post Submitted',
      body: 'Your post has been submitted successfully and is now pending review. Thank you for your contribution!',
      buttonText: 'Ok',
      buttonRoute: 'dashboard'
    };
  }

  openPostHere = () => {
    this.iabUtils.openUrl(this.contentCommunity.postHere);
  }
}
