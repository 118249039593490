import { Injectable } from '@angular/core';
import { HttpClient } from './http/http-client';

import { Me } from './me';

@Injectable()
export class ServerData {

  performances: Promise<any[]>;

  constructor(
    private httpClient: HttpClient,
    private me: Me
  ) {}

  cancelReasons(isCallOut: boolean = false): Promise<any[]> {
    const url = 'api/v1/homekeeper/cancellation-reasons' + (isCallOut ? '?for_call_out=true' : '');
    return this.httpClient.get(url);
  }

  performanceStatus(): Promise<any[]> {
    if (!this.performances) {
      this.performances = this.httpClient.get('api/v1/performances');
    }

    return this.performances;
  }

  async getCleaningTypes(): Promise<any> {
    return [
      {
        type: "tidy",
        name: "1 hour cleaning",
        duration: 60,
        number_of_homekeepers: 1,
        start_times: [
          "08:00",
          "09:30",
          "11:00",
          "12:30",
          "14:00",
          "15:30",
          "17:00",
          "18:30",
          "20:00",
          "21:30"
        ],
        price: 4500,
        otc_price: 5000,
        sdc_price: 6800
      },
      {
        type: "tidy_xl",
        name: "4 hour cleaning",
        duration: 240,
        number_of_homekeepers: 1,
        start_times: [
          "08:00",
          "09:30",
          "11:00",
          "12:30",
          "14:00",
          "15:30",
          "17:00",
          "18:30"
        ],
        price: 13500,
        otc_price: 14900,
        sdc_price: 20300
      },
      {
        type: "plus",
        name: "2.5 hour cleaning",
        duration: 150,
        number_of_homekeepers: 1,
        start_times: [
          "08:00",
          "09:30",
          "11:00",
          "12:30",
          "14:00",
          "15:30",
          "17:00",
          "18:30",
          "20:00"
        ],
        price: 9000,
        otc_price: 9900,
        sdc_price: 13500
      },
      {
        type: "half_mighty",
        name: "4 hour cleaning",
        duration: 240,
        number_of_homekeepers: 1,
        start_times: [
          "08:00",
          "09:30",
          "11:00",
          "12:30",
          "14:00",
          "15:30",
          "17:00",
          "18:30"
        ],
        price: 12500,
        otc_price: 13800,
        sdc_price: 18800
      },
      {
        type: "turnover_tidy",
        name: "1 hour cleaning",
        duration: 60,
        number_of_homekeepers: 2,
        start_times: [
          "08:00",
          "09:30",
          "11:00",
          "12:30",
          "14:00",
          "15:30",
          "17:00",
          "18:30",
          "20:00",
          "21:30"
        ],
        price: 9000,
        otc_price: 9900,
        sdc_price: 13500
      },
      {
        type: "turnover_plus",
        name: "2.5 hour cleaning",
        duration: 150,
        number_of_homekeepers: 2,
        start_times: [
          "08:00",
          "09:30",
          "11:00",
          "12:30",
          "14:00",
          "15:30",
          "17:00",
          "18:30",
          "20:00"
        ],
        price: 18000,
        otc_price: 19800,
        sdc_price: 27000
      },
      {
        type: "mighty",
        name: "4 hour cleaning",
        duration: 240,
        number_of_homekeepers: 2,
        start_times: [
          "08:00",
          "09:30",
          "11:00",
          "12:30",
          "14:00",
          "15:30",
          "17:00",
          "18:30"
        ],
        price: 25000,
        otc_price: 27500,
        sdc_price: 37500
      }
    ];
  }

}
