import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { MWStore } from "src/main-workflow/mw.store";
import { MWService } from "src/main-workflow/mw.service";
import { mwMoments } from 'src/main-workflow/mw.moments';

import { Me } from 'src/shared/providers/me';
import { Communication } from 'src/providers/communication/communication';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

import { Loading } from 'src/shared/components/loading/loading';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Component({
  templateUrl: 'job-messages.html'
})
export class JobMessagesPage extends Timeout {

  card: any;
  categoryImage: string;
  errorMessage: string;
  form: UntypedFormGroup;
  job: any;
  messages: any;
  messagesLoaded: boolean;
  proName: string;
  phoneNumber: string;
  sourceRoute: string;
  submitted: boolean;
  timezone: any;

  constructor(
    public communication: Communication,
    private fb: UntypedFormBuilder,
    private me: Me,
    private mwStore: MWStore,
    private mwService: MWService,
    private navCtrl: CustomNavController,
    public route: ActivatedRoute,
    router: Router,
    timeoutErrorHandler: TimeoutErrorHandler,
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.fb.group({
      message: ['', Validators.required],
    });
  }

  @Loading()
  async ionViewDidEnter() {
    try {
      this.loaded = false;
      this.messagesLoaded = false;
      this.phoneNumber = await this.mwStore.getPhoneNumber();
      this.sourceRoute = await this.mwStore.getRoute();
      this.job = await this.mwStore.getJob();
      const me = await this.me.load();
      this.timezone = me.profile.address.timezone_name;
      this.proName = me.profile.name;
      if (!this.sourceRoute) {
        this.sourceRoute = this.mwService.isTooEarlyForJob(this.job.startTime) ? 'not-ready-to-leave' : 'ready-to-leave';
      }
      this.loaded = true;
      this.messages = await this.communication.getSharedInboxForHomekeeperJob(this.job.homekeeper_job_id);
      await this.markMessagesAsRead();
      this.messagesLoaded = true;
    } catch (err) {
      this.timeoutHandler(err);
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async markMessagesAsRead() {
    let array: any = [];
    this.messages.map((message) => {
      if (!message.is_read && message.from_member.type == 'CustomerMember' && message?.chat_room_key) {
        array.push((message?.chat_room_key));
      }
    });
    if (array.length > 0) {
      await this.communication.markMessagesAsRead(array);
    }
  }

  async contactClient(type) {
    if (this.job.isStandby && this.mwService.isTooEarlyForJobStandby(this.job.startTime)) {
      this.showErrorMessage('You are on standby for this job. You can call/text the Client after indicating you arrived.');
      return await this.mwService.sendMomentToBackend(mwMoments.tooEarlyCallText, this.sourceRoute, this.job.id);
    }
    if (this.mwService.isTooEarlyForJob(this.job.startTime)) {
      this.showErrorMessage('It is too early to contact the Client.  Please wait until 85 minutes before the job.');
      return await this.mwService.sendMomentToBackend(mwMoments.tooEarlyCallText, this.sourceRoute, this.job.id);
    }
    if (type == 'call') {
      this.call();
    } else if (type == 'message') {
      this.message();
    } else if (type == 'attachment') {
      this.attachment();
    }
  }

  async call() {
    try {
      await this.mwService.startCommunication(this.job);
    } catch (err) {
      const error = await this.mwService.buildErrorAlert(err);
      return this.showErrorMessage(error);
    }
    window.location.href = `tel:${this.job.gateway_phone}`;
    this.saveMoment(mwMoments.calledClient);
  }

  @Loading()
  async message(attachment = []) {
    this.errorMessage = '';
    this.submitted = true;
    if (attachment.length == 0 && !this.form.get('message').valid) {
      return;
    }
    const payload = {
      chat: {
        type: 'homekeeper_job',
        data: {
          homekeeper_job_id: this.job.homekeeper_job_id
        }
      },
      message: {
        text: this.form.value.message,
        files: attachment
      }
    };
    try {
      await this.mwService.startCommunication(this.job);
      const response = await this.communication.sendSharedInboxMessage(payload);
      if (response?.data?.message == 'We couldn\'t send your message, please try again later!') {
        return this.errorMessage = response.data.message;
      }
      this.form.patchValue({
        message: ''
      });
      this.saveMoment(mwMoments.textedClient);
      this.messages = await this.communication.getSharedInboxForHomekeeperJob(this.job.homekeeper_job_id);
      this.submitted = false;
    } catch (err) {
      const error = await this.mwService.buildErrorAlert(err);
      return this.showErrorMessage(error);
    }
  }

  async attachment() {
    this.errorMessage = '';
    try {
      const attachment = await this.communication.addAttachment();
      if (attachment == '') {
        return this.errorMessage = 'Unable to attach photo. Please upload a PNG or JPEG file.';
      }
      await this.message([attachment]);
    } catch (err) {
      this.errorMessage =  err.error ? err.error.message : err.message;
    }
  }

  async saveMoment(moment) {
    const syncData = await this.mwStore.getSyncData();
    if (syncData === null || syncData === undefined) {
      await this.mwService.sendMomentToBackend(moment, this.sourceRoute, this.job.id);
    } else {
      await this.mwService.addMomentToSyncData(moment, this.sourceRoute, this.job.id);
    }
    const cameFromNoAccessPage = this.navCtrl.getParam('cameFromNoAccessPage');
    if (cameFromNoAccessPage) {
      this.mwStore.setCalledTexted(true);
    }
  }

  showErrorMessage(message) {
    this.errorMessage = message;
    setTimeout(() => this.errorMessage = null, 10000);
  }

}
