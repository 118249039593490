
  <ion-header>
    <ion-toolbar>
      <tidy-wrapper>
        <img
          decoding="async"
          class="bowtie"
          [src]="'/assets/style-guide-images/bowtie-white.svg'"
        />

        <br>

        <tidy-text title="{{ userName$ | async }}"></tidy-text>

        <br>

        <tidy-text body="{{ teamName$ | async }}"></tidy-text>

        <tidy-card-button
          [hideArrow]="true"
          *ngFor="let item of menuItems; let i = index"
          class="vertical-align-center"
          [class.current-item]="router.url === item.target"
          [class.first-element]="i === 0"
          (click)="goToLink(item.target)"
          button
        >

          <tidy-image
            class="title-size"
            [src]="(router.url === item.target ? item.activeIcon : item.icon)"
            slot="start"
            role="img"
            [attr.aria-label]="item.label"
          ></tidy-image>

          <tidy-text class="page-title" helper="{{item.label}}"></tidy-text>
        </tidy-card-button>
      </tidy-wrapper>
    </ion-toolbar>
  </ion-header>
