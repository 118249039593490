import { Injectable } from '@angular/core';
import { HttpClient } from 'src/shared/providers/http/http-client';

import { UpdateModel } from 'src/shared/models/update.model';

@Injectable()
export class Updates {

  constructor(
    private httpClient: HttpClient
  ) {}

  getUpdates(): Promise<UpdateModel[]> {
    const url = 'api/v1/homekeeper/updates';
    return this.httpClient.get(url);
  }

  getUpdatePage(updateType, updateData)  {
    const pages = {
      'constructive_feedback': `reviews`,
      'disputed_initiated': `dispute-resolution/${updateData.concierge_item_id}`,
      'equipment_not_certified': `equipment`,
      'equipment_certified': `background-check`,
      'quest_eligible': `quests`,
      'quest_completed': `quests`,
      'hk_received_positive_feedback' : 'reviews',
      'payment_transfer_failed_to_homekeeper': 'payout-settings',
      'payment_transfer_failed_and_bank_update_is_needed': 'payout-settings',
      'job_delegation_requested': 'accept-reject-job',
      'proposal_was_accepted_by_the_customer': `proposal/${updateData.proposal_id}`,
      'job_delegation_accepted': '',
      'completed_second_cleaning_same_customer': '',
      'more_five_jobs_streak': '',
      'transfer_was_sent_to_hk': 'payouts',
      'availability_request_initiated_by_customer': 'add',
      'approved_for_credit_cards': 'https://help.tidy.com/pros/accepting-credit-cards',
    };
    return pages[updateType];
  }

  trackUpdateAction(homekeeperUpdateId) {
    const data = {
      action_type: 'click'
    }
    const url = `api/v1/homekeeper/updates/${homekeeperUpdateId}/track`;
    return this.httpClient.post(url, data);
  }

  dismissUpdate(homekeeperUpdateId) {
    const url = `api/v1/homekeeper/updates/${homekeeperUpdateId}/dismiss`;
    return this.httpClient.put(url, {});
  }
}
