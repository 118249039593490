import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Me } from 'src/shared/providers/me';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Component({
  templateUrl: 'edit-language.html'
})

export class EditLanguagePage implements OnInit {

  languageItems: any;
  form: UntypedFormGroup;
  loaded: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private me: Me
  ) {
    this.form = this.fb.group({
      language: [''],
    });
  }

  async ngOnInit() {
    this.loaded = false;
    this.languageItems = this.getLanguageItems();
    this.form.patchValue({
      language: localStorage.getItem('language') || 'en'
    });
    this.loaded = true;
  }

  getLanguageItems() {
    return [
      {
        value: 'en',
        viewValue: 'English'
      },
      {
        value: 'pt',
        viewValue: 'Portuguese'
      },
      {
        value: 'es',
        viewValue: 'Spanish'
      },
      {
        value: 'ru',
        viewValue: 'Russian'
      },
      {
        value: 'fr',
        viewValue: 'French'
      },
      {
        value: 'de',
        viewValue: 'Dutch'
      }
    ]
  }

  async save() {
    await this.me.addMetadata({ key: 'language', value: this.form.value.language });
    localStorage.setItem('didUpdateLanguage', 'true');
    localStorage.setItem('language', this.form.value.language);
    this.navCtrl.navigateForward('more');
  }

}
