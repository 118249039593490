import { Injectable } from '@angular/core';
import { HttpClient } from 'src/shared/providers/http/http-client';

@Injectable()
export class Dashboard {

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  getHighlights() {
    const url = 'api/v1/homekeeper/dashboard/highlights?_ping=highlights';
    return this.httpClient.get(url);
  }

  getEstimatedNextPayment() {
    const url = 'api/v1/homekeeper/payments/estimated-future-payment-details';
    return this.httpClient.get(url);
  }

  getPaymentBalance() {
    const url = 'api/v1/homekeeper/payments/pending-balance';
    return this.httpClient.get(url);
  }

  getNextPaymentSnapshot() {
    const url = 'api/v1/homekeeper/payments/estimated-payment-snapshot';
    return this.httpClient.get(url);
  }

  scoreBar(score) {
    const scoreArray = [
      {
        begin: 0,
        end: 10,
        color: '#EB5757',
        width : '10%',
      },
      {
        begin: 10,
        end: 33,
        color: '#EB5757',
        width : score + '%',
      },
      {
        begin: 33,
        end: 66,
        color: '#FCFCB4',
        width : score + '%',
      },
      {
        begin: 66,
        end: 100,
        color: '#41CAB7',
        width : score + '%',
      },
      {
        begin: 100,
        end: 101,
        color: '#41CAB7',
        width : score + '%',
      }
    ];
    const scoreStyles = scoreArray.find((item) => score >= item.begin && score < item.end);
    return {
      width : scoreStyles.width,
      'border-top' : '8px solid ' + scoreStyles.color,
      height: '0px',
      margin: '-.4em 0 0 0',
    };
  }

}
