import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { MatDialog } from '@angular/material/dialog';
import { Subscription, timer } from "rxjs";
import { IdentificationCheck } from "src/shared/providers/identification-check";
import { tap } from "rxjs/operators";

const IDENTIFICATION_CHECK_STATUSES = {
  0: 'requires_input',
  1: 'processing',
  2: 'verified',
}

@Component({
  selector: 'identity-verification-modal',
  templateUrl: 'identity-verification.html',
  styleUrls: ['./identity-verification.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IdentityVerificationModalComponent implements OnInit, OnDestroy {
  isMobileResolution = false;
  isOnIdentificationCheck: boolean;
  progressBars = [
    {
      mode: 'determinate',
      value: 100,
    },
    {
      mode: 'determinate',
      value: 0,
    },
    {
      mode: 'determinate',
      value: 0,
    },
  ];
  identificationSteps = [
    {
      icon: 'assets/icon/id.png',
      title: 'New clients require a quick identification check',
      subtitle: 'All you need is your drivers license.',
      displayButton: true,
      buttonText: 'Get Verified',
      action: () => this.getVerified(),
    },
    {
      icon: 'assets/icon/identify.png',
      title: 'Identification in progress',
      subtitle: 'This typically takes less than 1 minute.',
      displayButton: false,
      buttonText: '',
      action: () => {},
    },
    {
      icon: 'assets/icon/verify.png',
      title: 'Verification Completed!',
      subtitle: 'Your identity has been succesfully verified.',
      displayButton: true,
      buttonText: 'Ok',
      action: () => this.dismissModal(),
    }
  ];
  currentIdentificationStep = this.identificationSteps[0];
  verificationUrl = '';
  verificationSubscription = new Subscription();
  identificationError: any;

  constructor(
    private dialog: MatDialog,
    private modalCtrl: ModalController,
    private identificationCheck: IdentificationCheck,
  ) {
    this.isMobileResolution = window.innerWidth <= 870;
  }

  async ngOnInit(): Promise<void> {
    await this.handleIdentificationCheck();
  }

  ngOnDestroy(): void {
    this.verificationSubscription?.unsubscribe();
  }

  async handleIdentificationCheck(): Promise<void> {
    this.resetProgressBar();
    const identificationCheck = await this.getIdentificationCheck();
    this.isOnIdentificationCheck = identificationCheck?.status !== IDENTIFICATION_CHECK_STATUSES[2];
    if (this.isOnIdentificationCheck) {
      this.currentIdentificationStep = (this.identificationError || identificationCheck?.status === IDENTIFICATION_CHECK_STATUSES[1]) ? this.identificationSteps[1] : this.identificationSteps[0];
      if (this.currentIdentificationStep === this.identificationSteps[1]) {
        this.progressBars[1] = {
          mode: 'indeterminate',
          value: 0,
        }
        this.startVerificationCheck();
      }
    }
  }

  resetProgressBar(): void {
    this.progressBars[1] = {
      mode: 'determinate',
      value: 0,
    }
    this.progressBars[2] = {
      mode: 'determinate',
      value: 0,
    }
  }

  goToVerificationPage(): void {
    window.open(this.verificationUrl, '_blank');
  }

  getVerified(): void {
    this.startVerificationCheck();
    this.goToVerificationPage();
    this.currentIdentificationStep = this.identificationSteps[1];
    this.progressBars[1] = {
      mode: 'indeterminate',
      value: 0,
    }
  }

  async startVerificationCheck(): Promise<void> {
    const fifteenSeconds = 15000;
    this.verificationSubscription.add(timer(0, fifteenSeconds)
      .pipe(
        tap(async () => {
          const identificationCheck = await this.getIdentificationCheck()
          if (identificationCheck.status === IDENTIFICATION_CHECK_STATUSES[2]) {
            this.finishVerificationCheck();
            this.verificationSubscription.unsubscribe();
          }
        })
      )
      .subscribe());
  }

  finishVerificationCheck(): void {
    this.currentIdentificationStep = this.identificationSteps[2];
      this.progressBars[1] = {
        mode: 'determinate',
        value: 100,
      }
      this.progressBars[2] = {
        mode: 'determinate',
        value: 100,
      }
  }

  async getIdentificationCheck(): Promise<any> {
    const identificationCheck = await this.identificationCheck.getIdentityCheck();
    this.verificationUrl = identificationCheck?.redirect_url;
    this.identificationError = identificationCheck?.last_error_message;
    console.log(identificationCheck);
    return identificationCheck;
  }

  dismissModal(): void {
    this.dialog?.closeAll();
    this.modalCtrl.getTop().then((res) => {
      if (res) {
        this.modalCtrl.dismiss();
      }
    });
  }
}
