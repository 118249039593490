import { Component } from '@angular/core';
import { MySchedule } from '../../../providers/my-schedule/my-schedule';
import { ServerData } from '../../../shared/providers/server-data';
import { Loading } from '../../../shared/components/loading/loading';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SelectModel } from '@tidyapp/tidy-ui-components';
import { SuccessPageParamsModel } from 'src/pages/success/success';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { Router, ActivatedRoute } from '@angular/router';

import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

@Component({
  selector: 'page-confirm-schedule',
  templateUrl: 'confirm-schedule.html'
})
export class ConfirmSchedulePage extends Timeout {

  date: string;
  selectedTimesText: string;
  singleDay: boolean;
  cancelReasons: Array<SelectModel>;
  cancellations: {
    bonuses_to_cancel: [],
    cleanings_to_cancel: []
  };
  hasCancellations = false;
  form: UntypedFormGroup;
  submitted = false;
  errorMessage: string;
  scheduleParams: any;

  constructor(
    private mySchedule: MySchedule,
    private serverData: ServerData,
    private customNaCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.fb.group({
      cancelReason: ['', Validators.required]
    });
  }

  @Loading()
  async ionViewDidEnter(): Promise<any> {
    try {
      const {date, selectedTimesText, singleDay, scheduleParams} = this.customNaCtrl.getParams();
      this.date = date;
      this.singleDay = singleDay;
      this.selectedTimesText = selectedTimesText;
      this.scheduleParams = scheduleParams;

      await this.handleCancellations();
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  async handleCancellations(): Promise<any> {
    try {
      this.cancellations = await this.mySchedule.loadCancellations(this.buildData(this.scheduleParams));

      this.hasCancellations = this.cancellations.cleanings_to_cancel.length > 0 ||
                              this.cancellations.bonuses_to_cancel.length > 0;

      if (this.hasCancellations) {
        await this.mountCancellationReasons();
      }
    } catch (err) {
      this.errorMessage = err.message || err.error.message;
    }
  }

  async save() {
    this.submitted = !this.form.valid;
    if (!this.hasCancellations || this.form.valid) {
      try {
        await this.mySchedule.saveSchedule(this.buildData(this.scheduleParams));
        const params: SuccessPageParamsModel = this.successPageParams();
        this.customNaCtrl.navigateForward('success', params);
      } catch (err) {
        this.errorMessage = err.message || err.error.message;
      }
    }
  }

  goBack() {
    this.customNaCtrl.back();
  }

  private buildData(scheduleParams): any {
    return {
      ...scheduleParams,
      cancellation_reason_id: this.form.value.cancelReason
    };
  }

  async mountCancellationReasons() {
    const cancelReasons = await this.serverData.cancelReasons(false);
    this.cancelReasons = cancelReasons.map(reason => {
      return {
        viewValue: reason.title,
        value: reason.id
      };
    });
  }

  successPageParams(): SuccessPageParamsModel {
    return {
      header: 'Change Schedule',
      body: 'Schedule saved',
      buttonText: 'Go To My Schedule',
      buttonRoute: 'bookable-hours'
    };
  }

}
