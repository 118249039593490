<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [noBow]="true"
    [title]="'Finish Job'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Please let us know how the job went.'">
        </tidy-text>
      </tidy-row>
      <tidy-card>
        <form [formGroup]="form" novalidate action="#">
          <tidy-row>
            <tidy-text
              [header]="'Job Feedback'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="('Rate ' | translate) +
              job?.client +
              (' (they don\'t see this):' | translate)">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding vertical-align-center">
            <tidy-grid>
              <ion-row>
                <ion-col size="2" *ngFor="let star of stars; index as i" style="margin-right:5px">
                  <tidy-image
                    [src]="'assets/svg/' + (i < rating ? 'star-tidy-green' : 'star-grey') + '.svg'"
                    [ngClass]="i < rating ? 'green' : 'grey'"
                    (click)=updateRating(i)
                    class="title-size">
                  </tidy-image>
                </ion-col>
              </ion-row>
            </tidy-grid>
          </tidy-row>
          <tidy-row>
            <tidy-radio-button
              formControlName="question"
              [form]="form.controls.question"
              [label]="'Did you do the To-Dos?'"
              [items]="[{viewValue: 'Yes', value: 'yes'},{viewValue: 'No - To-Dos were not accurate', value: 'no_not_accurate'}, {viewValue: 'No - Client asked for other things', value: 'no_client_asked_other_things'}]">
            </tidy-radio-button>
          </tidy-row>
        </form>
      </tidy-card>

      <tidy-card *ngIf="syncData?.job?.showUpgradePlanCard">
        <tidy-row>
          <tidy-text
            [header]="('Add ' | translate) + job?.client + '\'s ' + ('Future Jobs?' | translate)">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="job?.client">
          </tidy-text>
          <tidy-text
            [body]="' needs someone for their full plan.  Would you like them as your repeat Client?'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'This means you will be available for them '">
          </tidy-text>
          <tidy-text
            [body]="job?.startTime | tdate:'dddd'">
          </tidy-text>
          <tidy-text
            [body]="(' at ' | translate) + (job?.startTime | tdate:'h:mma') + (' to ' | translate) + (job?.endTime | tdate:'h:mma') + '.'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <form [formGroup]="form" novalidate action="#">
            <tidy-radio-button
              formControlName="plan"
              [form]="form.controls.plan"
              [items]="[{viewValue: ('Yes - Accept ' | translate) + job?.client + '\'s ' + ('Full Plan' | translate), value: 'yes'}, {viewValue: 'No Thank You', value: 'no'}]">
            </tidy-radio-button>
          </form>
        </tidy-row>
      </tidy-card>

      <slider-trigger
        [label]="'Save & Finish Job'"
        [onTriggeredAction]="finishJob.bind(this)">
      </slider-trigger>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
