import { Injectable } from '@angular/core';
import { HttpClient } from '../../shared/providers/http/http-client';
import { Platform } from '@ionic/angular';

@Injectable()
export class AppReview {

  constructor(
    private httpClient: HttpClient,
    private platform: Platform,
  ) {}

  getAppReviewData() {
    const platform = (this.platform.is('ios')) ? 'ios' : 'android';
    const url = `api/v1/homekeeper/app-reviews/information?platform=${platform}`;
    return this.httpClient.get(url);
  }

  recordAskedForStarReview(app_review_page_id, source, prompted_at) {
    const data = {
      app_review_page_id,
      source,
      prompted_at
    };
    const url = `api/v1/homekeeper/app-reviews`;
    return this.httpClient.post(url, data);
  }

  recordGaveStarReview(app_review_attempt_id, filter_rate) {
    const data = {
      id: app_review_attempt_id,
      filter_rate
    };
    const url = `api/v1/homekeeper/app-reviews/${app_review_attempt_id}`;
    return this.httpClient.put(url, data);
  }

  recordClickedAppReviewLink(app_review_attempt_id, filter_rate, review_at) {
    const data = {
      id: app_review_attempt_id,
      filter_rate,
      review_at
    };
    const url = `api/v1/homekeeper/app-reviews/${app_review_attempt_id}`;
    return this.httpClient.put(url, data);
  }
}
