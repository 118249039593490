<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Item Detail'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card>
      <tidy-row class="extra-bottom-padding vertical-align-center">
        <tidy-image
          [src]="item?.job_data?.total_amount > 0 ? 'assets/img/add-circle-green.svg' :  'assets/img/remove-circle-red.svg'"
          class="header-size">
        </tidy-image>
        <tidy-text
          [header]="(item?.job_data?.total_amount | tcurrency)">
        </tidy-text>
        <tidy-text
          [header]="(item?.job_data?.total_amount > 0 ? ' Payment' : ' Fee')">
        </tidy-text>
      </tidy-row>
      <ng-container *ngFor="let breakdown of item?.items; let last = last">
        <tidy-row [ngClass]="last ? 'extra-bottom-padding' : ''">
          <tidy-text
            [body]="breakdown?.description +
            ': ' +
            (breakdown?.amount | tcurrency)">
          </tidy-text>
          <tidy-text
            *ngIf="isNewBasePayment(breakdown)"
            [body]="' <b>*</b>'">
          </tidy-text>
        </tidy-row>
      </ng-container>
      <tidy-row *ngIf="hasNewBasePayment" class="extra-bottom-padding">
        <tidy-text
          [body]="'<b>*</b> This client first booked you on '">
        </tidy-text>
        <tidy-text
          [body]="(item?.job_data?.homekeeper_assigned_local_datetime?.date | tdate:'M/D/YY')">
        </tidy-text>
        <tidy-text
          [body]="' at '">
        </tidy-text>
        <tidy-text
          [body]="(item?.job_data?.homekeeper_assigned_local_datetime?.time | ttime)">
        </tidy-text>
        <tidy-text
          [body]="', prior to your most recent rate change. With your current rates the base payment would be '">
        </tidy-text>
        <tidy-text
          [body]="(item?.job_data?.base_pay_rate | tcurrency) + '.'">
        </tidy-text>
      </tidy-row>
      <tidy-card-button (action)="goToPastJobPage(item?.job_data?.id, item?.job_data?.is_private)">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/information-circle-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="(item?.job_data?.service_type_details?.bookable_service?.name | titlecase)">
          </tidy-text>
          <tidy-text
            [body]="' - '">
          </tidy-text>
          <tidy-text
            [body]="item?.job_data?.date">
          </tidy-text>
          <tidy-text
            [body]="' at '">
          </tidy-text>
          <tidy-text
            [body]="item?.job_data?.time">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="goToPrivateClientPage(clientId)" *ngIf="item?.job_data?.is_private">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/person-circle-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Private Client '">
          </tidy-text>
          <tidy-text
            [body]="item?.job_data?.customer?.name">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="goToContactConcierge(item?.title)">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/help-circle-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Log Issue'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="goToWaiveFees()" *ngIf="item?.able_to_waive_fee">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/alert-circle-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Waive Fee'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
