import { Component } from '@angular/core';

import { Certification } from 'src/providers/certification/certification';
import { PrivateClient } from 'src/providers/private-client/private-client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

import { CertificationsModel } from 'src/shared/models/certification.model';
import { ClientModel } from 'src/shared/models/client.model';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

@Component({
  templateUrl: 'clients.html'
})
export class ClientsPage extends Timeout {

  certifications: CertificationsModel
  clients: ClientModel[];
  noClientsCards: any;

  constructor(
    private certification: Certification,
    private customNavCtrl: CustomNavController,
    private iabUtils: InAppBrowserUtils,
    private privateClient: PrivateClient,
    private storage: TidyStorage,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
  }

  async ionViewDidEnter() {
    try {
      this.loaded = false;
      this.clients = await this.privateClient.getPrivateClients();
      this.certifications = await this.certification.getCertifications();
      this.noClientsCards = this.getNoClientsCards();
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  async goToClient(client) {
    await this.storage.save('privateClientBackRoute', 'clients');
    this.customNavCtrl.navigateForward(`/private-client/${client.id}`);
  }

  learnMore() {
    const url = 'https://help.tidy.com/pros/manage-private-clients#what-are-my-private-clients';
    this.iabUtils.openUrl(url);
  }

  addClient() {
    this.customNavCtrl.navigateForward('add-private-client');
  }

  getNoClientsCards() {
    return [
      {
        title: 'Manage Bookings',
        text: 'You and your Clients can reschedule, add To-Dos, notes, and more.',
        image: 'assets/img/calendar.svg'
      },
      {
        title: 'Collect Payment',
        text: 'Send invoices so your Clients can easily pay in 1 tap.',
        image: 'assets/img/lightning.svg'
      },
      {
        title: 'Send Proposals',
        text: 'Send Clients beautiful proposals they are more likely to accept.',
        image: 'assets/img/send.svg'
      },
      {
        title: 'Track Mileage & Equipment',
        text: 'Earn more by tracking your writeoffs and saving on taxes.',
        image: 'assets/img/pro.svg'
      }
    ];
  }
}
