import { Component, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Me } from 'src/shared/providers/me';
import { PrivateClient } from 'src/providers/private-client/private-client';
import { Team } from 'src/providers/team/team';

import validationUtils from 'src/shared/utils/validation-utils';

import { Loading } from 'src/shared/components/loading/loading';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: 'private-client-added.html'
})

export class ClientAddedPage extends Timeout {

  client: any;
  errorMessage: string;
  form: UntypedFormGroup;
  isMissingEmail: boolean;
  submitted: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private me: Me,
    private navCtrl: CustomNavController,
    private privateClient: PrivateClient,
    private team: Team,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.fb.group({
      body: ['', Validators.required],
      email: [''],
      subject: ['', Validators.required]
    });
  }

  @Loading()
  async ionViewDidEnter() {
    this.loaded = false;
    try {
      this.client = await this.navCtrl.getParam('client');
      this.isMissingEmail = this.client.email == '';
      if (this.isMissingEmail) {
        this.form.controls.email.setValidators([Validators.required, validationUtils.validateEmail]);
      }
      const team = await this.team.getMyTeam();
      const me = await this.me.load();
      this.form.patchValue({
        subject: 'My New Website for Booking',
        body: this.getBody(me, team)
      })
    } catch(err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
    this.loaded = true;
  }

  async sendEmail() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    try {
      if (this.isMissingEmail) {
        await this.privateClient.updatePrivateClient({email: this.form.value.email}, this.client.id);
      }
      const payload = this.buildPayload();
      await this.privateClient.sendClientEmail(payload);
      this.goToClientPage();
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  buildPayload() {
    return {
      customer_id: this.client.id,
      subject: this.form.value.subject,
      body: this.form.value.body,
      content_type: 'text/plain'
    }
  }

  goToClientPage() {
    this.navCtrl.navigateForward(`private-client/${this.client.id}`);
  }

  getBody(me, team) {
    const website = `www.tidy.com/${team.url_suffix}/client/${this.client.client_hub_uuid}`;
    return `${new TranslationPipe().transform('Hi')} ${this.client.first_name}
    
    ${new TranslationPipe().transform('Here is a link to my website with a personal page for you where you can see upcoming jobs with me, see my invoices, and pay me all online. You don\'t have to use it, but some clients like it so I thought I would share it.')}

    ${website}

    ${me.profile.name}`;
  }

}
