<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Reply to Concierge'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card>
      <tidy-row class="vertical-align-center">
        <tidy-image
          [src]="concierge.getStatusIcon(item?.components[0]?.value?.status)"
          class="header-size">
        </tidy-image>
        <tidy-text
          [header]="item?.header_text">
        </tidy-text>
      </tidy-row>

      <tidy-row *ngIf="item?.components[0]?.value?.status !== 'completed' && item?.components[0]?.value?.status !== 'closed'">
        <tidy-text
          *ngIf="item?.components[0]?.value?.status === 'waiting_on_tidy'"
          [body]="'<i>Waiting on TIDY</i>'">
        </tidy-text>
        <tidy-text
          *ngIf="item?.components[0]?.value?.status === 'waiting_on_customer'"
          [body]="'<b><i>Waiting on You</i><b>'">
        </tidy-text>
      </tidy-row>

      <tidy-row class="extra-bottom-padding extra-top-padding">
        <tidy-divider>
        </tidy-divider>
      </tidy-row>

      <form [formGroup]="form" novalidate action="#">
         <tidy-row class="field-top-padding">
          <tidy-textarea
            [label]="'Reply'"
            formControlName="content"
            [form]="form.controls.content"
            [submitted]="submitted"
            [icon]="'assets/svg/information-circle-outline.svg'"
            [errorMessage]="'Please enter a reply.'">
          </tidy-textarea>
        </tidy-row>
      </form>
      <tidy-row class="field-top-padding">
        <tidy-text
          [actionBody]="'Add Attachment'"
          (action)="addAttachment()"
          class="link">
        </tidy-text>
      </tidy-row>
      <tidy-row *ngIf="attachments.length" class="field-top-padding">
        <tidy-row>
          <tidy-text
            [body]="'Attachments:'"
          >
          </tidy-text>
        </tidy-row>
        <tidy-two-column-row *ngFor="let attachment of attachments; let i = index">
          <tidy-text
            [body]="attachment.filename">
          </tidy-text>
          <tidy-text
            [actionBody]="'remove'"
            (action)="removeAttachment(i)"
            class="link red">
          </tidy-text>
        </tidy-two-column-row>
      </tidy-row>
    </tidy-card>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-button
      [text]="'Send Reply'"
      [action]="send.bind(this)"
      class="primary">
    </tidy-button>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
