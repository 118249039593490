import { UntypedFormGroup } from '@angular/forms';

export function validateEqual(field: string, confirmField: string) {
  return (group: UntypedFormGroup): {[key: string]: any} => {
    const element = group.controls[field];
    const confirmElement = group.controls[confirmField];

    if (element.value !== confirmElement.value) {
      return {
        mismatchedFields: true
      };
    }
  };
}
