<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Exit Job?'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'Why do you need to exit the job?'">
      </tidy-text>
    </tidy-row>

    <tidy-button
      [text]="'Resume Job'"
      [action]="goBack.bind(this)"
      class="primary">
    </tidy-button>

    <tidy-button
      [text]="'Client Refused Service'"
      [action]="goToClientRefusedServicePage.bind(this)"
      class="secondary">
    </tidy-button>

    <tidy-button
      [text]="'Call Out'"
      [action]="goToCallOutPage.bind(this)"
      class="secondary">
    </tidy-button>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
