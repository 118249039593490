<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="title | titlecase">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-alert>
      <tidy-row>
        <tidy-text
          [header]="'Finish Getting Started to Use This'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="body">
        </tidy-text>
        <continue-certification-link>
        </continue-certification-link>
      </tidy-row>
    </tidy-alert>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
