<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Quests'"
    [canGoBack]="true"
    [customBack]="backButtonRoute">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="loaded">
      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Earn rewards by completing TIDY quests!'">
        </tidy-text><br>
        <tidy-text
          [body]="'Learn More'"
          (action)="learnMore()"
          class="link">
        </tidy-text>
      </tidy-row>

      <tidy-row>
        <tidy-text
          [body]="'Active Quests:'">
        </tidy-text>
      </tidy-row>

      <tidy-alert *ngIf="activeQuests?.length === 0">
        <tidy-text
          [body]="'None'">
        </tidy-text>
      </tidy-alert>

      <tidy-card *ngFor="let quest of activeQuests">
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            class="header-size"
            src="assets/img/quest-mountain-fill.svg">
          </tidy-image>
          <tidy-text
            [header]="quest?.title">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            [src]="'assets/svg/checkmark-circle-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="('Challenge: ' | translate) + quest?.challenge">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            [src]="'assets/svg/help-circle-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="quest?.reward">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center vertical-align-center">
          <tidy-image
            [src]="'assets/svg/stopwatch-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="('Active on: ' | translate) + (quest?.start_date | tdate: 'dddd M/DD/YYYY')">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-row>
        <tidy-text
          [body]="'Available Quests:'">
        </tidy-text>
      </tidy-row>

      <tidy-alert *ngIf="availableQuests?.length === 0">
        <tidy-text
          [body]="'None'">
        </tidy-text>
      </tidy-alert>

      <tidy-card *ngFor="let quest of availableQuests">
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            src="assets/img/quest-mountain-fill.svg"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="quest?.title">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            [src]="'assets/svg/checkmark-circle-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="('Challenge: ' | translate) + quest?.challenge">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            [src]="'assets/svg/help-circle-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="('Reward: ' | translate) + quest?.reward">
          </tidy-text>
        </tidy-row>
        <tidy-button
          [text]="'Start Quest'"
          [action]="startQuest.bind(this, quest)"
          class="primary">
        </tidy-button>
      </tidy-card>

      <tidy-error-message
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-row>
        <tidy-text
          [body]="'Past Quests:'">
        </tidy-text>
      </tidy-row>

      <tidy-alert *ngIf="pastQuests?.length === 0">
        <tidy-text
          [body]="'None'">
        </tidy-text>
      </tidy-alert>

      <tidy-card *ngFor="let quest of pastQuests">
        <tidy-row class="extra-bottom-padding" [alignLastItemRight]="true">
          <tidy-image
            src="assets/img/quest-flag-fill.svg"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="quest?.title">
          </tidy-text>
          <tidy-text
            [ngClass]="quest?.status === 'success' ? 'green' : ''"
            [body]="quest?.status === 'success' ? 'Completed' : 'Failed'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            [src]="'assets/svg/checkmark-circle-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="quest?.challenge">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            [src]="'assets/svg/help-circle-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="quest?.reward">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center vertical-align-center">
          <tidy-image
            [src]="'assets/svg/stopwatch-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="('Active on: ' | translate) + quest?.start_date | tdate: 'dddd M/DD/YYYY'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center vertical-align-center">
          <tidy-image
            [src]="'assets/svg/stopwatch-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="('Ended on: ' | translate) + quest?.end_date | tdate: 'dddd M/DD/YYYY'">
          </tidy-text>
        </tidy-row>
      </tidy-card>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
