import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'jobs-details-section',
  templateUrl: './jobs-details-section.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobsDetailsSectionComponent implements OnInit {

  @Input() billingType: string;
  @Input() date: string;
  @Input() frequency: string;
  @Input() lengthLabel: string;
  @Input() startTimeLabel: string;
  @Input() service: string;
  @Input() serviceName: string;
  @Input() description: string;
  @Input() price: string;

  constructor() { }

  ngOnInit() {}
}
