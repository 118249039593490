import { Component, ViewEncapsulation, Input, EventEmitter, AfterViewInit, OnDestroy} from '@angular/core';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Loading } from 'src/shared/components/loading/loading';
import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';
import { Subject, Subscription } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';


@Component({
  selector: 'success-page',
  templateUrl: './success.html',
  encapsulation: ViewEncapsulation.None
})

export class SuccessPage implements AfterViewInit, OnDestroy {
  pageMessage: string;
  params: SuccessPageParamsModel;
  @Input() modalParams: SuccessPageParamsModel;
  errorMessage: string;
  modalSubscription: Subscription;

  constructor(
    private customNavCtrl: CustomNavController,
    private iabUtils: InAppBrowserUtils,
    private modalCtrl: ModalController,
    private router: Router
  ) {
  }

  ngAfterViewInit() {
    this.modalSubscription = this.modalParams?.dismiss?.subscribe(dissmiss => {
      if (dissmiss) {
        this.dismissModal(this.params.buttonRoute);
      }
    });
  }

  @Loading()
  ionViewDidEnter() {
    this.params = this.modalParams || this.customNavCtrl.getParams() || this.params;
  }

  goToLearnMore() {
    this.iabUtils.openUrl(this.params.learnMoreLink);
  }

  buttonAction() {
    let error = null;
    try {
      if(this.params?.customAction) {
        this.params.actionSubject?.next();
        return this.params.customAction();
      }
      if (!this.params.externalUrl) {
        if (this.params.buttonRoute) {
          this.params.actionSubject?.next();
          return this.customNavCtrl.navigateRoot(this.params.buttonRoute, this.params.navigationExtras || {});
        }
        this.customNavCtrl.back();
      } else {
        this.iabUtils.openUrl(this.params.buttonRoute);
      }
    } catch (err) {
      error = err;
      this.errorMessage = err.error ? err.error.message : err.message;
    } finally {
      if(!error) {
        this.dismissModal(this.params.buttonRoute);
      }
    }
  }

  secondButtonAction() {
    let error = null;
    try {
      return this.params.secondCustomAction();
    } catch (err) {
      error = err;
      this.errorMessage = err.error ? err.error.message : err.message;
    } finally {
      if(!error) {
        this.dismissModal(this.params.buttonRoute);
      }
    }
  }

  ngOnDestroy() {
    this.modalSubscription?.unsubscribe();
  }

  dismissModal(targetRoute = null) {
    this.modalCtrl.dismiss();
    if (this.router.url.includes(targetRoute)) {
      window.location.reload();
    }
  }
}

export interface SuccessPageParamsModel {
  header: string;
  externalUrl?: boolean;
  body: string;
  buttonText: string;
  learnMoreLink?: string;
  buttonRoute?: string;
  navigationExtras?: {[key: string]: string}
  customAction?: (data?) => Promise<any> | void;
  secondButtonText?: string;
  secondCustomAction?: (data?) => Promise<any>;
  dismiss?: EventEmitter<boolean>;
  actionSubject?: Subject<void>;
}
