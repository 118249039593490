import { File } from '@awesome-cordova-plugins/file/ngx';
import { CommonUtils } from 'src/shared/utils/common-utils';
import { Aws } from 'src/shared/providers/aws';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { HttpClient } from 'src/shared/providers/http/http-client';
import { Injectable } from '@angular/core';
import {
  MediaCapture,
  CaptureVideoOptions,
  MediaFile,
  CaptureError,
} from '@awesome-cordova-plugins/media-capture/ngx';
import { Platform } from '@ionic/angular';
import { Logger } from 'src/shared/providers/logger';
import { Capacitor } from '@capacitor/core';
import { randomUUID } from 'src/shared/utils/secure-utils';
import { MWStore } from 'src/main-workflow/mw.store';
import { DeviceInfoProvider } from 'src/shared/providers/device-info';
import { Camera } from 'src/shared/providers/camera/camera';
import { Media } from '@capacitor-community/media';
import { Subject } from 'rxjs';

interface MediaData {
  uuid?: string;
  media_data?: string;
  media_url: string;
  media_format: 'photo' | 'video';
  description?: string;
  room_or_group?: {
    id: string;
    type: 'room' | 'group';
  };
  category: 'before_job' | 'after_job';
  exif?: any;
}
type MediaBeforeAfterTypes =
  | 'before_photos'
  | 'after_photos'
  | 'before_videos'
  | 'after_videos';

@Injectable({
  providedIn: 'root',
})
export class JobMediasProvider {
  private isSyncing = false;
  options: CaptureVideoOptions = {
    limit: 1,
    duration: Capacitor.getPlatform() == 'android' ? 180 : 240,
  };

  constructor(
    private http: HttpClient,
    private storage: TidyStorage,
    private mediaCapture: MediaCapture,
    private logger: Logger,
    private platform: Platform,
    private aws: Aws,
    private mwStore: MWStore,
    private utils: CommonUtils,
    private deviceInfo: DeviceInfoProvider,
    private camera: Camera,
    private file: File
  ) {}

  uploadJobMediasForCompletedJob(
    homekeeperJobId: string,
    mediasData: MediaData[]
  ): Promise<any> {
    const payload = {
      homekeeper_job_id: homekeeperJobId,
      job_medias: mediasData,
    };
    return this.http.post('/api/v1/homekeeper/job-medias', payload);
  }

  getJobMedias(jobIds: number[]): Promise<any> {
    return this.http.get(
      `api/v1/homekeeper/job-medias?job_ids=${jobIds.join(',')}`
    );
  }

  async takeBeforeAfterVideoAndSaveInStorage(
    videoType: MediaBeforeAfterTypes,
    jobUuid: string
  ): Promise<MediaData | void> {
    return new Promise(async (resolve, reject) => {
      let options: CaptureVideoOptions = {
        limit: 1,
        duration: 240,
      };
      await this.mediaCapture.captureVideo(options).then(
        (data: MediaFile[]) =>
          this.dispatchNativeVideo(data, videoType, jobUuid)
            .then((data) => resolve(data))
            .catch((err) => reject(err)),
        (err: CaptureError) => reject(err)
      );
    });
  }

  async dispatchNativeVideo(
    data: MediaFile[],
    videoType: MediaBeforeAfterTypes,
    jobUuid: string
  ): Promise<MediaData | void> {
    return new Promise(async (resolve, reject) => {
      try {
        const filePath = this.platform.is('ios')
          ? `file://${data[0].fullPath}`
          : data[0].fullPath;
        const mediaData = await this.fetchNativeVideoFileData(filePath);
        this.saveVideoToDevice(data[0], mediaData);
        const jobMedia = await this.uploadAndSaveVideoInStorage(
          mediaData,
          jobUuid,
          videoType
        );
        resolve(jobMedia);
      } catch (err) {
        this.logger.log('dispatch-video', err);
        reject(err);
      }
    });
  }

  async saveVideoToDevice(data: MediaFile, file: Blob): Promise<void> {
    try {
      const isNative = Capacitor.isNativePlatform();
      const directory = this.platform.is('ios')
        ? this.file.documentsDirectory
        : this.file.externalDataDirectory;

      if (isNative) {
        // Native platform logic
        await this.file.writeFile(directory, data.name, file, {
          replace: true,
        });
        const savedFile = await this.file.writeFile(directory, data.name, file, {
          replace: true,
        });
        if (this.platform.is('ios')) {
          await Media.saveVideo({
            path: savedFile.nativeURL,
          });
          await this.file.removeFile(directory, data.name);
        }
        console.log('Video saved to gallery');
      } else {
        // Mobile web platform logic
        return new Promise((resolve, reject) => {
          try {
            const url = URL.createObjectURL(file);
            const a = document.createElement('a');
            a.href = url;
            a.download = data.name;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
          console.log('Video saved to device on mobile web');
          resolve();
          } catch (err) {
            console.error('Error trying to save video to gallery:', err);
            reject(err);
          }
        });
      }
    } catch (err) {
      console.error('Error trying to save video to gallery:', err);
    }
  }

  async uploadAndSaveVideoInStorage(
    mediaData: Blob,
    jobUuid: string,
    videoType: MediaBeforeAfterTypes
  ): Promise<MediaData | void> {
    return new Promise(async (resolve, reject) => {
      try {
        const jobMedia = await this.saveBeforeAfterMediaInStorage(
          jobUuid,
          videoType,
          mediaData
        );
        resolve(jobMedia);
      } catch (err) {
        console.error('error on trying to take/upload file:', err);
        await this.utils.showError(
          'Video Upload Failed. Please try to upload it again. You can also take a video on your device and upload it after you complete the job when you have a better connection.'
        );
        reject(err);
      }
    });
  }

  async saveBeforeAfterMediaInStorage(
    jobUuid: string,
    mediaType: MediaBeforeAfterTypes,
    mediaData: string | Blob,
    exif?: any
  ): Promise<MediaData | void> {
    // Wait until the lock is free to avoid race conditions
    while (this.isSyncing) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
    this.isSyncing = true;
    try {
      let mediaUrl = mediaData;
      if (mediaData instanceof File) {
        mediaData = new Blob([mediaData]);
      }
      const jobIsCompleted = await this.storage.retrieve('jobIsCompleted');
      const mediaFormat = mediaType.includes('photo') ? 'photo' : 'video';
      const category = mediaType.includes('before') ? 'before_job' : 'after_job';
      const jobMedia: MediaData = {
        media_data: mediaFormat === 'photo' ? (mediaUrl as string) : null,
        media_url: mediaUrl as string,
        media_format: mediaFormat,
        category,
        exif,
        uuid: randomUUID(),
      };
      if (jobIsCompleted) {
        return await this.handleCompletedJobMedia(jobUuid, mediaType, jobMedia, exif);
      }
      await this.handleIncompleteJobMedia(jobUuid, mediaType, jobMedia);
    } catch (err) {
      console.error('Error saving media in storage:', err);
    } finally {
      this.isSyncing = false;
    }
  }

  private async handleCompletedJobMedia(
    jobUuid: string,
    mediaType: MediaBeforeAfterTypes,
    jobMedia: MediaData,
    exif?: any
  ): Promise<MediaData> {
    this.utils.showInfo('Your media is being uploaded. This may take a while.');
    const s3ObjectUrl = await this.mountS3ObjectUrl(jobUuid, mediaType);
    const s3Object = await this.aws.saveTodoMediaToAws(
      jobMedia.media_url,
      jobMedia.media_format,
      s3ObjectUrl,
      null,
      exif
    );
    const completedJobMedia: MediaData = {
      ...jobMedia,
      media_data: jobMedia.media_url,
      media_url: s3Object.Location,
    };
    this.utils.showSuccess('Media uploaded successfully');
    return completedJobMedia;
  }

  private async handleIncompleteJobMedia(
    jobUuid: string,
    mediaType: MediaBeforeAfterTypes,
    jobMedia: MediaData
  ): Promise<void> {
    const storeKey = mediaType.includes('before') ? 'before_photos' : 'after_photos';
    const mwJobCategoryKey = mediaType.includes('before') ? 'beforePhotos' : 'afterPhotos';
    const storageKey = await this.storage.retrieve('storageKey');
    let syncData = await this.storage.retrieve(storageKey);
    const page = await this.storage.retrieve('page');
    let storedMedia = page === 'MWToDosPage'
      ? syncData?.data?.[mwJobCategoryKey]
      : syncData?.[storeKey];
    storedMedia = storedMedia ? [...storedMedia, jobMedia] : [jobMedia];
    if (page === 'MWToDosPage') {
      syncData.data[mwJobCategoryKey] = storedMedia;
    } else {
      syncData[storeKey] = storedMedia;
    }
    await this.storage.save(storageKey, syncData);
  }

  async saveBeforeAfterPhotoInStorage(
    photoType: MediaBeforeAfterTypes,
    jobUuid: string,
    base64Photo: string,
    file?: any,
    exif?: any
  ): Promise<MediaData | void> {
    try {
      if (base64Photo.includes('heic')) {
        base64Photo = base64Photo.replace('heic', 'jpeg');
      }
      base64Photo = await this.camera.resizeImage(base64Photo);
      const isTakenWithCamera = !file && !exif;
      if (isTakenWithCamera) {
        this.saveImageToDevice(base64Photo);
      }

      const jobMedia = await this.saveBeforeAfterMediaInStorage(
        jobUuid,
        photoType,
        base64Photo,
        exif
      );
      return jobMedia;
    } catch (err) {
      console.error('error on trying to save before after photo:', err);
      await this.utils.showError(
        'Photo Upload Failed. Please try to upload it again. You can also take a photo on your device and upload it after you complete the job when you have a better connection.'
      );
    }
  }

  async saveImageToDevice(base64Photo: string): Promise<void> {
    try {
      if (!Capacitor.isNativePlatform()) {
        return;
      }
      const directory = this.platform.is('ios')
        ? this.file.documentsDirectory
        : this.file.externalDataDirectory;
      const fileName = `photo-${Date.now()}.jpeg`;
      await this.file.writeFile(directory, fileName, base64Photo, { replace: true });
    } catch (err) {
      console.error('error on trying to save image to device:', err);
    }
  }

  async saveBeforeAfterVideoInStorage(
    mediaData: any,
    jobUuid: string,
    videoType: MediaBeforeAfterTypes,
    exif?: any
  ): Promise<MediaData | void> {
    if (!Capacitor.isNativePlatform() && this.deviceInfo.isMobileWeb()) {
      // INFO: Save video to device if on mobile web
      // They are being saved at downloads folder because of Capacitor and Browser limitations
      await this.saveVideoToDevice({ name: 'video.mp4', ...mediaData }, mediaData);
    }
    const data = this.deviceInfo.isNativeMobile()
      ? await this.fetchNativeVideoFileData(mediaData.path)
      : mediaData;

    try {
      const jobMedia = await this.saveBeforeAfterMediaInStorage(
        jobUuid,
        videoType,
        data
      );
      return jobMedia;
    } catch (error) {
      console.error(error);
      await this.utils.showError(
        'Video Upload Failed. Please try to upload it again. You can also take a video on your device and upload it after you complete the job when you have a better connection.'
      );
    }
  }

  async mountS3ObjectUrl(
    jobUuid: string,
    mediaType: MediaBeforeAfterTypes
  ): Promise<string> {
    const randomHash =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    const page = await this.storage.retrieve('page');
    const type = mediaType.includes('photo') ? 'photos' : 'videos';
    const mimeType = type === 'photos' ? 'jpeg' : 'mp4';
    if (page === 'SharedToDoListPage' || page === 'SharedJobPage') {
      return `shared-link-before-after-photos/${jobUuid}/${page}/${mediaType}/${randomHash}.${mimeType}`;
    }
    if (page === 'PrivateJobPage') {
      return `private-job-before-after-photos/${jobUuid}/${mediaType}/${randomHash}.${mimeType}`;
    }
    if (page === 'MWToDosPage') {
      const job = await this.mwStore.getJob();
      return `before-after-photos/customers/${job.customerId}/jobs/${job.id}/homekeeper-jobs/${job.homekeeper_job_id}/${mediaType}/${randomHash}.${mimeType}`;
    }
    return `shared-link-before-after-photos/${jobUuid}/${page}/${mediaType}/${randomHash}.${mimeType}`;
  }

  private async fetchNativeVideoFileData(filePath: string): Promise<Blob> {
    const videoPath = Capacitor.convertFileSrc(filePath);
    return fetch(videoPath).then((r) => r.blob());
  }

  parseMedias(medias: any[], sourceType: string) {
    if (!medias) {
      return [];
    }
    const parsedMediasData = medias.filter(media => (media?.media_url)).map((media) => {
      let parsedMedia: any = {
        media_url: media?.media_url,
        media_format: media?.media_format,
        category: sourceType,
        uuid: media?.uuid || this.getMediaUUIDFromURLReturnedByBackend(media?.media_url),
        deleted: media?.deleted,
      };
      if (media?.room_or_group?.id && media?.room_or_group?.type) {
        parsedMedia[media.room_or_group.type === 'AddressRoom' ? 'address_room_id' : 'address_task_group_id'] = media?.room_or_group?.id;
      }
      if (media?.description) {
        parsedMedia.description = media?.description;
      }
      return parsedMedia;
    });
    return parsedMediasData;
  }

  getMediaUUIDFromURLReturnedByBackend(url) {
    //When FE saves a URL AWS returns it like: "https://samantha-temp-file-uploads.s3-us-west-2.amazonaws.com/sandbox3/private-job-before-after-photos/1763533/before_photos/fa41e206-1352-43e1-848b-2077efba301a.jpeg"
    //When BE returns a URL from job-medias it appears like: "https://samantha-file-uploads.s3.us-west-2.amazonaws.com/sandbox3/jobs/1763533/job-medias/904d1a43-a21e-4ba5-9804-01ec635f8778.jpeg"
    //When patching the form from BE response and not stored response, FE needs to get the UUID from this returned BE string
    if (!url) {
      return null;
    }
    const uuidMatch = url.match(/job-medias\/([^.]+)/);
    return uuidMatch ? uuidMatch[1] : null;
  }

}
