import { Injectable } from '@angular/core';
import { HttpClient } from '../../shared/providers/http/http-client';
import { TidyStorage } from '../../shared/providers/tidy-storage';

import { Location } from '../../shared/providers/location';
import { Cleanings } from '../cleanings/cleanings';

@Injectable()
export class OnRails {

  constructor(
    private cleanings: Cleanings,
    private httpClient: HttpClient,
    private storage: TidyStorage,
    private location: Location
  ) {}

  async getTodayCleaningsToCheckin() {
    const today = new Date();
    const todaysCards = await this.cleanings.fetch(today, today);
    return todaysCards[0].cards.filter(cleaningCard => cleaningCard.payment_price >= 0);
  }

  async confirmCleanings(cleaningIds: number[], cancelReasonId: number = null): Promise<any> {
    const url = `api/v1/homekeeper/confirm-jobs`;
    const { latitude, longitude, error: locationError } = await this.location.get();
    if (locationError) {
      this.location.throwErrorMessage();
    }
    const payload = {
      job_ids: cleaningIds,
      cancellation_reason_id: cancelReasonId,
      latitude, longitude
    };

    try {
      await this.httpClient.request({url, payload, timeout: 20000, method: 'PUT' });
    } catch (err) {
      if (err.status !== 400) {
        throw err;
      }
    }
  }

  async cleaningsPermissions(): Promise<any> {
    const url = `api/v1/homekeeper/permissions`;

    return this.httpClient.get(url);
  }

  private async hkId() {
    return await this.storage.retrieve('hk_id');
  }

  callOutAllDontFeelWell() {
    const dontFeelWellReasonId = 8;
    return this.confirmCleanings([], dontFeelWellReasonId);
  }
}
