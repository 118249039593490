import { Injectable } from '@angular/core';
import { HttpClient } from '../../shared/providers/http/http-client';


@Injectable()
export class FeeWaivers {

  constructor(
    private httpClient: HttpClient
  ) {}

  fetch(): Promise<any> {
    const url = `api/v1/homekeeper/fee-waivers`;
    return this.httpClient.getNoCache(url);
  }

  request(date: any): Promise<any> {
    const url = `api/v1/homekeeper/fee-waivers/request`;
    return this.httpClient.post(url, { date });
  }

}
