import { ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PrivateClient } from 'src/providers/private-client/private-client';
import { Loading } from 'src/shared/components/loading/loading';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm';
import { PrivateJobModel } from 'src/shared/models/private-job.model';
import { TidyDatePipe } from 'src/shared/pipes/tidy-date';
import { TimePipe } from 'src/shared/pipes/tidy-time';
import * as moment from 'moment';
import { SuccessPageParamsModel } from 'src/pages/success/success';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: 'private-cancellation.html'
})
export class PrivateCancellationPage {

  cancelType: string;
  clientId: string;
  clientName: string;
  errorMessage: string;
  jobData: PrivateJobModel;
  loaded: boolean;
  srcId: string;

  constructor(
    private customNavCtrl: CustomNavController,
    private modalCtrl: ModalController,
    private privateClient: PrivateClient,
    private route: ActivatedRoute,
    private tidyDatePipe: TidyDatePipe,
    private timePipe: TimePipe
  ) {
  }

  @Loading()
  ionViewDidEnter() {
    this.loaded = false;
    this.cancelType = this.route.snapshot.paramMap.get('cancelType');
    this.srcId = this.route.snapshot.paramMap.get('srcId');
    this.jobData = this.customNavCtrl.getParam('jobData', true);
    this.clientName = this.customNavCtrl.getParam('clientName', true);
    this.loaded = true;
  }

  checkLessThan24h(): boolean {
    const cleaningStartTime = `${this.jobData.date} ${this.jobData.start_time}`;
    const cleaningMoment = moment(cleaningStartTime, 'YYYY-MM-DD HH:mm');
    const diffHours = cleaningMoment.diff(moment(), 'hours');

    return diffHours < 24;
  }

  cancellation(source: string) {
    const lessThan24h = this.checkLessThan24h();
    if (source === 'customer' && lessThan24h) {
      return this.presentCancellationFeeModal(source);
    }
    return this.confirmCancellation({applyFee: false, source});
  }

  async presentCancellationFeeModal(source: string) {
    const params: ConfirmPageParamsModel = {
      title: 'Bill Cancellation Fee?',
      body: 'Do you want to bill them a cancellation fee per your settings?',
      backText: 'No',
      confirmText: 'Yes',
      confirmAction: this.confirmCancellation.bind(this),
      confirmActionParams: {applyFee: true, source},
      customBackAction: this.confirmCancellation.bind(this),
      customBackParams: {applyFee: false, source},
      sameRowButtons: false,
      confirmButtonClass: 'secondary'
    };

    const feeModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });

    feeModal.present();
  }

  async confirmCancellation(params: {applyFee: boolean, source: string}) {
    this.modalCtrl.dismiss();

    const date = this.tidyDatePipe.transform(this.jobData.date, 'dddd M/D');
    const time = this.timePipe.transform(this.jobData.start_time);
    const titleType = this.cancelType === 'job' ? 'Job' : 'Plan';
    const body = this.cancelType === 'job' ?
      `${new TranslationPipe().transform('Are you sure you want to cancel this job on')} ${date} ${new TranslationPipe().transform('at')} ${time}?` :
      `${new TranslationPipe().transform('Are you sure you want to cancel this plan with next cleaning on')} ${date} ${new TranslationPipe().transform('at')} ${time}?`;

    const modalParams: ConfirmPageParamsModel = {
      title: `${new TranslationPipe().transform('Cancel')} ${titleType}?`,
      body,
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: this.performCancellation.bind(this),
      confirmActionParams: params,
      confirmButtonClass: 'secondary'
    };
    const cancellationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: modalParams,
      animated: false
    });

    cancellationModal.present();
  }

  @Loading()
  async performCancellation(params: {applyFee: boolean, source: string}) {
    if (this.cancelType === 'job') {
      await this.privateClient.cancelPrivateJob(this.srcId, params.applyFee, params.source);
    } else {
      await this.privateClient.cancelPrivatePlan(this.srcId, params.applyFee, params.source);
    }

    this.modalCtrl.dismiss();

    this.onSuccessCancellation();
  }

  onSuccessCancellation() {
    const header = this.cancelType === 'job' ? 'Job Cancelled' : 'Plan Cancelled';
    const body = this.cancelType === 'job' ?
      'You can search for this Client to add new jobs.' :
      'You can search for this Client to add a new plan.';
    const buttonRoute = this.cancelType === 'job' ? 'jobs' : 'clients';
    const onSuccessParams: SuccessPageParamsModel = {
      header,
      body,
      buttonText: 'Ok',
      buttonRoute
    };
    this.customNavCtrl.navigateForward('success', onSuccessParams);
  }
}
