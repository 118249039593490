<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="loaded ? dispute.header_text : ''">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">

      <tidy-alert>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [header]="dispute?.header_text">
          </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let comment of dispute?.components[0]?.value; let last = last">
          <tidy-row  [ngClass]="!last ? 'extra-bottom-padding' : ''">
            <tidy-row>
              <tidy-text
                [body]="comment?.html">
              </tidy-text>
            </tidy-row>
          </tidy-row>
        </ng-container>
      </tidy-alert>

      <ng-container *ngIf="dispute?.type === 'dispute.homekeeper_dispute_initiated'">
        <tidy-button
          [text]="'Accept Dispute Proposal'"
          [action]="acceptDisputeProposal.bind(this)"
          class="primary">
        </tidy-button>
        <tidy-button
          [text]="'Send a Counter Proposal'"
          [action]="goToCounterDispute.bind(this)"
          class="secondary">
        </tidy-button>

        <tidy-row [align]="'center'">
          <tidy-text
            [header]="'Dispute Tips:'">
          </tidy-text>
        </tidy-row>

        <tidy-alert class="vertical-align-center" style="padding: 15px 15px 15px 15px">
          <tidy-row>
            <ion-row>
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/svg/split-money.svg'"
                  style="width:40px;height:50px">
                </tidy-image>
              </tidy-row>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'Try a partial resolution'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    body='Seeking "all" or "nothing" is often is rejected by the other party.'>
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-alert>

        <tidy-alert class="vertical-align-center" style="padding: 15px 15px 15px 15px">
          <tidy-row>
            <ion-row>
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/svg/shake-hands.svg'"
                  style="width:40px;height:50px">
                </tidy-image>
              </tidy-row>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'Be polite'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'Parties will be more likely to accept your resolution.'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-alert>
  
      </ng-container>

    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
