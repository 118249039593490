<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Future Jobs'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card *ngFor="let job of futureJobs">
      <tidy-card-button (click)="goToJob(job)" class="arrow-to-top">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/information-circle.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="job?.service_type_details?.name | titlecase">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="job?.service_type_details?.description">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center">
          <tidy-image
             [src]="'assets/svg/person-circle-outline.svg'"
             class="body-size">
          </tidy-image>
          <tidy-text
             [body]="'Booked with '">
           </tidy-text>
           <tidy-text
             [body]="(accountName && accountName !== '' ? accountName : (clientName | titlecase))">
           </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center">
          <tidy-image
             [src]="'assets/svg/cash-outline.svg'"
             class="body-size">
           </tidy-image>
           <tidy-text
            *ngIf="job?.service_type_details?.billing_type !== 'hourly' &&  job?.service_type_details?.billing_type !== 'price_later'"
             [body]="(job?.amount | tcurrency)">
           </tidy-text>
           <tidy-text
            *ngIf="job?.service_type_details?.billing_type !== 'hourly' &&  job?.service_type_details?.billing_type !== 'price_later'"
             [body]="' cost'">
           </tidy-text>
           <tidy-text
             *ngIf="job?.service_type_details?.billing_type == 'hourly'"
             [body]="(job?.amount | tcurrency)">
           </tidy-text>
           <tidy-text
             *ngIf="job?.service_type_details?.billing_type == 'hourly'"
             [body]="' / hour'">
           </tidy-text>
           <tidy-text
             *ngIf="job?.service_type_details?.billing_type == 'price_later'"
             [body]="'Price later'">
           </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center">
          <tidy-image
             [src]="'assets/svg/calendar-outline.svg'"
             class="body-size">
           </tidy-image>
          <tidy-text
            [body]="(job?.date | tdate:'dddd M/D/YY')">
          </tidy-text>
          <tidy-text
            [body]="' at '">
          </tidy-text>
          <tidy-text
            [body]="(job?.start_time | ttime)">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

    <tidy-alert *ngIf="futureJobs?.length === 0">
      <tidy-text
        [body]="clientName">
      </tidy-text>
      <tidy-text
        [body]="' has no future jobs at this address.'">
      </tidy-text>
    </tidy-alert>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
