import { Pipe, PipeTransform } from '@angular/core';
import cleaningTypeUtils from '../utils/cleaning-type-utils';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Pipe({
  name: 'tidyCleaningType'
})
export class CleaningTypePipe implements PipeTransform {

  transform(value: string): string {
    const cleaningType = `${value}`.toLowerCase();
    const duration = cleaningTypeUtils.getCleaningDuration(cleaningType);
    return duration ? this.formatHours(duration) : '';
  }

  private formatHours(duration: number) {
    const label = duration > 1 ? (new TranslationPipe().transform('hours')) : (new TranslationPipe().transform('hour'));
    return `${duration} ${label}`;
  }

}
