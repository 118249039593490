import { Component, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedLinksProvider } from 'src/providers/shared-links/shared-links';
import { Loading } from 'src/shared/components/loading/loading';
import { AddressService } from 'src/shared/providers/address';
import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';
import { ToDosProvider } from 'src/providers/to-dos/to-dos';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToDosComponent } from 'src/shared/components/to-dos/to-dos.component';
import { TidyStorage } from "src/shared/providers/tidy-storage";
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: 'shared-to-do-list.html',
  styleUrls: ['shared-to-do-list.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SharedToDoListPage {

  @ViewChild(ToDosComponent) toDosComponent: ToDosComponent;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenwidth = event.target.innerWidth
  }
  screenwidth: number;
  list: any;
  showContactButton: boolean = false;
  mapUrl: SafeUrl;
  map: any;
  mapItems: any;
  form: UntypedFormGroup;
  parkingPhotos: any;
  accessPhotos: any;
  closingPhotos: any;
  loaded: boolean = false;
  
  constructor(
    public route: ActivatedRoute,
    private sharedLinks: SharedLinksProvider,
    private addressService: AddressService,
    private iabUtils: InAppBrowserUtils,
    public toDos: ToDosProvider,
    private navCtrl: CustomNavController,
    private sanitazer: DomSanitizer,
    private fb: UntypedFormBuilder,
    private store: TidyStorage
  ) {
    this.screenwidth = window.innerWidth;
    this.form = this.fb.group({
      map: ['']
    });
  }

  async ionViewDidEnter() {
    this.loaded = false;
    const uuid = this.route.snapshot.paramMap.get('uuid');
    this.list = await this.store.retrieve(`sharedList/${uuid}`) || await this.sharedLinks.getSharedLink(uuid);
    await this.store.save(`sharedList/${uuid}`, this.list);
    this.showContactButton = !this.route.routeConfig.path.includes('shared');
    this.parkingPhotos = this.list.shared_link_data.address?.photo_notes.filter((photo) => photo.type == 'parking');
    this.accessPhotos = this.list.shared_link_data.address?.photo_notes.filter((photo) => photo.type == 'access');
    this.closingPhotos = this.list.shared_link_data.address?.photo_notes.filter((photo) => photo.type == 'closing');
    this.createAddressMap();
    this.loaded = true;
  }

  completeList() {
    this.toDosComponent.completeList();
  }

  @Loading()
  async getDirections() {
    await this.addressService.directions(this.list.shared_link_data.address.address);
  }

  goToLearnMore() {
    this.iabUtils.openUrl('https://help.tidy.com/to-do-list-links');
  }

  goToContactPage() {
    this.list.shared_link_data.address_task_list.address_rooms.map((room) => {
      room.tasks.map((task) => {
        task['customFieldForm'] = null;
      });
    });
    const payload = {
      data: this.list
    }
    this.navCtrl.navigateForward('shared-contact-client', payload)
  }

  changeMap(newUrl) {
    this.mapUrl = this.sanitazer.bypassSecurityTrustResourceUrl(newUrl);
  }

  createAddressMap() {
    if (this.list?.shared_link_data?.address?.address_map_renders.length > 0) {
      this.map = JSON.parse(this.list?.shared_link_data?.address?.address_map_renders[0]?.render_data);
      this.mapUrl = this.sanitazer.bypassSecurityTrustResourceUrl(this.map?.exterior?.url);
      this.mapItems = this.getMapItems();
      this.form.patchValue({map: this.mapItems[0]?.value});
    }
  }

  getMapItems() {
    return [
      {viewValue: 'Exterior', value: this.map?.exterior?.url},
       ...this.map.interior.floors.urls.map((url, i) =>
       {
          return { viewValue: new TranslationPipe().transform('Level') + ` ${i + 1}`, value: url };
      })];
  }
  
}