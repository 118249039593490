import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { AppConfig } from 'src/shared/providers/config';
import { HttpClient } from 'src/shared/providers/http/http-client';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { TidySession } from '../tidy-session/tidy-session';

@Injectable()
export class Auth {
  constructor(
    private cookieService: CookieService,
    private httpClient: HttpClient,
    private storage: TidyStorage,
    private tidySession: TidySession
  ) {}

  getToken(): Promise<any> {
    const url = 'api/v1/token';

    const credentials = this.addCredentials({ scope: 'certification', grant_type: 'client_credentials' });

    return this.httpClient.post(url, credentials)
      .then( (authResponse) => this.storeTokenData(authResponse.access_token, authResponse.token_type) );
  }

  async login(identity): Promise<any> {
    const track_data = this.getTrackData();
    const credentials = this.addCredentials(Object.assign({}, identity, {scope: 'homekeeper', grant_type: 'password'}));
    const url = 'api/v1/token';

    const authResponse = await this.httpClient.post(url, {...credentials, track_data});
    this.storeTokenData(authResponse.access_token, authResponse.token_type);
  }

  getTrackData() {
    return {
      cookie: this.cookieService.get('TidySession'),
      referrer:            this.tidySession.firstUrlViewed?.referrer,
      first_url_viewed:    this.tidySession.firstUrlViewed?.value,
      first_url_viewed_at: this.tidySession.firstUrlViewed?.viewedAt
    };
  }

  private addCredentials(identity): any {
    const utmSource = this.getTidyUtmSource();
    return Object.assign({}, {...identity, ...utmSource}, {
      client_id: AppConfig.CLIENT_DATA.client_id,
      client_secret: AppConfig.CLIENT_DATA.client_secret,
    });
  }

  private getTidyUtmSource() {
    const utmCookie = this.cookieService.get('__utmztidy');
    if (utmCookie) {
      const utmCookieData = JSON.parse(utmCookie);
      return {
        date_added_to_cookie: utmCookieData.dateTime,
        utm_source: utmCookieData.source
      };
    }

    return {};
  }

  private storeTokenData(accessToken, tokenType): void {
    this.storage.save('tidy_token', accessToken);
    this.storage.save('tidy_token_type', tokenType);
    localStorage.setItem('tidy_token', accessToken);
    localStorage.setItem('tidy_token_type', tokenType);
  }

  isAuthenticated(): boolean {
    return localStorage.getItem('tidy_token') != null && localStorage.getItem('tidy_token_type') != null;
  }
}
