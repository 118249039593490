import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { SuccessPageParamsModel } from 'src/pages/success/success';
import { MultipleCountryService } from 'src/providers/addresses/multiple-country.service';
import { Me } from 'src/shared/providers/me';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  selector: 'block-zipcode',
  templateUrl: 'block-zipcode.html'
})

export class ProfileBlockZipcodePage {

  errorMessage: string;
  form: UntypedFormGroup;
  submitted: boolean;

  constructor(
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private multipleCountryService: MultipleCountryService,
    private me: Me,
  ) {
    this.form = this.fb.group({
      zipcode: ['', this.multipleCountryService.getCountrySettings('US').validator],
      unassign: [false,]
    });
  }

  submit = async () => {
    this.errorMessage = '';
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    try {
      const data = {
        zipcode: this.form.value.zipcode,
        unassign_current_jobs: this.form.value.unassign
      }
      await this.me.disableZipcode(data);
      const params = this.mountSuccessPageParams(data);
      this.customNavCtrl.navigateForward('success', params);
    } catch (err) {
      this.errorMessage = err.error ? err.message : err.error.message;
    }
  }

  mountSuccessPageParams(data): SuccessPageParamsModel {
    return {
      header: `${new TranslationPipe().transform('Zipcode')} ${data.zipcode} ${new TranslationPipe().transform('Blocked')}`,
      body: `${new TranslationPipe().transform('Clients can no longer book jobs in this zipcode.')}`,
      buttonText: `${new TranslationPipe().transform('Ok')}`,
      buttonRoute: 'profile/location'
    };
  }
}
