import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

import { Certification } from 'src/providers/certification/certification';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Cleanings } from 'src/providers/cleanings/cleanings';
import { ClaimJobs } from 'src/providers/claim-jobs/claim-jobs';
import { Events } from 'src/providers/events/events';
import { Homekeepers } from 'src/providers/homekeepers/homekeepers';
import { Me } from 'src/shared/providers/me';
import { Team } from 'src/providers/team/team';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { AppConfig } from 'src/shared/providers/config';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm';
import { SuccessPageParamsModel } from 'src/pages/success/success';

import { CertificationsModel } from 'src/shared/models/certification.model';
import { ParsedExampleCoopModel, ExampleCoopModel } from 'src/shared/models/example-coop.model';
import { forkJoin } from 'rxjs';
import promiseUtils from 'src/shared/utils/promise-utils';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { AppState } from 'src/shared/providers/http/app-state';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { takeWhile } from 'rxjs/operators';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

declare const mapboxgl: any;

@Component({
  selector: 'add',
  templateUrl: 'add.html',
  styleUrls: ['./add.scss']
})
export class AddPage extends Timeout {

  addedZipcode: boolean;
  customBack: false;
  apiError: string;
  didGoLive: boolean;
  clientRequests: any;
  currentWeek: any;
  date: Date;
  delegationRequests: any;
  errorMessage: string;
  hasNoExampleCoops: boolean;
  homeLongitude: number;
  homeLatitude: number;
  isTimeOut = false;
  isLimitedAccount: boolean;
  jobMaps = [];
  legends: any;
  lms: any;
  lmsData: any;
  map: any = {};
  noItemsAvailable: boolean;
  examples: ParsedExampleCoopModel[];
  examplesLength: number;
  resumeSubscription;
  today: string;
  hasTeamMembers: boolean;

  constructor(
    private certification: Certification,
    private cleanings: Cleanings,
    private customNavCtrl: CustomNavController,
    private claimJobs: ClaimJobs,
    private events: Events,
    private homekeepers: Homekeepers,
    private me: Me,
    private modalController: ModalController,
    router: Router,
    private storage: TidyStorage,
    private team: Team,
    route: ActivatedRoute,
    timeoutErrorHandler: TimeoutErrorHandler,
    private appState: AppState
  ) {
    super(timeoutErrorHandler, router, route);
  }

  async ionViewDidEnter() {
    this.loaded = false;
    this.alreadyShowedOfflineOrTimeoutAlert = false;
    if (!await this.appState.isOnline()) {
      this.alreadyShowedOfflineOrTimeoutAlert = true;
      return this.timeoutErrorHandler.showOfflineAlert(this.successPageLoad, 'dashboard', this.retrySubject);
    }
    await this.getInitialData();
    this.loaded = true;
    if (!this.didGoLive && this.currentWeek?.days.length === 0 && this.currentWeek?.jobs.length === 0) {
      try {
        const examples: ExampleCoopModel[] = await this.certification.getPreCertificationCoops();
        this.examplesLength = examples.length;
        this.examples = this.parseExamples(examples);
        this.hasNoExampleCoops = this.examplesLength === 0;
      } catch (err) {
        this.hasNoExampleCoops = true;
      }
    }
    this.events.subscribe('coop-cleaning:claimed', () => this.modalController.dismiss());
    this.successPageLoad.emit(true);
    this.getClientRequests()
    this.fetchDelegations()
    this.getHkHomeData();
  }

  async getInitialData(): Promise<void> {
    try {
      this.isLimitedAccount = await this.storage.retrieve('isLimitedAccount');
      const [emergencyCleanings, certification] = await Promise.all([
        this.homekeepers.fetchLmsNoCache(),
        this.certification.getCertificationsNoCache(),
      ]);
      this.handleEmergencyCleanings(emergencyCleanings);
      this.handleCertification(certification);
      this.today = moment().format('YYYY-MM-DD');
      Promise.resolve();
    } catch(err) {
      this.timeoutHandler(err)
      this.showMessage(err)
      Promise.reject(err);
    }
  }

  handleCertification(certification: CertificationsModel) {
    this.addedZipcode = certification.steps.profile == 'complete';
    this.didGoLive = certification.steps.go_live === 'complete';
  }

  async fetchDelegations() {
    try {
      this.delegationRequests = await this.cleanings.fetchDelegations();
    } catch (err) {
      this.timeoutHandler(err)
      this.showMessage(err)
    }
  }

  async fetchCleaning(date: string): Promise<any> {
    const activeStandbyCleanings = await this.cleanings.getActiveStandbyCleanings(date);
    return activeStandbyCleanings.map((item) => {
      return {
        date: item.date,
        startTime: moment(item.start_time, 'HH:mm').format('h:mma'),
        latitude: item.address.latitude,
        longitude: item.address.longitude
      }
    });
  }

  checkIfHasGenderSpecificJobs() {
    let hasGenderSpecificJobs = false;
    this.currentWeek.days.map((day) => {
      day.cleanings.map((cleaning) => {
        if (cleaning?.homekeeper_preferences?.gender == 'female' ||  cleaning?.homekeeper_preferences?.gender == 'male') {
          hasGenderSpecificJobs = true;
        }
      });
    });
    return hasGenderSpecificJobs;
  }

  async getHkHomeData(): Promise<Array<number>> {
    const hk = await this.me.load();
    this.homeLongitude = hk.user.longitude;
    this.homeLatitude = hk.user.latitude;
    return [this.homeLongitude, this.homeLatitude];
  }

  async awaitMapElement(mapId, atempts = 1) {
    const mapElement = document.getElementById(mapId);

    if(!mapElement && atempts < 4) {
      await promiseUtils.sleep(300);
      await this.awaitMapElement(mapId, (atempts + 1));
    }
  }

  getCenterCoordinates(cards: any[], hasHome: boolean) {
    let centerLongitude: number;
    let centerLatitude: number;

    if (hasHome) {
      centerLongitude = this.homeLongitude;
      centerLatitude = this.homeLatitude;
    } else {
      let totalLongitude = 0;
      let totalLatitude = 0;
      cards.forEach((cleaning) => {
        totalLongitude += cleaning.address?.longitude;
        totalLatitude += cleaning.address?.latitude;
      });
      centerLongitude = totalLongitude / cards.length;
      centerLatitude = totalLatitude / cards.length;
    }
    return { centerLatitude, centerLongitude };
  }

  async setMap(item, index) {
    try {
      const scheduledCleanings = await this.fetchCleaning(item.formatedDate);
      this.legends = this.getDayLegend(scheduledCleanings);
      if(item?.showMap != undefined && item.showMap === false){
        this.currentWeek.days[index].showMap = true;
        return;
      }

      const hasHome = this.homeLongitude !== null && this.homeLongitude !== undefined;
      const { centerLatitude, centerLongitude } = this.getCenterCoordinates(item.cards, hasHome);

      mapboxgl.accessToken = AppConfig.MAPBOX_KEY;
      const mapElementId = `map${index}`;
      await this.awaitMapElement(mapElementId);

      const map = new mapboxgl.Map({
        container: mapElementId,
        style: 'mapbox://styles/mapbox/light-v10',
        center: [ centerLongitude, centerLatitude ],
        zoom: 3,
        maxZoom: 11,
        minZoom: 3
      });
      let bounds = new mapboxgl.LngLatBounds();
      scheduledCleanings.forEach((cleaning, i) => {
        if (cleaning.date === item.formatedDate) {
          this.addScheduledMarker(cleaning, map, i);
        }
      });
      item.cleanings.forEach( (cleaning, i) => {
        this.addOppMarker(cleaning, map, i);
        bounds.extend([cleaning.longitude, cleaning.latitude]);
      });
      if (hasHome) {
        this.addHomeMarker(map);
        bounds.extend([this.homeLongitude, this.homeLatitude]);
      }
      map.fitBounds(bounds, {
        padding: 20,
        maxZoom: 11,
        animate: false
      });
      this.currentWeek.days[index].showMap = true;
    }
    catch (err) {
      console.error(err);
      this.timeoutHandler(err)
      this.showMessage(err)
    }
  }

  hideExampleMap(index){
    this.examples[index].showMap = false;
  }

  getDayLegend(scheduledCleanings: any[]) {
    const legends = this.currentWeek.days.map(day => {
      const times = scheduledCleanings.map((cleaning) => {
        if (day.formatedDate === cleaning.date) {
          return cleaning.startTime;
        }
      });
      return times.filter((time) => time);
    });
    return legends;
  }

  addScheduledMarker(cleaning, map, i) {
    const el = document.createElement('div');
    el.className = 'marker';
    el.innerHTML = `<span class="map-marker-date">${cleaning.startTime}</span>`;
    this.createMarkerInstance({map, el, longLat: [ cleaning.longitude, cleaning.latitude ]});
  }

  async addHomeMarker(map) {
    const el = document.createElement('div');
    el.className = 'marker';
    el.innerHTML = `<image src="assets/img/home-black.svg" style="height: 30px"></image>`;
    this.createMarkerInstance({map, el, longLat: [ this.homeLongitude, this.homeLatitude ]});
  }

  addOppMarker(cleaning, map, i) {
    const el = document.createElement('div');
    const letter = String.fromCharCode(65+i);
    el.className = 'marker';
    el.innerHTML = `<span class="map-marker" style="margin-top: ${i * 2}0px">${letter}</span>`;
    this.createMarkerInstance({map, el, longLat: [ cleaning.longitude, cleaning.latitude ]})
  }

  createMarkerInstance(obj){
    const {el, longLat, map} = obj;
    new mapboxgl.Marker(el)
      .setLngLat(longLat)
      .addTo(map);
  }

  async handleEmergencyCleanings(cleanings: any[]): Promise<void> {
    try {
      this.noItemsAvailable = (cleanings.length === 0);
      const data = await this.claimJobs.parseDays(cleanings);
      this.currentWeek = data.currentWeek;
      this.noItemsAvailable = data.noItemsAvailable;
      const hasGenderSpecificJobs = this.checkIfHasGenderSpecificJobs();
      if (hasGenderSpecificJobs) {
        this.hasTeamMembers = await this.team.getIfHasTeamMembers();
      }
    } catch (err) {
      this.isTimeOut = (err.name === 'TimeoutError');
      this.timeoutHandler(err)
      this.showMessage(err)
    }
  }

  async getClientRequests() {
    try {
      const clientRequests = await this.homekeepers.getClientRequests();
      this.clientRequests = clientRequests.map((item) => {
        item.request_times = item.request_times.reduce((accumulator, currentItem) => {
          accumulator[currentItem.date] = [...accumulator[currentItem.date] || [], currentItem];
          return accumulator;
        }, {});
        item.request_times = Object.keys(item.request_times).map( (i) => item.request_times[i]);
        return item;
      });
    } catch (err) {
      this.timeoutHandler(err)
      this.showMessage(err)
    }
  }

  parseExamples(examples: ExampleCoopModel[]): ParsedExampleCoopModel[] {
    const days = [];
    const today = new Date();
    const todayNumber = today.getDay();
    for (let i=0; i<7; i++) {
      const day = (i === 0) ? today.getDate() : (today.getDate() + 1);
      let accNumber = todayNumber + i;
      if (accNumber > 7) {
        accNumber -= 7;
      }
      const parsedExamples = [];
      examples.map((example) => {
        if (example.day_of_week === accNumber) {
          parsedExamples.push(example);
        };
      });
      parsedExamples.sort((a,b) => {
        return parseInt(a.time, 10) - parseInt(b.time, 10);
      });
      days.push({
        day: today.setDate(day),
        examples: parsedExamples
      });
    }
    this.setExamplesMap(days[0], 0);
    return days;
  }

  async setExamplesMap(day, index) {
    if(day?.showMap != undefined && day.showMap === false){
      this.examples[index].showMap = true;
      return;
    }
    const hkHomeCoords = await this.getHkHomeData();
    mapboxgl.accessToken = AppConfig.MAPBOX_KEY;
      const map = new mapboxgl.Map({
        container: `map${index}`,
        style: 'mapbox://styles/mapbox/light-v10',
        center: hkHomeCoords,
        zoom: 5,
        maxZoom: 11,
        minZoom: 5
      });
      day.examples.forEach( (example, i) => {
        this.addExampleOppMarker(example, map, i);
      });
      if (this.homeLongitude !== null && this.homeLongitude !== undefined) {
        this.addHomeMarker(map);
      }
      this.examples[index].showMap = true;
  }

  addExampleOppMarker(example, map, i) {
    const el = document.createElement('div');
    const letter = String.fromCharCode(65+i);
    el.className = 'marker';
    el.innerHTML = `<span class="map-marker" style="margin-top: ${i * 2}0px">${letter}</span>`;
    this.createMarkerInstance({map, el, longLat: [ example.avg_longitude, example.avg_latitude ]})
  }

  async showExampleRequestAlert() {
    const successParams: SuccessPageParamsModel = {
      header: 'Finish Getting Started to Use This',
      body: 'Want to get Clients from TIDY?  First you\'ll need to get certified to get Clients from TIDY.  It\'s quick, easy and free!',
      buttonText: 'View Getting Started Steps in the Dashboard',
      buttonRoute: `dashboard`
    };
    this.customNavCtrl.navigateForward('success', successParams);
  }

  navigateToCleaning(cleaning) {
    const params = {
      cleaning,
      homeLongitude: this.homeLongitude,
      homeLatitude: this.homeLatitude,
      jobsFromSameClient: this.claimJobs.getJobsFromSameClient(this.currentWeek, cleaning),
      jobsGroupedByJobId: this.currentWeek.jobs,
      hasTeamMembers: this.hasTeamMembers
    }
    this.customNavCtrl.navigateForward('claim-job', params);
  }

  navigateToAcceptRequest(request) {
    this.customNavCtrl.navigateForward('client-request', request);
  }

  navigateToProposeAlternateRequest(request) {
    this.customNavCtrl.navigateForward('client-request-counter', request);
  }

  ionViewWillLeave(): void {
    this.loaded = true;
    if (this.resumeSubscription) {
      this.resumeSubscription.unsubscribe();
    }
  }

  addClient() {
    this.customNavCtrl.navigateForward('add-private-client');
  }

  addJob() {
    this.customNavCtrl.navigateForward('add-private-job');
  }

  sendProposal() {
    this.customNavCtrl.navigateForward('send-proposal');
  }

  addAccountInfo() {
    this.customNavCtrl.navigateForward('account-info', {finishPage: 'add'});
  }

  async declineRequest(request) {
    const params: ConfirmPageParamsModel = {
      title: 'Decline Request?',
      body: `${new TranslationPipe().transform('Notify')} ${request.address.customer.first_name} ${new TranslationPipe().transform('that you cannot help them at those times, but they are free to book you on your current bookable schedule.')}`,
      backText: 'Go Back',
      confirmText: 'Decline Request',
      confirmAction: this.confirmDeclineRequest.bind(this),
      confirmActionParams: request.id,
    };
    const confirmationModal = await this.modalController.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });
    confirmationModal.present();
  }

  async confirmDeclineRequest(id) {
    await this.homekeepers.declineClientRequest(id);
    const successParams: SuccessPageParamsModel = {
      header: 'Request Declined',
      body: 'We have sent a notification to this Client.',
      buttonText: 'Ok',
      buttonRoute: `add`
    };
    this.modalController.dismiss();
    this.customNavCtrl.navigateForward('success', successParams);
  }

  async cancelCounterRequest(request) {
    const params: ConfirmPageParamsModel = {
      title: 'Cancel Request?',
      body: `${new TranslationPipe().transform('Notify')} ${request.address.customer.first_name} ${new TranslationPipe().transform('that you cannot help them at those times, but they are free to book you on your current bookable schedule.')}`,
      backText: 'Go Back',
      confirmText: 'Cancel Request',
      confirmAction: this.confirmCancelCounterRequest.bind(this),
      confirmActionParams: request.id,
    };
    const confirmationModal = await this.modalController.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });
    confirmationModal.present();
  }

  async confirmCancelCounterRequest(id) {
    await this.homekeepers.cancelCounterRequest(id);
    const successParams: SuccessPageParamsModel = {
      header: 'Request Cancelled',
      body: 'We have sent a notification to this Client.',
      buttonText: 'Ok',
      buttonRoute: `add`
    };
    this.modalController.dismiss();
    this.customNavCtrl.navigateForward('success', successParams);
  }

  goToNewClients() {
    this.customNavCtrl.navigateForward('new-clients');
  }

  goToDriveTime() {
    this.customNavCtrl.navigateForward('profile/location');
  }

  goToRates() {
    this.customNavCtrl.navigateForward('services');
  }

  parseServiceLength(name) {
    const lastIndex = name.lastIndexOf(" ");
    return name.substring(0, lastIndex);
  }

  showMessage(err) {
    this.errorMessage = err?.error?.message ?? err?.message;
  }

  goToDelegationRequest(delegation) {
    const params = {
      updateId: delegation.job_data.homekeeper_update_id,
      requestId: delegation.id,
      delegatorName: delegation.delegated_by_team_member.homekeeper.name.substr(0,delegation.delegated_by_team_member.homekeeper.name.indexOf(' ')),
      jobDate: delegation.job_data.friendly_date,
      jobTime: delegation.job_data.friendly_start_time,
      jobDuration: delegation.job_data.duration_in_hours,
      cameFromAddPage: true
    };
    this.customNavCtrl.navigateForward('accept-reject-job', params);
  }
}
