<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="false"
    [title]="'Action Needed'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'You have failed to show up to jobs for at least 2 days. Your account settings have set to automatically clear your schedule 48 hours in the future until your account is unlocked.'">
      </tidy-text>
    </tidy-row>

    <ng-container *ngIf="!nextStep">
      <tidy-button
        [text]="'I Want to Continue Getting Clients from TIDY'"
        [action]="next.bind(this)"
        class="primary">
      </tidy-button>
    </ng-container>

    <ng-container *ngIf="nextStep">
      <tidy-card>
        <form [formGroup]="form" novalidate action="#">
          <tidy-row class="field-top-padding">
            <tidy-textarea
              formControlName="issue"
              [form]="form.controls.issue"
              [label]="'Let us know what happened'"
              [submitted]="submitted"
              [form]="form"
              [icon]="'assets/svg/help-circle-outline.svg'"
              [activeIcon]="'assets/svg/help-circle-outline-tidy-green.svg'"
              [errorMessage]="'Please describe what happened'">
            </tidy-textarea>
         </tidy-row>
       </form>
      </tidy-card>

      <tidy-button
        [text]="'Submit and Become Active Again'"
        [action]="submit.bind(this)"
        class="primary">
      </tidy-button>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
