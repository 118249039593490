<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Website URL'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <form [formGroup]="form" novalidate action="#">
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
      <tidy-card>
        <tidy-row class="field-top-padding">
          <tidy-input
            [label]="'www.tidy.com/'"
            [icon]="'assets/svg/globe-outline.svg'"
            [activeIcon]="'assets/svg/globe-outline-tidy-green.svg'"
            formControlName="url"
            type="url"
            [form]="form.controls.url"
            [submitted]="submitted"
            [errorMessage]="formErrorMessage">
          </tidy-input>
        </tidy-row>
      </tidy-card>

      <tidy-error-message
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-button
        [action]="save.bind(this)"
        [text]="'Save Changes'"
        class="primary">
      </tidy-button>
    </form>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
