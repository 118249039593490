<ng-template #content>
  <tidy-wrapper class="vertical-center hasnt-top-nav">
    <tidy-card>
      <tidy-row [align]="'center'" class="extra-bottom-padding">
        <tidy-text
          header="Error during synchronization">
        </tidy-text>
      </tidy-row>
      <tidy-row [align]="'center'" class="extra-bottom-padding">
        <tidy-text
          body="There was a &quottimeout error&quot. Most of the time this means a bad internet connection so please try again. Please contact us in the Concierge section if you think it is a different issue.">
        </tidy-text>
      </tidy-row>

      <tidy-row>
        <tidy-button
          text="Retry"
          [action]="retry.bind(this)"
          class="primary">
        </tidy-button>
      </tidy-row>

      <tidy-row>
        <tidy-button
          text="Exit to Dashboard"
          [action]="goToDashBoard.bind(this)"
          class="secondary">
        </tidy-button>
      </tidy-row>
    </tidy-card>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
