import { Component } from '@angular/core';

import { Certification } from 'src/providers/certification/certification';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { CertificationsModel } from 'src/shared/models/certification.model';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

@Component({
  selector: 'start-earning',
  templateUrl: './start-earning.html',
})
export class StartEarningPage extends Timeout {

  title: string;
  content: string[];
  certified: boolean;

  constructor(
    private certification: Certification,
    private customNavCtrl: CustomNavController,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
  }

  async ionViewDidEnter() {
    try {
      const certification: CertificationsModel = await this.certification.getCertifications();
      this.certified = certification.steps.go_live === 'complete';
    } catch (err) {
      this.timeoutHandler(err);
    }
    this.getContent();
  }

  getContent() {
    if (this.certified) {
      this.title = 'Top Ways To Earn';
      this.content = [
        '(1) Improve your Quality Scores. Clients tend to book with pros with great scores.',
        '(2) Make rates reasonable. Try lowering your rate. You can always raise them again after boosting your scores.',
        '(3) Accept credit card. Many customers love paying by credit card so accepting them can increase business. ',
        '(4) Collect reviews. Get your existing Clients to review you to make your business look better.',
        '(5) Increase your availability. Make yourself bookable during the times Clients most want.',
        '(6) Increase max drive time. Drive further to get more business, you can shrink it later after you boost your scores.'
      ];
    } else {
      this.title = 'Get Clients to Earn';
      this.content = [
        'Clients from TIDY are a great way to get new Clients and make more money.',
        '(1) Complete a certification process, then Clients who use TIDY can book directly on your schedule or request you.',
        '(2) You set your own rates, max driving distance, schedule, and other terms.',
        '(3) Automatically compete to win new Clients based on your scores and rates.',
        'It\’s easy with no upfront costs. Just pay a part of what you would earn.'
      ];
    }
  }

  goToNewClients() {
    this.customNavCtrl.navigateForward('new-clients');
  }
}
