import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectModel } from '@tidyapp/tidy-ui-components';
import * as moment from 'moment';

@Injectable()
export class StaticData {

  buildTimeList(start: string, minutesInterval: number, quantity: number): any[] {
    const startTime = moment(start, 'HH:mm');
    const times = [];
    for (let idx = 0; idx < quantity; idx++) {
      times.push({
        value: startTime.format('HH:mm'),
        name: startTime.format('h:mma'),
      });
      startTime.add(minutesInterval, 'minutes');
    }
    return times;
  }

  buildDateList(startDate: moment.Moment, daysInterval: number, quantity: number, format: string = 'ddd MMM Do'): any[] {
    const dates = [];
    for (let idx = 0; idx < quantity; idx++) {
      dates.push({
        displayFormat: moment(startDate).format(format),
        internationalFormat: moment(startDate).format('YYYY[-]M[-]D')
      });
      startDate.add(daysInterval, 'days');
    }
    return dates;
  }

  buildPossibleTimes(): Array<any> {
    return [
      { value: '08:00', label: '8:00am-9:00am', labelStart: '8am', labelEnd: '9am', checked: false },
      { value: '09:30', label: '9:30am-10:30am', labelStart: '9:30am', labelEnd: '10:30am', checked: false },
      { value: '11:00', label: '11:00am-12:00pm', labelStart: '11am', labelEnd: '12pm', checked: false },
      { value: '12:30', label: '12:30pm-1:30pm', labelStart: '12:30pm', labelEnd: '1:30pm', checked: false },
      { value: '14:00', label: '2:00pm-3:00pm', labelStart: '2pm', labelEnd: '3pm', checked: false },
      { value: '15:30', label: '3:30pm-4:30pm', labelStart: '3:30pm', labelEnd: '4:30pm', checked: false },
      { value: '17:00', label: '5:00pm-6:00pm', labelStart: '5pm', labelEnd: '6pm', checked: false },
      { value: '18:30', label: '6:30pm-7:30pm', labelStart: '6:30pm', labelEnd: '7:30pm', checked: false },
      { value: '20:00', label: '8:00pm-9:00pm', labelStart: '8pm', labelEnd: '9pm', checked: false },
      { value: '21:30', label: '9:30pm-10:30pm', labelStart: '9:30pm', labelEnd: '10:30pm', checked: false },
    ];
  }

  timeRanges(): Array<SelectModel> {
    return [
      {viewValue: '12:00am', value: '00:00'},
      {viewValue: '12:30am', value: '00:30'},
      {viewValue: '1:00am', value: '01:00'},
      {viewValue: '1:30am', value: '01:30'},
      {viewValue: '2:00am', value: '02:00'},
      {viewValue: '2:30am', value: '02:30'},
      {viewValue: '3:00am', value: '03:00'},
      {viewValue: '3:30am', value: '03:30'},
      {viewValue: '4:00am', value: '04:00'},
      {viewValue: '4:30am', value: '04:30'},
      {viewValue: '5:00am', value: '05:00'},
      {viewValue: '5:30am', value: '05:30'},
      {viewValue: '6:00am', value: '06:00'},
      {viewValue: '6:30am', value: '06:30'},
      {viewValue: '7:00am', value: '07:00'},
      {viewValue: '7:30am', value: '07:30'},
      {viewValue: '8:00am', value: '08:00'},
      {viewValue: '8:30am', value: '08:30'},
      {viewValue: '9:00am', value: '09:00'},
      {viewValue: '9:30am', value: '09:30'},
      {viewValue: '10:00am', value: '10:00'},
      {viewValue: '10:30am', value: '10:30'},
      {viewValue: '11:00am', value: '11:00'},
      {viewValue: '11:30am', value: '11:30'},
      {viewValue: '12:00pm', value: '12:00'},
      {viewValue: '12:30pm', value: '12:30'},
      {viewValue: '1:00pm', value: '13:00'},
      {viewValue: '1:30pm', value: '13:30'},
      {viewValue: '2:00pm', value: '14:00'},
      {viewValue: '2:30pm', value: '14:30'},
      {viewValue: '3:00pm', value: '15:00'},
      {viewValue: '3:30pm', value: '15:30'},
      {viewValue: '4:00pm', value: '16:00'},
      {viewValue: '4:30pm', value: '16:30'},
      {viewValue: '5:00pm', value: '17:00'},
      {viewValue: '5:30pm', value: '17:30'},
      {viewValue: '6:00pm', value: '18:00'},
      {viewValue: '6:30pm', value: '18:30'},
      {viewValue: '7:00pm', value: '19:00'},
      {viewValue: '7:30pm', value: '19:30'},
      {viewValue: '8:00pm', value: '20:00'},
      {viewValue: '8:30pm', value: '20:30'},
      {viewValue: '9:00pm', value: '21:00'},
      {viewValue: '9:30pm', value: '21:30'},
      {viewValue: '10:00pm', value: '22:00'},
      {viewValue: '10:30pm', value: '22:30'},
      {viewValue: '11:00pm', value: '23:00'},
      {viewValue: '11:30pm', value: '23:30'}
    ];
  }

  selectedTimesText(timeBlocks: Array<{start_time: string, end_time: string}>): string {
    return timeBlocks.reduce((prev, curr) => {
      return `${prev}${prev ? ', ' : ''}${this.formatMilitaryTime(curr.start_time)}-${this.formatMilitaryTime(curr.end_time)}`;
    },'');
  }

  formatMilitaryTime(militaryTime) {
    return moment(militaryTime, 'HH:mm').format('hh:mma');
  }

  buildWeekDays(): Array<any> {
    return [
      { day: 'Monday', availability: [] },
      { day: 'Tuesday', availability: [] },
      { day: 'Wednesday', availability: [] },
      { day: 'Thursday', availability: [] },
      { day: 'Friday', availability: [] },
      { day: 'Saturday', availability: [] },
      { day: 'Sunday', availability: [] }
    ];
  }

  getNextWeekStart(): moment.Moment {
    const today = moment();
    const daystoMonday = 0 - (today.isoWeekday() - 1) + 7;
    const nextMonday = today.add(daystoMonday, 'days');

    return nextMonday;
  }

  buildSUCQPickTimeOptions() {
    const dates = Array.apply(null, Array(3)).map( (_, i) => this.createDay(i + 1));
    const times = Array.apply(null, Array(4)).map( (_, i) => this.createTime(i));

    return [
      ...this.todayOptions(),
      ...this.crossDateTimes(dates, times)
    ];
  }

  todayOptions() {
    const todaySlotsPerHour = [4, 4, 4, 4, 4, 3, 3, 3, 2, 2, 2, 1, 1, 1];
    const currentHour = moment().hour();
    const todayTotal = todaySlotsPerHour[currentHour] || 0;
    const times = Array.apply(null, Array(4)).map( (_, i) => this.createTime(i)).slice(4 - todayTotal);

    return this.crossDateTimes([{ label: 'Today', date: moment().format('YYYY-MM-DD') }], times);
  }

  crossDateTimes(dates, times) {
    const expandArray = (accumalator, current) => [...accumalator, ...current];

    const mappedDateTimes = dates.map( day => {
      const createDateTime = time => ({label: `${day.label}, ${time.label}`, value: `${day.date}, ${time.time}`});
      return times.map( createDateTime );
    });

    return mappedDateTimes.reduce( expandArray, [] );
  }

  createDay = (plusDays = 0) => ({
    label: moment().add('days', plusDays).format('M/D'),
    date: moment().add('days', plusDays).format('YYYY-MM-DD')
  })

  createTime = (slot = 0) => ({
    label: moment(`${8 + (slot * 3)}:00`, 'H:mm').format('ha'),
    time: moment(`${8 + (slot * 3)}:00`, 'H:mm').format('HH:mm'),
  })

}
