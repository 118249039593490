import { Component, Input, ViewEncapsulation } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { bottomMenuItems } from 'src/shared/components/bottom-nav/bottom-nav-items';

@Component({
  selector: 'tidy-bottom-nav',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./bottom-nav.component.scss'],
  template: `
    <nav>
      <ul class="menu">
        <li
          tidyRipple
          class="menu-item"
          [attr.data-cy]="item.label"
          *ngFor="let item of menuItems"
          (click)="navigateTo(item.target)">
          <tidy-image
            class="no-right-margin menu title-size"
            [class.green]="isActiveItem(item)"
            [src]="(isActiveItem(item) ? item.activeIcon : item.icon)">
          </tidy-image>
          <span class="item-menu" [class.active]="isActiveItem(item)">
            {{item.label}}
          </span>
        </li>
      </ul>
    </nav>
  `
})
export class BottomNavComponent {

  @Input() activeRoute: string;
  menuItems = bottomMenuItems;

  constructor(
    private customNavController: CustomNavController
  ) {}

  navigateTo(url) {
    this.customNavController.navigateForward(url);
  }

  isActiveItem(item) {
    return this.activeRoute === item.target;
  }

}
