import { Component } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Team } from 'src/providers/team/team';

import { TeamServiceTypeModel } from 'src/shared/models/team.model';
import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';
import { ServiceTypeModel } from 'src/shared/models/team.model';

import { Loading } from 'src/shared/components/loading/loading';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { Timeout } from 'src/shared/components/timeout/timeout';

@Component({
  selector: 'services',
  templateUrl: './services.html'
})
export class ServicesPage extends Timeout {

  categories: ServiceTypeModel[]
  categoryItems: TidySelectNumberValueModel[];
  didNotSelectServiceError: boolean;
  errorMessage: any;
  teamServiceTypes: TeamServiceTypeModel[];
  serviceTypesModel: any = {};

  constructor(
    private customNavCtrl: CustomNavController,
    public team: Team,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
  }

  @Loading()
  async ionViewDidEnter() {
    try {
      this.loaded = false;
      this.teamServiceTypes = await this.team.getTeamServiceTypes();
      this.categoryItems = await this.getCategoryItems();
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  async getCategoryItems() {
    this.categories = await this.team.getServiceTypes();
    const array = this.categories.map((type) => {
      this.serviceTypesModel[type.id] = false;
      return {
        viewValue: type.name == 'Cleaning' ? 'Cleaning (most common)' : type.name,
        value: type.id
      };
    });
    const CleaningTypeId = 1;
    this.serviceTypesModel[CleaningTypeId] = true;
    return array;
  }

  async save() {
    const didSelectService = Object.values(this.serviceTypesModel).find((selectedService) => {
      return selectedService;
    });
    if (!didSelectService) {
      return this.didNotSelectServiceError = true;
    }
    const chosenCategories = this.getChosenCategories();
    try {
      await this.team.addTeamServiceTypes({ service_type_ids: chosenCategories });
      this.teamServiceTypes = await this.team.getTeamServiceTypes();
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  getChosenCategories() {
    const categoryIds = Object.keys(this.serviceTypesModel);
    const chosenIds = categoryIds.filter(catedoryId => this.serviceTypesModel[catedoryId]);
    return chosenIds.map(id => parseInt(id));
  }

  goToAddService() {
    const params = {
      categories: this.categories
    }
    this.customNavCtrl.navigateForward('add-service', params);
  }

  goToService(teamService) {
    this.customNavCtrl.navigateForward('service', {teamService: teamService});
  }

  addNewCategory() {
    this.customNavCtrl.navigateForward('');
  }

  goToRequestNewServiceType() {
    this.customNavCtrl.navigateForward('upcoming-feature', {feature: 'other_services'});
  }
  
}
