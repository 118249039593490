import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'ttime'
})
export class TimePipe implements PipeTransform {

  transform(value: string | moment.Moment): string {
    if (value) {
      return moment(value, 'HH:mm').format('h:mma');
    }
  }
}
