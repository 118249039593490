<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [customBack]="'more'"
    [title]="'My Account'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card>
      <tidy-card-button (action)="deleteAccount()">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/trash-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Delete My Account'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
