import { CommonUtils } from './../../utils/common-utils';
import { Injectable } from '@angular/core';
import { Camera as CameraPlugin, Photo, ImageOptions } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';

@Injectable()
export class Camera {

  constructor(private util: CommonUtils) {}

  async getPhoto(options: ImageOptions): Promise<Photo> {
    try {
      const cameraPhoto: Photo = await CameraPlugin.getPhoto(options);
      console.log('cameraPhoto', cameraPhoto);
      cameraPhoto.format = cameraPhoto.format.includes('heic') ? 'jpeg' : cameraPhoto.format;
      const imageHead = `data:image/${cameraPhoto.format};base64,`;
      const dataUrl = imageHead + cameraPhoto.base64String;

      const resizedImage: string = await this.resizeImage(
        dataUrl,
        cameraPhoto.format
      );
      cameraPhoto.dataUrl = resizedImage;
      cameraPhoto.base64String = resizedImage.replace(imageHead, '');

      return cameraPhoto;
    } catch (error) {
      const errorMessage = error?.error?.message ?? error?.message;
      this.util.showError('Error on get photo: ' + errorMessage);
      console.error(error);
      return null;
    }
  }

  resizeImage(
    dataUrl: string,
    format: string = null,
    quality: number = 0.5
  ): Promise<string> {
    if (typeof dataUrl !== 'string') {
      return dataUrl;
    }
    if (format === 'heic') {
      format = 'jpeg';
    }
    if (dataUrl.includes('heic')) {
      dataUrl = dataUrl.replace('heic', 'jpeg');
    }
    format = format ?? this.extractFormat(dataUrl);
    const img = document.createElement('img') as HTMLImageElement;
    img.src = dataUrl;

    return new Promise((resolve, reject) => {
      img.onload = (e) => {
        const canvas = document.createElement('canvas');

        const MAX_WIDTH = 1080;
        const MAX_HEIGHT = 1080;
        let width = img.width;
        let height = img.height;

        // Calculate aspect ratio
        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        const dataUrl: string = canvas.toDataURL(`image/${format}`, quality);
        resolve(dataUrl);
      };

      img.onerror = (err) => {
        console.error('error on resize image', err)
        resolve(dataUrl);
      };
    });
  }

  async getBlob(dataUrl: string): Promise<Blob> {
    const response = await fetch(dataUrl);
    return response.blob();
  }

  async blobToBase64(blob: Blob): Promise<string> {
    if (Capacitor.isNativePlatform()) {
      const arrayBuffer = await blob.arrayBuffer();
      const base64 = this.arrayBufferToBase64(arrayBuffer);
      return `data:${blob.type};base64,${base64}`;
    } else {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    }
  }

  private arrayBufferToBase64(buffer: ArrayBuffer): string {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  extractFormat(dataUrl: string): string {
    return dataUrl.substring('data:image/'.length, dataUrl.indexOf(';base64'));
  }
}
