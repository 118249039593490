<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Background Check'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="loaded">

      <ng-container *ngIf="!backgroundCheckPending">
        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'In order to get Clients from TIDY you\'ll need to complete a background check.
            TIDY uses a third party called TURN for background checks, the email will come from a @turning.io email.'">
          </tidy-text>
        </tidy-row>

        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'Please confirm detail about you before initiating a background check:'">
          </tidy-text>
        </tidy-row>

        <tidy-card>
          <form [formGroup]="form" novalidate action="#">
            <tidy-row class="extra-bottom-padding">
              <tidy-radio-button
                [label]="'Can you accept direct deposits?'"
                [linkText]="'Learn More'"
                (linkAction)="learnMoreDirectDeposits()"
                formControlName="directDeposit"
                [form]="form.controls.directDeposit"
                [items]="[{viewValue: 'Yes', value: 'yes'},{viewValue: 'No', value: 'no'}]"
                [selectError]="form.value.directDeposit === 'no'"
                [selectMessage]="'You must be setup to accept direct deposits to accept payments.'"
                [submitted]="submitted"
                [errorMessage]="'Please select an answer.'">
              </tidy-radio-button>
            </tidy-row>
            <tidy-row class="extra-bottom-padding">
              <tidy-radio-button
                [label]="'Do you have a smartphone?'"
                formControlName="smartPhone"
                [form]="form.controls.smartPhone"
                [items]="[{viewValue: 'Yes', value: 'yes'},{viewValue: 'No', value: 'no'}]"
                [selectError]="form.value.smartPhone === 'no'"
                [selectMessage]="'You must have a smartphone.'"
                [submitted]="submitted"
                [errorMessage]="'Please select an answer.'">
              </tidy-radio-button>
            </tidy-row>
            <tidy-row class="extra-bottom-padding">
              <tidy-radio-button
                [label]="'Do you have a car?'"
                formControlName="car"
                [form]="form.controls.car"
                [items]="[{viewValue: 'Yes', value: 'yes'},{viewValue: 'No', value: 'no'}]"
                [selectError]="form.value.car === 'no'"
                [selectMessage]="'You must have a car.'"
                [submitted]="submitted"
                [errorMessage]="'Please select an answer.'">
              </tidy-radio-button>
            </tidy-row>
            <tidy-row class="extra-bottom-padding">
              <tidy-radio-button
                [label]="'Do you have a felony or misdemeanor related to theft or violence on your background check? If so, do not apply, platform rules do not allow it and we do check thoroughly.'"
                [linkText]="'Learn More'"
                (linkAction)="learnMoreFelony()"
                formControlName="felony"
                [form]="form.controls.felony"
                [items]="[{viewValue: 'Yes', value: 'yes'},{viewValue: 'No', value: 'no'}]"
                [selectError]="form.value.felony === 'yes'"
                [selectMessage]="'Applicants with felonies are not allowed.'"
                [submitted]="submitted"
                [errorMessage]="'Please select an answer.'">
              </tidy-radio-button>
            </tidy-row>
            <tidy-row class="extra-bottom-padding">
              <tidy-radio-button
                [label]="'Do you have allergies that would prevent you from going to homes (including dog/cat allergies)?'"
                formControlName="allergies"
                [form]="form.controls.allergies"
                [items]="[{viewValue: 'Yes', value: 'yes'},{viewValue: 'No', value: 'no'}]"
                [selectError]="form.value.allergies === 'yes'"
                [selectMessage]="'We cannot accommodate allergies that might prevent going to homes at this time.'"
                [submitted]="submitted"
                [errorMessage]="'Please select an answer.'">
              </tidy-radio-button>
            </tidy-row>
            <tidy-row>
              <tidy-radio-button
                [label]="'Do you read and speak English well enough to understand Client notes?'"
                formControlName="english"
                [form]="form.controls.english"
                [items]="[{viewValue: 'Yes', value: 'yes'},{viewValue: 'No', value: 'no'}]"
                [selectError]="form.value.english === 'no'"
                [selectMessage]="'Applicants must be able to read and speak English to apply.'"
                [submitted]="submitted"
                [errorMessage]="'Please select an answer.'">
              </tidy-radio-button>
            </tidy-row>
          </form>
        </tidy-card>

        <tidy-error-message
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-button
          [text]="'Start Background Check'"
          [action]="startBackgroundCheck.bind(this)"
          class="primary">
        </tidy-button>
      </ng-container>

      <ng-container *ngIf="backgroundCheckPending">
        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'We sent an email to '">
          </tidy-text>
          <tidy-text
            [body]="email">
          </tidy-text>
          <tidy-text
            [body]="' from our background check provider TURN (from a @turning.io email). Check your email and complete the check to proceed. Please allow 24 hours for the email to arrive.'">
          </tidy-text>
        </tidy-row>

        <tidy-error-message
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-button
          [text]="'Resend Email'"
          [action]="resendBackgroundCheckEmail.bind(this)"
          class="primary">
        </tidy-button>
      </ng-container>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
