import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Me } from 'src/shared/providers/me';
import { SuccessPageParamsModel } from 'src/pages/success/success';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

@Component({
  selector: 'profile-telephone',
  templateUrl: 'profile-telephone.html',
  encapsulation: ViewEncapsulation.None
})

export class ProfileTelephonePage extends Timeout {

  errorMessage: string;
  phoneForm: UntypedFormGroup;
  currentPageState: PageState = PageState.EDIT;
  PageState = PageState;
  pageContent: PageContent;
  submitted: boolean;
  submittedCode: boolean;
  proCountryCode = '';

  constructor(
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private me: Me,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
    this.phoneForm = this.fb.group({
      phone: ['', Validators.required],
      code: ['']
    });
    this.pageContent = this.getPageContent(PageState.EDIT);
  }

  async ionViewDidEnter() {
    try {
      this.loaded = false;

      const me = await this.me.load();
      const countryCode = me.profile.address.country_code;
      this.proCountryCode = countryCode;

      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  submitNewPhone = async () => {
    this.errorMessage = '';
    this.submitted = true;
    if (!this.phoneForm.valid) {
      return;
    }
    try {
      await this.me.updatePhone(this.phoneForm.value.phone);
      this.currentPageState = PageState.CODE;
      this.pageContent = this.getPageContent(this.currentPageState);
    } catch (err) {
      this.submitted = false;
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  submitVerificarionCode = async () => {
    this.errorMessage = '';
    this.submitted = true;
    try {
      if (!this.phoneForm.controls.code.valid) {
        return;
      }
      await this.me.confirmPhone(this.phoneForm.value.code);
      const params = this.mountSuccessPageParams();
      this.customNavCtrl.navigateForward('success', params);
    } catch (err) {
      this.submitted = false;
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  buttonAction = async () => {
    if (this.currentPageState === PageState.EDIT) {
      await this.submitNewPhone();
    } else {
      await this.submitVerificarionCode();
    }
  }

  getPageContent(state: PageState): PageContent {
    const contents = {
      edit: {
        title: 'Change Phone Number',
        header: 'What is your new number?',
        formControlName: 'phone',
        inputMask: 'phone',
        buttonText: 'Send Verification SMS',
        textBellowButton: 'Check your SMS messages for a verification code.'
      },
      code: {
        title: 'Code Sent',
        header: 'Verification code',
        formControlName: 'code',
        inputMask: '',
        buttonText: 'Confirm',
        textBellowButton: 'Check your SMS messages for a verification code.'
      }
    };
    return contents[state];
  }

  mountSuccessPageParams(): SuccessPageParamsModel {
    return {
      header: `Phone Updated`,
      body: `Your Phone has been updated.`,
      buttonText: `Ok`,
      buttonRoute: `more`
    };
  }
}

interface PageContent {
  title: string;
  header: string;
  formControlName: string;
  inputMask: string;
  buttonText: string;
  textBellowButton: string;
}

enum PageState {
  EDIT= 'edit',
  CODE = 'code'
}
