<tidy-row class="extra-bottom-padding vertical-align-center">
  <tidy-image
    class="header-size"
    [src]="'assets/svg/information-circle.svg'">
  </tidy-image>
  <tidy-text
    [header]="frequency === 'once' ? 'Job' : 'Jobs'">
  </tidy-text>
</tidy-row>
<ng-container *ngIf="frequency !== 'once'">
  <tidy-row class="vertical-align-center">
    <tidy-image
      [src]="'assets/svg/information-circle-outline.svg'">
    </tidy-image>
    <tidy-text
      [body]="serviceName">
    </tidy-text>
    <tidy-text
      [body]="' '">
    </tidy-text>
    <tidy-text
      [body]="(frequency | planFrequency)">
    </tidy-text>
    <tidy-text
      [body]="' Plan'">
    </tidy-text>
  </tidy-row>
  <tidy-row class="vertical-align-center">
    <tidy-image
      [src]="'assets/svg/calendar-outline.svg'">
    </tidy-image>
    <tidy-text
      [body]="'Starting on '">
    </tidy-text>
    <tidy-text
      [body]="(date | tdate:'dddd M/D')">
    </tidy-text>
    <tidy-text
      [body]="' at '">
    </tidy-text>
    <tidy-text
      [body]="startTimeLabel">
    </tidy-text>
  </tidy-row>
</ng-container>

<ng-container *ngIf="frequency === 'once'">
  <tidy-row class="vertical-align-center">
    <tidy-image
      [src]="'assets/svg/information-circle-outline.svg'">
    </tidy-image>
    <tidy-text
      [body]="serviceName">
    </tidy-text>
  </tidy-row>
  <tidy-row class="vertical-align-center">
    <tidy-image
      [src]="'assets/svg/calendar-outline.svg'">
    </tidy-image>
    <tidy-text
      [body]="(date | tdate:'dddd M/D')">
    </tidy-text>
    <tidy-text
      [body]="' at '">
    </tidy-text>
    <tidy-text
      [body]="startTimeLabel">
    </tidy-text>
  </tidy-row>
</ng-container>

<tidy-row class="vertical-align-center" *ngIf="billingType !== 'price_later'">
  <tidy-image
    [src]="'assets/svg/cash-outline.svg'">
  </tidy-image>
  <tidy-text
    *ngIf="billingType !== 'hourly'"
    [body]="'Price: '">
  </tidy-text>
  <tidy-text
    *ngIf="billingType == 'hourly'"
    [body]="'Hourly Rate: '">
  </tidy-text>
  <tidy-text
    [body]="price">
  </tidy-text>
</tidy-row>

<tidy-row class="vertical-align-center">
  <tidy-image
    [src]="'assets/svg/chatbox-ellipses-outline.svg'">
  </tidy-image>
  <tidy-text
    [body]="description">
  </tidy-text>
</tidy-row>
