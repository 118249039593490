<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="false"
    [title]="'Unable to Notify'"
    [noBow]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'You already have another job in progress.  Please tap on that job in the Jobs Page to finish it.'">
      </tidy-text>
    </tidy-row>

    <tidy-button
      [text]="'Go to Jobs'"
      [action]="goToJobs.bind(this)"
      class="primary">
    </tidy-button>

    <tidy-row [align]="'center'">
      <tidy-text
        [helper]="'or'"
        class="divider">
      </tidy-text>
    </tidy-row>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'Having an issue with the other job? '">
      </tidy-text><br>
      <tidy-text
        [body]="'Clear Other Job Data'"
        (action)="clearStore()"
        class="link">
      </tidy-text>
    </tidy-row>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
