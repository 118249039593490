<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Reimbursement'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'Request a Parking Reimbursement from the Client by entering the amount and a
        photo of the receipt or meter as proof of payment. This will be
        automatically approved if equal to or below the Client\'s max on their
        account. It will go to the Client for approval if above the Client\'s max.'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-grid>
        <ion-row>
          <ion-col size="4"></ion-col>

          <ion-col size="4">
            <tidy-round-button
              class="cyan"
              [icon]="'assets/svg/camera-white.svg'"
              (action)="takePhoto()"
              [label]="'Take Photo'" >
            </tidy-round-button>
          </ion-col>

          <ion-col size="4"></ion-col>
        </ion-row>
      </tidy-grid>
    </tidy-row>

    <tidy-row *ngIf="receiptPhoto">
      <tidy-card>
        <tidy-image class="hero-size" [src]="receiptPhoto.dataUrl"></tidy-image>
      </tidy-card>
    </tidy-row>

    <tidy-row [align]="'center'">
      <tidy-error-message
        *ngIf="submitted && !receiptPhoto"
        [text]="'Please add a photo of the receipt or meter.'">
      </tidy-error-message>
    </tidy-row>

    <form [formGroup]="form" novalidate action="#">
      <tidy-card>
          <tidy-row class="field-top-padding">
           <tidy-input
             formControlName="amount"
             [label]="'Amount'"
             [form]="form"
             [icon]="'assets/svg/cash-outline.svg'"
             [activeIcon]="'assets/svg/cash-outline-tidy-green.svg'"
             errorMessage="Please enter the amount"
             [submitted]="submitted"
             inputMode="numeric"
             [mask]="'currencyMask'">
           </tidy-input>
         </tidy-row>
      </tidy-card>
    </form>

    <tidy-error-message
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-button item-right
      [text]="'Submit Request'"
      [action]="submitRequest.bind(this)"
      class="primary">
    </tidy-button>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
