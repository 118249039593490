import { Component } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Component({
  templateUrl: 'item-detail.html'
})

export class ItemDetailPage {

  clientId: any;
  hasNewBasePayment: boolean;
  item: any;
  loaded: boolean;

  constructor(
    private navCtrl: CustomNavController
  ) {}

  async ionViewDidEnter(): Promise<any> {
    this.loaded = false;
    this.item = this.navCtrl.getParam('item', true);
    this.clientId = this.navCtrl.getParam('clientId', false);
    this.loaded = true;
  }

  goToWaiveFees() {
    this.navCtrl.navigateRoot('waive-fee');
  }

  goToContactConcierge(paymentTitle) {
    const params = {
      title: 'Payment Issue',
      type: 'support.homekeeper_payment',
      metadata: {
        payment_title: paymentTitle
      }
    };
    this.navCtrl.navigateForward('contact-concierge', params);
  }

  goToPastJobPage(jobId, isPrivate) {
    if (isPrivate) {
      this.navCtrl.navigateForward(`private-job/${jobId}`);
    } else {
      this.navCtrl.navigateForward(`past-job/${jobId}`);
    }
  }

  goToPrivateClientPage(clientId) {
    this.navCtrl.navigateForward(`client/${clientId}`);
  }

  isNewBasePayment(breakdown) {
    if (breakdown.description == 'Cleaning Base Payment' && breakdown.amount > 0 && breakdown.amount !== this.item.job_data.base_pay_rate) {
      this.hasNewBasePayment = true;
      return true;
    };
  }
}
