import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';

import { Certification } from 'src/providers/certification/certification';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Injectable()
export class IncompleteRegisterGuard implements CanActivate {

  constructor(
    private certification: Certification,
    private customNavController: CustomNavController
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const requirement = route.data.requirement;
    let requirementReached;
    const pageTitle = route.data.title;
    try {
      const result = await this.certification.getCertifications();
      if (requirement === 'existing_clients') {
        requirementReached = result.existing_clients;
      } else if (requirement === 'go_live' || requirement === 'bookable_times'){
        requirementReached = result.steps[requirement] === 'complete';
      }
      if (!requirementReached) {
        this.customNavController.navigateForward(`complete-your-register/${pageTitle}/${requirement}`);
        return false;
      }
    } catch (err) {
      this.customNavController.navigateRoot('');
      return false;
    }

    return true;
  }
}
