import { Component } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { SuccessPageParamsModel } from 'src/pages/success/success';

@Component({
  templateUrl: 'check-in-learn-about-teams.page.html'
})

export class CheckInLearnAboutTeamsPage {

  body: string;
  title: string;
  cleanings: any;
  loaded: boolean;

  constructor(
    private navCtrl: CustomNavController
  ) {}

  async ionViewDidEnter() {
    this.loaded = false;
    this.cleanings = this.navCtrl.getParam('cleanings');
    this.title = this.cleanings.length === 1 ? 'Delegate Job Instead?' : 'Delegate Jobs Instead?';
    this.body = this.cleanings.length === 1 ? 'delegate this job' : 'delegate these jobs';
    this.loaded = true;
  }

  showSuccessPage() {
    const successParams: SuccessPageParamsModel = {
      header: 'Delegate Jobs',
      body: 'Great!  You can delegate any jobs by tapping the job in the Jobs Page.  You\'ll need to check in for those jobs first.',
      buttonText: 'Ok',
      buttonRoute: `check-in`
    };
    this.navCtrl.navigateForward('success', successParams);
  }

  goToCallOutPage() {
    this.navCtrl.navigateForward('check-in-call-out', {cleanings: this.cleanings});
  }
}
