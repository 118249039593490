import { Component, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';

import { BankInfo } from 'src/providers/bank-info/bank-info';
import { Certification } from 'src/providers/certification/certification';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Cleanings } from 'src/providers/cleanings/cleanings';
import { Dashboard } from 'src/providers/dashboard/dashboard';
import { Me } from 'src/shared/providers/me';
import { OnRails } from 'src/providers/on-rails/on-rails';
import { PrivateClient } from 'src/providers/private-client/private-client';
import { Proposals } from 'src/providers/proposals/proposals';
import { Quests } from 'src/providers/quests/quests';
import { Team } from 'src/providers/team/team';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Updates } from 'src/providers/updates/updates';

import { ConfirmPageParamsModel, ConfirmPage } from 'src/pages/confirm/confirm';
import { DashboardData } from 'src/pages/dashboard/dashboard-data';
import { parseDashboardData } from 'src/pages/dashboard/dashboard-data-parser';
import { SuccessPageParamsModel } from 'src/pages/success/success';

import { HomekeeperModel } from 'src/shared/models/homekeeper.model';
import { UpdateModel } from 'src/shared/models/update.model';
import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';
import { CertificationsModel } from 'src/shared/models/certification.model';
import { InviteModel } from 'src/shared/models/team.model';
import { PrivateJobModel } from 'src/shared/models/private-job.model';
import { ProposalModel } from 'src/shared/models/proposals.model';
import { Loading } from 'src/shared/components/loading/loading';
import { forkJoin } from 'rxjs';
import { Logger } from 'src/shared/providers/logger';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { AppState } from 'src/shared/providers/http/app-state';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { takeWhile } from 'rxjs/operators';
import { GlobalSubscriptions } from 'src/app/global-subscriptions';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

declare const window: any;

@Component({
  selector: 'dashboard',
  templateUrl: 'dashboard.html',
  encapsulation: ViewEncapsulation.None
})

export class DashboardPage extends Timeout {

  alerts: any;
  allProposals: ProposalModel[];
  delegationRequests: any;
  didFinishPaymentOnboarding: boolean;
  REFRESH_DELAY: number = 1000 * 60 * 2;
  highlights: any;
  certifications: CertificationsModel;
  estimatedNextPayment: any;
  errorMessage = '';
  pendingProposalsCount: number = 0;
  payoutSetting: any;
  paymentSetting: any;
  dashboardData: DashboardData;
  invitationsReceived: InviteModel[];
  isLimitedAccount: boolean;
  showModal: boolean;
  fetchInterval: number;
  updatesList: UpdateModel[];
  updateError: string;
  hkCertificationStatus: string;
  hkState: string;
  hkNotAvailableRepeat: boolean;
  teamIAmOn: any;
  unpaidJobs: PrivateJobModel[];
  availableQuestCount: string;

  constructor(
    private bankInfo: BankInfo,
    private certification: Certification,
    private customNavCtrl: CustomNavController,
    private cleanings: Cleanings,
    private dashboard: Dashboard,
    private team: Team,
    private me: Me,
    private modalCtrl: ModalController,
    private onRails: OnRails,
    private privateClient: PrivateClient,
    private proposals: Proposals,
    private quests: Quests,
    router: Router,
    route: ActivatedRoute,
    private storage: TidyStorage,
    private updates: Updates,
    private logger: Logger,
    private iabUtils: InAppBrowserUtils,
    timeoutErrorHandler: TimeoutErrorHandler,
    private appState: AppState,
    private globalSubscriptions: GlobalSubscriptions,
  ) {
    super(timeoutErrorHandler, router, route);
  }

  async ionViewDidEnter(): Promise<any> {
    this.checkHkStateAndRedirect();
    this.alreadyShowedOfflineOrTimeoutAlert = false;
    await this.fetchData();
  }

  async fetchData() {
    this.loaded = false;
    if (!await this.appState.isOnline()) {
      this.alreadyShowedOfflineOrTimeoutAlert = true;
      return this.timeoutErrorHandler.showOfflineAlert(this.successPageLoad, 'dashboard', this.retrySubject);
    }
    try {
      this.isLimitedAccount = await this.storage.retrieve('isLimitedAccount');
      await this.fetchDashboard();
      if (this.isLimitedAccount) {
        this.delegationRequests = await this.cleanings.fetchDelegations();
        const hkId = await this.storage.retrieve('hk_id');
        const teams = await this.team.getMyTeams();
        this.teamIAmOn = teams[1];
      }
      if (!this.fetchInterval) {
        this.fetchInterval = window.setInterval( async () => await this.fetchDashboard(), this.REFRESH_DELAY );
      }
    } catch (err) {
      this.errorMessage = err;
    }
  }

  async fetchDashboard() {
    this.needsCheckin();

    this.getProposals();
    this.getUnpaidJobs();

    const forkJoinSubs = forkJoin({
      hkNotAvailableRepeat: this.storage.retrieve('not_available_repeat'),
      invitationsReceived: this.team.getInvitationsReceives().catch(error => this.timeoutHandler(error)),
      updatesList: this.updates.getUpdates().catch(error => this.timeoutHandler(error)),
      highlights: this.dashboard.getHighlights().catch(error => this.timeoutHandler(error)),
      certifications: this.certification.getCertificationsNoCache().catch(error => this.timeoutHandler(error)),
      estimatedNextPayment: this.dashboard.getNextPaymentSnapshot().catch(error => this.timeoutHandler(error)),
      availableQuestCount: this.quests.getAvailableQuestCount().catch(error => this.timeoutHandler(error))
     }).pipe(
       takeWhile(() => !this.loaded)
     ).subscribe(
      async (res) => {
        Object.keys(res).forEach(resItem => {
          if(res[resItem].error) {
            const err = res[resItem];
            this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
            this[resItem] = [];
          } else {
            this[resItem] = res[resItem];
          }
        });

        try {
          this.payoutSetting = localStorage.getItem('payoutSetting');
          if (!this.payoutSetting) {
            const settings = await this.bankInfo.getPayoutSettings();
            this.payoutSetting = settings[0]?.value;
            if (this.payoutSetting) localStorage.setItem('payoutSetting', this.payoutSetting);
          }
          this.paymentSetting = localStorage.getItem('paymentSetting');
          if (!this.paymentSetting) {
            const settings = await this.bankInfo.getPaymentSettings();
            this.paymentSetting = settings[0]?.value;
            if (this.paymentSetting) localStorage.setItem('paymentSetting', this.paymentSetting);
          }
          this.didFinishPaymentOnboarding = this.payoutSetting && this.paymentSetting;
          const exampleCoops = await this.certification.getPreCertificationCoops(this.certifications.steps.go_live);
          this.dashboardData = parseDashboardData(this.highlights, this.estimatedNextPayment, exampleCoops);
          this.loaded = true;
          forkJoinSubs.unsubscribe();
          this.successPageLoad.emit(true);
        } catch (err) {
          this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
        }
      },
      (error) => {
        this.errorMessage = (error.error && error.error.message) ? error.error.message : error.message;
      }
     );
  }

  onError(error) {
    this.errorMessage = error?.message;
  }

  async getUnpaidJobs() {
    try {
      this.unpaidJobs = await this.privateClient.getUnpaidJobs();
    } catch (err) {
      this.logger.error(err);
    }
  }

  async getProposals() {
    try {
      this.allProposals = await this.proposals.getPrivateProposals();
      this.getPendingProposalsCount();
    } catch (err) {
      this.logger.error(err);
    };
  }

  ionViewWillLeave(): void {
    this.loaded = true;
    clearInterval(this.fetchInterval);
    this.fetchInterval = null;
  }

  getPendingProposalsCount() {
    this.allProposals.map((proposal) => {
      if (proposal.status == 'pending') {
        this.pendingProposalsCount += 1;
      }
    });
  }

  async goLive() {
    this.customNavCtrl.navigateForward('go-live');
  }

  goToProposalsPage() {
    const params = {
      proposals: this.allProposals,
      certifications: this.certifications
    }
    this.customNavCtrl.navigateForward('proposals', params);
  }

  goToUnpaidJobsPage() {
    const params = {
      unpaidJobs: this.unpaidJobs,
      certifications: this.certifications
    }
    this.customNavCtrl.navigateForward('unpaid-jobs', params);
  }

  goToCertificationPage(page) {
    this.customNavCtrl.navigateForward(page);
  }

  async goToQuestsPage() {
    await this.storage.save('questsBackRoute', 'dashboard');
    this.customNavCtrl.navigateRoot('quests');
  }

  goToCleaningsPage = () => {
    this.customNavCtrl.navigateRoot('jobs');
  }

  goToFeedbackPage = () =>  {
    this.customNavCtrl.navigateForward('feedback', {defaultHistory: 'dashboard'});
  }

  goToAddPage = () => {
    this.customNavCtrl.navigateForward('add');
  }

  async goToEstimatedNextPaymentPage(amount) {
    if (!amount || amount === '$0') {
      this.customNavCtrl.navigateForward('start-earning');
    } else {
      await this.storage.save('payoutsBackRoute', 'dashboard');
      this.customNavCtrl.navigateForward('payouts');
    }
  }

  goToReapplyPage = () => {
    this.customNavCtrl.navigateRoot('unpause/update-address');
  }

  goToMySchedule = () => {
    this.customNavCtrl.navigateRoot('bookable-hours');
  }

  async goToUpdatePage(update) {
    await this.updates.trackUpdateAction(update.id);
    const page = await this.updates.getUpdatePage(update.type, update.data);
    if (page !== undefined && page !== 'accept-reject-job') {
      await this.updates.dismissUpdate(update.id);
    }
    if(update.type === 'approved_for_credit_cards') {
      this.iabUtils.openUrl(page);
      return;
    }
    if (page === undefined) {
      this.updateError = 'You are on an older version of the app.  Please download the latest version to view this update.';
      setTimeout(() => this.updateError = '',  10000);
      return;
    }
    this.customNavCtrl.navigateForward(page, update);
  }

  goToAlertPage(page) {
    this.customNavCtrl.navigateForward(page);
  }

  @Loading()
  async dismissUpdate(homekeeperUpdateId) {
    await this.updates.trackUpdateAction(homekeeperUpdateId);
    await this.updates.dismissUpdate(homekeeperUpdateId);
    this.updatesList = await this.updates.getUpdates();
  }

  async needsCheckin() {
    try {
      const permissions = await this.onRails.cleaningsPermissions();
      const hasToCheckin = permissions.show_cleaning_confirmation_page;

      if (hasToCheckin) {
        this.customNavCtrl.navigateForward('check-in');
      }

      return hasToCheckin;
    } catch (e) {
      return false;
    }
  }

  async checkHkStateAndRedirect(): Promise<any> {
    const hk: HomekeeperModel = await this.me.fetchWithoutCache();
    this.alerts = hk.profile?.alerts;
    this.hkState = hk.user.state;
    this.hkCertificationStatus = hk.certification.status;
    if (this.hkState === 'homekeeper_paused') {
      this.customNavCtrl.navigateRoot('unpause/update-address');
    }
    if (this.hkState === 'homekeeper_pending_decertification') {
      this.customNavCtrl.navigateRoot('pending-decertification');
    }
    if (!this.isLimitedAccount) {
      const certificationStatus = hk.user?.compliance_status;
      this.globalSubscriptions.handleCertificationStatus(certificationStatus);
    }
  }

  async acceptTeamInvite(invite) {
    if (invite.job_delegation_request) {
      this.showAcceptJobAndTeamInvite(invite);
    } else {
      this.showAcceptTeamInvite(invite);
    }
  }

  async showAcceptJobAndTeamInvite(invite) {
    const confirmResend: ConfirmPageParamsModel = {
      title: 'Accept Job?',
      body: `${new TranslationPipe().transform('You will be added to')} ${invite.team.owner.name}\'s ${new TranslationPipe().transform('team and will be expected to complete this job.')}`,
      backText: 'Go Back',
      confirmText: 'Accept Job',
      confirmAction: this.confirmAcceptJobAndTeamInvite.bind(this),
      confirmActionParams: invite,
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: confirmResend,
      animated: false
    });
    confirmationModal.present();
  }

  async confirmAcceptJobAndTeamInvite(invite) {
    this.errorMessage = '';
    try {
      await this.team.acceptInvite(invite.id);
      await this.team.acceptJob(invite.job_delegation_request.id);
      const onSuccess: SuccessPageParamsModel = {
        header: 'Job Accepted!',
        body: `${new TranslationPipe().transform('You are now part of')} ${invite.team.owner.name}\'s ${new TranslationPipe().transform('team and expected to complete this job.')} ${new TranslationPipe().transform('View this job in the Jobs section and reach out to')} ${invite.team.owner.name} ${new TranslationPipe().transform('with any questions.')}`,
        buttonText: 'Ok',
        buttonRoute: 'dashboard'
      };
      this.modalCtrl.dismiss();
      this.customNavCtrl.navigateForward('success', onSuccess);
    } catch(err) {
      this.modalCtrl.dismiss();
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async showAcceptTeamInvite(invite) {
    const confirmResend: ConfirmPageParamsModel = {
      title: 'Accept Invite?',
      body: `${new TranslationPipe().transform('Accept invite to')} ${invite.team.owner.name}\'s ${new TranslationPipe().transform('team?')}`,
      backText: 'Go Back',
      confirmText: 'Accept Invite',
      confirmAction: this.confirmAcceptTeamInvite.bind(this),
      confirmActionParams: invite,
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: confirmResend,
      animated: false
    });
    confirmationModal.present();
  }

  async confirmAcceptTeamInvite(invite) {
    await this.team.acceptInvite(invite.id);
    const onSuccess: SuccessPageParamsModel = {
      header: 'Invite Accepted!',
      body: `${new TranslationPipe().transform('You are now part of')} ${invite.team.owner.name}\'s ${new TranslationPipe().transform('team!')}`,
      buttonText: 'Ok',
      buttonRoute: 'dashboard'
    };
    this.modalCtrl.dismiss();
    this.customNavCtrl.navigateForward('success', onSuccess);
  }

  async rejectTeamInvite(invite) {
    const confirmResend: ConfirmPageParamsModel = {
      title: invite.job_delegation_request ? 'Reject Job?' : 'Reject Invite?',
      body: invite.job_delegation_request ? `${new TranslationPipe().transform('Reject job delegation request & invite to')} ${invite.team.owner.name}\'s ${new TranslationPipe().transform('team?')}` : `${new TranslationPipe().transform('Reject invite to')} ${invite.team.owner.name}\'s ${new TranslationPipe().transform('team?')}` ,
      backText: 'Go Back',
      confirmText: invite.job_delegation_request ? 'Reject Job' : 'Reject Invite',
      confirmAction: this.confirmRejectTeamInvite.bind(this),
      confirmActionParams: invite,
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: confirmResend,
      animated: false
    });
    confirmationModal.present();
  }

  async confirmRejectTeamInvite(invite) {
    await this.team.rejectInvite(invite.id);
    if (invite.job_delegation_request) {
      await this.team.rejectJob(invite.job_delegation_request.id);
    }
    const onSuccess: SuccessPageParamsModel = {
      header: invite.job_delegation_request ? 'Job Rejected' : 'Invite Rejected',
      body: `${new TranslationPipe().transform('You rejected')} ${invite.team.owner.name}\'s ${new TranslationPipe().transform('invite.')}`,
      buttonText: 'Ok',
      buttonRoute: 'dashboard'
    };
    this.modalCtrl.dismiss();
    this.customNavCtrl.navigateForward('success', onSuccess);
  }

  goToDelegationRequest(delegation) {
    const params = {
      updateId: delegation.job_data.homekeeper_update_id,
      requestId: delegation.id,
      delegatorName: delegation.delegated_by_team_member.homekeeper.name.substr(0,delegation.delegated_by_team_member.homekeeper.name.indexOf(' ')),
      jobDate: delegation.job_data.friendly_date,
      jobTime: delegation.job_data.friendly_start_time,
      jobDuration: delegation.job_data.duration_in_hours,
      cameFromAddPage: true
    };
    this.customNavCtrl.navigateForward('accept-reject-job', params);
  }

  goToReceivePaymentsFromClientsPage() {
    this.customNavCtrl.navigateForward('receive-payments-from-clients');
  }

  goToServiceProviderAgreement() {
    this.iabUtils.openUrl('https://www.tidy.com/legal/service-provider/');
  }

}
