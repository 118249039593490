<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Job Messages'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      [text]="errorMessage"
      *ngIf="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">
      <tidy-card>
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            src="assets/style-guide-images/bowtie-black.svg"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="job?.job.date | tdate: 'ddd M/D':'YYYY-MM-DD'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-grid>
            <ion-row>
              <ion-col size="4">
                <tidy-text
                  [body]="job?.job.start_time | ttime">
                </tidy-text><br>
                <tidy-text
                  [body]="(job?.job?.duration_in_minutes / 60)">
                </tidy-text>
                <tidy-text
                  [body]="(job?.job?.duration_in_minutes > 60 ? ' hours' : ' hour')">
                </tidy-text>
              </ion-col>
              <ion-col size="1">
                <tidy-image
                  *ngIf="job?.homekeeper_job?.repeat_client"
                  [src]="'assets/svg/star-yellow.svg'"
                  class="body-size">
                </tidy-image>
              </ion-col>
              <ion-col size="7" >
                <tidy-text
                  [body]="job?.customer?.first_name + ' ' + (job?.customer?.last_name | abbrLastName: true)"
                  [translate]="false">
                </tidy-text> <br>
                <ng-container *ngIf="!job?.dispute || (job?.dispute && job?.dispute.status === 'resolved')">
                  <ng-container *ngIf="job?.card_type !== 'future_cleaning'">
                    <tidy-text
                      [body]="(job?.homekeeper_job.performance | performanceText) + ': '">
                    </tidy-text>

                     <tidy-text
                      *ngIf="job?.payment_price != null"
                      [body]="job?.payment_price | tcurrency">
                    </tidy-text>
                  </ng-container>
                  <tidy-text
                    *ngIf="job?.status === 'Accelerated'"
                    class="green"
                    [body]="' Accelerated'">
                  </tidy-text>
                  <tidy-text
                    *ngIf="job?.is_fee_waived"
                    [body]="' Fee Waived'"
                    class="green">
                  </tidy-text>
                </ng-container>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row [alignLastItemRight]="true">
          <tidy-image
            [src]="'assets/img/account.svg'"
            class="title-size">
          </tidy-image>
          <tidy-text
            [header]="job?.customer?.first_name + ' ' + (job?.customer?.last_name | abbrLastName: true)"
            [translate]="false">
          </tidy-text>
          <tidy-text
            *ngIf="!hasPassed14DaysFromJobStartDate()"
            [body]="'Call'"
            class="link"
            (action)="contactClient('call')">
          </tidy-text>
        </tidy-row>
        <ng-container *ngIf="!hasPassed14DaysFromJobStartDate(); else cantContactTemplate">
          <tidy-row class="field-top-padding extra-bottom-padding">
            <form [formGroup]="form" novalidate action="#">
              <tidy-textarea
                [label]="'Send Message'"
                formControlName="message"
                [form]="form.controls.message"
                [submitted]="submitted"
                [errorMessage]="'Please enter a message to send.'"
                [rightIconAction]="contactClient.bind(this, 'message')"
                [leftIconAction]="contactClient.bind(this, 'attachment')">
              </tidy-textarea>
            </form>
          </tidy-row>
        </ng-container>
        <ng-template #cantContactTemplate>
          <tidy-row [align]="'center'" class="extra-bottom-padding">
            <tidy-alert>
              <tidy-text
                [helper]="'You can\'t contact this client because it has been more than 14 days since the job has been completed.'">
              </tidy-text>
            </tidy-alert>
          </tidy-row>
        </ng-template>
        <tidy-row *ngIf="messages?.length == 0" [align]="'center'">
          <tidy-text
            [helper]="'No Messages'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let message of messages; let last = last">
          <message
            [message]="message"
            [last]="last">
          </message>
        </ng-container>
      </tidy-card>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
