import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { ClientFromTidy } from 'src/providers/client-from-tidy/client-from-tidy';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { Loading } from 'src/shared/components/loading/loading';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

import { RatesPreviewResponseModel, RatesPreviewModel } from 'src/shared/models/client-from-tidy';
import { TeamServiceModel } from 'src/shared/models/team.model';

import { SuccessPageParamsModel } from 'src/pages/success/success';

@Component({
  selector: 'clients-from-tidy-services-preview',
  templateUrl: './clients-from-tidy-services-preview.html'
})

export class ClientsFromTidyServicesPreviewPage {

  cancelledProposals: any;
  discount: any;
  errorMessage: string;
  loaded: boolean;
  payload: any;
  proposalsToBeReviewed: any;
  ratesPreview: RatesPreviewModel;
  showClientsToCancel: boolean;
  teamService: TeamServiceModel;

  constructor(
    private clientFromTidy: ClientFromTidy,
    private customNavCtrl: CustomNavController,
    private iabUtils: InAppBrowserUtils,
    private modalController: ModalController
  ) {}

  @Loading()
  async ionViewDidEnter() {
    this.loaded = false;
    try {
      this.teamService = this.customNavCtrl.getParam('teamService');
      this.discount = this.customNavCtrl.getParam('discount');
      this.payload = this.customNavCtrl.getParam('payload');
      this.ratesPreview = await this.getRatesPreview();
      this.cancelledProposals = [];
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
    this.loaded = true;
  }

  async getRatesPreview(): Promise<RatesPreviewModel> {
    try {
      this.proposalsToBeReviewed = null;
      const ratesPreview: RatesPreviewResponseModel = await this.clientFromTidy.previewRatesChange(this.teamService.id, this.payload.repeat_rate * 100);
      this.proposalsToBeReviewed = ratesPreview.proposal_data.proposals_to_review;
      const values = {
        raise: {
          header: 'Very Competitive',
          body: 'Increasing your rates would likely lead to more earnings.',
          color: 'yellow'
        },
        ok: {
          header: 'Competitive',
          body: 'Increasing or decreasing your rates would likely lead to less earnings.',
          color: 'green',
        },
        lower: {
          header: 'Uncompetitive',
          body: 'Your rates are higher than others in your area with a similar quality score. <b>It is likely fewer clients will book you, reducing your total earnings.</b> Increasing your quality score would increase the number of clients willing to book you at these rates, you may want to do that first.',
          color: 'yellow'
        },
        very_lower: {
          header: 'Very Uncompetitive',
          body: 'Your rates are much higher than others in your area with a similar quality score. <b>It is likely few clients will book you, reducing your total earnings.</b> Increasing your quality score would increase the number of clients willing to book you at these rates, you may want to do that first.',
          color: 'red'
        }
      }
      return values[ratesPreview.recommendation];
    } catch(err) {
      return {
        header: 'Not Valid',
        body: 'You set your rates too low.  Please increase your rates.',
        color: 'red'
      };
    }
  }

  changeShowClientsToCancel() {
    this.showClientsToCancel = !this.showClientsToCancel;
  }

  async confirmRequestChange() {
    let payload = {
      team_service_id: this.payload.team_service_id,
      repeat_rate: this.payload.repeat_rate * 100,
      new_rate: this.payload.new_rate * 100
    }
    if (this.cancelledProposals.length > 0) {
      payload['proposal_ids_to_force_update'] = this.cancelledProposals;
    }
    try {
      await this.clientFromTidy.requestRatesChange(payload);
      const successParams: SuccessPageParamsModel = {
        header: 'Rates Changed',
        body: 'Your rates will have changed for future Clients. Remember, anyone who has already booked with you will not be affected by this change.',
        buttonText: 'Ok',
        buttonRoute: `dashboard`
      };
      this.customNavCtrl.navigateForward('success', successParams);
      this.modalController.dismiss();
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  selectProposal(proposalId) {
    const index = this.cancelledProposals.findIndex((proposal) => proposal == proposalId);
    if (index !== -1) {
      this.cancelledProposals.splice(index, 1);
    } else {
      this.cancelledProposals.push(proposalId);
    }
  }

  learnMore() {
    this.iabUtils.openUrl('https://help.tidy.com/pros/set-rates-and-fees');
  }

}
