<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [noBow]="true"
    [title]="title ? title: ''">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper *ngIf="loaded">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="!undoable">
      <tidy-alert>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/alert-circle.svg'">
          </tidy-image>
          <tidy-text
            [header]="'Not Eligible to Undo'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'If another pro accepts the job, or the client already cancelled this because of your actions, then this cannot be un-done.'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>
      <tidy-button
        [text]="'Ok'"
        [action]="goBack.bind(this)"
        class="primary">
      </tidy-button>
    </ng-container>

    <ng-container *ngIf="undoable">
      <tidy-row>
        <tidy-text
          [body]="'If you proceed, your schedule will be marked available for this timeblock and you will be placed back onto this job.'">
        </tidy-text>
      </tidy-row>
      <tidy-error-message
        [text]="errorMessage">
      </tidy-error-message>
      <tidy-two-column-row>
        <tidy-button
          [text]="'Go Back'"
          [action]="goBack.bind(this)"
          [class]="'secondary'">
        </tidy-button>
        <tidy-button
          [text]="'Get this Job Back'"
          [action]="confirm.bind(this)"
          [class]="'primary'">
        </tidy-button>
      </tidy-two-column-row>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
