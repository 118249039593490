import { Injectable } from '@angular/core';
import { HttpClient } from '../../shared/providers/http/http-client';
import { AlertController } from '@ionic/angular';

@Injectable()
export class PayRates {

  constructor(
    private alertCtrl: AlertController,
    private httpClient: HttpClient
  ) {
  }

  async getPayRates() {
    const url = `api/v2/homekeeper/pay-rates`;
    return this.httpClient.get(url);
  }

  async getPaymentsSettings() {
    const url = `api/v1/homekeeper/payments/settings`;
    return this.httpClient.get(url);
  }

  async updateBookingProtection(value) {
    const url = `api/v1/homekeeper/payments/settings`;
    return this.httpClient.put(url, {booking_protection_fee: value});
  }

  async payRatePage() {
    const payRates = await this.getPayRates();
    const version = payRates.payment_system_version;

    if (version == 2) { return 'NewPayRatesPage'; }

    return 'PayRatePage';
  }

  getModalContent(option) {
    let alert;
    const modals = {
      editBookingProtection: {
        title: `Change Booking Protection Settings?`,
        message: `Booking protection settings can only be changed once every 100 cleanings.  Booking Protection will make sure you get paid a partial payment if your client cancels on you after 8pm the night before any appointment.  See pay rates for more details.  Booking protection costs 5% per cleaning.`,
        learnMore: ``,
        buttons: [
          {
            text: `Disable`,
            handler: () => {
              alert.dismiss();
              this.getModalContent('disableBookingProtection');
            }
          },
          {
            text: `Enable`,
            handler: () => {
              alert.dismiss();
              this.getModalContent('enableBookingProtection');
            }
          }
        ]
      },

      disableBookingProtection: {
        title: `Confirm`,
        message: `You don’t want cancellation payments, and want to earn 5% more per cleaning.`,
        learnMore: ``,
        buttons: [
          {
            text: `Go Back`,
            handler: ''
          },
          {
            text: `Confirm`,
            handler: async () => {
              alert.dismiss();
              await this.updateBookingProtection(false);
              this.getModalContent('settingsUpdated');
            }
          }
        ]
      },

      enableBookingProtection: {
        title: `Confirm`,
        message: `You want to pay 5% of each cleaning, but get paid partially if a client cancels after 8pm the night before.`,
        learnMore: ``,
        buttons: [
          {
            text: `Go Back`,
            handler: ''
          },
          {
            text: `Confirm`,
            handler: async () => {
              alert.dismiss();
              await this.updateBookingProtection(true);
              this.getModalContent('settingsUpdated');
            }
          }
        ]
      },

      settingsUpdated: {
        title: `Settings Updated`,
        message: `It may take several days for settings to take effect.`,
        learnMore: ``,
        buttons: [
          {
            text: `Ok`
          }
        ]
      },

    };

    alert = this.alertCtrl.create(modals[option]);
    alert.present();
  }

  editBookingProtection() {
    this.getModalContent('editBookingProtection');
  }

  learnMoreUrls(value) {
    const urls = {
      cancellations: 'https://help.tidy.com/pros/payments#when-are-payouts-sent',
      fees: 'https://help.tidy.com/pros/fees-for-clients-from-tidy',
      payment: 'https://help.tidy.com/pros/set-rates-and-fees',
      protection: 'https://help.tidy.com/pros/booking-protection/17525-what-is-booking-protection',
      rental: 'https://help.tidy.com/pros/certifications#equipment-certification'
    };

    return urls[value];
  }

}
