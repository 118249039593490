import { AlertController } from '@ionic/angular';
import { Component, ViewEncapsulation } from '@angular/core';
import { ActiveInCommunity } from '../../providers/active-in-community/active-in-community';
import { Me } from '../../shared/providers/me';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { SuccessPageParamsModel } from '../success/success';
import { Loading } from 'src/shared/components/loading/loading';
import { HomekeeperModel } from 'src/shared/models/homekeeper.model';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'active-in-community',
  templateUrl: 'active-in-community.html',
  encapsulation: ViewEncapsulation.None
})

export class ActiveInCommunityPage extends Timeout {
  places: any;
  reviews: any;
  completeList: any;

  constructor(
    private activeInComm: ActiveInCommunity,
    private me: Me,
    private customNavCtrl: CustomNavController,
    private alertCtrl: AlertController,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
  }

  @Loading()
  async ionViewDidEnter() {
    this.loaded = false;
    try {
      const me: HomekeeperModel = await this.me.load();
      this.reviews = await this.activeInComm.completedReviews(me.user.id);
      this.completeList = this.activeInComm.extractDestinationsComplete(this.reviews);
      this.places = this.activeInComm.publicPlaces();
      this.checkCertified(me.certification.status);
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  addReview = (destination) => {
    if (this.completeList[destination]) {
      return;
    }

    this.customNavCtrl.navigateForward(['add-community-review', destination]);
  }

  checkCertified(status: string) {
    if (status === 'under_review') {
      this.presentUnderReviewAlert();
    }
    if (status === 'certified' || status === 'completed') {
      const params = this.getSuccesPageParams();
      this.customNavCtrl.navigateRoot('success', params);
    }
  }

  async presentUnderReviewAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Compliance',
      subHeader: 'Certification Under Review',
      message: 'Your application is currently under review. Check back soon!',
      buttons: [
        {
          text: 'Ok',
          role: 'confirm',
          handler: () => {
            this.customNavCtrl.navigateRoot('dashboard');
          },
        },
      ],
      mode: 'ios',
      backdropDismiss: false,
      cssClass: 'compliance-alert',
    });

    await alert.present();
  }

  getSuccesPageParams(): SuccessPageParamsModel {
    return {
      header: 'Active in Community',
      body: 'This Certification helps clients feel more comfortable booking you, someone who takes pride in their public image. <br><br> You have earned the “Compliance” Certifications!  You’re awesome!',
      buttonText: 'Ok',
      buttonRoute: 'more'
    };
  }
}
