import { Injectable } from '@angular/core';
import { HttpClient } from '../../shared/providers/http/http-client';

@Injectable()
export class Survey {

  constructor(
    private httpClient: HttpClient,
  ) {}


  fetchSummaryData(jobId) {
    const url = `api/v1/homekeeper/cleanings/${jobId}/completed-confirmation-message`;
    return this.httpClient.get(url);
  }

}
