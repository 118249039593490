import { MWTodosModel } from 'src/main-workflow/mw.models';

export function parseTodosData(response): MWTodosModel {
  const length = response?.length;

  try {
    const rooms = response.slice(0, length - 2);
    const instructions = response[length - 1];
    const parsedRooms = parseRooms(rooms);

    return {
      onLeave: instructions?.instructions?.on_leave ?? '',
      rooms: parsedRooms,
      allRoomsAreEmpty: allRoomsAreEmpty(parsedRooms)
    };
  } catch (e) {
    return {
      onLeave: '',
      rooms: [],
      allRoomsAreEmpty: true
    }
  }
}

function parseRooms(rooms): any[] {
  const onlyRoomObjects = rooms?.filter(item => {
    return item?.address_room;
  });
  return onlyRoomObjects.map((item) => ({
    id: item?.address_room?.id,
    name: item?.address_room?.name,
    category: item?.address_room?.category,
    photo_notes: item?.address_room?.photo_notes,
    notes: item?.room_notes,
    tasks: parseTodoItems(item),
    is_do_not_clean_room: item?.is_do_not_clean_room,
    estimated_time: item?.estimated_time
  }));
}

function parseTodoItems(item): any[] {
  return item?.room_tasks?.filter(taskFilter).map( todoItem => ({
    job_task_id: todoItem?.assigned_job_task?.id,
    photo_notes: todoItem?.address_task?.photo_notes,
    title: todoItem?.address_task?.title,
    important: todoItem?.address_task?.important,
    note: todoItem?.address_task?.note,
    task_end_time: '',
    origin: todoItem,
    performance: 'not_performed',
    options: todoItem.assigned_job_task.options,
    assigned_job_task_options: todoItem?.assigned_job_task_options
  })) || [];
}

function taskFilter(task: any): boolean {
  return task?.address_task?.task_type !== 'take_before_photo'
    && task?.address_task?.task_type !== 'take_after_photo'
    && task?.address_task?.task_type !== 'take_before_video'
    && task?.address_task?.task_type !== 'take_after_video';
}

function allRoomsAreEmpty(rooms): boolean {
  return rooms?.every(room => {
    return room?.todos?.length === 0 && room?.notes?.length === 0;
  });
}
