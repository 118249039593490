import { Injectable } from '@angular/core';
import { HttpClient } from '../../shared/providers/http/http-client';
import { ClientModel } from 'src/shared/models/client.model';
import { AddressModel } from 'src/shared/models/address.model';
import { JobDetailsModel } from 'src/shared/models/job-details.model';
import { AddressSummaryModel } from 'src/shared/models/address-summary.model';
import { DeviceInfoProvider } from 'src/shared/providers/device-info';
import { Aws } from 'src/shared/providers/aws';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { CommonUtils } from 'src/shared/utils/common-utils';

@Injectable()
export class PrivateClient {

  constructor(
    private deviceInfo: DeviceInfoProvider,
    private httpClient: HttpClient,
    private aws: Aws,
    private storage: TidyStorage,
    private utils: CommonUtils
  ) {}

  getBookingNote(bookingId) {
    const url = `api/v1/homekeeper/booking-notes?booking_id=${bookingId}`;
    return this.httpClient.get(url);
  }

  getPrivateClients(): Promise<ClientModel[]> {
    const url = `api/v1/homekeeper/private/customers`;
    return this.httpClient.get(url);
  }

  getPrivateClientInfo(clientId): Promise<ClientModel> {
    const url = `api/v1/homekeeper/private/customers/${clientId}`;
    return this.httpClient.get(url);
  }

  deletePrivateClient(clientId: number) {
    const url = `api/v1/homekeeper/private/customers/${clientId}`;
    return this.httpClient.delete(url);
  }

  getPrivateClientAddress(clientId): Promise<AddressModel[]> {
    const url = `api/v1/homekeeper/private/addresses?customer_ids[]=${clientId}`;
    return this.httpClient.get(url);
  }

  deletePrivateClientAddress(addressId: number) {
    const url = `api/v1/homekeeper/private/addresses/${addressId}`;
    return this.httpClient.delete(url);
  }

  addPrivateClient(data) {
    const url = `api/v1/homekeeper/private/customers`;
    return this.httpClient.post(url, data);
  }

  updatePrivateClient(data, clientId) {
    const url = `api/v1/homekeeper/private/customers/${clientId}`;
    return this.httpClient.put(url, data);
  }

  getPrivateAddresses() {
    const url = `api/v1/homekeeper/private/addresses`;
    return this.httpClient.get(url);
  }

  getPrivateAddressInfo(addressId: number | string) : Promise<AddressModel> {
    const url = `api/v1/homekeeper/private/addresses/${addressId}`;
    return this.httpClient.get(url);
  }

  addPrivateAddress(data) {
    const url = `api/v1/homekeeper/private/addresses`;
    return this.httpClient.post(url, data);
  }

  updatePrivateAddress(data, addressId) {
    const url = `api/v1/homekeeper/private/addresses/${addressId}`;
    return this.httpClient.put(url, data);
  }

  async getUnpaidJobsForClient(clientId): Promise<any[]> {
    const url = `api/v1/homekeeper/private/jobs?custom_filters[]=past&customer_id=${clientId}&unpaid_only=true`;
    const response = await this.httpClient.get(url);
    return response.filter((job) => !job?.invoice?.full_paid);
  }

  async getUnpaidJobs(): Promise<any[]> {
    const url = `api/v1/homekeeper/private/jobs?custom_filters[]=past&unpaid_only=true`;
    const response = await this.httpClient.get(url);
    return response.filter((job) => !job?.invoice?.full_paid);
  }

  getFutureJobsForAddress(addressId) {
    const url = `api/v1/homekeeper/private/jobs?custom_filters[]=scheduled&address_id=${addressId}`;
    return this.httpClient.get(url);
  }

  getFutureJobs() {
    const url = `api/v1/homekeeper/private/jobs?custom_filters[]=scheduled`;
    return this.httpClient.get(url);
  }

  getPastJobsForAddress(addressId) {
    const url = `api/v1/homekeeper/private/jobs?custom_filters[]=past&address_id=${addressId}`;
    return this.httpClient.get(url);
  }

  getJobDetailsForAddress(jobId: string, withJobTasks: boolean = false): Promise<JobDetailsModel> {
    const url = `api/v1/homekeeper/private/jobs/${jobId}/details?with_job_tasks=${withJobTasks}&includes=job_delegation_request,remote_access_information,address_task_list,job_durations`;
    return this.httpClient.get(url);
  }

  getAddressSummary(addressId: number): Promise<AddressSummaryModel>{
    const url = `api/v1/homekeeper/private/addresses/${addressId}/summary`;
    return this.httpClient.get(url);
  }

  cancelPrivateJob(jobId: string, withFee: boolean, cancelledBy: string) {
    const url = `api/v1/homekeeper/private/jobs/${jobId}/cancel`;
    const params = {
      cancelled_by: cancelledBy,
      with_fee: withFee
    };
    return this.httpClient.post(url, params);
  }

  cancelPrivatePlan(planId: string, withFee: boolean, cancelledBy: string) {
    const url = `api/v1/homekeeper/private/plans/${planId}/cancel`;
    const params = {
      cancelled_by: cancelledBy,
      with_fee: withFee
    };
    return this.httpClient.post(url, params);
  }

  updateServices(data, teamServiceTypeId) {
    const url = `api/v1/homekeeper/team-service-types/${teamServiceTypeId}`;
    return this.httpClient.put(url, data);
  }

  formatPhoneDisplay(rawNumber) {
    if (rawNumber === null) {
      return '';
    }
    if (rawNumber.charAt(1) !== '1') {
      return rawNumber;
    }

    if (rawNumber === null) {
      return null;
    }
    const phone = rawNumber.slice(2);
    const areaCode = phone.slice(0,3)
    const firstThree = phone.slice(3,6)
    const lastFour = phone.slice(6,10)
    return '(' + areaCode + ') ' + firstThree + '-' + lastFour;
  }

  sendClientEmail(payload) {
    const url = `api/v1/homekeeper/private/customers/${payload.customer_id}/send-email`;
    return this.httpClient.post(url, payload);
  }

  editPrivateJobPrice(payload, jobId) {
    const url = `api/v1/homekeeper/private/jobs/${jobId}/change-price`;
    return this.httpClient.post(url, payload);
  }


}
