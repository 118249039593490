<tidy-card *ngIf="loaded">
  <tidy-row class="title-calendar">
    <span class="row-icon" (click)="previousMonth()" *ngIf="displayPreviousMonthArrow()">
      <tidy-image [src]="'assets/svg/caret-back.svg'"></tidy-image>
    </span>

    <tidy-text [header]="currentDate | date:'MMMM, yyyy'"> </tidy-text>

    <span class="row-icon" (click)="nextMonth()">
      <tidy-image [src]="'assets/svg/caret-forward.svg'"></tidy-image>
    </span>
  </tidy-row>

  <div>
     <ng-template #template let-view="view" let-row="row" let-col="col">
        <span [ngClass]="setDateClass(view.dates[row*7+col])" >
          <div class="black-circle" *ngIf="isToday(view.dates[row*7+col].date)">
            {{view.dates[row*7+col].label}}
          </div>
          <ng-container *ngIf="!isToday(view.dates[row*7+col].date)">
            {{view.dates[row*7+col].label}}
          </ng-container>
        </span>
    </ng-template>
    <calendar
      [monthviewDisplayEventTemplate]="template"
      [eventSource]="eventSource"
      calendarMode="month"
      [currentDate]="currentDate"
      (onTimeSelected)="onTimeSelected($event)"
      [lockSwipes]="lockSwipes"
      [markDisabled]="isDisabledDate"
      step="15"
      autoSelect="false">
    </calendar>
  </div>

  <tidy-bookable-legend></tidy-bookable-legend>
</tidy-card>
