class GetImageUtil {

  loadImg(url: string): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          const data = this.getDataUrlImage(img);
          resolve(data);
        };
        img.onerror = (err) => {
          reject(err)
        };
      } else {
        const data = this.getDataUrlImage(img);
        resolve(data);
      }
    });
  }

  getDataUrlImage(img: HTMLImageElement) {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL('image/png');
    return dataURL;
  }
}

export default new GetImageUtil();
