import { Component } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Dashboard } from 'src/providers/dashboard/dashboard';

import { Loading } from 'src/shared/components/loading/loading';

import { PendingBalanceModel } from 'src/shared/models/payment.model';

@Component({
  templateUrl: 'current-balance.html'
})

export class CurrentBalancePage {

  loaded: boolean;
  paymentBalance: PendingBalanceModel;

  constructor(
    private navCtrl: CustomNavController,
    private dashboard: Dashboard
  ) {}

  @Loading()
  async ionViewDidEnter(): Promise<any> {
    this.loaded = false;
    if (!this.paymentBalance) {
      this.paymentBalance = this.navCtrl.getParam('paymentBalance', true);
    }
    this.loaded = true;
  }

  goToWaiveFees() {
    this.navCtrl.navigateRoot('waive-fee');
  }

  goToContactConcierge(paymentTitle) {
    const params = {
      title: 'Payment Issue',
      type: 'support.homekeeper_payment',
      metadata: {
        payment_title: paymentTitle
      }
    };
    return this.navCtrl.navigateForward('contact-concierge', params);
  }

  goToPastJobPage(jobId, isPrivate) {
    if (isPrivate) {
      this.navCtrl.navigateForward(`private-job/${jobId}`);
    } else {
      this.navCtrl.navigateForward(`past-job/${jobId}`);
    }
  }

  goToPrivateClientPage(clientId) {
    this.navCtrl.navigateForward(`private-client/${clientId}`);
  }

  goToBalanceDetail(item) {
    const params = {
      item: item,
      clientId: item.job_data.customer?.id
    };
    this.navCtrl.navigateForward('item-detail', params);
  }

  getCustomerNameText(name) {
    return '\"' + name + '\"';
  }
  
}
