<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Missing Item'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card>
      <form [formGroup]="lostItemForm" novalidate action="#">
        <tidy-row class="field-top-padding">
         <tidy-textarea
           formControlName="description"
           control="description"
           [label]="'Please describe the item'"
           [errorMessage]="'Please describe the item'"
           [submitted]="submitted"
           [form]="lostItemForm"
           [icon]="'assets/svg/alert-circle-outline.svg'"
           [activeIcon]="'assets/svg/alert-circle-tidy-green.svg'">
         </tidy-textarea>
       </tidy-row>
     </form>
    </tidy-card>

    <tidy-button
      [text]="'Confirm'"
      [action]="initiateLostItem.bind(this)"
      class="secondary">
    </tidy-button>

    <tidy-error-message *ngIf="errorMessage"
      [text]="errorMessage">
     </tidy-error-message>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'We will notify the other party.'">
      </tidy-text>
    </tidy-row>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
