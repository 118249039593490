<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Bookable Hours'"
    [canGoBack]="true"
    [customBack]="'more'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'" *ngIf="loaded && calendarLoaded">
      <tidy-text
        [body]="'Tap on a day to edit current bookability.'">
      </tidy-text>
    </tidy-row>

    <tidy-calendar *ngIf="loaded"
     #tidyCalendar
     (calendarLoaded)="calendarLoaded = $event">
    </tidy-calendar>

    <ng-container *ngIf="loaded && calendarLoaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Bookable times are times clients can book you directly (like OpenTable). When choosing a pro, clients generally choose the pro with bookable times. '">
        </tidy-text>
        <tidy-text
          [actionBody]="'Learn More'"
          class="link"
          (action)="goToLearnMoreSchedule()">
        </tidy-text>
      </tidy-row>

      <tidy-row>
        <tidy-text
          (action)="pause()"
          [body]="'Pause All Bookable Hours'"
          class="link">
        </tidy-text>
      </tidy-row>

      <tidy-row>
        <tidy-text
          [header]="'Bookable Hours Change History'">
        </tidy-text>
      </tidy-row>

      <tidy-alert *ngIf="!changeHistory?.length">
        <tidy-row>
          <tidy-text
            [body]="'No bookable hours changes.'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <tidy-card *ngFor="let change of changeHistory">
        <tidy-row class="vertical-align-center">
          <tidy-image
            class="body-size"
            [src]="'assets/svg/arrow-redo-circle.svg'">
          </tidy-image>
          <tidy-text
            [helper]="'Change on '">
          </tidy-text>
          <tidy-text
            *ngIf="change?.item_type === 'schedule_change'"
            [helper]="change?.data?.history_change_created_at">
          </tidy-text>
          <tidy-text
            *ngIf="change?.item_type !== 'schedule_change'"
            [helper]="change?.data?.change_created_at">
          </tidy-text>
        </tidy-row>
        <ng-container *ngIf="change?.item_type === 'schedule_change'">
          <tidy-row>
            <tidy-text
              [body]="'New Bookable Hours: '">
            </tidy-text>
            <tidy-text
              [body]="change?.data?.history_change_days">
            </tidy-text>
            <tidy-text
              [body]="' - '">
            </tidy-text>
            <tidy-text
              [body]="change?.data?.times_marked_bookable">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="change?.history_change_cleaning">
            <tidy-text
              [body]="change?.history_change_cleaning">
            </tidy-text>
          </tidy-row>
        </ng-container>
        <tidy-row *ngIf="change?.item_type == 'pay_rate_change'">
          <tidy-text
            [body]="'Pay Rate Change: '">
          </tidy-text>
          <tidy-text
            [body]="change?.data?.team_service_name">
          </tidy-text>
          <tidy-text
            [body]="' rates changed to '">
          </tidy-text>
          <tidy-text
            [body]="(change?.data?.user_new_rate | tcurrency)">
          </tidy-text>
          <tidy-text
            [body]="' for new clients and '">
          </tidy-text>
          <tidy-text
            [body]="(change?.data?.user_repeat_rate | tcurrency)">
          </tidy-text>
          <tidy-text
            [body]="' for repeat clients.'">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="change?.item_type !== 'schedule_change' && change?.item_type !== 'pay_rate_change'">
          <tidy-text
            [body]="change?.data?.setting_changed">
          </tidy-text>
        </tidy-row>
      </tidy-card>
    </ng-container>

    <ng-container *ngIf="!loaded || !calendarLoaded">
      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="height:240px"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

      <tidy-row>
      <ion-skeleton-text animated style="width: 40%; height:13px"></ion-skeleton-text>
      </tidy-row>

      <tidy-row>
        <ion-skeleton-text animated style="width: 70%; height:13px"></ion-skeleton-text>
      </tidy-row>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 70%; height:13px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 100%; height:13px"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 20%; height:13px"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>
      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 73%; height:13px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 100%; height:13px"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 18%; height:13px"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>
      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 69%; height:13px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 100%; height:13px"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 15%; height:13px"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>
      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 72%; height:13px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 100%; height:13px"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 18%; height:13px"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>
      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 70%; height:13px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 100%; height:13px"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 24%; height:13px"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
