import { Pipe, PipeTransform } from '@angular/core';
import frequencies from 'src/shared/constants/frequencies';

@Pipe({name: 'planFrequency'})
export class PlanFrequencyPipe implements PipeTransform {

  transform(value: string): any {
    if (!value) { return value; }

    const frequency = frequencies;

    return frequency[value];
  }
}
