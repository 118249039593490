import { Injectable } from '@angular/core';
import { HttpClient } from '../../shared/providers/http/http-client';

@Injectable()
export class CleaningOportunities {

  constructor(
    private httpClient: HttpClient
  ) {}

  async changeStatus(status: string, notificationId: string): Promise<any> {
    const url = `api/v1/homekeeper/cleaning-opportunities/notifications/${notificationId}`;
    return this.httpClient.put(url, {notification_status: status});
  }

}
