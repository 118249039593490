<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Access Notes'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-card>
        <form [formGroup]="form" novalidate action="#">
          <tidy-row class="field-bottom-padding vertical-align-center">
            <tidy-image
              [src]="'assets/svg/car.svg'">
            </tidy-image>
            <tidy-text
              [header]="'How to Park'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="field-bottom-padding">
            <tidy-select
              [label]="'What kind of parking?'"
              [form]="form.controls.paid_parking"
              formControlName="paid_parking"
              [items]="parkingTypes"
              (optionChange)="clearFields()">
            </tidy-select>
          </tidy-row>
          <tidy-row class="field-bottom-padding">
            <tidy-select
              [label]="'What kind of spot?'"
              [form]="form.controls.parking_spot"
              formControlName="parking_spot"
              [items]="form.controls.paid_parking.value ? paidParkingSpots : freeParkingSpots">
            </tidy-select>
          </tidy-row>
          <ng-container *ngIf="form.controls.paid_parking.value">
            <tidy-row class="field-bottom-padding">
              <tidy-select
                [label]="'How will they pay?'"
                [form]="form.controls.parking_pay_with"
                formControlName="parking_pay_with"
                [items]="payOptions">
              </tidy-select>
            </tidy-row>
            <tidy-row class="field-bottom-padding">
              <tidy-select
                [label]="'How much will they reimburse?'"
                [form]="form.controls.max_parking_cost"
                formControlName="max_parking_cost"
                [items]="maxCostOptions">
              </tidy-select>
            </tidy-row>
          </ng-container>
          <tidy-row>
            <tidy-input
              [label]="'Any notes to help park?'"
              formControlName="parking_notes"
              [form]="form.controls.parking_notes"
              [errorMessage]="'Please detail any helpful notes on parking near this property'">
            </tidy-input>
          </tidy-row>
        </form>
      </tidy-card>

      <tidy-card>
        <form [formGroup]="form" novalidate action="#">
          <tidy-row class="extra-bottom-padding vertical-align-center">
            <tidy-image
              [src]="'assets/svg/lock-open.svg'">
            </tidy-image>
            <tidy-text
              [header]="'How to Get In'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="field-top-padding">
            <tidy-input
              [label]="'How to access the property?'"
              formControlName="home_access"
              [form]="form.controls.home_access"
              [submitted]="submitted"
              [errorMessage]="'Please detail on how to access their property'">
            </tidy-input>
          </tidy-row>
        </form>
      </tidy-card>

      <tidy-card>
        <form [formGroup]="form" novalidate action="#">
          <tidy-row class="extra-bottom-padding vertical-align-center">
            <tidy-image
              [src]="'assets/svg/lock-closed.svg'">
            </tidy-image>
            <tidy-text
              [header]="'How to Close Up'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="field-top-padding">
            <tidy-input
              [label]="'What to do as you leave the property?'"
              formControlName="home_close"
              [form]="form.controls.home_close">
            </tidy-input>
          </tidy-row>
        </form>
      </tidy-card>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-button
        [text]="'Save Changes'"
        data-cy="btn-saveChanges"
        [action]="saveChanges.bind(this)"
        class="primary">
      </tidy-button>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
