import { Component, Input, OnInit } from '@angular/core';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  selector: 'payment-tag',
  template: `
    <ng-container *ngIf="billingType == 'flat_rate'">
      <ng-container *ngIf="bonusAmount === 0">
        <tidy-text
          [body]="(totalAmount | tcurrency) + (' Total' | translate)">
        </tidy-text>
      </ng-container>
      <ng-container *ngIf="bonusAmount > 0">
        <tidy-text
          [body]="(totalAmount - bonusAmount | tcurrency) +
          ' + ' +
          (bonusAmount | tcurrency) +
          (' Bonus' | translate)">
        </tidy-text>
      </ng-container>
    </ng-container>
    <tidy-text
      *ngIf="billingType == 'hourly'"
      [body]="(totalAmount | tcurrency) + (' /hour' | translate)">
    </tidy-text>
    <tidy-text
      *ngIf="billingType == 'price_later'"
      [body]="'Price Later' | translate">
    </tidy-text>
  `
})

export class PaymentTag implements OnInit {
  @Input() totalValue: any;
  @Input() billingType: any;
  @Input() bonusValue: any;
  totalAmount: any;
  bonusAmount: any;

  ngOnInit() {
    this.totalAmount = this.totalValue;
    this.bonusAmount = this.bonusValue;
  }
}
