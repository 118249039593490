import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PrivatePayments } from 'src/providers/private-payments/private-payments';
import { PrivateClient } from 'src/providers/private-client/private-client';

import { Loading } from 'src/shared/components/loading/loading';

import { ClientModel } from 'src/shared/models/client.model';
import { PrivateJobModel } from 'src/shared/models/private-job.model';
import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';

import { TidyCurrencyPipe } from 'src/shared/pipes/tidy-currency';
import { TidyDatePipe } from 'src/shared/pipes/tidy-date';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: 'request-payment.html',
  encapsulation: ViewEncapsulation.None
})

export class RequestPaymentPage {

  client: ClientModel;
  errorMessage: string;
  loaded: boolean;
  noUnpaidJobCards: any;
  selectedJobs: PrivateJobModel[];
  unpaidJobs: PrivateJobModel[];
  unpaidJobsWithInvoice: PrivateJobModel[];
  unpaidJobItems: TidySelectNumberValueModel[];

  constructor(
    private navCtrl: CustomNavController,
    private privatePayments: PrivatePayments,
    private privateClient: PrivateClient,
    private tidyCurrencyPipe: TidyCurrencyPipe,
    private tidyDatePipe: TidyDatePipe
  ) {}

  @Loading()
  async ionViewDidEnter() {
    this.loaded = false;
    this.selectedJobs = [];
    this.unpaidJobs = this.navCtrl.getParam('unpaidJobs');
    this.client = this.navCtrl.getParam('client');
    this.unpaidJobItems = this.buildUnpaidJobItems();
    this.unpaidJobsWithInvoice = this.getUnpaidJobsWithInvoice();
    this.noUnpaidJobCards = this.getNoUnpaidJobCards();
    this.loaded = true;
  }

  buildUnpaidJobItems() {
    let array = [];
    const unpaidJobs = this.unpaidJobs.filter((job) => {
      if (!job.invoice && job.private_status !== 'scheduled') {
        return job;
      }
    });
    unpaidJobs.map((job) => {
      const amount = this.tidyCurrencyPipe.transform(job.billing.amount_due);
      const date = this.tidyDatePipe.transform(job.date, 'dddd M/D');
      array.push(({
        viewValue: amount + new TranslationPipe().transform(' owed for ') + date + new TranslationPipe().transform(' job'),
        value: job.id
      }));
    });
    return array;
  }

  getUnpaidJobsWithInvoice() {
    return this.unpaidJobs.filter((job) => {
      if (job?.invoice !== null && job?.invoice !== undefined) {
        return job;
      }
    });
  }

  selectJob(selectedJob) {
    const job = this.unpaidJobs.find((job) => (job.id == selectedJob.value));
    const jobAdded = this.selectedJobs.find((job) => (job.id == selectedJob.value));
    if (jobAdded) {
      const index = this.selectedJobs.indexOf(job);
      this.selectedJobs.splice(index, 1);
    } else {
      this.selectedJobs.push(job);
    }
  }

  goToAddJobPage() {
    const params = {
      clientName: this.client.first_name + ' ' + this.client.last_name
    }
    this.navCtrl.navigateForward(['add-private-job', this.client.id], params);
  }

  goToDepositForFutureJobPage() {
    this.navCtrl.navigateForward('upcoming-feature', {feature: 'deposit_future_job'});
  }

  async goToSendInvoicePage() {
    if (this.selectedJobs.length == 0) {
      return this.errorMessage = new TranslationPipe().transform('Please select job(s) to request.');
    }
    const jobDetails = await this.privateClient.getJobDetailsForAddress(this.selectedJobs[0].id.toString(), true);
    this.selectedJobs[0]['duration'] = jobDetails.job.job_durations.reported_by_homekeeper || jobDetails.job.job_durations.from_start_to_end_moment;
    const params = {
      selectedJobs: this.selectedJobs,
      client: this.client
    }
    this.navCtrl.navigateForward(`send-invoice`, params);
  }

  getNoUnpaidJobCards() {
    return [
      {
        text: 'Pick a job you need payment for.',
        image: 'assets/img/calendar.svg'
      },
      {
        text: 'Send your Client an invoice by email, by SMS, or by link.',
        image: 'assets/img/send.svg'
      },
      {
        text: 'Accept credit cards for faster payments & easy accounting.',
        image: 'assets/img/lightning.svg'
      }
    ];
  }
}
