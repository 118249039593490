<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Services & Pay Rates'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper *ngIf="loaded">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container>
      <tidy-row [align]="'center'">
        <tidy-text
          [body]="('These rates will be the default when you add jobs for a client or when they book you through your website: ' | translate) + 'www.tidy.com/' + website?.url_suffix">
        </tidy-text>
      </tidy-row>

      <tidy-row>
        <tidy-text
          [body]="savingText">
        </tidy-text>
      </tidy-row>

      <tidy-error-message
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-card>
        <tidy-row>
          <tidy-text
            [header]="'Cleaning Services'">
          </tidy-text>
        </tidy-row>
        <ng-container [formGroup]="form">
          <tidy-row class="field-top-padding field-bottom-padding">
            <tidy-select
              [icon]="'assets/svg/time-outline.svg'"
              [label]="'Minimum Length'"
              [form]="form.controls.minLength"
              formControlName="minLength"
              [items]="lengths"
              (optionChange)="saveChanges()">
            </tidy-select>
          </tidy-row>
          <tidy-row class="field-bottom-padding">
            <tidy-select
              [icon]="'assets/svg/time-outline.svg'"
              [label]="'Maximum Length'"
              [form]="form.controls.maxLength"
              formControlName="maxLength"
              [items]="lengths"
              (optionChange)="saveChanges()">
            </tidy-select>
          </tidy-row>
          <tidy-row class="field-bottom-padding">
            <tidy-select
              [icon]="'assets/svg/card-outline.svg'"
              [label]="'Minimum Cost'"
              [form]="form.controls.minCost"
              formControlName="minCost"
              [items]="costs"
              (optionChange)="saveChanges()">
            </tidy-select>
          </tidy-row>
          <tidy-row>
            <tidy-select
              [icon]="'assets/svg/card-outline.svg'"
              [label]="'Hourly Rate'"
              [form]="form.controls.hourlyRate"
              formControlName="hourlyRate"
              [items]="costs"
              (optionChange)="saveChanges()">
            </tidy-select>
          </tidy-row>
        </ng-container>
      </tidy-card>

      <tidy-card>
        <tidy-row class="extra-bottom-padding" [alignLastItemRight]="true">
          <tidy-text
            [header]="'Other Services'">
          </tidy-text>
          <tidy-text
            [body]="'Add Service'"
            (action)="goToUpcomingFeaturePage('other_services')"
            class="link">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'None Added'">
          </tidy-text>
        </tidy-row>
      </tidy-card>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
