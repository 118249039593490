import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[customTextarea]'
})

export class CustomTextarea  {

  private el: ElementRef;
  private lines: number;
  changed = false;

  @Input() text: string;
  @Input() lineSize: number;
  @Input() customTextarea: any;

  constructor(private _el: ElementRef) {
    this.el = this._el;
  }

  ngAfterViewInit() {
    const observer = new MutationObserver(mutations => {
      mutations.forEach(() => {
        if (this.changed === false) {
          this.changed = true;
          this.calcSize();
        }
      });
    });
    const config = { attributes: true, childList: true, characterData: true };
    observer.observe(this.el.nativeElement, config);
    this.calcSize();
  }

  calcSize() {
    const parent = this.el.nativeElement;
    const textarea = parent.querySelector('textarea');
    parent.style.cssText = 'height: 0';
    parent.style.height = `${textarea.scrollHeight}px`;
  }

  @HostListener('keyup', ['$event']) onKeyDown(e) {
    this.calcSize();
  }
}
