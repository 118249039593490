<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="('Reschedule ' | translate) + (rescheduleType | titlecase)">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-alert>
      <redo-alert-section
        [cancelledBy]="cancelledBy"
        [withFee]="withFee"
        [frequency]="currentFrequency"
        [rescheduleType]="rescheduleType"
        [date]="date"
      ></redo-alert-section>
    </tidy-alert>

    <tidy-card>
      <client-and-adress-section
        [clientName]="clientName"
        [addressName]="addressName"
      ></client-and-adress-section>
    </tidy-card>

    <tidy-card>
      <jobs-details-section
        [date]="date"
        [frequency]="frequency === 'not-changed' ? currentFrequency : frequency"
        [startTimeLabel]="startTimeLabel"
        [service]="service"
        [serviceName]="serviceName"
        [description]="description"
        [price]="amount"
      >
      </jobs-details-section>
    </tidy-card>

    <tidy-two-column-row>
      <tidy-button
        [text]="'Go Back'"
        [action]="goBack.bind(this)"
        class="secondary">
      >
      </tidy-button>

      <tidy-button
        [text]="'Next'"
        [action]="next.bind(this)"
        class="primary">
      >
      </tidy-button>
    </tidy-two-column-row>

    <tidy-error-message
      [text]="errorMessage"
    >
    </tidy-error-message>

    <footer-informations></footer-informations>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
