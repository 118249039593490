import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { BankInfo } from 'src/providers/bank-info/bank-info';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PrivatePayments } from 'src/providers/private-payments/private-payments';
import { Me } from 'src/shared/providers/me';

import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { Router, ActivatedRoute } from '@angular/router';

import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

@Component({
  templateUrl: 'client-payment-settings.html'
})

export class ClientPaymentSettingsPage extends Timeout {

  allowsCardPayments: boolean;
  addressItems: TidySelectNumberValueModel[];
  cameFromOnboardingPage: boolean;
  form: UntypedFormGroup;
  submitted: boolean;
  paymentOptions: any;

  constructor(
    private bankInfo: BankInfo,
    private formBuilder: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private me: Me,
    private privatePayments: PrivatePayments,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.formBuilder.group({
      settings: ['', Validators.required],
    })
  }

  async ionViewDidEnter() {
    try {
      this.loaded = false;
      this.cameFromOnboardingPage = this.navCtrl.getParam('cameFromOnboardingPage');
      const paymentSetting = localStorage.getItem('paymentSetting');
      if (!paymentSetting) {
        const payload = {
          key: 'payment_setting',
          value: 'true'
        }
        await this.bankInfo.updatePayoutSetting(payload);
        localStorage.setItem('paymentSetting', 'true');
      }
      this.paymentOptions = await this.privatePayments.getPaymentOptions();
      const settings = await this.me.getSettings();
      const setting = settings.items.find((item) => item.key == 'payments.allow_credit_card_on_private_payments');
      this.allowsCardPayments = setting.value == 'true';
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  addNewOption() {
    const params = {
      paymentOptions: this.paymentOptions
    }
    this.navCtrl.navigateForward('add-client-payment-setting', params);
  }

  goToEditPaymentOption(setting) {
    const params = {
      paymentOptions: this.paymentOptions,
      setting
    };
    this.navCtrl.navigateForward('edit-client-payment-setting', params);
  }
}
