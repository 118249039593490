<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Call Out'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-alert>
        <tidy-row [align]="'center'" class="extra-bottom-padding">
          <tidy-text
            [title]="title">
          </tidy-text>
        </tidy-row>
        <tidy-row [align]="'center'" class="extra-bottom-padding">
          <tidy-text
            [body]="'Add your team to easily delegate jobs.'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-divider>
          </tidy-divider>
        </tidy-row>
        <tidy-grid>
          <tidy-row class="extra-bottom-padding">
            <ion-row>
              <ion-col size="1">
                <tidy-image
                  class="body-size"
                  [src]="'assets/svg/flash.svg'">
                </tidy-image>
              </ion-col>
              <ion-col>
                <tidy-text
                  [header]="'Takes Just a Few Minutes'">
                </tidy-text><br>
                <tidy-text
                  [body]="'The person you send it to just has to fill out some basic info.'">
                </tidy-text>
              </ion-col>
            </ion-row>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <ion-row>
              <ion-col size="1">
                <tidy-image
                  class="body-size"
                  [src]="'assets/svg/cash.svg'">
                </tidy-image>
              </ion-col>
              <ion-col>
                <tidy-text
                  [header]="'Avoid Client Fees'">
                </tidy-text><br>
                <tidy-text
                  [body]="'Avoiding client fees is the biggest factor in earning more.'">
                </tidy-text>
              </ion-col>
            </ion-row>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <ion-row >
              <ion-col size="1">
                <tidy-image
                  class="body-size"
                  [src]="'assets/svg/happy.svg'">
                </tidy-image>
              </ion-col>
              <ion-col>
                <tidy-text
                  [header]="'Keep Your Clients Happy'">
                </tidy-text><br>
                <tidy-text
                  [body]="'Keep a strong relationship with your clients by never cancelling.'">
                </tidy-text>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-grid>
      </tidy-alert>

      <tidy-button
        [text]="'Go Back, I Want to Delegate'"
        [action]="showSuccessPage.bind(this)"
        class="primary">
      </tidy-button>

      <tidy-button
        [text]="'Call Out'"
        [action]="goToCallOutPage.bind(this)"
        class="secondary">
      </tidy-button>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
