import { Injectable } from '@angular/core';
import { HttpClient } from '../../shared/providers/http/http-client';
import { TidyStorage } from '../../shared/providers/tidy-storage';

@Injectable()
export class Homekeepers {
  constructor(
    private httpClient: HttpClient,
    private storage: TidyStorage
  ) {}

  async createFaceScan(data): Promise<any> {
    const url = `api/v1/homekeeper/face-scans`;
    return this.httpClient.post(url, data);
  }

  async declineCleaning(cleaningRequestId: string): Promise<any> {
    const url = `api/v1/homekeeper/emergency-cleanings/${cleaningRequestId}/decline`;
    return this.httpClient.post(url, { emergency_cleaning_request_id: cleaningRequestId });
  }

  async claimCleaning(data): Promise<any> {
    const url = `api/v1/homekeeper/emergency-cleanings`;

    return this.httpClient.post(url, data);
  }

  reportIssue(issue, job_id): Promise<any> {
    const url = `api/v1/homekeeper/cleanings/${job_id}/report-issue`;
    return this.httpClient.put(url, {issue});
  }

  async fetchLmsNoCache(): Promise<any> {
    const hkId = await this.hkId();
    const url = `api/v1/homekeeper/emergency-cleanings`;
    return this.httpClient.getNoCache(url);
  }

  async hkId() {
    return await this.storage.retrieve('hk_id');
  }

  acceptPlanUpgrade(planId) {
    const url = `api/v1/homekeeper/plans/${planId}/plan-upgrade`;
    return this.httpClient.post(url, {});
  }

  checkEligibleforPlanUpgrade(planId) {
    const url = `api/v1/homekeeper/plans/${planId}/check-eligibility-for-plan-upgrade`;
    return this.httpClient.get(url);
  }

  createANewPlan(params) {
    const url = `api/v1/homekeeper/private/plans`

    return this.httpClient.post(url, params);
  }


  rescheduleJob(params, jobId: string) {
    const url = `api/v1/homekeeper/private/jobs/${jobId}/reschedule`

    return this.httpClient.post(url, params);
  }

  /*
    TODO make the model for that endpoint
    {
      "plan" : {
        "frequency": "once|every_week|twice_a_week|thrice_a_week|every_other_week|every_four_weeks",
      },
      "jobs": [
        {
          "date":                "2016-10-10",
          "start_time":          "08:00",
          "duration_in_minutes": 60,
          "amount":              1000
        }
      ],

      "plan_id": 1
    }
  */
  reschedulePlan(params, planId: string) {
    const url = `api/v1/homekeeper/private/plans/${planId}/reschedule`

    return this.httpClient.post(url, params);
  }

  addJobToExistentPlan(params) {
    const url = `api/v1/homekeeper/private/jobs`;

    return this.httpClient.post(url, params);
  }

  async getClientRequests() {
    const url = `api/v1/homekeeper/availability-requests`;
    return this.httpClient.get(url);
  }

  async acceptClientRequest(id, data) {
    const url = `api/v1/homekeeper/availability-requests/${id}/accept`;
    return this.httpClient.put(url, data);
  }

  async declineClientRequest(id) {
    const url = `api/v1/homekeeper/availability-requests/${id}/reject`;
    const data = {};
    return this.httpClient.put(url, data);
  }

  async getAlternateTimes(addressId, frequency, cleaning) {
    const url = `api/v1/homekeeper/availability-requests/selectable-dates-and-times?address_id=${addressId}&service_type_key=${cleaning}&frequency=${frequency}`;
    return this.httpClient.get(url);
  }

  async proposeAlternateTimes(id, data) {
    const url = `api/v1/homekeeper/availability-requests/${id}/counter`;
    return this.httpClient.post(url, data);
  }

  async cancelCounterRequest(id) {
    const url = `api/v1/homekeeper/availability-requests/${id}`;
    return this.httpClient.delete(url);
  }

}
