import { Component } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Component({
  templateUrl: 'edit-private-plan.html'
})

export class EditPrivatePlanPage {

  constructor(
    private customNavCtrl: CustomNavController
  ) {}

  reschedulePlan() {
    const params = {
      clientName: this.customNavCtrl.getParam('clientName', true),
      currentFrequency: this.customNavCtrl.getParam('currentFrequency', true),
      addressName: this.customNavCtrl.getParam('addressName', true),
      jobDateAndHour: this.customNavCtrl.getParam('jobDateAndHour', true),
      jobData: this.customNavCtrl.getParam('jobData', true),
      planId: this.customNavCtrl.getParam('planId', true),
      rescheduleType: this.customNavCtrl.getParam('rescheduleType', true),
      serviceTypeDetails: this.customNavCtrl.getParam('serviceTypeDetails', true),
      addressId: this.customNavCtrl.getParam('addressId', true),
    };
    this.customNavCtrl.navigateForward('private-reschedule-ask-subject', params);
  }

  cancelPlan() {
    const params = {
      clientName: this.customNavCtrl.getParam('clientName', true),
      jobData: this.customNavCtrl.getParam('jobData', true)
    };
    const planId = this.customNavCtrl.getParam('planId', true);
    this.customNavCtrl.navigateForward(`private-cancellation/plan/${planId}`, params);
  }
}
