<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="teamIAmOn?.name"
    [customBack]="'team'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card>
      <tidy-row>
        <tidy-text
          [header]="'Owner Name'">
        </tidy-text>
      </tidy-row>
      <tidy-row class="extra-bottom-padding">
        <tidy-text
          [body]="teamIAmOn?.owner?.name"
          [translate]="false">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [header]="'Owner Email'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="teamIAmOn?.owner?.email">
        </tidy-text>
      </tidy-row>
      <ng-container *ngIf="teamIAmOn?.description">
        <tidy-row class="extra-top-padding">
          <tidy-text
            [header]="'Team Description'">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="teamIAmOn?.description">
          <tidy-text
            [body]="teamIAmOn?.description">
          </tidy-text>
        </tidy-row>
      </ng-container>
    </tidy-card>

    <tidy-button
      [action]="leaveTeam.bind(this)"
      [text]="'Leave Team'"
      class="secondary">
    </tidy-button>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
