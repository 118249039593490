import { Injectable } from "@angular/core";
import { IonRouterOutlet } from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class RouterOutletInjector {

  public ionRouterOutlet: IonRouterOutlet;

  setup(routerOutlet: IonRouterOutlet) {
    this.ionRouterOutlet = routerOutlet;
  }
}
