<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Sent Invoice'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-card>
        <tidy-row class="extra-bottom-padding">
          <tidy-grid>
            <tidy-row>
              <ion-row>
                <ion-col size="6">
                  <tidy-row>
                    <tidy-text
                      [title]="'Invoice From'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [body]="proName"
                      [translate]="false">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [body]="proEmail">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [body]="proPhoneNumber | telephone:'format'">
                    </tidy-text>
                  </tidy-row>
                </ion-col>
              </ion-row>
            </tidy-row>
          </tidy-grid>
        </tidy-row>

        <tidy-row class="extra-bottom-padding">
          <!--TODO divider to have [color] input and add dynamic color-->
          <tidy-divider
            class="full-card-width thick"
            style="background: #4DAEED">
          </tidy-divider>
        </tidy-row>

        <div style="margin-top: -15px; background: #F4F4F4; margin-left: -15px; margin-right: -15px; padding: 15px; padding-top:10px ">
          <tidy-row [alignLastItemRight]="true">
            <div>
              <tidy-text
                [body]="'To: '">
              </tidy-text>
              <tidy-text
                [body]="invoice?.customer?.first_name"
                [translate]="false">
              </tidy-text>
            </div>
            <div style="align-self: start">
              <tidy-text
                [body]="('Sent ' | translate) +
                (invoice?.created_at | tdate:'M/D/YY')">
              </tidy-text>
            </div>
          </tidy-row>
        </div>

        <tidy-row class="extra-top-padding" [alignLastItemRight]="true">
          <tidy-text
            [body]="'Service'">
          </tidy-text>
          <tidy-text
            [body]="'Owed'">
          </tidy-text>
        </tidy-row>

        <tidy-row class="extra-bottom-padding">
          <tidy-divider>
          </tidy-divider>
        </tidy-row>

        <ng-container *ngFor="let job of invoice?.items; let last = last">
          <tidy-row [alignLastItemRight]="true">
            <div style="width:85%">
              <tidy-text
                [header]="job?.service_details?.name | titlecase">
              </tidy-text>
            </div>
            <div>
              <tidy-text
                [body]="job?.item?.amount | tcurrency">
              </tidy-text>
            </div>
          </tidy-row>

          <tidy-row>
            <tidy-text
              [body]="(job?.item?.date | tdate: 'M/D') +
              ' ' +
              (job?.item?.start_time | tdate:'h:mma':'HH:mm') +
              (' at ' | translate) +
              (job?.service_details?.address?.address | titlecase) +
              (job?.service_details?.address?.unit !== '' ? (' #' + job?.service_type_details?.address?.unit) : '')">
            </tidy-text>
          </tidy-row>

          <tidy-row>
            <tidy-text
              [body]="'Job Duration: '">
            </tidy-text>
            <ng-container *ngIf="jobHours">
              <tidy-text
                *ngIf="jobHours > 0"
                [body]="jobHours + (jobHours == 1 ? ' hour ' : ' hours ')">
              </tidy-text>
              <tidy-text
                *ngIf="jobMinutes > 0"
                [body]="jobMinutes + (jobMinutes == 1 ? ' minute ' : ' minutes')">
              </tidy-text>
            </ng-container>
            <tidy-text
              *ngIf="!jobHours"
              [body]="'Unknown'">
            </tidy-text>
          </tidy-row>

          <tidy-row class="extra-top-padding" [ngClass] = "last ? '' : 'extra-bottom-padding'">
            <tidy-divider>
            </tidy-divider>
          </tidy-row>
        </ng-container>

        <ng-container *ngIf="!invoice?.full_paid">
          <tidy-row [alignLastItemRight]="true" class="extra-top-padding" *ngIf="creditsApplied > 0">
            <tidy-text
              [header]="'Credit Applied'">
            </tidy-text>
            <tidy-text
              [body]="creditsApplied | tcurrency">
            </tidy-text>
          </tidy-row>

          <tidy-row [alignLastItemRight]="true" class="extra-top-padding">
            <tidy-text
              [header]="'Total'">
            </tidy-text>
            <tidy-text
              [body]="'<b>' + (totalOwed | tcurrency) + '</b>'">
            </tidy-text>
          </tidy-row>
        </ng-container>

        <tidy-row [align]="'center'" class="extra-top-padding">
          <tidy-text
            [body]="'We thank you for your business!'">
          </tidy-text>
        </tidy-row>

        <tidy-row class="no-padding" [align]="'center'">
          <tidy-text
            [body]="proName"
            class="signature"
            [translate]="false">
          </tidy-text>
        </tidy-row>

        <tidy-divider
          style="margin-top:-15px">
        </tidy-divider>

        <ng-container class="no-padding">
          <tidy-row class="extra-top-padding">
            <tidy-text
              [header]="'Invoice Notes'">
            </tidy-text>
          </tidy-row>
          <tidy-row style="padding-bottom: 0px">
            <form [formGroup]="form" novalidate action="#">
              <tidy-select
                style="width:35%; font-size: 8px"
                [form]="form.controls.messageType"
                formControlName="messageType"
                [items]="messageSelectItems"
                [smallText]="true"
                [selectedItem]="selectedMessageType"
                (optionChange)="updateIsInternalComment($event)">
              </tidy-select>
            </form>
          </tidy-row>
          <tidy-row style="padding-top: 3px">
            <form [formGroup]="form" novalidate action="#">
              <tidy-textarea
                [label]="isInternalComment ? 'Add Internal Note' : 'Add Note'"
                formControlName="message"
                [form]="form.controls.message"
                [submitted]="submitted"
                [errorMessage]="'Please enter a message to send.'"
                [rightIconAction]="sendMessage.bind(this)"
                [leftIconAction]="sendAttachment.bind(this)"
                [showBlackButtons]="isInternalComment">
              </tidy-textarea>
            </form>
          </tidy-row>
          <tidy-row *ngIf="invoiceNotes?.length == 0" [align]="'center'">
            <tidy-text
              [helper]="'No Invoice Notes'">
            </tidy-text>
          </tidy-row>
          <ng-container *ngFor="let note of invoiceNotes; let last = last">
            <message
              [message]="note"
              [last]="last"
              [isInvoiceNote]="true">
            </message>
          </ng-container>
        </ng-container>
      </tidy-card>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
