import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Events } from 'src/providers/events/events';
import { Team } from 'src/providers/team/team';
import validationUtils from 'src/shared/utils/validation-utils';

import { SuccessPageParamsModel } from 'src/pages/success/success';

@Component({
  selector: 'website-business-detail',
  templateUrl: 'website-business-detail.html',
})
export class WebsiteBusinessDetailPage {

  errorMessage: string;
  formErrorMessage: string;
  form: UntypedFormGroup;
  submitted: boolean;
  successPageRoute: string;

  constructor(
    private customNavCtrl: CustomNavController,
    private events: Events,
    private fb: UntypedFormBuilder,
    private team: Team,
  ) {
    this.form = this.fb.group({
      businessName: [null, [Validators.required, validationUtils.forbiddenNameValidator(/tidy/i)]],
      businessDescription: ['', Validators.required],
    });
  }

  async ionViewDidEnter() {
    const params = await this.customNavCtrl.getParams();
    this.successPageRoute = params.successPageRoute;
    this.form.controls.businessName.setValue(params.name);
    this.form.controls.businessDescription.setValue(params.description);
  }

  async save() {
    this.errorMessage = '';
    this.submitted = true;

    const data = {
      name: this.form.value.businessName,
      description: this.form.value.businessDescription,
    };

    if (!this.form.valid) {
      if(data.name.toLowerCase().includes('tidy')){
        this.formErrorMessage = 'You can\'t use any variation of the term \"tidy\" in your team or website url.';
      }
      else{
        this.formErrorMessage = 'Please enter your business name.';
      }
      return;
    }

    try {
      await this.team.updateMyTeamDetail(data);
      const params = this.mountSuccessPageParams();
      this.events.publish('team-details:updated', { details: data })
      this.customNavCtrl.navigateForward('success', params);
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  mountSuccessPageParams(): SuccessPageParamsModel {
    return {
      header: `Detail Updated`,
      body: `This detail will now display on your website.`,
      buttonText: `Ok`,
      buttonRoute: this.successPageRoute
    };
  }

}
