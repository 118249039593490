<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Job Messages'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      [text]="errorMessage"
      *ngIf="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">
      <tidy-card>
        <tidy-row>
          <ion-row>
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="job?.job?.service_type_details?.service_category?.icon_url"
                style="width:30px;">
              </tidy-image>
            </tidy-row>
            <ion-col>
              <tidy-row>
                <tidy-text
                  [header]="job?.job?.service_type_details?.name | titlecase">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="job?.job?.date | tdate:'dddd M/D/YY'">
                </tidy-text>
                <tidy-text
                  [body]="' at '">
                </tidy-text>
                <tidy-text
                  [body]="job?.job?.start_time | ttime">
                </tidy-text>
              </tidy-row>
            </ion-col>
          </ion-row>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row class="extra-bottom-padding" [alignLastItemRight]="true">
          <tidy-image
            [src]="'assets/img/account.svg'"
            class="title-size">
          </tidy-image>
          <tidy-text
            [header]="clientName"
            [translate]="false">
          </tidy-text>
          <tidy-text
            [body]="'Call'"
            class="link"
            (action)="callAction()">
          </tidy-text>
        </tidy-row>
        <tidy-row class="field-top-padding extra-bottom-padding">
          <form [formGroup]="form" novalidate action="#">
            <tidy-textarea
              [label]="'Send Message'"
              formControlName="message"
              [form]="form.controls.message"
              [submitted]="submitted"
              [errorMessage]="'Please enter a message to send.'"
              [rightIconAction]="sendMessage.bind(this)"
              [leftIconAction]="sendAttachment.bind(this)">
            </tidy-textarea>
          </form>
        </tidy-row>
        <tidy-row *ngIf="messages?.length == 0" [align]="'center'">
          <tidy-text
            [helper]="'No Messages'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let message of messages; let last = last">
          <message
            [message]="message"
            [last]="last">
          </message>
        </ng-container>
      </tidy-card>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
