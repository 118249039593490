import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PrivatePayments } from 'src/providers/private-payments/private-payments';
import { PrivateClient } from 'src/providers/private-client/private-client';

import { Loading } from 'src/shared/components/loading/loading';

import { BillingHistoryModel } from 'src/models/private-payments.model';
import { ClientModel } from 'src/shared/models/client.model';
import { PrivateJobModel } from 'src/shared/models/private-job.model';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { Timeout } from 'src/shared/components/timeout/timeout';

@Component({
  templateUrl: 'client-billing-options.html',
  encapsulation: ViewEncapsulation.None
})

export class ClientBillingOptionsPage extends Timeout {

  billingHistory: BillingHistoryModel;
  clientId: any;
  client: ClientModel;
  unpaidJobs: PrivateJobModel[];

  constructor(
    private navCtrl: CustomNavController,
    private privateClient: PrivateClient,
    private privatePayments: PrivatePayments,
    public route: ActivatedRoute,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router
  ) {
    super(timeoutErrorHandler, router, route);
  }

  @Loading()
  async ionViewDidEnter() {
    this.loaded = false;
    this.clientId = this.route.snapshot.paramMap.get('clientId');
    try {
      this.client = this.navCtrl.getParam('client') || await this.privateClient.getPrivateClientInfo(this.clientId);
      this.unpaidJobs = this.navCtrl.getParam('unpaidJobs') || await this.privateClient.getUnpaidJobsForClient(this.clientId);
      this.billingHistory = this.navCtrl.getParam('billingHistory') || await this.privatePayments.getBillingHistory(this.clientId);
      this.loaded = true;
    } catch(err) {
      this.timeoutHandler(err);
      //TODO remove this once BE supports billing history for pending Clients
    }
  }

  goToBillingHistoryPage() {
    const params = {
      addresses: this.navCtrl.getParam('addresses'),
      addressSelected: this.navCtrl.getParam('addressSelected'),
      billingHistory: this.billingHistory,
      client: this.client,
      unpaidJobs: this.unpaidJobs,
    }
    this.navCtrl.navigateForward(`client-billing-history/${this.clientId}`, params);
  }

  goToAddCreditPage() {
    const payload = {
      client: this.client
    };
    this.navCtrl.navigateForward('add-credit', payload);
  }

  goToInvoicesPage() {
    localStorage.setItem('clientName', this.client.first_name);
    localStorage.setItem('accountName', this.client.account_name);
    this.navCtrl.navigateForward(`invoices/${this.clientId}`);
  }

  goToRequestPaymentPage() {
    const params = {
      unpaidJobs: this.unpaidJobs,
      client: this.client
    };
    this.navCtrl.navigateForward(`request-payment`, params);
  }

  goToChargeClientPage() {
    this.navCtrl.navigateForward(`request-payment`);
  }

  goToPrivateJobPage(job) {
    this.navCtrl.navigateForward(`private-job/${job.id}`);
  }
}
