import { HomekeeperModel } from 'src/shared/models/homekeeper.model';

export interface ProfileData {
  name: string;
  email: string;
  phone: string;
  address: string;
  driveTime: number;
  referralCode: string;
}

export function parseProfileData(homekeeper: HomekeeperModel): ProfileData {
  return {
    name: homekeeper.profile.name,
    email: homekeeper.profile.email,
    phone: homekeeper.profile.phone_number,
    address: `${homekeeper.profile.address.address}, ${homekeeper.profile.address.zipcode} ${homekeeper.profile.address?.address_name ? (' - ' + homekeeper.profile.address?.address_name + ',') : ','}`,
    driveTime: homekeeper.profile.max_travel_time,
    referralCode: homekeeper.custom_fields.referral_code
  };
}
