<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [backButton]="true"
    [title]="params?.date | tdate:'dddd M/D'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper *ngIf="loaded">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'Toggle the times clients can book with you:'">
      </tidy-text>
    </tidy-row>

    <tidy-card>

      <tidy-row style="padding-top: 10px;" *ngIf="timeRangeForms.length === 0">
        <tidy-text
          [helper]="'Not Bookable on '">
        </tidy-text>
        <tidy-text
          [helper]="(params?.date | tdate:'dddd M/D')">
        </tidy-text>
      </tidy-row>

      <ng-container *ngFor="let formGrp of timeRangeForms; let i = index">

        <tidy-two-column-row [formGroup]="formGrp" [style]=" i > 0 ? 'padding-top: 30px;' : 'padding-top: 10px;'">
          <tidy-select
            style="max-width: 35%; margin: 0;"
            [icon]="'assets/svg/time-outline.svg'"
            [label]="'Earliest Start'"
            [form]="formGrp.controls.start_time"
            formControlName="start_time"
            errorMessage="Select a valid time"
            [submitted]="true"
            (optionChange)="updateValidity()"
            [items]="timeOpts">
          </tidy-select>
          <span style="align-self: center; justify-content: space-around; max-width: 10%;">-</span>
          <tidy-select
            style="max-width: 35%; margin-right: 10px;"
            [icon]="'assets/svg/time-outline.svg'"
            [label]="'Latest Finish'"
            [form]="formGrp.controls.end_time"
            formControlName="end_time"
            errorMessage="Select a valid time"
            [submitted]="true"
            (optionChange)="updateValidity()"
            [items]="endTimes(formGrp.controls.start_time.value)">
          </tidy-select>
          <tidy-image (action)="removeTimeRange(i)" src="assets/img/trash-icon-outline.svg" class="icon body-size" style="max-width: 16px; align-self: center;"></tidy-image>
        </tidy-two-column-row>
      </ng-container>

      <tidy-row style="padding-top: 20px;">
        <tidy-text
          [body]="'+ Add Bookability'"
          (action)="checkDay()"
          class="link">
        </tidy-text>
      </tidy-row>

    </tidy-card>

    <tidy-button
      [action]="saveSingleDay.bind(this)"
      [text]="('Apply to <u>only</u> ' | translate) + (params.date | tdate:'dddd M/D')"
      class="secondary">
    </tidy-button>

    <tidy-button
      [action]="saveAllDays.bind(this)"
      [text]="('Apply to ' | translate)+ (params.date | tdate:'dddd M/D') + (' and <u>All ' | translate) + (params.date | tdate:'dddd') + '\'s ' + 'After</u>'"
      class="secondary">
    </tidy-button>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
