import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  EventEmitter,
  Output,
  OnChanges,
} from '@angular/core';
import * as moment from 'moment';
import {
  ICalendarDay,
  ICalendarJob,
  ISelectPeriodPayload,
  ISelectedPeriod,
  ISelectedWeek,
} from 'src/shared/models/jobs-calendar.model';
import { DateTime as LuxonDateTime } from 'luxon';

@Component({
  selector: 'tidy-jobs-calendar',
  templateUrl: './jobs-calendar.component.html',
  styleUrls: ['./jobs-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class JobsCalendarComponent implements OnInit, OnChanges {
  currentDate = new Date();
  daysInCurrentMonth: number;
  currentMonth: string;
  currentYear: number;
  calendarMatrix: ICalendarDay[][];
  todayDay: number;
  isInitialMonth: boolean;
  selectedWeek: ISelectedWeek;
  selectedDay: number;
  parsedStartDate: string;

  @Input() jobs: ICalendarJob[] = [];
  @Output() selectedStartDate = new EventEmitter<string>();
  @Output() selectedPeriod = new EventEmitter<ISelectedPeriod>();
  @Output() selectedWeekEmitter = new EventEmitter<ISelectedWeek>();

  constructor() {}

  ngOnInit(): void {
    this.initializeCalendarVariables();
    this.generateCalendar();
    this.emitInitialPeriod();
    this.automaticallySetWeek(true);
    this.putJobsInCalendar();
  }

  ngOnChanges(): void {
    this.putJobsInCalendar();
  }

  /**
   * Initialize the calendar variables
   */
  initializeCalendarVariables(): void {
    this.isInitialMonth = true;
    this.todayDay = new Date().getDate();
    this.currentMonth = moment().format('MMMM');
    this.currentYear = this.currentDate.getFullYear();
    this.daysInCurrentMonth = new Date(
      this.currentYear,
      this.currentDate.getMonth() + 1,
      0
    ).getDate();
    this.selectedDay = this.todayDay;
  }

  /**
   * Generate the calendar matrix
   */
  generateCalendar(): void {
    const firstDay = moment()
      .year(this.currentYear)
      .month(this.currentDate.getMonth())
      .date(1);
    const lastMonthDaysInCurrentMonth = firstDay.day();
    const calendar: any[][] = [[]];
    let indexWeek = 0;
    let indexDaysWeek = 0;
    let currentMonthIndexDay = 1;
    let prevMonthDays = moment()
      .year(this.currentYear)
      .month(this.currentDate.getMonth())
      .date(0)
      .daysInMonth();

    // Create an empty calendar with the first day of the month in the correct position
    Array.from(Array(7).keys()).forEach((i) => {
      if (i < lastMonthDaysInCurrentMonth) {
        calendar[indexWeek][i] = {
          day: prevMonthDays - lastMonthDaysInCurrentMonth + i + 1,
          disabled: true,
          month: moment()
            .month(this.currentDate.getMonth() - 1)
            .format('MMMM'),
        };
      } else {
        calendar[indexWeek][i] = {
          day: currentMonthIndexDay,
          disabled: false,
          month: moment().month(this.currentDate.getMonth()).format('MMMM'),
        };
        currentMonthIndexDay++;
      }
    });

    // Fill in the rest of the calendar
    Array.from(
      Array(this.daysInCurrentMonth - currentMonthIndexDay + 1).keys()
    ).forEach((i) => {
      if (i === 0) {
        indexWeek++;
        calendar[indexWeek] = [];
      }
      calendar[indexWeek][indexDaysWeek] = {
        day: i + currentMonthIndexDay,
        disabled: false,
        month: moment().month(this.currentDate.getMonth()).format('MMMM'),
      };
      if (indexDaysWeek >= 6) {
        indexWeek++;
        calendar[indexWeek] = [];
        indexDaysWeek = 0;
      } else {
        indexDaysWeek++;
      }
    });

    // Fill in the rest of the calendar with the next month days
    const emptySpaces = 7 - calendar[indexWeek].length;
    Array.from(Array(emptySpaces).keys()).forEach((key) => {
      calendar[indexWeek].push({
        day: key + 1,
        disabled: true,
        month: moment()
          .month(this.currentDate.getMonth() + 1)
          .format('MMMM'),
      });
    });

    this.calendarMatrix = calendar;
  }

  /**
   * Emit the initial period of time
   */
  emitInitialPeriod(): void {
    const firstDay = this.calendarMatrix[0][0];
    const endDay = this.calendarMatrix[this.calendarMatrix.length - 1][6];
    const payload = {
      startDate: {
        day: firstDay.day,
        disabled: firstDay.disabled,
      },
      endDate: {
        day: endDay.day,
        disabled: endDay.disabled,
      },
    };
    this.selectPeriod(payload);
  }

  /**
   * Automatically set the current week and day
   */
  automaticallySetWeek(isPeriodChange = false): void {
    if (!this.isInitialMonth) {
      this.selectWeek(0, isPeriodChange);
      this.selectDay(this.calendarMatrix[0][0]);
      return;
    }
    const foundWeekIndex = this.calendarMatrix.findIndex((week) =>
      week.find((day) => day.day === this.todayDay)
    );
    this.selectWeek(foundWeekIndex, isPeriodChange);
    const foundDayIndex = this.calendarMatrix[foundWeekIndex].findIndex(
      (day) => day.day === this.todayDay
    );
    this.selectDay(this.calendarMatrix[foundWeekIndex][foundDayIndex]);
  }

  /**
   * Put the jobs in the calendar matrix
   */
  putJobsInCalendar(): void {
    this.calendarMatrix.forEach((week) => {
      week.forEach((day) => {
        if (day && this.jobs) {
          if (day?.month) {
            const formattedDate = this.formatDate(
              day.day.toString(),
              day.month
            );
            const jobCount = this.jobs[formattedDate];
            if (jobCount) {
              day.jobQuantity = jobCount;
              day.jobQuantityToDisplay = jobCount > 4 ? 4 : jobCount;
            }
          }
        }
      });
    });
  }

  formatDate(dayNumber: string, monthName: string): string {
    const year = LuxonDateTime.now().year;
    const date = LuxonDateTime.fromObject({
      day: parseInt(dayNumber, 10),
      month: LuxonDateTime.fromFormat(monthName, 'MMMM').month,
      year: year,
    });
    return date.toFormat('MM-dd');
  }

  /**
   * Go to the previous month and update the calendar
   */
  previousMonth(): void {
    this.currentDate = new Date(
      this.currentYear,
      this.currentDate.getMonth() - 1,
      1
    );
    this.currentMonth = moment(this.currentDate).format('MMMM');
    this.currentYear = this.currentDate.getFullYear();
    this.daysInCurrentMonth = new Date(
      this.currentYear,
      this.currentDate.getMonth() + 1,
      0
    ).getDate();
    this.generateCalendar();
    this.verifyInitialMonth();
    this.automaticallySetWeek(true);
    const firstDay = this.calendarMatrix[0][0];
    const endDay = this.calendarMatrix[this.calendarMatrix.length - 1][6];
    const payload = {
      startDate: {
        day: firstDay.day,
        disabled: firstDay.disabled,
      },
      endDate: {
        day: endDay.day,
        disabled: endDay.disabled,
      },
    };
    this.selectPeriod(payload);
  }

  /**
   * Go to the next month and update the calendar
   */
  nextMonth(): void {
    this.currentDate = new Date(
      this.currentYear,
      this.currentDate.getMonth() + 1,
      1
    );
    this.currentMonth = moment(this.currentDate).format('MMMM');
    this.currentYear = this.currentDate.getFullYear();
    this.daysInCurrentMonth = new Date(
      this.currentYear,
      this.currentDate.getMonth() + 1,
      0
    ).getDate();
    this.generateCalendar();
    this.verifyInitialMonth();
    this.automaticallySetWeek(true);
    const firstDay = this.calendarMatrix[0][0];
    const endDay = this.calendarMatrix[this.calendarMatrix.length - 1][6];
    const payload = {
      startDate: {
        day: firstDay.day,
        disabled: firstDay.disabled,
      },
      endDate: {
        day: endDay.day,
        disabled: endDay.disabled,
      },
    };
    this.selectPeriod(payload);
  }

  /**
   * Verify if the current month is the initial month
   */
  private verifyInitialMonth(): void {
    if (
      this.currentDate.getMonth() === new Date().getMonth() &&
      this.currentDate.getFullYear() === new Date().getFullYear()
    ) {
      this.isInitialMonth = true;
    } else {
      this.isInitialMonth = false;
    }
  }

  /**
   * Select a week and update the selected day
   * @param index
   */
  selectWeek(index: number, isPeriodChange = false): void {
    if (
      this.selectedWeek?.index === index &&
      this.selectedWeek?.month === this.currentMonth
    ) {
      return;
    }
    const startWeekDate = this.calendarMatrix[index][0];
    let month = this.currentDate.getMonth();
    if (startWeekDate.disabled) {
      if (startWeekDate.day <= 7) {
        month++;
      }
      if (startWeekDate.day >= 23) {
        month--;
      }
    }
    const startDate = new Date(this.currentYear, month, startWeekDate.day);
    const parsedStartWeekDate = moment(startDate).format('YYYY-MM-DD');
    const today = moment().format('YYYY-MM-DD');
    const isTodayInWeek = this.calendarMatrix[index].find(
      (day) => day.day === moment(today).date()
    );
    this.selectedWeek = {
      index,
      month: this.currentMonth,
      startWeekDate: parsedStartWeekDate,
      selectedDate: isTodayInWeek && isPeriodChange ? today : (isPeriodChange ? parsedStartWeekDate : this.parsedStartDate),
    };
    this.selectedWeekEmitter.emit(this.selectedWeek);
  }

  /**
   * Verify if a day is selected
   * @param index
   * @returns boolean
   */
  isSelectedWeek(index: number): boolean {
    return (
      this.selectedWeek?.index === index &&
      this.selectedWeek?.month === this.currentMonth
    );
  }

  /**
   * Select a day and emit it for filtering
   * @param day
   */
  selectDay(day: ICalendarDay): void {
    let month = this.currentDate.getMonth();
    if (day.disabled) {
      if (day.day <= 7) {
        month++;
      }
      if (day.day >= 25) {
        month--;
      }
    }
    this.selectedDay = day.day;
    const startDate = new Date(this.currentYear, month, day.day);
    const parsedStartDate = moment(startDate).format('YYYY-MM-DD');
    this.parsedStartDate = parsedStartDate;
    this.selectedStartDate.emit(parsedStartDate);
  }

  /**
   * Select a period of time and emit it
   * @param payload
   */
  selectPeriod(payload: ISelectPeriodPayload): void {
    let startMonth = this.currentDate.getMonth();
    if (payload.startDate.disabled) {
      if (payload.startDate.day <= 7) {
        startMonth++;
      }
      if (payload.startDate.day >= 23) {
        startMonth--;
      }
    }
    let endMonth = this.currentDate.getMonth();
    if (payload.endDate.disabled) {
      if (payload.endDate.day <= 7) {
        endMonth++;
      }
      if (payload.endDate.day >= 25) {
        endMonth--;
      }
    }
    const startDate = new Date(
      this.currentYear,
      startMonth,
      payload.startDate.day
    );
    const endDate = new Date(this.currentYear, endMonth, payload.endDate.day);
    const parsedStartDate = moment(startDate).format('YYYY-MM-DD');
    const parsedEndDate = moment(endDate).format('YYYY-MM-DD');
    this.selectedPeriod.emit({
      startDate: parsedStartDate,
      endDate: parsedEndDate,
    });
  }

  /**
   * Returns an array of n elements
   * @param n
   * @returns an array of n elements
   */
  getArray(n: number): any[] {
    return Array(n).fill(0);
  }
}
