import { Component, ViewEncapsulation } from '@angular/core';
import { Photo } from '@capacitor/camera';
import { ParkingReimbursement } from '../../providers/parking-reimbursement/parking-reimbursement';
import { ReimbursementRequestModel } from '../../shared/models/parking-reimbursement.model';
import { Aws } from '../../shared/providers/aws';
import { Camera } from 'src/shared/providers/camera/camera';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Logger } from 'src/shared/providers/logger';
import { SuccessPageParamsModel } from '../success/success';
import { reimbursementOptions } from 'src/shared/constants/camera';
import { removeCurrencyMask } from 'src/shared/utils/currency-utils';

@Component({
  selector: 'parking-reimbursement',
  templateUrl: 'parking-reimbursement.html',
  encapsulation: ViewEncapsulation.None
})
export class ParkingReimbursementPage {

  errorMessage: string;
  jobId: number;
  loading = false;
  receiptPhoto: Photo;
  form: UntypedFormGroup;
  submitted = false;

  constructor(
    private aws: Aws,
    private camera: Camera,
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private parkReim: ParkingReimbursement,
    private route: ActivatedRoute,
    private logger: Logger
  ) {
    this.form = this.fb.group({
      amount: ['', Validators.required]
    });
  }

  async ionViewDidEnter() {
    this.jobId = +this.route.snapshot.paramMap.get('jobId');

    this.loading = false;
  }

  submitRequest = async () => {
    this.submitted = true;
    this.errorMessage = '';
    if (this.form.dirty && this.form.value.amount <= 0) {
      return this.errorMessage = 'Please enter an amount greater than $0.';
    }
    if (this.form.valid && this.receiptPhoto) {
      try {
        const awsResponse = await this.uploadReceiptToAws();
        const params: ReimbursementRequestModel = {
          jobId: this.jobId,
          amount: removeCurrencyMask(this.form.value.amount),
          s3ObjectKey: awsResponse.Key
        };

        const parkRequest = await this.parkReim.sendRequest(params);
        this.goToSuccessPage(parkRequest.status);
      } catch (err) {
        this.errorMessage = err?.message ? err?.message: err?.error?.message;
        this.logger.error(err, 'parking-reimbursement-request');
      }
    }
  }

  async uploadReceiptToAws() {
    const key = `parking-reimbursement/${this.jobId}`;
    const awsResponse = await this.aws.uploadImageToS3(this.receiptPhoto.base64String, key);
    return awsResponse;
  }

  takePhoto = async () => {
    try {
      this.camera.getPhoto(reimbursementOptions).then((photo: Photo) => {
        if (!photo?.base64String) {
          return;
        }
        this.receiptPhoto = photo;
      });
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  goToSuccessPage(status: string) {
    const params: SuccessPageParamsModel = this.parkReim.resquestPageContent(status);
    this.customNavCtrl.navigateForward('success', params);
  }
}
