import { Component } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

@Component({
  selector: 'advanced-settings',
  templateUrl: 'advanced-settings.html',
})

export class AdvancedSettingsPage {

  constructor(
    private customNavCtrl: CustomNavController,
    private iabUtils: InAppBrowserUtils
  ) {}

  goToTerms = () => {
    this.iabUtils.openUrl('https://www.tidy.com/terms');
  }

  goToFeesAndBooking = () => {
    this.customNavCtrl.navigateForward(`/clients-from-tidy-fees-and-booking-protection`);
  }

  goToBookingRestrictions = () => {
    this.customNavCtrl.navigateForward(`/booking-restrictions`);
  }

}
