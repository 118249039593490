import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Homekeepers } from 'src/providers/homekeepers/homekeepers';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm';
import { SuccessPageParamsModel } from 'src/pages/success/success';

import { TidyDatePipe } from 'src/shared/pipes/tidy-date';
import { TimePipe } from 'src/shared/pipes/tidy-time';

@Component({
  selector: 'client-request',
  templateUrl: './client-request.html'
})
export class ClientRequestPage {

  apiError: string;
  certificationError: boolean;
  request: any;
  errorMessage: any;

  constructor(
    private customNavCtrl: CustomNavController,
    private homekeepers: Homekeepers,
    private modalCtrl: ModalController,
    private tidyDatePipe: TidyDatePipe,
    private timePipe: TimePipe
  ) {}

  async ionViewDidEnter() {
    this.request = await this.customNavCtrl.getParams();
  }

  goToProposeAlternate = () => {
    this.customNavCtrl.navigateForward('client-request-counter', this.request);
  }

  acceptRequest = async (time) => {
    const bodyText = this.getAcceptBodyText(time);
    const params: ConfirmPageParamsModel = {
      title: 'Accept Job?',
      body: bodyText,
      backText: 'Go Back',
      confirmText: 'Accept Job',
      confirmAction: this.confirmAcceptRequest.bind(this),
      confirmActionParams: time,
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });
    confirmationModal.present();
  }

  getAcceptBodyText(time) {
    const end = this.getJobEndTime(time);
    const endTime = this.timePipe.transform(end);
    const date = this.tidyDatePipe.transform(time.date, 'M/DD/YY');
    const startTime = this.timePipe.transform(time.time);
    if (this.request.frequency !== 'once') {
      return `Your schedule will be marked available from ${startTime} to ${endTime} every week starting ${date} and you will automatically be booked with them.`;
    } else {
      return `Your schedule will be marked available from ${startTime} to ${endTime} on ${date} and you will automatically be booked with them.`;
    }
  }

  async confirmAcceptRequest(time) {
    const data = {
      date: time.date,
      time: time.time,
    };
    try{
      await this.homekeepers.acceptClientRequest(this.request.id, data);
      const successParams: SuccessPageParamsModel = {
        header: 'Job Accepted',
        body: 'We have sent a notification to this Client.',
        buttonText: 'Ok',
        buttonRoute: `add`
      };
      this.modalCtrl.dismiss();
      this.customNavCtrl.navigateForward('success', successParams);
    } catch (err){
      this.modalCtrl.dismiss();
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      if (this.errorMessage.includes('Unfortunately there are no longer')) {
        this.errorMessage = 'Finish the steps to add new Clients to access this page, it\'s quick and easy.';
        this.certificationError = true;
      }
    }
  }

  async declineRequest() {
    const params: ConfirmPageParamsModel = {
      title: 'Decline Request?',
      body: `Notify ${this.request.address.customer.first_name} that you cannot help them at those times, but they are free to book you on your current bookable schedule.`,
      backText: 'Go Back',
      confirmText: 'Decline Request',
      confirmAction: this.confirmDeclineRequest.bind(this),
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });
    confirmationModal.present();
  }

  async confirmDeclineRequest() {
    await this.homekeepers.declineClientRequest(this.request.id);
    const successParams: SuccessPageParamsModel = {
      header: 'Job Request',
      body: 'We have sent a notification to this Client.',
      buttonText: 'Ok',
      buttonRoute: `add`
    };
    this.modalCtrl.dismiss();
    this.customNavCtrl.navigateForward('success', successParams);
  }

  getJobEndTime(time) {
    const lengths = {
      tidy: 60,
      plus: 150,
      tidy_xl: 240,
      mighty: 240,
    };
    const jobLength = lengths[this.request.service_type];
    const dateTime = `${time.date} ${time.time}`;
    const startTime = moment(dateTime);
    const endTime = moment(startTime).add(jobLength, 'm');
    return endTime.format('H:mm');
  }

  goToFinishCertification() {
    this.customNavCtrl.navigateForward('new-clients');
  }
}
