import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Team } from 'src/providers/team/team';

import validationUtils from 'src/shared/utils/validation-utils';
import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

import { ConfirmPageParamsModel, ConfirmPage } from 'src/pages/confirm/confirm';
import { SuccessPageParamsModel } from 'src/pages/success/success';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: 'team-member-invite.html'
})

export class TeamMemberInvitePage {

  errorMessage: string;
  invitedEmail: string;
  form: UntypedFormGroup;
  submitted: boolean;

  constructor(
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private iabUtils: InAppBrowserUtils,
    private modalCtrl: ModalController,
    private team: Team,
  ) {
    this.form = this.fb.group({
      email: ['', Validators.compose([Validators.required, validationUtils.validateEmail])],
      firstName: ['', Validators.required],
      understand: [false, Validators.requiredTrue],
      terms: [false, Validators.requiredTrue],
      limitedPermissions: [false],
    });
  }

  async invite() {
    this.invitedEmail = this.form.value.email.toLowerCase();
    this.errorMessage = '';
    if (this.form.value.understand === false || this.form.value.terms === false) {
      this.errorMessage = 'You must confirm both of the above to invite a team member.';
    }
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    const confirmDelete: ConfirmPageParamsModel = {
      title: 'Invite Team Member?',
      body: `${new TranslationPipe().transform('Send an invite to')} ${this.invitedEmail}?`,
      backText: 'Go Back',
      confirmText: 'Send Invite',
      confirmAction: this.confirmInvite.bind(this)
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: confirmDelete,
      animated: false
    });
    confirmationModal.present();
  }

  async confirmInvite() {
    const payload = {
      email: this.invitedEmail,
      name:  this.form.value.firstName,
      limit_invited_homekeeper: this.form.value.limitedPermissions
    }
    await this.team.createInvite(payload);
    const onSuccess: SuccessPageParamsModel = {
      header: 'Team Member Invited',
      body: `${new TranslationPipe().transform('We sent an email to')} ${this.invitedEmail}.`,
      buttonText: 'Ok',
      buttonRoute: 'team'
    };
    this.modalCtrl.dismiss();
    this.customNavCtrl.navigateForward('success', onSuccess);
    this.form.controls.email.setValue('');
    this.submitted = false;
  }

  goToTerms() {
    this.iabUtils.openUrl('https://www.tidy.com/legal/service-provider');
  }

}
