<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [customBack]="'clients-from-tidy-settings'"
    [title]="'Notification Settings'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper *ngIf="loaded">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'Set when you want to be notified for job opportunities.'">
      </tidy-text>
    </tidy-row>

    <tidy-error-message
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-row>
      <tidy-text
        [title]="'In App Notifications'">
      </tidy-text>
    </tidy-row>

    <tidy-card *ngFor="let day of daysApp">
      <tidy-card-button (action)="editDay(day, 'app')">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/' + (day.checked ? 'notifications' : 'notifications-off') + '.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="day.label">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="day.checked">
          <ng-container *ngFor="let time of day.times; let i = index">
            <tidy-text
              [body]="time | ttime">
            </tidy-text>
            <tidy-text
              *ngIf="notFirstTime(i, day)"
              [body]="', '">
            </tidy-text>
          </ng-container>
        </tidy-row>
        <tidy-row *ngIf="!day.checked">
          <tidy-text
            [helper]="'<i>Do Not Notify</i>'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

    <tidy-row>
      <tidy-text
        [title]="'SMS Notifications'">
      </tidy-text>
    </tidy-row>

    <tidy-card *ngFor="let day of daysSms">
      <tidy-card-button (action)="editDay(day, 'sms')">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/' + (day.checked ? 'notifications' : 'notifications-off') + '.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="day.label">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="day.checked">
          <ng-container *ngFor="let time of day.times; let i = index">
            <tidy-text
              [body]="time | ttime">
            </tidy-text>
            <tidy-text
              *ngIf="notFirstTime(i, day)"
              [body]="', '">
            </tidy-text>
          </ng-container>
        </tidy-row>
        <tidy-row *ngIf="!day.checked">
          <tidy-text
            [helper]="'<i>Do Not Notify</i>'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
