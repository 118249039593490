import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, FormControl, ValidationErrors, Validators } from '@angular/forms';

import { Certification } from 'src/providers/certification/certification';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Logger } from 'src/shared/providers/logger';

import { SuccessPageParamsModel } from 'src/pages/success/success';


@Component({
  selector: 'equipment',
  templateUrl: 'instant-certification.html',
})
export class OnboardingInstantCertification {

  form: UntypedFormGroup;
  errorMessage: string;
  submitted: boolean;

  constructor(
    private certification: Certification,
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private logger: Logger,
  ) {
    this.form = this.fb.group({
      url: ['', [Validators.required]]
    });
  }

  async ionViewWillEnter() {
    this.submitted = false;
  }

  submit = async () => {
    this.submitted = true;
    this.errorMessage = '';

    const submittedUrl = this.form.value.url;
    const checkUrls = (submittedUrl.includes('yelp.com/') || submittedUrl.includes('google.com/'))

    if (!this.form.valid || !checkUrls) {
      this.errorMessage = 'Please enter a valid Yelp/Google URL';
      return;
    }
    try {
      const data = {
        url: this.form.value.url
      };
      await this.certification.finishStep('equipment_verification', data);
      const params = this.mountSuccessPageParams();
      this.customNavCtrl.navigateForward('success', params);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.logger.error(err, 'web-certification');
    }
  }

  mountSuccessPageParams(): SuccessPageParamsModel {
    return {
      header: 'Link Submitted',
      body: 'Your equipment has been certified.',
      buttonText: 'Ok',
      buttonRoute: 'new-clients'
    };
  }
}
