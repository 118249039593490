import { Component } from '@angular/core';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

@Component({
  selector: 'clients-from-tidy-fees',
  templateUrl: './clients-from-tidy-fees.html'
})

export class ClientsFromTidyFeesPage {

  certifiedForPage: boolean;

  constructor(
    private iabUtils: InAppBrowserUtils
  ) {}

  learnMoreBooking() {
    this.iabUtils.openUrl('https://help.tidy.com/pros/payments#when-are-payouts-sent');
  }

  learnMoreFees() {
    this.iabUtils.openUrl('https://help.tidy.com/pros/fees-for-clients-from-tidy');
  }
}
