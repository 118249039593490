import { Component, OnInit } from '@angular/core';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { ActivatedRoute } from '@angular/router';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  selector: 'incomplete-register-page',
  templateUrl: 'incomplete-register.html'
})
export class IncompleteRegisterPage implements OnInit {
  title: string;
  body: string;
  bodyMessages = {
    requirements: {
      existing_clients: 'Finish the steps to add existing Clients to access this page, it\'s quick and easy. ',
      bookable_times: 'Make yourself bookable to allow Clients to book on your schedule. ',
      go_live: 'Complete the steps to get new Clients from TIDY to access this page. It\'s quick, easy, and free. ',
    }
  }

  constructor(
    private customNavController: CustomNavController,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.title = new TranslationPipe().transform(this.formatTitle(this.route.snapshot.paramMap.get('pageTitle')));
    const requirement = this.route.snapshot.paramMap.get('requirement');
    this.body = new TranslationPipe().transform(this.bodyMessages.requirements[requirement]);
  }

  formatTitle(title) {
    return title.replace(/-/gu, ' ');
  }

  linktoRegister() {
    this.customNavController.navigateRoot('business-manager/dashboard');
  }

}
