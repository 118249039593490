import { Injectable } from '@angular/core';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { Platform } from '@ionic/angular';
import { Location } from 'src/shared/providers/location';

@Injectable()
export class AddressService {

  constructor(
    private launchNavigator: LaunchNavigator,
    private platform: Platform,
    private location: Location,
  ) { }

  async directions(clientAddress: string) {
    if (this.platform.is('mobileweb') || this.platform.is('desktop')) {
      const address = clientAddress.replace(/ /g, '+');
      const { latitude, longitude } = await this.location.get();
      const url = `https://maps.google.com/maps?saddr=${latitude},${longitude}&daddr=${address}`;

      window.open(url, '_system');
    } else {
      this.launchNavigator.navigate(clientAddress);
    }
  }
}
