import { Component } from '@angular/core';

import { BankInfo } from 'src/providers/bank-info/bank-info';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Dashboard } from 'src/providers/dashboard/dashboard';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { Loading } from 'src/shared/components/loading/loading';

import { TidyCurrencyPipe } from 'src/shared/pipes/tidy-currency';

import { EstimatedNextPaymentModel, PendingBalanceModel } from 'src/shared/models/payment.model';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

@Component({
  selector: 'payouts',
  templateUrl: 'payouts.html'
})
export class PayoutsPage extends Timeout {

  backButtonRoute: string;
  estimatedNextPayment: EstimatedNextPaymentModel;
  paymentBalance: PendingBalanceModel;
  paymentHistory: any;
  nextPayDate: any;

  constructor(
    private bankInfo: BankInfo,
    private customNavCtrl: CustomNavController,
    private dashboard: Dashboard,
    private storage: TidyStorage,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
  }

  async ionViewDidEnter(): Promise<any> {
    this.loaded = false;
    this.backButtonRoute = await this.storage.retrieve('payoutsBackRoute') || 'more';
    if (!this.estimatedNextPayment) {
      try {
        this.estimatedNextPayment = await this.dashboard.getEstimatedNextPayment();
        this.paymentBalance = await this.dashboard.getPaymentBalance();
        this.paymentHistory = await this.bankInfo.paymentHistory(true);
        this.nextPayDate = this.estimatedNextPayment.estimated_arrival;
      } catch (err) {
        this.timeoutHandler(err);
      }
    }
    this.loaded = true;
  }

  goToWaiveFees() {
    this.customNavCtrl.navigateRoot('waive-fee');
  }

  goToTotalBalance() {
    if (this.paymentBalance.total_amount == 0) {
      return;
    }
    this.customNavCtrl.navigateForward('current-balance', {paymentBalance: this.paymentBalance});
  }

  paymentDetails = (paymentId) => {
    this.customNavCtrl.navigateForward(`past-payout/${paymentId}`);
  }
}
