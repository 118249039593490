export interface TeamModel {
  accepted_payment_methods: string;
  background_photo_url: string;
  description: string;
  id: number;
  name: string;
  phone_visible_on_web: boolean;
  profile_photo_url: string;
  owner: {
    email: string;
    id: number;
    name: string;
  }
  service_types: TypeModel[];
  members: MemberModel[];
  url_suffix: string;
}

export interface MemberModel {
  homekeeper: {
    id: number;
    name: string;
    email: string;
  }
  id: number;
  state: string;
  team_id: number;
}

interface TypeModel {
  min_length: number;
  max_length: number;
  min_cost: number;
  hourly_rate: number;
  id: number;
  name: string;
  state: string;
}

export interface InviteModel {
  email: string;
  homekeeper: {
    id: number;
    name: string;
    email: string;
    phone: string;
  };
  team: {
    id: number;
    name: string;
    owner : {
      emai: string;
      id: number;
      name: string;
      phone: string;
    }
  }
  id: number;
  invitation_status: string;
  team_id: number;
  uuid: string;
}

export interface EligibilityModel {
  id: number;
  name: string;
  status: string;
  team_member_id: number;
  unavailability_message: string;
}

export interface DelegationModel {
  id: number;
  delegated_by_team_member_id: number;
  delegated_to_team_member_id: number;
  new_homekeeper_job_id: number;
  original_homekeeper_job_id: number;
  status: string;
  cancellation_trigger: string;
  answered_by_user_at: string;
  cancelled_at: string;
  created_at: string;
  updated_at: string;
}

export interface TeamServiceTypeModel {
  hourly_rate: number;
  id: number;
  max_length: number;
  min_cost: number;
  min_length: number;
  name: string;
  state: string;
  team_services: TeamServiceModel[];
}

export interface TeamServiceModel {
  agreement_url: string;
  billing_type: string;
  bookable_online: boolean;
  deposit_amount_required: number;
  description: string;
  duration_to_block_off: number;
  frequency_options: string;
  from_tidy: boolean;
  id: number;
  key: string;
  max_duration: number;
  min_duration: number;
  minimum_assessment_fee_amount: number;
  name: string;
  required_team_service_id: number;
  service_type: {
    id: number;
    key: string;
    name: string;
  };
  state: string;
  team: TeamModel[];
  user_new_rate: number;
  user_repeat_rate: number;
}

export interface ServiceTypeModel {
  id: number;
  key: string;
  name: string;
}
