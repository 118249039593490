<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Who is Rescheduling?'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'Who is rescheduling this '">
      </tidy-text>
      <tidy-text
        [body]="rescheduleType">
      </tidy-text>
      <tidy-text
        [body]="' on '">
      </tidy-text>
      <tidy-text
        [body]="jobDateAndHour +
        '?'">
      </tidy-text>
    </tidy-row>

    <tidy-error-message
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-button
      [text]="('Client ' | translate) + clientName"
      [action]="clientConfirm.bind(this)"
      class="secondary">
    </tidy-button>

    <tidy-button
      [text]="'Me'"
      [action]="meConfirm.bind(this)"
      class="secondary">
    </tidy-button>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
