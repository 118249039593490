import { Injectable } from '@angular/core';
import { HttpClient } from 'src/shared/providers/http/http-client';

@Injectable()
export class UpcomingFeature {

  constructor(
    private httpClient: HttpClient,
  ) {}

  requestUpcomingFeature(key, note) {
    const data = {
      key: key,
      note: note
    }
    const url = `api/v1/homekeeper/feature-requests`;
    return this.httpClient.post(url, data);
  }
}
