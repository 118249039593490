import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Loading } from 'src/shared/components/loading/loading';
import { Team } from 'src/providers/team/team';

import { EligibilityModel } from 'src/shared/models/team.model';
import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';

import { SuccessPageParamsModel } from 'src/pages/success/success';

import validationUtils from 'src/shared/utils/validation-utils';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

@Component({
  templateUrl: 'delegate-job.html'
})

export class DelegateJobPage extends Timeout {

  addingNewPro: boolean;
  eligiblePros: TidySelectNumberValueModel[];
  errorMessage: string;
  form: UntypedFormGroup;
  hkJobId: number;
  ineligiblePros: EligibilityModel[];
  submitted: boolean;

  constructor(
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private team: Team,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.fb.group({
      invite: ['', Validators.required],
      email: [''],
      firstName: [''],
      understand: [false],
    });
  }

  @Loading()
  async ionViewDidEnter() {
    try {
      this.hkJobId = await this.customNavCtrl.getParam('hkJobId', true);
      const pros: EligibilityModel[] = await this.team.getEligiblePros(this.hkJobId);
      this.eligiblePros = await this.parseEligiblePros(pros);
      this.ineligiblePros = await this.parseIneligiblePros(pros);
      this.addingNewPro = this.ineligiblePros.length === 0 && this.eligiblePros.length === 1;
      if (this.addingNewPro) {
        this.form.patchValue({invite: 0});
        this.setRequiredAddProFields();
      }
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  parseEligiblePros(pros): TidySelectNumberValueModel[] {
    let parsedPros = [];
    pros.map((pro) => {
      if (pro.status === 'available') {
        const parsedPro = {
          viewValue: pro.name,
          value: pro.team_member_id
        };
        parsedPros.push(parsedPro);
      }
    });
    parsedPros.push({
      viewValue: 'Add new team member',
      value: 0
    });
    return parsedPros;
  }

  parseIneligiblePros(pros): EligibilityModel[] {
    let parsedPros = [];
    pros.map((pro) => {
      if (pro.status === 'unavailable') {
        parsedPros.push(pro);
      }
    });
    return parsedPros;
  }

  async delegateJob() {
    this.errorMessage = '';
    this.submitted = true;
    const isAddingNewTeamMember = this.form.value.invite === 0;
    if (!this.form.valid) {
      return this.errorMessage = (isAddingNewTeamMember && this.form.value.understand === false) ? 'You must confirm above to invite a team member.' : '';
    }
    try {
      if (isAddingNewTeamMember) {
        const payload = {
          email: this.form.value.email,
          name:  this.form.value.firstName
        }
        const response = await this.team.createInvite(payload);
        await this.team.delegateJob(response.team_member.id, this.hkJobId);
      } else {
        await this.team.delegateJob(this.form.value.invite, this.hkJobId);
      }
      const onSuccess: SuccessPageParamsModel = {
        header: 'Request Sent',
        body: `We have sent an SMS / email to your team member asking if they will accept.`,
        buttonText: 'Ok',
        buttonRoute: 'jobs'
      };
      this.customNavCtrl.navigateForward('success', onSuccess);
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  changeProOption(selection) {
    this.addingNewPro = selection.value === 0;
    if (this.addingNewPro) {
      this.setRequiredAddProFields();
    } else {
      this.setNotRequiredAddProFields();
    }
  }

  setRequiredAddProFields() {
    this.form.get('email').setValidators([Validators.required, validationUtils.validateEmail]);
    this.form.get('email').updateValueAndValidity();
    this.form.get('firstName').setValidators([Validators.required]);
    this.form.get('firstName').updateValueAndValidity();
    this.form.get('understand').setValidators(Validators.requiredTrue);
    this.form.get('understand').updateValueAndValidity();
  }

  setNotRequiredAddProFields() {
    this.form.controls['email'].clearValidators();
    this.form.controls['email'].updateValueAndValidity();
    this.form.controls['firstName'].clearValidators();
    this.form.controls['firstName'].updateValueAndValidity();
    this.form.controls['understand'].clearValidators();
    this.form.controls['understand'].updateValueAndValidity();
  }
}
