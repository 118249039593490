import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ToastController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RoutingState } from 'src/app/providers/routing-state';

import * as moment from 'moment';

@Component({
  selector: 'tidy-top-nav',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./top-nav.component.scss'],
  template: `
    <tidy-loading-bar class="js-tidy-loading desktop"></tidy-loading-bar>

    <div class="container-top-nav">
      <div class="first-item">
        <img
          decoding="async"
          class="bowtie"
          *ngIf="!canGoBack && !showCloseItem() && !noBow"
          [src]="'/assets/style-guide-images/bowtie-white.svg'"
        />
        <tidy-image *ngIf="canGoBack" class="back-button desktop navbar back menu-icon" (click)="goBack()" src="assets/svg/arrow-back-black.svg"></tidy-image>
        <tidy-image *ngIf="canGoBack" class="back-button mobile navbar back menu-icon" (click)="goBack()" src="assets/svg/arrow-back-white.svg"></tidy-image>

        <tidy-image class="close-icon desktop navbar link menu-icon" *ngIf="showCloseItem() && !canGoBack" (click)="executeAction(closeItemAction)" src="assets/svg/close-circle.svg"></tidy-image>
        <tidy-image class="close-icon mobile navbar link menu-icon" *ngIf="showCloseItem() && !canGoBack" (click)="executeAction(closeItemAction)" src="assets/svg/close-circle-white.svg"></tidy-image>
      </div>

      <tidy-text
        *ngIf="title"
        class="title-text"
        [title]="title"
      ></tidy-text>

      <ng-content class="title-text capitalize"></ng-content>

      <ng-container *ngIf="!title && countdownTarget">
        <div>
          <tidy-text [title]="'Time Left: '"></tidy-text>
          <countdown [isTopNav]="true" [target]="countdownTarget"></countdown>
        </div>
      </ng-container>

      <div class="last-item">
        <span *ngIf="linkIcon && showLinkIcon" class="link-icon">
          <tidy-image style="width: 25px" class="link-icon-element mobile navbar link" (click)="executeAction(linkAction)" [src]="'assets/svg/' + linkIcon + '-white' + '.svg'"></tidy-image>
          <tidy-image style="width: 25px" class="link-icon-element desktop navbar link" (click)="executeAction(linkAction)" [src]="'assets/svg/' + linkIcon + '-black' + '.svg'"></tidy-image>

          <span class="bubble" *ngIf="linkIcon === 'star'">
            5
          </span>
        </span>
      </div>
    </div>

    <tidy-loading-bar class="js-tidy-loading mobile"></tidy-loading-bar>
  `
})

export class TopNavComponent {

  @Input() backButton: boolean;
  @Input() canGoBack: boolean;
  @Input() countdownTarget: moment.Moment;
  @Input() customBack: string;
  @Input() closeButton: boolean;
  @Input() linkIcon: string;
  @Input() noBow = false;
  @Input() navigationExtras = {};
  @Input() title: string;
  @Input() showLinkIcon = false;

  @Output() closeItemAction: EventEmitter<any> = new EventEmitter();
  @Output() linkAction: EventEmitter<any> = new EventEmitter();

  loading = false;
  checkGoBack;
  current: string;

  constructor(
    private customNavController: CustomNavController,
    private router: Router,
    private routingState: RoutingState,
    private toastCtrl: ToastController
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkGoBack = this.routingState.canGoBack();
        this.current = event.url;
      }
    });
  }

  showCloseItem() {
    return (this.closeItemAction.observers.length > 0);
  }

  async executeAction(emitter: EventEmitter<any>) {
    this.loading = true;
    try {
      await emitter.emit();
    } catch (error) {
      console.log(error);
      this.showError(error);
    }
    this.loading = false;
  }

  goBack() {
    if (this.customBack) {
      this.customNavController.navigateForward(this.customBack, this.navigationExtras);
    } else {
      this.customNavController.back();
    }
  }

  async showError(message) {
    const toast = await this.toastCtrl.create({ message, duration: 3000 });
    toast.present();
  }

}
