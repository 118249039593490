import { Component, Input, ViewEncapsulation } from "@angular/core";
import { DateTime as LuxonDateTime } from 'luxon';

import { Communication } from 'src/providers/communication/communication';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Component({
  selector: 'message',
  templateUrl: 'message.component.html',
  encapsulation: ViewEncapsulation.None
})

export class MessageComponent {

  @Input() addExtraPadding: boolean = true;
  @Input() last: boolean = false;
  @Input() message: any;
  @Input() isInvoiceNote: boolean;

  constructor(
    public communication: Communication,
    private navCtrl: CustomNavController
  ) {}

  getDateTime(message) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const relativeDate = LuxonDateTime.fromISO(message.sent_at || message.data.created_at).setZone(timezone).toRelative();
    if (relativeDate.includes('day') || relativeDate.includes('month') || relativeDate.includes('year')) {
      return LuxonDateTime.fromISO(message.sent_at).setZone(timezone).toFormat('EEE LLL d h:mm a');
    } else {
      return relativeDate;
    }
  }

  internalNoteStyle(isInternalNote) {
    if (isInternalNote) {
      return {
        'background-color': '#F0F0F0',
        'padding-top' : '5px',
        'padding-bottom' : '5px'
      };
    };
  }

}
