import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundWholeNumber'
})
export class RoundWholeNumberPipe implements PipeTransform {

  transform(value: number): number {
    return Math.round(value);
  }
}
