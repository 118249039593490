<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Invite Team Member'"
    [customBack]="'team'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'This asks someone to join your team, so you can send them to your jobs.'">
      </tidy-text>
    </tidy-row>

    <tidy-card>
      <form [formGroup]="form" novalidate action="#">
        <tidy-row class="field-top-padding field-bottom-padding">
          <tidy-input
            [label]="'Email'"
            [icon]="'assets/svg/mail-outline.svg'"
            formControlName="email"
            [form]="form.controls.email"
            [submitted]="submitted"
            [errorMessage]="'Please enter a valid email.'">
          </tidy-input>
        </tidy-row>
        <tidy-row>
          <tidy-input
            [label]="'First Name'"
            [icon]="'assets/svg/person-circle-outline.svg'"
            formControlName="firstName"
            [form]="form.controls.firstName"
            [submitted]="submitted"
            [errorMessage]="'Please enter a name.'">
          </tidy-input>
        </tidy-row>
      </form>
    </tidy-card>

    <tidy-card>
      <form [formGroup]="form" novalidate action="#">
        <tidy-row>
          <tidy-checkbox
            formControlName="limitedPermissions"
            [items]="[{viewValue: 'Limit their permissions (optional)', value: false}]">
          </tidy-checkbox>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'If you want to prevent this user from adding or managing clients, instead just let them only accept and view jobs you assign, tap here. This is good if they are your employees. Note: if they already have a TIDY account this will not limit them.'">
          </tidy-text>
        </tidy-row>
      </form>
    </tidy-card>

    <tidy-card>
      <form [formGroup]="form" novalidate action="#">
        <tidy-row>
          <tidy-grid>
            <ion-row>
              <ion-col size="1">
                <tidy-checkbox
                  style="margin-right: 5px"
                  formControlName="understand"
                  [form]="form.controls.understand"
                  [items]="[{viewValue: '', value: false}]">
                </tidy-checkbox>
              </ion-col>
              <ion-col>
                <tidy-text
                  [body]="'I understand I\'m responsible for jobs I delegate to team members, including insurance, safety, compliance, legal, and employment obligations.'">
                </tidy-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
        <tidy-row>
          <tidy-grid>
            <ion-row>
              <ion-col size="1">
                <tidy-checkbox
                  style="margin-right: 5px"
                  formControlName="terms"
                  [form]="form.controls.terms"
                  [items]="[{viewValue: '', value: false}]">
                </tidy-checkbox>
              </ion-col>
              <ion-col>
                <tidy-text
                  [body]="'I still agree to the '">
                </tidy-text>
                <tidy-text
                  (action)="goToTerms()"
                  [body]="'Terms of Use'"
                  class="link">
                </tidy-text>
                <tidy-text
                  [body]="', and that my team members are employees/subcontractors and subject to these terms.'">
                </tidy-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
        <tidy-error-message
          *ngIf="errorMessage"
          [text]="errorMessage">
        </tidy-error-message>
      </form>
    </tidy-card>

    <tidy-button
      [action]="invite.bind(this)"
      [text]="'Invite Team Member'"
      class="primary">
    </tidy-button>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
