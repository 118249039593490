import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Concierge } from 'src/providers/concierge/concierge';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { ConciergeItemModel } from 'src/models/concierge.model';
import { Logger } from 'src/shared/providers/logger';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { Timeout } from 'src/shared/components/timeout/timeout';


@Component({
  templateUrl: 'dispute-resolution.html',
  encapsulation: ViewEncapsulation.None
})

export class DisputeResolutionPage extends Timeout {

  errorMessage: string;
  dispute: ConciergeItemModel;

  constructor(
    private concierge: Concierge,
    private logger: Logger,
    private navCtrl: CustomNavController,
    public route: ActivatedRoute,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router
  ) {
    super(timeoutErrorHandler, router, route);
  }

  async ionViewDidEnter() {
    this.loaded = false;
    this.dispute = this.navCtrl.getParam('item');
    if (!this.dispute) {
      const disputeItemId = this.route.snapshot.paramMap.get('disputeItemId');
      try {
        this.dispute = await this.concierge.getConciergeItemById(disputeItemId);
      } catch (err) {
        this.timeoutHandler(err);
        this.logger.error(err);
        this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      }
    }
    this.loaded = true;
  }

  async acceptDisputeProposal(){
    const data = {
      concierge_item_id: this.dispute.id,
	    action_name: 'accept_dispute_proposal',
	    params: {
		    concierge_item_id: this.dispute.id,
		    concierge_item_type_component_id: this.dispute.components[0].id,
        customer_cleaning_dispute_id: this.dispute.metadata?.customer_cleaning_dispute_id
	    }
    }
    try {
      await this.concierge.replyDisputeCounter(this.dispute.id ,data);
      this.goToSuccessPage();
    } catch (err) {
      this.logger.error(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  goToCounterDispute(){
    try {
      const params = {
        item: this.dispute
      }
      this.navCtrl.navigateForward(`counter-dispute/${this.dispute.id}`, params);
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  goToSuccessPage() {
    const params = {
      header: 'Dispute Proposal Accepted',
      body: ``,
      buttonText: 'Ok',
      buttonRoute: 'concierge'
    };
    this.navCtrl.navigateForward('success', params);
  }
}
