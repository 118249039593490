<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Invoices'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <ng-container *ngIf="unpaidJobs?.length > 0">
        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'The following jobs are not fully paid. Send an invoice to collect payment:'">
          </tidy-text>
        </tidy-row>

        <tidy-card *ngFor="let job of unpaidJobs">
            <tidy-card-button (action)="goToPrivateJobPage(job)">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="!job?.invoice ? 'assets/svg/alert-circle-outline.svg' : 'assets/svg/send-outline.svg'"
                  class="body-size">
                </tidy-image>
                <tidy-text
                  *ngIf="job?.invoice"
                  [body]="(job?.invoice?.amount_due | tcurrency) + (' owed for ' | translate) + (job?.date | tdate: 'M/D') + (' job' | translate)">
                </tidy-text>
                <tidy-text
                  *ngIf="!job?.invoice"
                  [body]="(job?.billing?.amount_due | tcurrency) + (' owed for ' | translate) + (job?.date | tdate: 'M/D') + (' job' | translate)">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  *ngIf="job?.invoice !== null && job?.invoice !== undefined"
                  [body]="'Invoice Sent - Waiting on Client'">
                </tidy-text>
                <tidy-text
                  *ngIf="!job?.invoice && job?.private_status !== 'scheduled'"
                  [actionBody]="'No Invoice - Waiting on You to Send'"
                  class="green">
                </tidy-text>
                <tidy-text
                  *ngIf="job?.private_status == 'scheduled'"
                  [actionBody]="'Not Complete  - Mark Complete to Get Paid'"
                  class="green">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
        </tidy-card>
      </ng-container>

      <ng-container *ngIf="unpaidJobs?.length == 0">
        <tidy-card style="padding: 15px 15px 15px 10px">
          <tidy-row [align]="'center'">
            <tidy-text
              [title]="'Get Paid Faster'">
            </tidy-text>
          </tidy-row>
          <tidy-row [align]="'center'" class="extra-bottom-padding">
            <tidy-text
              [body]="'Use TIDY Invoices to supercharge your business today. '">
            </tidy-text>
            <tidy-text
              (action)="learnMore()"
              [body]="'Learn More'"
              class="link">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <video id="introVideo" preload="true" playsinline="" autoplay="" loop="" muted="" src="https://d30thx9uw6scot.cloudfront.net/web-images/invoiceoverview.mp4" poster="" style="max-width: 100%;" async></video>
          </tidy-row>
          <ng-container *ngFor="let card of noUnpaidJobCards; let first = first; let last = last">
            <tidy-grid>
              <tidy-row [ngClass]="first ? 'extra-top-padding' : ''">
                <ion-row>
                  <ion-col size="2" class="vertical-align-center">
                    <div style="margin: auto">
                      <tidy-image
                        [src]="card?.image"
                        style="width:30px">
                      </tidy-image>
                    </div>
                  </ion-col>
                  <ion-col>
                    <tidy-row>
                      <tidy-text
                        [body]="card?.text">
                      </tidy-text>
                    </tidy-row>
                  </ion-col>
                </ion-row>
              </tidy-row>
            </tidy-grid>
            <tidy-row class="extra-top-padding extra-bottom-padding" *ngIf="!last">
              <tidy-divider></tidy-divider>
            </tidy-row>
          </ng-container>
        </tidy-card>

        <tidy-alert>
          <tidy-row>
            <tidy-text
              [header]="'Add Jobs to Request Payment'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'You have no unpaid jobs.  Add a job then complete it to request payment.'">
            </tidy-text>
          </tidy-row>
        </tidy-alert>
        <tidy-button
          [text]="'Add a Job'"
          [action]="goToAddJobPage.bind(this)"
          class="primary">
        </tidy-button>

      </ng-container>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
