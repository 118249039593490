import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Injectable()
export class InAppBrowserUtils {

  constructor(
    private platform: Platform,
    private inAppBrowser: InAppBrowser,
  ) {
  }
  openUrl(url): void {
    if (this.platform.is('cordova')) {
      this.platform.ready().then(() => this.inAppBrowser.create(url, '_system', 'location=yes'));
    } else {
      window.open(url, '_blank');
    }
  }
}
