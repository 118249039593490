<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Record Payment'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-card>
        <tidy-row class="vertical-align-center">
         <tidy-image
            src="assets/style-guide-images/bowtie-black.svg"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="date | tdate:'dddd M/D/YY'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-grid>
            <ion-row>
              <ion-col size="5">
                <tidy-text
                  [body]="startTime | ttime">
                </tidy-text><br>
                <tidy-text
                  [body]="length | duration">
                </tidy-text>
              </ion-col>
              <ion-col size="7">
                <tidy-text
                  (action)="goToClientDetails()"
                  [body]="clientName"
                  class="link">
                </tidy-text><br>
                <tidy-text
                  [body]="amount | tcurrency">
                </tidy-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-divider>
          </tidy-divider>
        </tidy-row>
        <form [formGroup]="form">
          <tidy-row class="field-top-padding field-bottom-padding">
            <tidy-input
              formControlName="amount"
              inputMode="numeric"
              [label]="'Payment Amount'"
              [form]="form.controls.amount"
              [icon]="'assets/svg/cash.svg'"
              [activeIcon]="'assets/svg/cash-tidy-green.svg'"
              [mask]="'currencyMask'"
              [type]="'text'"
              [submitted]="submitted"
              [errorMessage]="'Please enter the payment amount.'">
            </tidy-input>
          </tidy-row>
        </form>
      </tidy-card>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-button
        [text]="'Record Payment'"
        [action]="recordPayment.bind(this)"
        class="primary">
      </tidy-button>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
