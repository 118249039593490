import { Component, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AddressForm } from 'src/providers/address-form/address-form';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PrivateClient } from 'src/providers/private-client/private-client';

import { SuccessPageParamsModel } from 'src/pages/success/success';

import { AutoCompleteAddressModel } from 'src/shared/models/autocomplete-address.model';

import { MultipleCountryService } from 'src/providers/addresses/multiple-country.service';
import { RadioButtonModel } from '@tidyapp/tidy-ui-components';
import { keepOnlyText } from 'src/shared/utils/text-utils';
import { Me } from 'src/shared/providers/me';
import { AddressMetadata } from 'src/shared/components/autocomplete-address/autocomplete-address';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { Timeout } from 'src/shared/components/timeout/timeout';

@Component({
  templateUrl: 'add-private-address.html'
})

export class AddPrivateAddressPage extends Timeout {
  autocompleteAddress: AutoCompleteAddressModel[];
  clientId: number;
  errorMessage: string;
  form: UntypedFormGroup;
  submitted: boolean;
  countryList: RadioButtonModel[];
  zipCodeMask = {
    mask: '00000'
  }
  countryCode: string;
  noAddressPickedTemplate: ElementRef;
  invalidAddressError: string;

  constructor(
    private addressForm: AddressForm,
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private privateClient: PrivateClient,
    private multipleCountryService: MultipleCountryService,
    private me: Me,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
    this.form = this.fb.group({
      address: ['', Validators.required],
      unit: [''],
      zipcode: ['', Validators.required],
      home_access: '',
      home_close: '',
      parking: this.fb.group({
        paid_parking: null,
        parking_spot: null,
        parking_pay_with: null,
        max_parking_cost: null,
        parking_notes: null
      }),
      latitude: '',
      longitude: '',
      city: ['', Validators.required],
      state: ['', Validators.required]
    });

    this.countryList = this.multipleCountryService.countryRadioButtonList;
  }

  async ionViewDidEnter() {
    try {
      const client = await this.customNavCtrl.getParams();
      this.clientId = client.id;
      await this.populateForm();
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  async populateForm() {
    const me = await this.me.fetchWithoutCache();
    const countryCode = me.profile.address.country_code;
    this.setValidators(countryCode);
    this.countryCode = countryCode;
  }

  setValidators(proCountryCode: string) {
    const validatorObject = this.multipleCountryService.getCountrySettings(proCountryCode);
    this.form.get('zipcode').setValidators(validatorObject.validator);
    this.form.get('zipcode').updateValueAndValidity();
    this.zipCodeMask = validatorObject.mask;
  }

  updateSearch = async () => {
    this.autocompleteAddress = await this.addressForm.updateSearch(this.form.value.address, this.countryCode);
  }

  choseItem(addressMetadata: AddressMetadata) {
    this.form.patchValue(addressMetadata);
    this.errorMessage = '';
  }

  addAddress = async () =>  {
    this.submitted = true;

    if(this.invalidAddressError) {
      this.errorMessage = this.invalidAddressError;
      return;
    }

    if (!this.form.valid) {
      return;
    }
    const zipcode = keepOnlyText(this.form.value.zipcode);
    const address = this.parseAddressName();
    const data = {
      address: {
        address,
        unit: this.form.value.unit,
        zipcode,
        country_code: this.countryCode,
        add_state: this.form.value.state,
        home_access: this.form.value.home_access,
        home_close: this.form.value.home_close
      },
      customer_id: this.clientId,
      latitude: this.form.value.latitude,
      longitude: this.form.value.longitude
    };

    try {
      await this.privateClient.addPrivateAddress(data);
      const params = this.mountSuccessPageParams();
      this.customNavCtrl.navigateForward('success', params);
    } catch (err) {
      this.errorMessage = err.message || err.error.message;
    }
  }

  parseAddressName() {
    const array = this.form.value.address.split(',');
    return array[0];
  }

  mountSuccessPageParams(): SuccessPageParamsModel {
    return {
      header: `Address Added`,
      body: `The address for this Client has been added.`,
      buttonText: `Ok`,
      buttonRoute: `private-client/${this.clientId}`
    };
  }
}
