import { Injectable } from '@angular/core';
import Fuse from 'fuse.js';

@Injectable({
  providedIn: 'root'
})
export class FuseSearchProvider {

  readonly DEFAULT_THRESHOLD: number = 0.4;

  createFuseSearch(collection: Array<any>, opts?: Fuse.IFuseOptions<any>, index?: Fuse.FuseIndex<any>): Fuse<any> {

    return new Fuse(
      collection,
      {
        threshold: this.DEFAULT_THRESHOLD,
        ...opts
      },
      index
      );
  }
}

