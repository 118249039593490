<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Client Refused Service'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="('How did ' | translate) +
          job?.client +
          ' ' +
          (job?.last_name | abbrLastName: true) +
          (' tell you they refused service?' | translate)">
        </tidy-text>
      </tidy-row>

      <tidy-button
        [text]="'In Person'"
        [action]="refusedService.bind(this)"
        class="secondary">
      </tidy-button>

      <tidy-button
        [text]="'Text / Phone'"
        [action]="refusedService.bind(this)"
        class="secondary">
      </tidy-button>

      <tidy-button
        text="Can't Access Property"
        [action]="showInvalidAction.bind(this)"
        class="secondary">
      </tidy-button>

      <tidy-button
        [text]="'Can\'t Contact Them'"
        [action]="showInvalidAction.bind(this)"
        class="secondary">
      </tidy-button>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
