// Angular dependencies
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PreloadAllModules, RouterModule, RouteReuseStrategy, Router } from '@angular/router';

// Ionic dependencies
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';

// Third party dependencies
import { NgCalendarModule  } from 'ionic2-calendar';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';


import { GlobalErrorHandler } from 'src/app/error-handler';
import { SharedModule } from 'src/shared/shared.module';
import { TidyHomekeeperAppPage } from 'src/app/app.component';
import { TidyUiComponentsModule } from '@tidyapp/tidy-ui-components';
import { pages, routes } from 'src/app/aggregators/pages';
import { providers } from 'src/app/aggregators/providers';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import * as Sentry from '@sentry/angular';

@NgModule({
    declarations: [
        TidyHomekeeperAppPage,
        ...pages
    ],
    imports: [
        CommonModule,
        NgCalendarModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot({
            name: '__mydb',
            driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage]
        }),
        BrowserAnimationsModule,
        TidyUiComponentsModule,
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload', useHash: true, relativeLinkResolution: 'legacy' }),
        SharedModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
        MatTooltipModule,
        MatProgressBarModule,
    ],
    providers: [
        ...providers,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        {
          provide: ErrorHandler,
          useValue: Sentry.createErrorHandler(),
        },
        {
          provide: Sentry.TraceService,
          deps: [Router],
        },
        {
          provide: APP_INITIALIZER,
          useFactory: () => () => {},
          deps: [Sentry.TraceService],
          multi: true,
        },
    ],
    bootstrap: [TidyHomekeeperAppPage],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
