import { Component } from '@angular/core';
import * as moment from 'moment';

import { MWStore } from "src/main-workflow/mw.store";
import { MWService } from "src/main-workflow/mw.service";
import { mwMoments } from 'src/main-workflow/mw.moments';

import { Me } from 'src/shared/providers/me';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { SuccessPageParamsModel } from 'src/pages/success/success';

import { MWJobModel } from "src/main-workflow/mw.models";

@Component({
  templateUrl: 'mw.cant-access.page.html'
})

export class CantAccessPage {

  loaded: boolean;
  job: MWJobModel;
  errorMessage: string;
  tryUntilTime: string;
  startCreditTime: string;
  phoneNumber: string;
  stillHelping: boolean;
  stillHelpingInterval: NodeJS.Timeout;

  readonly MINUTES_TO_WAIT = 20;
  readonly MINUTES_TO_CREDIT = 10;
  readonly SECONDS_TO_CREDIT = 480;
  readonly STILL_HELPING_INTERVAL = 1000 * 30;

  constructor(
    private navCtrl: CustomNavController,
    private me: Me,
    public mwService: MWService,
    private mwStore: MWStore
  ) {}

  async ionViewDidEnter() {
    this.loaded = false;
    this.job = await this.mwStore.getJob();
    this.phoneNumber = await this.mwStore.getPhoneNumber();
    await this.calcTimes();
    this.checkStillHelping();
    this.loaded = true;
  }

  async cantGetIn() {
    this.errorMessage = null;
    try {
      const calledOrTexted = await this.mwStore.getCalledTexted();
      if (!calledOrTexted) {
        const noCallTextError = 'Not enough calls to the Client. Try calling or texting for help.';
        return this.showErrorMessage(noCallTextError);
      }
      if (!this.waitedFullTime()) {
        return this.showErrorMessage('Please wait at least 20 minutes to cancel the job.');
      }
      await this.mwService.sendMomentToBackend(mwMoments.confirmedCantAccess, 'cant-access', this.job.id);
      await this.mwStore.clearStore();
      const successParams: SuccessPageParamsModel = {
        header: 'Job Cancelled',
        body: 'We are so sorry that the client didn\'t help you get into their home.',
        buttonText: 'Go to My Jobs',
        buttonRoute: `past-job/${this.job.id}`
      };
      this.navCtrl.navigateForward('success', successParams);
    } catch (err) {
      const message = await this.mwService.buildErrorAlert(err);
      this.showErrorMessage(message);
    }
  }

  waitedFullTime() {
    const tryUntilMoment = moment(this.tryUntilTime, 'h:mma');
    const diff = tryUntilMoment.diff(moment(), 'seconds');
    if (diff > 0) {
      return false;
    }
    return true;
  }

  async calcTimes() {
    const cantGetInTimeout = await this.mwStore.getCantGetInTimeout();
    if (cantGetInTimeout) {
      this.tryUntilTime = cantGetInTimeout;
      const startCreditMoment = moment(this.tryUntilTime, 'h:mma').subtract(this.MINUTES_TO_CREDIT, 'minutes');
      this.startCreditTime = startCreditMoment.format('h:mma');
    } else {
      let startTime: moment.Moment = moment(this.job.startTime, 'YYYY-MM-DD hh:mm:ss');
      const diffTime = moment().diff(startTime, 'seconds');
      if (diffTime > 0) {
        startTime = moment();
      }

      this.tryUntilTime = moment(startTime).add(this.MINUTES_TO_WAIT, 'minutes').format('h:mma');
      this.startCreditTime = moment(startTime).add(this.MINUTES_TO_CREDIT, 'minutes').format('h:mma');
      await this.mwStore.setCantGetInTimeout(this.tryUntilTime);
    }
  }

  async backToJob() {
    try {
      const startCreditMoment = moment(this.startCreditTime, 'h:mma');
      const diffTime = moment().diff(startCreditMoment, 'seconds');
      if(diffTime > 0) {
        await this.mwStore.setCreditTime(diffTime);
      }
      await this.mwService.sendMomentToBackend(mwMoments.gotAccess, 'cant-access', this.job.id);
      await this.mwStore.setRoute('arrived-at-job');
      this.navCtrl.navigateForward('arrived-at-job');
    } catch(err) {
      const message = await this.mwService.buildErrorAlert(err);
      this.showErrorMessage(message);
    }
  }

  checkStillHelping() {
    if (this.stillHelpingInterval) {
      clearInterval(this.stillHelpingInterval);
    }
    this.stillHelpingInterval = setInterval( () => {
      const startCreditMoment = moment(this.startCreditTime, 'h:mma');
      const diff = moment().diff(startCreditMoment, 'seconds');
      this.stillHelping = diff > 0;
    }, this.STILL_HELPING_INTERVAL);
  }

  showErrorMessage(message) {
    this.errorMessage = message;
    setTimeout(() => this.errorMessage = null, 10000);
  }

  goToCallOutPage() {
    this.navCtrl.navigateForward('cancel-job');
  }

  goToClientRefusedServicePage() {
    this.navCtrl.navigateForward('client-refused-service');
  }

  goToContactPage() {
    this.navCtrl.navigateForward('job-messages', {cameFromNoAccessPage: true});
  }

  ionViewWillLeave(): void {
    clearInterval(this.stillHelpingInterval);
    this.stillHelpingInterval = null;
  }
}
