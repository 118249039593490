import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PrivatePayments } from 'src/providers/private-payments/private-payments';

import { ConfirmPageParamsModel, ConfirmPage } from 'src/pages/confirm/confirm';
import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';

@Component({
  templateUrl: 'edit-client-payment-setting.html'
})

export class EditClientPaymentSettingPage {

  addressItems: TidySelectNumberValueModel[];
  errorMessage: string;
  form: UntypedFormGroup;
  isPreferred: boolean;
  loaded: boolean;
  paymentTypeItems: TidySelectNumberValueModel[];
  setting: any;
  submitted: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalCtrl: ModalController,
    private navCtrl: CustomNavController,
    private privatePayments: PrivatePayments
  ) {
    this.form = this.formBuilder.group({
      accountName: [''],
      notes: [''],
      paymentType: ['', Validators.required]
    })
  }

  async ionViewDidEnter() {
    this.loaded = false;
    this.setting = this.navCtrl.getParam('setting');
    this.paymentTypeItems = await this.buildPaymentTypeItems();
    this.form.patchValue({
      accountName: this.setting.third_party_account_name,
      notes: this.setting.notes,
      paymentType: this.setting.team_payment_option_type.id
    });
    this.form.controls.paymentType.disable();
    this.isPreferred = this.setting.is_preferred;
    this.loaded = true;
  }

  async buildPaymentTypeItems() {
    const paymentOptions = this.navCtrl.getParam('paymentOptions');
    const paymentTypes = await this.privatePayments.getEligiblePaymentTypes();
    const ineligibleTypes = [];
    paymentOptions.map((option) => {
      paymentTypes.map((type) => {
        if (type.name == option.team_payment_option_type.name && !ineligibleTypes.includes(type)) {
          ineligibleTypes.push(type);
        }
      })
    })
    let array = [{
      viewValue: this.setting.team_payment_option_type.name,
      value: this.setting.team_payment_option_type.id
    }];
    paymentTypes.map((type) => {
      if (!ineligibleTypes.includes(type)) {
        array.push({
          viewValue: type.name,
          value: type.id
        });
      }
    });
    return array
  }

  async saveChanges() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    try {
      const payload = this.buildPayload();
      await this.privatePayments.editPaymentOption(payload);
      this.showSuccessPage('Changes Saved');
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  buildPayload() {
    return {
      third_party_account_name: this.form.value.accountName,
      notes: this.form.value.notes,
      is_preferred: this.isPreferred,
      team_payment_option_id: this.setting.id,
    };
  }

  async removeOption() {
    const confirmResend: ConfirmPageParamsModel = {
      title: 'Remove Payment Option?',
      body: ``,
      backText: 'Go Back',
      confirmText: 'Remove Option',
      confirmAction: this.confirmRemoveOption.bind(this)
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: confirmResend,
      animated: false
    });
    confirmationModal.present();
  }

  async confirmRemoveOption() {
    const payload = this.buildPayload();
    payload['state'] = 'inactive';
    await this.privatePayments.editPaymentOption(payload);
    this.showSuccessPage('Option Removed');
  }

  showSuccessPage(title) {
    const successParams = {
      header: title,
      body: ``,
      buttonText: 'Ok',
      buttonRoute: 'client-payment-settings'
    };
    this.navCtrl.navigateForward('success', successParams);
    this.modalCtrl.dismiss();
  }

  showAccountNameField() {
    let showField = true;
    switch(this.setting.team_payment_option_type.id) {
      case 1:
      case 2:
      case 3:
        showField = false;
    }
    return showField;
  }
}
