<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Booking Settings'"
    [customBack]="'clients-from-tidy-settings'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form" novalidate action="#">
    <tidy-wrapper>
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'You are only paired with Clients from TIDY that match your booking restrictions.
          Less restrictions usually means more Clients.'">
        </tidy-text>
      </tidy-row>

      <tidy-row>
        <tidy-text
          [body]="changesSaved">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <tidy-row>
          <tidy-text
            [header]="'Payment Processing'">
          </tidy-text>
        </tidy-row>
        <tidy-card-button [hideArrow]="true" (action)="updateCheckboxSetting()">
          <tidy-row>
            <tidy-checkbox
              formControlName="paymentProcessing"
              [items]="[{viewValue: 'Require only clients that do not use TIDY Payment Processing', value: true}]">
            </tidy-checkbox>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <tidy-text
            [header]="'Cancellation Policy'">
          </tidy-text>
        </tidy-row>
        <tidy-card-button [hideArrow]="true" (action)="updateCheckboxSetting()">
          <tidy-row>
            <tidy-checkbox
              formControlName="cancellationPolicy"
              [items]="[{viewValue: 'Require cancellation policy from Clients', value: true}]">
            </tidy-checkbox>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <tidy-text
            [header]="'Default Contract'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'Your default contract for your clients unless otherwise agreed in writing:'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-top-padding">
          <tidy-select
            [form]="form.controls.contractTerms"
            formControlName="contractTerms"
            [items]="[{viewValue: 'Optional Service Contract Terms', value: ''}]">
          </tidy-select>
        </tidy-row>
        <tidy-row class="extra-top-padding">
          <tidy-text
            (action)="viewDefault()"
            [body]="'View Your Current Default'"
            class="link">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            (action)="showAlert('other')"
            [body]="'Upload a Contract'"
            class="link">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <tidy-text
            [header]="'Dispute Resolution'">
          </tidy-text>
        </tidy-row>
        <tidy-card-button [hideArrow]="true" (action)="updateCheckboxSetting()">
          <tidy-row>
            <tidy-checkbox
              formControlName="disputeResolution"
              [items]="[{viewValue: 'Allow TIDY Informal Dispute Resolution', value: true}]">
            </tidy-checkbox>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <tidy-text
            [header]="'Discounts'">
          </tidy-text>
        </tidy-row>
        <tidy-card-button [hideArrow]="true" (action)="showAlert('other')">
          <tidy-row>
            <tidy-text
              [body]="'Provide discount for new Clients '">
            </tidy-text>
            <tidy-text
              class="link"
              [body]="'Edit Discount Amount'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <tidy-text
            [header]="'Rate Commitment'">
          </tidy-text>
        </tidy-row>
        <tidy-card-button [hideArrow]="true" (action)="showAlert('other')">
          <tidy-row>
            <tidy-text
              [body]="'Length of time you honor your rate with clients: 1 year '">
            </tidy-text>
            <tidy-text
              [body]="'Edit'"
              (action)="showAlert('other')"
              class="link">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <tidy-text
            [header]="'Location Tracking'">
          </tidy-text>
        </tidy-row>
        <tidy-card-button [hideArrow]="true" (action)="updateCheckboxSetting()">
          <tidy-row>
            <tidy-checkbox
              formControlName="locationTracking"
              [items]="[{viewValue: 'Allow location tracking during jobs', value: true}]">
            </tidy-checkbox>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [header]="'Service Restrictions'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-top-padding">
          <tidy-select
            [form]="form.controls.minLength"
            formControlName="minLength"
            [label]="'Minimum Job Length'"
            [items]="serviceLengths">
          </tidy-select>
        </tidy-row>
        <tidy-row class="extra-top-padding">
          <tidy-select
            [form]="form.controls.maxLength"
            formControlName="maxLength"
            [label]="'Maximum Job Length'"
            [items]="serviceLengths">
          </tidy-select>
        </tidy-row>
        <tidy-button
          [text]="'Save Service Restrictions'"
          [action]="showAlert.bind(this, 'other')"
          class="primary">
        </tidy-button>
        <tidy-card-button [hideArrow]="true" (action)="updateCheckboxSetting()">
          <tidy-row>
            <tidy-checkbox
              formControlName="twoHomekeeper"
              [items]="[{viewValue: 'Allow simultaneous jobs at the same site with other Pros.', value: true}]">
            </tidy-checkbox>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <tidy-text
            [header]="'Client Restrictions'">
          </tidy-text>
        </tidy-row>
        <tidy-card-button [hideArrow]="true" (action)="updateCheckboxSetting()">
          <tidy-row>
            <tidy-checkbox
              formControlName="residential"
              [items]="[{viewValue: 'Allow residential Clients', value: true}]">
            </tidy-checkbox>
          </tidy-row>
          <tidy-row>
            <tidy-checkbox
              formControlName="rental"
              [items]="[{viewValue: 'Allow rental / AirBnB Clients', value: true}]">
            </tidy-checkbox>
          </tidy-row>
          <tidy-row>
            <tidy-checkbox
              formControlName="office"
              [items]="[{viewValue: 'Allow office Clients', value: true}]">
            </tidy-checkbox>
          </tidy-row>
          <tidy-row>
            <tidy-checkbox
              formControlName="repeat"
              [items]="[{viewValue: 'Allow repeat Clients', value: true}]">
            </tidy-checkbox>
          </tidy-row>
          <tidy-row>
            <tidy-checkbox
              formControlName="new"
              [items]="[{viewValue: 'Allow new Clients', value: true}]">
            </tidy-checkbox>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row [alignLastItemRight]="true">
          <tidy-text
            [header]="'Pay Restrictions'">
          </tidy-text>
          <tidy-text
            (action)="goTo('clients-from-tidy-services')"
            [body]="'View My Pay Rate'"
            class="link">
          </tidy-text>
        </tidy-row>
        <tidy-card-button [hideArrow]="true" (action)="updateCheckboxSetting()">
          <tidy-row>
            <tidy-checkbox
              formControlName="payRestrictions"
              [items]="[{viewValue: 'Only allow Clients that meet my pay rate.', value: true}]">
            </tidy-checkbox>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row [alignLastItemRight]="true">
          <tidy-text
            [header]="'Drive Restrictions'">
          </tidy-text>
          <tidy-text
            (action)="goTo('profile/location')"
            [body]="'View Drive Time'"
            class="link">
          </tidy-text>
        </tidy-row>
        <tidy-card-button [hideArrow]="true" (action)="updateCheckboxSetting()">
          <tidy-row>
            <tidy-checkbox
              formControlName="driveTime"
              [items]="[{viewValue: 'Only allow Clients that are within my max drive time.', value: true}]">
            </tidy-checkbox>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <tidy-text
            [header]="'Other Restrictions'">
          </tidy-text>
        </tidy-row>
        <tidy-card-button [hideArrow]="true" (action)="updateCheckboxSetting()">
          <tidy-row>
            <tidy-checkbox
              formControlName="other"
              [items]="[{viewValue: 'Allow Clients that require me to use TIDY.com.', value: true}]">
            </tidy-checkbox>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

    </tidy-wrapper>
  </form>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
