<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Waive Client Fees'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper *ngIf="loaded">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card>
      <tidy-row class="extra-bottom-padding vertical-align-center">
        <tidy-image
          [src]="'assets/svg/checkmark-circle.svg'"
          class="body-size">
        </tidy-image>
        <tidy-text
          [header]="'Waive Client Fees'">
        </tidy-text>
      </tidy-row>
      <tidy-row class="extra-bottom-padding">
        <tidy-text
          [body]="'Many clients allow you to waive infrequent fees. The median client allows fee waivers for up to 10% of completed jobs.'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          *ngIf="feesToWaive?.last_fee_waiver_date !== null && feesToWaive?.last_fee_waiver_date !== undefined"
          [body]="' Your last fee waiver was on '">
        </tidy-text>
        <tidy-text
          *ngIf="feesToWaive?.last_fee_waiver_date !== null && feesToWaive?.last_fee_waiver_date !== undefined"
          [body]="(feesToWaive?.last_fee_waiver_date | tdate:'M/D/YY':'YYYY-MM-DD') + '.'">
        </tidy-text>
        <tidy-text
          *ngIf="feesToWaive?.last_fee_waiver_date === null || feesToWaive?.last_fee_waiver_date === undefined"
          [body]="' You have never used a fee waiver.'">
        </tidy-text>
      </tidy-row>
    </tidy-card>

    <tidy-row *ngIf="!feesToWaive?.eligible">
      <tidy-text
        [body]="'You are not eligible for any fee waiver from your clients.'">
      </tidy-text>
    </tidy-row>

    <tidy-row *ngIf="!hasFeeLastThirtyDays">
      <tidy-text
        [body]="'You have no client fees within the last 30 days.'">
      </tidy-text>
    </tidy-row>

    <ng-container *ngIf="feesToWaive?.eligible">
      <tidy-row>
        <tidy-text *ngIf="hasFeeLastThirtyDays"
          [body]="'The following fees have been authorized for waiving by the client:'">
        </tidy-text>
      </tidy-row>
      <ng-container *ngFor="let fee of feesToWaive?.fees">
        <tidy-card *ngIf="fee.total_fees !== 0">
          <tidy-card-button (action)="waiveFee(fee)">
            <tidy-text
             [body]="(fee.total_fees | tcurrency)">
            </tidy-text>
            <tidy-text
             [body]="' on '">
            </tidy-text>
            <tidy-text
             [body]="(fee.date | tdate:'M/D/YY':'YYYY-MM-DD')">
            </tidy-text><br>
            <ng-container *ngFor="let cleaning of fee.cleanings">
              <tidy-text
                [helper]="cleaning?.performance">
              </tidy-text>
              <tidy-text
                [helper]="' at '">
              </tidy-text>
              <tidy-text
                [helper]="(cleaning?.start_datetime | tdate:'h:mma')">
              </tidy-text><br>
            </ng-container>
          </tidy-card-button>
        </tidy-card>
      </ng-container>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
