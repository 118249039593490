<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Reviews'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form" novalidate action="#">
    <tidy-wrapper *ngIf="loaded">
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
      <ng-container *ngIf="reviewsData.length > 0">
        <tidy-error-message
          *ngIf="errorMessage"
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-row>
          <tidy-text
            [body]="savingText">
          </tidy-text>
        </tidy-row>

        <tidy-card *ngFor="let review of reviewsData; let i = index">
          <tidy-row [alignLastItemRight]="true">
            <tidy-image
              [src]="'assets/svg/chatbubble.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="review.client_name | abbrLastName"
              [translate]="false">
            </tidy-text>
            <tidy-text
              [helper]="review.review_date | tdate:'M/D/YY'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [body]="review.review_content">
            </tidy-text>
          </tidy-row>
          <tidy-divider>
          </tidy-divider>
          <tidy-checkbox
            [items]="[{viewValue: 'Show on my Website', value: true}]"
            [initValue]="review.visible_on_web"
            [(checked)]="review.visible_on_web"
            (checkedChange)="changeVisibility(review.id, $event)">
          </tidy-checkbox>
        </tidy-card>
      </ng-container>

      <tidy-alert *ngIf="reviewsData.length === 0">
        <tidy-text
          [body]="'You don\'t have any reviews yet.'">
        </tidy-text>
      </tidy-alert>
    </tidy-wrapper>
  </form>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
