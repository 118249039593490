<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Block New Zipcode'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form">
    <tidy-wrapper>
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <tidy-card>
        <tidy-row>
          <tidy-text
            [body]="'This will prevent jobs from being booked in this zipcode.'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="field-top-padding">
          <tidy-input
            formControlName="zipcode"
            [label]="'Zipcode'"
            [form]="form.controls.zipcode"
            [submitted]="submitted"
            [errorMessage]="'Invalid zipcode'"
            [icon]="'assets/svg/home-outline.svg'"
            [activeIcon]="'assets/svg/home-outline-tidy-green.svg'">
          </tidy-input>
        </tidy-row>
        <tidy-row>
          <tidy-checkbox
            formControlName="unassign"
            [items]="[{viewValue: 'Cancel current jobs in this zipcode', value: false}]">
          </tidy-checkbox>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [helper]="'You can also leave it unchecked to keep your scheduled jobs within the zipcode area'">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-button
        item-right
        [action]="submit.bind(this)"
        [text]="'Block This Zipcode'"
        class="primary">
      </tidy-button>

      <tidy-error-message
        [text]="errorMessage">
      </tidy-error-message>

    </tidy-wrapper>
  </form>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
