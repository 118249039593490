import { Component, ViewEncapsulation, HostBinding, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateTime as LuxonDateTime } from 'luxon';

import { AddressService } from 'src/shared/providers/address';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { DeviceInfoProvider } from 'src/shared/providers/device-info';
import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';
import { Me } from 'src/shared/providers/me';
import { SharedLinksProvider } from 'src/providers/shared-links/shared-links';
import { TidyStorage } from "src/shared/providers/tidy-storage";

import { Loading } from 'src/shared/components/loading/loading';

import { SuccessPage } from '../success/success';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm';

import { ToDosProvider } from 'src/providers/to-dos/to-dos';
import { ToDosComponent } from 'src/shared/components/to-dos/to-dos.component';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';
import { CustomFieldsService } from 'src/shared/providers/custom-fields/custom-fields';

@Component({
  templateUrl: 'shared-job.html',
  encapsulation: ViewEncapsulation.None
})

export class SharedJobPage {

  @ViewChild(ToDosComponent) toDosComponent: ToDosComponent;

  loaded: boolean;

  accessPhotos: any;
  baMediaType: string;
  currentRoomId: number;
  closingPhotos: any;
  lastMoment: string;
  errorMessage: string;
  form: UntypedFormGroup;
  job: any;
  isShowingTodos: boolean = false;
  hasSameDayCheckIn: boolean;
  numberOfRooms: number;
  parkingPhotos: any;
  parkingDescriptions = {
    'meter' : 'at a meter',
    'paidlot' : 'in a paid lot',
    'street' : 'on the street',
    'myspot' : 'in their spot/driveway',
    'guest' : 'in guest parking'
  };
  storedIssues: any;
  storedToDos: any;
  showCollapse: boolean;
  showContactButton: boolean;
  showBeforePhotosButton: boolean;
  showAfterPhotosButton: boolean;
  submitted: boolean;
  previousGuestReservationDetails: any;
  nextGuestReservationDetails: any;
  screenwidth: number;
  customFields: any;

  @HostBinding('@.disabled') disableAnimations: boolean;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenwidth = event.target.innerWidth
  }

  constructor(
    private addressService: AddressService,
    public deviceInfo: DeviceInfoProvider,
    private iabUtils: InAppBrowserUtils,
    private fb: UntypedFormBuilder,
    public me: Me,
    private modalCtrl: ModalController,
    private navCtrl: CustomNavController,
    public route: ActivatedRoute,
    private sharedLinks: SharedLinksProvider,
    private store: TidyStorage,
    router: Router,
    public toDos: ToDosProvider,
    private customFieldsService: CustomFieldsService
  ) {
    this.screenwidth = window.innerWidth;
    this.form = this.fb.group({});
  }

  @Loading()
  async ionViewDidEnter() {
    try {
      this.loaded = false;
      const uuid = this.route.snapshot.paramMap.get('uuid');
      this.showContactButton = !this.route.routeConfig.path.includes('shared');
      this.job = await this.store.retrieve(`sharedJob/${uuid}`) || await this.sharedLinks.getSharedLink(uuid);
      await this.store.save(`sharedJob/${uuid}`, this.job);
      this.parkingPhotos = this.job.shared_link_data.address?.photo_notes.filter((photo) => photo.type == 'parking');
      this.accessPhotos = this.job.shared_link_data.address?.photo_notes.filter((photo) => photo.type == 'access');
      this.closingPhotos = this.job.shared_link_data.address?.photo_notes.filter((photo) => photo.type == 'closing');
      await this.getCustomFields();
      if (this.job?.shared_link_data?.guest_reservation_data?.previous_guest_reservation?.details) {
        this.previousGuestReservationDetails = {
          ...this.job?.shared_link_data?.guest_reservation_data?.previous_guest_reservation?.details,
          customFields: this.customFieldsService.getCustomFieldsForDetails(this.customFields, this.job?.shared_link_data?.guest_reservation_data?.previous_guest_reservation?.custom_fields)
        }
      }
      if (this.job?.shared_link_data?.guest_reservation_data?.next_guest_reservation?.details) {
        this.nextGuestReservationDetails = {
          ...this.job?.shared_link_data?.guest_reservation_data?.next_guest_reservation?.details,
          customFields: this.customFieldsService.getCustomFieldsForDetails(this.customFields, this.job?.shared_link_data?.guest_reservation_data?.next_guest_reservation?.custom_fields)
        }
        this.hasSameDayCheckIn = this.checkIfHasSameDayCheckIn();
      }
      const moments = await this.store.retrieve(`sharedJob/${this.job.uuid}/moments`);
      if (moments) {
        this.lastMoment = moments[moments?.length - 1]?.moment;
      }
      this.loaded = true;
    } catch (err) {
    }
  }

  async getCustomFields() {
    try {
      this.customFields = await this.customFieldsService.getCustomFields(this.job?.shared_link_data?.booking?.id, 'GuestReservation');
    } catch (err) {
      console.error(err);
    }
  }
  completeJob() {
    this.toDosComponent.completeList();
  }

  @Loading()
  async getDirections() {
    await this.addressService.directions(this.job.shared_link_data.address.address);
  }

  async showLogInAlert() {
    const modalParams = {
      header: 'Please Log In',
      body: 'You must be logged into the TIDY Pro app to use this feature.',
      buttonText: 'Go Back',
      customAction: this.goToJob.bind(this),
      secondButtonText: 'Log In',
      secondCustomAction: this.goToIntroduction.bind(this)
    };
    const modal = await this.modalCtrl.create({
      component: SuccessPage,
      componentProps: { modalParams },
      animated: false
    });
    modal.present();
  }

  goToIntroduction() {
    this.modalCtrl.dismiss();
    this.navCtrl.navigateForward('introduction');
  }

  goToJob() {
    this.modalCtrl.dismiss();
    this.navCtrl.navigateForward(`job/${this.job.uuid}`)
  }

  goToContactPage() {
    this.job.shared_link_data.address_task_list?.address_rooms.map((room) => {
      room.tasks.map((task) => {
        task['customFieldForm'] = null;
      });
    });
    const payload = {
      data: this.job
    }
    this.navCtrl.navigateForward('shared-contact-client', payload);
  }

  goToLearnMore() {
    this.iabUtils.openUrl('https://help.tidy.com/job-links');
  }

  async cancelJob() {
    const params: ConfirmPageParamsModel = {
      title: 'Cancel Job?',
      body: `${new TranslationPipe().transform('We will notify')} ${this.job.shared_link_data.customer.first_name}`,
      backText: 'Go Back',
      confirmText: 'Cancel Job',
      confirmAction: this.confirmCancelJob.bind(this)
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });
    confirmationModal.present();
  }

  async confirmCancelJob() {
    try {
      const payload = {
        action: 'cancel_job',
      }
      await this.sharedLinks.updateSharedLink(this.job.uuid, payload);
      this.showSuccessJobCancelled();
      this.modalCtrl.dismiss();
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  showSuccessJobCancelled() {
    const successParams = {
      header: 'Job Cancelled',
      body: '',
      buttonText: 'Ok',
      buttonRoute: 'introduction'
    };
    this.navCtrl.navigateForward('success', successParams);
  }

  goToIntroductionPage() {
    this.navCtrl.navigateForward('introduction');
  }

  checkIfHasSameDayCheckIn() {
    const checkInDate = LuxonDateTime.fromISO(this.job.shared_link_data.guest_reservation_data.next_guest_reservation.check_in_date);
    const jobDate = LuxonDateTime.fromISO(this.job?.shared_link_data?.job?.date);
    return checkInDate.hasSame(jobDate, 'day');
  }

  formatReservationDate(date) {
    const isoDate = LuxonDateTime.fromISO(date);
    return isoDate.toFormat('EEE MMM dd, yyyy');
  }

  formatReservationTime(time) {
    const isoDate = LuxonDateTime.fromISO(time, { zone: 'utc' });
    return isoDate.toFormat('h:mma').toLowerCase();
  }

  getReservationLength(checkInDate, checkOutDate) {
    const isoCheckInDate = LuxonDateTime.fromISO(checkInDate);
    const isoCheckOutDate = LuxonDateTime.fromISO(checkOutDate);
    const numberDays = isoCheckOutDate.diff(isoCheckInDate, 'days').days;
    return ' (' + numberDays + ((numberDays == 1) ? ' day)' : ' days)');
  }

  getLastSharedJobMoment() {
    return localStorage.getItem(`sharedJob/${this.job.uuid}/last-moment`);
  }

}
