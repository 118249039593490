<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Call Out?'"
    [canGoBack]="true"
    [customBack]="'check-in'"
    >
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          class="red"
          [body]="'Select jobs to call out:'">
        </tidy-text>
      </tidy-row>

      <tidy-card *ngFor="let cleaning of callOutCleanings">
        <tidy-row>
          <tidy-grid>
            <ion-row>
              <ion-col size="2">
                <tidy-checkbox
                  [(checked)]="cleaning.checked"
                  [initValue]="cleaning.checked"
                  [items]="[{viewValue: '', value: ''}]">
                </tidy-checkbox>
              </ion-col>
              <ion-col size="4">
                <tidy-text
                  [body]="cleaning?.job?.start_time | ttime"  >
                </tidy-text><br>
                <tidy-text
                  [body]="cleaning?.job?.duration_in_minutes">
                </tidy-text>
                <tidy-text
                  [body]="cleaning?.job?.duration_in_minutes > 60 ? ' hours' : ' hour'">
                </tidy-text>
              </ion-col>
              <ion-col size="1">
                <tidy-image
                  *ngIf="cleaning?.homekeeper_job?.repeat_client"
                  [src]="'assets/svg/star.svg'"
                  class="body-size">
                </tidy-image>
              </ion-col>
              <ion-col size="5" >
                <tidy-text
                  [body]="(cleaning?.customer?.account_name !== '' && cleaning?.customer?.account_name) ?
                  cleaning?.customer?.account_name :
                  (cleaning?.customer?.first_name + ' ' + (cleaning?.customer?.last_name | abbrLastName: true))">
                </tidy-text><br>
                <tidy-text
                  class="red"
                  [body]="'Call Out: '">
                </tidy-text>
                <tidy-text
                class="red"
                [body]="cleaning.call_out_price | tcurrency">
              </tidy-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
      </tidy-card>

      <tidy-row [align]="'center'">
        <tidy-error-message
          *ngIf="!choseCleaning"
          [text]="'Please select jobs to call out, or go back and check in.'">
        </tidy-error-message>
      </tidy-row>

      <tidy-row [align]="'center'">
        <tidy-text
          class="red"
          [body]="'Canceling on clients is the most common way to lose them.'">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <form [formGroup]="reasonForm" novalidate action="#">
          <tidy-row class="field-top-padding">
            <tidy-select
              [label]="'Why are you cancelling?'"
              [form]="reasonForm.controls.reason"
              formControlName="reason"
              [items]="cancelReasonsItems"
              [submitted]="submitted"
              errorMessage="Please select a reason">
            </tidy-select>
          </tidy-row>
        </form>
      </tidy-card>

      <tidy-error-message
        [text]="apiError">
      </tidy-error-message>

      <tidy-button item-right
        [text]="'Call Out'"
        [action]="confirm.bind(this)"
        class="secondary">
      </tidy-button>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
