<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="loaded ? ('Contact ' + job?.client + ' ' + (job?.last_name | abbrLastName: true)) : ''"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      [text]="errorMessage"
      *ngIf="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">
      <tidy-card>
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/style-guide-images/bowtie-black.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Today '">
          </tidy-text>
          <tidy-text
            [header]="job?.startTime | formatDateTitle">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-grid>
            <ion-row>
              <ion-col size="4">
                <tidy-text
                  [body]="job?.duration">
                </tidy-text><br>
                <tidy-text
                  [body]="job?.startTime | tdate:'h:mma'">
                </tidy-text>
              </ion-col>
              <ion-col size="1">
                <tidy-image
                  *ngIf="job?.isRepeatClient"
                  [src]="'assets/svg/star-yellow.svg'"
                  class="body-size">
                </tidy-image>
              </ion-col>
              <ion-col size="7" >
                <tidy-text
                  [body]="job?.client + ' ' + (job?.last_name | abbrLastName: true)"
                  [translate]="false">
                </tidy-text>
                <br>
                <tidy-text
                  *ngIf="job?.job_delegation_request?.role !== 'delegatee'"
                  [body]="job?.categoryName">
                </tidy-text>
                <tidy-text
                  *ngIf="job?.job_delegation_request?.role !== 'delegatee'"
                  [body]="': ' + (job?.payment_price | tcurrency)">
                </tidy-text>
                <tidy-text
                  *ngIf="job?.job_delegation_request?.role === 'delegatee'"
                  [body]="job?.categoryName">
                </tidy-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row class="extra-bottom-padding" [alignLastItemRight]="true">
          <tidy-image
            [src]="'assets/img/account.svg'"
            class="title-size">
          </tidy-image>
          <tidy-text
            [header]="job?.client">
          </tidy-text>
          <tidy-text
            [body]="'Call'"
            class="link"
            (action)="contactClient('call')">
          </tidy-text>
        </tidy-row>
        <ng-container *ngIf="!messagesLoaded">
          <tidy-loader></tidy-loader>
        </ng-container>
        <ng-container *ngIf="messagesLoaded">
          <tidy-row class="field-top-padding extra-bottom-padding">
            <form [formGroup]="form" novalidate action="#">
              <tidy-textarea
                [label]="'Send Message'"
                formControlName="message"
                [form]="form.controls.message"
                [submitted]="submitted"
                [errorMessage]="'Please enter a message to send.'"
                [rightIconAction]="contactClient.bind(this, 'message')"
                [leftIconAction]="contactClient.bind(this, 'attachment')">
              </tidy-textarea>
            </form>
          </tidy-row>
          <tidy-row *ngIf="messages?.length == 0" [align]="'center'">
            <tidy-text
              [helper]="'No Messages'">
            </tidy-text>
          </tidy-row>
          <ng-container *ngFor="let message of messages; let last = last">
            <message
              [message]="message"
              [last]="last">
            </message>
          </ng-container>
        </ng-container>
      </tidy-card>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
