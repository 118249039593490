<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav [class]="extraClass" [noBow]="true" [title]="title"> </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'" class="extra-bottom-padding">
      <tidy-text [body]="body"> </tidy-text>
    </tidy-row>

    <tidy-error-message [text]="errorMessage"></tidy-error-message>

    <tidy-two-column-row *ngIf="sameRowButtons">
      <tidy-button
        [text]="backText"
        [action]="backAction.bind(this)"
        [class]="backButtonClass"
      >
      </tidy-button>
      <tidy-button
        [text]="confirmText"
        [action]="confirm.bind(this)"
        [class]="confirmButtonClass"
      >
      </tidy-button>
    </tidy-two-column-row>

    <ng-container *ngIf="!sameRowButtons">
      <tidy-row>
        <tidy-button
          [text]="backText"
          [action]="backAction.bind(this)"
          [class]="backButtonClass"
        >
        </tidy-button>
      </tidy-row>
      <tidy-row>
        <tidy-button
          [text]="confirmText"
          [action]="confirm.bind(this)"
          [class]="confirmButtonClass"
        >
        </tidy-button>
      </tidy-row>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
