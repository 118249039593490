import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Proposals } from 'src/providers/proposals/proposals';
import { PrivateClient } from 'src/providers/private-client/private-client';
import { PrivatePayments } from 'src/providers/private-payments/private-payments';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm';
import { SuccessPageParamsModel } from 'src/pages/success/success';

import { ClientModel } from 'src/shared/models/client.model';
import { ProposalModel } from 'src/shared/models/proposals.model';

import { Timeout } from 'src/shared/components/timeout/timeout';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

@Component({
  templateUrl: 'proposal.html'
})

export class ProposalPage extends Timeout {

  client: ClientModel;
  errorMessage: string;
  justAcceptedProposal = false;
  proposal: ProposalModel;
  proName: string;
  proPhoneNumber: string;
  proEmail: string;

  constructor(
    private modalCtrl: ModalController,
    private navCtrl: CustomNavController,
    private privateClient: PrivateClient,
    public privatePayments: PrivatePayments,
    private proposals: Proposals,
    public route: ActivatedRoute,
    private storage: TidyStorage,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router
  ) {
    super(timeoutErrorHandler, router, route);
  }

  async ionViewDidEnter() {
    try {
      this.loaded = false;
      this.proposal = this.navCtrl.getParam('proposal');
      this.proName = await this.storage.retrieve('hk_name');
      this.proPhoneNumber = await this.storage.retrieve('hk_phone_number');
      this.proEmail = await this.storage.retrieve('hk_email');
      if (!this.proposal) {
        const proposalId = this.route.snapshot.paramMap.get('proposalId');
        this.proposal = await this.proposals.getProposalDetail(proposalId);
      }
      this.client = await this.privateClient.getPrivateClientInfo(this.proposal.customer.id);
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  bookJob() {
    const clientName = this.proposal.customer.first_name;
    this.navCtrl.navigateForward(['add-private-job', this.proposal.customer.id], { clientName });
  }

  async cancelProposal() {
    const params: ConfirmPageParamsModel = {
      title: 'Cancel Proposal?',
      body: '',
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: this.confirmCancelProposal.bind(this),
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });
    confirmationModal.present();
  }

  async confirmCancelProposal() {
    await this.proposals.cancelProposal(this.proposal.id);
    const params = {
      header: 'Proposal Cancelled',
      body: '',
      buttonText: 'Ok',
      buttonRoute: 'dashboard'
    }
    this.navCtrl.navigateForward('success', params);
    this.modalCtrl.dismiss();
  }

  async markAccepted() {
    const params: ConfirmPageParamsModel = {
      title: 'Mark Accepted?',
      body: ``,
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: this.confirmMarkAccepted.bind(this),
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });
    confirmationModal.present();
  }

  async confirmMarkAccepted() {
    await this.proposals.acceptProposal(this.proposal.id);
    const params = {
      header: 'Proposal Accepted',
      body: '',
      buttonText: 'Ok',
      buttonRoute: 'proposals'
    }
    this.justAcceptedProposal = true;
    this.proposal = await this.proposals.getProposalDetail(this.proposal.id);
    this.modalCtrl.dismiss();
  }

  goToDashboardPage() {
    this.navCtrl.navigateForward('dashboard');
  }
}
