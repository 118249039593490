import { Component } from '@angular/core';

import { Certification } from 'src/providers/certification/certification';
import { ClientFromTidy } from 'src/providers/client-from-tidy/client-from-tidy';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { Loading } from 'src/shared/components/loading/loading';

import { RatesResponseModel } from 'src/shared/models/client-from-tidy';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { Router, ActivatedRoute } from '@angular/router';

import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

@Component({
  selector: 'learn-clients-from-tidy',
  templateUrl: 'learn-clients-from-tidy.html',
})

export class OnboardingLearnClientsFromTidyPage extends Timeout {

  cards: Cards[];
  errorMessage: string;
  finishedStep: boolean;
  rates: any;
  showRates: boolean;

  constructor(
    private certification: Certification,
    private clientFromTidy: ClientFromTidy,
    private customNavCtrl: CustomNavController,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
  }

  @Loading()
  async ionViewDidEnter() {
    try {
      const certifications = await this.certification.getCertificationsNoCache();
      this.finishedStep = certifications.steps.walkthrough === 'complete';
      this.showRates = (
        certifications.steps.profile === 'complete' &&
        certifications.steps.max_drive_time === 'complete'
      );
      if (this.showRates) {
        const rates: RatesResponseModel = await this.clientFromTidy.getPayRates();
        this.rates = this.populateRates(rates);
      }
      this.cards = this.getCards();
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  getCards(): Cards[]  {
    return [
      {
        header: 'What are Clients from TIDY?',
        body: 'By turning on clients from TIDY, you let clients searching for a pro like you using TIDY book you. It is a great way to earn more.',
        icon: 'people'
      },
      {
        header: 'Earn $1,000+ More Per Week',
        body: 'Top performers in great regions have earned over $1,000 per week.',
        icon: 'cash'
      },
      {
        header: 'Control Your Settings',
        body: 'You control your schedule, driving distance, and pay rate for clients. Clients can book directly on your available schedule, or you can view requests from people needing help.',
        icon: 'settings'
      },
      {
        header: 'Win Clients With Quality & Rates',
        body: 'You win new clients based on your Quality Score x Rate. This means the better you do for clients, the more clients you get. You don\'t compete only on price.',
        icon: 'bar-chart'
      },
      {
        header: 'Earn More With Repeat Clients',
        body: 'The number 1 way clients see/choose pros is who keeps clients repeatedly booking. So keeping clients happy is more important than reviews.',
        icon: 'repeat'
      },
      {
        header: 'Set Your Rates',
        body: 'You set your rates, and can change them anytime. Here are median payments and fees for pros/clients near you:',
        icon: 'briefcase'
      },
      {
        header: 'Clients from TIDY Are Easy',
        body: 'Clients from TIDY should be a great way to earn more with almost no extra setup. Clients follow your existing settings and schedule. Your schedule automatically syncs, so that Clients from TIDY can\'t book over your existing Clients. If you book a Private Client, that will automatically block off your schedule.',
        icon: 'calendar'
      },
      {
        header: 'We Are Here To Help',
        body: 'If you ever have any questions about clients from TIDY please contact us in the Concierge section.',
        icon: 'help-circle'
      }
    ]
  }

  populateRates(rates): any {
    const services = ['tidy', 'tidyPlus', 'mighty'];

    const payRates = {new: {}, repeat: {}};
    rates.forEach((payRate, index) => {
      payRates.new[services[index]] = payRate?.user_new_rate;
      payRates.repeat[services[index]] = payRate?.user_repeat_rate;
    });

    return payRates;
  }

  async gotIt() {
    this.errorMessage = '';
    try {
      await this.certification.finishStep('walkthrough', {});
      this.customNavCtrl.navigateForward('new-clients');
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }
}

interface Cards {
  header: string;
  body: string;
  icon: string;
}
