<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Add Private Client'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="loaded">

      <tidy-card>
        <form [formGroup]="form" novalidate action="#">
          <tidy-row class="field-top-padding field-bottom-padding">
            <tidy-input
              [label]="'First Name *'"
              formControlName="firstName"
              data-cy="firstName"
              [form]="form.controls.firstName"
              [icon]="'assets/svg/person-circle-outline.svg'"
              [activeIcon]="'assets/svg/person-circle-outline-tidy-green.svg'"
              [submitted]="submitted"
              [errorMessage]="'Please enter Client\'s first name'">
            </tidy-input>
          </tidy-row>
          <tidy-row class="field-bottom-padding">
            <tidy-input
              [label]="'Last Name *'"
              formControlName="lastName"
              data-cy="lastName"
              [form]="form.controls.lastName"
              [icon]="'assets/svg/person-circle-outline.svg'"
              [activeIcon]="'assets/svg/person-circle-outline-tidy-green.svg'"
              [submitted]="submitted"
              [errorMessage]="'Please enter Client\'s last name'">
            </tidy-input>
          </tidy-row>
          <tidy-row class="field-bottom-padding">
            <tidy-input
              [label]="'Email'"
              formControlName="email"
              data-cy="email"
              type="email"
              [form]="form.controls.email"
              [icon]="'assets/svg/mail-outline.svg'"
              [activeIcon]="'assets/svg/mail-outline-tidy-green.svg'"
              [submitted]="submitted"
              [errorMessage]="'Please enter a valid email'">
            </tidy-input>
          </tidy-row>
          <tidy-row class="field-bottom-padding">
            <tidy-input-international-phone
              formControlName="phone"
              [label]="'Phone Number'"
              data-cy="phone"
              [form]="form.controls.phone"
              [inputMode]="'numeric'"
              [submitted]="submitted"
              [errorMessage]="'Please enter a valid phone number.'"
              [icon]="'assets/svg/phone-portrait-outline.svg'"
              [defaultCountryCode]="countryCode"
              [activeIcon]="'assets/svg/phone-portrait-outline-tidy-green.svg'">
            </tidy-input-international-phone>
          </tidy-row>
          <tidy-row class="field-bottom-padding">
            <tidy-auto-complete-address
            [submitted]="submitted"
            [addressFormControl]="form.controls.address"
            [countryCode]="countryCode"
            (onAddressChosen)="choseItem($event)"
            (onError)="errorMessage = $event"
            (noAddressPickedUpdate)="noAddressPickedTemplate = $event"
            (invalidAddressError)="invalidAddressError = $event"
          ></tidy-auto-complete-address>
          </tidy-row>
          <tidy-row class="field-bottom-padding">
            <tidy-input
              [label]="'Unit / Suite'"
              formControlName="unit"
              data-cy="unit"
              [form]="form.controls.unit"
              [icon]="'assets/svg/home-outline.svg'"
              [activeIcon]="'assets/svg/home-outline-tidy-green.svg'">
            </tidy-input>
          </tidy-row>
          <tidy-row>
            <tidy-input
              [customMask]="zipCodeMask"
              [label]="'Zipcode'"
              formControlName="zip"
              [inputMode]="'numeric'"
              data-cy="zipcode"
              [form]="form.controls.zip"
              [icon]="'assets/svg/home-outline.svg'"
              [activeIcon]="'assets/svg/home-outline-tidy-green.svg'"
              [submitted]="submitted"
              [errorMessage]="'Please enter a valid zip code'">
            </tidy-input>
          </tidy-row>
          <tidy-row class="extra-top-padding">
            <tidy-text
              [body]="'By adding I accept the terms ('">
            </tidy-text>
            <tidy-text
              (action)="openAgreement()"
              [body]="'Service Provider Customer Agreement'"
              class="link">
            </tidy-text>
            <tidy-text
              [body]="')'">
            </tidy-text>
          </tidy-row>
        </form>
      </tidy-card>

      <ng-container *ngTemplateOutlet="noAddressPickedTemplate">
      </ng-container>

      <tidy-error-message *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-row>
        <tidy-button
          [text]="'Add Client'"
          data-cy="btn-addClient"
          [action]="addPrivateClient.bind(this)"
          class="primary">
        </tidy-button>
      </tidy-row>

      <tidy-alert>
        <tidy-row [align]="'center'" class="vertical-align-center extra-bottom-padding">
          <tidy-image
            [src]="'assets/style-guide-images/private-client-black-background.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [header]="'Private Client Guarantee'">
          </tidy-text>
        </tidy-row>

        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'Your data will be kept private and your Clients will remain your Clients. '">
          </tidy-text>
          <tidy-text
            [body]="'Learn More'"
            (action)="learnMore()"
            class="link">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

    </ng-container>

    <ng-container *ngIf="!loaded">

      <tidy-card>
        <tidy-row class="extra-bottom-padding">
        </tidy-row>
        <tidy-row class="field-top-padding field-bottom-padding">
          <ion-skeleton-text animated style="width: 140px; margin-bottom: 5px"></ion-skeleton-text>
          <tidy-divider></tidy-divider>
        </tidy-row>
        <tidy-row class="field-top-padding field-bottom-padding">
          <ion-skeleton-text animated style="width: 140px; margin-bottom: 5px"></ion-skeleton-text>
          <tidy-divider></tidy-divider>
        </tidy-row>
        <tidy-row class="field-top-padding field-bottom-padding">
          <ion-skeleton-text animated style="width: 140px; margin-bottom: 5px"></ion-skeleton-text>
          <tidy-divider></tidy-divider>
        </tidy-row>
        <tidy-row class="field-top-padding field-bottom-padding">
          <ion-skeleton-text animated style="width: 140px; margin-bottom: 5px"></ion-skeleton-text>
          <tidy-divider></tidy-divider>
        </tidy-row>
        <tidy-row class="field-top-padding field-bottom-padding">
          <ion-skeleton-text animated style="width: 140px; margin-bottom: 5px"></ion-skeleton-text>
          <tidy-divider></tidy-divider>
        </tidy-row>
        <tidy-row class="field-top-padding field-bottom-padding">
          <ion-skeleton-text animated style="width: 140px; margin-bottom: 5px"></ion-skeleton-text>
          <tidy-divider></tidy-divider>
        </tidy-row>
        <tidy-row class="field-top-padding">
          <ion-skeleton-text animated style="width: 140px; margin-bottom: 5px"></ion-skeleton-text>
          <tidy-divider></tidy-divider>
        </tidy-row>
      </tidy-card>

      <tidy-row>
        <ion-skeleton-text animated style="height: 70px"></ion-skeleton-text>
      </tidy-row>

      <tidy-alert>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 220px; height: 20px; margin: auto"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-top-padding">
          <ion-skeleton-text animated style="width: 100%; margin: auto"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 230px; margin: auto"></ion-skeleton-text>
        </tidy-row>
      </tidy-alert>

    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
