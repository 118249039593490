<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Dashboard'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper *ngIf="loaded" [hasBottomNav]="true">
    <tidy-error-message *ngIf="errorMessage" [text]="errorMessage"></tidy-error-message>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="isLimitedAccount">
      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Please contact your team leader for questions regarding jobs or payments.'">
        </tidy-text>
      </tidy-row>
      <tidy-card>
        <tidy-card-button class="arrow-to-top" (action)="goToCleaningsPage()">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/calendar.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Next Job'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              *ngIf="dashboardData?.nextJob !== ''"
              [body]="dashboardData?.nextJob">
            </tidy-text>
            <tidy-text
              *ngIf="dashboardData?.nextJob === ''"
              [body]="'None Scheduled'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>
      <tidy-row>
        <tidy-text
          [header]="'Requests'">
        </tidy-text>
      </tidy-row>
      <tidy-alert *ngIf="invitationsReceived?.length == 0 && delegationRequests?.length == 0">
        <tidy-text
          [body]="'No requests'">
        </tidy-text>
      </tidy-alert>
      <tidy-card *ngFor="let delegation of delegationRequests">
        <tidy-card-button (action)="goToDelegationRequest(delegation)">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/alert-circle.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="delegation.delegated_by_team_member.homekeeper.name.substr(0,delegation.delegated_by_team_member.homekeeper.name.indexOf(' '))">
            </tidy-text>
            <tidy-text
              [header]="' asked you to accept a job'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Any questions? Contact '">
            </tidy-text>
            <tidy-text
              [body]="delegation.delegated_by_team_member.homekeeper.name.substr(0,delegation.delegated_by_team_member.homekeeper.name.indexOf(' '))"
              [translate]="false">
            </tidy-text>
            <tidy-text
              [body]="'.'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>
    </ng-container>

    <tidy-alert *ngFor="let invite of invitationsReceived">
      <ng-container *ngIf="invite?.job_delegation_request">
        <tidy-row class="vertical-align-center extra-bottom-padding">
          <tidy-image
            [src]="'assets/svg/alert-circle.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Job Delegation Request'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/calendar-outline.svg'">
          </tidy-image>
          <tidy-text
            [body]="invite?.job_delegation_request?.job?.friendly_date">
          </tidy-text>
          <tidy-text
            [body]="' at '">
          </tidy-text>
          <tidy-text
            [body]="invite?.job_delegation_request?.job?.friendly_start_time">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center extra-bottom-padding">
         <tidy-image
            [src]="'assets/svg/time-outline.svg'">
          </tidy-image>
          <tidy-text
            [body]="invite?.job_delegation_request?.job?.duration_in_hours">
          </tidy-text>
          <tidy-text
            [body]="' hour job'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="invite?.job_delegation_request?.delegator_name"
            [translate]="false">
          </tidy-text>
          <tidy-text
            [body]="' invited you to join their team and accept this job.
            If you accept, you will be expected to complete the job.
            Contact '">
          </tidy-text>
          <tidy-text
            [body]="invite?.job_delegation_request?.delegator_name"
            [translate]="false">
          </tidy-text>
          <tidy-text
            [body]="' for any questions.'">
          </tidy-text>
        </tidy-row>
      </ng-container>
      <ng-container *ngIf="!invite?.job_delegation_request">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/people.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Team Invite Pending'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="invite?.team?.owner?.name"
            [translate]="false">
          </tidy-text>
          <tidy-text
            [body]="' invited you to their team. By joining, you agree that you are a subcontractor or employee of '">
          </tidy-text>
          <tidy-text
            [body]="invite?.team?.owner?.name">
          </tidy-text>
          <tidy-text
            [body]="'\'s business, and they are responsible for safety, legal, insurance, and employment obligations as well as the '">
          </tidy-text>
          <tidy-text
            [body]="'Service Provider Customer Agreement'"
            (action)="goToServiceProviderAgreement()"
            class="link">
          </tidy-text>
          <tidy-text
            [body]="'.'">
          </tidy-text>
        </tidy-row>
      </ng-container>
      <tidy-row class="extra-bottom-padding">
        <tidy-button
          [action]="acceptTeamInvite.bind(this, invite)"
          [text]="invite?.job_delegation_request ? 'Accept Job & Join Team' : 'Accept Invite'"
          class="primary">
        </tidy-button>
      </tidy-row>
      <tidy-row [align]="'center'">
        <tidy-text
          (action)="rejectTeamInvite(invite)"
          [body]="invite?.job_delegation_request ? 'Reject Job & Team Invite' : 'Reject Invite'"
          class="link">
        </tidy-text>
      </tidy-row>
    </tidy-alert>

    <ng-container *ngFor="let alert of alerts">
      <tidy-alert>
        <tidy-row class="vertical-align-center">
          <tidy-text
            class="red"
            [header]="alert?.title">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            style="margin-right: 5px"
            [body]="alert?.body">
          </tidy-text>
          <tidy-text
            (action)="goToAlertPage(alert?.link)"
            [body]="alert?.link_text || 'More'"
            class="link">
          </tidy-text>
        </tidy-row>
      </tidy-alert>
    </ng-container>

    <tidy-card *ngIf="certifications?.all_steps_except_go_live">
      <tidy-row class="vertical-align-center">
        <tidy-image
          [src]="'assets/svg/checkmark-circle.svg'"
          class="header-size">
        </tidy-image>
        <tidy-text
          [header]="'You\'re Ready for Clients!'"
          class="green">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'You\’re ready to get Client from TIDY!'">
        </tidy-text>
      </tidy-row>
      <tidy-button
        [text]="'Turn on Clients from TIDY'"
        [action]="goLive.bind(this)"
        class="primary">
      </tidy-button>
    </tidy-card>

    <tidy-alert *ngIf="hkCertificationStatus === 'inactive'">
      <tidy-row class="vertical-align-center">
        <tidy-image
          class="red"
          [src]="'assets/icon/alert-circle-red.svg'"
          class="header-size">
        </tidy-image>
        <tidy-text
          class="red"
          [header]="'Certification No Longer Valid'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'You are unable to get Clients from TIDY.  Tap here to re-request Certification.'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          (action)="goToReapplyPage()"
          [body]="'Request Certification'"
          class="link">
        </tidy-text>
      </tidy-row>
    </tidy-alert>

    <tidy-alert *ngIf="hkNotAvailableRepeat && hkCertificationStatus !== 'inactive'">
      <tidy-row class="vertical-align-center">
        <tidy-image
          [src]="'assets/icon/alert-circle-red.svg'"
          class="red header-size">
        </tidy-image>
        <tidy-text
          class="red"
          [header]="'Not Available for Repeat Clients'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'Open up recurring availability if you want repeat Clients. '">
        </tidy-text>
        <tidy-text
          (action)="goToMySchedule()"
          [body]="'View My Availability'"
          class="link">
        </tidy-text>
      </tidy-row>
    </tidy-alert>

    <tidy-card *ngIf="!certifications.existing_clients && didFinishPaymentOnboarding">
      <tidy-card-button (action)="goToUnpaidJobsPage()">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/documents.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Invoices'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text *ngIf="!unpaidJobs" animated style="width: 50%"></ion-skeleton-text>
          <tidy-text *ngIf="unpaidJobs !== undefined"
            [body]="unpaidJobs?.length">
          </tidy-text>
          <tidy-text *ngIf="unpaidJobs !== undefined"
            [body]="' Unpaid Jobs'"
            class="margin-right-5">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

    <tidy-two-column-row *ngIf="certifications?.existing_clients">
      <tidy-card item-left data-cy="card-next-pay">
        <tidy-card-button class="arrow-to-top" (action)="goToEstimatedNextPaymentPage(dashboardData?.nextPay?.amount)">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/card.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Balance'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="vertical-align-center">
            <tidy-text
              *ngIf="dashboardData?.nextPay?.amount === '$0'"
              [body]="'$0 '">
            </tidy-text>
            <tidy-text
              class="link"
              *ngIf="dashboardData?.nextPay?.amount === '$0'"
              [body]="'Start Earning'"
              (action)="goToEstimatedNextPaymentPage(dashboardData?.nextPay?.amount)">
            </tidy-text>
            <tidy-text
              *ngIf="dashboardData?.nextPay?.amount !== '$0'"
              [body]="'~' + dashboardData?.nextPay?.formatedNextPay">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>
      <tidy-card item-right data-cy="card-next-job">
        <tidy-card-button class="arrow-to-top" (action)="goToCleaningsPage()">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/calendar.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Next Job'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              *ngIf="dashboardData?.nextJob !== ''"
              [body]="dashboardData?.nextJob">
            </tidy-text>
            <tidy-text
              *ngIf="dashboardData?.nextJob === ''"
              [body]="'None Scheduled'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>
    </tidy-two-column-row>

    <tidy-two-column-row *ngIf="certifications?.steps?.profile === 'complete'">
      <tidy-card item-left>
        <tidy-card-button (action)="goToQuestsPage()">
          <tidy-row class="vertical-align-center">
            <tidy-image
              src="assets/img/quest-mountain-fill.svg"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Quests'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="availableQuestCount">
            </tidy-text>
            <tidy-text
              [body]="' Available'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>
      <tidy-card data-cy="card-job-opportunities" item-right>
        <tidy-card-button (action)="goToAddPage()">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/add-circle.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Add Jobs'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="dashboardData?.jobOpportunities">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>
    </tidy-two-column-row>

    <tidy-two-column-row *ngIf="certifications?.existing_clients">
      <tidy-card item-left>
        <tidy-card-button (action)="goToProposalsPage()">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/proposals-black.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [header]="'Proposals'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="pendingProposalsCount">
            </tidy-text>
            <tidy-text
              [body]="' Pending'"
              class="margin-right-5">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>
      <tidy-card item-right>
        <tidy-card-button (action)="goToUnpaidJobsPage()">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/documents.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Invoices'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <ion-skeleton-text *ngIf="!unpaidJobs" animated style="width: 50%"></ion-skeleton-text>
            <tidy-text *ngIf="unpaidJobs !== undefined"
              [body]="unpaidJobs?.length">
            </tidy-text>
            <tidy-text *ngIf="unpaidJobs !== undefined"
              [body]="' Unpaid Jobs'"
              class="margin-right-5">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>
    </tidy-two-column-row>

    <tidy-card data-cy="card-scores" *ngIf="certifications?.steps?.go_live === 'complete' && dashboardData?.qualityScore">
      <tidy-card-button class="arrow-to-top" (action)="goToFeedbackPage()">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/bar-chart.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Scores'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-grid class="vertical-align" >
            <ion-row>
              <ion-col size="5">
                <tidy-text
                  [body]="'Quality'">
                </tidy-text>
              </ion-col>
              <ion-col size="7">
                <tidy-bar
                  [value]="dashboardData?.qualityScore">
                </tidy-bar>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="5">
                <tidy-text
                  [body]="'Reliability'">
                </tidy-text>
              </ion-col>
              <ion-col size="7">
                <tidy-bar
                  [value]="dashboardData?.reliabilityScore">
                </tidy-bar>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="5">
                <tidy-text
                  [body]="'Bookability'">
                </tidy-text>
              </ion-col>
              <ion-col size="7">
                <tidy-bar
                  [value]="dashboardData?.bookabilityScore">
                </tidy-bar>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

    <ng-container *ngIf="!certifications?.existing_clients && !isLimitedAccount">
      <tidy-row>
        <tidy-text
          [header]="'Learn About TIDY'">
        </tidy-text>
      </tidy-row>

      <tidy-two-column-row>
        <tidy-card item-left>
          <tidy-row [align]="'center'">
	          <video width="100%" controls>
	            <source src="https://d30thx9uw6scot.cloudfront.net/video/Pro-Web/In%20App%20Videos/Manage%20Existing%20Clients%20HQ.mp4" type="video/mp4" />
	          </video><br>
            <tidy-text
              [body]="'Existing Clients'">
            </tidy-text>
          </tidy-row>
        </tidy-card>
        <tidy-card item-right>
          <tidy-row [align]="'center'">
	          <video width="100%" controls>
	            <source src="https://d30thx9uw6scot.cloudfront.net/video/Pro-Web/In%20App%20Videos/Getting%20New%20Clients%20LQ.mp4" type="video/mp4" />
	          </video><br>
            <tidy-text
              [body]="'New Clients'">
            </tidy-text>
          </tidy-row>
        </tidy-card>
      </tidy-two-column-row>
    </ng-container>

    <ng-container *ngIf="certifications?.steps?.go_live !== 'complete' && !isLimitedAccount">
      <tidy-row>
        <tidy-text
          [header]="'Get Started Now'">
        </tidy-text>
      </tidy-row>
      <tidy-card *ngIf="!certifications?.existing_clients">
        <tidy-card-button (action)="goToCertificationPage('existing-clients')">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/style-guide-images/private-client-black-background.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Manage Existing Clients'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Book jobs, track mileage & more!'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>
      <tidy-card>
        <tidy-card-button (action)="goToCertificationPage('new-clients')">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/people-circle-outline.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Get New Clients'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Clients in your area are looking for Pros!'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>
      <tidy-card *ngIf="!didFinishPaymentOnboarding">
        <tidy-card-button (action)="goToReceivePaymentsFromClientsPage()">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/cash.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Receive Payments from Clients'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'TIDY makes it easier to earn more.'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>
    </ng-container>

    <ng-container *ngIf="!isLimitedAccount">

      <tidy-row>
        <tidy-text
          [header]="'Updates'">
        </tidy-text>
      </tidy-row>

      <tidy-row *ngIf="updatesList?.length === 0">
        <tidy-text
          [helper]="'No updates right now.'">
        </tidy-text>
      </tidy-row>

      <tidy-error-message
        [text]="updateError">
      </tidy-error-message>

      <tidy-card *ngFor="let update of updatesList">
        <ng-container *ngIf="update.action_type === 'link_to_page'">
          <tidy-card-button (action)="goToUpdatePage(update)">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/svg/alert-circle.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="update?.title">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="update?.text">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </ng-container>
        <ng-container *ngIf="update.action_type === 'dismissable'">
          <tidy-card-button (action)="dismissUpdate(update.id)" [customIcon]="'assets/icon/close-circle-grey.svg'" class="arrow-to-top" [hideArrow]="true">
            <tidy-row class="vertical-align-center wrappable">
              <tidy-image
                [src]="'assets/svg/alert-circle.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="update?.title">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="update?.text">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </ng-container>
      </tidy-card>

    </ng-container>

  </tidy-wrapper>

  <tidy-wrapper *ngIf="!loaded">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-two-column-row>
      <tidy-card item-left>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
          </tidy-row>
          <tidy-row>
            <ion-skeleton-text animated style="width: 45%"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>
      <tidy-card item-right>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
          </tidy-row>
          <tidy-row>
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>
    </tidy-two-column-row>

    <tidy-card>
      <tidy-card-button [hideArrow]="true">
        <tidy-row>
          <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <tidy-grid class="vertical-align" >
            <ion-row>
              <ion-col size="5">
                <ion-skeleton-text animated style="width: 55%"></ion-skeleton-text>
              </ion-col>
              <ion-col size="7">
                <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="5">
                <ion-skeleton-text animated style="width: 65%"></ion-skeleton-text>
              </ion-col>
              <ion-col size="7">
                <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="5">
                <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
              </ion-col>
              <ion-col size="7">
                <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="5">
                <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
              </ion-col>
              <ion-col size="7">
                <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

    <tidy-card>
      <tidy-card-button [hideArrow]="true">
        <tidy-row>
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

<tidy-bottom-nav activeRoute="/dashboard">
</tidy-bottom-nav>
