import { Injectable, OnDestroy } from "@angular/core";
import { NavigationStart, Router } from '@angular/router';
import { LoadingStateService } from "@tidyapp/tidy-ui-components";
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { clearStack } from 'src/shared/components/loading/loading';

@Injectable({
  providedIn: 'root'
})
export class LoadingBarHandler implements OnDestroy{

  private routerSub: Subscription;
  constructor(
    private router: Router,
    private loadingState: LoadingStateService
  ) {
  }

  setup() {
    this.routerSub = this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    )
    .subscribe(() => {
      clearStack();
    });

    window.addEventListener('open-loading-overlay', () => this.loadingState.loading = true);
    window.addEventListener('close-loading-overlay', () => this.loadingState.loading = false);
  }

  ngOnDestroy() {
    this.routerSub.unsubscribe();
  }
}