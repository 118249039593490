import { Injectable } from '@angular/core';
import { HttpClient } from 'src/shared/providers/http/http-client';

@Injectable()
export class Unpause {

  constructor(
    private httpClient: HttpClient,
  ) {}

  async unpause(hkId) {
    const url: any = `api/v1/homekeeper/${hkId}/unpause`;
    await this.httpClient.put(url, {});
  }
}
