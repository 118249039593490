import { Injectable } from '@angular/core';
import { CleaningOportunities } from '../cleaning-opportunities/cleaning-opportunities';
import SafeUtils from '../../shared/utils/safe-utils';
import { Logger } from '../../shared/providers/logger';
import { Events } from 'src/providers/events/events';
import OneSignal from 'onesignal-cordova-plugin';
import { Auth } from '../auth/auth';
import { Me } from 'src/shared/providers/me';
import { AppConfig } from 'src/shared/providers/config';
import { Capacitor } from '@capacitor/core';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class OneSignalNotification {
  private envTag = AppConfig.LOGGLY_TAG.replace('bm', 'hk');

  constructor(
    private cleaningOportunities: CleaningOportunities,
    private events: Events,
    private logger: Logger,
    private auth: Auth,
    private me: Me,
  ) {

  }

  initOneSignal(): void {
    try {
      OneSignal.setAppId('f5ca96d7-60b8-4340-b5c5-a8df82d79f02');
      OneSignal.setNotificationOpenedHandler(data => {
        this.onNotificationOpened(data);
      });

      OneSignal.promptForPushNotificationsWithUserResponse(accepted => this.afterPromptAnswer(accepted));
      OneSignal.setLocationShared(false);
    } catch (err) {
      this.logger.error(err, 'onesignal-init');
    }
  }

  afterPromptAnswer(accepted) {
    try {
      OneSignal.sendTag('env', this.envTag);
      this.alreadyAuthenticatedInit();

      OneSignal.setNotificationWillShowInForegroundHandler(data => {
        this.onNotificationReceived(data);
      });

    } catch (err) {
      this.logger.error(err, 'onesignal-after-prompt');
    }
  }

  private async alreadyAuthenticatedInit() {
    try {
      const meData = await this.me.load();
      this.setUserIdentification(meData.user.id, meData.profile.email);
    } catch (err) {
      // NOT AUTHENTICATED
    }
  }

  onNotificationReceived(data) {
    const notificationId = SafeUtils.get(data, ['payload', 'notificationID']);

    if (notificationId) {
      this.cleaningOportunities.changeStatus('received', notificationId);
    }
  }

  async onNotificationOpened(data) {
    const body = SafeUtils.get(data, ['notification', 'payload', 'body']);
    const title = SafeUtils.get(data, ['notification', 'payload', 'title']);
    const id = SafeUtils.get(data, ['notification', 'payload', 'notificationID']);

    this.events.publish('cleaning-opportunity-notification:open', { body, title });
    if (id) {
      await this.cleaningOportunities.changeStatus('opened', id);
    }
  }

  setUserIdentification(userId, userEmail, retry = 0): void {
    try {
      OneSignal.setExternalUserId(`${userId}`);
      OneSignal.sendTag('email', userEmail);
      OneSignal.setEmail(userEmail);
    } catch (err) {
      const tenMinutes = 600000;
      if (retry < 10) {
        setTimeout(() => {
          this.setUserIdentification(userId, userEmail, (retry + 1));
        }, tenMinutes);
      }

      err['platform'] = Capacitor.getPlatform();
      this.logger.error(err, 'onesignal-set-user-identification');
    }
  }
}
