import { DashboardData } from './dashboard-data';
import { TidyCurrencyPipe } from 'src/shared/pipes/tidy-currency';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

export function parseDashboardData(highlights, estimatedNextPayment, exampleCoops): DashboardData {

  let jobCount;
  let showJobCard;
  if (exampleCoops.length > 0) {
    jobCount = exampleCoops.length;
    showJobCard = (jobCount >= 7);
  } else {
    jobCount = highlights.active_coop_count;
    showJobCard = jobCount > 0;
  }
  const multipleJobs = jobCount !== 1 ? new TranslationPipe().transform('Opportunities') : new TranslationPipe().transform('Opportunity');


  const totalAmount = estimatedNextPayment?.amount ? estimatedNextPayment.amount : 0;
  const amount = new TidyCurrencyPipe().transform(totalAmount);

  return {
    nextPay: {
      amount,
      formatedNextPay: `${estimatedNextPayment?.estimated_arrival}: ${amount}`
    },
    nextJob: highlights?.next_cleaning?.date_time ? `${highlights.next_cleaning.date_time}` : '',
    jobOpportunities: `${jobCount} ${multipleJobs}`,
    showJobCard,
    qualityScore: highlights?.dashboard_highlight_score ? highlights.dashboard_highlight_score.quality_score : null,
    reliabilityScore: highlights?.dashboard_highlight_score ? highlights.dashboard_highlight_score.reliability_score : null,
    bookabilityScore: highlights?.bookability_scores ? highlights.bookability_scores.bookability_score : null,
    happenedStreak: `${highlights.current_happened_streak} ` + new TranslationPipe().transform('jobs')
  };
}
