<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Waive Fee'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text body="Waive "></tidy-text>
      <tidy-text [body]="fee?.total_fees | tcurrency"></tidy-text>
      <tidy-text body=" in fees on "></tidy-text>
      <tidy-text [body]="(fee?.date | tdate:'M/D/YY':'YYYY-MM-DD')"></tidy-text>
      <tidy-text body="?  You can waive up to 3 fees every 90 days."></tidy-text>
    </tidy-row>

    <tidy-error-message [text]="errorMessage"></tidy-error-message>

    <tidy-two-column-row>
      <tidy-button
        [text]="'Go Back'"
        [action]="goBack.bind(this)"
        class="primary">
      </tidy-button>
      <tidy-button
        [text]="'Confirm'"
        [action]="confirm.bind(this)"
        class="secondary">
      </tidy-button>
    </tidy-two-column-row>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
