import { Injectable } from '@angular/core';
import { HttpClient } from '../../shared/providers/http/http-client';
import { TidyStorage } from '../../shared/providers/tidy-storage';

import * as moment from 'moment';

@Injectable()
export class Cleanings {

  constructor(
    private httpClient: HttpClient,
    private storage: TidyStorage
  ) {}

  async changeSchedule(firstDayOfWeek): Promise<any> {
    const url = `api/v1/homekeepers/${await this.hkId()}/schedules?start_date=${firstDayOfWeek}`;
    return this.httpClient.get(url);
  }

  async weeksDropdown() {
    let response = await this.storage.retrieve('weeksDropdown');
    if (response) {
      const responseCurrentWeek = response.find(week => week.name === 'This Week');
      const today = new Date();
      const responseEndDate = new Date(`${responseCurrentWeek.end_date} 23:59:59`);
      if(responseEndDate >= today){
        return response;
      }
    }
    const url = 'api/v1/homekeeper/timeline/weeks';
    response = await this.httpClient.get(url);
    await this.storage.save('weeksDropdown', response);
    setTimeout(() => this.storage.delete('weeksDropdown'), 1200000);
    return response;
  }

  fetch(startDate, endDate): Promise<any> {
    const url = `api/v1/homekeeper/timeline?start_date=${startDate}&end_date=${endDate}`;
    return this.httpClient.get(url);
  }

  getCalendarJobCounts(startDate, endDate) {
    const url = `api/v1/homekeeper/timeline/calendar-counts?start_date=${startDate}&end_date=${endDate}`;
    return this.httpClient.get(url);
  }

  getActiveStandbyCleanings(date): Promise<any> {
    const url = `api/v2/homekeeper/cleanings?date=${date}`;
    return this.httpClient.get(url);
  }

  async totals(startDate, endDate): Promise<any> {
    const url = `api/v1/homekeeper/timeline/total-payments?start_date=${startDate}&end_date=${endDate}`;
    const totalsResponse = await this.httpClient.get(url);

    return this.processTotals(totalsResponse, startDate);
  }

  history(jobId) {
    const url = `api/v1/homekeeper/cleanings/${jobId}/moments?per_page=500`;
    return this.httpClient.get(url);
  }

  previewCancellations(zipcode: string): Promise<any> {
    const url = `api/v1/homekeeper/cleanings/preview-cancellations/serviceable-area-changes?zipcode=${zipcode}`;

    return this.httpClient.get(url);
  }

  previewCancellationsDrivableDistance(maxDrivableDistance) {
    const url = `api/v1/homekeeper/cleanings/preview-cancellations/serviceable-area-changes?max_driveable_distance=${maxDrivableDistance}`;
    return this.httpClient.get(url);
  }

  previewCancellationsZipcodeAndDrivableDistance(zipcode: string, maxDrivableDistance) {
    const url = `api/v1/homekeeper/cleanings/preview-cancellations/serviceable-area-changes?max_driveable_distance=${maxDrivableDistance}&zipcode=${zipcode}`;
    return this.httpClient.get(url);
  }

  fetchCleaning(jobId: number): Promise<any> {
    const url = `api/v1/homekeeper/timeline/cards/cleaning/${jobId}`;
    return this.httpClient.get(url);
  }

  fetchDelegations(): Promise<any> {
    const url = 'api/v1/homekeeper/job-delegation-requests';
    return this.httpClient.get(url);
  }

  fetchPaymentHistory(homekeeperJobId: number): Promise<any> {
    const url = `api/v1/homekeeper/payments/homekeeper-jobs/${homekeeperJobId}/earnings-breakdown`;
    return this.httpClient.get(url);
  }

  private async processTotals(totalsResponse, startDate): Promise<any> {
    const updatedEstArrival = moment(new Date('12/03/2017'));
    let acceleratedAmountArrival: string;
    let normalAmountArrival: string;

    if (moment(startDate).isAfter(updatedEstArrival)) {
      acceleratedAmountArrival = `${this.formatAddedDays(startDate, 10)} - ${this.formatAddedDays(startDate, 11)}`;
      normalAmountArrival = `${this.formatAddedDays(startDate, 17)} - ${this.formatAddedDays(startDate, 18)}`;
    } else {
      acceleratedAmountArrival = this.formatAddedDays(startDate, 9);
      normalAmountArrival = this.formatAddedDays(startDate, 16);
    }

    totalsResponse.normalAmount = totalsResponse.unconfirmed - totalsResponse.confirmed;
    totalsResponse.acceleratedAmountArrival = acceleratedAmountArrival;
    totalsResponse.normalAmountArrival = normalAmountArrival;

    return totalsResponse;
  }

  private formatAddedDays(startDate, amount) {
    return moment(startDate).add(amount, 'day').format('MM/DD')
  }

  private async hkId() {
    return await this.storage.retrieve('hk_id');
  }

  uploadPastPhoto(payload) {
    const url = 'api/v1/homekeeper/before-after-photos';
    return this.httpClient.post(url, payload);
  }

  getBeforeAfter(state: 'photo' | 'before_photo_only' | 'after_photo_only' | 'video' | 'before_video_only' | 'after_video_only') {
    return state;
  }
}
