import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Team } from 'src/providers/team/team';
import validationUtils from 'src/shared/utils/validation-utils';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

import { SuccessPageParamsModel } from 'src/pages/success/success';

@Component({
  selector: 'website-url',
  templateUrl: 'website-url.html',
})

export class WebsiteUrlPage {

  errorMessage: string;
  formErrorMessage: string;
  form: UntypedFormGroup;
  submitted: boolean;

  constructor(
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private team: Team,
  ) {
    this.form = this.fb.group({
      url: [null, [Validators.required, validationUtils.forbiddenNameValidator(/tidy/i)]],
    });
  }

  async ionViewDidEnter() {
    const params = await this.customNavCtrl.getParams();
    this.form.controls.url.setValue(params.url);
  }

  save = async () =>  {
    this.errorMessage = '';
    this.submitted = true;

    const data = {
      url_suffix: this.form.value.url
    };

    if (!this.form.valid) {
      if(data.url_suffix.toLowerCase().includes('tidy')){
        this.formErrorMessage = 'You can\'t use any variation of the term \"tidy\" in your team or website url.';
      }
      else{
        this.formErrorMessage = 'Please enter the URL.';
      }
      return;
    }

    try {
      await this.team.updateMyTeamUrl(data);
      const params = this.mountSuccessPageParams();
      this.customNavCtrl.navigateForward('success', params);
    } catch (err) {
      this.errorMessage = err.error ? err.message : err.error.message;
    }
  }

  mountSuccessPageParams(): SuccessPageParamsModel {
    return {
      header: `URL Updated`,
      body: `${new TranslationPipe().transform('You can now access your website at')} www.tidy.com/${this.form.value.url}`,
      buttonText: `Ok`,
      buttonRoute: `website`
    };
  }

}
