import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { MWStore } from "src/main-workflow/mw.store";
import { MWService } from "src/main-workflow/mw.service";
import { MWJobModel } from 'src/main-workflow/mw.models';
import { mwMoments } from 'src/main-workflow/mw.moments';
import { PhotosCache } from 'src/shared/providers/tidy-photos-cache';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm';

import { Loading } from 'src/shared/components/loading/loading';
import { CustomFieldsService } from 'src/shared/providers/custom-fields/custom-fields';

@Component({
  templateUrl: 'mw.arrived-at-job.page.html'
})

export class ArrivedAtJobPage {

  job: MWJobModel;
  photosCacheData: any;
  loaded: boolean;
  previousGuestReservationDetails: any;
  nextGuestReservationDetails: any;
  customFields: any;

  constructor(
    private modalCtrl: ModalController,
    private mwStore: MWStore,
    public mwService: MWService,
    private navCtrl: CustomNavController,
    private photosCache: PhotosCache,
    private customFieldsService: CustomFieldsService
  ) {}

  async ionViewDidEnter() {
    this.loaded = false;
    this.job = await this.mwStore.getJob();
    await this.getCustomFields();
    if (this.job?.guest_reservation_data?.previous_guest_reservation?.details) {
      this.previousGuestReservationDetails = {
        ...this.job?.guest_reservation_data?.previous_guest_reservation?.details,
        customFields: this.customFieldsService.getCustomFieldsForDetails(this.customFields, this.job?.guest_reservation_data?.previous_guest_reservation?.custom_fields)
      }
    }
    if (this.job?.guest_reservation_data?.next_guest_reservation?.details) {
      this.nextGuestReservationDetails = {
        ...this.job?.guest_reservation_data?.next_guest_reservation?.details,
        customFields: this.customFieldsService.getCustomFieldsForDetails(this.customFields, this.job?.guest_reservation_data?.next_guest_reservation?.custom_fields)
      }
    }
    this.photosCacheData = await this.photosCache.retrieveAll();
    this.loaded = true;
  }

  async getCustomFields() {
    try {
      this.customFields = await this.customFieldsService.getCustomFields(this.job?.guest_reservation_data?.booking?.id, 'GuestReservation');
    } catch (err) {
      console.error(err);
    }
  }

  @Loading()
  async notifyStartedJob() {
    await this.addSyncDataToStore();
    const moment = await this.mwService.isFarFromHome() ? mwMoments.startedJobFarFromHome : mwMoments.startedJob;
    this.mwService.addMomentToSyncData(moment, 'arrived-at-job', this.job.id);
    await this.mwStore.setRoute('to-dos');
    this.navCtrl.navigateForward('to-dos');
  }

  async addSyncDataToStore() {
    const eligibleForPlan = await this.mwStore.getEligibleForPlan();
    const initialSyncData = {
      shouldSync: false,
      data: {
        moments: [],
        todos: [],
        beforePhotos: [],
        afterPhotos: [],
        leftEarly: false,
        locationPicture: null,
        jobRating: null,
        didToDos: null,
        upgradePlan: null
      },
      job: {
        jobId: this.job.id,
        planId: eligibleForPlan.plan_id,
        showUpgradePlanCard: eligibleForPlan.show_opportunity,
        jobEndTime: this.job.endTime,
        isRepeatClient: this.job.isRepeatClient
      }
    };
    await this.mwStore.setSyncData(initialSyncData);
  }

  async initiateCantAccess() {
    const params: ConfirmPageParamsModel = {
      title: 'Can\'t Access Home?',
      body: `Notify the client that you are having trouble accessing their home?`,
      backText: 'Go Back',
      confirmText: 'Notify Client',
      confirmAction: this.goToCantAccessHomePage.bind(this)
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });
    confirmationModal.present();
  }

  @Loading()
  async goToCantAccessHomePage() {
    try {
      this.mwService.sendMomentToBackend(mwMoments.initiatedCantAccess, 'arrived-at-job', this.job.id);
      await this.mwStore.setRoute('cant-access');
      this.navCtrl.navigateForward('cant-access');
      this.modalCtrl.dismiss();
    } catch (err) {
      this.mwService.showErrorPage(err);
      this.modalCtrl.dismiss();
    }
  }

  goToContactPage() {
    this.navCtrl.navigateForward('job-messages');
  }

  goToExitJobPage() {
    this.navCtrl.navigateForward('exit-job');
  }

  goToCallOutPage() {
    this.navCtrl.navigateForward('cancel-job');
  }

  goToClientRefusedServicePage() {
    this.navCtrl.navigateForward('client-refused-service');
  }
}
