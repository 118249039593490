import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { ActivatedRoute } from '@angular/router';
import { PrivateJobModel } from 'src/shared/models/private-job.model';
import { PrivateClient } from 'src/providers/private-client/private-client';
import { PrivateJobService } from 'src/providers/private-job/private-job';

@Component({
  selector: 'app-private-change-status',
  templateUrl: './private-change-status.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivateChangeStatusPage {
  clientName: string;
  jobId: string;
  jobData: PrivateJobModel;
  jobStatus: string;
  confirmButtonText: string;
  errorMessage: string;

  constructor(
    private modalCtrl: ModalController,
    private customNavCtrl: CustomNavController,
    private route: ActivatedRoute,
    private privateClient: PrivateClient,
    private privateJobService: PrivateJobService
  ) {
    this.jobId = this.route.snapshot.paramMap.get('jobId');
    const params = this.customNavCtrl.getParams();

    this.clientName = params.clientName;
    this.jobData = params.jobData as PrivateJobModel;
    this.jobStatus = this.jobData.private_status;
    this.confirmButtonText = this.jobStatus.includes('cancelled')
      ? 'Mark Job as Complete' : 'Mark Job as Cancelled';
  }

  async confirm() {
    if (this.jobStatus.includes('cancelled')) {
      const modal = await this.buildConfirmationModal();

      modal.present();
    } else if (this.jobStatus === 'completed') {
      this.goToPrivateCancellation();
    }
  }

  async buildConfirmationModal() {
    const params: ConfirmPageParamsModel = {
      body: 'Are you sure you want to complete this job?',
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: this.confirmAction.bind(this),
      title: 'Complete Job',
    };

    const modal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });

    return modal;
  }

  async confirmAction() {
    await this.privateJobService.completePrivateJob(this.jobId, {});
    this.customNavCtrl.navigateRoot(['private-job', this.jobId]);
    this.modalCtrl.dismiss();
  }

  goToPrivateCancellation() {
    const params = {
      clientName: this.clientName,
      jobData: this.jobData
    };

    this.customNavCtrl.navigateForward(`private-cancellation/job/${this.jobId}`, params);
  }
}
