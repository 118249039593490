import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { MWService } from "src/main-workflow/mw.service";
import { MWStore } from "src/main-workflow/mw.store";
import { MWJobModel } from 'src/main-workflow/mw.models';
import { mwMoments } from 'src/main-workflow/mw.moments';

import { CompleteJobModal } from 'src/shared/components/complete-job-modal/complete-job-modal';

import { Loading } from 'src/shared/components/loading/loading';

@Component({
  templateUrl: 'mw.finish-job.page.html'
})

export class FinishJobPage {

  form: UntypedFormGroup;
  job: MWJobModel;
  loaded: boolean;
  rating = 0;
  stars = new Array(5);
  syncData: any;

  constructor(
    private fb: UntypedFormBuilder,
    private mwService: MWService,
    private mwStore: MWStore,
    private modalCtrl: ModalController,
  ) {
    this.form = this.fb.group({
      question: [''],
      plan: ['']
    });
  }

  async ionViewDidEnter() {
    this.loaded = false;
    this.job = await this.mwStore.getJob();
    this.syncData = await this.mwStore.getSyncData();
    this.loaded = true;
  }

  updateRating(rating) {
    this.rating = rating + 1;
  }

  @Loading()
  async finishJob() {
    await this.addToSyncData();
    await this.mwService.addMomentToSyncData(mwMoments.jobFinished, 'finish-job', this.job.id);
    const modal = await this.modalCtrl.create({
      component: CompleteJobModal
    });
    modal.present();
  }

  async addToSyncData() {
    this.syncData.shouldSync = true;
    this.syncData.data.didToDos = this.form.controls.question.value || null;
    this.syncData.data.jobRating = this.rating === 0 ? null : this.rating;
    this.syncData.data.upgradePlan = this.form.controls.plan.value === 'yes';
    await this.mwStore.setSyncData(this.syncData);
  }
}
