<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Contest Dispute'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'The Client said '">
      </tidy-text>
      <tidy-text
        [body]="navParams?.disputeTexts">
      </tidy-text>
      <tidy-text
        [body]="' and is disputing this cleaning.'">
      </tidy-text>
    </tidy-row>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'We are here to help you so please provide as much information as possible.'">
      </tidy-text>
    </tidy-row>

    <tidy-row [align]="'center'" class="extra-bottom-padding">
      <tidy-text
        [body]="'Do you have photos as evidence? '">
      </tidy-text>
      <tidy-text
        [body]="'Add Photo'"
        (action)="capturePhoto()">
      </tidy-text>
    </tidy-row>

    <tidy-row *ngIf="disputePhotos?.length > 0">
      <tidy-card>
        <tidy-carrousel>
          <ng-container *ngFor="let photo of disputePhotos">
            <tidy-image *tidyCarrousel [src]="photo.photo_url"></tidy-image>
          </ng-container>
        </tidy-carrousel>
      </tidy-card>
    </tidy-row>

    <form [formGroup]="form" novalidate action="#">
      <tidy-card>
        <tidy-row class="field-top-padding">
          <tidy-textarea
            formControlName="hkResponse"
            [label]="'Why are you contesting this dispute?'"
            [errorMessage]="'Please let us know why you are contesting.'"
            [submitted]="submitted"
            [form]="form"
            [icon]="'assets/svg/cash-outline.svg'"
            [activeIcon]="'assets/svg/cash-outline-tidy-green.svg'">
          </tidy-textarea>
        </tidy-row>
      </tidy-card>
    </form>

    <tidy-button item-right
      [text]="'Submit Evidence'"
      [action]="respondDispute.bind(this)"
      class="primary">
    </tidy-button>

    <tidy-error-message *ngIf="errorMessage" [text]="errorMessage">
    </tidy-error-message>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
