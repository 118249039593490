import { Component, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { AppConfig } from 'src/shared/providers/config';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Location } from 'src/shared/providers/location';

import { Me } from 'src/shared/providers/me';
import { HomekeeperModel } from 'src/shared/models/homekeeper.model';
import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';
import { Loading } from 'src/shared/components/loading/loading';

import { ProfileData, parseProfileData } from 'src/pages/profile/profile-data-parser';
import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';


declare const mapboxgl: any;

@Component({
  selector: 'location-info',
  templateUrl: 'location-info.html',
  encapsulation: ViewEncapsulation.None
})
export class ProfileLocationInfoPage extends Timeout {
  homeLongitude: any;
  homeLatitude: any;
  homekeeper: HomekeeperModel;
  profileData: ProfileData;
  isoData: any;
  blockedZipcodes: any;

  constructor(
    private me: Me,
    private iabUtils: InAppBrowserUtils,
    private customNavCtrl: CustomNavController,
    private modalController: ModalController,
    private location: Location,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
  }

  @Loading()
  async ionViewDidEnter() {
    try {
      this.loaded = false;
      this.loadProfile();
      this.blockedZipcodes = await this.me.getDisabledZipcodes();
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  goToProfileAddress = () => {
    this.customNavCtrl.navigateForward('profile/address');
  }

  goToProfileTravel = () => {
    const params = {
      isUpdatingMaxDriveTime: true
    }
    this.customNavCtrl.navigateForward('profile/travel-time', params);
  }

  async loadProfile(): Promise<any> {
    this.homekeeper = await this.me.fetchWithoutCache();
    this.profileData = parseProfileData(this.homekeeper);
    await this.getHkHomeData();
    await this.loadIsoData();
    // Call it on the next available frame after loading everything
    setTimeout(() => this.loadMap(), 0);
  }

  learnMoreReferral() {
    this.iabUtils.openUrl('https://help.tidy.com/pros/fees-for-clients-from-tidy#taking-clients-private');
  }

  async getHkHomeData() {
    const hk = await this.me.load();
    this.homeLongitude = hk.user.longitude;
    this.homeLatitude = hk.user.latitude;
  }

  loadMap() {
    mapboxgl.accessToken = AppConfig.MAPBOX_KEY;
    const map = new mapboxgl.Map({
      container: 'location-i-serve-map',
      style: 'mapbox://styles/mapbox/light-v10',
      center: [ this.homeLongitude, this.homeLatitude ],
      zoom: 7,
      maxZoom: 11,
      minZoom: 5
    });

    map.on('load', () => {
      map.addSource('iso', {
        type: 'geojson',
        data: {
          'type': 'FeatureCollection',
          'features': []
        }
      });

      map.addLayer({
        'id': 'isoLayer',
        'type': 'fill',
        'source': 'iso',
        'layout': {},
        'paint': {
          'fill-color': '#5a3fc0',
          'fill-opacity': 0.3
        }
      }, "poi-label");

      map.getSource('iso').setData(this.isoData);
    });
  }

  goToBlockZipcode(){
    this.customNavCtrl.navigateForward('profile/location/block-zipcode');
  }

   async confirmDeleteZipcode(zipcodeId, zipcode){
    const params: ConfirmPageParamsModel = {
      title: `${new TranslationPipe().transform('Allow Jobs in Zipcode')} ${zipcode}`,
      body: `${new TranslationPipe().transform('Are you sure you want to serve the zipcode')} ${zipcode}`,
      backText: 'Go Back',
      confirmText: `${new TranslationPipe().transform('Allow')} ${zipcode}`,
      confirmAction: this.deleteZipcode.bind(this, zipcodeId)
    };
    const confirmationModal = await this.modalController.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });
    confirmationModal.present();
  }

  async deleteZipcode(zipcode){
    await this.me.deleteDisableZipcode(zipcode);
    this.modalController.dismiss();
    window.location.reload();
  }

  async loadIsoData() {
    const profile = 'driving';
    const minutesDistance = this.homekeeper.profile.max_travel_time;
    this.isoData = await this.location.loadIsochroneMapbox(profile, this.homeLongitude, this.homeLatitude, minutesDistance);
  }

}
