import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Me } from 'src/shared/providers/me';
import { Logger } from 'src/shared/providers/logger';
import { AppState } from 'src/shared/providers/http/app-state';

import { HomekeeperModel } from 'src/shared/models/homekeeper.model';

@Component({
  selector: 'number-on-file',
  templateUrl: 'number-on-file.html',
  styleUrls: ['./number-on-file.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NumberOnFileComponent {

  phone: string;
  offlineErr = false;
  @Input() navCtrl: NavController;

  constructor(
    private me: Me,
    private appState: AppState,
    private logger: Logger
  ) {}
  ionViewDidEnter() {
    this.loadPhone();
  }

  async loadPhone() {
    try {
      const hkData: HomekeeperModel = await this.me.load();
      this.formatPhone(hkData.profile.phone_number);
    } catch (err) {
      this.logger.error(err, 'number on file error');
    }
  }

  formatPhone(phone) {
    const rawNumber = phone.replace(/\+1|[^0-9]/g, '');
    this.phone = `(${rawNumber.slice(0, 3)})${rawNumber.slice(3, 6)}-${rawNumber.slice(6)}`;
  }

  goToEditPhone() {
    if (this.appState.online) {
      this.navCtrl.navigateForward('ProfilePage');
    } else {
      this.offlineErr = true;
      setTimeout( () => this.offlineErr = false, 10000 );
    }
  }

}
