import { Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PrivatePayments } from 'src/providers/private-payments/private-payments';

import { Loading } from 'src/shared/components/loading/loading';

import { SuccessPageParamsModel } from 'src/pages/success/success';
import { removeCurrencyMask } from 'src/shared/utils/currency-utils';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: 'refund-charge.html',
  encapsulation: ViewEncapsulation.None
})

export class RefundChargePage {

  clientName: string;
  errorMessage: string;
  form: UntypedFormGroup;
  loaded: boolean;
  refundable: any;
  submitted: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private privatePayments: PrivatePayments
  ) {
    this.form = this.fb.group({
      amount: ['', Validators.required],
      reason: ['', Validators.required]
    });
  }

  @Loading()
  async ionViewDidEnter() {
    this.loaded = false;
    this.refundable = this.navCtrl.getParam('refundable');
    this.clientName = this.navCtrl.getParam('clientName');
    this.loaded = true;
  }

  async refundCharge() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    const amountToRefund = removeCurrencyMask(this.form.value.amount);
    if (this.refundable.amount_to_refund < amountToRefund) {
      return this.errorMessage = `${new TranslationPipe().transform('You can only refund up to')} $${this.refundable.amount_to_refund / 100}`;
    }
    try {
      const payload = {
        customer_id: this.navCtrl.getParam('clientId'),
        job_id: this.refundable.cleaning_id,
        amount_to_refund: amountToRefund,
        currency: 'usd',
        refund_reason: this.form.value.reason
      }
      await this.privatePayments.refundCharge(payload, this.refundable.charge_token);
      const successParams: SuccessPageParamsModel = {
        header: 'Charge Refunded',
        body: '',
        buttonText: 'Ok',
        buttonRoute: `private-job/${this.refundable.cleaning_id}`
      };
      this.navCtrl.navigateForward('success', successParams);
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }
}
