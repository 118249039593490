import { Injectable } from '@angular/core';
import { HttpClient } from '../../shared/providers/http/http-client';

@Injectable()
export class BankInfo {

  constructor(
    private httpClient: HttpClient
  ) {}

  async getStripeStatus(): Promise<any> {
    const url = 'api/v1/homekeeper/payments/accounts';
    return await this.httpClient.get(url);
  }

  async getStripeLink() {
    const url = 'api/v1/homekeeper/payments/accounts/connect-onboard';
    return await this.httpClient.get(url);
  }

  async saveBankAccount(routing, account, hasBank) {
    const data = {
      routing_number: routing,
      account_number: account
    };
    if (hasBank) {
      return await this.updateBankAccount(data);
    } else {
      return await this.newBankAccount(data);
    }
  }

  newBankAccount(data) {
    const url = 'api/v2/homekeeper/payments/accounts';
    return this.httpClient.post(url, data);
  }

  updateBankAccount(data) {
    const url = 'api/v1/homekeeper/payments/accounts';
    return this.httpClient.put(url, data);
  }

  paymentHistory(showAllPayments) {
    let url = 'api/v1/homekeeper/payments/history';
    url += showAllPayments ? '?filter=500' : '';

    return this.httpClient.get(url);
  }

  paymentDetails(transferId) {
    const url = `api/v1/homekeeper/payments/${transferId}/details`;
    return this.httpClient.getNoCache(url);
  }

  getPayoutSettings() {
    let url = `api/v1/homekeeper/homekeeper-payment-settings?keys=payout_setting`;
    return this.httpClient.get(url);
  }

  getPaymentSettings() {
    let url = `api/v1/homekeeper/homekeeper-payment-settings?keys=payment_setting`;
    return this.httpClient.get(url);
  }

  updatePayoutSetting(payload) {
    const url = 'api/v1/homekeeper/homekeeper-payment-settings';
    return this.httpClient.post(url, payload);
  }

}
