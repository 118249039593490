import { Injectable } from '@angular/core';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Logger } from 'src/shared/providers/logger';

import GetImageUtil from 'src/shared/utils/image-fetch-util';
import arrayUtils from '../utils/array-utils';
import { PhotoNoteModel } from '@tidyapp/tidy-ui-components';

@Injectable({
  providedIn: 'root'
})
export class PhotosCache {

  constructor(
    private storage: TidyStorage,
    private logger: Logger
  ) {}

  async save(photosData) {
    const photoNotes = await this.retrieveAll() || {};
    return this.storage.save('photo_notes_cache', {...photoNotes, ...photosData});
  }

  retrieveAll(): Promise<any> {
    return this.storage.retrieve('photo_notes_cache');
  }

  async clear(): Promise<void> {
    await this.storage.save('photo_notes_cache', {});
  }

  async loadPhotoNotes(photosMetaData: Array<PhotoNoteModel>) {
    try {
      const photosArray = await Promise.all(
        photosMetaData.map(async photoNote => {
          const dataUrl = await GetImageUtil.loadImg(photoNote.photo_url);
          const key = photoNote?.addressable_id ? `${photoNote?.addressable_id}${photoNote.id}` : photoNote.id;
          return {[key]: dataUrl};
        })
      );
      const photosObj = arrayUtils.arrayOfObjToObject(photosArray);
      return photosObj;
    } catch (err) {
      this.logger.error({...err, ...photosMetaData}, 'address-photos-fetch');
      return [];
    }
  }

}
