import { Component, ViewEncapsulation } from '@angular/core';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { FeeWaivers } from 'src/providers/fee-waivers/fee-waivers';
import { SuccessPageParamsModel } from 'src/pages/success/success';

@Component({
  selector: 'confirm-fee-waive',
  templateUrl: 'confirm-fee-waive.html',
  encapsulation: ViewEncapsulation.None
})
export class ConfirmFeeWaivePage {

  fee: any;
  errorMessage: string;

  constructor(
    private customNavCtrl: CustomNavController,
    private feeWaivers: FeeWaivers
  ) {
  }

  ionViewDidEnter() {
    const params = this.customNavCtrl.getParams();
    this.fee = params.fee;
  }

  confirm = async () => {
    try {
      await this.feeWaivers.request(this.fee.date);
      const successParams: SuccessPageParamsModel = this.successParams();
      this.customNavCtrl.navigateForward('success', successParams);
    } catch (err) {
      this.errorMessage = err.message;
    }
  }

  goBack = () => {
    this.customNavCtrl.back();
  }

  successParams(): SuccessPageParamsModel {
    return {
      header: 'Fee waived',
      body: 'You can waive up to 3 fees every 90 days.',
      buttonText: 'Go to Dashboard',
      buttonRoute: 'dashboard'
    };
  }
}
