<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Add Service'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form" novalidate action="#">
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

        <tidy-card>
          <tidy-row class="field-bottom-padding vertical-align-center">
            <tidy-image
              [src]="getServiceIcon()"
               class="title-size">
             </tidy-image>
            <tidy-text
              [header]="getServiceName()">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-select
              [icon]="'assets/svg/information-circle-outline.svg'"
              [label]="'Service Type'"
              [form]="form.controls.category"
              formControlName="category"
              [items]="categoryItems"
              [submitted]="submitted"
              [errorMessage]="'Please select a service category.'">
            </tidy-select>
           </tidy-row>
           <tidy-row [align]="'center'" class="field-bottom-padding">
             <tidy-text
               [helper]="'Don\'t see the type? '">
             </tidy-text>
             <tidy-text
               [helper]="'Request Type'"
               (action)="goToRequestNewServiceType()"
               class="link">
             </tidy-text>
           </tidy-row>
          <tidy-row class="field-bottom-padding">
            <tidy-input
              [label]="'Service Name'"
              [icon]="'assets/svg/information-circle-outline.svg'"
              formControlName="serviceName"
              [form]="form.controls.serviceName"
              [submitted]="submitted"
              [errorMessage]="'Please enter a service name.'">
            </tidy-input>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-textarea
              label="Service Description"
              icon="assets/svg/chatbox-ellipses-outline.svg"
              formControlName="description"
              [form]="form.controls.description"
              [submitted]="submitted"
              [errorMessage]="'Please enter a service description.'">
            </tidy-textarea>
          </tidy-row>
           <tidy-row>
             <tidy-checkbox
                [(checked)]="allowOnWebsite"
                [initValue]="allowOnWebsite"
                [items]="[
                  {
                    viewValue: 'Allow Clients to book on my website', value: true
                  }
                ]"
                [value]="allowOnWebsite">
              </tidy-checkbox>
            </tidy-row>
      </tidy-card>

      <tidy-card>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [header]="'Billing Type'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="field-top-padding" [ngClass]="form.value.billingType === '' ? '' : 'field-bottom-padding'">
            <tidy-select
              [icon]="'assets/svg/information-circle-outline.svg'"
              [label]="'Billing Type'"
              [form]="form.controls.billingType"
              formControlName="billingType"
              [items]="billingTypeItems"
              (optionChange)="changeBillingType($event)"
              [submitted]="submitted"
              [errorMessage]="'Please select a billing type.'">
            </tidy-select>
          </tidy-row>
          <ng-container *ngIf="showServiceCost">
            <tidy-row class="field-bottom-padding">
              <tidy-input
                formControlName="serviceCost"
                [label]="serviceCostLabel"
                [form]="form.controls.serviceCost"
                [icon]="'assets/svg/cash-outline.svg'"
                [errorMessage]="'Please select a rate.'"
                [submitted]="submitted"
                inputMode="numeric"
                [mask]="'currencyMask'">
              </tidy-input>
            </tidy-row>
          </ng-container>
          <tidy-row>
            <tidy-select
              [icon]="'assets/svg/time-outline.svg'"
              [label]="timeToBlockLabel"
              [form]="form.controls.timeToBlock"
              formControlName="timeToBlock"
              [items]="timeToBlockItems"
              [submitted]="submitted"
              [errorMessage]="'Please select a length.'">
            </tidy-select>
          </tidy-row>
      </tidy-card>

      <tidy-card *ngIf="showAdvancedSettings">
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [header]="'Advanced Settings'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="field-top-padding">
            <tidy-select
              [icon]="'assets/svg/reload-circle-outline.svg'"
              [label]="'Frequency'"
              [form]="form.controls.frequency"
              formControlName="frequency"
              [items]="frequencyItems">
            </tidy-select>
         </tidy-row>
      </tidy-card>

      <tidy-row [align]="'center'" *ngIf="!showAdvancedSettings">
        <tidy-text
          [actionBody]="'Advanced Settings'"
          (action)="toggleShowAdvancedSettings()"
          class="link">
        </tidy-text>
      </tidy-row>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-button
        [text]="'Add Service'"
        [action]="addService.bind(this)"
        class="primary">
      </tidy-button>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Want to talk with our Concierge? '">
        </tidy-text><br>
        <tidy-text
          [actionBody]="'Schedule a Support Call'"
          (action)="goToScheduleCallPage()"
          class="link">
        </tidy-text>
      </tidy-row>

    </ng-container>
  </tidy-wrapper>
  </form>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
