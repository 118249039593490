class CleaningTypeUtils {

  cleaningTypes: any = {
    tidy: 1,
    plus: 2.5,
    mighty: 4,
    half_mighty: 4,
    turnover_tidy: 1,
    turnover_plus: 2.5,
    tidy_xl: 4
  };

  getCleaningDuration(type: string): number {
    return this.cleaningTypes[type];
  }

}

export default new CleaningTypeUtils();
