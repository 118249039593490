// Native providers
import { AppCenterCrashes } from '@ionic-native/app-center-crashes/ngx';
import { CookieService } from 'ngx-cookie-service';

// App providers
import { ActiveInCommunity } from 'src/providers/active-in-community/active-in-community';
import { AddressForm } from 'src/providers/address-form/address-form';
import { AlreadyAuthenticatedGuard } from 'src/providers/guard/already-authenticated.guard';
import { AppReview } from 'src/providers/app-review/app-review';
import { AppState } from 'src/shared/providers/http/app-state';
import { Auth } from 'src/providers/auth/auth';
import { AuthGuard } from 'src/providers/guard/auth.guard';
import { Authenticated } from 'src/behaviors/authenticated';
import { BankInfo } from 'src/providers/bank-info/bank-info';
import { Blacklist } from 'src/providers/blacklist/blacklist';
import { Certification } from 'src/providers/certification/certification';
import { ClaimJobs } from 'src/providers/claim-jobs/claim-jobs';
import { CleaningOportunities } from 'src/providers/cleaning-opportunities/cleaning-opportunities';
import { Cleanings } from 'src/providers/cleanings/cleanings';
import { ClientFromTidy } from 'src/providers/client-from-tidy/client-from-tidy';
import { Concierge } from 'src/providers/concierge/concierge';
import { ContestDispute } from 'src/providers/contest-dispute/contest-dispute';
import { Communication } from 'src/providers/communication/communication';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Dashboard } from 'src/providers/dashboard/dashboard';
import { Events } from 'src/providers/events/events';
import { FeeWaivers } from 'src/providers/fee-waivers/fee-waivers';
import { GlobalSubscriptions } from 'src/app/global-subscriptions';
import { Homekeepers } from 'src/providers/homekeepers/homekeepers';
import { HttpClient } from 'src/shared/providers/http/http-client';
import { IncompleteRegisterGuard } from 'src/providers/guard/incomplete-register.guard';
import { Logger } from 'src/shared/providers/logger';
import { LostItem } from 'src/providers/lost-item/lost-item';
import { Me } from 'src/shared/providers/me';
import { MileageTracking } from 'src/providers/mileage-tracking/mileage-tracking';
import { MySchedule } from 'src/providers/my-schedule/my-schedule';
import { MultipleCountryService } from 'src/providers/addresses/multiple-country.service';
import { NotificationSettings } from 'src/providers/notification-settings/notification-settings';
import { OnRails } from 'src/providers/on-rails/on-rails';
import { OneSignalNotification } from 'src/providers/one-signal-notification/one-signal-notification';
import { ParkingReimbursement } from 'src/providers/parking-reimbursement/parking-reimbursement';
import { PrivatePayments } from 'src/providers/private-payments/private-payments';
import { PayRates } from 'src/providers/pay-rates/pay-rates';
import { PendingDecertification } from 'src/providers/pending-decertific/pending-decertific';
import { PrivateClient } from 'src/providers/private-client/private-client';
import { PrivateJobFieldsService } from 'src/shared/providers/private-job/private-job-fields';
import { Proposals } from 'src/providers/proposals/proposals';
import { Quests } from 'src/providers/quests/quests';
import { ReportIssue } from 'src/providers/report-issue/report-issue';
import { RequestCache } from 'src/shared/providers/http/request-cache';
import { Reviews } from 'src/providers/reviews/reviews';
import { RoutingState } from 'src/app/providers/routing-state';
import { ServerData } from 'src/shared/providers/server-data';
import { Survey } from 'src/providers/survey/survey';
import { Search } from 'src/providers/search/search';
import { SharedLinksProvider } from 'src/providers/shared-links/shared-links';
import { Team } from 'src/providers/team/team';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { UndoCancellation } from 'src/providers/undo-cancellation/undo-cancellation';
import { Unpause } from 'src/providers/unpause/unpause';
import { UpcomingFeature } from 'src/providers/upcoming-feature/upcoming-feature';
import { UpdateCheck } from 'src/providers/update-check/update-check';
import { Updates } from 'src/providers/updates/updates';
import { MWService } from 'src/main-workflow/mw.service';
import { MWStore } from 'src/main-workflow/mw.store';
import { IdentificationCheck } from 'src/shared/providers/identification-check';
import { ToDosProvider } from 'src/providers/to-dos/to-dos';
import { CustomNotificationsService } from 'src/shared/providers/custom-notifications/custom-notifications';
import { CustomFieldsService } from 'src/shared/providers/custom-fields/custom-fields';

export const providers = [
  // Native providers
  AppCenterCrashes,
  CookieService,

  // App providers
  ActiveInCommunity,
  AddressForm,
  AlreadyAuthenticatedGuard,
  AppReview,
  AppState,
  Auth,
  AuthGuard,
  Authenticated,
  BankInfo,
  Blacklist,
  Certification,
  ClaimJobs,
  CleaningOportunities,
  Cleanings,
  ClientFromTidy,
  ContestDispute,
  Concierge,
  Communication,
  CustomNavController,
  Dashboard,
  Events,
  FeeWaivers,
  GlobalSubscriptions,
  Homekeepers,
  HttpClient,
  IncompleteRegisterGuard,
  Logger,
  LostItem,
  Me,
  MileageTracking,
  MySchedule,
  MultipleCountryService,
  NotificationSettings,
  OnRails,
  OneSignalNotification,
  ParkingReimbursement,
  PrivatePayments,
  PayRates,
  PendingDecertification,
  PrivateClient,
  PrivateJobFieldsService,
  Proposals,
  Quests,
  ReportIssue,
  RequestCache,
  Reviews,
  RoutingState,
  ServerData,
  SharedLinksProvider,
  Survey,
  Search,
  Team,
  TidyStorage,
  UndoCancellation,
  Unpause,
  UpcomingFeature,
  UpdateCheck,
  Updates,
  MWService,
  MWStore,
  IdentificationCheck,
  ToDosProvider,
  CustomNotificationsService,
  CustomFieldsService
];
