import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { ClientFromTidy } from 'src/providers/client-from-tidy/client-from-tidy';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Team } from 'src/providers/team/team';
import { Me } from 'src/shared/providers/me';
import { MySchedule } from 'src/providers/my-schedule/my-schedule';
import { TidyStorage } from "src/shared/providers/tidy-storage";

import { Loading } from 'src/shared/components/loading/loading';

import { TeamServiceModel } from 'src/shared/models/team.model';
import { TidySelectNumberValueModel, TidySelectStringValueModel } from 'src/models/tidy-select-item.model';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm';

import { SuccessPage } from 'src/pages/success/success';
import { removeCurrencyMask } from 'src/shared/utils/currency-utils';

@Component({
  selector: 'service',
  templateUrl: './service.html'
})

export class ServicePage {

  allowOnWebsite: boolean;
  billingTypeItems: TidySelectStringValueModel[];
  billingType: string;
  errorMessage: string;
  form: UntypedFormGroup;
  frequencyItems: TidySelectStringValueModel[];
  loaded: boolean;
  ratesPreview: any;
  serviceCostLabel: string;
  showAdvancedSettings: boolean;
  showServiceCost: boolean;
  submitted: boolean;
  teamService: TeamServiceModel;
  timeToBlockItems: TidySelectNumberValueModel[];
  timeToBlockLabel: string;

  constructor(
    private clientFromTidy: ClientFromTidy,
    private fb: UntypedFormBuilder,
    private iabUtils: InAppBrowserUtils,
    private me: Me,
    private modalCtrl: ModalController,
    private mySchedule: MySchedule,
    private navCtrl: CustomNavController,
    private store: TidyStorage,
    private team: Team
  ) {
    this.form = this.fb.group({
      billingType: ['', Validators.required],
      description: ['', Validators.required],
      frequency: ['', Validators.required],
      serviceCost: ['', Validators.required],
      serviceName: ['', Validators.required],
      timeToBlock: ['', Validators.required]
    });
  }

  @Loading()
  async ionViewDidEnter() {
    this.loaded = false;
    this.teamService = this.navCtrl.getParam('teamService') || await this.store.retrieve('teamService');
    await this.buildFormItems();
    this.populateForm();
    if (this.teamService.from_tidy) {
      this.billingType = this.getBillingType();
    } else {
      this.billingType = 'Price Later'
    }
    this.changeBillingType(this.teamService.billing_type);
    this.ratesPreview = await this.getRatesPreview();
    this.loaded = true;
  }

  async getRatesPreview() {
    try {
      const ratesPreview = await this.clientFromTidy.previewRatesChange(this.teamService.id, this.teamService.user_repeat_rate);
      const values = {
        raise: {
          header: 'Very Competitive',
          body: 'Increasing your rates would likely lead to more earnings.',
          color: 'yellow'
        },
        ok: {
          header: 'Competitive',
          body: 'Increasing or decreasing your rates would likely lead to less earnings.',
          color: 'green',
        },
        lower: {
          header: 'Uncompetitive',
          body: 'Your rates are higher than others in your area with a similar quality score. <b>It is likely fewer clients will book you, reducing your total earnings.</b> Increasing your quality score would increase the number of clients willing to book you at these rates, you may want to do that first.',
          color: 'yellow'
        },
        very_lower: {
          header: 'Very Uncompetitive',
          body: 'Your rates are much higher than others in your area with a similar quality score. <b>It is likely few clients will book you, reducing your total earnings.</b> Increasing your quality score would increase the number of clients willing to book you at these rates, you may want to do that first.',
          color: 'red'
        }
      }
      return values[ratesPreview.recommendation];
    } catch (err) {
      return {
        header: 'Not Valid',
        body: 'You set your rates too low.  Please increase your rates.',
        color: 'red'
      };
    }
  }

  async buildFormItems() {
    this.billingTypeItems = this.team.buildBillingTypeItems();
    this.frequencyItems = this.team.buildFrequencyItems();
    this.timeToBlockItems = this.team.buildJobHourItems();
  }

  populateForm() {
    const type = this.teamService.billing_type;
    this.showServiceCost = type !== 'free';
    this.allowOnWebsite = this.teamService.bookable_online;
    this.form.patchValue({
      billingType: type,
      serviceName: this.teamService.name,
      description: this.teamService.description,
      frequency: this.teamService.frequency_options
    });
    this.timeToBlockLabel = 'Block Off My Schedule For';
    if (type == 'flat_rate') {
      this.serviceCostLabel = 'Flat Rate';
      this.form.patchValue({
        serviceCost: (this.teamService.user_new_rate / 100).toString()
      });
    }
    if (type == 'hourly') {
      this.serviceCostLabel = 'Minimum Length';
      this.form.patchValue({
        serviceCost: (this.teamService.user_new_rate / 100).toString()
      });
    }
    this.form.patchValue({
      timeToBlock: this.teamService.duration_to_block_off,
    });
  }

  changeBillingType(type) {
    if (type == 'flat_rate') {
      this.showServiceCost = true;
      this.serviceCostLabel = 'Flat Rate';
      this.timeToBlockLabel = 'Block Off My Schedule For';
      this.form.controls.serviceCost.setValidators([Validators.required]);
    } else if (type == 'hourly') {
      this.showServiceCost = true;
      this.serviceCostLabel = 'Hourly Rate';
      this.timeToBlockLabel = 'Minimum Length';
      this.form.controls.serviceCost.setValidators([Validators.required]);
    } else {
      this.showServiceCost = false;
      this.timeToBlockLabel = 'Block Off My Schedule For';
      this.form.get('serviceCost').clearValidators();
      this.form.get('serviceCost').updateValueAndValidity();
    }
  }

  async saveChanges() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    try {
      const payload = this.buildPayload();
      await this.team.updateService(this.teamService.id, payload);
      const params = {
        header: 'Service Updated',
        body: '',
        buttonText: 'Ok',
        buttonRoute: 'services'
      };
      this.navCtrl.navigateForward('success', params);
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  buildPayload() {
    const payload: any = {
      name: this.form.value.serviceName,
      description: this.form.value.description,
      service_type_id: this.teamService.service_type.id,
      frequency_options: this.form.value.frequency,
      duration_to_block_off: this.form.value.timeToBlock,
      billing_type: this.form.value.billingType,
      bookable_online: this.allowOnWebsite
    };
    if (this.form.value.billingType == 'flat_rate' || this.form.value.billingType == 'hourly') {
      payload.user_new_rate = removeCurrencyMask(this.form.value.serviceCost);
      payload.user_repeat_rate = removeCurrencyMask(this.form.value.serviceCost);
    } else if (this.form.value.billingType == 'free') {
      payload.user_new_rate = 0;
      payload.user_repeat_rate = 0;
    }
    return payload;
  }

  async deleteService() {
    if (this.teamService.from_tidy || this.teamService.required_team_service_id) {
      return this.showCantDelete();
    }
    const params: ConfirmPageParamsModel = {
      title: 'Delete Service?',
      body: '',
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: this.confirmDeleteService.bind(this)
    }
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });
    await confirmationModal.present();
  }

  async confirmDeleteService() {
    try{
      await this.team.deleteService(this.teamService.id);
      const params = {
        header: 'Service Deleted',
        body: '',
        buttonText: 'Ok',
        buttonRoute: 'services'
      };
      this.navCtrl.navigateForward('success', params);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
    this.modalCtrl.dismiss();
  }

  showCantDelete() {
    const params = {
      header: 'Unable to Delete',
      body: 'This is a default service and can\'t be deleted. Default services are the best way to get more work.',
      buttonText: 'Ok',
      buttonRoute: 'services',
      learnMoreLink: 'https://help.tidy.com/pros/set-your-services#4xmLj'
    };
    this.navCtrl.navigateForward('success', params);
  }

  getBillingType() {
    const array = {
      flat_rate: 'Flat Rate',
      undetermined: 'Undetermined',
      hourly: 'Hourly',
      free: 'Free',
      price_later: 'Price Later'
    };
    return array[this.teamService.billing_type];
  }

  toggleShowAdvancedSettings() {
    this.showAdvancedSettings = true;
  }

  @Loading()
  async goToEditRates() {
    const pro = await this.me.fetchWithoutCache();
    if (!pro.profile.address.address) {
      return this.showCantEditRates();
    }
    await this.store.save('repeatRate', (this.teamService.user_repeat_rate / 100).toString());
    await this.store.save('discount', ((this.teamService.user_repeat_rate - this.teamService.user_new_rate) / 100).toString());
    await this.store.save('teamService', this.teamService);
    const params = {
      header: 'Be Careful Changing Rates',
      body: 'Increase rates with caution. Most businesses change do this less than 1x per year. Volatile rates can cause you to lose customers. Speak with the Concierge if you need help.',
      buttonText: 'Ok',
      buttonRoute: 'clients-from-tidy-services'
    };
    this.navCtrl.navigateForward('success', params);
  }

  async showCantEditRates() {
    const modalParams = {
      header: 'Please Add Location',
      body: 'Add your location to see how your rates compare to the average in your area.',
      buttonText: 'Go Back',
      customAction: this.goToServices.bind(this),
      secondButtonText: 'Add Location',
      secondCustomAction: this.goToAccountInfo.bind(this)
    };
    const modal = await this.modalCtrl.create({
      component: SuccessPage,
      componentProps: { modalParams },
      animated: false
    });
    modal.present();
  }

  goToServices() {
    this.modalCtrl.dismiss();
    this.navCtrl.navigateForward('services');
  }

  goToAccountInfo() {
    this.modalCtrl.dismiss();
    this.navCtrl.navigateForward('account-info', {finishPage: 'services'});
  }

  goToScheduleCallPage() {
    this.iabUtils.openUrl('https://www.tidy.com/schedule-pro-support');
  }

  learnMoreDefaultService() {
    this.iabUtils.openUrl('https://help.tidy.com/pros/set-your-services#4x-default-services');
  }

  getCancellationFee() {
    const fees = {
      '1 hour cleaning': '$10',
      '2.5 hour cleaning': '$20',
      '4 hour cleaning': '$30'
    };
    if (fees[this.teamService.name] !== undefined) {
      return fees[this.teamService.name];
    } else {
      return '$0';
    }
  }

  goToRequestAccessCancellationFees() {
    const params = {
       nextPage: 'services',
       feature: 'pro_cancellation_fees'
    }
    this.navCtrl.navigateForward('upcoming-feature', params);
  }

  learnMoreRates() {
    this.iabUtils.openUrl('https://help.tidy.com/pros/set-rates-and-fees');
  }

}
