<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [noBow]="true"
    [title]="'I\'m Here - Take Photo'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="!cameraPhoto">
      <tidy-button
        text="Take Photo"
        [action]="takePhoto.bind(this)"
        class="primary">
      </tidy-button>
    </ng-container>

    <ng-container *ngIf="cameraPhoto">
      <tidy-image
        [src]="cameraPhoto.dataUrl"
        class="hero-size">
      </tidy-image>
      <tidy-button
        text="Retake Photo"
        [action]="takePhoto.bind(this)"
        class="secondary">
      </tidy-button>
      <tidy-button
        text="Submit Photo"
        [action]="submitPhoto.bind(this)"
        class="primary">
      </tidy-button>
    </ng-container>

    <tidy-alert>
      <tidy-row [align]="'center'">
        <tidy-image
          [src]="'assets/svg/alert.svg'"
          class="body-size">
        </tidy-image>
        <tidy-text
          [body]="'You have '">
        </tidy-text>
        <countdown
          *ngIf="timeoutTime"
          [target]="timeoutTime"
          (onTimeout)="onTimeout()">
        </countdown>
        <tidy-text
          [body]="' to complete this page.'">
        </tidy-text>
      </tidy-row>
    </tidy-alert>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
