import { Component } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Homekeepers } from 'src/providers/homekeepers/homekeepers';
import { PlanFrequencyPipe } from 'src/shared/pipes/tidy-plan-frequency';

import * as moment from 'moment';

import { formatHourToIso8601, removeHourAndTimezoneFromDate } from 'src/shared/utils/date-utils';
import { removeCurrencyMask } from 'src/shared/utils/currency-utils';
import { titleCase } from 'src/shared/utils/text-utils';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  selector: 'app-private-reschedule-confirm',
  templateUrl: './private-reschedule-confirm.html',
  styleUrls: ['./private-reschedule-confirm.scss'],
})

export class PrivateRescheduleConfirmPage {

  addressName: string;
  amount: string;
  clientName: string;
  cancelledBy: string;
  currentFrequency: string;
  date: string;
  errorMessage: string;
  frequency: string;
  jobDateAndHour: string;
  jobId: string;
  planId: string;
  rescheduleType: string;
  startTime: string;
  startTimeLabel: string;
  withFee: boolean;
  service: string;
  serviceName: string;
  description: string;

  constructor(
    private customNavCtrl: CustomNavController,
    private homekeepers: Homekeepers,
    private planFrequencyPipe: PlanFrequencyPipe
  ) {
    const params = this.customNavCtrl.getParams();
    const jobParams = this.customNavCtrl.getParam('job', true);
    this.addressName = params.addressName;
    this.amount = jobParams.price;
    this.clientName = params.clientName;
    this.cancelledBy = params.cancelledBy;
    this.currentFrequency = params.currentFrequency;
    this.date = jobParams.date;
    this.frequency = jobParams.frequency;
    this.jobDateAndHour = params.jobDateAndHour;
    this.jobId = params.jobId;
    this.planId = params.planId;
    this.rescheduleType = params.rescheduleType;
    this.startTime = jobParams.startTime;
    this.startTimeLabel = params.startTimeLabel;
    this.withFee = params.withFee;
    this.service = params.service;
    this.serviceName = params.serviceName;
    this.description = params.description;
  }

  async next() {
    const formattedDate = removeHourAndTimezoneFromDate(this.date);
    const formattedTime = formatHourToIso8601(this.startTime);
    const formattedCurrency = removeCurrencyMask(this.amount);

    const job = {
      amount: formattedCurrency,
      date: formattedDate,
      start_time: formattedTime,
      service_type_key: this.service
    };

    const frequency = this.frequency === 'not-changed' ? this.currentFrequency : this.frequency

    try {
      if (this.rescheduleType === 'plan') {
        const reschedulePlanParams = {
          jobs: [job],
          plan : {
            frequency
          },
          plan_id: this.planId,
        };

        await this.homekeepers.reschedulePlan(reschedulePlanParams, this.planId);
      }
      if (this.rescheduleType === 'job') {
        const rescheduleJobParams = {
          cancelled_by: this.cancelledBy,
          job,
          job_id: this.jobId,
          with_fee: this.withFee,
          proposal: {
            description: this.description,
            price: this.amount
          },
        };
        await this.homekeepers.rescheduleJob(rescheduleJobParams, this.jobId);
      }
      const successParams = {
        body: this.getBodyMessage(),
        buttonText: 'Go to Jobs',
        buttonRoute: 'jobs',
        header: `${titleCase(this.rescheduleType)} Rescheduled`
      };
      this.customNavCtrl.navigateForward('success', successParams);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  getBodyMessage() {
    const fromLabel = this.rescheduleType === 'job' ? `${new TranslationPipe().transform('from')} ${this.jobDateAndHour}` : '';
    const newDateFormatted = moment(this.date).format('dddd M/D');
    const frequency = this.frequency === 'not-changed' ? this.currentFrequency : this.frequency
    const frequencyAndDateLabel = frequency === 'once' ?
      `${new TranslationPipe().transform('to just one time on')} ${newDateFormatted} ${this.startTimeLabel}` :
      `${new TranslationPipe().transform('to')} ${this.serviceName} ${this.planFrequencyPipe.transform(frequency)} ${new TranslationPipe().transform('on')} ${newDateFormatted}s ${new TranslationPipe().transform('at')} ${this.startTimeLabel}`;
    return `
      ${new TranslationPipe().transform('You rescheduled')} ${this.clientName}'s ${this.planFrequencyPipe.transform(this.currentFrequency)} ${this.rescheduleType}
      ${fromLabel} ${frequencyAndDateLabel}
    `;
  }

  goBack() {
    this.customNavCtrl.back();
  }
}
