import { Capacitor } from '@capacitor/core';
import { Injectable } from '@angular/core';
import { AlertController, ModalController, Platform, ToastController } from '@ionic/angular';

import { AppCenterCrashes, AppCenterCrashReport } from '@ionic-native/app-center-crashes/ngx';
import { AppState } from 'src/shared/providers/http/app-state';
import { Auth } from 'src/providers/auth/auth';
import { CheckForUpdatesPage } from 'src/pages/check-for-updates/check-for-updates';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Events } from 'src/providers/events/events';
import { LoadingBarHandler } from 'src/providers/loading-bar-handler/loading-bar-handler';
import { Logger } from 'src/shared/providers/logger';
import { Me } from 'src/shared/providers/me';
import { MWStore } from 'src/main-workflow/mw.store';
import { OneSignalNotification } from 'src/providers/one-signal-notification/one-signal-notification';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { UpdateCheck } from 'src/providers/update-check/update-check';

import { App } from '@capacitor/app';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { Keyboard } from '@capacitor/keyboard';

declare let cordova: any;

@Injectable()
export class GlobalSubscriptions {

  constructor(
    private alertCtrl: AlertController,
    private appCenterCrashes: AppCenterCrashes,
    private appState: AppState,
    private auth: Auth,
    private customNavCtrl: CustomNavController,
    private events: Events,
    private loadingBarHandler: LoadingBarHandler,
    private logger: Logger,
    private me: Me,
    private modalCtrl: ModalController,
    private mwStore: MWStore,
    private oneSignalNotification: OneSignalNotification,
    private platform: Platform,
    private storage: TidyStorage,
    private toastCtrl: ToastController,
    private updateCheck: UpdateCheck,
  ) {}

  subscribe(): void {
    try {
      this.platformReady();
    } catch (err) {
      this.logger.error(err, 'Err on platform.ready')
    }

    this.events.subscribe( 'auth:authenticated', (data) => this.onAuthenticated(data.me) );
    this.events.subscribe( 'auth:logout', () => this.onLogout() );
    this.events.subscribe( 'message:show', (data) => this.showMessage(data.message, data.styleClass));
    this.events.subscribe( 'app:connection', (data) => this.onConnectionChanged(data.online) );
    this.events.subscribe( 'cleaning-opportunity-notification:open', (data) => this.displayCoops() );
    this.loadingBarHandler.setup();
  }

  platformReady(): void {
    this.setupNativeConfigurations();
    App.addListener('backButton', () => { /* do nothing on back button pressed */ });
    this.isAuthenticated();
    this.askTrackingPermission();

    try {
      this.oneSignalNotification.initOneSignal();
    } catch (error) {
      this.logger.error(error, 'onesignal-init');
    }
  }

  async isAuthenticated() {
    try {
      if (this.auth.isAuthenticated()) {
        const me = await this.me.load();
        this.storage.save('me', me);
        this.events.publish('auth:authenticated', { me })
        const route = window.location.hash;
        if (route.includes('dashboard')) {
          return;
        }
        const certificationStatus = me.user?.compliance_status;
        this.handleCertificationStatus(certificationStatus);
      }
    } catch (error) {
      this.logger.error(error, 'is-authenticated');
    }
  }

  handleCertificationStatus(certificationStatus: string) {
    const route = window.location.hash;
    if (route.includes('active-in-community')) {
      return;
    }
    if (certificationStatus === 'needed') {
      const complianceAlertDismissed = localStorage.getItem('complianceAlert');
      if (complianceAlertDismissed) {
        return;
      }
      const alertButtons = [
        {
          text: 'Do Later',
          role: 'later',
          handler: () => {
            localStorage.setItem('complianceAlert', 'later');
          },
        },
        {
          text: 'Ok',
          role: 'confirm',
          handler: () => {
            this.customNavCtrl.navigateRoot('active-in-community');
          },
        },
      ];
      this.presentComplianceAlert(alertButtons);
    }
    if (certificationStatus === 'overdue') {
      const alertButtons = [
        {
          text: 'Ok',
          role: 'confirm',
          handler: () => {
            this.customNavCtrl.navigateRoot('active-in-community');
          },
        },
      ];
      this.presentComplianceAlert(alertButtons);
    }
  }

  async presentComplianceAlert(buttons: any[]) {
    const alert = await this.alertCtrl.create({
      header: 'Compliance',
      subHeader: 'Missing Compliance Certification',
      message: 'Please complete the required steps to receive your certification.',
      buttons,
      mode: 'ios',
      backdropDismiss: false,
      cssClass: 'compliance-alert',
    });

    await alert.present();
  }

  askTrackingPermission() {
    if (this.platform.is('cordova') && this.platform.is('ios')) {
      const idfaPlugin = cordova.plugins.idfa;
      idfaPlugin.requestPermission();
    }
  }

  async onAuthenticated(me): Promise<void> {
    await this.displayCheckUpdates();
    this.appState.fireCurrentState();
    this.oneSignalNotification.setUserIdentification(me.user.id, me.profile.email);
  }

  async onLogout() {
    localStorage.clear();
    await this.mwStore.clearStore();
    await this.storage.clear();
    await this.customNavCtrl.navigateRoot('introduction');
  }


  async onConnectionChanged(online: boolean) {
    if (online) {
      await this.oneSignalNotification.initOneSignal();
    }
  }

  async displayCoops() {
    this.customNavCtrl.navigateRoot('/add');
  }

  async setupNativeConfigurations() {
    if (Capacitor.getPlatform() !== 'web') {
      this.enableCrashTracking();
      StatusBar.setStyle({ style: Style.Dark });
      await SplashScreen.hide()
      await Keyboard.setAccessoryBarVisible({ isVisible: false });
    }
  }

  async showMessage(messageStr, styleClass = 'success') {
    const toast = await this.toastCtrl.create({
      message: messageStr,
      cssClass: styleClass,
      duration: 8000,
      position: 'bottom'
    });
    await toast.present();
  }

  async displayCheckUpdates(): Promise<void> {
    if (await this.updateCheck.shouldDisplayCheckUpdate()) {
      await this.updateCheck.updateLastDisplayedCheck();
      const modal = await this.modalCtrl.create({ component: CheckForUpdatesPage });
      await modal.present();
    }
  }

  async enableCrashTracking(): Promise<void> {
    await this.appCenterCrashes.setEnabled(true);
    const crashReport: AppCenterCrashReport = await this.appCenterCrashes.lastSessionCrashReport();

    if (crashReport) {
      this.logger.error(crashReport, 'crash-report');
    }
  }

}
