export class AppConfig {

  static API: string = 'https://api-production.gotidy.com/';
  static AWS_ACCESS_KEY_ID: string = 'AKIA236WTUK56U5ULQZR';
  static AWS_S3_BUCKET: string = 'samantha-temp-file-uploads/production';
  static AWS_S3_BUCKET_URL: string = 'https://samantha-temp-file-uploads.s3-us-west-2.amazonaws.com/production/';
  static AWS_S3_REGION_BUCKET: string = 'us-west-2';
  static AWS_SECRET_ACCESS_KEY: string = 'IUIEiKyE07Duy14oIJl6Sj84z4b5NQaSPd/fNk/N';
  static BEARER = () => `${localStorage.getItem('tidy_token_type')} ${localStorage.getItem('tidy_token')}`;
  static CLIENT_DATA: any = { client_id: '018a900f1bd1c943c1e5d5255e654c7fede5001fe064aa07cd78501e05f22e1d', client_secret: 'ba36f670f4d45515dbb5eb158a041e7aeaf332f2326b7ef2e4ef16997ddcba5f' };
  static FB_APP_ID: string = '473610673031420';
  static FACEBOOK_PIXEL_ID: string = '1708273092789855';
  static GOOGLE_ANALYTICS_ID = 'AW-968467335';
  static GOOGLE_MAPS_KEY = 'AIzaSyCXnQz-WizOcOIhmb_hHj59tNcqXn2i-94';
  static LOGGLY_TAG: string = 'fe-bm-production';
  static MAPBOX_KEY  = 'pk.eyJ1IjoidmljdG9yZmVycmF6IiwiYSI6ImNrYTNzb3Y0cTB0OHUzbXIzNms1YzR2dGkifQ.MSXPQztBeFXmDD0T_GC0oQ';
  static PRODUCTION: boolean = true;
  static REQUEST_LOG_THRESHOLD: number = 2000;
  static TIMEOUT_THRESHOLD: number = 10000;
  static VERSION: string = '1.37.3';

}
