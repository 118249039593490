<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Clients from TIDY'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper *ngIf="loaded">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card *ngFor="let card of cards">
      <tidy-row class="vertical-align-center">
       <tidy-image
          [src]="'assets/svg/' + card?.icon + '.svg'"
          class="header-size">
        </tidy-image>
        <tidy-text
          [header]="card?.header">
        </tidy-text>
      </tidy-row>
      <tidy-row [ngClass]="card?.icon === 'briefcase' ? 'extra-bottom-padding' : ''">
        <tidy-text
          [body]="card?.body">
        </tidy-text>
      </tidy-row>
      <ng-container *ngIf="card?.icon === 'briefcase'">
        <ng-container *ngIf="showRates">
          <tidy-row>
            <tidy-text
              [body]="'Base Rates:'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'1 Hour New Client: '">
            </tidy-text>
            <tidy-text
              [body]="(rates?.new?.tidy | tcurrency)">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'1 Hour Repeat Client: '">
            </tidy-text>
            <tidy-text
              [body]="(rates?.repeat?.tidy | tcurrency)">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'2.5 Hour New Client: '">
            </tidy-text>
            <tidy-text
              [body]="(rates?.new?.tidyPlus | tcurrency)">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'2.5 Hour Repeat Client: '">
            </tidy-text>
            <tidy-text
              [body]="(rates?.repeat?.tidyPlus | tcurrency)">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'4 Hour New Client: '">
            </tidy-text>
            <tidy-text
              [body]="(rates?.new?.mighty | tcurrency)">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [body]="'4 Hour Repeat Client: '">
            </tidy-text>
            <tidy-text
              [body]="(rates?.repeat?.mighty | tcurrency)">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Client Fees:'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'$5  - Didn\’t Start Correct Time'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'50% of job cost - Did not clean Client\’s full time'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'$10 - Cancelling within 72 hours'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'$20 - Cancelling within 24 hours'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'$30 - Cancelling within 3 hours'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'$40 - No show'">
            </tidy-text>
          </tidy-row>
        </ng-container>
        <tidy-row *ngIf="!showRates">
          <tidy-text
            [body]="'<i>Add account info & set your max drive time to see rates!</i>'">
          </tidy-text>
        </tidy-row>
      </ng-container>
    </tidy-card>

    <tidy-error-message
      [text]="errorMessage">
     </tidy-error-message>

    <tidy-button
      *ngIf="!finishedStep"
      [text]="'Got It'"
      [action]="gotIt.bind(this)"
      class="primary">
    </tidy-button>

    <tidy-row *ngIf="finishedStep">
      <tidy-text
        [body]="'You have already finished this step of certification.'">
      </tidy-text>
    </tidy-row>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
