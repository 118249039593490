import { Component, HostListener } from '@angular/core';

import { CommonUtils } from 'src/shared/utils/common-utils';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { DeviceInfoProvider } from 'src/shared/providers/device-info';
import { Me } from 'src/shared/providers/me';
import { PhotosCache } from 'src/shared/providers/tidy-photos-cache';

import { Loading } from 'src/shared/components/loading/loading';

import { MWJobModel, MWTodosModel } from 'src/main-workflow/mw.models';
import { MWStore } from 'src/main-workflow/mw.store';

@Component({
  templateUrl: 'mw.to-dos.page.html'
})

export class ToDosPage {

  job: MWJobModel;
  jobTimer: any;
  loaded: boolean;
  photosCacheData;
  screenwidth: number;
  toDos: MWTodosModel;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenwidth = event.target.innerWidth
  }

  constructor(
    public deviceInfo: DeviceInfoProvider,
    public me: Me,
    private mwStore: MWStore,
    private navCtrl: CustomNavController,
    private photosCache: PhotosCache,
    private util: CommonUtils
  ) {}

  @Loading()
  async ionViewWillEnter() {
    try {
      this.loaded = false;
      this.job = await this.mwStore.getJob();
      this.toDos = await this.mwStore.getToDos();
      this.jobTimer = await this.mwStore.getTimer();
      this.photosCacheData = await this.photosCache.retrieveAll();
      this.loaded = true;
    } catch (error) {
      const errorMessage = (error.error && error.error.message) ? error.error.message : error.message;
      this.util.showError(errorMessage, 10000);
    }
  }

  goToContactPage() {
    this.navCtrl.navigateForward('job-messages');
  }

  goToExitJobPage() {
    this.navCtrl.navigateForward('exit-job');
  }

}
