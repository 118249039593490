import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { JobMediasProvider } from 'src/providers/job-medias/job-medias.provider';
import { CommonUtils } from 'src/shared/utils/common-utils';
import { Component, Input, OnInit } from '@angular/core';
import { DeviceInfoProvider } from 'src/shared/providers/device-info';
import { Camera } from 'src/shared/providers/camera/camera';
import { beforeAfterOptions } from 'src/shared/constants/camera';
import { Photo } from '@capacitor/camera';
import { ToDosProvider } from 'src/providers/to-dos/to-dos';

export interface BeforeAfterPhoto {
  uuid?: string;
  url: string;
  mediaType: string;
}

type BeforeAfterTypes =
  | 'before_photos'
  | 'after_photos'
  | 'before_videos'
  | 'after_videos';

type JobTypes = 'mw_job' | 'private_job' | 'shared_job' | 'shared_to_do_list';

@Component({
  selector: 'tidy-before-after',
  templateUrl: 'before-after.component.html',
})
export class BeforeAfterComponent implements OnInit {

  @Input() uuid: string;
  @Input() page: string;
  @Input() type: BeforeAfterTypes;
  @Input() jobIsCompleted: boolean;
  @Input() beforeAfterPhotos: BeforeAfterPhoto[] = [];
  @Input() showDeleteButton = true;
  errorMessage: string;
  mediasType: string;
  beforeAfterType: string;

  constructor(
    public deviceInfo: DeviceInfoProvider,
    private utils: CommonUtils,
    private camera: Camera,
    private jobMediasProvider: JobMediasProvider,
    private storage: TidyStorage,
    private toDosProvider: ToDosProvider
  ) {}

  async ngOnInit() {
    try {
      this.mediasType = this.type.includes('photo') ? 'photos' : 'videos';
      this.beforeAfterType = this.type.includes('before') ? 'before' : 'after';
      if (!this.jobIsCompleted) {
        this.beforeAfterPhotos = await this.parseBeforeAfterPhotos() || [];
      }
      this.storage.save('jobIsCompleted', this.jobIsCompleted);
    } catch (err) {
      const errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.utils.showError(errorMessage);
    }
  }

  async uploadAllMediaAndSyncJob(jobMedia = null) {
    if (jobMedia && this.jobIsCompleted) {
      return await this.jobMediasProvider.uploadJobMediasForCompletedJob(this.uuid, jobMedia);
    }
    this.beforeAfterPhotos = await this.parseBeforeAfterPhotos() || [];
    await this.toDosProvider.uploadAllMediaAndStoreResponse();
    const didUploadNewMedia = await this.storage.retrieve('didUploadNewMedia');
    const mediaFailedToUpload = await this.storage.retrieve('mediaFailedToUpload');
    if (didUploadNewMedia && !mediaFailedToUpload) {
      this.toDosProvider.syncJob();
    }
  }

  async takeVideo() {
    this.errorMessage = '';
    try {
      const jobMedia = await this.jobMediasProvider.takeBeforeAfterVideoAndSaveInStorage(
        this.type,
        this.uuid
      );
      if (jobMedia && this.jobIsCompleted) {
        this.beforeAfterPhotos.push({
          url: jobMedia.media_url,
          mediaType: jobMedia.media_format,
        });
      }
      await this.uploadAllMediaAndSyncJob(jobMedia);
    } catch (err) {
      const errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.utils.showError(errorMessage);
    }
  }

  async uploadVideo(videos: any[]) {
    try {
      const videosToUpload = [];
      await Promise.all(
        videos.map(async (video) => {
          const jobMedia = await this.jobMediasProvider.saveBeforeAfterVideoInStorage(
            video.file,
            this.uuid,
            this.type,
            video?.exif
          );
          if (jobMedia && this.jobIsCompleted) {
            videosToUpload.push(jobMedia);
            this.beforeAfterPhotos.push({
              url: jobMedia.media_url,
              mediaType: jobMedia.media_format,
            });
          }
        })
      );
      await this.uploadAllMediaAndSyncJob(videosToUpload);
    } catch (err) {
      console.error(err);
      this.utils.showError('Error uploading video. Please try again.', err);
    } finally {
    }
  }

  async takePhoto() {
    this.camera.getPhoto(beforeAfterOptions).then(async (photo: Photo) => {
      if (!photo?.base64String && !photo?.dataUrl) {
        return;
      }
      const parsedPhoto =
        photo?.dataUrl && !photo?.base64String
          ? photo?.dataUrl
          : `data:image/jpeg;base64,${photo.base64String}`;
      let jobMedia: any = await this.jobMediasProvider.saveBeforeAfterPhotoInStorage(
        this.type,
        this.uuid,
        parsedPhoto
      );
      if (jobMedia && this.jobIsCompleted) {
        this.beforeAfterPhotos.push({
          url: jobMedia.media_url,
          mediaType: jobMedia.media_format,
        });
        jobMedia = [jobMedia];
      }
      await this.uploadAllMediaAndSyncJob(jobMedia);
    });
  }

  async uploadPhoto(photos: any[]) {
    try {
      const photosToUpload = [];
      await Promise.all(
        photos.map(async (photo) => {
          const jobMedia = await this.jobMediasProvider.saveBeforeAfterPhotoInStorage(
            this.type,
            this.uuid,
            photo.url,
            photo.file,
            photo?.exif
          );
          if (jobMedia && this.jobIsCompleted) {
            photosToUpload.push(jobMedia);
            this.beforeAfterPhotos.push({
              url: jobMedia.media_url,
              mediaType: jobMedia.media_format,
            });
          }
        })
      );
      await this.uploadAllMediaAndSyncJob(photosToUpload);
    } catch (err) {
      this.utils.showError('Error uploading photo. Please try again.', err);
    }
  }

  async removeMedia(event) {
    let confirmRemoval = false;

    if (!navigator.onLine) {
      // Show a simple browser alert if offline
      confirmRemoval = window.confirm('Are you sure you want to remove this media?');
    } else {
      // Show a custom confirmation alert if online
      const alertOptions = {
        header: 'Remove Media',
        message: 'Are you sure you want to remove this media?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: 'Remove',
            role: 'confirm',
          },
        ],
      };
      const alert = await this.utils.showConfirmAlert(alertOptions);
      const res = await alert.onDidDismiss();
      confirmRemoval = res.role === 'confirm';
    }

    if (!confirmRemoval) {
      return;
    }

    try {
      const storageKey = await this.storage.retrieve('storageKey');
      const syncData = await this.storage.retrieve(storageKey) || [];
      const storeMediasKey =
        this.page !== 'MWToDosPage'
          ? `${this.beforeAfterType}_photos`
          : `${this.beforeAfterType}Photos`;
      const storedMedia =
        this.page === 'MWToDosPage'
          ? syncData?.data?.[storeMediasKey]
          : syncData?.[storeMediasKey];
      const indexOfMedia = storedMedia.findIndex((media) =>
        media.uuid ? (media.uuid === event?.image?.uuid || media.uuid === event?.uuid) :
        this.getUUIDFromURL(media?.media_url) == this.getUUIDFromURL(event?.image?.url)
      );
      storedMedia[indexOfMedia].deleted = true;
      storedMedia[indexOfMedia].media_data = null;
      if (this.page === 'MWToDosPage') {
        syncData.data[storeMediasKey] = storedMedia;
      } else {
        syncData[storeMediasKey] = storedMedia;
      }
      await this.storage.save(storageKey, syncData);
      this.uploadAllMediaAndSyncJob(null);
    } catch (err) {
      this.utils.showError('Error removing photo. Please try again.', err);
    }
  }

  getUUIDFromURL(url) {
    //This method is used to get the UUID from the media that was returned by BE and then stored for private jobs, since BE doesn't return the UUID
    if (!url) {
      return null;
    }
    const uuidMatch = url.match(/job-medias\/([^.]+)/);
    return uuidMatch ? uuidMatch[1] : null;
  }

  async parseBeforeAfterPhotos() {
    try {
      const storageKey = await this.storage.retrieve('storageKey');
      let syncData = await this.storage.retrieve(storageKey) || [];
      const storeMediasKey =
        this.page !== 'MWToDosPage'
          ? `${this.beforeAfterType}_photos`
          : `${this.beforeAfterType}Photos`;
      const storedMedia =
        this.page === 'MWToDosPage'
          ? syncData?.data?.[storeMediasKey]
          : syncData?.[storeMediasKey];
      if (!storedMedia) {
        return [];
      }
      const photos = storedMedia.filter((photo) => !photo?.deleted);
      const parsedPhotos = photos.map((photo) => {
        let url = photo?.media_data || photo?.media_url;
        if (url && typeof url === 'string' && url?.includes(';base64,') && photo?.media_format?.includes('video')) {
          url = null;
        }
        return {
          uuid: photo.uuid,
          url: photo?.media_data || photo?.media_url,
          mediaType: photo.media_format,
          exif: photo?.exif,
        };
      });
      return parsedPhotos;
    } catch (err) {
      return [];
    }
  }

  filePickerError(e) {
    this.utils.showError(e);
  }

}
