import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import * as moment from 'moment';
import { TimeoutError } from 'rxjs';
import { ConnectionStatus, Network } from '@capacitor/network';
import { DateTime as LuxonDateTime } from 'luxon';

import { MWStore } from 'src/main-workflow/mw.store';
import { mwMoments } from 'src/main-workflow/mw.moments';

import { Aws } from 'src/shared/providers/aws';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { DeviceInfoProvider } from 'src/shared/providers/device-info';
import { HttpClient } from 'src/shared/providers/http/http-client';
import { Location } from 'src/shared/providers/location';
import { Logger } from 'src/shared/providers/logger';
import { PhotosCache } from 'src/shared/providers/tidy-photos-cache';
import { ToDosProvider } from 'src/providers/to-dos/to-dos';

import { parseJobData } from 'src/main-workflow/parsers/mw.job.parser';
import { parseTodosData } from 'src/main-workflow/parsers/mw.to-dos.parser';

import arrayUtils from 'src/shared/utils/array-utils';

import { JobMediaPayload, MWJobModel, MWTodosModel } from 'src/main-workflow/mw.models';
import { SuccessPageParamsModel } from 'src/pages/success/success';

import { Loading } from 'src/shared/components/loading/loading';
import { randomUUID } from 'src/shared/utils/secure-utils';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { JobMediasProvider } from 'src/providers/job-medias/job-medias.provider';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Injectable({
  providedIn: 'root'
})

export class MWService {

  constructor(
    private aws: Aws,
    private deviceInfo: DeviceInfoProvider,
    private httpClient: HttpClient,
    private launchNavigator: LaunchNavigator,
    private location: Location,
    private logger: Logger,
    private mwStore: MWStore,
    private navCtrl: CustomNavController,
    private photosCache: PhotosCache,
    private platform: Platform,
    private storage: TidyStorage,
    private jobMediasProvider: JobMediasProvider,
    private toDosProvider: ToDosProvider
  ) {}

  async getJob(jobId): Promise<MWJobModel> {
    const url = `api/v1/homekeeper/timeline/cards/cleaning/${jobId}`;
    const response = await this.httpClient.get(url);
    const bookingNotesUrl = `api/v1/homekeeper/booking-notes?booking_id=${response.booking.id}`;
    const bookingNotesResponse = await this.httpClient.get(bookingNotesUrl);
    const bookingFormAnswersUrl = `api/v1/homekeeper/customer-booking-form-answers?job_id=${jobId}`;
    const bookingFormAnswersResponse = await this.httpClient.get(bookingFormAnswersUrl);
    const job = parseJobData(response, bookingNotesResponse, bookingFormAnswersResponse);
    return job;
  }

  async startCommunication(job) {
    try {
      if (!job.gateway_phone) {
        const url = `api/v1/homekeeper/communication/start`;
        const communicationResponse = await this.httpClient.post(url, {homekeeper_job_ids: [Number(job.homekeeper_job_id)]});
        job['gateway_phone'] = communicationResponse[0].gateway_phone;
        await this.mwStore.setJob(job);
      }
    } catch (err) {
      this.logger.error(err, 'Error starting communication.');
      throw err;
    }
  }

  async getToDos(): Promise<MWTodosModel> {
    const jobId = await this.mwStore.getJobId();
    const url = `api/v1/homekeeper/cleanings/${jobId}/to-dos`;
    const response = await this.httpClient.get(url);
    return parseTodosData(response);
  }

  async isFarFromHome(): Promise<boolean> {
    const job = await this.mwStore.getJob();
    const { latitude, longitude } = job;
    try {
      const deviceLocation = await this.location.get();
      const addressLocation = { latitude, longitude };
      const distance = this.location.kmDistanceBetween(deviceLocation, addressLocation);
      return distance > 1;
    } catch (err) {
      return true;
    }
  }

  async isTooEarlyToFinish(): Promise<boolean> {
    const timerTarget = await this.mwStore.getTimer();
    return moment().isBefore(timerTarget.add(-1, 'm'));
  }

  getEligibleForPlan(planId) {
    const url = `api/v1/homekeeper/plans/${planId}/check-eligibility-for-plan-upgrade`;
    return this.httpClient.get(url);
  }

  isTooEarlyForJob(jobStartTime): boolean {
    return moment().isBefore(moment(jobStartTime).add(-85, 'minutes'));
  }

  isTooEarlyForJobStandby(jobStartTime): boolean {
    return moment().isBefore(moment(jobStartTime).add(-5, 'minutes'));
  }

  isTooLateForJob(jobEndTime): boolean {
    return moment().isAfter(moment(jobEndTime).add(2, 'hours'));
  }

  async showTooLateAlert() {
    const job = await this.mwStore.getJob();
    const route = await this.mwStore.getRoute();
    const syncData = await this.mwStore.getSyncData();
    if (syncData !== null && syncData !== undefined && (job.id == syncData.jobId)) {
      await this.addMomentToSyncData(mwMoments.tooLateForAction, route, job.id);
      syncData.shouldSync = true;
      this.mwStore.setSyncData(syncData);
    } else {
      await this.sendMomentToBackend(mwMoments.tooLateForAction, route, job.id);
      this.mwStore.clearStore();
    }
    return this.navCtrl.navigateForward('success', {
      header: 'Too Late for Action',
      body: 'It is too late to perform this action.  You can report any issues by clicking on the past job in the "Jobs" page.',
      buttonText: 'Ok',
      buttonRoute: 'jobs'
    });
  }

  async showJobCancelledAlert(clearStore) {
    if (clearStore) {
      await this.mwStore.clearStore();
    }
    return this.navCtrl.navigateForward('success', {
      header: 'Client Cancelled',
      body: 'This was cancelled by the client.  You will receive a last minute cancellation fee.',
      buttonText: 'Go To Jobs',
      buttonRoute: 'jobs'
    });
  }

  async showStandbyCancelledAlert(clearStore) {
    if (clearStore) {
      await this.mwStore.clearStore();
    }
    return this.navCtrl.navigateForward('success', {
      header: 'Standby Cancelled',
      body: 'You are no longer needed as a Standby.  You will receive a last minute cancellation fee.',
      buttonText: 'Go To Jobs',
      buttonRoute: 'jobs'
    });
  }

  async showInactiveJobAlert(clearStore) {
    if (clearStore) {
      await this.mwStore.clearStore();
    }
    return this.navCtrl.navigateForward('success', {
      header: 'Job No Longer Available',
      body: 'This job is no longer available. Tap on the past job in the "Jobs" page for more detail.',
      buttonText: 'Go To Jobs',
      buttonRoute: 'jobs'
    });
  }

  async buildErrorAlert(err) {
    const networkStatus: ConnectionStatus = await Network.getStatus();
    if (!networkStatus.connected) {
      return 'You are offline.  The app works offline for some things, but not everything.  Connect to the internet to take the next steps.';
    } else if (err instanceof TimeoutError) {
      return 'There was a &quottimeout error&quot. Most of the time this means a bad internet connection so please try again. Contact the TIDY Concierge if you think it is a different issue.';
    } else {
      const apiErrorMessage = err?.error?.message || err?.message;
      return `${new TranslationPipe().transform('There was a server error:')} ${apiErrorMessage}`;
    }
  }

  async showErrorPage(err) {
    const params: SuccessPageParamsModel = {
      header: '',
      body: '',
      buttonText: 'Ok'
    };
    const networkStatus = await Network.getStatus();
    if (!networkStatus.connected) {
      params.header = 'You are offline';
      params.body = 'The app works offline for some things, but not everything.  Connect to the internet to take the next steps.';
    } else if (err instanceof TimeoutError) {
      params.header = 'Timeout Error';
      params.body = `There was a &quottimeout error&quot. Most of the time this means a bad internet connection so please try again. Contact the TIDY Concierge if you think it is a different issue.`;
    } else {
      const apiErrorMessage = err?.error?.message || err?.message;
      params.header = 'Server Error'
      params.body = `${new TranslationPipe().transform('There was an error:')} ${apiErrorMessage}`
    }
    this.navCtrl.navigateForward('success', params);
  }

  async addMomentToSyncData(data, route, jobId) {
    const momentData = await this.buildMoment(data, route, jobId);
    const syncData = await this.mwStore.getSyncData();
    syncData.data['moments'].push(momentData);
    return this.mwStore.setSyncData(syncData);
  }

  async sendMomentToBackend(data, route, jobId) {
    const momentData = await this.buildMoment(data, route, jobId);
    const url = `api/v1/homekeeper/cleanings/${jobId}/moments`;
    this.httpClient.post(url, momentData);
  }

  async buildMoment(data, route, jobId) {
    const location = await this.location.get();
    const response: any = {
      date: moment.utc().format('YYYY-MM-DD'),
      time: moment.utc().format('HH:mm:ss'),
      latitude: location.latitude,
      longitude: location.longitude,
      moment: data.name,
      description: data.description,
      source: route,
      moment_uuid: randomUUID()
    };
    if (location?.error) {
      response['location_error_message'] = location?.error;
    }
    return response;
  }

  @Loading()
  async getDirections(job, sourceRoute) {
    this.sendMomentToBackend(mwMoments.getDirections, sourceRoute, job.id);
    if (this.platform.is('mobileweb') || this.platform.is('desktop')) {
      const {latitude, longitude} = await this.location.get();
      const address = job.routeAddress.replace(/ /g, '+');
      const url = `https://maps.google.com/maps?saddr=${latitude},${longitude}&daddr=${address}`;
      window.open(url, '_system');
    } else {
      this.launchNavigator.navigate(job.address);
    }
  }

  async getDistanceFromJob(job) {
    const location = await this.location.distanceTo(`${job.routeAddress}, USA`);
    return location.duration.withMargin;
  }

  async cancelFutureJob(jobId: number, cancelReasonId: number) {
    const url = `api/v1/homekeeper/cleanings/${jobId}/leave-cleaning`;
    const params = {
      device_id: await this.deviceInfo.uuid(),
      moments: [],
      reason: 'call_out',
      set_unavailable: true,
      cancellation_reason_id: cancelReasonId
    };
    return this.httpClient.post(url, params);
  }

  async cancelJob(type, job, cancelReasonId?: number) {
    let params = {
      device_id: await this.deviceInfo.uuid(),
      moments: [],
      reason: type,
      set_unavailable: false
    };
    if (type === 'call_out') {
      params['set_unavailable'] = true;
      params['cancellation_reason_id'] = cancelReasonId;
    }
    const moment = type === 'call_out' ? mwMoments.callOut : mwMoments.clientRefusedService;
    const route = await this.mwStore.getRoute() || 'ready-to-leave';
    const syncData = await this.mwStore.getSyncData();
    const cancelingSameJobAsSyncData = syncData?.jobId == job.id;
    if (cancelingSameJobAsSyncData) {
      await this.addMomentToSyncData(moment, route, job.id);
      const syncData = await this.mwStore.getSyncData();
      params['moments'] = syncData.data.moments;
      params['to_dos'] = syncData.data.todos;
      params['job_rating'] = syncData.data.rating;
      params['did_to_dos'] = syncData.data.question || null;
      params['left_early'] = job.isRepeatClient && syncData.data.leftEarly;
    } else {
      const momentData = await this.buildMoment(moment, route, job.id);
      params['moments'] = [momentData];
    }

    const url = `api/v1/homekeeper/cleanings/${job.id}/leave-cleaning`;
    await this.httpClient.post(url, params);

    const jobInProgressId = await this.mwStore.getJobId();
    const cancelingSameJobAsInProgress = jobInProgressId == job.id;
    if (cancelingSameJobAsInProgress) {
      await this.mwStore.clearStore();
    }
  }

  async uploadLocationPicture() {
    const uploadedLocationPicture = await this.storage.retrieve('uploadedLocationPicture');
    if (uploadedLocationPicture) {
      return uploadedLocationPicture;
    }
    const locationPicture = await this.mwStore.getLocationPicture();
    if (locationPicture) {
      try {
        const job = await this.mwStore.getJob();
        const awsObjectKey = `location-verification-photo/${job.id}`
        await this.aws.uploadImageToS3(locationPicture.base64String, awsObjectKey);
        await this.storage.save('uploadedLocationPicture', awsObjectKey);
        return awsObjectKey;
      } catch (error) {
        this.logger.error(error, 'Error uploading confirm address photo.');
      }
    }
    return null;
  }

  async fetchTodosPhotoNotes(todosData) {
    const allPhotosArray = await Promise.all(todosData?.rooms?.map(async room => {
      let roomPhotos;
      if(room.photo_notes) {
        roomPhotos = await this.photosCache.loadPhotoNotes(room.photo_notes);
      }
      const todosPhotosArray = await Promise.all(room.tasks.map(async todo => {
        if(todo.photo_notes) {
         return await this.photosCache.loadPhotoNotes(todo.photo_notes);
        }
      }));
      const todosPhotos = arrayUtils.arrayOfObjToObject(todosPhotosArray);
      return {...roomPhotos, ...todosPhotos};
    }));
    const allPhotos = arrayUtils.arrayOfObjToObject(allPhotosArray);
    return this.photosCache.save(allPhotos);
  }

  async fetchAddressPhotoNotes(jobData) {
    const addressPhotosKeys = ['addressPhotosAccess', 'addressPhotosClosing', 'addressPhotosParking'];
    const addressPhotosArray = await Promise.all(addressPhotosKeys.map(async photoKey => {
      return await this.photosCache.loadPhotoNotes(jobData[photoKey]);
    }));
    const addressPhotosObj = arrayUtils.arrayOfObjToObject(addressPhotosArray);
    return this.photosCache.save(addressPhotosObj);
  }

  formatReservationDate(date) {
    const isoDate = LuxonDateTime.fromISO(date);
    return isoDate.toFormat('EEE MMM dd, yyyy');
  }

  formatReservationTime(time) {
    const isoDate = LuxonDateTime.fromISO(time, { zone: 'utc' });
    return isoDate.toFormat('h:mma').toLowerCase();
  }

  getReservationLength(checkInDate, checkOutDate) {
    const isoCheckInDate = LuxonDateTime.fromISO(checkInDate);
    const isoCheckOutDate = LuxonDateTime.fromISO(checkOutDate);
    const numberDays = isoCheckOutDate.diff(isoCheckInDate, 'days').days;
    return ' (' + numberDays + ((numberDays == 1) ? ' day)' : ' days)');
  }

  async goToMWFinishJobPage(mwJob) {
    if (mwJob.showTimer) {
      const isTooEarly = await this.isTooEarlyToFinish();
      if (isTooEarly) {
        await this.addMomentToSyncData(mwMoments.finishedToDosEarly, 'to-dos', mwJob.id);
        return this.navCtrl.navigateForward('finish-early');
      }
    }
    if (await this.isFarFromHome()) {
      await this.addMomentToSyncData(mwMoments.finishedToDosFarFromHome, 'to-dos', mwJob.id);
      return this.navCtrl.navigateForward('finish-far-from-client');
    }
    await this.addMomentToSyncData(mwMoments.finishedToDos, 'to-dos', mwJob.id);
    this.mwStore.setRoute('finish-job')
    this.navCtrl.navigateForward('finish-job');
  }
  
}
