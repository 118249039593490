import { Component, ViewEncapsulation } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

@Component({
    selector: 'introduction-page',
    templateUrl: 'introduction.html',
    encapsulation: ViewEncapsulation.None
  })

export class IntroductionPage {

  carrouselCards = [
    {
      title: 'TIDY.com for Pros',
      body: 'TIDY.com for Pros helps you earn more money on your terms.'
    },
    {
      title: 'Earn More',
      body: 'Get Clients when you want, let your customers book you online, and collect payments.'
    },
    {
      title: 'Save Money',
      body: 'Smart drive time optimization limits how much you drive. Track mileage and equipment usage to write off on taxes. Never pay more than you have to.'
    },
    {
      title: 'Save Time',
      body: 'Send reminders, collect payments from customers, let Clients download invoices and reschedule themselves, and more.'
    },
    {
      title: 'Delight Clients',
      body: 'Let Clients see maps of you coming to them, track what they want done in their home, and let them do things in their online portal.'
    }
  ]

  constructor(
    private customNavCtrl: CustomNavController,
    private iabUtils: InAppBrowserUtils
  ) {}

  goToLogin() {
    this.customNavCtrl.navigateForward('login');
  }

  createAccount() {
    this.customNavCtrl.navigateForward('create-account');
  }

  openAgreement = () => {
    this.iabUtils.openUrl('https://www.tidy.com/legal/service-provider/');
  }

}
