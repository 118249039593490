<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="isTooEarly ? 'Not Ready to Leave' : 'Ready to Leave'"
    [customBack]="backPage"
    [showLinkIcon]="true"
    [linkIcon]="'call'"
    (linkAction)="goToContactPage()">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'" *ngIf="distanceFromJob && !isLoadingDistanceToJob">
        <tidy-text
          [body]="('You are located <b>' | translate) + distanceFromJob + (' </b> away.' | translate)">
        </tidy-text>
      </tidy-row>

      <tidy-alert *ngIf="job?.hasSameDayCheckIn">
        <tidy-row class="extra-bottom-padding">
          <tidy-image
            [src]="'assets/img/green-alert.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Time Sensitive'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="job?.client + ' ' + (job?.last_name | abbrLastName: true)"
            [translate]="false">
          </tidy-text>
          <tidy-text
            [body]="' has a guest checking in '">
          </tidy-text>
          <tidy-text
            *ngIf="job?.guest_reservation_data?.next_guest_reservation?.check_in_time"
            [body]="('at ' | translate) + mwService.formatReservationTime(job?.guest_reservation_data?.next_guest_reservation?.check_in_time)">
          </tidy-text>
          <tidy-text
            [body]="' on the same day as the job.'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <tidy-row *ngIf="isLoadingDistanceToJob">
        <ion-skeleton-text animated="true" style="width: 140px; margin: auto"></ion-skeleton-text>
      </tidy-row>

      <slider-trigger
        [label]="'Tell Client I\'m On the Way'"
        [onTriggeredAction]="notifyOnTheWay.bind(this)">
      </slider-trigger>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-alert *ngIf="job?.isStandby">
        <tidy-text
          [body]="'This is a standby job. '">
        </tidy-text>
        <tidy-text
          [body]="'Learn more'"
          (action)="learnMoreStandby()"
          class="link">
        </tidy-text>
      </tidy-alert>

      <tidy-alert *ngIf="job?.other_pro && !job?.isStandby">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/person-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="('You will be cleaning with ' | translate) + job?.other_pro">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <tidy-alert *ngIf="job?.job_delegation_request?.status === 'requested' && job?.job_delegation_request?.role === 'delegator'">
        <tidy-row [alignLastItemRight]="true">
          <tidy-image
            [src]="'assets/svg/people-circle.svg'"
            class="body-size wiggle-animation">
          </tidy-image>
          <tidy-text
            [header]="'Pending Delegation'">
          </tidy-text>
          <tidy-text
            (action)="cancelDelegationRequest('cancel')"
            [body]="'Cancel'"
            class="link">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <ng-container *ngIf="job?.job_delegation_request?.delegatee?.name !== null && job?.job_delegation_request?.delegatee?.name !== undefined">
            <tidy-text
              [body]="'You requested that '">
            </tidy-text>
            <tidy-text
              *ngIf="job?.job_delegation_request?.delegatee?.name"
              [body]="job?.job_delegation_request?.delegatee?.name"
              [translate]="false">
            </tidy-text>
            <tidy-text
              *ngIf="!job?.job_delegation_request?.delegatee?.name"
              [body]="'a newly invited team member'">
            </tidy-text>
            <tidy-text
              [body]="' accept this job. We sent an email to '">
            </tidy-text>
            <tidy-text
              [body]="job?.job_delegation_request?.delegatee?.email +
              '. '"
              [translate]="false">
            </tidy-text>
          </ng-container>
          <tidy-text
            *ngIf="job?.job_delegation_request?.delegatee?.name === null && job?.job_delegation_request?.delegatee?.name === undefined"
            [body]="'You invited a new team member and requested that they accept this job.'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <ng-container *ngIf="job?.job_delegation_request?.status === 'accepted'">
        <tidy-alert *ngIf="job?.job_delegation_request?.role === 'delegatee'">
          <tidy-row [alignLastItemRight]="true">
           <tidy-image
              [src]="'assets/svg/people-circle.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [header]="'Job Delegated to You'">
            </tidy-text>
            <tidy-text
              (action)="cancelDelegationRequest('undo')"
              [body]="'Undo'"
              class="link">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'This job was delegated to you by '">
            </tidy-text>
            <tidy-text
              [body]="job?.job_delegation_request?.delegator?.name"
              [translate]="false">
            </tidy-text>
            <tidy-text
              [body]="'. All payment will be made to them.'">
            </tidy-text>
          </tidy-row>
        </tidy-alert>

        <tidy-alert *ngIf="job?.job_delegation_request?.role === 'delegator'">
          <tidy-row [alignLastItemRight]="true">
           <tidy-image
              [src]="'assets/svg/people-circle.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [header]="'Job Delegated'">
            </tidy-text>
            <tidy-text
              (action)="cancelDelegationRequest('getJobBack')"
              [body]="'Undo'"
              class="link">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'You delegated this job to '">
            </tidy-text>
            <tidy-text
              [body]="job?.job_delegation_request?.delegator?.name"
              [translate]="false">
            </tidy-text>
            <tidy-text
              [body]="'. All payment will be made to you.'">
            </tidy-text>
          </tidy-row>
        </tidy-alert>
      </ng-container>

      <tidy-card>
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/style-guide-images/bowtie-black.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="('Today ' | translate) +
            job?.startTime | formatDateTitle">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-grid>
            <ion-row>
              <ion-col size="4">
                <tidy-text
                  [body]="job?.duration">
                </tidy-text><br>
                <tidy-text
                  [body]="job?.startTime | tdate:'h:mma'">
                </tidy-text>
              </ion-col>
              <ion-col size="1">
                <tidy-image
                  *ngIf="job?.isRepeatClient"
                  [src]="'assets/svg/star-yellow.svg'"
                  class="body-size">
                </tidy-image>
              </ion-col>
              <ion-col size="7" >
                <tidy-text
                  [body]="job?.client + ' ' + (job?.last_name | abbrLastName: true)"
                  [translate]="false">
                </tidy-text>
                <br>
                <tidy-text
                  *ngIf="job?.job_delegation_request?.role !== 'delegatee'"
                  [body]="job?.categoryName + ': ' + (job?.payment_price | tcurrency)">
                </tidy-text>
                <tidy-text
                  *ngIf="job?.job_delegation_request?.role === 'delegatee'"
                  [body]="job?.categoryName">
                </tidy-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
        <tidy-row *ngIf="job?.isPremium">
          <tidy-text
            [actionBody]="'Big Spender: They book many jobs.'"
            class="green">
          </tidy-text>
        </tidy-row>
        <ng-container *ngIf="!job?.job_delegation_request?.status">
          <tidy-row class="extra-top-padding extra-bottom-padding">
            <tidy-divider>
            </tidy-divider>
          </tidy-row>
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/people-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              (action)="goToDelegatePage()"
              [body]="'Delegate to Team Member'"
              class="link">
            </tidy-text>
          </tidy-row>
        </ng-container>
      </tidy-card>

      <tidy-card *ngIf="job?.guest_reservation_data?.next_guest_reservation || job?.guest_reservation_data?.previous_guest_reservation">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/reservation.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Rental Information'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="job?.client"
            [translate]="false">
          </tidy-text>
          <tidy-text
            [body]="' rents out their property.'">
          </tidy-text>
        </tidy-row>
        <tidy-row [ngClass]="job?.guest_reservation_data?.next_guest_reservation ? 'extra-bottom-padding' : ''" *ngIf="job?.guest_reservation_data?.previous_guest_reservation">
          <tidy-text
            [body]="'<strong>Last Reservation:</strong>'">
          </tidy-text><br>
          <tidy-text
            [body]="mwService.formatReservationDate(job?.guest_reservation_data?.previous_guest_reservation?.check_in_date) + ' - ' + mwService.formatReservationDate(job?.guest_reservation_data?.previous_guest_reservation?.check_out_date) + mwService.getReservationLength(job?.guest_reservation_data?.previous_guest_reservation?.check_in_date, job?.guest_reservation_data?.previous_guest_reservation?.check_out_date)">
          </tidy-text>
          <ng-container *ngIf="previousGuestReservationDetails">
            <br>
            <tidy-text
              [body]="'Guests: '"
              *ngIf="previousGuestReservationDetails?.total_guests || previousGuestReservationDetails?.total_adults || previousGuestReservationDetails?.total_children || previousGuestReservationDetails?.total_pets">
            </tidy-text>
            <tidy-text
              *ngIf="previousGuestReservationDetails?.total_guests"
              [body]="previousGuestReservationDetails?.total_guests + (' guest(s)' | translate) + (previousGuestReservationDetails?.total_adults || previousGuestReservationDetails?.total_children || previousGuestReservationDetails?.total_pets ? (' | ' | translate) : '')">
            </tidy-text>
            <tidy-text
              *ngIf="previousGuestReservationDetails?.total_adults"
              [body]="previousGuestReservationDetails?.total_adults + (' adult(s)' | translate) + (previousGuestReservationDetails?.total_children || previousGuestReservationDetails?.total_pets ? (' | ' | translate) : '')">
            </tidy-text>
            <tidy-text
              *ngIf="previousGuestReservationDetails?.total_children"
              [body]="previousGuestReservationDetails?.total_children + (' children' | translate) + (previousGuestReservationDetails?.total_pets ? (' | ' | translate) : '')">
            </tidy-text>
            <tidy-text
              *ngIf="previousGuestReservationDetails?.total_pets"
              [body]="previousGuestReservationDetails?.total_pets + (' pet(s)' | translate)">
            </tidy-text>
          </ng-container>
          <ng-container *ngIf="job?.guest_reservation_data?.previous_guest_reservation?.check_out_time">
            <br>
            <tidy-text
              [body]="mwService.formatReservationTime(job?.guest_reservation_data?.previous_guest_reservation?.check_out_time) + (' check out' | translate)">
            </tidy-text>
          </ng-container>
          <ng-container *ngIf="previousGuestReservationDetails?.customFields">
            <ng-container *ngFor="let customField of previousGuestReservationDetails?.customFields">
              <tidy-row>
                <tidy-text
                  [body]="customField">
                </tidy-text>
              </tidy-row>
            </ng-container>
          </ng-container>
        </tidy-row>
        <tidy-row *ngIf="job?.guest_reservation_data?.next_guest_reservation">
          <tidy-text
            [body]="'<strong>Next Reservation:</strong>'">
          </tidy-text><br>
          <tidy-text
            [body]="mwService.formatReservationDate(job?.guest_reservation_data?.next_guest_reservation?.check_in_date) + ' - ' + mwService.formatReservationDate(job?.guest_reservation_data?.next_guest_reservation?.check_out_date) + mwService.getReservationLength(job?.guest_reservation_data?.next_guest_reservation?.check_in_date, job?.guest_reservation_data?.next_guest_reservation?.check_out_date)">
          </tidy-text>
          <ng-container *ngIf="nextGuestReservationDetails">
            <br>
            <tidy-text
              [body]="'Guests: '"
              *ngIf="nextGuestReservationDetails?.total_guests || nextGuestReservationDetails?.total_adults || nextGuestReservationDetails?.total_children || nextGuestReservationDetails?.total_pets">
            </tidy-text>
            <tidy-text
              *ngIf="nextGuestReservationDetails?.total_guests"
              [body]="nextGuestReservationDetails?.total_guests + (' guest(s)' | translate) + (nextGuestReservationDetails?.total_adults || nextGuestReservationDetails?.total_children || nextGuestReservationDetails?.total_pets ? (' | ' | translate) : '')">
            </tidy-text>
            <tidy-text
              *ngIf="nextGuestReservationDetails?.total_adults"
              [body]="nextGuestReservationDetails?.total_adults + (' adult(s)' | translate) + (nextGuestReservationDetails?.total_children || nextGuestReservationDetails?.total_pets ? (' | ' | translate) : '')">
            </tidy-text>
            <tidy-text
              *ngIf="nextGuestReservationDetails?.total_children"
              [body]="nextGuestReservationDetails?.total_children + (' children' | translate) + (nextGuestReservationDetails?.total_pets ? (' | ' | translate) : '')">
            </tidy-text>
            <tidy-text
              *ngIf="nextGuestReservationDetails?.total_pets"
              [body]="nextGuestReservationDetails?.total_pets + (' pet(s)' | translate)">
            </tidy-text>
          </ng-container>
          <ng-container *ngIf="job?.guest_reservation_data?.next_guest_reservation?.check_in_time">
            <br>
            <tidy-text
              [body]="mwService.formatReservationTime(job?.guest_reservation_data?.next_guest_reservation?.check_in_time) + (' check in' | translate)">
            </tidy-text>
          </ng-container>
          <ng-container *ngIf="nextGuestReservationDetails?.customFields">
            <ng-container *ngFor="let customField of nextGuestReservationDetails?.customFields">
              <tidy-row>
                <tidy-text
                  [body]="customField">
                </tidy-text>
              </tidy-row>
            </ng-container>
          </ng-container>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row [alignLastItemRight]="true">
          <tidy-image
            [src]="'assets/svg/home.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Address'">
          </tidy-text>
          <tidy-text
           [body]="'Get Directions'"
           (action)="mwService.getDirections(job, route)"
           class="link">
         </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="job?.address"
            [translate]="false">
          </tidy-text>
          <ng-container *ngIf="job?.addressUnit">
            <br>
            <tidy-text
              [body]="job?.addressUnit">
            </tidy-text>
          </ng-container>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row class="vertical-align-center">
         <tidy-image class="body-size"
            [src]="'assets/svg/car.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Parking'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text [body]="'<i>See parking notes on next screen</i>'">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/lock-open.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Access'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            body="<i>See access notes on next screen</i>">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-card *ngIf="job?.introVideo">
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/videocam.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Intro Video'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            body="<i>See intro video on next screen</i>">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-grid>
        <ion-row>
          <ion-col size="3">
            <tidy-round-button
              class="black"
              [icon]="'assets/svg/close-circle-white.svg'"
              (action)="goToCallOutPage()"
              [label]="'Call Out'">
            </tidy-round-button>
          </ion-col>
        </ion-row>
      </tidy-grid>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
