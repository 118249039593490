<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="('Reschedule ' | translate) + (rescheduleType | titlecase)">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-alert>
        <redo-alert-section
          [cancelledBy]="cancelledBy"
          [withFee]="withFee"
          [rescheduleType]="rescheduleType"
          [frequency]="currentFrequency"
          [date]="currentDate"
        ></redo-alert-section>
      </tidy-alert>

      <tidy-card>
        <client-and-adress-section
          [clientName]="clientName"
          [addressName]="addressName"
        ></client-and-adress-section>
      </tidy-card>

      <tidy-card>
        <private-reschedule-form
          [submitted]="submitted"
          [rescheduleType]="rescheduleType"
          [disableFrequencyDropdown]="cannotChangeFrequency"
          [teamServiceTypes]="teamServiceTypes"
          [teamServiceItems]="teamServiceItems"
          [formData]="formInitData"
        >
        </private-reschedule-form>
      </tidy-card>

      <tidy-button
        [text]="'Next'"
        [action]="save.bind(this)"
        class="primary">
      >
      </tidy-button>

      <footer-informations></footer-informations>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
