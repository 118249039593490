import { Component, ViewEncapsulation } from '@angular/core';

import { Certification } from 'src/providers/certification/certification';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PrivateClient } from 'src/providers/private-client/private-client';

import { Loading } from 'src/shared/components/loading/loading';

import { CertificationsModel } from 'src/shared/models/certification.model';
import { PrivateJobModel } from 'src/shared/models/private-job.model';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';
import { Timeout } from 'src/shared/components/timeout/timeout';

@Component({
  templateUrl: 'unpaid-jobs.html',
  encapsulation: ViewEncapsulation.None
})

export class UnpaidJobsPage extends Timeout {

  certifications: CertificationsModel
  errorMessage: string;
  unpaidJobs: PrivateJobModel[];
  noUnpaidJobCards: any;

  constructor(
    private certification: Certification,
    private iabUtils: InAppBrowserUtils,
    private navCtrl: CustomNavController,
    private privateClient: PrivateClient,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router,
    route: ActivatedRoute
  ) {
    super(timeoutErrorHandler, router, route);
  }

  @Loading()
  async ionViewDidEnter() {
    this.loaded = false;
    try {
      this.certifications = this.navCtrl.getParam('certifications') || await this.certification.getCertifications();
      this.unpaidJobs = this.navCtrl.getParam('unpaidJobs') || await this.privateClient.getUnpaidJobs();
      this.noUnpaidJobCards = this.getNoUnpaidJobCards();
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  goToPrivateJobPage(job) {
    const params = {
      cameFromUnpaidJobsPage: true
    }
    this.navCtrl.navigateForward(`private-job/${job.id}`, params);
  }

  getNoUnpaidJobCards() {
    return [
      {
        text: 'Send payment requests (invoices) instantly',
        image: 'assets/img/lightning.svg'
      },
      {
        text: 'Clients pay the way you want (including credit card)',
        image: 'assets/img/check.svg'
      },
      {
        text: 'Automatic reminders ensure you get paid',
        image: 'assets/img/reminders.svg'
      }
    ];
  }

  learnMore() {
    this.iabUtils.openUrl('https://help.tidy.com/pros/proposals');
  }

  goToAddJobPage() {
    this.navCtrl.navigateForward('add-private-job');
  }
}
