<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Job Requested'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="('Your team leader ' | translate) +
          delegatorName +
          (' invited you to accept this job. ' | translate) +
          ('If you accept, you will be expected to complete the job. ' | translate) +
          ('Contact ' | translate) +
          delegatorName +
          (' for any questions.' | translate)">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/calendar-outline.svg'">
          </tidy-image>
          <tidy-text
            [body]="jobDate +
            (' at ' | translate) +
            jobTime">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center">
         <tidy-image
            [src]="'assets/svg/time-outline.svg'">
          </tidy-image>
          <tidy-text
            [body]="jobDuration +
            (' hour job' | translate)">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-error-message
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-button
        [action]="showConfirm.bind(this, 'acceptJob')"
        [text]="'Accept Job'"
        class="primary">
      </tidy-button>

      <tidy-button
        [action]="showConfirm.bind(this, 'rejectJob')"
        [text]="'Reject Job'"
        class="secondary">
      </tidy-button>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
