<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Payout Settings'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="payoutSetting == 'check'">
      <tidy-card>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/mail.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Check'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'US law requires us to collect info from you for tax reporting purposes, typically using a form W-9: '">
          </tidy-text>
          <tidy-text
            (action)="openIRSLink()"
            [actionBody]="'https://www.irs.gov/pub/irs-pdf/fw9.pdf'"
            class="link">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="'We already have your name, address, on file. If any of these are incorrect, please go back. The last remaining info for the IRS W-9 is a SSN or EIN.'">
          </tidy-text>
        </tidy-row>
        <form [formGroup]="form">
          <tidy-row>
            <tidy-radio-button
              [label]="'Which number do you want to use?'"
              formControlName="ssnOrEin"
              [form]="form.controls.ssnOrEin"
              [items]="ssnOrEinItems"
              [submitted]="submitted"
              (optionChange)="changePersonalNumberLabel($event)"
              [errorMessage]="'Please select a type'">
            </tidy-radio-button>
          </tidy-row>
          <tidy-row class="field-top-padding">
            <tidy-input
              formControlName="personalNumber"
              [label]="ssnOrEinLabel"
              [errorMessage]="'Please enter a valid number'"
              [form]="form.controls.personalNumber"
              [icon]="'assets/svg/information-circle-outline.svg'"
              [submitted]="submitted">
            </tidy-input>
          </tidy-row>
        </form>
      </tidy-card>
      <tidy-button
        [text]="'Save Setting'"
        [action]="addCheckInfo.bind(this)"
        class="primary">
      </tidy-button>
    </ng-container>

    <ng-container *ngIf="payoutSetting == 'direct_deposit'">
      <tidy-card>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/business.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Direct Deposit'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'Add bank info for your direct deposit:'">
          </tidy-text>
        </tidy-row>
        <form [formGroup]="form">
          <tidy-row class="field-top-padding field-bottom-padding">
            <tidy-input
              [inputMode]="'numeric'"
              formControlName="routing_number"
              [label]="'Routing (ABA) Number'"
              [errorMessage]="'Please enter a valid routing number'"
              [form]="form"
              [icon]="'assets/svg/help-circle-outline.svg'"
              [activeIcon]="'assets/svg/information-circle-outline-tidy-green.svg'"
              [submitted]="submitted">
            </tidy-input>
          </tidy-row>
          <tidy-row class="field-bottom-padding">
            <tidy-input
              [inputMode]="'numeric'"
              formControlName="account_number"
              [label]="'Account number'"
              [errorMessage]="'Please enter a valid account number'"
              [form]="form"
              [icon]="'assets/svg/help-circle-outline.svg'"
              [activeIcon]="'assets/svg/information-circle-outline-tidy-green.svg'"
              [submitted]="submitted">
            </tidy-input>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-input
              [inputMode]="'numeric'"
              formControlName="confirm_account_number"
              [label]="'Confirm account number'"
              [errorMessage]="'Please enter a valid account number'"
              [form]="form"
              [icon]="'assets/svg/help-circle-outline.svg'"
              [activeIcon]="'assets/svg/information-circle-outline-tidy-green.svg'"
              [submitted]="submitted">
            </tidy-input>
          </tidy-row>
        </form>
        <tidy-row>
          <tidy-text
            [body]="'We use Stripe to securely process all payments. Stripe is a large multinational payment processing company with bank grade security '">
          </tidy-text>
          <tidy-text
            (action)="learnMoreStripe()"
            [body]="'Learn More'"
            class="link">
          </tidy-text>
          <tidy-text
            [body]="'.'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'After you add your bank info, Stripe will ask standard identification information required by banks as a part of Know Your Customer laws.'">
          </tidy-text>
        </tidy-row>
      </tidy-card>
      <tidy-button
        [text]="'Add Bank Info & Go to Verify'"
        [action]="addDirectDepostInfo.bind(this)"
        class="primary">
      </tidy-button>
    </ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
