import { Component } from '@angular/core';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

@Component({
  selector: 'contact-support',
  templateUrl: 'contact-support.html'
})

export class ContactSupportPage {

  constructor(
    private iabUtils: InAppBrowserUtils
  ) {}

  goToDocumentation() {
    this.iabUtils.openUrl('https://help.tidy.com/pros');
  }
}
