import { Injectable } from '@angular/core';
import { HttpClient } from 'src/shared/providers/http/http-client';
import * as moment from 'moment';
import { Me } from 'src/shared/providers/me';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Injectable()
export class ClaimJobs {

  constructor(
    private httpClient: HttpClient,
    private me: Me
  ) {}

  async parseDays(lms: any[]) {
    const days = [];
    const distanceFormat = await this.me.getDistanceFormatValue();
    const cleanings = lms.map(  item => {
      if (distanceFormat === 'mi') {
        item.real_distance = `${item.distance_mi.toFixed(0)} ${new TranslationPipe().transform('Miles from Home')}`;
      } else {
        item.real_distance = `${item.distance_mi.toFixed(0) * 1.60934} ${new TranslationPipe().transform('Kilometers from Home')}`;
      }
      this.extractDays(days, item);
      return item;
    });
    let currentWeek = {
      days: days.sort( (a, b) => a.date.diff(b.date))
    };
    currentWeek['jobs'] = this.groupJobsByJobId(currentWeek);
    this.filterOneJobIdPerDay(currentWeek);
    return {
      currentWeek: currentWeek,
      noItemsAvailable: cleanings.length === 0
    }
  }

  groupJobsByJobId(currentWeek) {
    const group: any = [];
    currentWeek.days.map((day) => {
      day.cleanings.map((cleaning) => {
        let alreadyAdded = false;
        group.map((item) => {
          if (item.job_id == cleaning.job_id) {
            alreadyAdded = true;
          }
        })
        if (!alreadyAdded) {
          group.push({
            job_id: cleaning.job_id,
            jobs: [
              cleaning
            ]
          });
        } else {
          const index = group.findIndex(item => item.job_id === cleaning.job_id);
          group[index].jobs.push(cleaning);
        }
      });
    })
    return group;
  }

  filterOneJobIdPerDay(currentWeek) {
    currentWeek.days.map((day) => {
      day['filteredCleanings'] = [];
      day.cleanings.map((cleaning, i) => {
        const cleaningAlreadyAdded = day['filteredCleanings'].find((item) => item.job_id == cleaning.job_id);
        if (!cleaningAlreadyAdded) {
          const object = {
            ...cleaning,
            lastTimeThatDay: this.getLastTimeThatDay(cleaning.job_id, day.cleanings)
          }
          day['filteredCleanings'].push(object);
        }
      });
    });
  }

  getLastTimeThatDay(jobId, cleanings) {
    let lastTime = null;
    let count = 0;
    cleanings.map((cleaning) => {
      if (cleaning.job_id == jobId) {
        count += 1;
        lastTime = cleaning.time;
      }
    });
    if (count == 1) {
      return null;
    } else {
      return lastTime;
    }
  }

  getJobsFromSameClient(currentWeek, cleaning) {
    const datesFromSameClient: any = [];
    currentWeek.days.map(day => {
      day.cleanings.map(time => {
        const isSameClient = time.customer_id == cleaning.customer_id;
        if (isSameClient) {
          datesFromSameClient.push(time);
        }
      });
    });
    const formattedDates: any = [];
    const cleanings = datesFromSameClient.map(item => {
      this.extractDays(formattedDates, item);
      return item;
    });
    const sortedDates = formattedDates.sort((a, b) => a.date.diff(b.date));
    sortedDates.forEach(item => {
      item.date = item.date.toString();
    });
    return sortedDates;
  }

  extractDays(days, cleaning): void {
    const day = days.find( d => d.formatedDate == cleaning.date);
    if (day) {
      day.cleanings.push(cleaning);
    } else {
      const date = moment(cleaning.date, 'YYYY-MM-DD');
      days.push({
        date,
        dateLabel: date.format('dddd M/D'),
        formatedDate: cleaning.date,
        cleanings: [cleaning]
      });
    }
  }

}
