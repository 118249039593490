import { Injectable } from '@angular/core';
import { HttpClient } from '../../shared/providers/http/http-client';

export interface NotificationSettingsDayModel {
	value: string;
  label: string;
  checked: boolean;
  times: string[];
}

export interface NotificationSettingsDayTimesModel {
	value: string;
  label: string;
  checked: boolean;
}

@Injectable()
export class NotificationSettings {

  constructor(
    private httpClient: HttpClient
  ) {}

  getNotificationSettings() {
    const url = `api/v1/homekeeper/notification-time-settings`;
    return this.httpClient.get(url);
  }

  changeNotificationSetting(data) {
    const url = `api/v1/homekeeper/notification-time-settings/weekday`;
    return this.httpClient.post(url, data);
  }

  buildNotificationDayTimes(times): NotificationSettingsDayTimesModel[] {
    let dayTimes = [
        { value:'08:00', label: '8:00am - 9:30am', checked: false },
        { value:'09:30', label: '9:30am - 11:00am', checked: false },
        { value:'11:00', label: '11:00am - 12:30pm', checked: false },
        { value:'12:30', label: '12:30pm - 2:00pm', checked: false },
        { value:'14:00', label: '2:00pm - 3:30pm', checked: false },
        { value:'15:30', label: '3:30pm - 5:00pm', checked: false },
        { value:'17:00', label: '5:00pm - 6:30pm', checked: false },
        { value:'18:30', label: '6:30pm - 8:00pm', checked: false },
        { value:'20:00', label: '8:00pm - 9:30pm', checked: false },
        { value:'21:30', label: '9:30pm - 10:30pm', checked: false },
    ]
    return dayTimes.map((time) => {
      time.checked = times.length > 0 && times.includes(time.value);
      return time;
    })
  }

  buildNotificationDays(notificationSettings, type): NotificationSettingsDayModel[] {
    const times = ["08:00", "09:30", "11:00", "12:30", "14:00", "15:30", "17:00", "18:30", "20:00", "21:30"];
    let days = [
      { value:'0', label: 'Mondays', checked: true, times: times},
      { value:'1', label: 'Tuesdays', checked: true, times: times},
      { value:'2', label: 'Wednesdays', checked: true, times: times},
      { value:'3', label: 'Thursdays', checked: true, times: times},
      { value:'4', label: 'Fridays', checked: true, times: times},
      { value:'5', label: 'Saturdays', checked: true, times: times},
      { value:'6', label: 'Sundays', checked: true, times: times},
    ]
    if (notificationSettings.weekdays) {
      notificationSettings.weekdays.map((day) => {
        const daySettings = day[type];
        if (daySettings) {
          const dayValue = day.weekday;
          const dayTimes = daySettings.times;
          days[dayValue].times = dayTimes;
          days[dayValue].checked = dayTimes.length > 0;
        }
      })
    }
    return days;
  }
}
