<ion-header>
  <ion-toolbar>
    <ion-title>Retention Score</ion-title>
  </ion-toolbar>
</ion-header>

<ng-template #content padding>
  <section class="cards">
    <ion-card>
      <ion-card-header>
        <tidy-image class="body-size" src="assets/svg/star-tidy-green.svg"></tidy-image> {{ retentionScore || '--'}}% Retention Score
      </ion-card-header>
      <ion-card-content>
        <p>
          <span *ngIf="retentionScore; else completeMoreCleanings">Clients usually choose Homekeepers with higher scores.</span>
          <ng-template #completeMoreCleanings>You have not completed enough cleanings to get a score yet. Complete more cleanings to build a score.</ng-template>
        </p>
      </ion-card-content>

      <ion-list>
        <ion-item text-wrap>
          <strong>How good is my score?</strong>
          <p>The average retention score is 60-70%, the lowest score is 0%, and the highest is 100%. Over 70% is better than average</p>
        </ion-item>
        <ion-item text-wrap>
          <strong>How is my score used?</strong>
          <p>Many clients will book with the best available Homekeeper for a time. If 2 Homekeepers are available for the client's requested time, the one with the higher retention score wins the booking.</p>
        </ion-item>
        <ion-item text-wrap>
          <strong>How is my score determined?</strong>
          <p>The score is technically ((# of recurring clients who stopped using TIDY) + (# of clients who blacklisted you)) / (# of recurring cleanings over 90 days).</p>
          <p>Clients who schedule one time cleanings do not affect your score.</p>
          <p>In other words, if a client blacklists you or a recurring client cancels TIDY, that reduces your score. Keeping you paired clients is the only way to improve your score. The score is updated weekly.</p>
        </ion-item>
        <ion-item text-wrap>
          <strong>What if a client gives an unfair ranking?</strong>
          <p>Clients will occasionally not be fair. As we only use averages, and all Homekeeper are affected by this, it should not affect you much.</p>
        </ion-item>
      </ion-list>
    </ion-card>
  </section>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
