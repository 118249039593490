import { Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { StaticData } from 'src/shared/providers/static-data';

import { MySchedule } from 'src/providers/my-schedule/my-schedule';
import { Loading } from 'src/shared/components/loading/loading';


@Component({
  selector: 'page-edit-schedule',
  templateUrl: 'edit-schedule.html',
  encapsulation: ViewEncapsulation.None
})
export class EditSchedulePage {

  timeOpts = [];
  timeRangeForms: Array<UntypedFormGroup> = [];
  checkboxForm: UntypedFormGroup;
  params: {
    date: string;
    time_ranges?: Array<{start: string, end: string}>;
  };
  submitted: boolean;
  loaded: boolean;

  constructor(
    private customNavCtrl: CustomNavController,
    private staticData: StaticData,
    private formBuilder: UntypedFormBuilder,
    private mySchedule: MySchedule
  ) {
    this.timeOpts = this.staticData.timeRanges();
    this.checkboxForm = this.formBuilder.group({
      available: true
    });
  }

  @Loading()
  async ionViewDidEnter() {
    this.loaded = false;
    this.timeRangeForms = [];
    this.params = this.customNavCtrl.getParams();

    this.params.time_ranges.forEach(timeBlock => {
      this.mySchedule.addTimeRange(timeBlock.start, timeBlock.end, this.timeRangeForms);
    });
    this.checkboxForm.controls.available.setValue(this.timeRangeForms.length > 0);
    this.loaded = true;
  }

  save(singleDay: boolean): void {
    this.submitted = true;

    const formValid = this.timeRangeForms.every(formGroup => formGroup.valid);

    if(!formValid) {
      return;
    }

    const formData = this.timeRangeForms.map(formGroup => {
      return formGroup.value;
    });

    const scheduleParams = {
      start_date: this.params.date,
      time_blocks: formData,
      schedule_type: singleDay ? 'single_time' : 'recurring'
    };

    const selectedTimesText = this.staticData.selectedTimesText(formData);

    this.customNavCtrl.navigateForward('confirm-schedule', { date: this.params.date, selectedTimesText, singleDay, scheduleParams });
  }

  saveSingleDay() {
    this.save(true);
  }

  saveAllDays() {
    this.save(false);
  }

  updateValidity() {
    this.timeRangeForms.forEach(form => {
      form.updateValueAndValidity();
    });
  }

  checkDay() {
    this.mySchedule.addNewTimeRange(this.timeRangeForms.length, this.timeRangeForms);
  }

  removeTimeRange(index: number) {
    this.timeRangeForms.splice(index, 1);
    this.checkboxForm.controls.available.setValue(this.timeRangeForms.length > 0);
  }

  endTimes(startTime: string) {
    if (!startTime) return [];
    
    return this.timeOpts.filter(opt => {
      return opt.value > startTime;
    });
  }
}
