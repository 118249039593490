<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [customBack]="'/private-client/' + clientId"
    [title]="'More Billing Options'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Viewing billing options for '">
        </tidy-text>
        <tidy-text
          [body]="(client?.account_name && client?.account_name !== '' ? client?.account_name : (client?.first_name | titlecase)) + '.'"
          [translate]="false">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <tidy-row [alignLastItemRight]="true">
          <div class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/cash.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Balance'">
            </tidy-text>
          </div>
          <tidy-text
            [body]="(billingHistory?.balance | tcurrency)">
          </tidy-text>
        </tidy-row>
        <tidy-card-button (action)="goToRequestPaymentPage()">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/document-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="'Request Payment'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToInvoicesPage()">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/documents-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="'Invoice History'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToBillingHistoryPage()">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/chatbox-ellipses-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="'Billing History'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToAddCreditPage()">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/add-circle-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="'Add Credit'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
