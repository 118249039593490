import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'page-check-for-updates',
  templateUrl: 'check-for-updates.html'
})
export class CheckForUpdatesPage {

  constructor(
    private modalController: ModalController
  ) {}

  close() {
    this.modalController.dismiss();
  }

}
