import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'serviceFilter'
})
export class ServiceFilterPipe implements PipeTransform {

  transform(value: Array<any>): Array<any> {
    if (value) {
      return value.filter( (item) => (
        item.service_type === 'tidy'
        || item.service_type === 'plus'
        || item.service_type === 'mighty'
      ));

    }
  }
}
