import { Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { MySchedule } from 'src/providers/my-schedule/my-schedule';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm';
import { SuccessPageParamsModel } from 'src/pages/success/success';

import { TidyDatePipe } from 'src/shared/pipes/tidy-date';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  selector: 'pause-schedule-date',
  templateUrl: 'pause-schedule-date.html',
  encapsulation: ViewEncapsulation.None
})
export class PauseScheduleDatePage {

  errorMessage: string;
  date: string;
  submitted = false;
  form: UntypedFormGroup;
  minDate: Object;

  constructor(
    private customNavCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private modalCtrl: ModalController,
    private mySchedule: MySchedule,
    private tidyDatePipe: TidyDatePipe
  ) {
    this.minDate = new Date();
    this.form = this.fb.group({
      date: ['', Validators.required],
      reason: ['', Validators.required],
    });
  }

  goBack = () => {
    this.customNavCtrl.navigateForward('pause-schedule');
  }

  pause = async () => {
    if (!this.form.valid) {
      return;
    }
    this.submitted = true;
    this.date = this.tidyDatePipe.transform(this.form.value.date, 'M/DD/YY');
    const params: ConfirmPageParamsModel = {
      title: 'Pause Schedule?',
      body: `${new TranslationPipe().transform('All scheduled jobs on')} ${this.date} ${new TranslationPipe().transform('and after will be cancelled.')} ${new TranslationPipe().transform('Normal client fees apply.')}`,
      backText: 'Go Back',
      confirmText: 'Pause Schedule',
      confirmAction: this.confirmPause.bind(this),
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false
    });
    confirmationModal.present();
  }

  async confirmPause() {
    this.errorMessage = '';
    const data = {
      pause_date: this.form.value.date,
      pause_reason: this.form.value.reason,
    };
    try{
      await this.mySchedule.pauseSchedule(data);
      const successParams: SuccessPageParamsModel = {
        header: 'Schedule Paused',
        body: `${new TranslationPipe().transform('Your schedule is paused starting on')} ${this.date}.`,
        buttonText: 'Ok',
        buttonRoute: `my-schedule`
      };
      this.customNavCtrl.navigateForward('success', successParams);
    } catch (err){
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
    this.modalCtrl.dismiss();
    this.submitted = false;
  }
  
}
