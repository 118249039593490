import { Component, Input, ViewEncapsulation } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Component({
  selector: 'timeout-modal',
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'timeout-modal.html'
})
export class TimeoutModal {

  @Input() retryAction: any;

  constructor(
    private modalController: ModalController,
    private customNavCtrl: CustomNavController
  ) {}

  retry() {
    this.retryAction();
    this.modalController.dismiss();
  }

  goToDashBoard() {
    this.modalController.dismiss();
    this.customNavCtrl.navigateRoot('dashboard');
  }
}
