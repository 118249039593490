<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [customBack]="backButtonRoute"
    [title]="'Edit Job Mileage'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper *ngIf="loaded">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'Edit mileage for your job with '">
      </tidy-text>
      <tidy-text
        [body]="jobMileage?.homekeeper_job?.client_name +
        ' '"
        [translate]="false">
      </tidy-text>
      <tidy-text
        [body]="'View Job'"
        (action)="viewJob()"
        class="link">
      </tidy-text>
    </tidy-row>

    <tidy-card>
      <form [formGroup]="form">
        <tidy-row class="field-top-padding field-bottom-padding">
          <tidy-input
            [label]="'Start Point'"
            [icon]="'assets/svg/play-circle-outline.svg'"
            formControlName="start"
            [form]="form.controls.start"
            [submitted]="submitted"
            [errorMessage]="'Please enter a start point'">
          </tidy-input>
        </tidy-row>

        <tidy-row class="field-bottom-padding">
          <tidy-input
            [label]="'End Point'"
            [icon]="'assets/svg/play-circle-outline.svg'"
            formControlName="end"
            [form]="form.controls.end"
            [submitted]="submitted"
            [errorMessage]="'Please enter an end point'">
          </tidy-input>
        </tidy-row>

        <tidy-row>
          <tidy-input
            [label]="distanceFormat === 'mi' ? 'Miles Travelled' : 'Kilometers Travelled'"
            [icon]="'assets/svg/car-outline.svg'"
            formControlName="miles"
            [form]="form.controls.miles"
            [submitted]="submitted"
            [errorMessage]="distanceFormat === 'mi' ? 'Please enter miles travelled' : 'Please enter kilometers travelled'">
          </tidy-input>
        </tidy-row>
      </form>
    </tidy-card>

    <tidy-row>
      <tidy-button
        [text]="'Save Changes'"
        [action]="saveChanges.bind(this)"
        class="primary">
      </tidy-button>
    </tidy-row>

    <tidy-error-message
      [text]="errorMessage">
    </tidy-error-message>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
