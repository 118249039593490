<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [canGoBack]="true"
    [class]="extraClass"
    [title]="'Agreement Management'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card>
      <tidy-row class="extra-bottom-padding">
        <tidy-text
          [header]="'Agreements with TIDY'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'You have opted to not use a custom agreement, and are currently subject to the terms available at https://www.tidy.com/legal/service-provider. To request a pdf copy of this agreement, please contact us. To request a custom agreement, contact legal@tidy.com at this time. '">
        </tidy-text>
        <tidy-text
          (action)="goToTerms()"
          [body]="'View Agreement'"
          class="link">
        </tidy-text>
      </tidy-row>
    </tidy-card>

    <tidy-card>
      <tidy-row class="extra-bottom-padding">
        <tidy-text
          [header]="'Agreements with Clients'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'Manage your agreements with clients here'">
        </tidy-text>
        <tidy-text
          [body]="'. Start by uploading a contract, then you can assign it to your clients, jobs and more. '">
        </tidy-text>
        <tidy-text
          (action)="goToLearnMoreClientAgreements()"
          [body]="'Learn More'"
          class="link">
        </tidy-text>
      </tidy-row>
    </tidy-card>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
