import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { PlanFrequencyPipe } from 'src/shared/pipes/tidy-plan-frequency';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';
import * as moment from 'moment';

@Component({
  selector: 'redo-alert-section',
  templateUrl: './redo-alert-section.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RedoAlertSectionComponent implements OnInit {
  @Input() cancelledBy: string;
  @Input() date: string;
  @Input() frequency: string;
  redoBodyText: string;
  @Input() rescheduleType: string;
  @Input() withFee: boolean;

  constructor(private planFrequencyPipe: PlanFrequencyPipe) {}

  ngOnInit() {
    this.redoBodyText = this.chooseRedoBodyText();
  }

  chooseRedoBodyText() {
    const frequency = this.planFrequencyPipe.transform(this.frequency);
    const isToShowDate = this.rescheduleType !== 'plan';
    const dateLabel = isToShowDate ? `on ${moment(this.date).format('dddd M/D')}` : '';
    if (this.cancelledBy === 'customer') {
      let clientBodyText = new TranslationPipe().transform('The Client is rescheduling');
      clientBodyText += ` ${frequency} ${this.rescheduleType} ${dateLabel}`;
      if (this.withFee) {
        clientBodyText = new TranslationPipe().transform('The Client is rescheduling');
        clientBodyText += ` ${frequency} ${this.rescheduleType} ${dateLabel} `;
        clientBodyText += new TranslationPipe().transform('and will be charged a cancellation fee.');
      }
      return clientBodyText;
    }
    if (this.cancelledBy === 'homekeeper') {
      let homekeeperBodyText = new TranslationPipe().transform('I am rescheduling');
      homekeeperBodyText += ` ${frequency} ${this.rescheduleType} ${dateLabel}.`;
      return homekeeperBodyText;
    }
  }
}
