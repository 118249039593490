import { Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { MWService } from "src/main-workflow/mw.service";
import { mwMoments } from 'src/main-workflow/mw.moments';
import { MWStore } from 'src/main-workflow/mw.store';

import { Concierge } from 'src/providers/concierge/concierge';
import { AttachmentModel } from 'src/models/concierge.model';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';
import validationUtils from 'src/shared/utils/validation-utils';
import { HomekeeperModel } from 'src/shared/models/homekeeper.model';
import { Me } from 'src/shared/providers/me';
import { Loading } from 'src/shared/components/loading/loading';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: 'contact-concierge.html',
  encapsulation: ViewEncapsulation.None
})

export class ContactConciergePage {

  addingEmailCC: boolean;
  correctPerformanceViewValue: string;
  errorMessage: string;
  email: string;
  form: UntypedFormGroup;
  metadata: any;
  otherText: string;
  replyTime: string;
  submitted: boolean;
  title: string;
  type: string;
  attachments: AttachmentModel[] = [];

  constructor(
    private concierge: Concierge,
    private fb: UntypedFormBuilder,
    private iabUtils: InAppBrowserUtils,
    private me: Me,
    private mwService: MWService,
    private mwStore: MWStore,
    private navCtrl: CustomNavController
  ) {
    this.form = this.fb.group({
      subject: ['', Validators.required],
      message: ['', Validators.required],
      email: ['']
    });
  }

  async ionViewDidEnter() {
    this.title = this.navCtrl.getParam('title', true);
    this.email = localStorage.getItem('proEmail');
    this.type = this.navCtrl.getParam('type', true);
    this.metadata = this.navCtrl.getParam('metadata');
    this.correctPerformanceViewValue = this.navCtrl.getParam('correctPerformanceViewValue');
    this.replyTime = this.concierge.getReplyTime(this.type);
    this.otherText = '\"Other\" is good if none of the other categories work. ';
  }

  async send() {
    this.errorMessage = '';
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    let data = {
    	type: this.type,
    	data: {
    		subject: this.form.value.subject,
    		body: this.form.value.message,
        emails: [],
        attachments: this.attachments.map(item => item.fileKey)
    	}
    };
    if (this.metadata) {
     data['metadata'] = this.metadata;
    }
    if (this.addingEmailCC) {
     data.data.emails.push(this.form.value.email);
    }
    try {
      if (data.type === 'support.homekeeper_job_issue') {
        await this.mwService.sendMomentToBackend(mwMoments.reportIssue, 'contact-concierge', this.metadata.job_id);
      }
      await this.concierge.addConciergeItem(data);
      const isMainWorkflow = this.navCtrl.getParam('isMainWorkflow');
      if (isMainWorkflow) {
        let storedIssues = await this.mwStore.getStoredIssues() || [];
        storedIssues.push({
          type: 'concierge-bell',
          title: this.form.value.subject,
          description: this.form.value.message
        });
        await this.mwStore.setStoredIssues(storedIssues);
      }
      this.goToSuccessPage();
    } catch (err) {
      this.errorMessage = err.message;
    }
  }

  goToSuccessPage() {
    const pathAfterReportIssue = localStorage.getItem('pathAfterReportIssue') || 'concierge';
    const params = {
      header: 'Message Sent',
      body: `${new TranslationPipe().transform('We expect to reply within')} ${this.replyTime}. ${new TranslationPipe().transform('We will reply both in the app and to your email')} ${this.email}`,
      buttonText: 'Ok',
      buttonRoute: pathAfterReportIssue
    };
    this.navCtrl.navigateForward('success', params);
  }

  goToScheduleCallPage() {
    this.iabUtils.openUrl('https://www.tidy.com/schedule-pro-support');
  }

  addEmailCC() {
    this.form.controls.email.setValidators([Validators.required, validationUtils.validateEmail]);
    this.addingEmailCC = true;
  }

  @Loading()
  async addAttachment() {
    this.errorMessage = '';
    try {
      const attachment = await this.concierge.addAttachment();
      if (attachment.filename !== '') {
        this.attachments.push(attachment);
      } else {
        this.errorMessage = 'Unable to attach photo. Please upload a PNG or JPEG file.';
      }
    } catch (err) {
      this.errorMessage = err;
    }
  }

  removeAttachment(attachIndex: number) {
    this.attachments.splice(attachIndex, 1);
  }

}
