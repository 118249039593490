<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Delegate Job'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'" *ngIf="eligiblePros?.length === 1 && ineligiblePros?.length === 0">
        <tidy-text
          [body]="'You haven\'t added any team members yet.  Add one to delegate them a job.'">
        </tidy-text>
      </tidy-row>

      <tidy-card *ngIf="eligiblePros?.length > 1 || ineligiblePros?.length > 0">
        <tidy-row>
          <tidy-text
            [header]="'Eligible Team Members'">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="eligiblePros?.length === 1">
          <tidy-text
            [helper]="'No team members eligible for this job.'">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="eligiblePros?.length > 0">
          <form [formGroup]="form">
            <tidy-radio-button
              [label]="eligiblePros?.length === 1 ? '' : 'Select Team Member'"
              formControlName="invite"
              [form]="form.controls.invite"
              [items]="eligiblePros"
              [submitted]="submitted"
              [errorMessage]="'Please select a team member.'"
              (optionChange)="changeProOption($event)">
            </tidy-radio-button>
          </form>
        </tidy-row>
      </tidy-card>

      <tidy-card *ngIf="ineligiblePros?.length > 0">
        <tidy-row>
          <tidy-text
            [header]="'Ineligible Team Members'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let pro of ineligiblePros">
          <tidy-row>
            <tidy-text
              [body]="pro?.name">
            </tidy-text><br>
            <tidy-text
              [helper]="pro?.unavailability_message">
            </tidy-text>
          </tidy-row>
        </ng-container>
      </tidy-card>

      <ng-container *ngIf="addingNewPro">
        <tidy-card>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [header]="'Add New Team Member'">
            </tidy-text>
          </tidy-row>
          <form [formGroup]="form" novalidate action="#">
            <tidy-row class="field-bottom-padding">
              <tidy-input
                [label]="'Email'"
                [icon]="'assets/svg/mail-outline.svg'"
                [activeIcon]="'assets/svg/mail-outline-tidy-green.svg'"
                formControlName="email"
                [form]="form.controls.email"
                [submitted]="submitted"
                [errorMessage]="'Please enter a valid email.'">
              </tidy-input>
            </tidy-row>
            <tidy-row>
              <tidy-input
                [label]="'First Name'"
                [icon]="'assets/svg/person-circle-outline.svg'"
                formControlName="firstName"
                [form]="form.controls.firstName"
                [submitted]="submitted"
                [errorMessage]="'Please enter a name.'">
              </tidy-input>
            </tidy-row>
          </form>
          <form [formGroup]="form" novalidate action="#">
            <tidy-row class="field-top-padding">
              <tidy-checkbox
                formControlName="understand"
                [items]="[{viewValue: 'I understand I\'m responsible for jobs I delegate to team members, including insurance, safety, compliance, legal, and employment obligations.', value: false}]">
              </tidy-checkbox>
            </tidy-row>
          </form>
        </tidy-card>
      </ng-container>

      <tidy-error-message
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-button
        [action]="delegateJob.bind(this)"
        [text]="addingNewPro ? 'Add & Ask to Accept' : 'Ask Team Member to Accept'"
        class="primary">
      </tidy-button>

      <tidy-alert>
        <tidy-row>
          <tidy-text
            [header]="'How it works:'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'1) The Team Member will receive an SMS / email & see a notification in their app.'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'2) You are still assigned to the job until the team member accepts the job.'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'3) All payment or fees incurred will be made to your profile'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
