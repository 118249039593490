import { Component } from '@angular/core';

import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';

@Component({
  selector: 'agreement-management',
  templateUrl: 'agreement-management.html'
})

export class AgreementManagementPage {

  constructor(
    private iabUtils: InAppBrowserUtils
  ) {}

  goToTerms() {
    this.iabUtils.openUrl('https://www.tidy.com/legal/service-provider');
  }

  goToLearnMoreClientAgreements() {
    this.iabUtils.openUrl('https://help.tidy.com/pros/proposals#GOl24');
  }

}
