const frequencies = {
  every_week: 'every week',
  every_other_week: 'every other week',
  twice_a_week: '2x per week',
  thrice_a_week: '3x per week',
  every_four_weeks: 'every four weeks',
  once: 'one time'
};

export default frequencies;
