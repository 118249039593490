import { MWJobModel } from 'src/main-workflow/mw.models';

import { TidyCurrencyPipe } from 'src/shared/pipes/tidy-currency';

import { DateTime as LuxonDateTime } from 'luxon';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

export function parseJobData(job, bookingNote, bookingFormAnswers): MWJobModel {
  const customer = job.customer || {};
  const address = job.address || {};
  const addressTaskList = job.address_task_list;
  return {
    id: Number(job.job.id),
    introVideo: job.address_task_list.intro_video,
    date: job.job.date,
    time: job.job.start_time,
    startTime: job.job.date + ' ' + job.job.start_time,
    endTime: job.job.date + ' ' + job.job.end_time,
    type: job.job.type,
    before_after_photos: addressTaskList?.before_after_photos_state,
    block_complete_on_missing_fields: addressTaskList?.block_complete_on_missing_fields === 'true',
    client: customer.account_name !== '' && customer.account_name ? customer.account_name : customer.first_name,
    last_name: customer.account_name !== '' && customer.account_name ? '' : customer.last_name,
    addressId: address.id,
    address: [address.address1, address.address2, address.zip].filter(Boolean).join(', ') + (address?.address_name ? (' - ' + address?.address_name) : ''),
    addressPhotosParking: extractPhotos(job.address, 'parking'),
    addressPhotosAccess: extractPhotos(job.address, 'access'),
    addressPhotosClosing: extractPhotos(job.address, 'closing'),
    addressUnit: address.address2,
    zip: address.zip,
    parking: address.parking_instructions,
    access: address.home_access,
    city: address.city,
    fee: job.call_out_price,
    performance: job.performance,
    homekeeper_job_id: job.homekeeper_job.id,
    routeAddress: [address.address1, address.zip].filter(Boolean).join(', '),
    latitude: address.latitude,
    longitude: address.longitude,
    jobHasToDoList: addressTaskList?.id !== null && addressTaskList?.id !== undefined,
    state: job.homekeeper_job.state,
    isRepeatClient: job.homekeeper_job.repeat_client,
    isStandby: job.homekeeper_job.state === 'standby',
    isStandbyCancelled: job.homekeeper_job.performance === 'standby_cancellation',
    paid_parking: ((address.paid_parking === true) ? 'Paid' : 'Free'),
    parking_spot: parkingSpot[address.parking_spot],
    payment_instructions: extractParkingPaymentInstructions(address),
    payment_price: job.payment_price,
    parking_notes: address.parking_notes,
    customerId: customer.id,
    accountType: customer.account_type,
    inactive: checkInactiveJob(job),
    cancelled: checkCancelledJob(job),
    isBookingProtection: job.card_type === 'booking_protection_cleaning',
    checkTime: job.check_time_in_minutes,
    planId: job.job.plan_id,
    deviceId: job.deviceId,
    job_delegation_request: job.job_delegation_request,
    other_pro: job.other_homekeeper_job?.first_name,
    remoteAccessPin: job?.remote_access_information?.pin,
    showTimer: job?.service_type_details?.service_category?.name == 'Regular Cleaning',
    categoryName: job?.service_type_details?.service_category?.name == 'Regular Cleaning' ? 'Cleaning' : job?.service_type_details?.service_category?.name,
    duration: job?.job?.duration_in_minutes / 60 + (job?.job?.duration_in_minutes > 60 ? ' hours' : ' hour'),
    bookingNote: bookingNote,
    bookingFormAnswers: bookingFormAnswers,
    isPremium: customer?.is_premium,
    guest_reservation_data: job?.guest_reservation_data,
    hasSameDayCheckIn: checkIfHasSameDayCheckIn(job?.guest_reservation_data?.next_guest_reservation?.check_in_date, job.job.date)
  };
}

function checkIfHasSameDayCheckIn(nextReservationDate, jobDate) {
  if (!nextReservationDate) {
    return false;
  }
  const checkInDate = LuxonDateTime.fromISO(nextReservationDate);
  const formattedJobDate = LuxonDateTime.fromISO(jobDate);
  return checkInDate.hasSame(formattedJobDate, 'day');
}

function extractPhotos(address, type) {
  if (!address || !address.photo_notes) {
    return [];
  }
  return address.photo_notes.filter((photo) => photo.type === type);
}

function extractParkingPaymentInstructions(address) {
  if (address.paid_parking !== true) {
    return null;
  }
  const { parking_spot, parking_pay_with, max_parking_cost } = address;
  switch (parking_spot) {
    case 'paidlot':
    case 'meter':
      const formattedParkingCost = new TidyCurrencyPipe().transform(max_parking_cost);
      return `${new TranslationPipe().transform('Client will reimburse up to')} ${formattedParkingCost} ${paidParkingMessage[parking_pay_with]}`;
    default:
      return null;
  }
}

function checkInactiveJob(cleaning: any): boolean {
  return inactivePerformances.indexOf(cleaning.homekeeper_job.performance) > -1
      || inactiveStates.indexOf(cleaning.homekeeper_job.state) > -1;
}

function checkCancelledJob(cleaning: any): boolean {
  return cleaning.homekeeper_job.performance === 'client_cancellation'
      || cleaning.homekeeper_job.performance === 'client_cancellation_arb';
}

const parkingSpot = {
  paidlot: new TranslationPipe().transform('lot'),
  meter: new TranslationPipe().transform('meter'),
  myspot: new TranslationPipe().transform('client spot / driveway'),
  guest: new TranslationPipe().transform('guest'),
  street: new TranslationPipe().transform('street')
};

const paidParkingMessage = {
  card: new TranslationPipe().transform('on their card.  Send a picture of your receipt to us in the Concierge section.'),
  cash: new TranslationPipe().transform('with cash in person.')
};

const inactivePerformances = [
  'no_access_client_called',
  'homekeeper_cancelled_7_days',
  'call_out',
  'last_minute_call_out',
  'no_show',
  'po_cancellation',
  'cancelled_after',
  'call_out_all_day',
  'excused_cancellation',
  'auto_no_show',
  'scheduling_error'
];

const inactiveStates = [
  'cleaning_cancelled',
  'inactive',
  'cancelled_after',
  'admin_error'
];
