<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [customBack]="getBackRoute()"
    [title]="'Private Job'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-alert *ngIf="hasSameDayCheckIn">
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            [src]="'assets/img/green-alert.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Time Sensitive'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="(jobDetails?.customer?.account_name !== '' && jobDetails?.customer?.account_name) ?
            jobDetails?.customer?.account_name :
            (jobDetails?.customer?.first_name + ' ' + (jobDetails?.customer?.last_name | abbrLastName: true))"
            [translate]="false">
          </tidy-text>
          <tidy-text
            [body]="' has a guest checking in'">
          </tidy-text>
          <tidy-text
            *ngIf="jobDetails?.guest_reservation_data?.next_guest_reservation?.check_in_time"
            [body]="' at '">
          </tidy-text>
          <tidy-text
            *ngIf="jobDetails?.guest_reservation_data?.next_guest_reservation?.check_in_time"
            [body]="formatReservationTime(jobDetails?.guest_reservation_data?.next_guest_reservation?.check_in_time)">
          </tidy-text>
          <tidy-text
            [body]="' on the same day as the job.'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <ng-container *ngIf="jobDetails?.job?.private_status === 'scheduled'">
        <tidy-alert *ngIf="jobDetails?.job?.job_delegation_request?.status === 'requested' && delegationRole === 'delegator'">
          <tidy-row>
            <tidy-text
              [body]="'You requested that '">
            </tidy-text>
            <tidy-text
              *ngIf="jobDetails?.job?.job_delegation_request?.delegated_to_team_member?.homekeeper?.name"
              [body]="jobDetails?.job?.job_delegation_request?.delegated_to_team_member?.homekeeper?.name"
              [translate]="false">
            </tidy-text>
            <tidy-text
              *ngIf="!jobDetails?.job?.job_delegation_request?.delegated_to_team_member?.homekeeper?.name"
              [body]="'an invited team member'">
            </tidy-text>
            <tidy-text
              [body]="' accept this job. We sent an email to '">
            </tidy-text>
            <tidy-text
              [body]="jobDetails?.job?.job_delegation_request?.delegated_to_team_member?.homekeeper?.email + '. '"
              [translate]="false">
            </tidy-text>
            <tidy-text
              (action)="cancelDelegationRequest('cancel')"
              [body]="'Cancel Request'"
              class="link">
            </tidy-text>
          </tidy-row>
        </tidy-alert>

        <ng-container *ngIf="jobDetails?.job?.job_delegation_request?.status === 'accepted'">
          <tidy-alert *ngIf="delegationRole === 'delegatee'">
            <tidy-row>
              <tidy-text
                [body]="'This job was delegated to you by '">
              </tidy-text>
              <tidy-text
                [body]="jobDetails?.job?.job_delegation_request?.delegated_by_team_member?.homekeeper?.name"
                [translate]="false">
              </tidy-text>
              <tidy-text
                [body]="'.  All payment will be made to them. '">
              </tidy-text>
              <tidy-text
                (action)="cancelDelegationRequest('undo')"
                [body]="'Undo'"
                class="link">
              </tidy-text>
            </tidy-row>
          </tidy-alert>

          <tidy-alert *ngIf="delegationRole === 'delegator'">
            <tidy-row>
              <tidy-text
                [body]="'You delegated this job to '">
              </tidy-text>
              <tidy-text
                [body]="jobDetails?.job?.job_delegation_request?.delegated_to_team_member?.homekeeper?.name"
                [translate]="false">
              </tidy-text>
              <tidy-text
                [body]="'.  All payment will be made to you. '">
              </tidy-text>
              <tidy-text
                (action)="cancelDelegationRequest('getJobBack')"
                [body]="'Undo'"
                class="link">
              </tidy-text>
            </tidy-row>
          </tidy-alert>
        </ng-container>
      </ng-container>

      <tidy-alert *ngIf="jobDetails?.job?.private_status !== 'scheduled' && jobDetails?.job?.job_delegation_request?.status === 'accepted'">
        <tidy-row>
          <ng-container *ngIf="delegationRole === 'delegator'">
            <tidy-text
              [body]="'You delegated this job to '">
            </tidy-text>
            <tidy-text
              [body]="jobDetails?.job?.job_delegation_request?.delegated_to_team_member?.homekeeper?.name"
              [translate]="false">
            </tidy-text>
            <tidy-text
              [body]="' and they completed it.  All payment will be made to you. '">
            </tidy-text>
          </ng-container>
          <ng-container *ngIf="delegationRole === 'delegatee'">
            <tidy-text
              [body]="'This job was delegated to you by '">
            </tidy-text>
            <tidy-text
              [body]="jobDetails?.job?.job_delegation_request?.delegated_by_team_member?.homekeeper?.name"
              [translate]="false">
            </tidy-text>
            <tidy-text
              [body]="'.  All payment will be made to them. '">
            </tidy-text>
          </ng-container>
        </tidy-row>
      </tidy-alert>

      <tidy-row [align]="'center'" *ngIf="toDos.getSliderText('PrivateJobPage', jobDetails) == 'Tell Client I\'ve Finished' && jobDetails?.job?.private_status == 'scheduled'">
        <tidy-text
          [body]="'Client was told you have <b>started the job</b>.'">
        </tidy-text>
      </tidy-row>

      <ng-container *ngIf="jobDetails?.job?.private_status === 'scheduled'">
        <ng-container *ngIf="screenwidth < 1000">
          <slider-trigger
            [label]="toDos.getSliderText('PrivateJobPage', jobDetails)"
            [onTriggeredAction]="completeJobAction.bind(this)">
          </slider-trigger>
        </ng-container>

        <ng-container *ngIf="screenwidth >= 1000">
          <tidy-button
            [text]="toDos.getSliderText('PrivateJobPage', jobDetails)"
            class="primary"
            [action]="completeJobAction.bind(this)">
          ></tidy-button>
        </ng-container>
      </ng-container>

      <tidy-row *ngIf="jobDetails?.job?.private_status == 'scheduled' && !toDos.getSliderText('PrivateJobPage', jobDetails).includes('Finished')" [align]="'center'">
        <tidy-text
          [body]="'Already Finished? '">
        </tidy-text>
        <tidy-text
          (action)="markJobComplete()"
          [body]="'Mark Complete'"
          class="link">
        </tidy-text>
      </tidy-row>

      <tidy-row *ngIf="jobDetails?.job?.private_status !== 'scheduled'" [align]="'center'">
        <tidy-text
          [body]="'Marked as '">
        </tidy-text>
        <tidy-text
          [body]="(jobDetails.job.private_status | privateStatus)">
        </tidy-text>
        <tidy-text
          [body]="'. '">
        </tidy-text>
        <tidy-text
          (action)="editStatus()"
          [body]="'Edit'"
          class="link">
        </tidy-text>
      </tidy-row>

      <tidy-error-message
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-card>
        <tidy-row>
          <ion-row>
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="jobDetails?.job?.service_type_details?.service_category?.icon_url"
                style="width:30px;">
              </tidy-image>
            </tidy-row>
            <ion-col>
              <tidy-row>
                <tidy-text
                  [header]="(jobDetails?.job?.service_type_details?.customer_name || jobDetails?.job?.service_type_details?.name) | titlecase">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="'Blocking '">
                </tidy-text>
                <tidy-text
                  [body]="(jobDetails?.job?.service_type_details?.duration_in_minutes / 60)">
                </tidy-text>
                <tidy-text
                  [body]="' hour' + ((jobDetails?.job?.service_type_details?.duration_in_minutes / 60) == 1 ? '' : 's') + ' on calendar'">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="(date | tdate:'dddd M/D/YY')">
                </tidy-text>
                <tidy-text
                  [body]="' at '">
                </tidy-text>
                <tidy-text
                  [body]="(startTime | ttime)">
                </tidy-text>
              </tidy-row>
            </ion-col>
          </ion-row>
        </tidy-row>
        <tidy-row *ngIf="jobDetails?.job?.private_status == 'completed'" class="vertical-align-center" [ngClass]="{'extra-bottom-padding': showJobDuration}">
          <tidy-image
            [src]="'assets/svg/time-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Job Duration: '">
          </tidy-text>
          <tidy-text
            style="margin-right: 5px"
            [body]="jobDuration">
          </tidy-text>
          <tidy-text
            *ngIf="!showJobDuration"
            class="link"
            (action)="toggleShowEditJobDuration()"
            [body]="'Edit'">
          </tidy-text>
        </tidy-row>

        <tidy-row *ngIf="showJobDuration" class="extra-bottom-padding" style="margin-top: -10px">
          <tidy-text
            class="link"
            (action)="toggleShowEditJobDuration()"
            [body]="'Stop Editing'">
          </tidy-text>
        </tidy-row>

        <ng-container *ngIf="showJobDuration">
          <form [formGroup]="form" novalidate action="#">
            <tidy-row>
              <tidy-select
                [icon]="'assets/svg/time-outline.svg'"
                [label]="'Hours'"
                [form]="form.controls.jobHours"
                formControlName="jobHours"
                [items]="jobHoursItems">
              </tidy-select>
            </tidy-row>
            <tidy-select
              [icon]="'assets/svg/time-outline.svg'"
              [label]="'Minutes'"
              [form]="form.controls.jobMinutes"
              formControlName="jobMinutes"
              [items]="jobMinutesItems">
            </tidy-select>
            <tidy-button
              [text]="'Save Change'"
              [action]="submitJobDuration.bind(this)"
              class="primary">
            </tidy-button>
          </form>
        </ng-container>
        <ng-container *ngIf="jobDetails?.job?.private_status === 'scheduled'">
          <tidy-card-button (action)="delegateJob()" *ngIf="jobDetails?.job?.job_delegation_request?.status !== 'accepted' && jobDetails?.job?.job_delegation_request?.status !== 'requested'">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/pros.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [body]="'Delegate Job'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
          <tidy-card-button data-cy="rescheduleJob" (action)="goToRescheduleCancelPage()">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/arrow-forward.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [body]="'Reschedule / Cancel Job'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </ng-container>
      </tidy-card>

      <tidy-card *ngIf="jobDetails?.guest_reservation_data?.next_guest_reservation || jobDetails?.guest_reservation_data?.previous_guest_reservation">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/reservation.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Rental Information'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="(jobDetails?.customer?.account_name !== '' && jobDetails?.customer?.account_name) ?
            jobDetails?.customer?.account_name :
            (jobDetails?.customer?.first_name + ' ' + (jobDetails?.customer?.last_name | abbrLastName: true))"
            [translate]="false">
          </tidy-text>
          <tidy-text
            [body]="' rents out their property.'">
          </tidy-text>
        </tidy-row>
        <tidy-row [ngClass]="jobDetails?.guest_reservation_data?.next_guest_reservation ? 'extra-bottom-padding' : ''" *ngIf="jobDetails?.guest_reservation_data?.previous_guest_reservation">
          <tidy-text
            [body]="'<strong>Last Reservation:</strong>'">
          </tidy-text><br>
          <tidy-text
            [body]="formatReservationDate(jobDetails?.guest_reservation_data?.previous_guest_reservation?.check_in_date) + ' - ' + formatReservationDate(jobDetails?.guest_reservation_data?.previous_guest_reservation?.check_out_date) + getReservationLength(jobDetails?.guest_reservation_data?.previous_guest_reservation?.check_in_date, jobDetails?.guest_reservation_data?.previous_guest_reservation?.check_out_date)">
          </tidy-text>
          <ng-container *ngIf="previousGuestReservationDetails">
            <br>
            <tidy-text
              *ngIf="previousGuestReservationDetails?.total_guests"
              [body]="previousGuestReservationDetails?.total_guests">
            </tidy-text>
            <tidy-text
              *ngIf="previousGuestReservationDetails?.total_guests"
              [body]="' guest(s)'">
            </tidy-text>
            <tidy-text
              *ngIf="previousGuestReservationDetails?.total_guests"
              [body]="(previousGuestReservationDetails?.total_adults || previousGuestReservationDetails?.total_children || previousGuestReservationDetails?.total_pets ? ' | ' : '')">
            </tidy-text>
            <tidy-text
              *ngIf="previousGuestReservationDetails?.total_adults"
              [body]="previousGuestReservationDetails?.total_adults">
            </tidy-text>
            <tidy-text
              *ngIf="previousGuestReservationDetails?.total_adults"
              [body]="' adult(s)'">
            </tidy-text>
            <tidy-text
              *ngIf="previousGuestReservationDetails?.total_adults"
              [body]="(previousGuestReservationDetails?.total_children || previousGuestReservationDetails?.total_pets ? ' | ' : '')">
            </tidy-text>
            <tidy-text
              *ngIf="previousGuestReservationDetails?.total_children"
              [body]="previousGuestReservationDetails?.total_children">
            </tidy-text>
            <tidy-text
              *ngIf="previousGuestReservationDetails?.total_children"
              [body]="' children'">
            </tidy-text>
            <tidy-text
              *ngIf="previousGuestReservationDetails?.total_children"
              [body]="(previousGuestReservationDetails?.total_pets ? ' | ' : '')">
            </tidy-text>
            <tidy-text
              *ngIf="previousGuestReservationDetails?.total_pets"
              [body]="previousGuestReservationDetails?.total_pets">
            </tidy-text>
            <tidy-text
              *ngIf="previousGuestReservationDetails?.total_pets"
              [body]="' pet(s)'">
            </tidy-text>
          </ng-container>
          <ng-container *ngIf="previousGuestReservationDetails?.customFields">
            <ng-container *ngFor="let customField of previousGuestReservationDetails?.customFields">
              <tidy-row>
                <tidy-text
                  [body]="customField">
                </tidy-text>
              </tidy-row>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="jobDetails?.guest_reservation_data?.previous_guest_reservation?.check_out_time">
            <br>
            <tidy-text
              [body]="formatReservationTime(jobDetails?.guest_reservation_data?.previous_guest_reservation?.check_out_time)">
            </tidy-text>
            <tidy-text
              [body]="' check out'">
            </tidy-text>
          </ng-container>
        </tidy-row>
        <tidy-row *ngIf="jobDetails?.guest_reservation_data?.next_guest_reservation">
          <tidy-text
            [body]="'<strong>Next Reservation:</strong>'">
          </tidy-text><br>
          <tidy-text
            [body]="formatReservationDate(jobDetails?.guest_reservation_data?.next_guest_reservation?.check_in_date) + ' - ' + formatReservationDate(jobDetails?.guest_reservation_data?.next_guest_reservation?.check_out_date) + getReservationLength(jobDetails?.guest_reservation_data?.next_guest_reservation?.check_in_date, jobDetails?.guest_reservation_data?.next_guest_reservation?.check_out_date)">
          </tidy-text>
          <ng-container *ngIf="nextGuestReservationDetails">
            <br>
            <tidy-text
              *ngIf="nextGuestReservationDetails?.total_guests"
              [body]="nextGuestReservationDetails?.total_guests">
            </tidy-text>
            <tidy-text
              *ngIf="nextGuestReservationDetails?.total_guests"
              [body]="' guest(s)'">
            </tidy-text>
            <tidy-text
              *ngIf="nextGuestReservationDetails?.total_guests"
              [body]="(nextGuestReservationDetails?.total_adults || nextGuestReservationDetails?.total_children || nextGuestReservationDetails?.total_pets ? ' | ' : '')">
            </tidy-text>
            <tidy-text
              *ngIf="nextGuestReservationDetails?.total_adults"
              [body]="nextGuestReservationDetails?.total_adults">
            </tidy-text>
            <tidy-text
              *ngIf="nextGuestReservationDetails?.total_adults"
              [body]="' adult(s)'">
            </tidy-text>
            <tidy-text
              *ngIf="nextGuestReservationDetails?.total_adults"
              [body]="(nextGuestReservationDetails?.total_children || nextGuestReservationDetails?.total_pets ? ' | ' : '')">
            </tidy-text>
            <tidy-text
              *ngIf="nextGuestReservationDetails?.total_children"
              [body]="nextGuestReservationDetails?.total_children">
            </tidy-text>
            <tidy-text
              *ngIf="nextGuestReservationDetails?.total_children"
              [body]="' children'">
            </tidy-text>
            <tidy-text
              *ngIf="nextGuestReservationDetails?.total_children"
              [body]="(nextGuestReservationDetails?.total_pets ? ' | ' : '')">
            </tidy-text>
            <tidy-text
              *ngIf="nextGuestReservationDetails?.total_pets"
              [body]="nextGuestReservationDetails?.total_pets">
            </tidy-text>
            <tidy-text
              *ngIf="nextGuestReservationDetails?.total_pets"
              [body]="' pet(s)'">
            </tidy-text>
          </ng-container>
          <ng-container *ngIf="jobDetails?.guest_reservation_data?.next_guest_reservation?.check_in_time">
            <br>
            <tidy-text
              [body]="formatReservationTime(jobDetails?.guest_reservation_data?.next_guest_reservation?.check_in_time)">
            </tidy-text>
            <tidy-text
              [body]="' check in'">
            </tidy-text>
          </ng-container>
          <ng-container *ngIf="nextGuestReservationDetails?.customFields">
            <ng-container *ngFor="let customField of nextGuestReservationDetails?.customFields">
              <tidy-row>
                <tidy-text
                  [body]="customField">
                </tidy-text>
              </tidy-row>
            </ng-container>
          </ng-container>
        </tidy-row>
      </tidy-card>

      <tidy-row [alignLastItemRight]="true">
        <tidy-text
          [header]="'Client'">
        </tidy-text>
        <tidy-text
          [actionBody]="'View Client'"
          class="link"
          (action)="goToClientDetails()">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <tidy-row class="extra-bottom-padding" [alignLastItemRight]="true">
          <tidy-image
            [src]="'assets/img/account.svg'"
            class="title-size">
          </tidy-image>
          <tidy-text
            [header]="(jobDetails?.customer?.account_name !== '' && jobDetails?.customer?.account_name) ?
            jobDetails?.customer?.account_name :
            (jobDetails?.customer?.first_name + ' ' + (jobDetails?.customer?.last_name | abbrLastName: true))"
            [translate]="false">
          </tidy-text>
          <tidy-text
            [body]="'Call'"
            class="link"
            (action)="callAction()">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="jobDetails?.customer?.is_premium">
          <tidy-text
            [actionBody]="'Big Spender: They book many jobs.'"
            class="green">
          </tidy-text>
        </tidy-row>
        <tidy-row class="field-top-padding" [ngClass]="messages?.length ? 'extra-bottom-padding' : ''">
          <form [formGroup]="form" novalidate action="#">
            <tidy-textarea
              [label]="'Send Message'"
              formControlName="message"
              [form]="form.controls.message"
              [submitted]="submitted"
              [errorMessage]="'Please enter a message to send.'"
              [rightIconAction]="sendMessage.bind(this)"
              [leftIconAction]="sendAttachment.bind(this)">
            </tidy-textarea>
          </form>
        </tidy-row>
        <ng-container *ngIf="messagesLoaded">
          <tidy-row *ngIf="messages?.length == 0" [align]="'center'">
            <tidy-text
              [helper]="'No Messages'">
            </tidy-text>
          </tidy-row>
          <ng-container *ngFor="let message of messages; let last = last; let index = index">
            <message
              *ngIf="index < 3"
              [message]="message"
              [last]="last">
            </message>
          </ng-container>
          <tidy-row [align]="'center'" *ngIf="messages?.length > 3">
            <tidy-text
              class="link"
              [actionBody]="'View '"
              (action)="goToJobMessagesPage()">
            </tidy-text>
            <tidy-text
              class="link"
              [actionBody]="(messages?.length - 3)"
              (action)="goToJobMessagesPage()">
            </tidy-text>
            <tidy-text
              class="link"
              [actionBody]="' More Message' + (messages?.length == 4 ? '' : 's')"
              (action)="goToJobMessagesPage()">
            </tidy-text>
          </tidy-row>
        </ng-container>
        <tidy-row *ngIf="!messagesLoaded" [align]="'center'">
          <tidy-text
            [helper]="'Loading Messages...'">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-card *ngIf="delegationRole !== 'delegatee'">
        <tidy-row [alignLastItemRight]="true">
          <tidy-image
            [src]="'assets/img/credit-card.svg'"
            class="title-size">
          </tidy-image>
          <tidy-text
            [header]="'Payments'">
          </tidy-text>
          <div>
            <tidy-text
              *ngIf="jobDetails?.job?.private_status !== 'scheduled' && jobDetails.job.service_type_details.billing_type =='price_later'"
              [body]="'No price set'">
            </tidy-text>
            <ng-container *ngIf="jobDetails?.job?.private_status !== 'scheduled' && jobDetails.job.service_type_details.billing_type !=='price_later'">
              <tidy-text
                *ngIf="jobDetails?.job?.invoice"
                [body]="(jobDetails?.job?.invoice?.amount_due | tcurrency)">
              </tidy-text>
              <tidy-text
                *ngIf="jobDetails?.job?.invoice"
                [body]="' owed of '">
              </tidy-text>
              <tidy-text
                *ngIf="jobDetails?.job?.invoice"
                [body]="(amount | tcurrency)">
              </tidy-text>
              <tidy-text
                *ngIf="jobDetails?.job?.invoice"
                [body]="' price'">
              </tidy-text>
              <tidy-text
                *ngIf="!jobDetails?.job?.invoice"
                [body]="(jobDetails?.job?.billing?.amount_due | tcurrency)">
              </tidy-text>
              <tidy-text
                *ngIf="!jobDetails?.job?.invoice"
                [body]="' owed of '">
              </tidy-text>
              <tidy-text
                *ngIf="!jobDetails?.job?.invoice"
                [body]="(amount | tcurrency)">
              </tidy-text>
              <tidy-text
                *ngIf="!jobDetails?.job?.invoice"
                [body]="' price'">
              </tidy-text>
            </ng-container>
            <ng-container *ngIf="jobDetails?.job?.private_status == 'scheduled'">
              <tidy-text
                *ngIf="jobDetails?.job?.service_type_details?.billing_type !== 'price_later' && jobDetails?.job?.service_type_details?.billing_type !== 'hourly'"
                [body]="(amount | tcurrency)">
              </tidy-text>
              <tidy-text
                *ngIf="jobDetails?.job?.service_type_details?.billing_type !== 'price_later' && jobDetails?.job?.service_type_details?.billing_type !== 'hourly'"
                [body]="' price '">
              </tidy-text>
              <tidy-text
                *ngIf="jobDetails?.job?.service_type_details?.billing_type == 'hourly'"
                [body]="(amount | tcurrency)">
              </tidy-text>
              <tidy-text
                *ngIf="jobDetails?.job?.service_type_details?.billing_type == 'hourly'"
                [body]="' / hour '">
              </tidy-text>
              <tidy-text
                *ngIf="jobDetails?.job?.service_type_details?.billing_type == 'price_later'"
                [body]="'Price later '">
              </tidy-text>
            </ng-container>
            <tidy-text
              style="margin-left: 5px"
              (action)="goToEditJobPrice()"
              [body]="'Edit'"
              class="link">
            </tidy-text>
          </div>
        </tidy-row>
        <tidy-row class="extra-bottom-padding" *ngIf="jobDetails?.job?.billing?.details?.length == 0">
          <tidy-text
            [body]="'<i>No Payments for this Job</i>'">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngFor="let detail of jobDetails?.job?.billing?.details" class="vertical-align-center extra-top-padding" [ngClass]="jobDetails?.job?.invoice ? (jobDetails?.job?.invoice?.amount_due == 0 ? '' : 'extra-bottom-padding') : (jobDetails?.job?.billing?.amount_due == 0 ? '' : 'extra-bottom-padding')">
          <tidy-image
            [src]="detail?.credit_type == 'credit' ? 'assets/svg/add-circle-outline.svg' : 'assets/svg/card-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="(detail.amount_used | tcurrency)">
          </tidy-text>
          <tidy-text
            style="margin-right: 5px"
            [body]="' used of '">
          </tidy-text>
          <tidy-text
            [body]="(detail.amount | tcurrency)">
          </tidy-text>
          <tidy-text
            style="margin-right: 5px"
            [body]="' ' + detail.credit_type">
          </tidy-text>
          <tidy-text
            *ngIf="detail.credit_type == 'charge' && detail.debit_type !== 'refund' && jobDetails?.job?.billing?.refundable"
            [body]="'Refund'"
            (action)="goToRefundChargePage()"
            class="link">
          </tidy-text>
        </tidy-row>
        <tidy-card-button (action)="goToSendInvoicePage(jobDetails?.job?.private_status === 'scheduled')" *ngIf="!jobDetails?.job?.invoice && !jobDetails?.job?.invoice?.full_paid">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="jobDetails?.job?.private_status === 'scheduled' ? 'assets/img/checkmark.svg' : 'assets/img/send.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="jobDetails?.job?.private_status === 'scheduled' ?
              'Mark Complete & Request Payment' :
              'Request Payment'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button [hideArrow]="true" *ngIf="jobDetails?.job?.invoice && !jobDetails?.job?.invoice?.full_paid">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/send-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="'Invoice Sent'">
            </tidy-text>&nbsp;
            <tidy-text
              [actionBody]="'View Invoice'"
              class="link"
              (action)="goToSentInvoicePage()">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="clientName | titlecase"
              [translate]="false">
            </tidy-text>
            <tidy-text
              [body]="' received an email at '">
            </tidy-text>
            <tidy-text
              [body]="jobDetails?.customer?.email"
              [translate]="false">
            </tidy-text>
            <tidy-text
              [body]="' and can see your invoice at your Client\'s Hub. '">
            </tidy-text>
            <tidy-text
              [body]="'Copy URL'"
              (action)="privatePayments.copyWebsite(this.jobDetails.customer.client_hub_uuid)"
              class="link">
            </tidy-text>
            <tidy-text
              *ngIf="privatePayments.isWebsiteCopied()"
              [helper]="' Copied'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="recordPayment()" *ngIf="!jobDetails?.job?.invoice?.full_paid && jobDetails?.job?.private_status !== 'scheduled'">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/create-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="'Mark as Paid'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card *ngIf="jobMileage?.length > 0">
        <tidy-row [alignLastItemRight]="true" class="extra-bottom-padding vertical-align-center">
          <tidy-image
            [src]="'assets/svg/car.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Mileage Tracking'">
          </tidy-text>
          <tidy-text
            (action)="editMileage()"
            [body]="'Edit'"
            class="link">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="distanceFormat === 'mi'">
          <tidy-text
            [body]="'Miles Travelled: '">
          </tidy-text>
          <tidy-text
            [body]="jobMileage[0]?.estimated_mileage">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="distanceFormat === 'km'">
          <tidy-text
            [body]="'Kilometers Travelled: '">
          </tidy-text>
          <tidy-text
            [body]="(jobMileage[0]?.estimated_mileage * 1.60934) || '0'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'Tax Deduction: '">
          </tidy-text>
          <tidy-text
            [body]="(jobMileage[0]?.estimated_total | tcurrency)">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row [alignLastItemRight]="true">
          <tidy-image
            [src]="'assets/img/home.svg'"
            class="title-size">
          </tidy-image>
          <tidy-text
            [header]="'Address'">
          </tidy-text>
          <tidy-text
            [body]="'Get Directions'"
            (action)="getDirections()"
            class="link">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="addressName"
            [translate]="false">
          </tidy-text>
          <ng-container *ngIf="unit && unit !== ''">
            <br>
            <tidy-text
              [body]="'Unit '">
            </tidy-text>
            <tidy-text
              [body]="unit">
            </tidy-text>
          </ng-container>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/car.svg'"
            class="title-size">
          </tidy-image>
          <tidy-text
            [header]="'Parking'">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="parking?.paid_parking !== null && parking?.paid_parking !== undefined || parking?.parking_notes !== null && parking?.parking_notes !== undefined && parking?.parking_notes !== ''">
          <tidy-text
            *ngIf="parking?.paid_parking !== null && parking?.paid_parking !== undefined"
            [body]="parking?.paid_parking ? 'Paid parking ' : 'Free parking '">
          </tidy-text>
          <tidy-text
            *ngIf="parking?.parking_spot"
            [body]="parkingDescriptions[parking?.parking_spot]">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="parking?.max_parking_cost">
          <tidy-text
            [body]="'Client will cover up to '">
          </tidy-text>
          <tidy-text
            [body]="(parking?.max_parking_cost | tcurrency)">
          </tidy-text>
          <tidy-text
            *ngIf="parking?.parking_pay_with"
            [body]="' and reimburse ' + (parking?.parking_pay_with == 'with cash' ? 'cash.' : 'through the TIDY app.')">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="parking?.parking_notes">
          <tidy-text
            [body]="'Client Notes: '"
            [translate]="false">
          </tidy-text>
          <tidy-text
            [body]="parking?.parking_notes"
            [translate]="false">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="!parking?.parking_notes && !parking?.paid_parking">
          <tidy-text
            [body]="'<i>No Parking Notes</i>'">
          </tidy-text>
        </tidy-row>
        <tidy-carrousel *ngIf="parkingPhotos?.length > 0" >
          <ng-container *ngFor="let photo of parkingPhotos">
            <tidy-photo-note
              class="full-width there-is-carrousel no-margin"
              *tidyCarrousel
              [caption]="photo?.photo_note_caption"
              [src]="photo?.photo_url">
            </tidy-photo-note>
          </ng-container>
        </tidy-carrousel>
      </tidy-card>

      <tidy-card>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/notes.svg'"
            class="title-size">
          </tidy-image>
          <tidy-text
            [header]="'Access'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <ng-container *ngIf="jobDetails?.remote_access_information?.pin">
            <tidy-text
              [body]="'Lockbox code: '">
            </tidy-text>
            <tidy-text
              [body]="jobDetails?.remote_access_information?.pin">
            </tidy-text>
            <tidy-text
              [body]="', Notes:'">
            </tidy-text>&nbsp;
          </ng-container>
          <tidy-text
            [body]="homeAccess">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="!homeAccess && !jobDetails?.remote_access_information?.pin">
          <tidy-text
            [body]="'<i>No Access Notes</i>'">
          </tidy-text>
        </tidy-row>
        <tidy-carrousel *ngIf="accessPhotos?.length > 0" >
          <ng-container *ngFor="let photo of accessPhotos">
            <tidy-photo-note
              class="full-width there-is-carrousel no-margin"
              *tidyCarrousel
              [caption]="photo?.photo_note_caption"
              [src]="photo?.photo_url">
            </tidy-photo-note>
          </ng-container>
        </tidy-carrousel>
      </tidy-card>

      <tidy-row [align]="'center'" *ngIf="todos?.length == 0">
        <tidy-text
          [helper]="clientName">
        </tidy-text>
        <tidy-text
          [helper]="' did not add any To-Dos for this job.'">
        </tidy-text>
      </tidy-row>

      <ng-container *ngIf="bookingNote?.length || jobDetails?.booking_form_answers?.length">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/information.svg'"
            class="title-size">
          </tidy-image>
          <tidy-text
            [title]="'Job Note'">
          </tidy-text>
        </tidy-row>

        <tidy-card>
          <ng-container *ngFor="let answer of jobDetails?.booking_form_answers">
            <ng-container *ngIf="answer?.template_input?.type == 'text' || answer?.template_input?.type == 'text_area'">
              <tidy-row>
                <tidy-text
                  [body]="answer?.template_input?.label + ': ' + answer?.data?.value">
                </tidy-text>
              </tidy-row>
              <tidy-photo-note
                *ngIf="answer?.template_input?.image_url"
                [src]="answer?.template_input?.image_url">
              </tidy-photo-note>
            </ng-container>
            <ng-container *ngIf="answer?.template_input?.type == 'select'">
              <tidy-row>
                <tidy-text
                  [body]="answer?.template_input?.label + ': ' + me.getBookingFormSelectValue(answer?.data?.value)">
                </tidy-text>
              </tidy-row>
              <tidy-photo-note
                *ngIf="answer?.template_input?.image_url"
                [src]="answer?.template_input?.image_url">
              </tidy-photo-note>
            </ng-container>
            <ng-container *ngIf="answer?.template_input?.type == 'multiselect'">
              <tidy-row>
                <tidy-text
                  [body]="answer?.template_input?.label + ': ' + me.getBookingFormMultiselectValue(answer?.data?.value)">
                </tidy-text>
              </tidy-row>
              <tidy-photo-note
                *ngIf="answer?.template_input?.image_url"
                [src]="answer?.template_input?.image_url">
              </tidy-photo-note>
            </ng-container>
            <ng-container *ngIf="answer?.template_input?.type == 'file' && answer?.template_input?.data?.type == 'image'">
              <tidy-row>
                <tidy-text
                  [body]="answer?.template_input?.label">
                </tidy-text>
              </tidy-row>
              <tidy-carrousel
                class="photo-notes-carrousel only-padding-bottom"
                [scaleImages]="true">
                <tidy-photo-note
                  *tidyCarrousel
                  [src]="answer?.data?.value">
                </tidy-photo-note>
              </tidy-carrousel>
            </ng-container>
            <ng-container *ngIf="answer?.template_input?.type == 'files'  && answer?.template_input?.data?.type == 'images'">
              <tidy-row>
                <tidy-text
                  [body]="answer?.template_input?.label">
                </tidy-text>
              </tidy-row>
              <tidy-carrousel
                class="photo-notes-carrousel only-padding-bottom"
                [scaleImages]="true">
                <ng-container *ngFor="let imageUrl of answer?.data?.value">
                  <tidy-photo-note
                    *tidyCarrousel
                    [src]="imageUrl">
                  </tidy-photo-note>
                </ng-container>
              </tidy-carrousel>
            </ng-container>
          </ng-container>
          <tidy-row *ngIf="bookingNote?.length">
            <tidy-text
              [body]="bookingNote[0]?.text">
            </tidy-text>
          </tidy-row>
        </tidy-card>
      </ng-container>

      <tidy-to-dos
        [page]="'PrivateJobPage'"
        [privateJob]="jobDetails"
        [rooms]="jobDetails?.todos">
      </tidy-to-dos>

    </ng-container>
    <ng-container *ngIf="!loaded">

      <tidy-row>
        <ion-skeleton-text animated style="height: 55px"></ion-skeleton-text>
      </tidy-row>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 140px; height: 20px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 100px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 140px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 80px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 170px"></ion-skeleton-text>
        </tidy-row>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated style="width: 170px"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated style="width: 170px"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated style="width: 170px"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 100px; height: 20px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 140px"></ion-skeleton-text>
        </tidy-row>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated style="width: 230px"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <tidy-grid>
            <ion-row>
              <ion-col size="6">
                <ion-skeleton-text animated style="width: 100px; height: 20px"></ion-skeleton-text>
              </ion-col>
              <ion-col size="6">
                <ion-skeleton-text animated style="width: 100px; float: right"></ion-skeleton-text>
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 200px"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 105px; height: 20px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 22%"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 100px; height: 20px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 45%"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 150px; height: 20px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 44%"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 95px; height: 20px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 15%"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

    </ng-container>

  </tidy-wrapper>

</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
