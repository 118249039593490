<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="day?.label + (' Settings' | translate)">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text [body]="'Send me '">
      </tidy-text>
      <tidy-text [body]="type">
      </tidy-text>
      <tidy-text [body]="' notifications if there are job opportunities at these times on '">
      </tidy-text>
      <tidy-text [body]="day?.label + ':'">
      </tidy-text>
    </tidy-row>

    <ng-container *ngFor="let time of times; let even = even; let i = index">
      <tidy-two-column-row *ngIf="even">
        <tidy-checkbox-button
          [label]="time.label"
          [value]="time.value"
          [(checked)]="time.checked">
        </tidy-checkbox-button>
        <tidy-checkbox-button
          item-right
          [label]="times[i+1].label"
          [value]="times[i+1].value"
          [(checked)]="times[i+1].checked">
        </tidy-checkbox-button>
      </tidy-two-column-row>
    </ng-container>

    <tidy-row>
      <tidy-bookable-legend
        [labelOn]="'Send'"
        [labelOff]="'Do Not Send'">
      </tidy-bookable-legend>
    </tidy-row>

    <tidy-error-message
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-button
      [action]="saveDay.bind(this)"
      [text]="('Apply to all ' | translate) + day?.label"
      class="primary">
    </tidy-button>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
