import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Concierge } from 'src/providers/concierge/concierge';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Logger } from "src/shared/providers/logger";

import { ConciergeItemModel } from 'src/models/concierge.model';
import { InAppBrowserUtils } from 'src/shared/utils/in-app-browser-utils';
import { AppConfig } from 'src/shared/providers/config';
import { Timeout } from 'src/shared/components/timeout/timeout';
import { TimeoutErrorHandler } from 'src/shared/providers/http/timeout-error-handler';

@Component({
  templateUrl: 'concierge-message.html',
  encapsulation: ViewEncapsulation.None
})

export class ConciergeMessagePage extends Timeout {

  email: string;
  errorMessage: string;
  item: ConciergeItemModel;
  pageTitle: string;
  replyTime: string;
  status: string;
  isJobIssue: boolean;
  jobData: any;

  constructor(
    public concierge: Concierge,
    private logger: Logger,
    private navCtrl: CustomNavController,
    public route: ActivatedRoute,
    private iabUtils: InAppBrowserUtils,
    timeoutErrorHandler: TimeoutErrorHandler,
    router: Router
  ) {
    super(timeoutErrorHandler, router, route);
  }

  async ionViewDidEnter() {
    this.loaded = false;
    this.item = this.navCtrl.getParam('item');
    if (!this.item) {
      const conciergeItemId = this.route.snapshot.paramMap.get('conciergeItemId');
      try {
        this.item = await this.concierge.getConciergeItemById(conciergeItemId);
      } catch (err) {
        this.timeoutHandler(err);
        this.logger.error(err);
        this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      }
    }
    this.email = localStorage.getItem('proEmail');
    if (!this.email) {
      await this.concierge.setProInfo();
      this.email = localStorage.getItem('proEmail');
    }
    this.isJobIssue = this.item.type == 'support.homekeeper_job_issue' && this.item.metadata.custom.job_id != null;
    this.pageTitle = this.getPageTitle();
    this.status = this.item.components[0].value.status;
    this.replyTime = this.concierge.getReplyTime(this.item.type);
    this.loaded = true;
  }

  getPageTitle() {
    switch (this.item.type) {
      case 'support.homekeeper_job_issue':
        return 'Job Issue';
      case 'support.homekeeper_how_to':
        return 'How to Use TIDY';
      case 'support.homekeeper_payment':
        return 'Billing Issue';
      case 'support.homekeeper_team':
        return 'Managing My Team';
      case 'support.homekeeper_private_client':
        return 'Private Clients';
      case 'support.homekeeper_other':
        return 'Other Question';
    }
  }

  goToJobDetails(){
    this.navCtrl.navigateForward(`past-job/${this.item.metadata.custom.job_id}`);
  }

  async reply() {
    const params = {
      item: this.item
    };
    this.navCtrl.navigateForward('concierge-reply', params);
  }

  openAttachment(fileUrl: string) {
    this.iabUtils.openUrl(fileUrl);
  }
}
