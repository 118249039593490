<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="pageContent?.title">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper *ngIf="loaded">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'" >
      <tidy-image
        *ngIf="pageContent?.image !== ''"
        [src]="pageContent?.image"
        class="upcoming-feature-image hero-size">
      </tidy-image>
    </tidy-row>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="pageContent?.body">
      </tidy-text>
    </tidy-row>

    <tidy-card>
      <form [formGroup]="form" novalidate action="#">
        <tidy-row class="field-top-padding">
         <tidy-textarea
           formControlName="note"
           [form]="form.controls.note"
           [form]="form"
           [label]="feature == 'other_services' ? 'What services types do you offer?' : 'Why do you want this feature?'"
           [icon]="'assets/svg/help-circle-outline.svg'"
           [activeIcon]="'assets/svg/help-circle-outline-tidy-green.svg'">
         </tidy-textarea>
       </tidy-row>
     </form>
    </tidy-card>

    <tidy-error-message
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-row>
      <tidy-button
        [text]="feature == 'other_services' ? 'Request New Service Types' : 'Request this Feature'"
        [action]="requestFeature.bind(this)"
        class="primary">
      </tidy-button>
    </tidy-row>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
