import { Injectable } from '@angular/core';
import { HttpClient } from '../../shared/providers/http/http-client';

@Injectable()
export class UndoCancellation {

  constructor(
    private httpClient: HttpClient,
  ) {}

  checkUndoable(jobId: number): Promise<any> {
    const url = `api/v1/homekeeper/cleanings/${jobId}/cancellation/check-for-undoing`;
    return this.httpClient.get(url);
  }

  undoCancellation(jobId: number): Promise<any> {
    const url = `api/v1/homekeeper/cleanings/${jobId}/cancellation/undo`;
    return this.httpClient.post(url, {});
  }

}
