<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Clients from TIDY'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Start with the median rates in your area, or edit them to what matches your business.'">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <ng-container *ngFor="let service of requiredServices; let i = index; let last = last">
          <tidy-row [alignLastItemRight]="i == 0">
            <tidy-text
              [header]="service?.name | titlecase">
            </tidy-text>
            <tidy-text
              *ngIf="i == 0"
              [body]="'Edit Rates'"
              (action)="goToEditRates()"
              class="link">
            </tidy-text>
          </tidy-row>
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/cash-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="'New Client Rate: '">
            </tidy-text>
            <tidy-text
              [body]="service?.user_new_rate ? (service?.user_new_rate | tcurrency) : ''">
            </tidy-text>
          </tidy-row>
          <tidy-row class="vertical-align-center" [ngClass]="!last ? 'extra-bottom-padding' : ''">
            <tidy-image
              [src]="'assets/svg/cash-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="'Repeat Client Rate: '">
            </tidy-text>
            <tidy-text
              [body]="service?.user_repeat_rate ? (service?.user_repeat_rate | tcurrency) : ''">
            </tidy-text>
          </tidy-row>
        </ng-container>
      </tidy-card>

      <tidy-row [align]="'center'">
      </tidy-row>

      <tidy-button
        [text]="'Turn on Clients from TIDY'"
        [action]="goLive.bind(this)"
        class="primary">
      </tidy-button>

    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
